import React from 'react';
import cs from 'classnames';
import { Button } from '../Button/Button';
import { t } from 'src/lib/i18n';
import './SearchInput.scss';
import searchImportantLinesLeft from 'src/images/illustrations/search-important-left.svg';
import searchImportantLinesRight from 'src/images/illustrations/search-important-right.svg';
import { Icon } from '@telia/styleguide';
import { useTextField } from '../TextField/useTextField';
import { TextFieldProps } from '../TextField/TextField';

export type SearchStyleType = 'default' | 'dark' | 'important';
export type SearchInputProps = TextFieldProps & {
  inputStyleType?: SearchStyleType;
  placeholder?: string;
  narrow?: boolean;
  menuOpen?: boolean;
  onSearch?: () => void;
  onClose?: () => void;
};

/**
 * A specialized variant of TextField, with form and function adapted to search.
 */
export const SearchInput: React.FC<SearchInputProps> = props => {
  const {
    focus,
    ref,
    onBlur,
    onFocus,
    onKeyDown,
    onChange,
    setFocus,
    inputActive,
  } = useTextField(props);

  const mouseEnterHandler = (event: React.MouseEvent<HTMLInputElement>) => {
    if (props.inputStyleType === 'important' && !inputActive) {
      setFocus(true);
    }
    if (props.onMouseEnter) {
      props.onMouseEnter(event);
    }
  };

  const mouseLeaveHandler = (event: React.MouseEvent<HTMLInputElement>) => {
    if (props.inputStyleType === 'important' && !inputActive) {
      setFocus(false);
    }
    if (props.onMouseLeave) {
      props.onMouseLeave(event);
    }
  };

  const i18n = t.ui.SearchInput;

  const inputComponent = (
    <div
      className={cs('SearchInput input-group', props.className, {
        'SearchInput--open': props.menuOpen,
        'SearchInput--dark': props.inputStyleType === 'dark',
        narrow: props.narrow,
        focus: focus || props.menuOpen,
        disabled: props.disabled,
      })}
    >
      <div className="input-group-prepend">
        <span
          className={cs('input-group-text', {
            disabled: props.disabled,
          })}
        >
          {props.onSearch ? (
            <Button
              icon="search"
              type="button"
              base="default"
              color="dark"
              size="small"
              hideLabel={true}
              transparent={true}
              rounded={true}
              onClick={props.onSearch}
            >
              {i18n.btnSearch}
            </Button>
          ) : (
            <Icon
              icon="search"
              style={{
                height: '1.25rem',
                width: '1.25rem',
                margin: '0.5rem 0.25rem',
              }}
            />
          )}
        </span>
      </div>
      <input
        id={props.id}
        type="text"
        className="SearchInput-input form-control"
        placeholder={props.placeholder}
        disabled={props.disabled}
        value={props.value}
        ref={ref}
        aria-label={props['aria-label']}
        aria-activedescendant={props['aria-activedescendant']}
        aria-autocomplete={props['aria-autocomplete']}
        aria-controls={props['aria-controls']}
        aria-labelledby={props['aria-labelledby']}
        autoComplete={props.autoComplete}
        onFocus={onFocus}
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        onKeyUp={props.onKeyUp}
        onChange={onChange}
        onMouseEnter={mouseEnterHandler}
        onMouseLeave={mouseLeaveHandler}
      />
      {(props.value || props.menuOpen) && props.onClose && (
        <div className="input-group-append">
          <span className="input-group-text">
            <Button
              icon="close"
              type="button"
              base="default"
              color="dark"
              size="small"
              hideLabel={true}
              transparent={true}
              rounded={true}
              onClick={props.onClose}
            >
              {i18n.btnClear}
            </Button>
          </span>
        </div>
      )}
    </div>
  );

  if (
    props.inputStyleType === undefined ||
    props.inputStyleType === 'default' ||
    props.inputStyleType === 'dark'
  ) {
    return inputComponent;
  }

  const hasValue = Boolean(props.value);
  return (
    <div
      className={cs('SearchInput-wrapper d-flex align-items-center', {
        'SearchInput-wrapper--important':
          props.inputStyleType === 'important' && !hasValue,
        focus: focus,
      })}
      style={{ position: 'relative', zIndex: 1 }}
    >
      <img
        src={searchImportantLinesLeft}
        className={cs('pr-3', focus || hasValue ? 'hide' : undefined)}
        style={{ marginLeft: '-2rem', position: 'absolute' }}
        alt=""
      />
      <div style={{ flex: 1 }}>{inputComponent}</div>
      <img
        src={searchImportantLinesRight}
        className={cs('pl-3', focus || hasValue ? 'hide' : undefined)}
        style={{ marginRight: '-2rem', position: 'absolute', right: 0 }}
        alt=""
      />
    </div>
  );
};
