import { useRouter } from 'src/lib/utils/useRouter';
import { urlFor } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import {
  useListQueryParams,
  ListQueryParams,
} from 'src/lib/utils/useListQueryParams';

export type SubscriptionListParams = ListQueryParams & {
  routeToSubscription: (subId: string) => void;
  noResultsMessage: string;
  defaultSort: { key: string; order: string };
};

export const useSubscriptionListParams = (params: {
  preferenceNamespace: 'data' | 'mobile' | 'tele' | 'tv' | 'all';
  defaultSortKey: string;
  defaultSortOrder: 'asc' | 'desc';
}): SubscriptionListParams => {
  const router = useRouter();
  const translate = t.subscriptions.ListSubscriptionsByGroup;

  const nameSpace = `${params.preferenceNamespace}Subscription`;

  const listQueryParams = useListQueryParams({
    preferenceNamespace: nameSpace,
    defaultSortKey: params.defaultSortKey,
    defaultSortOrder: params.defaultSortOrder,
  });

  const routeToSubscription = (subId: string) => {
    router.history.push(urlFor({ subscription: subId }));
  };

  const noResultsMessage = translate.noResultMessage.text;

  return {
    ...listQueryParams,
    routeToSubscription,
    noResultsMessage,
    defaultSort: { key: params.defaultSortKey, order: params.defaultSortOrder },
  };
};
