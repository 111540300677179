import { customerSupportNumber, customerSupportEmail } from './../../variables';
import nb from '../../nb/Main/ordering';
import OrderIpVpn from './ordering.OrderIpVpn';

const ordering: typeof nb = {
  required: 'Required',
  numberOnly: 'Must be a number',

  OrderIpVpn,
  OrderingModal: {
    cancelBtn: 'Cancel',
    submitBtn: 'Confirm',
  },
  SubscriptionOrdering: {
    heading: 'Order changes to subscription',
  },
  dateLabels: {
    required: 'Fill in date',
    match: 'Fill in date (dd.mm.yyyy)',
    notFutureDate: 'Date cannot be ahead of time',
    notPastDate: 'Choose a date ahead of time',
  },
  OrderingContent: {
    noOrdering: `Do you want to order other services? Chat with us, contact us at ${customerSupportEmail}, or call ${customerSupportNumber}, and we will help you to arrange it.`,
    pageTitle: 'Ordering',
    mobileHeader: 'Mobile',
    newSubscriptionBtn: {
      label: 'Order subscription',
      helpText: 'Order a new mobile subscription',
    },
    newM2mSubBtn: {
      label: 'Order M2M',
      helpText: 'Order a new M2M subscription',
    },
    newNumberSeriesBtn: {
      label: 'Order number',
      helpText: 'Reserve one or more phone numbers',
    },
    newReserveSimCardBtn: {
      label: 'Order SIM card',
      helpText: 'Order new SIM cards',
    },

    ordersHeader: 'Ongoing orders',
    newOrder: 'New order',
    started: 'Started',
    lastUpdated: 'Last updated',
    deleteModal: {
      btn: 'Delete',
      header: 'Delete ongoing order',
      content: 'Do you want to delete the ongoing subscription?',
    },
  },
  layout: {
    continue: 'Continue',
    OrderingFooter: {
      delete: 'Delete',
      save: 'Save and close',
      submit: 'Send order',
      modal: {
        confirmText: 'Are you sure you want to delete this order?',
        header: 'Delete',
      },
    },
  },
  MobileUnitOrder: {
    OrderNumberSeries: {
      pageHeader: 'Order numbers',
      loading: 'Sending order',
      submitted: {
        thankYou: 'Thank you for your order!',
        expectedDelivery: '',
        info: 'You can find the order in the order overview with order number',
        orderDetails: 'Order details',
      },
      ordering: {
        quantity: {
          label: 'Quantity',
          error: 'Fill in quantity',
        },
        comment: 'Comments',
        sendOrderBtn: 'Send order',
      },
    },
    OrderReserveSimCards: {
      pageHeader: 'Order SIM card',
      loading: 'Sending order',
      submitted: {
        thankYou: 'Thank you for your order!',
        expectedDelivery:
          'Expected delivery of telephone numbers is 1-2 business days.',
        info: 'You can find the order in the order overview with order number',
        orderDetails: 'Order details',
      },
      ordering: {
        quantity: {
          label: 'Quantity',
          error: 'Fill in quantity',
        },
        formFactor: {
          label: 'Select SIM card type',
          error: 'Select a SIM card type.',
          placeholder: 'Select SIM card type',
        },
        address: {
          label: 'Address',
          error: 'Fill in address.',
        },
        postalCode: {
          label: 'Postal code',
          error: 'Fill in postal code.',
        },
        postalArea: {
          label: 'Postal area',
          error: 'Fill in postal area.',
        },
        att: {
          label: 'Additional information',
          error: 'Fill in additional information',
        },
        sendOrderBtn: 'Send order',
        formFactorOpts: {
          regular: 'Regular',
        },
      },
    },
  },
  OrderBlockSim: {
    errorMsg: 'Someting went wrong. We could not block the SIM card',
    blockSimCard: 'Block sim card',
    blockSim: 'Block sim',
    description:
      'By blocking the SIM card the subscription will be blocked from further use. The monthly fee will remain. You can unblock the SIM card at any time.',
  },
  OrderOpenSimcard: {
    header: 'Open sim card',
    label: 'Open sim card',
    content: 'Do you wish to open sim card?',
  },
  OrderTerminateMobileSubscription: {
    additionalSim: {
      terminateDataSim: 'Terminate additional data SIM card',
      terminateVoiceSim: 'Terminate additional voice SIM card',
      terminate: 'Terminate',
      description: 'The SIM card will be terminated 10 days from today. ',
      errorMsg:
        'Something went wrong! We were not able to terminate the service.',
    },
    header: 'End subscription',
    terminationDate: {
      label: 'Requested date for termination',
      info: `The subscription will be canceled no earlier than 10 days from today's date. The subscription can be reactivated within 30 days from the cancellation date.`,
    },
    parkNumber: {
      label: number => `Do you want to keep the number ${number}?`,
      info:
        'If you keep the number, this can be used as soon as the subscription is canceled. If you choose not to keep the number, this will be returned to your original mobile operator after 6 months.',
      options: {
        yes: 'Yes, keep the number',
        no: 'No, cancel number',
      },
    },
    btnSubmit: 'End subscription',
    orderComplete: 'Complete',
    error: 'Something went wrong, try again or contact Telia.',
  },
  OrderExtraSim: {
    header: 'Order extra sim',
    deliveryDescription: 'Enter the delivery address for the new SIM-card',
    deliveryTime:
      'Your order will be processed immediately. Delivery takes 2-5 businessdays',
    activationTime: 'Your new SIM-card will beactivated within a few minutes',
    simType: {
      label: 'SIM-type',
      error: 'Choose a type',
      opts: {
        voice: {
          label: 'Extra voice SIM',
          description:
            'With a voice SIM you can have multiple devices on the same subscription and phonenumber to receive calls. It is only possible to have one additional voice SIM',
        },

        data: {
          label: 'Extra data SIM',
          description:
            'With a data SIM you can browse on a tablet or other device with the data on you subscription. You can have at most two extra data SIMs',
        },
      },
    },
    name: {
      label: 'Name',
      error: 'Fill in name',
    },
    address: {
      label: 'Address',
      error: 'Fill in address',
    },
    postalArea: {
      label: 'Postal area',
      error: 'Fill in postal area',
    },
    postalCode: {
      label: 'Postal code',
      error: 'Fill in postal code (4 characters)',
    },
    att: {
      label: 'Att',
    },
    submitBtn: 'Send order',
    orderSent: 'Order sent',
  },
  OrderMobileSubscription: {
    pageTitleM2m: 'Order new M2M subscription',
    pageTitleMobile: 'Order new mobile subscription',
    OrderMobileSubscriptionFailure: {
      header: 'Something went wrong...',
    },
    orderingError:
      'Ordering was not completed. Please refresh the page and try again. If the problem persists, contact Telia.',
    orderingWarning:
      'Ordering completed, but we did not find the order at this time.',
    Step1: {
      title: 'Phone number',
      header: 'Phone number',
      phoneNumberSourceOpts: {
        new: 'Request new number',
        existing: 'Port existing number',
      },
      phoneNumber: {
        label: 'Number',
        placeholderSelect: 'Pick a number',
        noOpts: 'You have no phone numbers available',
        placeholderTextM2m: 'E.g. 580012345678',
        placeholderTextMobile: 'E.g. 40820125',
        helpTextM2m: '12 characters, without country code',
        helpTextMobile: '8 characters, without country code',
        error: 'Enter phone number, 8 characters without country code',
        errorM2m: 'Enter phone number, 12 characters without country code',
        errorReserved: 'The number is reserved, please select a new one',
      },
      formerOwnerHeader: 'Former legal owner',
      formerOwner: {
        self: 'Subscriber himself',
        company: 'Company',
        private: 'Other private person',
        error: 'Choose former legal owner.',
      },
      firstName: {
        label: 'First name',
        placeholder: 'E.g. Navn',
        error: 'Enter first name',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'E.g. Navnesen',
        error: 'Enter last name',
      },
      birthDate: {
        label: 'Birth date',
        helpText: 'Birth date is required to confirm change of ownership',
        error: 'Enter birth date',
      },
      orgName: {
        label: 'Company name',
        placeholder: 'E.g. Telia Norge AS',
        error: 'Enter company name',
      },
      orgNumber: {
        label: 'Organisation number',
        placeholder: 'E.g. 981929055',
        error: 'Enter organisation number',
      },
      transferPaymentsToNewOwner: {
        label:
          'New responsible company agrees to take over the remaining binding time and breakage fee for GSM number, furthermore it is agreed that the GSM number is transferred to the same, or equivalent subscription.',
        value: (value: boolean): string => (value ? 'Yes' : 'No'),
      },
    },
    Step2: {
      title: 'Subscriber',
      header: 'Subscriber',
      firstName: {
        label: 'First name',
        placeholder: 'E.g. Navn',
        error: 'Enter first name',
      },
      lastName: {
        label: 'Last name',
        placeholder: 'E.g. Navnesen',
        error: 'Enter last name',
      },
      birthDate: {
        label: 'Birthdate',
        helpText: 'Birth date is required to confirm change of ownership',
        error: 'Enter birthdate dd.mm.åååå',
      },
      email: {
        label: 'E-mail',
        helpText:
          'The email address is required to give the subscriber access to my subscription page',
        placeholder: 'E.g. eksempel@telia.no',
        error: 'Enter e-mail address',
      },
      employeeNumber: {
        label: 'Employee number',
        placeholder: 'E.g. 1234',
      },
      jobTitle: {
        label: 'Job title',
        placeHolder: 'E.g. project manager',
      },
    },
    Step3: {
      title: 'Cost settings',
      header: 'Cost and invoice',
      organisation: {
        label: 'Select invoice center',
        placeholder: 'Choose invoice center',
        error: 'Choose invoice center',
      },
      costCenter: {
        label: 'Select cost center',
        placeholder: 'Not selected',
      },
      taxHeader: 'Tax settings',
      taxModel: {
        opts: {
          none: 'Not set',
          free: 'Free',
          officePhone: 'Office phone',
          departmentPhone: 'Department phone',
          personal: 'Personal',
        },
        error: 'Choose field',
      },
      // special field for Bergen Kommune
      fakturaMottaker: 'Invoice recipient',
      areaOfUse: 'Select area of use', // special field for Bergen Kommune
      notSelected: 'Not selected',
    },
    Step4: {
      title: 'Subscription',
      header: 'Choose subscription',
      products: {
        establishmentFee: 'Establishment fee',
        monthlyFee: 'Monthly fee',
      },
      chooseSub: 'Choose subscription',
      subscription: 'Subscription',
      extra: 'Additional products',
      extraHeader: 'Choose additional products',
    },
    Step5: {
      title: 'SIM card',
      header: 'SIM card',
      activateSim: 'Activate SIM',
      simcardSource: {
        opts: {
          new: 'Get new SIM card',
          existing: 'Activate an existing SIM card',
        },
      },
      simcardNumber: {
        placeholder: 'Select available SIM card',
        error: 'Select a simcard number',
      },
      recipientHeader: 'Recipient (company or private person)',
      recipient: {
        label: 'Recipient',
        placeholder: 'E.g. Navn Navnesen or Company AS',
        error: 'Enter recipient',
      },
      address: {
        label: 'Address',
        placeholder: 'E.g. Adresseveien 2',
        error: 'Enter address',
      },
      postalCode: {
        label: 'Postal code ',
        placeholder: 'E.g. 1234',
        error: 'Enter postal code',
      },
      postalArea: {
        label: 'Postal area',
        placeholder: 'E.g. Stedstad',
        error: 'Enter postal area',
      },
      attention: {
        label: 'Att',
        placeholder: 'E.g. Deliver to Karl Svendsen',
      },
      activationDateHeader: 'Preferred startdate for subscription',
      activationDate: {
        label: 'Activation date',
        error: 'Enter activation date',
      },
      activationDateType: {
        default: 'Activate as early as possible',
        custom: 'Choose activation date',
      },
      activationDateInfo: {
        newSim:
          'Porting will take place no earlier than 5 business days, or on the desired porting date.',
        exixtingSim:
          'Porting will take place no earlier than 2 business days after Telia has received the ownership change form, or on the desired porting date.',
      },
    },
  },
};

export default ordering;
