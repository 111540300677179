import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import './StepFlowCard.scss';

import { StepCircle } from '../StepCircle/StepCircle';
import { t } from 'src/lib/i18n';
import { Button } from '../Button/Button';
import AnimateHeight from 'react-animate-height';
interface Props {
  title?: string;
  step: number;
  currentStep: number;
  form?: React.ReactNode;
  summary?: React.ReactNode;
  onClick?: (step: number) => void;
  activeElement?: (node: HTMLDivElement) => void;
  isValid: boolean;
  isLastStep?: boolean;
  hideContinue?: boolean;
  onContinue: () => void;
}

export const StepFlowCard: React.FunctionComponent<Props> = ({
  currentStep,
  step,
  onClick,
  title,
  summary,
  form,
  activeElement,
  isValid,
  isLastStep,
  ...props
}) => {
  const [height, setHeight] = useState<'auto' | 0>(0);
  const isActive: boolean = currentStep === step;
  const isCompleted: boolean = currentStep > step;

  useEffect(() => {
    setHeight(isActive ? 'auto' : 0);
  }, [isActive]);

  return (
    <>
      <div
        className={cs('StepFlowCard d-flex pt-3 px-3', {
          'pb-3': !isActive,
          clickable: isCompleted,
        })}
        onClick={() => {
          if (isCompleted && onClick) {
            onClick(step);
          }
        }}
        ref={isActive ? activeElement : undefined}
      >
        <StepCircle step={step + 1} active={isActive} />
        <div style={{ flex: '1' }}>
          <div className=" d-flex justify-content-between">
            <div className="preamble mb-3 pl-5">{title}</div>
            {isCompleted ? <div className="w-50">{summary}</div> : null}
          </div>
          <AnimateHeight duration={500} height={height}>
            <div
              className="Step border-left mb-3"
              style={{
                // half width of StepCircle
                marginLeft: '-16px',
                paddingLeft: '4rem',
              }}
              key={step}
            >
              {isActive ? form : null}
              {!(isLastStep || props.hideContinue) && (
                <div className="pb-4 px-4 d-flex">
                  <Button
                    className="ml-auto"
                    color="purple"
                    disabled={!isValid}
                    type="button"
                    onClick={props.onContinue}
                  >
                    {t.ordering.layout.continue}
                  </Button>
                </div>
              )}
            </div>
          </AnimateHeight>
        </div>
      </div>
    </>
  );
};
