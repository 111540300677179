import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';

import ListInvoices from './ListInvoices';

const Invoices: React.FC<RouteComponentProps<{}>> = ({ match }) => (
  <Switch>
    <Route exact={true} path={match.url} component={ListInvoices} />
  </Switch>
);

export default Invoices;
