import React, { useRef, useState, useEffect } from 'react';
import { TextFieldProps } from './TextField';
import { generateUeid } from 'src/lib/utils';

/**
 * Extract text field handlers and stateful logic to ensure consistent behavior across
 * different instances of text inputs, such as TextField and SearchInput
 */
export const useTextField = (props: TextFieldProps) => {
  const fieldGuid = useRef<string | undefined>(generateUeid());
  const inputProps = {
    ...(props.inputProps ? props.inputProps?.() : undefined),
    autoComplete: props.autoComplete,
  };
  const inputRef = useRef<HTMLInputElement>(null);
  const [focus, setFocus] = useState(false);
  const [inputActive, setInputActive] = useState<boolean>(false);

  const ref = inputProps.ref || props.innerRef || inputRef;
  const current = ref?.current;
  useEffect(() => {
    fieldGuid.current = props.label === undefined ? undefined : generateUeid();
  }, [props.label]);

  useEffect(() => (focus ? current?.focus() : current?.blur()), [
    focus,
    current,
  ]);

  const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(true);
    setInputActive(true);
    props.onFocus?.(e);
    inputProps?.onFocus?.(e);
  };

  const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
    setFocus(false);
    setInputActive(false);
    props.onBlur?.(e);
    inputProps?.onBlur?.(e);
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(e);
    inputProps?.onChange?.(e);
  };

  const onKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    props.onKeyDown?.(e);
    if (e.keyCode === 9) {
      e.stopPropagation();
    }
    inputProps?.onKeyDown?.(e);
  };

  return {
    ref,
    focus,
    setFocus,
    fieldGuid,
    inputProps,
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
    inputActive,
  };
};
