import { OptionSelectProps } from 'src/lib/ui/OptionSelect';
import { find } from 'lodash';
import { Period } from 'src/lib/types';
import { getPeriodOptions } from 'src/lib/utils';
import { useI18nContext, t } from 'src/lib/i18n';
interface PeriodPickerProps {
  costPeriods: {
    invoicePeriods: Period[];
    usagePeriods: Period[];
  };
  /**
   * undefined === all periods
   */
  period: Period | undefined;
  setPeriod: (period: Period | undefined) => void;
  /**
   * Option to make "All periods" option available.
   */
  allPeriodsAvailable?: boolean;
}

export const usePeriodFilter = (
  props: PeriodPickerProps
): OptionSelectProps => {
  const i18n = useI18nContext();
  const allPeriodsOption = { value: 'all', label: t.invoices.allPeriods };
  const options = [
    ...(props.allPeriodsAvailable ? [allPeriodsOption] : []),
    ...getPeriodOptions(props.costPeriods, i18n.locale),
  ];

  const period =
    typeof props.period === 'string'
      ? find(options, o => o.value === props.period)
      : props.allPeriodsAvailable
      ? allPeriodsOption
      : props.period;

  return {
    id: 'period',
    label: period?.label ?? 'Velg periode',
    options: options,
    selectedOptions: period ? [period.value] : [],
    allowMultiple: false,
    blackBorderOnSelect: true,
    handleSave: value => {
      props.setPeriod(
        value?.[0]
          ? value?.[0] === 'all'
            ? undefined
            : value?.[0]
          : options[0].value
      );
    },
  };
};
