import React from 'react';
import { Attribute } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { SubscriptionStatusIcon } from 'src/areas/main/subscriptions/lib/SubscriptionStatusIcon/SubscriptionStatusIcon';
import { getLabelFromCpeStatus } from 'src/areas/main/subscriptions/utils';
import { useCpe } from 'src/areas/main/subscriptions/queries/useCpe';
import cs from 'classnames';

export const Status = (props: {
  subscription: { id: string; isMonitoringPossible?: boolean | null };
  className: string;
}) => {
  const { data } = useCpe(props.subscription.id);
  const i18n = t.locations.Sidebar.subscription;
  if (!data || !props.subscription.isMonitoringPossible) {
    return null;
  }
  const label = getLabelFromCpeStatus(data?.cpe?.status);
  const responseTime = data.cpe?.ping?.roundTripTime
    ? data.cpe.ping.roundTripTime + 'ms'
    : null;

  return (
    <div className={cs('d-flex align-items-center', props.className)}>
      <SubscriptionStatusIcon
        className="mr-4"
        cpeStatus={data.cpe?.status}
        circleBackground={true}
        hasStatus={props.subscription.isMonitoringPossible}
      />
      <div>
        <small className="d-block grey-dark-text">{i18n.status}</small>
        <div>{label}</div>
        {data.cpe?.model && (
          <Attribute label={i18n.cpeModel} value={data.cpe?.model} />
        )}
        {responseTime && (
          <Attribute label={i18n.responseTime} value={responseTime} />
        )}
      </div>
    </div>
  );
};
