import * as React from 'react';
import { DeliveryStatus } from 'src/__types/graphql-global-types';
import { Alert } from 'src/lib/ui';
import { getDeliveryStatusDefinitions } from '../../utils';
import { t } from 'src/lib/i18n';
interface SubscriptionDeliveryStatusCardProps {
  className?: string;
  subscription: {
    deliveryStatus?: DeliveryStatus | null;
  };
}

export const SubscriptionDeliveryStatusCard: React.FunctionComponent<SubscriptionDeliveryStatusCardProps> = props => {
  const i18n = t.subscriptions.SubscriptionDeliveryStatusCard;

  if (!props.subscription.deliveryStatus) {
    return null;
  }
  const deliveryStatus = props.subscription.deliveryStatus;
  const {
    deliveryStatusIcon,
    deliveryStatusTranslations,
  } = getDeliveryStatusDefinitions(deliveryStatus);

  if (!deliveryStatusTranslations) {
    return null;
  }

  return (
    <Alert
      className={props.className}
      icon={deliveryStatusIcon}
      status="default"
      header={deliveryStatusTranslations.status}
      // subHeader="En dato" // Delivery date will come soon
    >
      <>
        <div className="mb-1">{deliveryStatusTranslations.infoText}</div>
        <small>{i18n.support}</small>
      </>
    </Alert>
  );
};
