// import React from 'react';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';

import * as types from './__types/createNetworkOrder';

const query = gql`
  mutation createNetworkOrder($input: CreateNetworkOrderInput) {
    createNetworkOrder(input: $input) {
      errors {
        code
        message
      }
      success
    }
  }
`;

export const usePocIpVpnMutation = () =>
  useMutation<types.createNetworkOrder, types.createNetworkOrderVariables>(
    query
  );
