const orderIpVpn = {
  title: 'Bestill aksess',

  OrderError: {
    heading: 'Det oppsto en feil',
  },

  OrderReceipt: {
    heading: 'Bestillingen er mottatt',
    p1: 'Vi går snart gjennom bestillingen og oppretter en ordre for deg.',
    p2:
      'Når det er gjort får du en ordrebekreftelse via e-post som viser hva vi kan levere. Den vil også dukke opp under "aktive ordre" her i Min Portal.',
    p3: 'I bekreftelsen får du mer informasjon om hva som skjer videre.',
    backButton: 'Tilbake til portalen',
    newOrderButton: 'Bestill en ny aksess',
  },

  OrderIpVpnSummary: {
    title: 'Se over og send',
    summary:
      'Ta en ekstra titt og sjekk at informasjonen er sånn du vil ha den. Etter det kan du fullføre bestillingen.',
    whatsNext: 'Hva skjer videre?',
    whatsNextSummary:
      'Når du sender bestillingen vil vi undersøke hvordan vi kan levere tjenesten til deg. Etter vurderingen blir en ordre opprettet i Min Portal. Du kommer også til å få en ordrebekreftelse via e-post. Bekreftelsen inneholder informasjonen du har oppgitt, og et forslag om hvordan aksessen kan leveres.',
  },

  IpVpnOrderCard: {
    heading: 'Bestill ny IP VPN-aksess',
    description:
      'Telia IP VPN er et multiservicenett som samler alle dine kommunikasjonsbehov i ett enkelt nett. Med Telia IP VPN har bedriften din et fullverdig nett for overføring av ulike typer trafikk, som ASP/Citrix, IP-telefoni, videokonferanser og alarmer.',
    buttonLabel: 'Bestill aksess',
  },

  Step1: {
    title: 'Legg til stedsinformasjon',
    summary:
      'Fortell hvor du ønsker at aksessen leveres. Posisjonen brukes til å vurdere hvordan aksessen leveres.',
    toggleAddress: 'Adresse',
    toggleGnrBnr: 'Gårds- og bruksnummer',
    knrSummary: 'Kommune',
    gnrSummary: 'Gnr',
    bnrSummary: 'Bnr',
    addressSummary: 'Adresse',
    postCodeSummary: 'Postnummer',
    countrySummary: 'Land',

    knrLabel: 'Kommune',
    gnrLabel: 'Gnr',
    bnrLabel: 'Bnr',
    addressLabel: 'Adresse',
    postCodeLabel: 'Postnummer',
    countryLabel: 'Land',
  },

  Step2: {
    title: 'Legg til informasjon om bygningen',
    summary:
      'Fortell hvor i bygget aksessen skal leveres. Dette brukes til å gi informasjon til teknikerne som installerer aksessen.',
    floorSummary: 'Etasje',
    floorLabel: 'Etasje',
    needsWiring: {
      label: 'Er det internkabling der aksessen skal installeres?',
      summary: 'Har internkabling',
      yes: 'Nei, vi ønsker at Telia legger kabler',
      no: 'Ja, det er klart for installasjon',
    },
  },

  Step3: {
    title: 'Velg båndbredde',
    summary:
      'Fortell hvor rask du ønsker at aksessen skal være. Båndbredde brukes til å finne ut hva slags aksess som leveres.',
    accessBandwidthSummary: 'Båndbredde',
    accessBandwidthLabel: 'Båndbredde',
    accessBandwidthPlaceholder: 'Båndbredde (Mbit/s)',
  },

  Step4: {
    title: 'Legg til kontaktperson',
    summary:
      'Under velger du hvem som skal kontaktes når aksessen skal leveres.',
    siteContactSummary: 'Kontaktperson',
    siteContactLabel: 'Kontaktperson',
    siteContactPlaceholder: 'Velg kontaktperson',
  },

  Step5: {
    title: 'Legg til fakturasted',
    formSummary: 'Under velger du hvem som skal motta EHF-faktura.',
    organisationIdLabel: 'Velg fakturasted',
    organisationIdSummary: 'Fakturasted',
  },

  Step6: {
    title: 'Velg ønsket leveringsdato',
    summary:
      'Her velger du når du ønsker at aksessen skal installeres. Du kan også legge til ekstra informasjon du mener er viktig.',
    deliveryDateLabel: 'Ønsket leveringsdato',
    additionalInformationLabel:
      'Skriv inn annen informasjon om bestillingen du ønsker å dele (valgfritt)',
    deliveryDateSummary: 'Ønsket leveringsdato',
    additionalInformationSummary: 'Tilleggsinformasjon',
  },
};

export default orderIpVpn;
