import { useStatisticsParams } from './utils/useStatisticsParams';
import React from 'react';
import { StatisticsFilter } from './components/StatisticsFilter';
import { Standard } from './views/Standard/Standard';
import { StandardStatisticsType } from './queries/WithStatistics';
import { Qos } from './views/Qos/Qos';
import { Gsm } from './views/Gsm/Gsm';
import { Live } from './views/Live/Live';
import { Snapshot } from './views/Snapshot/Snapshot';
import { ExtendedQos } from './views/ExtendedQos/ExtendedQos';
import { ExtendedQosStatisticsType } from 'src/__types/graphql-global-types';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { useStatisticsInterfaces } from './utils/useStatisticsInterfaces';
import { Redirect } from 'react-router';
import { t } from 'src/lib/i18n';

const standardStatisticsTypeList: StandardStatisticsType[] = [
  'throughput',
  'cumulative',
  'packetDrop',
  'responseTime',
  'utilization',
];

export const StatisticsContainer = () => {
  const params = useStatisticsParams();
  const query = useQueryParameters();
  const interfaces = useStatisticsInterfaces(params.subscriptionId);

  if (interfaces.loading) {
    return null;
  }

  /**
   * Logic for setting default filters
   * Default to Standard
   * Fallback is Live
   * If no interfaces exists. Go to statistics with no params.
   */
  if (!params.currentView && !params.currentInterface) {
    if (interfaces.standardInterfaces?.length) {
      const url = query.getUrlWithQueryParams({
        interface: interfaces.standardInterfaces?.[0]?.value,
        view: 'throughput',
      });
      return <Redirect to={url} />;
    } else if (interfaces.liveInterfaces?.length) {
      const url = query.getUrlWithQueryParams({
        interface: interfaces.liveInterfaces?.[0]?.value,
        view: 'live',
      });
      return <Redirect to={url} />;
    }
  }

  return (
    <>
      <StatisticsFilter />
      <ViewSelector />
    </>
  );
};

const ViewSelector = () => {
  const { currentView } = useStatisticsParams();
  if (
    standardStatisticsTypeList.includes(currentView as StandardStatisticsType) // Cast type to check if it exists
  ) {
    return <Standard currentView={currentView as StandardStatisticsType} />;
  } else if (currentView === 'qosTransmit' || currentView === 'qosDrop') {
    return <Qos currentView={currentView} />;
  } else if (currentView === 'gsm') {
    return <Gsm />;
  } else if (currentView === 'live') {
    return <Live />;
  } else if (currentView === 'all') {
    return <Snapshot />;
  } else if (
    currentView === 'jitter' ||
    currentView === 'roundTripTime' ||
    currentView === 'packetLoss'
  ) {
    return (
      <ExtendedQos currentView={currentView as ExtendedQosStatisticsType} />
    );
  }
  return <div>{t.statistics.noStatistics}</div>;
};
