import React from 'react';
import { t } from 'src/lib/i18n';
import { Attribute, Card, colors } from 'src/lib/ui';
import { formatName } from 'src/lib/utils';
import { NotificationChannel } from 'src/__types/graphql-global-types';
import { useIncidentFollowers_customer_incidents_subscription_incidentFollowers } from '../../queries/__types/useIncidentFollowers';

interface Props {
  contact?: useIncidentFollowers_customer_incidents_subscription_incidentFollowers | null;
}

export const SubscriptionFollowerCard: React.FC<Props> = (props: Props) => {
  const { contact } = props;
  if (!contact) {
    return null;
  }

  const mapContactMethod = (contactMethod: NotificationChannel) => {
    switch (contactMethod) {
      case NotificationChannel.email:
        return t.incidents.NotificationSelector.email;
      case NotificationChannel.sms:
        return t.incidents.NotificationSelector.sms;
      default:
        return t.incidents.IncidentSummary.portal;
    }
  };

  const contactMethod = props.contact?.notificationSettings?.incident?.channels
    ?.map(channel => mapContactMethod(channel))
    .join(', ');

  return (
    <Card className="p-2" transparent={true}>
      <div style={{ color: colors.black }}>
        {formatName({
          firstName: contact.firstName,
          lastName: contact.lastName,
        })}
      </div>

      <Attribute
        value={`${
          contactMethod?.includes('portal')
            ? t.incidents.IncidentSummary.getsNotificationIn
            : t.incidents.IncidentSummary.getsNotificationOn
        } ${contactMethod}`}
      />
    </Card>
  );
};

export default SubscriptionFollowerCard;
