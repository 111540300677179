import React from 'react';
import { Loading, Checkbox } from 'src/lib/ui';
import { Option } from './index';
import cs from 'classnames';
import { Icon } from '@telia/styleguide';
import { t } from 'src/lib/i18n';
import './OptionSelectMenu.scss';

export const OptionSelectMenu = (props: {
  menuProps?: any;
  loading?: boolean;
  options: (Option | null)[];
  allowMultiple?: boolean;
  // Old type? Is it correct?
  // getItemProps?: ({ item: NewSelectField, index: i }) => any;
  getItemProps?: ({ item, index }) => any;
  highlightedIndex?: number;
  optionIsSelected: (option: Option) => boolean;
  optionIsPartiallySelected?: (option: Option) => boolean;
  noOptionsMessage?: string;
  rowHeight: number;
  tabIndex?: number;
  fullWidth?: boolean;
  inputValue?: string;
}) => {
  const i18n = t.ui.SelectField;

  return (
    <div
      {...props.menuProps}
      style={props.fullWidth ? { width: '100%' } : undefined}
      tabIndex={props.tabIndex}
    >
      {props.loading ? (
        <Loading />
      ) : (
        <>
          {props.options?.length ? (
            props.options.map((option, i) => {
              const itemProps = props.getItemProps?.({
                item: option,
                index: i,
              });

              const style = {
                height: props.rowHeight,
              };

              if (!option) {
                return (
                  <div tabIndex={-1} key={i} style={style} {...itemProps} />
                );
              }
              const isSelected = props.optionIsSelected(option);
              const isPartial = props.optionIsPartiallySelected?.(option);

              if ('onClick' in option) {
                return props.inputValue ? (
                  <button
                    tabIndex={-1}
                    key={option.value}
                    className={cs(
                      'OptionSelectMenu-item d-flex align-items-center px-4',
                      {
                        active: props.highlightedIndex === i,
                      }
                    )}
                    style={style}
                    {...itemProps}
                  >
                    <Icon icon={option.icon} className="ml-n1 mr-2" /> "
                    {props.inputValue}" ({option.label})
                  </button>
                ) : null;
              }
              return (
                <button
                  tabIndex={-1}
                  key={option.value}
                  className={cs(
                    'OptionSelectMenu-item d-flex align-items-center px-4',
                    {
                      selected: isSelected,
                      active: props.highlightedIndex === i,
                      negative: option.negative,
                      'OptionSelectMenu--allow-multiple': props.allowMultiple,
                    }
                  )}
                  style={style}
                  {...itemProps}
                >
                  {props.allowMultiple ? (
                    <Checkbox
                      checked={isSelected}
                      indeterminate={isPartial}
                      tabIndex={-1}
                      className="ml-n3"
                      onChange={e => e.preventDefault()}
                    />
                  ) : null}
                  <span>
                    <div className="OptionSelectMenu-item-label">
                      {option.label}
                    </div>
                    {option.subLabel ? (
                      <div className="OptionSelectMenu-item-subLabel">
                        {option.subLabel}
                      </div>
                    ) : null}
                  </span>
                  {!props.allowMultiple && isSelected ? (
                    // Icon need to be wrapped around a div to keep it size and aspect ratio
                    <div className="ml-auto">
                      <Icon icon="check-mark" />
                    </div>
                  ) : null}
                </button>
              );
            })
          ) : (
            <div className="py-3 px-4">
              {props.noOptionsMessage ?? i18n.noOptionsMessage}
            </div>
          )}
        </>
      )}
    </div>
  );
};
