import * as React from 'react';
import { Icon } from '@telia/styleguide';

import './PersonalizedDashboardContactCard.scss';

interface Props {
  label: string;
  text: string;
  email?: string;
  phoneNumber?: string;
  children?: React.ReactNode;
}

function phoneLink(ph: string | undefined | null) {
  if (!ph) {
    return undefined;
  }
  const noSpaces = ph.replace(/ /g, '');
  if (noSpaces.indexOf('+') === 0 || noSpaces.indexOf('00') === 0) {
    return `tel:${noSpaces}`;
  } else {
    return `tel:+47${noSpaces}`;
  }
}

export function PersonalizedDashboardContactCard(props: Props) {
  return (
    <div className="PersonalizedDashboardContactCard">
      <h2>{props.label}</h2>

      <p>{props.text}</p>

      {props.email && (
        <div className="PersonalizedDashboardContactCard-contact">
          <a href={`mailto:${props.email}`}>
            <Icon icon="email" />
            {props.email}
          </a>
        </div>
      )}

      {props.phoneNumber && (
        <div className="PersonalizedDashboardContactCard-contact">
          <a href={phoneLink(props.phoneNumber)}>
            <Icon icon="phone" />
            {props.phoneNumber}
          </a>
        </div>
      )}

      {props.children}
    </div>
  );
}
