import { useMutation } from '@apollo/react-hooks';
import * as q from './queries';
import { DataProxy } from 'apollo-cache';

/**
 * Function shared among the mutations to update the feature flag cache.
 */
function updateFeatureFlagsCache(
  cache: DataProxy,
  updateFunc: (cached: q.FeatureFlag[]) => q.FeatureFlag[]
) {
  const cached = cache.readQuery<q.QueryData>({ query: q.query });
  const cachedFeatureFlags =
    cached && cached.featureFlags ? cached.featureFlags : [];
  const featureFlags = updateFunc(cachedFeatureFlags);
  featureFlags.sort((a, b) =>
    a.key.toLowerCase().localeCompare(b.key.toLowerCase())
  );
  cache.writeQuery({
    query: q.query,
    data: {
      featureFlags,
    },
  });
}

export function useFeatureMutations() {
  /**
   * Set (create/update)
   */
  const [setFeatureFlag] = useMutation<
    q.SetFeatureFlagPayload,
    { input: q.SetFeatureFlagInput }
  >(q.setFeatureFlag, {
    update(cache, { data }) {
      if (data && data.setFeatureFlag.featureFlag) {
        updateFeatureFlagsCache(cache, cachedFlags => [
          ...cachedFlags.filter(
            cf => cf.key !== data.setFeatureFlag.featureFlag.key
          ),
          data.setFeatureFlag.featureFlag,
        ]);
      }
    },
  });

  /**
   * Delete
   */
  const [deleteFeatureFlag] = useMutation<
    q.DeleteFeatureFlagPayload,
    { input: q.DeleteFeatureFlagInput }
  >(q.deleteFeatureFlag, {
    update(cache, params) {
      const { data } = params;
      if (data && data.deleteFeatureFlag.success) {
        updateFeatureFlagsCache(cache, cachedFlags =>
          cachedFlags.filter(cf => cf.key !== data.deleteFeatureFlag.key)
        );
      }
    },
  });

  /**
   * Assign
   */
  const [assignFeatureFlag] = useMutation<
    q.AssignFeatureFlagPayload,
    { input: q.AssignFeatureFlagInput }
  >(q.assignFeatureFlag, {
    update(cache, params) {
      console.log('assignFeatureFlag', cache, params);
      if (params.data && params.data.assignFeatureFlag.featureFlag) {
        const flag = params.data.assignFeatureFlag.featureFlag;
        updateFeatureFlagsCache(cache, cached => [
          ...cached.filter(f => f.key !== flag.key),
          flag,
        ]);
      }
    },
  });

  /**
   * Unassign
   */
  const [unassignFeatureFlag] = useMutation<
    q.UnassignFeatureFlagPayload,
    { input: q.UnassignFeatureFlagInput }
  >(q.unassignFeatureFlag, {
    update(cache, params) {
      if (params.data && params.data.unassignFeatureFlag.featureFlag) {
        const flag = params.data.unassignFeatureFlag.featureFlag;
        updateFeatureFlagsCache(cache, cached => [
          ...cached.filter(f => f.key !== flag.key),
          flag,
        ]);
      }
      console.log('unassignFeatureFlag', cache, params);
    },
  });

  return {
    setFeatureFlag: (input: q.SetFeatureFlagInput) =>
      setFeatureFlag({ variables: { input } }),
    deleteFeatureFlag: (input: q.DeleteFeatureFlagInput) =>
      deleteFeatureFlag({ variables: { input } }),
    assignFeatureFlag: (input: q.AssignFeatureFlagInput) =>
      assignFeatureFlag({ variables: { input } }),
    unassignFeatureFlag: (input: q.UnassignFeatureFlagInput) =>
      unassignFeatureFlag({ variables: { input } }),
  };
}
