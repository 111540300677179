const chat = {
  messagePlaceholder: 'Kort beskrivelse av hva det gjelder',

  invalidMessage: 'Vennligst gi en beskrivelse av problemet ditt.',

  submitConfirmation: 'Takk! Vi setter deg over til kundesenteret.',
  editMessage: 'Rediger melding',
  deleteMessgae: 'Slett melding',
  couldNotEdit: 'Ops! Vi klarte ikke redigere meldingen din',
  couldNotDelete: 'Ops! Vi klarte ikke slette meldingen din',
};

export default chat;
