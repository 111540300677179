import React, { useEffect } from 'react';
import { FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { File } from 'src/lib/types';
import { useSetFileTeliaInternal } from '../mutations/useSetFileTeliaInternal';
import { ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  file: File;
  close: () => void;
}
export const ChangeSharing: React.FC<Props> = props => {
  const {
    setFileTeliaInternal,
    data,
    loading,
    error,
  } = useSetFileTeliaInternal();

  useEffect(() => {
    if (data && !error) {
      props.close();
    }
  }, [error, data, props, props.close]);

  return (
    <>
      <ModalBody>
        <div>
          {props.file.teliaInternal
            ? t.documents.ChangeSharingModal.setFileSharedConfirmation
            : t.documents.ChangeSharingModal.setFileInternalConfirmation}
        </div>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          loading={loading}
          submitLabel={t.documents.ChangeSharingModal.confirm}
          submit={() => setFileTeliaInternal(props.file)}
          cancel={props.close}
        />
      </ModalFooter>
    </>
  );
};
