import React from 'react';
import { Contact } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

interface Props {
  contact: Contact;
}

export const ContactNotifications: React.FC<Props> = ({ contact }) => {
  const i18n = t.contacts;

  const incidentSettings = contact.notificationSettings?.incident;
  const changeSettings = contact.notificationSettings?.change;

  return (
    <div className="d-flex px-4 pb-4">
      <Icon className="mr-3" icon="alarm" />
      <div className="d-flex flex-column">
        <div className="pb-2">
          {`${i18n.editRoles.incidents}: ${
            !incidentSettings?.active
              ? '0'
              : incidentSettings.notifyForAllCustomerSubscriptions
              ? 'alle'
              : incidentSettings.subscriptions?.length
          } ${i18n.editRoles.notifications.toLowerCase()}`}
        </div>
        <div>
          {`${i18n.editRoles.plannedWork}: ${
            !changeSettings?.active
              ? '0'
              : changeSettings.notifyForAllCustomerSubscriptions
              ? 'alle'
              : changeSettings.subscriptions?.length
          } ${i18n.editRoles.notifications.toLowerCase()}`}
        </div>
      </div>
    </div>
  );
};
