import * as React from 'react';
import { formatFileSize } from 'src/lib/utils';
import FileIcon from 'src/areas/main/documents/table/FileIcon';
import ChatMessage from './ChatMessage';
import './ChatAttachment.scss';
import { AttachmentActivity, CommonActivityProps } from 'src/lib/chat/types';
import { Icon } from '@telia/styleguide';

export const ChatAttachment: React.SFC<AttachmentActivity &
  CommonActivityProps> = props => (
  <ChatMessage {...props} className="ChatAttachment-wrapper">
    <div className="ChatAttachment">
      <a
        target="_blank"
        rel="noopener noreferrer"
        href={props.attachment.downloadLink || ''}
        style={{
          color: 'inherit',
          textDecoration: 'inherit',
        }}
      >
        <div className="d-flex align-items-center">
          <div className="pl-2 pr-2">
            {props.attachment.contentType ? (
              <FileIcon mimeType={props.attachment.contentType} />
            ) : (
              <Icon icon="document" />
            )}
          </div>
          <div>
            <div className="ChatAttachment-header">
              {props.attachment.fileName}
            </div>
            <div className="ChatAttachment-size">
              {props.attachment.size && formatFileSize(props.attachment.size)}
            </div>
          </div>
        </div>
      </a>
    </div>
  </ChatMessage>
);

export default ChatAttachment;
