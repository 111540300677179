import { useState, useEffect } from 'react';
import { CpeStatus } from 'src/__types/graphql-global-types';

type InterfaceType = {
  name: string;
  alias: string | null;
  status: CpeStatus | null;
  arpTable?: null | Array<{
    ip: string;
    mac: string;
  }>;
  subscription: null | {
    vrf: null | {
      vpnAlias: string | null;
      vpnId: string;
      vrfName: string;
      sagCode: string;
    };
  };
};
export const useInterfaceModal = () => {
  const [open, setOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState<InterfaceType | undefined>(
    undefined
  );

  useEffect(() => {
    setOpen(!!selectedRow);
  }, [selectedRow]);

  useEffect(() => {
    if (!open) {
      setSelectedRow(undefined);
    }
  }, [open]);

  const renderModal = (cpeInterface: InterfaceType) =>
    setSelectedRow(cpeInterface);

  return { renderModal, selectedRow, open, setOpen };
};
