import { useState, useEffect, useMemo } from 'react';
import { useCostCenters } from 'src/areas/main/cost/lib/queries/WithCostCenters';
import { useSetCostCenterForSubscription } from 'src/areas/main/cost/lib/mutations/useSetCostCenterForSubscription';
import { SubscriptionTaxModel } from 'src/__types/graphql-global-types';
import { useUpdateAttribute } from 'src/areas/main/subscriptions/mutations/useUpdateAttribute';
import { getTaxModelLabelAndDescription } from '../../../utils/getTaxModelLabelAndDescription';
import { getCostCenterLabel } from '../../../utils/getCostCenterLabel';
import { t } from 'src/lib/i18n';
import { Subscription } from 'src/lib/types';
import { useTvSubscription_customer_subscriptions } from '../../Tv/__types/useTvSubscription';

export interface Props {
  subscription: Subscription | useTvSubscription_customer_subscriptions;
  toggleOpen: () => void;
  isOpen?: boolean;
}

export const useEditCostPreferences = (props: Props) => {
  const { costCenters } = useCostCenters();
  const {
    setCostCenterForSubscription,
    ...costCenterContext
  } = useSetCostCenterForSubscription();
  const {
    setAttributeForSubscription,
    ...attributeContext
  } = useUpdateAttribute();
  const [loading, setLoading] = useState(false);
  const [activeTaxModel, setActiveTaxModel] = useState(
    props.subscription.taxModel
  );
  const [activeCosCenter, setActiveCostCenter] = useState(
    props.subscription.costCenter
  );

  useEffect(() => {
    if (
      !loading &&
      (costCenterContext.result || attributeContext.data) &&
      !costCenterContext.error &&
      !attributeContext.error
    ) {
      props.toggleOpen();
    }
  }, [loading]); //eslint-disable-line

  const costCenterLabel =
    getCostCenterLabel(activeCosCenter) ??
    t.subscriptions.SubscriptionCostDetails.selectCostCenter;
  const costCenterOptions = useMemo(
    () =>
      costCenters?.map(costCenter => ({
        value: costCenter.id,
        label: getCostCenterLabel(costCenter) ?? '',
      })) ?? [],
    [costCenters]
  );
  const costCenterSelectedOption = activeCosCenter
    ? [activeCosCenter.id]
    : undefined;

  const taxModelLabel =
    getTaxModelLabelAndDescription(activeTaxModel).title ??
    t.subscriptions.taxModel.none;
  const taxModelOptions = [
    {
      label: `${getTaxModelLabelAndDescription(
        SubscriptionTaxModel.free
      ).title.toUpperCase()} -
        ${
          getTaxModelLabelAndDescription(SubscriptionTaxModel.free).description
        }`,
      value: SubscriptionTaxModel.free,
    },
    {
      label: `${getTaxModelLabelAndDescription(
        SubscriptionTaxModel.officePhone
      ).title.toUpperCase()} -
        ${
          getTaxModelLabelAndDescription(SubscriptionTaxModel.officePhone)
            .description
        }`,
      value: SubscriptionTaxModel.officePhone,
    },
    {
      label: `${getTaxModelLabelAndDescription(
        SubscriptionTaxModel.departmentPhone
      ).title.toUpperCase()} -
        ${
          getTaxModelLabelAndDescription(SubscriptionTaxModel.departmentPhone)
            .description
        }`,
      value: SubscriptionTaxModel.departmentPhone,
    },
    {
      label: `${getTaxModelLabelAndDescription(
        SubscriptionTaxModel.none
      ).title.toUpperCase()}`,
      value: SubscriptionTaxModel.none,
    },
  ];
  const taxModelSelectedOption = activeTaxModel
    ? [activeTaxModel?.toString()]
    : undefined;

  const setCostCenter = (selectedCostCenter?: string[] | null) => {
    if (selectedCostCenter?.[0]) {
      setActiveCostCenter(
        costCenters?.find(c => c.id === selectedCostCenter[0])
      );
    }
  };

  const setTaxModel = (selectedTaxModel?: string[] | null) => {
    setActiveTaxModel(
      selectedTaxModel?.[0]
        ? SubscriptionTaxModel[selectedTaxModel[0]]
        : SubscriptionTaxModel.none
    );
  };

  const handleSave = async () => {
    setLoading(true);
    if (activeTaxModel && !(activeTaxModel === props.subscription.taxModel)) {
      await setAttributeForSubscription(
        props.subscription.id,
        'taxModel',
        activeTaxModel
      );
    }
    if (
      activeCosCenter &&
      !(activeCosCenter.id === props.subscription.costCenter?.id)
    ) {
      await setCostCenterForSubscription(
        props.subscription.id,
        activeCosCenter.id
      );
    }
    setLoading(false);
  };

  const cancel = () => {
    setActiveCostCenter(props.subscription.costCenter);
    setActiveTaxModel(props.subscription.taxModel);
    props.toggleOpen();
  };

  const error = attributeContext.error || costCenterContext.error;
  const disableSave =
    !!(activeTaxModel && activeTaxModel === props.subscription.taxModel) &&
    !!(
      activeCosCenter &&
      activeCosCenter.id === props.subscription.costCenter?.id
    );
  return {
    costCenterLabel,
    costCenterOptions,
    costCenterSelectedOption,
    setCostCenter,
    taxModelLabel,
    taxModelOptions,
    taxModelSelectedOption,
    setTaxModel,
    handleSave,
    cancel,
    error,
    loading,
    disableSave,
  };
};
