import React from 'react';
import './SettingsListItem.scss';
import cs from 'classnames';

interface Props {
  title: string;
  description?: string;
  toggle?: React.ReactNode;
  toggelAlignEnd?: boolean;
  className?: string;
}
export const SettingsListItem: React.FC<Props> = ({
  title,
  description,
  children,
  toggle,
  toggelAlignEnd,
  className,
}) => {
  return (
    <div className={cs('SettingsListItem', className)}>
      <div className="d-flex">
        <div>{title}</div>
        {toggle ? (
          <div className={cs('pl-4', { 'ml-auto': toggelAlignEnd })}>
            {toggle}
          </div>
        ) : null}
      </div>
      {description && (
        <div className="SettingsListItem-description">{description}</div>
      )}
      {children}
    </div>
  );
};
