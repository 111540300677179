import * as React from 'react';
import { urlFor, ListHeader, Button, FilterSelect } from 'src/lib/ui';

import { PeriodSelector } from '.';

import { CostContext, useCostContext } from './CostContext';
import { Redirect } from 'react-router';
import { CostCenter } from 'src/lib/types';
import { usePeriodFilter } from 'src/lib/filters';

import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';

import { t } from 'src/lib/i18n';

interface Props {
  costCenterId: string;
  children: (
    costContext: CostContext,
    costCenter: CostCenter
  ) => JSX.Element | null;
}

export const CostCenterPage: React.SFC<Props> = ({
  costCenterId,
  children,
}) => {
  const costContext = useCostContext();
  const costCenter = costContext.getCostCenterById(costCenterId);
  const periodFilter = usePeriodFilter({
    costPeriods: costContext.costPeriods,
    period: costContext.activePeriod,
    setPeriod: costContext.setActivePeriod,
  });

  if (!costCenter) {
    // Cost center not found!
    // Possible explanations:
    //   - Can be a valid cost center for another customer
    //   - Can be a cost center since deleted
    //   - Typo in the URL
    // Now, we'll just redirect to the cost center picker.
    return <Redirect to="/kostnader/velg-kostnadssted" />;
  }

  const rootUrl = urlFor({ area: 'cost', costCenter: costCenter.id });
  const i18n = t.costs.CostCenterPage;

  return (
    <>
      <PageHeader
        title={costCenter ? `${costCenter.description}` : ''}
        navTabs={[
          {
            url: rootUrl,
            label: i18n.PageHeader.navTabs.summary,
            exact: true,
          },
          // {
          //   url: `${rootUrl}/detaljer`,
          //   label: 'Detaljer',
          // },
        ]}
      >
        <Button icon="company" to="/kostnader/velg-fakturasted" color="white">
          {i18n.PageHeader.actions.chooseOrg}
        </Button>
        <Button icon="company" to="/kostnader/velg-kostnadssted" color="white">
          {i18n.PageHeader.actions.chooseCostCenter}
        </Button>
      </PageHeader>

      <ListHeader className="mb-4">
        <FilterSelect {...periodFilter} />

        <PeriodSelector
          className="px-4"
          setActivePeriodType={costContext.setPeriodBy}
          activePeriodType={costContext.periodBy}
        />
      </ListHeader>

      {children(costContext, costCenter)}
    </>
  );
};
