import React from 'react';
import { Subscription } from 'src/lib/types';
import { useAppContext } from 'src/lib/global';
import {
  SubscriptionContext,
  SubscriptionContextProvider,
} from 'src/areas/mobile/lib/SubscriptionContext';
import GenericSubscriptionPage from 'src/areas/main/subscriptions/Pages/Generic/GenericSubscriptionPage';
import { MobileSubscriptionPageInner } from 'src/areas/main/subscriptions/Pages/Mobile/MobileSubscriptionPageInner';

interface Props {
  subscription: Subscription;
}
const MobileSubscriptionPage: React.FC<Props> = ({ subscription }) => {
  const { phoneNumbers } = subscription;
  const app = useAppContext();

  const phoneNumber =
    phoneNumbers && phoneNumbers.length
      ? phoneNumbers[0].phoneNumber
      : undefined;
  if (phoneNumber) {
    const subscriptionContext: SubscriptionContext = {
      id: phoneNumber,
      name: '',
      availableSubscriptionIds: app.access.mobileSubscriptions
        ? app.access.mobileSubscriptions.map(s => s.id)
        : [],
      customerId: app.activeCustomerId || '',
      selectSubscriptionId: app.setActiveSubscriptionId,
      disableEdit: !app.customerRoles.filter(r =>
        [
          'CustomerAdministrator',
          'OrderManager',
          'SystemAdministrator',
        ].includes(r)
      ).length,
    };
    return (
      <SubscriptionContextProvider value={subscriptionContext}>
        <MobileSubscriptionPageInner />
      </SubscriptionContextProvider>
    );
  } else {
    return <GenericSubscriptionPage subscription={subscription} />;
  }
};

export default MobileSubscriptionPage;
