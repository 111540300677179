import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { ApolloError, ApolloQueryResult } from 'apollo-client';
import * as t from './__types/useSubscription';
import { Subscription, SubscriptionFragment } from 'src/lib/types';

export const WITH_SUBSCRIPTION = gql`
  query useSubscription($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        # Fetch all the same data as we have on the list/search page
        ...SubscriptionSearchResult

        #
        # Additional fields not included in the list/search query
        #

        # # Search
        activeIncidents {
          # id
          # incidentStatus
          # active
          # shortDescription

          # Additional fields that Search does not include
          category
          description
          opened
          priority
          severity
          updatedOn
        }

        # # WithSub
        costCenter {
          id
          code
          description
        }

        ownerAttributes {
          firstName
          lastName
          employeeNumber
          position
          email
        }

        incidents {
          id
          incidentStatus
          active
          shortDescription

          category
          description
          priority
          severity
          createdAt
          updatedAt
        }

        parent {
          id
          cpe {
            status
            model
          }
          redundancies {
            id
          }
        }

        # Navi suscription contacts. deprecated.
        contacts {
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
          }
        }

        technicalContacts {
          priority

          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            secondaryPhone
            description
          }
        }

        followers {
          id
          firstName
          lastName
          email
          mobilePhone
        }

        incidentFollowers {
          id
          firstName
          lastName
          email
          mobilePhone
        }

        cpe {
          name
          description
          model
          status
        }

        startTime
        endTime

        # Breadcrumbs
        breadcrumbs {
          id
          subscriptionId
          name
          alias
          group
        }

        flex1
        flex2
        flex3
        flex4
        taxModel

        accessBandwidth {
          downstream
          upstream
        }
        serviceBandwidth {
          downstream
          upstream
        }
        sla
        plannedWork {
          id
        }
      }
    }
  }
  ${SubscriptionFragment}
`;

export interface SubscriptionData {
  loading: boolean;
  error?: ApolloError | null;
  subscription?: Subscription;
  customerId: string;
  refetch?: (
    variables?: t.useSubscriptionVariables | undefined
  ) => Promise<ApolloQueryResult<t.useSubscription>>;
}

export const useSubscription = (subscriptionId?: string): SubscriptionData => {
  const customer = useCustomer();
  const options = subscriptionId
    ? {
        variables: {
          customerId: customer.id,
          subscriptionId: subscriptionId,
        },
      }
    : { skip: true };

  const { data, loading, error, refetch } = useQuery<
    t.useSubscription,
    t.useSubscriptionVariables
  >(WITH_SUBSCRIPTION, {
    ...options,
    errorPolicy: 'all',
  });
  const result: SubscriptionData = {
    loading,
    error,
    subscription: data?.customer?.subscriptions?.[0]
      ? data.customer.subscriptions[0]
      : undefined,
    refetch: refetch,
    customerId: customer.id,
  };
  return result;
};
