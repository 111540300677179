import { useNetworkChangeOrder } from './../../../mutations/useNetworkChangeOrder';
import { useEffect, useState, useMemo } from 'react';
import { IpRouting } from 'src/lib/types/subscription/IpRouting';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

type ConfigKeys = keyof Pick<
  IpRouting,
  'networkAddress' | 'subnetMask' | 'gateway'
>;
export const configKeys: Array<ConfigKeys> = [
  'networkAddress',
  'subnetMask',
  'gateway',
];

export const useEditIpRouting = (initialRoutes: IpRouting[]) => {
  const [openModal, setOpenModal] = useState(false);
  const [routes, setRoutes] = useState(initialRoutes);
  const { changeStaticRouting, data, loading } = useNetworkChangeOrder();
  const submitted = !!data?.createNetworkChangeOrder?.success;

  useEffect(() => {
    if (openModal) {
      setRoutes(initialRoutes);
    }
  }, [openModal, initialRoutes]);
  // TODO clear mutation result after success
  // TODO handle error
  const hasChanged = useMemo(
    () => !!differenceWith(initialRoutes, routes, isEqual).length,
    [initialRoutes, routes]
  );
  const toggleModal = () => setOpenModal(!openModal);
  const handleChange = (payload: {
    value: string;
    configKey: ConfigKeys;
    index: number;
  }) => {
    const newState = routes.map((ip, index) =>
      index === payload.index
        ? {
            ...ip,
            [payload.configKey]: payload.value,
          }
        : ip
    );
    setRoutes(newState);
  };

  const handleSubmit = (subscriptionId: string) => {
    const mapped = routes.map(route => ({
      id: route.id,
      subnetMask: route.subnetMask,
      gateway: route.gateway,
    }));
    changeStaticRouting(subscriptionId, mapped);
  };

  return {
    routes,
    handleChange,
    openModal,
    toggleModal,
    handleSubmit,
    submitted,
    loading,
    disableSubmit: !hasChanged,
  };
};
