import React from 'react';
import {
  Loading,
  Table,
  TableBodyRow,
  TableBodyCell,
  TableColumnDefintion,
  Pagination,
  urlFor,
} from 'src/lib/ui';
import { Addon } from '../../../queries/useAddons';
import { t } from 'src/lib/i18n';
import SubscriptionStatusIcon from '../../../lib/SubscriptionStatusIcon/SubscriptionStatusIcon';
import { usePagination } from 'src/lib/ui/Pagination/usePagination';
import { useRouter } from 'src/lib/utils';
import { useSavedUserPreferences } from 'src/lib/userPreferences/SavedUserPreferencesContext';
import { parseStringToNumber } from 'src/lib/utils/parseStringTo';

export const AddonTable = (props: {
  addons?: Addon[] | null;
  loading: boolean;
}) => {
  const router = useRouter();
  const i18n = t.subscriptions.Addons;
  const { addons, loading } = props;
  const { userPreferences, setUserPreference } = useSavedUserPreferences();
  const pageSize = userPreferences.subscriptionConfigPageSize;
  const defaultPageSize = pageSize ? parseStringToNumber(pageSize) : 5;

  const { filteredResult, paginationProps } = usePagination({
    results: addons ?? [],
    defaultPageSize,
    onTogglePageSize: num =>
      setUserPreference('subscriptionConfigPageSize', num.toString()),
  });

  const columns: TableColumnDefintion[] = [
    {
      key: 'status',
      label: i18n.status,
      cellWidth: 80,
    },
    { key: 'alias', label: i18n.alias, cellWidth: 200 },
    {
      key: 'type',
      label: i18n.type,
      cellWidth: 200,
    },
    {
      key: 'vrg',
      label: i18n.vpn,
    },
  ];

  if (loading) {
    return <Loading />;
  }
  if (!addons || addons.length === 0) {
    return null;
  }

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        sortable={false}
        bordered={false}
        rowsCount={defaultPageSize}
        noResult={addons?.length === 0}
      >
        {filteredResult?.map(row => {
          if (!row) {
            return null;
          }

          return (
            <TableBodyRow
              key={row.id}
              onClick={() =>
                router.history.push(urlFor({ subscription: row.id }))
              }
            >
              <TableBodyCell>
                <SubscriptionStatusIcon
                  cpeStatus={row.cpe?.status}
                  circleBackground={true}
                  size="sm"
                  className="mr-2"
                  hasStatus={row.isMonitoringPossible}
                />
              </TableBodyCell>
              <TableBodyCell>{row.alias}</TableBodyCell>
              <TableBodyCell>{row.name}</TableBodyCell>
              <TableBodyCell>
                {row.vrf
                  ? `${row.vrf.vpnAlias} ${row.vrf.vpnId} ${row.vrf.vrfName}`
                  : null}
              </TableBodyCell>
            </TableBodyRow>
          );
        })}
      </Table>
      <div className="d-flex p-3 justify-content-end border-top">
        <Pagination {...paginationProps} />
      </div>
    </div>
  );
};
