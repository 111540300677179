import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as tt from './__types/useSubscriptionTypeCount';

const query = gql`
  query useSubscriptionTypeCount($customerId: ID!) {
    customer(id: $customerId) {
      id
      dataSubscriptions: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [{ filter: "group", value: ["data"] }]
        }
      ) {
        totalResults
      }

      mobileSubscriptions: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [{ filter: "group", value: ["mobile"] }]
        }
      ) {
        totalResults
      }

      teleSubscriptions: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [{ filter: "group", value: ["tele"] }]
        }
      ) {
        totalResults
      }
      tvSubscriptions: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [{ filter: "group", value: ["tv"] }]
        }
      ) {
        totalResults
      }
    }
  }
`;

export interface SubscriptionTypeCount {
  loading: boolean;
  dataSubscriptions?: number | null;
  mobileSubscriptions?: number | null;
  teleSubscriptions?: number | null;
  tvSubscriptions?: number | null;
}

export default function useSubscriptionTypeCount(): SubscriptionTypeCount {
  const customer = useCustomer();

  const result = useQuery<
    tt.useSubscriptionTypeCount,
    tt.useSubscriptionTypeCountVariables
  >(query, {
    variables: {
      customerId: customer.id,
    },
    errorPolicy: 'all',
  });

  if (!result.data || !result.data.customer) {
    return {
      loading: result.loading,
      dataSubscriptions: undefined,
      mobileSubscriptions: undefined,
      teleSubscriptions: undefined,
      tvSubscriptions: null,
    };
  }

  return {
    loading: result.loading,
    dataSubscriptions: result.data.customer.dataSubscriptions?.totalResults,
    mobileSubscriptions: result.data.customer.mobileSubscriptions?.totalResults,
    teleSubscriptions: result.data.customer.teleSubscriptions?.totalResults,
    tvSubscriptions: result.data.customer.tvSubscriptions?.totalResults,
  };
}
