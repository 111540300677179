import React, { useState } from 'react';
import { BodyCard, CardHeader, NoResult, Button } from 'src/lib/ui';
import { WrenchIcon } from '@telia/styleguide';
import { Tab, Tabs } from '@telia/styleguide/business';
import { usePlannedWork } from '../queries/usePlannedWork';
import { DashboardPlannedWorkItem } from './DashboardPlannedWorkItem';
import './DashboardPlannedWork.scss';
import { t } from 'src/lib/i18n';
import moment from 'moment';
import { useBreakpoint, useFeature } from 'src/lib/utils';
import { useDownloadLink } from 'src/lib/utils/useDownloadLink';

export const DashboardPlannedWork = () => {
  const { plannedWork, servicesWithPlannedWork } = usePlannedWork();
  const desktop = useBreakpoint('sm');
  const downloadLink = useDownloadLink();
  const hasPlannedWorkPlacementFeature = useFeature('planned-work-placement');

  const [showSubs, setShowSubs] = useState(false);
  const result = plannedWork
    ?.filter(pw => {
      const now = moment();
      const end = moment(pw.dates.plannedEndDate);

      return end.isAfter(now);
    })
    .sort((a, b) =>
      moment(a.dates.plannedStartDate).diff(moment(b.dates.plannedStartDate))
    );

  const downloadPlannedWorkReportsLink =
    '/api/files/report/customer/plannedWork?';

  const formParameters = {
    type: 'plannedWork',
  };

  let plannedWorkTitle = hasPlannedWorkPlacementFeature
    ? `${servicesWithPlannedWork} ${t.dashboard.DashboardGrid.servicesWithPlannedWork(
        servicesWithPlannedWork
      )}`
    : t.dashboard.plannedWork.plannedWork;

  return (
    <BodyCard noGutter={true} className="DashboardPlannedWork">
      <div
        style={{
          flexDirection: 'row',
          display: 'flex',
          justifyContent: 'space-between',
        }}
      >
        <CardHeader>
          <h2>{plannedWorkTitle}</h2>
        </CardHeader>
        <Button
          icon="download"
          color="dark"
          transparent={true}
          size="small"
          style={{ margin: '1.5rem' }}
          onClick={() =>
            downloadLink(downloadPlannedWorkReportsLink, formParameters)
          }
        >
          {t.dashboard.plannedWork.download}
        </Button>
      </div>
      <div className="Table-container">
        {!result?.length ? (
          <NoResult>
            <WrenchIcon
              style={{ width: '50px', height: '50px', marginBottom: '10px' }}
            />
            <div>{t.dashboard.plannedWork.noPlannedWork}</div>
          </NoResult>
        ) : (
          <table className="Table" style={{ minWidth: '0' }}>
            <thead>
              <tr>
                <td>{t.dashboard.plannedWork.referenceNumber}</td>
                <td>{t.dashboard.plannedWork.date}</td>
                {desktop && <td>{t.dashboard.plannedWork.time}</td>}
                <td>
                  {hasPlannedWorkPlacementFeature ? (
                    <div className="affectedServices">
                      <Tabs
                        className="PlannedWorkTabs"
                        outlined={true}
                        centered={true}
                        onClick={value => setShowSubs(value === 'true')}
                        currentValue={`${showSubs}`}
                      >
                        <Tab
                          value={'true'}
                          label={t.dashboard.plannedWork.affectedServices}
                        />
                        <Tab
                          value={'false'}
                          label={
                            t.dashboard.plannedWork.numberOfAffectedServices
                          }
                        />
                      </Tabs>
                    </div>
                  ) : (
                    t.dashboard.plannedWork.numberOfAffectedServices
                  )}
                </td>
              </tr>
            </thead>
            <tbody>
              {result?.map(p => (
                <DashboardPlannedWorkItem
                  key={p.id}
                  plannedWork={p}
                  showSub={showSubs}
                />
              ))}
            </tbody>
          </table>
        )}
      </div>
    </BodyCard>
  );
};
