import React, { useRef, useEffect } from 'react';
import { useCombobox } from 'downshift';
import { SearchInput, urlFor } from 'src/lib/ui';
import * as t from '../types';
import * as i18n from 'src/lib/i18n';
import SearchMenu, { SearchMenuProps } from './SearchMenu';
import { SearchProvider } from '../lib/SearchProvider';
import cx from 'classnames';
import './SearchField.scss';
import { useRouter } from 'src/lib/utils/useRouter';
import SearchMenuSimple from './SearchMenuSimple';
import { SearchStyleType } from 'src/lib/ui/SearchInput/SearchInput';
import { groupSearchResults } from '../lib/groupSearchResults';

type Props = {
  searchProvider: SearchProvider;
  searchMenu?: React.ComponentType<SearchMenuProps>;
  styleType?: SearchStyleType;
  renderSearchMenuInitial?: boolean;
  renderSearchMenuOnFocus?: boolean;
  isExpandable?: boolean;
  hideMenuOnInputBlur?: boolean;
  searchMenuResultProps?: any;
  noMenu?: boolean;
  disabled?: boolean;
  displayBlock?: boolean;
  noHitsMessage?: string;
  placeholder?: string;
  resetLocationFilter?: () => void;
  onSelect?: (item?: t.SearchResult) => void;
  onSearch?: () => void;
  itemIsDisabled?: (item: t.SearchResult) => boolean;
  additionalInfoOnResult?: (item: t.SearchResult) => React.ReactElement | null;
  narrow?: boolean;
};
const ENTER_KEYCODE = 13;

const SearchField: React.FC<Props> = props => {
  const router = useRouter();
  const inputElement = useRef<HTMLInputElement>(null);
  const searchFieldRef = useRef<HTMLDivElement>(null);

  const results =
    props.searchMenu === SearchMenu
      ? groupSearchResults(props.searchProvider.results).flatMap(g => g.items)
      : props.searchProvider.results;

  const downshift = useCombobox({
    items: results,
    circularNavigation: true,
    initialIsOpen: props.renderSearchMenuInitial,
    onInputValueChange: changes => {
      props.searchProvider.setQuery(changes.inputValue || '');
    },
    onSelectedItemChange: changes => {
      if (props.onSelect) {
        props.onSelect?.(changes?.selectedItem);
      } else if (changes.selectedItem) {
        router.history.push(urlFor(changes.selectedItem));
      }
    },
    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.InputBlur:
          return state;
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          return {
            ...actionAndChanges.changes,
            isOpen: false,
            inputValue: '',
          };
        default:
          return actionAndChanges.changes;
      }
    },
  });

  useEffect(() => {
    const searchAndTrack = (e: KeyboardEvent) => {
      if (
        e.keyCode === ENTER_KEYCODE &&
        searchFieldRef.current?.contains(e.target as Node) &&
        downshift.isOpen
      ) {
        props.onSearch?.();
        downshift.closeMenu();
      }
    };
    window.addEventListener('keydown', searchAndTrack);
    return () => window.removeEventListener('keydown', searchAndTrack);
  }, [searchFieldRef, downshift, downshift.isOpen, downshift.closeMenu]); // eslint-disable-line

  const getLabelProps = downshift.getLabelProps({
    className: 'sr-only ',
  });

  useEffect(() => {
    const closeMenu = (e: MouseEvent) => {
      if (
        !searchFieldRef.current?.contains(e.target as Node) &&
        downshift.isOpen
      ) {
        downshift.closeMenu();
      }
    };
    window.addEventListener('click', closeMenu);
    return () => {
      window.removeEventListener('click', closeMenu);
    };
  }, [searchFieldRef, downshift, downshift.isOpen, downshift.closeMenu]);

  // Search History not used for now
  // const isInSearchHistory = this.props.searchProvider.query.length <= 2;
  // this.state && this.state.query && this.state.query.length <= 2;
  const Element = props.searchMenu || SearchMenuSimple;
  if (props.noMenu) {
    return (
      <div
        className={cx({
          SearchField: true,
          block: props.displayBlock,
          'SearchField--expandable': props.isExpandable,
        })}
      >
        <label
          id="SearchField-label"
          htmlFor="SearchField-input"
          className="sr-only"
        >
          {i18n.t.layout.SearchField.searchInputPlaceholder}
        </label>
        <SearchInput
          id="SearchField-input"
          menuOpen={downshift.isOpen}
          disabled={props.disabled}
          narrow={props.narrow}
          placeholder={
            props.placeholder ?? i18n.t.search.SearchField.placeholder
          }
          loading={props.searchProvider.loading}
          onChange={e => {
            if (props.resetLocationFilter) {
              props.resetLocationFilter();
              props.searchProvider.setFilter([
                {
                  filter: 'location',
                  value: '',
                },
              ]);
            }
            props.searchProvider.setQuery(e.currentTarget.value);
          }}
          inputStyleType={
            props.styleType === 'important' ? 'important' : 'default'
          }
        />
      </div>
    );
  }

  return (
    <div
      className={cx({
        SearchField: true,
        'SearchField--expandable': props.isExpandable,
        isOpen: downshift.isOpen,
        block: props.displayBlock,
      })}
      {...downshift.getComboboxProps()}
      ref={searchFieldRef}
    >
      <label {...getLabelProps}>{i18n.t.layout.SearchField.label}</label>

      <SearchInput
        disabled={props.disabled}
        value={props.searchProvider.query}
        menuOpen={downshift.isOpen}
        innerRef={inputElement}
        placeholder={
          props.placeholder || i18n.t.layout.SearchField.searchInputPlaceholder
        }
        loading={props.searchProvider.loading}
        narrow={props.narrow}
        inputStyleType={props.styleType}
        onClose={() => {
          downshift.closeMenu();
          props.searchProvider.setQuery('');
        }}
        onSearch={props.onSearch}
        inputProps={downshift.getInputProps}
        onFocus={() => {
          if (props.renderSearchMenuOnFocus) {
            downshift.openMenu();
          }
        }}
      />

      <Element
        getMenuProps={downshift.getMenuProps}
        getItemProps={downshift.getItemProps}
        isOpen={downshift.isOpen}
        inputValue={downshift.inputValue}
        noHitsMessage={props.noHitsMessage}
        highlightedIndex={downshift.highlightedIndex}
        selectedItem={downshift.selectedItem}
        searchHistory={props.searchProvider.history}
        results={props.searchProvider.results}
        loading={props.searchProvider.loading}
        totalResults={props.searchProvider.totalResults}
        totalResultsByDomain={props.searchProvider.totalResultsByDomain}
        onCategoryClick={downshift.closeMenu}
        itemIsDisabled={props.itemIsDisabled}
        additionalInfoOnResult={props.additionalInfoOnResult}
      />
    </div>
  );
};

export default SearchField;
