const menu = {
  mySubscription: 'Mitt abonnement',
  sendSMS: 'Send SMS',
  useageAndCost: 'Forbruk og kostnader',
  subscriptionSelectorHeader: 'Mine telefonnumre',
  settings: 'Innstillinger',
  history: 'Historikk',
  profile: 'Min profil',
  language: 'Velg språk',
  overview: 'Oversikt',
  toPortal: 'Gå til Min Portal',
  logout: 'Logg ut',
};

export default menu;
