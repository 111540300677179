import React, { useState } from 'react';
import { InfoCard } from 'src/lib/ui';
import { Contact } from 'src/lib/types';
import { mapResponseToRoles } from '../editContact/utils';
import SaveOverlay from '../SaveOverlay/SaveOverlay';
import './EditRoles.scss';
import { formatName } from 'src/lib/utils/formatName';
import { t } from 'src/lib/i18n';
import { NotificationSettings } from 'src/areas/dashboard/profile/Notifications/NotificationSettings';
import { useUser } from 'src/lib/global/useUser';
import {
  useContact_customer_contacts_notificationSettings_change,
  useContact_customer_contacts_notificationSettings_incident,
} from '../../queries/__types/useContact';
import { useUpdateNotificationSettingsOnUser } from 'src/areas/dashboard/profile/mutations/useUpdateNotificationSettingsOnUser';
import {
  NotificationSettingsInput,
  NotificationSettingsType,
} from 'src/__types/graphql-global-types';

export const EditNotificationSettings = (props: {
  contact: Contact;
  cancel: () => void;
}) => {
  const [
    incidentNotificationSettings,
    setIncidentNotificationSettings,
  ] = useState<
    | useContact_customer_contacts_notificationSettings_incident
    | null
    | undefined
  >(props.contact.notificationSettings?.incident);
  const [changeNotificationSettings, setChangeNotificationSettings] = useState<
    useContact_customer_contacts_notificationSettings_change | null | undefined
  >(props.contact.notificationSettings?.change);
  const {
    updateSettings,
    error,
    loading,
  } = useUpdateNotificationSettingsOnUser(props.contact.id);

  const i18n = t.contacts.editRoles;
  const { data } = useUser();

  const currentUserRoles = mapResponseToRoles(
    data?.me?.customerAccess?.roles ?? []
  );

  const setSettings = (
    settings:
      | useContact_customer_contacts_notificationSettings_incident
      | useContact_customer_contacts_notificationSettings_change
      | null
  ) => {
    if (settings?.type === NotificationSettingsType.incident) {
      setIncidentNotificationSettings(settings);
    } else if (settings?.type === NotificationSettingsType.change) {
      setChangeNotificationSettings(settings);
    }
  };

  const submitNotificationSettings = () => {
    submitIncidentSettings();
    submitChangeSettings();
  };

  const submitIncidentSettings = async () => {
    const incidentSubs =
      incidentNotificationSettings?.subscriptions?.flatMap(s =>
        s ? [s.id] : []
      ) ?? [];
    const incidentSettings: NotificationSettingsInput = {
      type: NotificationSettingsType.incident,
      active: incidentNotificationSettings?.active ?? false,
      channels: incidentNotificationSettings?.channels ?? [],
      subscriptionIds: incidentSubs,
      notifyForAllCustomerSubscriptions:
        incidentNotificationSettings?.notifyForAllCustomerSubscriptions ??
        false,
    };
    await updateSettings(incidentSettings);
  };

  const submitChangeSettings = async () => {
    const changeSubs =
      changeNotificationSettings?.subscriptions?.flatMap(s =>
        s ? [s.id] : []
      ) ?? [];
    const changeSettings: NotificationSettingsInput = {
      type: NotificationSettingsType.change,
      active: changeNotificationSettings?.active ?? false,
      channels: changeNotificationSettings?.channels ?? [],
      subscriptionIds: changeSubs,
      notifyForAllCustomerSubscriptions:
        changeNotificationSettings?.notifyForAllCustomerSubscriptions ?? false,
    };
    await updateSettings(changeSettings);
  };

  const fullName = formatName({
    firstName: props.contact.firstName,
    lastName: props.contact.lastName,
  });

  const isAdminOrOwnUser =
    currentUserRoles.adminRole || data?.me?.id === props.contact.user?.id;

  return (
    <div
      className="EditRoles-notifications pt-4"
      style={{ paddingBottom: '7.5rem' }}
    >
      {isAdminOrOwnUser ? (
        <>
          {data?.me?.id !== props.contact.user?.id && (
            <InfoCard className="my-4">{`${i18n.notificationsInfo1} ${fullName}. ${i18n.notificationsInfo2} ${props.contact.firstName}.`}</InfoCard>
          )}
          {props.contact.notificationSettings && (
            <NotificationSettings
              incidentSettings={props.contact.notificationSettings.incident}
              changeSettings={props.contact.notificationSettings.change}
              contactId={props.contact.id}
              setSettings={setSettings}
            />
          )}
          <SaveOverlay
            submit={submitNotificationSettings}
            ignoreSidebar={true}
            loading={loading}
            error={error}
            cancel={props.cancel}
          />
        </>
      ) : (
        <div>
          <InfoCard className="my-4">
            {i18n.notificationChangeAccesses}
          </InfoCard>
        </div>
      )}
    </div>
  );
};
