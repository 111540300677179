import { RadioButton } from 'src/lib/ui';
import React, { useEffect, useState } from 'react';
import cx from 'classnames';
import './TabbedRadioButtonGroup.scss';
import { throttle } from 'lodash';
export interface RadioGroupProps {
  id?: string;
  disabled?: boolean;
  error?: string;
  helpText?: string;
  value?: string;
  className?: string;
  label?: string;
  options: RadioGroupOption[];
  tabbedOptions: RadioGroupOption[];

  /** TO ACCESS FULL WIDTH */
  parentContainerClassName: string;
  onChange: (value?: string) => void;
  onBlur?: () => void;
}

interface RadioGroupOption {
  value: string | number | boolean;
  label: string;
}

const TabbedRadioButtonGroup: React.FunctionComponent<RadioGroupProps> = props => {
  const [innerContainerHeight, setInnCntainerHeight] = useState(0);

  const [radioButtonLeft, setRadioButtonLeft] = useState([0]);

  const refs = props.tabbedOptions.map(option => ({
    value: option.value,
    ref: React.createRef<HTMLDivElement>(),
  }));
  const parentRef = React.createRef<HTMLDivElement>();

  const resize = () => {
    if (parentRef.current) {
      setInnCntainerHeight(parentRef.current.clientHeight);
    }

    props.tabbedOptions.forEach((option, i) => {
      const radioBtn = refs.find(ref => ref.value === option.value);
      if (radioBtn && radioBtn.ref.current) {
        radioButtonLeft[i] = radioBtn.ref.current.offsetLeft;
        setRadioButtonLeft([...radioButtonLeft]);
      }
    });
  };

  useEffect(() => {
    resize();

    const throttledResize = throttle(resize, 100);
    window.addEventListener('resize', throttledResize);
    return () => window.removeEventListener('resize', throttledResize);
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className={cx('TabbedRadioButtonGroup', props.className)}
      ref={parentRef}
    >
      {props.label ? <label>{props.label}</label> : null}
      {props.options.map((option, idx) => {
        const ref = refs.find(r => r.value === option.value);
        return (
          <RadioButton
            key={idx}
            divRef={ref ? ref.ref : undefined}
            id={option.value.toString()}
            name={option.value.toString()}
            checked={props.value === option.value}
            label={option.label}
            value={option.value}
            onChange={props.onChange}
            onBlur={props.onBlur}
          />
        );
      })}
      {props.tabbedOptions.map(
        (option, i) =>
          option.value === props.value && (
            <div
              key={i}
              className="TabbedRadioButtonGroup-line"
              style={{
                top: innerContainerHeight + innerContainerHeight / 4,
              }}
            >
              <div
                className="TabbedRadioButtonGroup-box-arrow"
                style={{
                  left: radioButtonLeft[i],
                  top: '-8px',
                }}
              />
            </div>
          )
      )}
    </div>
  );
};

export default TabbedRadioButtonGroup;
