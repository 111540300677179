import { useRouter } from 'src/lib/utils/useRouter';
import { urlFor } from 'src/lib/ui';
import { NavigateTo } from 'src/lib/types';

export const useNavigateToFilesAndFolder = () => {
  const router = useRouter();

  const navigateTo: NavigateTo = link => {
    if (typeof link === 'object' && 'file' in link) {
      return router.history.push(
        urlFor({
          path: router.match.url,
          file: link.file.id,
        })
      );
    } else if (typeof link === 'object' && 'folder' in link) {
      if (link.folder.parentId && !(link.folder.createdBy === 'system')) {
        return router.history.push(
          urlFor({
            path: router.match.url,
            folder: link.folder.id,
          })
        );
      }
    }
    return router.history.push(router.match.url);
  };
  return navigateTo;
};
