import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useIncidentFollowers';
import { IncidentFollowerFragment } from 'src/lib/types/contact/IncidentFollower';
export const INCIDENT_FOLLOWERS_QUERY = gql`
  query useIncidentFollowers($customerId: ID!, $incidentId: ID!) {
    customer(id: $customerId) {
      id
      incidents(ids: [$incidentId]) {
        id
        subscription {
          id
          incidentFollowers {
            ...IncidentFollowerFragment
          }
        }
      }
    }
  }
  ${IncidentFollowerFragment}
`;

export const useIncidentFollowers = (
  incidentId: string,
  fetchNetwork?: boolean
) => {
  const customer = useCustomer();
  const { data, loading, error, refetch } = useQuery<
    t.useIncidentFollowers,
    t.useIncidentFollowersVariables
  >(INCIDENT_FOLLOWERS_QUERY, {
    variables: {
      customerId: customer.id,
      incidentId: incidentId,
    },
    fetchPolicy: fetchNetwork ? 'network-only' : 'cache-first',
  });

  return {
    loading: loading,
    refetch: refetch,
    error: !!error,
    incidentFollowers:
      data?.customer?.incidents?.[0]?.subscription?.incidentFollowers ??
      undefined,
  };
};
