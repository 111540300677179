import React, { useState } from 'react';
import nbLocale from 'date-fns/locale/nb';
import { format, parse } from 'date-fns';

import { Icon } from '@telia/styleguide';
import { CircleIcon } from 'src/lib/ui';
import { useBreakpoint } from 'src/lib/utils';
import { trackPlannedWork } from 'src/lib/analytics';
import { t, useI18nContext } from 'src/lib/i18n';

import * as types from '../queries/__types/usePlannedWork';
import { PlannedWorkModal } from '../PlannedWork/PlannedWorkModal';

import { firstCharToUpper } from 'src/lib/utils/strings';

import './PersonalizedDashboardPlannedWorkItem.scss';

const nbparam = { locale: nbLocale };

function parseDate(date: string, desktopFormat: boolean, locale: string) {
  const formatString = desktopFormat ? 'dddd D. MMM' : 'D. MMM';
  const d = parse(date);
  return {
    date: firstCharToUpper(
      format(d, formatString, locale === 'nb' ? nbparam : undefined)
    ),
    time: format(d, 'HH:mm'),
  };
}

export const PersonalizedDashboardPlannedWorkItem = (props: {
  plannedWork: types.usePlannedWork_customer_plannedWork;
}) => {
  const [open, setOpen] = useState(false);
  const i18n = useI18nContext();
  const desktop = useBreakpoint('sm');

  const start = parseDate(
    props.plannedWork.dates.plannedStartDate,
    desktop,
    i18n.locale
  );
  const end = parseDate(
    props.plannedWork.dates.plannedEndDate,
    desktop,
    i18n.locale
  );

  const spansDays = start.date !== end.date;

  function openModal() {
    trackPlannedWork();
    setOpen(true);
  }

  return (
    <>
      <tr
        onClick={openModal}
        onKeyUp={e => (e.key === 'Enter' ? openModal() : null)}
        role="button"
        tabIndex={0}
        className="PersonalizedDashboardPlannedWorkItem"
      >
        <td>
          <div className="PersonalizedDashboardPlannedWorkItem-date">
            {start.date}
          </div>
          {spansDays ? (
            <div className="PersonalizedDashboardPlannedWorkItem-date">
              {end.date}
            </div>
          ) : null}
        </td>

        <td className="PersonalizedDashboardPlannedWorkItem-time-col">
          {spansDays ? (
            <>
              <div className="PersonalizedDashboardPlannedWorkItem-time">
                <Icon icon="time" /> {t.dashboard.plannedWork.startTime}{' '}
                {start.time}
              </div>
              <div className="PersonalizedDashboardPlannedWorkItem-time">
                <Icon icon="time" /> {t.dashboard.plannedWork.endTime}{' '}
                {end.time}
              </div>
            </>
          ) : (
            <div className="PersonalizedDashboardPlannedWorkItem-time">
              <Icon icon="time" /> {start.time} {t.dashboard.plannedWork.to}{' '}
              {end.time}
            </div>
          )}
        </td>

        <td>
          <div className="PersonalizedDashboardPlannedWorkItem-number">
            {t.dashboard.plannedWork.servicesAffected(
              props.plannedWork.numberOfImpactedSubscriptions ?? 0
            )}
          </div>
        </td>
        <td>
          {props.plannedWork.emergency ? (
            <CircleIcon icon="alert" color="danger" filled={true} />
          ) : null}
        </td>
      </tr>

      <PlannedWorkModal
        open={open}
        toggleOpen={() => setOpen(!open)}
        plannedWork={props.plannedWork}
      />
    </>
  );
};
