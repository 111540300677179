import * as React from 'react';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { sortBy } from 'lodash';
import {
  unwrapWrappedSearchResults,
  WrappedSearchResult,
} from 'src/areas/main/search';
import * as t from './__types/WithCostCenters';

const query = gql`
  query WithCostCenters($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        size
        totalResults
        cursor
        results {
          __typename
          ... on CostCenterSearchResult {
            costCenter {
              id
              code
              description
              customer {
                id
                name
              }
            }
          }
        }
      }
    }
  }
`;

interface Props {
  customerId: string;
  children: (res: Result) => JSX.Element | null;
}

interface Result {
  loading: boolean;
  costCenters:
    | t.WithCostCenters_customer_search_results_CostCenterSearchResult_costCenter[]
    | undefined;
}

/** TODO: get rid of his render props  */
export const WithCostCenters: React.FC<Props> = props => {
  const res = useCostCenters();
  return props.children(res);
};

export const useCostCenters = (): Result => {
  const customer = useCustomer();

  const { data, loading } = useQuery<
    t.WithCostCenters,
    t.WithCostCentersVariables
  >(query, {
    variables: {
      customerId: customer.id,
      search: {
        types: ['costCenter'],
        size: 10000,
      },
    },
  });

  const unwrappedResults = data?.customer?.search?.results
    ? unwrapWrappedSearchResults(
        data?.customer?.search?.results as WrappedSearchResult[]
      )
    : undefined;

  return {
    loading: loading,
    costCenters: unwrappedResults
      ? sortBy(
          unwrappedResults as t.WithCostCenters_customer_search_results_CostCenterSearchResult_costCenter[],
          cc => cc.description
        )
      : undefined,
  };
};
