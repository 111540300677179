import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import useStructureCacheUpdater from 'src/areas/main/documents/lib/useStructureCacheUpdater';
import { ApolloError } from 'apollo-client';
import * as t from './__types/useDeleteFolder';

const MUTATION_DELETE_FOLDER = gql`
  mutation useDeleteFolder($id: ID!) {
    deleteFolder(input: { id: $id }) {
      id
    }
  }
`;

interface DeleteFolderResult {
  deleteFolder: (folderId: string, cb?: () => void) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export const useDeleteFolder = (): DeleteFolderResult => {
  const cacheUpdater = useStructureCacheUpdater<t.useDeleteFolder>();
  const [mutation, { loading, error }] = useMutation<
    t.useDeleteFolder,
    t.useDeleteFolderVariables
  >(MUTATION_DELETE_FOLDER);
  const deleteFolder = async (folderId, cb) => {
    await mutation({
      variables: { id: folderId },
      update: cacheUpdater(ff => ({
        files: ff.files,
        folders: ff.folders.filter(f => f.id !== folderId),
      })),
    });
    if (cb) {
      cb();
    }
  };

  return { deleteFolder, loading, error };
};
