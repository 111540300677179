import React, { useEffect } from 'react';
import { SectionProps } from '../BarringSettingsFormModal';
import { SettingsListItem } from '../../SettingsListItem';
import { Toggle } from '@telia/styleguide/business';
import { t } from 'src/lib/i18n';
import { colors } from 'src/lib/ui';

export function BarPremiumServices(props: SectionProps) {
  const { state, toggleOption } = props;
  const i18n = t.mobile.settings.barring.barPaidServices;

  useEffect(() => {
    if (state.premiumServices) {
      toggleOption({
        premiumServices820: false,
        premiumServices829: false,
        donations: false,
        numberInformation: false,
      });
    }
  }, [state.premiumServices]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="pt-4 pb-2">{i18n.paidServices}</div>
      <div className="mx-3" style={{ fontSize: '0.875rem' }}>
        <div style={{ color: colors.greyDarkText }}>{i18n.description}</div>
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barPaidServices}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.premiumServices}
              onChange={() => toggleOption('premiumServices')}
            />
          }
        />

        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.bar820}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.premiumServices820}
              disabled={state.premiumServices}
              onChange={() => toggleOption('premiumServices820')}
            />
          }
        />

        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.bar829}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.premiumServices829}
              disabled={state.premiumServices}
              onChange={() => toggleOption('premiumServices829')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barDonation}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.donations}
              disabled={state.premiumServices}
              onChange={() => toggleOption('donations')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barPhonebook}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.numberInformation}
              disabled={state.premiumServices}
              onChange={() => toggleOption('numberInformation')}
            />
          }
        />
      </div>
    </>
  );
}
