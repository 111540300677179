import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import * as types from './__types/useDeleteTag';
import { DeleteTagInput } from './../../../../__types/graphql-global-types';
import { useSearchTag } from 'src/areas/main/subscriptions/queries/__types/useSearchTag';
import { QUERY_SEARCH_TAG } from 'src/areas/main/subscriptions/queries/useSearchTag';
import { produce } from 'immer';
import { useCustomer } from 'src/lib/global';
import { useListQueryParams } from 'src/lib/utils/useListQueryParams';
import {
  tagsDefaultSortOrder,
  tagsDefaultSortBy,
  tagsPreferredNamespace,
} from 'src/areas/main/subscriptions/Pages/Tag/Tag';

const DELETE_TAG = gql`
  mutation useDeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
      success
      error {
        code
        message
      }
    }
  }
`;

export const useDeleteTagMutation = () => {
  const queryParams = useListQueryParams({
    defaultSortKey: tagsDefaultSortBy,
    defaultSortOrder: tagsDefaultSortOrder,
    preferenceNamespace: tagsPreferredNamespace,
  });
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    types.useDeleteTag,
    types.useDeleteTagVariables
  >(DELETE_TAG, {
    update(cache, { data }) {
      const cachedData = cache.readQuery<useSearchTag>({
        query: QUERY_SEARCH_TAG,
        variables: {
          customerId: customer.id,
          query: queryParams.searchQuery,
          sortBy: queryParams.sortBy,
          sortOrder: queryParams.sortOrder,
          size: queryParams.pageSize,
          page: queryParams.page,
        },
      });
      const updatedData = produce(cachedData, draftData => {
        if (data?.deleteTag?.success) {
          const index = draftData?.customer?.search?.results.findIndex(
            t =>
              t.__typename === 'TagSearchResult' &&
              t.tag.id === data?.deleteTag?.id
          );
          if (index) {
            draftData?.customer?.search?.results.splice(index, 1);
          }
        }
      });
      cache.writeQuery({
        query: QUERY_SEARCH_TAG,
        variables: {
          customerId: customer.id,
          query: queryParams.searchQuery,
          sortBy: queryParams.sortBy,
          sortOrder: queryParams.sortOrder,
          size: queryParams.pageSize,
          page: queryParams.page,
        },
        data: updatedData,
      });
    },
  });
  const deleteTag = (input: DeleteTagInput) => {
    mutation({
      variables: {
        input: input,
      },
    });
  };
  return {
    deleteTag: deleteTag,
    data,
    loading,
    error,
  };
};
