import * as React from 'react';
import cs from 'classnames';
import { StatusColor } from 'src/lib/types';

import './SearchMenuResultItem.scss';
import { Icon, IconDefinition } from '@telia/styleguide';

/**
 * These are the props given to the outer result item, e.g. SearchMenuContactResult. The actual props expected by `SearchMenuResultItem` is defined as `Props` below.
 */
export type SearchMenuResultProps<T> = {
  id?: string;

  renderAs?: 'div' | 'li';

  /**
   * Props from downshift, passed on to the list element.
   */
  itemProps?: any;

  /**
   * Downshift state props
   */
  active?: boolean;
  selected?: boolean;

  /**
   * State props
   */
  disabled?: boolean;
  /**
   * Extra content on the card
   */
  children?: any;

  /**
   * The search result entry (i.e. a Subscription, Contact etc. object)
   */
  entry: T;

  /**
   * Additional info to display on end of card
   */
  additionalInfo?: React.ReactElement;
};

type Props = SearchMenuResultProps<{ __typename?: string }> & {
  /**
   * The main title for the search result
   */
  title: string;

  /**
   * The subtitle for the search result
   */
  subTitle?: string;

  /**
   * A small text summary to represent the search result
   */
  summary?: string | null;

  /**
   * The icon to be displayed in the left side of the card
   */
  icon?: IconDefinition;

  /**
   * The color for the icon
   */
  iconColor?: StatusColor;

  // status?: boolean;
  // /**
  //  * What badge to display next to the card title. Provide a `<Badge>` element, a string (will create a default badge) or a fragment to contain multiple badges.
  //  */
  // badge?: React.ReactNode | string;
  // /**
  //  * Props from downshift, passed on to the list element.
  //  */
  // itemProps?: any;
  // active?: boolean;
  // selected?: boolean;
  // children?: any;
  // entry: T;
  // attributes?: string | Array<string | React.ReactNode>;
  // /**
  //  * Contextual, result-specific components to display in the card.
  //  */
  // contextual?: React.ReactNode;
};

export const SearchMenuResultItem: React.FC<Props> = props => {
  const Element = props.renderAs || 'li';

  // const attributes =
  //   typeof props.attributes === 'string' ? (
  //     <span className="ListResultCard-attribute">{props.attributes}</span>
  //   ) : (
  //     map(props.attributes, attribute =>
  //       typeof attribute === 'string' ? (
  //         <span className="ListResultCard-attribute">{attribute}</span>
  //       ) : (
  //         attribute
  //       )
  //     )
  //   );

  return (
    <Element
      id={props.id}
      role="button"
      className={cs(
        'SearchMenuResultItem',
        props.entry.__typename
          ? `SearchMenuResultItem--${props.entry.__typename}`
          : undefined,
        props.active ? 'active' : undefined,
        props.selected ? 'selected' : undefined,
        props.disabled ? 'disabled' : undefined
      )}
      {...props.itemProps}
    >
      <div
        className={cs(
          'SearchMenuResultItem-icon',
          props.iconColor ? `text-${props.iconColor}` : undefined
        )}
      >
        {props.icon ? <Icon icon={props.icon} /> : null}
      </div>
      <div className="SearchMenuResultItem-main">
        <div className="SearchMenuResultItem-title">
          {props.title ? props.title : null}
          {props.subTitle ? <span>{props.subTitle}</span> : null}
        </div>
        {props.summary ? (
          <div className="SearchMenuResultItem-summary">{props.summary}</div>
        ) : null}
      </div>
      {props.additionalInfo && props.additionalInfo}
    </Element>
  );
};
