import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';

import ListOrders from './ListOrders';
import OrderContent from './OrderContent';

const Orders: React.FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Route
      exact={true}
      path={`${match.url}/historikk`}
      component={ListOrders}
    />
    <Route path={`${match.url}/:id`} component={OrderContent} />
    <Route exact={true} path={`${match.url}`} component={ListOrders} />
  </Switch>
);

export default Orders;
