import { QosStatisticsPeriod } from '../../queries/WithStatisticsQoS';
import moment from 'moment';
import {
  WithStatisticsQoS_customer_subscriptions_statisticsQoS_periods_trafficClasses,
  WithStatisticsQoS_customer_subscriptions_statisticsQoS_trafficClasses,
} from '../../queries/__types/WithStatisticsQoS';

export const mapQosData = (
  data?: QosStatisticsPeriod[] | null,
  trafficClasses?:
    | WithStatisticsQoS_customer_subscriptions_statisticsQoS_trafficClasses[]
    | null
) => {
  if (!data || !trafficClasses) {
    return null;
  }

  return data.map(x => {
    let periodData: WithStatisticsQoS_customer_subscriptions_statisticsQoS_periods_trafficClasses[] = [];
    for (let trafficClass of trafficClasses) {
      const trafficClassData = x.trafficClasses?.find(
        tc => tc.name === trafficClass.name
      );
      periodData.push({
        __typename: 'TrafficClass',
        name: trafficClass.name,
        transmittedBytes: trafficClassData?.transmittedBytes ?? null,
        transmitRate: trafficClassData?.transmitRate ?? null,
        dropRate: trafficClassData?.dropRate ?? null,
        droppedBytes: trafficClassData?.droppedBytes ?? null,
      });
    }

    return {
      trafficClasses: periodData,
      periodStart: moment(x.periodStart).valueOf(),
      periodEnd: moment(x.periodEnd).valueOf(),
    };
  });
};
