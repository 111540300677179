import * as React from 'react';
import { FormSubmitBar } from 'src/lib/ui';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'src/lib/i18n';

interface Props {
  submitChanges: () => void;
  setOpen: (open: boolean) => void;
  open: boolean;
  contactName: string;
}

const RemoveAccessRolesModal = (props: Props) => {
  const i18n = t.contacts.editRoles;
  return (
    <Modal
      size="md"
      isOpen={props.open}
      toggle={() => props.setOpen(!props.open)}
    >
      <ModalHeader>{i18n.removeAllAccess}</ModalHeader>
      <ModalBody>
        <div>
          <p>{`${i18n.removeAllAccessConfirmation} ${props.contactName}?`}</p>
        </div>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          cancel={() => props.setOpen(false)}
          submitLabel={i18n.confirm}
          submit={props.submitChanges}
        />
      </ModalFooter>
    </Modal>
  );
};
export default RemoveAccessRolesModal;
