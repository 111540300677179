import * as React from 'react';
import { isBefore } from 'src/lib/utils/dateUtils';
import { Attribute } from 'src/lib/ui';
import OrderingSection from '../layout/OrderingSection';
import {
  Step,
  StepShape,
  FormikRadioGroup,
  FormikSelectField,
  FormikTextField,
  FormikDatePicker,
} from 'src/lib/flow';

import {
  MobileOrderConsumer,
  SimcardTypes,
  PhoneNumberTypes,
  ActivationDateType,
} from './OrderMobileSubscription';
import { t } from 'src/lib/i18n';

import * as yup from 'yup';
import {
  yupValidatePostalCode,
  yupValidateDateIsAfter,
} from 'src/lib/utils/yupValidations';

export type Step5Values = {
  simcardSource: SimcardTypes;
  simcardNumber?: string;
  activationDateType?: ActivationDateType;
  activationDate?: string; // date
  newSimCardRecipient?: {
    name: string;
    address: string;
    postalCode: string;
    postalArea: string;
    attention: string;
  };
} & {
  // We need to access this value from Step1
  phoneNumberSource: PhoneNumberTypes;
};

/**
 * Bergen is allowed access to ordering before 11. november, but we do not want them to order activation before that date.
 * TODO: Remove when this restriction is over.
 */
const temporaryDateRestiction = '2019-11-10';

const Step5: Step<Step5Values> = (): StepShape<Step5Values> => {
  const i18n = t.ordering.OrderMobileSubscription.Step5;
  const simcardOptions = [
    { value: 'existingSimcard', label: i18n.simcardSource.opts.existing },
    { value: 'newSimcard', label: i18n.simcardSource.opts.new },
  ];

  const activationDateTypeOptions = [
    { value: 'default', label: i18n.activationDateType.default },
    { value: 'custom', label: i18n.activationDateType.custom },
  ];
  return {
    title: i18n.header,
    validationSchema: yup.object().shape({
      simcardSource: yup.string().required(),
      simcardNumber: yup.string().when('simcardSource', {
        is: 'existingSimcard',
        then: yup.string().required(i18n.simcardNumber.error),
      }),
      newSimCardRecipient: yup.object().when('simcardSource', {
        is: 'newSimcard',
        then: yup.object().shape({
          name: yup.string().required(i18n.recipient.error),
          address: yup.string().required(i18n.address.error),
          postalCode: yupValidatePostalCode(i18n.postalCode.error),
          postalArea: yup.string().required(i18n.postalArea.error),
          attention: yup.string(),
        }),
      }),
      activationDateType: yup.string().required(),
      activationDate: yup
        .string()
        .when(['phoneNumberSource', 'activationDateType'], {
          is: (phoneNumberSource, activationDateType) =>
            phoneNumberSource === 'existingNumber' &&
            activationDateType === 'custom',
          // TODO: Use yupValidateAndIsAfterTomorrow after 11. november 2019.
          // then: yupValidateDateAndIsAfterTomorrow(),
          then: yupValidateDateIsAfter(temporaryDateRestiction),
        }),
    }),
    renderSummary: ({ values }) => (
      <>
        {values.simcardSource === 'existingSimcard' ? (
          <>
            <Attribute label={i18n.activateSim} value={values.simcardNumber} />
          </>
        ) : (
          <>
            {values.newSimCardRecipient && (
              <>
                <Attribute
                  label={i18n.recipient.label}
                  value={values.newSimCardRecipient.name}
                />
                <Attribute
                  label={i18n.address.label}
                  value={values.newSimCardRecipient.address}
                />
                <Attribute
                  label={i18n.postalCode.label}
                  value={values.newSimCardRecipient.postalCode}
                />
                <Attribute
                  label={i18n.postalArea.label}
                  value={values.newSimCardRecipient.postalArea}
                />
                <Attribute
                  label={i18n.attention.label}
                  value={values.newSimCardRecipient.attention}
                />
              </>
            )}
          </>
        )}
        {values.phoneNumberSource === 'existingNumber' ? (
          <Attribute
            label={i18n.activationDate.label}
            value={
              values.activationDateType === 'custom'
                ? values.activationDate
                : i18n.activationDateType.default
            }
          />
        ) : null}
      </>
    ),
    renderForm: ({ flow }) => (
      <>
        <MobileOrderConsumer>
          {mobileContext => {
            const availableMobileNumbers = mobileContext.data.availableSimCards
              ? mobileContext.data.availableSimCards.map(sim => ({
                  value: sim.simCardNumber,
                  label: sim.simCardNumber,
                }))
              : [];

            return (
              <>
                <OrderingSection>
                  <FormikRadioGroup
                    id="simcardSource"
                    block={true}
                    options={simcardOptions}
                  />
                  {flow.values.simcardSource === 'existingSimcard' ? (
                    <FormikSelectField
                      className="pt-4"
                      id="simcardNumber"
                      placeholder={i18n.simcardNumber.placeholder}
                      options={availableMobileNumbers}
                      fastField={true}
                      virtualized={true}
                    />
                  ) : (
                    <div className="pt-4">
                      <h4>{i18n.recipientHeader}</h4>
                      <div className="row pb-4">
                        <FormikTextField
                          id="newSimCardRecipient.name"
                          label={i18n.recipient.label}
                          required={true}
                          className="col-md-6"
                        />
                        <FormikTextField
                          id="newSimCardRecipient.address"
                          label={i18n.address.label}
                          required={true}
                          className="col-md-6"
                        />
                      </div>

                      <div className="row pb-4">
                        <FormikTextField
                          id="newSimCardRecipient.postalCode"
                          label={i18n.postalCode.label}
                          required={true}
                          className="col-md-6"
                        />
                        <FormikTextField
                          id="newSimCardRecipient.postalArea"
                          label={i18n.postalArea.label}
                          required={true}
                          className="col-md-6"
                        />
                      </div>
                      <div className="row">
                        <FormikTextField
                          id="newSimCardRecipient.attention"
                          label={i18n.attention.label}
                          className="col-md-6"
                        />
                      </div>
                    </div>
                  )}
                </OrderingSection>
                {flow.values.phoneNumberSource === 'existingNumber' && (
                  <OrderingSection header={i18n.activationDateHeader}>
                    <p>
                      {flow.values.simcardSource === 'newSimcard'
                        ? i18n.activationDateInfo.newSim
                        : i18n.activationDateInfo.exixtingSim}
                    </p>
                    <FormikRadioGroup
                      id="activationDateType"
                      block={true}
                      options={activationDateTypeOptions}
                    />
                    {flow.values.activationDateType === 'custom' && (
                      <FormikDatePicker
                        className="pt-4"
                        id="activationDate"
                        showPicker={true}
                        label={i18n.activationDate.label}
                        required={true}
                        // TODO: Remove startDate when Bergen kommune has started using ordering (11. november 2019)
                        isOutsideRange={isBefore(0, temporaryDateRestiction)}
                      />
                    )}
                  </OrderingSection>
                )}
              </>
            );
          }}
        </MobileOrderConsumer>
      </>
    ),
  };
};

export default Step5;
