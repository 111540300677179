import * as React from 'react';
import { AppContextConsumer } from 'src/lib/global';
import { some, every, castArray } from 'lodash';
import { RoleName } from 'src/areas/main/contacts/lib/createContact/types';
import { RoleTemplate } from 'src/__types/graphql-global-types';

type AllRolesProps = { all: RoleName[] };
type AnyRoleProps = { role: RoleName };
type SingleRoleProps = { any: RoleName[] };

type SharedProps = {
  orWhenCustomerSearchIsAllowed?: boolean;
};

type Props = SharedProps & (AllRolesProps | AnyRoleProps | SingleRoleProps);

export const RequireRole: React.FC<Props> = props => (
  <AppContextConsumer>
    {appContext => {
      // Temporary check; if orWhenCustomerSearchIsAllowed is true and the user
      // has the `allowCustomerSearch` property set, we'll display the children.
      if (
        props.orWhenCustomerSearchIsAllowed === true &&
        appContext.access.allowCustomerSearch
      ) {
        return props.children;
      }

      if ('all' in props) {
        if (
          !every(props.all, roleToTest =>
            appContext.customerRoles.includes(roleToTest as RoleTemplate)
          )
        ) {
          return null;
        }
      } else {
        const rolesToTest = 'role' in props ? castArray(props.role) : props.any;
        if (
          !some(rolesToTest, roleToTest =>
            appContext.customerRoles.includes(roleToTest as RoleTemplate)
          )
        ) {
          return null;
        }
      }

      return props.children;
    }}
  </AppContextConsumer>
);
