import { PeriodFragment } from './../utils/PeriodFragment';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import * as t from './__types/useOverviewData';

export const QUERY_OVERVIEW_DATA = gql`
  query useOverviewData($subscriptionId: ID!, $dontFetchPinPuk: Boolean!) {
    subscription(id: $subscriptionId) {
      id
      customer {
        id
        name
      }
      steppedPriceModel {
        usageUpTo
        cost
      }
      user {
        firstName
        lastName
        position
        employeeNum
        email
        flexField1
        flexField2
        flexField3
        flexField4
      }
      origin {
        firstName
        middleName
        lastName
        streetAddress
        streetNum
        apartmentNum
        floor
        gnr
        fnr
        knr
        postCode
        postArea
        country
      }
      current {
        ...PeriodFragment
      }
      simCards {
        simCardNumber
        operator
        msisdn
        initialPin @skip(if: $dontFetchPinPuk)
        puk1 @skip(if: $dontFetchPinPuk)
        puk2 @skip(if: $dontFetchPinPuk)
        isMainSimCard
        type
        ossi
      }
    }
  }
  ${PeriodFragment}
`;

export const useOverviewData = (dontFetchPinPuk?: boolean) => {
  const subscription = useSubscriptionContext();
  return useQuery<t.useOverviewData, t.useOverviewDataVariables>(
    QUERY_OVERVIEW_DATA,
    {
      variables: {
        subscriptionId: subscription.id,
        dontFetchPinPuk: !!dontFetchPinPuk,
      },
      errorPolicy: 'all',
      skip: !subscription.id,
    }
  );
};
