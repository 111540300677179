import { useState } from 'react';
import { usePingRoundTripTimeMultipleSubs } from '../../queries/usePingRoundTrip';
import { usePingStatus } from '../../queries/usePingStatus';
import { useDelayedRender } from 'src/lib/utils';

export const useRefetchStatusInformation = (
  subscriptionId: string,
  redundanciesIdList?: null | Array<{ id: string }>
) => {
  const [isRefetching, setIsRefetching] = useState(false);
  const { ping, uptime, loading } = usePingStatus({
    subscriptionId,
    redundanciesIdList,
  });
  const delay = useDelayedRender(200, loading || isRefetching);

  const roundTripTimePing = usePingRoundTripTimeMultipleSubs([
    { id: subscriptionId },
    ...(redundanciesIdList ?? []),
  ]);

  const refetch = async () => {
    setIsRefetching(true);
    await Promise.all([ping(), roundTripTimePing.pingAll()]);
    setIsRefetching(false);
  };

  return {
    uptime,
    loading: delay,
    refetch,
    roundTripTimeResponses: roundTripTimePing.roundTripTimes,
  };
};
