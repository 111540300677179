import { Definition } from './types';
import { formatColumns, MobileColumns } from './index';
import { getSortOptions, getSortOption } from '../utils/getSortOptionsByKey';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getMobileColumnDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const mobileColumnMap: {
    [key in MobileColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    name: {
      label: t.subscriptions.GenericSubscriptionTable.service,
      sortOptions: getSortOptions(['alias', 'type', 'subscriptionId']),
      fixed: true,
    },
    ownerName: {
      label: t.subscriptions.MobileSubscriptionTable.name,
      sortOptions: getSortOption('ownerName', true),
    },
    phoneNumber: {
      label: t.subscriptions.MobileSubscriptionTable.phoneNumber,
      sortOptions: getSortOption('phoneNumber', true),
      cellWidth: 200,
    },

    deliveryStatus: {
      label: t.subscriptions.TeleSubscriptionTable.deliveryStatus,
      cellWidth: 200,
    },
    invoiceCenter: {
      label: t.subscriptions.GenericSubscriptionTable.invoiceCenter,
    },
    costCenter: {
      label: t.subscriptions.GenericSubscriptionTable.costCenter,
    },
    tags: {
      label: t.subscriptions.GenericSubscriptionTable.tags,
    },
    address: {
      label: t.subscriptions.GenericSubscriptionTable.address,
    },
  };

  const defaultMobileColumns: Array<MobileColumns> = [
    'name',
    'ownerName',
    'phoneNumber',
    'invoiceCenter',
    'tags',
    'address',
  ];

  return formatColumns({
    map: mobileColumnMap,
    defaultColumns: defaultMobileColumns,
    savedColumnPreferences,
  });
};
