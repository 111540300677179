import * as React from 'react';
import { Query, useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { WithCustomerContext } from 'src/lib/global';
import { ApolloError } from 'apollo-client';
import { DeliveryStatus } from 'src/lib/types';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/WithStatisticsRealtime';

export const WITH_STATISTICS_REALTIME = gql`
  query WithStatisticsRealtime(
    $customerId: ID!
    $subscriptionId: ID!
    $interface: String
  ) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        name
        type
        deliveryStatus
        group
        parent {
          id
        }
        subscriptionId
        startTime
        endTime
        lastModified
        interfaces(id: $interface) {
          name
          type
          status
          statistics {
            cumulativeIn
            cumulativeOut
            uptimeSeconds
            speed
          }
        }
      }
    }
  }
`;

export const useLiveStatistics = (inputProps: {
  subscriptionId: string;
  interface?: string | null;
}) => {
  const customer = useCustomer();
  /**
   * Since we cannot conditonally render a hook, we rather skip the query if some params are missing
   */
  const skipQuery = !inputProps.interface;

  const res = useQuery<
    t.WithStatisticsRealtime,
    t.WithStatisticsRealtimeVariables
  >(WITH_STATISTICS_REALTIME, {
    variables: {
      customerId: customer.id,
      subscriptionId: inputProps.subscriptionId,
      interface: inputProps.interface,
    },
    skip: skipQuery,
    pollInterval: 3000,
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });

  return {
    loading: res.loading,
    error: res.error,
    data:
      res.data?.customer?.subscriptions?.[0]?.interfaces?.[0]?.statistics ??
      null,
  };
};

export interface Subscription {
  id: string;
  subscriptionId?: string;
  name?: string;
  type?: string;
  deliveryStatus?: DeliveryStatus;
  group?: string;
  parent?: {
    id: string;
  };
  topSubscriptionId?: string;
  startTime?: string;
  endTime?: string;
  lastModified?: string;
  interfaces?: Interface[];
}

export interface Interface {
  name: string;
  type: string;
  status: string;
  statistics?: Statistics;
}

export interface Statistics {
  /**
   * bytes
   */
  cumulativeIn: string;
  /**
   * bytes
   */
  cumulativeOut: string;
  uptimeSeconds: string;
  speed: string;
}

interface Data {
  customer?: {
    subscriptions?: Subscription[];
  };
}

interface Variables {
  subscriptionId: string;
  customerId: string;
  interface: string;
}

export interface SubscriptionData {
  loading: boolean;
  error: ApolloError | null;
  subscription: Subscription | undefined;
}

interface Props {
  subscriptionId: string;
  interface: string;
  children: (data: SubscriptionData) => JSX.Element | null;
}

const WithStatisticsRealtime: React.SFC<Props> = (props: Props) => {
  return (
    <WithCustomerContext>
      {customer => (
        <Query<Data, Variables>
          query={WITH_STATISTICS_REALTIME}
          variables={{
            customerId: customer.id,
            subscriptionId: props.subscriptionId,
            interface: props.interface,
          }}
          pollInterval={3000}
          errorPolicy="all"
          fetchPolicy="network-only"
        >
          {result => {
            const data = {
              loading: result.loading,
              error: result.error ? result.error : null,
              subscription:
                result.data &&
                result.data.customer &&
                result.data.customer.subscriptions
                  ? result.data.customer.subscriptions[0]
                  : undefined,
            };
            return props.children(data);
          }}
        </Query>
      )}
    </WithCustomerContext>
  );
};

export default WithStatisticsRealtime;
