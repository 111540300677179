import React, { useState, useEffect } from 'react';
import { Button } from 'src/lib/ui';
import { MessageActivity, CommonActivityProps } from 'src/lib/chat/types';
import './EmailForm.scss';
import { formatDate } from 'src/lib/utils';
import { t, useI18nContext } from 'src/lib/i18n';

const generateEmailHref = (
  recipients: string[],
  subject: string,
  body: string,
  messages: Array<MessageActivity & CommonActivityProps>,
  locale: 'en' | 'nb',
  link?: string
) => {
  let href = 'mailto:';
  const indent = '%20%20%20%20';
  const newLine = '%0A';
  recipients.forEach(
    (r, i) => (href = href.concat(r, i === recipients.length - 1 ? '' : ','))
  );
  href = href.concat(`?subject=${subject.replace(/ /g, '%20')}`);
  href = href.concat(
    `&body=${
      body
        ? body.replace(/\n/g, '%0A').replace(/ /g, '%20') + newLine + newLine
        : ''
    }`
  );

  if (link) {
    href = href.concat(link, '%0A%0A');
  }

  messages.forEach(m => {
    const formattedMessage = m.message.replace(/\r?\n/g, newLine + indent);

    href = href.concat(
      `${indent}${m.contact.name} ${formatDate(
        m.timestamp,
        'mediumWithTime',
        locale
      )}${newLine}${indent}${formattedMessage}${newLine}${newLine}`
    );
  });
  return href;
};

interface Props {
  messages: Array<MessageActivity & CommonActivityProps>;
  setMessages: (messgaes: Array<MessageActivity & CommonActivityProps>) => void;
  subject: string;
  setSubject?: (subject: string) => void;
}

// const schema = yup.object().shape({
//   email: yupValidateEmail(''),
// });

export const EmailForm = (props: Props) => {
  // const [isOpen, setIsOpen] = useState<boolean>(false);
  const [recipients /*,setRecipients*/] = useState<Array<string>>([]);
  // const [currentRecipient, setCurrentRecipient] = useState<string>('');
  const [body /*,setBody*/] = useState<string>('');
  // const [invalidEmail, setInvalidEmail] = useState(false);
  const [includeLink /* , setIncludeLink */] = useState(true);
  const [href, setHref] = useState('');
  const i18n = t.worklogOrder.chat.sendEmail;
  const { locale } = useI18nContext();

  useEffect(
    () =>
      setHref(
        generateEmailHref(
          recipients,
          props.subject,
          body,
          props.messages,
          locale,
          includeLink ? window.location.href : undefined
        )
      ),
    [props.messages, recipients, props.subject, body, includeLink] // eslint-disable-line react-hooks/exhaustive-deps
  );

  // const handleRecipientChange = (e: React.ChangeEvent<HTMLInputElement>) =>
  //   setCurrentRecipient(e.target.value);

  // const handleSubsjectChange = (e: React.ChangeEvent<HTMLInputElement>) =>
  //   props.setSubject?.(e.target.value);

  // const handleBodyChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
  //   setBody(e.target.value);

  // const addRecipient = (email: string) => {
  //   if (email) {
  //     schema
  //       .validate({ email })
  //       .then(r => {
  //         setInvalidEmail(false);
  //         setRecipients([...recipients, email]);
  //         setCurrentRecipient('');
  //       })
  //       .catch(e => setInvalidEmail(true));
  //   }
  // };

  // const removeMessage = (id: string) =>
  //   props.setMessages(props.messages.filter(m => m.id !== id) ?? []);

  // const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
  //   setInvalidEmail(false);

  //   // 13 = ENTER, 9 = TAB
  //   if (e.keyCode === 13 || e.keyCode === 9) {
  //     e.preventDefault();
  //     addRecipient(currentRecipient.replace(',', '').trim());
  //   }

  //   // 32 = space
  //   if (
  //     e.keyCode === 32 &&
  //     currentRecipient.slice(currentRecipient.length - 1) === ','
  //   ) {
  //     addRecipient(currentRecipient.replace(',', '').trim());
  //   }
  // };

  return (
    <div className="EmailForm ml-auto">
      <a href={href}>
        <Button
          // className="ml-auto"
          icon="email"
          color="white"
          size="small"
          type="button"
          // onClick={() => setIsOpen(!isOpen)}
        >
          {i18n.emailSelected}
        </Button>
      </a>

      {/* <Modal isOpen={isOpen} size="lg">
        <ModalHeader> {i18n.writeAnEmail}</ModalHeader>

        <ModalBody className="EmailForm">
          <TextField
            className="w-100 pb-3"
            label={i18n.recipients}
            value={currentRecipient}
            onChange={handleRecipientChange}
            onKeyDown={handleKeyDown}
            onBlur={e => addRecipient(currentRecipient.replace(',', '').trim())}
            error={invalidEmail ? 'Feil' : undefined}
          />
          <div className="pb-3">
            {recipients.map((r, i) => (
              <Chip
                key={i}
                label={r}
                active={true}
                type="secondary"
                mode="select"
                onClick={() =>
                  setRecipients(recipients.filter(rec => rec !== r))
                }
              />
            ))}
          </div>

          <TextField
            className="w-100 pb-3"
            label={i18n.subject}
            value={props.subject}
            onChange={handleSubsjectChange}
          />
          <div className="EmailForm-TextArea">
            <TextArea
              placeholder={i18n.writeSomethingHere}
              value={body}
              onChange={handleBodyChange}
            />
            <div
              className="p-3 d-flex align-items-center"
              style={{ color: includeLink ? colors.black : colors.greyDark }}
            >
              {window.location.href}
              <Checkbox
                className="ml-auto"
                checked={includeLink}
                onChange={e => setIncludeLink(!includeLink)}
              />
            </div>

            <div>
              {props.messages?.map(m => (
                <div
                  key={m.id}
                  className="EmailForm-Message p-3 d-flex align-items-center"
                >
                  <div className="EmailForm-Message-content">
                    <div className="EmailForm-Message-content-header pb-3">
                      {m.contact.name} {formatDate(m.timestamp, 'long', 'en')}
                    </div>
                    {m.message}
                  </div>
                  <div>
                    <Button icon="close" onClick={() => removeMessage(m.id)}>
                      {i18n.remove}
                    </Button>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button onClick={() => setIsOpen(false)}>{i18n.cancel}</Button>
          <a href={href}> {i18n.send} </a>
        </ModalFooter>
      </Modal> */}
    </div>
  );
};
