import React, { useState, useEffect } from 'react';
import { Modal } from 'reactstrap';
import { ItemListPickerBody } from './ItemListPickerBody';

import './ItemListPicker.scss';

export type ItemListPickerProps = {
  /**
   * An array of the items initially selected. If the availableItems does not contain
   * the initially selected items, the behaviour is undefined.
   */
  initialSelectedItems?: Item[];

  /**
   * The heading shown at the top of the modal.
   */
  heading: string;

  /**
   * The subheader with description shown at the top of the modal.
   */
  subHeader?: string;

  /**
   * All items available for selection.
   */
  availableItems: Item[];

  /**
   * SubHeader for available Items.
   */

  availableItemsSubHeader?: string;

  /**
   * The text to display in place of the items when no items are available (i.e. all of them are selected or there are no items at all).
   */
  availableItemsPlaceholder?: string;

  /**
   * SubHeader for selected Items.
   */

  selectedItemsSubHeader?: string;

  /**
   * The text to display in place of the items when no items are selected.
   */
  selectedItemsPlaceholder?: string;

  /**
   * Whether the modal should show a loading indicator. If this is true, the modal
   * will expect availableItems and/or initialSelectedItems to change.
   */
  loading?: boolean;

  /**
   * Indicator for submit loading; i.e. the modal is loading after submit has been pressed.
   */
  submitLoading?: boolean;

  /**
   * The placeholder text to display in the filter text field.
   */
  filterPlaceholder?: string;

  /**
   * Whether to close the modal when saving/submitting.
   */
  toggleOnSave?: boolean;

  /**
   * Whether only a single item can be selected at a time.
   */
  singleSelect?: boolean;

  /**
   * Whether to disable the 'Add new' button
   */
  disableAddNew?: boolean;

  /**
   * This is called when the user clicks submit/save.
   */
  onSave: (selectedItems: Item[]) => void;

  /**
   * Whether the modal is open.
   */
  isOpen?: boolean;

  /**
   * Toggle the modal open state.
   */
  toggleState: () => void;
} & ({ setQuery: (query: string) => void; query: string } | {});

export interface Item {
  /**
   * The ID of the item. An ID of `undefined` indicates a new/unsaved Item.
   */
  id?: string;

  label: string;
}

export const ItemListPicker = (props: ItemListPickerProps) => {
  const [selectedItems, setSelectedItems] = useState(
    props.initialSelectedItems ?? []
  );
  const [query, setQuery] = useState('');

  useEffect(() => {
    setQuery('');
    setSelectedItems(props.initialSelectedItems || []);
  }, [props.isOpen, props.initialSelectedItems]);

  const handleSave = () => {
    props.onSave(selectedItems || []);
    if (props.toggleOnSave) {
      props.toggleState();
    }
  };

  const onInputChange = (filter: string) => setQuery(filter);

  const select = (item: Item) => {
    // Clear search field input if we just added a new element
    if (item.id === undefined) {
      setQuery('');
    }

    if (props.singleSelect) {
      return setSelectedItems([item]);
    } else {
      setSelectedItems([...selectedItems, item]);
    }
  };

  const unselect = (item: Item) =>
    setSelectedItems(
      item.id === undefined
        ? selectedItems.filter(i => i.label !== item.label)
        : selectedItems.filter(i => i.id !== item.id)
    );

  return (
    <Modal
      className="ItemListPicker"
      size="lg"
      isOpen={props.isOpen}
      toggle={props.toggleState}
    >
      <ItemListPickerBody
        {...props}
        selectedItems={selectedItems}
        subHeader={props.subHeader}
        query={'query' in props ? props.query : query}
        select={select}
        unselect={unselect}
        availableItemsSubHeader={props.availableItemsSubHeader}
        selectedItemsSubHeader={props.selectedItemsSubHeader}
        onInputChange={'setQuery' in props ? props.setQuery : onInputChange}
        toggleState={props.toggleState}
        handleSave={handleSave}
      />
    </Modal>
  );
};
