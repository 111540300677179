import React from 'react';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import { Icon } from '@telia/styleguide';
import {
  colors,
  FilterToggles,
  FilterSelect,
  OptionSelectProps,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
export const FilterSectionFilter = (
  props: OptionSelectProps & {
    onClick?: () => void;
    renderFilterToggles?: boolean;
  }
) => {
  const breakpoint = useBreakpoint('md');
  const label = breakpoint
    ? props.label.concat(
        props.allowMultiple && props.selectedOptions?.length
          ? ' (' + props.selectedOptions.length + ')'
          : ''
      )
    : props.label;
  return (
    <div className="FilterSectionFilter">
      {breakpoint ? (
        props.renderFilterToggles ? (
          <FilterToggles
            {...props}
            currentFilter={props.selectedOptions}
            setCurrentFilter={props.handleSave}
          />
        ) : (
          <FilterSelect
            {...props}
            alignRight={props.alignRight}
            label={label}
            blackBorderOnSelect={true}
          />
        )
      ) : (
        <MobileFilter {...props} label={label} />
      )}
    </div>
  );
};

const MobileFilter = (
  props: OptionSelectProps & {
    onClick?: () => void;
  }
) => {
  const i18n = t.ui.FilterSection;

  return (
    <div onClick={props.onClick} className="d-flex border-bottom px-3 py-2">
      <div>{props.label}</div>
      <div className="d-flex align-items-center ml-auto">
        {props.selectedOptions?.length ? (
          <small className="pr-2" style={{ color: colors.greyDarkText }}>
            {props.selectedOptions?.length < 3 ? (
              props.selectedOptions.map(
                (selectedOption, i) =>
                  (i === 1 ? ', ' : '') +
                  props.options.find(option => option.value === selectedOption)
                    ?.label
              )
            ) : (
              <div>
                {props.selectedOptions.length} {i18n.selectedFilters}
              </div>
            )}
          </small>
        ) : null}
        <Icon icon="arrow-right" style={{ height: '1rem', width: '1rem' }} />
      </div>
    </div>
  );
};
