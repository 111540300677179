import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as t from 'src/areas/mobile/settings/mutations/__types/useSetCallerIdConfiguration';
import { QUERY_ALLOWED_CALLER_IDS_FOR_SUBSCRIPTION } from 'src/areas/main/subscriptions/queries/useAllowedCallerIdsForSubscription';

type CallerIdConfiguration = {
  subscriptionId: string;
  allowedCallerIdList: string[];
};

export const SET_CALLER_ID_CONFIGURATION = gql`
  mutation useSetCallerIdConfiguration(
    $customerId: ID!
    $callerIdConfiguration: CallerIdConfigurationInput!
  ) {
    setCallerIdConfiguration(
      input: {
        customerId: $customerId
        callerIdConfiguration: $callerIdConfiguration
      }
    ) {
      callerIdConfiguration {
        allowedCallerIdList
      }
      error {
        code
        message
      }
      __typename
    }
  }
`;

export const useSetCallerIdConfiguration = () => {
  const [mutation, { data, loading, error }] = useMutation<
    t.useSetCallerIdConfiguration,
    t.useSetCallerIdConfigurationVariables
  >(SET_CALLER_ID_CONFIGURATION);
  const setCallerIdConfiguration = async (
    customerId: string,
    callerIdConfiguration: CallerIdConfiguration
  ) => {
    await mutation({
      variables: {
        customerId: customerId,
        callerIdConfiguration: callerIdConfiguration,
      },
      refetchQueries: [
        {
          query: QUERY_ALLOWED_CALLER_IDS_FOR_SUBSCRIPTION,

          variables: {
            msisdn: callerIdConfiguration.subscriptionId,
          },
        },
      ],
    });
  };
  return {
    setCallerIdConfiguration,
    allCallerIds:
      data?.setCallerIdConfiguration?.callerIdConfiguration
        ?.allowedCallerIdList,
    error,
    loading,
  };
};
