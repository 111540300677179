import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { Order } from 'src/lib/types';
import { mapOrderStatustoIcon } from 'src/areas/main/orders/utils';

export const SearchMenuOrderResult: React.FC<SearchMenuResultProps<
  Order
>> = props => (
  <SearchMenuResultItem
    {...props}
    icon={mapOrderStatustoIcon(props.entry.portalStatus)}
    title={props.entry.displayId}
    summary={props.entry.description}

    /** WE CURRENTLY DO NOT HAVE STATUS ON ORDERS. */

    // summary={
    //   props.entry.orderStatus
    //     ? `${mapOrderStatusToLabel(props.entry.orderStatus)}`
    //     : ''
    // }
  />
);
