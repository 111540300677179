import React, { useMemo } from 'react';
import { Loading, colors } from 'src/lib/ui';
import * as yup from 'yup';
import { Formik } from 'formik';
import {
  FormikTextField,
  FormikRadioGroup,
  FormikSelectField,
} from 'src/lib/flow';
import { SimOptions, useExtraSimCardOrder } from './useExtraSimCardOrder';
import { t } from 'src/lib/i18n';
import { OrderingComponentShape } from '../types';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { useAvailableSimCards } from 'src/areas/mobile/overview/useAvailableSimCards';

const simOptions = (): Array<{
  value: SimOptions;
  label: string;
  description: string;
}> => [
  {
    value: 'voice',
    label: t.ordering.OrderExtraSim.simType.opts.voice.label,
    description: t.ordering.OrderExtraSim.simType.opts.voice.description,
  },
  {
    value: 'data',
    label: t.ordering.OrderExtraSim.simType.opts.data.label,
    description: t.ordering.OrderExtraSim.simType.opts.data.description,
  },
];

interface OrderExtraSimProps {
  msisdn: string;
  initialValues?: Values;
  disableTypeSelect: boolean;
  children: (props: OrderingComponentShape) => React.ReactNode;
}

interface Values {
  simState: 'new' | 'existing';
  extraSimType: 'voice' | 'data';
  name: string;
  address: string;
  postalArea: string;
  postalCode: string;
  attention: string;
  simCardId: string;
}

const OrderExtraSim: React.FunctionComponent<OrderExtraSimProps> = props => {
  const extraSimMutation = useExtraSimCardOrder();
  const subscription = useSubscriptionContext();
  const availableSimCardsResult = useAvailableSimCards(subscription.customerId);
  const i18n = t.ordering.OrderExtraSim;

  const initialValues: Values = {
    simState: props.initialValues?.simState || 'existing',
    simCardId: props.initialValues?.simCardId || '',
    extraSimType: props.initialValues?.extraSimType || 'voice',
    name: props.initialValues?.name || '',
    address: props.initialValues?.address || '',
    postalArea: props.initialValues?.postalArea || '',
    postalCode: props.initialValues?.postalCode || '',
    attention: props.initialValues?.attention || '',
  };

  const availableSimCards = useMemo(
    () =>
      availableSimCardsResult.data
        ? availableSimCardsResult.data.map(sim => ({
            label: sim.simCardNumber,
            value: sim.simCardNumber,
          }))
        : [],
    [availableSimCardsResult.data]
  );
  return (
    <Formik<Values>
      initialValues={initialValues}
      enableReinitialize={true}
      validationSchema={yup.object().shape({
        extraSimType: yup.string().required(i18n.simType.error),
        simCardId: yup.string().when('simState', {
          is: simState => simState === 'existing',
          then: yup.string().required(t.ordering.required),
        }),
        simState: yup.string(),
        name: yup.string().when('simState', {
          is: simState => simState === 'new',
          then: yup.string().required(t.ordering.required),
        }),
        address: yup.string().when('simState', {
          is: simState => simState === 'new',
          then: yup.string().required(t.ordering.required),
        }),
        postalCode: yup.string().when('simState', {
          is: simState => simState === 'new',
          then: yup.string().required(t.ordering.required),
        }),
        postalArea: yup.string().when('simState', {
          is: simState => simState === 'new',
          then: yup.string().required(t.ordering.required),
        }),
      })}
      onSubmit={(values, actions) => {
        extraSimMutation.extraSimCardOrder({
          msisdn: props.msisdn,
          extraSimType: values.extraSimType,
          simCardId:
            values.simState === 'existing' ? values.simCardId : undefined,
          newSimCardRecipient:
            values.simState === 'new'
              ? {
                  name: values.name,
                  address: values.address,
                  postalArea: values.postalArea,
                  postalCode: values.postalCode,
                  attention: values.attention,
                }
              : undefined,
        });
      }}
    >
      {formikProps => {
        let form;

        if (extraSimMutation.loading) {
          form = <Loading />;
        } else if (
          !extraSimMutation.loading &&
          extraSimMutation.data &&
          !extraSimMutation.error
        ) {
          form = <div>{i18n.orderSent}</div>;
        } else {
          form = (
            <form className="d-flex flex-column">
              <small style={{ color: colors.greyDarkText }}>
                {t.mobile.overview.sim.addSimToSubscription}
              </small>

              <div className="my-3">{t.mobile.overview.sim.chooseType}</div>
              <FormikRadioGroup
                className="mb-3"
                id="extraSimType"
                options={simOptions()}
                block={true}
                disabled={props.disableTypeSelect}
              />
              <div className="my-3">
                {t.mobile.overview.sim.activateOrOrderNewQuestion}
              </div>
              <FormikRadioGroup
                block={true}
                id="simState"
                className="mb-3"
                options={[
                  {
                    value: 'existing',
                    label: t.mobile.overview.sim.existingSim,
                  },
                  {
                    value: 'new',
                    label: t.mobile.overview.sim.newSim,
                  },
                ]}
              />

              {formikProps.values.simState === 'new' ? (
                <>
                  <div className="mt-3">
                    {t.mobile.overview.sim.deliveryInformation}
                  </div>
                  <FormikTextField
                    id="name"
                    label={i18n.name.label}
                    required={true}
                    className="mt-2"
                  />
                  <FormikTextField
                    id="address"
                    label={i18n.address.label}
                    required={true}
                    className="mt-2"
                  />
                  <div className="mt-2">
                    <FormikTextField
                      id="postalArea"
                      label={i18n.postalArea.label}
                      required={true}
                      className=" pr-2 w-25"
                    />
                    <FormikTextField
                      id="postalCode"
                      label={i18n.postalCode.label}
                      required={true}
                      className=" w-75"
                    />
                  </div>
                  <FormikTextField
                    id="attention"
                    label={i18n.att.label}
                    className="mt-2"
                  />

                  <div className="mt-3 pt-4">{i18n.deliveryDescription}</div>
                </>
              ) : (
                <>
                  <FormikSelectField
                    id="simCardId"
                    options={availableSimCards}
                  />
                  <div className="mt-3 pt-4">{i18n.activationTime}</div>
                </>
              )}
            </form>
          );
        }

        return props.children({
          header: i18n.header,
          render: form,
          isFormValid: formikProps.isValid,
          submit: formikProps.submitForm,
          submitLabel: i18n.submitBtn,
        });
      }}
    </Formik>
  );
};
export default OrderExtraSim;
