import { useQuery } from 'react-apollo';
import * as types from '../../dev/changelog/query/__types/WithChangelogs';
import { GET_CHANGELOGS } from 'src/areas/dev/changelog/query/WithChangelogs';

const defaultChangelogDropDownQueryPageNumber = 1;
const defaultChangelogDropDownQueryPageSize = 4;

export const useQueryUserDropdownChangelogs = () => {
  return useQuery<types.WithChangelogs, types.WithChangelogsVariables>(
    GET_CHANGELOGS,
    {
      variables: {
        input: {
          page: defaultChangelogDropDownQueryPageNumber,
          size: defaultChangelogDropDownQueryPageSize,
          from: '2018-12-01 00:00:00',
          includeUnpublished: false,
        },
      },
    }
  );
};
