import * as React from 'react';

import { RouteComponentProps } from 'react-router';
import './ServicePlusCard.scss';
import { Loading, SearchListPicker } from 'src/lib/ui';

import { PageHeader } from '../common/PageHeader/PageHeader';
import { t } from 'src/lib/i18n';
import { AddressCard } from 'src/areas/main/servicePlus/AddressCard';
import { NewAddressCard } from 'src/areas/main/servicePlus/NewAddressCard';
import { useServicePlus } from './queries/useServicePlusAddresses';
import { useCustomer } from 'src/lib/global';

type Props = RouteComponentProps<{ type: 'oversikt' | 'lokasjoner' }>;

export const ChooseServicePlusArea: React.FC<Props> = ({ match }) => {
  const translations = t.servicePlus;
  const cardStyle = { margin: '20px 12px' };

  const customer = useCustomer();
  const {
    servicePlusLocationsLoading,
    servicePlusLocationsData,
  } = useServicePlus(customer.id);
  const servicePlusLocations =
    servicePlusLocationsData?.customer?.servicePlusAddresses ?? [];

  servicePlusLocations.forEach(servicePlusAddress => {
    servicePlusAddress.value = servicePlusAddress.address;
  });

  return (
    <>
      <PageHeader
        title="Service Pluss"
        navTabs={[
          {
            label: translations.overview.title,
            url: '/service-plus/oversikt',
            exact: true,
          },
          {
            label: translations.address.title,
            url: '/service-plus/lokasjoner',
            exact: true,
          },
        ]}
      />

      <div className="container">
        {match.params.type === 'oversikt' ? (
          servicePlusLocationsLoading ? (
            <Loading />
          ) : (
            <div
              style={{
                display: 'flex',
                margin: '0px -12px',
                flexFlow: 'row wrap',
              }}
            >
              {servicePlusLocations.map(card => (
                <AddressCard
                  addressId={card.id}
                  address={card.address}
                  city={card.city}
                  postalCode={card.postalCode}
                  createdBy={card.creatorName}
                  style={cardStyle}
                  creatorEmail={card.creatorEmail}
                />
              ))}
              <NewAddressCard style={cardStyle} />
            </div>
          )
        ) : (
          <div className="pt-4">
            {servicePlusLocationsLoading ? (
              <Loading />
            ) : (
              <SearchListPicker items={servicePlusLocations} />
            )}
          </div>
        )}
      </div>
    </>
  );
};
