import { useEffect, useRef } from 'react';

/**
 * This hooks enables clicking buttons with `Enter`-key,
 * when used inside of reactstrap dropdowns.
 *
 * Enables toggeling of dropdown menu with enter key, when using custom
 * dropdown toggle
 *
 * reactstrap prevent all keyboard events inside dropdown, other than tabs.
 * https://github.com/reactstrap/reactstrap/blob/master/src/Dropdown.js
 */
export const useTabbedDropdown = () => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const container = ref.current;
    const handleEvent = (e: KeyboardEvent) => {
      // 32 === space
      if (e.key === 'Enter' || e.keyCode === 32) {
        if ((e.target as HTMLElement).className.includes('Button')) {
          (e.target as HTMLElement).click();
        }
      }
    };
    container?.addEventListener('keyup', handleEvent);
    return () => container?.removeEventListener('keyup', handleEvent);
  }, []);

  return ref;
};
