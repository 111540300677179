import { OptionSelectProps } from 'src/lib/ui/OptionSelect';
import { SortOrder } from 'src/__types/graphql-global-types';

interface Props {
  label: string;
  sortFields?: Array<{
    key: string;
    order: string;
    label: string;
  }>;
  currentSortBy?: string;
  currentSortOrder?: string;
  setSort: (sortBy: string | undefined, sortOrder: SortOrder) => void;
  defaultOption: { key: string; order: string };
}

export const useSortFilter = (props: Props): OptionSelectProps => {
  const handleSave = (value?: null | string[]) => {
    const sortOrder = value?.[0]
      ? value?.[0].indexOf('asc') > -1
        ? SortOrder.asc
        : SortOrder.desc
      : undefined;
    if (sortOrder) {
      const sortKey = value?.[0]?.replace(sortOrder, '');
      props.setSort(sortKey, sortOrder);
    }
  };

  const sortFields = props.sortFields || [];
  const mappedOptions = sortFields.map(o => ({
    value: o.key + o.order,
    label: o.label,
  }));
  const currentSort =
    (props.currentSortBy ?? '') + props.currentSortOrder ?? '';
  const defaultOption = props.defaultOption.key + props.defaultOption.order;
  return {
    id: 'sort',
    label: props.label,
    allowMultiple: false,
    allowSearch: false,
    alignRight: true,
    options: mappedOptions,
    selectedOptions: [currentSort],
    handleSave: handleSave,
    defaultOption,
    blackBorderOnSelect: true,
  };
};
