import React from 'react';
import { ExtendedQosStatisticsPeriods } from '../../queries/useExtendedQosStatistics';
import { colors } from 'src/lib/ui';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { Area } from 'recharts';
import { t } from 'src/lib/i18n';
import { formatNordic } from 'src/lib/utils';
import { createDataKeyFromTrafficClass } from '../../utils/createDataKeyFromTrafficClass';
import { getPrecision } from '../../utils/getPrecision';

export const PacketLossChartElements = (idx: number, dataKey: string) => [
  <Area
    key="1"
    stroke={colors.corePurple}
    strokeWidth="1"
    fill={colors.corePurple}
    fillOpacity="0.2"
    dataKey={`${createDataKeyFromTrafficClass(idx, dataKey)}.in`}
    name={t.statistics.Legend.packetLossIn}
  />,
  <Area
    key="2"
    stroke={colors.blueDark}
    strokeWidth="1"
    fill={colors.blueDark}
    fillOpacity="0.2"
    dataKey={`${createDataKeyFromTrafficClass(idx, dataKey)}.out`}
    name={t.statistics.Legend.packetLossOut}
  />,
];

/**
 * Area filled dot
 * Line circle
 */
export const PacketLossTooltip = (idx: number) => ({
  data,
  hideTimestamp,
}: {
  data: ExtendedQosStatisticsPeriods;
  hideTimestamp?: boolean;
}) => {
  const i18n = t.statistics.StandardDetails;
  const currentData = data.trafficClasses?.[idx];
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header={i18n.in}>
        <SectionItem
          title={i18n.packetLoss}
          color={colors.corePurple}
          value={
            currentData?.packetLoss?.in
              ? formatNordic(
                  currentData.packetLoss.in,
                  getPrecision(currentData.packetLoss.in),
                  false
                )
              : t.statistics.noData
          }
        />
      </Section>
      <Section header={i18n.out}>
        <SectionItem
          title={i18n.packetLoss}
          color={colors.blueDark}
          value={
            currentData?.packetLoss?.out
              ? formatNordic(
                  currentData.packetLoss.out,
                  getPrecision(currentData.packetLoss.out),
                  false
                )
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};
