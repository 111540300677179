import React from 'react';
import { SubscriptionsTableProps } from '../../types';
import { Subscription } from 'src/lib/types';
import { getDeliveryStatusDefinitions } from '../../utils';
import { TableBodyRow, TableBodyCell, Table, colors, Badge } from 'src/lib/ui';
import {
  TagsTableCell,
  CostCenterTableCell,
  OrganisationTableCell,
} from '../Components';
import { formatName } from 'src/lib/utils';
import { MobileColumns } from 'src/lib/table';
import { getBasicSubscriptionTitles } from '../../utils';
import { formatInstallationAddress } from 'src/lib/utils/formatInstallationAddress';
export const MobileSubscriptionsTable = (props: SubscriptionsTableProps) => {
  const {
    loading,
    results,
    subscriptionListParams: sp,
    columns,
    selectProps,
  } = props;
  return (
    <Table
      loading={loading}
      columns={columns}
      sortable={true}
      setSort={sp.setSort}
      sortBy={sp.sortBy}
      sortOrder={sp.sortOrder}
      noResultMessage={sp.noResultsMessage}
      rowsCount={sp.pageSize}
      noResult={!results.length}
      availableColumns={props.availableColumns}
      onChangeColumns={sp.setColumns}
      activeSort={{ key: sp.sortBy, order: sp.sortOrder }}
      {...(selectProps ? { selectable: true, ...selectProps } : {})}
    >
      {results.map(row => {
        const subsData = row as Subscription;

        return (
          <TableBodyRow
            key={subsData.id}
            onClick={() => {
              sp.routeToSubscription(subsData.id);
            }}
            {...(selectProps ? { selectable: true } : {})}
            selected={selectProps?.selected.includes(subsData.id)}
            onSelectRow={() => selectProps?.onSelect(subsData.id)}
          >
            {props.columns.map((c, i) => (
              <MobileTableCell
                key={`${c}-${i}`}
                column={c.key as MobileColumns}
                subsData={subsData}
              />
            ))}
          </TableBodyRow>
        );
      })}
    </Table>
  );
};

const MobileTableCell = (props: {
  column: MobileColumns;
  subsData: Subscription;
}) => {
  const {
    deliveryStatusIcon,
    deliveryStatusTranslations,
  } = getDeliveryStatusDefinitions(props.subsData.deliveryStatus);
  const { title, subtitle } = getBasicSubscriptionTitles(props.subsData);

  switch (props.column) {
    case 'name':
      return (
        <TableBodyCell>
          <div>{title}</div>
          <div style={{ color: colors.greyDarkText }}>{subtitle}</div>
        </TableBodyCell>
      );
    case 'ownerName':
      return (
        <TableBodyCell>
          {props.subsData.ownerAttributes &&
            formatName({
              firstName: props.subsData.ownerAttributes.firstName,
              lastName: props.subsData.ownerAttributes.lastName,
            })}
        </TableBodyCell>
      );
    case 'deliveryStatus':
      return (
        <TableBodyCell>
          {deliveryStatusTranslations && (
            <Badge icon={deliveryStatusIcon}>
              {deliveryStatusTranslations.status}
            </Badge>
          )}
        </TableBodyCell>
      );
    case 'phoneNumber':
      return (
        <TableBodyCell>
          {props.subsData.phoneNumbers &&
            props.subsData.phoneNumbers.length > 0 &&
            props.subsData.phoneNumbers[0].phoneNumber}
        </TableBodyCell>
      );
    case 'address':
      return (
        <TableBodyCell>
          {formatInstallationAddress(
            props.subsData.installationAddress,
            'fullAddress'
          )}
        </TableBodyCell>
      );

    case 'invoiceCenter':
      return <OrganisationTableCell subscription={props.subsData} />;
    case 'costCenter':
      return <CostCenterTableCell subscription={props.subsData} />;
    case 'tags':
      return <TagsTableCell subscription={props.subsData} />;
    default:
      return null;
  }
};
