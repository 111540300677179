import cs from 'classnames';
import React from 'react';
import { Link } from 'react-router-dom';

interface Props {
  className?: string;
  children?: React.ReactNode;
  style?: React.CSSProperties;
  onClick?: () => void;
  linkTo?: string;
}

export function ServicePlusCard(props: Props) {
  if (props.linkTo) {
    return (
      <Link
        className={cs('ServicePlusCard', props.className)}
        children={props.children}
        style={props.style}
        onClick={props.onClick}
        to={props.linkTo}
      />
    );
  } else {
    return (
      <div
        className={cs('ServicePlusCard', props.className)}
        children={props.children}
        style={props.style}
        onClick={props.onClick}
      />
    );
  }
}
