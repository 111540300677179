import React, { useEffect, useState } from 'react';
import cs from 'classnames';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { useCombobox } from 'downshift';
import { SearchInput, Loading } from 'src/lib/ui';

import useGetTagOptions from 'src/areas/main/subscriptions/utils/useGetTagOptions';

import { Checkbox, OptionSelectFooter } from 'src/lib/ui';

import './SelectTagsDropdown.scss';

interface Option {
  label: string;
  value: string;
}

interface Props {
  allowMultiple?: boolean;
  buttonLabel?: string;
  selectedTags?: string[];
  setSelectedTags?: (tags: string[]) => void;
  trigger?: 'string' | React.ReactType;
}

export function SelectTagsDropdown(props: Props) {
  const st = useGetTagOptions('');

  /**
   * We start the selected tags from outside
   */
  const [selected, setSelected] = useState<string[]>(
    () => props.selectedTags || []
  );

  /**
   * Whenever the outside selection changes, update the selection in here
   */
  useEffect(() => {
    setSelected(props.selectedTags || []);
  }, [props.selectedTags]);

  const optionIsSelected = (option: Option) =>
    option && !!selected?.find(selectedValue => selectedValue === option.value);

  const removeOption = (option: Option) => {
    setSelected(
      selected.filter(selectedValue => selectedValue !== option.value)
    );
  };

  const addOption = (option: Option) => {
    setSelected([...selected, option.value]);
  };

  const toggleOption = (option: Option) =>
    optionIsSelected(option) ? removeOption(option) : addOption(option);

  const handleSave = (tags: string[]) => props.setSelectedTags?.(tags);

  const downshift = useCombobox({
    items: st.result,

    onIsOpenChange: changes => {
      if (!changes.isOpen) {
        return handleSave(selected);
      } else {
        downshift.setInputValue('');
      }
    },

    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.FunctionOpenMenu:
          return {
            ...actionAndChanges.changes,
            highlightedIndex: 0,
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return state;
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (actionAndChanges.changes?.selectedItem) {
            toggleOption(actionAndChanges.changes.selectedItem);
          }
          return {
            ...state,
            selectedItem: actionAndChanges.changes.selectedItem,
            isOpen: true,
          };
        default:
          return actionAndChanges.changes;
      }
    },
  });

  const applySelection = () => downshift.closeMenu();

  const cancel = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    setSelected([]);
    e.preventDefault();
  };

  const menuProps = downshift.getMenuProps({
    className: 'SelectTagsDropdown-menu',
  });

  const filterString = downshift.inputValue.toLocaleLowerCase();

  return (
    <Dropdown
      isOpen={downshift.isOpen}
      toggle={downshift.isOpen ? downshift.closeMenu : downshift.openMenu}
      {...downshift.getComboboxProps()}
    >
      <DropdownToggle tag={props.trigger}>{props.buttonLabel}</DropdownToggle>

      <DropdownMenu right={true}>
        <SearchInput inputProps={downshift.getInputProps} />

        <ul {...menuProps}>
          {st.loading ? (
            <li>
              <Loading />
            </li>
          ) : st.result?.length ? (
            <>
              {st.result?.map((option, i) => {
                if (
                  downshift.inputValue &&
                  !option?.label.toLocaleLowerCase().includes(filterString)
                ) {
                  return null;
                }

                const itemProps = downshift.getItemProps({
                  item: option,
                  index: i,
                });

                if (!option) {
                  return <li tabIndex={-1} key={i} {...itemProps} />;
                }

                const isSelected = optionIsSelected(option);

                return (
                  <li
                    role="button"
                    tabIndex={-1}
                    key={option.value}
                    className={cs('SelectTagsDropdown-item', {
                      selected: isSelected,
                      active: downshift.highlightedIndex === i,
                    })}
                    {...itemProps}
                  >
                    <Checkbox
                      checked={isSelected}
                      tabIndex={-1}
                      onChange={e => e.preventDefault()}
                    />
                    <span>
                      <div className="OptionSelectMenu-item-label">
                        {option.label}
                      </div>
                    </span>
                  </li>
                );
              })}
            </>
          ) : (
            <li style={{ height: '100px' }} />
          )}
        </ul>

        <OptionSelectFooter
          apply={applySelection}
          reset={cancel}
          loading={st.loading}
        />
      </DropdownMenu>
    </Dropdown>
  );
}
