import React from 'react';

interface Props {
  attributes?: { [key: string]: string };
  onCreate?: () => void;
  onError?: () => void;
  onLoad?: () => void;
  url: string;
}

export default class Script extends React.Component<Props, {}> {
  // A dictionary mapping script URLs to a dictionary mapping
  // component key to component for all components that are waiting
  // for the script to load.
  static scriptObservers: { [key: string]: any } = {};

  // A dictionary mapping script URL to a boolean value indicating if the script
  // has already been loaded.
  static loadedScripts: { [key: string]: any } = {};

  // A dictionary mapping script URL to a boolean value indicating if the script
  // has failed to load.
  static erroredScripts: { [key: string]: any } = {};

  // A counter used to generate a unique id for each component that uses
  // ScriptLoaderMixin.
  static idCount = 0;

  private scriptLoaderId: string;

  constructor(props: Props) {
    super(props);
    this.scriptLoaderId = `id${Script.idCount++}`; // eslint-disable-line space-unary-ops, no-plusplus
  }

  componentDidMount() {
    const { onError, onLoad, url } = this.props;

    if (Script.loadedScripts[url]) {
      if (onLoad) onLoad();
      return;
    }

    if (Script.erroredScripts[url]) {
      if (onError) onError();
      return;
    }

    // If the script is loading, add the component to the script's observers
    // and return. Otherwise, initialize the script's observers with the component
    // and start loading the script.
    if (Script.scriptObservers[url]) {
      Script.scriptObservers[url][this.scriptLoaderId] = this.props;
      return;
    }

    Script.scriptObservers[url] = {
      [this.scriptLoaderId]: this.props,
    };

    this.createScript();
  }

  componentWillUnmount() {
    const { url } = this.props;
    const observers = Script.scriptObservers[url];

    // If the component is waiting for the script to load, remove the
    // component from the script's observers before unmounting the component.
    if (observers) {
      delete observers[this.scriptLoaderId];
    }
  }

  createScript() {
    const { onCreate, url, attributes } = this.props;
    const script = document.createElement('script');

    if (onCreate) onCreate();

    // add 'data-' or non standard attributes to the script tag
    if (attributes) {
      Object.keys(attributes).forEach(prop =>
        script.setAttribute(prop, attributes[prop])
      );
    }

    script.src = url;

    // default async to true if not set with custom attributes
    if (!script.hasAttribute('async')) {
      script.async = true;
    }

    const callObserverFuncAndRemoveObserver = (shouldRemoveObserver: any) => {
      const observers = Script.scriptObservers[url];
      Object.keys(observers).forEach(key => {
        if (shouldRemoveObserver(observers[key])) {
          delete Script.scriptObservers[url][this.scriptLoaderId];
        }
      });
    };
    script.onload = () => {
      Script.loadedScripts[url] = true;
      callObserverFuncAndRemoveObserver(({ onLoad }: Props) => {
        if (onLoad) onLoad();
        return true;
      });
    };

    script.onerror = () => {
      Script.erroredScripts[url] = true;
      callObserverFuncAndRemoveObserver(({ onError }: Props) => {
        if (onError) onError();
        return true;
      });
    };

    document.body.appendChild(script);
  }

  render() {
    return null;
  }
}
