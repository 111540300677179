import React from 'react';
import { useListQueryParams } from 'src/lib/utils/useListQueryParams';
import FilterSection from '../common/FilterSection';
import { Pagination, IncidentGroupInUrl, urlFor } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import ListIncidents from './ListIncidents';
import { IncidentsTable } from './IncidentsTable';
import { useRouter } from 'src/lib/utils/useRouter';
import { Tab, PageHeader } from '../common/PageHeader/PageHeader';
import {
  useSubscriptionsFilter,
  useIncidentStatusFilter,
  useTagsFilter,
  useSortFilter,
} from 'src/lib/filters';

import { useQueryParamPagination } from 'src/lib/ui/Pagination/useQueryParamPaginations';
import { getFilterCountFromSearchFilter } from 'src/lib/utils/';

import { useSearchIncidents } from './queries/useSearchIncidents';
const defaultOption = { key: 'createdAt', order: 'desc' };

export const IncidentsContainer = () => {
  const query = useQueryParameters();
  const i18n = t.incidents;

  const mobileBreakpoint = useBreakpoint('md');
  const router = useRouter<{ group: IncidentGroupInUrl }>();
  const listParams = useListQueryParams({
    defaultSortKey: defaultOption.key,
    defaultSortOrder: defaultOption.order,
    preferenceNamespace: 'incidents',
  });

  const paginationProps = useQueryParamPagination({
    defaultPageSize: listParams.pageSize,
    onTogglePageSize: listParams.setPageSize,
  });

  const subscriptionsFilters = useSubscriptionsFilter();
  const tagsFilters = useTagsFilter({ disableNoTagsFilter: true });

  const filters = [
    {
      filter: 'incidentStatus',
      value: query.getArray('incidentStatus'),
    },
    {
      filter: 'active',
      value: (router.match.params.group === 'aktive').toString(),
    },
    {
      filter: 'tag',
      value: query.getArray('tag'),
    },
    {
      filter: 'tagsCombined',
      value: query.getArray('tagsCombined'),
    },
    {
      filter: 'subscription',
      value: query.getArray('subscription'),
    },
  ];
  const statusFilter = useIncidentStatusFilter();

  const search = useSearchIncidents({
    query: listParams.searchQuery,
    page: listParams.page,
    sortBy: listParams.sortBy,
    sortOrder: listParams.sortOrder,
    size: listParams.pageSize,
    filters,
  });

  const tabs: Tab[] = [
    {
      label: i18n.tabs.active,
      url: urlFor({ area: 'incidents', group: 'aktive' }),
    },
    {
      label: i18n.tabs.history,
      url: urlFor({ area: 'incidents', group: 'historikk' }),
    },
  ];

  const sortFields = [
    {
      key: 'createdAt',
      order: 'desc',
      label: i18n.sortFields.created + ' ' + i18n.sortFields.mostRecent,
    },
    {
      key: 'createdAt',
      order: 'asc',
      label: i18n.sortFields.created + ' ' + i18n.sortFields.oldFirst,
    },
    {
      key: 'updated',
      order: 'desc',
      label: i18n.sortFields.updated,
    },
    {
      key: 'status',
      order: 'desc',
      label: i18n.sortFields.status,
    },
    {
      key: 'id',
      order: 'desc',
      label: i18n.sortFields.id,
    },
  ];

  const sortOptions = useSortFilter({
    label: i18n.sortFields.lable,
    sortFields: sortFields,
    setSort: (by, order) => {
      query.set({
        sortBy: by,
        sortOrder: order,
      });
    },
    currentSortBy: listParams.sortBy,
    currentSortOrder: listParams.sortOrder,
    defaultOption,
  });
  const filterCountFromSearch = getFilterCountFromSearchFilter(filters);
  const tagsCombined = query.get('tagsCombined') ?? false;
  const filterCount =
    tagsCombined && filterCountFromSearch
      ? filterCountFromSearch - 1
      : filterCountFromSearch;

  return (
    <>
      <PageHeader title={t.incidents.listTitle} navTabs={tabs} />

      <FilterSection
        filterToggling={true}
        totalSearchResults={search.totalResults ?? 0}
        totalFiltersActive={filterCount}
        searchValue={listParams.searchQuery}
        onSearch={val => listParams.setSearchQuery(val)}
        onResetSearch={() => listParams.setSearchQuery('')}
        setCurrentView={view => listParams.setView(view)}
        currentView={listParams.view}
        filterOptions={[
          subscriptionsFilters,
          tagsFilters,
          statusFilter,
          ...(listParams.view === 'list' ? [sortOptions] : []),
        ]}
      />

      <div className="container">
        {listParams.view === 'list' || !mobileBreakpoint ? (
          <ListIncidents loading={search.loading} results={search.incidents} />
        ) : (
          <IncidentsTable
            results={search.incidents}
            loading={search.loading}
            listParams={listParams}
          />
        )}

        <div className="flex-fill d-flex justify-content-end mb-5 mt-4">
          <Pagination
            totalResults={search.totalResults || 0}
            {...paginationProps}
          />
        </div>
      </div>
    </>
  );
};
