const search = {
  ResourceType: {
    contact: 'Kontakter',
    file: 'Dokumenter',
    incident: 'Feilmeldinger',
    invoice: 'Fakturaer',
    organisation: 'Fakturasteder',
    subscription: 'Tjenester',
    dataSubscription: 'Nettverk',
    mobileSubscription: 'Mobil',
    teleSubscription: 'Telefoni',
    tvSubscription: 'TV',
    otherSubscription: 'Andre tjenester',
    costCenter: 'Kostsenter',
    tag: 'Tags',
    order: 'Ordre',
  },

  SearchListCostCenterResult: {
    costCenter: 'Kostnadssted',
  },

  SearchListIncidentResult: {
    title: 'Feilmelding',
    opened: 'Opprettet',
    lastUpdated: 'Sist endret',
  },

  SearchListPlannedWorkResult: {
    title: 'Planlagt arbeid',
    active: 'Planlagt',
    closed: 'Lukket',
    opened: 'Opprettet',
    lastUpdated: 'Sist endret',
    expectedDowntime: 'Forventet nedetid',
  },

  SearchListInvoiceResult: {
    titlePrefix: 'Faktura',
    organisation: 'Fakturasted',
    invoiceDate: 'Fakturadato',
    dueDate: 'Forfallsdato',
    amountExclVat: 'Beløp ekskl.mva.',
  },

  SearchListOrderResult: {
    titlePrefix: 'Ordre',
    created: 'Opprettet',
    lastUpdated: 'Sist oppdatert',
  },

  SearchListSubscriptionResult: {
    noConnection: 'Ingen kontakt',
    incident: 'Feilmelding',
  },

  SearchListResult: {
    noResult: 'Ingen treff på søk',
  },

  SearchMenuHistoryEntry: {
    removeFromHistory: 'Fjern søk fra historikk',
  },

  SearchMenuInvoiceResult: {
    titlePrefix: 'Faktura',
    currencyInclVat: (formattedCurrency: string) =>
      `kr ${formattedCurrency} inkl.mva.`,
    currencyExclVat: (formattedCurrency: string) =>
      `kr ${formattedCurrency} ekskl.mva.`,
  },

  SearchMenu: {
    foundHits: (hits: number, total: number) =>
      `${hits} treff (av totalt ${total})`,
    noHits: 'Ingen resultater',
    loading: 'Laster ...',
    sidemenuTitle: 'Kategorier',
    allResults: (n: number) => `Alle resultater (${n})`,
  },

  Search: {
    showingNResults: (n: number, total: number) => `Viser ${n} av ${total}`,
    searchingForSuffix: (resourceTypeLabel: string) =>
      ` i ${resourceTypeLabel}`,
    title: (label?: string) =>
      label ? `Resultater for «${label}»` : 'Resultater',
  },

  SearchField: {
    placeholder: 'Søk',
  },
};

export default search;
