import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useGenerateReportMobileTaxContentServices';
import { useCustomer } from 'src/lib/global';

export const GENERATE_MOBILE_REPORT_FOR_TAX_CONTENT_SERVICES = gql`
  mutation useGenerateReportMobileTaxContentServices(
    $customerId: ID!
    $fromDate: Date!
    $toDate: Date!
    $organisationId: ID
  ) {
    generateReportMobileTaxContentServices(
      input: {
        customerId: $customerId
        fromDate: $fromDate
        toDate: $toDate
        organisationId: $organisationId
      }
    ) {
      url
      filename
    }
  }
`;

export const useMobileReportTaxContentServices = () => {
  const customer = useCustomer();

  const [mutation, { loading, error }] = useMutation<
    t.useGenerateReportMobileTaxContentServices,
    t.useGenerateReportMobileTaxContentServicesVariables & {
      customerId: string;
    }
  >(GENERATE_MOBILE_REPORT_FOR_TAX_CONTENT_SERVICES);

  const generate = async (
    fromDate: string,
    toDate: string,
    organisationId?: string
  ) =>
    await mutation({
      variables: {
        customerId: customer.id,
        fromDate,
        toDate,
        organisationId,
      },
    });

  return {
    generate,
    loading,
    error,
  };
};
