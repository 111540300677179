import gql from 'graphql-tag';

export const NotificationFragment = gql`
  fragment NotificationSearchResult on Notification {
    id
    notificationType
    order {
      id
    }
    incident {
      id
    }
    subscriptions {
      id
    }
    comment
    incidentStatus
    createdAt
    read
  }
`;
