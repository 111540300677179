import { TextField, TextFieldProps } from 'src/lib/ui';
import { Field, FieldProps } from 'formik';
import _get from 'lodash/get';
import * as React from 'react';

type FormikTextFieldProps = Omit<TextFieldProps, 'value'>;

const FormikTextField: React.FunctionComponent<FormikTextFieldProps> = props => {
  return (
    <Field name={props.id}>
      {formikProps => {
        const { field, form } = formikProps as FieldProps;
        const hasError =
          Boolean(_get(form.touched, field.name)) &&
          Boolean(_get(form.errors, field.name));

        /**
         * Formik.isValid has some weird logic that is not correct for our use-case, so we cannot rely on that property.
         */
        const success =
          Boolean(field.value) &&
          Boolean(_get(form.touched, field.name)) &&
          !_get(form.errors, field.name);

        return (
          <TextField
            {...props}
            value={field.value || ''}
            onChange={e => {
              field.onChange(e);
              if (props.onChange) {
                props.onChange(e);
              }
            }}
            onBlur={e => {
              field.onBlur(e);
              if (props.onBlur) {
                props.onBlur(e);
              }
            }}
            onFocus={e => {
              form.setFieldTouched(field.name, false);
              if (props.onFocus) {
                props.onFocus(e);
              }
            }}
            error={
              hasError ? (_get(form.errors, field.name) as string) : undefined
            }
            success={success}
          />
        );
      }}
    </Field>
  );
};

export default FormikTextField;
