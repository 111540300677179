import React from 'react';
import {
  SearchOptionSelect,
  OptionSelect,
  OptionSelectProps,
} from '../OptionSelect';
import { sortBy } from 'lodash';

export const FilterSelect = (props: OptionSelectProps) => {
  const sorted = sortBy(props.options, [o => o?.negative]);

  if (props.allowSearch) {
    return (
      <SearchOptionSelect
        {...props}
        color="dark"
        icon="arrow-small-down"
        iconPosition="after"
        outline={true}
        options={sorted}
      />
    );
  }
  return (
    <OptionSelect
      {...props}
      color="dark"
      icon="arrow-small-down"
      iconPosition="after"
      outline={true}
      options={sorted}
    />
  );
};
