import { formatDate } from 'src/lib/utils';

import Nb from '../../nb/Main/dashboard';

const dashboard: typeof Nb = {
  bannerText: {
    part1:
      'We are currently experiencing issues with incident tickets created from our portal. ',
    part2:
      'Please contact Service Desk on phone 02150 (key selection 2) to report issues.',
    part3: '',
    message:
      'We are having problems showing reported incidents at the moment. We are working on solving the issue and we apologize for any inconvenience.',
  },
  new: {
    heading: 'Overview',
    activeFilters: 'Active filters',
    status: 'Status',
    filterButtonLabel: (numberOfActiveFilters: number) =>
      numberOfActiveFilters
        ? `Change filter (${numberOfActiveFilters})`
        : 'Filter',
    kpi: {
      subscriptionsWithUnreportedFaults:
        'Services offline without an incident report',
      incidentsOnHoldPendingCustomer: 'Incidents waiting on response',
      servicesWithPlannedWork: 'Services with planned work',
      overdueInvoices: 'Overdue invoices',
      activeIncidents: 'Active incidents',
      activeOrders: 'Active orders',
      servicesAssigning: 'Services being assigned',
      servicesTerminating: 'Services being terminated',
    },
  },

  Dashboard2: {
    greeting: (name: string | null | undefined) =>
      name ? `Hello, ${name}!` : null,
    welcome: (name: string) =>
      `Here is an overview for ${name}, ${formatDate(
        new Date(),
        'long',
        'en'
      )}.`,
  },

  DashboardTags: {
    heading: 'Tags',
    introText:
      'With tags, you can filter lists and search results, group services ' +
      'in reports and control users access to services.',
  },

  DashboardGrid: {
    activeIncidents: (n?: number | null) =>
      n !== 1 ? 'active incidents' : 'active incident',
    activeSolvedIncidents: (n?: number | null) =>
      (n !== 1 ? 'incidents' : 'incident') + ' solved the last 5 days',
    servicesWithUnreportedIncidents: (n?: number | null) =>
      `service${n !== 1 ? 's' : ''} with unreported faults`,
    incidentsWaitingForClient: (n?: number | null) =>
      `incident${n !== 1 ? 's' : ''} waiting for reply`,
    activeOrders: (n?: number | null) =>
      (n !== 1 ? 'active orders' : 'active order') as string,
    overdueInvoices: (n: number | null) =>
      n !== 1 ? 'overdue invoices' : `overdue invoice`,
    servicesWithPlannedWork: (n: number | null): string =>
      n !== 1 ? 'services with planned work' : `service with planned work`,
  },

  hello: 'Hello!',

  statusForClient: (client?: string) =>
    client
      ? `Status for ${client}, ${formatDate(new Date(), 'long', 'en')}`
      : `Status, ${formatDate(new Date(), 'long', 'en')}`,

  servicesWithUnreportedIncidents: (count: number) =>
    `${count} service${count !== 1 ? 's' : ''} with unreported faults`,

  incidentsWaitingForClient: (
    count: number,
    client: string | null | undefined
  ) =>
    `${count} incident${count !== 1 ? 's' : ''} waiting on response${
      client ? ` from ${client}` : ''
    }`,

  showMeTheServices: 'Show me the services',

  showMeXServices: (count: number) =>
    `Show me ${count} service${count !== 1 ? 's' : ''}`,

  showMeIncidents: 'Show me incidents',

  dataServices: 'Data and internet services',

  mobileServices: 'Mobile services',

  teleServices: 'Telephony services',

  activeIncidents: (count: number) =>
    count === 1 ? '1 active incident' : `${count} active incidents`,

  unpaidInvoices: (count: number) =>
    count === 1 ? '1 unpaid invoice' : `${count} unpaid invoices`,

  tagsHeading: 'Tags',

  tagsComments: [
    'With tags, you can ...',
    '...filter lists and search results',
    '...group services in reports',
    '...control users access to services',
  ],

  footerHeading: 'Do you need assistance?',

  supportContactLabel: 'Customer support',
  supportContactDetails:
    'Can help you with subscriptions, services and billing',

  contactCardEmail: 'Email:',
  contactCardPhone: 'Phone:',
  cannyApp: {
    header: 'Give feedback',
    feedbackLabel: 'Feedback',
    feedbackDetails: 'See what we are working on and give feedback',
    linkLabel: 'Give feedback',
  },
  plannedWork: {
    date: 'Date',
    time: 'Time',
    referenceNumber: 'Reference number',
    affectedServices: 'Affected services',
    numberOfAffectedServices: 'Number of services',
    startTime: 'start',
    endTime: 'end',
    emergency: 'Emergency',
    emergencyDesription:
      'Necessary work thet need to be done to fix an incident or avoid/limit the impact of an incident.',
    nextTwoWeeks: 'Next 14 days',
    all: 'All cases',
    plannedWork: 'Planned work',
    impactedSubscription: 'Impacted subscriptions',
    close: 'Close',
    to: 'to',
    noPlannedWork: 'There is currently no planned work',
    servicesAffected: (num: number) =>
      num === 1 ? `${num} service is affected` : `${num} services are affected`,
    download: 'Download',
  },
};

export default dashboard;
