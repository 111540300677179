import * as React from 'react';
import { Link } from 'react-router-dom';

import { urlFor, UrlForObject } from './urlFor';

export type LinkToProps = {
  children?: string | React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
  noPropagation?: boolean;
} & UrlForObject;

const stopPropagation = (
  event: React.MouseEvent<HTMLAnchorElement, MouseEvent>
) => {
  event.stopPropagation();
};

/**
 * The LinkTo component always has a children prop, but only _one of_
 * the other properties (invoice, incident etc.).
 * @deprecated (Use Link Component + urlFor)
 */
export const LinkTo: React.SFC<LinkToProps> = props => {
  const url = urlFor(props);
  return (
    <Link
      style={props.style}
      className={props.className}
      to={url}
      onClick={props.noPropagation ? stopPropagation : undefined}
    >
      {props.children}
    </Link>
  );
};
