import React from 'react';
import { MobilePeriodDetails } from 'src/areas/mobile/types';
import {
  Attribute,
  BodyCard,
  CardHeader,
  CardBody,
  CardDivider,
  CardFooter,
} from 'src/lib/ui';
import { Subscription } from 'src/lib/types';
import { formatDate, formatNordic } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import OrderTerminateMobileSubscription from 'src/areas/main/ordering/OrderTerminateMobileSubscription/OrderTerminateMobileSubscription';
import OrderingModal from 'src/areas/main/ordering/layout/OrderingModal';
import { convertMegaByteToGigaByte } from 'src/areas/main/statistics/utils/convertUnit';

interface Props {
  subscription: Subscription;
  mobileSubscription: MobilePeriodDetails | null;
}
const stringifyDate = (date: string) => {
  const dateTyped = new Date(date);
  return formatDate(dateTyped, 'long', 'nb');
};

export const AboutMobileSubscriptionCard: React.SFC<Props> = ({
  mobileSubscription,
  subscription,
}) => {
  const { id, disableEdit } = useSubscriptionContext();

  const i18n = t.subscriptions.AboutMobileSubscriptionCard;
  if (!mobileSubscription) {
    return null;
  }
  const dataIncluded = mobileSubscription.dataFreeUse
    ? i18n.freeUse
    : mobileSubscription.dataInclusive
    ? `${formatNordic(
        convertMegaByteToGigaByte(mobileSubscription.dataInclusive),
        0
      )} GB`
    : undefined;

  const fixedCost = mobileSubscription.monthlyFee
    ? `${formatNordic(mobileSubscription.monthlyFee)}kr`
    : undefined;

  const startTime = subscription.startTime
    ? stringifyDate(subscription.startTime)
    : undefined;

  return (
    <BodyCard noGutter>
      <CardHeader>
        <h2>{i18n.details}</h2>
      </CardHeader>

      <CardBody>
        <Attribute label={i18n.subscription} value={mobileSubscription.name} />
        {fixedCost ? (
          <Attribute label={i18n.fixedCost} value={fixedCost} />
        ) : null}
        {dataIncluded ? (
          <Attribute label={i18n.dataIncluded} value={dataIncluded} />
        ) : null}
      </CardBody>
      <CardDivider />
      <CardBody>
        <Attribute label={i18n.serviceType} value={subscription.name} />
        <Attribute label={i18n.id} value={subscription.id} />
        {startTime ? (
          <Attribute label={i18n.started} value={startTime} />
        ) : null}

        <Attribute
          label={i18n.validTo}
          value={
            subscription.endTime
              ? stringifyDate(subscription.endTime)
              : i18n.continuous
          }
        />
      </CardBody>

      {!disableEdit && (
        <CardFooter>
          <OrderTerminateMobileSubscription msisdn={id}>
            {terminateMobileSub => (
              <OrderingModal
                title={terminateMobileSub.header}
                disableSubmitButton={!terminateMobileSub.isFormValid}
                confirmButtonLabel={terminateMobileSub.submitLabel}
                submit={terminateMobileSub.submit}
                icon="stop"
              >
                {terminateMobileSub.render}
              </OrderingModal>
            )}
          </OrderTerminateMobileSubscription>
        </CardFooter>
      )}
    </BodyCard>
  );
};
