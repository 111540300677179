import React, { useEffect } from 'react';
import { Modal, ModalFooter, Form } from 'reactstrap';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { t } from 'src/lib/i18n';
import { withFormik, FormikProps } from 'formik';
import { FormikTextField } from 'src/lib/flow';
import { OriginDetails } from 'src/areas/mobile/types';
import { useUpdateSubscriptionOrigin } from 'src/areas/mobile/settings/mutations';
import { UpdateSubscriptionOriginResult } from 'src/areas/mobile/settings/mutations/useUpdateSubscriptionOriginDetails';
import { colors, FormSubmitBar } from 'src/lib/ui';

interface Props {
  origin: OriginDetails;
  open: boolean;
  toggle: () => void;
}

interface InnerProps extends Props {
  result: UpdateSubscriptionOriginResult;
}

const InnerFormModal: React.FC<InnerProps &
  FormikProps<OriginDetails>> = props => {
  const i18n = t.mobile.settings;

  useEffect(() => {
    if (props.result.data && !props.result.error) {
      props.toggle();
    }
  }, [props.result.loading, props.result.error, props.result.data]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={props.open} size="lg" toggle={props.toggle}>
      <ModalHeader>{i18n.origin.title}</ModalHeader>
      <ModalBody>
        <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
          {i18n.origin.description}
        </small>
        <Form>
          <div className="pb-4">
            <FormikTextField
              id="firstName"
              label={i18n.firstName}
              className="w-50 pr-3"
            />
            <FormikTextField
              id="lastName"
              label={i18n.lastName}
              className="w-50"
            />
          </div>
          <FormikTextField
            id="streetAddress"
            label={i18n.address}
            className="w-100 pb-4"
          />
          <div className="pb-4">
            <FormikTextField
              id="postCode"
              label={i18n.postCode}
              className="w-25 pr-3"
            />
            <FormikTextField
              id="postArea"
              label={i18n.postArea}
              className="w-75"
            />
          </div>
          <FormikTextField
            id="country"
            label={i18n.country}
            className="w-100 pb-4"
          />
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={() => props.handleSubmit()}
          cancel={props.toggle}
          loading={props.result.loading}
          error={props.result.error}
          errorMsg={t.mobile.settings.modalFields.unableToSave}
        />
      </ModalFooter>
    </Modal>
  );
};

const FormikOriginForm = withFormik<InnerProps, OriginDetails>({
  mapPropsToValues: ({ origin }) => ({
    firstName: origin.firstName,
    lastName: origin.lastName,
    middleName: origin.middleName,
    streetAddress: origin.streetAddress,
    postArea: origin.postArea,
    postCode: origin.postCode,
    country: origin.country,
    streetNum: origin.streetNum,
    apartmentNum: origin.apartmentNum,
    floor: origin.floor,
    gnr: origin.gnr,
    fnr: origin.fnr,
    knr: origin.knr,
  }),

  handleSubmit: (values: OriginDetails, bag) => {
    bag.props.result.updateOrigin({
      firstName: values.firstName,
      middleName: bag.props.origin.middleName,
      lastName: values.lastName,
      streetAddress: values.streetAddress,
      postArea: values.postArea,
      postCode: values.postCode,
      country: values.country,
      streetNum: values.streetNum,
      apartmentNum: values.apartmentNum,
      floor: values.floor,
      gnr: values.gnr,
      fnr: values.fnr,
      knr: values.knr,
    });
  },
})(InnerFormModal);

export const OriginFromModal: React.FC<Props> = props => {
  const result = useUpdateSubscriptionOrigin();

  return (
    <FormikOriginForm
      origin={props.origin}
      result={result}
      open={props.open}
      toggle={props.toggle}
    />
  );
};
