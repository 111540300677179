import React from 'react';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
import { t } from 'src/lib/i18n';
import { useRouter } from 'src/lib/utils/useRouter';
import { useContact } from '../../queries/useContact';
import { Loading, RequireRole, Alert } from 'src/lib/ui';
import { getContactDisplayName } from 'src/areas/main/incidents/utils/incidentUtils';
import ContactCard from './ContactCard';
import ContactServices from './ContactServices';
import { DeletePerson } from '../deleteContact/DeletePerson';
import { useAppContext } from 'src/lib/global';
import { Contact } from 'src/lib/types';
import { ContactPermissionsCard } from './ContactPermissionsCard';
import { useFeature } from 'src/lib/utils/useFeature';

const DisplayContact = () => {
  const i18n = t.contacts;
  const router = useRouter<{ id: string }>();
  const app = useAppContext();

  const { data, loading } = useContact(router.match.params.id);

  const isFederatedUser = useFeature('scim-federated-user');

  const contact = data?.customer?.contacts?.length
    ? data.customer.contacts[0]
    : undefined;

  const subscriptions = contact?.technicalContactSubscriptions;
  const mappedContact = contact as Contact;

  if (loading) {
    return <Loading />;
  } else if (contact) {
    return (
      <>
        <PageHeader
          breadcrumbs={[{ title: i18n.breadCrumb, url: '/kontakter' }]}
          title={getContactDisplayName(contact)}
        />
        <div className="container">
          {contact.user && !contact.user.emailVerified && (
            <div className="my-4">
              <Alert
                icon="alert"
                status="warning"
                header={t.contacts.status.waitingActivation}
              >
                {t.contacts.status.userHasNotVerifiedEmail}
              </Alert>
            </div>
          )}

          <div className="my-4">
            <ContactCard contact={mappedContact} />
          </div>

          <RequireRole role="CustomerAdministrator">
            <div className="mb-4">
              <ContactServices subscriptions={subscriptions} />
            </div>
            {/**  NOTIFICATIONS  */}
            <div className="mb-4">
              <ContactPermissionsCard contact={mappedContact} />
            </div>

            {/* 21.01.2020 -- excluded from first releas of new contacts */}
            {/* <div className="mb-4">
              <ContactMobileSubscription contact={contact} />
            </div>

          </div> */}

            {contact?.user?.id !== app.access.id && !isFederatedUser ? (
              <div style={{ marginBottom: '5rem' }}>
                <DeletePerson contact={mappedContact} />
              </div>
            ) : null}
          </RequireRole>
        </div>
      </>
    );
  } else {
    return null;
  }
};

export default DisplayContact;
