import React, { useContext } from 'react';
import * as Sentry from '@sentry/browser';

import en from '../../translations/en';
import nb from '../../translations/nb';
import moment from 'moment';
import 'moment/locale/nb';

import * as variables from '../../translations/variables';

// const getBrowserLanguage = () => {
//   return navigator.language || ((navigator as any).userLanguage as string);
// };

/**
 * Override the `en` locale in moment to have the same week settings
 * as Norwegian locale (week starts on Monday=1, first week of the year
 * is the first week with Thursday=4)
 */
moment.updateLocale('en', {
  week: {
    dow: 1,
    doy: 4,
  },
});

const setGlobalLocale = (locale: string) => {
  if (locale === 'en') {
    // globalLocale = 'en';
    t = en;
  } else if (locale === 'nb') {
    // globalLocale = 'nb';
    t = nb;
  }

  Sentry.configureScope(scope => {
    scope.setTag('page_locale', locale);
  });
};

let t = nb;

Sentry.configureScope(scope => {
  scope.setTag('page_locale', 'nb');
});

// let globalLocale: Locale = 'nb';

const setMomentLocale = (locale: Locale) => {
  moment.locale(locale);
};

export type Locale = 'en' | 'nb';

interface I18nContext {
  locale: Locale;
  setLocale: (locale: Locale) => void;
  toggleLocale: () => void;
}

const CreateI18nContext = React.createContext<I18nContext>({
  locale: 'nb',
  setLocale: () => undefined,
  toggleLocale: () => undefined,
});

const { Provider, Consumer } = CreateI18nContext;

function useI18nContext() {
  return useContext(CreateI18nContext);
}

interface Props {
  preferredLocale?: Locale;
  onChangeLocale?: (locale: Locale) => void;
}
interface State {
  locale: Locale;
}

class I18nProvider extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      locale:
        this.props.preferredLocale &&
        ['en', 'nb'].includes(this.props.preferredLocale)
          ? this.props.preferredLocale
          : 'nb',
    };
    setGlobalLocale(this.state.locale);
    setMomentLocale(this.state.locale);
  }

  setLocale = (locale: Locale) => {
    setGlobalLocale(locale);
    this.setState({ locale });
    if (this.props.onChangeLocale) {
      this.props.onChangeLocale(locale);
    }
    setMomentLocale(locale);
    /**
     * Because the global locale isn't part of React state, we need to force a rerender
     * of the component tree when the locale changes. This is definitely not
     * idiomatic React, but works adequately well.
     *
     * Setting key={this.state.locale} on the Provider would also technically work,
     * but will clear all state for the child component hierarchy -- losing e.g.
     * the currently selected customer.
     */
    this.forceUpdate();
  };

  toggleLocale = () => {
    this.setLocale(this.state.locale === 'en' ? 'nb' : 'en');
  };

  render() {
    /**
     * We set the Provider key here to invalidate the whole subtree when the locale changes.
     * Somewhat brutal approach, but changing the language is a low-frequency action.
     */
    return (
      <Provider
        value={{
          locale: this.state.locale,
          setLocale: this.setLocale,
          toggleLocale: this.toggleLocale,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export { t, variables, I18nProvider, Consumer as WithI18n, useI18nContext };
