import * as React from 'react';
import { urlFor, ButtonCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

export interface SubscriptionAdditionalCasesLinkProps {
  subscription: {
    id: string;
  };
}

/**
 * A ButtonCard with spesific props and styling to fit the design for Reporting Incidents.
 */
export function SubscriptionAdditionalCasesLink(
  props: SubscriptionAdditionalCasesLinkProps
) {
  const i18n = t.subscriptions.layout.SubscriptionLinkToIncidentTab;
  return (
    <ButtonCard
      color="dark"
      stackHorizontally={true}
      to={urlFor({
        subscription: props.subscription.id,
        subPage: 'incidents',
      })}
    >
      <div className="d-flex align-items-center">
        <span>{`${i18n.text} `}</span>
        <span className="ml-auto">
          <Icon icon="arrow-large-right" />
        </span>
      </div>
    </ButtonCard>
  );
}
