import React from 'react';
import { ContextMenu, ContextMenuItem } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useChatContext } from './ChatContext';

interface Props {
  id: string;
  setEdit: () => void;
}

export function ChatMessageContextMenu(props: Props) {
  const { commentHandlers } = useChatContext();

  return (
    <ContextMenu>
      {commentHandlers?.handleEdit ? (
        <ContextMenuItem onClick={props.setEdit} icon="pen">
          {t.chat.editMessage}
        </ContextMenuItem>
      ) : null}

      {commentHandlers?.handleDelete && (
        <ContextMenuItem
          icon="trash"
          onClick={() => commentHandlers?.handleDelete?.(props.id)}
        >
          {t.chat.deleteMessgae}
        </ContextMenuItem>
      )}
    </ContextMenu>
  );
}
