import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useStatisticsGSMRecent';

const GET_RECENT_GSM = gql`
  query useStatisticsGSMRecent($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        statisticsGSMRecent {
          currentRssi
          currentEcio
          currentBand
          currentChannelNumber
          measurementTimeStamp
          signalType
        }
      }
    }
  }
`;

export interface Result {
  loading: boolean;
  error: ApolloError | null;
  data?: t.useStatisticsGSMRecent_customer_subscriptions | null;
}

export const useStatisticsGSMRecent = (subscriptionId: string): Result => {
  const customer = useCustomer();
  const result = useQuery<
    t.useStatisticsGSMRecent,
    t.useStatisticsGSMRecentVariables
  >(GET_RECENT_GSM, {
    variables: {
      customerId: customer.id,
      subscriptionId: subscriptionId,
    },
    errorPolicy: 'all',
  });
  return {
    loading: result.loading,
    error: result.error ? result.error : null,
    data: result.data?.customer?.subscriptions?.[0],
  };
};
