import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { useRouter } from 'src/lib/utils/useRouter';
import { format } from 'date-fns';
import { StandardStatisticsType } from '../queries/WithStatistics';
import { trackStatisticsView } from 'src/lib/analytics';
import { trackStatisticsFilter } from 'src/lib/analytics';
import moment from 'moment';

export type StatisticsView =
  | StandardStatisticsType
  | 'all'
  | 'qosTransmit'
  | 'qosDrop'
  | 'live'
  | 'gsm'
  | 'jitter'
  | 'roundTripTime'
  | 'packetLoss';

interface StatisticsParamsInterface {
  subscriptionId: string;
  currentView?: StatisticsView;
  currentInterface?: string;
  currentStartPeriod: string;
  currentEndPeriod: string;
  showUtilization: number;
  setView: (view?: StatisticsView) => void;
  setInterface: (i?: string) => void;
  setPeriod: (startPeriod?: string, endPeriod?: string) => void;
  setStartPeriod: (period?: string) => void;
  setEndPeriod: (period?: string) => void;
  toggleUtilization: () => void;
}

export const useStatisticsParams = (): StatisticsParamsInterface => {
  const router = useRouter<{ id: string }>();
  const query = useQueryParameters();
  const currentTime = moment();

  // readable params
  const currentView: StatisticsView = query.get('view') as StatisticsView;
  const currentInterface = query.get('interface');
  const currentStartPeriod =
    query.get('startPeriod') ||
    format(
      currentTime
        .set({
          hour: 0,
          minute: 0,
          second: 0,
          millisecond: 0,
        })
        .toDate(),
      'YYYY-MM-DD HH:mm:00'
    );
  const currentEndPeriod =
    query.get('endPeriod') ||
    format(
      currentTime
        .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
        .toDate(),
      'YYYY-MM-DD HH:mm:00'
    );
  const showUtilization = query.getNumber('showUtil') || 0;

  const setView = (view?: StatisticsView) => {
    if (view) trackStatisticsFilter('type', view.toString());
    query.set({
      view,
      // interface: undefined,
      // startPeriod: undefined,
      // endPeriod: undefined,
    });
  };

  const setInterface = (i?: string) => {
    if (i) trackStatisticsFilter('interface', i.toString());
    query.set('interface', i);
  };

  const setPeriod = (startPeriod?: string, endPeriod?: string) => {
    query.set({
      startPeriod,
      endPeriod,
    });
  };

  const setStartPeriod = (period?: string) => {
    query.set('startPeriod', period);
  };
  const setEndPeriod = (period?: string) => {
    query.set('endPeriod', period);
  };

  const toggleUtilization = () => {
    trackStatisticsView('utnyttelse', (!showUtilization).toString());
    query.set('showUtil', showUtilization ? 0 : 1);
  };

  return {
    subscriptionId: router.match.params.id,
    currentView,
    currentInterface,
    currentStartPeriod,
    currentEndPeriod,
    showUtilization,
    setView,
    setInterface,
    setPeriod,
    setStartPeriod,
    setEndPeriod,
    toggleUtilization,
  };
};
