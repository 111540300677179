import * as React from 'react';
import cx from 'classnames';
import './FormFieldWrapper.scss';

export interface FormFieldWrapperProps {
  className?: string;
  required?: boolean;
  success?: boolean;
  error?: string;
  label?: string;
  helpText?: string;
  subTextPosition?: 'left' | 'right';
  labelId?: string;
}

export const FormFieldWrapper: React.FunctionComponent<FormFieldWrapperProps> = ({
  className,
  required,
  success,
  error,
  label,
  helpText,
  subTextPosition = 'left',
  labelId,
  children,
}) => {
  /**
   * Render priority
   * 1. Success -> No subText
   * 2. Error
   * 3. HelpText
   */
  const subText = success ? null : error || helpText;

  return (
    <div
      className={cx('FormFieldWrapper', className, {
        error: Boolean(error),
      })}
    >
      {label ? (
        <label className="FormFieldWrapper-label" id={labelId}>
          {label}
          {required ? ' *' : null}
        </label>
      ) : null}
      {children}
      {subText ? (
        <small
          className={cx(
            'FormFieldWrapper-subText',
            subTextPosition === 'right' ? 'ml-auto' : undefined
          )}
        >
          {subText}
        </small>
      ) : null}
    </div>
  );
};
