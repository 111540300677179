import React from 'react';
import { Attribute } from 'src/lib/ui';
import './Period.scss';
import { formatNordic, parsePeriod } from 'src/lib/utils';
import { PeriodTable } from 'src/areas/mobile/history/PeriodTable';
import { t } from 'src/lib/i18n';
import { MobilePeriodDetails } from 'src/areas/mobile/types';
import { ExpansionPanel } from 'src/lib/ui/ExpansionPanel/ExpansionPanel';

export interface InvoiceCardProps {
  period: MobilePeriodDetails;
}

export const Period: React.FC<InvoiceCardProps> = ({ period }) => {
  const usage = period.usage;
  const totalCost = usage ? usage.totalUsageAmount : 0;
  const { monthLong, year } = parsePeriod(period.period);
  if (usage) {
    return (
      <ExpansionPanel
        className="Period rounded border"
        heading={
          <>
            <div className="d-flex flex-column align-items-start">
              <div className="month">
                {monthLong} {year}
              </div>
              {period.name}

              <Attribute
                label={t.mobile.history.total}
                value={
                  totalCost !== null
                    ? ` Kr ${formatNordic(totalCost, 2)}`
                    : undefined
                }
              />
            </div>
          </>
        }
      >
        <PeriodTable usage={usage} />
      </ExpansionPanel>
    );
  }
  return null;
};
