import * as React from 'react';
import { Loading, FullScreenError, NoResult } from 'src/lib/ui';
import OverviewStep from './OverviewStep';
import { calculateStepMeta } from 'src/lib/charts';
import { useOverviewData } from 'src/areas/mobile/overview/useOverviewData';
import OverviewPackage from './OverviewPackage';
import { OverviewProfileCard, OverviewSimCardsCard } from './cards';
import { t } from 'src/lib/i18n';
import { MinSidePageHeader } from 'src/areas/mobile/ui/MinSidePageHeader/MinSidePageHeader';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import cs from 'classnames';
import CurrentUsage from 'src/areas/mobile/overview/cards/CurrentUsage';
import { Summary } from 'src/areas/mobile/overview/cards/Summary';
import { OriginDetails, UserDetails } from '../types';

const Overview: React.SFC = () => {
  const { data, loading } = useOverviewData();
  const xl = useBreakpoint('xl');

  if (loading) {
    return <Loading />;
  }
  if (!data || !data.subscription) {
    return (
      <FullScreenError technicalReason="Could not load MobileSubscription" />
    );
  }
  const {
    current,
    steppedPriceModel,
    simCards,
    user,
    origin,
  } = data.subscription;

  if (!current) {
    return <NoResult text="No data" />;
  }

  const usageLimit = current.isBucketMember
    ? current.speedDrop
    : current.dataInclusive;

  // TODO: Will propably be fixed in backend where data.sub.current will return null as a whole if no data exists
  const dataUsageRatio =
    current.isBucketMember &&
    current.usage &&
    current.usage.nationalCallVolume &&
    current.speedDrop &&
    current.usage.nationalDataVolume !== null
      ? current.usage.nationalDataVolume / current.speedDrop
      : current.dataInclusive !== 0 &&
        current.usage &&
        current.usage.nationalDataVolume &&
        current.dataInclusive
      ? current.usage.nationalDataVolume / current.dataInclusive
      : 0;

  // Step model
  const priceModel = steppedPriceModel ? steppedPriceModel : undefined;
  const stepsMeta =
    priceModel && current.usage && current.usage.nationalDataVolume !== null
      ? calculateStepMeta(priceModel, current.usage.nationalDataVolume)
      : undefined;
  return (
    <div className="Overview">
      <MinSidePageHeader
        title={t.mobile.overview.greeting(user?.firstName)}
        subText={t.mobile.overview.overviewDescription}
      />
      <div className="container">
        {current.usage ? (
          stepsMeta ? (
            <OverviewStep usage={current.usage} />
          ) : (
            <OverviewPackage
              usage={current.usage}
              usageLimit={usageLimit ? usageLimit : 0}
              usageRatio={dataUsageRatio}
            />
          )
        ) : null}
        <div className="d-flex flex-column flex-xl-row">
          <div
            className={cs('flex-fill', { 'mr-4': xl })}
            style={{ width: xl ? '60%' : 'auto' }}
          >
            <Summary subscription={current} stepsMeta={stepsMeta} />

            <OverviewSimCardsCard simCards={simCards} />
          </div>
          <div style={{ width: xl ? '40%' : 'auto' }}>
            {current.usage ? (
              <CurrentUsage
                subscriptionName={current.name}
                usage={current.usage}
              />
            ) : null}

            <OverviewProfileCard
              user={user as UserDetails}
              origin={origin as OriginDetails}
              customer={data?.subscription?.customer?.name}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Overview;
