export const colors = {
  // Global colors
  background: '#fbfbfb',

  // Primary colors
  corePurple: '#990ae3',
  black: '#222222',
  grey: '#f2f2f2',
  white: '#ffffff',

  // Secondary colors
  orange: '#ff9b00',
  teal: '#00cdcd',
  tealDark: '#009999',
  green: '#00cc66',
  red: '#ff3264',
  redDark: '#e4175c',
  purpleDark: '#642d96',
  pinkDark: '#d22d89',
  greyMedium: '#ebebeb',
  greyLight: '#f9f9f9',
  greyExtraLight: '#fcfcfc',
  greyDarkText: '#595959',
  greyDark: '#a0a0a0',
  blueDark: '#0099ff',
  corePurpleDark: '#9b009b',
  corePurpleLight: '#cc00ff',

  // Aliases
  primary: '#990ae3', // corePurple
  success: '#009999', // tealDark
  warning: '#ff9b00', // orange
  danger: '#e4175c', // redDark
  info: '#0099ff', // blueDark

  graph_blueLight: 'rgba(0, 163, 136, 0.3)',
  graph_blueDark: 'rgb(49, 130, 189)',
};
