import React from 'react';
import { useOverviewData } from 'src/areas/mobile/overview/useOverviewData';
import { Loading } from 'src/lib/ui';
import { calculateStepMeta } from 'src/lib/charts';
import {
  OverviewSimCardsCard,
  OverviewProfileCard,
} from 'src/areas/mobile/overview/cards';
import GenericSubscriptionPage from 'src/areas/main/subscriptions/Pages/Generic/GenericSubscriptionPage';
import CurrentUsage from 'src/areas/mobile/overview/cards/CurrentUsage';
import { useSubscription } from 'src/areas/main/subscriptions/queries/useSubscription';
import { useRouter } from 'src/lib/utils/useRouter';
import { Summary } from 'src/areas/mobile/overview/cards/Summary';
import { SubscriptionSummaryContainer } from '../../layout';
import { TechnicalContactsCard } from '../../Pages/Components/TechnicalContacts/TechnicalContactsCard';
import {
  SubscriptionCostDetails,
  AdditionalInformationCard,
  M2MUsage,
  ConnectedSubscription,
  AboutMobileSubscriptionCard,
} from '../../Pages/Components';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { OriginDetails, UserDetails } from 'src/areas/mobile/types';
import { useFeature } from 'src/lib/utils';
import { useAppContext } from 'src/lib/global';
import { userIsCustomerOrSystemAdmin } from 'src/areas/main/contacts/lib/editContact/utils';

export const MobileSubscription = () => {
  const router = useRouter<{ id: string }>();
  const { subscription } = useSubscription(router.match.params.id);
  const dontFetchPinPuk = useFeature('hide-pin-puk');
  const appContext = useAppContext();
  const adminUser = userIsCustomerOrSystemAdmin(appContext.customerRoles);

  const { loading, data } = useOverviewData(dontFetchPinPuk && !adminUser);
  const { disableEdit } = useSubscriptionContext();

  if (subscription) {
    if (loading) {
      return <Loading />;
    }
    if (!data || !data.subscription) {
      return <GenericSubscriptionPage subscription={subscription} />;
    }

    const {
      current,
      steppedPriceModel,
      simCards,
      user,
      origin,
    } = data.subscription;

    const priceModel = steppedPriceModel ? steppedPriceModel : undefined;
    const stepsMeta =
      priceModel &&
      current &&
      current.usage &&
      current.usage.nationalDataVolume !== null
        ? calculateStepMeta(priceModel, current.usage.nationalDataVolume)
        : undefined;

    return (
      <SubscriptionSummaryContainer
        subscription={subscription}
        main={
          <>
            <ConnectedSubscription subscription={subscription} />

            {current && stepsMeta ? (
              <Summary
                subscription={current}
                stepsMeta={stepsMeta}
                hideToolbar={true}
              />
            ) : null}
            {current && current.usage ? (
              <>
                <M2MUsage subscription={subscription} />
                <CurrentUsage
                  usage={current.usage}
                  subscriptionName={current.name}
                />
              </>
            ) : null}
          </>
        }
        sidebar={
          <>
            <AboutMobileSubscriptionCard
              mobileSubscription={current}
              subscription={subscription}
            />

            <OverviewProfileCard
              user={user as UserDetails}
              origin={origin as OriginDetails}
              customer={data.subscription.customer?.name ?? ''}
            />
            <OverviewSimCardsCard simCards={simCards} />
            <SubscriptionCostDetails
              subscription={subscription}
              disableEdit={disableEdit}
            />
            <TechnicalContactsCard
              subscription={subscription}
              disableEdit={disableEdit}
            />
            <AdditionalInformationCard
              subscription={subscription}
              disableEdit={disableEdit}
            />
          </>
        }
      />
    );
  }
  return null;
};
