import { Definition } from './types';
import { formatColumns, InvoiceColumns } from './index';
import { getSortOption } from '../utils/getSortOptionsByKey';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getInvoiceColumnDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const invoiceColumnMap: {
    [key in InvoiceColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    status: {
      label: t.invoices.statusLabel,
      cellWidth: 150,
      sortOptions: getSortOption('status', true),
      fixed: true,
    },
    id: {
      label: t.invoices.invoiceNumber,
      cellWidth: 170,
      sortOptions: getSortOption('id', true),
      fixed: true,
    },
    address: {
      label: t.invoices.invoiceAddress,
    },
    invoiceDate: {
      label: t.invoices.invoiceDate,
      sortOptions: getSortOption('date', true),
    },
    dueDate: {
      label: t.invoices.dueDate,
      sortOptions: getSortOption('dueDate', true),
    },
    sumExVat: {
      label: t.invoices.sumExVat,
      sortOptions: getSortOption('net', true),
    },
    actions: {
      fixed: true,
      label: '',
      cellWidth: 50,
    },
  };

  const defaultInvoiceColumns: Array<InvoiceColumns> = [
    'status',
    'id',
    'address',
    'invoiceDate',
    'dueDate',
    'sumExVat',
    'actions',
  ];

  return formatColumns({
    map: invoiceColumnMap,
    defaultColumns: defaultInvoiceColumns,
    savedColumnPreferences,
  });
};
