import { orderBy } from 'lodash';
import { Contact } from 'src/lib/types';
import { getIncidentStatusLabel } from 'src/lib/types/incident/getIncidentStatusLabel';
import { Item } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { ChatActivity } from 'src/lib/chat/types';
import { IncidentStatus } from 'src/__types/graphql-global-types';
import { useIncident_customer_incidents } from '../queries/__types/useIncident';

export const mapActivitiesToChatMessages = (
  email: string,
  incident: useIncident_customer_incidents
) => {
  //  MUST be updated to properly handle missing values.
  const activities = incident.activities
    ? (incident.activities.map((a, i) => {
        if (a) {
          // Comments
          if (a.__typename === 'IncidentComment') {
            return {
              id: `${i}`,
              type: 'comment',
              message: a.comment || '',
              timestamp: a.timestamp || new Date(),
              contact: {
                name: a.userName || a.userEmail || '',
              },
              prominent: !!a.userCustomerId,
              direction: a.userEmail === email ? 'sent' : 'received',
            };
          }

          // Fieldchange
          if (a.__typename === 'IncidentFieldChange') {
            // Handle updated incidentStatus
            if (a.fieldName === 'incidentStatus') {
              return {
                id: `${i}`,
                type: 'statusChange',
                status: getIncidentStatusLabel(
                  (a.value as IncidentStatus) || undefined
                ),
                timestamp: a.timestamp || new Date(),
              };
            }
            // Default fieldChange
            return {
              type: a.type,
              value: a.value,
              fieldName: a.fieldName,
              timestamp: a.timestamp || new Date(),
            };
          }
        }
        return {};
      }) as ChatActivity[])
    : [];

  // Handle attachments
  if (incident.attachments) {
    incident.attachments.forEach((a, i) =>
      activities.push({
        id: `${i}`,
        type: 'attachment',
        contact: { name: a?.userName ?? '' }, // a.userCustomerName
        direction: 'sent',
        attachment: a,
        timestamp: a.updatedOn || new Date(),
      })
    );
  }

  // Sort activities by timestamp
  return orderBy(activities, ['timestamp'], ['asc']);
};

// map NotificationType to boolean sms and email vars
export const mapNotificationTypeToBoolean = (notify?: string) => {
  if (notify) {
    if (notify === 'DoNotNotify') {
      return { sms: false, email: false };
    } else if (notify === 'Email') {
      return { sms: false, email: true };
    } else if (notify === 'SMS') {
      return { sms: true, email: false };
    } else {
      return { sms: true, email: true };
    }
  }
  return { sms: false, email: false };
};

// map contacts to items to fit ItemListPicker

export const mapContactsToItem = (
  contacts: Array<{
    id: string;
    firstName?: string | null;
    lastName?: string | null;
    email?: string | null;
  }>
): Item[] => {
  return contacts.map(
    contact =>
      ({
        id: contact.id,
        label: `${
          contact.firstName
            ? contact.lastName
              ? contact.firstName + ' ' + contact.lastName
              : contact.firstName
            : contact.lastName
            ? contact.lastName
            : ''
        } ${contact.email ? '(' + contact.email + ')' : ''}`,
      } as Item)
  );
};

export const mapNotificationContactToItem = (
  notificationContacts: Array<{
    id: string;
    name?: string;
    email?: string | null;
    contact?: null | { id: string };
  }>
): Item[] =>
  notificationContacts.map(
    notificationContact =>
      ({
        id: notificationContact.contact
          ? notificationContact.contact.id
          : notificationContact.id,
        label: `${
          notificationContact.name ? notificationContact.name : ''
        }        ${
          notificationContact.email ? '(' + notificationContact.email + ')' : ''
        }`,
      } as Item)
  );

export const getContactDisplayName = (contact: Contact): string => {
  return contact.firstName
    ? contact.lastName
      ? contact.firstName + ' ' + contact.lastName
      : contact.firstName
    : contact.lastName
    ? contact.lastName
    : t.incidents.contact;
};
