import React from 'react';
import { t } from 'src/lib/i18n';
import { colors, StepFlowCard } from 'src/lib/ui';
import { FormState } from '../editServices/useReplaceContactStepFlow';

export const Step3 = (props: { form: FormState; step: number }) => {
  const i18n = t.contacts.DeletePerson;

  return (
    <StepFlowCard
      step={props.step}
      title={i18n.deleteContact}
      currentStep={props.form.currentStep}
      isValid={true}
      isLastStep={true}
      onClick={() => props.form.setStep(props.step)}
      onContinue={props.form.nextStep}
      form={<Form {...props.form} />}
    />
  );
};

const Form = (form: FormState) => {
  const i18n = t.contacts.DeletePerson;
  const keys = Object.keys(form.values.map ?? {});

  const numReplacements = keys.reduce(
    (acc, current) =>
      form.values.map?.[current]?.technicalContact ? acc + 1 : acc,
    0
  );

  const numNotReplacements = keys.length - numReplacements;
  return (
    <>
      <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
        {i18n.step3.description}
      </small>
      <div className="pb-5" style={{ color: colors.greyDarkText }}>
        {form.values.replace === 'all' ? (
          <div>{i18n.step3.replaceAll}</div>
        ) : (
          <div>
            {numReplacements !== 0 ? (
              <div className="pb-2">
                {i18n.step3.replaceNumSubscriptions(numReplacements)}
              </div>
            ) : null}
            {numNotReplacements !== 0 ? (
              <div>
                {i18n.step3.noReplacementNumSubscriptions(numNotReplacements)}
              </div>
            ) : null}
          </div>
        )}
      </div>
    </>
  );
};
