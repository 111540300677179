import gql from 'graphql-tag';

export const CostCenterFragment = gql`
  fragment CostCenterSearchResult on CostCenter {
    id
    code
    description
    customer {
      id
      name
    }
  }
`;
