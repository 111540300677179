import * as React from 'react';

interface LocalStorageInterface<T> {
  value: T | undefined;
  setValue: (value: T) => Promise<void>;
}

//
// NOTE regarding StorageEvent
//
// Ideally, intersession sync would be implemented using:
// window.addEventListener('storage', (e: StorageEvent) => { ... })
//
// However, browser support does not seem sufficient to replace the timer.
// For now, we are only using a refresh timer to check whether the value
// stored in localStorage has changed.
//

interface Props<T> {
  /**
   * If set, how frequently (in milliseconds) the component will check localStorage for updated values.
   */
  refresh?: number;

  /**
   * The key used to store/fetch values from localStorage.
   */
  storageKey: string;

  children: (lsi: LocalStorageInterface<T>) => null | React.ReactNode;
}

interface State<T> {
  /**
   * A snapshot of the previously fetched data from localStorage.
   */
  snapshot: string | null;

  /**
   * The parsed data sent to the children render function.
   */
  value: T | undefined;
}

export class LocalStorage<T> extends React.Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    try {
      const snapshot = localStorage.getItem(this.props.storageKey);
      const value = snapshot === null ? undefined : JSON.parse(snapshot);
      this.state = {
        snapshot,
        value,
      };
    } catch (err) {
      console.log(err);
      this.state = {
        snapshot: null,
        value: undefined,
      };
    }
  }

  private timer: number | undefined;
  private disableTimer: number = 0;

  componentDidMount() {
    this.setRefreshTimer();
  }

  componentDidUpdate(prevProps: Props<T>, prevState: State<T>) {
    if (prevProps.refresh !== this.props.refresh) {
      this.clearRefreshTimer();
      this.setRefreshTimer();
    }
  }

  componentWillUnmount() {
    this.clearRefreshTimer();
  }

  private readFromStorage = () => {
    // let value: string | null = null;
    try {
      const local = localStorage.getItem(this.props.storageKey);
      // We have a value from the localStorage; but let's compare it with the snapshot before we do anything.
      if (local !== this.state.snapshot) {
        const value = local === null ? undefined : JSON.parse(local);
        this.setState({
          snapshot: local,
          value,
        });
      }
    } catch (err) {
      console.log(err);
    }
  };

  setValue = async (value: T) => {
    return new Promise<void>((resolve, reject) => {
      try {
        const stringified = JSON.stringify(value);
        // Critical area; we want to make sure that state and localstorage
        // is updated simultaneously. I.e. disable timer in this area!
        this.disableTimer += 1;
        this.setState(
          {
            value: value,
            snapshot: stringified,
          },
          () => {
            localStorage.setItem(this.props.storageKey, stringified);
            this.disableTimer -= 1;
            resolve();
          }
        );
        // End of critical area
      } catch (err) {
        reject(err);
      }
    });
  };

  private setRefreshTimer() {
    if (this.props.refresh !== undefined) {
      this.timer = window.setInterval(this.refresh, this.props.refresh);
    }
  }

  private clearRefreshTimer() {
    if (this.timer !== undefined) {
      window.clearInterval(this.timer);
    }
  }

  private refresh = () => {
    if (this.disableTimer === 0) {
      this.readFromStorage();
    }
  };

  render() {
    return this.props.children({
      value: this.state.value,
      setValue: this.setValue,
    });
  }
}
