import React from 'react';
import { formatNordic } from 'src/lib/utils';

export const PeriodTableLine: React.FC<{
  description: string;
  volume: string;
  cost?: number | null;
}> = ({ description, volume, cost }) => {
  return (
    <tr>
      <td>{description}</td>
      <td>{volume}</td>
      <td>
        {cost !== null && cost !== undefined ? (
          <>Kr {formatNordic(cost, 2)}</>
        ) : null}
      </td>
    </tr>
  );
};
