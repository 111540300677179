import React from 'react';
import { ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import {
  Button,
  Loading,
  colors,
  FormSubmitBar,
  Chip,
  SearchInput,
} from 'src/lib/ui';
import './ItemListPicker.scss';
import matchSorter from 'match-sorter';
import { map, memoize } from 'lodash';
import { Item } from './ItemListPicker';
import './ItemListPicker.scss';
import { t } from 'src/lib/i18n';

type Callbacks = {
  select: (item: Item) => void;
  unselect: (item: Item) => void;
  onInputChange: (query: string) => void;
  handleSave: () => void;
};

type Props = {
  query: string;
  availableItems: Item[];
  availableItemsSubHeader?: string;
  selectedItems?: Item[];
  selectedItemsSubHeader?: string;
  availableItemsPlaceholder?: string;
  filterPlaceholder?: string;
  subHeader?: string;
  disableAddNew?: boolean;
  toggleState: () => void;
  heading: string;
  loading?: boolean;
  submitLoading?: boolean;
} & Callbacks;

export const ItemListPickerBody = (props: Props) => {
  const selectHandler = memoize((item: Item) => () => props.select(item));

  const unselectHandler = memoize((item: Item) => () => props.unselect(item));

  const addHandler = () => {
    props.select({ label: props.query });
  };

  const onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    props.onInputChange(event.currentTarget.value);
  };

  const notSelectedItems = props.availableItems.filter(
    item => !props.selectedItems?.find(i => i.id === item.id)
  );

  const visibleItems = props.query
    ? matchSorter(notSelectedItems, props.query, { keys: ['label'] })
    : notSelectedItems;

  const perfectFilterMatch = props.query
    ? props.selectedItems?.findIndex(i => i.label === props.query) !== -1 ||
      visibleItems.findIndex(i => i.label === props.query) !== -1
    : false;

  return (
    <>
      <ModalHeader>{props.heading}</ModalHeader>
      <ModalBody className="ItemListPicker-body">
        {props.submitLoading ? (
          <Loading loadingText={t.ui.ItemListPicker.submitting} />
        ) : (
          <>
            {props.subHeader ? (
              <div className="mb-4">
                <small style={{ color: colors.greyDarkText }}>
                  {props.subHeader}
                </small>
              </div>
            ) : null}
            <SearchInput
              placeholder={props.filterPlaceholder}
              value={props.query}
              onChange={onInputChange}
              className="mb-2"
            />

            <div>
              {props.selectedItemsSubHeader ? (
                <h4>{props.selectedItemsSubHeader}</h4>
              ) : null}
              <div className="ItemListPicker-selectedItems">
                {props.selectedItems && props.selectedItems.length > 0 ? (
                  <div className="p-0 m-0">
                    {map(props.selectedItems, (item, ix) => (
                      <Chip
                        key={ix}
                        className="mb-2"
                        mode="select"
                        label={item.label}
                        onClick={unselectHandler(item)}
                        active={true}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            </div>
            {props.loading ? (
              <Loading />
            ) : (
              <div className="ItemListPicker-container">
                {props.availableItemsSubHeader ? (
                  <h4>{props.availableItemsSubHeader}</h4>
                ) : null}
                {visibleItems.length > 0 ? (
                  <ul className="ItemListPicker-items">
                    {visibleItems.map((item: Item) => (
                      <li
                        className="ItemListPicker-item"
                        key={item.id}
                        role="button"
                        tabIndex={0}
                        onClick={selectHandler(item)}
                      >
                        {item.label}
                      </li>
                    ))}
                  </ul>
                ) : (
                  <div className="ItemListPicker-itemsPlaceholder">
                    {!props.query
                      ? props.availableItemsPlaceholder ||
                        t.ui.ItemListPicker.noMoreItemsInList
                      : null}
                  </div>
                )}

                {/* Add new item */}

                {!props.disableAddNew &&
                props.query &&
                (!props.availableItems.length || !perfectFilterMatch) ? (
                  <>
                    {t.ui.ItemListPicker.addItem}

                    <Button
                      className="ml-2"
                      icon="add"
                      color="white"
                      onClick={addHandler}
                    >
                      {props.query}
                    </Button>
                  </>
                ) : null}
              </div>
            )}
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          cancel={props.toggleState}
          submit={props.handleSave}
          disabled={props.submitLoading}
        />
      </ModalFooter>
    </>
  );
};
