import incidentsNb from '../../nb/Main/incidents';

const incidents: typeof incidentsNb = {
  listTitle: 'Incidents',
  title: 'Incident',
  noIncidents: 'No incidents',
  sortFields: {
    lable: 'Sort',
    opened: 'Created',
    updated: 'Last changed',
    created: 'Created',
    id: 'ID',
    status: 'Status',
    subscriptionId: 'Service id',
    subscriptionName: 'Service name',
    subscriptionAlias: 'Service alias',
    mostRecent: 'most recent',
    oldFirst: 'oldest first',
  },
  subscriptionsFilter: {
    lable: 'Tjenester',
    placeholder: 'Filtrer på tjenester',
  },
  tabs: {
    active: 'Active',
    history: 'History',
  },
  table: {
    status: 'Status',
    created: 'Created',
    updated: 'Last changed',
    id: 'ID',
    details: 'Details',
    subscription: 'Service',
    solved: 'Solved',
  },
  showXofY: (showing: number, total?: number) =>
    `Showing ${showing} of ${total} incidents`,
  status: {
    label: 'Status',
    placeholder: 'Filter på status',
    solved: 'Solved',
    canceled: 'Canceled',
    closed: 'Closed',
    onHoldPendingTelia: 'Waiting for Telia',
    onHoldPendingCustomer: (customerName?: string) =>
      `Waiting for ${customerName || 'customer'}`,
    fallback: 'Unknown',
  },
  contact: 'contact',

  reportIncident: {
    notifyMeAboutNotifications: 'Notify me about updates on the incident',
    sendNotificationsByEmail: 'Notify all followers of the incident by email',
    background: 'Background',
    reportIncident: 'Report incident',
    noContact: {
      text: 'You have to be a contact in order to report incidents.',
    },
    pickErrorDescription: 'Choose what currently describes the service best',
    step1: {
      whatHappened: 'Tell us what happened',
      description:
        'A detailed description makes it easier for us to help you more quickly. Please include information about power outages and affected devices.',
    },
    step2: {
      additionalInformation: 'Additional information',
      customereRefTooLong: 'Reference number is too long',
      notificationHeading: 'Notifications',
      notificationDescription:
        'You can add contacts to be notified about this incident. The contacts will be notified by email or sms based on their personal notification settings.',
    },
    incidentTypes: {
      connectionDown: {
        title: 'Service down',
        helpText: 'When the service is not responding.',
      },
      connectionUnstable: {
        title: 'Service unstable',
        helpText: 'When the connection is unstable.',
      },
      connectionSlow: {
        title: 'Service slow',
        helpText:
          'When service has a high response time or reduced throughput.',
      },
      otherServiceError: {
        title: 'Something else is wrong',
        subTitle: 'Tell us what happened',
        helpText:
          'A detailed description leads to faster resolution. Helpful information includes what equipment is affected, if there has been a power outage, or other relevant information.',
      },
    },
    btnReportIncident: 'Finish and submit',
    errorMsg: 'Could not report incident. Try again later',
  },
  IncidentFileUploader: {
    title: 'Attachments',
    description: 'Upload pictues or other files',
    btnAdd: 'Upload attachments',
  },
  IncidentActions: {
    markSolved: 'Mark as solved',
    showDetails: 'Show details',
  },
  ResolveIncidentConfirmationModal: {
    title: 'Mark solved',
    description:
      'When you confirm that the incident is solved, the incident is marked as done. The incident will automatically be closed after 3 days.',
    description2:
      'If the problem persists, you can still comment until it closes. It will then be reopened, and we will help you with the problem.',
  },
  IncidentSidebar: {
    header: 'Details',
    aboutIncident: 'About incident',
    aboutService: 'About service',
    contacts: 'contacts',
    followsTheIncident: 'Follows the incident',
    serviceLevelAgreement: 'Service level agreement',
    followsTheService: 'Follows the service',
    referenceNumber: 'Reference number',
    referenceNumberDescription:
      'If you are using an external incident system, you may put their reference number here.',
    sendNotificationsByEmail: 'Send notifications by email',
  },
  EditCustomerReference: {
    title: 'Reference number',
    description:
      'Set a reference nubmer for this incident. It is possible to change the reference number after the incident has been created.',
    helpText: 'Max 40 characters',
  },
  NotificationSelector: {
    title: 'Contacts will be notified by: ',
    email: 'Email',
    sms: 'SMS',
  },
  IncidentContacts: {
    btnAdd: 'Add',
    contactsHeading: 'Follows the incident',
    btnEdit: 'Change',
    addContacts: 'Add contacts',
    add: 'Add',
  },
  ChatInput: {
    placeHolder: 'Write message here',
    btnSend: 'Send',
    btnClear: 'Clear',
  },
  ChatMessage: {
    btnShowLess: 'Show less',
    btnShowMore: 'Show more',
    statusUpdate: 'Status set to: ',
    setTo: 'Set to',
    emailFromCustomer: 'Email from customer',
  },
  faultType: 'Fault type',
  customerContact: 'Customer contact',
  createdBy: 'Reported by',
  created: 'Created',
  description: 'Description',
  IncidentSummary: {
    affectedService: 'Affected service',
    mainService: 'Main service',
    canceledStatusText:
      'If you have any questions regarding the incident, you can contact us by sending an e-mail to feilmelding@telia.no.',
    status: 'Status',
    btnMarkSolved: 'Mark as solved',
    solvedWarning:
      'Once the incident is solved, Telia will not keep following this incident. Are you sure this incident is solved?',
    subscriptionHeading: 'The service incident applies to',
    mainSubscriptionHeading: 'Main service',
    notificationErrorMesg: 'Could not save notification preferences',
    referenceErrorTooLong: 'Reference number is too long',
    referenceErrorDefault: 'Could no save referene number',
    referenceSaved: 'New reference number was set',
    severity: 'Severity',
    minor: 'Minor error',
    major: 'Major error',
    critical: 'Critical error',
    getsNotificationOn: 'Notified by',
    getsNotificationIn: 'Notified in the',
    portal: 'portal',
    web: 'Customer portal',
    phone: 'Phone',
    email: 'Email',
    chat: 'Chat',
    monitoring: 'Monitoring',
    unknown: 'Unknown',
  },
  origin: 'Origin',
};

export default incidents;
