import gql from 'graphql-tag';

export const BarringSettingsFragment = gql`
  fragment BarringSettingsFragment on BarringMobileSettings {
    data
    dataRoaming
    incomingCallsAndSms
    outgoingCallsAndSms
    outgoingCalls
    internationalCalls
    internationalCallsExceptHome
    internationalIncomingCalls
    internationalOutgoingCalls
    cpaDisabled
    cpaAmount100
    cpaAmount250
    cpaAmount500
    cpaGoodsAndServices
    premiumServices
    premiumServices820
    premiumServices829
    donations
    numberInformation
  }
`;
