import * as React from 'react';
import { ListHeader } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useSubscriptionFilesAndFolders } from 'src/areas/main/subscriptions/queries/WithSubscriptionFilesAndFolders';
import { DocumentsContextProvider } from 'src/areas/main/documents/DocumentsContext';
import DocumentsContainer from 'src/areas/main/documents/DocumentsContainer';
import { useRouter } from 'src/lib/utils/useRouter';
import { firstCharToUpper } from 'src/lib/utils/strings';
import { useNavigateToFilesAndFolder } from 'src/areas/main/documents/contexts/useNavigateToFilesAndFolders';
import { useSubscription } from '../queries/useSubscription';
import { useCustomer } from 'src/lib/global';
import { Category } from 'src/lib/analytics/Analytics';

const SubscriptionDocuments = () => {
  const router = useRouter<{ id: string }>();
  const { id } = useCustomer();
  const data = useSubscriptionFilesAndFolders(router.match.params.id, id);
  const navigateTo = useNavigateToFilesAndFolder();
  const { subscription } = useSubscription(router.match.params.id);
  const group =
    subscription && subscription.group ? subscription.group.toString() : '';
  if (!subscription) {
    return <ListHeader>{t.documents.genericError}</ListHeader>;
  }

  // Anything following `router.match.url` in
  // `router.location.pathname` is used to specify the file/folder
  const path = router.location.pathname.substr(router.match.url.length);

  return (
    <DocumentsContextProvider
      value={{
        path,
        subscriptionId: subscription.id,
        documentArchive: {
          folders: data.folders,
          files: data.files,
        },
        setDocumentsInternalOnUpload: false,
        navigateTo,
        loading: data.loading,
        refetchFileStructure: data.refetch,
        customerId: id,
        category: firstCharToUpper(group) as Category,
      }}
    >
      <DocumentsContainer />
    </DocumentsContextProvider>
  );
};

export default SubscriptionDocuments;
