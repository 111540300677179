import React, { useState } from 'react';
import { useSubscriptionDirectoryDetails } from 'src/areas/mobile/lib/useSubscriptionDirectoryDetails';
import { Loading } from 'src/lib/ui';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { t } from 'src/lib/i18n';
import { SettingsCard } from 'src/areas/mobile/settings/SettingsCard';
import { DirectoryFormModal } from 'src/areas/mobile/settings/forms/DirectoryFormModal';

export const DirectorySettings = () => {
  const [isModalOpen, setModalOpen] = useState(false);

  const { directory, loading } = useSubscriptionDirectoryDetails();

  const address = directory
    ? (
        (directory.streetAddress || '') +
        ', ' +
        directory.postCode +
        ' ' +
        directory.postArea +
        ' ' +
        directory.country
      ).trim()
    : '';

  const name = directory ? `${directory.firstName} ${directory.lastName}` : '';
  const i18n = t.mobile.settings.phonebook;
  if (loading) {
    return <Loading />;
  }

  if (directory) {
    return (
      <SettingsCard
        title={i18n.service}
        description={i18n.phonebookInfo}
        onClick={() => setModalOpen(true)}
      >
        {directory.visibility === 'ShowUser' ||
          (directory.visibility === 'ShowUserAndCompany' && (
            <>
              <SettingsListItem
                title={t.mobile.settings.name}
                description={name}
              />
              <SettingsListItem
                title={t.mobile.settings.address}
                description={address}
              />
            </>
          ))}
        <SettingsListItem
          title={i18n.showCompanyInformation}
          description={
            directory.visibility === 'ShowCompany'
              ? t.mobile.settings.activated
              : t.mobile.settings.notActivated
          }
        />
        <SettingsListItem
          title={i18n.reserveFromDirectory}
          description={
            directory.visibility === 'Hidden'
              ? t.mobile.settings.activated
              : t.mobile.settings.notActivated
          }
        />

        <DirectoryFormModal
          directory={directory}
          open={isModalOpen}
          toggle={() => setModalOpen(!isModalOpen)}
        />
      </SettingsCard>
    );
  }
  return null;
};
