import * as React from 'react';
import {
  Table,
  TableBodyRow,
  TableBodyCell,
  TableColumnDefintion,
  urlFor,
} from 'src/lib/ui';
import { formatDate } from 'src/lib/utils';
import { SearchResult } from 'src/areas/main/search';
import { ListQueryParams } from 'src/lib/utils/useListQueryParams';
import { Order } from 'src/lib/types';
import { mapOrderStatusToLabel, mapOrderStatustoIcon } from '../utils';
import { useRouter } from 'src/lib/utils/useRouter';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import { OrderColumns } from 'src/lib/table';
import { DeliveryDateLabel } from './DeliveryDateLabel';
interface OrdersTableProps {
  queryParams: ListQueryParams;
  results: SearchResult[];
  loading: boolean;
  columns: TableColumnDefintion[];
  availableColumns?: TableColumnDefintion[];
  setColumns: (columns: string[]) => void;
  noResultsMessage: string;
}

const OrdersTable: React.FunctionComponent<OrdersTableProps> = ({
  queryParams,
  loading,
  results,
  columns,
  noResultsMessage,
  ...props
}) => {
  const router = useRouter();
  return (
    <Table
      loading={loading}
      columns={columns}
      sortable={true}
      setSort={queryParams.setSort}
      sortBy={queryParams.sortBy}
      sortOrder={queryParams.sortOrder}
      rowsCount={queryParams.pageSize}
      noResult={results.length === 0}
      noResultMessage={noResultsMessage}
      availableColumns={props.availableColumns}
      onChangeColumns={props.setColumns}
    >
      {results.map(row => {
        const orderData = row as Order;

        return (
          <TableBodyRow
            key={orderData.id}
            onClick={() => {
              router.history.push(urlFor({ order: orderData.id }));
            }}
          >
            {columns.map((col, i) => (
              <OrderTableCell
                key={`${col.key}-${i}`}
                column={col.key as OrderColumns}
                orderData={orderData}
              />
            ))}
          </TableBodyRow>
        );
      })}
    </Table>
  );
};

const OrderTableCell = (props: { column: OrderColumns; orderData: Order }) => {
  const { column, orderData } = props;
  const statusLabel = mapOrderStatusToLabel(orderData.portalStatus);
  const statusIcon = mapOrderStatustoIcon(orderData.portalStatus);
  switch (column) {
    case 'portalStatus':
      return (
        <TableBodyCell>
          <div className="d-flex align-items-center">
            <Icon icon={statusIcon} className="mr-2" />
            {statusLabel}
          </div>
        </TableBodyCell>
      );
    case 'description':
      return (
        <TableBodyCell title={orderData.description ?? ''}>
          {orderData.description}
        </TableBodyCell>
      );
    case 'type':
      return (
        <TableBodyCell>
          {orderData.type ? mapOrderTypeToLabel(orderData.type) : null}
        </TableBodyCell>
      );
    case 'id':
      return <TableBodyCell title={orderData.id}>{orderData.id}</TableBodyCell>;
    case 'createdAt':
      return (
        <TableBodyCell>
          {orderData.dates?.createdAt
            ? formatDate(orderData.dates.createdAt, 'short')
            : ''}
        </TableBodyCell>
      );
    case 'updatedAt':
      return (
        <TableBodyCell>
          {orderData.dates?.updatedAt
            ? formatDate(orderData.dates.updatedAt, 'short')
            : ''}
        </TableBodyCell>
      );
    case 'deliveryDate':
      return (
        <TableBodyCell>
          <DeliveryDateLabel dates={orderData.dates} />
        </TableBodyCell>
      );
    default:
      return null;
  }
};

// TODO: temporary till we use generated types in search
type OrderType = 'subscription' | 'legacy' | 'mobile' | 'mobileUnit';

const mapOrderTypeToLabel = (type: string) => {
  const orderType = type as OrderType;

  if (orderType === 'subscription' || orderType === 'legacy') {
    return t.orders.OrdersTable.network;
  } else if (orderType === 'mobile' || orderType === 'mobileUnit') {
    return t.orders.OrdersTable.mobile;
  }
  return t.orders.OrdersTable.other;
};

export default OrdersTable;
