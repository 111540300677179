import * as React from 'react';
import { Spinner, SpinnerProps } from '../Spinner/Spinner';

type Props = SpinnerProps & {
  height?: 'extra-small' | 'small' | 'medium' | 'large';
  loadingText?: string;
};

export const Loading: React.FC<Props> = ({
  height,
  loadingText,
  ...spinnerProps
}) => (
  <div
    style={{
      width: '100%',
      minHeight:
        height === 'large'
          ? '300px'
          : height === 'small'
          ? '80px'
          : height === 'extra-small'
          ? '20px'
          : '150px',
      textAlign: 'center',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      ...spinnerProps.style,
    }}
  >
    <div className="d-flex flex-column align-items-center">
      <Spinner {...spinnerProps} />
      {loadingText && <div className="mt-2">{loadingText}</div>}
    </div>
  </div>
);
