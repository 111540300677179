import * as React from 'react';
import cx from 'classnames';
import moment from 'moment';

import { DayPickerSingleDateController } from 'react-dates';
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';

import { Button } from '../Button/Button';

import './DatePickerField.scss';
import { t } from 'src/lib/i18n';
import { FormFieldWrapper } from 'src/lib/ui';
import { generateUeid } from 'src/lib/utils';
import { Icon } from '@telia/styleguide';
import { Loading } from '../Loading/Loading';

export type DatePickerFieldProps = {
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  innerRef?: React.RefObject<HTMLInputElement>;
  'aria-label'?: string;
  'aria-activedescendant'?: string;
  'aria-autocomplete'?: 'none' | 'inline' | 'list' | 'both';
  'aria-controls'?: string;
  // 'aria-labelledby'?: string;
  autoComplete?: string;
  onBlur?: () => void;
  onFocus?: () => void;
  onChange?: (dateString: string) => void;
  showPicker?: boolean;
  isOutsideRange?: (day: any) => boolean;
  loading?: boolean;
  className?: string;
  required?: boolean;
  success?: boolean;
  error?: string;
  label?: string;
  helpText?: string;
  subTextPosition?: 'left' | 'right';
} & (
  | {
      showPicker: true;
      pickerOnly?: boolean;
    }
  | { showPicker?: false }
);

interface DatePickerFieldState {
  pickerOpen: boolean;
  focused: boolean;
}

export class DatePickerField extends React.Component<
  DatePickerFieldProps,
  DatePickerFieldState
> {
  private fieldGuid: string | undefined;
  constructor(props) {
    super(props);
    if (props.label) {
      this.fieldGuid = generateUeid();
    }
    this.state = {
      pickerOpen: false,
      focused: false,
    };
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label) {
      this.fieldGuid =
        this.props.label === undefined ? undefined : generateUeid();
    }
  }

  onDateChange = (date: moment.Moment | null) => {
    if (this.props.onChange && date && date.isValid()) {
      this.props.onChange(date.format('DD.MM.YYYY'));
    }
  };

  onFocusChange = ({ focused }) => {
    if (focused === false && this.props.onBlur) {
      this.props.onBlur();
    }
    this.setState({ pickerOpen: focused });
  };

  dateStringToMoment = (value: string | undefined) => {
    const momentDate = moment(value, 'DD.MM.YYYY');

    if (!value) {
      return null;
    }

    if (momentDate.isValid()) {
      return momentDate;
    }

    return null;
  };

  onInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (this.props.onChange) {
      return this.props.onChange(event.currentTarget.value);
    }
  };

  onInputBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (this.props.onBlur) {
      return this.props.onBlur();
    }
  };

  onInputClick = () => {
    if (this.props.showPicker === true && this.props.pickerOnly) {
      this.setState({ focused: true, pickerOpen: true });
    }
  };

  togglePicker = () => {
    this.setState(
      prevState => ({ pickerOpen: !prevState.pickerOpen }),
      () => {
        // if picker closes, run onBlur() for validation
        if (this.state.pickerOpen === false && this.props.onBlur) {
          this.props.onBlur();
        }
      }
    );
  };

  getStatusIcon = (): React.ReactNode => {
    const { error, success, loading } = this.props;
    /**
     * Render priority
     * 1. Success
     * 2. Error
     * 3. Loading
     */
    if (success) {
      return <Icon icon="check-mark" />;
    }
    if (error) {
      return <Icon icon="alert" />;
    }
    if (loading) {
      return <Loading size="xs" style={{ minHeight: '1rem' }} />;
    }
    return null;
  };

  render() {
    const statusIcon = this.getStatusIcon();

    return (
      <FormFieldWrapper
        className={this.props.className}
        error={this.props.error}
        required={this.props.required}
        helpText={this.props.helpText}
        label={
          this.props.label
            ? `${this.props.label} ${t.ui.DatePicker.expectedFormat}`
            : undefined
        }
        success={this.props.success}
        subTextPosition={this.props.subTextPosition}
        labelId={this.fieldGuid}
      >
        <div
          className={cx('DatePickerField', 'input-group', {
            disabled: !!this.props.disabled,
            isAppended: !!statusIcon,
            success: !!this.props.success,
            error: !!this.props.error,
          })}
        >
          <input
            id={this.props.id}
            type="text"
            className="form-control" // for BS styling
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            value={this.props.value}
            ref={this.props.innerRef}
            aria-label={this.props['aria-label']}
            aria-activedescendant={this.props['aria-activedescendant']}
            aria-autocomplete={this.props['aria-autocomplete']}
            aria-controls={this.props['aria-controls']}
            aria-labelledby={this.fieldGuid}
            autoComplete={this.props.autoComplete}
            onFocus={this.props.onFocus}
            onClick={this.onInputClick}
            onBlur={this.props.onBlur}
            onChange={this.onInputChange}
            readOnly={this.props.showPicker === true && this.props.pickerOnly}
          />
          {statusIcon ? (
            <div className="input-group-append">
              <span className="input-group-text">{statusIcon}</span>
            </div>
          ) : null}

          {this.props.showPicker && (
            // Wrap button in a container to avoid it trying to match height of the inputfield
            <span className="position-relative ml-1">
              <Button
                type="button"
                icon="calendar"
                rounded={true}
                outline={true}
                size="small"
                color="white"
                hideLabel={true}
                onClick={this.togglePicker}
                style={{
                  position: 'absolute',
                  top: '50%',
                  transform: 'translateY(-50%)',
                }}
              >
                {t.ui.DatePicker.showCalendar}
              </Button>
            </span>
          )}
          {this.state.pickerOpen && (
            <DayPickerSingleDateController
              onDateChange={this.onDateChange}
              onFocusChange={this.onFocusChange}
              focused={this.state.pickerOpen}
              date={this.dateStringToMoment(this.props.value)}
              onOutsideClick={this.togglePicker}
              isOutsideRange={this.props.isOutsideRange}
            />
          )}
        </div>
      </FormFieldWrapper>
    );
  }
}
