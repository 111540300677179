import React from 'react';

export function Json(props: { data: any }) {
  return (
    <pre
      className="Json"
      style={{
        backgroundColor: 'black',
        padding: '4px',
        fontFamily: 'Consolas, monospace',
        borderRadius: '3px',
        fontSize: '9px',
        lineHeight: '11px',
        transition: 'all 0.1s ease-in-out',
        color: 'rgb(226, 187, 95)',
      }}
    >
      {typeof props.data === 'string'
        ? props.data
        : JSON.stringify(props.data, null, 2)}
    </pre>
  );
}
