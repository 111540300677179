import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';

import { Icon, IconDefinition } from '@telia/styleguide';
import { CSSTransition } from 'react-transition-group';

import { trackShortcuts } from 'src/lib/analytics';

import './PersonalizedDashboardKpiCard.scss';

interface Props {
  icon?: IconDefinition;
  label?: string;
  value?: string | number | undefined | null;
  indicator?: 'red' | 'orange' | 'green' | undefined | null;
  marker?: string;
  loading?: boolean;
  className?: string;

  /**
   * If present, clicking the card will take the user here.
   */
  linkTo?: string;
}

const Thumb = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 64 64"
    className="PersonalizedDashboardKpiCard-thumb"
  >
    <path
      fill="#0C6"
      fillRule="evenodd"
      d="M35.712 13.26l.133.052 1.967 1.007a5 5 0 0 1 2.295 2.438l.35.796a5 5 0 0 1 .371 2.719l-.595 4.148-.539 4.949a1.7 1.7 0 0 0 .145.891.244.244 0 0 0 .206.142l9.38.597a5 5 0 0 1 2.649.964l.786.58a3.003 3.003 0 0 1 .637 4.198l-.065.086-.423.498a1 1 0 0 0 .013 1.313l.286.322a3.72 3.72 0 0 1 .24 4.635l-.797 1.113a1 1 0 0 0-.01 1.15l.37.537a3.427 3.427 0 0 1-.438 4.412l-1.166 1.127a1 1 0 0 0-.28.943l.206.9a3.633 3.633 0 0 1-1.889 4.047l-1.62.827a5 5 0 0 1-1.232.437l-2.072.441a5 5 0 0 1-1.041.11h-12.85a5 5 0 0 1-1.763-.321L24 57.446V58a2 2 0 0 1-2 2H12a2 2 0 0 1-2-2V31a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v1.989l2.323-1.185a3 3 0 0 0 1.354-1.4l4.57-9.757a3 3 0 0 0 .216-.641l1.048-4.867a5 5 0 0 1 .332-1.007l.035-.078a1.487 1.487 0 0 1 1.834-.795zM22 31H12v27h10l-.002-1.938-.001-.03V34.623L22 34.56V31zm13.51-15.615l-.023.087-1.069 4.955a5 5 0 0 1-.36 1.068l-4.57 9.758a5 5 0 0 1-2.256 2.333L24 35.234V55.31l5.67 2.136a3 3 0 0 0 1.059.193h12.85a3 3 0 0 0 .625-.066l2.072-.441a3 3 0 0 0 .74-.262l1.619-.828a1.633 1.633 0 0 0 .849-1.818l-.206-.9a3 3 0 0 1 .839-2.828l1.167-1.127c.502-.486.58-1.263.182-1.838l-.37-.536a3 3 0 0 1 .03-3.452l.797-1.113a1.72 1.72 0 0 0-.112-2.142l-.285-.323a3 3 0 0 1-.038-3.938l.355-.415.044-.057a1.003 1.003 0 0 0-.213-1.402l-.786-.58a3 3 0 0 0-1.59-.578l-9.38-.597a2.244 2.244 0 0 1-1.898-1.305 3.7 3.7 0 0 1-.314-1.94l.543-4.983.6-4.182a3 3 0 0 0-.224-1.631l-.35-.796a3 3 0 0 0-1.376-1.463l-1.39-.713zM17 50a2 2 0 1 1 0 4 2 2 0 0 1 0-4zm10-34a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2h4zm22 0a1 1 0 0 1 0 2h-4a1 1 0 0 1 0-2h4zM27.293 8.879l2.828 2.828a1 1 0 1 1-1.414 1.414l-2.828-2.828a1 1 0 1 1 1.414-1.414zm18.828 0a1 1 0 0 1 0 1.414l-2.828 2.828a1 1 0 1 1-1.414-1.414l2.828-2.828a1 1 0 0 1 1.414 0zM36 5a1 1 0 0 1 1 1v4a1 1 0 0 1-2 0V6a1 1 0 0 1 1-1z"
    />
  </svg>
);

const Dots = () => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    className="PersonalizedDashboardKpiCard-dots"
    viewBox="0 0 289 72"
  >
    <g fill="#0C6" fillRule="evenodd">
      <circle cx="192.782" cy="31.782" r="2.5" />
      <circle cx="214.282" cy="68.282" r="1" />
      <circle cx="39.282" cy="60.282" r="1" />
      <circle cx="98.782" cy="68.782" r="2.5" />
      <circle cx="286.782" cy="53.782" r="1.5" />
      <circle cx="70.782" cy="17.782" r="1.5" />
      <path d="M220.63 2.439l4.495 2.192-2.192 4.494-4.494-2.192zM99.63 18.439l2.697 1.315-1.315 2.696-2.696-1.315zM228.86 48.885l2.818-1.026 1.026 2.82-2.819 1.025zM253.201 15.825l1.88-.684.684 1.88-1.88.683zM128 .684L129.88 0l.683 1.88-1.879.683zM0 43.684L1.88 43l.683 1.88-1.879.683zM72.634 45.666l2.264 1.968-1.968 2.264-2.264-1.968z" />
    </g>
  </svg>
);

function SuccessOverlay() {
  return (
    <div className="PersonalizedDashboardKpiCard-success-overlay">
      <Dots />
      <Thumb />
    </div>
  );
}

export default function PersonalizedDashboardKpiCard(props: Props) {
  const isSuccess = props.value === 0 || props.value === '0';
  const indicator = isSuccess ? 'green' : props.indicator;

  const cardProps = {
    className: cn(
      'PersonalizedDashboardKpiCard',
      props.loading ? 'PersonalizedDashboardKpiCard--loading' : undefined,
      isSuccess && props.linkTo
        ? 'PersonalizedDashboardKpiCard--success'
        : undefined,
      props.linkTo ? undefined : 'disabled',
      props.className
    ),
    style: {
      cursor: props.linkTo ? 'pointer' : undefined,
    },
  };

  const [showSuccess, setShowSuccess] = useState(false);

  useEffect(() => {
    if (showSuccess === true) {
      const tm = window.setTimeout(() => {
        setShowSuccess(false);
      }, 2500);
      return () => {
        clearTimeout(tm);
      };
    }
  }, [showSuccess]);

  function onClick(e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) {
    if (isSuccess) {
      e.preventDefault();
      setShowSuccess(!showSuccess);
    }
    trackShortcuts(props.label ?? '', 'redirect');
  }

  const children = (
    <>
      {props.icon && (
        <div
          className={cn(
            'PersonalizedDashboardKpiCard-icon',
            props.indicator
              ? `PersonalizedDashboardKpiCard-icon--${indicator}`
              : null
          )}
        >
          <Icon icon={props.icon} />
        </div>
      )}

      {props.label && (
        <div className="PersonalizedDashboardKpiCard-label">{props.label}</div>
      )}

      {props.value !== undefined && props.value !== null ? (
        <div className="PersonalizedDashboardKpiCard-value">{props.value}</div>
      ) : null}

      <CSSTransition
        in={showSuccess}
        timeout={300}
        classNames="DashboardKpiCard-animation"
        unmountOnExit
      >
        <SuccessOverlay />
      </CSSTransition>
    </>
  );

  if (props.linkTo) {
    return (
      <Link {...cardProps} to={props.linkTo} onClick={onClick}>
        {children}
      </Link>
    );
  }

  return <div {...cardProps}>{children}</div>;
}
