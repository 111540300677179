import { partition } from 'lodash';
import { groupBy } from 'lodash';
import { OrderLineFragment } from 'src/lib/types/order/__types/OrderLineFragment';

export const groupCasesByParentId = (
  cases: OrderLineFragment[]
): Array<{
  parent: OrderLineFragment | string;
  children: OrderLineFragment[];
}> => {
  const [casesWithoutParent, casesWithParent] = partition(
    cases,
    p => p.parentId === ''
  );
  const grouped = groupBy(casesWithParent, 'parentId');
  const casesWithoutParentOrChild = casesWithoutParent.filter(
    c => !Object.keys(grouped).includes(c.id)
  );

  const mappedGroupedCases = Object.keys(grouped).map(c => ({
    parent: cases.find(({ id }) => id === c) ?? c,
    children: grouped[c] || [],
  }));
  const mappedCasesWithoutParent = casesWithoutParentOrChild.map(c => ({
    parent: c,
    children: [],
  }));

  return [...mappedCasesWithoutParent, ...mappedGroupedCases];
};

export function isOrderLineFragment(
  caseItem: OrderLineFragment | string
): caseItem is OrderLineFragment {
  return (caseItem as OrderLineFragment).id !== undefined;
}
