import React, { useState, useEffect } from 'react';
import { VoicemailMobileSettings } from 'src/lib/types';
import { useUpdateSubscriptionVoicemailSetting } from 'src/areas/mobile/settings/mutations/useUpdateSubscriptionVoicemailSetting';
import Modal from 'reactstrap/lib/Modal';
import { ModalHeader, ModalFooter } from 'reactstrap';
import ModalBody from 'reactstrap/lib/ModalBody';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { t } from 'src/lib/i18n';
import { colors, FormSubmitBar } from 'src/lib/ui';
import { Toggle } from '@telia/styleguide/business';

interface Props {
  voicemailSettings: VoicemailMobileSettings;
  isOpen: boolean;
  toggle: () => void;
}

const VoiceMailFormModal: React.FC<Props> = ({
  voicemailSettings,
  ...props
}) => {
  const [sms, setSms] = useState(voicemailSettings.smsNotification);
  const {
    updateSettings,
    data,
    loading,
    error,
  } = useUpdateSubscriptionVoicemailSetting();
  const i18n = t.mobile.settings.voicemail;

  useEffect(() => {
    if (data && !error) {
      props.toggle();
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    updateSettings({ ...voicemailSettings, smsNotification: sms });
  };
  return (
    <Modal isOpen={props.isOpen} size="lg" toggle={props.toggle}>
      <ModalHeader>{i18n.voicemail}</ModalHeader>
      <ModalBody>
        <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
          {i18n.voicemailInfo}
        </small>

        <SettingsListItem
          className="border-0 px-0"
          title={i18n.notificationOnSMS}
          description={i18n.sendSmsOnMessage}
          toggle={<Toggle checked={sms} onChange={() => setSms(!sms)} />}
        />
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={submit}
          cancel={props.toggle}
          loading={loading}
          errorMsg={t.mobile.settings.modalFields.unableToSave}
          error={error}
        />
      </ModalFooter>
    </Modal>
  );
};

export default VoiceMailFormModal;
