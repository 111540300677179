import * as React from 'react';

import { Button } from '../Button/Button';

import './LoadMoreButton.scss';
import { t } from 'src/lib/i18n';

export interface LoadMoreButtonProps {
  onClick?: () => void;
  disabled?: boolean;
  label?: string;
}

export const LoadMoreButton: React.FC<LoadMoreButtonProps> = props => (
  <div className="LoadMoreButton col-md-8 col-lg-6 offset-md-2 offset-lg-3 text-center mt-5">
    <span>
      <Button
        color="white"
        rounded={true}
        outline={true}
        onClick={props.onClick}
        disabled={props.disabled}
      >
        {props.label || t.ui.LoadMoreButton.showMore}
      </Button>
    </span>
  </div>
);
