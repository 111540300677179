import React from 'react';
import { t } from 'src/lib/i18n';
import { colors } from 'src/lib/ui';

export const DifferentVendor = () => {
  const i18n = t.subscriptions.DifferentVendor;
  return (
    <div className="mx-auto pl-4 py-4 my-4 border-left">
      <h3 className="mb-4">{i18n.title}</h3>
      <div style={{ color: colors.greyDarkText }}>{i18n.helpText}</div>
    </div>
  );
};
