import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { useQuery } from 'react-apollo';
import * as t from './__types/usePlannedWorkOnSubscription';

export const QUERY_PLANNED_WORK = gql`
  query usePlannedWorkOnSubscription($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        plannedWork {
          id
          changeNumber
          state
          serviceImpact
          description
          dates {
            plannedStartDate
            plannedEndDate
            createdAt
            updatedAt
            oldEndDate
            oldStartDate
          }
          expectedTotalDowntimeInMinutes
          impactedSubscriptionIds
          closeCode
          cancelReason
          onHoldReason
          onHold
          customer {
            id
          }
        }
      }
    }
  }
`;

export const usePlannedWorkOnSubscription = (subscriptionId?: string) => {
  const customer = useCustomer();

  const { data, loading, error } = useQuery<
    t.usePlannedWorkOnSubscription,
    t.usePlannedWorkOnSubscriptionVariables
  >(QUERY_PLANNED_WORK, {
    skip: !subscriptionId,
    variables: {
      customerId: customer.id,
      subscriptionId: subscriptionId ?? '',
    },
  });
  return {
    plannedWork: data?.customer?.subscriptions?.[0]?.plannedWork,
    error,
    loading,
  };
};
