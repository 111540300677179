import { formatDate } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import { pickMostPreciseDeliveryDate } from './pickMostPreciseDeliveryDate';

interface GetDateAttributesFromOrderLineDates {
  createdAt?: string | null;
  committedDeliveryDate?: string | null;
  estimatedDeliveryDate?: string | null;
  plannedDeliveryDate?: string | null;
}

export function getDateAttributesFromOrderLine(
  dates?: GetDateAttributesFromOrderLineDates | null
) {
  const i18n = t.orders.ServiceOrderContent.OrderLinesTable;
  const res: Array<{ label: string; value: string }> = [];

  // Always include createdAt if present
  if (dates?.createdAt) {
    res.push({
      label: i18n.created,
      value: formatDate(dates.createdAt, 'short'),
    });
  }

  const mostPreciseDate = pickMostPreciseDeliveryDate(dates);

  if (mostPreciseDate) {
    res.push({
      label: mostPreciseDate.status,
      value: formatDate(mostPreciseDate.date, 'short'),
    });
  }

  return res;
}
