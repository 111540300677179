import { customerSupportNumber, customerSupportEmail } from './../../variables';
const contacts = {
  table: {
    name: 'Navn',
    phone: 'Telefon',
    email: 'E-post',
    employeeNumber: 'Ansattnummer',
    description: 'Beskrivelse',
    tags: 'Tilgang til tags',
    invoiceCenter: 'Tilgang til fakturasted',
    access: 'Tilgang',
    cases: 'Saker',
  },
  title: 'Kontakter',
  type: 'Type',
  btnCreate: 'Opprett ny kontakt',
  contactWithAccess: 'Kontakter med tilgang',
  contactWithoutAccess: 'Kontakter uten tilgang',
  btnFilterAdmin: 'Administrator',

  sortFields: {
    lable: 'Sorter',
    AtoÅ: 'A til Å',
    ÅtoA: 'Å til A',
    lastName: 'Etternavn',
    firstName: 'Fornavn',
    email: 'Epost',
  },
  status: {
    waitingActivation: 'Venter på aktivering',
    userHasNotVerifiedEmail:
      'Denne kontakten har ikke bekreftet sin epost adresse.',
  },
  listingNoResult: 'Ingen kontakter',

  EditContact: {
    title: 'Endre kontaktinformasjon',
    description: 'Denne informasjonen vil være synlig for andre i Min Portal',
    errors: {
      failed: 'Oi, vi kunne ikke oppdatere kontakten',
      required: 'Påkrevd',
      phone: 'Ugyldig telefonnummer',
      email: 'Ugyldig epost',
      tooLong: (num: number) => `Maks ${num} tegn`,
      tooShort: (num: number) => `Minst ${num} tegn`,
    },
    changeEmailAddress: 'Ønsker du å endre e-postadresse?',
    lookingForUserSettings:
      'Ser du etter innstillingene for brukerkontoen din?',
    adminChangeEmailInfoText: `I dag er det ikke mulig å endre e-postadressen som er knyttet til en brukerkonto. For å gjøre dette må brukeren selv ta kontakt med kundeservice via chat, ved å ringe ${customerSupportNumber}  eller ved å sende en mail til ${customerSupportEmail} så skal vi hjelpe dem med dette.`,
    userChangeEmailInfoText: `I dag er det dessverre ikke mulig å endre e-postadressen som er knyttet til brukerkontoen din. Har du behov for å bytte e-postadresse kan du ta kontakt med kundeservice på chat, ved å ringe ${customerSupportNumber} eller ved å sende en mail til ${customerSupportEmail} så skal vi hjelpe deg med dette.`,
    ifYouWishToChangePassword: 'Dersom du ønsker å endre passord',
    ifYouWishToChangeMFA:
      'Dersom du ønsker å endre Dersom du ønsker å endre totrinnsbekreftelse',
    youCanDoThatHere: 'kan du gjøre det her',
  },
  editRoles: {
    access: 'Tilganger',
    accessAndNotificationsCopied: 'Tilganger og varslinger er kopiert',
    accessToMinPortal: 'Tilganger i Min Portal',
    cancel: 'Avbryt',
    close: 'Lukk',
    confirm: 'Bekreft',
    confirmAccess: 'Godkjenn tilganger',
    confirmNotifications: 'Godkjenn varsler',
    copyAccess: 'Kopier tilganger og varsler',
    copyAccessWarning:
      'NB! Dette vil overskrive de originale innstillingene til',
    copyAndSave: 'Kopier og lagre',
    economy: 'Økonomi',
    from: 'fra',
    fromAnotherUser: 'fra en annen bruker',
    giveAccess: 'Gi tilganger og varsler',
    editAccess: 'Endre tilganger og varsler',
    givingAccessFor: 'Du gir nå tilganger til',
    editingAccessFor: 'Du endrer nå tilganger til',
    incidents: 'Feilmeldinger',
    noNotifications: 'Ingen varsler',
    notifications: 'Varsler',
    operations: 'Drift',
    order: 'Bestilling',
    plannedWork: 'Planlagt arbeid',
    removeAllAccess: 'Fjern alle tilganger til Min Portal',
    removeAllAccessConfirmation:
      'Er du sikker på at du ønsker å fjerne alle tilganger og varsler for',
    reviewNotifications: 'Se over varslinger',
    notificationsInfo1: 'Du endrer nå varslinger på vegne av',
    notificationsInfo2:
      'Som administrator kan du legge til tjenester som er utenfor tilgangsnivået til',
    notificationChangeAccesses:
      'Du kan bare endre varslinger dersom det er din egen bruker, eller hvis du er administrator.',
    selectPerson: 'Velg person',
    somethingWentWrongNotifications:
      'Noe gikk galt med kopiering av varsler. Vennligst prøv igjen senere eller kontakt kundeservice.',
    somethingWentWrongRoles:
      'Noe gikk galt med kopiering av roller. Vennligst prøv igjen senere eller kontakt kundeservice.',
    somethingWentWrong:
      'Kopiering av roller og varsler gikk galt. Vennligst prøv igjen senere eller kontakt kundeservice.',
  },
  editServices: {
    editContactResponsibilities: 'Endre kontaktansvar',
    step1: {
      whatChangesDoYouWantToMake: 'Hvilken endring ønsker du å gjøre?',
      option1: 'Flytt hele kontaktansvaret til én annen kontakt',
      option2: 'Gjør endringer på enkelte tjenester',
      option3: 'Fjern hele kontaktansvaret',
      description: (num: number) =>
        `Denne kontakten er kontaktperson for ${num +
          ' ' +
          (num !== 1
            ? 'tjenester'
            : 'tjeneste')}. Hvordan ønker du å endre kontaktansvaret?`,
    },
    step3: {
      removeAll: 'Fjern hele kontaktansvaret',
    },
  },
  DeletePerson: {
    deleteError: 'Vi klarte ikke slette kontakten',
    replaceError: 'Vi klarte ikke å overføre kontaktansvaret',
    step1: {
      replaceContactPerson: 'Erstatt kontaktperson',
      description1:
        'Ved sletting fjernes kontakten som kontaktperson på alle tjenester og assosierte feilmeldinger.',
      description2: (num: number) =>
        `Denne kontakten er kontaktperson for ${num +
          ' ' +
          (num !== 1
            ? 'tjenester'
            : 'tjeneste')}. Ønsker du å øverføre kontaktansvaret før du går videre?`,
      option1: 'Ja, overfør alt ansvar til én annen kontakt',
      option2: 'Ja, sett ansvar på nytt per tjeneste',
      option3: 'Nei, slett kontakten uten å overføre ansvar',
    },
    step2: {
      selectReplacement: 'Sett ny kontaktperson',
      newContactPerson: 'Ny kontaktperson',
      replaceByService: 'Sett ny kontaktperson per tjeneste.',
      leaveEmpty:
        'Dersom du ikke velger en ny kontakt for en tjeneste fjernes kontaktansvaret uten overføring til en ny kontakt.',
      replaceAll: (num: number) =>
        `Sett ny kontaktperson for ${num +
          ' ' +
          (num !== 1 ? 'tjenester' : 'tjeneste')}.`,
    },
    step3: {
      description:
        'Kontakten slettes fra systemet. Handlingen kan ikke angres.',
      replaceNumSubscriptions: (num: number) =>
        `Du erstatter kontaktansvaret på ${num +
          ' ' +
          (num !== 1 ? 'tjenester' : 'tjeneste')}`,
      replaceAll: 'Du erstatter kontaktansvar for alle tjenester',
      noReplacements: 'Du fjerner all kontaktansvar uten noen erstatning',
      noReplacementNumSubscriptions: (num: number) =>
        `Du fjerner kontaktansvaret på ${num +
          ' ' +
          (num !== 1 ? 'tjenester' : 'tjeneste')} uten noen erstatning`,
    },
    deleteContact: 'Er du sikker på at du vil slette kontakten?',
    deleteIsTemporaryDisabled:
      'Det er for øyeblikket ikke mulig å slette kontakter fordi vi oppdaterer noen av systemene våre. Vi beklager ulempen.',
    delete: 'Slett',
    contact: 'kontakten',
    title: (name: string) => `Ønsker du å slette ${name}?`,
    error: (name: string) =>
      `Vi klarte ikke å slette ${name}. Prøv igjen senere`,
    contactOnActiveCaseDeleteError: (name: string) =>
      `Vi klarte ikke å slette ${name} fordi kontakten er innmelder på en pågående feilmelding. Vent til den er løst, eller lukk feilmeldingen først for å slette kontakten.`,
    deletionWarningUser: (
      name: string
    ) => `Når du sletter ${name} fjernes kontakten permanent. Du
          vil ikke ha mulighet til å angre slettingen`,
    deletionWarningContact: (
      name: string
    ) => `Når du sletter ${name} fjernes kontakten permanent. Du
          vil ikke ha mulighet til å angre slettingen`,
    btnDelete: 'slett',

    /** Remove once new contact is done */
    cannotDeleteUserDescription: (
      name: string
    ) => `Fordi ${name} har brukertilganger er det for
          øyeblikket ikke mulig å slette`,
  },

  formLabels: {
    accessToMinPortal: 'Tilgang til Min Portal',
    firstName: 'Fornavn',
    lastName: 'Etternavn',
    email: 'Epost',
    mobilePhone: 'Telefonnummer (primær)',
    secondaryPhone: 'Telefonnummer (sekundær)',
    employeeNumber: 'Ansattnummer',
    description: 'Beskrivelse',
  },

  breadCrumb: 'Kontakter',
  permissions: 'Tilganger og varslinger',
  admin: 'Administrator',
  fullAccess: 'Full tilgang',
  partialAccess: 'Begrenset tilgang',
  accessToTags: 'Tilgang til følgende tags',
  accessToOrganisations: 'Tilgang til følgende fakturasteder',
  accessTo: {
    services: 'til tjenester',
    finance: 'til kostnader',
    ordering: 'til bestilling',
  },
  create: {
    createNew: 'Lag ny',
    step1: {
      yes: 'Ja',
      no: 'Nei',
      title: 'Skal kontakten ha tilgang til Min Portal?',
      description:
        'Når du oppretter en ny kontakt vil informasjonen vises i kontaktlisten, og kontakten kan settes som kontaktperson på en tjeneste eller feilmelding. Du kan i tillegg gi kontakten tilgang til Min Portal',
    },
    step2: {
      removeContact: 'Fjern valgt kontakt',
      title: 'Kontaktinformasjon',
      description:
        'Denne informasjonen brukes til å opprette en ny, unik kontakt og vil være synlig i kontaktlisten.',
      moveForwardToGiveRoles: (name: string) =>
        `Gå videre for å gi brukertilganger til ${name}`,
      contactExists: `Kontakten finnes`,
      useExists: `Kontaken har allerede tilgang `,
      clickToEditUser:
        'Du kan endre roller og informasjon fra kontaktsiden ved å klikke på kontakten over',
      clickToEditContact:
        'Du kan endre informasjon fra kontaktsiden ved å klikke på kontakten over',
    },
    step3: {
      title: 'Tilganger',
    },
    emailInfo_part1:
      'Når du oppretter den nye kontakten kan de aktivere kontoen sin selv. Det gjør de ved å gå til',
    emailInfo_part2: ', oppgi e-posten sin, og bekrefte med BankID.',
    emailInfo_part3:
      'Dessverre blir de ikke varslet via e-post fordi systemene våre byttes ut. Vi beklager, og håper det ikke skaper for mange problemer. Inntil videre anbefaler vi at du gir beskjed til den nye kontakten om at brukeren er opprettet.',
    whatHappensNext: 'Hva skjer videre?',
    defaultError:
      'Oj, unnskyld. Vi klarte ikke å opprette kontakten for deg. Prøv på nytt',
  },
  contact: 'Kontakt',
  withAccess: 'tilgang til Min Portal',
  contactResponsibilities: 'Kontaktansvar',
  noContactPerson: 'Denne kontakten er ikke kontaktperson for noen tjenester',
  contactPerson: (num: number) =>
    `Kontaktperson for ${num + ' ' + (num === 1 ? 'tjeneste' : 'tjenester')}`,
  noPermissions: (name: string) => `${name} har ikke tilgang til Min Portal`,
  // btnCreateUser: 'Lag bruker',
  mobileSubscription: 'Abonnement',
  noSubscription: (name: string, customerName: string) =>
    `${name} har ikke mobilabonnement gjennom ${customerName}`,

  descriptionHelpText: 'f.eks stillingstittel eller ansvarsområde',
};

export default contacts;
