import Nb from '../../nb/Main/tags';

const tags: typeof Nb = {
  create: 'Create new tag',
  edit: 'Edit information',
  delete: 'Delete tag',
  deleteButton: 'delete',
  deleteInformation:
    'A tag can only be deleted if it is not attached to any services and if it is not used to control access',
  noResults: 'No results',
  sortFields: {
    lable: 'Sort',
    name: 'Name',
    AtoÅ: 'A to Å',
    ÅtoA: 'Å to A',
  },
  descriptionHeader: 'What are tags?',
  descriptionBody:
    'Tags allow you to manage your services in a way that suits your company. They can be used to filter lists and search results, group services in reports, and control who should have access to the services in the portal.',
  form: {
    tag: 'Name',
    description: 'Description',
  },
  error: {
    deleteTag:
      'The tag has services attached to it or is being used to control access.',
    createAndEditTag: 'A tag with the same name already exists',
  },
  success: {
    tagCreated: (tag: string) =>
      `Tag ${tag} was created. Try to search for it in the list`,
  },
};

export default tags;
