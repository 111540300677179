import * as React from 'react';
import { map } from 'lodash';
import cs from 'classnames';

import { Badge } from '../Badge/Badge';
import { Tags } from '../Tags/Tags';

import './ListResultCard.scss';
import { UrlForObject, urlFor } from '../LinkTo/urlFor';
import { StatusColor } from 'src/lib/types';
import { Icon, IconDefinition } from '@telia/styleguide';
import { Checkbox } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils';

export type ListResultCardProps = {
  /**
   * The main icon for the search result.
   */
  icon?: IconDefinition;

  /**
   * An optional alternative color/status marking for the icon.
   */
  iconColor?: StatusColor;

  /**
   * An optional description to explain icon
   */
  iconDescription?: string;

  /**
   * Make icon spin
   */
  iconSpin?: boolean;

  /**
   * The main title for the card.
   */
  title: string;

  /**
   * What element the card will be rendered with. Default is `li`.
   */
  renderAs?: 'li' | 'div';

  /**
   * What element will be used to render the card title. Default is `h2`.
   */
  renderTitleAs?: 'h2' | 'h3' | 'h1';

  /**
   * The tags belo
   */
  tags?: Array<{
    id: string;
    tag: string;
  }>;

  /**
   * The attributes to display below the card title.
   */
  attributes?: Array<
    | {
        label?: string;
        value: React.ReactNode;
      }
    | null
    | undefined
  >;

  /**
   * What badge to display next to the card title. Provide a `<Badge>` element, a string (will create a default badge) or a fragment to contain multiple badges.
   */
  badge?: React.ReactNode | string;

  className?: string;

  /**
   * Display a secondary/associated result below the main result. Used for addon/redundancy subscriptions.
   */
  secondaryResult?: {
    icon?: IconDefinition;
    iconColor?: StatusColor;
    title: string;
  };

  /**
   *  Display sub-results below the main results. Used for addon/redundancies subscriptions.
   */
  subResults?: Array<{
    icon?: IconDefinition;
    iconColor?: StatusColor;
    title: string;
  }>;

  /**
   * Action to display for the card. Typically a ContextMenu, could be a Button -- should be a small component.
   */
  action?: React.ReactNode;

  /**
   * Contextual, result-specific components to display in the card.
   */
  contextual?: React.ReactNode;

  /**
   * To what page the list result card should link by default (the card may contain other click/event handlers like buttons etc.).
   */
  linkTo?: UrlForObject;

  /**
   * Optional onClick handler when clicking on the card
   */
  onClick?: () => void;
} & ({ onSelect: () => void; selected: boolean } | { selectable?: false });

export const ListResultCard: React.FC<ListResultCardProps> = props => {
  const router = useRouter();
  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    if (props.onClick) {
      props.onClick();
    }
    if (props.linkTo) {
      router.history.push(urlFor(props.linkTo));
    }
  };

  const onKeyUp = (e: React.KeyboardEvent<HTMLElement>) => {
    if (props.linkTo) {
      // If we pressed Enter __and the event originated from the element itself,
      // we proceed with the redirect. We don't want to redirect if e.g. a nested
      // <input> in a modal triggered the keypress.
      if (e.key === 'Enter' && e.target === e.currentTarget) {
        e.stopPropagation();
        router.history.push(urlFor(props.linkTo));
      }
    }
    // Continue handling key event
    return true;
  };

  const Element = props.renderAs || 'li';
  const TitleElement = props.renderTitleAs || 'h2';
  const selectable = 'onSelect' in props;
  const onSelect = 'onSelect' in props ? props.onSelect : undefined;
  const selected = 'selected' in props && props.selected;
  const badge =
    typeof props.badge === 'string' ? (
      <Badge>{props.badge}</Badge>
    ) : (
      props.badge
    );

  const attributes = map(props.attributes, (attribute, ix) => {
    if (!attribute) {
      return null;
    }
    return (
      <span key={ix} className="ListResultCard-attribute">
        {attribute.label ? `${attribute.label}: ` : null}
        {attribute.value}
      </span>
    );
  });

  return (
    <Element
      onClick={onClick}
      className={cs('ListResultCard', props.className, {
        clickable: props.linkTo !== undefined || props.onClick !== undefined,
        'ListResultCard--selectable': selectable,
        'ListResultCard--selected': selected,
      })}
      onKeyUp={onKeyUp}
      tabIndex={0}
      role="link"
    >
      <div className="ListResultCard-primary d-flex flex-row align-items-center">
        {props.icon ? (
          <div
            className={cs(
              'ListResultCard-icon justify-self-baseline px-4',
              props.iconDescription !== undefined
                ? 'ListResultCard-icon-wide'
                : undefined
            )}
            onClick={e => e.stopPropagation()}
          >
            <IconWithSelect
              icon={props.icon}
              iconColor={props.iconColor}
              onSelect={onSelect}
              selected={selected}
            />
            {props.iconDescription !== undefined ? (
              <span className="ListResultCard-icon-description">
                {props.iconDescription}
              </span>
            ) : null}
          </div>
        ) : onSelect ? (
          <div
            className="ListResultCard-icon--fixed"
            onClick={e => e.stopPropagation()}
          >
            <Checkbox checked={selected} onChange={onSelect} />
          </div>
        ) : null}

        <div className="ListResultCard-main pl-0">
          <div className="d-sm-flex flex-row align-items-center mb-1">
            <TitleElement className="ListResultCard-title mr-sm-2 mb-2 my-sm-0">
              {props.title}
            </TitleElement>

            {badge ? badge : null}
          </div>

          {attributes && attributes.length > 0 ? (
            <div className="ListResultCard-attributes">{attributes}</div>
          ) : null}

          <Tags tags={props.tags} />
        </div>

        {props.contextual ? (
          <div className="ListResultCard-context">{props.contextual}</div>
        ) : null}

        {props.action ? (
          <div className="ListResultCard-action">{props.action}</div>
        ) : null}
      </div>
      {props.secondaryResult ? (
        <div className="ListResultCard-secondary pt-3">
          <div className="ListResultCard-icon justify-self-baseline ml-4">
            <Icon icon="arrow-subdirectory" />
          </div>
          {props.secondaryResult.icon ? (
            <IconWithSelect
              icon={props.secondaryResult.icon}
              iconColor={props.secondaryResult.iconColor}
              onSelect={'onSelect' in props ? props.onSelect : undefined}
              selected={'selected' in props && props.selected}
            />
          ) : null}
          {props.secondaryResult.title}
        </div>
      ) : null}

      {props.subResults
        ? props.subResults.map((res, i) => (
            <div className="ListResultCard-secondary pt-3" key={i}>
              <div className="ListResultCard-icon justify-self-baseline ml-4">
                <Icon icon="arrow-subdirectory" />
              </div>
              {res.icon ? (
                <IconWithSelect
                  icon={res.icon}
                  iconColor={res.iconColor}
                  onSelect={undefined}
                  selected={selected}
                />
              ) : null}
              {res.title}
            </div>
          ))
        : null}
    </Element>
  );
};

const IconWithSelect = (props: {
  onSelect?: () => void;
  selected: boolean;
  icon: IconDefinition;
  iconColor?: StatusColor;
}) => {
  return (
    <div className="ListResultCard-icon--fixed">
      <Icon
        className={cs(props.iconColor ? `text-${props.iconColor}` : undefined, {
          'd-none': 'onSelect' in props && props.selected,
        })}
        icon={props.icon}
      />
      {props.onSelect || props.selected ? (
        <>
          <Checkbox
            className={cs('ListResultCard-icon--checkbox', {
              'd-block': props.selected,
            })}
            checked={props.selected}
            onChange={props.onSelect}
          />
        </>
      ) : null}
    </div>
  );
};
