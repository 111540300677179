import React, { useContext } from 'react';

import { AuthContext } from 'src/modules/auth/AuthContext';
import { Area, RoleTemplate } from 'src/__types/graphql-global-types';
import { CustomerAccessQuery_me_customerAccess_customer_permissions } from './__types/CustomerAccessQuery';
import { useAccessQuery_me } from './__types/useAccessQuery';
import { FeatureFlag } from 'src/lib/utils/useFeature';

const CreateAppContext = React.createContext<AppContext>({} as AppContext);

const { Provider, Consumer } = CreateAppContext;

function useAppContext() {
  return useContext(CreateAppContext);
}

export interface AppContext {
  auth: AuthContext;
  cannyToken?: null | string;
  globalSystemMessage?: {
    message: string | null;
    twitterHandle: string | null;
    timestamp: any | null;
  };
  access: useAccessQuery_me;
  activeCustomerId: string | undefined;
  activeCustomerName: string | undefined;
  activeSubscriptionId: string | undefined;
  setActiveSubscriptionId: (subscriptionId: string) => void;
  setActiveCustomer: (customerId: string, customerName: string) => void;
  customerFeatureFlags: FeatureFlag[];
  customerAreas: Area[];
  customerRoles: RoleTemplate[];
  customerFlexFields?: Array<{
    id: string;
    label?: string | null;
    options?: Array<{
      option: string;
      description?: string | null;
    }>;
  }>;
  customerPermissions?: CustomerAccessQuery_me_customerAccess_customer_permissions;
}

export {
  useAppContext,
  Provider as AppContextProvider,
  Consumer as AppContextConsumer,
};
