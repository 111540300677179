import gql from 'graphql-tag';

export const PeriodFragment = gql`
  fragment PeriodFragment on MobilePeriodDetails {
    period
    name
    speedDrop
    monthlyFee
    isBucketMember
    minutesInclusive
    minutesFreeUse
    dataInclusive
    dataFreeUse
    smsInclusive
    smsFreeUse
    usage {
      nationalCallVolume
      nationalCallAmount
      nationalDataVolume
      nationalDataAmount
      smsVolume
      smsAmount
      mmsVolume
      mmsAmount
      roamingAmount
      abroadVolume
      abroadAmount
      specialNumberVolume
      specialNumberAmount
      totalUsageAmount
    }
  }
`;
