import gql from 'graphql-tag';
import { useMutation, ExecutionResult } from 'react-apollo';
import useStructureCacheUpdater from '../lib/useStructureCacheUpdater';
import { FolderFragment } from 'src/lib/types';
import * as t from './__types/useAlterFolder';

const MUTATION_ALTER_FOLDER = gql`
  mutation useAlterFolder($input: AlterFolderInput) {
    alterFolder(input: $input) {
      folder {
        ...FolderFragment
      }
      error {
        message
        code
      }
    }
  }
  ${FolderFragment}
`;

export type AlterFolderFunc = (
  folderId: string,
  parentId?: string | null,
  name?: string,
  cb?: () => void
) => Promise<ExecutionResult<t.useAlterFolder>>;
export interface AlterFolderResult {
  alterFolder: AlterFolderFunc;
  data?: t.useAlterFolder;
  loading: boolean;
  error: boolean;
}

export const useAlterFolder = (): AlterFolderResult => {
  const cacheUpdater = useStructureCacheUpdater<t.useAlterFolder>();

  const [mutation, { data, loading, error }] = useMutation<
    t.useAlterFolder,
    t.useAlterFolderVariables
  >(MUTATION_ALTER_FOLDER);

  const alterFolder = async (folderId, parentId, name, cb) =>
    mutation({
      variables: {
        input: {
          name,
          parentId,
          id: folderId,
        },
      },
      update: (cache, { data }) => {
        if (data?.alterFolder?.folder) {
          return cacheUpdater((ff, muationData) => ({
            files: ff.files,
            folders: ff.folders.map(f =>
              f.id === folderId && muationData.alterFolder?.folder
                ? muationData.alterFolder?.folder
                : f
            ),
          }));
        }
      },
    });

  return {
    alterFolder,
    data,
    loading,
    error: !!error || !!data?.alterFolder?.error,
  };
};
