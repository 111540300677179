import React from 'react';
import { IpDetails } from './IpDetails';
import { t } from 'src/lib/i18n';
import {
  Button,
  Fullscreen,
  FullscreenBody,
  FullscreenHeader,
  Feature,
} from 'src/lib/ui';
import { useEditLanIp } from './useEditLanIp';
import { ChangeSubmitted } from './ChangeSubmitted';
import { LanIpChange } from './LanIpChange';
import * as types from './../../../../../../lib/types/subscription/Cpe';
import { trackOrderChange } from 'src/lib/analytics';

export const LanIp = (props: {
  subscription: {
    id: string;
  };
  ipAddresses: types.LanIp[];
}) => {
  const {
    ipAddresses,
    handleChange,
    handleSubmit,
    openModal,
    toggleModal,
    submitted,
    loading,
    disableSubmit,
  } = useEditLanIp(props.ipAddresses);
  const i18n = t.subscriptions.TechnicalDetailsCard;

  const handleLanIpChange = (subscriptionId: string) => {
    handleSubmit(subscriptionId);
    trackOrderChange('LAN IP');
  };

  return (
    <div>
      <IpDetails ipAddresses={props.ipAddresses} heading={i18n.lanIp} />
      <Feature feature="poc-network-change-order">
        <div className="pb-4">
          <Button outline={true} icon="pen" onClick={toggleModal}>
            {i18n.editLanIP.edit}
          </Button>
        </div>
      </Feature>

      {openModal ? (
        <Fullscreen>
          <FullscreenHeader
            title={submitted ? '' : i18n.editLanIP.changeLanIP}
            onClickBack={toggleModal}
          />

          <FullscreenBody>
            {submitted ? (
              <ChangeSubmitted
                className="w-50"
                onGoBack={toggleModal}
                submittedDescription={i18n.editLanIP.submittedDescription}
              />
            ) : (
              <LanIpChange
                subscription={props.subscription}
                ipAddresses={ipAddresses}
                handleChange={handleChange}
                handleSubmit={handleLanIpChange}
                loading={loading}
                toggle={toggleModal}
                disableSubmit={disableSubmit}
              />
            )}
          </FullscreenBody>
        </Fullscreen>
      ) : null}
    </div>
  );
};
