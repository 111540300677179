import { useState, useEffect } from 'react';

export function usePagination<T>(props: {
  results: Array<T>;
  defaultPageSize?: number;
  onTogglePageSize?: (pageSize: number) => void;
}) {
  const { results, defaultPageSize, onTogglePageSize } = props;
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(defaultPageSize ?? 10);
  const totalResults = results?.length;
  const [filteredResult, setFilteredResult] = useState<Array<T>>(results);

  useEffect(() => {
    const first = pageSize * (page - 1);
    const last = pageSize * page;

    setFilteredResult(
      results?.slice(Math.max(0, first), Math.min(last, results?.length))
    );
  }, [page, pageSize, results]);

  useEffect(() => {
    setPage(1);
    if (pageSize !== defaultPageSize) {
      onTogglePageSize?.(pageSize);
    }
  }, [pageSize]); // eslint-disable-line react-hooks/exhaustive-deps

  return {
    paginationProps: {
      page,
      setPage,
      pageSize,
      setPageSize,
      totalResults,
    },
    filteredResult,
  };
}
