import * as React from 'react';

import * as t from '../types';

import { WithLocalState, LocalState } from 'src/lib';
import { ApolloConsumer } from 'react-apollo';
import ApolloClient from 'apollo-client';
import { castArray, find, get } from 'lodash';

import { WithCustomerContext } from 'src/lib/global';
import asQueryVariables from './asQueryVariables';
import { withRouter, RouteComponentProps } from 'react-router';

import { throttle } from 'lodash';

import { SearchProvider } from './SearchProvider';
import { ResourceType, DomainResult } from 'src/lib/types';

import { unwrapWrappedSearchResults } from './unwrapWrappedSearchResults';

const defaultSearchSize = 20;

interface Props {
  children: (search: SearchProvider) => React.ReactNode;

  /** The initial search parameters. Changing these will reset the search. */
  initial?: {
    types?: ResourceType[];
    query?: string;
    size?: number;
    page?: number;
    filter?: t.Filter[];
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
    cluster?: boolean;
  };

  /** Whether a search should be executed straight away when mounting the component (using the initial parameters). */
  searchImmediately?: boolean;
}

type OuterProps = Props & RouteComponentProps<{}>;

type InnerProps = OuterProps & {
  client: ApolloClient<any>;
  localState: LocalState;
  customerId: string;
};

interface State {
  query: string;
  loading: boolean;
  totalResults: number;
  totalResultsByDomain: Array<DomainResult>;
  results: t.SearchResult[];
  noOfRunningSearches: number;
  after?: string;
  types?: ResourceType[];
  size?: number;
  page?: number;
  filter?: t.Filter[];
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  // An incrementing ID to separate different searches (unique just within a single instance of WithSearch)
  searchId: number;
}

class InnerSearch extends React.Component<InnerProps, State> {
  constructor(props: InnerProps) {
    super(props);
    this.state = {
      noOfRunningSearches: 0,
      loading: props.searchImmediately !== false,
      totalResults: 0,
      totalResultsByDomain: [],
      results: [],
      searchId: 0,
      ...(this.props.initial
        ? {
            types: this.props.initial.types,
            query: this.props.initial.query || '',
            size:
              this.props.initial.size !== undefined &&
              this.props.initial.size !== null
                ? this.props.initial.size
                : defaultSearchSize,
            // NOT defaulting PAGE to a number beacuse we want to be able to set this to "undefined" to be able to use "after" in the search-query
            page: this.props.initial.page,
            filter: this.props.initial.filter,
            sortBy: this.props.initial.sortBy,
            sortOrder: this.props.initial.sortOrder,
          }
        : {
            query: '',
            size: defaultSearchSize,
          }),
    };
  }

  private _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;
    if (this.props.searchImmediately !== false) {
      this.restart();
    }
  }

  componentWillUnmount() {
    this._isMounted = false;
    // Loop through unsubscribe events!
  }

  restart = (restartFromInitial?: boolean) => {
    if (restartFromInitial) {
      this.setState(
        prevState => ({
          // We are resetting the search, but we keep the old results in
          // totalResults,results while we are waiting for the next result.
          types: this.props.initial ? this.props.initial.types : undefined,
          query: this.props.initial ? this.props.initial.query || '' : '',
          size: this.props.initial
            ? this.props.initial.size !== undefined &&
              this.props.initial.size !== null
              ? this.props.initial.size
              : defaultSearchSize
            : undefined,
          page: this.props.initial ? this.props.initial.page : undefined,
          filter: this.props.initial ? this.props.initial.filter : undefined,
          sortBy: this.props.initial ? this.props.initial.sortBy : undefined,
          sortOrder: this.props.initial
            ? this.props.initial.sortOrder
            : undefined,
          after: undefined,
          searchId: prevState.searchId + 1,
        }),
        this.search
      );
    } else {
      this.setState(
        prevState => ({
          after: undefined,
          searchId: prevState.searchId + 1,
        }),
        this.search
      );
    }
  };

  setQuery = (query: string) => {
    this.setState({ query });
  };

  setFilter = (filter: t.Filter[]) => {
    this.setState({ filter });
  };

  setSingleFilter = (
    filterKey: string,
    filterValue: t.FilterValue | t.FilterValue[]
  ) => {
    this.setState(prevState => ({
      filter: [
        ...(prevState.filter
          ? prevState.filter.filter(f => f.filter && f.filter !== filterKey)
          : []),
        { filter: filterKey, value: castArray(filterValue) },
      ],
    }));
  };

  // Convenience function. Use with caution in Pure Components.
  getFilterValue = (filterKey: string) =>
    castArray(
      get(
        find(this.state.filter, f => f.filter === filterKey),
        ['value']
      )
    ).filter(f => f) as string[];

  setSortOrder = (sortOrder: 'asc' | 'desc') => this.setState({ sortOrder });
  setSortBy = (sortBy: string) => this.setState({ sortBy });

  setSort = (
    sortBy: string | undefined,
    sortOrder: 'asc' | 'desc' | undefined
  ) => {
    if (!sortBy || !sortOrder) {
      return;
    }
    this.setState({ sortBy, sortOrder });
  };
  setPage = (page: number) => this.setState({ page });
  setSize = (size: number) => this.setState({ size });

  componentDidUpdate(prevProps: InnerProps, prevState: State) {
    // Likely to be a full reset if any of the props have changed --- unless only localState has changed!
    if (
      this.state.query !== prevState.query ||
      this.state.types !== prevState.types ||
      this.state.filter !== prevState.filter ||
      this.state.size !== prevState.size ||
      this.state.page !== prevState.page ||
      this.state.sortBy !== prevState.sortBy ||
      this.state.sortOrder !== prevState.sortOrder ||
      this.props.initial !== prevProps.initial
    ) {
      this.restart(
        // If the initial props have changed, we reset the search settings to the ones from the initial object
        this.props.initial !== prevProps.initial
      );
    }
    // if any of the other props have changed, we probably need to reset some state here
  }

  loadMore = (numberOfRecords?: number) => {
    if (!this.state.loading) {
      if (typeof numberOfRecords === 'number') {
        this.setState({ size: numberOfRecords }, this.search);
      } else {
        this.search();
      }
    }
  };

  unthrottledSearch = async () => {
    // Would be nice to cancel any previous searches here for performance and
    // privacy reasons, for now we simply ignore any results from earlier searches.

    if (!this._isMounted) {
      return;
    }

    this.setState(prevState => ({
      noOfRunningSearches: prevState.noOfRunningSearches + 1,
      loading: true,
    }));

    const searchId = this.state.searchId;

    const res = await this.props.client.query<t.QueryData, t.QueryVariables>({
      query: t.searchQuery,
      variables: asQueryVariables(this.props.customerId, {
        query: this.state.query,
        types: this.state.types,
        ...(this.state.types && this.state.types.length === 1
          ? {
              // These parameters are only valid for searches for a single specific result type.
              filter: this.state.filter,
              sortBy: this.state.sortBy,
              sortOrder: this.state.sortOrder,
            }
          : {}),
        size:
          this.state.size !== undefined && this.state.size !== null
            ? this.state.size
            : defaultSearchSize,
        page: this.state.page,
        // Page and after cannot be combined. If "page" is defined in the search we always discard "after".
        after: this.state.page === undefined ? this.state.after : undefined,
        // We don't keep the cluster in state. But is read directly from props
        cluster: this.props.initial && this.props.initial.cluster,
      }),
      errorPolicy: 'all',
    });

    // If the WithSearch component is no longer mounted, we abort.
    if (!this._isMounted) {
      return;
    }

    // If the result we are currently processing is not for the latest query, we abort.
    if (searchId !== this.state.searchId) {
      // But make sure to reduce the current number of running queries
      this.setState(prevState => ({
        loading: prevState.noOfRunningSearches !== 1,
        noOfRunningSearches: prevState.noOfRunningSearches - 1,
      }));
      return;
    }

    let cursor: string | undefined;

    // const records = (res.data &&
    // res.data.customer &&
    // res.data.customer.search &&
    // res.data.customer.search.results
    //   ? (res.data.customer.search.results as unknown)
    //   : []) as t.SearchResult[];

    // Temporary mapper to map the new ResultUnion from GraphQL to our old types.
    // Because we use our custom types throughout the app, we need to change all our types before we are able to use the new ResultUnion + generated types.
    const unmappedRecords = ((res?.data?.customer?.search?.results ??
      []) as unknown) as t.WrappedSearchResult[];
    const records = unwrapWrappedSearchResults(unmappedRecords);

    if (records.length > 0) {
      cursor = res.data.customer.search.cursor;
    }

    this.setState(prevState => {
      const results = [
        // If 'after' is undefined, this is a new search and we
        // want to discard all the previous results.
        ...(this.state.after ? prevState.results : []),
        ...records,
      ];

      const totalResults =
        res.data && res.data.customer && res.data.customer.search
          ? res.data.customer.search.totalResults
          : 0;

      const totalResultsByDomain =
        res.data && res.data.customer && res.data.customer.search
          ? res.data.customer.search.totalResultsByDomain
          : [];

      return {
        loading: prevState.noOfRunningSearches !== 1,
        noOfRunningSearches: prevState.noOfRunningSearches - 1,
        totalResults,
        totalResultsByDomain,
        results,
        after: cursor,
      };
    });
  };

  search = throttle(this.unthrottledSearch, 200, {
    leading: false,
    trailing: true,
  });

  render() {
    return this.props.children({
      history: this.props.localState.searchHistory,
      loading: this.state.loading,
      results: this.state.results,
      totalResults: this.state.totalResults,
      totalResultsByDomain: this.state.totalResultsByDomain,
      loadMore: this.loadMore,
      isMore: this.state.totalResults
        ? this.state.results.length < this.state.totalResults
        : undefined,
      query: this.state.query,
      setQuery: this.setQuery,

      types: this.state.types,

      filter: this.state.filter,
      setFilter: this.setFilter,
      setSingleFilter: this.setSingleFilter,
      getFilterValue: this.getFilterValue,

      sortOrder: this.state.sortOrder,
      sortBy: this.state.sortBy,
      setSortOrder: this.setSortOrder,
      setSortBy: this.setSortBy,
      setSort: this.setSort,
      // Used in pagination
      page: this.state.page,
      setPage: this.setPage,
      size: this.state.size,
      setSize: this.setSize,
    });
  }
}

const WithSearch: React.SFC<OuterProps> = props => (
  <WithCustomerContext>
    {customer => (
      <ApolloConsumer>
        {client => (
          <WithLocalState>
            {localState => (
              <InnerSearch
                client={client}
                localState={localState}
                customerId={customer.id}
                {...props}
              />
            )}
          </WithLocalState>
        )}
      </ApolloConsumer>
    )}
  </WithCustomerContext>
);

export default withRouter(WithSearch);
