import * as React from 'react';
import { FormikTextField } from 'src/lib/flow';
import { t } from 'src/lib/i18n';
import { Personalia } from '../createContact/types';

interface Props {
  setFieldValue: (field: keyof Personalia, value: string) => void;
  values: Personalia;
  disabled?: boolean;
  enableEmail?: boolean;
}

const PersonaliaForm: React.SFC<Props> = props => {
  return (
    <div className="py-3">
      <div className="d-flex">
        <FormikTextField
          className="mb-3 mr-3 flex-grow-1"
          label={t.contacts.formLabels.firstName}
          required={true}
          id="firstName"
          aria-label={t.contacts.formLabels.firstName}
          disabled={props.disabled}
        />
        <FormikTextField
          id="lastName"
          className="mb-3 flex-grow-1"
          label={t.contacts.formLabels.lastName}
          required={true}
          aria-label={t.contacts.formLabels.lastName}
          disabled={props.disabled}
        />
      </div>

      <FormikTextField
        id="email"
        className="mb-3 w-50"
        label={t.contacts.formLabels.email}
        required={true}
        type="email"
        aria-label={t.contacts.formLabels.email}
        disabled={!props.enableEmail}
      />

      <div className="d-flex flex-column w-50">
        <FormikTextField
          id="mobilePhone"
          className="mb-3"
          label={t.contacts.formLabels.mobilePhone}
          aria-label={t.contacts.formLabels.mobilePhone}
          disabled={props.disabled}
        />
        <FormikTextField
          id="secondaryPhone"
          className="mb-3"
          label={t.contacts.formLabels.secondaryPhone}
          aria-label={t.contacts.formLabels.secondaryPhone}
          disabled={props.disabled}
        />
      </div>

      <FormikTextField
        id="description"
        className="mb-3 w-100"
        label={t.contacts.formLabels.description}
        aria-label={t.contacts.formLabels.description}
        disabled={props.disabled}
      />

      <FormikTextField
        id="employeeNumber"
        className="mb-3 w-50"
        label={t.contacts.formLabels.employeeNumber}
        aria-label={t.contacts.formLabels.employeeNumber}
        disabled={props.disabled}
      />
    </div>
  );
};
export default PersonaliaForm;
