import { useSearchOptions } from './useSearchOptions';
import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { SearchFilterOption } from './../../__types/graphql-global-types';

export const useSubscriptionTypeFilter = () => {
  const query = useQueryParameters();
  const selectedOptions = query.getArray('type');
  const i18n = t.subscriptions.filters.subscriptionType;
  const { data } = useSearchOptions();

  const typeOptions = data?.customer?.searchOptions?.filters
    ?.filter(option => option.filter === SearchFilterOption.type)
    ?.flatMap(o => o.options);

  const options =
    typeOptions?.map(option => ({
      label: option,
      value: option,
    })) ?? [];

  return {
    id: 'type',
    label: i18n.label,
    options: options,
    allowMultiple: true,
    allowSearch: true,
    selectedOptions,
    handleSave: values => {
      setFilterAndResetPage(query, 'type', values ? values : undefined);
    },
  };
};
