import React from 'react';
import { useRouter } from 'src/lib/utils/useRouter';
import { useSubscription } from '../../../queries/useSubscription';
import { SubscriptionSummaryContainer } from '../../../layout';
import {
  DefaultAboutCard,
  SubscriptionReportIncidentButton,
  AdditionalInformationCard,
  TechnicalContactsCard,
  SubscriptionCostDetails,
  ConnectionStretch,
  SubscriptionConfiguration,
} from '../../Components';

export const DefaultDataSummaryPage = () => {
  const router = useRouter<{ id: string }>();
  const { subscription } = useSubscription(router.match.params.id);

  if (subscription) {
    return (
      <>
        <SubscriptionSummaryContainer
          subscription={subscription}
          main={
            <>
              {subscription.installationAddress &&
                subscription.installationAddress2 && (
                  <ConnectionStretch
                    installationAddress={subscription.installationAddress}
                    installationAddress2={subscription.installationAddress2}
                  />
                )}
              <SubscriptionReportIncidentButton subscription={subscription} />
              <SubscriptionConfiguration subscription={subscription} />
            </>
          }
          sidebar={
            <>
              <DefaultAboutCard subscription={subscription} />
              <SubscriptionCostDetails subscription={subscription} />
              <TechnicalContactsCard subscription={subscription} />
              <AdditionalInformationCard subscription={subscription} />
            </>
          }
        />
      </>
    );
  }
  return null;
};
