import { SubscriptionTaxModel } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';

export const getTaxModelLabelAndDescription = (
  taxModel?: SubscriptionTaxModel | null
) => {
  switch (taxModel) {
    case SubscriptionTaxModel.departmentPhone:
      return {
        title: t.subscriptions.taxModel.department.title,
        description: t.subscriptions.taxModel.department.description,
      };
    case SubscriptionTaxModel.free:
      return {
        title: t.subscriptions.taxModel.free.title,
        description: t.subscriptions.taxModel.free.description,
      };
    case SubscriptionTaxModel.none:
      return {
        title: t.subscriptions.taxModel.none,
        description: undefined,
      };
    case SubscriptionTaxModel.officePhone:
      return {
        title: t.subscriptions.taxModel.office.title,
        description: t.subscriptions.taxModel.office.description,
      };
    case SubscriptionTaxModel.personal:
      return {
        title: t.subscriptions.taxModel.personal,
        description: undefined,
      };
    default:
      return { title: '', description: undefined };
  }
};
