import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useCpe';

export const GET_CPE = gql`
  query useCpe($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        cpe {
          status
          description
          model
          name
          uptime
          ping {
            roundTripTime
            status
          }
        }
        redundancies {
          id
          cpe {
            status
            description
            model
            name
            uptime
          }
        }
      }
    }
  }
`;

export interface CpeData {
  loading: boolean;
  error: ApolloError | null;
  data?: t.useCpe_customer_subscriptions | null;
}

export const useCpe = (subscriptionId: string): CpeData => {
  const customer = useCustomer();
  const result = useQuery<t.useCpe, t.useCpeVariables>(GET_CPE, {
    variables: {
      customerId: customer.id,
      subscriptionId: subscriptionId,
    },
    errorPolicy: 'all',
  });
  return {
    loading: result.loading,
    error: result.error ? result.error : null,
    data: result.data?.customer?.subscriptions?.[0],
  };
};
