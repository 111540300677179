import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { WithCustomerContext } from 'src/lib/global';
import { ProductPackage, ProductType } from '../types';

const WITH_MOBILE_PRODUCTS = gql`
  query WithMobileProducts(
    $customerId: ID!
    $organisationId: ID!
    $productType: [String!]
  ) {
    customer(id: $customerId) {
      id
      availableProductPackages(
        organisationId: $organisationId
        productType: $productType
      ) {
        mainProduct {
          id
          name
          installationCost
          mandatoryForPackage
          uniqueGroup
          monthlyCost
        }
        additionalProducts {
          id
          name
          installationCost
          mandatoryForPackage
          uniqueGroup
          monthlyCost
        }
      }
    }
  }
`;

interface Variables {
  customerId: string;
  organisationId: string;
  productType: string[];
}
interface Data {
  customer?: {
    availableProductPackages?: ProductPackage[];
  };
}

export interface MobileProductsData {
  loading: boolean;
  error: ApolloError | null;
  availableProductPackages?: ProductPackage[];
}

interface Props {
  organisationId: string;
  productType: ProductType[];
  children: (data: MobileProductsData) => JSX.Element | null;
}

const WithMobileProducts: React.SFC<Props> = (props: Props) => {
  return (
    <WithCustomerContext>
      {customerContext => (
        <Query<Data, Variables>
          query={WITH_MOBILE_PRODUCTS}
          variables={{
            customerId: customerContext.id,
            organisationId: props.organisationId,
            productType: props.productType,
          }}
          errorPolicy="all"
        >
          {result => {
            return props.children({
              availableProductPackages:
                result.data &&
                result.data.customer &&
                result.data.customer.availableProductPackages,
              loading: result.loading,
              error: result.error || null,
            });
          }}
        </Query>
      )}
    </WithCustomerContext>
  );
};

export default WithMobileProducts;
