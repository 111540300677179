import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';
import { Tag } from 'src/lib/types';

export const SearchListTagResult: React.FC<{ entry: Tag }> = ({ entry }) => (
  <ListResultCard
    linkTo={entry}
    className="ListResultCard--Tag"
    icon="tags"
    title={`${entry.tag} (${entry.id})`}
  />
);
