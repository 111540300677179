/**
 *
 * A singleton bearer token, outside the React component hierarchy.
 * This is used by the GraphQL transport in createApolloClient.ts.
 *
 */

let bearerTokenSingleton: string | undefined;

export function getBearerToken() {
  return bearerTokenSingleton;
}

export function setBearerToken(bearerToken: string | undefined) {
  bearerTokenSingleton = bearerToken;
}
