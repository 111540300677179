import { StatisticsGranularity } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';

export const getGranularity = (granularity: StatisticsGranularity) => {
  switch (granularity) {
    case StatisticsGranularity.raw:
      return t.statistics.Granularity.raw;
    case StatisticsGranularity.hour:
      return t.statistics.Granularity.hour;
    case StatisticsGranularity.day:
      return t.statistics.Granularity.day;
  }
};
