import React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router';
import OrderingContent from './OrderingContent';

const Ordering: React.FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    {/* <Route
      path={`${match.url}/bestill-abo`}
      component={OrderMobileSubscription}
    /> */}
    <Route
      path={`${match.url}/:orderType/new`}
      render={props => (
        <Redirect to={props.match.url.substr(0, props.match.url.length - 4)} />
      )}
    />
    <Route path={`${match.url}/:orderType`} component={OrderingContent} />
    {/* <Route path={`${match.url}/bestill-nummer`} component={OrderNumberSeries} /> */}
    {/* <Route path={`${match.url}/ekstra-sim`} component={OrderExtraSim} /> */}
    <Route exact={true} path={match.url} component={OrderingContent} />
  </Switch>
);

export default Ordering;
