import { WrappedSearchResult, SearchResult } from '../types';

/**
 * Temporary mapper to map the new SearchResult union from GraphQL.
 *
 * Old:
 *  {
 *    ...subscriptionProps
 *  }
 *
 * New:
 *  {
 *    __typename
 *    subscription {
 *      ...subscriptionProps
 *    }
 *  }
 *
 * Mapper will map the new type over to the old type to support the current state of the app.
 */

export function unwrapWrappedSearchResults(results: WrappedSearchResult[]) {
  return results.map(r => {
    switch (r.__typename) {
      case 'ClusterSearchResult':
        return r.cluster;

      case 'ContactSearchResult':
        return r.contact;

      case 'CostCenterSearchResult':
        return r.costCenter;

      case 'FileSearchResult':
        return r.file;

      case 'IncidentSearchResult':
        return r.incident;

      case 'InvoiceSearchResult':
        return r.invoice;

      case 'NotificationSearchResult':
        return r.notification;

      case 'OrderSearchResult':
        return r.order;

      case 'OrganisationSearchResult':
        return r.organisation;

      case 'SubscriptionSearchResult':
        return r.subscription;

      case 'TagSearchResult':
        return r.tag;

      default:
        // For other cases, we skip the unwrapping and just return the object as-is.
        return r;
    }
  }) as SearchResult[];
}
