import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { useCustomer } from 'src/lib/global';
import { TechnicalContactInput } from 'src/__types/graphql-global-types';
import { QUERY_CONTACT } from '../../contacts/queries/useContact';
import * as t from './__types/useUpdateTechnicalContacts';

const MUTATION_UPDATE_TECHNICAL_CONTACTS = gql`
  mutation useUpdateTechnicalContacts(
    $input: SetTechnicalContactsOnSubscriptionInput
  ) {
    setTechnicalContactsOnSubscription(input: $input) {
      technicalContacts {
        priority
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
          secondaryPhone
          description
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export const QUERY_TECHNICAL_CONTACTS = gql`
  query TechnicalContactsOnSubscription(
    $customerId: ID!
    $subscriptionId: ID!
  ) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        technicalContacts {
          priority
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            secondaryPhone
            description
          }
        }
      }
    }
  }
`;

export interface UpdateTechnicalContactsResult {
  setTechnicalContactsOnSubscription: (
    subscriptionId: string,
    initialContacts: string[],
    technicalContacts: t.useUpdateTechnicalContacts_setTechnicalContactsOnSubscription_technicalContacts[]
  ) => Promise<any>;
  loading: boolean;
  mutationErrorCode?: string;
  graphqlError?: ApolloError;
}

export const useUpdateTechnicalContacts = (): UpdateTechnicalContactsResult => {
  const customer = useCustomer();
  const [mutation, result] = useMutation<
    t.useUpdateTechnicalContacts,
    t.useUpdateTechnicalContactsVariables
  >(MUTATION_UPDATE_TECHNICAL_CONTACTS);

  const setTechnicalContactsOnSubscription = (
    subscriptionId: string,
    initialContactsIds: string[],
    technicalContacts: t.useUpdateTechnicalContacts_setTechnicalContactsOnSubscription_technicalContacts[]
  ) => {
    /**
     * A subscription can have up to three technical contacts.
     * To make sure that contacts to be removed is actually removed, we always send the full list of contacts
     * with the unused slots empty (contactId = null).
     * To accomplish this, we merge our input with a list ofempty slots
     **/
    const input: TechnicalContactInput[] = [
      { priority: 1, contactId: null },
      { priority: 2, contactId: null },
      { priority: 3, contactId: null },
    ];
    const technicalContactInput: TechnicalContactInput[] = technicalContacts.map(
      technicalContact => ({
        priority: technicalContact.priority,
        contactId: technicalContact.contact?.id,
      })
    );

    input.splice(0, technicalContactInput.length, ...technicalContactInput);

    const currentContacts = input.map(c => c.contactId);
    const contactsToUpdate = currentContacts
      .filter(
        current => !initialContactsIds.find(initial => initial === current)
      )
      .concat(
        initialContactsIds.filter(
          initial => !currentContacts.find(current => current === initial)
        )
      )
      .filter(id => !!id);

    return mutation({
      variables: {
        input: {
          subscriptionId: subscriptionId || '',
          technicalContacts: input,
        },
      },
      refetchQueries: [
        ...contactsToUpdate.map(contactId => ({
          query: QUERY_CONTACT,
          variables: {
            customerId: customer.id,
            contactId: contactId,
          },
        })),
        {
          query: QUERY_TECHNICAL_CONTACTS,
          variables: {
            subscriptionId,
            customerId: customer.id,
          },
        },
      ],
    });
  };

  return {
    setTechnicalContactsOnSubscription,
    loading: result.loading,
    mutationErrorCode:
      result.data?.setTechnicalContactsOnSubscription?.error?.code,
    graphqlError: result.error,
  };
};
