import React from 'react';
import { Subscription } from 'src/lib/types';
import { Button, BodyCard, Toolbar, Attribute } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils';
import {
  getLabelFromCpeStatus,
  getRedundancyStatusLabel,
  getMillisecondsFromUptime,
  formatDurationString,
} from '../../utils/subscriptionUtils';
import { getDurationFromMilliseconds } from 'src/lib/utils/dateUtils';
import { t } from 'src/lib/i18n';
import { useRefetchStatusInformation } from './useRefetchStatusInformation';
import { getSubscriptionDisplayTitles } from 'src/lib/types/subscription/getSubscriptionDisplayTitles';
import { SubscriptionStatusItem } from './SubscriptionStatusItem';
import { StatusIllustration } from './StatusIllustration';
import { NotMonitored } from '../../Pages/Components/NotMonitored';

interface Props {
  subscription: Subscription;
}

export const SubscriptionStatus: React.FC<Props> = props => {
  const router = useRouter<{ id: string }>();
  const i18n = t.subscriptions.SubscriptionStatusSummaryCard;

  const currentIsRedundancy = props.subscription?.isRedundancy;

  const currentIsAddon = props.subscription?.parent && !currentIsRedundancy;
  const mainSubscription = currentIsRedundancy
    ? props.subscription.parent
    : props.subscription;

  const redundancies = currentIsRedundancy
    ? [props.subscription]
    : props.subscription.redundancies;

  const {
    uptime,
    loading,
    refetch,
    roundTripTimeResponses,
  } = useRefetchStatusInformation(mainSubscription?.id || '', redundancies);

  const formattedUptime = uptime
    ? formatDurationString(
        getDurationFromMilliseconds(getMillisecondsFromUptime(uptime)),
        t.ui.times
      )
    : undefined;

  if (!props.subscription?.cpe?.status) {
    return <NotMonitored />;
  }

  return (
    <BodyCard heading={i18n.title} className="SubscriptionStatus">
      <div className="d-flex align-items-center pt-3 pb-5">
        <div>
          <SubscriptionStatusItem
            subscriptionId={mainSubscription?.id}
            status={mainSubscription?.cpe?.status}
            disabled={router.match.params.id === mainSubscription?.id}
            refetching={loading}
          >
            <div style={{ fontSize: '1.25rem' }}>
              {getLabelFromCpeStatus(mainSubscription?.cpe?.status)}
            </div>

            <small>
              <Attribute
                label={i18n.cpeModel}
                value={mainSubscription?.cpe?.model}
              />
              {uptime && (
                <Attribute
                  label={i18n.uptime}
                  value={loading ? '...' : formattedUptime}
                />
              )}
              {!currentIsAddon && mainSubscription?.id && (
                <Attribute
                  label={i18n.responsetime}
                  value={roundTripTimeResponses[mainSubscription.id]?.map(
                    (r, i) => (
                      <div key={i} className="d-inline pr-2">
                        {r.roundTripTime < 0 ? '-' : r.roundTripTime + 'ms'}
                      </div>
                    )
                  )}
                />
              )}
            </small>
          </SubscriptionStatusItem>

          {redundancies
            ? redundancies.map(r => (
                <React.Fragment key={r.id}>
                  <div className="SubscriptionStatusItem-connected" />
                  <SubscriptionStatusItem
                    subscriptionId={r.id}
                    disabled={router.match.params.id === r.id}
                    key={r.id}
                    refetching={loading}
                    status={r.cpe?.status}
                  >
                    <div> {getRedundancyStatusLabel(r?.cpe?.status)}</div>
                    <small>
                      <div>{getSubscriptionDisplayTitles(r).title}</div>

                      <Attribute
                        label={i18n.responsetime}
                        value={roundTripTimeResponses[r.id]?.map((r, i) => (
                          <div key={i} className="d-inline pr-2">
                            {r.roundTripTime + 'ms'}
                          </div>
                        ))}
                      />
                    </small>
                  </SubscriptionStatusItem>
                </React.Fragment>
              ))
            : null}
        </div>

        {mainSubscription && (
          <StatusIllustration
            subscription={mainSubscription}
            redundancies={redundancies}
            loading={loading}
          />
        )}
      </div>

      {mainSubscription?.id ? (
        <Toolbar>
          <Button icon={'refresh'} color="white" onClick={refetch}>
            {loading ? i18n.btnRefetching : i18n.btnRefetch}
          </Button>
        </Toolbar>
      ) : null}
    </BodyCard>
  );
};
