import { LanIp } from './../../../../../../lib/types/subscription/Cpe';
import { useNetworkChangeOrder } from './../../../mutations/useNetworkChangeOrder';
import { useEffect, useState, useMemo } from 'react';
import differenceWith from 'lodash/differenceWith';
import isEqual from 'lodash/isEqual';

export const configKeys: Array<keyof LanIp> = [
  'networkAddress',
  'subnetMask',
  'hsrpIpAddress',
  'interfaceId',
  'interfaceMode',
];

type LanIpWithId = {
  id: string;
} & Omit<LanIp, 'id'>;

function hasId(ip: LanIp): ip is LanIpWithId {
  return !!ip.id;
}
export const useEditLanIp = (initialIpAddresses: LanIp[]) => {
  const [openModal, setOpenModal] = useState(false);
  const [ipAddresses, setIpAddresses] = useState(initialIpAddresses);
  const { changeLanRouting, data, loading } = useNetworkChangeOrder();
  const [submitted, setSubmitted] = useState(false);

  useEffect(() => {
    if (openModal) {
      setIpAddresses(initialIpAddresses);
    }
  }, [openModal, initialIpAddresses]);

  useEffect(() => {
    setSubmitted(!!data?.createNetworkChangeOrder?.success);
  }, [data]);

  useEffect(() => {
    /**
     * Apollo does not support resetting mutation state
     * so we handle our own submitted state for now.
     *
     * Follow the issue: https://github.com/apollographql/apollo-feature-requests/issues/170
     */
    if (!openModal) {
      setSubmitted(false);
    }
  }, [openModal]);

  const hasChanged = useMemo(
    () => !!differenceWith(initialIpAddresses, ipAddresses, isEqual).length,
    [initialIpAddresses, ipAddresses]
  );

  // TODO handle error

  const toggleModal = () => setOpenModal(!openModal);
  const handleChange = (payload: {
    value: string;
    configKey: keyof LanIp;
    index: number;
  }) => {
    const newState = ipAddresses.map((ip, index) =>
      index === payload.index
        ? {
            ...ip,
            [payload.configKey]: payload.value,
          }
        : ip
    );
    setIpAddresses(newState);
  };

  const handleSubmit = (subscriptionId: string) => {
    const mapped = ipAddresses.filter(hasId).map(ip => ({
      id: ip.id,
      networkAddress: ip.networkAddress,
      subnetMask: ip.subnetMask,
      interfaceId: ip.interfaceId,
      hsrpIpAddress: ip.hsrpIpAddress,
      interfaceMode: ip.interfaceMode,
    }));
    changeLanRouting(subscriptionId, mapped);
  };
  return {
    ipAddresses,
    handleChange,
    openModal,
    toggleModal,
    handleSubmit,
    submitted,
    loading,
    disableSubmit: !hasChanged,
  };
};
