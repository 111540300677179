import * as React from 'react';

import cs from 'classnames';

import './Toolbar.scss';
import { colors } from 'src/lib/ui/colors';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export const Toolbar: React.FC<Props> = props => (
  <div
    className={cs('Toolbar pt-4', props.className)}
    style={{ borderTop: `1px solid ${colors.grey}`, ...props.style }}
  >
    {props.children}
  </div>
);
