import { usePlannedWorkOnSubscription_customer_subscriptions_plannedWork } from 'src/areas/main/subscriptions/queries/__types/usePlannedWorkOnSubscription';
import { PlannedWork } from 'src/areas/main/subscriptions/Pages/Components/SubscriptionPlannedWorkCard';
import { isBefore } from 'src/lib/utils';
import moment from 'moment';

export const hasActiveState = (plannedWork: Pick<PlannedWork, 'state'>) =>
  plannedWork.state === 'Scheduled' || plannedWork.state === 'Implement';

export const isNotExpired = (
  { endDate }: Pick<PlannedWork, 'endDate'>,
  now: string | Date = new Date().toISOString()
) => isBefore(0, endDate)(moment(now));

export const shouldShowPlanedWork = (
  p: Pick<PlannedWork, 'state' | 'endDate'>,
  now: string | Date = new Date().toISOString()
) => hasActiveState(p) && isNotExpired(p, now);

export const toPlannedWork = (
  entry: usePlannedWorkOnSubscription_customer_subscriptions_plannedWork
): PlannedWork => ({
  id: entry.changeNumber,
  startDate: entry.dates.plannedStartDate,
  endDate: entry.dates.plannedEndDate,
  state: entry.state,
  oldStartDate: entry.dates.oldStartDate,
  oldEndDate: entry.dates.oldEndDate,
  expectedTotalDowntimeInMinutes: entry.expectedTotalDowntimeInMinutes,
  description: entry.description,
  serviceImpact: entry.serviceImpact,
});
