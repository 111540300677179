/* tslint:disable:no-console max-line-length */
import * as React from 'react';
import { Switch, Route, withRouter, RouteComponentProps } from 'react-router';
import { Redirect } from 'react-router-dom';
import Keycloak, { KeycloakLoginOptions } from 'keycloak-js';
import config from '../../config';
import { reduce } from 'lodash';
import { AuthContextProvider } from './AuthContext';
import { setBearerToken } from './bearerToken';
import { User } from 'src/lib/global';
import createUserFromToken from './createUserFromToken';

interface State {
  initializing: boolean;
  isSigningIn: boolean;
  isSigningOut: boolean;
  user?: User;
}

type Props = RouteComponentProps<{}>;

class AuthProvider extends React.Component<Props, State> {
  private adapter: Keycloak.KeycloakInstance | undefined;

  constructor(props: Props) {
    super(props);
    this.state = {
      initializing: true,
      isSigningIn: false,
      isSigningOut: false,
      user: undefined,
    };
  }

  requestSignIn = () => {
    if (this.adapter) {
      this.setState({
        isSigningIn: true,
      });
      this.adapter.login({
        redirectUri: `${config.applicationRootUrl}_auth/callback?path=${window.location.pathname}`,
      });
    }
  };

  requestSignOut = () => {
    if (this.adapter) {
      this.setState({
        isSigningOut: true,
      });
      const redirectUri = [
        config.applicationRootUrl || '',
        config.applicationRootUrl && config.applicationRootUrl.endsWith('/')
          ? ''
          : '/',
        this.props.location.pathname.indexOf('/minside') === 0 ? 'minside' : '',
      ].join('');
      this.adapter.logout({ redirectUri });
    }
  };

  authenticate = () => {
    this.setState({
      initializing: false,
      isSigningIn: true,
      isSigningOut: false,
      user: undefined,
    });

    const openIdClient =
      this.props.location.pathname.indexOf('/minside') === 0
        ? config.openIdClientIdMobile
        : config.openIdClientId;

    this.adapter = Keycloak({
      url: config.openIdAuthority,
      clientId: openIdClient,
      realm: config.openIdRealm,
      redirectUri: `${config.applicationRootUrl}_auth/callback`,
    });

    /**
     * Process for figuring out what, if any, idp hint we will
     * use for the authentication process.
     */
    let idpHint: string = '';
    if (
      this.props.location.pathname.indexOf('/worklog') === 0 ||
      this.props.location.pathname.indexOf('/intern') === 0
    ) {
      // When we authenticate from the /worklog or /intern URLs, we pass
      // along `idpHint=telia` to Keycloak to use Telia identity SSO.
      idpHint = 'telia';
    } else if (this.props.location.pathname.indexOf('/ekstern/') === 0) {
      // When we authenticate from e.g. /ekstern/wold-loken, we pass
      // along the customer-specific `idpHint=wold-loken`.
      idpHint = this.props.location.pathname.substr(9);
    }

    if (idpHint) {
      // No obvious option to set idpHint when using constructor/init method of authenticating.
      // Solution from https://stackoverflow.com/questions/41758593/cannot-pass-idphint-option-to-keycloak
      const originalCreateLoginUrl = this.adapter.createLoginUrl;
      this.adapter.createLoginUrl = (opts: KeycloakLoginOptions) =>
        originalCreateLoginUrl({
          ...opts,
          idpHint,
        });
    }

    this.adapter.onReady = this.onReady;
    this.adapter.onAuthSuccess = this.onAuthSuccess;
    this.adapter.onAuthError = this.onAuthError;
    this.adapter.onAuthRefreshSuccess = this.onAuthRefreshSuccess;
    this.adapter.onAuthRefreshError = this.onAuthRefreshError;
    this.adapter.onAuthLogout = this.onAuthLogout;
    this.adapter.onTokenExpired = this.onTokenExpired;

    this.adapter.init({
      flow: 'hybrid', // 'hybrid' | 'standard' | 'implicit'
      onLoad: 'login-required', // 'login-required' | 'check-sso'
      checkLoginIframe: false,
    });
  };

  componentDidUpdate(prevProps: Props) {
    // If a mobile user is trying to access the regular portal, force
    // a sign-out to make the user reauthenticate as a regular user.
    if (
      this.state.user &&
      this.state.user.isMobileUser &&
      this.props.location.pathname.indexOf('/minside') !== 0 &&
      !this.state.isSigningOut
    ) {
      this.requestSignOut();
      return;
    }

    // If we have crossed the Min side/Min portal threshold, we must reauthenticate.
    if (
      this.props.location.pathname.indexOf('/minside') !==
      prevProps.location.pathname.indexOf('/minside')
    ) {
      this.authenticate();
    }
  }

  componentDidMount() {
    this.authenticate();
  }

  onReady = () => {
    this.setState({
      initializing: false,
    });
  };

  onAuthSuccess = () => {
    if (this.adapter) {
      setBearerToken(this.adapter.token);
      const user = createUserFromToken(this.adapter);
      this.setState({ user });
    }
  };

  onAuthError = () => {
    // console.log('onAuthError', this.adapter);
  };

  onAuthRefreshSuccess = () => {
    if (this.adapter) {
      setBearerToken(this.adapter.token);
    }

    this.setState(prevState => {
      if (prevState.user) {
        return {
          ...prevState,
          user: {
            ...prevState.user,
            bearerToken: this.adapter?.token,
            idToken: this.adapter?.idToken,
          },
        };
      }
      return null;
    });
  };

  onAuthRefreshError = () => {
    // console.log('onAuthRefreshError', this.adapter);
  };

  onAuthLogout = () => {
    // console.log('onAuthLogout', this.adapter);
  };

  onTokenExpired = () => {
    // console.log('onTokenExpired', this.adapter);
    if (this.adapter) {
      this.adapter.updateToken(60);
    }
  };

  componentWillUnmount() {
    if (this.adapter) {
      this.adapter.onReady = undefined;
      this.adapter.onAuthSuccess = undefined;
      this.adapter.onAuthError = undefined;
      this.adapter.onAuthRefreshSuccess = undefined;
      this.adapter.onAuthRefreshError = undefined;
      this.adapter.onAuthLogout = undefined;
      this.adapter.onTokenExpired = undefined;
    }
  }

  renderChildren = () => {
    return this.props.children;
  };

  loginCallback = (props: any) => {
    // console.log('AuthProvider', 'loginCallback', props);
    // console.log('loginCallback', props);
    // console.log('   auth status?', this.adapter.authenticated);

    if (!this.adapter?.authenticated) {
      return <span>waiting for authentication ...</span>;
    }

    let redirectPath = '/';

    if (props.location.search && props.location.search.length > 5) {
      const params: { [key: string]: string } = reduce(
        props.location.search.substr(1).split('&'),
        (prev, curr) => {
          const ix = curr.indexOf('=');
          if (ix === -1) {
            prev[curr] = true;
          } else {
            prev[curr.substr(0, ix)] = decodeURIComponent(curr.substr(ix + 1));
          }
          return prev;
        },
        {}
      );

      if (params.path) {
        redirectPath = params.path;
      }
    }

    // console.log('loginCallback, redirect to ', redirectPath);
    return <Redirect to={redirectPath} />;
  };

  render() {
    // console.log('AuthProvider', 'render', this.state);

    return (
      <AuthContextProvider
        value={{
          bearerToken: this.state.user
            ? this.state.user.bearerToken
            : undefined,
          idToken: this.state.user ? this.state.user.idToken : undefined,
          isAuthenticated: Boolean(this.state.user),
          isLoading:
            this.state.isSigningIn ||
            this.state.isSigningOut ||
            this.state.initializing,
          isSigningIn: this.state.isSigningIn,
          isSigningOut: this.state.isSigningOut,
          requestSignIn: this.requestSignIn,
          requestSignOut: this.requestSignOut,
          user: this.state.user,
        }}
      >
        <Switch>
          <Route
            exact={true}
            path="/_auth/callback"
            render={this.loginCallback}
          />
          <Route render={this.renderChildren} />
        </Switch>
      </AuthContextProvider>
    );
  }
}

export default withRouter(AuthProvider);

/*
    Init options:

      onLoad - Specifies an action to do on load. Supported values are 'login-required' or 'check-sso'.
      token - Set an initial value for the token.
      refreshToken - Set an initial value for the refresh token.
      idToken - Set an initial value for the id token (only together with token or refreshToken).
      timeSkew - Set an initial value for skew between local time and Keycloak server in seconds (only together with token or refreshToken).
      checkLoginIframe - Set to enable/disable monitoring login state (default is true).
      checkLoginIframeInterval - Set the interval to check login state (default is 5 seconds).
      responseMode - Set the OpenID Connect response mode send to Keycloak server at login request. Valid values are query or fragment . Default value is fragment, which means that after successful authentication will Keycloak redirect to javascript application with OpenID Connect parameters added in URL fragment. This is generally safer and recommended over query.
      flow - Set the OpenID Connect flow. Valid values are standard, implicit or hybrid.

      Returns promise to set functions to be invoked on success or error.

    login(options)

      Redirects to login form on (options is an optional object with redirectUri and/or prompt fields).
      Options is an Object, where:

      redirectUri - Specifies the uri to redirect to after login.
      prompt - By default the login screen is displayed if the user is not logged-in to Keycloak. To only authenticate to the application if the user is already logged-in and not display the login page if the user is not logged-in, set this option to none. To always require re-authentication and ignore SSO, set this option to login .
      maxAge - Used just if user is already authenticated. Specifies maximum time since the authentication of user happened. If user is already authenticated for longer time than maxAge, the SSO is ignored and he will need to re-authenticate again.
      loginHint - Used to pre-fill the username/email field on the login form.
      action - If value is 'register' then user is redirected to registration page, otherwise to login page.
      locale - Specifies the desired locale for the UI.

    createLoginUrl(options)

      Returns the URL to login form on (options is an optional object with redirectUri and/or prompt fields).
      Options is an Object, which supports same options like the function login .

*/
