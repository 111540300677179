import React from 'react';
import { getSubscriptionDisplayTitles, Subscription } from 'src/lib/types';
import { getSubscriptionBreadcrumbs } from 'src/lib/types/subscription/getSubscriptionBreadcrumbs';
import { t } from 'src/lib/i18n';
import { useRouter } from 'src/lib/utils/useRouter';
import { Switch, Route } from 'react-router';
import DataSummary from './DataSummary';
import { StatisticsContainer } from 'src/areas/main/statistics/StatisticsContainer';
import SubscriptionDocuments from '../../documents/SubscriptionDocuments';
import Invoices from '../../invoices/Invoices';
import { SubscriptionPageHeader } from '../../layout';
import Cases from 'src/areas/main/subscriptions/cases/Cases';

interface Props {
  subscription: Subscription;
}
export const DataSubscriptionPage: React.FC<Props> = ({ subscription }) => {
  const router = useRouter<{ id: string }>();
  if (!subscription) {
    return null;
  }

  const breadcrumbs = getSubscriptionBreadcrumbs(subscription);
  const i18n = t.subscriptions.SubscriptionPage;
  const { title } = getSubscriptionDisplayTitles(subscription);

  const navTabs = [
    {
      url: router.match.url,
      label: i18n.tabs.summary,
      exact: true,
    },
    // If a sub has monitoring, it will also have statistics.
    // Details on which subs has statistics: https://tsnwiki.atlassian.net/wiki/spaces/TK/pages/1517453450/Statistikk+-+Visning
    ...(subscription.isMonitoringPossible
      ? [
          {
            url: `${router.match.url}/statistikk`,
            label: i18n.tabs.statistics,
          },
        ]
      : []),
    ...((subscription.incidents && subscription.incidents.length) ||
    (subscription.plannedWork && subscription.plannedWork.length)
      ? [
          {
            url: `${router.match.url}/saker`,
            label: i18n.tabs.cases,
          },
        ]
      : []),
    {
      url: `${router.match.url}/dokumenter`,
      label: i18n.tabs.documents,
    },
  ];
  /**
   * TODO: Implement a "getSubscriptionDescription()"?
   * The subscription description will vary from the type (mobile, tele, data, tv), but also vary from subscription (IP VPN, IP VPN wireless, Dark Fiber etc.)
   */
  return (
    <>
      <SubscriptionPageHeader
        description=""
        subscription={subscription}
        title={title}
        breadcrumbs={breadcrumbs}
        navTabs={navTabs}
      />
      <div className="container pt-4 pb-5">
        <Switch>
          <Route
            path={router.match.path}
            exact={true}
            component={DataSummary}
          />
          {/* <Route
            path={`${router.match.path}/statistikk`}
            component={StatisticsSection}
          /> */}
          <Route
            path={`${router.match.path}/statistikk`}
            component={StatisticsContainer}
          />
          <Route path={`${router.match.path}/saker`} component={Cases} />
          <Route path={`${router.match.path}/fakturaer`} component={Invoices} />

          <Route
            path={`${router.match.path}/dokumenter`}
            component={SubscriptionDocuments}
          />
        </Switch>
      </div>
    </>
  );
};
