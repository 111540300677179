import * as React from 'react';
import { CSSTransition } from 'react-transition-group';

import './CircleProgress.scss';

// linechart
const radius = 0.45;
const circleLength = 2 * radius * Math.PI;

interface Props {
  /**
   * The current progress, 0 to 1. Values of 1 or above will show as a full circle.
   */
  progress: number;
  label: string;
  color: string;
}

// Note: The 90 degree rotate is there to place the 0/starting point at the top.

const CircleProgress: React.SFC<Props> = props => (
  <CSSTransition
    in={true}
    appear={true}
    enter={true}
    classNames={{
      appear: 'CircleProgress-appear',
      appearActive: 'CircleProgress-appear-active',
      enter: 'CircleProgress-enter',
      enterActive: 'CircleProgress-enter-active',
      enterDone: 'CircleProgress-enter-done',
      exit: 'CircleProgress-exit',
      exitActive: 'CircleProgress-exit-active',
      exitDone: 'CircleProgress-exit-done',
    }}
    timeout={5000}
  >
    {transitionState => (
      <div className="CircleProgress">
        <svg viewBox="0 0 1 1" xmlns="http://www.w3.org/2000/svg">
          <g transform="rotate(-90 0.5 0.5)">
            <circle
              className="CircleProgress-bg-circle"
              stroke="#eee"
              cx={0.5}
              cy={0.5}
              r={radius}
              strokeWidth={0.06}
              fill="none"
            />
            <circle
              className="CircleProgress-progress-circle"
              stroke={props.color}
              cx={0.5}
              cy={0.5}
              r={radius}
              fill="none"
              strokeWidth={0.05}
              strokeDasharray={[
                circleLength * props.progress,
                circleLength,
              ].join(' ')}
              strokeLinecap="round"
            />
          </g>
        </svg>
      </div>
    )}
  </CSSTransition>
);

export default CircleProgress;
