import { useCallback, useEffect, useMemo, useState } from 'react';
import { useSavedUserPreferences } from 'src/lib/userPreferences/SavedUserPreferencesContext';
import { useCustomer } from 'src/lib/global';

type CustomerSpecificTagPreferences = [string[], (tags: string[]) => void];

interface PersistedTagPreferences {
  [customerId: string]: string[];
}

/**
 * Parse an encoded string and return a PersistedTagPreferences object
 * @param s The encoded string
 */
function parsePersistedTagPreferences(
  s: string | null | undefined
): PersistedTagPreferences {
  if (!s) {
    return {};
  }
  try {
    const obj = JSON.parse(s);
    if (typeof obj !== 'object') {
      return {};
    }
    return obj;
  } catch (ex) {
    return {};
  }
}

/**
 * Fetch and save customer-specific tag filter preferences for the personalized dashboard
 */
export function useCustomerSpecificTagPreferences(): CustomerSpecificTagPreferences {
  const customer = useCustomer();
  const prefs = useSavedUserPreferences();

  /**
   * Load and parse the preferences from the userPreferences string
   */
  const preferenceObject = useMemo(
    () =>
      parsePersistedTagPreferences(prefs.userPreferences.dashboardFilterTags),
    [prefs.userPreferences.dashboardFilterTags]
  );

  const [selectedTags, setSelectedTagsInternal] = useState(
    () => preferenceObject[customer.id] ?? []
  );

  /**
   * When the preferences change or the current customer changes, update the
   * selected tags with the tags from the new customer.
   */
  useEffect(() => {
    setSelectedTagsInternal(preferenceObject[customer.id] ?? []);
  }, [customer, preferenceObject]);

  const setSelectedTags = useCallback(
    (tags: string[]) => {
      prefs.setUserPreference(
        'dashboardFilterTags',
        JSON.stringify({
          ...preferenceObject,
          [customer.id]: tags,
        })
      );
    },
    [customer, preferenceObject, prefs]
  );

  return [selectedTags, setSelectedTags];
}
