import React from 'react';
import { SearchResult } from '../search';
import { useRouter } from 'src/lib/utils/useRouter';
import {
  Table,
  TableBodyRow,
  urlFor,
  TableBodyCell,
  Badge,
  Badges,
} from 'src/lib/ui';
import { useContactsParams } from './useContactsParams';
import { Icon, IconDefinition } from '@telia/styleguide';
import { userIsAdmin } from './lib/editContact/utils';
import { formatName } from 'src/lib/utils/formatName';
import { ContactColumns } from 'src/lib/table';
import { Contact } from 'src/lib/types/contact/Contact';
import { StatusBadge } from 'src/lib/ui/StatusBadge/StatusBadge';
import { t } from 'src/lib/i18n';
import {
  getRoleList,
  getLevelSetForRole,
} from 'src/areas/main/contacts/lib/editContact/utils';
import { mapResponseToRoles } from 'src/areas/main/contacts/lib/editContact/mapResponseToRoles';

export const ContactsTable = (props: {
  loading: boolean;
  results: SearchResult[];
  noResultsMessage: string;
}) => {
  const router = useRouter();
  const { loading, results, noResultsMessage } = props;
  const {
    columns,
    activeSort,
    availableColumns,
    listParams,
  } = useContactsParams();

  return (
    <Table
      loading={loading}
      columns={columns}
      sortable={true}
      setSort={listParams.setSort}
      sortBy={listParams.sortBy}
      sortOrder={listParams.sortOrder}
      rowsCount={listParams.pageSize}
      noResult={results.length === 0}
      noResultMessage={noResultsMessage}
      activeSort={activeSort}
      onChangeColumns={listParams.setColumns}
      availableColumns={availableColumns}
    >
      {results.map(row => {
        const contact: Contact | undefined =
          row.__typename === 'Contact' ? row : undefined;

        if (!contact) {
          return null;
        }

        return (
          <TableBodyRow
            key={contact.id}
            onClick={() => {
              router.history.push(urlFor({ contact: contact.id }));
            }}
          >
            {columns.map((c, i) => (
              <ContactsTableCell
                key={`${c.key}-${i}`}
                column={c.key as ContactColumns}
                contact={contact}
              />
            ))}
          </TableBodyRow>
        );
      })}
    </Table>
  );
};

const ContactsTableCell = (props: {
  column: ContactColumns;
  contact: Contact;
}) => {
  const { column, contact } = props;

  const icon: IconDefinition = contact.user
    ? userIsAdmin(contact.user)
      ? 'user-admin'
      : 'user'
    : 'contact';

  const name = formatName({
    firstName: contact.firstName,
    lastName: contact.lastName,
  });

  const roles = getRoleList(
    mapResponseToRoles(contact.user?.customerAccess?.roles ?? [])
  );
  switch (column) {
    case 'icon':
      return (
        <TableBodyCell>
          <Icon icon={icon} />
        </TableBodyCell>
      );
    case 'name':
      return <TableBodyCell title={name}>{name}</TableBodyCell>;
    case 'phone':
      return (
        <TableBodyCell>
          <div className="d-flex flex-column">
            <div>{contact?.mobilePhone}</div>
            <div>{contact?.secondaryPhone}</div>
          </div>
        </TableBodyCell>
      );
    case 'email':
      return (
        <TableBodyCell title={contact.email ?? undefined}>
          {contact.email}
        </TableBodyCell>
      );
    case 'description':
      return <TableBodyCell>{contact.description}</TableBodyCell>;
    case 'cases':
      return (
        <TableBodyCell>
          {contact?.user?.emailVerified && (
            <StatusBadge
              label={t.contacts.status.waitingActivation}
              color="warning"
            />
          )}
        </TableBodyCell>
      );
    case 'access':
      return (
        <TableBodyCell>
          <Badges inline={true}>
            {roles.map(role => {
              const level = getLevelSetForRole(role);
              const levelLabel =
                level === 'full'
                  ? t.ui.PermissionCard.fullRoleAccess
                  : level === 'partial'
                  ? t.ui.PermissionCard.partialAccess
                  : t.ui.PermissionCard.noAccess;

              switch (role.role) {
                case 'CustomerAdministrator':
                  return (
                    <Badge>
                      {t.ui.ContactPermissionsForm.roles.CustomerAdministrator}
                    </Badge>
                  );
                case 'FinancialManager':
                  return (
                    <Badge>
                      {t.ui.ContactPermissionsForm.roles.FinancialManager}:{' '}
                      {levelLabel}
                    </Badge>
                  );
                case 'OrderManager':
                  return (
                    <Badge>
                      {t.ui.ContactPermissionsForm.roles.OrderManager}:{' '}
                      {levelLabel}
                    </Badge>
                  );
                case 'SystemAdministrator':
                  return (
                    <Badge>
                      {t.ui.ContactPermissionsForm.roles.SystemAdministrator}:{' '}
                      {levelLabel}
                    </Badge>
                  );
                default:
                  return null;
              }
            })}
          </Badges>
        </TableBodyCell>
      );
    // case 'invoiceCenter':
    // case 'tags':
    default:
      return null;
  }
};
