import { RadioButton } from 'src/lib/ui';
import React, { useRef } from 'react';
import cn from 'classnames';
import { generateUeid } from 'src/lib/utils';
import './RadioGroup.scss';

export interface RadioGroupProps {
  /**
   * Name for the RadioGroup. It has to be unique for each RadioGroup that is rendered.
   */
  name?: string;
  disabled?: boolean;
  error?: string;
  helpText?: string;
  value?: string;
  className?: string;
  label?: string;
  block?: boolean;
  options: RadioGroupOption[];
  onChange?: (value: string | number | boolean) => void;
  'aria-labelledby'?: string;
}
export interface RadioGroupOption {
  value: string | number | boolean;
  label: string;
  description?: string;
  disabled?: boolean;
}

export const RadioGroup: React.FunctionComponent<RadioGroupProps> = props => {
  const uniqueGroupId = useRef<string>(generateUeid());
  return (
    <div
      className={cn('RadioGroup', props.className)}
      role="radiogroup"
      aria-labelledby={props['aria-labelledby']}
    >
      {props.label ? (
        <label className="RadioGroup-label">{props.label}</label>
      ) : null}
      <div className={cn('RadioGroup-body', props.block ? 'block' : undefined)}>
        {props.options.map((option, idx) => (
          <RadioButton
            key={idx}
            name={props.name ? props.name : uniqueGroupId.current}
            checked={props.value === option.value}
            label={option.label}
            description={option.description}
            value={option.value}
            disabled={option.disabled}
            onChange={e => {
              if (props.onChange) {
                props.onChange(option.value);
              }
            }}
          />
        ))}
      </div>

      {props.error ? (
        <div className="RadioGroup-error">{props.error}</div>
      ) : null}
    </div>
  );
};
