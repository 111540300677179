import { push, Category } from './Analytics';

export type DocumentsActions =
  | 'download_document'
  | 'upload_document'
  | 'delete_document'
  | 'create_folder'
  | 'delete_folder';

export function trackDocument(category: Category, action: DocumentsActions) {
  push({
    category: category,
    action: action,
  });
}
