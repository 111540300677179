import React, { useState, useMemo } from 'react';
import { OptionSelectProps } from '../OptionSelect';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import { FullscreenHeader, Fullscreen, FullscreenBody } from 'src/lib/ui';
import { Collapse } from 'reactstrap';
import { FilterSectionFilter } from './FilterSectionFilter';
import { FilterSelection } from './FilterSelection';
import './FilterSectionFilterContainer.scss';
import { t } from 'src/lib/i18n';
import { sortBy } from 'lodash';
import { SelectedFilterChips } from './SelectedFilterChips';
import { useFeature } from 'src/lib/utils/useFeature';

export const FilterSectionFilterContainer: React.FC<{
  close: () => void;
  filterToggling?: boolean;
  open: boolean;
  results?: number;
  totalSearchResults?: number;
  filterOptions?: (OptionSelectProps & { alignRight?: boolean })[];
}> = props => {
  const md = useBreakpoint('md');
  const i18n = t.ui.FilterSection;
  const [currentFilterId, setCurrentFilterId] = useState<string | undefined>(
    undefined
  );

  const currentFilter: OptionSelectProps | undefined = useMemo(
    () =>
      currentFilterId && props.filterOptions
        ? props.filterOptions.find(o => o.id === currentFilterId)
        : undefined,
    [props.filterOptions, currentFilterId]
  );

  const leftGroup = props.filterOptions?.filter(o => !o.alignRight);
  const rightGroup = props.filterOptions?.filter(o => o.alignRight);
  const hasFewFilters =
    leftGroup?.length === 1 &&
    (props.filterOptions?.[0]?.options?.length ?? 0) < 4;

  const showActiveFiltersOutsideCollapseSection = useFeature(
    'filter-improvements'
  );

  const filterContent = (
    <div className="FilterSectionFilterContainer">
      <div className="FilterSectionFilterContainer-filters">
        <div className="FilterSectionFilterContainer-filters-group">
          {leftGroup?.map(o => (
            <FilterSectionFilter
              key={o.id}
              {...o}
              /**
               * If we only have one type of filter with less than 4 options,
               * we render them as toggles instead of a SelectField
               */
              renderFilterToggles={!o.loading && hasFewFilters}
            />
          ))}
        </div>
        <div className="FilterSectionFilterContainer-filters-group right">
          {rightGroup?.map(o => (
            <FilterSectionFilter key={o.id} {...o} />
          ))}
        </div>
      </div>
      {!showActiveFiltersOutsideCollapseSection && !hasFewFilters ? (
        <SelectedFilterChips filterOptions={props.filterOptions} />
      ) : null}
    </div>
  );
  const orderedOptions = sortBy(props.filterOptions, [o => o?.alignRight]);
  return md ? (
    props.filterToggling ? (
      <>
        <Collapse isOpen={props.open}>{filterContent}</Collapse>

        {showActiveFiltersOutsideCollapseSection && !hasFewFilters ? (
          <SelectedFilterChips filterOptions={props.filterOptions} />
        ) : null}
      </>
    ) : (
      <>
        {filterContent}

        {showActiveFiltersOutsideCollapseSection && !hasFewFilters ? (
          <SelectedFilterChips filterOptions={props.filterOptions} />
        ) : null}
      </>
    )
  ) : props.open ? (
    <Fullscreen>
      <FullscreenHeader
        title={currentFilter ? currentFilter.label : 'Filter'}
        hideBackOption={!currentFilter}
        onClickBack={() => setCurrentFilterId(undefined)}
      />
      <FullscreenBody>
        <div className="FilterSectionFilterContainer--mobile">
          {currentFilter ? (
            <FilterSelection
              {...currentFilter}
              goBack={() => setCurrentFilterId(undefined)}
            />
          ) : (
            <>
              {orderedOptions?.map(o => (
                <FilterSectionFilter
                  {...o}
                  key={o.id}
                  onClick={() => setCurrentFilterId(o.id)}
                  /**
                   * If we only have one type of filter with less than 4 options,
                   * we render them as toggles instead of a SelectField
                   */
                  renderFilterToggles={
                    (props.filterOptions || []).length < 2 &&
                    o.options.length < 4
                  }
                />
              ))}
            </>
          )}
          {!currentFilter && (
            <button
              onClick={props.close}
              className="FilterSectionFilterContainer--mobile-footer"
            >
              {props.totalSearchResults
                ? i18n.showResults(props.totalSearchResults)
                : i18n.noResults}
            </button>
          )}
        </div>
      </FullscreenBody>
    </Fullscreen>
  ) : null;
};
