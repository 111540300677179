import { WithChangelogs_changelogs } from './query/__types/WithChangelogs';
import React from 'react';
import { Card, OptionsDropdownHeader, OptionsDropdownItem } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { ChangelogDropdownItem } from 'src/areas/dashboard/Changelog/ChangelogDropdownItem';

type Props = {
  changelogs?: WithChangelogs_changelogs[] | null;
  totalResults?: number;
};

export function ListChangelogForUser(props: Props) {
  return (
    <Card
      className="ListChangelogForUser"
      shadow="1"
      border={true}
      padding={false}
      style={{ maxWidth: '320px' }}
    >
      <OptionsDropdownHeader heading={t.changelogs.displayListHeader} />

      {props.changelogs?.map(changelog => (
        <ChangelogDropdownItem key={changelog.id} changelog={changelog} />
      ))}

      <OptionsDropdownItem
        centered={true}
        aria-label={t.changelogs.toDetailedPage}
        label={t.changelogs.toDetailedPage}
      />
    </Card>
  );
}
