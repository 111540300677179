import * as React from 'react';
import { Card, HeadingPicker, Button, WithState } from 'src/lib/ui';
import { CircleChart } from 'src/lib/charts';

import { map } from 'lodash';

import OrganisationCostSummaryTable from './OrganisationCostSummaryTable';
import {
  CostCardHeader,
  CostMatrix,
  categoryDefinitions,
  PeriodSelector,
} from '..';

import { PeriodBy } from 'src/lib/types';
import { t } from 'src/lib/i18n';

import './OrganisationCostSummary.scss';

interface Props {
  costMatrix?: CostMatrix;
  costType: 'gross' | 'net';
  header?: string | React.ReactNode;
  organisationRootUrl?: string;
  downloadLink?: string;
  summaryDescription?: string | React.ReactNode;
  periodProps?: {
    periods: Array<{ key: string; label: string }>;
    activePeriod: string;
    periodBy: PeriodBy;
    setPeriodBy: (periodBy: PeriodBy) => void;
    setActivePeriod: (period: string) => void;
  };
}

const OrganisationCostSummary: React.FC<Props> = props => {
  if (!props.costMatrix) {
    return null;
  }
  const { costMatrix } = props;
  const categoryDefs = categoryDefinitions();
  const circleChartSegments = map(categoryDefs, cat => ({
    color: cat.color,
    label: cat.label,
    value: costMatrix[cat.key].total[props.costType],
  }));

  const i18n = t.costs.ui.OrganisationCostSummary;

  return (
    <Card className="OrganisationCostSummary p-0">
      <CostCardHeader>
        {props.header && (
          <h3 style={{ margin: 0, padding: 0, fontWeight: 'normal' }}>
            {props.header}
          </h3>
        )}
        {props.periodProps && (
          <React.Fragment>
            <HeadingPicker
              options={props.periodProps.periods}
              selectedOption={props.periodProps.activePeriod}
              setOption={opt =>
                props.periodProps && props.periodProps.setActivePeriod(opt.key)
              }
            />
            <PeriodSelector
              className="px-4"
              setActivePeriodType={props.periodProps.setPeriodBy}
              activePeriodType={props.periodProps.periodBy}
            />
          </React.Fragment>
        )}
        {/* <div style={{ marginLeft: 'auto' }}>
          <Button icon="download">{i18n.downloadBtn}</Button>
        </div> */}
      </CostCardHeader>
      <div className="OrganisationCostSummary-body pl-4 ">
        <div className="pt-4 pb-4 pr-4">
          <div className="p-4">
            <WithState<number | undefined> default={undefined}>
              {(activeIndex, setActiveIndex) => (
                <div style={{ minWidth: '260px' }}>
                  <CircleChart
                    activeIndex={activeIndex}
                    segments={circleChartSegments}
                    label={i18n.circleChartLabel}
                    onClickSegment={idx => {
                      if (idx === activeIndex) {
                        setActiveIndex(undefined);
                      } else {
                        setActiveIndex(idx);
                      }
                    }}
                  />
                </div>
              )}
            </WithState>
          </div>
          {props.summaryDescription && <div>{props.summaryDescription}</div>}
          {props.organisationRootUrl && (
            <div className="px-4">
              <Button
                base="primary"
                color="purple"
                className="d-block"
                disabled={true}
                to={`${props.organisationRootUrl}/detaljer`}
              >
                {i18n.detailsBtn}
              </Button>
            </div>
          )}
        </div>
        <div className="OrganisationCostSummary-tableWrapper">
          <OrganisationCostSummaryTable
            costMatrix={props.costMatrix}
            costType={props.costType}
          />
        </div>
      </div>
    </Card>
  );
};

export default OrganisationCostSummary;
