import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { Folder, File, FileFragment } from 'src/lib/types';
import { FolderFragment } from 'src/lib/types/documents/Folder';

export const SUBSCRIPTION_DOCUMENTS_QUERY = gql`
  query WithSubscriptionFilesAndFolders(
    $customerId: ID!
    $subscriptionId: ID!
  ) {
    customer(id: $customerId) {
      __typename
      id
      subscriptions(ids: [$subscriptionId]) {
        __typename
        id
        documents {
          __typename
          allFolders {
            ...FolderFragment
          }
          allFiles {
            ...FileSearchResult
          }
        }
      }
    }
  }
  ${FileFragment}
  ${FolderFragment}
`;

interface Variables {
  customerId: string;
  subscriptionId: string;
}

interface Data {
  customer: {
    subscriptions: [
      {
        documents?: {
          allFiles: File[];
          allFolders: Folder[];
        };
      }
    ];
  };
}

interface Result {
  loading: boolean;
  files: File[];
  folders: Folder[];
  refetch?: () => void;
}

export const useSubscriptionFilesAndFolders = (
  subscriptionId: string,
  customerId: string
): Result => {
  const { data, loading, refetch } = useQuery<Data, Variables>(
    SUBSCRIPTION_DOCUMENTS_QUERY,
    {
      variables: {
        customerId: customerId,
        subscriptionId,
      },
      errorPolicy: 'all',
    }
  );

  const { files, folders } =
    !loading &&
    data &&
    data.customer &&
    data.customer.subscriptions &&
    data.customer.subscriptions[0] &&
    data.customer.subscriptions[0].documents &&
    data.customer.subscriptions[0].documents.allFolders.length > 0
      ? {
          files: data.customer.subscriptions[0].documents.allFiles.filter(
            f => f.uploading !== true
          ),
          folders: data.customer.subscriptions[0].documents.allFolders,
        }
      : { files: [], folders: [] };

  return { loading, files, folders, refetch };
};
