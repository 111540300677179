import * as React from 'react';
import './UndoPopup.scss';
import { t } from 'src/lib/i18n';
import { Button } from 'src/lib/ui';
import { toast } from 'react-toastify';
import { CloseButton } from 'src/lib/ui/Toast/Toast';

export const undoPopup = (props: {
  msg: string | React.ReactNode;
  mutation: () => void;
}) =>
  toast(
    <div className="d-flex align-items-center">
      <div>{props.msg}</div>
      <Button
        className="ml-auto mr-2"
        color="white"
        size="small"
        transparent={true}
        onClick={() => props.mutation()}
      >
        {t.documents.UndoPopup.undo}
      </Button>
    </div>,
    {
      position: toast.POSITION.BOTTOM_CENTER,
      className: 'UndoPopup',
      progressClassName: 'UndoPopup-progress',
      closeButton: <CloseButton />,
      autoClose: 5000,
    }
  );

export default undoPopup;
