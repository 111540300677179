import { ResourceType } from 'src/lib/types';
import { t } from 'src/lib/i18n';

export const mapResourceTypeToLabel = (type: ResourceType): string => {
  const i18n = t.search.ResourceType;

  switch (type) {
    case 'contact':
      return i18n.contact;
    case 'costCenter':
      return i18n.costCenter;
    case 'file':
      return i18n.file;
    case 'incident':
      return i18n.incident;
    case 'invoice':
      return i18n.invoice;
    case 'order':
      return i18n.order;
    case 'organisation':
      return i18n.organisation;
    case 'subscription':
      return i18n.subscription;
    case 'dataSubscription':
      return i18n.dataSubscription;
    case 'mobileSubscription':
      return i18n.mobileSubscription;
    case 'teleSubscription':
      return i18n.teleSubscription;
    case 'tvSubscription':
      return i18n.tvSubscription;
    case 'otherSubscription':
      return i18n.otherSubscription;
    case 'tag':
      return i18n.tag;
    default:
      return '';
  }
};
