import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useOrganisations';

export const GET_ORGANISATIONS = gql`
  query useOrganisations($customerId: ID!) {
    customer(id: $customerId) {
      id
      organisations {
        id
        name
      }
    }
  }
`;

export const useOrganisations = () => {
  const customer = useCustomer();
  const { data, loading, error } = useQuery<
    t.useOrganisations,
    t.useOrganisationsVariables
  >(GET_ORGANISATIONS, {
    variables: { customerId: customer.id },
    errorPolicy: 'all',
  });

  return {
    organisations: data?.customer?.organisations,
    loading,
    error: !!error,
  };
};
