import * as React from 'react';
import WithBuildDetails from './WithBuildDetails';
import { distanceInWordsToNow } from 'date-fns';
import { BuildDetail } from './BuildDetail';

const Heading: React.SFC<{ heading: string }> = ({ heading }) => (
  <tbody>
    <tr>
      <th
        style={{
          fontSize: '16px',
          marginTop: '8px',
          fontWeight: 700,
          width: '120px',
        }}
      >
        {heading}
      </th>
      <th>&nbsp;</th>
    </tr>
  </tbody>
);

const Body: React.SFC<{ buildDetails: BuildDetail; repo: string }> = ({
  buildDetails: { branch, buildTime, commit, commitTime, env },
  repo,
}) => (
  <tbody>
    {env ? (
      <tr>
        <th>Environment:</th>
        <td>{env}</td>
      </tr>
    ) : null}
    {buildTime ? (
      <tr>
        <th>Build age:</th>
        <td>{distanceInWordsToNow(buildTime)} ago</td>
      </tr>
    ) : null}
    {buildTime ? (
      <tr>
        <th>Build time:</th>
        <td>{buildTime}</td>
      </tr>
    ) : null}
    {branch ? (
      <tr>
        <th>Branch:</th>
        <td>{branch}</td>
      </tr>
    ) : null}
    {commit ? (
      <>
        <tr>
          <th>Commit:</th>
          <td>
            <a
              href={`https://github.com/telia-company/${repo}/commit/${commit}`}
            >
              {commit.substr(0, 8)}
            </a>
            &nbsp;
            <a
              href={`https://github.com/telia-company/${repo}/compare/${commit}...master`}
            >
              [diff]
            </a>
          </td>
        </tr>
      </>
    ) : null}
    {commitTime ? (
      <tr>
        <th>Commit age:</th>
        <td>{distanceInWordsToNow(commitTime)} ago</td>
      </tr>
    ) : null}
    {commitTime ? (
      <tr>
        <th>Commit time:</th>
        <td>{commitTime}</td>
      </tr>
    ) : null}
  </tbody>
);

export const BuildDetails: React.SFC = () => (
  <>
    <h1>Build details</h1>

    <WithBuildDetails>
      {build => (
        <table className="Dev-table">
          <Heading heading="Frontend" />
          <Body
            buildDetails={build.frontend}
            repo="getaccess-minportal-frontend"
          />
          <Heading heading="Gateway" />
          <Body
            buildDetails={build.gateway}
            repo="getaccess-gettdc-tdc-portal-gateway"
          />
          <Heading heading="Backend" />
          <Body
            buildDetails={build.backend}
            repo="getaccess-gettdc-tdc-portal-api"
          />
        </table>
      )}
    </WithBuildDetails>
  </>
);
