import React, { useContext } from 'react';

export type SubscriptionContext = {
  id: string;
  name: string;
  customerId: string;
  availableSubscriptionIds: string[];
  selectSubscriptionId: (subscriptionId: string) => void;
  disableEdit?: boolean;
};

const SubscriptionContextCreate = React.createContext<
  SubscriptionContext | undefined
>(undefined);

const { Provider, Consumer } = SubscriptionContextCreate;

const SubscriptionContextConsumer: React.SFC<{
  children: (ctx: SubscriptionContext) => React.ReactNode;
}> = props => (
  <Consumer>
    {subscription => {
      if (!subscription) {
        throw new Error('No subscription provided');
      }
      return props.children(subscription);
    }}
  </Consumer>
);

function useSubscriptionContext() {
  const context = useContext(SubscriptionContextCreate);
  if (!context) {
    throw new Error('No subscription provided');
  }
  return context;
}

export {
  Provider as SubscriptionContextProvider,
  SubscriptionContextConsumer,
  Consumer as SubscriptionContextOptionalConsumer,
  useSubscriptionContext,
};
