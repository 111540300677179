import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import moment from 'moment';

import {
  Button,
  urlFor,
  OptionsDropdown,
  OptionsDropdownToggle,
  OptionsDropdownMenu,
  OptionsDropdownHeader,
  OptionsDropdownItem,
} from 'src/lib/ui';
import { useSavedUserPreferences } from 'src/lib/userPreferences/SavedUserPreferencesContext';
import { useToggler } from 'src/areas/mobile/settings/useToggler';
import { t } from 'src/lib/i18n';
import { useQueryUserDropdownChangelogs } from 'src/areas/dashboard/Changelog/useQueryUserDropdownChangelogs';
import { useRouter } from 'src/lib/utils/useRouter';

import './ChangelogDropdown.scss';

import { ChangelogDropdownItem } from './ChangelogDropdownItem';

import { trackChangelog } from '../../../lib/analytics';

export const ChangelogDropDown = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [changelogLightMap, setChangelogLightMap] = useToggler({});
  const { userPreferences, setUserPreference } = useSavedUserPreferences();
  const [lightChangelogIcon, setLightChangelogIcon] = useState(false);
  const changelogs = useQueryUserDropdownChangelogs().data?.changelogs;
  const maxChangelogsInDropdown = 4;
  const router = useRouter();

  useEffect(() => {
    if (changelogs && changelogs.length > 0) {
      const mom = moment(userPreferences.changelogLastSeenAtTimestamp);
      for (const cl of changelogs) {
        if (
          moment(cl.publishedAt).isAfter(mom) ||
          userPreferences.changelogLastSeenAtTimestamp === undefined
        ) {
          setLightChangelogIcon(true);
          setChangelogLightMap({ [cl.id]: true });
        }
      }
    }
  }, [changelogs]); // eslint-disable-line react-hooks/exhaustive-deps

  function handleClick() {
    if (!isOpen) {
      trackChangelog();
      setUserPreference(
        'changelogLastSeenAtTimestamp',
        moment().format('YYYY-MM-DD HH:mm:ss')
      );
    } else {
      setLightChangelogIcon(false);
      changelogs?.forEach(cl => {
        setChangelogLightMap({ [cl.id]: false });
      });
    }

    setIsOpen(!isOpen);
  }

  return (
    <OptionsDropdown
      className="ChangelogDropdown"
      isOpen={isOpen}
      toggle={handleClick}
      direction="down"
    >
      <OptionsDropdownToggle tag="span">
        <Button
          className={cn(
            'ml-2',
            lightChangelogIcon ? 'ChangelogDropdown-showlight' : undefined
          )}
          icon="lightbulb"
          hideLabel={true}
          color="white"
          transparent={true}
          title={t.changelogs.toggleBtn}
        >
          {t.changelogs.toggleBtn}
        </Button>
      </OptionsDropdownToggle>

      <OptionsDropdownMenu right={true} className="ChangelogDropdown-menu mt-3">
        <OptionsDropdownHeader heading={t.changelogs.displayListHeader} />

        {changelogs?.slice(0, maxChangelogsInDropdown).map(changelog => (
          <ChangelogDropdownItem
            key={changelog.id}
            highlight={changelogLightMap[changelog.id]}
            onClick={() =>
              router.history.push(
                urlFor({
                  area: 'changelog',
                  publishedDate: moment(
                    changelog.publishedAt,
                    'YYYY-MM-DD'
                  ).format('YYYY-MM-DD'),
                })
              )
            }
            changelog={changelog}
          />
        ))}

        <OptionsDropdownItem
          centered={true}
          aria-label={t.changelogs.toDetailedPage}
          onClick={() => router.history.push('/changelog')}
          label={t.changelogs.toDetailedPage}
        />
      </OptionsDropdownMenu>
    </OptionsDropdown>
  );
};
