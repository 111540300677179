import * as React from 'react';
import './CostCardHeader.scss';

const CostCardHeader: React.SFC<{ className?: string }> = props => (
  <div
    className={`CostCardHeader py-2 px-4 ${
      props.className ? props.className : ''
    }`}
  >
    {props.children}
  </div>
);

export default CostCardHeader;
