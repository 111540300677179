import { useState } from 'react';
import { useAppContext } from 'src/lib/global';
import { useCreateIncident } from '../../mutations/WithCreateIncident';
import { useRouter } from 'src/lib/utils/useRouter';
import { Item } from 'src/lib/ui';
import axios from 'axios';
import {
  IncidentSeverity,
  IncidentType,
} from 'src/__types/graphql-global-types';

interface Values {
  description: string;
  files: File[];
  contacts?: Item[];
  severity?: IncidentSeverity;
  incidentType?: IncidentType;
  emailNotification?: boolean;
}

function isString(entry: string | undefined): entry is string {
  return typeof entry === 'string';
}
export const useSubmitIncident = (subscriptionId: string) => {
  const app = useAppContext();
  const { createIncident, ...createIncidentContext } = useCreateIncident();
  const [loading, setLoading] = useState(false);
  const router = useRouter();

  const submitIncident = (values: Values) => {
    setLoading(true);
    const input = {
      description: values.description,
      subscriptionId: subscriptionId,
      severity: values.severity,
      incidentType: values.incidentType,
      contactIds: values.contacts
        ? values.contacts.map(c => c.id).filter(isString)
        : undefined,
      emailNotification: values.emailNotification,
    };

    const uploadFile = async (file: File, incidentId?: string) => {
      if (!incidentId) {
        return;
      }
      const formData = new FormData();
      formData.append('file', file, file.name);

      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
          Authorization: `Bearer ${app.auth.bearerToken}`,
        },
      };
      return axios
        .post(
          [
            '/api/files/incidents/',
            encodeURIComponent(incidentId),
            '/attachment',
          ].join(''),
          formData,
          config
        )
        .catch(e => {
          throw new Error(e);
        });
    };

    createIncident(input)
      .then(res => {
        Promise.all(
          values.files.length
            ? values.files.map(f =>
                uploadFile(
                  f,
                  res.data?.createIncident?.incident
                    ? res.data.createIncident.incident.id
                    : undefined
                )
              )
            : []
        )
          .then(r => {
            if (res.data?.createIncident?.incident) {
              router.history.push(
                `/meldinger/${res.data.createIncident.incident.id}`
              );
            }
          })
          .catch(e => {
            throw new Error(e);
          });
      })
      .finally(() => setLoading(false));
  };

  return {
    submitIncident,
    data: createIncidentContext.data,
    error: createIncidentContext.error,
    loading,
  };
};
