import React from 'react';
import { t } from 'src/lib/i18n';
import { BodyCard, colors } from 'src/lib/ui';

export const NotMonitored = () => {
  const i18n = t.subscriptions.NotMonitored;

  return (
    <BodyCard>
      <div className="pb-3">{i18n.title}</div>
      <small style={{ color: colors.greyDarkText }}>{i18n.description}</small>
    </BodyCard>
  );
};
