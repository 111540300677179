import { map, filter } from 'lodash';
import { CostAggregate } from '..';

interface MobileCostGroups {
  [key: string]: {
    label: string;
    color?: string;
  };
}

/**
 * Default color for groups without a color defined
 */
const defaultColor = '#343643';

const groups: MobileCostGroups = {
  abonnement: { label: 'Abonnement' },
  data: { label: 'Mobildata', color: '#eb0066' },
  innhold: { label: 'Innholdstjenester', color: '#00d7ea' },
  spesial: { label: 'Spesialnummer', color: '#00a57c' },
  smsUtland: { label: 'SMS/MMS utland', color: '#ff3e4f' },
  anropUtland: { label: 'Anrop utland', color: '#4c5bda' },
  utland: { label: 'Utland' },
  sms: { label: 'SMS/MMS', color: '#313b9c' },
  fastnett: { label: 'Fastnettjenester' },
  anrop: { label: 'Anrop' },
  ekstra: { label: 'Ekstratjenester' },
  annet: { label: 'Annet', color: '#343643' },
};

interface Options {
  filterBy?: {
    costType?: string;
  };
}

export const groupMobileCostAggregates = (
  aggs: CostAggregate[],
  opts?: Options
) => {
  const costTypeFilter =
    opts && opts.filterBy && opts.filterBy.costType
      ? opts.filterBy.costType
      : undefined;

  const costAggregates = costTypeFilter
    ? filter(aggs, a => a.costType === costTypeFilter)
    : aggs;

  return map(costAggregates, ag => {
    const group =
      ag.groupedProductDesign && groups[ag.groupedProductDesign]
        ? groups[ag.groupedProductDesign]
        : groups.annet;

    return {
      key: ag.groupedProductDesign,
      label: group.label,
      color: group.color || defaultColor,
      totalAmountGross: ag.totalAmountGross,
      totalAmountNet: ag.totalAmountNet,
    };
  });
};
