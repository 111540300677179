import * as React from 'react';

interface Props<T> {
  url: string;
  children: (state: State<T>) => JSX.Element | null;
}

type State<T> =
  | {
      loading: true;
      error: false;
      data: undefined;
    }
  | {
      loading: false;
      error: false;
      data: any;
    }
  | {
      loading: false;
      error: true;
      data: undefined;
    };

class WithRemoteData<T = any> extends React.Component<Props<T>, State<T>> {
  constructor(props: Props<T>) {
    super(props);
    this.state = {
      loading: true,
      error: false,
      data: undefined,
    };
  }

  componentDidMount() {
    fetch(this.props.url)
      .then(data => data.json())
      .then(data => {
        this.setState({
          error: false,
          loading: false,
          data,
        });
      })
      .catch(err => {
        console.error(err);
        this.setState({
          loading: false,
          error: true,
        });
      });
  }

  render() {
    return this.props.children(this.state);
  }
}

export default WithRemoteData;
