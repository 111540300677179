import * as React from 'react';
import { CpeStatus } from 'src/__types/graphql-global-types';
import './MapMarker.scss';
import { MarkerProps } from '../../types';

import wireless_off from './map_error.svg';
import question from './map_no_status.svg';
import wireless from './map_online.svg';
import not_monitored from './map_not_monitored.svg';

/**
 * Note: lat/lon is not used by the marker itself, but by
 * the Google Maps integration. They need to be present in Props.
 */

const getMarkerFromStatus = (status?: CpeStatus | null) => {
  switch (status) {
    case CpeStatus.Critical:
      return { svg: wireless_off, alt: 'active-subscriptions status-critical' };
    case CpeStatus.Unknown:
      return { svg: question, alt: 'active-subscriptions status-unknow' };
    case CpeStatus.NoStatus:
      return { svg: question, alt: 'active-subscriptions status-noStatus' };
    case CpeStatus.Normal:
      return { svg: wireless, alt: 'active-subscriptions status-normal' };
    default:
      return { svg: not_monitored, alt: 'active-subscriptions status-null' };
  }
};
function MapMarker(props: MarkerProps) {
  const marker = getMarkerFromStatus(props.status);
  return (
    <div
      className="MapMarker"
      onClick={props.onClick}
      tabIndex={props.onClick ? 0 : undefined}
      role={props.onClick ? 'button' : undefined}
    >
      <img src={marker?.svg} alt={marker?.alt} />
    </div>
  );
}

export default MapMarker;
