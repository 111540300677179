import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import { TextField, toast } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useEditCustomerReference } from '../../mutations/useEditCustomerReference';
import { useRouter } from 'src/lib/utils/useRouter';
import { useIncident } from 'src/areas/main/incidents/queries/useIncident';

interface Props {
  disabled?: boolean;
  className?: string;
}

const EditCustomerReference: React.FC<Props> = props => {
  const {
    editCustomerReference,
    loading,
    data,
    error,
  } = useEditCustomerReference();
  const router = useRouter<{ id: string }>();
  const { incident } = useIncident(router.match.params.id);
  const [customerRef, setCustomerRef] = useState(incident?.customerReference);
  const [errorText, setErrorText] = useState<string | undefined>(undefined);

  useEffect(() => setCustomerRef(incident?.customerReference), [
    incident,
    // eslint-disable-next-line
    incident?.customerReference,
  ]);
  useEffect(() => {
    if (error) {
      toast('error', t.incidents.IncidentSummary.referenceErrorDefault);
    }
  }, [error]);

  useEffect(() => {
    if (data && !error) {
      toast('ok', t.incidents.IncidentSummary.referenceSaved);
    }
  }, [data, error]);

  useEffect(() => setErrorText(undefined), [loading]);

  useEffect(() => {
    if (customerRef && customerRef.length > 40) {
      return setErrorText(t.incidents.IncidentSummary.referenceErrorTooLong);
    } else if (errorText?.length) {
      setErrorText(undefined);
    }
  }, [customerRef, errorText]);

  const saveCustomerReference = () => {
    if (
      incident?.id &&
      incident?.customerReference !== customerRef &&
      !errorText
    ) {
      editCustomerReference(incident.id, customerRef ?? '');
    }
  };

  return (
    <div className="EditCustomerReference">
      <small className="d-block mx-3">
        {t.incidents.IncidentSidebar.referenceNumberDescription}
      </small>
      <TextField
        className={cs('EditCustomerReference', 'w-100', props.className)}
        helpText={t.incidents.EditCustomerReference.helpText}
        label={t.incidents.EditCustomerReference.title}
        error={errorText}
        loading={loading}
        value={customerRef ?? ''}
        onChange={e => setCustomerRef(e.target.value)}
        onBlur={saveCustomerReference}
        disabled={props.disabled}
        bordered={true}
      />
    </div>
  );
};

export default EditCustomerReference;
