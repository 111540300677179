const documents = {
  title: 'Dokumenter',
  thName: 'Navn',
  thLastChange: 'Sist endret',
  thLastChangeBy: 'Sist endret av',
  thSize: 'Størrelse',
  thSharing: 'Deling',
  btnUpload: 'Last opp',
  btnNewFolder: 'Ny mappe',
  rootFolder: 'Rotmappe',
  folderIsEmpty: 'Mappen er tom.',
  genericError: 'Det oppstod en feil.',
  shared: 'Delt med kunde',
  internal: 'Telia internt',

  newFolderModal: {
    heading: 'Opprett ny mappe',
    label: 'Navn',
    submit: 'Opprett mappe',
  },
  uploadModal: {
    placeholder: {
      main: 'Her er det ingen dokumenter enda',
      secondary: 'Dra en fil hit, eller klikk for å laste opp',
    },
  },
  downloadModal: {
    heading: 'Last ned fil',
    submit: 'Last ned',
  },

  DeleteFileForm: {
    submit: 'Slett',
    confirmDelete: 'Er du sikker på at du vil slette filen',
  },

  DeleteFolderForm: {
    submit: 'Slett',
    confirmDelete: 'Er du sikker på at du vil slette mappen',
  },

  ChangeSharingModal: {
    heading: 'Du er i ferd med å endre deling av denne filen',
    confirm: 'Bekreft',
    setFileSharedConfirmation:
      'Er du sikker på at du vil dele denne filen? Kunden vil få tilgang til filen.',
    setFileInternalConfirmation:
      'Er du sikker på at du vil gjøre denne filen til Telia intern? Kunden som nå har tilgang til filen vil miste tilgangen.',
  },

  NewFolderForm: {
    nameLabel: 'Navn',
    submit: 'Opprett mappe',
    accessControl: 'Tilgangsstyrt',
  },

  FileOptions: {
    deleteFile: 'Slett fil',
    renameFile: 'Gi nytt navn',
    deleteFileModalHeading: 'Slett fil',
    renameFileModalHeading: 'Gi nytt navn på fil',
    setFileShared: 'Set filen som delt',
    setFileInternal: 'Set filen til Telia intern',
  },

  FolderOptions: {
    accessFolder: 'Tilgangsstyring',
    deleteFolder: 'Slett mappe',
    renameFolder: 'Gi nytt navn',
    deleteFolderModalHeading: 'Slett mappe',
    renameFolderModalHeading: 'Gi nytt navn på mappe',
  },

  validations: {
    nameTooLong: (max: number) =>
      `Navnet kan ikke bestå av mer enn ${max} tegn.`,
    nameTooShort: 'Navnet kan ikke være tomt.',
  },

  RenameFileForm: {
    newNameLabel: 'Nytt navn',
    newNamePlaceholder: 'Filnavn',
    submit: 'Gi nytt navn',
  },

  RenameFolderForm: {
    newNameLabel: 'Nytt navn',
    newNamePlaceholder: 'Mappenavn',
    submit: 'Gi nytt navn',
  },

  UpdateFolderAccessForm: {
    description: 'Legg til personer som skal ha tilgang til mappen',
    submit: 'Lagre tilganger',
    personsWithAccess: 'Personer med tilgang',
  },

  errors: {
    couldNotMoveFile: 'Ops! Vi klarte ikke flytte filen',
    couldNotMoveFolder: 'Ops! Vi klarte ikke flytte mappen',
  },

  UndoActions: {
    movedFile: 'Flyttet filen',
    movedFolder: 'Flyttet mappen',
    deletedFile: 'Slettet filen',
    deletedFolder: 'Slettet mappen',
    renamedFolderA: 'Endret navn på mappen',
    renamedFolderB: '. Nytt navn er ',
    renamedFileA: 'Endret navn på filen',
    renamedFileB: '. Nytt navn er ',
    to: 'til',
  },

  UndoPopup: {
    undo: 'Angre',
    undoing: 'Angrer ...',
  },
};

export default documents;
