import { Definition } from './types';
import { DataColumns, formatColumns } from './index';
import { getSortOptions, getSortOption } from '../utils/getSortOptionsByKey';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getDataColumnDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const dataColumnMap: {
    [key in DataColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    status: {
      label: t.subscriptions.DataSubscriptionTable.status,
      sortOptions: getSortOption('monitoringStatus', true),
      cellWidth: 180,
      fixed: true,
    },
    name: {
      label: t.subscriptions.GenericSubscriptionTable.service,
      fixed: true,
      sortOptions: getSortOptions(['alias', 'type', 'subscriptionId']),
      cellWidth: 240,
    },
    address: {
      label: t.subscriptions.GenericSubscriptionTable.address,
      sortOptions: getSortOption('address', true),
    },
    cases: {
      label: t.subscriptions.DataSubscriptionTable.cases,
      cellWidth: 200,
    },
    cpe: {
      label: t.subscriptions.DataSubscriptionTable.cpeModel,
    },
    invoiceCenter: {
      label: t.subscriptions.GenericSubscriptionTable.invoiceCenter,
    },
    costCenter: {
      label: t.subscriptions.GenericSubscriptionTable.costCenter,
    },
    tags: {
      label: t.subscriptions.GenericSubscriptionTable.tags,
    },
  };
  const defaultDataColumns: Array<DataColumns> = [
    'status',
    'name',
    'address',
    'cases',
    'cpe',
  ];

  return formatColumns({
    map: dataColumnMap,
    defaultColumns: defaultDataColumns,
    savedColumnPreferences,
  });
};
