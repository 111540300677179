import * as React from 'react';
import { Button } from 'src/lib/ui';
import './DownloadFileForm.scss';
import { formatFileSize } from 'src/lib/utils';
import FileIcon from '../table/FileIcon';
import { t } from 'src/lib/i18n';
import { File } from 'src/lib/types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useDocumentsContext } from '../DocumentsContext';
import { trackDocument } from 'src/lib/analytics';

interface Props {
  file: File;
  cancel: () => void;
}

const DownloadFileForm: React.SFC<Props> = props => {
  const downloadUrl = props.file.downloadToken
    ? `/api/files/documents/file/data?downloadToken=${encodeURIComponent(
        props.file.downloadToken
      )}`
    : undefined;

  const { category } = useDocumentsContext();
  const trackDownloadButton = () => {
    trackDocument(category, 'download_document');
  };
  return (
    <>
      <ModalBody>
        <div className="DownloadFileForm">
          <div className="DownloadFileForm-icon">
            <FileIcon mimeType={props.file.mimeType} />
            <div className="my-2">{props.file.name}</div>
            <div style={{ fontSize: '14px' }}>
              {formatFileSize(props.file.size ? props.file.size : 0)}
            </div>
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button
          base="primary"
          color="white"
          className="mr-3"
          onClick={props.cancel}
        >
          {t.ui.FormSubmitBar.btnCancel}
        </Button>
        <a
          className="DownloadFileForm-link"
          target="_blank"
          rel="noopener noreferrer"
          href={downloadUrl}
        >
          <Button
            base="primary"
            color="purple"
            icon="download"
            onClick={trackDownloadButton}
          >
            {t.documents.downloadModal.submit}
          </Button>
        </a>
        {/* <DownloadLink href={`/api/file?fileId=${props.file.id}`}>
          <Icon icon="download" className="pr-1" />
          Last ned
        </DownloadLink> */}
      </ModalFooter>
    </>
  );
};

export default DownloadFileForm;
