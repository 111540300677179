import * as React from 'react';
import { DetailCard, List, ListResultCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import { Report } from './context/ReportContext';

type Props = {
  selectReport: (reportType: Report) => void;
};

export const LandLine = (props: Props) => {
  const translate = t.reports;

  return (
    <DetailCard heading={translate.categories.landline} color="white">
      <List container={false}>
        <ListResultCard
          className="pl-3 border-bottom"
          title={translate.landline.subscriptionOverview}
          onClick={() => props.selectReport('fixedSubscriptionOverview')}
          action={<Icon icon="arrow-right" />}
        />
        <ListResultCard
          className="pl-3 border-bottom"
          title={translate.landline.sumPerANum}
          onClick={() => props.selectReport('fixedANumberMonthly')}
          action={<Icon icon="arrow-right" />}
        />
        <ListResultCard
          className="pl-3 border-bottom"
          title={translate.landline.sumPerANumTotal}
          onClick={() => props.selectReport('fixedANumberYearly')}
          action={<Icon icon="arrow-right" />}
        />
      </List>
    </DetailCard>
  );
};
