import * as React from 'react';
import { urlFor, ButtonCard, colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

export interface SubscriptionReportIncidentButtonProps {
  subscription: {
    id: string;
  };
}

/**
 * A ButtonCard with spesific props and styling to fit the design for Reporting Incidents.
 */
export function SubscriptionReportIncidentButton(
  props: SubscriptionReportIncidentButtonProps
) {
  const i18n = t.subscriptions.layout.SubscriptionReportIncidentButton;
  return (
    <ButtonCard
      color="dark"
      stackHorizontally={true}
      to={urlFor({
        subscription: props.subscription.id,
        subPage: 'report-incident',
      })}
    >
      <div className="d-flex align-items-center">
        <span>
          {`${i18n.preText} `}
          <span
            style={{ color: colors.corePurple, textDecoration: 'underline' }}
          >
            {i18n.link}
          </span>
          {`, ${i18n.postText}`}
        </span>
        <span className="ml-auto">
          <Icon icon="arrow-large-right" />
        </span>
      </div>
    </ButtonCard>
  );
}
