import React, { useState, useMemo, Fragment } from 'react';
import {
  Table,
  TableColumnDefintion,
  TableBodyRow,
  TableBodyCell,
  List,
  ListResultCard,
} from 'src/lib/ui';
import { useSubscriptionOrder_customer_order_orderLines } from '../queries/__types/useSubscriptionOrder';
import { mapOrderStatusToLabel, mapOrderStatustoIcon } from '../utils';
import { formatDate } from 'src/lib/utils';
import {
  groupCasesByParentId,
  isOrderLineFragment,
} from 'src/areas/worklog/utils';
import { Icon } from '@telia/styleguide';
import { OrderLineSubscriptionModal } from './OrderLineSubscriptionModal';
import { t } from 'src/lib/i18n';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import { DeliveryDateLabel } from './DeliveryDateLabel';
import { getDateAttributesFromOrderLine } from './getDateAttributesFromOrderLine';
import { trackOrderlineDetails } from 'src/lib/analytics';

export interface OrderLinesTableProps {
  orderLines: useSubscriptionOrder_customer_order_orderLines[];
}

export function OrderLinesTable(props: OrderLinesTableProps) {
  const [activeOrderLineId, setActiveOrderlineId] = useState<
    string | undefined
  >(undefined);
  const [modalOpen, setModalOpen] = useState<boolean>(false);
  const breakpoint = useBreakpoint('md');

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const onClickTableRow = (orderLineId: string) => {
    setActiveOrderlineId(orderLineId);
    setModalOpen(true);
    trackOrderlineDetails();
  };

  const onCloseModal = () => {
    setActiveOrderlineId(undefined);
  };

  const activeOrderline = useMemo(() => {
    // return early if id is not defined to avoid searching for undefined item
    if (activeOrderLineId === undefined) {
      return undefined;
    }
    return props.orderLines.find(ol => ol.id === activeOrderLineId);
  }, [activeOrderLineId]); // eslint-disable-line react-hooks/exhaustive-deps

  const activeOrderlineParentLabel = useMemo(() => {
    if (activeOrderline) {
      const parent = props.orderLines.find(
        ol => ol.id === activeOrderline?.parentId
      );
      if (parent) {
        return `${parent.description} (ID ${parent.id})`;
      }
    }
    return undefined;
  }, [activeOrderLineId]); // eslint-disable-line react-hooks/exhaustive-deps

  const i18n = t.orders.ServiceOrderContent.OrderLinesTable;

  const columns: TableColumnDefintion[] = [
    {
      label: i18n.id,
      key: 'id',
      cellWidth: 175,
    },
    {
      label: i18n.status,
      key: 'portalStatus',
      cellWidth: 175,
    },
    {
      label: i18n.details,
      key: 'description',
    },
    {
      label: i18n.created,
      key: 'createdAt',
      cellWidth: 150,
    },
    {
      label: i18n.deliveryDate,
      key: 'deliveryDate',
      cellWidth: 200,
    },
  ];

  const groupedByParentOrderLines = groupCasesByParentId(props.orderLines);

  return (
    <>
      <OrderLineSubscriptionModal
        isOpen={modalOpen}
        toogle={toggleModal}
        orderLine={activeOrderline}
        parentLabel={activeOrderlineParentLabel}
        onClose={onCloseModal}
      />

      {!breakpoint ? (
        <List container={false} border={true}>
          {groupedByParentOrderLines.map(ol => {
            const parentId = isOrderLineFragment(ol.parent)
              ? ol.parent.id
              : ol.parent;
            return (
              <Fragment key={parentId}>
                {isOrderLineFragment(ol.parent) ? (
                  <ListResultCard
                    className="px-2"
                    icon={mapOrderStatustoIcon(ol.parent.portalStatus)}
                    title={`${ol.parent.description} (ID ${ol.parent.id})`}
                    attributes={getDateAttributesFromOrderLine(ol.parent.dates)}
                  />
                ) : (
                  <ListResultCard
                    className="px-2"
                    title={`${i18n.deletedCase} (ID ${parentId})`}
                  />
                )}

                {ol.children.map(child => (
                  <ListResultCard
                    key={child.id}
                    className="px-2"
                    onClick={() => onClickTableRow(child.id)}
                    icon="arrow-subdirectory"
                    title={`${child.description} (ID ${child.id})`}
                    attributes={getDateAttributesFromOrderLine(child.dates)}
                  />
                ))}
              </Fragment>
            );
          })}
        </List>
      ) : (
        <Table columns={columns}>
          {groupedByParentOrderLines.map(ol => {
            /**
             * If a orderline does not have children, treat it as a regular orderline that can show information in a modal.
             */
            const noChildren = ol.children.length === 0;
            const parentId = isOrderLineFragment(ol.parent)
              ? ol.parent.id
              : ol.parent;
            return (
              <Fragment key={parentId}>
                <TableBodyRow
                  onClick={
                    noChildren ? () => onClickTableRow(parentId) : undefined
                  }
                >
                  <TableBodyCell>{parentId}</TableBodyCell>
                  <TableBodyCell>
                    {isOrderLineFragment(ol.parent)
                      ? mapOrderStatusToLabel(ol.parent.portalStatus)
                      : '-'}
                  </TableBodyCell>
                  <TableBodyCell>
                    {isOrderLineFragment(ol.parent)
                      ? ol.parent.description
                      : '-'}
                  </TableBodyCell>

                  <TableBodyCell>
                    {isOrderLineFragment(ol.parent)
                      ? ol.parent.dates?.createdAt
                        ? formatDate(ol.parent.dates.createdAt, 'short')
                        : ''
                      : '-'}
                  </TableBodyCell>
                  <TableBodyCell>
                    {isOrderLineFragment(ol.parent) ? (
                      <DeliveryDateLabel dates={ol.parent.dates} />
                    ) : (
                      '-'
                    )}
                  </TableBodyCell>
                </TableBodyRow>
                {ol.children.map(child => {
                  return (
                    <TableBodyRow
                      key={child.id}
                      onClick={() => onClickTableRow(child.id)}
                    >
                      <TableBodyCell>
                        <div className="d-flex align-items-center">
                          <Icon
                            className="mr-2"
                            icon="arrow-subdirectory"
                            style={{ width: '22px', height: '22px' }}
                          />
                          {child.id}
                        </div>
                      </TableBodyCell>
                      <TableBodyCell>
                        {mapOrderStatusToLabel(child.portalStatus)}
                      </TableBodyCell>
                      <TableBodyCell>{child.description}</TableBodyCell>

                      <TableBodyCell>
                        {child.dates?.createdAt
                          ? formatDate(child.dates.createdAt, 'short')
                          : ''}
                      </TableBodyCell>
                      <TableBodyCell>
                        <DeliveryDateLabel dates={child.dates} />
                      </TableBodyCell>
                    </TableBodyRow>
                  );
                })}
              </Fragment>
            );
          })}
        </Table>
      )}
    </>
  );
}
