import { useMemo } from 'react';
import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { IncidentGroupInUrl } from 'src/lib/ui';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { IncidentStatus } from 'src/__types/graphql-global-types.ts';
import { useCustomer } from 'src/lib/global/CustomerContext';
import { useRouter } from 'src/lib/utils/useRouter';

const getStatusOption = (
  status: IncidentStatus,
  customerName?: string
): { value: string; label: string } => {
  const i18n = t.incidents.status;
  switch (status) {
    case IncidentStatus.canceled:
      return { value: IncidentStatus.canceled, label: i18n.canceled };
    case IncidentStatus.closed:
      return { value: IncidentStatus.closed, label: i18n.closed };
    case IncidentStatus.onHoldPendingCustomer:
      return {
        value: IncidentStatus.onHoldPendingCustomer,
        label: i18n.onHoldPendingCustomer(customerName),
      };
    case IncidentStatus.onHoldPendingTelia:
      return {
        value: IncidentStatus.onHoldPendingTelia,
        label: i18n.onHoldPendingTelia,
      };
    case IncidentStatus.solved:
      return { value: IncidentStatus.solved, label: i18n.solved };
  }
};

export const useIncidentStatusFilter = () => {
  const i18n = t.incidents.status;
  const query = useQueryParameters();
  const { name } = useCustomer();
  const selectedStatus = query.getArray('incidentStatus');

  const router = useRouter<{ group: IncidentGroupInUrl }>();

  const statusOptions = useMemo(
    () =>
      router.match.params.group === 'aktive'
        ? [
            getStatusOption(IncidentStatus.onHoldPendingCustomer, name),
            getStatusOption(IncidentStatus.onHoldPendingTelia),
            getStatusOption(IncidentStatus.solved),
          ]
        : [
            getStatusOption(IncidentStatus.canceled),
            getStatusOption(IncidentStatus.closed),
          ],
    [router.match.params.group, name]
  );

  return {
    id: 'status',
    label: i18n.label,
    options: statusOptions,
    allowMultiple: true,
    allowSearch: false,
    selectedOptions: selectedStatus,
    handleSave: values => {
      setFilterAndResetPage(
        query,
        'incidentStatus',
        values ? values : undefined
      );
    },
  };
};
