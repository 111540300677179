import { InvoiceStatus } from 'src/lib/types';

const invoices = {
  title: 'Fakturaer',
  statusLabel: 'Status',
  status: {
    Paid: {
      status: 'Paid' as InvoiceStatus,
      label: 'Betalt',
    },
    PartiallyPaid: {
      status: 'PartiallyPaid' as InvoiceStatus,
      label: 'Ubetalt',
    },
    Unpaid: {
      status: 'Unpaid' as InvoiceStatus,
      label: 'Ubetalt',
    },
    CreditNote: {
      status: 'CreditNote' as InvoiceStatus,
      label: 'Kreditnota',
    },
    Overdue: {
      status: 'Overdue' as InvoiceStatus,
      label: 'Forfalt',
    },
  },
  invoiceNumber: 'Fakturanummer',
  invoiceAddress: 'Fakturasted',
  invoiceDate: 'Fakturadato',
  dueDate: 'Forfallsdato',
  sumExVat: 'Beløp eks. mva',
  showInvoice: 'Vis faktura',
  btnDownloadPdf: 'Last ned PDF',
  btnDownloadCSV: 'Last ned CSV',
  btnDownloadExcel: 'Last ned Excel',
  noInvoices: 'Ingen fakturaer',
  showXofYInvoices: (showing: number, total?: number) => `Viser ${showing} av
  ${total} fakturaer`,
  detailReportDownloadBtn: 'Last ned månedsrapport',
  allPeriods: 'Alle perioder',
  newBankAccountNumber: {
    title: 'Varsel om nytt kontonummer til Telia',
    url: 'https://cloud.enterprise.telia.no/datakom',
    linkText: 'Les mer',
  },
};

export default invoices;
