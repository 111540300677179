import * as React from 'react';
import { Flow, Step, StepShape } from 'src/lib/flow';
import { colors, Attribute } from 'src/lib/ui';
import * as yup from 'yup';
import { SearchField } from 'src/areas/main/search';
import { t } from 'src/lib/i18n';
import { useOrderContext } from './context';

export interface Step4Values {
  // siteContactId?: string;
  // siteContactLabel?: string;
  siteContact?: {
    id: string;
    firstName: string;
    lastName: string;
    email: string;
  };
}

function Form({ flow }: { flow: Flow<Step4Values> }) {
  const ctx = useOrderContext();
  return (
    <>
      <p className="small mt-0 mb-3" style={{ color: colors.greyDarkText }}>
        {t.ordering.OrderIpVpn.Step4.siteContactPlaceholder}
      </p>

      <div className="row mb-3">
        <div className="col-md-8 col-lg-6">
          <SearchField
            searchProvider={ctx.contactSearch}
            onSelect={c => {
              if (c?.__typename === 'Contact') {
                flow.setFieldValue('siteContact', c, true);
              }
            }}
            placeholder={t.ordering.OrderIpVpn.Step4.siteContactPlaceholder}
            renderSearchMenuOnFocus={true}
          />
        </div>
      </div>

      {flow.values.siteContact ? (
        <div className="row my-3">
          <div className="col">
            <Attribute
              label={t.ordering.OrderIpVpn.Step4.siteContactLabel}
              value={`${flow.values.siteContact.firstName} ${
                flow.values.siteContact.lastName
              } ${
                flow.values.siteContact.email
                  ? ` (${flow.values.siteContact.email})`
                  : ''
              }`}
            />
          </div>
        </div>
      ) : null}
    </>
  );
}

const Step4: Step<Step4Values> = (): StepShape<Step4Values> => {
  return {
    title: t.ordering.OrderIpVpn.Step4.title,

    validationSchema: yup.object({
      siteContact: yup.object({
        id: yup.string().required(t.ordering.required),
      }),
    }),

    renderSummary: ({ values }) => {
      return (
        <>
          {values.siteContact ? (
            <Attribute
              label={t.ordering.OrderIpVpn.Step4.siteContactSummary}
              value={`${values.siteContact.firstName} ${
                values.siteContact.lastName
              } ${
                values.siteContact.email ? ` (${values.siteContact.email})` : ''
              }`}
            />
          ) : null}
        </>
      );
    },

    renderForm: Form,
  };
};

export default Step4;
