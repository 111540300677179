import { PlannedWorkFragment } from './../../../../lib/types/subscription/PlannedWorkFragment';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { useQuery } from 'react-apollo';
import * as t from './__types/usePlannedWork';
import { usePlannedWork_customer_plannedWork } from './__types/usePlannedWork';
import { ApolloError } from 'apollo-client';

export const QUERY_PLANNED_WORK = gql`
  query usePlannedWork($customerId: ID!) {
    customer(id: $customerId) {
      id
      plannedWork {
        ...PlannedWorkSearchResult
      }
      servicesWithPlannedWork: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [{ filter: "cases", value: ["withPlannedWork"] }]
        }
      ) {
        totalResults
      }
    }
  }

  ${PlannedWorkFragment}
`;
type PlannedWorkData = {
  error?: ApolloError;
  loading: boolean;
  servicesWithPlannedWork: number | null;
  plannedWork: usePlannedWork_customer_plannedWork[] | null | undefined;
};

export const usePlannedWork = (): PlannedWorkData => {
  const customer = useCustomer();
  const { data, loading, error } = useQuery<
    t.usePlannedWork,
    t.usePlannedWorkVariables
  >(QUERY_PLANNED_WORK, {
    variables: {
      customerId: customer.id,
    },
    skip: !customer.id,
  });

  return {
    plannedWork: data?.customer?.plannedWork,
    servicesWithPlannedWork:
      data?.customer?.servicesWithPlannedWork?.totalResults ?? null,
    error,
    loading,
  };
};
