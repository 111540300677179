import * as t from './__types/useCustomerSearch';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

const WITH_CUSTOMER = gql`
  query useCustomerSearch($input: CustomerSearchInput!) {
    searchCustomers(input: $input) {
      size
      cursor
      totalResults
      results {
        id
        name
        organisationNumber
      }
    }
  }
`;

interface Props {
  query: string;
  skipQuery?: boolean;
}

interface Result {
  loading: boolean;
  result?: Array<t.useCustomerSearch_searchCustomers_results> | null;
  size: number;
  totalResults: number;
}

export const useCustomerSearch = (props: Props): Result => {
  const { data, loading } = useQuery<
    t.useCustomerSearch,
    t.useCustomerSearchVariables
  >(WITH_CUSTOMER, {
    variables: {
      input: {
        query: props.query,
      },
    },
    errorPolicy: 'all',
    skip: props.skipQuery,
  });

  return {
    loading: loading,
    result: data?.searchCustomers?.results,
    size: data?.searchCustomers?.size ?? 0,
    totalResults: data?.searchCustomers?.totalResults ?? 0,
  };
};
