import React from 'react';
import './MinSidePageHeader.scss';
import pebble from 'src/images/telia-logo-pebble.svg';

interface Props {
  title: string;
  subText?: string;
}

export const MinSidePageHeader: React.FC<Props> = props => {
  return (
    <div className="MinSidePageHeader">
      <h1>{props.title} </h1>
      <div className="pt-3 MinSidePageHeader-subText ">
        {props.subText ? props.subText : undefined}
      </div>
      <div className="MinSidePageHeader-pebble-background">
        <img src={pebble} alt="" />
        <img src={pebble} alt="" />
      </div>
    </div>
  );
};
