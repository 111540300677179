import React from 'react';
import { colors, Badges, Badge } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { getDeliveryStatusDefinitions } from '../../utils';
import { getIncidentStatusLabel } from 'src/lib/types/incident/getIncidentStatusLabel';

import {
  IncidentStatus,
  DeliveryStatus,
} from 'src/__types/graphql-global-types';

export const CaseBadges = (props: {
  subscription?: null | {
    id: string;
    deliveryStatus?: DeliveryStatus | null;
    plannedWork?: Array<{ state: string | null }> | null;
    activeIncidents?: Array<{
      id: string;
      incidentStatus: IncidentStatus | null;
    }> | null;
  };
}) => {
  const hasActiveState = plannedWork =>
    plannedWork.state === 'Scheduled' || plannedWork.state === 'Implement';
  const hasPlannedwork = !!props.subscription?.plannedWork?.filter(
    hasActiveState
  ).length;
  const {
    deliveryStatusIcon,
    deliveryStatusTranslations,
  } = getDeliveryStatusDefinitions(props.subscription?.deliveryStatus);

  const plannedWorkBadge = hasPlannedwork
    ? [
        <Badge icon="wrench" key={`${props.subscription?.id}-plannedWorkBadge`}>
          {t.subscriptions.SubscriptionPlannedWorkCard.plannedWorkHeader}
        </Badge>,
      ]
    : [];

  const deliveryStatusBagde = deliveryStatusTranslations
    ? [
        <Badge
          icon={deliveryStatusIcon}
          key={`${props.subscription?.id}-deliveryStatusBagde`}
        >
          {deliveryStatusTranslations.statusShort}
        </Badge>,
      ]
    : [];

  const incidentBagdes =
    props.subscription?.activeIncidents?.map(i => (
      <Badge
        icon="email"
        key={`${props.subscription?.id}-incidentBagdes-${i.id}`}
      >
        {getIncidentStatusLabel(i.incidentStatus)}
      </Badge>
    )) ?? [];

  const allBages = [
    ...plannedWorkBadge,
    ...deliveryStatusBagde,
    ...incidentBagdes,
  ];

  const displayCases = allBages.slice(0, Math.min(3, allBages.length));
  const remainingCases = allBages.length - displayCases?.length;
  if (!allBages.length) {
    return null;
  }

  return (
    <>
      <Badges inline={true}>{displayCases}</Badges>
      {remainingCases ? (
        <small className="d-block py-1" style={{ color: colors.greyDarkText }}>
          + {remainingCases}{' '}
          {remainingCases === 1 ? t.subscriptions.case : t.subscriptions.cases}
        </small>
      ) : null}
    </>
  );
};
