import * as React from 'react';
import { Subscription, getSubscriptionDisplayTitles } from 'src/lib/types';
import { DefaultAboutCard } from '../Components/DefaultAboutCard';
import { getSubscriptionBreadcrumbs } from 'src/lib/types/subscription/getSubscriptionBreadcrumbs';
import {
  SubscriptionPageHeader,
  SubscriptionSummaryContainer,
  SubscriptionMainContentPlaceholder,
} from '../../layout';
import {
  SubscriptionCostDetails,
  AdditionalInformationCard,
  SubscriptionReportIncidentButton,
} from '../Components';

import { TechnicalContactsCard } from '../../Pages/Components/TechnicalContacts/TechnicalContactsCard';

interface Props {
  subscription: Subscription;
}

const GenericSubscriptionPage: React.FC<Props> = ({ subscription }) => {
  const breadcrumbs = getSubscriptionBreadcrumbs(subscription);
  const { title } = getSubscriptionDisplayTitles(subscription);

  return (
    <>
      <SubscriptionPageHeader
        description=""
        subscription={subscription}
        title={title}
        breadcrumbs={breadcrumbs}
      />
      <div className="container mt-4">
        <SubscriptionSummaryContainer
          subscription={subscription}
          main={
            <>
              <SubscriptionReportIncidentButton subscription={subscription} />
              <SubscriptionMainContentPlaceholder />
            </>
          }
          sidebar={
            <>
              <DefaultAboutCard subscription={subscription} />
              <SubscriptionCostDetails subscription={subscription} />
              <TechnicalContactsCard subscription={subscription} />
              <AdditionalInformationCard subscription={subscription} />
            </>
          }
        />
      </div>
      {/* <div className="container pt-4 pb-5">
        <BodyCard heading={translate.aboutSubscription}>
          <div className="row">
            <div className="col-lg-6">
              <SubscriptionAbout
                subscription={subscription}
                showMap={false}
                noCardAndHeading={true}
              />
            </div>
            <div className="col-lg-6">
              <div className="mb-3" style={{ height: '400px' }}>
                {installationAddress &&
                installationAddress.location &&
                installationAddress.location.lat &&
                installationAddress.location.lon ? (
                  <Map
                    searchResult={[subscription]}
                    center={
                      installationAddress.location &&
                      typeof installationAddress.location.lat === 'number' &&
                      typeof installationAddress.location.lon === 'number'
                        ? {
                            lat: installationAddress.location.lat,
                            lon: installationAddress.location.lon,
                          }
                        : undefined
                    }
                    zoomLevel={15}
                  />
                ) : (
                  <Map searchResult={[subscription]} />
                )}
              </div>
            </div>
          </div>
        </BodyCard>
      </div> */}
    </>
  );
};

export default GenericSubscriptionPage;
