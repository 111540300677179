import React from 'react';
import { Subscription } from 'src/lib/types';
import { BodyCard, Attributes, Attribute } from 'src/lib/ui';
import { formatBandwidth, formatDate } from 'src/lib/utils';
import { t, useI18nContext } from 'src/lib/i18n';

interface DataWithStatusAboutProps {
  subscription: Subscription;
}

export const DataWithStatusAbout: React.FunctionComponent<DataWithStatusAboutProps> = ({
  subscription,
}) => {
  const i18nContext = useI18nContext();
  const i18n = t.subscriptions.DataWithStatusAbout;

  const accessBandwidth = formatBandwidth(subscription.accessBandwidth, 0);
  const serviceBandwidth = formatBandwidth(subscription.serviceBandwidth, 0);
  return (
    <BodyCard heading={i18n.heading}>
      <Attributes>
        {subscription.alias && (
          <Attribute label={i18n.alias} value={subscription.alias} />
        )}
        <Attribute label={i18n.type} value={subscription.name} />
        <Attribute label={i18n.id} value={subscription.id} />

        {subscription.cpe?.model && (
          <Attribute label={i18n.equipment} value={subscription.cpe?.model} />
        )}

        {subscription.accessType && (
          <Attribute label={i18n.access} value={subscription.accessType} />
        )}

        {accessBandwidth && (
          <Attribute label={i18n.accessBandwidth} value={accessBandwidth} />
        )}

        {serviceBandwidth && (
          <Attribute label={i18n.serviceBandwidth} value={serviceBandwidth} />
        )}

        {subscription.accessSupplier && (
          <Attribute
            label={i18n.supplier}
            value={subscription.accessSupplier}
          />
        )}

        {/**
         * Field that is only indexed for SSV.
         */}
        {subscription.networkToNetworkInterface ? (
          <Attribute
            label={i18n.networkToNetworkInterface}
            value={subscription.networkToNetworkInterface}
          />
        ) : null}

        {subscription.transmissionNumber &&
        subscription.transmissionNumber.length > 0 ? (
          <Attribute
            label={i18n.transmissionNumber}
            value={subscription.transmissionNumber.join(', ')}
          />
        ) : null}

        {subscription.sla && (
          <Attribute label={i18n.sla} value={subscription.sla} />
        )}

        {subscription.startTime && (
          <Attribute
            label={i18n.created}
            value={formatDate(
              subscription.startTime,
              'long',
              i18nContext.locale
            )}
          />
        )}

        <Attribute
          label={i18n.validUntil}
          value={
            subscription.endTime
              ? formatDate(subscription.endTime, 'long', i18nContext.locale)
              : i18n.validUntilNotSet
          }
        />
      </Attributes>
    </BodyCard>
  );
};
