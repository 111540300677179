import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useUserPreferencesQuery';

export const USER_PREFERENCE_QUERY = gql`
  query useUserPreferencesQuery {
    me {
      id
      preferences {
        key
        value
      }
    }
  }
`;

export const useUserPreferencesQuery = () => {
  return useQuery<t.useUserPreferencesQuery>(USER_PREFERENCE_QUERY, {
    errorPolicy: 'all',
  });
};
