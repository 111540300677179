import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useTags';

export const USE_TAGS = gql`
  query useTags($customerId: ID!, $subscriptions: [ID!]!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: $subscriptions) {
        id
        tags {
          id
          tag
        }
      }
    }
  }
`;

/**
 * Get tags for single subscription
 */
export const useTags = (subscriptionId: string) =>
  useTagsOnSubscriptions([subscriptionId]);

/**
 * Get tags for multiple subscription
 */
export const useTagsOnSubscriptions = (subscriptionIds: string[]) => {
  const customer = useCustomer();
  return useQuery<t.useTags, t.useTagsVariables>(USE_TAGS, {
    variables: {
      customerId: customer.id,
      subscriptions: subscriptionIds,
    },
  });
};
