import * as React from 'react';
import cs from 'classnames';
import { FormFieldWrapper } from 'src/lib/ui';
import { generateUeid } from 'src/lib/utils';
import { Icon, IconDefinition } from '@telia/styleguide';
import { Loading } from '../Loading/Loading';
import './TextArea.scss';

export type TextAreaProps = {
  id?: string;
  icon?: IconDefinition;
  placeholder?: string;
  disabled?: boolean;
  value?: string;
  readOnly?: boolean;
  'aria-label'?: string;
  'aria-activedescendant'?: string;
  'aria-autocomplete'?: 'none' | 'inline' | 'list' | 'both';
  'aria-controls'?: string;
  autoComplete?: string;
  onFocus?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLTextAreaElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLTextAreaElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;

  innerRef?: React.RefObject<HTMLTextAreaElement>;
  loading?: boolean;
  className?: string;
  required?: boolean;
  success?: boolean;
  error?: string;
  label?: string;
  helpText?: string;
  subTextPosition?: 'left' | 'right';
  style?: React.CSSProperties;
};
export class TextArea extends React.PureComponent<TextAreaProps> {
  private fieldGuid: string | undefined;
  constructor(props) {
    super(props);
    if (props.label) {
      this.fieldGuid = generateUeid();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.label !== this.props.label) {
      this.fieldGuid =
        this.props.label === undefined ? undefined : generateUeid();
    }
  }

  getStatusIcon = (): React.ReactNode => {
    const { error, success, loading } = this.props;
    /**
     * Render priority
     * 1. Success
     * 2. Error
     * 3. Loading
     */
    if (success) {
      return <Icon icon="check-mark" />;
    }
    if (error) {
      return <Icon icon="alert" />;
    }
    if (loading) {
      return <Loading size="xs" style={{ minHeight: '1rem' }} />;
    }
    return null;
  };

  render() {
    const statusIcon = this.getStatusIcon();

    return (
      <FormFieldWrapper
        className={this.props.className}
        error={this.props.error}
        required={this.props.required}
        helpText={this.props.helpText}
        label={this.props.label}
        success={this.props.success}
        subTextPosition={this.props.subTextPosition}
        labelId={this.fieldGuid}
      >
        <div
          className={cs('TextArea', 'input-group', {
            disabled: !!this.props.disabled,
            isAppended: !!statusIcon,
            isPrepended: !!this.props.icon,
            success: !!this.props.success,
            error: !!this.props.error,
          })}
          style={this.props.style}
        >
          {this.props.icon ? (
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Icon icon={this.props.icon} />
              </span>
            </div>
          ) : null}
          <textarea
            ref={this.props.innerRef}
            id={this.props.id}
            className="form-control" // for BS styling
            placeholder={this.props.placeholder}
            disabled={this.props.disabled}
            value={this.props.value}
            aria-label={this.props['aria-label']}
            aria-activedescendant={this.props['aria-activedescendant']}
            aria-autocomplete={this.props['aria-autocomplete']}
            aria-controls={this.props['aria-controls']}
            aria-labelledby={this.fieldGuid}
            autoComplete={this.props.autoComplete}
            onFocus={this.props.onFocus}
            onBlur={this.props.onBlur}
            onKeyDown={this.props.onKeyDown}
            onChange={this.props.onChange}
            readOnly={this.props.readOnly}
          />
        </div>
      </FormFieldWrapper>
    );
  }
}
