const costs = {
  ui: {
    CostSummaryTable: {
      category: 'Kategori',
      consumption: 'Forbruk',
      fixedCosts: 'Faste kostnader',
      oneTimeFee: 'Enkeltbeløp',
      VAT: 'Sum inkl. mva',
      noVAT: 'Sum ekskl. mva',
      total: 'Total',
    },
    PeriodSelector: {
      invoice: 'Fakturerte utgifter',
      usage: 'Påløpte utgifter',
    },
    InvoiceTable: {
      status: 'Status',
      invoiceNumber: 'Fakturanummer',
      invoiceDate: 'Fakturadato',
      dueDate: 'Forfallsdato',
      net: 'Beløp ekskl. mva.',
      gross: 'Totalbeløp',
      ContextMenu: {
        showInvoice: 'Vis faktura',
        dlPdf: 'Last ned PDF',
        dlCsv: 'Last ned CSV',
        dlExcel: 'Last ned Excel',
      },
      noInvoices: 'Ingen faktura',
    },
    OrganisationCostSummary: {
      downloadBtn: 'Last ned',
      detailsBtn: 'Se detaljer',
      circleChartLabel: 'Totalforbruk i kroner',
    },
    OrganisationCostSummaryTable: {
      category: 'Kategori',
      usage: 'Forbruk',
      fixedCost: 'Faste kostnader',
      oneTimeFee: 'Enkeltbeløp',
      noVat: 'ekskl. mva.',
      vat: 'inkl. mva.',
    },
    MobileCostSummary: {
      header: (periodBy, month, year) =>
        `Mobil kostnader utover pakke ${
          periodBy === 'usage' ? 'påløpt' : 'fakturert'
        } i ${month} ${year}`,
      circleChartLabel: 'Totalforbruk i kroner',
      downloadBtn: 'Last ned',
    },
  },
  ChooseOrganisationOrCostCenter: {
    PageHeader: {
      title: 'Kostnader',
      navTabs: {
        organisation: 'Fakturasteder',
        costCenter: 'Kostnadsteder',
      },
    },
    costCenterTable: {
      id: 'ID',
      description: 'Beskrivelse',
      LoadMoreBtn: 'Vis flere kostnadssteder',
    },
  },
  OrganisationPage: {
    Pageheader: {
      actions: {
        chooseOrg: 'Velg fakturasted',
        chooseCostCenter: 'Velg kostnadssted',
      },
      navTabs: {
        summary: 'Sammendrag',
        details: 'Detaljer',
        invoice: 'Fakturaer',
      },
      breadcrumbs: {
        cost: 'Kostnader',
      },
    },
    ListHeader: {
      toggleBtn: (count: number) =>
        `Inkluder ${count} underliggende fakturasteder`,
    },
  },
  categoryDefinitions: {
    mobile: 'Mobil',
    data: 'Data',
    tele: 'Telefoni',
    other: 'Annet',
  },
  OrganisationDetails: {
    CostTable: {
      costGroup: 'Kostnadsgruppe',
      invoiceNumber: 'Fakturanr.',
      period: 'Periode',
      noVAT: 'Kostnader ekskl. mva.',
      VAT: 'Kostnader inkl. mva.',
      totalLabel: 'Totalkostnad',
    },
    filters: {
      data: 'Data',
      mobile: 'Mobil',
      tele: 'Telefoni',
      other: 'Annet',
    },
  },
  OrganisationInvoices: {
    filters: {
      overdue: 'Forfalt',
      unpaid: 'Ubetalt',
      paid: 'Betalt',
    },
    showMoreBtn: 'Vis flere fakturaer',
  },
  CostCenterPage: {
    PageHeader: {
      actions: {
        chooseOrg: 'Velg fakturasted',
        chooseCostCenter: 'Velg annet kostnadssted',
      },
      navTabs: {
        summary: 'Sammendrag',
      },
    },
  },
  InvoiceDetails: {
    noInvoice: 'Fant ikke faktura',
    backBtn: 'Tilbake',
    header: 'Faktura',
    dueDate: 'Forfallsdato',
    invoicePeriod: 'Fakturaperiode',
    invoiceDate: 'Fakturadato',
    reference: 'Referanse',
    unknown: 'ukjent',
    sentAs: 'Sendt som',
    sentAsEhf: 'Sendt via EHF',
    sentAsPdf: 'Sendt på e-post (PDF)',
    sentAsMail: 'Sendt som brevpost',
    invoicePart: 'Fakturapart',
    objectionString: 'Har du innsigelser til fakturaen?',
    contact: 'Kontakt oss på',
    returnAdr: 'Returadresse',
    invoiceRecipient: 'Fakturamottak',
    adr: 'Adresse',
    email: 'E-postadresse',
    noEmail: 'Ingen e-postadresse registrert',
    billCycle: 'Fakturasyklus',
    noReg: 'Ikke registrert',
    invoiceSummary: 'Fakturasammendrag',
    paymentTerms: 'Betalingsbetingelser',
    noPaymentText:
      'Ved for sen betaling vil det belastes pr dags dato gjeldende forsinkelsesrente. Intrum Justitia AS håndterer betalingsoppfølgingen på vegne av Telia Norge AS.',
    exclVat: 'Beløp netto',
    inclVat: 'MVA beløp',
  },
  InvoiceLineGroup: {
    download: 'Last ned',
    totalInclVat: 'Total inkl. MVA',
    showInvoiceLines: (lines, total) =>
      `Viser ${lines} av ${total} fakturalinjer`,
    showMore: 'Vis flere fakturalinjer',
    error: 'Kunne ikke laste kostnader',
    table: {
      subscription: 'tjeneste',
      period: 'periode',
      netCost: 'sum netto',
      costInclVat: 'sum inkl. mva',
    },
  },
  CostCenterModal: {
    createBtn: 'Opprett nytt kostnadssted',
    header: 'Opprett nytt kostnadssted',
    id: 'ID',
    idError: 'ID er obligatorisk',
    description: 'Beskrivelse',
    cancelBtn: 'Avbryt',
    confirmBtn: 'Opprett',
  },
};

export default costs;
