import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';

export const useCaseFilter = () => {
  const query = useQueryParameters();
  const selectedOptions = query.getArray('cases');

  const i18n = t.subscriptions.filters.cases;

  const options = [
    {
      value: 'withoutIncidents',
      label: i18n.withoutIncidents,
      negative: true,
    },
    {
      value: 'withoutPlannedWork',
      label: i18n.withoutPlannedWork,
      negative: true,
    },
    {
      value: 'withIncidents',
      label: i18n.withIncidents,
    },
    {
      value: 'withPlannedWork',
      label: i18n.withPlannedWork,
    },
  ];
  return {
    id: 'cases',
    label: i18n.label,
    options: options,
    allowMultiple: true,
    selectedOptions,
    handleSave: values =>
      setFilterAndResetPage(query, 'cases', values ? values : undefined),
  };
};
