import React from 'react';
import { useRouter } from 'src/lib/utils/useRouter';
import { Table, TableBodyRow, urlFor, TableBodyCell } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';
import { t } from 'src/lib/i18n';
import { ListQueryParams } from 'src/lib/utils/useListQueryParams';
import { getIncidentStatusLabel } from 'src/lib/types/incident/getIncidentStatusLabel';
import { getIncidentIcon } from 'src/lib/types/incident/getIncidentIcon';
import { formatDate } from 'src/lib/utils';
import { getSubscriptionDisplayTitles } from 'src/lib/types';
import { useDomainColumns, IncidentColumns } from 'src/lib/table';
import {
  CostCenterTableCell,
  OrganisationTableCell,
  TagsTableCell,
} from 'src/areas/main/subscriptions/Pages/Components';
import { SearchResult } from 'src/areas/main/search/types/index';
import { SearchDomain } from 'src/__types/graphql-global-types';
import { IncidentSearchResult } from 'src/lib/types/incident/__types/IncidentSearchResult';
export const IncidentsTable = (props: {
  results: SearchResult[];
  loading: boolean;
  listParams: ListQueryParams;
}) => {
  const router = useRouter();
  const { results, loading, listParams } = props;
  const { columns, availableColumns } = useDomainColumns(
    SearchDomain.incident,
    listParams.columns
  );
  return (
    <Table
      loading={loading}
      columns={columns}
      sortable={true}
      setSort={listParams.setSort}
      sortBy={listParams.sortBy}
      sortOrder={listParams.sortOrder}
      rowsCount={listParams.pageSize}
      noResult={results.length === 0}
      noResultMessage={t.incidents.noIncidents}
      availableColumns={availableColumns}
      onChangeColumns={listParams.setColumns}
      activeSort={{ key: listParams.sortBy, order: listParams.sortOrder }}
    >
      {results.map(row => {
        const incident = row.__typename === 'Incident' ? row : undefined;

        if (!incident) {
          return null;
        }

        return (
          <TableBodyRow
            key={incident.id}
            onClick={() => {
              router.history.push(urlFor({ incident: incident.id }));
            }}
          >
            {columns.map((col, i) => (
              <IncidentTableBodyCell
                key={`${col}-${i}`}
                col={col.key as IncidentColumns}
                incident={incident}
              />
            ))}
          </TableBodyRow>
        );
      })}
    </Table>
  );
};

const IncidentTableBodyCell = (props: {
  col: IncidentColumns;
  incident: IncidentSearchResult;
}) => {
  const { col, incident } = props;
  const statusLabel = getIncidentStatusLabel(incident.incidentStatus);
  const { icon, iconColor } = getIncidentIcon(incident.incidentStatus);

  switch (col) {
    case 'createdAt':
      return (
        <TableBodyCell>{formatDate(incident.createdAt, 'short')}</TableBodyCell>
      );
    case 'id':
      return <TableBodyCell>{incident.id}</TableBodyCell>;
    case 'details':
      return <TableBodyCell>{incident.shortDescription}</TableBodyCell>;
    case 'updated':
      return (
        <TableBodyCell>{formatDate(incident.updatedAt, 'short')}</TableBodyCell>
      );
    case 'subscription':
      return (
        <TableBodyCell>
          {incident.subscription
            ? getSubscriptionDisplayTitles(incident.subscription).title
            : null}
        </TableBodyCell>
      );
    case 'status':
      return (
        <TableBodyCell>
          <>
            <Icon className={'mr-2 text-' + iconColor} icon={icon} />
            {statusLabel}
          </>
        </TableBodyCell>
      );
    case 'costCenter':
      return <CostCenterTableCell subscription={incident.subscription} />;
    case 'invoiceCenter':
      return <OrganisationTableCell subscription={incident.subscription} />;
    case 'tags':
      return <TagsTableCell subscription={incident.subscription} />;
    case 'solved':
      return (
        <TableBodyCell>
          {incident.closed ? formatDate(incident.closed, 'short') : null}
        </TableBodyCell>
      );
    default:
      return null;
  }
};
