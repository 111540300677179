import { Definition } from './types';
import { TvColumns, formatColumns } from './index';
import { getSortOptions, getSortOption } from '../utils/getSortOptionsByKey';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getTvTableDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const tvColumnMap: {
    [key in TvColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    name: {
      label: t.subscriptions.GenericSubscriptionTable.service,
      sortOptions: getSortOptions(['alias', 'type', 'subscriptionId']),
      fixed: true,
    },
    address: {
      label: t.subscriptions.GenericSubscriptionTable.address,
      sortOptions: getSortOption('address', true),
    },
    cases: {
      label: t.subscriptions.GenericSubscriptionTable.cases,
      cellWidth: 200,
    },
    invoiceCenter: {
      label: t.subscriptions.GenericSubscriptionTable.invoiceCenter,
    },
    costCenter: {
      label: t.subscriptions.GenericSubscriptionTable.costCenter,
    },
    tags: {
      label: t.subscriptions.GenericSubscriptionTable.tags,
    },
  };

  const defaultTvColumns: Array<TvColumns> = [
    'name',
    'address',
    'cases',
    'invoiceCenter',
    'tags',
  ];

  return formatColumns({
    map: tvColumnMap,
    defaultColumns: defaultTvColumns,
    savedColumnPreferences,
  });
};
