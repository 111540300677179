import { convertByteToMegaByte } from './../../utils/convertUnit';
import {
  StandardStatistics,
  StandardStatisticsBandwidth,
} from '../../queries/WithStatistics';
import {
  convertBitsToMegabits,
  convertByteToGigaByte,
} from '../../utils/convertUnit';
import { formatNordic } from 'src/lib/utils';
import { isLowerThan1GB } from './mapStandardData';

export const mapStandardDataDetails = (
  data: StandardStatistics,
  serviceBandwidth?: StandardStatisticsBandwidth
) => {
  if (!data) {
    return null;
  }

  const showCumulativeInMB =
    isLowerThan1GB(data.traffic?.sumIn ?? 0) &&
    isLowerThan1GB(data.traffic?.sumOut ?? 0);

  const avgIn = data.throughput?.averageIn
    ? convertBitsToMegabits(data.throughput.averageIn)
    : null;
  const avgOut = data.throughput?.averageOut
    ? convertBitsToMegabits(data.throughput.averageOut)
    : null;
  const maxIn = data.throughput?.maxIn
    ? convertBitsToMegabits(data.throughput.maxIn)
    : null;
  const maxOut = data.throughput?.maxOut
    ? convertBitsToMegabits(data.throughput.maxOut)
    : null;

  return {
    throughput: data.throughput
      ? {
          p95In: data.throughput.p95In
            ? `${formatNordic(
                convertBitsToMegabits(data.throughput.p95In),
                5
              )} Mbit/s`
            : '',
          p95Out: data.throughput.p95Out
            ? `${formatNordic(
                convertBitsToMegabits(data.throughput.p95Out),
                5
              )} Mbit/s`
            : '',
          averageIn: avgIn ? `${formatNordic(avgIn, 5)} Mbit/s` : '',
          averageOut: avgOut ? `${formatNordic(avgOut, 5)} Mbit/s` : '',
          maxIn: maxIn ? `${formatNordic(maxIn, 5)} Mbit/s` : '',
          maxOut: maxOut ? `${formatNordic(maxOut, 5)} Mbit/s` : '',
        }
      : null,
    utilization:
      data.throughput &&
      serviceBandwidth &&
      serviceBandwidth.downstream &&
      serviceBandwidth.upstream
        ? {
            averageIn:
              avgIn &&
              formatNordic((avgIn / serviceBandwidth.downstream) * 100, 2),
            averageOut:
              avgOut &&
              formatNordic((avgOut / serviceBandwidth.upstream) * 100, 2),
            maxIn:
              maxIn &&
              formatNordic((maxIn / serviceBandwidth.downstream) * 100, 2),
            maxOut:
              maxOut &&
              formatNordic((maxOut / serviceBandwidth.upstream) * 100, 2),
          }
        : null,
    packetDrop: data.packetDrop
      ? {
          averageIn: data.packetDrop.averageIn,
          averageOut: data.packetDrop.averageOut,
          maxIn: data.packetDrop.maxIn,
          maxOut: data.packetDrop.maxOut,
        }
      : null,
    traffic: data.traffic
      ? {
          averageIn: data.traffic.averageIn
            ? `${formatNordic(
                convertByteToGigaByte(data.traffic.averageIn),
                2
              )} GB`
            : '',
          averageOut: data.traffic.averageOut
            ? `${formatNordic(
                convertByteToGigaByte(data.traffic.averageOut),
                2
              )} GB`
            : '',
          averageTotal: data.traffic.averageTotal
            ? `${formatNordic(
                convertByteToGigaByte(data.traffic.averageTotal),
                2
              )} GB`
            : '',
          sumIn: data.traffic.sumIn
            ? `${formatNordic(
                showCumulativeInMB
                  ? convertByteToMegaByte(data.traffic.sumIn)
                  : convertByteToGigaByte(data.traffic.sumIn),
                2
              )} ${showCumulativeInMB ? 'MB' : 'GB'}`
            : '',
          sumOut: data.traffic.sumOut
            ? `${formatNordic(
                showCumulativeInMB
                  ? convertByteToMegaByte(data.traffic.sumOut)
                  : convertByteToGigaByte(data.traffic.sumOut),
                2
              )} ${showCumulativeInMB ? 'MB' : 'GB'}`
            : '',
          sumTotal: data.traffic.sumTotal
            ? `${formatNordic(
                convertByteToGigaByte(data.traffic.sumTotal),
                2
              )} GB `
            : '',
        }
      : null,
    responseTime: data.responseTime
      ? {
          average: data.responseTime.average
            ? `${data.responseTime.average.toFixed()} ms`
            : '',
          max: data.responseTime.max
            ? `${data.responseTime.max.toFixed()} ms`
            : '',
        }
      : null,
  };
};
