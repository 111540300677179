import * as React from 'react';
import { formatNordic } from 'src/lib/utils';
import {
  ResponsiveContainer,
  ComposedChart,
  Bar,
  YAxis,
  XAxis,
  CartesianGrid,
} from 'recharts';
import './StepChart.scss';
import { StepsMeta } from 'src/lib/charts/StepChart';
import { colors } from 'src/lib/ui';
import { StepBar } from 'src/lib/charts/StepChart/StepBar';
import { t } from 'src/lib/i18n';
interface Props {
  stepsMeta: StepsMeta;
  height?: number;
}
export interface RectangleProps {
  x: number;
  y: number;
  usage: number;
  index: number;
  width: number;
  height: number;
}

export const StepChart: React.SFC<Props> = props => {
  const getYTicks = (cost: number[]) => {
    const max = cost.reduce((prev, curr) => (curr > prev ? curr : prev), 0);
    const roundedEstimate = Math.ceil(max / 0.35) * 0.35;
    return new Array(6).fill(0).map((_, i) => roundedEstimate * (i / 5));
  };

  const yTicks = getYTicks(props.stepsMeta.steps.map(step => step.cost));
  const chartHeight = props.height || 325;
  let usageUpToCumulative = 0;
  const steps = props.stepsMeta.steps.map(step => {
    usageUpToCumulative += step.usageUpTo || 0;
    return {
      ...step,
      usageUpToCumulative: step.usageUpTo ? usageUpToCumulative : undefined,
    };
  });

  return (
    <ResponsiveContainer height={chartHeight} className="StepChart">
      <ComposedChart
        data={steps}
        margin={{ left: 30, bottom: 20, top: 60, right: 10 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <svg xmlns="http://www.w3.org/2000/svg">
          <text
            x={0}
            y={20}
            fill={colors.greyDarkText}
            style={{ fontWeight: 500 }}
          >
            {t.mobile.overview.subscription.chartLabel}
          </text>
        </svg>
        <YAxis
          dataKey="cost"
          type="number"
          name="cost"
          unit="kr"
          tickLine={false}
          ticks={yTicks}
          tickCount={7}
          tickMargin={30}
          tickFormatter={tick => formatNordic(tick, 2)}
        />
        <XAxis
          dataKey="usageUpToCumulative"
          type="category"
          name="usage"
          tickFormatter={tick =>
            tick
              ? `${formatNordic(tick / 1024, 1)} GB`
              : t.mobile.overview.over6GB
          }
          tickLine={false}
          tickMargin={20}
        />
        <Bar
          dataKey="cost"
          barSize={110}
          fill={colors.corePurple}
          isAnimationActive={true}
          shape={data => (
            <StepBar
              {...(data as RectangleProps)}
              usage={props.stepsMeta.totalUsage}
              currentStep={props.stepsMeta.currentStep}
              fractionOfCurrentStep={props.stepsMeta.fractionOfCurrentStep}
            />
          )}
        />
      </ComposedChart>
    </ResponsiveContainer>
  );
};
