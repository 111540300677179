import React from 'react';
import {
  Fullscreen,
  FullscreenHeader,
  FullscreenBody,
  Button,
  colors,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Step1 } from './Step1';
import { useReplaceTechnicalContact } from 'src/areas/main/subscriptions/mutations/useReplaceTechnicalContact';
import { Step2 } from '../editServices/Step2';
import { Step3 } from './Step3';
import SaveOverlay from '../SaveOverlay/SaveOverlay';
import { useReplaceContactStepFlow } from '../editServices/useReplaceContactStepFlow';
import { useFeature } from 'src/lib/utils';
interface Props {
  technicalContactSubscriptions: Array<{
    id: string;
    name: string;
    address: string;
    type: string;
  }>;
  contactId: string;
}

export const ReplaceTechnicalContact = (props: Props) => {
  const disableDeleteContact = useFeature('disable-delete-contact');

  const {
    form,
    replaceTechnicalContacts,
    open,
    loading,
    error,
    toggle,
  } = useReplaceContactStepFlow({
    technicalContactSubscriptions: props.technicalContactSubscriptions,
    deleteContact: true,
  });

  const i18n = t.contacts.DeletePerson;
  const subsIds = props.technicalContactSubscriptions.map(s => s.id);
  const {
    replaceAllTechnicalContact,
    replaceTechnicalContact,
    ...replaceResult
  } = useReplaceTechnicalContact(props.contactId, subsIds);

  const isLastStep =
    form.values.replace === 'none'
      ? form.currentStep === 1
      : form.currentStep === 2;

  if (open) {
    return (
      <Fullscreen>
        <FullscreenHeader title={i18n.deleteContact}></FullscreenHeader>
        <FullscreenBody>
          <form>
            <Step1 form={form} />
            {form.values.replace !== 'none' ? (
              <Step2
                form={form}
                isValid={form.isFieldValid('replaceAllContact')}
                isLastStep={false}
              />
            ) : null}
            <Step3 form={form} step={form.values.replace === 'none' ? 1 : 2} />
            <SaveOverlay
              ignoreSidebar={true}
              cancel={toggle}
              submit={() => replaceTechnicalContacts(form.values)}
              loading={loading}
              disabled={!(isLastStep && form.isFieldValid('replaceAllContact'))}
              submitLabel={i18n.delete}
              error={error}
              errorMsg={
                replaceResult.error ? i18n.replaceError : i18n.deleteError
              }
            />
          </form>
        </FullscreenBody>
      </Fullscreen>
    );
  }
  return disableDeleteContact ? (
    <div style={{ color: colors.greyDarkText }} className="d-block float-right">
      {i18n.deleteIsTemporaryDisabled}
    </div>
  ) : (
    <Button
      icon="trash"
      className="d-block ml-auto"
      color="white"
      onClick={toggle}
    >
      {i18n.delete}
    </Button>
  );
};
