import React, { useState } from 'react';
import { ContextMenu, ContextMenuItem } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';
import { t } from 'src/lib/i18n';
import EditTagModal from 'src/areas/main/subscriptions/Pages/Tag/EditTagModal';
import DeleteTagModal from 'src/areas/main/subscriptions/Pages/Tag/DeleteTagModal';
import './TagContextMenu.scss';

interface Props {
  tag: {
    id: string;
    tag: string;
    description?: string | null;
  };
  refetchTags: () => void;
}

export default function TagContextMenu(props: Props) {
  const translate = t.tags;
  const [showEditModal, setShowEditModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const toggleEditModal = () => {
    setShowEditModal(!showEditModal);
  };

  const toggleDeleteModal = () => {
    setShowDeleteModal(!showDeleteModal);
  };

  return (
    <div className={'TagContextMenu'}>
      <ContextMenu alignRight={true}>
        <ContextMenuItem onClick={toggleEditModal}>
          <Icon icon="pen" className="mr-2" />
          {translate.edit}
        </ContextMenuItem>
        <ContextMenuItem onClick={toggleDeleteModal}>
          <Icon icon="trash" className="mr-2" />
          {translate.delete}
        </ContextMenuItem>
      </ContextMenu>
      <EditTagModal
        title={translate.edit}
        toggle={toggleEditModal}
        show={showEditModal}
        id={props.tag.id}
        initialValues={{
          tag: props.tag.tag,
          description: props.tag.description ?? '',
        }}
      />
      <DeleteTagModal
        title={translate.delete}
        toggle={toggleDeleteModal}
        show={showDeleteModal}
        id={props.tag.id}
        tagName={props.tag.tag}
        refetchTags={props.refetchTags}
      />
    </div>
  );
}
