import { KeyValue } from 'src/lib/types';

export const formatKeyValueArrayToObject = (keyValueArray: KeyValue[]) => {
  return keyValueArray.reduce((acc, value) => {
    return {
      ...acc,
      [value.key]: value.value,
    };
  }, {});
};
