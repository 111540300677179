import * as React from 'react';
import { Card } from 'src/lib/ui';
import './LinkCard.scss';
import { Icon, IconDefinition } from '@telia/styleguide';
import cs from 'classnames';
type LinkCardProps = {
  text?: string;
  subText?: string;
  icon?: IconDefinition;
  iconClass?: string;
  renderAs?: 'div' | 'span' | 'li';
  className?: string;
} & ({ url: string } | { onClick: () => void });

export const LinkCard: React.SFC<LinkCardProps> = props => (
  <Card
    className={cs('LinkCard', props.className)}
    renderAs={props.renderAs}
    linkTo={'url' in props ? props.url : undefined}
    onClick={'onClick' in props ? props.onClick : undefined}
  >
    <div className="d-flex align-items-center">
      <div className="LinkCard-text d-flex align-items-center flex-grow-1 overflow-hidden">
        <div
          style={{
            width: props.icon ? (props.text ? 200 : 100) : props.text ? 100 : 0,
          }}
        >
          {props.icon && <Icon className={props.iconClass} icon={props.icon} />}
          {props.text && <span className="pl-2">{props.text}</span>}
        </div>

        <div className="flex-grow-1 overflow-hidden">{props.children}</div>
      </div>
      <div className="d-flex align-items-center ml-auto">
        {props.subText && (
          <small className="d-block pr-2">{props.subText}</small>
        )}
        <Icon icon="arrow-large-right" />
      </div>
    </div>
  </Card>
);

export const LinkCardList: React.SFC = props => (
  <ul className="LinkCardList">{props.children}</ul>
);
