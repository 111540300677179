import * as React from 'react';
import cn from 'classnames';
import { Icon, IconDefinition } from '@telia/styleguide';

import './SelectCard.scss';

import { colors } from 'src/lib/ui';
import { CSSProperties } from 'react';

type SelectCardProps = {
  style?: CSSProperties;
  className?: string;
  helpText?: string;
  title?: string;
  selected?: boolean;
  icon?: IconDefinition;
  iconColor?: string;
  onClick?: (evt: React.SyntheticEvent<HTMLButtonElement>) => void;
};

export const SelectCard: React.FunctionComponent<SelectCardProps> = props => {
  const baseColor = props.iconColor ?? colors.greyMedium;

  return (
    <button
      className={cn(
        'SelectCard-button',
        props.selected ? 'SelectCard-button--selected' : undefined
      )}
      aria-pressed={props.selected}
      onClick={props.onClick}
      title={props.title}
    >
      <div>
        {props.icon && (
          <Icon
            className="SelectCard-icon mt-2"
            icon={props.icon}
            style={{ color: props.selected ? colors.corePurple : baseColor }}
          />
        )}
        {props.title && (
          <div className="SelectCard-title mt-3">{props.title}</div>
        )}
        {props.helpText && (
          <div className="SelectCard-helpText mt-1">{props.helpText}</div>
        )}
      </div>
    </button>
  );
};
