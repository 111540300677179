import React from 'react';
import {
  BodyCard,
  CardHeader,
  CardFooter,
  CardBody,
  Attributes,
  Attribute,
  LinkCard,
  colors,
  Loading,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useRouter, dateDiffDuration } from 'src/lib/utils';

import moment from 'moment';
import { useStatisticsGSMRecent } from 'src/areas/main/subscriptions/queries/useStatisticsGSMRecent';
import {
  mapSignalTypeToDisplaySignal,
  formatDurationString,
} from 'src/areas/main/subscriptions/utils';
import { NoData } from './';
interface Props {
  subscription: {
    id: string;
    hasGsmStatistics?: boolean | null;
  };
}
export const SignalCard = (props: Props) => {
  const i18n = t.subscriptions.SignalCard;
  const router = useRouter();
  const { data, loading } = useStatisticsGSMRecent(props.subscription.id);
  const recentGsm = data?.statisticsGSMRecent;
  /**
   * Recent GSM data is not indexed correctly in ES before this date.
   * We only show GSM-data if data is newer.
   * */
  const dateRestriction = moment('2019-04-30 00:00:00');
  const hasValidTimeStamp = recentGsm
    ? dateRestriction.isBefore(moment(recentGsm.measurementTimeStamp))
    : null;
  const durationSinceLastUpdate =
    recentGsm && hasValidTimeStamp
      ? dateDiffDuration(new Date(), new Date(recentGsm.measurementTimeStamp))
      : undefined;

  let body = <NoData dataTypeLabel={i18n.signal} />;

  if (loading) {
    body = <Loading />;
  }
  if (recentGsm && hasValidTimeStamp) {
    body = (
      <div>
        <div style={{ fontSize: '1.5rem' }}>
          {mapSignalTypeToDisplaySignal(recentGsm.signalType)}
        </div>
        <Attributes inline>
          <Attribute
            label={i18n.strength}
            value={`${recentGsm.currentRssi}dBm`}
          />
          <Attribute
            label={i18n.quality}
            value={`${recentGsm.currentEcio}dB`}
          />
          <Attribute label={i18n.bandNumber} value={recentGsm.currentBand} />
          <Attribute
            label={i18n.channelNumber}
            value={recentGsm.currentChannelNumber}
          />
        </Attributes>
      </div>
    );
  }

  return (
    <BodyCard noGutter>
      <CardHeader>
        <h2>{i18n.title}</h2>
        {durationSinceLastUpdate ? (
          <small style={{ color: colors.greyDarkText }}>
            {i18n.lastUpdated(
              formatDurationString(durationSinceLastUpdate, t.ui.times)
            )}
          </small>
        ) : null}
      </CardHeader>
      <CardBody>{body}</CardBody>
      {props.subscription.hasGsmStatistics && (
        <CardFooter noSpacing>
          <LinkCard className="py-4" url={router.match.url + '/statistikk'}>
            {i18n.showDetailedSignalStatistics}
          </LinkCard>
        </CardFooter>
      )}
    </BodyCard>
  );
};
