import React, { useState } from 'react';
import { Attribute } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils';
import { useCustomerSearch } from '../../customerSelection/useCustomerSearch';
import { Customer } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import './CustomerSelector.scss';
import {
  Dropdown,
  DropdownSearchItem,
  DropdownItem,
  DropdownMenu,
  DropdownToggle,
  DropdownSearchToggle,
} from '@telia/styleguide';
interface Props {
  customerList: Array<Customer>;
  allowSearch: boolean;
  activeCustomerName: string;
  setActiveCustomer: (id: string, name: string) => void;
  renderAsDropdown?: boolean;
}

export const CustomerSelector: React.FC<Props> = props => {
  const [query, setQuery] = useState('');
  const router = useRouter();
  const customer = useCustomerSearch({ query, skipQuery: !query.length });

  const selectCustomer = (item?: { id: string; name: string }) => {
    if (item?.id) {
      props.setActiveCustomer(item.id, item.name);
      router.history.push('/');
    }
  };

  const customers = customer.result ?? props.customerList;
  if (props.customerList.length <= 1 && !props.allowSearch) {
    return null;
  }

  return (
    <div className="CustomerSelector">
      <Dropdown>
        {props.renderAsDropdown ? (
          <DropdownToggle
            label={props.activeCustomerName}
            color="purple"
            outline={false}
          />
        ) : (
          <DropdownSearchToggle
            onInputChange={setQuery}
            icon="search"
            value={query}
            openImmediately={true}
          />
        )}
        <DropdownMenu position={props.renderAsDropdown ? 'right' : 'left'}>
          {props.allowSearch && props.renderAsDropdown ? (
            <DropdownSearchItem icon="search" onInputChange={setQuery} />
          ) : null}
          <div className="CustomerSelector-options">
            {customers.map(customer => (
              <DropdownItem
                key={customer.id}
                onClick={() => selectCustomer(customer)}
              >
                <div style={{ fontWeight: 'bold' }}>{customer.name}</div>
                <Attribute label="ID" value={customer.id} />
              </DropdownItem>
            ))}
            {customer.result && !customer.totalResults ? (
              <DropdownItem
                header={true}
                label={t.customerSelection.noResult}
              />
            ) : null}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
