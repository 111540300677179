import * as React from 'react';
import { FormattedNumber } from 'src/lib/ui';
import './MobileCostSummaryList.scss';

export type CostItem = {
  label: string;
  totalAmountNet: number;
  color: string;
  percent?: number | undefined;
};

interface Props {
  items: CostItem[];
  total: number;
  activeIndex: number | undefined;
  setActiveIndex: (index: number | undefined) => void;
}

const MobileCostSummaryList: React.SFC<Props> = props => (
  <div className="MobileCostSummaryList">
    <ul>
      {props.items.map((d, i) => (
        <MobileCostSummaryListitem
          key={i}
          item={d}
          active={props.activeIndex === i}
          onClick={() =>
            props.setActiveIndex(props.activeIndex === i ? undefined : i)
          }
        />
      ))}
    </ul>
    <div className="MobileCostSummaryList-total pr-3 pl-3">
      <div>Total</div>
      <div>
        Kr <FormattedNumber value={props.total} />
      </div>
    </div>
  </div>
);

interface ListItemProps {
  item: CostItem;
  active: boolean;
  onClick?: (e: React.MouseEvent<HTMLLIElement>) => void;
}

const MobileCostSummaryListitem: React.SFC<ListItemProps> = props => (
  <li
    className={`${
      props.active ? 'MobileCostSummaryList-item--active' : ''
    } pt-2 pb-2`}
    style={props.active ? { backgroundColor: props.item.color } : {}}
    onClick={props.onClick}
  >
    <div className="pr-3 pl-2">
      <div className="MobileCostSummaryList-label">
        <div
          className="MobileCostSummaryList-colorIndicator pr-2"
          style={{ borderColor: props.item.color }}
        />
        <div>{props.item.label}:</div>
      </div>
      <div className="MobileCostSummaryList-amount">
        kr <FormattedNumber value={props.item.totalAmountNet} decimals={2} />
        {props.item.percent !== undefined ? (
          <span className="MobileCostSummaryList-percent pl-1">
            (<FormattedNumber value={props.item.percent} percentage={true} />)
          </span>
        ) : null}
      </div>
    </div>
  </li>
);

export default MobileCostSummaryList;
