import { useReducer } from 'react';

export type ToggleAction<T> = Partial<{ [key in keyof T]: boolean }> | keyof T;

export function useToggler<
  T extends {
    [key: string]: boolean;
  }
>(initialValue: T) {
  const reducer = (state: T, actions: ToggleAction<T>): T => {
    if (typeof actions === 'string') {
      return {
        ...state,
        [actions]: !state[actions],
      };
    }
    if (typeof actions === 'object') {
      return {
        ...state,
        ...actions,
      };
    }
    return state;
  };

  return useReducer(reducer, initialValue);
}
