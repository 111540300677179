import React from 'react';
import ChatWrapper from './lib/Chat/ChatWrapper';
import { useAddIncidentComment } from './mutations/useAddIncidentComment';
import { useAppContext } from 'src/lib/global';
import './IncidentContent.scss';
import { mapActivitiesToChatMessages } from './utils/incidentUtils';
import { useRouter } from 'src/lib/utils/useRouter';
import { useIncident } from 'src/areas/main/incidents/queries/useIncident';
import { Loading } from 'src/lib/ui';
import { IncidentSidebar } from 'src/areas/main/incidents/IncidentSidebar';
import { IncidentPageHeader } from 'src/areas/main/incidents/IncidentPageHeader';
import { IncidentStatus } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';
import { useSubscription } from 'src/areas/main/subscriptions/queries/useSubscription';
import { useSavedUserPreferences } from 'src/lib/userPreferences/SavedUserPreferencesContext';
import cs from 'classnames';
import { useIncidentFollowers } from './queries/useIncidentFollower';

export const IncidentContent = () => {
  const { auth } = useAppContext();
  const { userPreferences, setUserPreference } = useSavedUserPreferences();

  const { addCommentToIncident } = useAddIncidentComment();

  const router = useRouter<{ id: string }>();
  const { incident, refetch, loading } = useIncident(
    router.match.params.id,
    true
  );
  const { incidentFollowers } = useIncidentFollowers(
    router.match.params.id,
    true
  );

  const subscriptionFullData = useSubscription(
    incident?.subscription?.id ?? ''
  );

  /*

  TEMPORARILY DISABLING LIVE UPDATES WHILE WAITING FOR THE FIREWALL TO ALLOW WSS

  const {
    newIncidentActivity,
    loadingNewIncidentActivity,
    newIncidentActivityError,
  } = useNewIncidentActivtiySusbcription(incident?.id);


  const checkForResponseErrors = () =>
    newIncidentActivityError &&
    (newIncidentActivityError.message ===
      'GraphQL error: Request failed with status code 401' ||
      newIncidentActivityError.message ===
        'Request failed with status code 401' ||
      newIncidentActivityError.message ===
        'Request failed with status code 404');

  if (checkForResponseErrors()) {
    window.location.reload();
  }

  useEffect(() => {
    if (incident && !loadingNewIncidentActivity && newIncidentActivity) {

      // This now fetches the entire incident instead of just the new Activity
      // because the chat wont rerender when incident.activities = newIncidentActivity
      refetch();
    }
  }, [incident, refetch, newIncidentActivity, loadingNewIncidentActivity]);


  */

  const sidebarOpen =
    !userPreferences?.incidentDetailsOpen ||
    userPreferences?.incidentDetailsOpen === 'open';

  /**
   * Temporary state to avoid bugs when sending messages when the incident is closed/unactive
   * TODO: Find a correct state for when a incident is resolved
   */
  const incidentClosed = incident?.incidentStatus === 'closed';

  if (loading) {
    return <Loading />;
  }
  if (!incident) {
    return null;
  }

  const resolveStatus = (status: IncidentStatus | null) => {
    switch (status) {
      case IncidentStatus.onHoldPendingTelia:
        return t.incidents.status.onHoldPendingTelia;
      case IncidentStatus.canceled:
        return t.incidents.status.canceled;
      case IncidentStatus.onHoldPendingCustomer:
        return t.incidents.status.onHoldPendingCustomer();
      case IncidentStatus.closed:
        return t.incidents.status.closed;
      case IncidentStatus.solved:
        return t.incidents.status.solved;
      default:
        return t.incidents.status.fallback;
    }
  };

  const incidentStatusText = resolveStatus(incident.incidentStatus);

  const toggleSidebar = () =>
    userPreferences.incidentDetailsOpen === 'closed'
      ? setUserPreference('incidentDetailsOpen', 'open')
      : setUserPreference('incidentDetailsOpen', 'closed');

  return (
    <div className="IncidentContent">
      <IncidentPageHeader
        incident={incident}
        routerId={router.match.params.id}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={toggleSidebar}
        incidentStatusText={incidentStatusText}
      />
      <div
        className={cs('IncidentContent-body', {
          'IncidentContent-body--sidebar-open': sidebarOpen,
        })}
      >
        <ChatWrapper
          bearerToken={auth.bearerToken}
          messages={mapActivitiesToChatMessages(
            auth?.user?.email ?? '',
            incident
          )}
          handleSend={addCommentToIncident}
          disableInput={incidentClosed}
          incidentId={incident?.id}
          refetch={refetch}
        />
        <IncidentSidebar
          incident={incident}
          incidentFollowers={incidentFollowers}
          sidebarOpen={sidebarOpen}
          setSidebarOpen={toggleSidebar}
          incidentStatusText={incidentStatusText}
          subscriptionFullData={subscriptionFullData}
        />
      </div>
    </div>
  );
};
