import { CostCategory } from './types';
import { t } from 'src/lib/i18n';

export const categoryDefinitions = (): CostCategory[] => {
  const i18n = t.costs.categoryDefinitions;
  return [
    { key: 'mobile', color: '#4c5bda', label: i18n.mobile, icon: 'mobile' },
    { key: 'data', color: '#d80d66', label: i18n.data, icon: 'pie-chart' },
    { key: 'tele', color: '#20d4e7', label: i18n.tele, icon: 'phone' },
    // { key: 'television',  color: '#515ba8', label: 'TV', icon: 'television' },
    {
      key: 'other',
      color: '#fe5457',
      label: i18n.other,
      icon: 'add',
    },
  ];
};
