import React from 'react';
import cs from 'classnames';
import './Chip.scss';
import { Icon, IconDefinition } from '@telia/styleguide';
import { Button } from 'src/lib/ui';
interface Props {
  label: string;
  className?: string;
  icon?: IconDefinition;

  /**
   * Whether the chip is currently active/selected
   */
  active?: boolean;
  disabled?: boolean;

  /**
   * To use Chip in `filter` or `select` mode.
   * In `select` mode, the chip will show an inline `remove` icon button when it is a active.
   * In `filter` mode, each chip functions as a toggle button.
   */
  mode?: 'filter' | 'select';

  /**
   * The click event for the whole chip (in filter mode) or for the remove button (in select mode).
   */
  onClick?: (evt: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const Chip: React.FC<Props> = props => {
  const Tag = props.mode === 'select' && props.active ? 'div' : 'button';
  const role =
    Tag === 'div' && !(props.mode === 'select' && props.active)
      ? 'button'
      : undefined;
  return (
    <Tag
      role={role}
      type={Tag === 'button' ? 'button' : undefined}
      className={cs(
        'Chip',
        props.mode === 'filter' ? 'Chip--filter' : 'Chip--select',
        props.disabled ? 'disabled' : undefined,
        props.active ? 'active' : undefined,
        props.className
      )}
      onClick={e => {
        if (props.active && props.mode === 'select') {
          return;
        } else if (props.onClick) {
          props.onClick(e);
        }
      }}
      tabIndex={
        props.disabled || (props.active && props.mode === 'select') ? -1 : 0
      }
    >
      {props.icon && (
        <Icon
          className="mr-2"
          style={{ width: '1rem', height: '1rem' }}
          icon={props.icon}
        />
      )}
      <div className="Chip-label">{props.label}</div>

      {props.mode === 'select' && props.active && (
        <Button
          style={{ marginLeft: '1rem', marginRight: '-6px' }}
          type="button"
          rounded={true}
          hideLabel={true}
          transparent={true}
          size={'xs'}
          color="white"
          icon="close"
          onClick={props.onClick}
          disabled={props.disabled}
        >
          remove
        </Button>
      )}
    </Tag>
  );
};

export default Chip;
