import * as React from 'react';
import './CostTable.scss';
import Table from 'reactstrap/lib/Table';
import { FormattedNumber, ExpandButton, LinkTo } from 'src/lib/ui';
import { formatDateInterval } from 'src/lib/utils';
import { Icon } from '@telia/styleguide';

export interface CostItem {
  id: string;
  name: string;
  amountGross: number;
  amountNet: number;
  lines: Array<{
    name: string;
    invoiceId?: string;
    periodStart?: string;
    periodEnd?: string;
    amountGross: number;
    amountNet: number;
  }>;
}

interface Props {
  data: CostItem[];
  totalNet?: number;
  totalGross?: number;
  totalLabel: string;
  colWidths?: string[];
  expandedItem: string | undefined;
  expandItem: (id: string) => void;
}

export const CostTable: React.SFC<Props> = props => (
  <Table className="CostTable">
    <colgroup>
      {props.colWidths &&
        props.colWidths.map((w, i) =>
          w ? <col key={i} width={w} /> : <col key={i} />
        )}
    </colgroup>
    <thead>
      <tr>{props.children}</tr>
    </thead>
    <tbody>
      <tr className="CostTable-totalRow">
        <td colSpan={3} className="pl-4">
          {props.totalLabel}
        </td>
        <td style={{ textAlign: 'right' }}>
          <FormattedNumber value={props.totalNet} />
        </td>
        <td style={{ textAlign: 'right' }}>
          <FormattedNumber value={props.totalGross} />
        </td>
        <td />
      </tr>
      {props.data.map((d, i) => (
        <CostTableRow
          key={i}
          data={d}
          expanded={props.expandedItem === d.id}
          expandItem={() => props.expandItem(d.id)}
        />
      ))}
    </tbody>
  </Table>
);

interface CostTableRowProps {
  data: CostItem;
  expanded: boolean;
  expandItem: () => void;
}

const CostTableRow: React.SFC<CostTableRowProps> = props => (
  <React.Fragment>
    <tr className={`CostTableRow ${props.expanded && 'CostTableRow-expanded'}`}>
      <td colSpan={3} className="pl-4">
        {props.data.name}
      </td>
      <td style={{ textAlign: 'right', fontWeight: 500 }}>
        <FormattedNumber value={props.data.amountNet} />
      </td>
      <td style={{ textAlign: 'right', fontWeight: 500 }}>
        <FormattedNumber value={props.data.amountGross} />
      </td>
      <td style={{ textAlign: 'center' }}>
        <ExpandButton toggle={props.expandItem} active={props.expanded} />
      </td>
    </tr>
    {props.expanded &&
      props.data.lines.map((l, i) => (
        <tr key={i} className="CostTableRow-child">
          <td>
            <div className="ml-5">{l.name}</div>
          </td>
          <td>
            {l.invoiceId ? (
              <LinkTo invoice={l.invoiceId}>{l.invoiceId}</LinkTo>
            ) : null}
          </td>
          <td>
            {l.periodStart && l.periodEnd
              ? formatDateInterval(l.periodStart, l.periodEnd)
              : ''}
          </td>
          <td style={{ textAlign: 'right', fontWeight: 500 }}>
            <FormattedNumber value={l.amountNet} />
          </td>
          <td style={{ textAlign: 'right', fontWeight: 500 }}>
            <FormattedNumber value={l.amountGross} />
          </td>
          <td />
        </tr>
      ))}
  </React.Fragment>
);

interface CostTableColumnProps {
  style?: React.CSSProperties;
  className?: string;
  onClick?: () => void;
  sorted?: boolean;
  sortOrder?: 'asc' | 'desc';
}

export const CostTableColumn: React.SFC<CostTableColumnProps> = props => (
  <React.Fragment>
    <th style={props.style} className={props.className}>
      <span
        style={props.onClick ? { cursor: 'pointer' } : {}}
        onClick={props.onClick}
      >
        {props.children}
        {props.sorted && props.sortOrder && (
          <Icon
            style={{ position: 'absolute' }}
            className="pl-1"
            icon={
              props.sortOrder === 'asc' ? 'arrow-large-up' : 'arrow-large-down'
            }
          />
        )}
      </span>
    </th>
  </React.Fragment>
);
