import * as React from 'react';
import group from 'src/images/illustrations/group.svg';

export function SubscriptionMainContentPlaceholder() {
  return (
    <div style={{ height: '500px', width: '100%', textAlign: 'center' }}>
      <span
        style={{
          display: 'inline-block',
          height: '100%',
          verticalAlign: 'middle',
        }}
      />
      <img src={group} alt="No data" style={{ verticalAlign: 'middle' }} />
    </div>
  );
}
