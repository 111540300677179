import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import useStructureCacheUpdater from 'src/areas/main/documents/lib/useStructureCacheUpdater';
import { ApolloError } from 'apollo-client';
import * as t from './__types/useDeleteFile';
import { useDocumentsContext } from 'src/areas/main/documents/DocumentsContext';
import { trackDocument } from 'src/lib/analytics';

const MUTATION_DELETE_FILE = gql`
  mutation useDeleteFile($id: ID!) {
    deleteFile(input: { id: $id }) {
      id
    }
  }
`;

interface DeleteFileResult {
  deleteFile: (fileId: string, cb?: () => void) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export const useDeleteFile = (): DeleteFileResult => {
  const cacheUpdater = useStructureCacheUpdater<t.useDeleteFile>();

  const { category } = useDocumentsContext();

  const [mutation, { loading, error }] = useMutation<
    t.useDeleteFile,
    t.useDeleteFileVariables
  >(MUTATION_DELETE_FILE);

  const deleteFile = async (fileId, cb) => {
    trackDocument(category, 'delete_document');
    await mutation({
      variables: { id: fileId },
      update: cacheUpdater(ff => ({
        files: ff.files.filter(f => f.id !== fileId),
        folders: ff.folders,
      })),
    });
    if (cb) {
      cb();
    }
  };
  return { deleteFile, loading, error };
};
