import * as React from 'react';
import {
  Attribute,
  Attributes,
  Loading,
  BodyCard,
  StatusProgressCardSteps,
  StatusProgressCard,
} from 'src/lib/ui';
import { colors, Alert } from 'src/lib/ui';
import { mapOrderStatusToLabel } from '../utils';
import { WithCustomerContext } from 'src/lib/global';
import { useMobileOrder } from '../queries/useMobileOrder';
import { useOrder_customer_order } from '../queries/__types/useOrder';

import { formatDate } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

const MobileOrderContent = (props: { order: useOrder_customer_order }) => {
  const mobileOrderQuery = useMobileOrder(props.order.id);
  const mobileOrder =
    mobileOrderQuery.data?.customer?.order?.mobileOrderDetails;
  const i18n = t.orders;

  if (mobileOrderQuery.loading) {
    return <Loading />;
  }

  if (!mobileOrder) {
    return <div>{i18n.MobileOrderContent.noOrder}</div>;
  }
  const progressBarStatuses: StatusProgressCardSteps[] = [
    { step: i18n.orderStatus.newOrder, icon: 'delivery', focus: true },
    { step: i18n.orderStatus.inProgress, icon: 'time-waiting', focus: true },
    { step: i18n.orderStatus.completed, icon: 'check-mark' },
  ];

  const orderMainProduct =
    mobileOrder.products &&
    mobileOrder.products.find(product => product.mainProduct);

  return (
    <WithCustomerContext>
      {cContext => (
        <div className="container">
          <BodyCard>
            <div className="row flex-md-row-reverse">
              <div className="col-md-4 py-4">
                <div
                  className="mb-4"
                  style={{ fontSize: '1.5rem', fontWeight: 500 }}
                >
                  {props.order.description}
                </div>
                <StatusProgressCard
                  steps={progressBarStatuses}
                  currentStep={progressBarStatuses.findIndex(
                    status =>
                      mapOrderStatusToLabel(props.order.portalStatus) ===
                      status.step
                  )}
                />
                <Attributes inline={true} className="mt-4">
                  {/* <Attribute
                  label={i18n.MobileOrderContent.orderType}
                  value={props.order.description}
                  displayBlock={true}
                /> */}
                  <Attribute
                    label={i18n.MobileOrderContent.orderDate}
                    value={formatDate(
                      props.order.dates && props.order.dates.createdAt
                        ? props.order.dates.createdAt
                        : '',
                      'short'
                    )}
                    displayBlock={true}
                  />
                </Attributes>
                {mobileOrder.responsibleUser.name ||
                mobileOrder.responsibleUser.phone ||
                mobileOrder.responsibleUser.email ? (
                  <Attribute
                    className="mt-4"
                    label="Ordre opprettet av"
                    value={
                      <div className="d-flex flex-column">
                        {mobileOrder.responsibleUser.name && (
                          <div>{mobileOrder.responsibleUser.name}</div>
                        )}
                        {mobileOrder.responsibleUser.email && (
                          <div>
                            <Icon icon="mobile" className="mr-1" />
                            {mobileOrder.responsibleUser.phone}
                          </div>
                        )}
                        {mobileOrder.responsibleUser.email && (
                          <div>{mobileOrder.responsibleUser.email}</div>
                        )}
                      </div>
                    }
                  />
                ) : null}

                {/* <TextField
              label={i18n.input.refNum}
              value="123456789ABC"
              helpText={i18n.input.helpText}
              subTextPosition="right"
              className="mt-4"
              readOnly={true}
            /> */}
                {/* <h4>Kontakter</h4> */}
              </div>
              <div className="col-md-8 py-4">
                {mobileOrder.downloadToken &&
                mobileOrder.portFormCompleted === false ? (
                  <Alert
                    status="success"
                    icon="check-mark"
                    header={i18n.MobileOrderContent.Alert.schema}
                    className="mb-4"
                  >
                    <p>
                      {i18n.MobileOrderContent.Alert.sentTo(
                        mobileOrder.personals && mobileOrder.personals.email
                          ? mobileOrder.personals.email
                          : ''
                      )}
                    </p>
                    <a
                      href={`/api/files/mobile/order/portForm/download?downloadToken=${encodeURIComponent(
                        mobileOrder.downloadToken
                      )}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      <Icon icon="attach" className="mr-2" />
                      {i18n.MobileOrderContent.Alert.download}
                    </a>
                  </Alert>
                ) : null}

                <h2>{i18n.MobileOrderContent.orderDetails}</h2>
                {mobileOrder && mobileOrder.personals ? (
                  <div className="mt-4">
                    <h3>{i18n.MobileOrderContent.subscriber}</h3>
                    <Attributes inline={true}>
                      <Attribute
                        label={i18n.MobileOrderContent.name}
                        value={`${mobileOrder.personals.firstName} ${mobileOrder.personals.lastName}`}
                        displayBlock={true}
                      />
                      {mobileOrder.personals.email && (
                        <Attribute
                          label={i18n.MobileOrderContent.email}
                          value={mobileOrder.personals.email}
                          displayBlock={true}
                        />
                      )}
                      {mobileOrder.phoneNumber && (
                        <Attribute
                          label={i18n.MobileOrderContent.phone}
                          value={mobileOrder.phoneNumber}
                          displayBlock={true}
                        />
                      )}
                    </Attributes>
                  </div>
                ) : null}

                {/** Own component? */}
                {mobileOrder.products && mobileOrder.products.length > 0 && (
                  <div className="mt-4">
                    <h3>{i18n.MobileOrderContent.subscription}</h3>
                    {orderMainProduct ? (
                      <div
                        className="d-flex justify-content-between align-items-center p-2"
                        style={{ backgroundColor: colors.grey }}
                      >
                        <span>
                          <Icon
                            icon="phone"
                            className="mr-2"
                            style={{ fontSize: '24px' }}
                          />
                          {orderMainProduct.name}
                        </span>
                        <span>
                          {i18n.MobileOrderContent.price(
                            orderMainProduct.monthlyCost
                          )}
                        </span>
                      </div>
                    ) : null}

                    {mobileOrder.products
                      ? mobileOrder.products
                          .filter(product => !product.mainProduct)
                          .map((product, idx) => (
                            <div
                              key={idx}
                              className="d-flex justify-content-between mt-2"
                            >
                              <span>{product.name}</span>
                              <span>
                                {i18n.MobileOrderContent.price(
                                  product.monthlyCost
                                )}
                              </span>
                            </div>
                          ))
                      : null}
                  </div>
                )}

                {mobileOrder.simCardNumber ||
                mobileOrder.newSimCardRecipient ? (
                  <div className="mt-4">
                    <h3>{i18n.MobileOrderContent.simcard}</h3>
                    {mobileOrder.simCardNumber && (
                      <div
                        className="d-flex p-2"
                        style={{ backgroundColor: colors.grey }}
                      >
                        <span>
                          <Icon
                            icon="sim-card"
                            style={{ fontSize: '24px' }}
                            className="mr-2"
                          />
                          {i18n.MobileOrderContent.mainSim(
                            mobileOrder.simCardNumber
                          )}
                        </span>
                      </div>
                    )}
                    {mobileOrder.newSimCardRecipient && (
                      <Attribute
                        displayBlock={true}
                        label={i18n.MobileOrderContent.deliveryDetails}
                        value={
                          <>
                            <div>{mobileOrder.newSimCardRecipient.name}</div>
                            <div>{mobileOrder.newSimCardRecipient.address}</div>
                            <div>
                              {mobileOrder.newSimCardRecipient.postalCode}{' '}
                              {mobileOrder.newSimCardRecipient.postalArea}
                            </div>
                            {mobileOrder.newSimCardRecipient.attention ? (
                              <div>{`Att: ${mobileOrder.newSimCardRecipient.attention}`}</div>
                            ) : null}
                          </>
                        }
                      />
                    )}
                  </div>
                ) : null}

                <div className="mt-4">
                  <h3>{i18n.MobileOrderContent.orgAndCost}</h3>
                  <Attributes inline={true}>
                    <Attribute
                      displayBlock={true}
                      label={i18n.MobileOrderContent.org}
                      value={mobileOrder.organisation?.name ?? ''}
                    />
                    {mobileOrder.costCenter ? (
                      <Attribute
                        displayBlock={true}
                        label={i18n.MobileOrderContent.costCenter}
                        value={`${mobileOrder.costCenter.code} - ${mobileOrder.costCenter.description}`}
                      />
                    ) : null}
                  </Attributes>
                </div>

                {/* <div className="mt-4">
              <h2>Tidligere juridisk eier</h2>
              <Attributes inline={true}>
                <Attribute
                  displayBlock={true}
                  label="Fakturasted"
                  value={mobileOrder.organisationId}
                />
                <Attribute
                  displayBlock={true}
                  label="Kostnadssted"
                  value={mobileOrder.costCenterId}
                />
              </Attributes>
            </div> */}
              </div>
            </div>
          </BodyCard>
        </div>

        // <div className="container">
        //   <div className="row">
        //     <div className="col-lg-7 col-sm-12">
        //       <Card className="mb-4">
        //         <h2>Abonnement</h2>

        //         <h3>Tjenester</h3>
        //         {mobileOrder &&
        //           mobileOrder.products &&
        //           mobileOrder.products.map(product => (
        //             <div key={product.name}>{product.name}</div>
        //           ))}
        //       </Card>
        //       <Card className="mb-4">
        //         <h2>Abonnent</h2>
        //         <Attribute
        //           label="navn"
        //           value={`${_get(mobileOrder, 'personals.firstName')} ${_get(
        //             mobileOrder,
        //             'personals.lastName'
        //           )}`}
        //         />
        //         <Attribute
        //           label="Ansattnummer"
        //           value={_get(mobileOrder, 'personals.employeeNumber')}
        //         />
        //         <Attribute
        //           label="E-post"
        //           value={_get(mobileOrder, 'personals.email')}
        //         />
        //       </Card>
        //       <Card className="mb-4">SIM-kort</Card>
        //     </div>
        //     <div className="col-lg-5 col-sm-12">
        //       <Card className="mb-4">Kontakter</Card>
        //       <Card className="mb-4">Avtale</Card>
        //       <Card className="mb-4">Priser</Card>
        //       <Card className="mb-4">Kostnadsinnstillinger</Card>
        //     </div>
        //   </div>
        // </div>
      )}
    </WithCustomerContext>
  );
};

export default MobileOrderContent;
