import React from 'react';
import cs from 'classnames';
import { Icon, IconDefinition } from '@telia/styleguide';
import { OptionsDropdownDivider, OptionsDropdownItem } from 'src/lib/ui';
import { useI18nContext } from 'src/lib/i18n';

interface Props {
  onClick?: any;
  highlight?: boolean;
  changelog?: {
    headingNorwegian: string;
    headingEnglish: string;
    bodyNorwegian: string | null;
    bodyEnglish: string | null;
    summaryNorwegian: string;
    summaryEnglish: string;

    /**
     * As a string to handle non-typed icons coming from the backend.
     */
    icon: string;
  };
}

export function ChangelogDropdownItem(props: Props) {
  const locale = useI18nContext();

  if (!props.changelog) {
    return null;
  }

  return (
    <>
      <OptionsDropdownItem onClick={props.onClick}>
        <div
          style={{
            width: '272px',
            whiteSpace: 'normal',
            margin: '8px 0',
          }}
        >
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'flex-start',
              marginBottom: '8px',
              fontWeight: 600,
            }}
          >
            <Icon
              className={cs('ChangelogDropdown-icon', {
                'ChangelogDropdown-icon-notify': props.highlight,
              })}
              icon={props.changelog.icon as IconDefinition}
            />
            <div>
              {locale.locale === 'en'
                ? props.changelog.headingEnglish
                : props.changelog.headingNorwegian}
            </div>
          </div>

          <div>
            {locale.locale === 'en'
              ? props.changelog.summaryEnglish
              : props.changelog.summaryNorwegian}
          </div>
        </div>
      </OptionsDropdownItem>

      <OptionsDropdownDivider />
    </>
  );
}
