import * as React from 'react';
import { map } from 'lodash';
import { urlFor, Loading, ListHeader, List, Button } from 'src/lib/ui';
import { SearchListIncidentResult } from 'src/areas/main/search/components/list/SearchListIncidentResult';
import { t } from 'src/lib/i18n';
import { useRouter } from 'src/lib/utils/useRouter';
import { useSubscriptionIncidents } from '../queries/useSubscriptionIncidents';

const Incidents = () => {
  const router = useRouter<{ id: string }>();
  const { data, loading } = useSubscriptionIncidents(router.match.params.id);
  if (loading) {
    return <Loading />;
  }

  const subscription = data?.customer?.subscriptions?.[0];

  if (!subscription) {
    return null;
  }

  const button = (
    <Button
      className="mr-3 my-2"
      icon="arrow-right"
      transparent={true}
      color="white"
      to={urlFor({
        subscription: subscription.id,
        subPage: 'report-incident',
      })}
      iconPosition="after"
    >
      {t.subscriptions.SubscriptionStatusSummaryCard.btnReportError}
    </Button>
  );

  if (!subscription.incidents || !subscription.incidents.length) {
    return (
      <>
        <ListHeader>{t.subscriptions.Incidents.noIncidnets}</ListHeader>
        {button}
      </>
    );
  }

  return (
    <>
      <ListHeader>
        {t.subscriptions.Incidents.showing(subscription.incidents.length)}
      </ListHeader>

      <List border={true} container={false}>
        {map(subscription.incidents, inc => (
          <SearchListIncidentResult
            key={inc.id}
            entry={{
              id: inc.id,
              incidentStatus: inc.incidentStatus,
              shortDescription: inc.shortDescription,
              opened: inc.createdAt,
              updatedAt: inc.updatedAt,
              subscription: inc.addon,
            }}
          />
        ))}
      </List>

      {button}
    </>
  );
};

export default Incidents;
