import * as React from 'react';
import { useRouter } from 'src/lib/utils/useRouter';
import { useSubscription } from './queries/useSubscription';
import { ReportIncident } from '../incidents/lib/reportIncident/ReportIncident';

const ReportIncidentSection = () => {
  const router = useRouter<{ id: string }>();
  const { subscription, loading } = useSubscription(router.match.params.id);

  if (loading || !(subscription && subscription.subscriptionId)) {
    return null;
  }

  return <ReportIncident subscription={subscription} />;
};

export default ReportIncidentSection;
