import React from 'react';
import { Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import cs from 'classnames';

export const OptionSelectFooter = (props: {
  reset:
    | (() => void)
    | ((evt: React.SyntheticEvent<HTMLButtonElement>) => void);
  resetLabel?: string;
  disabled?: boolean;
  apply: () => void;
  className?: string;
  loading?: boolean;
}) => {
  const i18n = t.ui.SelectField;

  return (
    <div
      className={cs(
        'OptionSelectFooter d-flex justify-content-end py-2 px-4',
        props.className
      )}
    >
      <Button
        className="order-1"
        transparent={true}
        onClick={props.apply}
        disabled={props.disabled || props.loading}
      >
        {i18n.apply}
      </Button>
      <Button
        className="order-0 mr-3"
        color="white"
        transparent={true}
        onClick={props.reset}
        disabled={props.loading}
      >
        {props.resetLabel ?? i18n.reset}
      </Button>
    </div>
  );
};
