import { customerSupportNumber, customerSupportEmail } from './../../variables';

const profile = {
  settings: 'Kontoinnstillinger',
  tabs: {
    profile: 'Profil',
    notificationPreferences: 'Varsling',
  },
  personaliaForm: {
    edit: 'Endre',
    description:
      'Dette er epostadressen du bruker til å logge inn i Min Portal. E-postadressen vil være synlig for andre brukere som kan se din kontaktinformasjon.',
    aboutTitle: 'Om deg',
    firstName: 'Fornavn',
    lastName: 'Etternavn',
    email: 'Epost',
    phoneNumber: 'Telefonnummer',
    btnSave: 'Lagre',
    emailError: 'Ugyldig epost',
    changeEmail: 'Endre epostadresse',
    existingEmail: 'Epostadressen er i bruk',
  },
  identityInformation: {
    lookingForUserSettings:
      'Ser du etter innstillingene for brukerkontoen din?',
    changePassword: 'Endre passord',
    changePasswordInfo:
      'Lenken åpner en ny fane hvor du vil få muligheten til å endre passordet for brukerkontoen din.',
    clickToChangePassword: 'Klikk her for å endre passord',

    changeMFA: 'Endre innstillinger for totrinnsbekreftelse',
    changeMFAInfo:
      'Lenken åpner et nytt vindu hvor du vil få muligheten til å endre innstillinger for totrinnsbekreftelse for brukerkontoen din.',
    clickToChangeMFA: 'Klikk her for å endre totrinnsbekreftelse',

    changeEmailAddress: 'Endre e-postadresse',
    notPossible:
      'I dag er det dessverre ikke mulig å endre e-postadressen som er knyttet til brukerkontoen din i Min Portal.',
    contactCustomerService: `Har du behov for å bytte e-postadresse kan du ta kontakt med kundeservice på chat, ved å ringe ${customerSupportNumber} eller ved å sende en mail til ${customerSupportEmail} så skal vi hjelpe deg med dette.`,
    close: 'Lukk',
  },
  notificationPreferences: {
    title: 'Varslingsinnstillinger',
    description:
      'Alle varsler er synlig i portalen. Innstillingene du setter her gjelder for varsling på e-post og sms',
    incidents: {
      title: 'Feilmeldinger',
    },
    plannedWork: {
      title: 'Planlagt arbeid',
    },
  },

  editNotifications: {
    channelDescription: 'Kanaler du ønsker disse varslingene i',
    emailNotifications: 'Du vil bli varslet på epost',
    subscriptionSelection: 'Tjenester du får varslinger på',
    subscriptionSelectionInfo:
      'Om du velger en tag vil alle tjenestene som tilhører taggen legges til i listen. Om taggen får en ny tjeneste knyttet til seg, er du nødt til å legge til taggen på nytt for å få denne med i varselinnstillingene.',
    placeholder: 'Søk på en tjeneste',
    all: 'Alle',
    selected: 'Et utvalg av tjenester',
    email: 'E-post',
    sms: 'SMS',
    btnSave: 'Lagre',
    subscriptionSearchHeader:
      'Finn tjenester eller tags du vil legge til varslinger på',
    globalNotificationsInformation:
      'Du har globale varslinger. Kontakt din systemadministrator for endringer.',
    activeNotifications: 'Varsling aktivert',
    btnRemove: 'Fjern',
    error: 'Noe gikk galt! Endringene dine ble ikke lagret',
  },
};

export default profile;
