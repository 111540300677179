import React, { useState } from 'react';
import { t } from 'src/lib/i18n';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { SettingsCard } from 'src/areas/mobile/settings/SettingsCard';
import { BarringMobileSettings } from 'src/lib/types';
import { BarringSettingsFormModal } from './forms/BarringSettingsFormModal';

interface Props {
  barring: BarringMobileSettings;
}
export const BarringSettings: React.FC<Props> = ({ barring }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const i18n = t.mobile.settings;

  return (
    <SettingsCard
      title={i18n.barring.usageRestrictions}
      description={i18n.barring.description}
      onClick={() => setModalOpen(true)}
    >
      {barring.data && (
        <SettingsListItem
          title={i18n.barring.barData.barAllData}
          description={i18n.activated}
        />
      )}
      {barring.dataRoaming && (
        <SettingsListItem
          title={i18n.barring.barData.barRoamingData}
          description={i18n.activated}
        />
      )}

      {/* Bar call inland */}
      {barring.incomingCallsAndSms && (
        <SettingsListItem
          title={i18n.barring.barCalls.barCallsMsgIncom}
          description={i18n.activated}
        />
      )}

      {barring.outgoingCallsAndSms && (
        <SettingsListItem
          title={i18n.barring.barCalls.barCallsMsgOutg}
          description={i18n.activated}
        />
      )}

      {barring.outgoingCalls && (
        <SettingsListItem
          title={i18n.barring.barCalls.barCallsOutg}
          description={i18n.activated}
        />
      )}

      {/* Bar call abroad */}
      {barring.internationalCalls && (
        <SettingsListItem
          title={i18n.barring.barCalls.barCallsAbroad}
          description={i18n.activated}
        />
      )}

      {barring.internationalCallsExceptHome && (
        <SettingsListItem
          title={i18n.barring.barCalls.barCallsAbroadExcHome}
          description={i18n.activated}
        />
      )}

      {barring.internationalIncomingCalls && (
        <SettingsListItem
          title={i18n.barring.barCalls.barIncomCallsAbroad}
          description={i18n.activated}
        />
      )}

      {barring.internationalOutgoingCalls && (
        <SettingsListItem
          title={i18n.barring.barCalls.barOutgCallsAbroad}
          description={i18n.activated}
        />
      )}

      {/* Bar content services*/}
      {barring.cpaDisabled && (
        <SettingsListItem
          title={i18n.barring.barContentServices.cpa}
          description={i18n.activated}
        />
      )}

      {barring.cpaAmount100 && (
        <SettingsListItem
          title={i18n.barring.barContentServices.cpa100}
          description={i18n.activated}
        />
      )}
      {barring.cpaAmount250 && (
        <SettingsListItem
          title={i18n.barring.barContentServices.cpa250}
          description={i18n.activated}
        />
      )}

      {barring.cpaAmount500 && (
        <SettingsListItem
          title={i18n.barring.barContentServices.cpa500}
          description={i18n.activated}
        />
      )}

      {barring.cpaGoodsAndServices && (
        <SettingsListItem
          title={i18n.barring.barContentServices.cpaGoodsAndServices}
          description={i18n.activated}
        />
      )}

      {/* Paid services */}
      {barring.premiumServices && (
        <SettingsListItem
          title={i18n.barring.barPaidServices.barPaidServices}
          description={i18n.activated}
        />
      )}

      {barring.premiumServices820 && (
        <SettingsListItem
          title={i18n.barring.barPaidServices.bar820}
          description={i18n.activated}
        />
      )}

      {barring.premiumServices829 && (
        <SettingsListItem
          title={i18n.barring.barPaidServices.bar829}
          description={i18n.activated}
        />
      )}

      {barring.donations && (
        <SettingsListItem
          title={i18n.barring.barPaidServices.barDonation}
          description={i18n.activated}
        />
      )}

      {barring.numberInformation && (
        <SettingsListItem
          title={i18n.barring.barPaidServices.barPhonebook}
          description={i18n.activated}
        />
      )}

      <BarringSettingsFormModal
        isOpen={isModalOpen}
        toggle={() => setModalOpen(!isModalOpen)}
        barring={barring}
      />
    </SettingsCard>
  );
};
