import React from 'react';
import { TableBodyCell } from 'src/lib/ui';
import { CaseBadges } from './CaseBadges';
import {
  IncidentStatus,
  DeliveryStatus,
} from 'src/__types/graphql-global-types';
export const CasesTableCell = (props: {
  subscription?: null | {
    id: string;
    deliveryStatus?: DeliveryStatus | null;
    plannedWork?: Array<{ state: string | null }> | null;
    activeIncidents?: Array<{
      id: string;
      incidentStatus: IncidentStatus | null;
    }> | null;
  };
}) => (
  <TableBodyCell>
    <CaseBadges subscription={props.subscription} />
  </TableBodyCell>
);
