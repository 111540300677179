import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import * as t from './__types/useExtendedQosStatistics';
import { ExtendedQosStatisticsType } from 'src/__types/graphql-global-types';
import { useCustomer } from 'src/lib/global';

const EXTENDED_QOS = gql`
  query useExtendedQosStatistics(
    $customerId: ID!
    $input: ExtendedQosStatisticsInput!
    $subscriptionId: [ID!]!
  ) {
    customer(id: $customerId) {
      id
      subscriptions(ids: $subscriptionId) {
        id
        statisticsExtendedQos(input: $input) {
          took
          periodStart
          periodEnd
          trafficClasses {
            name
            jitter {
              in
              out
            }
            roundTripTime {
              average
              max
            }
            packetLoss {
              in
              out
            }
          }
          granularity
          periods {
            periodStart
            periodEnd
            trafficClasses {
              name
              jitter {
                in
                out
              }
              roundTripTime
              packetLoss {
                in
                out
              }
            }
          }
        }
      }
    }
  }
`;

export const useExtendedQosStatistics = (inputProps: {
  subscriptionId: string;
  periodStart: string;
  periodEnd: string;
  types: ExtendedQosStatisticsType[];
}) => {
  const { subscriptionId, ...restProps } = inputProps;
  const customer = useCustomer();

  const res = useQuery<
    t.useExtendedQosStatistics,
    t.useExtendedQosStatisticsVariables
  >(EXTENDED_QOS, {
    variables: {
      customerId: customer.id,
      subscriptionId: [subscriptionId],
      input: restProps,
    },
  });

  return {
    loading: res.loading,
    error: res.error,
    data: res.data?.customer?.subscriptions?.[0] ?? null,
  };
};

export type ExtendedQosStatisticsPeriods = Omit<
  t.useExtendedQosStatistics_customer_subscriptions_statisticsExtendedQos_periods,
  '__typename'
>;

export type ExtendedQosStatistics = Omit<
  t.useExtendedQosStatistics_customer_subscriptions_statisticsExtendedQos,
  '__typename'
>;
