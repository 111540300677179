import React from 'react';
import { RequireRole, Pagination, Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
import { useSearch } from 'src/areas/main/search';
import FilterSection from 'src/areas/main/common/FilterSection';
import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import UserContactCard from './UserContactcard';
import { ContactsTable } from './ContactsTable';
import { useContactsParams } from './useContactsParams';
import ListContacts from './ListContacts';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import { useQueryParamPagination } from 'src/lib/ui/Pagination/useQueryParamPaginations';
import { getFilterCountFromSearchFilter } from 'src/lib/utils';
import { SearchDomain } from 'src/__types/graphql-global-types';

export const ContactsContainer = () => {
  const translate = t.contacts;
  const noResultsMessage = translate.listingNoResult;
  const {
    listParams,
    query,
    sortOptions,
    filters,
    hasUser,
  } = useContactsParams();
  const mobileBreakpoint = useBreakpoint('md');
  const paginationProps = useQueryParamPagination({
    defaultPageSize: listParams.pageSize,
    onTogglePageSize: listParams.setPageSize,
  });

  const filterOptions = {
    id: 'hasUser',
    label: translate.type,
    allowMultiple: false,
    options: filters,
    selectedOptions: hasUser,
    handleSave: cf => setFilterAndResetPage(query, 'hasUser', cf),
  };
  const search = useSearch({
    query: listParams.searchQuery,
    page: listParams.page,
    types: [SearchDomain.contact],
    sortBy: listParams.sortBy,
    sortOrder: listParams.sortOrder,
    size: listParams.pageSize,
    filter: [{ filter: 'hasUser', value: hasUser }],
  });

  const filterCount = search.filter
    ? getFilterCountFromSearchFilter(search.filter)
    : undefined;

  return (
    <>
      <PageHeader title={translate.title} />

      <div className="container pt-4">
        <UserContactCard />
      </div>

      <FilterSection
        searchValue={listParams.searchQuery}
        onSearch={val => listParams.setSearchQuery(val)}
        onResetSearch={() => listParams.setSearchQuery('')}
        filterToggling={false}
        filterOptions={[
          filterOptions,
          ...(listParams.view === 'list' ? [sortOptions] : []),
        ]}
        totalSearchResults={search.totalResults}
        totalFiltersActive={filterCount}
        actions={
          <>
            <RequireRole
              role="CustomerAdministrator"
              orWhenCustomerSearchIsAllowed={false}
            >
              <Button
                to="/kontakter/opprett"
                icon="user"
                transparent={true}
                size="small"
                color="white"
              >
                {translate.btnCreate}
              </Button>
            </RequireRole>

            {mobileBreakpoint && (
              <Button
                icon={listParams.view === 'list' ? 'table-view' : 'list-view'}
                color="dark"
                size="small"
                transparent={true}
                onClick={() =>
                  listParams.setView(
                    listParams.view === 'list' ? 'table' : 'list'
                  )
                }
              >
                {listParams.view === 'list'
                  ? t.layout.FilterSection.showTable
                  : t.layout.FilterSection.showList}
              </Button>
            )}
          </>
        }
      />

      <div className="container">
        {listParams.view === 'list' || !mobileBreakpoint ? (
          <ListContacts
            loading={search.loading}
            results={search.results}
            noResultsMessage={noResultsMessage}
          />
        ) : (
          <ContactsTable
            loading={search.loading}
            results={search.results}
            noResultsMessage={noResultsMessage}
          />
        )}

        <div className="flex-fill d-flex justify-content-end mb-5 mt-4">
          <Pagination
            totalResults={search.totalResults || 0}
            {...paginationProps}
          />
        </div>
      </div>
    </>
  );
};
