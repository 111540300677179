import { customerSupportNumber, customerSupportEmail } from './../../variables';
import nb from '../../nb/Main/orders';

const orders: typeof nb = {
  PageHeader: {
    title: 'Orders',
  },
  status: 'Status',
  orderStatus: {
    newOrder: 'New',
    inProgress: 'In progress',
    completed: 'Completed',
    cancelled: 'Cancelled',
    unknown: 'Unknown',
  },
  orderLineStatus: {
    ready: 'Ready for processing',
    inProgress: 'In progress',
    error: 'Error',
    done: 'Done',
    cancelled: 'Cancelled',
    unknown: 'Unknown',
  },

  orderType: {
    service: 'Data services',
    mobile: 'Mobile',
    mobileUnit: 'Mobile unit',
  },

  orderTableColumns: {
    status: 'Status',
    description: 'Description',
    category: 'Category',
    id: 'ID',
    created: 'Created',
    updated: 'Updated',
    deliveryDate: 'Delivery date',
  },

  searchRes: (count, total) => `Showing ${count} of ${total}`,
  loadMoreBtn: 'View more orders',

  navTabs: {
    active: 'Active orders',
    history: 'History',
  },
  ListOrders: {
    noResult: 'No orders',
  },
  OrdersTable: {
    network: 'Network',
    mobile: 'Mobile',
    other: 'Other',
  },
  OrderContent: {
    PageHeader: {
      title: (id: string) => `Order ${id}`,
      breadcrumb: 'Order',
      actions: (customerName: string) => `${customerName} reference`,
    },
    noData: 'Order details unavailable.',
  },

  ServiceOrderContent: {
    orderDetails: 'Order details',
    noOrder: 'Order details unavailable.',
    id: 'Order ID',
    casesCount: 'Number of cases',
    description: 'Description',
    created: 'Created',
    updated: 'Last updated',
    status: 'Status',
    orderlines: {
      heading: 'Order lines',
      subscriptionId: 'Subscription ID',
      subscription: 'Product',
      estimatedDate: 'Estimated date',
      status: 'Status',
    },
    worklog: {
      heading: 'Work log',
      date: 'Date',
      content: 'Comment',
    },
    newOrderLabel: 'We have received the order and it will be processed soon',
    singleCompletedCase: () => '1 case is completed',
    multipleCompletedCases: (count: number) => `${count} cases are completed`,
    singleInProgressCase: () => '1 case is in progress',
    multipleInProgressCases: (count: number) =>
      `${count} cases are in progress`,
    cancelledOrderLabel: `If you have any questions about the order you can contact us via chat, by calling ${customerSupportNumber}, or email ${customerSupportEmail}`,
    OrderLinesTable: {
      id: 'ID',
      status: 'Status',
      details: 'Details',
      created: 'Created',
      deliveryDate: 'Delivery date',
      deletedCase: 'The case has been deleted',
    },
    deliveryDateStatus: {
      committed: 'Confirmed',
      estimated: 'Estimated',
      planned: 'Planned',
      closed: 'Closed',
    },
    OrderLineSubscriptionModal: {
      parentCase: 'Parent case',
      id: 'ID',
      created: 'Created',
      updated: 'Last updated',
      deliveryDate: 'Delivery date',
      status: 'Status',
      description: 'Description',
      alias: 'Alias',
      notSet: 'Not set',
      subId: 'ID',
      type: 'Service type',
      address: 'Address',
      mainSubHeading: (text: string) => `Main service (${text})`,
      orderLineSubHeading: 'Related services',
    },
  },

  MobileUnitOrderContent: {
    noOrder: 'Order details unavailable.',
    description: 'Description',
    created: 'Created',
    status: 'Status',
    quantity: 'Count',
  },

  MobileOrderContent: {
    noOrder: 'Order details unavailable.',
    status: 'Status',
    orderDetails: 'Order details',
    orderType: 'Order type',
    orderDate: 'Order date',
    input: {
      refNum: 'Reference number customer',
      helpText: 'Max 40 characters',
    },
    Alert: {
      schema:
        'The change of ownership form has been sent to the end user, waiting for the signed form and approval from Telia',
      sentTo: (email: string) => `Sent to: ${email}`,
      download: 'Click here to download port form (PDF)',
    },
    subscriber: 'Subscriber',
    name: 'Name',
    email: 'E-mail',
    phone: 'Phone number',
    subscription: 'Subscription',
    price: (cost: string | number) => `${cost} kr/mo`,
    simcard: 'SIM-card',
    mainSim: (simcardNumber: string | number) => `Main-SIM ${simcardNumber}`,
    orgAndCost: 'Invoice center and cost center',
    org: 'Invoice center',
    costCenter: 'Cost center',
    deliveryDetails: 'SIM card delivery address',
  },
};

export default orders;
