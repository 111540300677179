import React, { useEffect } from 'react';
import { Modal, ModalFooter, Form } from 'reactstrap';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { t } from 'src/lib/i18n';
import { withFormik, FormikProps } from 'formik';
import { FormikTextField } from 'src/lib/flow';
import { OriginDetails, UserDetails } from 'src/areas/mobile/types';
import { useUpdateSubscriptionUser } from 'src/areas/mobile/settings/mutations';
import { FormSubmitBar } from 'src/lib/ui';
import { UpdateSubscriptionUserResult } from '../mutations/useUpdateSubscriptionUserDetails';

interface Props {
  origin: OriginDetails;
  userDetails: UserDetails;
  open: boolean;
  setOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

interface InnerProps extends Props {
  user: UpdateSubscriptionUserResult;
}

const InnerFormModal: React.FC<InnerProps &
  FormikProps<UserDetails>> = props => {
  const i18n = t.mobile.settings;
  const { user, setOpen, open } = props;

  useEffect(() => {
    if (user.data && !user.error) {
      setOpen(false);
    }
  }, [setOpen, user.data, user.error]);

  return (
    <Modal isOpen={open} size="lg" toggle={() => setOpen(!open)}>
      <ModalHeader>{i18n.profileUpdate.title}</ModalHeader>
      <ModalBody>
        <Form>
          <div className="pb-4">
            <FormikTextField
              id="firstName"
              label={i18n.firstName}
              className="w-50 pr-3"
              required
            />
            <FormikTextField
              id="lastName"
              label={i18n.lastName}
              className="w-50"
              required
            />
            <FormikTextField
              id="email"
              label={i18n.email}
              className="w-100 pr-5"
            />
            <FormikTextField
              id="employeeNum"
              label={i18n.employeeNum}
              className="w-100 pr-5"
            />
            <FormikTextField
              id="position"
              label={i18n.employeePosition}
              className="w-100 pr-5"
            />
          </div>
          <div className="ml-2 pt-5 d-flex border-top">
            <div className="font-weight-bold mr-2">{i18n.address}:</div>
            <div>{`${props.origin.streetAddress} ${props.origin.streetNum ??
              ''}, ${props.origin.postCode} ${props.origin.postArea}`}</div>
          </div>
          <div className="ml-2 pb-4">
            <div className="grey-dark-text">{i18n.changeAddress}</div>
          </div>
        </Form>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={() => props.handleSubmit()}
          cancel={() => setOpen(false)}
          loading={props.user.loading}
          error={props.user.error}
          errorMsg={t.mobile.settings.modalFields.unableToSave}
        />
      </ModalFooter>
    </Modal>
  );
};

const FormikProfileForm = withFormik<InnerProps, UserDetails>({
  enableReinitialize: true,
  mapPropsToValues: ({ userDetails }) => ({
    firstName: userDetails.firstName,
    lastName: userDetails.lastName,
    email: userDetails.email,
    position: userDetails.position,
    employeeNum: userDetails.employeeNum,
    flexField1: userDetails.flexField1,
    flexField2: userDetails.flexField2,
    flexField3: userDetails.flexField3,
    flexField4: userDetails.flexField4,
  }),
  handleSubmit: (values: UserDetails, bag) => {
    bag.props.user.updateUser({
      firstName: values.firstName,
      lastName: values.lastName,
      email: values.email,
      position: values.position,
      employeeNum: values.employeeNum,
      flexField1: values.flexField1,
      flexField2: values.flexField2,
      flexField3: values.flexField3,
      flexField4: values.flexField4,
    });
  },
})(InnerFormModal);

export const ProfileFormModal: React.FC<Props> = props => {
  const user = useUpdateSubscriptionUser();
  return (
    <FormikProfileForm
      origin={props.origin}
      userDetails={props.userDetails}
      open={props.open}
      setOpen={props.setOpen}
      user={user}
    />
  );
};
