import * as React from 'react';
import { CostMatrix, categoryDefinitions } from '..';
import { formatNordic } from 'src/lib/utils';
import { t } from 'src/lib/i18n';

import './CostSummaryTable.scss';
import { Icon } from '@telia/styleguide';

interface Props {
  costMatrix?: CostMatrix;
  costType: 'gross' | 'net';
}

export const CostSummaryTable: React.FC<Props> = ({ costMatrix, costType }) => {
  const i18n = t.costs.ui.CostSummaryTable;
  const categoryDef = categoryDefinitions();
  if (!costMatrix) {
    return null;
  }
  return (
    <table className="CostSummaryTable">
      <thead>
        <tr>
          <th>{i18n.category}</th>
          <th>{i18n.consumption}</th>
          <th>{i18n.fixedCosts}</th>
          <th>{i18n.oneTimeFee}</th>
          <th>{costType === 'gross' ? i18n.VAT : i18n.noVAT}</th>
        </tr>
      </thead>

      <tbody>
        {categoryDef.map(cd => (
          <tr key={cd.label}>
            <td>
              <Icon icon={cd.icon} className="pr-2" />
              {cd.label}
            </td>
            <td>{formatNordic(costMatrix[cd.key].usage[costType], 2)}</td>
            <td>
              {formatNordic(costMatrix[cd.key].subscription[costType], 2)}
            </td>
            <td>{formatNordic(costMatrix[cd.key].oneTime[costType], 2)}</td>
            <td>{formatNordic(costMatrix[cd.key].total[costType], 2)}</td>
          </tr>
        ))}
      </tbody>

      <tfoot>
        <tr>
          <td>{i18n.total}</td>
          <td>{formatNordic(costMatrix.total.usage[costType], 2)}</td>
          <td>{formatNordic(costMatrix.total.subscription[costType], 2)}</td>
          <td>{formatNordic(costMatrix.total.oneTime[costType], 2)}</td>
          <td>{formatNordic(costMatrix.total.total[costType], 2)}</td>
        </tr>
      </tfoot>
    </table>
  );
};
