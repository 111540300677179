import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from 'src/areas/main/orders/queries/__types/useMobileOrder';

const MOBILE_ORDER = gql`
  query useMobileOrder($customerId: ID!, $orderId: ID!) {
    customer(id: $customerId) {
      id
      order(id: $orderId) {
        id
        mobileOrderDetails {
          # downloadToken for port form
          downloadToken
          # number of main sim card
          simCardNumber
          # the products the order will modify
          products {
            action
            installationCost
            monthlyCost
            name
            mainProduct
          }
          # null for e.g. new mobile subscription if order is not completed
          phoneNumber
          organisation {
            id
            name
          }
          customerName
          # id of order
          orderId
          # null if not completed
          completedAt
          orderedAt
          #OrderStatus-type
          portalStatus
          # whether port form has been submitted and accepted (valid forport number order)
          portFormCompleted
          description
          costCenter {
            id
            description
            code
          }
          # The person to which a new SIM card should be sent if no existing SIM card id is specified.
          newSimCardRecipient {
            name
            address
            postalCode
            postalArea
            # (who should be notified when unit order is processed)
            attention
          }
          # 'free' | 'officePhone' | 'departmentPhone' | 'personal' | 'none' | null, used internally by some customers for invoice ordering, or else we would remove it... set as 'Fri' | 'Arbeidstelefon' | 'Kontortelefon' | 'Privat' | 'Ikke satt' in UI
          taxModel
          responsibleUser {
            name
            phone
            email
            contactInformation
          }
          personals {
            firstName
            lastName
            jobTitle
            localNumber
            email
            employeeNumber
          }
          formerOwner {
            businessName
            # if false then businessName and organisationNumber are not null, if true then other 3 fiels are not null
            privateOwner
            # Brønnøysundregister
            organisationNumber
            firstName
            lastName
            birthDate
          }
        }
      }
    }
  }
`;

export const useMobileOrder = (orderId: string) => {
  const customer = useCustomer();

  return useQuery<t.useMobileOrder, t.useMobileOrderVariables>(MOBILE_ORDER, {
    variables: {
      customerId: customer.id,
      orderId: orderId,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });
};
