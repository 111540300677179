import { SelectField, SelectFieldProps } from 'src/lib/ui';
import { SelectOption } from 'src/lib/ui/SelectField/SelectField';
import { Field, FieldProps, FastField } from 'formik';
import _get from 'lodash/get';

import * as React from 'react';

type FormikSelectFieldProps = Omit<SelectFieldProps, 'value'> & {
  fastField?: boolean;
};

const FormikSelectField: React.FunctionComponent<FormikSelectFieldProps> = props => {
  /**
   * When a selectField has too many options, every re-render is costly. Consider using props.fastField if neccesary.
   *
   * Field: Regular formik field.
   *
   * FastField: Performance optimalization that implements shouldComponentUpdate().
   * Will only re-render if its value is changed in the following formik objects: "values", "errors", "touched".
   * Changes in formik.isSubmitting wil also trigger re-render.
   * https://jaredpalmer.com/formik/docs/api/fastfield
   */
  const FieldType = props.fastField ? FastField : Field;

  return (
    <FieldType name={props.id}>
      {formikProps => {
        const { field, form } = formikProps as FieldProps;

        const hasError =
          Boolean(_get(form.touched, field.name)) &&
          Boolean(_get(form.errors, field.name));

        if (props.allowMultiple) {
          return (
            <SelectField
              {...props}
              allowMultiple={true}
              onChange={value => {
                // If allow multiple, return array with only values to formik.
                form.setFieldValue(
                  field.name,
                  value ? value.map(v => v.value) : undefined
                );
                if (props.onChange) {
                  // TS does not recognize that allowMultiple is true
                  const onChangeHandler = props.onChange as (
                    value?: SelectOption[] | null
                  ) => void;
                  onChangeHandler(value);
                }
              }}
              onBlur={e => {
                form.setFieldTouched(field.name, true);
                field.onBlur(e);
                if (props.onBlur) {
                  props.onBlur(e);
                }
              }}
              value={field.value || []}
              error={
                hasError ? (_get(form.errors, field.name) as string) : undefined
              }
            />
          );
        }

        return (
          <SelectField
            {...props}
            allowMultiple={false}
            onChange={value => {
              form.setFieldValue(field.name, value ? value.value : undefined);
              if (props.onChange) {
                // TS does not recognize that allowMultiple is false
                const onChangeHandler = props.onChange as (
                  value?: SelectOption | null
                ) => void;
                onChangeHandler(value);
              }
            }}
            onBlur={e => {
              form.setFieldTouched(field.name, true);
              field.onBlur(e);
              if (props.onBlur) {
                props.onBlur(e);
              }
            }}
            value={field.value || ''}
            error={
              hasError ? (_get(form.errors, field.name) as string) : undefined
            }
          />
        );
      }}
    </FieldType>
  );
};

export default FormikSelectField;
