import gql from 'graphql-tag';
import { UserFragment } from 'src/lib/types/contact/UserFragment';

export const ContactWithNotificationSettingsFragment = gql`
  fragment ContactWithNotificationSettingsFragment on Contact {
    id
    firstName
    lastName
    email
    mobilePhone
    secondaryPhone
    description
    employeeNumber
    user {
      ...UserFragment
    }
    notificationSettings {
      incident {
        type
        active
        channels
        subscriptions {
          id
          alias
          name
          type
          group
          cpe {
            status
            model
          }
          installationAddress {
            zip
            address
            city
            location {
              lat
              lon
            }
          }
        }
        notifyForAllCustomerSubscriptions
      }
      change {
        type
        active
        channels
        subscriptions {
          id
          alias
          name
          type
          group
          cpe {
            status
            model
          }
          installationAddress {
            zip
            address
            city
            location {
              lat
              lon
            }
          }
        }
        notifyForAllCustomerSubscriptions
      }
    }
  }
  ${UserFragment}
`;
