import * as React from 'react';
import { Spinner } from 'src/lib/ui';
import { useSubscription } from '../queries/useSubscription';
import { useRouter } from 'src/lib/utils/useRouter';

const Invoices = () => {
  console.log('??');
  const router = useRouter<{ id: string }>();
  const { subscription, loading } = useSubscription(router.match.params.id);
  return (
    <div className="pt-4">
      <div className="d-flex align-item-center">
        {loading && (
          <div className="pl-3">
            <Spinner />
          </div>
        )}
      </div>
      <div className="pt-5">
        <pre>{JSON.stringify(subscription, null, 2)}</pre>
      </div>
    </div>
  );
};

export default Invoices;
