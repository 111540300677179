import { useEffect, useRef } from 'react';

export const useExpandableTextArea = () => {
  const ref = useRef<HTMLTextAreaElement>(null);

  const resize = () => {
    if (ref.current) {
      const offset = ref.current.offsetHeight - ref.current.clientHeight;
      ref.current.style.height = 'auto';
      ref.current.style.height = ref.current.scrollHeight + offset + 'px';
    }
  };

  useEffect(() => {
    resize();
    if (ref.current) {
      ref.current.style.overflowY = 'hidden';
      ref.current.addEventListener('input', resize);
    }
  }, [ref.current]); // eslint-disable-line react-hooks/exhaustive-deps

  return ref;
};
