import React from 'react';
import { CpeStatus } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';
import cn from 'classnames';
import routerUnknown from 'src/images/illustrations/router-unknown.svg';
import routerNoConnection from 'src/images/illustrations/router-no-connection.svg';
import routerConnection from 'src/images/illustrations/router-connection.svg';
import './StatusIllustration.scss';

export const StatusIllustration: React.SFC<{
  subscription: {
    cpe?: null | {
      status?: CpeStatus | null;
    };
  };
  redundancies?: null | Array<{
    cpe?: null | {
      status?: CpeStatus | null;
    };
  }>;
  loading?: boolean;
}> = props => {
  const main = getStatusIllustrationAndDescription({
    status: props.subscription?.cpe?.status,
    isMain: true,
    hasRedundancy: !!props.redundancies?.length,
  });

  return (
    <div className="StatusIllustration d-none d-sm-flex  flex-column justify-content-center align-items-center ml-auto pr-5">
      <div
        className={cn(
          `StatusIllustration-${
            props.redundancies?.length && props.redundancies.length > 1
              ? 'small'
              : 'normal'
          }`,
          'd-flex align-items-center pb-3'
        )}
      >
        <img src={main.illustration} alt="main-subscription" />

        {props.redundancies?.map((r, idx) => {
          const currentR = getStatusIllustrationAndDescription({
            status: r?.cpe?.status,
            isMain: false,
            hasRedundancy: false,
          });
          return (
            <React.Fragment key={idx}>
              <div className="StatusIllustration-connected" />
              <img src={currentR.illustration} alt="redudancy" />
            </React.Fragment>
          );
        })}
      </div>
      <div className="StatusIllustration-text text-center">
        <div>
          {props.loading
            ? t.subscriptions.SubscriptionStatusSummaryCard.checkingService +
              '...'
            : main.description}
        </div>
        {!props.loading &&
          props.redundancies?.map((r, idx) => {
            const currentR = getStatusIllustrationAndDescription({
              status: r?.cpe?.status,
              isMain: false,
              hasRedundancy: false,
              redundancyNumber: idx + 1,
            });
            return <div key={idx}>{currentR.description}</div>;
          })}
      </div>
    </div>
  );
};

const getStatusIllustrationAndDescription = (props: {
  status?: CpeStatus | null;
  isMain: boolean;
  hasRedundancy: boolean;
  redundancyNumber?: number;
}) => {
  const i18n = t.subscriptions.SubscriptionStatusSummaryCard;
  const { status, isMain, hasRedundancy } = props;

  const redundancyNumberLabel =
    props.redundancyNumber && props.redundancyNumber > 1
      ? ` ${props.redundancyNumber}`
      : '';

  switch (status) {
    case CpeStatus.Critical:
      return {
        illustration: routerNoConnection,
        description: isMain
          ? !hasRedundancy
            ? i18n.serviceNoSignal
            : i18n.primary + ' ' + i18n.noSignal
          : i18n.redundancy + redundancyNumberLabel + ' ' + i18n.noSignal,
      };
    case CpeStatus.Normal:
      return {
        illustration: routerConnection,
        description: isMain
          ? !hasRedundancy
            ? i18n.serviceIsNormal
            : i18n.primary + ' ' + i18n.isNormal
          : i18n.redundancy + redundancyNumberLabel + ' ' + i18n.isNormal,
      };
    case CpeStatus.NotInstalled:
      return {
        illustration: routerUnknown,
        description: i18n.notInstalled,
      };
    default:
      return {
        illustration: routerUnknown,
        description: isMain
          ? !hasRedundancy
            ? i18n.statusUnknow
            : i18n.unknownPrimary
          : i18n.unknownRedundancy,
      };
  }
};
