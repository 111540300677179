import React, { useEffect, useState, useMemo } from 'react';
import { Fullscreen, FullscreenBody, FullscreenHeader } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import ChangelogCardGrouped from 'src/lib/ui/ChangelogCard/ChangelogCardGrouped';
import { WithChangelogs_changelogs } from 'src/areas/dev/changelog/query/__types/WithChangelogs';
import moment from 'moment';
import { useQueryUserHistoryChangelogs } from 'src/areas/main/changelog/useQueryUserHistoryChangelogs';
import { Button } from 'src/lib/ui';

const initialPageNumber = 1;
const pageSize = 10;

const ChangelogPage: React.FunctionComponent = props => {
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const changelogResult = useQueryUserHistoryChangelogs({
    page: pageNumber,
    size: pageSize,
  });
  const [changelogs, setChangelogs] = useState<WithChangelogs_changelogs[]>([]);

  function incrementPageSize() {
    setPageNumber(pageNumber + 1);
  }

  useEffect(() => {
    if (changelogResult?.data?.changelogs) {
      setChangelogs([
        ...changelogs,
        ...(changelogResult?.data?.changelogs ?? []),
      ]);
    }
  }, [changelogResult?.data?.changelogs]); // eslint-disable-line react-hooks/exhaustive-deps

  function groupChangelogsByPublishedAtDate(
    changelogsInput: WithChangelogs_changelogs[] | null | undefined
  ): { [id: string]: WithChangelogs_changelogs[] } | undefined {
    const groupedChangelogsByDate: {
      [id: string]: WithChangelogs_changelogs[];
    } = {};
    if (changelogsInput) {
      changelogsInput?.forEach(currentChangelog => {
        const currentPublishedAtDate = moment(
          currentChangelog.publishedAt,
          'YYYY-MM-DD'
        ).format('YYYY-MM-DD');
        if (groupedChangelogsByDate[currentPublishedAtDate]) {
          groupedChangelogsByDate[currentPublishedAtDate].push(
            currentChangelog
          );
        } else {
          groupedChangelogsByDate[currentPublishedAtDate] = [currentChangelog];
        }
      });
      return groupedChangelogsByDate;
    }
    return undefined;
  }
  const groupedChangelogs = useMemo(
    () => groupChangelogsByPublishedAtDate(changelogs),
    [changelogs] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Fullscreen style={{ scrollBehavior: 'smooth' }}>
      <FullscreenHeader title={t.changelogs.displayListHeader} />
      <FullscreenBody>
        <div className="ChangelogPage">
          {groupedChangelogs &&
            Object.keys(groupedChangelogs).map((key, index) => (
              <ChangelogCardGrouped
                key={index}
                changelogs={groupedChangelogs[key]}
                publishedAtDate={key}
              />
            ))}
        </div>
        {changelogs && changelogs?.length >= pageNumber * pageSize && (
          <Button
            base="primary"
            color="white"
            transparent={true}
            onClick={incrementPageSize}
          >
            {t.changelogs.toDetailedPage}
          </Button>
        )}
      </FullscreenBody>
    </Fullscreen>
  );
};

export default ChangelogPage;
