import { getSortOptions } from 'src/lib/utils/getSortOptionsByKey';
import { getSortOption } from './../utils/getSortOptionsByKey';
import { Definition } from './types';
import { IncidentColumns, formatColumns } from './index';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getIncodentColumnDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const incidentColumnMap: {
    [key in IncidentColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    status: {
      label: t.incidents.table.status,
      sortOptions: getSortOption('status', true),
      cellWidth: 230,
      fixed: true,
    },
    id: {
      label: t.incidents.table.id,
      sortOptions: getSortOption('id', true),
      cellWidth: 200,
      fixed: true,
    },
    details: {
      label: t.incidents.table.details,
      cellWidth: 180,
    },
    subscription: {
      label: t.incidents.table.subscription,
      sortOptions: getSortOptions(['subscriptionAlias', 'subscriptionName']),
      cellWidth: 230,
    },
    createdAt: {
      label: t.incidents.table.created,
      sortOptions: getSortOption('createdAt', true),
      cellWidth: 90,
    },
    updated: {
      label: t.incidents.table.updated,
      sortOptions: getSortOption('updated', true),
      cellWidth: 90,
    },
    tags: {
      label: t.subscriptions.GenericSubscriptionTable.tags,
    },
    costCenter: {
      label: t.subscriptions.GenericSubscriptionTable.costCenter,
    },
    invoiceCenter: {
      label: t.subscriptions.GenericSubscriptionTable.invoiceCenter,
    },
    solved: {
      label: t.incidents.table.solved,
      cellWidth: 90,
    },
  };

  const defaultIncidentColumns: Array<IncidentColumns> = [
    'status',
    'id',
    'details',
    'subscription',
    'createdAt',
    'updated',
  ];

  return formatColumns({
    map: incidentColumnMap,
    defaultColumns: defaultIncidentColumns,
    savedColumnPreferences,
  });
};
