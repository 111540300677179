import { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/usePingRoundTrip';

const GET_SUBSCRIPTION_ROUND_TRIP = gql`
  query usePingRoundTrip($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        cpe {
          ping {
            roundTripTime
            status
          }
        }
      }
    }
  }
`;

interface PingStatus {
  roundTripTime: number;
  status: string;
}

const usePingRoundTrip = () => {
  const customer = useCustomer();
  const client = useApolloClient();

  const ping = async (subscriptionId: string, count: number) => {
    const roundTripTimes: PingStatus[] = [];
    for (let i = 0; i < count; i++) {
      const res = await client.query<
        t.usePingRoundTrip,
        t.usePingRoundTripVariables
      >({
        query: GET_SUBSCRIPTION_ROUND_TRIP,
        variables: {
          customerId: customer.id,
          subscriptionId,
        },
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      });
      const pingRes = res.data?.customer?.subscriptions?.[0]?.cpe?.ping;
      if (pingRes) {
        roundTripTimes.push(pingRes);
      }
    }
    return {
      [subscriptionId]: roundTripTimes,
    };
  };
  return {
    ping,
  };
};

export const usePingRoundTripTimeMultipleSubs = (
  subscriptionIds: Array<{ id: string }>
) => {
  const { ping } = usePingRoundTrip();
  const [loading, setLoading] = useState<boolean>(false);
  const [roundTripTimes, setRoundTripTimes] = useState<{
    [key: string]: Array<PingStatus>;
  }>({});

  const pingAll = async (count: number = 5) => {
    setLoading(true);
    setRoundTripTimes({});
    const allResults = await Promise.all(
      subscriptionIds.map(sub => ping(sub.id, count))
    );

    let obj = {};
    allResults.forEach(res => {
      obj = {
        ...obj,
        ...res,
      };
    });
    setRoundTripTimes(obj);
    setLoading(false);
  };

  useEffect(() => {
    pingAll(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    loading,
    pingAll,
    roundTripTimes,
  };
};

// export const usePingRoundTrip = (subscriptionId?: string) => {
//   const customer = useCustomer();
//   const [roundTripTimes, setRoundTripTimes] = useState<PingStatus[]>([]);
//   useEffect(() => setRoundTripTimes([]), [subscriptionId]);

//   const { data, loading, refetch } = useQuery<
//     t.usePingRoundTrip,
//     t.usePingRoundTripVariables
//   >(GET_SUBSCRIPTION_ROUND_TRIP, {
//     skip: !subscriptionId,
//     variables: {
//       customerId: customer.id,
//       subscriptionId: subscriptionId,
//     },
//     notifyOnNetworkStatusChange: true,
//     fetchPolicy: 'network-only',
//     errorPolicy: 'all',
//   });

//   useEffect(() => {
//     const ping = data?.customer?.subscriptions?.[0]?.cpe?.ping;
//     if (!loading && ping) {
//       setRoundTripTimes(prevState => [...prevState, { ...ping }]);
//     }
//   }, [data, loading]);

//   const ping = async () => {
//     if (subscriptionId) {
//       setRoundTripTimes([]);
//       const maxPings = 5;

//       for (let i = 0; i < maxPings; i++) {
//         await refetch();
//       }
//     }
//   };

//   return { ping, data, loading, roundTripTimes };
// };
