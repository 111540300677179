import React from 'react';
import { Item, ItemListPicker } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useAllCallerIdsForSubscription } from 'src/areas/main/subscriptions/queries/useAllCallerIdsForSubscription';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { useSetCallerIdConfiguration } from 'src/areas/mobile/settings/mutations/useSetCallerIdConfiguration';

interface Props {
  callerIds: Item[];
  isOpen: boolean;
  toggle: () => void;
  msisdn: string;
  updateCallerIds?: (callerIdList: string[]) => void;
}

const CallerIdFormModal: React.FC<Props> = props => {
  const subscriptionContext = useSubscriptionContext();
  const allCallerIdsItems = useAllCallerIdsForSubscription(props.msisdn)
    .allCallerIds?.filter(e => e !== props.msisdn)
    .map(value => ({ id: value, label: value } as Item));
  const { setCallerIdConfiguration } = useSetCallerIdConfiguration();
  const saveFunction = async (selectedItems: Item[]) => {
    const allowedCallerIdList = selectedItems.flatMap(item => item.label);
    await setCallerIdConfiguration(subscriptionContext.customerId, {
      subscriptionId: props.msisdn,
      allowedCallerIdList: allowedCallerIdList,
    });
    if (props.updateCallerIds) {
      props.updateCallerIds(allowedCallerIdList);
    }
  };
  const i18n = t.mobile.settings.callerId.modal;
  return (
    <ItemListPicker
      isOpen={props.isOpen}
      availableItems={allCallerIdsItems}
      heading={i18n.header}
      toggleState={props.toggle}
      toggleOnSave={true}
      disableAddNew={true}
      singleSelect={false}
      filterPlaceholder={i18n.filterPlaceholder}
      onSave={selectedItems => {
        saveFunction(selectedItems);
      }}
      subHeader={i18n.description}
      initialSelectedItems={props.callerIds}
      selectedItemsSubHeader={i18n.selectedItemsSubHeader}
      availableItemsSubHeader={i18n.availableItemsSubHeader}
    />
  );
};
export default CallerIdFormModal;
