import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

export const SERVICE_PLUS_ADDRESS_QUERY = gql`
  query useServicePlusAddress($customerId: ID!, $addressId: ID!) {
    customer(id: $customerId) {
      id
      servicePlusAddress(id: $addressId) {
        id
        address
        postalCode
        city
        createdAt
        createdBy
        customerId
        organisationNumber
        creatorName
        creatorEmail
        creatorPhone
        __typename
      }
      __typename
    }
    __typename
  }
`;

export const useServicePlusAddress = (customerId, addressId) => {
  const {
    loading: servicePlusLocationLoading,
    error: servicePlusLocationError,
    data: servicePlusLocationData,
  } = useQuery(SERVICE_PLUS_ADDRESS_QUERY, {
    variables: { customerId: customerId, addressId: addressId },
  });

  return {
    servicePlusLocationLoading,
    servicePlusLocationError,
    servicePlusLocationData,
  };
};
