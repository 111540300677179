import * as React from 'react';
import { Query, QueryResult, useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import * as types from './__types/CustomerAccessQuery';

export const customerAccessQuery = gql`
  query CustomerAccessQuery($customerId: ID!) {
    me {
      id
      cannyToken
      contact(customerId: $customerId) {
        id
        firstName
        lastName
        email
        mobilePhone
      }
      featureFlags(customerId: $customerId) {
        flag
        value
      }
      customerAccess(customerId: $customerId) {
        customer {
          id
          name
          flexFields {
            id
            label
            options {
              option
              description
            }
          }
          permissions {
            mobileOrders {
              standardMobileSubscriptions
              m2mMobileSubscriptions
            }
          }
        }
        roles {
          role
          organisation {
            id
            name
          }
          tag {
            id
            tag
          }
        }
        areas
      }
    }
  }
`;

interface Props {
  customerId?: string | undefined;
  children: (
    res:
      | undefined
      | QueryResult<
          types.CustomerAccessQuery,
          types.CustomerAccessQueryVariables
        >
  ) => JSX.Element | null;
}

export function useCustomerAccessQuery(customer: undefined | { id: string }) {
  return useQuery<
    types.CustomerAccessQuery,
    types.CustomerAccessQueryVariables
  >(customerAccessQuery, {
    errorPolicy: 'all',
    variables: {
      // The empty string is to satisfy TS -- a query
      // with '' will never be run due to the `skip` option below.
      customerId: customer ? customer.id : '',
    },
    skip: !customer,
  });
}

export const CustomerAccessQuery: React.FC<Props> = props => {
  if (!props.customerId) {
    return <>{props.children(undefined)}</>;
  }
  return (
    <Query<types.CustomerAccessQuery, types.CustomerAccessQueryVariables>
      query={customerAccessQuery}
      variables={{
        customerId: props.customerId,
      }}
      errorPolicy="all"
    >
      {res => props.children(res)}
    </Query>
  );
};
