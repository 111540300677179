export function mapNullableBooleanObject<
  T extends { [key: string]: boolean | null }
>(object: T): { [key in keyof T]: boolean } {
  return Object.assign(
    {},
    ...Object.keys(object).map(key => ({
      [key]: !!object[key],
    }))
  ) as {
    [key in keyof T]: boolean;
  };
}
