const cases = {
  cases: 'Cases',
  sort: 'Sort by',
  createdNewest: 'Created newest first',
  createdOldest: 'Created oldest first',
  updated: 'Updated at',
  incident: 'Incident',
  plannedWork: 'Planned Work',
  active: 'Active',
  closed: 'Closed',
  reset: 'Reset',
  use: 'Use',
};

export default cases;
