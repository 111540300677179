export const formatName = (params: {
  firstName?: string | null;
  lastName?: string | null;
}): string => {
  let name = '';
  if (params.firstName) {
    name = params.firstName;
  }

  if (params.lastName) {
    if (name.length > 0) {
      name = name + ' ';
    }
    name = name + params.lastName;
  }

  return name;
};
