import * as React from 'react';
import ProgressIndicator, {
  ProgressIndicatorStep,
} from '../ProgressIndicator/ProgressIndicator';
import './StatusProgressCard.scss';

export type StatusProgressCardSteps = {
  subTitle?: string;
  description?: string | React.ReactNode;
} & ProgressIndicatorStep;

type StatusProgressCardProps = {
  steps: StatusProgressCardSteps[];
  currentStep: number;
  className?: string;
} & (
  | {
      hasFailed: boolean;
      failedStep: StatusProgressCardSteps;
    }
  | {}
);
export const StatusProgressCard = (props: StatusProgressCardProps) => {
  const currentStep =
    'hasFailed' in props && props.hasFailed
      ? props.failedStep
      : props.steps[props.currentStep];
  return (
    <div className={props.className}>
      <div className="StatusProgressCard-header">Status</div>
      <div className="mb-4">
        <div className="StatusProgressCard-title">{currentStep.step}</div>
        {currentStep.subTitle && (
          <small className="StatusProgressCard-subTitle">
            {currentStep.subTitle}
          </small>
        )}
      </div>
      {!('hasFailed' in props && props.hasFailed) && (
        <ProgressIndicator
          steps={props.steps}
          currentStep={props.currentStep}
        />
      )}
      {currentStep.description ? (
        <div className="mt-4">{currentStep.description}</div>
      ) : null}
    </div>
  );
};
