export const getGroupLabel = (group: string) => {
  switch (group) {
    case 'data':
      return 'Data';
    case 'mobile':
      return 'Mobil';
    case 'tele':
      return 'Telefoni';
    default:
      return 'Annet';
  }
};
