import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useGenerateReportFixedSubscriptionOverview';
import { useCustomer } from 'src/lib/global';

export const GENERATE_FIXED_REPORT_FOR_SUBSCRIPTION_OVERVIEW = gql`
  mutation useGenerateReportFixedSubscriptionOverview(
    $customerId: ID!
    $organisationId: ID
  ) {
    generateReportFixedSubscriptionOverview(
      input: { customerId: $customerId, organisationId: $organisationId }
    ) {
      url
      filename
    }
  }
`;

export const useFixedReportForSubscriptionOverview = () => {
  const customer = useCustomer();

  const [mutation, { loading, error }] = useMutation<
    t.useGenerateReportFixedSubscriptionOverview,
    t.useGenerateReportFixedSubscriptionOverviewVariables & {
      customerId: string;
    }
  >(GENERATE_FIXED_REPORT_FOR_SUBSCRIPTION_OVERVIEW);

  const generate = async (organisationId?: string) =>
    await mutation({
      variables: {
        customerId: customer.id,
        organisationId,
      },
    });

  return {
    generate,
    loading,
    error,
  };
};
