import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { SortOrder } from 'src/__types/graphql-global-types';
import { IncidentFragment } from 'src/lib/types/incident/IncidentFragment';
import { castArray } from 'lodash';
import * as t from './__types/useSearchIncidents';
import {
  unwrapWrappedSearchResults,
  WrappedSearchResult,
} from 'src/areas/main/search';

const QUERY_INCIDENTS = gql`
  query useSearchIncidents($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        totalResults
        results {
          __typename
          ... on IncidentSearchResult {
            incident {
              ...IncidentSearchResult
            }
          }
        }
      }
    }
  }
  ${IncidentFragment}
`;

export function useSearchIncidents(search: {
  sortBy?: string;
  sortOrder?: SortOrder;
  query?: string;
  filters?: Array<{ filter: string; value: string | string[] }>;
  size?: number;
  page?: number;
}) {
  const customer = useCustomer();
  const searchFilter = search.filters?.map(f => ({
    filter: f.filter,
    value: castArray(f.value),
  }));
  const { data, error, loading } = useQuery<
    t.useSearchIncidents,
    t.useSearchIncidentsVariables
  >(QUERY_INCIDENTS, {
    variables: {
      customerId: customer.id,
      search: {
        types: ['incident'],
        query: search.query ?? '',
        sortBy: search.sortBy,
        filters: searchFilter,
        sortOrder: search.sortOrder,
        size: search.size ?? 1000000,
        page: search.page,
      },
    },
    errorPolicy: 'all',
  });

  return {
    incidents: (data?.customer?.search?.results
      ? unwrapWrappedSearchResults(
          data.customer.search.results as WrappedSearchResult[]
        )
      : []) as t.useSearchIncidents_customer_search_results_IncidentSearchResult_incident[],
    totalResults: data?.customer?.search?.totalResults,
    loading,
    error,
  };
}
