import * as React from 'react';
import { useState } from 'react';
import { Button, colors, ListResultCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { formatDate } from 'src/lib/utils';
import * as types from 'src/areas/main/subscriptions/queries/__types/useSearchCases';
import Modal from 'reactstrap/lib/Modal';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { getHoursAndMinutesFromMinutes } from 'src/areas/main/subscriptions/utils/subscriptionUtils';

interface Props {
  renderAs?: 'div' | 'li';
  entry: types.useSearchCases_customer_search_results_PlannedWorkSearchResult_plannedWork;
}

const formatHoursAndMinutesToString = (hours: number, minutes: number) => {
  return (
    (hours ? hours + ' ' + t.ui.times.hours + (minutes ? ', ' : '') : '') +
    (minutes ? minutes + ' ' + t.ui.times.minutes : '')
  );
};

export const SearchListPlannedWorkResult: React.FC<Props> = ({
  entry,
  renderAs,
}) => {
  const translate = t.search.SearchListPlannedWorkResult;
  const modalTranslate = t.subscriptions.SubscriptionPlannedWorkCard;
  const startDate = formatDate(entry.dates.plannedStartDate, 'shortWithTime');
  const endDate = formatDate(entry.dates.plannedEndDate, 'shortWithTime');
  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);
  const [downtimeHours, downtimeMinutes] =
    entry.expectedTotalDowntimeInMinutes !== undefined &&
    entry.expectedTotalDowntimeInMinutes !== null
      ? getHoursAndMinutesFromMinutes(entry.expectedTotalDowntimeInMinutes)
      : [null, null];
  const hoursAndMinutesString =
    downtimeHours !== null && downtimeMinutes !== null
      ? formatHoursAndMinutesToString(downtimeHours, downtimeMinutes)
      : '';
  return (
    <>
      <ListResultCard
        renderAs={renderAs}
        className="ListResultCard--Incident"
        icon={entry.active ? 'time-waiting' : 'check-mark'}
        onClick={() => setModalIsOpen(!modalIsOpen)}
        iconDescription={entry.active ? translate.active : translate.closed}
        title={`${translate.title} ${startDate} - ${endDate}`}
        attributes={[
          entry.serviceImpact
            ? {
                label: translate.expectedDowntime,
                value: entry.expectedTotalDowntimeInMinutes + ' min',
              }
            : undefined,

          entry.description
            ? {
                label: translate.opened,
                value: entry.dates.createdAt,
              }
            : undefined,

          entry.state
            ? {
                label: translate.lastUpdated,
                value: entry.dates.updatedAt,
              }
            : undefined,
        ]}
      />
      <Modal
        isOpen={modalIsOpen}
        toggle={() => setModalIsOpen(!modalIsOpen)}
        centered={true}
      >
        <ModalHeader>
          {translate.title} {formatDate(entry.dates.plannedStartDate, 'short')}
        </ModalHeader>
        <ModalBody>
          <div className="d-inline" style={{ color: colors.greyDarkText }}>
            {modalTranslate.status}:{'  '}
          </div>
          {entry.state}
          <br />
          <br />
          <div className="d-inline" style={{ color: colors.greyDarkText }}>
            {modalTranslate.reference}:{'  '}
          </div>
          {entry.changeNumber}
          <br />
          <br />
          <div className="d-inline" style={{ color: colors.greyDarkText }}>
            {modalTranslate.startTime}:{'  '}
          </div>
          {formatDate(entry.dates.plannedStartDate, 'shortWithTime')}
          <br />
          <br />
          <div className="d-inline" style={{ color: colors.greyDarkText }}>
            {modalTranslate.endTime}:{'  '}
          </div>
          {formatDate(entry.dates.plannedEndDate, 'shortWithTime')}
          <br />
          <br />
          <div className="d-inline" style={{ color: colors.greyDarkText }}>
            {modalTranslate.expectedDowntime}:{'  '}
          </div>
          {hoursAndMinutesString}
          <br />
          <br />
          <div className="d-inline" style={{ color: colors.greyDarkText }}>
            {modalTranslate.details}:{'  '}
          </div>
          {entry.description}
          <br />
        </ModalBody>
        <ModalFooter>
          <Button
            color="purple"
            base="primary"
            onClick={() => setModalIsOpen(!modalIsOpen)}
          >
            {modalTranslate.close}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};
