import React, { useRef, useEffect } from 'react';
import generateUeid from 'src/lib/utils/generateUeid';
import cs from 'classnames';
import './RadioButton.scss';

interface Props {
  id?: string;
  name?: string;
  label?: string;
  value: string | number | boolean;
  checked: boolean;
  disabled?: boolean;
  className?: string;
  divRef?: React.RefObject<HTMLDivElement>;
  description?: string;
  // 'aria-labelledby'?: string;
  onChange?: (value: any) => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const RadioButton: React.FC<Props> = props => {
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldGuid = useRef<string>(generateUeid());

  /**
   * Create a new unique id for component if a new label is passed
   */
  useEffect(() => {
    if (props.label) {
      fieldGuid.current = generateUeid();
    }
  }, [props.label]);

  function handleClick(e: React.MouseEvent<HTMLDivElement>) {
    if (inputRef && inputRef.current) {
      e.preventDefault();
      inputRef.current.focus();
      inputRef.current.click();
    }
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (props.onChange) {
      props.onChange(props.value);
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.onFocus) {
      props.onFocus();
    }
  };

  const handleBlur = (event: React.FocusEvent<HTMLInputElement>) => {
    if (props.onBlur) {
      props.onBlur();
    }
  };

  let inputValue;
  if (typeof props.value === 'boolean') {
    inputValue = props.value ? 'true' : 'false';
  } else {
    inputValue = props.value;
  }
  return (
    <div
      id={props.id}
      className={cs(
        'RadioButton',
        {
          checked: !!props.checked,
          disabled: !!props.disabled,
        },
        props.className
      )}
      ref={props.divRef}
    >
      <div className="RadioButton-content">
        <input
          id={fieldGuid.current ? fieldGuid.current : undefined}
          ref={inputRef}
          name={props.name}
          value={inputValue}
          checked={props.checked}
          disabled={props.disabled}
          type="radio"
          onChange={handleChange}
          onFocus={handleFocus}
          onBlur={handleBlur}
          className="RadioButton-screenreader-only"
        />

        <div className="RadioButton-outer" onClick={handleClick}>
          <div className="RadioButton-inner" />
        </div>
        {props.label && (
          <label
            className="RadioButton-label"
            htmlFor={fieldGuid.current ? fieldGuid.current : undefined}
          >
            {props.label}
          </label>
        )}
      </div>
      {props.description && (
        <div className="RadioButton-description">{props.description}</div>
      )}
    </div>
  );
};

export { RadioButton };
