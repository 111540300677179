import { Organisation } from '../types';
import { findIndex } from 'lodash';

/**
 * Find the root organisation, build an ID map and a tree structure.
 * This mutates the organisations with an extra `childOrganisations` field.
 */
export function organiseOrganisations(
  organisations: Organisation[],
  rootOrganisation?: Organisation | undefined
) {
  let root: Organisation | undefined = rootOrganisation;
  const list = [...organisations];

  // If root organisation is already in the list, replace it with the root org.
  if (rootOrganisation !== undefined) {
    const rootIndex = rootOrganisation
      ? findIndex(list, (org: Organisation) => {
          return org.id === rootOrganisation.id;
        })
      : -1;
    if (rootIndex > -1) {
      list[rootIndex] = rootOrganisation;
    } else {
      list.push(rootOrganisation);
    }
  }
  const indexById: { [id: string]: number } = {};
  const organisationById: { [id: string]: Organisation } = {};
  let i: number;
  const len = list.length;

  for (i = 0; i < len; i += 1) {
    indexById[list[i].id] = i;
    const newOrg = {
      ...list[i],
      childOrganisations: [],
    };
    organisationById[list[i].id] = newOrg;
    if (!list[i].parentId) {
      root = newOrg;
    }
  }

  for (i = 0; i < list.length; i += 1) {
    const node = list[i];
    // Ignore missing children
    if (node.parentId && organisationById[node.parentId]) {
      organisationById[node.parentId].childOrganisations.push(
        organisationById[node.id]
      );
    }
  }

  return {
    organisationById,
    rootOrganisation: root,
  };
}
