import * as React from 'react';
import { MobilePeriodDetails } from 'src/areas/mobile/types';
import { StepsMeta, StepChart } from 'src/lib/charts';
import { Attributes, Attribute } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';

interface Props {
  subscription: MobilePeriodDetails;
  stepsMeta: StepsMeta;
}

const SummaryStepModel: React.SFC<Props> = ({ subscription, stepsMeta }) => {
  const i18n = t.mobile.overview.subscription;
  const { id } = useSubscriptionContext();
  return (
    <>
      <div className="py-3">
        <Attributes>
          <Attribute label={i18n.name} value={subscription.name} />
          <Attribute label={i18n.phoneNumber} value={id} />

          <Attribute
            label={i18n.pricePerMB}
            value={`${i18n.stepPriceNow} ${
              stepsMeta.steps[stepsMeta.currentStep].cost
            } kr/MB`}
          />
          <Attribute
            label={i18n.step}
            value={`${stepsMeta.currentStep + 1} / ${stepsMeta.steps.length}`}
          />
        </Attributes>
      </div>
      <div className="py-4">
        <StepChart stepsMeta={stepsMeta} />
      </div>
    </>
  );
};
export default SummaryStepModel;
