import { useEffect, useState } from 'react';

/**
 * This might be replaced by React.Suspense with it is fully released.
 *
 * Purpose: Loading states can be unpredictable.
 * For the UI to become more predictable, we delay the rendering of the content by a few milliseconds.
 * By doing this, we can show the loading state in the delay time to avoid flickering views when loading state changes quickly.
 */

export const useDelayedRender = (
  delay: number,
  loading: boolean | null | undefined
) => {
  const [delayed, setDelayed] = useState<boolean>(false);

  useEffect(() => {
    if (loading === undefined || loading === null) {
      return;
    }

    if (loading) {
      // If loading has started, start delay.
      setDelayed(true);
    } else {
      // start delayed render when loading is set to "false", start delayed rendering
      const timeout = setTimeout(() => setDelayed(false), delay);
      return () => clearTimeout(timeout);
    }
  }, [loading, delay]);

  return delayed;
};
