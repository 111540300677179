import { OriginDetails } from 'src/areas/mobile/types';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { SUBSCRIPTION_ORIGIN_DETAILS } from 'src/areas/mobile/lib/useSubscriptionOriginDetails';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';

const UPDATE_SUBSCRIPTION_ORIGIN = gql`
  mutation useUpdateSubscriptionOrigin($input: UpdateMobileOriginInput) {
    updateMobileOrigin(input: $input) {
      id
      origin {
        firstName
        middleName
        lastName
        streetAddress
        streetNum
        apartmentNum
        floor
        gnr
        fnr
        knr
        postCode
        postArea
        country
      }
    }
  }
`;

interface Variables {
  input: {
    id: string;
  } & OriginDetails;
}
interface Data {
  updateMobileOrigin: {
    id: string;
    origin: OriginDetails;
  };
}

export interface UpdateSubscriptionOriginResult {
  updateOrigin: (origin: OriginDetails, optimisticResponse?: any) => void;
  data?: Data;
  loading: boolean;
  error: boolean;
}
export const useUpdateSubscriptionOrigin = (): UpdateSubscriptionOriginResult => {
  const subscription = useSubscriptionContext();
  const [mutation, { loading, error, data }] = useMutation<Data, Variables>(
    UPDATE_SUBSCRIPTION_ORIGIN,
    {
      update: (cache, res) => {
        if (
          res.data &&
          res.data.updateMobileOrigin &&
          res.data.updateMobileOrigin.origin
        ) {
          const cached = cache.readQuery({
            query: SUBSCRIPTION_ORIGIN_DETAILS,
            variables: { id: subscription.id },
          });
          (cached as any).subscription.origin =
            res.data.updateMobileOrigin.origin;
          cache.writeQuery({
            query: SUBSCRIPTION_ORIGIN_DETAILS,
            variables: { id: subscription.id },
            data: cached,
          });
        }
      },
    }
  );

  const updateOrigin = (
    originDetails: OriginDetails,
    optimisticResponse?: any
  ) => {
    mutation({
      variables: {
        input: {
          id: subscription.id,
          ...originDetails,
        },
      },
      optimisticResponse,
    });
  };
  const mutationsResult: UpdateSubscriptionOriginResult = {
    updateOrigin,
    error: !!error,
    loading,
    data,
  };
  return mutationsResult;
};
