import React from 'react';
import { TextFieldProps, TextField } from './TextField';
import { SearchResult } from 'src/areas/main/search';

import SearchMenuSimple from 'src/areas/main/search/components/SearchMenuSimple';
import { useCombobox } from 'downshift';
import { generateUeid } from 'src/lib/utils';

export const TextFieldWithSearch: React.FC<TextFieldProps & {
  search: {
    results: SearchResult[];
    loading: boolean;
    totalResults?: number;
    setQuery: (value: string) => void;
  };
  onSelect: (item: SearchResult) => void;
}> = props => {
  const downshift = useCombobox({
    items: props.search.results,
    onInputValueChange: changes => {
      if (!changes.selectedItem) {
        props.search.setQuery(changes.inputValue || '');
      }
    },
    onSelectedItemChange: changes => {
      if (changes.selectedItem) {
        props.onSelect(changes.selectedItem);
      }
    },
  });

  return (
    <div className="position-relative w-50" {...downshift.getComboboxProps()}>
      <div className="d-flex">
        <TextField
          inputProps={downshift.getInputProps}
          autoComplete={generateUeid()}
          {...props}
        />
      </div>
      <SearchMenuSimple
        getMenuProps={downshift.getMenuProps}
        getItemProps={downshift.getItemProps}
        isOpen={downshift.isOpen && !!props.search.results.length}
        inputValue={downshift.inputValue}
        highlightedIndex={downshift.highlightedIndex}
        selectedItem={downshift.selectedItem}
        results={props.search.results}
        loading={props.search.loading}
        totalResults={props.search.totalResults}
        totalResultsByDomain={undefined}
      />
    </div>
  );
};
