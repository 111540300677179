import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

export const SERVICE_PLUS_LOCATIONS_QUERY = gql`
  query useServicePlusLocations($customerId: ID!) {
    customer(id: $customerId) {
      id
      servicePlusAddresses {
        id
        address
        postalCode
        city
        createdAt
        createdBy
        customerId
        organisationNumber
        creatorName
        creatorEmail
        creatorPhone
        __typename
      }
      __typename
    }
    __typename
  }
`;

export const useServicePlus = customerId => {
  const {
    loading: servicePlusLocationsLoading,
    error: servicePlusLocationsError,
    data: servicePlusLocationsData,
  } = useQuery(SERVICE_PLUS_LOCATIONS_QUERY, {
    variables: { customerId: customerId },
  });

  return {
    servicePlusLocationsLoading,
    servicePlusLocationsError,
    servicePlusLocationsData,
  };
};
