import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import useStructureCacheUpdater from 'src/areas/main/documents/lib/useStructureCacheUpdater';
import { FolderFragment } from 'src/lib/types';
import * as t from './__types/useRestoreFolder';

const MUTATION_RESTORE_FOLDER = gql`
  mutation useRestoreFolder($input: RestoreFolderInput) {
    restoreFolder(input: $input) {
      folder {
        ...FolderFragment
      }
    }
  }
  ${FolderFragment}
`;

interface RestoreFolderResult {
  restoreFolder: (id: string, cb?: () => void) => Promise<void>;
  loading: boolean;
}

export const useRestoreFolder = (): RestoreFolderResult => {
  const cacheUpdater = useStructureCacheUpdater<t.useRestoreFolder>();

  const [mutation, { loading }] = useMutation<
    t.useRestoreFolder,
    t.useRestoreFolderVariables
  >(MUTATION_RESTORE_FOLDER);
  const restoreFolder = async (id, cb) => {
    await mutation({
      variables: {
        input: { id },
      },
      update: cacheUpdater((ff, data) => ({
        files: ff.files,
        folders: data.restoreFolder?.folder
          ? [...ff.folders, data.restoreFolder.folder]
          : ff.folders,
      })),
    });
    if (cb) {
      cb();
    }
  };

  return { restoreFolder, loading };
};
