import React, { useState } from 'react';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { Button } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';

export const SelectContactDropdown: React.FC<{
  activeContactName: string;
}> = props => {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <Dropdown isOpen={isOpen} toggle={() => setIsOpen(!isOpen)}>
      <DropdownToggle tag="span" className="d-inline-block w-100">
        <Button
          outline={true}
          type="button"
          className="d-flex align-items-center w-100"
        >
          {props.activeContactName || 'Velg person'}
          <Icon
            className="ml-auto"
            icon={isOpen ? 'arrow-small-up' : 'arrow-small-down'}
            style={{ height: '1rem', width: '1rem' }}
          />
        </Button>
      </DropdownToggle>

      <DropdownMenu className="py-0 m-0 w-100" style={{ border: 'none' }}>
        {props.children}
      </DropdownMenu>
    </Dropdown>
  );
};
