import React from 'react';

import useDashboardData from './queries/useDashboardData';
import DashboardKpiCard from './DashboardKpiCard';
import { urlFor } from 'src/lib/ui';
import { IncidentStatus, CpeStatus } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';
import { IconDefinition } from '@telia/styleguide';
import { activeIncidentText } from 'src/areas/main/dashboard/activeIncidentText';
import { useFeature } from 'src/lib/utils';

type DashboardKpi = {
  icon: IconDefinition;
  color: 'red' | 'green' | 'purple' | 'yellow';
  value: number | string | undefined | null;
  label: string;
  linkTo: string;
};

export function DashboardGrid() {
  const data = useDashboardData();
  const hasPlannedWorkPlacementFeature = useFeature('planned-work-placement');
  const plannedWorkKpi: DashboardKpi[] = !hasPlannedWorkPlacementFeature
    ? [
        {
          icon: 'wrench',
          color: 'yellow',
          value: data.servicesWithPlannedWork,
          label: t.dashboard.DashboardGrid.servicesWithPlannedWork(
            data.servicesWithPlannedWork
          ),
          linkTo: urlFor({
            area: 'subscriptions',
            cases: 'withPlannedWork',
          }),
        },
      ]
    : [];

  const useNewActiveIncidents = useFeature('new-active-incidents');
  const activeSolvedIncidents: DashboardKpi[] = useNewActiveIncidents
    ? [
        {
          icon: 'check-mark',
          color: 'green',
          value: data.activeSolvedIncidents,
          label: t.dashboard.DashboardGrid.activeSolvedIncidents(
            data.activeSolvedIncidents
          ),
          linkTo: urlFor({
            area: 'incidents',
            incidentStatus: IncidentStatus.solved,
          }),
        },
      ]
    : [];

  const kpis: DashboardKpi[] = [
    {
      icon: 'alert' as IconDefinition,
      color: 'red',
      value: data.subscriptionsWithUnreportedFaults,
      label: t.dashboard.DashboardGrid.servicesWithUnreportedIncidents(
        data.subscriptionsWithUnreportedFaults
      ),
      linkTo: urlFor({
        area: 'subscriptions',
        status: CpeStatus.Critical,
        cases: 'withoutIncidents',
        group: 'nettverk',
      }),
    },

    {
      icon: 'chat',
      color: 'red',
      value: data.incidentsOnHoldPendingCustomer,
      label: t.dashboard.DashboardGrid.incidentsWaitingForClient(
        data.incidentsOnHoldPendingCustomer
      ),
      linkTo: urlFor({
        area: 'incidents',
        incidentStatus: IncidentStatus.onHoldPendingCustomer,
      }),
    },

    {
      icon: 'document',
      color: 'red',
      value: data.overdueInvoices,
      label: t.dashboard.DashboardGrid.overdueInvoices(data.overdueInvoices),
      linkTo: urlFor({
        area: 'invoices',
        invoiceStatus: 'overdue',
      }),
    },

    {
      icon: 'shoppingcart',
      color: 'purple',
      value: data.activeOrders,
      label: t.dashboard.DashboardGrid.activeOrders(data.activeOrders),
      linkTo: urlFor({
        area: 'orders',
      }),
    },

    {
      icon: 'chat',
      color: 'purple',
      value: activeIncidentText(data, useNewActiveIncidents),
      label: t.dashboard.DashboardGrid.activeIncidents(data.activeIncidents),
      linkTo: urlFor({
        area: 'incidents',
        incidentStatus: useNewActiveIncidents
          ? [
              IncidentStatus.onHoldPendingTelia,
              IncidentStatus.onHoldPendingCustomer,
            ]
          : [],
      }),
    },
    ...plannedWorkKpi,
    ...activeSolvedIncidents,
  ];

  return (
    <div className="DashboardGrid">
      <div className="row">
        {kpis.map(kpi => (
          <div className="col-lg-4 col-md-6 mb-3" key={kpi.linkTo}>
            <DashboardKpiCard
              icon={kpi.icon}
              color={kpi.color}
              loading={data.loading}
              value={kpi.value}
              label={kpi.label}
              linkTo={kpi.linkTo}
            />
          </div>
        ))}
      </div>
    </div>
  );
}
