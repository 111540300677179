import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { ContactFragment } from 'src/lib/types';
import * as t from './__types/useSearchContacts';
import { SearchFilter, SortOrder } from 'src/__types/graphql-global-types';
export const QUERY_CONTACTS = gql`
  query useSearchContacts($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        results {
          ... on ContactSearchResult {
            contact {
              ...ContactSearchResult
            }
          }
        }
      }
    }
  }
  ${ContactFragment}
`;

export const useSearchContacts = (search: {
  query?: string;
  size?: number;
  sortOrder?: SortOrder | null;
  sortBy?: string;
  filter?: SearchFilter[] | undefined;
}) => {
  const customer = useCustomer();
  const { data, loading, error } = useQuery<
    t.useSearchContacts,
    t.useSearchContactsVariables
  >(QUERY_CONTACTS, {
    variables: {
      customerId: customer.id,
      search: {
        types: ['contact'],
        size: search.size ?? 20,
        query: search.query ?? '',
        sortOrder: search.sortOrder,
        sortBy: search.sortBy,
        filters: search.filter,
      },
    },
  });

  return {
    contacts: data?.customer?.search?.results
      ?.map(c =>
        c.__typename === 'ContactSearchResult' ? c.contact : undefined
      )
      .filter(isContact),
    loading,
    error,
  };
};

function isContact(
  entry:
    | t.useSearchContacts_customer_search_results_ContactSearchResult_contact
    | undefined
): entry is t.useSearchContacts_customer_search_results_ContactSearchResult_contact {
  return !!entry;
}
