import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { WithCustomerContext } from 'src/lib/global';
import { SubscriptionTaxModel } from 'src/__types/graphql-global-types';
import {
  MobileOrderDetails,
  NewSimCardRecipient,
  PersonalDetails,
} from '../types';

import { MobileOrderDetailsFragment } from '../lib/MobileOrderDetailsFragment';

const MUTATION_PORT_MOBILE_SUBSCRIPTION_ORDER = gql`
  mutation portMobileSubscriptionOrder(
    $input: PortMobileSubscriptionOrderInput
  ) {
    portMobileSubscriptionOrder(input: $input) {
      orderDetails {
        ... on MobileOrderDetails {
          ...MobileOrderDetailsResult
        }
      }
    }
  }
  ${MobileOrderDetailsFragment}
`;

export interface PortMobileSubscriptionOrderInput {
  activationDate?: string;
  costCenterId?: string;
  organisationId: string;
  phoneNumber: string;
  simCardId?: string;
  newSimCardRecipient?: NewSimCardRecipient;
  personalDetails: PersonalDetails;
  formerOwner: {
    businessName?: string;
    organisationNumber?: string;
    firstName?: string;
    lastName?: string;
    birthDate?: string;
  };
  taxModel: SubscriptionTaxModel;
  products: string[];
  transferPaymentsToNewOwner: boolean;
  flex1?: string;
  flex2?: string;
  flex3?: string;
  flex4?: string;
}

interface Variables {
  input: PortMobileSubscriptionOrderInput & { customerId: string };
}

interface Props {
  children: (res: PortMobileSubscriptionOrderResult) => JSX.Element | null;
}

export interface PortMobileSubscriptionOrderResult {
  portMobileSubscriptionOrder: (
    input: PortMobileSubscriptionOrderInput
  ) => void;
  loading: boolean;
  error?: ApolloError;
  data: any;
}

interface Data {
  portMobileSubscriptionOrder: MobileOrderDetails;
}

const WithPortMobileSubscriptionOrderMutation = (props: Props) => (
  <WithCustomerContext>
    {cContext => (
      <Mutation<Data, Variables>
        mutation={MUTATION_PORT_MOBILE_SUBSCRIPTION_ORDER}
      >
        {(mutation, mutationResult) => {
          const portMobileSubscriptionOrderContext: PortMobileSubscriptionOrderResult = {
            portMobileSubscriptionOrder: input => {
              mutation({
                variables: { input: { customerId: cContext.id, ...input } },
              });
            },
            loading: mutationResult.loading,
            error: mutationResult.error,
            data:
              mutationResult.data &&
              mutationResult.data.portMobileSubscriptionOrder,
          };
          return props.children(portMobileSubscriptionOrderContext);
        }}
      </Mutation>
    )}
  </WithCustomerContext>
);

export default WithPortMobileSubscriptionOrderMutation;
