import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { Contact } from 'src/lib/types';

export const SearchMenuContactResult: React.FC<SearchMenuResultProps<
  Contact
>> = props => {
  const { firstName, lastName, email } = props.entry;
  return (
    <SearchMenuResultItem
      {...props}
      icon="user"
      title={`${firstName} ${lastName}`}
      summary={email || undefined}
    />
  );
};
