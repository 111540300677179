import * as React from 'react';
import cs from 'classnames';

import { MainMenuItemProps } from './MainMenuItem';
import { MainMenuDividerProps } from './MainMenuDivider';

import './MainMenu.scss';

type MainMenuChildProps = MainMenuItemProps | MainMenuDividerProps;

interface Props {
  className?: string;
  children?: Array<React.ReactElement<MainMenuChildProps>>;
}

export const MainMenu: React.FC<Props> = props => (
  <nav className={cs('MainMenu', props.className)}>
    <ul className="nav flex-column">{props.children}</ul>
  </nav>
);
