import { useAlterFile } from '../mutations/useAlterFile';
import { useEffect } from 'react';
import { getUndoFileMoveAction } from '../undo/UndoActions';
import { File, Folder } from 'src/lib/types';
import undoPopup from 'src/areas/main/documents/undo/UndoPopup';
import { toast } from 'react-toastify';
import { t } from 'src/lib/i18n';

export const useMoveFile = (folder: Folder) => {
  const { alterFile, data, loading, error } = useAlterFile();
  let toastId;
  const displayError = () => {
    toastId = toast(t.documents.errors.couldNotMoveFile, {
      position: toast.POSITION.BOTTOM_CENTER,
      className: 'FileToast',
      progress: 0.99999,
      bodyClassName: 'FileToast-body',
      progressClassName: 'FileToast-progress error',
    });
  };
  useEffect(() => {
    if (!loading) {
      if (
        data?.alterFile?.file &&
        data.alterFile.file.folderId !== folder.parentId
      ) {
        undoPopup(
          getUndoFileMoveAction(
            {
              id: data.alterFile.file.id,
              folderId: folder.parentId,
              name: data.alterFile.file.name,
            } as File,
            folder.name,
            alterFile
          )
        );
      } else if (data?.alterFile?.error || error) {
        displayError();
        setTimeout(() => toast.dismiss(toastId), 5000);
      }
    }
  }, [loading]); //eslint-disable-line

  const moveFile = (fileId: string, targetFolderId: string) =>
    alterFile(fileId, targetFolderId);

  return moveFile;
};
