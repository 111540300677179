import { User } from 'src/lib/global';
import { KeycloakTokenParsed } from 'keycloak-js';

/**
 * A helper type that adds all the Portal-specific fields (attribute maps etc.)
 * we are using in the portal. This represents the token as we receive it from
 * the Keycloak adapter.
 */
export interface PortalKeycloakTokenParsed extends KeycloakTokenParsed {
  jti?: string;
  nbf?: number;
  iss?: string;
  typ?: string;
  azp?: string;
  aud?: string | string[];

  test?: unknown;
  groups?: string | string[];

  auth_time?: number;
  at_hash?: string;
  c_hash?: string;
  acr?: string;
  s_hash?: string;
  email_verified?: boolean;
  verified_mobile_subscriptions?: string[]; // ['44556677::00000000000000000000'],
  customer_id?: string;
  customer_ids?: string[];
  features?: string[];
  customerIdList?: string[];
  mobile_number?: string;
  locale?: string;
  name?: string;
  email?: string;
  phone?: string;
  given_name?: string;
  family_name?: string;
  preferred_username?: string;
  portal_minside_user?: boolean;
}

export default function createUserFromToken(keycloak: {
  idTokenParsed?: KeycloakTokenParsed;
  idToken?: string;
  token?: string;
}): User | undefined {
  if (!keycloak.idTokenParsed) {
    return undefined;
  }

  const tok = keycloak.idTokenParsed as PortalKeycloakTokenParsed;

  const user: User = {
    email: tok.email || '',
    mobilePhone: tok.phone || '',
    idToken: keycloak.idToken || '',
    bearerToken: keycloak.token || '',
    firstName: tok.given_name || '',
    lastName: tok.family_name || '',
    name: tok.name || '',
    username: tok.preferred_username || '',
    isMobileUser: tok.portal_minside_user === true,
    customerIds: tok.customer_ids ? tok.customer_ids : [],
    features: tok.features ? tok.features : [],
  };

  return user;
}
