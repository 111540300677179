import { formatNordic } from './formatNordic';

const thresholds = [
  { unit: 'GB', threshold: 0.9 * 1073741824, bytes: 1073741824 },
  { unit: 'MB', threshold: 0.9 * 1048576, bytes: 1048576 },
  { unit: 'KB', threshold: 0.9 * 1024, bytes: 1024 },
];

export const formatFileSize = (filesize: number) => {
  for (const k of Object.keys(thresholds)) {
    const { unit, bytes, threshold } = thresholds[k];
    if (filesize >= threshold) {
      const val = filesize / bytes;
      const digits = val >= 100 ? 0 : val >= 10 ? 1 : 2;
      return `${formatNordic(filesize / bytes, digits)} ${unit}`;
    }
  }
  return `${filesize} B`;
};
