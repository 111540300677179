import { DayPickerSingleDateController } from 'react-dates';
import TimeField from 'react-simple-timefield';
import moment from 'moment';
import React, { useState } from 'react';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

const valueToDateString = (value: string | undefined) => {
  if (!value) {
    return null;
  }
  const mom = moment(value, 'YYYY-MM-DD');
  if (mom) {
    return mom.format('YYYY-MM-DD');
  }
  return null;
};

const valueToTimeString = (value: string | undefined) => {
  if (!value) {
    return null;
  }

  const mom = moment(value, 'YYYY-MM-DD HH:mm');
  if (mom) {
    return mom.format('HH:mm');
  }
  return null;
};

export const DateTimePicker: React.FunctionComponent<DateTimePickerProps> = props => {
  const [pickerOpen, setPickerOpen] = useState(false);

  function stringToMoment(input: string | undefined) {
    if (input) {
      return moment(input);
    }
    return null;
  }

  const onDateChange = (date: moment.Moment | null) => {
    if (date && date.isValid()) {
      const oldValueAsMoment = moment(props.value);
      const newDate = date.set({
        hour: oldValueAsMoment.get('hour'),
        minute: oldValueAsMoment.get('minute'),
        second: oldValueAsMoment.get('second'),
      });
      if (props.onChange) {
        props.onChange(newDate.format('YYYY-MM-DD HH:mm:ss'));
      }
      togglePicker();
    }
  };

  const onTimeChange = (timeAsHourColonMinute: string) => {
    const regExpMatch = timeAsHourColonMinute.match(/\d\d:\d\d/);
    if (regExpMatch) {
      const momentNewTime = moment(timeAsHourColonMinute, 'HH:mm');
      const newValue = moment(props.value).set({
        hour: momentNewTime.get('hour'),
        minute: momentNewTime.get('minute'),
        second: 0,
      });
      if (props.onChange) {
        props.onChange(newValue.format('YYYY-MM-DD HH:mm:ss'));
      }
    }
  };

  const togglePicker = () => {
    setPickerOpen(!pickerOpen);
  };

  return (
    <div className="DateRangePicker">
      {
        <DateTimeInputs
          onClick={togglePicker}
          {...props}
          onChange={onTimeChange}
        />
      }
      {pickerOpen && (
        <DayPickerSingleDateController
          onDateChange={onDateChange}
          /* Not necessary yet, but required by component */
          onFocusChange={() => {
            return;
          }}
          focused={pickerOpen}
          date={stringToMoment(props.value)}
          onOutsideClick={togglePicker}
          isOutsideRange={props.isOutsideRange}
        />
      )}
    </div>
  );
};

const DateTimeInputs: React.FunctionComponent<DateTimePickerProps> = props => (
  <div className={`DateInputs ${props.className || ''}`}>
    <div className="DateInputs-inputsWrapper">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center pr-2">
          <Icon icon="calendar" />
          <input
            onClick={props.onClick}
            autoComplete="false"
            className="DateInputs-date"
            data-lpignore="true"
            name="start date"
            placeholder={t.ui.DateRangePicker.placeholder.startDate}
            readOnly={props.readOnly}
            type="text"
            value={valueToDateString(props.value) ?? ''}
          />
        </div>
        {!props.hideTime && (
          <div className="d-flex align-items-center">
            <Icon icon="time" />
            <TimeField
              className="DateInputs-time"
              value={valueToTimeString(props.value)}
              placeholder={'start tid'}
              onChange={props.onChange}
              style={{
                width: undefined /* override the default width of the component */,
              }}
            />
          </div>
        )}
      </div>
    </div>
  </div>
);

interface DateTimePickerProps {
  className?: string;
  readOnly?: boolean;
  hideTime?: boolean;
  /**
   * Must be in the format YYYY-MM-DD HH:MM:SS
   */
  value: string;

  onChange: (dateTime: string) => void;
  onClick?: () => void;
  isOutsideRange?: (day: any) => boolean;

  // onFocus?: (input: FocusedInputShape) => void;
  // onBlur?: () => void;
}
