import React, { useEffect } from 'react';
import { useUpdateCallForwardingSettings } from 'src/areas/mobile/settings/mutations';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { CallForwardingMobileSettings } from 'src/lib/types';
import { withFormik, FormikProps } from 'formik';
import { UpdateCallforwardSettingsResult } from 'src/areas/mobile/settings/mutations/useUpdateCallForwardingSettings';
import { t } from 'src/lib/i18n';
import FormikToggle from 'src/lib/flow/Fields/FormikToggle';
import { FormikTextField } from 'src/lib/flow';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { colors, FormSubmitBar } from 'src/lib/ui';
import { yupValidatePhone } from 'src/lib/utils/yupValidations';
import * as yup from 'yup';
interface Props {
  callForwarding: CallForwardingMobileSettings;
  isOpen: boolean;
  toggle: () => void;
}
interface InnerProps {
  mutation: UpdateCallforwardSettingsResult;
}

const InnerFormModal: React.FC<Props &
  InnerProps &
  FormikProps<CallForwardingMobileSettings>> = ({
  values,
  isOpen,
  toggle,
  handleSubmit,
  ...props
}) => {
  const i18n = t.mobile.settings.forwarding;

  useEffect(() => {
    if (props.mutation.data && !props.mutation.error) {
      toggle();
    }
  }, [props.mutation.loading, props.mutation.error, props.mutation.data]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <Modal isOpen={isOpen} size="lg" toggle={toggle}>
      <ModalHeader>{i18n.forwarding}</ModalHeader>
      <ModalBody>
        <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
          {i18n.forwardingInfo}
        </small>
        <SettingsListItem
          className="px-0"
          title={i18n.forwardAll}
          description={i18n.forwardAllInfo}
          toggle={
            <FormikToggle
              id="allCalls"
              disabled={!!values.busy || !!values.noAnswer}
            />
          }
        >
          <FormikTextField
            className="w-50 pt-4"
            id="allCallsDestination"
            disabled={!!values.busy || !!values.noAnswer}
            label={i18n.phoneNumberLabel}
          />
        </SettingsListItem>

        <SettingsListItem
          className="px-0"
          title={i18n.forwardBusy}
          description={i18n.forwardAllInfo}
          toggle={<FormikToggle id="busy" disabled={!!values.allCalls} />}
        >
          <FormikTextField
            className="w-50 pt-4"
            id="busyDestination"
            label={i18n.phoneNumberLabel}
            disabled={!!values.allCalls}
          />
        </SettingsListItem>

        <SettingsListItem
          className="px-0 border-0"
          title={i18n.forwardNoAnswer}
          description={i18n.forwardNoAnswerInfo}
          toggle={<FormikToggle id="noAnswer" disabled={!!values.allCalls} />}
        >
          <FormikTextField
            className="w-50 pt-4"
            id="noAnswerDestination"
            label={i18n.phoneNumberLabel}
            disabled={!!values.allCalls}
          />
        </SettingsListItem>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={() => handleSubmit()}
          cancel={toggle}
          disabled={!props.isValid}
          loading={props.mutation.loading}
          error={!!props.mutation.error}
          errorMsg={t.mobile.settings.modalFields.unableToSave}
        />
      </ModalFooter>
    </Modal>
  );
};

const EnhanceForm = withFormik<
  Props & InnerProps,
  CallForwardingMobileSettings
>({
  mapPropsToValues: props => ({
    allCalls: !!props.callForwarding.allCalls,
    allCallsDestination: props.callForwarding.allCallsDestination || '',
    busy: !!props.callForwarding.busy,
    busyDestination: props.callForwarding.busyDestination || '',
    noAnswer: !!props.callForwarding.noAnswer,
    noAnswerDestination: props.callForwarding.noAnswerDestination || '',
  }),
  validationSchema: () =>
    yup.object().shape<CallForwardingMobileSettings>({
      allCalls: yup.boolean(),
      allCallsDestination: yup.string().when('allCalls', {
        is: true,
        then: yupValidatePhone(t.mobile.settings.forwarding.invalidNumber),
      }),
      busy: yup.boolean(),
      busyDestination: yup.string().when('busy', {
        is: true,
        then: yupValidatePhone(
          t.mobile.settings.forwarding.invalidNumber
        ).required(t.mobile.settings.forwarding.required),
      }),
      noAnswer: yup.boolean(),
      noAnswerDestination: yup.string().when('noAnswer', {
        is: true,
        then: yupValidatePhone(
          t.mobile.settings.forwarding.invalidNumber
        ).required(t.mobile.settings.forwarding.required),
      }),
    }),

  handleSubmit: (values, bag) => {
    bag.props.mutation.updateSettings({
      ...values,
      noAnswerDestination: values.noAnswerDestination,
    });
  },
})(InnerFormModal);

const CallForwardingFormModal: React.FC<Props> = props => {
  const mutation = useUpdateCallForwardingSettings();
  return (
    <EnhanceForm
      mutation={mutation}
      callForwarding={props.callForwarding}
      isOpen={props.isOpen}
      toggle={props.toggle}
    />
  );
};
export default CallForwardingFormModal;
