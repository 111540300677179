import { useMemo } from 'react';
import * as t from '../types/__types/Search';
import { searchQuery, WrappedSearchResult, SearchResult } from '../types';
import { unwrapWrappedSearchResults } from './unwrapWrappedSearchResults';

import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import {
  SortOrder,
  SearchDomain,
  SearchFilter,
} from 'src/__types/graphql-global-types';
const defaultSearchSize = 20;

interface SearchInput {
  types?: SearchDomain[];
  query?: string;
  size?: number;
  page?: number;
  filter?: SearchFilter[];
  sortBy?: string;
  sortOrder?: SortOrder;
  cluster?: boolean;
}

interface Search {
  loading: boolean;
  results: SearchResult[];
  totalResults: number;
  isMore: boolean;
  totalResultsByDomain: Array<t.Search_customer_search_totalResultsByDomain>;
  filter: SearchFilter[] | undefined;
}

export const useSearch = (search: SearchInput): Search => {
  const customer = useCustomer();

  const res = useQuery<t.Search, t.SearchVariables>(searchQuery, {
    variables: {
      customerId: customer.id,
      search: {
        query: search.query,
        types: search.types,
        ...(search?.types?.length === 1
          ? {
              // These parameters are only valid for searches for a single specific result type.
              filters: search.filter,
              sortBy: search.sortBy,
              sortOrder: search.sortOrder,
            }
          : {}),
        size: search.size ?? defaultSearchSize,
        page: search.page,
        cluster: search?.cluster,
      },
    },
    errorPolicy: 'all',
  });

  // // Temporary mapper to map the new ResultUnion from GraphQL to our old types.
  // // Because we use our custom types throughout the app, we need to change all our types before we are able to use the new ResultUnion + generated types.
  const unmappedRecords = useMemo(
    () =>
      ((res.data?.customer?.search?.results ??
        []) as unknown) as WrappedSearchResult[],
    [res.data]
  );
  const records = useMemo(() => unwrapWrappedSearchResults(unmappedRecords), [
    unmappedRecords,
  ]);
  return {
    loading: res.loading,
    results: records,
    totalResults: res.data?.customer?.search?.totalResults ?? 0,
    totalResultsByDomain:
      res?.data?.customer?.search?.totalResultsByDomain ?? [],
    isMore: Boolean(
      res?.data?.customer?.search?.totalResults &&
        res?.data?.customer?.search?.results.length <
          res?.data?.customer?.search?.totalResults
    ),
    filter: search.filter,
  };
};
