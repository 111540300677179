import React, { useState } from 'react';
import * as types from '../queries/__types/usePlannedWork';
import moment from 'moment';
import { formatDate } from 'src/lib/utils/dateUtils';
import { t, useI18nContext } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import './DashboardPlannedWorkItem.scss';
import { PlannedWorkModal, SubLinks } from './PlannedWorkModal';
import { CircleIcon } from 'src/lib/ui';
import { useBreakpoint, useFeature } from 'src/lib/utils';
import cs from 'classnames';
import { trackPlannedWork } from 'src/lib/analytics';

const useDayAndTime = (date: string, format: 'day' | 'dateWithMonth') => {
  const i18n = useI18nContext();
  const momentDate = moment(date);
  const day = formatDate(momentDate.toDate(), format, i18n.locale);
  const time = momentDate.format('HH:mm');
  return { day, time, momentDate };
};

export const DashboardPlannedWorkItem = (props: {
  plannedWork: types.usePlannedWork_customer_plannedWork;
  showSub?: boolean;
}) => {
  const [open, setOpen] = useState(false);
  const desktop = useBreakpoint('sm');
  const hasPlannedWorkPlacementFeature = useFeature('planned-work-placement');
  const start = useDayAndTime(
    props.plannedWork.dates.plannedStartDate,
    desktop ? 'day' : 'dateWithMonth'
  );
  const end = useDayAndTime(
    props.plannedWork.dates.plannedEndDate,
    desktop ? 'day' : 'dateWithMonth'
  );
  const spansDays = end.momentDate.isAfter(start.momentDate, 'day');
  const openModal = () => {
    trackPlannedWork();
    setOpen(true);
  };

  return (
    <>
      <tr
        onClick={openModal}
        onKeyUp={e => (e.key === 'Enter' ? openModal() : null)}
        role="button"
        tabIndex={0}
        className="DashboardPlannedWorkItem"
      >
        <td className="w-20">
          <div
            className={cs('DashboardPlannedWorkItem-id ', {
              'DashboardPlannedWorkItem-id--past': start.momentDate.isBefore(
                moment.now()
              ),
            })}
          >
            {props.plannedWork.changeNumber}
          </div>
        </td>
        <td className="w-20">
          <div
            className={cs('DashboardPlannedWorkItem-date ', {
              'DashboardPlannedWorkItem-date--past': start.momentDate.isBefore(
                moment.now()
              ),
            })}
          >
            {start.day}
          </div>
          {spansDays ? (
            <div
              className={cs('DashboardPlannedWorkItem-date ', {
                'DashboardPlannedWorkItem-date--past': end.momentDate.isBefore(
                  moment.now()
                ),
              })}
            >
              {end.day}
            </div>
          ) : null}
        </td>
        {desktop && (
          <td>
            {spansDays ? (
              <div className="DashboardPlannedWorkItem-time">
                <div
                  className={cs({
                    'DashboardPlannedWorkItem-time--past': start.momentDate.isBefore(
                      moment.now()
                    ),
                  })}
                >
                  <Icon icon="time" />
                  {start.time}
                </div>
                <div
                  className={cs({
                    'DashboardPlannedWorkItem-time--past': end.momentDate.isBefore(
                      moment.now()
                    ),
                  })}
                >
                  <Icon icon="time" />
                  {end.time}
                </div>
              </div>
            ) : (
              <div
                className={cs('DashboardPlannedWorkItem-time d-flex', {
                  'DashboardPlannedWorkItem-time--past': end.momentDate.isBefore(
                    moment.now()
                  ),
                })}
              >
                <Icon icon="time" />
                <div>
                  {start.time} {t.dashboard.plannedWork.to} {end.time}
                </div>
              </div>
            )}
          </td>
        )}
        <td>
          <div
            className={cs('DashboardPlannedWorkItem-number', {
              'DashboardPlannedWorkItem-number--past': end.momentDate.isBefore(
                moment.now()
              ),
            })}
          >
            {hasPlannedWorkPlacementFeature && props.showSub ? (
              <SubLinks ids={props.plannedWork.impactedSubscriptionIds} />
            ) : (
              t.dashboard.plannedWork.servicesAffected(
                props.plannedWork.numberOfImpactedSubscriptions ?? 0
              )
            )}
          </div>
        </td>
        <td>
          {props.plannedWork.emergency ? (
            <CircleIcon icon="alert" color="danger" filled={true} />
          ) : null}
        </td>
      </tr>
      <PlannedWorkModal
        open={open}
        toggleOpen={() => setOpen(!open)}
        plannedWork={props.plannedWork}
      />
    </>
  );
};
