import { GsmSignalType } from '../../../../__types/graphql-global-types';
import { t } from 'src/lib/i18n';

export const mapSignalTypeToDisplaySignal = (
  signalType: GsmSignalType | null
) => {
  const i18n = t.subscriptions.SignalCard.signalType;
  switch (signalType) {
    case GsmSignalType.unknown:
      return i18n.unknown;
    case GsmSignalType.signal2g:
      return i18n.gen2;
    case GsmSignalType.signal3g:
      return i18n.gen3;
    case GsmSignalType.signal4g:
      return i18n.gen4;
    default:
      return i18n.unknown;
  }
};
