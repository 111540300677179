import * as Sentry from '@sentry/browser';
import _ from 'lodash';
import { GraphQLError } from 'graphql';

export type PossibleErrorFormats =
  | GraphQLError
  | readonly GraphQLError[]
  | undefined;

export const extractErrors = (
  errors: PossibleErrorFormats
): GraphQLError[] | undefined => {
  const errorList = _.compact<GraphQLError>(
    _.flattenDeep([...[errors ? [errors] : []]])
  );
  return _.isEmpty(errorList) ? undefined : errorList;
};

export const captureGraphQLErrors = (sentry = Sentry) => (
  queryFunction: string,
  errors: PossibleErrorFormats
): GraphQLError[] | undefined => {
  const errorList = extractErrors(errors);
  if (!errorList) return;

  sentry.withScope(scope => {
    scope.setTag('queryFunction', queryFunction);
    scope.setTag('errorType', 'graphQL');

    _.uniqBy(errorList, e => e.message).forEach(error => {
      const graphQLPath = (error.path ?? []).join(' > ');
      scope.setExtras({ error });
      scope.setTag('queryPath', graphQLPath);

      if (
        error.message === 'Request failed with status code 401' ||
        (error.message === 'Request failed with status code 400' &&
          graphQLPath === 'customer > search')
      ) {
        console.warn('Reload because of expired token');
        window.location.reload();
        return;
      }

      sentry.captureMessage(
        `GraphQL error in query function ${queryFunction}: ${error.message}`
      );
    });
  });

  return errorList;
};
