import * as React from 'react';
import './FileToast.scss';
import cs from 'classnames';
import { t } from 'src/lib/i18n';
import { Icon, IconDefinition } from '@telia/styleguide';
import { Loading } from '../Loading/Loading';

interface Props {
  error?: boolean;
  loading?: boolean;
  fileName: string;
}

export function FileToast(props: Props) {
  const { loading, error, fileName } = props;
  const icon: IconDefinition = error ? 'alert' : 'check-mark';

  return (
    <div className="d-flex">
      {loading ? (
        <Loading />
      ) : (
        <Icon icon={icon} className={cs('mr-3', error ? 'error' : 'success')} />
      )}
      <div>
        {loading
          ? t.ui.Toast.uploading(fileName)
          : error
          ? t.ui.Toast.error + ` ${fileName}`
          : t.ui.Toast.success + ` ${fileName}`}
      </div>
    </div>
  );
}

export default FileToast;
