import { useMemo } from 'react';
import { PlannedWorkFragment } from '../../../../../lib/types/subscription/PlannedWorkFragment';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { useQuery } from 'react-apollo';
import moment from 'moment';

import * as t from './__types/usePersonalizedPlannedWork';
export const QUERY_PLANNED_WORK = gql`
  query usePersonalizedPlannedWork($customerId: ID!) {
    customer(id: $customerId) {
      id
      plannedWork {
        ...PlannedWorkSearchResult
      }
    }
  }

  ${PlannedWorkFragment}
`;
export const usePersonalizedPlannedWork = () => {
  const customer = useCustomer();

  const { data, loading, error } = useQuery<
    t.usePersonalizedPlannedWork,
    t.usePersonalizedPlannedWorkVariables
  >(QUERY_PLANNED_WORK, {
    variables: {
      customerId: customer.id,
    },
  });

  const visibleItems = useMemo(() => {
    if (!data?.customer?.plannedWork) {
      return undefined;
    }

    const now = moment();
    return data.customer.plannedWork.filter(pw => {
      const end = moment(pw.dates.plannedEndDate);
      return end.isAfter(now);
    });
  }, [data]);

  return {
    plannedWork: visibleItems,
    error,
    loading,
  };
};
