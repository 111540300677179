import React from 'react';
import { Icon } from '@telia/styleguide';
import { t } from 'src/lib/i18n';

export const GlobalBanner = () => {
  return (
    <div
      style={{
        backgroundColor: '#990ae3',
        padding: '0.8rem',
        color: 'white',
        fontSize: '18px',
        display: 'flex',
        alignItems: 'center',
        borderRadius: '6px',
      }}
    >
      <Icon
        icon="alert"
        className="pr-2"
        style={{ width: '40px', height: '40px' }}
      />
      <div>
        {t.dashboard.bannerText.part1}
        {t.dashboard.bannerText.part2}
        <Icon
          style={{
            display: 'inline-block',
            height: '24px',
            verticalAlign: 'text-bottom',
          }}
          icon="lightbulb"
        />
        {t.dashboard.bannerText.part3}
      </div>
    </div>
  );
};
