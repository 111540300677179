import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

export const QUERY_ALL_CALLER_IDS_FOR_SUBSCRIPTION = gql`
  query useAllCallerIdsForSubscription($msisdn: ID!) {
    subscription(id: $msisdn) {
      id
      allCallerIds
    }
  }
`;

export const useAllCallerIdsForSubscription = (subscriptionId: string) => {
  const { data, loading, error } = useQuery(
    QUERY_ALL_CALLER_IDS_FOR_SUBSCRIPTION,
    {
      variables: {
        msisdn: subscriptionId,
      },
    }
  );
  return {
    allCallerIds: data?.subscription?.allCallerIds,
    error,
    loading,
  };
};
