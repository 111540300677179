import * as React from 'react';
import gql from 'graphql-tag';
import { Query, QueryResult, useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/WithStatisticsGSM';

const WITH_STATISTICS_GSM = gql`
  query WithStatisticsGSM(
    $customerId: ID!
    $input: GSMStatisticsInput!
    $subscriptionId: [ID!]!
  ) {
    customer(id: $customerId) {
      id
      subscriptions(ids: $subscriptionId) {
        id
        statisticsGSMRecent {
          currentBand
          currentChannelNumber
          measurementTimeStamp
        }
        statisticsGSM(input: $input) {
          periodStart
          periodEnd
          rssi {
            avg
            max
            min
          }
          ecio {
            avg
            max
            min
          }
          granularity
          periods {
            periodStart
            periodEnd
            avg_rssi
            avg_ecio
            currentChannelNumber
            currentBand
          }
        }
      }
    }
  }
`;

export type GsmStatisticsPeriod = Omit<
  t.WithStatisticsGSM_customer_subscriptions_statisticsGSM_periods,
  '__typename'
>;

export const useStatisticsGsm = (inputProps: {
  subscriptionId: string;
  periodStart: string;
  periodEnd: string;
  periods: number;
}) => {
  const { subscriptionId, ...restProps } = inputProps;
  const customer = useCustomer();
  /**
   * Since we cannot conditonally render a hook, we rather skip the query if some params are missing
   */
  const skipQuery = Boolean(
    restProps.periodEnd === undefined ||
      restProps.periodStart === undefined ||
      restProps.periods === undefined
  );

  const res = useQuery<t.WithStatisticsGSM, t.WithStatisticsGSMVariables>(
    WITH_STATISTICS_GSM,
    {
      variables: {
        customerId: customer.id,
        subscriptionId: [subscriptionId],
        input: restProps,
      },
      skip: skipQuery,
    }
  );

  return {
    loading: res.loading,
    error: res.error,
    data: res.data?.customer?.subscriptions?.[0] ?? null,
  };
};
interface GSMStatisticsInput {
  periodStart: string;
  periodEnd: string;
  periods: number;
}

interface Variables {
  customerId: string;
  subscriptionId: string[];
  input: GSMStatisticsInput;
}
/**
 * Interface for a period shown in statistics
 * Allowing string | number for "Dates" since we parse Date-Strings to number (unix-epoch)
 */
export interface Period {
  periodStart: string | number;
  periodEnd: string | number;
  avg_rssi: number | null;
  avg_ecio: number | null;
  currentChannelNumber: number;
  currentBand: number;
}

interface GSMData {
  avg: number;
  min: number;
  max: number;
}

interface Data {
  customer?: {
    subscriptions?: Array<{
      statisticsGSM?: {
        periodStart: string | number;
        periodEnd: string | number;
        rssi: GSMData;
        ecio: GSMData;
        periods?: null | Array<Period>;
      };
    }>;
  };
}
interface Props {
  customerId: string;
  subscriptionId: string;
  periodStart: string;
  periodEnd: string;
  periods: number;
  children: (result: QueryResult<Data, Variables>) => JSX.Element | null;
}

const WithStatisticsGSM = (props: Props) => (
  <Query<Data, Variables>
    query={WITH_STATISTICS_GSM}
    variables={{
      customerId: props.customerId,
      subscriptionId: [props.subscriptionId],
      input: {
        periodStart: props.periodStart,
        periodEnd: props.periodEnd,
        periods: props.periods,
      },
    }}
  >
    {props.children}
  </Query>
);

export default WithStatisticsGSM;
