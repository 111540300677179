import React from 'react';
import { SearchResult } from 'src/areas/main/search';
import { NoResult, List } from 'src/lib/ui';
import { SearchListSubscriptionResult } from 'src/areas/main/search/components/list/SearchListSubscriptionResult';
import { Subscription } from 'src/lib/types';
import { useDelayedRender } from 'src/lib/utils/useDelayedRender';
import { getPhoneNumberDisplayLabel } from '../../utils/getPhoneNumberDisplayLabel';
import { t } from 'src/lib/i18n';

interface Props {
  loading: boolean;
  results: SearchResult[];
  noResultsMessage: string;
  onSelectRow?: (id: string) => void;
  selected?: string[];
}

export const TeleSubscriptionsList = (props: Props) => {
  const delayedRender = useDelayedRender(500, props.loading);
  return (
    <List border={true} container={false} loading={delayedRender}>
      {props.results.length === 0 && !props.loading ? (
        <div style={{ width: '100%', height: '50vh' }}>
          <NoResult text={props.noResultsMessage} />
        </div>
      ) : (
        props.results.map(sub => {
          const subData = sub as Subscription;
          const onSelectRow = props.onSelectRow
            ? () => props.onSelectRow?.(subData.id)
            : undefined;
          const phoneNumberLabel = getPhoneNumberDisplayLabel(
            subData.phoneNumbers,
            'long'
          );

          return (
            <SearchListSubscriptionResult
              key={subData.id}
              entry={subData}
              attributes={
                phoneNumberLabel
                  ? [
                      {
                        label:
                          t.subscriptions.TeleSubscriptionsList.phoneNumber,
                        value: phoneNumberLabel,
                      },
                    ]
                  : undefined
              }
              onSelect={onSelectRow}
              selected={props.selected?.includes(subData.id)}
            />
          );
        })
      )}
    </List>
  );
};
