import React, { useState } from 'react';
import { SettingsCard } from 'src/areas/mobile/settings/SettingsCard';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { VoicemailMobileSettings } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import VoiceMailFormModal from 'src/areas/mobile/settings/forms/VoiceMailFormModal';

interface Props {
  voicemailSettings: VoicemailMobileSettings;
}

export const VoicemailSetting: React.FC<Props> = ({ voicemailSettings }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const i18n = t.mobile.settings.voicemail;

  return (
    <SettingsCard
      title={i18n.voicemail}
      description={i18n.voicemailInfo}
      onClick={() => setModalOpen(true)}
    >
      <SettingsListItem
        title={i18n.notificationOnSMS}
        description={
          voicemailSettings.smsNotification
            ? t.mobile.settings.activated
            : t.mobile.settings.notActivated
        }
      />
      <VoiceMailFormModal
        voicemailSettings={voicemailSettings}
        isOpen={isModalOpen}
        toggle={() => setModalOpen(!isModalOpen)}
      />
    </SettingsCard>
  );
};
