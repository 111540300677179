import React from 'react';
import { Button } from 'src/lib/ui';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { t } from 'src/lib/i18n';

import './Pagination.scss';

interface Props {
  totalResults: number;

  /**
   * Optional function on pageSize selection.
   */
  setPageSize: (pageSize: number) => void;
  setPage: (page: number) => void;
  page: number;
  pageSize: number;
}

const getPageInterval = (
  currentPage: number,
  size: number,
  totalResults: number
) => {
  if (totalResults === 0) {
    return '0';
  }
  const minValue = (currentPage - 1) * size;
  const maxValue = minValue + size;
  return `${minValue + 1}-${maxValue > totalResults ? totalResults : maxValue}`;
};

export const Pagination: React.FC<Props> = ({
  totalResults,
  setPageSize,
  pageSize,
  page,
  setPage,
}) => {
  const i18n = t.ui.Pagination;
  const pageCount = Math.ceil(totalResults / pageSize);
  const currentPageInterval = getPageInterval(page, pageSize, totalResults);
  const pageSizes = [5, 10, 20, 50, 100];

  return (
    <div className="Pagination">
      <div className="Pagination-perpage">
        <UncontrolledDropdown>
          <DropdownToggle tag="div">
            <Button
              icon="arrow-small-down"
              transparent={true}
              size="small"
              iconPosition="after"
            >
              {`${i18n.rowsPerPage} ${pageSize}`}
            </Button>
          </DropdownToggle>

          <DropdownMenu>
            {pageSizes.map(sz => (
              <DropdownItem
                key={sz}
                active={pageSize === sz}
                onClick={() => setPageSize(sz)}
              >
                {sz}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>

      <div className="Pagination-pages">
        {i18n.xOfY(currentPageInterval, totalResults)}
      </div>

      <Button
        className="Pagination-prev"
        icon="arrow-left"
        hideLabel={true}
        transparent={true}
        rounded={true}
        disabled={page === 1}
        size="xs"
        onClick={() => setPage(page - 1)}
      >
        {i18n.btnPrevPage}
      </Button>

      <Button
        className="Pagination-next"
        icon="arrow-right"
        hideLabel={true}
        transparent={true}
        rounded={true}
        size="xs"
        disabled={page >= pageCount}
        onClick={() => setPage(page + 1)}
      >
        {i18n.btnNextPage}
      </Button>
    </div>
  );
};
