// tslint:disable: no-bitwise

const x = 2147483648; // 2^31

// Borrowed from Googles closure library, goog.string.getRandomString()
// https://github.com/google/closure-library/blob/555e0138c83ed54d25a3e1cd82a7e789e88335a7/closure/goog/string/string.js#L1177

/**
 * Generate "Unique enough" ID. Can be assumed safely unique in a single browser context.
 */
const generateUeid = () =>
  Math.floor(Math.random() * x).toString(36) +
  Math.abs(Math.floor(Math.random() * x) ^ Date.now()).toString(36);

export default generateUeid;
