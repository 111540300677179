import * as React from 'react';

import { Loading, SearchInput, Attribute, Attributes } from 'src/lib/ui';
import cx from 'classnames';

import { t } from 'src/lib/i18n';

import './SearchListPicker.scss';
export interface SearchListPickerItem {
  /**
   * Unique ID that can be included
   */
  id?: string;
  /**
   * Value of item
   */
  value: string;
  /**
   * Optional label to describe value
   */
  label?: string;
  /**
   * Additional attributes for item. Generally used to show additonal information for an item.
   */
  attributes?: SearchListPickerItem[];
}

export type SearchListPickerProps = {
  /**
   * Array of items to render
   */
  items: Array<SearchListPickerItem>;
  /**
   * Searchfield placeholder
   */
  placeholder?: string;
  /**
   * Show/hide searchfield
   */
  allowQuery?: boolean;
  /**
   * Function for setting value in searchfield
   */
  setQuery?: (query: string) => void;
  /**
   * Current value in searchfield
   */
  query?: string;
  /**
   * Optional header for result list
   */
  listHeader?: string;
  /**
   * Boolean to show loading indicator
   */
  loading?: boolean;
  /**
   * Should list items be bordered
   */
  bordered?: boolean;
  // renderItem?: (item: SearchListPickerItem) => React.ReactNode;
  /**
   * Function when clicking on listItem
   */
  onClick?: (item: SearchListPickerItem) => void;
  /**
   * Custom list styling
   */
  listStyle?: React.CSSProperties;
} & (DownshiftProps | { renderedInDropdown?: false });

type DownshiftProps = {
  renderedInDropdown: true;
  highlightIndex: number;
  getMenuProps: any;
  getItemProps: any;
  getInputProps?: any;
};

export const SearchListPicker: React.FC<SearchListPickerProps> = props => {
  const onSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (props.setQuery) {
      return props.setQuery(e.target.value);
    }
  };

  const onClick = (item: SearchListPickerItem) => {
    if (props.onClick) {
      return props.onClick(item);
    }
  };

  const onKeyPress = (e: React.KeyboardEvent, item: SearchListPickerItem) => {
    if (e.key === 'Enter' || e.keyCode === 32) {
      onClick(item);
    }
  };

  return (
    <div className="SearchListPicker">
      {props.allowQuery ? (
        <SearchInput
          value={props.query}
          onChange={onSearch}
          placeholder={props.placeholder}
          inputProps={
            'getInputProps' in props ? props.getInputProps : undefined
          }
        />
      ) : null}
      {props.listHeader ? (
        <div className="px-3 py-2">{props.listHeader}</div>
      ) : null}

      {props.loading ? (
        <Loading />
      ) : (
        <ul
          style={props.listStyle}
          {...('getMenuProps' in props ? props.getMenuProps() : {})}
        >
          {!props.items || props.items.length === 0 ? (
            <span className="p-2">{t.ui.SearchListPicker.noHits}</span>
          ) : null}
          {props.items.map((item, idx) => (
            <li
              key={idx}
              role="button"
              tabIndex={'renderedInDropdown' in props ? -1 : 0}
              onClick={() => onClick(item)}
              onKeyPress={e => onKeyPress(e, item)}
              className={cx({
                clickable: onClick !== undefined,
                bordered: props.bordered,
                highlighted:
                  'highlightIndex' in props && props.highlightIndex === idx,
              })}
              {...('getItemProps' in props
                ? props.getItemProps({
                    item: item,
                    index: idx,
                  })
                : {})}
            >
              <div className="SearchListPicker-item-heading">{item.value}</div>
              {item.attributes && item.attributes.length > 0 && (
                <Attributes>
                  {item.attributes.map(attr => (
                    <Attribute
                      key={attr.value}
                      value={attr.value}
                      label={attr.label}
                    />
                  ))}
                </Attributes>
              )}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};
