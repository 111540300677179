import React, { useEffect, useRef } from 'react';
import { Button, colors, InfoCard } from 'src/lib/ui';
import './AreaSelector.scss';
import { SearchField, SearchProvider } from 'src/areas/main/search';
import { SearchResult } from 'src/areas/main/search/types';
import { SearchMenuResult } from 'src/areas/main/search/components/menu/SearchMenuResult';
import { t } from 'src/lib/i18n';
import { useSearchSubscriptions } from 'src/areas/main/subscriptions/queries/useSearchSubscriptions';

interface Props {
  title?: string;
  availableItems: SearchResult[];
  selectedItems: SearchResult[];
  searchPlaceholder?: string;
  searchProvider: SearchProvider;
  onChange: (currentItems: SearchResult[]) => void;
}

const AreaSelector = (props: Props) => {
  const clickedTag = useRef<string | null>(null);
  const { subscriptions } = useSearchSubscriptions({
    size: 10000,
    filter: [
      { filter: 'tag', value: [clickedTag.current ?? ''] },
      {
        filter: 'group',
        value: ['data'],
      },
    ],
    skipSearch: clickedTag === null,
  });

  const addItem = (item: SearchResult) => {
    props.onChange([...(props.selectedItems ? props.selectedItems : []), item]);
  };

  const addItems = (items: SearchResult[]) => {
    if (items.length > 0) {
      props.onChange([
        ...(props.selectedItems ? props.selectedItems : []),
        ...items,
      ]);
    }
    clickedTag.current = null;
  };

  const itemIsSelected = (item: SearchResult) =>
    !!props.selectedItems.find(selectedItem => selectedItem.id === item.id);

  const removeItem = (item: SearchResult) => {
    const newCurrent = props.selectedItems.filter(
      current => current.id !== item.id
    );
    props.onChange(newCurrent);
  };

  const addSubscriptionsForTag = (tagId: string) => {
    clickedTag.current = tagId;
  };

  useEffect(() => {
    addItems(subscriptions.map(s => s.subscription as SearchResult));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [subscriptions]);

  const sortResult = (items: SearchResult[]) => {
    return items.sort((first, second) => {
      if (
        first.__typename === 'Subscription' &&
        second.__typename === 'Subscription'
      ) {
        return (first?.alias ?? first?.name ?? first.id) >
          (second?.alias ?? second?.name ?? second.id)
          ? 1
          : -1;
      }
      return 0;
    });
  };

  const translate = t.profile.editNotifications;
  return (
    <div className="AreaSelector">
      <InfoCard className="my-4">
        {translate.subscriptionSelectionInfo}
      </InfoCard>

      <span className="AreaSelector-title mediumBold">{props.title}</span>
      <div className="AreaSelector-search">
        <SearchField
          searchProvider={props.searchProvider}
          renderSearchMenuOnFocus={true}
          onSelect={v => {
            if (v) {
              if (v.__typename === 'Tag') {
                addSubscriptionsForTag(v.id);
              } else {
                addItem(v);
              }
            }
          }}
          itemIsDisabled={itemIsSelected}
          additionalInfoOnResult={item =>
            itemIsSelected(item) ? (
              <div
                className="pr-2"
                style={{
                  textAlign: 'end',
                  color: `${colors.greyDarkText}`,
                  width: '30%',
                  fontSize: 'small',
                }}
              >
                {translate.activeNotifications}
              </div>
            ) : null
          }
        />
      </div>

      {props.selectedItems && props.selectedItems.length > 0 && (
        <ul className="AreaSelector-selected-list">
          {sortResult(props.selectedItems).map(item => (
            <SearchMenuResult
              key={item.id || ''}
              entry={item}
              itemProps={{
                disabled: true,
                role: 'list-item',
                className: 'AreaSelector-selected-list-item',
              }}
              additionalInfo={
                <Button
                  type="button"
                  className="text-danger remove mr-2"
                  color="dark"
                  icon="close"
                  transparent={true}
                  hideLabel={true}
                  onClick={() => removeItem(item)}
                >
                  {translate.btnRemove}
                </Button>
              }
            />
          ))}
        </ul>
      )}
    </div>
  );
};

export default AreaSelector;
