import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import * as t from './__types/useSmsHistory';

export const QUERY_SMS_HISTORY = gql`
  query useSmsHistory {
    me {
      id
      mobileSubscriptions {
        id
        smsHistory {
          id
          msisdn
          message
          timestamp
          recipients {
            msisdn
            success
          }
        }
      }
    }
  }
`;

interface Response {
  error: boolean;
  loading: boolean;
  smsHistory?: Array<t.useSmsHistory_me_mobileSubscriptions_smsHistory | null> | null;
}

export const useSmsHistory = (): Response => {
  const { data, loading, error } = useQuery<t.useSmsHistory>(QUERY_SMS_HISTORY);
  const { id } = useSubscriptionContext();
  const currentSub = data?.me
    ? data.me.mobileSubscriptions
      ? data.me.mobileSubscriptions.filter(sub => sub.id === id)[0]
      : undefined
    : undefined;
  const smsHistory = currentSub ? currentSub.smsHistory : undefined;

  return { smsHistory, error: !!error, loading: loading };
};
