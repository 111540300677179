import React from 'react';
import { BodyCard, Button } from 'src/lib/ui';
import { t, useI18nContext } from 'src/lib/i18n';
import { ExpansionPanel } from 'src/lib/ui/ExpansionPanel/ExpansionPanel';
import { EditIncidentContacts } from 'src/areas/main/incidents/lib/IncidentContacts/EditIncidentContacts';
import EditCustomerReference from 'src/areas/main/incidents/lib/EditCustomerReference/EditCustomerReference';
import { IncidentSeverity } from 'src/__types/graphql-global-types';
import { useIncident_customer_incidents } from 'src/areas/main/incidents/queries/__types/useIncident';
import './IncidentSidebar.scss';
import { SubscriptionData } from 'src/areas/main/subscriptions/queries/useSubscription';
import { DataWithStatusAbout } from 'src/areas/main/subscriptions/Pages/Data/views/DataWithStatusSummary/DataWithStatusAbout';
import cn from 'classnames';
import { formatDate } from 'src/lib/utils';
import { SubscriptionIncidentFollowers } from './lib/SubscriptionIncidentFollowers/SubscriptionIncidentFollowers';
import { useIncidentFollowers_customer_incidents_subscription_incidentFollowers } from './queries/__types/useIncidentFollowers';

type Props = {
  incident: useIncident_customer_incidents;
  incidentFollowers?: useIncidentFollowers_customer_incidents_subscription_incidentFollowers[];
  sidebarOpen: boolean;
  setSidebarOpen: () => void;
  incidentStatusText: string;
  subscriptionFullData: SubscriptionData;
};

const resolveSeverity = (severity: IncidentSeverity | null) => {
  switch (severity) {
    case 'Minor':
      return t.incidents.IncidentSummary.minor;
    case 'Major':
      return t.incidents.IncidentSummary.major;
    case 'Critical':
      return t.incidents.IncidentSummary.critical;
    default:
      return t.incidents.status.fallback;
  }
};

const resolveContactType = (contactType: string | null) => {
  switch (contactType) {
    case 'Kundeportalen':
      return t.incidents.IncidentSummary.web;
    case 'Phone':
      return t.incidents.IncidentSummary.phone;
    case 'Email':
      return t.incidents.IncidentSummary.email;
    case 'Chat':
      return t.incidents.IncidentSummary.chat;
    case 'Monitoring':
      return t.incidents.IncidentSummary.monitoring;
    default:
      return t.incidents.IncidentSummary.unknown;
  }
};

export const IncidentSidebar = (props: Props) => {
  const i18n = useI18nContext();
  return (
    <BodyCard
      noGutter={true}
      borders={true}
      boxShadow={1}
      className={cn(
        'IncidentSidebar',
        props.sidebarOpen ? 'IncidentSidebar--open' : null
      )}
    >
      <div className="IncidentSidebar-top">
        <h3>{t.incidents.IncidentSidebar.header}</h3>
        <Button
          transparent={true}
          className="IncidentContent-sidebar-close"
          rounded={true}
          hideLabel={true}
          icon="close"
          onClick={props.setSidebarOpen}
        >
          {t.layout.MainApp.closeMenu}
        </Button>
      </div>
      <ExpansionPanel
        icon="info"
        heading={t.incidents.IncidentSidebar.aboutIncident}
        expanded
      >
        <div className="IncidentSidebar-about">
          <div className="IncidentSidebar-about-title">{props.incident.id}</div>
          <div className="IncidentSidebar-about-metadata grey-dark-text">
            {t.incidents.created}:{' '}
            {formatDate(
              props.incident.createdAt || new Date(),
              'mediumWithTime',
              i18n.locale
            )}
            <br />
            {t.incidents.customerContact}: {props.incident.createdBy}
            <br /> <br />
            {t.incidents.faultType}: {props.incident.shortDescription}
            <br />
            {t.incidents.description}: {props.incident.description}
            <br />
            {t.incidents.origin}:{' '}
            {resolveContactType(props.incident.contactType)}
            <br />
            {t.incidents.status.label}: {props.incidentStatusText}
            <br />
            {t.incidents.IncidentSummary.severity}:{' '}
            {resolveSeverity(props.incident.severity)}
          </div>
        </div>
      </ExpansionPanel>
      {props.subscriptionFullData.subscription && (
        <ExpansionPanel
          icon="world"
          heading={t.incidents.IncidentSidebar.aboutService}
        >
          <div className="IncidentSidebar-details">
            <DataWithStatusAbout
              subscription={props.subscriptionFullData.subscription}
            />
          </div>
        </ExpansionPanel>
      )}
      {/*
  // TODO: Not added files to incident sidebar yet
  <ExpansionPanel icon="document" heading="Filer">
    files
  </ExpansionPanel>
  */}
      <ExpansionPanel
        icon="user"
        heading={t.incidents.IncidentSidebar.followsTheIncident}
      >
        <EditIncidentContacts incident={props.incident} />
      </ExpansionPanel>
      <ExpansionPanel
        icon="alarm"
        heading={t.incidents.IncidentSidebar.followsTheService}
      >
        <SubscriptionIncidentFollowers
          incidentFollowers={props.incidentFollowers}
        />
      </ExpansionPanel>
      <ExpansionPanel
        icon="contact"
        heading={t.incidents.IncidentSidebar.referenceNumber}
      >
        <EditCustomerReference
          className="IncidentSidebar-edit-customer-reference"
          disabled={props.incident.closed === true ?? false}
        />
      </ExpansionPanel>
    </BodyCard>
  );
};
