import React from 'react';
import {
  FilterSectionFilter,
  Button,
  CardHeader,
  CardBody,
  CardFooter,
  SelectedFilterChips,
} from 'src/lib/ui';
import {
  useTagsFilter,
  useSubscriptionStatusFilter,
  useSubscriptionTypeFilter,
  useDeliveryStatusFilter,
  useCaseFilter,
  useCpeTypeFilter,
} from 'src/lib/filters';
import { useRouter, useQueryParameters } from 'src/lib/utils';
import { t } from 'src/lib/i18n';

export const SidebarFilters = (props: { toggleFilters: () => void }) => {
  const tagsFilterProps = useTagsFilter();
  const statusFilterProps = useSubscriptionStatusFilter();
  const typeFilterProps = useSubscriptionTypeFilter();
  const deliveryStatusFilterProps = useDeliveryStatusFilter();
  const caseFilterProps = useCaseFilter();
  const cpeFilterProps = useCpeTypeFilter();
  const router = useRouter();
  const queryParams = useQueryParameters();

  const i18n = t.locations.Sidebar.filters;
  const filters = [
    tagsFilterProps,
    statusFilterProps,
    typeFilterProps,
    deliveryStatusFilterProps,
    caseFilterProps,
    ...(cpeFilterProps ? [cpeFilterProps] : []),
  ];

  const clearFilters = () => {
    const query = queryParams.get('query');
    const path = '/kart' + (query?.length ? `?query=${query}` : '');
    router.history.push(path);
  };
  return (
    <>
      <CardHeader>
        <Button
          onClick={props.toggleFilters}
          icon="arrow-large-left"
          hideLabel={true}
        >
          {i18n.back}
        </Button>
      </CardHeader>
      <CardBody className="Sidebar-mainContent">
        {filters.map(filter =>
          filter ? (
            <div className="mb-3" key={filter.id}>
              <FilterSectionFilter
                {...filter}
                className="mb-3"
                blackBorderOnSelect={true}
              />
            </div>
          ) : null
        )}
        {i18n.activeFilters}
        <SelectedFilterChips filterOptions={filters} />
      </CardBody>
      <CardFooter noSpacing={true}>
        <div className="p-3 text-right">
          <Button
            className="mr-3"
            base="primary"
            color="white"
            onClick={clearFilters}
          >
            {i18n.reset}
          </Button>
          <Button base="primary" color="purple" onClick={props.toggleFilters}>
            {i18n.back}
          </Button>
        </div>
      </CardFooter>
    </>
  );
};
