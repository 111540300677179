import { SearchFilter } from 'src/__types/graphql-global-types';
import { Filter } from 'src/areas/main/search';
import { sumBy } from 'lodash';

export const getFilterCountFromSearchFilter = (
  filters?: Array<Filter | SearchFilter>
) =>
  filters
    ? sumBy(
        filters
          .filter(f => f.filter !== 'group' && f.filter !== 'active')
          .map(f => f.value),
        'length'
      )
    : 0;
