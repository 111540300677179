import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useSearchTag';
import { SortOrder } from 'src/__types/graphql-global-types';

export const QUERY_SEARCH_TAG = gql`
  query useSearchTag(
    $customerId: ID!
    $query: String
    $sortBy: String
    $sortOrder: SortOrder
    $size: Int
    $page: Int
  ) {
    customer(id: $customerId) {
      id
      search(
        input: {
          types: ["tag"]
          query: $query
          sortBy: $sortBy
          sortOrder: $sortOrder
          size: $size
          page: $page
        }
      ) {
        size
        page
        totalResults
        results {
          ... on TagSearchResult {
            tag {
              id
              tag
              description
            }
          }
        }
      }
    }
  }
`;

export function useSearchTag(search: {
  sortBy?: string;
  sortOrder?: SortOrder;
  filterQuery?: string;
  size?: number;
  page?: number;
}) {
  const customer = useCustomer();
  const res = useQuery<t.useSearchTag, t.useSearchTagVariables>(
    QUERY_SEARCH_TAG,
    {
      variables: {
        customerId: customer.id,
        query: search.filterQuery ?? '',
        sortBy: search.sortBy,
        sortOrder: search.sortOrder,
        size: search.size,
        page: search.page,
      },
      errorPolicy: 'all',
      fetchPolicy: 'cache-and-network',
    }
  );

  return {
    refetch: res.refetch,
    loading: res.loading,
    totalResults: res.data?.customer?.search?.totalResults,
    /**
     * When we run a search query with `types: ["tag"]`, we _know_ that any and all results will
     * be of the `Tag` type. This cannot me modelled in GraphQL, so we override the generated
     * type to ensure that the results from `useSearchTag` is always of the `Tag` type in TypeScript.
     */
    data: (res.data?.customer?.search?.results ??
      []) as t.useSearchTag_customer_search_results_TagSearchResult[],
  };
}
