import React from 'react';
import { LegendProps } from 'recharts';
import cn from 'classnames';
import './CustomLegend.scss';

// Custom styling for Legend.
// All type will default to a dot, but if type === 'circle', we show circle.
export const CustomLegend = (
  props: LegendProps & { actions?: React.ReactNode }
) => {
  const { payload } = props;
  return (
    <div className="CustomLegend-container">
      <div className="CustomLegend">
        {payload?.map((item, idx) => (
          <div key={idx} className="CustomLegend-item">
            <div
              className={cn('CustomLegend-item-dot')}
              style={{ backgroundColor: item.color }}
            >
              {item.type === 'circle' && (
                <div className="CustomLegend-item-dot--inner" />
              )}
            </div>
            <div>{item.value}</div>
          </div>
        ))}
      </div>

      {props.actions ? (
        <div className="CustomLegend-actions">{props.actions}</div>
      ) : null}
    </div>
  );
};
