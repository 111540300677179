import * as React from 'react';

import { SearchMenuContactResult } from './SearchMenuContactResult';
import { SearchMenuCostCenterResult } from './SearchMenuCostCenterResult';
import { SearchMenuFileResult } from './SearchMenuFileResult';
import { SearchMenuIncidentResult } from './SearchMenuIncidentResult';
import { SearchMenuInvoiceResult } from './SearchMenuInvoiceResult';
import { SearchMenuOrderResult } from './SearchMenuOrderResult';
import { SearchMenuOrganisationResult } from './SearchMenuOrganisationResult';
import { SearchMenuSubscriptionResult } from './SearchMenuSubscriptionResult';
import { SearchMenuTagResult } from './SearchMenuTagResult';

const menuResultComponentsByTypename = {
  Contact: SearchMenuContactResult,
  CostCenter: SearchMenuCostCenterResult,
  File: SearchMenuFileResult,
  Incident: SearchMenuIncidentResult,
  Invoice: SearchMenuInvoiceResult,
  Order: SearchMenuOrderResult,
  Organisation: SearchMenuOrganisationResult,
  Subscription: SearchMenuSubscriptionResult,
  Tag: SearchMenuTagResult,
};

/**
 * SearchMenuResult looks at the `__typename` property of the entry object, and renders the appropriate `SearchMenu*Result` component.
 */
export const SearchMenuResult: React.FC<{
  id?: string | null;
  itemProps?: any;
  active?: boolean;
  selected?: boolean;
  entry?: any; // Type `SearchResult`; but TS cannot resolve the proper types here.
  disabled?: boolean;
  additionalInfo?: React.ReactElement | null;
}> = props => {
  const Element = menuResultComponentsByTypename[props.entry.__typename];

  if (!Element) {
    return null;
  }

  return <Element {...props} />;
};
