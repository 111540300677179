import { useRemoveTagsFromSubscriptions } from '../mutations/useRemoveTagsFromSubscriptions';
import { useAddTagsOnSubscriptions } from '../mutations/useAddTagsToSubscriptions';
import { undoPopup } from 'src/areas/main/documents/undo/UndoPopup';
import * as i18n from 'src/lib/i18n';

export const useUpdateTagsOnSubscriptions = () => {
  const { addTags, ...addContext } = useAddTagsOnSubscriptions();
  const { removeTags, ...removeContext } = useRemoveTagsFromSubscriptions();
  const loading = addContext.loading || removeContext.loading;

  const updateTags = async (input: {
    initial: {
      commonTags: string[];
      partialTags?: Array<{ tagId: string; subscriptionIds: string[] }>;
    };
    update: {
      subscriptionIds: string[];
      tagsToAdd?: string[];
      tagsToRemove?: string[];
    };
  }) => {
    const { update } = input;

    if (update.tagsToAdd?.length) {
      addTags(update.tagsToAdd, update.subscriptionIds);
    }

    if (update.tagsToRemove?.length) {
      removeTags(update.tagsToRemove, update.subscriptionIds);
    }

    undoPopup({
      msg: i18n.t.subscriptions.tags.changedTagsForServices(
        update.subscriptionIds.length
      ),
      mutation: () => undoTagsUpdate(input),
    });
  };

  const undoTagsUpdate = async (input: {
    initial: {
      commonTags: string[];
      partialTags?: Array<{ tagId: string; subscriptionIds: string[] }>;
    };
    update: {
      subscriptionIds: string[];
      tagsToAdd?: string[];
      tagsToRemove?: string[];
    };
  }) => {
    const { initial, update } = input;
    const partialTags = initial.partialTags?.map(o => o.tagId);
    const tagsToAddOnSomeSubscriptions = update.tagsToRemove
      ?.filter(tag => partialTags?.includes(tag))
      .map(tag => initial.partialTags?.find(o => o.tagId === tag));

    const tagsToAddOnAllSubscriptions = update.tagsToRemove?.filter(
      tag => !partialTags?.includes(tag)
    );

    const tagsToRemoveFromAllSubscriptions = update.tagsToAdd;

    if (tagsToAddOnAllSubscriptions?.length && update.subscriptionIds) {
      await addTags(tagsToAddOnAllSubscriptions, update.subscriptionIds);
    }
    if (tagsToRemoveFromAllSubscriptions?.length && update.subscriptionIds) {
      await removeTags(
        tagsToRemoveFromAllSubscriptions,
        update.subscriptionIds
      );
    }
    tagsToAddOnSomeSubscriptions?.forEach(async o => {
      if (o) await addTags([o.tagId], o.subscriptionIds);
    });
  };

  return { updateTags, loading };
};
