import React, { useRef, useState, useMemo } from 'react';
import { SearchInput, OptionSelectProps, OptionSelectFooter } from 'src/lib/ui';
import cs from 'classnames';
import { Icon } from '@telia/styleguide';
import './FilterSelection.scss';

export const FilterSelection = (
  props: OptionSelectProps & { goBack: () => void }
) => {
  const [search, setSearch] = useState('');

  const filterResults = (queryString: string) => {
    return props.options?.filter(option =>
      option?.label.toLowerCase().includes(queryString.toLowerCase())
    );
  };

  const options = useMemo(
    () => (search ? filterResults(search) : props.options),
    [search, props.options] // eslint-disable-line
  );

  const handleSave = (value: string) =>
    props.allowMultiple
      ? props.selectedOptions?.find(option => option === value)
        ? props.handleSave(props.selectedOptions.filter(o => o !== value))
        : props.handleSave([...(props.selectedOptions ?? []), value])
      : props.selectedOptions?.find(option => option === value)
      ? props.handleSave([])
      : props.handleSave([value]);

  const reset = () => {
    props.handleSave([]);
  };

  const ref = useRef(null);

  return (
    <div ref={ref} className="FilterSelection">
      {props.allowSearch ? (
        <div className="FilterSelection-search">
          <SearchInput
            value={search}
            onChange={e => setSearch(e.target.value)}
          />
        </div>
      ) : null}
      <div className="FilterSelection-menu">
        {options.map(option => (
          <button
            onClick={() => handleSave(option.value)}
            key={option.value}
            className={cs('FilterSelection-menu-item  d-flex py-3 px-4', {
              selected: !!props.selectedOptions?.find(s => s === option.value),
              negative: option.negative,
            })}
          >
            {option.label}
            {props.selectedOptions?.find(s => s === option.value) ? (
              <Icon className="ml-auto" icon="check-mark" />
            ) : null}
          </button>
        ))}
      </div>

      <OptionSelectFooter reset={reset} apply={props.goBack} />
    </div>
  );
};
