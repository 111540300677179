import gql from 'graphql-tag';

export const InstallationAddressFragment = gql`
  fragment InstallationAddressFragment on SubscriptionAddress {
    zip
    address
    city
    location {
      lon
      lat
    }
  }
`;
