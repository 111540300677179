import React from 'react';

import usePersonalizedDashboardData from './queries/usePersonalizedDashboardData';
import PersonalizedDashboardKpiCard from './PersonalizedDashboardKpiCard';
import { urlFor } from 'src/lib/ui';
import {
  DeliveryStatus,
  IncidentStatus,
  CpeStatus,
} from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';
import './PersonalizedDashboardGrid.scss';
import { activeIncidentText } from '../activeIncidentText';
import { useFeature } from 'src/lib/utils';

interface Props {
  /**
   * If present and non-empty; filter the data according to these tags.
   */
  tags?: string[];
}

export function PersonalizedDashboardGrid(props: Props) {
  const data = usePersonalizedDashboardData({ filterByTags: props.tags });
  const useNewActiveIncidents = useFeature('new-active-incidents');
  return (
    <div className="PersonalizedDashboardGrid">
      <div className="PersonalizedDashboardGrid-row">
        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="wireless-off"
            label={t.dashboard.new.kpi.subscriptionsWithUnreportedFaults}
            indicator="red"
            loading={data.loading}
            value={data.subscriptionsWithUnreportedFaults}
            linkTo={urlFor({
              area: 'subscriptions',
              status: CpeStatus.Critical,
              cases: 'withoutIncidents',
              group: 'nettverk',
              filterByTag: props.tags,
            })}
          />
        </div>

        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="time-waiting"
            label={t.dashboard.new.kpi.incidentsOnHoldPendingCustomer}
            indicator="red"
            loading={data.loading}
            value={data.incidentsOnHoldPendingCustomer}
            linkTo={urlFor({
              area: 'incidents',
              incidentStatus: IncidentStatus.onHoldPendingCustomer,
              filterByTag: props.tags,
            })}
          />
        </div>

        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="wrench"
            label={t.dashboard.new.kpi.servicesWithPlannedWork}
            indicator="orange"
            loading={data.loading}
            value={data.servicesWithPlannedWork}
            linkTo={urlFor({
              area: 'subscriptions',
              cases: 'withPlannedWork',
              filterByTag: props.tags,
            })}
          />
        </div>

        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="document"
            label={t.dashboard.new.kpi.overdueInvoices}
            indicator="red"
            loading={data.loading}
            value={data.overdueInvoices}
            linkTo={urlFor({
              area: 'invoices',
              invoiceStatus: 'overdue',
            })}
          />
        </div>
      </div>

      <div className="PersonalizedDashboardGrid-row">
        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="email"
            label={t.dashboard.new.kpi.activeIncidents}
            // indicator={kpi.color}
            loading={data.loading}
            value={activeIncidentText(data, useNewActiveIncidents)}
            linkTo={urlFor({
              area: 'incidents',
              filterByTag: props.tags,
            })}
          />
        </div>

        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="shopping"
            label={t.dashboard.new.kpi.activeOrders}
            // indicator={kpi.color}
            loading={data.loading}
            value={data.activeOrders}
            linkTo={urlFor({
              area: 'orders',
            })}
          />
        </div>

        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="delivery"
            label={t.dashboard.new.kpi.servicesAssigning}
            // indicator={kpi.color}
            loading={data.loading}
            value={data.servicesAssigning}
            linkTo={urlFor({
              area: 'subscriptions',
              deliveryStatus: DeliveryStatus.assigning,
              group: 'nettverk',
              filterByTag: props.tags,
            })}
          />
        </div>

        <div className="PersonalizedDashboardGrid-col">
          <PersonalizedDashboardKpiCard
            icon="stop"
            label={t.dashboard.new.kpi.servicesTerminating}
            // indicator={kpi.color}
            loading={data.loading}
            value={data.servicesTerminating}
            linkTo={urlFor({
              area: 'subscriptions',
              deliveryStatus: DeliveryStatus.terminating,
              group: 'nettverk',
              filterByTag: props.tags,
            })}
          />
        </div>
      </div>
    </div>
  );
}
