import { customerSupportEmail, customerSupportNumber } from './../../variables';
const orders = {
  PageHeader: {
    title: 'Ordre',
  },
  status: 'Status',
  orderStatus: {
    newOrder: 'Ny',
    inProgress: 'Under behandling',
    completed: 'Fullført',
    cancelled: 'Avbrutt',
    unknown: 'Ukjent',
  },

  orderLineStatus: {
    ready: 'Klar for prosessering',
    inProgress: 'Pågående',
    error: 'Feil',
    done: 'Ferdigstilt',
    cancelled: 'Kansellert',
    unknown: 'Ukjent',
  },
  orderType: {
    service: 'Tjenester',
    mobile: 'Mobil',
    mobileUnit: 'Mobilenheter',
  },

  orderTableColumns: {
    status: 'Status',
    description: 'Beskrivelse',
    category: 'Kategori',
    id: 'ID',
    created: 'Opprettet',
    updated: 'Oppdatert',
    deliveryDate: 'Leveransedato',
  },

  navTabs: {
    active: 'Aktive ordre',
    history: 'Historikk',
  },
  ListOrders: {
    noResult: 'Ingen ordre',
  },
  OrdersTable: {
    network: 'Nettverk',
    mobile: 'Mobil',
    other: 'Annet',
  },
  searchRes: (count, total) => `Viser ${count} av ${total}`,

  loadMoreBtn: 'Vis flere ordre',

  OrderContent: {
    PageHeader: {
      title: (id: string) => `Ordre ${id}`,
      breadcrumb: 'Ordre',
      actions: (customerName: string) => `${customerName} referanse`,
    },
    noData: 'Ordredetaljer er ikke tilgjengelige.',
  },

  ServiceOrderContent: {
    orderDetails: 'Bestillingsdetaljer',
    noOrder: 'Ordredetaljer er ikke tilgjengelige.',
    id: 'Ordre ID',
    casesCount: 'Antall saker',
    description: 'Beskrivelse',
    created: 'Opprettet',
    updated: 'Sist endret',
    status: 'Status',
    orderlines: {
      heading: 'Ordrelinjer',
      subscriptionId: 'Tjeneste-ID',
      subscription: 'Tjeneste',
      estimatedDate: 'Estimert dato',
      status: 'Status',
    },
    worklog: {
      heading: 'Arbeidslogg',
      date: 'Dato',
      content: 'Kommentar',
    },
    newOrderLabel: 'Vi har mottatt ordren og den vil snart bli behandlet',
    singleCompletedCase: () => '1 sak er ferdig behandlet',
    multipleCompletedCases: (count: number) =>
      `${count} saker er ferdig behandlet`,
    singleInProgressCase: () => '1 sak er under behandling',
    multipleInProgressCases: (count: number) =>
      `${count} saker er under behandling`,
    cancelledOrderLabel: `Har du spørsmål om ordren kan du kontakte oss via chat, ved å ringe ${customerSupportNumber}, eller sende e-post til ${customerSupportEmail}`,
    OrderLinesTable: {
      id: 'ID',
      status: 'Status',
      details: 'Detaljer',
      created: 'Opprettet',
      deliveryDate: 'Leveransedato',
      deletedCase: 'Saken har blitt slettet',
    },
    deliveryDateStatus: {
      committed: 'Bekreftet',
      estimated: 'Estimert',
      planned: 'Planlagt',
      closed: 'Lukket',
    },
    OrderLineSubscriptionModal: {
      parentCase: 'Overordnet sak',
      id: 'ID',
      created: 'Opprettet',
      updated: 'Sist endret',
      deliveryDate: 'Leveransedato',
      status: 'Status',
      description: 'Beskrivelse',
      alias: 'Alias',
      notSet: 'Ikke satt',
      subId: 'ID',
      type: 'Tjenestetype',
      address: 'Adresse',
      mainSubHeading: (text: string) => `Hovedtjeneste (${text})`,
      orderLineSubHeading: 'Tilhørende tjenester',
    },
  },
  MobileUnitOrderContent: {
    noOrder: 'Ordredetaljer er ikke tilgjengelige.',
    description: 'Beskrivelse',
    created: 'Opprettet',
    status: 'Status',
    quantity: 'Antall',
  },

  MobileOrderContent: {
    noOrder: 'Ordredetaljer er ikke tilgjengelige.',
    status: 'Status',
    orderDetails: 'Bestillingsdetaljer',
    orderType: 'Ordretype',
    orderDate: 'Ordredato',
    input: {
      refNum: 'Referansenummer kunde',
      helpText: 'Maks 40 tegn',
    },
    Alert: {
      schema:
        'Eierskifteskjema er sendt til sluttbruker, venter på signert skjema og godkjenning fra Telia',
      sentTo: (email: string) => `Sendt til: ${email}`,
      download: 'Klikk her for å laste ned eierskifteskjema (PDF)',
    },
    subscriber: 'Abonnent',
    name: 'Navn',
    email: 'E-post',
    phone: 'Telefonnummer',
    subscription: 'Abonnement',
    price: (cost: string | number) => `${cost} kr/mnd`,
    simcard: 'SIM-kort',
    mainSim: (simcardNumber: string | number) => `Hoved-SIM ${simcardNumber}`,
    orgAndCost: 'Fakturasted og kostnadssted',
    org: 'Fakturasted',
    costCenter: 'Kostnadssted',
    deliveryDetails: 'Leveringsadresse for SIM-kort',
  },
};

export default orders;
