import React, { useState, useEffect, useRef } from 'react';
import Table from 'reactstrap/lib/Table';
import { FileTableRow } from './FileTableRow';
import FolderTableRow from './FolderTableRow';
import { getFileSortKey } from '../utils/sortUtils';
import { usePath } from 'src/areas/main/documents/contexts/PathContext';
import { orderBy } from 'lodash';
import { Card } from 'src/lib/ui';
import { memoize } from 'lodash';
import './DocumentTable.scss';
import { t } from 'src/lib/i18n';
import { Folder, File } from 'src/lib/types';
import { useDocumentsContext } from '../DocumentsContext';
import { Icon } from '@telia/styleguide';
import { useWindowScroll } from './useWindowScroll';

interface Props {
  folder: Folder;
  parentFolder?: Folder;
  subFolders: Folder[];
  folders: Folder[];
  files: File[];
  hideBreadcrumbs?: boolean;
}
const SortIcon = (props: { order: string }) => (
  <Icon icon={props.order === 'asc' ? 'arrow-down' : 'arrow-up'} />
);

const DocumentTable: React.FC<Props> = props => {
  const rowHeight = 70;
  const containerRef = useRef<HTMLDivElement>(null);
  const [sortedRows, setSortedRows] = useState<Array<File | Folder>>([]);
  const renderList = useWindowScroll<File | Folder>({
    allNodes: sortedRows,
    initialOffsetTop: containerRef.current?.offsetTop || 0,
    rowHeight,
  });

  const [activeSort, setActiveSortState] = useState<{
    key: string;
    order: 'asc' | 'desc';
  }>({ key: 'name', order: 'asc' });
  const ctx = usePath();
  const { allowEditFileSharing } = useDocumentsContext();
  const setActiveSort = memoize((key: string) => () => {
    const order =
      key === activeSort.key
        ? activeSort.order === 'asc'
          ? 'desc'
          : 'asc'
        : 'asc';

    setActiveSortState({ key, order });
  });

  const handleKeyUp = (e: React.KeyboardEvent, key: string) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      setActiveSort(key)();
    }
  };

  useEffect(() => {
    const sortedFolders = orderBy<Folder>(
      props.subFolders,
      [f => (f[activeSort.key] ? f[activeSort.key].toLowerCase() : null)],
      [activeSort.order]
    );

    const fileSortKey = getFileSortKey(activeSort.key);

    const sortedFiles = orderBy<File>(
      props.files,
      activeSort.key !== 'size'
        ? [f => (f[fileSortKey] ? f[fileSortKey].toLowerCase() : null)]
        : [activeSort.key],
      [activeSort.order]
    );

    const filesAndFolders: Array<File | Folder> = [
      ...sortedFolders,
      ...sortedFiles,
    ];

    setSortedRows(filesAndFolders);
  }, [
    props.folder,
    props.files,
    activeSort.key,
    activeSort.order,
    props.subFolders,
  ]);
  if (renderList) {
    return (
      <Card className="p-0">
        <div ref={containerRef}>
          <Table
            hover={true}
            responsive={true}
            className="DocumentTable"
            style={{
              height: sortedRows.length * rowHeight,
              overflow: 'hidden',
            }}
          >
            <colgroup>
              <col span={1} />
              <col span={1} style={{ width: '18%' }} />
              <col span={1} style={{ width: '18%' }} />
              <col span={1} style={{ width: '18%' }} />
              {allowEditFileSharing && (
                <col span={1} style={{ width: '60px' }} />
              )}
              <col span={1} style={{ width: '60px' }} />
            </colgroup>
            <thead>
              <tr className="pt-3 pb-3">
                {[
                  { key: 'name', label: t.documents.thName },
                  { key: 'createdAt', label: t.documents.thLastChange },
                  { key: 'createdBy', label: t.documents.thLastChangeBy },
                  { key: 'size', label: t.documents.thSize },
                ].map(v => (
                  <th
                    key={v.key}
                    onClick={setActiveSort(v.key)}
                    onKeyDown={e => handleKeyUp(e, v.key)}
                    tabIndex={0}
                  >
                    {v.label}
                    {activeSort.key === v.key && (
                      <SortIcon order={activeSort.order} />
                    )}
                  </th>
                ))}
                {allowEditFileSharing && <th> {t.documents.thSharing} </th>}
                <th />
              </tr>
            </thead>
            {!props.subFolders.length && !props.files.length ? (
              <tbody>
                <tr>
                  <td colSpan={6}>{t.documents.folderIsEmpty}</td>
                </tr>
              </tbody>
            ) : (
              <tbody>
                {renderList.map((f, i) => {
                  if (!f) {
                    return <tr key={i} />;
                  }
                  if (f.__typename === 'File') {
                    return (
                      <FileTableRow
                        key={`file-${f.id}`}
                        file={f}
                        context={ctx}
                        displaySharing={allowEditFileSharing}
                      />
                    );
                  }
                  return (
                    <FolderTableRow
                      key={f.id}
                      folder={f}
                      allowDisplaySharing={allowEditFileSharing}
                    />
                  );
                })}
              </tbody>
            )}
          </Table>
        </div>
      </Card>
    );
  }
  return null;
};

export default DocumentTable;
