import * as React from 'react';
import { Step, StepShape, FormikTextField } from 'src/lib/flow';
import { colors, Chip, Attribute, Attributes } from 'src/lib/ui';
import * as yup from 'yup';
import { t } from 'src/lib/i18n';

export interface Step1Values {
  useGnrBnr?: boolean;
  address?: string;
  postCode?: string;
  country?: string;
  knr?: string;
  gnr?: string;
  bnr?: string;
}

const Step1: Step<Step1Values> = (): StepShape<Step1Values> => {
  return {
    title: t.ordering.OrderIpVpn.Step1.title,

    validationSchema: yup.object({
      useGnrBnr: yup.boolean().required(),

      address: yup.string().when('useGnrBnr', {
        is: false,
        then: yup.string().required(t.ordering.required),
      }),

      postCode: yup.string().when('useGnrBnr', {
        is: false,
        then: yup.string().required(t.ordering.required),
      }),

      country: yup.string().when('useGnrBnr', {
        is: false,
        then: yup.string().required(t.ordering.required),
      }),

      knr: yup.string().when('useGnrBnr', {
        is: true,
        then: yup.string().required(t.ordering.required),
      }),

      gnr: yup.number().when('useGnrBnr', {
        is: true,
        then: yup
          .number()
          .typeError(t.ordering.numberOnly)
          .required(t.ordering.required),
      }),

      bnr: yup.number().when('useGnrBnr', {
        is: true,
        then: yup
          .number()
          .typeError(t.ordering.numberOnly)
          .required(t.ordering.required),
      }),
    }),

    renderSummary: ({ values }) => {
      return (
        <div>
          {values.useGnrBnr ? (
            <Attributes>
              <Attribute
                label={t.ordering.OrderIpVpn.Step1.knrSummary}
                value={values.knr}
              />
              <Attribute
                label={t.ordering.OrderIpVpn.Step1.gnrSummary}
                value={values.gnr}
              />
              <Attribute
                label={t.ordering.OrderIpVpn.Step1.bnrSummary}
                value={values.bnr}
              />
            </Attributes>
          ) : (
            <Attributes>
              <Attribute
                label={t.ordering.OrderIpVpn.Step1.addressSummary}
                value={values.address}
              />
              <Attribute
                label={t.ordering.OrderIpVpn.Step1.postCodeSummary}
                value={values.postCode}
              />
              <Attribute
                label={t.ordering.OrderIpVpn.Step1.countrySummary}
                value={values.country}
              />
            </Attributes>
          )}
        </div>
      );
    },

    renderForm: ({ flow }) => (
      <>
        <p className="small mt-0 mb-2" style={{ color: colors.greyDarkText }}>
          {t.ordering.OrderIpVpn.Step1.summary}
        </p>

        <div className="mt-4">
          <Chip
            label={t.ordering.OrderIpVpn.Step1.toggleAddress}
            onClick={() => flow.setFieldValue('useGnrBnr', false)}
            icon="map"
            active={!flow.values.useGnrBnr}
            className="mr-3"
          />

          <Chip
            label={t.ordering.OrderIpVpn.Step1.toggleGnrBnr}
            onClick={() => flow.setFieldValue('useGnrBnr', true)}
            icon="home"
            active={flow.values.useGnrBnr}
          />
        </div>

        {flow.values.useGnrBnr ? (
          <div className="row">
            <FormikTextField
              id="knr"
              label={t.ordering.OrderIpVpn.Step1.knrLabel}
              required={true}
              className="col-md-4"
              bordered={true}
            />
            <FormikTextField
              id="gnr"
              label={t.ordering.OrderIpVpn.Step1.gnrLabel}
              required={true}
              className="col-md-4"
              bordered={true}
            />
            <FormikTextField
              id="bnr"
              label={t.ordering.OrderIpVpn.Step1.bnrLabel}
              required={true}
              className="col-md-4"
              bordered={true}
            />
          </div>
        ) : (
          <div className="row">
            <FormikTextField
              id="address"
              label={t.ordering.OrderIpVpn.Step1.addressLabel}
              required={true}
              className="col-md-6"
              bordered={true}
            />
            <FormikTextField
              id="postCode"
              label={t.ordering.OrderIpVpn.Step1.postCodeLabel}
              required={true}
              className="col-md-3"
              bordered={true}
            />
            <FormikTextField
              id="country"
              label={t.ordering.OrderIpVpn.Step1.countryLabel}
              required={true}
              className="col-md-3"
              bordered={true}
            />
          </div>
        )}
      </>
    ),
  };
};

export default Step1;
