import * as React from 'react';
import { PathContextProvider } from './contexts/PathContext';
import DocumentsContent from './DocumentsContent';
import { getFilesByFolderId, getFoldersByParentId } from './utils/folderUtils';
import { useDocumentsContext } from './DocumentsContext';
import { Folder, File } from 'src/lib/types';
import { Loading } from 'src/lib/ui';

const DocumentsContainer: React.FC<{}> = () => {
  const documentsContext = useDocumentsContext();
  const {
    path,
    navigateTo,
    loading,
    subscriptionId,
    orderId,
  } = documentsContext;
  const { files, folders } = documentsContext.documentArchive;

  // First, see what the URL matches. We should end up with a Folder object -- either the
  // root folder, a specified folder, or the folder in which the currently requested file resides.
  let folder: Folder | undefined;
  let file: File | undefined;

  if (path.substr(0, 3) === '/f/') {
    const fileId = path.substr(3);
    file = files.find(f => f.id === fileId);
    if (file) {
      const folderId = file.folderId;
      folder = folders.find(f => f.id === folderId);
    }
    // 404 check for file
  } else if (path.substr(0, 3) === '/m/') {
    const folderId = path.substr(3);
    // 404 check for folder?
    folder = folders.find(f => f.id === folderId);
  } else if (path === '' || path === '/') {
    if (subscriptionId || orderId) {
      folder = folders.find(f => f.createdBy === 'system');
    } else {
      folder = folders.find(f => !f.parentId);
    }
  }
  if (loading) {
    return <Loading />;
  }

  if (folder) {
    const parentId = folder.parentId;

    const parentFolder = folder
      ? folders.find(f => f.id === parentId)
      : undefined;

    const subFolders = getFoldersByParentId(folder.id, folders);
    const folderFiles = getFilesByFolderId(folder.id, files);

    return (
      <PathContextProvider
        value={{
          folder,
          folderFiles,
          allFiles: files,
          allFolders: folders,
          subFolders,
          parentFolder,
          navigateTo: navigateTo,
          loading,
          file,
        }}
      >
        <DocumentsContent />
      </PathContextProvider>
    );
  }
  return <p>No such folder or file</p>;
};

export default DocumentsContainer;
