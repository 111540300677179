/**
 * Based on `src/main/frontend/util/Analytics.js` in `https://stash.getaccess.no/scm/get/app-myget.git`.
 */

// import config from '../config';

/**
 * To receive these events in google analytics you need to
 * create triggers for custom events in google tag manager,
 * and set the trigger on a tag.
 * https://support.google.com/tagmanager/answer/7679219?hl=en
 */

import { DashboardActions } from './Dashboard';
import { UserPreferenceActions } from './UserPreferences';
import { MapActions } from './Map';
import { IncidentsActions } from './Incidents';
import { NotificationSettingsActions } from './NotificationSettings';
import { InvoiceActions } from './Invoice';
import { OrdersActions } from './Orders';
import { ContactsActions } from './Contacts';
import {
  ServiceActions,
  DataActions,
  MobileActions,
  TagsActions,
} from './Service';
import { DocumentsActions } from './Documents';
import { StatisticsActions } from './Statistics';

export type Category =
  | 'Dashboard'
  | 'Map'
  | 'Incidents'
  | 'Notifications Settings'
  | 'Orders'
  | 'Invoice'
  | 'Contacts'
  | 'Data'
  | 'Mobile'
  | 'Tele'
  | 'Tv'
  | 'Tags'
  | 'Documents'
  | 'Worklog'
  | 'Statistics'
  | 'All services';

type Action =
  | DashboardActions
  | MapActions
  | IncidentsActions
  | NotificationSettingsActions
  | InvoiceActions
  | OrdersActions
  | UserPreferenceActions
  | ContactsActions
  | ServiceActions
  | DataActions
  | MobileActions
  | TagsActions
  | DocumentsActions
  | StatisticsActions;

type Event = 'custom_event';

interface DataLayerOpts {
  event?: Event;
  category: Category;
  action: Action;
  label?: string;
  value?: string;
}

interface WDL {
  dataLayer: {
    push: (opts: DataLayerOpts) => void;
  };
}

export const push = (opts: DataLayerOpts) => {
  if ('dataLayer' in window) {
    ((window as unknown) as WDL).dataLayer.push({
      ...opts,
      event: 'custom_event',
      label: opts.label ?? '',
      value: opts.value ?? '',
    });
  }
};
