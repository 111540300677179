import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

export const SERVICE_PLUS_PERSON_ON_ADDRESS_QUERY = gql`
  query useServicePlusPersonsOnAddress($customerId: ID!, $addressId: ID!) {
    customer(id: $customerId) {
      servicePlusPersonsOnAddress(addressId: $addressId) {
        id
        firstName
        lastName
        email
        phone
        customerId
      }
      __typename
    }
    __typename
  }
`;

export const useServicePlusPersonsOnAddress = (customerId, addressId) => {
  const {
    loading: servicePlusPersonsOnAddressLoading,
    error: servicePlusPersonsOnAddressError,
    data: servicePlusPersonsOnAddressData,
  } = useQuery(SERVICE_PLUS_PERSON_ON_ADDRESS_QUERY, {
    variables: { customerId: customerId, addressId: addressId },
    fetchPolicy: 'no-cache',
  });

  return {
    servicePlusPersonsOnAddressLoading,
    servicePlusPersonsOnAddressError,
    servicePlusPersonsOnAddressData,
  };
};
