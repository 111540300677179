import gql from 'graphql-tag';

export const SubscriptionFragment = gql`
  fragment SubscriptionSearchResult on Subscription {
    id
    subscriptionId
    alias
    tags {
      tag
      id
    }
    deliveryStatus
    transmissionNumber
    ownerAttributes {
      firstName
      lastName
      employeeNumber
      position
      email
    }
    organisation {
      id
      name
    }
    costCenter {
      id
      code
      description
    }
    phoneNumbers {
      type
      phoneNumber
      seriesStart
      seriesEnd
      validTo
    }
    name
    deliveryStatus
    type
    group
    lastModified
    installationAddress {
      zip
      address
      city
      location {
        lat
        lon
      }
    }
    installationAddress2 {
      zip
      address
      city
      location {
        lat
        lon
      }
    }
    cpe {
      status
      model
    }
    activeIncidents {
      id
      incidentStatus
      active
      shortDescription
    }
    plannedWork {
      id
      state
    }
    redundancies {
      id
      subscriptionId
      name
      deliveryStatus
      type
      group
      lastModified
      alias
      isMonitoringPossible
      cpe {
        status
      }
    }
    isRedundancy
    serviceBandwidth {
      downstream
      upstream
    }
    hasExtendedStatistics
    hasQosStatistics
    hasGsmStatistics
    accessSupplier
    networkToNetworkInterface
    accessType
    isMonitoringPossible
  }
`;
