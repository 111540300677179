import React, { useState, useEffect } from 'react';
import { toast, colors, Button } from 'src/lib/ui';
import SmsForm from 'src/areas/mobile/sms/lib/SmsForm';
import { orderBy } from 'lodash';
import { t } from 'src/lib/i18n';
import { MinSidePageHeader } from 'src/areas/mobile/ui/MinSidePageHeader/MinSidePageHeader';
import { useSmsHistory } from 'src/areas/mobile/lib/useSmsHistory';
import { useSendSms } from 'src/areas/mobile/lib/useSendSms';
import { SmsItem } from 'src/areas/mobile/sms/lib/SmsItem';

const Sms = () => {
  const { smsHistory } = useSmsHistory();
  const { sendSms, ...result } = useSendSms();
  const [recipients, setRecipients] = useState<string[]>([]);
  const [message, setMessage] = useState<string>('');
  const [numSmsToShow, setNumSmsToShow] = useState<number>(
    smsHistory && smsHistory.length ? 5 : 0
  );
  useEffect(() => {
    if (result?.sms && !result.error && result.sms.recipients?.length) {
      result.sms.recipients.forEach(s =>
        s?.success
          ? toast('ok', t.mobile.sms.sentTo + ' ' + s.msisdn)
          : toast('error', t.mobile.sms.failed + ' ' + s?.msisdn)
      );
    }
  }, [result]);

  useEffect(() => setNumSmsToShow(smsHistory && smsHistory.length ? 5 : 0), [
    smsHistory,
  ]);

  const addRecipient = (recipient: string) => {
    const existis = !!recipients.find(r => r === recipient);
    if (!existis) {
      setRecipients([...recipients, recipient]);
    }
  };

  const removeRecipient = (recipient: string) =>
    setRecipients(recipients.filter(r => r !== recipient));

  const handleMessageChange = (value: string) => setMessage(value);

  const handleSendSms = (
    e?: React.SyntheticEvent<HTMLButtonElement, Event>
  ) => {
    e?.preventDefault();
    sendSms(message, recipients)
      .then(r => {
        setRecipients([]);
        setMessage('');
      })
      .catch(err => console.log(err));
  };

  const orderedSmsHistory = orderBy(smsHistory, ['timestamp'], ['desc']).filter(
    sms => !!sms?.message && !!sms?.recipients?.length
  );
  const smsToShow = orderedSmsHistory.slice(0, numSmsToShow);

  // Inline CSS for convinience - should be moved to external .scss file
  return (
    <div className="p-0">
      <MinSidePageHeader title="Send SMS" />
      <div className="container">
        <SmsForm
          send={e => handleSendSms(e)}
          message={message}
          addRecipient={addRecipient}
          recipients={recipients}
          removeRecipient={removeRecipient}
          handleMessageChange={handleMessageChange}
        />

        {numSmsToShow ? (
          <div className="py-5">
            <h3>{t.mobile.sms.previouslySentSms}</h3>
            {smsToShow.map((sms, i) => (
              <SmsItem key={i} sms={sms} />
            ))}

            <div className="pb-5 pt-3">
              <div
                className="mt-2 text-center"
                style={{ color: colors.greyDarkText }}
              >
                {t.mobile.sms.showXofY(
                  Math.min(numSmsToShow, orderedSmsHistory.length),
                  orderedSmsHistory.length
                )}
              </div>
              {numSmsToShow < orderedSmsHistory.length && (
                <Button
                  className="d-block mx-auto"
                  color="white"
                  onClick={() => setNumSmsToShow(numSmsToShow + 5)}
                >
                  {t.mobile.sms.showMoreSMS}
                </Button>
              )}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

export default Sms;
