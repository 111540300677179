import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { NotificationFragment } from 'src/lib/types';
import { ApolloQueryResult } from 'apollo-client';
import { useCustomer } from 'src/lib/global/CustomerContext';
import * as t from './__types/useNotifications';
import { SortOrder, SearchFilter } from 'src/__types/graphql-global-types';
import {
  WrappedSearchResult,
  unwrapWrappedSearchResults,
} from 'src/areas/main/search';

export const NOTIFICATIONS_QUERY = gql`
  query useNotifications($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        size
        totalResults
        totalResultsByDomain {
          domain
          resultCount
        }
        cursor
        results {
          __typename
          ... on NotificationSearchResult {
            notification {
              ...NotificationSearchResult
            }
          }
        }
      }
    }
  }
  ${NotificationFragment}
`;

interface Props {
  size?: number;
  filters?: SearchFilter[];
  sortBy?: string;
  sortOrder?: SortOrder | null;
}
interface Result {
  search?: {
    results: t.useNotifications_customer_search_results_NotificationSearchResult_notification[];
    totalResults: number | null;
  };
  loading: boolean;
  refetch: () => Promise<ApolloQueryResult<t.useNotifications>>;
}

export type Notification = t.useNotifications_customer_search_results_NotificationSearchResult_notification;

export const useNotifications = (props: Props): Result => {
  const customer = useCustomer();
  const { data, loading, refetch } = useQuery<
    t.useNotifications,
    t.useNotificationsVariables
  >(NOTIFICATIONS_QUERY, {
    variables: {
      customerId: customer.id,
      search: {
        sortBy: props.sortBy,
        sortOrder: props.sortOrder,
        filters: props.filters,
        types: ['notification'],
        size: props.size,
      },
    },
    errorPolicy: 'all',
    pollInterval: 30000,
    fetchPolicy: 'network-only',
  });
  return {
    search: {
      totalResults: data?.customer?.search?.totalResults || null,
      results: (data?.customer?.search?.results
        ? unwrapWrappedSearchResults(
            data?.customer?.search?.results as WrappedSearchResult[]
          )
        : []) as t.useNotifications_customer_search_results_NotificationSearchResult_notification[],
    },
    loading,
    refetch,
  };
};
