import React from 'react';
import { BodyCard, Attributes, Attribute } from 'src/lib/ui';
import { t, useI18nContext } from 'src/lib/i18n';
import { formatDate, formatBandwidth } from 'src/lib/utils';

interface Props {
  subscription: {
    id: string;
    name: string | null;
    startTime?: string | null;
    endTime?: string | null;
    accessBandwidth?: {
      downstream?: null | number;
      upstream?: null | number;
    } | null;
  };
}

export const DefaultAboutCard = ({ subscription }: Props) => {
  const i18nContext = useI18nContext();
  if (!subscription) {
    return null;
  }
  const i18n = t.subscriptions.DefaultAboutCard;

  return (
    <BodyCard heading={i18n.heading}>
      <Attributes>
        <Attribute label={i18n.type} value={subscription.name} />
        <Attribute label={i18n.id} value={subscription.id} />
        <Attribute
          label={i18n.created}
          value={
            subscription.startTime
              ? formatDate(subscription.startTime, 'long', i18nContext.locale)
              : ''
          }
        />
        {subscription.accessBandwidth ? (
          <Attribute
            label={i18n.accessBandwidth}
            value={formatBandwidth(subscription.accessBandwidth, 0)}
          />
        ) : null}

        <Attribute
          label={i18n.validUntil}
          value={
            subscription.endTime
              ? formatDate(subscription.endTime, 'long', i18nContext.locale)
              : i18n.validNotSet
          }
        />
      </Attributes>
    </BodyCard>
  );
};
