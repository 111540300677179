import React from 'react';
import Script from './components/Script';
import { useAuthContext } from 'src/modules/auth/AuthContext';
import { t } from 'src/lib/i18n';

function ChatWidget() {
  const auth = useAuthContext();

  if (!auth.user) {
    return null;
  }

  return (
    <>
      <Script url="https://wds.ace.teliacompany.com/wds/instances/Jg3KjqJs/ACEWebSDK.min.js" />
      <a href="//telia-no.humany.net/tno-ace-datakom-chat-minportal">
        {t.chatSupport.chat}
      </a>
    </>
  );
}

export default ChatWidget;
