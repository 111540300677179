import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { Tag } from 'src/lib/types';

export const SearchMenuTagResult: React.FC<SearchMenuResultProps<
  Tag
>> = props => (
  <SearchMenuResultItem {...props} icon="tags" title={props.entry.tag} />
);
