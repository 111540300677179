import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useGenerateReportFixedANoYearly';
import { useCustomer } from 'src/lib/global';

export const GENERATE_FIXED_REPORT_FOR_A_NO_YEARLY = gql`
  mutation useGenerateReportFixedANoYearly(
    $customerId: ID!
    $year: String!
    $organisationId: ID
  ) {
    generateReportFixedAnumberYearly(
      input: {
        customerId: $customerId
        year: $year
        organisationId: $organisationId
      }
    ) {
      url
      filename
    }
  }
`;

export const useFixedReportANoYearly = () => {
  const customer = useCustomer();

  const [mutation, { loading, error }] = useMutation<
    t.useGenerateReportFixedANoYearly,
    t.useGenerateReportFixedANoYearlyVariables & { customerId: string }
  >(GENERATE_FIXED_REPORT_FOR_A_NO_YEARLY);

  const generate = async (year: string, organisationId?: string) =>
    await mutation({
      variables: {
        customerId: customer.id,
        year: year,
        organisationId,
      },
    });

  return {
    generate,
    loading,
    error,
  };
};
