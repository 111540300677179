import * as React from 'react';
import { urlFor } from 'src/lib/ui';
import { ServicePlusCard } from 'src/areas/main/servicePlus/ServicePlusCard';
import cs from 'classnames';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

interface Props {
  addressId: number;
  address?: string;
  postalCode?: string;
  city?: string;
  created?: string;
  createdBy?: string;
  creatorEmail?: string;
  className?: string;
  style?: React.CSSProperties;
}

export function AddressCard(props: Props) {
  const translations = t.servicePlus;
  const url = urlFor({ servicePlusAddress: props.addressId.toString() });
  return (
    <ServicePlusCard
      className={cs('AddressCard', props.className)}
      style={props.style}
      linkTo={url}
    >
      <Icon icon="map" style={{ fontSize: '2rem' }} />
      <p>
        {props.address}
        <br />
        {props.postalCode} {props.city}
      </p>
      <div style={{ lineHeight: '1.2rem' }}>
        <small>{translations.newLocation.contact}:</small>
        <br />
        <small>{props.createdBy}</small>
        <br />
        <small>{props.creatorEmail}</small>
      </div>
    </ServicePlusCard>
  );
}
