import { difference } from 'lodash';
import { useQueryParameters } from 'src/lib/utils';
import { useEffect, useState } from 'react';
import { trackMapFilter } from 'src/lib/analytics';

const hasChanged = (previous: string[], current: string[]) =>
  difference(previous, current).length || difference(current, previous).length;

export const useTrackMapFilters = () => {
  const query = useQueryParameters();

  /**
   * currentValues contains the filters directly from the url.
   * This reference will change on every render,
   * regardless of whether the values have changed
   * We preserve the values in state,
   * to only track an actual change of value event
   */

  const currentValues = {
    tags: query.getArray('tag'),
    status: query.getArray('status'),
    type: query.getArray('type'),
    cases: query.getArray('cases'),
    deliveryStatus: query.getArray('deliveryStatus'),
    cpeType: query.getArray('cpe'),
  };

  const [preservedFilterValues, setPreservedFilterValues] = useState(
    currentValues
  );

  const track = (type: string, value: string[]) => {
    if (hasChanged(value, preservedFilterValues[type])) {
      setPreservedFilterValues({ ...preservedFilterValues, [type]: value });
      trackMapFilter(type);
    }
  };
  useEffect(() => {
    Object.entries(currentValues).forEach(entry => track(...entry));
  }, [currentValues]); // eslint-disable-line
};
