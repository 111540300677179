import * as React from 'react';
import { Subscription, getSubscriptionDisplayTitles } from 'src/lib/types';
import { getSubscriptionBreadcrumbs } from 'src/lib/types/subscription/getSubscriptionBreadcrumbs';
import {
  SubscriptionPageHeader,
  SubscriptionSummaryContainer,
  SubscriptionMainContentPlaceholder,
} from '../../layout';

import { TeleSubscriptionAbout } from './TeleSubscriptionAbout';
import { TelePhoneNumberList } from './TelePhoneNumberList';
import {
  SubscriptionCostDetails,
  AdditionalInformationCard,
  TechnicalContactsCard,
} from '../../Pages/Components';

interface Props {
  subscription: Subscription;
}

const TeleSubscriptionPage = ({ subscription }: Props) => {
  const breadcrumbs = getSubscriptionBreadcrumbs(subscription);
  const { title } = getSubscriptionDisplayTitles(subscription);
  return (
    <>
      <SubscriptionPageHeader
        description=""
        subscription={subscription}
        title={title}
        breadcrumbs={breadcrumbs}
      />
      <div className="container mt-4">
        <SubscriptionSummaryContainer
          subscription={subscription}
          main={
            <>
              {subscription?.phoneNumbers?.length ? (
                <TelePhoneNumberList subscription={subscription} />
              ) : (
                <SubscriptionMainContentPlaceholder />
              )}
            </>
          }
          sidebar={
            <>
              <TeleSubscriptionAbout subscription={subscription} />
              <SubscriptionCostDetails subscription={subscription} />
              <TechnicalContactsCard subscription={subscription} />
              <AdditionalInformationCard subscription={subscription} />
            </>
          }
        />
      </div>
    </>
  );
};

export default TeleSubscriptionPage;
