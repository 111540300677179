import { push } from './Analytics';

export type StatisticsActions = 'update_view' | 'update_interval' | 'filter';

export function trackStatisticsView(label: string, value?: string) {
  push({
    category: 'Statistics',
    action: 'update_view',
    label: label,
    value: value,
  });
}

export function trackStatisticsInterval(label: string) {
  push({
    category: 'Statistics',
    action: 'update_interval',
    label: label,
  });
}

export function trackStatisticsFilter(label: string, value: string) {
  push({
    category: 'Statistics',
    action: 'filter',
    label: label,
    value: value,
  });
}
