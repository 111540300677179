import { useQuery } from 'react-apollo';
import * as types from 'src/areas/dev/changelog/query/__types/WithChangelogs';
import { GET_CHANGELOGS } from 'src/areas/dev/changelog/query/WithChangelogs';

type Props = {
  page: number;
  size: number;
};

export const useQueryUserHistoryChangelogs = (props: Props) => {
  return useQuery<types.WithChangelogs, types.WithChangelogsVariables>(
    GET_CHANGELOGS,
    {
      variables: {
        input: {
          page: props.page,
          size: props.size,
          from: '2018-12-01 00:00:00',
          includeUnpublished: false,
        },
      },
    }
  );
};
