import { customerSupportEmail, customerSupportNumber } from './../../variables';
import settingsNo from '../../nb/Mobile/settings';

const settings: typeof settingsNo = {
  noSettings: 'No settings',
  edit: 'Edit',
  email: 'Email',
  name: 'Name',
  firstName: 'First name',
  middleName: 'Middle name',
  lastName: 'Last name',
  address: 'Addresse',
  postCode: 'Post code',
  postArea: 'Post area',
  country: 'Country',
  streetNum: 'Number',
  activated: 'Activated',
  notActivated: 'Not activated',
  attention: 'Att.',
  employeeNum: 'Employee number',
  employeePosition: 'Employee position',
  changeAddress: 'You can change the address by going to origin in settings',

  forwarding: {
    required: 'Enter the number to forward the call to',
    forwarding: 'Call Forwarding',
    invalidNumber: 'Invalid phone number',
    forwardedToVoicemail: 'Forward call to voicemail',
    forwardingInfo:
      'When you are unable to answer a call, the call can be forwarded to another number',
    forwardAll: 'Forward all calls',
    forwardAllInfo:
      'When somebody tries to call you, their call will be automatically forwarded to the below number. If you do not provide a number, incomin calls will be forwarded to voicemail',
    forwardBusy: 'Forward calls when you are busy',
    forwardBusyInfo:
      'When somebody tries to call you, and you are preoccupied with another call, their call will be automatically forwarded to the below number.',
    forwardNoAnswer: 'Forward calls when you do not answer',
    forwardNoAnswerInfo:
      'When somebody tries to call you and you do not reply, their call will be automatically forwarded to the below number.',
    phoneNumberLabel: 'Phone number',
    callRecipient: phoneNumber => `Forward call to ${phoneNumber}`,
  },

  smsRoaming: {
    smsRoamingAlert: 'SMS concerning roaming prices',
    smsRoamingAlertInfo:
      'Get pricing information on SMS when the phone is used abroad',
  },

  phonebook: {
    service: 'Telephone directory services',
    reserveFromDirectory: `Exclude from telephone directories`,
    showCompanyInformation: 'Show only company',
    phonebookInfo:
      'Telephone directories are listings where others can locate your contact information, e.g. Gulesider, 1880, 1881.',
  },
  origin: {
    title: 'Origin',
    description:
      'When you call an emergency number your number will be checked against the national database. Name and address you provide here will be transferred to the emergency services',
  },

  profileUpdate: {
    title: 'Update profile',
  },

  voicemail: {
    voicemail: 'Voicemail',
    notificationOnSMS: 'Get notified on sms',
    sendSmsOnMessage: `Send an SMS when somebody leaves a message in my voicemail.`,
    voicemailInfo:
      'When you are unable to pick up your phone, the caller can leave a voicemail for you. Above you can choose to be notified of a voicemail. ',
  },

  visualVoicemail: {
    visualVoicemailForiPhone: 'Visual Voicemail for iPhone',
    VisualVoicemail: 'Use Visual Voicemail',
    visualVoicemailInfo:
      'Visual Voicemail gathers voicemail messages on your phone.  Visual Voicemail is only supported by iPhones (4S or newer). If you have a different phone, changing this setting will not cause any changes. ',
  },

  barring: {
    usageRestrictions: 'Usage restrictions',
    restrictUsage: 'Set restrictions on usage',
    description:
      'You can restrict the usage for this subscription by blocking services and usage',
    barData: {
      barData: 'Bar mobile data',
      barAllData: 'Bar all mobile data',
      barRoamingData: 'Bar mobile data while roaming',
    },
    barCalls: {
      outgoing: 'Bar outgoing calls and messages',
      incoming: 'Bar incoming call and messages',
      barCallsMsgIncom: 'Bar all incoming calls and messages',
      barCallsMsgOutg: 'Bar all outgoing calls and messages',
      barCallsOutg: 'Bar all outgoing calls',

      barCallsAbroad: 'Bar all calls while abroad',
      barCallsAbroadExcHome:
        'Bar all calls while abroad, except those destined for your home country',
      barIncomCallsAbroad: 'Bar all incoming calls while abroad',
      barOutgCallsAbroad: 'Bar all outgoing calls while abroad',
    },
    barContentServices: {
      description: 'Applies to services that charge the phone bill',
      contentServices: 'Bar Content Provider Access',
      cpa: 'Bar all content providers',
      cpa100: 'Bar all content providers after having spent 100.00 NOK',
      cpa250: 'Bar all content providers after having spent 250.00 NOK',
      cpa500: 'Bar all content providers after having spent 500.00 NOK',
      cpaGoodsAndServices:
        'Bar purchases such as vending machines, tickets etc.',
    },
    barPaidServices: {
      description: 'Applies to calls to paid services',
      paidServices: 'Bar Premium services',
      allowPaidServices: 'Allow premium services',
      barPaidServices: 'Bar all premium services',
      bar820: 'Bar premium services originating from numbers prefixed by 820',
      bar829: 'Bar premium services originating from numbers prefixed by 829',
      barDonation: 'Bar calls to donation numbers',
      barPhonebook: 'Bar calls to telephone directories',
    },
    errorMsg: 'Something went wrong! We were not able so save your changes',
  },

  modalFields: {
    unableToSave: 'We were unable to save the settings.',
    retry: 'Retry',
    ohDear: 'Oh dear.',
    errorPersists: `If the error persists, please contact customer service by email ${customerSupportEmail}, or by calling ${customerSupportNumber}`,
    savingSettings: 'We are saving your settings...',
    enterNumber: 'Enter telephone number, e.g. 92405050',
    recipentNum: "Recipient's phone number",
    exampleNum: 'E.g. 92405050',
  },

  callerId: {
    title: 'Change Caller Id',
    description: 'Subscriber may change their caller id',
    allowedCallerIds: 'Allowed caller ids',
    ownNumberOnly: 'Subscribers own number only',
    modal: {
      header: 'Choose caller id',
      description:
        'The subscriber can choose which number is shown as the caller ID for outgoing calls. You can administrate which numbers the subscriber will be able to use.',
      filterPlaceholder: 'Find phone number',
      selectedItemsSubHeader: 'Allowed Caller Ids: ',
      availableItemsSubHeader: 'Available Caller Ids: ',
    },
  },
};

export default settings;
