import React from 'react';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import {
  CHANGE_AUTHENTICATION,
  CHANGE_PASSWORD,
} from 'src/areas/dashboard/profile/IdentityInformationModal';

export const EditSettingIdentity = () => {
  const i18n = t.contacts.EditContact;

  return (
    <small className="d-block pb-5 grey-dark-text">
      <div className="font-weight-bold pb-2">{i18n.lookingForUserSettings}</div>
      <div className="pb-4">{i18n.userChangeEmailInfoText}</div>
      <div className="d-flex align-items-center pb-2">
        {i18n.ifYouWishToChangePassword}
        <div className="pl-2 font-weight-bold">
          <a href={CHANGE_PASSWORD} target="_blank" rel="noopener noreferrer">
            {i18n.youCanDoThatHere}
            <Icon
              className="ml-2"
              style={{ height: '1rem', width: '1rem' }}
              icon="open"
            />
          </a>
        </div>
      </div>

      <div className="d-flex align-items-center">
        {i18n.ifYouWishToChangeMFA}
        <div className="pl-2 font-weight-bold">
          <a
            href={CHANGE_AUTHENTICATION}
            target="_blank"
            rel="noopener noreferrer"
          >
            {i18n.youCanDoThatHere}
            <Icon
              className="ml-2"
              style={{ height: '1rem', width: '1rem' }}
              icon="open"
            />
          </a>
        </div>
      </div>
    </small>
  );
};
