import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import * as types from './__types/useSetChangelogMutation';
import { SetChangelogInput } from '../../../../__types/graphql-global-types';
import { GET_CHANGELOGS } from '../query/WithChangelogs';

const SET_CHANGELOG = gql`
  mutation useSetChangelogMutation($input: SetChangelogInput) {
    setChangelog(input: $input) {
      changelog {
        id
        publishedAt
        hidden
        createdAt
        createdBy {
          id
        }
        updatedAt
        updatedBy {
          id
        }
        headingNorwegian
        headingEnglish
        bodyNorwegian
        bodyEnglish
        summaryNorwegian
        summaryEnglish
        icon
      }
      error {
        code
        message
      }
    }
  }
`;

export const useSetChangelogMutation = () => {
  const [mutation, { data, loading, error }] = useMutation<
    types.useSetChangelogMutation,
    types.useSetChangelogMutationVariables
  >(SET_CHANGELOG);
  const setChangelog = (
    input: SetChangelogInput,
    page: number,
    size: number,
    from?: string,
    includeUnpublished?: boolean
  ) => {
    mutation({
      variables: {
        input: {
          id: input.id,
          icon: input.icon,
          bodyEnglish: input.bodyEnglish,
          bodyNorwegian: input.bodyNorwegian,
          headingEnglish: input.headingEnglish,
          headingNorwegian: input.headingNorwegian,
          summaryEnglish: input.summaryEnglish,
          summaryNorwegian: input.summaryNorwegian,
          hidden: input.hidden,
          publishedAt: input.publishedAt,
        },
      },
      refetchQueries: [
        {
          // The following refetch is to update the changelog admin tool on the left hand side of the changelog dev page
          query: GET_CHANGELOGS,
          variables: {
            input: {
              page: page,
              size: size,
              from: from ? from : '2018-12-01 00:00:00',
              includeUnpublished: includeUnpublished
                ? includeUnpublished
                : true,
            },
          },
        },
        {
          // The following refetch is to update the view on the right hand side of the changelog dev page
          query: GET_CHANGELOGS,
          variables: {
            input: {
              page: 1,
              size: 4,
              from: '2018-12-01 00:00:00',
              includeUnpublished: false,
            },
          },
        },
      ],
    });
  };
  return {
    setChangelog: setChangelog,
    data,
    loading,
    error,
  };
};
