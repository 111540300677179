import React, { useState } from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  Area,
  CartesianGrid,
  Legend,
} from 'recharts';
import { colors } from 'src/lib/ui';
import { format } from 'date-fns';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { CustomLegend } from 'src/areas/main/statistics/components/CustomLegend';
import { t } from 'src/lib/i18n';
import { Checkbox } from 'src/lib/ui';
export interface Tick {
  in: number;
  out: number;
  timestamp: string;
}
interface Props {
  data: Tick[];
  subTitle?: string;
  // showGrid?: boolean;
}

const defaultMargin = { top: 30, right: 0, bottom: 0, left: 0 };
const formatDate = tick => {
  if (!tick) {
    return '';
  }
  return format(new Date(tick), 'HH:mm:ss');
};

/**
 * A copy from RealTimeChart with UI changes to match new design.
 */
export const NewRealTimeChart: React.SFC<Props> = props => {
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const toggleGrid = () => setShowGrid(!showGrid);
  return (
    <>
      <div className="mb-4">
        <h2 className="m-0 p-0">{t.statistics.StatisticsFilter.live}</h2>
        {props.subTitle ? (
          <div style={{ color: colors.greyDarkText }}>{props.subTitle}</div>
        ) : null}
      </div>
      <ResponsiveContainer width="100%" height={400}>
        <ComposedChart data={props.data} margin={defaultMargin}>
          <CartesianGrid strokeOpacity={0.5} vertical={showGrid} />
          <XAxis
            dataKey="timestamp"
            tickFormatter={formatDate}
            tickLine={false}
          />
          <YAxis tickLine={false} orientation="right" axisLine={false}>
            <Label value="Mbit/s" position="top" offset={10} />
          </YAxis>
          <Tooltip content={renderRealtimeTooltip} />

          <Area
            isAnimationActive={false}
            dataKey="in"
            fill={colors.corePurple}
            fillOpacity="0.2"
            stroke={colors.corePurple}
            name={t.statistics.Legend.trafficIn}
          />
          <Area
            isAnimationActive={false}
            dataKey="out"
            fill={colors.blueDark}
            fillOpacity="0.2"
            stroke={colors.blueDark}
            name={t.statistics.Legend.trafficOut}
          />
          <Legend
            content={
              <CustomLegend
                actions={
                  <Checkbox
                    label={t.statistics.showGrid}
                    checked={showGrid}
                    onChange={toggleGrid}
                  />
                }
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
};

const renderRealtimeTooltip = props => {
  if (
    props.active &&
    props.payload &&
    props.payload[0] &&
    props.payload[0].payload
  ) {
    const tooltipData = props.payload[0].payload as Tick;
    return (
      <CustomTooltipContainer>
        <Header data={tooltipData.timestamp} hideTimestamp={false} />
        <Section header="">
          <SectionItem
            title="In"
            color={colors.corePurple}
            value={`${tooltipData.in.toFixed(4)} Mbit/s`}
          />
          <SectionItem
            title="Out"
            color={colors.blueDark}
            value={`${tooltipData.out.toFixed(4)} Mbit/s`}
          />
        </Section>
      </CustomTooltipContainer>
    );
  }
  return null;
};
