import { IconDefinition } from '@telia/styleguide';
import { t } from 'src/lib/i18n';
import { PortalOrderStatus } from 'src/__types/graphql-global-types';

export const mapOrderStatusToLabel = (
  orderStatus: PortalOrderStatus | null
) => {
  const i18n = t.orders.orderStatus;
  switch (orderStatus) {
    case 'cancelled':
      return i18n.cancelled;
    case 'newOrder':
      return i18n.newOrder;
    case 'completed':
      return i18n.completed;
    case 'inProgress':
      return i18n.inProgress;
    default:
      return i18n.unknown;
  }
};

export const mapOrderStatustoIcon = (
  orderStatus: PortalOrderStatus | null
): IconDefinition => {
  switch (orderStatus) {
    case 'cancelled':
      return 'alert';
    case 'newOrder':
      return 'shoppingcart';
    case 'completed':
      return 'check-mark';
    case 'inProgress':
      return 'time-waiting';
    default:
      return 'shoppingcart';
  }
};
