import React, { useState } from 'react';
import { useAppContext } from 'src/lib/global';
import { useAddTagToSubscription } from 'src/areas/main/subscriptions/tags/useAddTagToSubscription';
import { useRemoveTagFromSubscription } from 'src/areas/main/subscriptions/tags/useRemoveTagFromSubscription';
import { Tag } from 'src/lib/types';
import { ItemListPicker, Item, Button } from 'src/lib/ui';
import { WithCustomerTags } from './WithCustomerTags';
import { t } from 'src/lib/i18n';
import { RoleTemplate } from 'src/__types/graphql-global-types';
import { ApolloQueryResult } from 'apollo-client';
import { useTags } from './../../../tags/__types/useTags';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';
import { trackUpdateServiceMetadata } from 'src/lib/analytics';

interface Props {
  refetch: () => Promise<ApolloQueryResult<useTags>>;
  subscription: {
    id: string;
    tags?: Array<{
      __typename: 'Tag';
      id: string;
      tag: string;
    }> | null;
  };
  serviceType?: SubscriptionGroup | null;
}

export const mapTagsToItem = (tags?: Tag[] | null) => {
  if (!tags) {
    return [];
  }
  return tags.map(
    tag =>
      ({
        id: tag.id,
        label: tag.tag,
      } as Item)
  );
};

const SubscriptionTagsPicker: React.FC<Props> = props => {
  const appContext = useAppContext();
  const [open, setOpen] = useState<boolean>(false);
  const toggle = () => {
    setOpen(!open);
  };
  const addTag = useAddTagToSubscription();
  const removeTag = useRemoveTagFromSubscription();

  const updateTags = async (tagsToAdd: string[], tagsToRemove: string[]) => {
    await Promise.all([
      addTag.addTagsToSubscription(props.subscription.id, tagsToAdd),
      removeTag.removeTagsFromSubscription(props.subscription.id, tagsToRemove),
    ]);
    props.refetch();
    toggle();
    if (props.serviceType)
      trackUpdateServiceMetadata(props.serviceType, 'tags');
  };

  const handleChange = (selectedItems: Item[]) => {
    const subscriptionTags = mapTagsToItem(props.subscription.tags);

    const tagsToAdd = (subscriptionTags
      ? selectedItems.filter(
          selectedTag =>
            !subscriptionTags.some(initalTag => initalTag.id === selectedTag.id)
        )
      : selectedItems
    ).map(tag => tag.label);

    const tagsToRemove = (subscriptionTags
      ? subscriptionTags.filter(
          item =>
            item.id !== undefined &&
            !selectedItems.some(tag => item.id === tag.id)
        )
      : []
    ).map(tag => tag.label);

    updateTags(tagsToAdd, tagsToRemove);
  };

  const translate = t.subscriptions.SubscriptionTagsPicker;
  const allowAddNewTag =
    appContext.customerRoles.includes(RoleTemplate.CustomerAdministrator) ||
    appContext.customerRoles.includes(RoleTemplate.OrderManager);

  return (
    <WithCustomerTags>
      {tags => (
        <>
          <Button
            color="grey"
            icon={props.subscription?.tags?.length === 0 ? 'add-circle' : 'pen'}
            size="small"
            rounded={true}
            onClick={toggle}
          >
            {props.subscription?.tags?.length === 0
              ? t.subscriptions.SubscriptionTagsPicker.toggleBtnAdd
              : t.subscriptions.SubscriptionTagsPicker.toggleBtnEdit}
          </Button>
          <ItemListPicker
            heading={translate.title}
            initialSelectedItems={mapTagsToItem(props.subscription.tags)}
            availableItems={mapTagsToItem(tags.tags)}
            onSave={selectedItems => handleChange(selectedItems)}
            toggleState={toggle}
            isOpen={open}
            loading={tags.loading}
            submitLoading={addTag.loading || removeTag.loading}
            filterPlaceholder={translate.filterPlaceholder}
            disableAddNew={!allowAddNewTag}
          />
        </>
      )}
    </WithCustomerTags>
  );
};

export default SubscriptionTagsPicker;
