import React from 'react';
import { useSearch } from 'src/areas/main/search';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { Pagination, SelectedRowsBanner } from 'src/lib/ui';
import { SubscriptionsProps } from '../../types';
import {
  useSubscriptionListParams,
  getSubscriptionSortOptions,
  useSelectableSubscriptions,
} from '../../utils';
import { DataSubscriptionsTable } from './DataSubscriptionsTable';
import { DataSubscriptionsFilters } from './DataSubscriptionsFilters';
import { getFilterCountFromSearchFilter } from 'src/lib/utils/';
import { DataSubscriptionsList } from './DataSubscriptionsList';
import { useQueryParamPagination } from 'src/lib/ui/Pagination/useQueryParamPaginations';
import {
  SubscriptionGroup,
  SearchDomain,
} from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';
import { useDomainColumns } from 'src/lib/table';

export default function DataSubscriptions(props: SubscriptionsProps) {
  const query = useQueryParameters();
  const sp = useSubscriptionListParams({
    preferenceNamespace: 'data',
    defaultSortKey: 'monitoringStatus',
    defaultSortOrder: 'desc',
  });
  const paginationProps = useQueryParamPagination({
    defaultPageSize: sp.pageSize,
    onTogglePageSize: sp.setPageSize,
  });
  const { availableColumns, columns } = useDomainColumns(
    SearchDomain.dataSubscription,
    sp.columns
  );

  const subscriptionSearchFilters = [
    {
      filter: 'group',
      value: ['data'],
    },
    {
      filter: 'tag',
      value: query.getArray('tag'),
    },
    {
      filter: 'tagsCombined',
      value: query.getArray('tagsCombined'),
    },
    {
      filter: 'cases',
      value: query.getArray('cases'),
    },
    { filter: 'status', value: query.getArray('status') },
    {
      filter: 'cpeType',
      value: query.getArray('cpeType'),
    },
    {
      filter: 'type',
      value: query.getArray('type'),
    },
    {
      filter: 'deliveryStatus',
      value: query.getArray('deliveryStatus'),
    },
  ];

  const search = useSearch({
    query: sp.searchQuery,
    size: sp.pageSize,
    page: sp.page,
    types: [SearchDomain.subscription],
    filter: subscriptionSearchFilters,
    sortBy: sp.sortBy,
    sortOrder: sp.sortOrder,
  });

  const { selectProps, handleSelectAll, loading } = useSelectableSubscriptions({
    results: search.results,
    totalResults: search.totalResults,
    query: sp.searchQuery,
    filters: subscriptionSearchFilters,
  });

  const sortOptions = getSubscriptionSortOptions(SubscriptionGroup.data);

  const tagsCombined = query.get('tagsCombined') ?? false;
  const filterCountFromSearch = search.filter
    ? getFilterCountFromSearchFilter(search.filter)
    : undefined;
  const filterCount =
    tagsCombined && filterCountFromSearch
      ? filterCountFromSearch - 1
      : filterCountFromSearch;

  return (
    <>
      <DataSubscriptionsFilters
        subscriptionListParams={sp}
        filterCount={filterCount}
        sortPickerOptions={sortOptions}
        totalResults={search.totalResults}
        selectProps={selectProps}
      />
      <div className="container">
        {selectProps?.allSelected ? (
          <SelectedRowsBanner
            numCurrentRows={selectProps.selected.length}
            allRows={search.totalResults}
            selectAll={handleSelectAll}
            clearSelect={() => selectProps.setSelected([])}
            domain={t.subscriptions.ListSubscriptionsByGroup.data.title}
            loading={loading}
          />
        ) : null}
        {sp.view === 'list' || !props.mobileBreakpoint ? (
          <DataSubscriptionsList
            results={search.results}
            loading={search.loading}
            noResultsMessage={sp.noResultsMessage}
            onSelectRow={selectProps?.onSelect}
            selected={selectProps?.selected}
          />
        ) : (
          <DataSubscriptionsTable
            results={search.results}
            loading={search.loading}
            totalResults={search.totalResults}
            subscriptionListParams={sp}
            columns={columns}
            availableColumns={availableColumns}
            selectProps={selectProps}
          />
        )}
      </div>
      <div
        className="d-flex container"
        style={{
          paddingTop: '1rem',
          paddingBottom: '60px', // to make place for the floating chat-button
        }}
      >
        <div className="ml-auto">
          <Pagination
            totalResults={search.totalResults || 0}
            {...paginationProps}
          />
        </div>
      </div>
    </>
  );
}
