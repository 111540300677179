import React from 'react';
import { AppContext } from 'src/lib/global';
import { map } from 'lodash';
import { Icon } from '@telia/styleguide';

export function Customers(props: { app: AppContext }) {
  return (
    <table className="Dev-table">
      <thead>
        <tr>
          <th style={{ width: '40px', textAlign: 'center', lineHeight: 0 }}>
            &nbsp;
          </th>
          <th>ID</th>
          <th>Customer</th>
        </tr>
      </thead>
      <tbody>
        {map(props.app.access.customers, customer => (
          <tr
            key={customer.id}
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={() =>
              props.app.setActiveCustomer(customer.id, customer.name)
            }
          >
            <td style={{ width: '40px', textAlign: 'center', lineHeight: 0 }}>
              {customer.id === props.app.activeCustomerId ? (
                <span
                  style={{
                    color: '#00a388',
                    fontWeight: 700,
                  }}
                >
                  <Icon icon="check-mark" />
                </span>
              ) : null}
            </td>

            <td>{customer.id}</td>
            <td>{customer.name}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
