import React from 'react';
import { t } from 'src/lib/i18n';
import { useRouter } from 'src/lib/utils';
import { Link } from 'react-router-dom';
import { colors } from 'src/lib/ui';

const reload = () => window.location.reload();
export const NoData = (props: { dataTypeLabel: string }) => {
  const router = useRouter();
  const i18n = t.subscriptions.NoData;
  return (
    <div className="mx-auto pl-4 py-4 my-4 border-left">
      <h3 className="mb-4">
        {i18n.weWereUnableToFetchData} {props.dataTypeLabel}
      </h3>

      <div className="pb-3" style={{ color: colors.greyDarkText }}>
        {i18n.tryTo}{' '}
        <Link onClick={reload} to={router.match.url}>
          {i18n.reloadThePage}
        </Link>
      </div>
      <div style={{ color: colors.greyDarkText }}>{i18n.helpText}</div>
    </div>
  );
};
