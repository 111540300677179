import React, { useEffect } from 'react';
import { SectionProps } from '../BarringSettingsFormModal';
import { SettingsListItem } from '../../SettingsListItem';
import { Toggle } from '@telia/styleguide/business';
import { t } from 'src/lib/i18n';

export function BarData(props: SectionProps) {
  const { state, toggleOption } = props;
  const i18n = t.mobile.settings.barring;

  useEffect(() => {
    if (state.data) {
      toggleOption({ dataRoaming: false });
    }
  }, [state.data, toggleOption]);

  return (
    <>
      <div className="pt-4 pb-2">{i18n.barData.barData}</div>
      <div className="mx-3" style={{ fontSize: '0.875rem' }}>
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barData.barAllData}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.data}
              onChange={() => toggleOption('data')}
            />
          }
        />

        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barData.barRoamingData}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.dataRoaming}
              disabled={state.data}
              onChange={() => toggleOption('dataRoaming')}
            />
          }
        />
      </div>
    </>
  );
}
