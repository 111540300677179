import * as React from 'react';
import cs from 'classnames';
import { Link, Switch, Route, Redirect } from 'react-router-dom';
import { useRouter } from 'src/lib/utils/useRouter';

import { Dash } from './dash/Dash';
import { AccountDetails } from './account/AccountDetails';
import { BuildDetails } from './build/BuildDetails';
import { Features } from './features/Features';
import { ChangelogDev } from './changelog/ChangelogDev';

import logo from 'src/images/telia-logo.svg';
import './Dev.scss';

import { Icon, IconDefinition } from '@telia/styleguide';

interface NavProps {
  icon: IconDefinition;
  to: string;
  label: string;
  className?: string;
}

function Nav(props: NavProps) {
  const router = useRouter();
  const active = props.to === router.location.pathname;
  return (
    <Link
      to={props.to}
      className={cs(active ? 'active' : undefined, props.className)}
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <Icon className="Dev-menu-icon" icon={props.icon} />
      {props.label}
    </Link>
  );
}

function Dev() {
  return (
    <div className="Dev">
      <div className="Dev-menu">
        <img className="Dev-logo" src={logo} alt="Telia logo" />
        <div className="Dev-dev-indicator">dev</div>

        <Nav
          to="/"
          icon="arrow-large-left"
          label="Back to front page"
          className="mb-4"
        />

        <Nav to="/dev" icon="settings" label="Dashboard" />
        <Nav to="/dev/account" icon="user" label="Account details" />
        <Nav to="/dev/build" icon="wrench" label="Build details" />
        <Nav to="/dev/features" icon="shuffle" label="Feature flags" />
        <Nav to="/dev/changelog" icon="time" label="Changelog" />
        {/* <Nav to="/dev/users" icon="account-multiple" label="User support" /> */}
      </div>

      <div className="Dev-body">
        <Switch>
          <Route exact={true} path="/dev" component={Dash} />
          <Route exact={true} path="/dev/account" component={AccountDetails} />
          <Route exact={true} path="/dev/build" component={BuildDetails} />
          <Route exact={true} path="/dev/features" component={Features} />
          <Route exact={true} path="/dev/changelog" component={ChangelogDev} />
          {/* <Route exact={true} path="/dev/users" component={UserSupport} /> */}
          <Route render={() => <Redirect to="/dev" />} />
        </Switch>
      </div>
    </div>
  );
}

export default Dev;
