import { IconDefinition } from '@telia/styleguide';
import { StatusColor } from 'src/lib/types';
import { CpeStatus } from 'src/__types/graphql-global-types';

export const getCpeStatusIcon = (
  status?: CpeStatus | null,
  /**
   * Not all subscriptions has monitoring. Ignore status and return a default icon if the subscription does not have monitoring.
   */
  hasStatus?: boolean | null
): {
  icon: IconDefinition;
  color: StatusColor;
} => {
  let color: StatusColor = 'muted';
  let icon: IconDefinition = 'wireless';
  if (hasStatus === false) {
    return {
      icon: 'internet',
      color: 'default',
    };
  }

  if (status) {
    switch (status) {
      case CpeStatus.Critical:
        icon = 'wireless-off';
        color = 'danger';
        break;
      case CpeStatus.NoStatus:
        icon = 'question';
        color = 'warning';
        break;
      case CpeStatus.Normal:
        icon = 'wireless';
        color = 'success';
        break;
      // This status should not exist, but still an existing value in GQL. If for some reason this is returned, we still have to handle it.
      case CpeStatus.Unknown:
        icon = 'question';
        color = 'warning';
        break;
    }
  }

  return { icon, color };
};
