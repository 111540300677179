import * as React from 'react';
import { Formik, FormikProps, Form } from 'formik';
import { Loading, RadioGroupOption, urlFor } from 'src/lib/ui';
import { FormikDatePicker, FormikRadioGroup } from 'src/lib/flow';
import {
  formatOrderingDateToDateString,
  isBefore,
} from 'src/lib/utils/dateUtils';
import * as yup from 'yup';
import { t } from 'src/lib/i18n';
import { yupValidateDateAndIsAfterTomorrow } from 'src/lib/utils/yupValidations';
import { addDays, format } from 'date-fns';
import { OrderingComponentShape } from '../types';
import { Redirect } from 'react-router-dom';
import { useTerminateMobileSubscription } from './useTerminateMobileSubscription';
import { trackTerminateSubscription } from 'src/lib/analytics';

interface OrderTerminateMobileSubscriptionProps {
  msisdn: string;
  children: (props: OrderingComponentShape) => React.ReactNode;
}

interface OrderTerminateMobileSubscriptionState {
  terminationDate: string;
  parkNumber?: boolean;
}

const OrderTerminateMobileSubscription: React.FunctionComponent<OrderTerminateMobileSubscriptionProps> = props => {
  const i18n = t.ordering.OrderTerminateMobileSubscription;
  const terminateMobileSubscription = useTerminateMobileSubscription();

  const parkNumberOptions: RadioGroupOption[] = [
    {
      label: i18n.parkNumber.options.yes,
      value: true,
    },
    { label: i18n.parkNumber.options.no, value: false },
  ];

  const defaultDate = format(addDays(new Date(), 14), 'DD.MM.YYYY');

  return (
    <Formik
      initialValues={{ terminationDate: defaultDate, parkNumber: false }}
      validationSchema={() =>
        yup.object().shape({
          terminationDate: yupValidateDateAndIsAfterTomorrow(13),
          parkNumber: yup.bool().required(),
        })
      }
      /**
       * Will be fixed in formik V2
       */
      isInitialValid={true}
      onSubmit={(values, actions) => {
        trackTerminateSubscription('mobile');
        terminateMobileSubscription.terminateMobileSubscriptionOrder({
          msisdn: props.msisdn,
          terminationDate: formatOrderingDateToDateString(
            values.terminationDate
          ),
          parkNumber: Boolean(values.parkNumber),
        });
      }}
    >
      {(formikProps: FormikProps<OrderTerminateMobileSubscriptionState>) => {
        let form: React.ReactNode = null;
        if (terminateMobileSubscription.loading) {
          form = <Loading />;
        } else if (
          terminateMobileSubscription.data &&
          terminateMobileSubscription.data.orderDetails?.orderId
        ) {
          form = (
            <Redirect
              to={urlFor({
                order: terminateMobileSubscription.data.orderDetails.orderId,
              })}
            />
          );
        } else if (
          terminateMobileSubscription.error ||
          (terminateMobileSubscription.data &&
            !terminateMobileSubscription.data.orderDetails?.orderId)
        ) {
          form = <div>{i18n.error}</div>;
        } else {
          form = (
            <Form>
              <FormikDatePicker
                id="terminationDate"
                required={true}
                label={i18n.terminationDate.label}
                showPicker={true}
                isOutsideRange={isBefore(13)}
              />

              <div className="pt-3 pb-4" style={{ fontSize: '14px' }}>
                {i18n.terminationDate.info}
              </div>

              <FormikRadioGroup
                id="parkNumber"
                options={parkNumberOptions}
                label={i18n.parkNumber.label(props.msisdn)}
                block={true}
              />

              <div className="pt-3 pb-4" style={{ fontSize: '14px' }}>
                {i18n.parkNumber.info}
              </div>
            </Form>
          );
        }

        return props.children({
          header: i18n.header,
          render: form,
          isFormValid: formikProps.isValid,
          submitLabel: i18n.btnSubmit,
          submit: formikProps.submitForm,
        });
      }}
    </Formik>
  );
};

export default OrderTerminateMobileSubscription;
