import { GsmStatisticsPeriod } from '../../queries/WithStatisticsGSM';
import moment from 'moment';

export const mapGsmData = (data?: GsmStatisticsPeriod[] | null) => {
  if (!data) {
    return null;
  }
  return (
    data &&
    data.map(x => {
      return {
        ...x,
        periodStart: moment(x.periodStart).valueOf(),
        periodEnd: moment(x.periodEnd).valueOf(),
      };
    })
  );
};
