import * as React from 'react';
import { BodyCard, List } from 'src/lib/ui';

import './SubscriptionContentList.scss';

/**
 * A generic list that can take in ListResultCard as children.
 * Will have a border and header.
 */

interface Props {
  heading: string;
}

export const SubscriptionContentList: React.FC<Props> = props => {
  return (
    <BodyCard
      className="SubscriptionContentList"
      noGutter={true}
      heading={props.heading}
    >
      <List container={false}>{props.children}</List>
    </BodyCard>
  );
};
