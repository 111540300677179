import React, { useRef, useEffect } from 'react';
import cs from 'classnames';
import './Checkbox.scss';
import { colors } from '../colors';
import generateUeid from 'src/lib/utils/generateUeid';

export interface CheckboxProps {
  id?: string;
  value?: string;
  name?: string;
  checked: boolean;
  indeterminate?: boolean;
  disabled?: boolean;
  className?: string;
  style?: React.CSSProperties;
  'aria-labelledby'?: string;
  'aria-label'?: string;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  label?: string;
  tabIndex?: number;
  /**
   * Specify position of label. Optional. Defaults to after.
   */
  labelPosition?: 'before' | 'after';
  error?: string;
}

export const Checkbox: React.FC<CheckboxProps> = props => {
  const inputRef = useRef<HTMLInputElement>(null);
  const fieldGuid = useRef<string>(generateUeid());
  const labelPosition = props.labelPosition ?? 'after';

  /**
   * Create a new unique id for component if a new label is passed
   */
  useEffect(() => {
    if (props.label) {
      fieldGuid.current = generateUeid();
    }
  }, [props.label]);

  useEffect(() => {
    /** we update the propperty of the checkbox for accessibility */
    if (inputRef.current) {
      inputRef.current.indeterminate = !!props.indeterminate;
    }
  }, [props.indeterminate]);

  const handleClick = (e: React.MouseEvent<HTMLDivElement>) => {
    e.preventDefault();
    if (inputRef && inputRef.current) {
      inputRef.current.focus();
      inputRef.current.click();
    }
  };
  const label = (
    <label
      className={cs('Checkbox-label')}
      htmlFor={fieldGuid.current ?? undefined}
    >
      {props.label}
    </label>
  );

  const { className, indeterminate, ...inputProps } = props;
  return (
    <div
      id={props.id}
      className={cs(
        'Checkbox',
        {
          checked: props.checked,
          indeterminate: props.indeterminate,
          disabled: props.disabled,
        },
        className
      )}
      style={props.style}
    >
      <input
        {...inputProps}
        id={fieldGuid.current ?? undefined}
        name={props.name}
        ref={inputRef}
        onChange={props.onChange}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
        type="checkbox"
        checked={props.checked}
        disabled={props.disabled}
        className="Checkbox-screenreader-only"
        tabIndex={props.tabIndex}
      />
      {props.label && labelPosition === 'before' ? label : null}
      <div className="Checkbox-hover" onClick={handleClick}>
        <div className="Checkbox-input">
          {props.indeterminate ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              width="20px"
              viewBox="0 0 18 18"
            >
              <path
                stroke={props.disabled ? colors.greyDark : colors.white}
                d="M3.792 8.781h10c.161 0 .292-.224.292-.5s-.13-.5-.292-.5H3.792c-.161 0-.292.224-.292.5s.13.5.292.5"
              />
            </svg>
          ) : props.checked ? (
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="20px"
              width="20px"
              viewBox="0 0 18 18"
            >
              <path
                id="check"
                d="M 2 13 L 9 13 L 9 2"
                stroke={props.disabled ? colors.greyDark : colors.white}
                strokeLinejoin="round"
                strokeLinecap="round"
                fill="transparent"
                transform="rotate(45 9 11)"
                strokeWidth="2"
              />
            </svg>
          ) : null}
        </div>
      </div>
      {props.label && labelPosition === 'after' ? label : null}
    </div>
  );
};
