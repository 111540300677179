import React from 'react';
import {
  LineChart,
  ReferenceLine,
  ResponsiveContainer,
  XAxis,
  YAxis,
  Line,
  Label,
  Tooltip,
} from 'recharts';
import { colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const M2MUsageGraph = (props: {
  maxValue?: number;
  data?: Array<{ month: string; usage: number }>;
}) => {
  const maxUsage = props.data ? Math.max(...props.data.map(d => d.usage)) : 0;
  const exceedsLimit = props.maxValue && maxUsage > props.maxValue;
  const max = props.maxValue ? props.maxValue : undefined;
  return (
    <ResponsiveContainer height={320}>
      <LineChart width={500} data={props.data} margin={{ top: 20, bottom: 5 }}>
        <defs>
          <linearGradient id="lineStroke" x1="0%" y1="0%" x2="0%" y2="100%">
            <stop offset="0%" stopColor={colors.red} />
            <stop offset="100%" stopColor={colors.corePurple} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="month"
          padding={{ left: 100, right: 100 }}
          tickLine={false}
          stroke={colors.greyMedium}
          tick={{ fill: colors.greyDarkText }}
        />

        <YAxis hide={true} height={max ? max + 1 : undefined} />
        {max && (
          <ReferenceLine
            y={max}
            stroke={colors.greyMedium}
            ifOverflow="extendDomain"
          >
            <Label
              fill={colors.greyDarkText}
              value={max + 'GB'}
              position="insideBottomRight"
            />
          </ReferenceLine>
        )}
        <Tooltip cursor={false} content={<CustomTooltip maxValue={max} />} />
        <Line
          isAnimationActive={false}
          type="monotone"
          stroke={exceedsLimit ? 'url(#lineStroke)' : colors.corePurple}
          strokeWidth={3}
          dataKey="usage"
          dot={<CustomizedDot maxValue={max} />}
        ></Line>
      </LineChart>
    </ResponsiveContainer>
  );
};

const CustomizedDot = props => {
  const { cx, cy, value } = props;
  const fill =
    props.maxValue && value > props.maxValue ? colors.red : colors.corePurple;
  return <circle cx={cx} cy={cy} r={4} fill={fill} />;
};

const CustomTooltip = props => {
  const { active, payload } = props;
  const i18n = t.subscriptions.M2MUsage;
  const value = payload?.[0]?.value;
  const color =
    props.maxValue && value > props.maxValue ? colors.red : colors.corePurple;
  if (active) {
    return (
      <div
        className="px-4 py-3 text-center"
        style={{
          border: `1px solid ${colors.greyMedium}`,
          backgroundColor: colors.white,
          borderRadius: '4px',
          boxShadow:
            '0 1px 4px 0 rgba(0, 0, 0, 0.04), 0 1px 1px -1px rgba(0, 0, 0, 0.1), 0 3px 8px 0 rgba(0, 0, 0, 0.02)',
        }}
      >
        <p style={{ color }}>{value + 'GB'}</p>
        <small>{i18n.dataUsed}</small>
      </div>
    );
  }

  return null;
};
