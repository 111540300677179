import layoutNb from '../../nb/Main/layout';

const layout: typeof layoutNb = {
  menu: {
    home: 'Home',
    services: 'Services',
    servicesData: 'Networks',
    servicesMobile: 'Mobile',
    servicesTele: 'Telephony',
    servicesOther: 'Other services',
    map: 'Map',
    incidents: 'Incidents',
    cost: 'Costs',
    invoices: 'Invoices',
    orders: 'Orders',
    ordering: 'Ordering',
    contacts: 'Contacts',
    documents: 'Documents',
    reports: 'Reports',

    mobileSubscriptionsHeading: 'Subscriptions',
  },

  AreaSelectorDropdown: {
    openButton: 'Switch application',
    heading: 'Switch application',
  },

  ProfileDropdown: {
    openButton: 'Open menu',
    contactDetails: 'Contact details',
    switchToNorwegian: 'Bytt til norsk',
    switchToEnglish: 'View in English',
    privacyStatement: 'Privacy statement',
    privacyStatementLink:
      'https://www.telia.no/personvern/personvern-for-bedrift/',
    logOut: 'Log out',
  },

  SearchField: {
    label: 'Search',
    searchInputPlaceholder: 'Search',
  },

  SearchPicker: {
    searchInputPlaceholder: 'Search',
  },

  MainApp: {
    customersHeading: 'Customers',
    search: 'Search',
    closeMenu: 'Close menu',
  },

  NotFoundPage: {
    pageNotFound: 'Could not find the page',
  },

  CustomerSelection: {
    searchForCustomer: 'Search for customer',
    selectCustomer: 'Select customer',
    nHits: (count: number) => `${count} ${count === 1 ? 'hit' : 'hits'}`,
  },

  CustomerSelector: {
    searchListHeader: 'Search:',
    searchForCustomerPlaceholder: 'Search for customer',
    yourCustomers: 'Your customers:',
  },

  FilterSection: {
    fullscreenCloseBtn: 'Close',
    filter: 'Filter',
    searchInputPlaceholder: 'Filter',
    searchInputListPlaceholder: 'Search in list',
    searchInputTablePlaceholder: 'Search in table',
    showList: 'Show list',
    showTable: 'Show table',
  },
};

export default layout;
