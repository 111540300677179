import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { useSearchSubscriptions } from 'src/areas/main/subscriptions/queries/useSearchSubscriptions';
import { getSubscriptionDisplayTitles } from 'src/lib/types/subscription/getSubscriptionDisplayTitles';

export const useSubscriptionsFilter = () => {
  const i18n = t.incidents.subscriptionsFilter;
  const query = useQueryParameters();
  const selectedSubscriptions = query.getArray('subscription');
  const { subscriptions, loading } = useSearchSubscriptions({
    size: 10000,
    filter: [{ filter: 'hasIncident', value: ['true'] }],
  });

  const mappedSubscriptions = subscriptions.map(({ subscription }) => ({
    value: subscription.id,
    label: getSubscriptionDisplayTitles(subscription).title,
  }));

  const selectedSubscriptionOptions = subscriptions
    .filter(s => selectedSubscriptions.indexOf(s.subscription.id) >= 0)
    .map(s => s.subscription.id);

  return {
    id: 'subscription',
    label: i18n.lable,
    options: mappedSubscriptions,
    allowMultiple: true,
    allowSearch: true,
    selectedOptions: selectedSubscriptionOptions,
    loading: loading,
    handleSave: values => {
      setFilterAndResetPage(query, 'subscription', values ? values : undefined);
    },
  };
};
