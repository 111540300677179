import * as React from 'react';
import FileOptions from './FileOptions';
import FileIcon from 'src/areas/main/documents/table/FileIcon';
import { PathContext } from 'src/areas/main/documents/contexts/PathContext';
import { formatDate, formatFileSize } from 'src/lib/utils';
import { File } from 'src/lib/types';
import { t } from 'src/lib/i18n';

interface Props {
  file: File;
  displaySharing?: boolean;
  context: PathContext;
}

export const FileTableRow = (props: Props) => {
  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData('objectType', 'file');
    e.dataTransfer.setData('objectData', JSON.stringify(props.file));
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      props.context.navigateTo({ file: props.file });
    }
  };

  return (
    <tr
      tabIndex={0}
      onClick={() => props.context.navigateTo({ file: props.file })}
      onKeyUp={handleKeyUp}
    >
      <td draggable={true} onDragStart={e => handleDragStart(e)}>
        <span className="DocumentTable-fileIcon pr-3">
          <FileIcon mimeType={props.file.mimeType} />
        </span>
        {props.file.name}
      </td>
      <td>
        {props.file.uploaded
          ? formatDate(props.file.uploaded, 'shortWithTime')
          : '-'}
      </td>
      <td>{props.file.uploadedBy}</td>
      <td>{props.file.size ? formatFileSize(props.file.size) : '-'}</td>
      {props.displaySharing && (
        <td>
          {props.file.teliaInternal ? t.documents.internal : t.documents.shared}
        </td>
      )}
      <td onClick={e => e.stopPropagation()}>
        <FileOptions file={props.file} />
      </td>
    </tr>
  );
};
