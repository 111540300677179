import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import * as t from './__types/useMobileSimCards';
const WITH_AVAILABLE_SIM_CARDS = gql`
  query useMobileSimCards($customerId: ID!) {
    customer(id: $customerId) {
      id
      availableSimCards {
        simCardNumber
      }
    }
  }
`;

interface Result {
  loading: boolean;
  error: boolean;
  data?: t.useMobileSimCards_customer_availableSimCards[] | null;
}

export const useAvailableSimCards = (customerId: string): Result => {
  const { data, loading, error } = useQuery<
    t.useMobileSimCards,
    t.useMobileSimCardsVariables
  >(WITH_AVAILABLE_SIM_CARDS, {
    variables: {
      customerId,
    },
  });

  return {
    loading,
    error: !!error,
    data: data?.customer?.availableSimCards,
  };
};
