import gql from 'graphql-tag';

export const query = gql`
  query Features {
    featureFlags {
      key
      description
      exposeToFrontend
      assignments {
        user {
          id
          email
        }
        customer {
          id
          name
        }
        value
      }
    }
  }
`;

export interface QueryData {
  featureFlags: null | Array<FeatureFlag>;
}

interface PortalError {
  code: string;
  message: null | string;
}

export interface FeatureFlag {
  key: string;
  description: null | string;
  exposeToFrontend: boolean;
  assignments: null | Array<{
    user: null | { id: string; email: string };
    customer: null | { id: string; name: string };
    value: null | string;
  }>;
}

export interface AssignFeatureFlagInput {
  key: string;
  userId?: string;
  customerId?: string;
  value?: string;
}

export interface AssignFeatureFlagPayload {
  assignFeatureFlag: {
    featureFlag: FeatureFlag;
    error: null | PortalError;
  };
}

export const assignFeatureFlag = gql`
  mutation assignFeatureFlag($input: AssignFeatureFlagInput) {
    assignFeatureFlag(input: $input) {
      featureFlag {
        key
        description
        exposeToFrontend
        assignments {
          user {
            id
            email
          }
          customer {
            id
            name
          }
          value
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export interface SetFeatureFlagInput {
  key: string;
  description: null | string;
  exposeToFrontend: null | boolean;
}

export interface SetFeatureFlagPayload {
  setFeatureFlag: {
    featureFlag: FeatureFlag;
    error: PortalError;
  };
}

export const setFeatureFlag = gql`
  mutation setFeatureFlag($input: SetFeatureFlagInput) {
    setFeatureFlag(input: $input) {
      featureFlag {
        key
        description
        exposeToFrontend
        assignments {
          user {
            id
            email
          }
          customer {
            id
            name
          }
          value
        }
      }
      error {
        code
        message
      }
    }
  }
`;

export interface DeleteFeatureFlagInput {
  key: string;
}

export interface DeleteFeatureFlagPayload {
  deleteFeatureFlag: {
    success: boolean;
    key: string;
    error: PortalError;
  };
}

export const deleteFeatureFlag = gql`
  mutation deleteFeatureFlag($input: DeleteFeatureFlagInput) {
    deleteFeatureFlag(input: $input) {
      success
      key
      error {
        code
        message
      }
    }
  }
`;

export interface UnassignFeatureFlagInput {
  key: string;
  userId: null | string;
  customerId: null | string;
}

export interface UnassignFeatureFlagPayload {
  unassignFeatureFlag: {
    featureFlag: FeatureFlag;
    error: PortalError;
  };
}

export const unassignFeatureFlag = gql`
  mutation unassignFeatureFlag($input: UnassignFeatureFlagInput) {
    unassignFeatureFlag(input: $input) {
      featureFlag {
        key
        description
        exposeToFrontend
        assignments {
          user {
            id
            email
          }
          customer {
            id
            name
          }
          value
        }
      }
      error {
        code
        message
      }
    }
  }
`;
