import React, { useEffect, useMemo } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { colors, FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import {
  ReplaceSimResult,
  useReplaceSimCard,
} from 'src/areas/mobile/lib/useReplaceSimCard';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { useAvailableSimCards } from 'src/areas/mobile/overview/useAvailableSimCards';
import { useSubscriptionOriginDetails } from 'src/areas/mobile/lib/useSubscriptionOriginDetails';
import { withFormik, FormikProps } from 'formik';
import * as yup from 'yup';
import {
  FormikRadioGroup,
  FormikTextField,
  FormikSelectField,
} from 'src/lib/flow';
import { OriginDetails } from 'src/areas/mobile/types';

interface Props {
  open: boolean;
  toggle: () => void;
}
interface InnerProps {
  mutation: ReplaceSimResult;
  origin?: OriginDetails;
  availableSimCards: Array<{ label: string; value: string }>;
}
const ReplaceSimModalInner: React.FC<Props &
  InnerProps &
  FormikProps<Values>> = ({ open, toggle, availableSimCards, ...props }) => {
  useEffect(() => {
    if (props.mutation.data && !props.mutation.error) {
      props.resetForm();
      toggle();
    }
  }, [props.mutation.data, props.mutation.error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    props.validateForm();
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={open} toggle={toggle}>
      <ModalHeader>{t.mobile.overview.sim.modalHeader}</ModalHeader>
      <ModalBody>
        <div className="pb-3">
          <small style={{ color: colors.greyDarkText }}>
            {t.mobile.overview.sim.activateOrOrderNew}
          </small>
        </div>
        {t.mobile.overview.sim.activateOrOrderNewQuestion}
        <FormikRadioGroup
          block={true}
          id="simType"
          className="pb-4 pt-3"
          options={[
            {
              value: 'existing',
              label: t.mobile.overview.sim.existingSim,
            },
            {
              value: 'new',
              label: t.mobile.overview.sim.newSim,
            },
          ]}
        />
        {props.values.simType === 'existing' ? (
          <>
            <FormikSelectField
              className="mb-4"
              id="selectedOption"
              options={availableSimCards}
              label={t.mobile.overview.sim.textLabel}
              clearable={true}
              virtualized={true}
            />
            <div> {t.mobile.overview.sim.activateSimDescription}</div>
          </>
        ) : (
          <>
            <FormikTextField
              id="name"
              className="w-100"
              label={t.mobile.settings.name}
            />
            <FormikTextField
              id="address"
              className="w-100"
              label={t.mobile.settings.address}
            />
            <FormikTextField
              id="postalCode"
              className="w-100"
              label={t.mobile.settings.postCode}
            />
            <FormikTextField
              id="postalArea"
              className="w-100"
              label={t.mobile.settings.postArea}
            />
            <FormikTextField
              id="attention"
              className="w-100"
              label={t.mobile.settings.attention}
            />
            <div> {t.mobile.overview.sim.orderSimDescription}</div>
          </>
        )}
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submitLabel={
            props.values.simType === 'new'
              ? t.mobile.overview.sim.submitLabelNew
              : t.mobile.overview.sim.submitLabelExisting
          }
          error={props.mutation.error}
          errorMsg={t.mobile.overview.sim.errorMsg}
          loading={props.mutation.loading}
          cancel={toggle}
          disabled={!props.isValid}
          submit={() => props.handleSubmit()}
        />
      </ModalFooter>
    </Modal>
  );
};

interface Values {
  selectedOption: string;
  simType: 'new' | 'existing';
  name: string;
  address: string;
  postalCode: string;
  postalArea: string;
  attention: string;
}
const EnhancedReplaceSimModal = withFormik<Props & InnerProps, Values>({
  // We allow Formik to re-initialize beacuse "origin"-data fetch is async, we need to re-populate initialValues after the fetch is done.
  enableReinitialize: true,
  mapPropsToValues: ({ origin }) => {
    return {
      selectedOption: '',
      simType: 'existing',
      name: origin
        ? ((origin.firstName || '') + ' ' + origin.lastName || '').trim()
        : '',
      address: origin
        ? (
            (origin.streetAddress || '') + ' ' + origin.apartmentNum || ''
          ).trim()
        : '',
      postalCode: origin ? origin.postCode || '' : '',
      postalArea: origin ? origin.postArea || '' : '',
      attention: '',
    };
  },
  validationSchema: () =>
    yup.object().shape({
      selectedOption: yup.string().when('simType', {
        is: simType => simType === 'existing',
        then: yup.string().required(t.ordering.required),
      }),
      simType: yup.string(),
      name: yup.string().when('simType', {
        is: simType => simType === 'new',
        then: yup.string().required(t.ordering.required),
      }),
      address: yup.string().when('simType', {
        is: simType => simType === 'new',
        then: yup.string().required(t.ordering.required),
      }),
      postalCode: yup.string().when('simType', {
        is: simType => simType === 'new',
        then: yup.string().required(t.ordering.required),
      }),
      postalArea: yup.string().when('simType', {
        is: simType => simType === 'new',
        then: yup.string().required(t.ordering.required),
      }),
    }),
  handleSubmit: (values, bag) => {
    bag.props.mutation.replaceSimCard(
      values.simType === 'existing' ? values.selectedOption : undefined,
      values.simType === 'new'
        ? {
            name: values.name,
            address: values.address,
            postalArea: values.postalArea,
            postalCode: values.postalCode,
            attention: values.attention,
          }
        : undefined
    );
  },
  // Does not work as intended
  // https://github.com/jaredpalmer/formik/issues/1950
  // validateOnMount: true,
})(ReplaceSimModalInner);

export const ReplaceSimModal: React.FC<Props> = props => {
  const mutationResult = useReplaceSimCard();
  const origin = useSubscriptionOriginDetails();
  const subscription = useSubscriptionContext();
  const availableSimCardsResult = useAvailableSimCards(subscription.customerId);

  const availableSimCards = useMemo(
    () =>
      availableSimCardsResult.data
        ? availableSimCardsResult.data.map(sim => ({
            label: sim.simCardNumber,
            value: sim.simCardNumber,
          }))
        : [],
    [availableSimCardsResult.data]
  );

  return (
    <EnhancedReplaceSimModal
      mutation={mutationResult}
      open={props.open}
      toggle={props.toggle}
      origin={origin?.data?.subscription?.origin ?? undefined}
      availableSimCards={availableSimCards}
    />
  );
};
