import { ApolloClient } from 'apollo-client';
import { InMemoryCache } from 'apollo-cache-inmemory';
import { HttpLink } from 'apollo-link-http';
import { onError } from 'apollo-link-error';
import { ApolloLink } from 'apollo-link';
import { WebSocketLink } from 'apollo-link-ws';
import { split } from 'apollo-link';
import { getMainDefinition } from 'apollo-utilities';
import Cookies from 'js-cookie';
import { IntrospectionFragmentMatcher } from 'apollo-cache-inmemory';
import graphqlIntrospectionResult from './graphqlIntrospectionResult';
import config from './config';
import { getBearerToken } from 'src/modules/auth/bearerToken';
import { captureGraphQLErrors } from 'src/captureGraphQLErrors';
import * as Sentry from '@sentry/browser';

/**
 * Authentication middleware
 */
const authMiddleware = new ApolloLink((operation, forward) => {
  const token = getBearerToken();
  operation.setContext(({ headers = {} }) => ({
    headers: {
      ...headers,
      authorization: `Bearer ${token}`,
      'x-wrap-search-results': 1,
    },
  }));
  return forward ? forward(operation) : null;
});

let applicationUrl: URL = new URL('ws://localhost/');
if (config.applicationRootUrl) {
  applicationUrl = new URL(config.applicationRootUrl);
  if (config.environment === 'qa' || config.environment === 'pr') {
    applicationUrl.protocol = 'wss';
  } else {
    applicationUrl.protocol = 'ws';
    applicationUrl.port = '3001';
  }
}

const wsLink = new WebSocketLink({
  uri: config.applicationRootUrl
    ? `${applicationUrl}api/graphql`
    : 'ws://localhost:3001/api/graphql',
  options: {
    lazy: true,
    reconnect: true,
    connectionParams: () => ({
      authToken: getBearerToken(),
      sessionId: Cookies.get('SessionId'),
    }),
  },
});

const httpLink = new HttpLink({
  uri: config.apiRootUrl ? `${config.apiRootUrl}/api/graphql` : '/api/graphql',
});

let link = ApolloLink.from([
  onError(({ graphQLErrors, networkError, operation }) => {
    if (graphQLErrors) {
      graphQLErrors.map(({ message, locations, path }) =>
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
        )
      );
      captureGraphQLErrors(Sentry)(operation?.operationName, graphQLErrors);
    }
    if (networkError)
      console.error(`[Network error]: ${networkError}`, networkError.stack);
  }),
  authMiddleware,
  split(
    // split based on operation type
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === 'OperationDefinition' &&
        definition.operation === 'subscription'
      );
    },
    wsLink,
    httpLink
  ),
]);

const createApolloClient = () =>
  new ApolloClient({
    link: link,

    cache: new InMemoryCache({
      fragmentMatcher: new IntrospectionFragmentMatcher({
        introspectionQueryResultData: graphqlIntrospectionResult,
      }),
    }),
  });

export default createApolloClient;
