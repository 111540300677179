import React from 'react';
import { StandardStatisticsPeriod } from '../../queries/WithStatistics';
import { colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { formatNordic } from 'src/lib/utils';

export const SnapshotTooltip = ({
  data,
  hideTimestamp,
}: {
  data: StandardStatisticsPeriod;
  hideTimestamp?: boolean;
}) => {
  const i18n = t.statistics.StandardDetails;
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header={i18n.in}>
        <SectionItem
          title={i18n.throughputAvg}
          color={colors.corePurple}
          value={
            data.throughput?.in
              ? `${formatNordic(data.throughput.in, 5)} Mbit/s ${
                  data.utilization
                    ? data.utilization.in &&
                      `${formatNordic(data.utilization.in, 2)}%`
                    : ''
                }`
              : t.statistics.noData
          }
        />
        <SectionItem
          title={i18n.throughputMax}
          color={colors.corePurple}
          filled={false}
          value={
            data.throughput?.maxIn
              ? `${formatNordic(data.throughput.maxIn, 5)} Mbit/s `
              : t.statistics.noData
          }
        />
        <SectionItem
          title={i18n.packetDrop}
          color={colors.corePurple}
          value={data.packetDrop?.in?.toString() ?? t.statistics.noData}
        />
        <SectionItem
          title={i18n.cumulativeAvg}
          color={colors.corePurple}
          value={
            data.cumulativeTraffic?.in
              ? `${formatNordic(data.cumulativeTraffic.in, 2)} GB`
              : t.statistics.noData
          }
        />
      </Section>
      <Section header={i18n.out}>
        <SectionItem
          title={i18n.throughputAvg}
          color={colors.blueDark}
          value={
            data.throughput?.out
              ? `${formatNordic(data.throughput.out, 5)} Mbit/s ${
                  data.utilization
                    ? data.utilization.out &&
                      `${formatNordic(data.utilization.out, 2)}%`
                    : ''
                }`
              : t.statistics.noData
          }
        />
        <SectionItem
          title={i18n.throughputMax}
          color={colors.blueDark}
          filled={false}
          value={
            data.throughput?.maxOut
              ? `${formatNordic(data.throughput.maxOut, 5)} Mbit/s`
              : t.statistics.noData
          }
        />
        <SectionItem
          title={i18n.packetDrop}
          color={colors.blueDark}
          value={data.packetDrop?.out?.toString() ?? t.statistics.noData}
        />
        <SectionItem
          title={i18n.cumulativeAvg}
          color={colors.blueDark}
          value={
            data.cumulativeTraffic?.out
              ? `${formatNordic(data.cumulativeTraffic.out, 2)} GB`
              : t.statistics.noData
          }
        />
      </Section>
      <Section header={i18n.other}>
        <SectionItem
          title={i18n.responseTimeAvg}
          color={colors.orange}
          value={
            data.responseTime
              ? `${data.responseTime.toFixed()} ms`
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};
