import React, { useEffect, useState } from 'react';
import { Modal, ModalFooter } from 'reactstrap';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalBody from 'reactstrap/lib/ModalBody';
import { t } from 'src/lib/i18n';
import { withFormik, FormikProps } from 'formik';
import { FormikTextField } from 'src/lib/flow';
import { DirectoryDetails, Visibilit } from 'src/areas/mobile/types';
import { useUpdateSubscriptionDirectoryDetails } from 'src/areas/mobile/settings/mutations';
import { colors, Checkbox, FormSubmitBar } from 'src/lib/ui';
import { UpdateDirectoryDetailsResult } from 'src/areas/mobile/settings/mutations/useUpdateSubscriptionDirectoryDetails';
interface Values {
  firstName?: string;
  lastName?: string;
  streetAddress?: string;
  postCode?: string;
  postArea?: string;
  country?: string;
  visibility: Visibilit;
}

interface Props {
  directory: DirectoryDetails;
  open: boolean;
  toggle: () => void;
}

interface InnerProps {
  visibility: Visibilit;
  setVisibility: (visibility: Visibilit) => void;
  mutation: UpdateDirectoryDetailsResult;
}

const InnerFormModal: React.FC<Props & InnerProps & FormikProps<Values>> = ({
  visibility,
  setVisibility,
  mutation,
  ...props
}) => {
  const i18n = t.mobile.settings;

  useEffect(() => {
    if (mutation.data && !mutation.error) {
      props.toggle();
      props.resetForm();
    }
  }, [mutation.loading, mutation.error, mutation.data]); // eslint-disable-line react-hooks/exhaustive-deps

  const disableTextFields =
    visibility === 'Hidden' || visibility === 'ShowCompany';

  return (
    <Modal isOpen={props.open} size="lg" toggle={props.toggle}>
      <ModalHeader>{i18n.phonebook.service}</ModalHeader>
      <ModalBody>
        <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
          {i18n.phonebook.phonebookInfo}
        </small>
        <div style={{ display: 'grid', paddingBottom: '2rem' }}>
          <Checkbox
            checked={visibility === 'Hidden'}
            onChange={() =>
              visibility === 'Hidden'
                ? setVisibility('ShowUserAndCompany')
                : setVisibility('Hidden')
            }
            disabled={visibility === 'ShowCompany'}
            label={i18n.phonebook.reserveFromDirectory}
          />

          <Checkbox
            checked={visibility === 'ShowCompany'}
            onChange={() =>
              visibility === 'ShowCompany'
                ? setVisibility('ShowUserAndCompany')
                : setVisibility('ShowCompany')
            }
            disabled={visibility === 'Hidden'}
            label={i18n.phonebook.showCompanyInformation}
          />
        </div>

        <div className="pb-4">
          <FormikTextField
            id="firstName"
            label={i18n.firstName}
            className="w-50 pr-3"
            disabled={disableTextFields}
          />
          <FormikTextField
            id="lastName"
            label={i18n.lastName}
            className="w-50"
            disabled={disableTextFields}
          />
        </div>
        <FormikTextField
          id="streetAddress"
          label={i18n.address}
          className="w-100 pb-4"
          disabled={disableTextFields}
        />
        <div className="pb-4">
          <FormikTextField
            id="postCode"
            label={i18n.postCode}
            className="w-25 pr-3"
            disabled={disableTextFields}
          />
          <FormikTextField
            id="postArea"
            label={i18n.postArea}
            className="w-75"
            disabled={disableTextFields}
          />
        </div>
        <FormikTextField
          id="country"
          label={i18n.country}
          className="w-100 pb-4"
          disabled={disableTextFields}
        />
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={() => props.handleSubmit()}
          cancel={() => {
            props.toggle();
            props.resetForm();
          }}
          loading={mutation.loading}
          error={mutation.error}
          errorMsg={t.mobile.settings.modalFields.unableToSave}
        />
      </ModalFooter>
    </Modal>
  );
};

const FormikDirectoryForm = withFormik<Props & InnerProps, Values>({
  mapPropsToValues: ({ directory }) => ({
    firstName: directory.firstName,
    lastName: directory.lastName,
    streetAddress: directory.streetAddress,
    postArea: directory.postArea,
    postCode: directory.postCode,
    country: directory.country,
    visibility: directory.visibility,
  }),

  handleSubmit: (values: Values, bag) => {
    bag.props.mutation.updateDirectory({
      firstName: values.firstName,
      middleName: bag.props.directory.middleName,
      lastName: values.lastName,
      streetAddress: values.streetAddress,
      postArea: values.postArea,
      postCode: values.postCode,
      country: values.country,
      visibility: bag.props.visibility,
    });
  },
})(InnerFormModal);

export const DirectoryFormModal: React.FC<Props> = props => {
  const mutation = useUpdateSubscriptionDirectoryDetails();
  const [visibility, setVisibility] = useState<Visibilit>(
    props.directory.visibility
  );
  return (
    <FormikDirectoryForm
      visibility={visibility}
      setVisibility={setVisibility}
      mutation={mutation}
      directory={props.directory}
      open={props.open}
      toggle={props.toggle}
    />
  );
};
