import * as React from 'react';
import { format } from 'date-fns';
import { t, useI18nContext } from 'src/lib/i18n';
import { SelectField } from 'src/lib/ui';
import { getAvailableMonths } from './lib';
import './PeriodSelector.scss';

type Props = {
  from: string;
  to: string;
  setFrom: (from: string) => void;
  setTo: (to: string) => void;
};

export const PeriodSelector = (props: Props) => {
  const i18n = useI18nContext();

  const current = new Date();
  const monthsFrom = getAvailableMonths(
    i18n.locale,
    undefined,
    new Date(current.getFullYear(), current.getMonth() - 1)
  );
  const dateFrom = new Date(props.from);
  const dateFromNextMonth = new Date(
    dateFrom.setMonth(dateFrom.getMonth() + 1)
  );
  const monthsTo = getAvailableMonths(i18n.locale, dateFromNextMonth);

  const setMonthFrom = (monthFrom: string) => {
    const to = props.to ? new Date(props.to) : null;
    const from = new Date(monthFrom);
    if (to && from > to) {
      const newFrom = new Date(from.getFullYear(), from.getMonth() + 1);
      props.setTo(format(newFrom, 'YYYY-MM-DD'));
    }
    props.setFrom(format(from, 'YYYY-MM-DD'));
  };

  const setMonthTo = (monthTo: string) => {
    const dateTo = new Date(monthTo);
    const lastDateOfPreviousMonth = new Date(
      dateTo.setDate(dateTo.getDate() - 1)
    );
    props.setTo(format(lastDateOfPreviousMonth, 'YYYY-MM-DD'));
  };

  const translate = t.reports;
  return (
    <div className="PeriodSelector pb-4">
      <div>
        <SelectField
          label={translate.downloadModal.period}
          allowMultiple={false}
          value={props.from}
          options={monthsFrom}
          placeholder={translate.downloadModal.from}
          onChange={val => setMonthFrom(val?.value ?? '')}
          required
        />
      </div>
      <span className="Line">{' - '}</span>
      <div className="mt-4 pt-1">
        <SelectField
          allowMultiple={false}
          value={props.to}
          options={monthsTo}
          placeholder={translate.downloadModal.to}
          onChange={val => setMonthTo(val?.value ?? '')}
          required
        />
      </div>
    </div>
  );
};
