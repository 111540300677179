const servicePlus = {
  newLocation: {
    address: 'Adresse',
    postalCode: 'Postnummer',
    city: 'Poststed',
    organisationNumber: 'Organisasjonsnummer',
    contact: 'Kontaktperson',
    contactFirstName: 'Fornavn',
    contactLastName: 'Etternavn',
    contactEmail: 'Epost',
    contactPhone: 'Telefon',
    addNewLocation: 'Legg til ny lokasjon',
    postalCodeError: 'Postnummer må være fire siffer',
    tooShortError: 'For kort',
    required: 'Påkrevd felt',
  },
  overview: {
    title: 'Oversikt',
  },
  address: {
    servicePlus: 'Service Pluss',
    title: 'Lokasjoner',
    statusOk: 'Alt fungerer',
  },
};
export default servicePlus;
