import moment from 'moment';
import { format, isLastDayOfMonth, addMonths, isSameMonth } from 'date-fns';
import { formatDate } from 'src/lib/utils/dateUtils';

/**
 *
 * @param locale nb og en
 * @returns return a array of months from february 2019 (no data from earlier months), excluding currentMonth. Only finished months is included.
 */
export const getAvailableMonths = (
  locale: 'nb' | 'en',
  start?: Date,
  end?: Date
) => {
  const dates: Array<{ label: string; value: string }> = [];
  const currentDate: Date = end ? end : new Date();
  let startDate: Date = start ?? new Date(currentDate.getFullYear() - 5, 1);

  while (startDate < currentDate) {
    if (isSameMonth(startDate, currentDate)) {
      if (isLastDayOfMonth(startDate)) {
        dates.push({
          label: formatDate(startDate, 'monthWithYear', locale),
          value: format(startDate, 'YYYY-MM-DD'),
        });
      }
    } else {
      dates.push({
        label: formatDate(startDate, 'monthWithYear', locale),
        value: format(startDate, 'YYYY-MM-DD'),
      });
    }
    startDate = addMonths(startDate, 1);
  }
  return dates;
};

export const getYears = () => {
  const years: Array<{ label: string; value: string }> = [];
  const dateStart = moment().subtract(5, 'y');
  const dateEnd = moment();
  while (dateEnd.diff(dateStart, 'years') >= 0) {
    let year = dateStart.format('YYYY');
    years.push({ label: year, value: year });
    dateStart.add(1, 'year');
  }
  return years;
};
