import React from 'react';
import { StepFlowCard, colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

import { FormState } from './useReplaceContactStepFlow';

export const Step3 = (props: { form: FormState }) => {
  const i18n = t.contacts.editServices.step3;

  return (
    <StepFlowCard
      step={1}
      title={i18n.removeAll}
      currentStep={props.form.currentStep}
      isValid={true}
      isLastStep={true}
      onClick={() => props.form.setStep(1)}
      onContinue={props.form.nextStep}
      form={<Form />}
    />
  );
};
const Form = () => {
  return (
    <>
      <div className="pb-5" style={{ color: colors.greyDarkText }}>
        <div>{t.contacts.DeletePerson.step3.noReplacements}</div>
      </div>
    </>
  );
};
