/**
 * For subscriptions that does not match any of the existing subscription types.
 * For these we only show list view because what columns to show in table is not known.
 */

import React from 'react';
import { useSearch } from 'src/areas/main/search';

import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { Pagination, SelectedRowsBanner } from 'src/lib/ui';
import { SubscriptionsProps } from '../../types';

import {
  useSubscriptionListParams,
  getSubscriptionSortOptions,
  useSelectableSubscriptions,
} from '../../utils';
import { SubscriptionsList } from '../Components';
import { getFilterCountFromSearchFilter } from 'src/lib/utils/';
import { GenericSubscriptionsFilters } from './GenericSubscriptionsFilters';
import { useQueryParamPagination } from 'src/lib/ui/Pagination/useQueryParamPaginations';
import { t } from 'src/lib/i18n';
import {
  SubscriptionGroup,
  SearchDomain,
} from 'src/__types/graphql-global-types';

export default function GenericSubscriptions(props: SubscriptionsProps) {
  const query = useQueryParameters();
  const sp = useSubscriptionListParams({
    preferenceNamespace: 'all',
    defaultSortKey: 'monitoringStatus',
    defaultSortOrder: 'desc',
  });
  const paginationProps = useQueryParamPagination({
    defaultPageSize: sp.pageSize,
    onTogglePageSize: sp.setPageSize,
  });
  const sortOptions = getSubscriptionSortOptions(SubscriptionGroup.other);

  const subscriptionSearchFilters = [
    {
      filter: 'tag',
      value: query.getArray('tag'),
    },
    {
      filter: 'tagsCombined',
      value: query.getArray('tagsCombined'),
    },
    {
      filter: 'cases',
      value: query.getArray('cases'),
    },
    {
      filter: 'status',
      value: query.getArray('status'),
    },
    {
      filter: 'type',
      value: query.getArray('type'),
    },
  ];
  const search = useSearch({
    query: sp.searchQuery,
    size: sp.pageSize,
    page: sp.page,
    types: [SearchDomain.subscription],
    filter: subscriptionSearchFilters,
    sortBy: sp.sortBy,
    sortOrder: sp.sortOrder,
  });

  const tagsCombined = query.get('tagsCombined') ?? false;
  const filterCountFromSearch = search.filter
    ? getFilterCountFromSearchFilter(search.filter)
    : undefined;
  const filterCount =
    tagsCombined && filterCountFromSearch
      ? filterCountFromSearch - 1
      : filterCountFromSearch;

  const { selectProps, handleSelectAll, loading } = useSelectableSubscriptions({
    results: search.results,
    totalResults: search.totalResults,
    query: sp.searchQuery,
    filters: subscriptionSearchFilters,
  });

  return (
    <>
      <GenericSubscriptionsFilters
        subscriptionListParams={sp}
        filterCount={filterCount}
        sortPickerOptions={sortOptions}
        totalResults={search.totalResults}
        selectProps={selectProps}
      />
      <div className="container">
        {selectProps?.allSelected ? (
          <SelectedRowsBanner
            numCurrentRows={selectProps.selected.length}
            allRows={search.totalResults}
            selectAll={handleSelectAll}
            clearSelect={() => selectProps.setSelected([])}
            domain={t.subscriptions.ListSubscriptionsByGroup.all.title}
            loading={loading}
          />
        ) : null}
        <SubscriptionsList
          loading={search.loading}
          results={search.results}
          noResultsMessage={sp.noResultsMessage}
          onSelectRow={selectProps?.onSelect}
          selected={selectProps?.selected}
        />
      </div>
      <div
        className="d-flex container"
        style={{
          paddingTop: '1rem',
          paddingBottom: '60px', // to make place for the floating chat-button
        }}
      >
        <div className="ml-auto">
          <Pagination
            totalResults={search.totalResults || 0}
            {...paginationProps}
          />
        </div>
      </div>
    </>
  );
}
