import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global/CustomerContext';
import * as t from './__types/useUpdateNotificationSettingsOnUser';
import { NotificationSettingsInput } from 'src/__types/graphql-global-types';
import { ContactWithNotificationSettingsFragment } from 'src/lib/types/contact/ContactWithNotificationSettingsFragment';

export const UPDATE_NOTIFICATION_SETTINGS_ON_USER = gql`
  mutation useUpdateNotificationSettingsOnUser(
    $input: SetNotificationSettingsOnUserInput!
    $customerId: ID!
  ) {
    setNotificationSettingsOnUser(input: $input) {
      contact {
        ...ContactWithNotificationSettingsFragment
      }
      error {
        message
        code
      }
    }
  }
  ${ContactWithNotificationSettingsFragment}
`;

export const useUpdateNotificationSettingsOnUser = (contactId?: string) => {
  const customer = useCustomer();
  const [mutation, result] = useMutation<
    t.useUpdateNotificationSettingsOnUser,
    t.useUpdateNotificationSettingsOnUserVariables
  >(UPDATE_NOTIFICATION_SETTINGS_ON_USER);

  const updateSettings = (settings: NotificationSettingsInput) => {
    if (!contactId) return null;
    return mutation({
      variables: {
        customerId: customer.id,
        input: {
          customerId: customer.id,
          contactId: contactId ?? '',
          settings,
        },
      },
    });
  };

  return {
    data: result.data,
    loading: result.loading,
    error:
      !!result.error || !!result.data?.setNotificationSettingsOnUser?.error,
    updateSettings,
  };
};
