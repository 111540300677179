import { push } from './Analytics';

export type OrdersActions = 'view_orderline_details';

export function trackOrderlineDetails() {
  push({
    category: 'Orders',
    action: 'view_orderline_details',
  });
}
