import React, { useState, useEffect, useRef } from 'react';
import { useAlterFile } from '../mutations/useAlterFile';
import Form from 'reactstrap/lib/Form';
import { TextField, FormSubmitBar } from 'src/lib/ui';
import { getUndoRenameFileAction } from '../undo/UndoActions';
import { t } from 'src/lib/i18n';
import { File } from 'src/lib/types';
import undoPopup from 'src/areas/main/documents/undo/UndoPopup';
import { ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  cancel: () => void;
  file: File;
}

const RenameFileForm: React.FC<Props> = props => {
  const [nameInput, setNameInput] = useState<string>(props.file.name || '');
  const [inputError, setInputError] = useState<string | undefined>(undefined);
  const textInput = useRef<HTMLInputElement>(null);
  const alterFileContext = useAlterFile();
  const { file, cancel } = props;
  useEffect(() => {
    if (textInput.current) {
      window.setInterval(
        () => textInput.current && textInput.current.focus(),
        25
      );
    }
  }, [textInput]);

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setNameInput(e.target.value);
    if (e.target.value.length > 50) {
      setInputError(t.documents.validations.nameTooLong(50));
    } else if (e.target.value.length <= 0) {
      setInputError(t.documents.validations.nameTooShort);
    } else {
      setInputError(undefined);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    if (!inputError) {
      alterFileContext
        .alterFile(file.id, file.folderId, nameInput)
        .then(() =>
          undoPopup(
            getUndoRenameFileAction(file, nameInput, alterFileContext.alterFile)
          )
        );
      cancel();
    }
  };

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <ModalBody>
        <TextField
          className="w-100"
          label={t.documents.RenameFileForm.newNameLabel}
          innerRef={textInput}
          id="new-folder-input"
          value={nameInput}
          onChange={handleNameInputChange}
        />
        {inputError && <div className="pb-4">{inputError}</div>}
        {alterFileContext.error && (
          <div className="pb-4">{t.documents.genericError}</div>
        )}
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          cancel={props.cancel}
          loading={alterFileContext.loading}
          submitLabel={t.documents.RenameFileForm.submit}
          error={!!alterFileContext.error}
          disabled={!!inputError}
        />
      </ModalFooter>
    </Form>
  );
};

export default RenameFileForm;
