import { WizardTable, WizardTableProps } from 'src/lib/ui';
import { Field, FieldProps } from 'formik';

import * as React from 'react';

type FormikWizardTableProps = Omit<WizardTableProps, 'value'>;

const FormikWizardTable: React.FunctionComponent<FormikWizardTableProps> = props => {
  return (
    <Field name={props.id}>
      {formikProps => {
        const { field, form } = formikProps as FieldProps;
        // Add this if WizardTable can show errors
        // const hasError =
        //   Boolean(_get(form.touched, field.name)) &&
        //   Boolean(_get(form.errors, field.name));

        const customOnChange = (value: string[]) => {
          form.setFieldValue(field.name, value);
          form.setFieldTouched(field.name, true, false);
          if (props.onChange) {
            props.onChange(value);
          }
        };

        return (
          <WizardTable
            {...props}
            onChange={customOnChange}
            value={field.value || []}
          />
        );
      }}
    </Field>
  );
};

export default FormikWizardTable;
