import * as React from 'react';
import { RadioButton } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

const PeriodSelector: React.SFC<{
  setActivePeriodType: (type: 'invoice' | 'usage') => void;
  activePeriodType: 'invoice' | 'usage';
  className?: string;
}> = props => (
  <form className={props.className}>
    <RadioButton
      name="periodType"
      value="invoice"
      checked={props.activePeriodType === 'invoice'}
      onChange={() => props.setActivePeriodType('invoice')}
      className="mr-4"
      label={t.costs.ui.PeriodSelector.invoice}
    />
    <RadioButton
      checked={props.activePeriodType === 'usage'}
      name="periodType"
      value="usage"
      onChange={() => props.setActivePeriodType('usage')}
      label={t.costs.ui.PeriodSelector.usage}
    />
  </form>
);

export default PeriodSelector;
