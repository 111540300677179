import * as React from 'react';
import { RouteComponentProps } from 'react-router';

import { WithState } from 'src/lib/ui';

import {
  CostSummaryTable,
  extractCostMatrix,
  groupMobileCostAggregates,
  MobileCostSummary,
  OrganisationPage,
  WithCostAggregateForOrganisation,
} from './lib';

export const OrganisationSummary: React.FC<RouteComponentProps<{
  organisationId: string;
}>> = props => (
  <OrganisationPage organisationId={props.match.params.organisationId}>
    {(costContext, organisation) => (
      <div className="container">
        <WithCostAggregateForOrganisation
          organisationId={organisation.id}
          recursive={costContext.recursive}
          periodStart={costContext.activePeriod || ''}
          periodEnd={costContext.activePeriod || ''}
          periodBy={costContext.periodBy}
          groupBy={['month', 'costType', 'productGroup']}
        >
          {c => (
            <CostSummaryTable
              costType="net"
              costMatrix={extractCostMatrix(c.costAggregates)}
            />
          )}
        </WithCostAggregateForOrganisation>
        <WithCostAggregateForOrganisation
          organisationId={organisation.id}
          recursive={costContext.recursive}
          periodStart={costContext.activePeriod || ''}
          periodEnd={costContext.activePeriod || ''}
          periodBy={costContext.periodBy}
          productGroup="mobile"
          groupBy={['month', 'costType', 'groupedProductDesign']}
        >
          {c => {
            if (c.loading) {
              return null;
            }
            if (!c.costAggregates.length) {
              return null;
            }
            if (!costContext.activePeriod) {
              return null;
            }
            const activePeriod = costContext.activePeriod;

            return (
              <div className="py-5">
                <WithState<number | undefined> default={undefined}>
                  {(activeIndex, setActiveIndex) => (
                    <MobileCostSummary
                      data={groupMobileCostAggregates(c.costAggregates, {
                        filterBy: { costType: 'Forbruk' },
                      })}
                      activePeriod={activePeriod}
                      periodBy={costContext.periodBy}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                    />
                  )}
                </WithState>
              </div>
            );
          }}
        </WithCostAggregateForOrganisation>
      </div>
    )}
  </OrganisationPage>
);
