import * as React from 'react';
import { Step, StepShape, FormikTextField } from 'src/lib/flow';
import { colors, Chip, Attribute, Attributes } from 'src/lib/ui';
import * as yup from 'yup';
import { t } from 'src/lib/i18n';

export interface Step2Values {
  needsWiring?: boolean;
  floor?: string;
}

const Step2: Step<Step2Values> = (): StepShape<Step2Values> => {
  return {
    title: t.ordering.OrderIpVpn.Step2.title,

    validationSchema: yup.object({
      floor: yup.string(),
      needsWiring: yup.boolean(),
    }),

    renderSummary: ({ values }) => {
      return (
        <Attributes>
          <Attribute
            label={t.ordering.OrderIpVpn.Step2.floorSummary}
            value={values.floor}
          />
          <Attribute
            label={t.ordering.OrderIpVpn.Step2.needsWiring.summary}
            value={
              values.needsWiring
                ? t.ordering.OrderIpVpn.Step2.needsWiring.yes
                : t.ordering.OrderIpVpn.Step2.needsWiring.no
            }
          />
        </Attributes>
      );
    },

    renderForm: ({ flow }) => (
      <>
        <p className="small mt-0 mb-2" style={{ color: colors.greyDarkText }}>
          {t.ordering.OrderIpVpn.Step2.summary}
        </p>
        <div className="row">
          <FormikTextField
            id="floor"
            label={t.ordering.OrderIpVpn.Step2.floorLabel}
            required={true}
            className="col-md-4"
            bordered={true}
          />
        </div>
        <p>{t.ordering.OrderIpVpn.Step2.needsWiring.label}</p>
        <div className="mt-2 mb-3">
          <Chip
            label={t.ordering.OrderIpVpn.Step2.needsWiring.no}
            onClick={() => flow.setFieldValue('needsWiring', false)}
            icon="add-circle"
            active={!flow.values.needsWiring}
            className="mr-3"
          />

          <Chip
            label={t.ordering.OrderIpVpn.Step2.needsWiring.yes}
            onClick={() => flow.setFieldValue('needsWiring', true)}
            icon="stop"
            active={flow.values.needsWiring}
          />
        </div>{' '}
      </>
    ),
  };
};

export default Step2;
