import React, { useRef, useMemo } from 'react';
import { Values } from './useReplaceContactStepFlow';
import { t } from 'src/lib/i18n';
import { colors, Attribute, useFullScreenRef } from 'src/lib/ui';
import { SelectContactDropdown } from './SelectContactDropdown';
import { formatName } from 'src/lib/utils/formatName';
import { WithSearch, SearchField } from 'src/areas/main/search';
import { useWindowScroll } from 'src/areas/main/documents/table/useWindowScroll';

export const Step2Virtualized = (props: {
  values: Partial<Values>;
  setFieldValue: (key: keyof Values, value: any, validate: boolean) => void;
}) => {
  const rowHeight = 185;
  const { values, setFieldValue } = props;
  const i18n = t.contacts.DeletePerson.step2;
  const options = useMemo(() => Object.entries(values.map ?? {}), [values.map]);
  const containerRef = useRef<HTMLDivElement>(null);
  const scrollRef = useFullScreenRef();
  const renderList = useWindowScroll({
    allNodes: options,
    initialOffsetTop: containerRef.current?.offsetTop || 0,
    rowHeight,
    scrollRef,
  });

  return (
    <div className="ml-3" ref={containerRef}>
      <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
        <div className="pb-2">{i18n.replaceByService}</div>
        <div>{i18n.leaveEmpty}</div>
      </small>
      {renderList.map((item, i) => {
        if (!item) {
          return <div style={{ height: rowHeight }} key={i} />;
        } else {
          const [subsId, contact] = item;
          return (
            <div className="pb-5" style={{ height: rowHeight }} key={subsId}>
              <div>
                <div className="pb-2" style={{ color: colors.greyDarkText }}>
                  {contact.name}
                </div>
                <small className="d-block pb-2">
                  <Attribute label="Adresse" value={contact?.address} />
                  <Attribute label="Type" value={contact?.type} />
                  <Attribute label="ID" value={subsId} />
                </small>
              </div>
              <div className="w-50 d-flex align-items-center">
                <div className="pr-3" style={{ color: colors.greyDarkText }}>
                  {i18n.newContactPerson}:
                </div>

                <div className="flex-grow-1">
                  <SelectContactDropdown
                    activeContactName={formatName({
                      firstName: contact?.technicalContact?.firstName,
                      lastName: contact?.technicalContact?.lastName,
                    })}
                  >
                    <div>
                      <WithSearch initial={{ types: ['contact'] }} key={subsId}>
                        {sp => (
                          <SearchField
                            narrow={true}
                            searchProvider={{
                              ...sp,
                              results: sp.results.filter(
                                c => c.id !== values.initialContactId
                              ),
                            }}
                            renderSearchMenuOnFocus={true}
                            onSelect={item => {
                              if (item?.__typename === 'Contact') {
                                setFieldValue(
                                  'map',
                                  {
                                    ...values.map,
                                    [subsId]: {
                                      ...contact,
                                      technicalContact: item,
                                    },
                                  },
                                  true
                                );
                              }
                            }}
                          />
                        )}
                      </WithSearch>
                    </div>
                  </SelectContactDropdown>
                </div>
              </div>
            </div>
          );
        }
      })}
    </div>
  );
};
