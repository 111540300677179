import Nb from '../../nb/Main/statistics';

const statistics: typeof Nb = {
  noStatistics: 'This subscription does not have statistics',
  noInterface: 'Subscription does not have interfaces available',
  noData: 'No data',
  noPeriods: 'No data for chosen period',
  noTrafficClasses: 'No traffic classes for this selection',
  options: 'Options',
  showGrid: 'Show grid',
  granularity: 'Granularity',
  toggleFilters: {
    standard: 'STANDARD',
    live: 'LIVE-TRAFFIC',
    qos: 'QUALITY OF SERVICE',
    gsm: 'SIGNAL STRENGTH',
  },
  Legend: {
    trafficIn: 'Traffic in',
    trafficOut: 'Traffic out',
    trafficInAvg: 'Traffic in (avg)',
    trafficOutAvg: 'Traffic out (avg)',
    trafficInMax: 'Traffic in (max)',
    trafficOutMax: 'Traffic out (max)',
    jitterIn: 'Jitter in',
    jitterOut: 'Jitter out',
    packetDropIn: 'Packet drop in',
    packetDropOut: 'Packet drop out',
    packetLossIn: 'Packet loss in',
    packetLossOut: 'Packet loss out',
    responseTime: 'Response time',
    roundTripTime: 'Round-trip time',
  },
  InterfaceDropdownPicker: {
    placeholder: 'Search for interface',
  },
  StatisticsDetails: {
    header: 'Details',
  },
  StatisticsGSM: {
    noData: 'No data',
  },
  SyncChartElement: {
    threshold: 'Threshold',
    thresholdBtn: 'See utilization',
  },
  Gsm: {
    signalQuality: 'Signal quality (Ec/Io)',
  },
  Qos: {
    noTraffic: 'No traffic',
    traffic: 'Traffic',
    dropRate: 'Drop rate',
  },
  StandardDetails: {
    showUtilization: 'Show utilization',
    in: 'In',
    out: 'Out',
    other: 'Other information',
    throughputAvg: 'Throughput (avg)',
    throughputMax: 'Throughput (max)',
    cumulativeAvg: 'Cumulative traffic (avg)',
    cumulativeTotal: 'Cumulative traffic (total)',
    packetDrop: 'Packet drop (amount)',
    responseTimeAvg: 'Response time (avg)',
    responseTimeMax: 'Response time (max)',
    jitter: 'Jitter',
    packetLoss: 'Packet loss (amount)',
    utilization: 'Utilization',
    roundTripTimeAvg: 'Round-trip time (avg)',
    roundTripTimeMax: 'Round-trip time (max)',
  },
  StatisticsFilter: {
    throughput: 'Throughput',
    cumulative: 'Cumulative traffic',
    packetDrop: 'Packet drop',
    responseTime: 'Response time',
    jitter: 'Jitter',
    packetLoss: 'Packet loss',
    utilization: 'Utilization',
    roundTripTime: 'Round-trip time',
    qosTransmit: 'QoS traffic',
    qosDrop: 'QoS packet drop',
    live: 'Live traffic',
    gsm: 'GSM',
    allStandard: 'Snapshot',
    chooseInterface: 'Choose interface',
    chooseStatistics: 'Choose statistics',
    show: 'Show',
    for: 'for',
    inPeriod: 'in the period',
  },
  StandardChart: {
    capacity: 'Capacity',
    capacityIn: 'Capacity in',
    capacityOut: 'Capacity out',
  },
  Granularity: {
    raw: 'per 5 minutes',
    hour: 'per hour',
    day: 'per day',
  },
};

export default statistics;
