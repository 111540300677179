import * as React from 'react';

import { formatNordic } from 'src/lib/utils';

interface NumberProps {
  value?: number | null;
  percentage?: boolean;
  decimals?: number;
  unit?: string;
}

export const FormattedNumber: React.SFC<NumberProps> = props => {
  if (typeof props.value !== 'number') {
    return null;
  }
  const decimals = props.decimals === undefined ? 2 : props.decimals;
  return (
    <React.Fragment>
      {props.percentage
        ? `${formatNordic(props.value * 100, decimals)} %`
        : formatNordic(props.value, decimals)}
      {props.unit ? ` ${props.unit}` : null}
    </React.Fragment>
  );
};
