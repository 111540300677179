import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { MobileOrderDetails } from '../types';
import { MobileOrderDetailsFragment } from '../lib/MobileOrderDetailsFragment';
import { ExecutionResult } from 'graphql';
import * as t from './__types/useBlockSim';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';

const MUTATION_BLOCK_SIMCARD_ORDER = gql`
  mutation useBlockSim($input: BlockSimCardOrderInput) {
    blockSimCardOrder(input: $input) {
      orderDetails {
        ... on MobileOrderDetails {
          ...MobileOrderDetailsResult
        }
      }
    }
  }
  ${MobileOrderDetailsFragment}
`;

export interface BlockSimCardOrderInput {
  customerId: string;
  msisdn: string;
}

export interface BlockSimcardOrderResult {
  blockSimcardOrder: (msisdn: string) => Promise<ExecutionResult>;
  loading: boolean;
  error: boolean;
  data?: MobileOrderDetails | null;
}

export const useBlockSim = (): BlockSimcardOrderResult => {
  const { customerId } = useSubscriptionContext();
  const [mutation, { data, loading, error }] = useMutation<
    t.useBlockSim,
    t.useBlockSimVariables
  >(MUTATION_BLOCK_SIMCARD_ORDER);

  const blockSimcardOrder = async (msisdn: string) =>
    await mutation({
      variables: {
        input: { customerId, msisdn: msisdn },
      },
    });

  return {
    blockSimcardOrder,
    loading,
    error: !!error,
    data: data?.blockSimCardOrder,
  };
};
