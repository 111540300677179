import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { MobileSettings } from 'src/lib/types';
import * as t from './__types/useMobileSettings';
import { BarringSettingsFragment } from 'src/lib/types/mobile/fragments';
import { mapNullableBooleanObject } from '../utils/objectUtils';
import _ from 'lodash';

function omitTypenameKeyFromObject(obj) {
  _.forIn(obj, function(value, key) {
    if (_.isObject(value)) {
      omitTypenameKeyFromObject(value);
    } else if (key === '__typename') {
      delete obj[key];
    }
  });
}

export const WITH_MOBILE_SETTINGS = gql`
  query useMobileSettings($subscriptionId: ID!) {
    subscription(id: $subscriptionId) {
      id
      settings {
        roamingAlert {
          smsWhenRoaming
        }
        callForwarding {
          allCalls
          allCallsDestination
          busy
          busyDestination
          noAnswer
          noAnswerDestination
        }
        voicemail {
          smsNotification
          emailNotification
          visualVoicemail
        }
        barring {
          ...BarringSettingsFragment
        }
      }
    }
  }
  ${BarringSettingsFragment}
`;

interface Result {
  loading: boolean;
  settings?: MobileSettings;
  error?: boolean;
}

export const useMobileSettings = (): Result => {
  const subscription = useSubscriptionContext();

  const { data, loading, error } = useQuery<
    t.useMobileSettings,
    t.useMobileSettingsVariables
  >(WITH_MOBILE_SETTINGS, {
    variables: {
      subscriptionId: subscription.id,
    },
  });
  omitTypenameKeyFromObject(data?.subscription?.settings);
  const barring = data?.subscription?.settings?.barring
    ? mapNullableBooleanObject<
        Omit<t.useMobileSettings_subscription_settings_barring, '__typename'>
      >(data.subscription.settings.barring)
    : undefined;

  const voicemail = data?.subscription?.settings?.voicemail
    ? mapNullableBooleanObject<
        Omit<t.useMobileSettings_subscription_settings_voicemail, '__typename'>
      >(data.subscription.settings.voicemail)
    : undefined;
  const roamingAlert = data?.subscription?.settings?.roamingAlert
    ? mapNullableBooleanObject<
        Omit<
          t.useMobileSettings_subscription_settings_roamingAlert,
          '__typename'
        >
      >(data.subscription.settings.roamingAlert)
    : undefined;

  const settings: MobileSettings = {
    voicemail,
    barring,
    roamingAlert,
    callForwarding: data?.subscription?.settings?.callForwarding ?? undefined,
  };

  return {
    loading,
    settings,
    error: !!error,
  };
};
