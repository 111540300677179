import React from 'react';
import { Subscription } from 'src/lib/types';
import { formatInstallationAddress } from 'src/lib/utils';
import { CircleIcon, Attribute } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import cs from 'classnames';

export const Position = (props: {
  subscription: Subscription;
  className: string;
}) => {
  const { subscription } = props;
  const fullAddress = formatInstallationAddress(
    subscription.installationAddress,
    'fullAddress'
  );
  const i18n = t.locations.Sidebar.subscription;
  return (
    <div className={cs('d-flex align-items-center', props.className)}>
      <div className="mr-4">
        <CircleIcon icon="map" filled={true} />
      </div>
      <div>
        <small className="d-block grey-dark-text">{i18n.position}</small>
        <div>{fullAddress}</div>
        {subscription?.installationAddress?.location?.lon &&
          subscription?.installationAddress?.location?.lat && (
            <>
              <Attribute
                label={i18n.lat}
                value={subscription.installationAddress.location.lat.toFixed(7)}
              />
              <Attribute
                label={i18n.lon}
                value={subscription.installationAddress.location.lon.toFixed(7)}
              />
            </>
          )}
      </div>
    </div>
  );
};
