import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
import { DetailCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import './ReportsContent.scss';
import { Accounting } from './Accounting';
import { LandLine } from './LandLine';
import { Mobile } from './Mobile';
import business from 'src/images/illustrations/business.svg';
import { DownloadModal } from './DownloadModal';
import { useReportContext } from './context/ReportContext';

const ReportsContent: React.FunctionComponent<RouteComponentProps> = props => {
  const { selectReport, downloadParams, open, setOpen } = useReportContext();
  return (
    <>
      <PageHeader title={t.reports.reports} />
      <div className="ReportsContent container">
        <Accounting selectReport={selectReport} />
        <LandLine selectReport={selectReport} />
        <Mobile selectReport={selectReport} />
        <DetailCard heading={t.reports.categories.aboutReports} color="white">
          <div className="p-3">{t.reports.aboutReports.description}</div>
          <div className="text-center">
            <img src={business} alt="business" />
          </div>
        </DetailCard>

        {downloadParams.generateReport !== undefined && (
          <DownloadModal
            header={downloadParams.fileType}
            open={open}
            toggle={() => setOpen(!open)}
            generateReport={downloadParams.generateReport}
            {...downloadParams}
          />
        )}
      </div>
    </>
  );
};

export default ReportsContent;
