import * as React from 'react';
import { useDeleteFolder } from '../mutations/useDeleteFolder';
import Form from 'reactstrap/lib/Form';
import { getUndoDeleteFolderAction } from 'src/areas/main/documents/undo/UndoActions';
import { t } from 'src/lib/i18n';
import { Folder } from 'src/lib/types';
import { useRestoreFolder } from '../mutations/useRestoreFolder';
import { FormSubmitBar } from 'src/lib/ui';
import undoPopup from 'src/areas/main/documents/undo/UndoPopup';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useDocumentsContext } from '../DocumentsContext';
import { trackDocument } from 'src/lib/analytics';

interface Props {
  cancel: () => void;
  folder: Folder;
}

const DeleteFolderForm = (props: Props) => {
  const context = useDeleteFolder();
  const { restoreFolder } = useRestoreFolder();
  const { category } = useDocumentsContext();

  const handleSubmit = e => {
    trackDocument(category, 'delete_folder');
    e.preventDefault();
    context
      .deleteFolder(props.folder.id)
      .then(() =>
        undoPopup(getUndoDeleteFolderAction(props.folder, restoreFolder))
      );
  };

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <ModalBody>
        <div>
          <p>
            {t.documents.DeleteFolderForm.confirmDelete}{' '}
            <b>{props.folder.name}</b>?
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          cancel={props.cancel}
          loading={context.loading}
          submitLabel={t.documents.DeleteFolderForm.submit}
          error={!!context.error}
        />
      </ModalFooter>
    </Form>
  );
};

export default DeleteFolderForm;
