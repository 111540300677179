import * as React from 'react';
import { useBlockSim } from './useBlockSim';
import { t } from 'src/lib/i18n';
import { Modal, ModalFooter, ModalHeader, ModalBody } from 'reactstrap';
import { FormSubmitBar } from 'src/lib/ui';

interface OrderBlockSimProps {
  msisdn: string;
  isOpen: boolean;
  toggle: () => void;
}

const BlockSimModal: React.FunctionComponent<OrderBlockSimProps> = props => {
  const i18n = t.ordering.OrderBlockSim;
  const { blockSimcardOrder, loading, error } = useBlockSim();

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader>{i18n.blockSimCard}</ModalHeader>
      <ModalBody>{i18n.description}</ModalBody>
      <ModalFooter>
        <FormSubmitBar
          loading={loading}
          submit={() =>
            blockSimcardOrder(props.msisdn).then(r => props.toggle())
          }
          cancel={props.toggle}
          submitLabel={i18n.blockSim}
          error={error}
          errorMsg={i18n.errorMsg}
        />
      </ModalFooter>
    </Modal>
  );
};

export default BlockSimModal;
