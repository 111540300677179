import { FileFragment } from './../../../../lib/types/file/FileFragment';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { File } from 'src/lib/types';
import * as t from './__types/useSetFileTeliaInternal';

const SET_INTERNAL = gql`
  mutation useSetFileTeliaInternal($input: SetFileTeliaInternalInput) {
    setFileTeliaInternal(input: $input) {
      file {
        ...FileSearchResult
      }
      error {
        code
        message
      }
    }
  }
  ${FileFragment}
`;

interface SetFileTeliaInternalResult {
  setFileTeliaInternal: (file: File) => void;
  data?: t.useSetFileTeliaInternal;
  loading: boolean;
  error?: boolean;
}
export const useSetFileTeliaInternal = (): SetFileTeliaInternalResult => {
  const [mutation, { data, loading, error }] = useMutation<
    t.useSetFileTeliaInternal,
    t.useSetFileTeliaInternalVariables
  >(SET_INTERNAL, { errorPolicy: 'all' });

  const setFileTeliaInternal = (file: File) => {
    mutation({
      variables: {
        input: {
          fileId: file.id,
          internal: !file.teliaInternal,
        },
      },
    });
  };

  return {
    setFileTeliaInternal,
    data,
    loading,
    error: !!(error || !!data?.setFileTeliaInternal?.error),
  };
};
