import React from 'react';
import { useFormik } from 'formik';
import { TextField, FormSubmitBar } from 'src/lib/ui';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import * as yup from 'yup';
import { useCreatePersonMutation } from 'src/areas/main/servicePlus/queries/mutations/useCreateServicePlusPerson';
import { useCustomer } from 'src/lib/global';
import { useCreateLocationMutation } from 'src/areas/main/servicePlus/queries/mutations/useCreateServicePlusAddress';
import { t } from 'src/lib/i18n';
interface Props {
  isOpen: boolean;
  setOpen(boolean): any;
}

export function NewAddressModal(props: Props) {
  const translations = t.servicePlus;
  const customer = useCustomer();
  const createPersonMutation = useCreatePersonMutation();
  const createLocationMutation = useCreateLocationMutation();

  function toggleState() {
    formik.setTouched({});
    props.setOpen(!props.isOpen);
  }

  interface FormValues {
    address: string;
    postalCode: string;
    city: string;
    organisationNumber: string;
    contactFirstName: string;
    contactLastName: string;
    contactEmail: string;
    contactPhone: string;
  }

  const formik = useFormik<FormValues>({
    initialValues: {
      address: '',
      postalCode: '',
      city: '',
      organisationNumber: '',
      contactFirstName: '',
      contactLastName: '',
      contactEmail: '',
      contactPhone: '',
    },
    onSubmit: async (values, formikBag) => {
      const creator = await createPersonMutation.useCreateServicePlusPerson(
        customer.id,
        values.contactFirstName,
        values.contactLastName,
        values.contactEmail,
        values.contactPhone
      );
      const creatorId = parseInt(
        creator?.data?.createServicePlusPerson?.servicePlusPerson?.id ?? '0',
        10
      );
      const newLocation = await createLocationMutation.useCreateServicePlusLocation(
        customer.id,
        values.address,
        values.city,
        values.postalCode,
        creatorId,
        values.organisationNumber
      );
      props.setOpen(false);
      formikBag.resetForm();
      return newLocation;
    },
    validationSchema: () =>
      yup.object().shape({
        address: yup
          .string()
          .min(1, translations.newLocation.tooShortError)
          .required(translations.newLocation.required),
        postalCode: yup
          .string()
          .matches(/\d{4}/, translations.newLocation.postalCodeError)
          .required(translations.newLocation.required),
        city: yup
          .string()
          .min(1, translations.newLocation.tooShortError)
          .required(translations.newLocation.required),
        organisationNumber: yup
          .string()
          .min(1, translations.newLocation.tooShortError)
          .required(translations.newLocation.required),
        contactFirstName: yup
          .string()
          .min(1, translations.newLocation.tooShortError)
          .required(translations.newLocation.required),
        contactLastName: yup
          .string()
          .min(1, translations.newLocation.tooShortError)
          .required(translations.newLocation.required),
        contactEmail: yup
          .string()
          .min(1, translations.newLocation.tooShortError)
          .required(translations.newLocation.required),
        contactPhone: yup.string(),
      }),
  });
  return (
    <>
      <Modal isOpen={props.isOpen} toggle={toggleState}>
        <ModalHeader>{translations.newLocation.addNewLocation}</ModalHeader>
        <ModalBody>
          <div style={{ flexDirection: 'column' }}>
            <b>Adresse</b>
            <div>
              <TextField
                id="address"
                label={translations.newLocation.address}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.address}
                required={true}
                error={
                  formik.touched.address && formik.errors.address
                    ? formik.errors.address
                    : undefined
                }
              />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
              <TextField
                id="postalCode"
                label={translations.newLocation.postalCode}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.postalCode}
                required={true}
                error={
                  formik.touched.postalCode && formik.errors.postalCode
                    ? formik.errors.postalCode
                    : undefined
                }
              />
              <TextField
                id="city"
                label={translations.newLocation.city}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.city}
                required={true}
                error={
                  formik.touched.city && formik.errors.city
                    ? formik.errors.city
                    : undefined
                }
              />
            </div>
            <div>
              <TextField
                id="organisationNumber"
                label={translations.newLocation.organisationNumber}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.organisationNumber}
                required={true}
                error={
                  formik.touched.organisationNumber &&
                  formik.errors.organisationNumber
                    ? formik.errors.organisationNumber
                    : undefined
                }
              />
            </div>
            <br />
            <b>{translations.newLocation.contact}</b>
            <div>
              <TextField
                id="contactFirstName"
                label={translations.newLocation.contactFirstName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactFirstName}
                required={true}
                error={
                  formik.touched.contactFirstName &&
                  formik.errors.contactFirstName
                    ? formik.errors.contactFirstName
                    : undefined
                }
              />
            </div>
            <div>
              <TextField
                id="contactLastName"
                label={translations.newLocation.contactLastName}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactLastName}
                required={true}
                error={
                  formik.touched.contactLastName &&
                  formik.errors.contactLastName
                    ? formik.errors.contactLastName
                    : undefined
                }
              />
            </div>
            <div>
              <TextField
                id="contactEmail"
                label={translations.newLocation.contactEmail}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactEmail}
                required={true}
                error={
                  formik.touched.contactEmail && formik.errors.contactEmail
                    ? formik.errors.contactEmail
                    : undefined
                }
              />
            </div>
            <div>
              <TextField
                id="contactPhone"
                label={translations.newLocation.contactPhone}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.contactPhone}
                error={
                  formik.touched.contactPhone && formik.errors.contactPhone
                    ? formik.errors.contactPhone
                    : undefined
                }
              />
            </div>
          </div>
        </ModalBody>
        <ModalFooter>
          <FormSubmitBar
            cancel={toggleState}
            submit={() => formik.handleSubmit()}
          />
        </ModalFooter>
      </Modal>
    </>
  );
}
