import React, { useState } from 'react';
import { ContextMenu, ContextMenuItem } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import BlockSimModal from 'src/areas/main/ordering/OrderBlockSim/BlockSimModal';
import { ReplaceSimModal } from 'src/areas/mobile/overview/ReplaceSimModal';
import { TerminateAdditionalSimModal } from 'src/areas/main/ordering/OrderTerminateMobileSubscription/TerminateAdditionalSimModal';

interface Props {
  isMain: boolean;
  msidn: string;
}

export const SimOptions: React.FC<Props> = props => {
  const [blockModal, setBlockModal] = useState(false);
  const [replaceModal, setReplaceModal] = useState(false);
  const [terminateModal, setTerminateModal] = useState(false);

  const toggleBlockModal = () => setBlockModal(!blockModal);
  const toggleReplaceModal = () => setReplaceModal(!replaceModal);
  const toggleTerminateModal = () => setTerminateModal(!terminateModal);

  return (
    <React.Fragment>
      <ContextMenu>
        {props.isMain && (
          <ContextMenuItem onClick={toggleBlockModal}>
            {t.ordering.OrderBlockSim.blockSim}
          </ContextMenuItem>
        )}
        {props.isMain && (
          <ContextMenuItem onClick={toggleReplaceModal}>
            {t.mobile.overview.sim.replaceSim}
          </ContextMenuItem>
        )}
        {!props.isMain && (
          <ContextMenuItem onClick={toggleTerminateModal}>
            {
              t.ordering.OrderTerminateMobileSubscription.additionalSim
                .terminate
            }
          </ContextMenuItem>
        )}
      </ContextMenu>
      <BlockSimModal
        isOpen={blockModal}
        toggle={toggleBlockModal}
        msisdn={props.msidn}
      />
      <ReplaceSimModal open={replaceModal} toggle={toggleReplaceModal} />
      <TerminateAdditionalSimModal
        msisdn={props.msidn}
        isOpen={terminateModal}
        toggle={toggleTerminateModal}
      />
    </React.Fragment>
  );
};
