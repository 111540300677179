import React, { useState } from 'react';
import Chat from '../Chat/Chat';
import axios from 'axios';
import { ChatActivity } from 'src/lib/chat/types';
import { ChatContextProvider } from 'src/lib/chat/ChatContext';
import { useAppContext } from 'src/lib/global';

interface Props {
  messages: ChatActivity[];
  bearerToken?: string;
  handleSend: (incidentId: string, commentInput: string) => void;
  disableInput: boolean;
  incidentId?: string;
  refetch: () => void;
}

const ChatWrapper: React.FC<Props> = props => {
  const [chatInput, setChatInput] = useState('');
  const [isUploadingFiles, setIsUploadingFiles] = useState(false);
  const [files, setFiles] = useState<File[]>([]);
  const app = useAppContext();
  const { incidentId } = props;

  const innerRef = React.createRef<HTMLTextAreaElement>();
  const handleInputChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setChatInput(e.target.value);
  };

  const handleFilesChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles([...files, ...newFiles]);
    }
  };

  const handleRemoveFile = (name: string) => {
    setFiles(files.filter(f => f.name !== name));
  };

  const handleUploadFile = (file: File) => {
    if (!incidentId || !props.bearerToken) {
      return;
    }

    const formData = new FormData();
    formData.append('file', file, file.name);

    const config = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${props.bearerToken}`,
      },
    };

    return axios
      .post(
        [
          '/api/files/incidents/',
          encodeURIComponent(incidentId),
          '/attachment',
        ].join(''),
        formData,
        config
      )
      .then(() => {
        console.log(`finished uploading ${file.name}`);
      })
      .catch(errorMsg => {
        console.log(errorMsg);
      });
  };

  const handleSend = async (e: React.FormEvent<Element>) => {
    e.preventDefault();
    if (chatInput.length && incidentId) {
      props.handleSend(incidentId, chatInput);
      setChatInput('');
    }
    if (files.length) {
      setIsUploadingFiles(true);
      await Promise.all(files.map(f => handleUploadFile(f)));
      props.refetch();
      setFiles([]);
      setIsUploadingFiles(false);
    }
  };

  return (
    <ChatContextProvider
      value={{
        activeUserId: app.access.id,
        messages: props.messages,
        chatInput: chatInput,
        files: files,
        isUploadingFiles: isUploadingFiles,
        handlers: {
          handleSend: handleSend,
          handleInputChange: handleInputChange,
          handleRemoveFile: handleRemoveFile,
          handleFileInputChange: handleFilesChange,
        },
        disableInput: props.disableInput,
        innerRef: innerRef,
      }}
    >
      <Chat />
    </ChatContextProvider>
  );
};

export default ChatWrapper;
