import React from 'react';
import {
  FormSubmitBar,
  Attribute,
  Attributes,
  Button,
  TextField,
  colors,
  Feature,
} from 'src/lib/ui';
import { IpRouting } from 'src/lib/types/subscription/IpRouting';
import { t } from 'src/lib/i18n';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useEditIpRouting, configKeys } from './useEditIpRouting';
import { ChangeSubmitted } from './ChangeSubmitted';
import { ipRoutingInputConstraints } from './validations';
import { trackOrderChange } from 'src/lib/analytics';

export const IpRoutingComponent: React.FC<{
  subscription: {
    id: string;
  };
  ipRouting?: null | IpRouting[];
}> = props => {
  const {
    routes,
    handleChange,
    toggleModal,
    openModal,
    handleSubmit,
    submitted,
    disableSubmit,
  } = useEditIpRouting(props.ipRouting ?? []);
  const i18n = t.subscriptions.TechnicalDetailsCard;

  if (!props.ipRouting) {
    return null;
  }

  const handleIpRoutingChange = (subscriptionId: string) => {
    handleSubmit(subscriptionId);
    trackOrderChange('static routing');
  };

  const ips = props.ipRouting?.map((ipRoute, idx) => {
    if (!ipRoute.networkAddress) {
      return null;
    }
    return (
      <div key={idx} className="TechnicalDetailsCard-row-sub pb-4">
        <span style={{ fontWeight: 600 }}>{ipRoute.networkAddress}</span>
        <Attributes inline={true}>
          {ipRoute.subnetMask && (
            <Attribute label={i18n.subnetMask} value={ipRoute.subnetMask} />
          )}
          {ipRoute.gateway && (
            <Attribute label={i18n.gateway} value={ipRoute.gateway} />
          )}
          <Attribute
            label="Metric/Distance"
            value={ipRoute.metricDistance || ''}
          />
          <Attribute
            label="No redist."
            value={ipRoute.noRedistribution || i18n.noRedist}
          />
        </Attributes>
      </div>
    );
  });

  return (
    <div className="TechnicalDetailsCard-row">
      <h3 style={{ fontWeight: 600 }}>{i18n.staticRouting}</h3>
      {ips}

      <Feature feature="poc-network-change-order">
        <div className="pb-4">
          <Button outline={true} icon="pen" onClick={toggleModal}>
            {i18n.editIpRouting.edit}
          </Button>
        </div>
      </Feature>
      <Modal isOpen={openModal} toggle={toggleModal} size="lg">
        {submitted ? (
          <ModalBody style={{ paddingTop: '5rem' }}>
            <ChangeSubmitted
              onGoBack={toggleModal}
              submittedDescription={i18n.editIpRouting.submittedDescription}
              goBackLabel={i18n.editIpRouting.close}
            />
          </ModalBody>
        ) : (
          <>
            <ModalHeader>{i18n.editIpRouting.changeIpRouting}</ModalHeader>
            <ModalBody>
              <small className="d-block pb-4">
                {i18n.editIpRouting.description}
              </small>
              {routes.map((route, index) => (
                <div className="d-flex" key={index}>
                  {configKeys.map(key => (
                    <TextField
                      key={`${key}-${index}`}
                      className="pr-3"
                      bordered={true}
                      value={route[key] ?? ''}
                      pattern={ipRoutingInputConstraints[key]}
                      onChange={e => {
                        if (!e.target.validity.patternMismatch) {
                          handleChange({
                            value: e.target.value,
                            configKey: key,
                            index,
                          });
                        }
                      }}
                      label={i18n[key]}
                    />
                  ))}
                </div>
              ))}

              <small
                className="d-block p-3"
                style={{
                  backgroundColor: colors.background,
                  borderRadius: '0.5rem',
                }}
              >
                {i18n.editIpRouting.onCompleteDescription}
              </small>
            </ModalBody>
            <ModalFooter>
              <FormSubmitBar
                disabled={disableSubmit}
                cancel={toggleModal}
                submitLabel={i18n.editIpRouting.submitLabel}
                submit={() => handleIpRoutingChange(props.subscription.id)}
              />
            </ModalFooter>
          </>
        )}
      </Modal>
    </div>
  );
};
