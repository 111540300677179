import { push } from './Analytics';

export type InvoiceActions = 'download_invoice';

export function trackInvoiceDownload(url: string) {
  let documentType = '';

  if (url.includes('invoiceDetails') && url.includes('report')) {
    documentType = 'månedsrapport';
  } else if (url.includes('pdf') && url.includes('invoice')) {
    documentType = 'pdf';
  } else if (url.includes('csv') && url.includes('invoice')) {
    documentType = 'csv';
  } else if (url.includes('excel') && url.includes('invoice')) {
    documentType = 'excel';
  }
  if (documentType.length > 0) {
    push({
      category: 'Invoice',
      action: 'download_invoice',
      label: documentType,
    });
  }
}
