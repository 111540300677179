import React from 'react';
import {
  Button,
  FullscreenHeader,
  FullscreenBody,
  Fullscreen,
} from 'src/lib/ui';
import { getSubscriptionDisplayTitles } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { formatInstallationAddress } from 'src/lib/utils';
import { getGroupLabel } from 'src/areas/main/subscriptions/utils';
import { TechnicalContactSubscription } from '../../queries/useContact';
import { useReplaceContactStepFlow } from './useReplaceContactStepFlow';
import SaveOverlay from '../SaveOverlay/SaveOverlay';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Step3 } from './Step3';

export const EditServices = (props: {
  subscriptions?: TechnicalContactSubscription[] | null;
}) => {
  const i18n = t.contacts;

  const contactSubscriptions = props.subscriptions?.map(s => ({
    id: s.id,
    name: getSubscriptionDisplayTitles(s).title,
    address: s.installationAddress
      ? formatInstallationAddress(s.installationAddress, 'fullAddress')
      : '',
    type: `${s.group ? ` (${getGroupLabel(s.group)})` : ''}`,
  }));

  const {
    form,
    replaceTechnicalContacts,
    open,
    loading,
    error,
    toggle,
  } = useReplaceContactStepFlow({
    technicalContactSubscriptions: contactSubscriptions ?? [],
    deleteContact: false,
  });

  const isLastStep = form.currentStep === 1;
  if (open) {
    return (
      <Fullscreen>
        <FullscreenHeader
          title={i18n.editServices.editContactResponsibilities}
        ></FullscreenHeader>
        <FullscreenBody>
          <form>
            <Step1 form={form} />
            {form.currentStep === 1 ? (
              form.values.replace !== 'none' ? (
                <Step2 form={form} isValid={true} isLastStep={true} />
              ) : (
                <Step3 form={form} />
              )
            ) : null}
            <SaveOverlay
              ignoreSidebar={true}
              cancel={toggle}
              submit={() => replaceTechnicalContacts(form.values)}
              loading={loading}
              disabled={!(isLastStep && form.isFieldValid('replaceAllContact'))}
              error={error}
              errorMsg={i18n.DeletePerson.replaceError}
            />
          </form>
        </FullscreenBody>
      </Fullscreen>
    );
  }
  return (
    <Button className="d-block" color="white" onClick={toggle}>
      {i18n.editServices.editContactResponsibilities}
    </Button>
  );
};
