import React, { useContext } from 'react';
import { File, Folder, NavigateTo } from 'src/lib/types';
import { Category } from 'src/lib/analytics/Analytics';

type DocumentsContext = {
  documentArchive: {
    files: File[];
    folders: Folder[];
  };
  setDocumentsInternalOnUpload: boolean;
  navigateTo: NavigateTo;
  loading: boolean;
  path: string;
  subscriptionId?: string;
  orderId?: string;
  disableFolders?: boolean;
  allowEditFileSharing?: boolean;
  refetchFileStructure?: () => void;
  customerId: string;
  /** This prop us used to track document events to Google Analytics,
      with different categories based on the area/context the documents are in.
      Such as the document archive, orders, worklog or subscriptions */
  category: Category;
};

const documentsContext = React.createContext<DocumentsContext | undefined>(
  undefined
);

const { Provider } = documentsContext;

const useDocumentsContext = () => {
  const context = useContext(documentsContext);
  if (!context) {
    throw new Error('No document context');
  }
  return context;
};

export { Provider as DocumentsContextProvider, useDocumentsContext };
