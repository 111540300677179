import React, { useEffect, RefObject, useRef, useContext } from 'react';
import cs from 'classnames';

import './Fullscreen.scss';
import FocusLock from 'react-focus-lock';

interface FullscreenProps {
  style?: React.CSSProperties;
  dimmedBackground?: boolean;
}

type FullscreenContext =
  | undefined
  | {
      fullscreenRef: RefObject<HTMLDivElement>;
    };

const FullScreenContext = React.createContext<FullscreenContext>(undefined);

export const useFullScreenRef = () =>
  useContext(FullScreenContext)?.fullscreenRef;

export const Fullscreen: React.FunctionComponent<FullscreenProps> = props => {
  const fullscreenRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    document.body.classList.add('noscroll');
    return () => {
      document.body.classList.remove('noscroll');
    };
  }, []);

  return (
    <FocusLock>
      <FullScreenContext.Provider value={{ fullscreenRef }}>
        <div
          className={cs('Fullscreen', { dimmed: props.dimmedBackground })}
          style={props.style}
          ref={fullscreenRef}
        >
          {props.children}
        </div>
      </FullScreenContext.Provider>
    </FocusLock>
  );
};
