import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as t from './__types/useSetCostCenterForSubscription';
const MUTATION_SET_COST_CENTER_FOR_SUBSCRIPTION = gql`
  mutation useSetCostCenterForSubscription(
    $input: SetCostCenterForSubscriptionInput
  ) {
    setCostCenterForSubscription(input: $input) {
      subscription {
        id
        costCenter {
          id
          description
        }
      }
      error {
        code
        message
      }
    }
  }
`;

interface SetCostCenterForSubscriptionResult {
  setCostCenterForSubscription: (
    subscriptionId: string,
    costCenterId: string
  ) => Promise<void>;
  loading: boolean;
  error: boolean;
  result?: t.useSetCostCenterForSubscription;
}

export const useSetCostCenterForSubscription = (): SetCostCenterForSubscriptionResult => {
  const [mutation, { data, loading, error }] = useMutation<
    t.useSetCostCenterForSubscription,
    t.useSetCostCenterForSubscriptionVariables
  >(MUTATION_SET_COST_CENTER_FOR_SUBSCRIPTION);

  const setCostCenterForSubscription = async (
    subscriptionId: string,
    costCenterId: string
  ) => {
    await mutation({
      variables: {
        input: { subscriptionId, costCenterId },
      },
    });
  };

  return {
    setCostCenterForSubscription,
    loading,
    result: data,
    error: !!error || !!data?.setCostCenterForSubscription?.error,
  };
};
