import * as React from 'react';
import './CircleIcon.scss';
import { IconDefinition, Icon } from '@telia/styleguide';
import cs from 'classnames';
import { Spinner } from 'src/lib/ui/Spinner/Spinner';
import { StatusColor } from 'src/lib/types';

interface Props {
  className?: string;
  icon: IconDefinition;
  size?: 'sm' | 'default';
  loading?: boolean;
  color?: StatusColor;
  filled?: boolean;
}
export const CircleIcon = (props: Props) => {
  return (
    <div
      className={cs(
        'CircleIcon',

        {
          'CircleIcon-filled': props.filled,
          'CircleIcon-filled--small': props.size,
        },
        props.color,
        props.className
      )}
    >
      {props.loading ? <Spinner size="md" /> : <Icon icon={props.icon} />}
    </div>
  );
};
