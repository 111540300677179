import React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { omit } from 'lodash';
import { DayPickerRangeController } from 'react-dates';
import TimeField from 'react-simple-timefield';
import { t } from 'src/lib/i18n';

/**
 * Custom styles has to come after react-dates
 */
import 'react-dates/initialize';
import 'react-dates/lib/css/_datepicker.css';
import './DateRangePicker.scss';
import { FilterButton } from 'src/lib/ui';
import { InputDatesProps, DateRangePickerProps } from './types';
import moment from 'moment';
import { Icon } from '@telia/styleguide';
import { trackStatisticsView } from 'src/lib/analytics';

const presetToday = () => {
  const currentDate = moment();

  return {
    days: 0,
    title: t.ui.DateRangePicker.presetDays[0],
    start: currentDate
      .clone()
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 }),
    end: currentDate,
  };
};

const presetYesterday = () => {
  const yesterday = moment()
    .subtract(1, 'day')
    .set({ hour: 0, minute: 0, second: 0, millisecond: 0 });
  const midnight = moment()
    .subtract(1, 'day')
    .set({
      hour: 23,
      minute: 59,
      second: 0,
      millisecond: 0,
    });

  return {
    days: 1,
    title: t.ui.DateRangePicker.presetDays[1],
    start: yesterday,
    end: midnight,
  };
};

const generatePresets = (): DatePreset[] => {
  const i18n = t.ui.DateRangePicker.presetDays;
  const presetPeriods: Array<{ title: string; days: number }> = [
    { title: i18n[7], days: 7 },
    { title: i18n[28], days: 28 },
    { title: i18n[90], days: 90 },
  ];
  const currentDate = moment();
  return presetPeriods.map(period => ({
    days: period.days,
    title: period.title,
    start: currentDate.clone().subtract(period.days - 1, 'days'),
    end: currentDate,
  }));
};

interface DatePreset {
  days: number;
  title: string;
  start: moment.Moment;
  end: moment.Moment;
}

interface State {
  selectedPreset: number | undefined;
}

class DateRangePicker extends React.Component<
  DateRangePickerProps & RouteComponentProps,
  State
> {
  private presets: DatePreset[];
  constructor(props) {
    super(props);
    this.presets = [presetToday(), presetYesterday(), ...generatePresets()];
    this.state = {
      selectedPreset: undefined,
    };
  }

  trackDatePresets(presetTitle: string) {
    const url = this.props.location;
    if (url.pathname.includes('statistikk')) trackStatisticsView(presetTitle);
    return null;
  }

  onPresetHandler = (preset: DatePreset) => {
    this.trackDatePresets(preset.title);
    this.setState({ selectedPreset: preset.days });
    // return as one object to render only once in parent
    this.props.dayPickerProps.onDatesChange({
      startDate: preset.start,
      endDate: preset.end,
    });
  };

  onDateChangeHandler = args => {
    this.props.dayPickerProps.onDatesChange(args);
    this.setState({ selectedPreset: undefined });
  };

  renderDatePresets = () => {
    return (
      <div className="DateRangePicker-presets py-2 px-4">
        {this.presets.map(preset => (
          <FilterButton
            key={preset.days}
            checked={preset.days === this.state.selectedPreset}
            label={preset.title}
            onClick={() => this.onPresetHandler(preset)}
          />
        ))}
      </div>
    );
  };

  render() {
    const {
      dayPickerProps,
      inputProps,
      showDayPicker,
      showInputs,
    } = this.props;

    const props = omit(this.props.dayPickerProps, ['onDatesChange']);

    const startDateString =
      dayPickerProps.startDate && dayPickerProps.startDate.format('DD/MM/YYYY');
    const endDateString =
      dayPickerProps.endDate && dayPickerProps.endDate.format('DD/MM/YYYY');

    return (
      <div className="DateRangePicker">
        {showInputs ||
          (showInputs === undefined && (
            <DateInputs
              {...inputProps}
              endDate={dayPickerProps.endDate}
              startDate={dayPickerProps.startDate}
              endDateString={endDateString}
              startDateString={startDateString}
            />
          ))}
        {showDayPicker && (
          <DayPickerRangeController
            {...props}
            onDatesChange={this.onDateChangeHandler}
            renderCalendarInfo={this.renderDatePresets}
          />
        )}
      </div>
    );
  }
}

const DateInputs: React.SFC<InputDatesProps> = props => (
  <div className={`DateInputs ${props.className || ''}`}>
    <div className="DateInputs-inputsWrapper">
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center">
          <Icon className="ml-2" icon="calendar" />
          <input
            autoComplete="false"
            className="DateInputs-date"
            data-lpignore="true"
            name="start date"
            onFocus={() =>
              props.onInputFocus && props.onInputFocus('startDate')
            }
            placeholder={t.ui.DateRangePicker.placeholder.startDate}
            readOnly={props.readonly}
            type="text"
            value={props.startDateString || ''}
          />
        </div>
        {props.inputType === 'PeriodTime' && (
          <div className="d-flex align-items-center">
            <Icon icon="time" />
            <TimeField
              className="DateInputs-time"
              value={props.startTime}
              style={{
                width: undefined /* override the default width of the component */,
              }}
              onChange={val =>
                props.onTimeChange &&
                props.onTimeChange({
                  startTime: val.match(/\d\d:/)[0] + '00',
                  endTime: props.endTime,
                })
              }
            />
          </div>
        )}
      </div>
      <Icon className="DateInputs-inputSeperator" icon="arrow-right" />
      <div className="d-flex align-items-center">
        <div className="d-flex align-items-center pr-2">
          <Icon icon="calendar" />
          <input
            autoComplete="false"
            className="DateInputs-date"
            data-lpignore="true"
            name="end date"
            onFocus={() => props.onInputFocus && props.onInputFocus('endDate')}
            placeholder={t.ui.DateRangePicker.placeholder.endDate}
            readOnly={props.readonly}
            type="text"
            value={props.endDateString || ''}
          />
        </div>
        {props.inputType === 'PeriodTime' && (
          <div className="d-flex align-items-center">
            <Icon icon="time" />
            <TimeField
              className="DateInputs-time"
              value={props.endTime}
              style={{
                width: undefined /* override the default width of the component */,
              }}
              onChange={val =>
                props.onTimeChange &&
                props.onTimeChange({
                  startTime: props.startTime,
                  endTime: val.match(/\d\d:/)[0] + '00',
                })
              }
            />
          </div>
        )}
      </div>
    </div>
  </div>
);

export default withRouter(DateRangePicker);
