import { useAppContext } from 'src/lib/global';
import { trackDownload } from 'src/lib/analytics';

export const useDownloadLink = () => {
  const app = useAppContext();
  const bearerToken = app.auth?.bearerToken ?? '';

  if (!app.activeCustomerId) {
    throw new Error('No active customer id');
  }

  const customerParam = `c=${encodeURIComponent(app.activeCustomerId)}`;

  const downloadLink = (
    path: string,
    formParams: {
      [key: string]: string | number | null | undefined;
    },
    method?: 'post' | 'get'
  ) => {
    const urlWithCustomerId =
      path + (path.indexOf('?') > 0 ? '&' : '?') + customerParam;

    const params = {
      token: bearerToken,
      bearer_token: bearerToken,
      ...formParams,
    };

    trackDownload(path);

    const form = document.createElement('form');
    form.method = method ? method : 'post';
    form.action = urlWithCustomerId;

    const keys = Object.keys(params);
    keys.forEach(key => {
      const hiddenField = document.createElement('input');
      hiddenField.type = 'hidden';
      hiddenField.name = key;
      hiddenField.value = params[key]?.toString() ?? '';
      form.appendChild(hiddenField);
    });

    document.body.appendChild(form);
    form.submit();
  };

  return downloadLink;
};
