import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as types from './__types/useCreateServicePlusPerson';

export const CREATE_PERSON_QUERY = gql`
  mutation useCreateServicePlusPerson(
    $customerId: String!
    $firstName: String!
    $lastName: String!
    $email: String!
    $phone: String
  ) {
    createServicePlusPerson(
      input: {
        customerId: $customerId
        firstName: $firstName
        lastName: $lastName
        email: $email
        phone: $phone
      }
    ) {
      servicePlusPerson {
        id
        firstName
        lastName
        email
        phone
      }
      error {
        code
        message
      }
    }
  }
`;

export const useCreatePersonMutation = () => {
  const [mutation, { data, loading, error }] = useMutation<
    types.useCreateServicePlusPerson,
    types.useCreateServicePlusPersonVariables
  >(CREATE_PERSON_QUERY);
  const useCreateServicePlusPerson = async (
    customerId,
    firstName,
    lastName,
    email,
    phone
  ) => {
    return mutation({
      variables: {
        customerId: customerId,
        firstName: firstName,
        lastName: lastName,
        email: email,
        phone: phone,
      },
    });
  };
  return {
    useCreateServicePlusPerson: useCreateServicePlusPerson,
    createPersonData: data,
    createPersonLoading: loading,
    createPersonError: error,
  };
};
