import React from 'react';
import {
  useAccountingReportForDataServices,
  useAccountingReportDetailedInvoice,
  useAccountingReportForFixedPhones,
  useFixedReportANoMonthly,
  useFixedReportANoYearly,
  useFixedReportForSubscriptionOverview,
  useMobileReportSubscriptionOverview,
  useMobileReportTaxContentServices,
} from '../queries';
import { t } from 'src/lib/i18n';
import axios from 'axios';
import { useAppContext, useCustomer } from 'src/lib/global';
import { toast } from 'react-toastify';
import { Spinner } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';

export type SelectReportProps = {
  fileType: string;
  generateReport?: GenerateReportFunction;
  organizationSelector: boolean;
  periodSelector: boolean;
  subscriptionSelector: boolean;
  yearSelector: boolean;
};

export type GenerateReportFunction = (input: {
  fromDate: string;
  toDate: string;
  year: string;
  organizationId: string;
  subscriptionId: string;
}) => void;

export type Report =
  | 'accountingDataServices'
  | 'accountingDetailedInvoice'
  | 'accountingFixedPhones'
  | 'fixedANumberMonthly'
  | 'fixedANumberYearly'
  | 'fixedSubscriptionOverview'
  | 'mobileSubscriptionOverview'
  | 'mobileTaxContentServices';

type ContextValue = {
  selectReport: (reportType: Report) => void;
  downloadParams: SelectReportProps;
  open: boolean;
  setOpen: (open: boolean) => void;
};

const ReportContext = React.createContext<ContextValue | undefined>(undefined);

export const useReportContext = () => {
  const contextValue = React.useContext(ReportContext);

  if (!contextValue) {
    throw Error('No---');
  }

  return contextValue;
};

export const ReportContextProvider: React.FC = props => {
  const customer = useCustomer();
  const { auth } = useAppContext();
  const bearerToken = auth.bearerToken;

  const [open, setOpen] = React.useState(false);
  const [downloadParams, setDownloadParams] = React.useState<SelectReportProps>(
    {
      fileType: '',
      generateReport: undefined,
      organizationSelector: false,
      periodSelector: false,
      subscriptionSelector: false,
      yearSelector: false,
    }
  );

  // mutations
  const accountingDataServices = useAccountingReportForDataServices();
  const accountingDetailedInvoice = useAccountingReportDetailedInvoice();
  const accountingFixedPhones = useAccountingReportForFixedPhones();
  const fixedANumberMonthly = useFixedReportANoMonthly();
  const fixedANumberYearly = useFixedReportANoYearly();
  const fixedSubscriptionOverview = useFixedReportForSubscriptionOverview();
  const mobileSubscriptionOverview = useMobileReportSubscriptionOverview();
  const mobileTaxContentServices = useMobileReportTaxContentServices();

  const downloadReport = async (
    url: string,
    fileName?: string,
    toastId?: React.ReactText
  ) => {
    setOpen(false);
    resetDownloadParams();
    await axios({
      url: '/api/files/report/io',
      method: 'GET',
      params: {
        c: customer.id,
        preSignedUrl: url,
      },
      headers: {
        'Access-Control-Allow-Origin': '*',
        Authorization: `Bearer ${bearerToken}`,
      },
      responseType: 'blob',
    })
      .then(response => {
        if (response.status === 202) {
          setTimeout(() => downloadReport(url, fileName, toastId), 5000);
        } else if (response.status === 204) {
          toast.dismiss(toastId);
          const errorToastId = renderMessageToast(t.reports.noContent);
          toast.update(errorToastId);
        } else if (response.status === 200) {
          const downloadUrl = window.URL.createObjectURL(
            new Blob([response.data])
          );
          const link = document.createElement('a');
          link.href = downloadUrl;
          link.setAttribute('download', `${fileName}.xlsx`); //any other extension
          document.body.appendChild(link);
          link.click();
          link.remove();
          toast.dismiss(toastId);
        } else {
          console.warn(`Something went wrong. Status code: ${response.status}`);
          toast.dismiss(toastId);
          const errorToastId = renderMessageToast(t.reports.somethingWentWrong);
          toast.update(errorToastId);
        }
      })
      .catch(e => {
        console.warn(`Something went wrong. Status code: ${e.status}`);
        toast.dismiss(toastId);
        const errorToastId = renderMessageToast(
          `${t.reports.somethingWentWrong} ${t.reports.errorDescription}`
        );
        toast.update(errorToastId);
      });
  };

  const renderToast = (fileName: string) =>
    toast(
      <div className="d-flex">
        <div className="d-flex px-1">{`${t.reports.generatingReports}: ${fileName}`}</div>
        <Spinner size="md" />
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        bodyClassName: 'd-flex p-1 align-items-center',
      }
    );

  const renderMessageToast = (message: string) =>
    toast(
      <div className="d-flex">
        <Icon icon="warning" />
        <div className="d-flex px-2">{message}</div>
      </div>,
      {
        position: toast.POSITION.BOTTOM_RIGHT,
        bodyClassName: 'd-flex p-2 align-items-center',
      }
    );

  const resetDownloadParams = () => {
    setDownloadParams({
      fileType: '',
      generateReport: undefined,
      organizationSelector: false,
      periodSelector: false,
      subscriptionSelector: false,
      yearSelector: false,
    });
  };

  const startPolling = (url: string, fileName: string) => {
    const toastId = renderToast(fileName);
    downloadReport(url, fileName, toastId);
  };

  const selectReport = (reportType?: Report) => {
    setOpen(true);
    switch (reportType) {
      case 'accountingDataServices':
        setDownloadParams({
          fileType: t.reports.accounting.accountingData,
          generateReport: params => {
            accountingDataServices
              .generate(
                params.fromDate,
                params.toDate,
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined
              )
              .then(response => {
                const data =
                  response.data?.generateReportAccountingDataServices;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: true,
          subscriptionSelector: false,
          yearSelector: false,
        });
        break;
      case 'accountingFixedPhones':
        setDownloadParams({
          fileType: t.reports.accounting.accountingLandline,
          generateReport: params => {
            accountingFixedPhones
              .generate(
                params.fromDate,
                params.toDate,
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined
              )
              .then(response => {
                const data = response.data?.generateReportAccountingFixedPhones;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: true,
          subscriptionSelector: false,
          yearSelector: false,
        });
        break;
      case 'accountingDetailedInvoice':
        setDownloadParams({
          fileType: t.reports.accounting.invoiceDetails,
          generateReport: params => {
            accountingDetailedInvoice
              .generate(
                params.fromDate,
                params.toDate,
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined,
                params.subscriptionId.length > 0
                  ? params.subscriptionId
                  : undefined
              )
              .then(response => {
                const data = response.data?.generateReportDetailedInvoice;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: true,
          subscriptionSelector: true,
          yearSelector: false,
        });
        break;
      case 'fixedANumberMonthly':
        setDownloadParams({
          fileType: t.reports.landline.sumPerANum,
          generateReport: params => {
            fixedANumberMonthly
              .generate(
                params.fromDate,
                params.toDate,
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined
              )
              .then(response => {
                const data = response.data?.generateReportFixedAnumberMonthly;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: true,
          subscriptionSelector: false,
          yearSelector: false,
        });
        break;
      case 'fixedANumberYearly':
        setDownloadParams({
          fileType: t.reports.landline.sumPerANumTotal,
          generateReport: params => {
            fixedANumberYearly
              .generate(
                params.year,
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined
              )
              .then(response => {
                const data = response.data?.generateReportFixedAnumberYearly;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: false,
          subscriptionSelector: false,
          yearSelector: true,
        });
        break;
      case 'fixedSubscriptionOverview':
        setDownloadParams({
          fileType: t.reports.landline.subscriptionOverview,
          generateReport: params => {
            fixedSubscriptionOverview
              .generate(
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined
              )
              .then(response => {
                const data =
                  response.data?.generateReportFixedSubscriptionOverview;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: false,
          subscriptionSelector: false,
          yearSelector: false,
        });
        break;
      case 'mobileSubscriptionOverview':
        setDownloadParams({
          fileType: t.reports.mobile.overviewSubscription,
          generateReport: params => {
            mobileSubscriptionOverview
              .generate(
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined
              )
              .then(response => {
                const data =
                  response.data?.generateReportMobileSubscriptionOverview;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: false,
          subscriptionSelector: false,
          yearSelector: false,
        });
        break;
      case 'mobileTaxContentServices':
        setDownloadParams({
          fileType: t.reports.mobile.taxReport,
          generateReport: params => {
            mobileTaxContentServices
              .generate(
                params.fromDate,
                params.toDate,
                params.organizationId.length > 0
                  ? params.organizationId
                  : undefined
              )
              .then(response => {
                const data =
                  response.data?.generateReportMobileTaxContentServices;
                if (data?.url && data?.filename) {
                  startPolling(data.url, data.filename);
                }
              });
          },
          organizationSelector: true,
          periodSelector: true,
          subscriptionSelector: false,
          yearSelector: false,
        });
        break;
    }
  };

  return (
    <ReportContext.Provider
      value={{ selectReport, downloadParams, open, setOpen }}
    >
      {props.children}
    </ReportContext.Provider>
  );
};
