const locations = {
  h1SrOnly: 'Lokasjoner',
  searchInThisArea: 'Søk i dette området',
  Sidebar: {
    backBtn: 'Tilbake til liste',
    btnClose: 'Lukk',
    services: 'Tjenester',
    header: {
      count: 'Antall',
      noResults: 'Ingen resultater',
    },
    subscription: {
      goToService: 'Gå til tjenesten',
      back: 'Tilbake',
      status: 'Status',
      cpeModel: 'CPE-modell',
      responseTime: 'Responstid',
      position: 'Posisjon',
      lat: 'Breddegrad',
      lon: 'Lengdegrad',
      addons: 'Tilhørende tjenester',
    },
    filters: {
      back: 'Tilbake',
      reset: 'Nullstill',
      activeFilters: 'Aktive filtre',
      offline: 'Offline',
      plannedWork: 'Planlagt arbeid',
      data: 'Nettverk',
      mobile: 'Mobil',
      tele: 'Telefoni',
      tv: 'TV',
    },
    footer: {
      title: 'Hvorfor vises ikke alle tjenestene mine i kartet?',
      description:
        'I dag er det kun tjenester med lokasjonsdata i riktig format som kan vises i kartet.',
    },
  },
  searchinputPlaceholder: 'Filtrer',
};

export default locations;
