import * as React from 'react';
import cs from 'classnames';

import './ListHeader.scss';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export const ListHeader: React.FC<Props> = props => (
  <div className={cs('ListHeader', props.className)} style={props.style}>
    <div className="container ListHeader-container">{props.children}</div>
  </div>
);
