import React, { useState } from 'react';
import { Loading, Button, ButtonProps, FormSubmitBar } from 'src/lib/ui';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'src/lib/i18n';

export interface ModalProps {
  /**
   * Text displayed on the modal-header
   */
  headerText?: string;
  /**
   * Label on the button
   */
  label: string;
  /**
   * Optional function on confirming. Supports both sync and async functions.
   */
  onConfirm?: () => void | Promise<void>;
  /**
   *  Loading state of the onConfirm function.
   */
  loading?: boolean;
  /**
   * Optional function on canceling.
   */
  onCancel?: () => void;
  /**
   * React-node with the modal-body content
   */
  children?: React.ReactNode;
}

export type ConfirmationModalButtonProps = ButtonProps & ModalProps;

export const ConfirmationModalButton: React.FC<ConfirmationModalButtonProps> = props => {
  const [modalOpen, setModalOpen] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const {
    headerText,
    onConfirm,
    loading,
    onCancel,
    label,
    children,
    ...restProps
  } = props;

  const modalCancel = () => {
    if (onCancel) {
      onCancel();
    }
    setModalOpen(false);
  };

  const modalConfirm = () => {
    if (onConfirm) {
      setSubmitting(true);
      return Promise.resolve(onConfirm()).then(() => {
        // TODO: Maybe receive callBack and display error if existing?
        setSubmitting(false);
        return setModalOpen(false);
      });
    }
    return setModalOpen(false);
  };

  const onClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if ('onClick' in props && props.onClick) {
      props.onClick(e);
    }
    setModalOpen(!modalOpen);
  };

  return (
    <div className="ConfirmationModalButton">
      <Button {...restProps} type="button" onClick={onClick}>
        {label}
      </Button>
      <Modal
        isOpen={modalOpen}
        toggle={() => setModalOpen(!modalOpen)}
        className="ConfirmationModalButton-modal"
        zIndex={10000}
      >
        <ModalHeader>{headerText}</ModalHeader>
        <ModalBody>{loading ? <Loading /> : children}</ModalBody>

        <ModalFooter>
          <FormSubmitBar
            loading={submitting}
            submit={modalConfirm}
            cancel={modalCancel}
            submitLabel={t.ui.ConfirmationModalButton.confirm}
          />
        </ModalFooter>
      </Modal>
    </div>
  );
};
