import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';
import { Organisation } from 'src/lib/types';

export const SearchListOrganisationResult: React.FC<{
  entry: Organisation;
}> = ({ entry }) => (
  <ListResultCard
    linkTo={entry}
    className="ListResultCard--Organisation"
    icon="company"
    title={`${entry.id} ${entry.name}`}
  />
);
