import * as React from 'react';
import { urlFor, Loading } from 'src/lib/ui';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
// import FilterSection from 'src/areas/main/common/FilterSection';
import { RouteComponentProps } from 'react-router';
import MobileOrderContent from './components/MobileOrderContent';
import ServiceOrderContent from './components/ServiceOrderContent';
import MobileUnitOrderContent from './components/MobileUnitOrderContent';

import { useOrder } from 'src/areas/main/orders/queries/useOrder';
import { useOrder_customer_order } from 'src/areas/main/orders/queries/__types/useOrder';

import { t } from 'src/lib/i18n';

import './OrderContent.scss';
import { useRouter } from 'src/lib/utils/useRouter';

// Defined in frontend for now because this is not an ENUM in GQL yet
type OrderType = 'subscription' | 'legacy' | 'mobile' | 'mobileUnit';

const OrderContent: React.FunctionComponent<RouteComponentProps<{
  id: string;
}>> = props => {
  const { match } = useRouter<{ id: string }>();
  const orderQuery = useOrder(match.params.id);
  // const customerContext = useCustomer();
  const i18n = t.orders.OrderContent;

  return (
    <div className="Orders OrderContent">
      <PageHeader
        title={i18n.PageHeader.title(props.match.params.id)}
        breadcrumbs={[
          {
            url: urlFor({ area: 'orders' }),
            title: i18n.PageHeader.breadcrumb,
          },
        ]}
      >
        {/* Placement of this? It is not a action really, so should it be placed in filterSection? */}
        {/* {order.customerOrderId && (
            <FilterSection>
              <Attribute
                label={i18n.PageHeader.actions(customerContext.name)}
                value={order.customerOrderId}
              />
            </FilterSection>
          )} */}
      </PageHeader>

      <div className="mt-4">
        {orderQuery.loading ? (
          <Loading />
        ) : (
          <InnerOrderContent order={orderQuery.data?.customer?.order} />
        )}
      </div>
    </div>
  );
};

const InnerOrderContent = (props: {
  order?: useOrder_customer_order | null;
}) => {
  const i18n = t.orders.OrderContent;
  if (!props.order) {
    return <div>{i18n.noData}</div>;
  }

  const orderType = props.order.type as OrderType;

  if (orderType === 'mobile') {
    return <MobileOrderContent order={props.order} />;
  }

  if (orderType === 'subscription' || orderType === 'legacy') {
    return <ServiceOrderContent id={props.order.id} />;
  }

  if (orderType === 'mobileUnit') {
    return <MobileUnitOrderContent order={props.order} />;
  }

  return <div>{i18n.noData}</div>;
};

export default OrderContent;
