import gql from 'graphql-tag';
import { InstallationAddressFragment } from '../subscription/InstallationAddressFragment';

export const OrderLineFragment = gql`
  fragment OrderLineFragment on OrderLine {
    id
    portalStatus
    topId
    parentId
    description
    contacts {
      deliveryCoordinator
      datacomTechnician
    }
    dates {
      createdAt
      updatedAt
      estimatedDeliveryDate
      plannedDeliveryDate
      committedDeliveryDate
      estimatedAt
      closedAt
    }
    mainSubscriptionId
    mainSubscription {
      id
      name
      alias
      transmissionNumber
      installationAddress {
        ...InstallationAddressFragment
      }
    }
    subscriptionIds
    subscriptions {
      id
      name
    }
    addons {
      id
    }
    customer {
      id
    }
  }
  ${InstallationAddressFragment}
`;
