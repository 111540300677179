import React from 'react';
import { TableBodyCell } from 'src/lib/ui';

export const CostCenterTableCell = (props: {
  subscription?: null | {
    costCenter?: null | {
      description: string | null;
      code: string;
    };
  };
}) => {
  const costCenter = props.subscription?.costCenter
    ? props.subscription?.costCenter.description
      ? `${props.subscription?.costCenter.description} - ${props.subscription?.costCenter?.code}`
      : props.subscription?.costCenter.code
    : null;

  return (
    <TableBodyCell>
      <div>{costCenter}</div>
    </TableBodyCell>
  );
};
