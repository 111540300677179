import React, { useMemo } from 'react';
import cx from 'classnames';
import Select, { components, createFilter } from 'react-select';
import VirtualizedSelect from 'react-select-virtualized';
import { t } from 'src/lib/i18n';
import { FormFieldWrapper } from 'src/lib/ui';
import { generateUeid } from 'src/lib/utils';
import './SelectField.scss';
import { Icon } from '@telia/styleguide';
import { Loading } from '../Loading/Loading';

export interface SelectOption {
  value: string;
  label: string;
}

export type SelectFieldProps = {
  id?: string;
  placeholder?: string;
  disabled?: boolean;
  value?: string | string[] | SelectOption | SelectOption[];
  className?: string;
  'aria-label'?: string;
  // 'aria-labelledby'?: string;
  noOptionsMessage?: string;
  clearable?: boolean;
  options: Array<{
    value: string;
    label: string;
  }>;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  required?: boolean;
  loading?: boolean;
  success?: boolean;
  error?: string;
  label?: string;
  helpText?: string;
  subTextPosition?: 'left' | 'right';
  /**
   * Allow for a controllable inputField
   */
  inputValue?: string;
  onInputChange?: (value: string) => void;
  virtualized?: boolean;
  customStyles?: {
    option?: (
      styles: React.CSSProperties,
      state: { data: SelectOption }
    ) => React.CSSProperties;
    singleValue?: (
      styles: React.CSSProperties,
      state: { data: SelectOption }
    ) => React.CSSProperties;
    multiValue?: (
      styles: React.CSSProperties,
      state: { data: SelectOption }
    ) => React.CSSProperties;
    multiValueLabel?: (
      styles: React.CSSProperties,
      state: { data: SelectOption }
    ) => React.CSSProperties;
  };
} & (
  | {
      allowMultiple: true;
      onChange?: (value?: Array<SelectOption> | null) => void;
    }
  | {
      allowMultiple?: false;
      onChange?: (value?: SelectOption | null) => void;
    }
);

export const SelectField = (props: SelectFieldProps) => {
  const fieldGuid = useMemo(() => (props.label ? generateUeid() : undefined), [
    props.label,
  ]);
  // private fieldGuid: string | undefined;
  // constructor(props) {
  //   super(props);
  //   if (props.label) {
  //     fieldGuid = generateUeid();
  //   }
  // }

  // useEffect(()=> {
  //   fieldGuid.current =
  // } [props.label])

  // componentDidUpdate(prevProps) {
  //   if (prevProps.label !== this.props.label) {
  //     this.fieldGuid =
  //       this.props.label === undefined ? undefined : generateUeid();
  //   }
  // }

  const CustomSelectInput = props => {
    return (
      <div className="SelectField-customSelectInput">
        <components.Input {...props} />
        {renderStatusIcon()}
      </div>
    );
  };

  const renderStatusIcon = (): React.ReactNode => {
    const { error, success, loading } = props;
    /**
     * Render priority
     * 1. Success
     * 2. Error
     * 3. Loading
     */
    if (success) {
      return <Icon icon="check-mark" />;
    }
    if (error) {
      return <Icon icon="alert" />;
    }
    if (loading) {
      return <Loading size="xs" style={{ minHeight: '1rem' }} />;
    }
    return null;
  };

  const translate = t.ui.SelectField;
  const { value } = props;
  let currentValue;

  if (props.allowMultiple && value && Array.isArray(value)) {
    if (value.every(val => typeof val === 'string')) {
      // Array of strings
      const typedValue = value as string[];
      currentValue = props.options.filter(option =>
        typedValue.includes(option.value)
      );
    } else {
      // Array of selectOption
      currentValue = value;
    }
  } else {
    // Value is a SelectOption
    if (value && typeof value === 'object' && 'value' in value) {
      currentValue = value;
    } else {
      // Value is a string, try to find object in options
      currentValue = props.options.find(option => option.value === value);
    }
  }
  // When using Virtualized version, we dont get keyboard support because of a bug in React-Select.
  // https://github.com/guiyep/react-select-virtualized/issues/12
  // TODO: update libaries when bug is fixed
  const SelectComponent = props.virtualized ? VirtualizedSelect : Select;

  const select = (
    <SelectComponent
      id={props.id}
      inputId={props.id}
      value={currentValue}
      className={cx('SelectField', {
        error: Boolean(props.error),
        success: Boolean(props.success),
      })}
      classNamePrefix="SelectField"
      placeholder={props.placeholder}
      isDisabled={props.disabled}
      options={props.options}
      onChange={props.onChange}
      onBlur={props.onBlur}
      filterOption={createFilter({ ignoreAccents: false })}
      noOptionsMessage={
        props.noOptionsMessage
          ? () => props.noOptionsMessage || null
          : () => translate.noOptionsMessage
      }
      isMulti={props.allowMultiple}
      isClearable={props.clearable}
      inputProps={{
        'aria-label': props['aria-label'],
        'aria-labelledBy': fieldGuid,
      }}
      components={{
        Input: CustomSelectInput,
      }}
      inputValue={props.inputValue}
      onInputChange={props.onInputChange}
      styles={props.customStyles}
    />
  );

  return (
    <FormFieldWrapper
      className={props.className}
      error={props.error}
      required={props.required}
      helpText={props.helpText}
      label={props.label}
      success={props.success}
      subTextPosition={props.subTextPosition}
      labelId={fieldGuid}
    >
      {select}
    </FormFieldWrapper>
  );
};
