import React from 'react';
import {
  Card,
  colors,
  urlFor,
  Toolbar,
  LinkCard,
  LinkCardList,
} from 'src/lib/ui';
import {
  getSubscriptionIcon,
  getSubscriptionDisplayTitles,
} from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { TechnicalContactSubscription } from '../../queries/useContact';
import { formatInstallationAddress } from 'src/lib/utils';
import { ExpansionPanel } from 'src/lib/ui/ExpansionPanel/ExpansionPanel';
import { EditServices } from '../editServices/EditServices';
import cn from 'classnames';
interface Props {
  subscriptions?: TechnicalContactSubscription[] | null;
}

const ContactServices: React.FC<Props> = props => {
  const i18n = t.contacts;
  return (
    <Card border={true} padding={false} style={{ color: colors.greyDarkText }}>
      <div className="px-4 pt-4 pb-2 preamble" style={{ color: colors.black }}>
        {i18n.contactResponsibilities}
      </div>
      <ExpansionPanel
        icon="user"
        className={cn({ 'border-bottom': props?.subscriptions?.length })}
        heading={
          props.subscriptions && props.subscriptions.length
            ? i18n.contactPerson(props.subscriptions.length)
            : i18n.noContactPerson
        }
      >
        {props.subscriptions?.length ? (
          <LinkCardList>
            {props.subscriptions.map(s => (
              <LinkCard
                key={s.id}
                icon={getSubscriptionIcon(s).icon}
                iconClass={`text-${getSubscriptionIcon(s).color}`}
                text={
                  s?.group
                    ? t.subscriptions.ListSubscriptionsByGroup[s?.group]
                        ?.title ?? ''
                    : ''
                }
                url={urlFor({ subscription: s.id })}
                subText="Gå til tjenesten"
              >
                <div className="d-flex flex-column m-auto">
                  <small style={{ color: colors.black }}>
                    {getSubscriptionDisplayTitles(s).title}
                  </small>
                  {s.installationAddress ? (
                    <small>
                      {formatInstallationAddress(
                        s.installationAddress,
                        'fullAddress'
                      )}
                    </small>
                  ) : null}
                </div>
              </LinkCard>
            ))}
          </LinkCardList>
        ) : null}
      </ExpansionPanel>
      {!!props.subscriptions?.length && (
        <Toolbar className="ml-0 p-4 border-top-0">
          <EditServices subscriptions={props.subscriptions} />
        </Toolbar>
      )}
    </Card>
  );
};

export default ContactServices;
