import React, { useMemo, useState, useEffect } from 'react';
import {
  Item,
  Toolbar,
  Button,
  ButtonCard,
  BodyCard,
  ItemListPicker,
  LinkCard,
  urlFor,
} from 'src/lib/ui';
import { mapNotificationContactToItem } from '../../utils/incidentUtils';
import { difference } from 'lodash';
import { useUpdateContactsOnIncident } from '../../mutations/useUpdateContactsOnIncident';
import { t } from 'src/lib/i18n';
import { mapContactsToItem } from '../../utils/incidentUtils';
import { useSearchContacts } from 'src/areas/main/contacts/queries/useSearchContacts';
import { TechnicalContactCard } from 'src/areas/main/subscriptions/Pages/Components/TechnicalContacts/TechnicalContactCard';
import { Toggle } from '@telia/styleguide/business';
import { useUpdateIncidentNotification } from '../../mutations/useUpdateIncidentNotification';

interface Props {
  incident: {
    id: string;
    notify?: string | null;
    notificationContacts: null | Array<{
      id: string;
      name: string;
      email: string | null;
      phoneNumber: string | null;
      contact: null | {
        id: string;
        firstName: string | null;
        lastName: string | null;
        email: string | null;
        mobilePhone: string | null;
        secondaryPhone: string | null;
        description: string | null;
      };
    }>;
  };
}

function isString(entry: string | undefined): entry is string {
  return typeof entry === 'string';
}

export const EditIncidentContacts = (props: Props) => {
  const { incident } = props;
  const [open, setOpen] = useState(false);
  const [query, setQuery] = useState<string | undefined>();
  const [emailToggle, setEmailToggle] = useState(
    incident.notify === 'Email' || incident.notify === 'SMSEmail'
  );

  const { contacts } = useSearchContacts({ query });
  const {
    updateContactsOnIncident,
    ...updateContext
  } = useUpdateContactsOnIncident();
  const { updateIncidentNotification } = useUpdateIncidentNotification();

  useEffect(() => {
    if (updateContext.data && !updateContext.error) {
      setOpen(false);
    }
  }, [updateContext.data, updateContext.error]);

  const handleSelectedContactsChange = (
    currentSelectedContactItems: Item[]
  ) => {
    /**
     * It is possible that we get notificationsContacts from backend
     * that does not have an actual Min Portal contact connected to it.
     * In that case we use the id of the notificationsContacts.
     *
     * In any case we must use the notificationsContact ids to delete contacts from an Incident
     */

    const initialContactIds =
      incident.notificationContacts
        ?.map(
          notificationsContact =>
            notificationsContact?.contact?.id ?? notificationsContact.id
        )
        .filter(id => !!id) ?? [];

    const selectedContactIds = currentSelectedContactItems.map(item => item.id);

    const contactIdsToAdd: string[] = difference(
      selectedContactIds,
      initialContactIds
    ).filter(isString);
    const contactIdsToDelete = difference(
      initialContactIds,
      selectedContactIds
    );
    const notificationContactsIdsToDelete = incident.notificationContacts
      ?.filter(
        notificationContact =>
          !!contactIdsToDelete.find(id =>
            notificationContact?.contact
              ? notificationContact.contact.id === id
              : notificationContact.id === id
          )
      )
      .map(notificationContact => notificationContact.id);

    updateContactsOnIncident(
      incident.id,
      contactIdsToAdd,
      notificationContactsIdsToDelete
    );
  };
  const initialContactItems = useMemo(
    () =>
      incident.notificationContacts
        ? mapNotificationContactToItem(incident.notificationContacts)
        : undefined,
    [incident.notificationContacts]
  );
  const contactItems: Item[] = contacts ? mapContactsToItem(contacts) : [];

  const contactPicker = (
    <ItemListPicker
      disableAddNew={true}
      submitLoading={updateContext.loading}
      heading={t.incidents.IncidentContacts.addContacts}
      initialSelectedItems={initialContactItems}
      availableItems={contactItems}
      toggleState={() => setOpen(!open)}
      isOpen={open}
      onSave={handleSelectedContactsChange}
      setQuery={setQuery}
      query={query}
    />
  );

  const toggle = (checked: boolean) => {
    setEmailToggle(checked);
    updateIncidentNotification(incident.id, checked)
      .then()
      .catch(e => {
        console.log('error', e);
      });
  };

  return (
    <>
      {incident.notificationContacts?.length ? (
        <BodyCard className="ml-4 ml-sm-0 mr-4" noGutter={true}>
          <div>
            <div className="px-4 pt-4" style={{ fontSize: '1.25rem' }}>
              {t.incidents.IncidentContacts.contactsHeading}
            </div>

            {incident.notificationContacts.map(contact =>
              contact.contact ? (
                <LinkCard
                  key={contact.id}
                  renderAs="div"
                  url={urlFor({ contact: contact.contact.id })}
                >
                  <TechnicalContactCard contact={contact.contact} />
                </LinkCard>
              ) : (
                <div className="p-4" key={contact.id}>
                  <TechnicalContactCard
                    contact={{
                      id: contact.id,
                      firstName: contact.name,
                      lastName: null,
                      email: contact.email,
                      mobilePhone: contact.phoneNumber,
                      secondaryPhone: null,
                      description: null,
                    }}
                  />
                </div>
              )
            )}
          </div>
          <div className="d-flex p-4">
            <Toggle
              checked={emailToggle}
              onChange={e => toggle(e.target.checked)}
            />
            <div className="ml-2">
              {t.incidents.IncidentSidebar.sendNotificationsByEmail}
            </div>
          </div>
          <Toolbar className="EditIncidentContacts-toolbar px-4 pb-4 ml-0">
            <Button
              type="button"
              icon="pen"
              color="white"
              onClick={() => setOpen(!open)}
            >
              {t.incidents.IncidentContacts.btnEdit}
            </Button>
          </Toolbar>
        </BodyCard>
      ) : (
        <div className="m-4 ml-sm-0">
          <ButtonCard
            onClick={() => setOpen(!open)}
            icon="support"
            stackHorizontally={true}
            color="dark"
          >
            {t.incidents.IncidentContacts.add}
          </ButtonCard>
        </div>
      )}
      {contactPicker}
    </>
  );
};
