import { IncidentStatus } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';

export const getIncidentStatusLabel = (
  incidentStatus?: IncidentStatus | null,
  customerName?: string
): string => {
  const translate = t.incidents.status;
  switch (incidentStatus) {
    case 'onHoldPendingTelia':
      return translate.onHoldPendingTelia;
    case 'onHoldPendingCustomer':
      return translate.onHoldPendingCustomer(customerName);
    case 'canceled':
      return translate.canceled;
    case 'closed':
      return translate.closed;
    case 'solved':
      return translate.solved;
    default:
      return translate.fallback;
  }
};
