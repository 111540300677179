import React, { useState, useEffect } from 'react';
import { t } from 'src/lib/i18n';
import './UploadFile.scss';
import { useFileUpload } from './useFileUpload';
import { Icon } from '@telia/styleguide';

const UploadFile = () => {
  const { handleSelectedFiles, files } = useFileUpload();
  const [value, setValue] = useState<string | undefined>(undefined);
  useEffect(() => (files.length ? setValue(undefined) : setValue('')), [files]);

  // Added Button classes to style it as a button
  return (
    <label className="UploadFile Button Button--default Button--dark transparent">
      {/** mr-2 follows the default margin from Button.tsx */}
      <Icon className="mr-2" icon="upload" /> {t.documents.btnUpload}
      <input
        style={{ display: 'none' }}
        type="file"
        id="IncidentFileUploader--fileInput"
        value={value}
        onChange={e => {
          handleSelectedFiles(e.target.files ? Array.from(e.target.files) : []);
        }}
        multiple={true}
      />
    </label>
  );
};
export default UploadFile;
