export const worklogOrder = {
  status: {
    internalStatus: 'Internal status',
    complete: 'Complete',
    new: 'New',
    pendingCustomer: 'Pending customer',
    pendingInternal: 'Pending internal',
    pendingSubcontractor: 'Pending subcontractor',
    prioritized: 'Prioritized',
    working: 'Working',
    updateStatusError: 'We were not able to change the case status',
    updateStatusSuccess: (status: string) => `Status set to ${status}`,
  },
  hi: (name?: string | null) => `Hi${name ? `, ${name}` : ''}`,
  question: 'What are you looking for today?',
  order: 'Order',
  noSearchResults: 'No search results',
  subscriptionSearchHeader: 'Orders for subscription',
  created: 'Created',
  lastUpdated: 'Last updated at',
  estimated: 'Estimated completion date',
  closed: 'Closed at',
  organisationNumber: 'Org.no',
  salesRep: 'Sales representative',
  orderWide: 'Order wide',
  case: 'Case',
  assignedTo: 'Assigned to',
  address: 'Address',
  alias: 'Alias',
  connestionNumber: 'Connection Number',
  dkServiceId: 'TDC DK Service ID',
  addons: 'Addons',
  mainSubscription: 'Main subscription',
  deliveryCoordinator: 'Delivery coordinator',
  notAssigned: 'Not assigned',
  datacomTechnician: 'Datacom technician',
  chat: {
    finishWork: 'Finish or cancel work on messages before leaving!',
    sendEmail: {
      writeAnEmail: 'Write an email',
      invalidEmail: 'Invalid email',
      recipients: 'Recipients',
      subject: 'Subject',
      writeSomethingHere: 'Write something here',
      cancel: 'Cancel',
      remove: 'Remove',
      send: 'Send',
      emailSelected: 'Email selected messages',
      clearSelection: 'Clear selection',
    },
  },
  emptyWorklog: {
    information: 'This worklog is empty',
    instruction: 'Start adding to it down below',
  },
  worklog: {
    unknown: 'Uknown',
  },
  orderLineStatus: {
    ready: 'Ready for processing',
    inProgress: 'In progress',
    error: 'Error',
    done: 'Done',
    cancelled: 'Cancelled',
    unknown: 'Unknown',
  },
  btnDeleteWorklog: 'Delete worklog',
  orderNotFound: {
    ops: 'Oops!',
    couldNotFindOrder: (order: string) => `We could not find order "${order}" `,
    recentlyCreated:
      'Looking for a new order? It will show up in a few minutes',
    nonExisting: 'You can search for another order from the main page',
    toTheMainPage: 'Go to the main page',
    refresh: 'Refresh page',
  },
  subscriptionNotFound: {
    documentsNotAvailable: 'Unable to add documents',
    notValidated: 'Order must be validated to add documents on cases',
    doesNotExist: 'Subscription does not exist',
  },
  caseDescription: {
    helpText: 'Key information',
    error: 'We were not able to update the key information',
    succes: 'Key information was updated',
  },
};

export default worklogOrder;
