import React, { useState, useEffect } from 'react';
import cn from 'classnames';
import { Checkbox, NoResult } from 'src/lib/ui';
import { times } from 'lodash';
import { t } from 'src/lib/i18n';

import './Table.scss';
import { useDelayedRender } from 'src/lib/utils/useDelayedRender';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { generateUeid } from 'src/lib/utils';
import { ColumnOptions } from './ColumnOptions';
import { SortOrder } from 'src/__types/graphql-global-types';

type TableCellAlignment = 'right' | 'left';
// type SortOrder = 'asc' | 'desc';
export interface TableColumnDefintion {
  /** Unique key that can be mapped to a data-key */
  key: string;
  /** Label of the table header column */
  label: string;
  /** Sort-key used for sorting this column */
  sortKey?: string;

  /** Sort options for the column definition */
  sortOptions?: Array<{ key: string; order: SortOrder; label: string }>;
  /** Alignment of the cell-data for this column */
  cellAlignment?: TableCellAlignment;
  /** Optional Cell width of the cell-data for this column in px. */
  cellWidth?: number;
  /** Whether the column is fixed or can be changed. */
  fixed?: boolean;
}

type TableProps = {
  /** Specification of the table columns. */
  columns: TableColumnDefintion[];
  /** Specification of the available table columns. */
  availableColumns?: TableColumnDefintion[];
  /** Specify if table-body (data) is loading */
  loading?: boolean;
  noResult?: boolean;
  noResultMessage?: string;
  /** If table should be wrapped in a border */
  bordered?: boolean;
  /** Row size */
  rowsCount?: number;
  /** Current active sort */
  activeSort?: { key: string; order: SortOrder };

  onChangeColumns?: (columnKeys: string[]) => void;
} & (
  | {
      selectable: true;
      /** A array of selected rows. */
      selected: string[];
      onSelectAll: () => void;
      allSelected: boolean;
    }
  | {
      selectable?: false;
    }
) &
  (
    | {
        sortable: true;
        setSort: (key: string, order: SortOrder) => void;
        sortBy?: string;
        sortOrder?: SortOrder;
      }
    | {
        sortable?: false;
      }
  );

type TableBodyRowProps = {
  children: React.ReactNode;
  onClick?: () => void;
  /** Whether current row should be connected to next row (visually) */
  connectedToNextRow?: boolean;
} & (
  | {
      selectable: true;
      selected?: boolean;
      onSelectRow?: () => void;
      selectColor?: string; // TODO: defined a set of available colors? Or allow whatever color?
    }
  | {
      selectable?: false;
    }
);

export const TableBodyRow: React.FC<TableBodyRowProps> = props => {
  return (
    <tr
      onKeyUp={e => {
        if (e.key === 'Enter' && props.onClick) {
          props.onClick();
        }
      }}
      onClick={() => {
        if (props.onClick) {
          props.onClick();
        }
      }}
      className={cn(
        'Table-TableBodyRow',
        props.onClick ? 'clickable' : undefined,
        props.connectedToNextRow ? 'connected' : undefined,
        props.selectable && props.selectColor ? 'colorize' : undefined,
        props.selectable && props.selected ? 'selected' : undefined
      )}
      tabIndex={0}
    >
      {props.selectable && (
        <td
          className="Table-TableBodyCell-Checkbox"
          onClick={e => e.stopPropagation()}
        >
          <Checkbox
            checked={Boolean(props.selected)}
            onChange={props.onSelectRow}
          />
        </td>
      )}
      {props.children}
    </tr>
  );
};

interface TableBodyCellProps {
  cellAlignment?: TableCellAlignment;
  title?: string;
  ellipsis?: boolean;
}

export const TableBodyCell: React.FC<TableBodyCellProps> = props => {
  // Default is to let cells ellipsis if content is larger then cell-size
  const ellipsis = props.ellipsis === false ? false : true;
  return (
    <td
      className={cn(
        'Table-TableBodyCell',
        props.cellAlignment === 'right' ? 'alignRight' : undefined
      )}
    >
      {ellipsis ? (
        <div className="Table-TableBodyCell-content" title={props.title}>
          {props.children}
        </div>
      ) : (
        props.children
      )}
    </td>
  );
};

type TableHeaderCellProps = {
  column: TableColumnDefintion;
  handleSort?: (key: string, order: SortOrder) => void;
  sortOptions?: Array<{ key: string; label: string; order: SortOrder }>;
  columnOptions?: Array<{ key: string; label: string }>;
  handleColumnSelect?: (key: string) => void;
  activeSort?: { key: string; order: SortOrder };
};

export const TableHeaderCell: React.FC<TableHeaderCellProps> = props => {
  const cellClassNames = cn(
    'Table-TableHeaderCell',
    props.column.cellAlignment === 'right' ? 'alignRight' : undefined
  );
  if (props.sortOptions?.length) {
    return (
      <th
        className={cellClassNames}
        style={
          props.column.cellWidth ? { width: props.column.cellWidth } : undefined
        }
      >
        <ColumnOptions
          columnOptions={props.columnOptions}
          sortFields={props.sortOptions}
          handleSort={props.handleSort}
          handleColumnSelect={props.handleColumnSelect}
          currentColumn={props.column}
          activeSort={props.activeSort}
        />
      </th>
    );
  } else if (props.columnOptions?.length) {
    return (
      <th
        className={cellClassNames}
        style={
          props.column.cellWidth ? { width: props.column.cellWidth } : undefined
        }
      >
        <ColumnOptions
          columnOptions={props.columnOptions}
          sortFields={props.sortOptions}
          handleSort={props.handleSort}
          handleColumnSelect={props.handleColumnSelect}
          currentColumn={props.column}
          activeSort={props.activeSort}
        />
      </th>
    );
  }
  return (
    <th
      className={cellClassNames}
      style={
        props.column.cellWidth ? { width: props.column.cellWidth } : undefined
      }
    >
      {props.column.label}
    </th>
  );
};

interface TableSkeletonLoadingProps {
  columns: TableColumnDefintion[];
  rowsCount: number;
}

const TableSkeletonLoading: React.FC<TableSkeletonLoadingProps> = props => (
  <>
    {times(props.rowsCount, idx => (
      <tr className="Table-SkeletonRow" key={idx}>
        {props.columns.map((col, i) => (
          <td
            className={cn('Table-TableBodyCell')}
            key={`${col.key}-${i}-${idx}`}
          >
            <div>
              <div>&nbsp;</div>
            </div>
          </td>
        ))}
      </tr>
    ))}
  </>
);

export const Table: React.FC<TableProps> = props => {
  const [uid, setUid] = useState<string>(generateUeid());

  // Table bordered by default
  const bordered = props.bordered === false ? undefined : 'bordered';
  const delayValue = 'loading' in props ? 500 : 0;
  const delayedRender = useDelayedRender(delayValue, props.loading);

  useEffect(() => {
    /**
     * If delayedRender has changed, and is set to "false". There has been a fetch of new data, so we want to set new "UID" in order to tell CSS-transition to fade in.
     * This is to avoid generating a "UID" in each render.
     */
    if (delayedRender === false) {
      setUid(generateUeid());
    }
  }, [delayedRender]);

  const tableOptions = props.availableColumns
    ?.filter(o => !o.fixed)
    .map(f => ({
      key: f.key,
      label: f.label,
    }));

  return (
    <div className={cn('Table-container', bordered)}>
      <table className="Table">
        <thead>
          <tr>
            {props.selectable && (
              <th className="Table-TableHeaderCell" style={{ width: '75px' }} />
            )}
            {props.columns.map((column, index) => {
              const sortOptions =
                props.sortable && column.sortKey
                  ? [
                      {
                        key: column.sortKey,
                        order: SortOrder.asc,
                        label: t.ui.Table.ColumnOptions.ascending,
                      },
                      {
                        key: column.sortKey,
                        order: SortOrder.desc,
                        label: t.ui.Table.ColumnOptions.descending,
                      },
                    ]
                  : column.sortOptions;
              const handleSort = props.sortable ? props.setSort : undefined;
              const handleColumnSelect = (key: string) => {
                const newColumns = props.columns.map((c, i) =>
                  i === index ? key : c.key
                );
                props.onChangeColumns?.(newColumns);
              };
              const columnOptions = column.fixed ? undefined : tableOptions;
              return (
                <TableHeaderCell
                  key={`${column.key}-${index}`}
                  columnOptions={columnOptions}
                  sortOptions={sortOptions}
                  handleSort={handleSort}
                  handleColumnSelect={handleColumnSelect}
                  column={column}
                  activeSort={props.activeSort}
                />
              );
            })}
          </tr>
        </thead>

        {delayedRender ? (
          <tbody>
            <TableSkeletonLoading
              rowsCount={
                props.rowsCount && props.rowsCount <= 20 ? props.rowsCount : 20
              }
              columns={
                props.selectable
                  ? [
                      { key: 'select', cellWidth: 75, label: '' },
                      ...props.columns,
                    ]
                  : props.columns
              }
            />
          </tbody>
        ) : (
          <TransitionGroup component={null}>
            <CSSTransition
              timeout={{
                enter: 300,
                appear: 0,
                exit: 0,
              }}
              classNames="fade"
              key={uid}
            >
              <tbody>
                {props.noResult ? (
                  <tr>
                    <td colSpan={props.columns.length}>
                      <div className="Table-NoData">
                        <NoResult
                          text={
                            props.noResultMessage
                              ? props.noResultMessage
                              : t.ui.Table.noData
                          }
                        />
                      </div>
                    </td>
                  </tr>
                ) : (
                  props.children
                )}
              </tbody>
            </CSSTransition>
          </TransitionGroup>
        )}
      </table>
    </div>
  );
};
