import * as React from 'react';
import cs from 'classnames';
import {
  getSubscriptionDisplayTitles,
  getSubscriptionIcon,
} from 'src/lib/types';

import './SubscriptionBadge.scss';
import { Icon } from '@telia/styleguide';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';

interface Props {
  subscription: {
    id: string;
    name?: string | null;
    alias?: string | null;
    group: SubscriptionGroup | null;
    ownerAttributes?: null | {
      firstName: string | null;
      lastName: string | null;
    };
    phoneNumbers?: null | Array<{
      type: number;
      phoneNumber: string;
      seriesStart: string | null;
      seriesEnd: string | null;
    }>;
  };
  className?: string;
}

export const SubscriptionBadge: React.FC<Props> = props => {
  const { subscription } = props;

  const { title } = getSubscriptionDisplayTitles(subscription);
  const { icon, color } = getSubscriptionIcon(subscription);

  return (
    <span className={cs('SubscriptionBadge', props.className)}>
      <span
        className={cs(
          'SubscriptionBadge-icon',
          'mr-1',
          color ? `text-${color}` : undefined
        )}
      >
        {icon ? <Icon icon={icon} /> : null}
      </span>

      <span className="SubscriptionBadge-main">
        {title ? (
          <span className="SubscriptionBadge-title">{title}</span>
        ) : null}
      </span>
    </span>
  );
};
