import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { OrderFragment } from 'src/lib/types';
import { OrderLineFragment } from 'src/lib/types/order/OrderLineFragment';
import * as t from '../queries/__types/useSubscriptionOrder';

const WITH_ORDER = gql`
  query useSubscriptionOrder($customerId: ID!, $orderId: ID!) {
    customer(id: $customerId) {
      id
      order(id: $orderId) {
        ... on Order {
          ...OrderSearchResult
        }
        orderLines {
          ...OrderLineFragment
        }
      }
    }
  }
  ${OrderFragment}
  ${OrderLineFragment}
`;

export const useSubscriptionOrder = (orderId: string) => {
  const customer = useCustomer();

  return useQuery<t.useSubscriptionOrder, t.useSubscriptionOrderVariables>(
    WITH_ORDER,
    {
      variables: {
        customerId: customer.id,
        orderId: orderId,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  );
};
