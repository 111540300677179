import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { CpeStatus } from 'src/__types/graphql-global-types';
import { firstCharToLower } from 'src/lib/utils/strings';
import { getLabelFromCpeStatus } from 'src/areas/main/subscriptions/utils/subscriptionUtils';

export const useSubscriptionStatusFilter = () => {
  const query = useQueryParameters();

  const selectedStatus = query.getArray('status');

  const statusOptions = [
    {
      value: 'empty',
      label: t.subscriptions.status.notMonitored,
      negative: true,
    },
    {
      value: firstCharToLower(CpeStatus.NotInstalled),
      label: getLabelFromCpeStatus(CpeStatus.NotInstalled),
    },
    {
      value: firstCharToLower(CpeStatus.Critical),
      label: getLabelFromCpeStatus(CpeStatus.Critical),
    },
    {
      value: firstCharToLower(CpeStatus.Normal),
      label: getLabelFromCpeStatus(CpeStatus.Normal),
    },
    {
      value: firstCharToLower(CpeStatus.NoStatus),
      label: getLabelFromCpeStatus(CpeStatus.NoStatus),
    },
  ];

  return {
    id: 'status',
    label: t.subscriptions.filters.operationalStatus,
    options: statusOptions,
    allowMultiple: true,
    allowSearch: false,
    selectedOptions: selectedStatus,
    handleSave: values => {
      setFilterAndResetPage(query, 'status', values ? values : undefined);
    },
  };
};
