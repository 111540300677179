import * as React from 'react';

import { MainMenu, MainMenuItem, Feature } from 'src/lib/ui';

import { t } from 'src/lib/i18n';

interface Props {
  onClickItem?: () => void;
  className?: string;
  renderBefore?: React.ReactNode;
}

const Menu: React.FC<Props> = props => (
  <MainMenu className={props.className}>
    {props.renderBefore}
    <MainMenuItem
      onClick={props.onClickItem}
      exact={true}
      to="/"
      label={t.layout.menu.home}
      icon="home"
    />

    <MainMenuItem
      onClick={props.onClickItem}
      to="/tjenester"
      label={t.layout.menu.services}
      icon="world"
    />
    <MainMenuItem
      onClick={props.onClickItem}
      to="/kart"
      label={t.layout.menu.map}
      icon="map"
    />
    {/* <MainMenuDivider /> */}
    <MainMenuItem
      onClick={props.onClickItem}
      to="/meldinger"
      label={t.layout.menu.incidents}
      icon="email"
    />
    {/* <MainMenuDivider /> */}
    <Feature feature="main-menu-cost">
      <MainMenuItem
        onClick={props.onClickItem}
        to="/kostnader"
        label={t.layout.menu.cost}
        icon="money"
      />
    </Feature>
    <MainMenuItem
      onClick={props.onClickItem}
      to="/fakturaer"
      label={t.layout.menu.invoices}
      icon="document"
    />
    <Feature feature="ordering">
      <MainMenuItem
        onClick={props.onClickItem}
        to="/bestilling"
        label={t.layout.menu.ordering}
        icon="shoppingcart"
      />
    </Feature>
    <MainMenuItem
      onClick={props.onClickItem}
      to="/ordre"
      label={t.layout.menu.orders}
      icon="delivery"
    />
    <MainMenuItem
      onClick={props.onClickItem}
      to="/kontakter"
      label={t.layout.menu.contacts}
      icon="group"
    />
    <MainMenuItem
      onClick={props.onClickItem}
      to="/dokumenter"
      label={t.layout.menu.documents}
      icon="folder"
    />
    <Feature feature="io-reports">
      <MainMenuItem
        onClick={props.onClickItem}
        to="/rapporter"
        label={t.layout.menu.reports}
        icon="document"
      />
    </Feature>
    <Feature feature="service-plus">
      <MainMenuItem
        onClick={props.onClickItem}
        to="/service-plus"
        label="Service Pluss"
        icon="star"
      />
    </Feature>

    <Feature feature="main-menu-dev">
      <MainMenuItem
        onClick={props.onClickItem}
        to="/dev"
        label="Dev"
        icon="robot"
        className="mt-4"
      />
    </Feature>
    {props.children}
  </MainMenu>
);

export default Menu;
