import * as React from 'react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  XAxis,
  YAxis,
  ReferenceLine,
  CartesianGrid,
  LabelList,
} from 'recharts';
import { formatDate, formatNordic } from 'src/lib/utils';
import { addDays } from 'date-fns';
import './LinearDataEstimateChart.scss';

interface Props {
  periodStart: Date;
  periodEnd: Date;
  date: Date;
  elapsedDays: number;
  periodLength: number;
  usageInPeriod: number;
  usageLimitInPeriod: number;
  estimatedUsageInPeriod: number;
}

interface State {
  hasAnimated: boolean;
}

const dotRadius = 10;
const lineStrokeWidth = 5;
const animationDuration = 1000;

class LinearLinearDataEstimateChart extends React.Component<Props, State> {
  state: State = {
    hasAnimated: false,
  };

  formatXTick = (tick: number) =>
    formatDate(addDays(this.props.periodStart, tick), 'dateWithMonth');

  formatYTick = (tick: number) => `${formatNordic(tick / 1024, 1)} GB`;

  getYTickValues = () => {
    const roundedEstimate =
      Math.ceil(this.props.estimatedUsageInPeriod / 1024) * 1024;
    return Array(6)
      .fill(0)
      .map((_, i) => roundedEstimate * (i / 5));
  };

  render() {
    const data = [
      { x: 0, y: 0 },
      { x: this.props.elapsedDays, y: this.props.usageInPeriod },
      {
        x: this.props.periodLength,
        y: this.props.estimatedUsageInPeriod,
      },
    ];

    // Check limits
    const estimateIsOverLimit = this.props.usageLimitInPeriod
      ? this.props.estimatedUsageInPeriod > this.props.usageLimitInPeriod
      : false;
    const usageIsOverLimit = this.props.usageLimitInPeriod
      ? this.props.usageInPeriod > this.props.usageLimitInPeriod
      : false;

    // Animation calculation
    const elapsedRatio = this.props.elapsedDays / this.props.periodLength;
    const firstAnimationDuration = elapsedRatio * animationDuration;
    const secondAnimationDuration = (1 - elapsedRatio) * animationDuration;

    return (
      <React.Fragment>
        <ResponsiveContainer height={350} className="LinearDataEstimateChart">
          <LineChart margin={{ top: 10, right: 20, bottom: 10, left: 20 }}>
            <defs>
              <linearGradient
                id={
                  estimateIsOverLimit || usageIsOverLimit
                    ? 'LinearDataEstimateChart-gradient-danger'
                    : 'LinearDataEstimateChart-gradient'
                }
                x1="0"
                y1="0"
                x2="0"
                y2="1"
              >
                <stop offset="0%" />
                <stop offset="100" />
              </linearGradient>
            </defs>
            <CartesianGrid strokeDasharray="3 3" stroke="#E7F0F2" />
            {this.props.usageLimitInPeriod && (
              <ReferenceLine
                y={this.props.usageLimitInPeriod}
                className={
                  usageIsOverLimit
                    ? 'LinearDataEstimateChart-referenceLine--usageOver'
                    : ''
                }
                stroke="#353E6B"
                strokeDasharray="5 3"
              />
            )}
            <Line
              className={`LinearDataEstimateChart-actualUsageLine
              ${
                usageIsOverLimit
                  ? 'LinearDataEstimateChart-actualUsageLine--usageOver'
                  : ''
              }
              `}
              dataKey="y"
              strokeWidth={lineStrokeWidth}
              dot={{ r: dotRadius }}
              data={[data[0], data[1]]}
              animationEasing="linear"
              isAnimationActive={!this.state.hasAnimated}
              animationDuration={firstAnimationDuration}
            />
            <Line
              className={`LinearDataEstimateChart-estimateLine ${
                usageIsOverLimit
                  ? 'LinearDataEstimateChart-estimateLine--usageOver'
                  : estimateIsOverLimit
                  ? 'LinearDataEstimateChart-estimateLine--estimateOver'
                  : ''
              }`}
              dataKey="y"
              strokeWidth={lineStrokeWidth}
              dot={{ r: dotRadius }}
              data={[data[1], data[2]]}
              animationEasing="linear"
              isAnimationActive={!this.state.hasAnimated}
              animationDuration={secondAnimationDuration + 50}
              animationBegin={firstAnimationDuration}
              onAnimationEnd={() => this.setState({ hasAnimated: true })}
            >
              <LabelList
                dataKey="y"
                position="top"
                content={labelProps => (
                  <CustomizedLabel
                    {...labelProps}
                    elapsedRatio={elapsedRatio}
                    usageIsOverLimit={usageIsOverLimit}
                    estimateIsOverLimit={estimateIsOverLimit}
                  />
                )}
              />
            </Line>
            <XAxis
              type="number"
              dataKey="x"
              tickMargin={15}
              tickLine={false}
              axisLine={false}
              tickCount={6}
              padding={{ left: 15, right: 15 }}
              tickFormatter={this.formatXTick}
            />
            <YAxis
              tickMargin={15}
              tickLine={false}
              axisLine={false}
              tickCount={6}
              ticks={this.getYTickValues()}
              padding={{ top: 20, bottom: 15 }}
              tickFormatter={this.formatYTick}
            />
          </LineChart>
        </ResponsiveContainer>
      </React.Fragment>
    );
  }
}

const CustomizedLabel = props => {
  // index = 0: actual usage, index = 1: estimated usage
  const { x, y, index, value } = props;
  const width = index === 0 ? 100 : 130;
  const height = 30;

  // Handle label collision
  let translateX: number;
  let translateY: number;
  if (props.elapsedRatio < 0.3 && index === 0) {
    translateX = x + dotRadius + 10;
    translateY = y;
  } else if (props.elapsedRatio > 0.8 && index === 0) {
    translateX = x - width / 2;
    translateY = y + height / 2 + 5;
  } else {
    translateX = x - (width + dotRadius + 10);
    translateY = y - height;
  }

  return (
    <g
      transform={`translate(${translateX}, ${translateY})`}
      className={`LinearDataEstimateChart-label
      ${
        props.usageIsOverLimit
          ? 'LinearDataEstimateChart-label--usageOver'
          : props.estimateIsOverLimit
          ? 'LinearDataEstimateChart-label--estimateOver'
          : ''
      }`}
    >
      <rect
        fill="#4C5BDA"
        stroke="#888"
        width={width}
        height={height}
        rx="2"
        ry="2"
      />
      <text
        fill="#fff"
        fontSize={10}
        y={height / 2 + 5}
        x={10}
        style={{ fontSize: '16px' }}
      >
        {index === 0
          ? `Brukt: ${formatNordic(value / 1024, 1)} GB`
          : `Prognose: ${formatNordic(value / 1024, 1)} GB`}
      </text>
    </g>
  );
};

export default LinearLinearDataEstimateChart;
