import React from 'react';
import FilterSection from 'src/areas/main/common/FilterSection';
import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { SubscriptionsFilterSectionProps } from '../../types';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { SubscriptionExportButton } from 'src/areas/main/subscriptions/lib/SubscriptionExportButton/SubscriptionExportButton';
import {
  useTagsFilter,
  useSortFilter,
  useCaseFilter,
  useSubscriptionTypeFilter,
} from 'src/lib/filters';
import { t } from 'src/lib/i18n';
import { BatchEditTags } from '../Components';

export const GenericSubscriptionsFilters = (
  props: SubscriptionsFilterSectionProps
) => {
  const { subscriptionListParams: sp, sortPickerOptions } = props;
  const query = useQueryParameters();

  const tagsFilter = useTagsFilter();
  const sortOptions = useSortFilter({
    label: t.subscriptions.sortFields.lable,
    currentSortBy: sp.sortBy,
    currentSortOrder: sp.sortOrder,
    setSort: sp.setSort,
    sortFields: sortPickerOptions,
    defaultOption: sp.defaultSort,
  });
  const caseFilter = useCaseFilter();
  const subscriptionTypeFilter = useSubscriptionTypeFilter();

  return (
    <FilterSection
      currentView={sp.view}
      searchValue={sp.searchQuery}
      onSearch={val => setFilterAndResetPage(query, 'query', val)}
      onResetSearch={() => setFilterAndResetPage(query, 'query')}
      filterOptions={[
        tagsFilter,
        caseFilter,
        subscriptionTypeFilter,
        ...(sp.view === 'list' ? [sortOptions] : []),
      ]}
      totalFiltersActive={props.filterCount}
      totalSearchResults={props.totalResults}
      filterToggling={true}
      actions={
        <>
          <BatchEditTags
            selectedSubscriptionIds={props.selectProps?.selected ?? []}
          />

          <SubscriptionExportButton
            query={sp.searchQuery}
            tags={query.getArray('tag')}
            cases={query.getArray('cases')}
            status={query.getArray('status')}
            subscriptionsIds={props.selectProps?.selected}
          />
        </>
      }
      selectProps={props.selectProps}
    />
  );
};
