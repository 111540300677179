import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useTechnicalContactSubscriptions';

export const useTechnicalContactSubscriptionsQuery = gql`
  query useTechnicalContactSubscriptions(
    $customerId: ID!
    $subscriptionIds: [ID!]!
  ) {
    customer(id: $customerId) {
      id
      subscriptions(ids: $subscriptionIds) {
        id
        technicalContacts {
          priority
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            secondaryPhone
            description
          }
        }
      }
    }
  }
`;

/**
 * get subscriptions with full list of technicalContacts, for all subscriptions a user is technical contact for
 */
export const useTechnicalContactSubscriptions = (
  subscriptionIds: string[],
  contactId: string
) => {
  const customer = useCustomer();
  const { data, loading, error } = useQuery<
    t.useTechnicalContactSubscriptions,
    t.useTechnicalContactSubscriptionsVariables
  >(useTechnicalContactSubscriptionsQuery, {
    variables: {
      customerId: customer.id,
      subscriptionIds: subscriptionIds,
    },
  });
  return { subscriptions: data?.customer?.subscriptions, loading, error };
};
