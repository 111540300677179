import invoicesNb from '../../nb/Main/invoices';
import { InvoiceStatus } from 'src/lib/types';

const invoices: typeof invoicesNb = {
  title: 'Invoices',
  statusLabel: 'Status',
  status: {
    Paid: {
      status: 'Paid' as InvoiceStatus,
      label: 'Paid',
    },
    PartiallyPaid: {
      status: 'PartiallyPaid' as InvoiceStatus,
      label: 'Unpaid',
    },
    Unpaid: {
      status: 'Unpaid' as InvoiceStatus,
      label: 'Unpaid',
    },
    CreditNote: {
      status: 'CreditNote' as InvoiceStatus,
      label: 'Credit note',
    },
    Overdue: {
      status: 'Overdue' as InvoiceStatus,
      label: 'Overdue',
    },
  },
  invoiceNumber: 'Invoice number',
  invoiceAddress: 'Invoice address',
  invoiceDate: 'Invoice date',
  dueDate: 'Due date',
  sumExVat: 'Sum ex. VAT',
  showInvoice: 'Show invoice',
  btnDownloadPdf: 'Download PDF',
  btnDownloadCSV: 'Download CSV',
  btnDownloadExcel: 'Download Excel',
  noInvoices: 'No invoices',
  showXofYInvoices: (showing: number, total?: number) => `Showing ${showing} of
  ${total} invoices`,
  detailReportDownloadBtn: 'Download monthly report',
  allPeriods: 'All periods',
  newBankAccountNumber: {
    title: 'Notification of new bank account number to Telia',
    url: 'https://cloud.enterprise.telia.no/datakomeng',
    linkText: 'Read more',
  },
};

export default invoices;
