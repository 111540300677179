import { DefaultDataSummaryPage, DataWithStatusSummary } from '.';
import { SdWanSummary } from './SdWanSummary';

export const getDataSubscriptionView = (subscription: {
  isMonitoringPossible?: boolean | null;
  name?: string | null;
}) => {
  if (subscription.name === 'Telia SD WAN') {
    return SdWanSummary;
  }

  if (subscription.isMonitoringPossible) {
    return DataWithStatusSummary;
  }

  return DefaultDataSummaryPage;
};
