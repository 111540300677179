import * as React from 'react';
import cx from 'classnames';
import './Alert.scss';
import { Icon, IconDefinition } from '@telia/styleguide';
import { UrlForObject, urlFor } from '../LinkTo/urlFor';
import { useRouter } from 'src/lib/utils/useRouter';

export interface AlertProps {
  status: 'success' | 'error' | 'warning' | 'default';
  icon?: IconDefinition;
  header?: string;
  subHeader?: string;
  secondSubHeader?: string;
  className?: string;
  linkTo?: UrlForObject;
}

// TODO: Use new icon with background component when its available
export const Alert: React.FunctionComponent<AlertProps> = props => {
  const router = useRouter();
  return (
    <div
      className={cx(
        'Alert',
        props.status,
        props.className,
        props.linkTo !== undefined ? 'clickable' : undefined
      )}
      onClick={
        props.linkTo
          ? () => props.linkTo && router.history.push(urlFor(props.linkTo))
          : undefined
      }
      role={props.linkTo !== undefined ? 'link' : undefined}
      tabIndex={props.linkTo !== undefined ? 0 : undefined}
      onKeyUp={
        props.linkTo !== undefined
          ? e => {
              if (e.keyCode === 13 && props.linkTo) {
                router.history.push(urlFor(props.linkTo));
              }
            }
          : undefined
      }
    >
      <div className="Alert-wrapper">
        <div className="Alert-icon">
          <Icon icon={props.icon} />
        </div>
      </div>
      <div className="Alert-content">
        <div className="Alert-content-header mb-4">
          {props.header && <h2>{props.header}</h2>}
          <div className="Alert-content-header-subHeader">
            {props.subHeader && <small>{props.subHeader}</small>}
            {props.secondSubHeader && (
              <small className="pl-4">{props.secondSubHeader}</small>
            )}
          </div>
        </div>
        {props.children}
      </div>
    </div>
  );
};
