import { useState, useEffect, useMemo } from 'react';
import { compact } from 'lodash';
export interface TableSelectionProps {
  selected: string[];
  onSelect: (id: string) => void;
  allSelected: boolean;
  onSelectAll: () => void;
  setSelected: (id: string[]) => void;
}
export const useTableSelection = (
  values: Array<{ id: string | null }>
): TableSelectionProps | undefined => {
  const [selected, setSelected] = useState<string[]>([]);
  const allSelected = useMemo(
    () =>
      Boolean(
        values.length && selected.length && values.length <= selected.length
      ),
    [values, selected]
  );

  useEffect(() => {
    setSelected(prev => {
      return prev.filter(id => values.map(obj => obj.id).includes(id));
    });
  }, [values]);

  const onSelect = (id: string) => {
    if (selected.indexOf(id) === -1) {
      setSelected([...selected, id]);
    } else {
      setSelected(selected.filter(select => select !== id));
    }
  };

  const onSelectAll = () => {
    if (allSelected) {
      setSelected([]);
    } else {
      setSelected(compact(values.map(val => val.id)));
    }
  };

  return {
    selected,
    onSelect,
    allSelected,
    onSelectAll,
    setSelected,
  };
};
