import { IconDefinition } from '@telia/styleguide';
import { DeliveryStatus } from './../../../../__types/graphql-global-types';
import { t } from 'src/lib/i18n';

export const getDeliveryStatusDefinitions = (
  deliveryStatus?: DeliveryStatus | null
) => {
  const i18n = t.subscriptions.SubscriptionDeliveryStatusCard;

  const deliveryStatusIcon: IconDefinition | undefined =
    deliveryStatus === DeliveryStatus.assigning
      ? 'delivery'
      : deliveryStatus === DeliveryStatus.terminating
      ? 'stop'
      : undefined;

  const deliveryStatusTranslations =
    deliveryStatus === DeliveryStatus.assigning
      ? i18n.assigning
      : deliveryStatus === DeliveryStatus.terminating
      ? i18n.terminating
      : undefined;

  return {
    deliveryStatusIcon,
    deliveryStatusTranslations,
  };
};
