import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useUpdateIncidentNotification';
import { INCIDENT_QUERY } from 'src/areas/main/incidents/queries/useIncident';
import { ContactFragment } from 'src/lib/types/contact/ContactFragment';

const UPDATE_INCIDENT_NOTIFICATION = gql`
  mutation useUpdateIncidentNotification(
    $input: UpdateIncidentNotificationInput
    $customerId: ID!
  ) {
    updateIncidentNotification(input: $input) {
      incident {
        id
        notificationContacts {
          id
          name
          email
          phoneNumber
          contact {
            ...ContactSearchResult
          }
        }
      }
    }
  }
  ${ContactFragment}
`;

export const useUpdateIncidentNotification = () => {
  const customer = useCustomer();

  const [mutation, { data, loading, error }] = useMutation<
    t.useUpdateIncidentNotification,
    t.useUpdateIncidentNotificationVariables
  >(UPDATE_INCIDENT_NOTIFICATION);

  const updateIncidentNotification = (
    incidentId: string,
    emailNotification: boolean
  ) => {
    return mutation({
      variables: {
        input: {
          id: incidentId,
          sms: false,
          email: emailNotification,
        },
        customerId: customer.id,
      },
      refetchQueries: [
        {
          query: INCIDENT_QUERY,
          variables: { customerId: customer.id, incidentId },
        },
      ],
    });
  };

  return {
    updateIncidentNotification,
    loading,
    error: !!error,
    data: data?.updateIncidentNotification?.incident ?? undefined,
  };
};
