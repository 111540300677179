import { Category } from './Analytics';
import { SubscriptionGroupInUrl } from 'src/lib/ui/LinkTo/urlFor';

export const getCategory = (service: string): Category => {
  switch (service) {
    case 'alle' as SubscriptionGroupInUrl:
      return 'All services';
    case 'nettverk' as SubscriptionGroupInUrl:
      return 'Data';
    case 'mobil' as SubscriptionGroupInUrl:
      return 'Mobile';
    case 'telefoni' as SubscriptionGroupInUrl:
      return 'Tele';
    case 'tv' as SubscriptionGroupInUrl:
      return 'Tv';
    case 'meldinger':
      return 'Incidents';
    case 'kontakter':
      return 'Contacts';
    default:
      return 'All services';
  }
};
