import React, { useRef, useState } from 'react';
import './ReportIncident.scss';
import {
  Button,
  Fullscreen,
  FullscreenBody,
  FullscreenHeader,
  TextArea,
  Alert,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useRouter } from 'src/lib/utils/useRouter';
import SaveOverlay from 'src/areas/main/contacts/lib/SaveOverlay/SaveOverlay';
import { useSubmitIncident } from './useSubmitIncident';
import { SelectCard } from 'src/lib/ui/SelectCard/SelectCard';
import IncidentFileUploader from 'src/areas/main/incidents/lib/IncidentFileUploader/IncidentFileUploader';
import { useExpandableTextArea } from 'src/lib/ui/TextArea/useExpandableTextArea';
import {
  SubscriptionGroup,
  IncidentSeverity,
  IncidentType,
} from 'src/__types/graphql-global-types';
import { Toggle } from '@telia/styleguide/business';
import { useCurrentContact } from '../../queries/useCurrentContact';
import { trackReportIncident } from 'src/lib/analytics';
import { useFormik } from 'formik';

interface Props {
  subscription: {
    id: string;
    group?: SubscriptionGroup | null;
    incidentFollowers?: null | Array<{
      id: string;
      firstName?: string | null;
      lastName?: string | null;
      email?: string | null;
    }>;
  };
}

interface FormikValues {
  description: string;
}

type ErrorType = 'down' | 'unstable' | 'slow' | 'other';

export const ReportIncident = (props: Props) => {
  const txt = t.incidents.reportIncident.incidentTypes;
  const { submitIncident, error, loading } = useSubmitIncident(
    props.subscription.id
  );
  const { contactId } = useCurrentContact();

  const bottomRef = useRef<HTMLDivElement>(null);
  const scrollToBottom = () => {
    bottomRef.current?.scrollIntoView({
      behavior: 'smooth',
    });
  };

  const textAreaRef = useExpandableTextArea();
  const router = useRouter();

  const [selectedServiceErrors, setSelectedServiceErrors] = useState<
    ErrorType | undefined
  >(props.subscription.group === 'other' ? 'other' : undefined);

  const [files, setFiles] = useState<File[]>([]);
  const [addContactPerson, setAddContactPerson] = useState(true);
  const [notificationsByEmail, setNotificationsByEmail] = useState(true);

  const otherServiceErrorSelected = async () => {
    await setSelectedServiceErrors('other');
    textAreaRef.current?.focus();
    scrollToBottom();
  };
  const addFile = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      const newFiles = Array.from(e.target.files);
      setFiles(prevState => prevState.concat(newFiles));
    }
  };
  const removeFile = (fileName: string) => {
    setFiles(files.filter(f => f.name !== fileName));
  };

  const contacts =
    addContactPerson && contactId
      ? [
          {
            id: contactId,
            label: contactId ?? '',
          },
        ]
      : undefined;

  const formik = useFormik<FormikValues>({
    initialValues: {
      description: '',
    },
    onSubmit: (values, formikBag) => {
      if (props.subscription.group)
        trackReportIncident(props.subscription.group);

      switch (selectedServiceErrors) {
        // The user has selected "Service is down"
        case 'down':
          submitIncident({
            description: formik.values.description,
            files: files,
            incidentType: IncidentType.ConnectionDown,
            severity: IncidentSeverity.Major,
            contacts: contacts,
            emailNotification: notificationsByEmail ?? undefined,
          });
          break;

        // The user has selected "Service is unstable"
        case 'unstable':
          submitIncident({
            description: formik.values.description,
            files: files,
            incidentType: IncidentType.UnstableConnection,
            severity: IncidentSeverity.Minor,
            contacts: contacts,
            emailNotification: notificationsByEmail ?? undefined,
          });
          break;

        // The user has selected "Service is slow"
        case 'slow':
          submitIncident({
            description: formik.values.description,
            files: files,
            incidentType: IncidentType.SlowConnection,
            severity: IncidentSeverity.Minor,
            contacts: contacts,
            emailNotification: notificationsByEmail ?? undefined,
          });
          break;

        // The user has selected "Other error"
        case 'other':
          submitIncident({
            description: formik.values.description,
            files: files,
            incidentType: IncidentType.Other,
            severity: IncidentSeverity.Minor,
            contacts: contacts,
            emailNotification: notificationsByEmail ?? undefined,
          });
          break;
      }
    },
  });
  const isSubmitButtonDisabled =
    selectedServiceErrors === undefined ||
    (selectedServiceErrors === 'other' &&
      formik.values.description.length < 1) ||
    loading ||
    error;

  return (
    <Fullscreen>
      <FullscreenHeader title={t.incidents.reportIncident.reportIncident} />
      <FullscreenBody>
        {contacts ? (
          <>
            <div className="ReportIncident-bottom-margin">
              {props.subscription.group !== 'other' && (
                <>
                  <div>
                    <div className="ReportIncident-title">
                      {t.incidents.reportIncident.background}
                    </div>
                    <div className="ReportIncident-subtitle">
                      {t.incidents.reportIncident.pickErrorDescription}
                    </div>
                  </div>
                  <div className="mt-5 row">
                    <div className="ReportIncident-category col-md-4">
                      <SelectCard
                        title={txt.connectionDown.title}
                        icon="world-off"
                        helpText={txt.connectionDown.helpText}
                        iconColor="red"
                        selected={selectedServiceErrors === 'down'}
                        onClick={() => setSelectedServiceErrors('down')}
                      />
                    </div>
                    <div className="ReportIncident-category col-md-4">
                      <SelectCard
                        title={txt.connectionUnstable.title}
                        helpText={txt.connectionUnstable.helpText}
                        icon="pulse"
                        iconColor="Orange"
                        selected={selectedServiceErrors === 'unstable'}
                        onClick={() => setSelectedServiceErrors('unstable')}
                      />
                    </div>
                    <div className="ReportIncident-category col-md-4 ReportIncident-slow-error">
                      <SelectCard
                        title={txt.connectionSlow.title}
                        helpText={txt.connectionSlow.helpText}
                        icon="speedometer"
                        iconColor="blue"
                        selected={selectedServiceErrors === 'slow'}
                        onClick={() => setSelectedServiceErrors('slow')}
                      />
                    </div>
                  </div>
                  <div className="ReportIncident-separator mt-5 row" />
                  <div className="d-flex justify-content-center mt-4">
                    <Button
                      className={
                        selectedServiceErrors === 'other' ? 'active' : undefined
                      }
                      style={
                        selectedServiceErrors === 'other'
                          ? undefined
                          : { color: 'gray' }
                      }
                      transparent={selectedServiceErrors === 'other'}
                      outline
                      size="small"
                      rounded
                      color={
                        selectedServiceErrors === 'other' ? 'white' : 'dark'
                      }
                      onClick={otherServiceErrorSelected}
                    >
                      {txt.otherServiceError.title}
                    </Button>
                  </div>
                </>
              )}
              {typeof selectedServiceErrors !== 'undefined' ? (
                <div>
                  <div>
                    <div className="ReportIncident-title mt-4">
                      {txt.otherServiceError.subTitle}
                    </div>
                    <div className="ReportIncident-subtitle mt-2">
                      {txt.otherServiceError.helpText}
                    </div>
                    <TextArea
                      innerRef={textAreaRef}
                      style={{ height: '125px' }}
                      id="description"
                      onChange={formik.handleChange}
                      value={formik.values.description}
                    />
                    <IncidentFileUploader
                      files={files}
                      addFile={addFile}
                      removeFile={removeFile}
                      disabled={loading}
                    />
                  </div>
                  <div id="bottom" ref={bottomRef} className="row" />
                </div>
              ) : null}
              <div className="ReportIncident-separator mt-5 row" />
              <div
                className="d-flex flex-column mt-4"
                style={{ paddingBottom: '90px' }}
              >
                <div className="align-self-center">
                  <div className="d-flex">
                    <Toggle
                      checked={addContactPerson}
                      onChange={() => setAddContactPerson(!addContactPerson)}
                      className="mr-3"
                    />
                    {t.incidents.reportIncident.notifyMeAboutNotifications}
                  </div>
                  <div className="d-flex mt-4">
                    <Toggle
                      checked={notificationsByEmail}
                      onChange={() =>
                        setNotificationsByEmail(!notificationsByEmail)
                      }
                      className="mr-3"
                    />
                    {t.incidents.reportIncident.sendNotificationsByEmail}
                  </div>
                </div>
              </div>
            </div>
            <SaveOverlay
              ignoreSidebar={true}
              cancel={() => router.history.goBack()}
              submit={() => formik.handleSubmit()}
              submitLabel={t.incidents.reportIncident.btnReportIncident}
              loading={loading}
              disabled={isSubmitButtonDisabled}
              error={error}
              errorMsg={t.incidents.reportIncident.errorMsg}
            />
          </>
        ) : (
          <Alert
            status="error"
            icon="group"
            header={t.incidents.reportIncident.noContact.text}
            className="mb-4"
            linkTo={{ contact: '' }}
          ></Alert>
        )}
      </FullscreenBody>
    </Fullscreen>
  );
};
