//
// WARNING
// This file is automatically generated.
// Do not make manual changes.
//
// Generated by: scripts/update-graphql-schema.js.
// Generated at: 2023-09-14 13:20:13
//

export default {
  __schema: {
    types: [
      {
        kind: 'UNION',
        name: 'IncidentActivity',
        possibleTypes: [
          {
            name: 'IncidentComment',
          },
          {
            name: 'IncidentFieldChange',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'WorklogActivity',
        possibleTypes: [
          {
            name: 'WorklogStatusChange',
          },
          {
            name: 'WorklogComment',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'Cost',
        possibleTypes: [
          {
            name: 'TagCostAggregate',
          },
          {
            name: 'ProductCostAggregate',
          },
          {
            name: 'SubscriptionCostAggregate',
          },
          {
            name: 'ProductGroupCostAggregate',
          },
          {
            name: 'OrganisationCostAggregate',
          },
          {
            name: 'InvoiceLine',
          },
          {
            name: 'Invoice',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'SearchResult',
        possibleTypes: [
          {
            name: 'ContactSearchResult',
          },
          {
            name: 'FileSearchResult',
          },
          {
            name: 'IncidentSearchResult',
          },
          {
            name: 'InvoiceSearchResult',
          },
          {
            name: 'OrganisationSearchResult',
          },
          {
            name: 'CustomerSearchResult',
          },
          {
            name: 'SubscriptionSearchResult',
          },
          {
            name: 'CostCenterSearchResult',
          },
          {
            name: 'TagSearchResult',
          },
          {
            name: 'OrderSearchResult',
          },
          {
            name: 'ClusterSearchResult',
          },
          {
            name: 'NotificationSearchResult',
          },
          {
            name: 'PlannedWorkSearchResult',
          },
        ],
      },
      {
        kind: 'UNION',
        name: 'UpdateTagsOnSubscriptionsError',
        possibleTypes: [
          {
            name: 'Error',
          },
          {
            name: 'TagOrSubscriptionUpdateError',
          },
        ],
      },
    ],
  },
};
