import React from 'react';
import { useOverviewData } from 'src/areas/mobile/overview/useOverviewData';
import { Loading } from 'src/lib/ui';
import GenericSubscriptionPage from 'src/areas/main/subscriptions/Pages/Generic/GenericSubscriptionPage';
import { useSubscription } from 'src/areas/main/subscriptions/queries/useSubscription';
import { useRouter } from 'src/lib/utils/useRouter';
import { Tab } from 'src/areas/main/common/PageHeader/PageHeader';
import { Switch, Route } from 'react-router';
import { Settings } from 'src/areas/mobile/settings/Settings';
import { History } from 'src/areas/mobile/history/History';
import { getSubscriptionBreadcrumbs } from 'src/lib/types/subscription/getSubscriptionBreadcrumbs';
import { getSubscriptionDisplayTitles } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { MobileSubscription } from 'src/areas/main/subscriptions/Pages/Mobile/MobileSubscription';
import { SubscriptionPageHeader } from '../../layout';
import { useFeature } from 'src/lib/utils';
import { useAppContext } from 'src/lib/global';
import { userIsCustomerOrSystemAdmin } from 'src/areas/main/contacts/lib/editContact/utils';

export const MobileSubscriptionPageInner = () => {
  const router = useRouter<{ id: string }>();
  const { subscription } = useSubscription(router.match.params.id);
  const dontFetchPinPuk = useFeature('hide-pin-puk');
  const appContext = useAppContext();
  const adminUser = userIsCustomerOrSystemAdmin(appContext.customerRoles);
  const { loading, data } = useOverviewData(dontFetchPinPuk && !adminUser);

  if (subscription) {
    if (loading) {
      return <Loading />;
    }
    if (!data || !data.subscription) {
      return <GenericSubscriptionPage subscription={subscription} />;
    }

    const breadcrumbs = getSubscriptionBreadcrumbs(subscription);
    const { title } = getSubscriptionDisplayTitles(subscription);
    const tabs: Tab[] = [
      {
        url: router.match.url,
        label: t.subscriptions.MobileSubscription.summary,
        exact: true,
      },
      {
        url: `${router.match.url}/innstillinger`,
        label: t.subscriptions.MobileSubscription.settings,
      },
      {
        url: `${router.match.url}/forbruk`,
        label: t.subscriptions.MobileSubscription.usage,
      },
    ];
    return (
      <>
        <SubscriptionPageHeader
          description=""
          subscription={subscription}
          title={title}
          breadcrumbs={breadcrumbs}
          navTabs={tabs}
        />
        <div className="container py-4 pb-5">
          <Switch>
            <Route
              path={router.match.path}
              exact={true}
              component={MobileSubscription}
            />
            <Route
              path={`${router.match.path}/innstillinger`}
              component={Settings}
            />
            <Route path={`${router.match.path}/forbruk`} component={History} />
          </Switch>
        </div>
      </>
    );
  }
  return null;
};
