import React, { useContext } from 'react';
import { TvSubscriptionAbout } from './TvSubscriptionAbout';
import { TvSubscriptionContentList } from './TvSubscriptionContentList';
import { TvSubscriptionPageContext } from './TvSubscriptionPage';
import { SubscriptionSummaryContainer } from '../../layout';
import {
  SubscriptionReportIncidentButton,
  SubscriptionCostDetails,
  TechnicalContactsCard,
  AdditionalInformationCard,
} from '../../Pages/Components';

export const TvSubscriptionSummary = () => {
  const subscription = useContext(TvSubscriptionPageContext);
  if (!subscription) {
    return null;
  }

  return (
    <SubscriptionSummaryContainer
      sidebar={
        <>
          <TvSubscriptionAbout />
          <SubscriptionCostDetails subscription={subscription} />
          <TechnicalContactsCard subscription={subscription} />
          <AdditionalInformationCard subscription={subscription} />
        </>
      }
      main={
        <>
          <TvSubscriptionContentList />
          <SubscriptionReportIncidentButton subscription={subscription} />
        </>
      }
      subscription={subscription}
    />
  );
};
