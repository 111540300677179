export const getPrecision = (size: number) => {
  if (size < 1) {
    return 3;
  } else if (size < 10) {
    return 2;
  } else if (size < 100) {
    return 1;
  }
  return 0;
};
