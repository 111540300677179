import * as React from 'react';
import { get } from 'lodash';
import { Loading } from 'src/lib/ui';
import config from 'src/config';
import WithRemoteData from './WithRemoteData';
import { BuildDetails } from './BuildDetail';

interface Props {
  children: (details: BuildDetails) => JSX.Element | null;
}

const WithBuildDetails: React.SFC<Props> = props => (
  <WithRemoteData url={`${config.apiRootUrl || ''}/api/versions`}>
    {state => {
      if (state.loading) {
        // return props.children(undefined);
        return <Loading />;
      }
      if (state.error) {
        console.error(state.error);
        // return props.children(undefined);
        return (
          <div className="alert alert-danger">
            Kunne ikke laste API-versjoner
          </div>
        );
      }
      return props.children({
        gateway: {
          env: get(state, 'data.gateway.env'),
          branch: get(state, 'data.gateway.branch'),
          commit: get(state, 'data.gateway.commit'),
          commitTime: get(state, 'data.gateway.commitTime'),
          buildTime: get(state, 'data.gateway.buildTime'),
        },
        backend: {
          branch: get(state, 'data.backend.git.branch'),
          commit: get(state, 'data.backend.git.commit.id'),
          commitTime: get(state, 'data.backend.git.commit.time'),
          buildTime: get(state, 'data.backend.build.time'),
        },
        frontend: {
          env: process.env.NODE_ENV || undefined,
          branch: process.env.PORTAL_GIT_BRANCH || undefined,
          commit: process.env.PORTAL_GIT_COMMIT || undefined,
          commitTime: process.env.PORTAL_GIT_TIME || undefined,
          buildTime: process.env.PORTAL_BUILD_TIME || undefined,
        },
      });
    }}
  </WithRemoteData>
);

export default WithBuildDetails;
