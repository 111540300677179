import * as React from 'react';
import { withRouter, RouteComponentProps } from 'react-router';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import { SearchListPicker, SearchListPickerItem, Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

interface SubscriptionSelectorProps {
  subscriptionList: Array<{ id: string }>;
  activeSubscriptionId?: string;
  setActiveSubscription: (id: string) => void;
}

interface SubscriptionSelectorState {
  dropdownOpen: boolean;
}

type Props = SubscriptionSelectorProps & RouteComponentProps;

class SubscriptionSelector extends React.Component<
  Props,
  SubscriptionSelectorState
> {
  constructor(props: Props) {
    super(props);

    this.state = {
      dropdownOpen: false,
    };
  }

  toogleDropdown = () =>
    this.setState(prevState => ({
      dropdownOpen: !prevState.dropdownOpen,
    }));

  onClick = (item: SearchListPickerItem) => {
    if (item.id) {
      this.props.setActiveSubscription(item.id);
      this.toogleDropdown();
    }
  };

  mapItemsToListItem = (subscriptionList: Array<{ id: string }>) => {
    return subscriptionList.map(subscription => {
      return {
        id: subscription.id,
        value: subscription.id,
      };
    });
  };

  render() {
    return (
      <Dropdown
        isOpen={this.state.dropdownOpen}
        toggle={this.toogleDropdown}
        className="d-inline"
      >
        <DropdownToggle tag="span">
          <Button
            color="white"
            iconPosition="after"
            icon={
              this.state.dropdownOpen ? 'arrow-small-up' : 'arrow-small-down'
            }
          >
            {this.props.activeSubscriptionId}
          </Button>
        </DropdownToggle>
        <DropdownMenu right={true}>
          <SearchListPicker
            allowQuery={false}
            items={this.mapItemsToListItem(this.props.subscriptionList)}
            listHeader={t.mobile.menu.subscriptionSelectorHeader}
            onClick={this.onClick}
          />
        </DropdownMenu>
      </Dropdown>
    );
  }
}

export default withRouter(SubscriptionSelector);
