import * as React from 'react';
import Table from 'reactstrap/lib/Table';
import './InvoiceLineTable.scss';
import { Icon } from '@telia/styleguide';

interface Props {
  cols: string[];
  colAlign?: Array<'left' | 'center' | 'right'>;
  colSortKeys?: string[];
  currentSort?: string;
  currentSortOrder?: 'asc' | 'desc';
  setSort?: (key: string) => void;
}

const InvoiceLineTable: React.SFC<Props> = props => (
  <Table className="InvoiceLineCostTable">
    <thead>
      <tr>
        {props.cols.map((col, i) => (
          <th
            key={i}
            onClick={() => {
              if (props.setSort && props.colSortKeys && props.colSortKeys[i]) {
                return props.setSort(props.colSortKeys[i]);
              }
            }}
            style={{
              ...(props.colAlign && props.colAlign[i]
                ? { textAlign: props.colAlign[i] }
                : {}),
              ...(props.colSortKeys && props.colSortKeys[i]
                ? { cursor: 'pointer' }
                : {}),
            }}
          >
            <span>
              {col}
              {props.currentSort &&
                props.colSortKeys &&
                props.currentSortOrder &&
                props.currentSort === props.colSortKeys[i] && (
                  <span style={{ position: 'absolute' }} className="ml-1">
                    <Icon
                      icon={
                        props.currentSortOrder === 'asc'
                          ? 'arrow-large-up'
                          : 'arrow-large-down'
                      }
                    />
                  </span>
                )}
            </span>
          </th>
        ))}
      </tr>
    </thead>
    <tbody>{props.children}</tbody>
  </Table>
);

export default InvoiceLineTable;
