import { QUERY_TECHNICAL_CONTACTS } from './useUpdateTechnicalContacts';
import { useCustomer } from 'src/lib/global/CustomerContext';
import { QUERY_CONTACT } from './../../contacts/queries/useContact';
import { ExecutionResult } from 'react-apollo';
import * as t from './__types/useReplaceTechnicalContact';
import { useMutation } from '@apollo/react-hooks';
import { useTechnicalContactSubscriptions } from '../../contacts/queries/useTechnicalContactSubscriptions';
import { Contact } from 'src/lib/types';
import gql from 'graphql-tag';
import { useState } from 'react';
import { trackContactUpdate } from 'src/lib/analytics';

const MUTATION_REPLACE_TECHNICAL_CONTACTS = gql`
  mutation useReplaceTechnicalContact(
    $input: SetTechnicalContactsOnSubscriptionInput
  ) {
    setTechnicalContactsOnSubscription(input: $input) {
      technicalContacts {
        priority
        contact {
          id
          firstName
          lastName
          email
          mobilePhone
          secondaryPhone
          description
        }
      }
      error {
        code
        message
      }
    }
  }
`;

interface ReplaceTechnicalContactResult {
  replaceAllTechnicalContact: (newContact?: Contact) => void;
  replaceTechnicalContact: (
    subscriptionId: string,
    newContact: Contact
  ) => Promise<ExecutionResult<t.useReplaceTechnicalContact>>;
  loading: boolean;
  error: boolean;
  data: t.useReplaceTechnicalContact | undefined;
}

export const useReplaceTechnicalContact = (
  currentContactId: string,
  subscriptionIds: string[]
): ReplaceTechnicalContactResult => {
  const { subscriptions } = useTechnicalContactSubscriptions(
    subscriptionIds,
    currentContactId
  );
  const customer = useCustomer();
  const [waiting, setWaiting] = useState(false);
  const [mutation, result] = useMutation<
    t.useReplaceTechnicalContact,
    t.useReplaceTechnicalContactVariables
  >(MUTATION_REPLACE_TECHNICAL_CONTACTS);

  const replaceContactOnSubscription = (
    id: string,
    newContact?: Contact,
    withTracking: boolean = true
  ): Promise<ExecutionResult<t.useReplaceTechnicalContact>> => {
    if (withTracking) trackContactUpdate('contact responsibilities', 'one');

    const currentContacts = subscriptions?.find(s => s?.id === id)
      ?.technicalContacts;

    const selectedIsContactPerson = !!currentContacts?.find(
      c => c.contact.id === newContact?.id
    );

    const newContacts = currentContacts?.map(contact =>
      contact.contact.id === currentContactId
        ? {
            priority: contact.priority,
            contactId: selectedIsContactPerson ? null : newContact?.id ?? null,
          }
        : { priority: contact.priority, contactId: contact.contact.id }
    );

    return mutation({
      variables: {
        input: {
          subscriptionId: id,
          technicalContacts: newContacts,
        },
      },
      refetchQueries: [
        {
          query: QUERY_CONTACT,
          variables: {
            customerId: customer.id,
            contactId: currentContactId,
          },
        },
        {
          query: QUERY_TECHNICAL_CONTACTS,
          variables: {
            subscriptionId: id,
            customerId: customer.id,
          },
        },
      ],
    });
  };

  const replaceAllTechnicalContact = (newContact?: Contact) => {
    trackContactUpdate('contact responsibilities', 'all');
    setWaiting(true);
    Promise.all(
      subscriptionIds.map(id =>
        replaceContactOnSubscription(id, newContact, false)
      )
    ).then(() => setWaiting(false));
  };

  return {
    replaceTechnicalContact: replaceContactOnSubscription,
    replaceAllTechnicalContact,
    loading: result.loading || waiting,
    error: !!(
      result.error || result.data?.setTechnicalContactsOnSubscription?.error
    ),
    data: result.data,
  };
};
