import { RadioGroup, RadioGroupProps } from 'src/lib/ui';
import { Field, FieldProps } from 'formik';
import React from 'react';
import _get from 'lodash/get';

type FormikRadioGroupProps = { id: string } & Omit<
  RadioGroupProps,
  'value' | 'error'
>;

const FormikRadioGroup: React.FunctionComponent<FormikRadioGroupProps> = props => {
  return (
    <Field name={props.id}>
      {formikProps => {
        const { field, form } = formikProps as FieldProps;

        const hasError =
          Boolean(_get(form.touched, field.name)) &&
          Boolean(_get(form.errors, field.name));

        const customOnChange = (value: string | number | boolean) => {
          form.setFieldValue(field.name, value);
          if (props.onChange) {
            props.onChange(value);
          }
        };

        return (
          <RadioGroup
            {...props}
            value={field.value}
            onChange={customOnChange}
            error={
              hasError ? (_get(form.errors, field.name) as string) : undefined
            }
          />
        );
      }}
    </Field>
  );
};

export default FormikRadioGroup;
