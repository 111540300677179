import * as React from 'react';

import {
  Step,
  StepShape,
  FormikTextField,
  FormikSelectField,
  FormikRadioGroup,
  FormikDatePicker,
  FormikCheckbox,
} from 'src/lib/flow';

import { Attribute, SelectOption } from 'src/lib/ui';

import {
  PhoneNumberTypes,
  MobileOrderConsumer,
  FormerOwnerTypes,
} from './OrderMobileSubscription';
import { t } from 'src/lib/i18n';
import { MobileType } from '../types';

import OrderingSection from '../layout/OrderingSection';

import * as yup from 'yup';
import { yupValidateDateAndIsBeforeTomorrow } from 'src/lib/utils/yupValidations';
import { useReserveOrderNumber_reserveOrderNumber } from '../queries/__types/useReserveOrderNumber';

export interface Step1Values {
  phoneNumber?: string;
  phoneNumberSource: PhoneNumberTypes;
  formerOwnerSource?: FormerOwnerTypes;
  formerOwner?: {
    businessName: string;
    organisationNumber: string;
    firstName: string;
    lastName: string;
    birthDate: string;
  };
  transferPaymentsToNewOwner: boolean;
}

const Step1: Step<Step1Values> = (opts?: {
  orderingType?: MobileType;
  reserveOrderNumber?: (phoneNumber: string) => void;
  reserveOrderNumberResult?: useReserveOrderNumber_reserveOrderNumber;
}): StepShape<Step1Values> => {
  const i18n = t.ordering.OrderMobileSubscription.Step1;
  const orderingType =
    opts && 'orderingType' in opts ? opts.orderingType : 'mobile';

  const phoneNumberSourceOptions: Array<{
    value: PhoneNumberTypes;
    label: string;
  }> = [
    {
      value: 'existingNumber',
      label: i18n.phoneNumberSourceOpts.existing,
    },
    { value: 'newNumber', label: i18n.phoneNumberSourceOpts.new },
  ];

  const formerOwnerSourceOptions: Array<{
    value: FormerOwnerTypes;
    label: string;
  }> = [
    { value: 'self', label: i18n.formerOwner.self },
    { value: 'private', label: i18n.formerOwner.private },
    { value: 'company', label: i18n.formerOwner.company },
  ];

  const reservedNumber = () => !opts?.reserveOrderNumberResult?.error;

  return {
    title: i18n.title,
    validationSchema: yup.object().shape({
      phoneNumberSource: yup.string(),
      phoneNumber: yup
        .string()
        .required(
          orderingType === 'm2m'
            ? i18n.phoneNumber.errorM2m
            : i18n.phoneNumber.error
        )
        .when('phoneNumberSource', {
          is: 'existingNumber',
          then: yup
            .string()
            .matches(
              orderingType === 'm2m' ? /(^58[0-9]{10})$/ : /(^[49][0-9]{7})$/,
              orderingType === 'm2m'
                ? i18n.phoneNumber.errorM2m
                : i18n.phoneNumber.error
            ),
        })
        .test({
          test: reservedNumber,
          message: i18n.phoneNumber.errorReserved,
        }),

      formerOwnerSource: yup.string().when('phoneNumberSource', {
        is: 'existingNumber',
        then: yup.string().required(i18n.formerOwner.error),
      }),
      formerOwner: yup
        .object()
        .when(['phoneNumberSource', 'formerOwnerSource'], {
          is: (phoneNumberSource, formerOwnerSource) =>
            phoneNumberSource === 'existingNumber' &&
            formerOwnerSource === 'private',
          then: yup.object().shape({
            firstName: yup.string().required(i18n.firstName.error),
            lastName: yup.string().required(i18n.lastName.error),
            birthDate: yupValidateDateAndIsBeforeTomorrow(),
          }),
        })
        .when(['phoneNumberSource', 'formerOwnerSource'], {
          is: (phoneNumberSource, formerOwnerSource) =>
            phoneNumberSource === 'existingNumber' &&
            formerOwnerSource === 'company',
          then: yup.object().shape({
            businessName: yup.string().required(i18n.orgName.error),
            organisationNumber: yup
              .string()
              .required(i18n.orgNumber.error)
              .matches(/\d{9}/, i18n.orgNumber.error),
          }),
        }),
      transferPaymentsToNewOwner: yup.string().when(['phoneNumberSource'], {
        is: phoneNumberSource => phoneNumberSource === 'existingNumber',
        then: yup.string().required('Velg en verdi'),
      }),
    }),
    renderSummary: ({ values }) => {
      return (
        <>
          <Attribute
            label={i18n.phoneNumber.label}
            value={values.phoneNumber}
          />
          {values.phoneNumberSource === 'existingNumber' &&
            values.formerOwnerSource !== 'self' &&
            values.formerOwner && (
              <>
                <div className="pt-2">{i18n.formerOwnerHeader}</div>
                {values.formerOwnerSource === 'private' && (
                  <>
                    <Attribute
                      label={i18n.firstName.label}
                      value={values.formerOwner.firstName}
                    />
                    <Attribute
                      label={i18n.lastName.label}
                      value={values.formerOwner.lastName}
                    />
                    <Attribute
                      label={i18n.birthDate.label}
                      value={values.formerOwner.birthDate}
                    />
                  </>
                )}
                {values.formerOwnerSource === 'company' && (
                  <>
                    <Attribute
                      label={i18n.orgName.label}
                      value={values.formerOwner.businessName}
                    />
                    <Attribute
                      label={i18n.orgNumber.label}
                      value={values.formerOwner.organisationNumber}
                    />
                  </>
                )}
              </>
            )}

          {values.phoneNumberSource === 'existingNumber' && (
            <Attribute
              label={i18n.transferPaymentsToNewOwner.label}
              value={i18n.transferPaymentsToNewOwner.value(
                Boolean(values.transferPaymentsToNewOwner)
              )}
            />
          )}
        </>
      );
    },

    renderForm: ({ flow }) => (
      <MobileOrderConsumer>
        {mobileContext => {
          const mobileNumbers = mobileContext.data.voiceNumbers
            ? mobileContext.data.voiceNumbers.map(number => ({
                value: number,
                label: number,
              }))
            : [];
          const dataNumbers = mobileContext.data.dataNumbers
            ? mobileContext.data.dataNumbers.map(number => ({
                value: number,
                label: number,
              }))
            : [];

          const selectNumber = (value?: SelectOption | null) => {
            if (opts?.reserveOrderNumber) {
              opts.reserveOrderNumber(value?.value ?? '');
            }
          };

          return (
            <>
              <OrderingSection>
                <FormikRadioGroup
                  id="phoneNumberSource"
                  block={true}
                  options={phoneNumberSourceOptions}
                  onChange={() => flow.setFieldValue('phoneNumber', '', true)}
                />
                <div className="row pt-4">
                  {flow.values.phoneNumberSource === 'newNumber' ? (
                    <FormikSelectField
                      id="phoneNumber"
                      label={i18n.phoneNumber.label}
                      required={true}
                      placeholder={i18n.phoneNumber.placeholderSelect}
                      options={
                        orderingType === 'm2m'
                          ? dataNumbers
                          : [...mobileNumbers, ...dataNumbers]
                      }
                      noOptionsMessage={i18n.phoneNumber.noOpts}
                      className="col-md-6"
                      onChange={selectNumber}
                      helpText={
                        opts?.reserveOrderNumberResult?.error
                          ? i18n.phoneNumber.errorReserved
                          : undefined
                      }
                    />
                  ) : (
                    <FormikTextField
                      id="phoneNumber"
                      label={i18n.phoneNumber.label}
                      required={true}
                      helpText={
                        orderingType === 'm2m'
                          ? i18n.phoneNumber.helpTextM2m
                          : i18n.phoneNumber.helpTextMobile
                      }
                      className="col-md-6"
                    />
                  )}
                </div>
              </OrderingSection>

              {flow.values.phoneNumberSource === 'existingNumber' && (
                <OrderingSection header={i18n.formerOwnerHeader}>
                  <FormikRadioGroup
                    id="formerOwnerSource"
                    options={formerOwnerSourceOptions}
                    className="mb-4"
                    block={true}
                  />

                  {flow.values.formerOwnerSource === 'private' && (
                    <>
                      <div className="row">
                        <FormikTextField
                          id="formerOwner.firstName"
                          label={i18n.firstName.label}
                          required={true}
                          className="col-md-6"
                        />
                        <FormikTextField
                          id="formerOwner.lastName"
                          label={i18n.lastName.label}
                          required={true}
                          className="col-md-6"
                        />
                      </div>
                      <div className="row mb-4">
                        <FormikDatePicker
                          id="formerOwner.birthDate"
                          label={i18n.birthDate.label}
                          required={true}
                          helpText={i18n.birthDate.helpText}
                          className="col-md-6"
                        />
                      </div>
                    </>
                  )}

                  {flow.values.formerOwnerSource === 'company' && (
                    <div className="row mb-4">
                      <FormikTextField
                        id="formerOwner.businessName"
                        label={i18n.orgName.label}
                        required={true}
                        className="col-md-6"
                      />
                      <FormikTextField
                        id="formerOwner.organisationNumber"
                        label={i18n.orgNumber.label}
                        required={true}
                        className="col-md-6"
                      />
                    </div>
                  )}

                  <FormikCheckbox
                    id="transferPaymentsToNewOwner"
                    label={i18n.transferPaymentsToNewOwner.label}
                  />
                </OrderingSection>
              )}
            </>
          );
        }}
      </MobileOrderConsumer>
    ),
  };
};

export default Step1;
