import * as React from 'react';
import { Mutation } from 'react-apollo';
import gql from 'graphql-tag';
import { NotificationFragment } from 'src/lib/types';
import {
  markNotificationRead,
  markNotificationReadVariables,
} from './__types/markNotificationRead';

export const MARK_READ = gql`
  mutation markNotificationRead($input: MarkNotificationAsReadInput) {
    markNotificationAsRead(input: $input) {
      notification {
        ...NotificationSearchResult
      }
    }
  }
  ${NotificationFragment}
`;

export interface MarkNotificationAsReadResult {
  loading: boolean;
  markNotificationRead: (notificationId: string, cb?: () => void) => void;
  response?: markNotificationRead;
}

interface Props {
  children: (res: MarkNotificationAsReadResult) => JSX.Element | null;
}

export const MarkNotificationRead: React.FC<Props> = props => (
  <Mutation<markNotificationRead, markNotificationReadVariables>
    mutation={MARK_READ}
  >
    {(mutation, result) => {
      const markNotificationAsReadContext: MarkNotificationAsReadResult = {
        markNotificationRead: async (notificationId, cb) => {
          await mutation({
            variables: {
              input: {
                notificationId,
              },
            },
          });
          if (cb) {
            cb();
          }
        },
        loading: result.loading,
        response: result.data,
      };
      return props.children(markNotificationAsReadContext);
    }}
  </Mutation>
);
