import { SearchDomain } from 'src/__types/graphql-global-types';
import { useDomainColumns } from 'src/lib/table';
import {
  useListQueryParams,
  useQueryParameters,
  getSortOptions,
} from 'src/lib/utils';

import { FilterTogglesValue } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { IconDefinition } from '@telia/styleguide';
import { useSortFilter } from 'src/lib/filters';

export const useContactsParams = () => {
  const i18n = t.contacts;
  const defaultOption = { key: 'firstName', order: 'asc' };
  const query = useQueryParameters();
  const listParams = useListQueryParams({
    defaultSortKey: defaultOption.key,
    defaultSortOrder: defaultOption.order,
    preferenceNamespace: 'contacts',
  });
  const { columns, availableColumns } = useDomainColumns(
    SearchDomain.contact,
    listParams.columns
  );

  const hasUser = query.getArray('hasUser');

  const filters: FilterTogglesValue[] = [
    {
      value: 'true',
      label: `${i18n.contactWithAccess}`,
      icon: 'user' as IconDefinition,
    },
    {
      value: 'false',
      label: `${i18n.contactWithoutAccess}`,
      icon: 'contact' as IconDefinition,
    },
  ];

  const sortOptions = useSortFilter({
    label: i18n.sortFields.lable,
    sortFields: getSortOptions(['firstName', 'lastName', 'email']),
    setSort: (by, order) => {
      query.set({
        sortBy: by,
        sortOrder: order,
      });
    },
    currentSortBy: listParams.sortBy,
    currentSortOrder: listParams.sortOrder,
    defaultOption,
  });

  return {
    query,
    sortOptions,
    filters,
    columns,
    availableColumns,
    hasUser,
    listParams,
    activeSort: { key: listParams.sortBy, order: listParams.sortOrder },
  };
};
