import { Definition } from './types';
import { TableColumnDefintion } from 'src/lib/ui';

/**
 * This function formats current active columns for a user,
 * based on saved userpreferensen and actual table column definitions in the code,
 * to type TableColumnDefintion understood by our table api.
 *
 * It also formats all available columns within a domain.
 *
 * If there is a mismatch in the saved user preferences and the actual column definitions,
 * this formatting will ensure a complete list of active, existing columns.
 */

export function formatColumns<T extends string>(props: {
  map: { [key in T]: Omit<TableColumnDefintion, 'key'> };
  defaultColumns: Array<T>;
  savedColumnPreferences: string[];
}): Definition {
  const { map, defaultColumns, savedColumnPreferences } = props;
  const allColumns = Object.keys(map);

  /**
   * If some of the saved column preferences is no longer valid we filter them out.
   * (ie. no longer a part of the column definitions, column name has changes etc)
   */
  const validSavedColumns = savedColumnPreferences.filter(column =>
    allColumns.includes(column as T)
  );

  /**
   * Default columns determine the number of columns we must have in a table.
   */
  const requiredNumberOfColumns = defaultColumns.length;
  if (validSavedColumns.length !== requiredNumberOfColumns) {
    /**
     * If we do not have a sufficient number of valid, saved column preferences,
     * we append columns from the definitions, that are not yet selected,
     * until we have a complete list.
     */
    if (validSavedColumns.length < requiredNumberOfColumns) {
      /**
       * Columns from the definitions that are not part of the save user preferneces
       */
      const notSelectedColumns = defaultColumns
        .filter(column => !validSavedColumns.includes(column))
        .reverse();
      while (validSavedColumns.length < requiredNumberOfColumns) {
        validSavedColumns.push(notSelectedColumns?.pop() ?? '');
      }
    } else {
      /**
       * If we have more save column preferences than the number of columns we allow in the table,
       * we simply remove the last elements
       */
      while (validSavedColumns.length > requiredNumberOfColumns) {
        validSavedColumns.pop();
      }
    }
  }

  /**
   * List of columns that will be rendered initilaly in the table
   */
  const currentColumns = validSavedColumns;

  /**
   * List of all columns the user can choose to set at their prefered columns
   */
  const availableColumns = Object.keys(map)?.map(c => ({
    key: c,
    ...map?.[c],
  }));

  const selectedColumns = currentColumns?.map(c => ({
    key: c,
    ...map?.[c],
  }));

  const columnOptions =
    availableColumns?.length === selectedColumns?.length
      ? []
      : availableColumns;
  return {
    availableColumns: columnOptions,
    columns: selectedColumns,
  };
}
