import { getSortOption } from './../utils/getSortOptionsByKey';
import { Definition } from './types';
import { formatColumns, OrderColumns } from './index';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getOrderColumnDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const orderColumnMap: {
    [key in OrderColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    portalStatus: {
      label: t.orders.orderTableColumns.status,
      cellWidth: 150,
      fixed: true,
    },
    id: {
      label: t.orders.orderTableColumns.id,
      cellWidth: 200,
      fixed: true,
    },
    type: {
      label: t.orders.orderTableColumns.category,
      cellWidth: 135,
    },
    description: {
      label: t.orders.orderTableColumns.description,
    },
    createdAt: {
      label: t.orders.orderTableColumns.created,
      cellWidth: 90,
      sortOptions: getSortOption('createdAt', true),
    },
    updatedAt: {
      label: t.orders.orderTableColumns.updated,
      cellWidth: 90,
    },
    deliveryDate: {
      label: t.orders.orderTableColumns.deliveryDate,
      cellWidth: 90,
    },
  };
  const defaultOrderColumns: Array<OrderColumns> = [
    'portalStatus',
    'description',
    'type',
    'id',
    'createdAt',
  ];

  return formatColumns({
    map: orderColumnMap,
    defaultColumns: defaultOrderColumns,
    savedColumnPreferences,
  });
};
