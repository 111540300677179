import { push } from './Analytics';

export type NotificationSettingsActions =
  | 'toggle'
  | 'set_channel_sms'
  | 'set_channel_email'
  | 'set_services'
  | 'select_services';

export function trackNotificationSettings(
  action: NotificationSettingsActions,
  notificationItem: string,
  value: string
) {
  push({
    category: 'Notifications Settings',
    action: action,
    label: notificationItem,
    value: value,
  });
}
