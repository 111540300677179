import React from 'react';
import NotificationSection from './NotificationSection';
import { t } from 'src/lib/i18n';
import { useCustomerAccessQuery } from 'src/lib/global/CustomerAccessQuery';
import { allowAllNotifications } from 'src/areas/dashboard/Notification/utils';
import { useCustomer } from 'src/lib/global/CustomerContext';
import {
  useContact_customer_contacts_notificationSettings_incident,
  useContact_customer_contacts_notificationSettings_change,
} from 'src/areas/main/contacts/queries/__types/useContact';

type Props = {
  incidentSettings: useContact_customer_contacts_notificationSettings_incident | null;
  changeSettings: useContact_customer_contacts_notificationSettings_change | null;
  contactId?: string;
  setSettings?: (
    settings:
      | useContact_customer_contacts_notificationSettings_incident
      | useContact_customer_contacts_notificationSettings_change
      | null
  ) => void;
};

export const NotificationSettings = (props: Props) => {
  const customer = useCustomer();
  const access = useCustomerAccessQuery(customer);
  const role =
    access &&
    access.data &&
    access.data.me &&
    access.data.me.customerAccess &&
    access.data.me.customerAccess.roles;

  return (
    <>
      {/** INCIDENTS */}
      {props.incidentSettings && (
        <NotificationSection
          title={t.profile.notificationPreferences.incidents.title}
          allowAllNotifications={role ? allowAllNotifications(role) : undefined}
          allowSelectChannel={true}
          notificationSettings={props.incidentSettings}
          contactId={props.contactId}
          setSettings={props.setSettings}
        />
      )}

      {/** PLANNEDWORK */}
      {props.changeSettings && (
        <NotificationSection
          forceEmailChannel={true}
          title={t.profile.notificationPreferences.plannedWork.title}
          allowAllNotifications={role ? allowAllNotifications(role) : undefined}
          notificationSettings={props.changeSettings}
          contactId={props.contactId}
          setSettings={props.setSettings}
        />
      )}
    </>
  );
};
