import React, { useMemo } from 'react';
import {
  Dropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';
import { Button, colors, useTabbedDropdown } from 'src/lib/ui';
import { useSelect, UseSelectGetItemPropsOptions } from 'downshift';
import { SortOrder } from 'src/__types/graphql-global-types';
import { Icon } from '@telia/styleguide';
import { t } from 'src/lib/i18n';
interface Props {
  sortFields?: Array<SortItem>;
  handleSort?: (sortKey: string, sortOrder: SortOrder) => void;
  columnOptions?: Array<Column>;
  handleColumnSelect?: (columnKey: string) => void;
  currentColumn: {
    key: string;
    label: string;
  };
  activeSort?: Omit<SortItem, 'label'>;
}

type SortItem = {
  key: string;
  label: string;
  order: SortOrder;
};

type Column = {
  key: string;
  label: string;
};

export const ColumnOptions = (props: Props) => {
  const dropdownRef = useTabbedDropdown();
  const options: Array<Column | SortItem> = useMemo(
    () => [...(props.sortFields ?? []), ...(props.columnOptions ?? [])],
    [props.sortFields, props.columnOptions]
  );
  const i18n = t.ui.Table.ColumnOptions;
  const downshift = useSelect({
    items: options,
    circularNavigation: true,
    initialHighlightedIndex: 0,
    onSelectedItemChange: changes => {
      if (changes?.selectedItem && 'order' in changes.selectedItem) {
        props.handleSort?.(
          changes?.selectedItem.key,
          changes?.selectedItem.order
        );
      } else if (changes?.selectedItem) {
        props.handleColumnSelect?.(changes?.selectedItem?.key);
      }
    },
  });

  return (
    <div ref={dropdownRef}>
      <Dropdown
        className="ml-n3"
        isOpen={downshift.isOpen}
        toggle={downshift.isOpen ? downshift.closeMenu : downshift.openMenu}
      >
        <DropdownToggle tag="span">
          <Button
            size="small"
            iconPosition="after"
            icon="arrow-small-down"
            {...downshift.getToggleButtonProps({ refKey: 'innerRef' })}
          >
            {props.currentColumn.label}
          </Button>
        </DropdownToggle>
        <DropdownMenu>
          <div
            {...downshift.getMenuProps()}
            onBlur={e => {
              e.stopPropagation();
            }}
          >
            {props.sortFields?.length ? (
              <DropdownItem className="font-weight-bold text-uppercase" header>
                {i18n.sort}
              </DropdownItem>
            ) : null}
            {props.sortFields?.map((item, i) => (
              <ColumnOption
                item={item}
                index={i}
                key={i}
                selected={Boolean(
                  props.activeSort &&
                    item.key + item.order ===
                      props.activeSort.key + props.activeSort.order
                )}
                highlightedIndex={downshift.highlightedIndex}
                getItemProps={downshift.getItemProps}
              />
            ))}

            {props.sortFields && props.columnOptions && (
              <DropdownItem divider />
            )}
            {props.columnOptions?.length ? (
              <DropdownItem className="font-weight-bold text-uppercase" header>
                {i18n.show}
              </DropdownItem>
            ) : null}
            {props.columnOptions?.map((item, i) => (
              <ColumnOption
                item={item}
                index={(props.sortFields?.length ?? 0) + i}
                selected={props.currentColumn.key === item.key}
                key={i}
                highlightedIndex={downshift.highlightedIndex}
                getItemProps={downshift.getItemProps}
              />
            ))}
          </div>
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};

const ColumnOption = (props: {
  item: SortItem | Column;
  index: number;
  selected: boolean;
  highlightedIndex: number;
  getItemProps: (
    options: UseSelectGetItemPropsOptions<Column | SortItem>
  ) => any;
}) => {
  const { index, item, selected } = props;
  const selectedStyle = selected ? { color: colors.corePurple } : undefined;
  const highlightedStyle =
    props.highlightedIndex === index
      ? { backgroundColor: colors.grey }
      : undefined;
  return (
    <button
      tabIndex={-1}
      className={'dropdown-item'}
      style={{ ...highlightedStyle, ...selectedStyle }}
      {...props.getItemProps({
        item,
        index,
      })}
    >
      {item.label}
      {selected ? (
        <div className="ml-auto">
          <Icon
            icon="check-mark"
            style={{ height: '1rem', width: '1rem', fill: colors.corePurple }}
          />
        </div>
      ) : null}
    </button>
  );
};
