import React from 'react';
import { Modal, ModalFooter, ModalBody, ModalHeader } from 'reactstrap';
import { CpeStatus } from 'src/__types/graphql-global-types';
import { Button } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import {
  colors,
  Table,
  TableBodyRow,
  TableBodyCell,
  TableColumnDefintion,
  Loading,
} from 'src/lib/ui';
import { useInterfaces } from 'src/areas/main/subscriptions/queries/useInterfaces';

type InterfaceType = {
  name: string;
  alias: string | null;
  status: CpeStatus | null;
  arpTable?: null | Array<{
    ip: string;
    mac: string;
  }>;
  subscription: null | {
    vrf: null | {
      vpnAlias: string | null;
      vpnId: string;
      vrfName: string;
      sagCode: string;
    };
  };
};

export const InterfaceModal = (props: {
  open: boolean;
  setOpen: (open: boolean) => void;
  selectedRow?: InterfaceType;
}) => {
  const router = useRouter<{ id: string }>();
  const { refetch, loading } = useInterfaces(router.match.params.id, true);
  const { open, setOpen, selectedRow } = props;
  const columns: TableColumnDefintion[] = [
    {
      key: 'ip',
      label: t.subscriptions.ArpTable.ipAddress,
    },
    {
      key: 'mac',
      label: t.subscriptions.ArpTable.mac,
    },
  ];
  return (
    <Modal isOpen={open} size="lg" toggle={() => setOpen(!open)}>
      <ModalHeader>
        {t.subscriptions.SubscriptionInterfaces.interface} {selectedRow?.name}{' '}
        {selectedRow?.alias}
      </ModalHeader>

      <ModalBody>
        <div className="pb-4">
          <Button icon="refresh" onClick={() => refetch()} color="white">
            {t.subscriptions.ArpTable.update}
          </Button>
        </div>
        {selectedRow?.arpTable?.length ? (
          <Table
            columns={columns}
            sortable={false}
            bordered={false}
            loading={loading}
            rowsCount={selectedRow?.arpTable?.length}
          >
            {selectedRow?.arpTable?.map(arp => (
              <TableBodyRow key={arp?.ip}>
                <TableBodyCell>{arp?.ip}</TableBodyCell>
                <TableBodyCell>{arp?.mac}</TableBodyCell>
              </TableBodyRow>
            ))}
          </Table>
        ) : (
          <div
            className="d-flex align-items-center justify-content-center"
            style={{ height: 250, color: colors.greyDarkText }}
          >
            {loading ? (
              <Loading />
            ) : (
              t.subscriptions.ArpTable.noConnectedDevices
            )}
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        <Button onClick={() => setOpen(false)}>
          {t.subscriptions.ArpTable.close}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
