import * as React from 'react';
import { CSSTransition } from 'react-transition-group';
import { BottomOverlay, FormSubmitBar } from 'src/lib/ui';
import './SaveOverlay.scss';
interface Props {
  loading?: boolean;
  disabled?: boolean;
  error?: boolean;
  errorMsg?: string;
  success?: boolean;
  successMsg?: string;
  ignoreSidebar?: boolean;
  submit: (
    e?:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>
      | React.SyntheticEvent<EventTarget>
  ) => void;
  cancel?: (e?: React.SyntheticEvent<EventTarget>) => void;
  submitLabel?: string;
  linkToOnCancel?: string;
}

export const SaveOverlay: React.SFC<Props> = props => {
  return (
    <CSSTransition
      in={true}
      timeout={300}
      classNames="SaveOverlay"
      unmountOnExit={true}
    >
      <BottomOverlay
        className="SaveOverlay"
        ignoreSidebar={props.ignoreSidebar}
      >
        <div className="SaveOverlay-container">
          <FormSubmitBar
            loading={props.loading}
            error={props.error}
            errorMsg={props.errorMsg}
            disabled={props.disabled}
            successMsg={props.successMsg}
            cancel={props.cancel}
            submit={props.submit}
            submitLabel={props.submitLabel}
            linkToOnCancel={props.linkToOnCancel}
          />
        </div>
      </BottomOverlay>
    </CSSTransition>
  );
};

export default SaveOverlay;
