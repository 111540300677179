import { customerSupportNumber, customerSupportEmail } from './../../variables';
import contactsNb from '../../nb/Main/contacts';

const contacts: typeof contactsNb = {
  table: {
    name: 'Name',
    phone: 'Phone',
    email: 'Email',
    employeeNumber: 'Employee number',
    description: 'Description',
    tags: 'Access to tags',
    invoiceCenter: 'Access to invoice center',
    access: 'Access',
    cases: 'Cases',
  },
  title: 'Contacts',
  type: 'Type',
  btnCreate: 'Create new contact',
  contactWithAccess: 'Contact with access',
  contactWithoutAccess: 'Contacts without access',
  btnFilterAdmin: 'Administrator',
  sortFields: {
    lable: 'Sort',
    AtoÅ: 'A to Å',
    ÅtoA: 'Å to A',
    lastName: 'Last name',
    firstName: 'First name',
    email: 'Email',
  },
  status: {
    waitingActivation: 'Waiting for activation',
    userHasNotVerifiedEmail: 'This user has not verified their email address.',
  },
  listingNoResult: 'No contacts',

  EditContact: {
    title: 'Edit contact information',
    description:
      'This information will be visible for other user. Email is connected to the users credentials and can only be changed by the user.',
    errors: {
      failed: 'Ops, we were not able to edit the contact',
      required: 'Required',
      phone: 'Invalid phone number',
      email: 'Invalid email',
      tooLong: (num: number) => `The limit is ${num} characters`,
      tooShort: (num: number) => `You need at least ${num} characters`,
    },
    changeEmailAddress: 'Do you wish to change email address?',
    lookingForUserSettings: 'Are you looking for your settings?',
    adminChangeEmailInfoText: `It is currently not possible to change email address connected to a user. To do this, the user must contact customer service via chat, by calling ${customerSupportNumber} or by sending an email to ${customerSupportEmail}, and we will assist you in this matter.`,
    userChangeEmailInfoText: `It is currently not possible to change the email address connected to your user.  If you need to change your email  contact customer service via chat, by calling ${customerSupportNumber} or by sending an email to ${customerSupportEmail}, and we will assist you in this matter.`,
    ifYouWishToChangePassword: 'If you wish to change password',
    ifYouWishToChangeMFA: 'If you wish to change two factor authentication',
    youCanDoThatHere: 'you can do that here',
  },
  editRoles: {
    access: 'Access',
    accessAndNotificationsCopied: 'Access and notifications are copied',
    accessToMinPortal: 'Access to Min Portal',
    cancel: 'Cancel',
    close: 'Close',
    confirm: 'Confirm',
    confirmAccess: 'Confirm access',
    confirmNotifications: 'Confirm notifications',
    copyAccess: 'Copy access and notifications',
    copyAccessWarning: 'NB! This will overwrite the original access for',
    copyAndSave: 'Copy and save',
    economy: 'Economy',
    from: 'from',
    fromAnotherUser: 'from another user',
    giveAccess: 'Give access and notifications',
    editAccess: 'Change access and notifications',
    givingAccessFor: 'You are giving user access and roles to the contact',
    editingAccessFor: 'You are changing roles for',
    incidents: 'Incidents',
    noNotifications: 'No notifications',
    notifications: 'Notifications',
    operations: 'Operations',
    order: 'Orders',
    plannedWork: 'Planned work',
    removeAllAccess: 'Remove all access to Min Portal',
    removeAllAccessConfirmation:
      'Are you sure you want to remove all access and notifications for',
    reviewNotifications: 'Review notifications',
    notificationsInfo1: 'You are now changing notification on behalf of',
    notificationsInfo2:
      'As an admin you can add subscriptions that are outside of the access scope of',

    notificationChangeAccesses:
      "You may only change notifications if it is your user, or if you're an administrator.",
    selectPerson: 'Select person',
    somethingWentWrongNotifications:
      'Something went wrong when copying notifications. Please try again later or contact customer service',
    somethingWentWrongRoles:
      'Something went wrong when copying roles. Please try again later or contact customer service',
    somethingWentWrong:
      'Something went wrong when copying roles and notifications. Please try again later or contact customer service.',
  },
  editServices: {
    editContactResponsibilities: 'Change contact responsibilities',
    step1: {
      whatChangesDoYouWantToMake: 'What changes do you want to make?',
      option1: 'Move all contact responsibilities to another contact',
      option2: 'Make changes for each service',
      option3: 'Remove all contact responsibilities',
      description: (num: number) =>
        `This contact is a contact person for ${num +
          ' ' +
          (num !== 1
            ? 'services'
            : 'service')}. How do you want to change the contact responsibilities?`,
    },
    step3: {
      removeAll: 'Remove all contact responsibilities',
    },
  },
  DeletePerson: {
    deleteError: 'We were not able to delete the contact',
    replaceError: 'We were not able to transfer contact responsibilities',
    step1: {
      replaceContactPerson: 'Replace contact person',
      description1:
        'Upon deletion the contact is removed as contact person on all services and related incidents',
      description2: (num: number) =>
        `This contact is a contact person for ${num +
          ' ' +
          (num !== 1
            ? 'services'
            : 'service')}. Do you want to transfere the contact responsibilities before moving forward?`,
      option1:
        'Yes, transfer entire contact responsibility to a different user',
      option2: 'Yes, replace contact responsibilities for each service',
      option3:
        'Nei, delete contact without replacing contact responsibilities ',
    },
    step2: {
      selectReplacement: 'Set new contact person',
      newContactPerson: 'New contact person',
      replaceByService: 'Set new contact person by service',
      leaveEmpty:
        'If you do not select a new contact for a service, the current contact person will be removed without a replacement',
      replaceAll: (num: number) =>
        `Set new contact person for ${num +
          ' ' +
          (num !== 1 ? 'services' : 'service')}`,
    },
    step3: {
      description:
        'The contact will be deleted from Min Portal. The action cannot be undone.',
      replaceNumSubscriptions: (num: number) =>
        `You are replacing contact responsibilities på ${num +
          ' ' +
          (num !== 1 ? 'services' : 'service')}`,
      replaceAll: 'You are replacing contact responsibilities for all services',
      noReplacements:
        'You are removing all contact responsibilities without any replacements',
      noReplacementNumSubscriptions: (num: number) =>
        `You are removing contact responsibilities on ${num +
          ' ' +
          (num !== 1 ? 'services' : 'servie')} without any replacements`,
    },
    deleteContact: 'Delete contact',
    deleteIsTemporaryDisabled:
      'Since we are updating our systems, it is unfortunately not possible to delete contacts at this time. We apologize for the inconvenience',
    delete: 'Delete',
    contact: 'contact',
    title: (name: string) => `Do you wish to delete ${name}?`,

    error: (name: string) => `Could not delete ${name}. Try again later`,
    contactOnActiveCaseDeleteError: (name: string) =>
      `Could not delete ${name} because the contact is the reporter on an active incident. Wait until the incident is solved, or close the incident first in order to delete this contact.`,
    deletionWarningUser: (name: string) =>
      `When you delete ${name} the user is permanently removed. You will not be able to revert this change.`,
    deletionWarningContact: (name: string) =>
      `When you delete ${name} the contact is permanently removed. You will not be able to revert this change.`,
    btnDelete: 'Delete',

    /** Remove once new contact is done */
    cannotDeleteUserDescription: (name: string) =>
      `Because ${name} has user access it is currently not possible to delete user`,
  },

  formLabels: {
    accessToMinPortal: 'Access to Min Portal',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    mobilePhone: 'Phone (primary)',
    secondaryPhone: 'Phone (secondary)',
    employeeNumber: 'Employee number',
    description: 'Description',
  },

  accessToTags: 'Access to the following tags',
  accessToOrganisations: 'Access to the following invoice centers',
  breadCrumb: 'Contacts',
  admin: 'Administrator',
  create: {
    createNew: 'Create new',
    step1: {
      yes: 'Yes',
      no: 'No',
      title: 'Should the contact have access to Min Portal?',
      description:
        'If you create a contact, the contact can be assigned as contact person for a service or incident. You can later give a contact access to Min Portal',
    },
    step2: {
      removeContact: 'Remove chosen contact',
      title: 'Contact information',
      description:
        'This information is used to create a new unique contact and will be visible in the contact list',
      moveForwardToGiveRoles: (name: string) =>
        `You can assign roles to ${name} in the next step`,
      contactExists: `The contact already exists.`,
      useExists: 'The user already exists.',
      clickToEditContact:
        ' You can edit information from the contact page by clicking the contact above',
      clickToEditUser:
        ' You can edit roles and information from the user page by clicking the user above',
    },
    step3: {
      title: 'User permissions',
    },
    emailInfo_part1:
      "When you create the new contact, the contact are able to activate it's user. This can be done by going to",
    emailInfo_part2: ', enter their email, and confirm with BankID.',
    emailInfo_part3:
      'Unfortunately they will not be notified by mail due to our systems being changed. We are sorry for the inconvenience. In the meantime we suggest that you notify the new contact about their new user.',
    whatHappensNext: 'What happens next?',
    defaultError:
      'Ops, sorry. We were not able to create the contact. Try again.',
  },
  contact: 'Contact',
  withAccess: 'access to Min Portal',
  contactResponsibilities: 'Contact responsibilities',
  contactPerson: (num: number) =>
    `Contact person for ${num + ' ' + (num === 1 ? ' service' : ' services')}`,
  noContactPerson: 'The contact is not a contact person for any services',
  permissions: 'Permissions and notifications',
  fullAccess: 'Full access',
  partialAccess: 'Partial access',
  accessTo: {
    services: 'to services',
    finance: 'to finance',
    ordering: 'to ordering',
  },
  noPermissions: (name: string) => `${name} does not have access to Min Portal`,
  // btnCreateUser: 'Create user',
  mobileSubscription: 'Mobile subscription',
  noSubscription: (name: string, customerName: string) =>
    `${name} doesn not have a subscription under ${customerName}`,

  descriptionHelpText: 'e.g role og area of responsibility',
};

export default contacts;
