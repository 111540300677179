import * as React from 'react';
import { map, sortBy } from 'lodash';

import { Tag } from '../Tag/Tag';

import './Tags.scss';

interface Props {
  tags?: Array<{
    id: string;
    tag: string;
  }>;

  /**
   * Whether to sort tags alphabetically. Default is `true`.
   */
  //
  sortTags?: boolean;
  onSelect?: (tagId: string) => void;
  onClick?: (tagId: string) => void;
  onDelete?: (id: string) => void;
  disabled?: boolean;
  redirect?: (tagId: string) => void;
}

export const Tags: React.FC<Props> = props => {
  if (!props.tags) {
    return null;
  }
  const onDelete = (e: React.SyntheticEvent<Element>, id: string) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }
    if ('onClick' in props && props.onClick) {
      props.onClick(id);
    }
  };

  const tags =
    props.sortTags !== false ? sortBy(props.tags, t => t.tag) : props.tags;

  const disabled = props.disabled !== false ? true : props.disabled;
  if (props.onSelect) {
    return (
      <ul className="Tags">
        {map(tags, tag => (
          <Tag
            key={tag.id}
            tag={tag}
            renderAs="li"
            onSelect={props.onSelect}
            disabled={disabled}
            redirect={props.redirect}
          />
        ))}
      </ul>
    );
  } else {
    return (
      <ul className="Tags">
        {map(tags, tag => (
          <Tag
            key={tag.id}
            tag={tag}
            renderAs="li"
            onClick={e => onDelete(e, tag.id)}
            disabled={disabled}
            redirect={props.redirect}
          />
        ))}
      </ul>
    );
  }
};
