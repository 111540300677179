import React from 'react';
import { Attribute } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const MplsDetails: React.FC<{
  vrf: {
    vpnAlias: string | null;
    vpnId: string;
    vrfName: string;
    sagCode: string;
  };

  heading: string;
}> = props => {
  const translate = t.subscriptions.TechnicalDetailsCard;

  return (
    <div>
      <h3>{props.heading}</h3>
      <Attribute
        label={translate.vrf}
        value={
          props.vrf.vpnAlias +
          ' ' +
          props.vrf.vpnId +
          ' ' +
          props.vrf.vrfName +
          ' (' +
          props.vrf.sagCode +
          ')'
        }
      />
      <Attribute label={translate.vrfName} value={props.vrf.vrfName} />
      <Attribute label={translate.vrfAlias} value={props.vrf.vpnAlias} />
      <Attribute label={translate.vrfId} value={props.vrf.vpnId} />
    </div>
  );
};
