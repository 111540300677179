import React from 'react';
import './IncidentFileUploader.scss';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
interface Props {
  addFile: (e: React.ChangeEvent<HTMLInputElement>) => void;
  removeFile: (fileName: string) => void;
  files?: File[];
  disabled?: boolean;
}
const IncidentFileUploader: React.FC<Props> = props => (
  <div className="IncidentFileUploader mb-5">
    <label className=" Button Button--default Button--dark">
      {/** mr-2 follows the default margin from Button.tsx */}
      <Icon className="mr-2" icon="attach" />
      {t.incidents.IncidentFileUploader.btnAdd}
      <input
        style={{ display: 'none' }}
        type="file"
        id="IncidentFileUploader--fileInput"
        onChange={props.addFile}
        multiple={true}
      />
    </label>

    {props.files ? (
      props.files.length > 0 ? (
        <div className="IncidentFileUploader--fileContainer">
          <FileList
            disabled={props.disabled}
            handleRemoveFile={props.removeFile}
            files={props.files}
          />
        </div>
      ) : null
    ) : null}
  </div>
);
interface FileListProps {
  handleRemoveFile: (fileName: string) => void;
  files: File[];
  disabled?: boolean;
}

const FileList: React.SFC<FileListProps> = ({
  handleRemoveFile,
  files,
  disabled,
}) => (
  <div>
    <div className="pl-2">
      {files.map((f, i) => (
        <div className="FileList--file" key={i}>
          {f.name}
          <span
            className="pl-2"
            onClick={!disabled ? () => handleRemoveFile(f.name) : undefined}
          >
            <Icon className="FileList--file--close" icon="close" />
          </span>
        </div>
      ))}
    </div>
  </div>
);

export default IncidentFileUploader;
