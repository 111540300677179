const statistics = {
  noStatistics: 'Denne tjenesten har ingen statistikk',
  noInterface: 'Tjenesten har ingen interface tilgjengelig',
  noData: 'Ingen data',
  noPeriods: 'Ingen data i det valgte tidsrommet',
  noTrafficClasses: 'Ingen trafikk klasser for dette utvalget',
  options: 'Valg',
  showGrid: 'Vis rutenett',
  granularity: 'Granularitet',
  toggleFilters: {
    standard: 'STANDARD',
    live: 'LIVE-TRAFIKK',
    qos: 'QUALITY OF SERVICE',
    gsm: 'SIGNALSTYRKE',
  },
  Legend: {
    trafficIn: 'Trafikk inn',
    trafficOut: 'Trafikk ut',
    trafficInAvg: 'Trafikk inn (avg)',
    trafficOutAvg: 'Trafikk ut (avg)',
    trafficInMax: 'Trafikk inn (max)',
    trafficOutMax: 'Trafikk ut (max)',
    jitterIn: 'Jitter inn',
    jitterOut: 'Jitter ut',
    packetDropIn: 'Pakkedropp inn',
    packetDropOut: 'Pakkedropp ut',
    packetLossIn: 'Pakketap inn',
    packetLossOut: 'Pakketap ut',
    responseTime: 'Responstid',
    roundTripTime: 'Round-trip time',
  },
  InterfaceDropdownPicker: {
    placeholder: 'Søk etter interface',
  },
  StatisticsDetails: {
    header: 'Detaljer',
  },
  StatisticsGSM: {
    noData: 'No data',
  },
  SyncChartElement: {
    threshold: 'Terskel',
    thresholdBtn: 'Se utnyttelse',
  },
  Gsm: {
    signalQuality: 'Signal kvalitet (Ec/Io)',
  },
  Qos: {
    noTraffic: 'Ingen trafikk',
    traffic: 'Trafikk',
    dropRate: 'Dropprate',
  },
  StandardDetails: {
    showUtilization: 'Vis utnyttelse',
    in: 'Inn',
    out: 'Ut',
    other: 'Annen informasjon',
    throughputAvg: 'Trafikk (avg)',
    throughputMax: 'Trafikk (max)',
    cumulativeAvg: 'Kumulativ trafikk (avg)',
    cumulativeTotal: 'Kumulativ trafikk (total)',
    packetDrop: 'Pakkedropp (antall)',
    responseTimeAvg: 'Responstid (avg)',
    responseTimeMax: 'Responstid (max)',
    jitter: 'Jitter',
    packetLoss: 'Pakketap (antall)',
    utilization: 'Utnyttelse',
    roundTripTimeAvg: 'Round-trip time (avg)',
    roundTripTimeMax: 'Round-trip time (max)',
  },
  StatisticsFilter: {
    throughput: 'Trafikk',
    cumulative: 'Kumulativ trafikk',
    packetDrop: 'Pakkedropp',
    responseTime: 'Responstid',
    jitter: 'Jitter',
    packetLoss: 'Pakketap',
    utilization: 'Utnyttelse',
    roundTripTime: 'Round-trip time',
    qosTransmit: 'QoS trafikk',
    qosDrop: 'QoS pakkedropp',
    live: 'Live trafikk',
    gsm: 'GSM',
    allStandard: 'Øyeblikksbilde',
    chooseInterface: 'Velg interface',
    chooseStatistics: 'Velg statistikk',
    show: 'Vis',
    for: 'for',
    inPeriod: 'i perioden',
  },
  StandardChart: {
    capacity: 'Kapasitet',
    capacityIn: 'Kapasitet inn',
    capacityOut: 'Kapasitet ut',
  },
  Granularity: {
    raw: 'per 5 minutter',
    hour: 'per time',
    day: 'per dag',
  },
};

export default statistics;
