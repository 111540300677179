import NbNotifications from '../../nb/Dashboard/notifications';

const notifications: typeof NbNotifications = {
  NotificationBell: {
    btnSeeAll: 'See all notifications',
    notificationPreferences: 'Notification preferences',
    noUnreadNotifications: 'You have no unread notifications!',
    btnDropdown: 'Notifications',
  },
  incidentCreate: {
    label: 'Incident',
    description: 'New incident created',
  },
  incidentStatus: {
    label: 'Incident',
    description: 'Incident has changed status',
  },
  incidentComment: {
    label: 'Incident',
    description: 'New message',
  },
  incidentChange: {
    label: 'Incident',
    description: 'Incident is updated',
  },
  order: {
    label: 'Order',
    description: '',
  },
  plannedWork: {
    label: 'Planned work',
    description: 'There is planned work affecting one of your services',
  },
  default: {
    label: 'Notification',
    description: 'There is planned work that will affect some of your services',
  },
  id: 'ID',
  NotificationPage: {
    title: 'Notifications',
    showXofY: (showing: number, total?: number) =>
      `Showing ${showing} of ${total} notifications`,
    noNotifications: 'No notifications',

    incident: 'Incident',
    order: 'Order',
    subscription: 'Service',
    type: 'Type',
    status: 'Status',
    filterLabels: {
      statusChange: 'Changed status',
      newComment: 'New message',
      created: 'Created',
      read: 'Read',
      unread: 'Not read',
      updated: 'Incident updated',
    },
  },
};

export default notifications;
