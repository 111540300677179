import React from 'react';
import { colors } from 'src/lib/ui';
import { DashboardContactCard } from 'src/areas/main/dashboard/DashboardContactCard';
import { t, variables } from 'src/lib/i18n';

export default function MobileFooter() {
  return (
    <div
      className="container text-center mt-5"
      style={{
        borderTop: `1px solid ${colors.grey}`,
        padding: '2rem 0',
      }}
    >
      <DashboardContactCard
        label={t.dashboard.supportContactLabel}
        text={t.dashboard.supportContactDetails}
        email={variables.customerSupportEmail}
        phoneNumber={variables.customerSupportNumber}
      />
    </div>
  );
}
