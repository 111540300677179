import { IpRouting } from './../../../../../../lib/types/subscription/IpRouting';
import { LanIp } from './../../../../../../lib/types/subscription/Cpe';

const ipRegexString = '^([0-9]{1,3}[.]){0,3}[0-9]{0,3}$';
const subnetMaskRegexString = '^[/]?[0-9]{0,2}$';

export const ipLanInputConstraints: {
  [key in keyof Omit<LanIp, 'id'>]: string | undefined;
} = {
  hsrpIpAddress: ipRegexString,
  networkAddress: ipRegexString,
  subnetMask: subnetMaskRegexString,
  interfaceId: undefined,
  interfaceMode: undefined,
};

export const ipRoutingInputConstraints: {
  [key in keyof Omit<IpRouting, 'id' | 'metricDistance' | 'noRedistribution'>]:
    | string
    | undefined;
} = {
  gateway: ipRegexString,
  networkAddress: ipRegexString,
  subnetMask: subnetMaskRegexString,
};
