import React, { useEffect } from 'react';
import { SectionProps } from '../BarringSettingsFormModal';
import { SettingsListItem } from '../../SettingsListItem';
import { Toggle } from '@telia/styleguide/business';
import { t } from 'src/lib/i18n';
import { colors } from 'src/lib/ui';

export function BarCpa(props: SectionProps) {
  const { state, toggleOption } = props;
  const i18n = t.mobile.settings.barring.barContentServices;

  useEffect(() => {
    if (state.cpaDisabled) {
      toggleOption({
        cpaAmount100: false,
        cpaAmount250: false,
        cpaAmount500: false,
        cpaGoodsAndServices: false,
      });
    }
  }, [state.cpaDisabled]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.cpaAmount500) {
      toggleOption({ cpaAmount100: false, cpaAmount250: false });
    }
  }, [state.cpaAmount500]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.cpaAmount250) {
      toggleOption({ cpaAmount100: false, cpaAmount500: false });
    }
  }, [state.cpaAmount250]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.cpaAmount100) {
      toggleOption({ cpaAmount250: false, cpaAmount500: false });
    }
  }, [state.cpaAmount100]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="pt-4 pb-2">{i18n.contentServices}</div>
      <div className="mx-3" style={{ fontSize: '0.875rem' }}>
        <div style={{ color: colors.greyDarkText }}>{i18n.description}</div>
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.cpa}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.cpaDisabled}
              onChange={() => toggleOption('cpaDisabled')}
            />
          }
        />

        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.cpaGoodsAndServices}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.cpaGoodsAndServices}
              disabled={state.cpaDisabled}
              onChange={() => toggleOption('cpaGoodsAndServices')}
            />
          }
        />

        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.cpa100}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.cpaAmount100}
              disabled={state.cpaDisabled}
              onChange={() => toggleOption('cpaAmount100')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.cpa250}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.cpaAmount250}
              disabled={state.cpaDisabled}
              onChange={() => toggleOption('cpaAmount250')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.cpa500}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.cpaAmount500}
              disabled={state.cpaDisabled}
              onChange={() => toggleOption('cpaAmount500')}
            />
          }
        />
      </div>
    </>
  );
}
