import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { useQuery } from 'react-apollo';
import * as t from './__types/useTechnicalInformation';
const SUBSCRIPTION_TECHNICAL_INFO = gql`
  query useTechnicalInformation($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        dmVpnTunnelInformation {
          ipAddress
          subnetMask
        }
        ipRouting {
          id
          gateway
          subnetMask
          metricDistance
          networkAddress
          noRedistribution
        }
        vrf {
          vpnAlias
          vpnId
          vrfName
          sagCode
        }
        ngfw {
          protectionPackage
          totalCapacity
          location
          binding
          httpsInspection
          idAwareness
          eventReports
          logApi
        }
        cpe {
          name
          description
          model
          status
          configuration {
            lan {
              id
              networkAddress
              subnetMask
              interfaceMode
              interfaceId
              hsrpIpAddress
            }
            wan {
              networkAddress
              subnetMask
            }
            managementIp
            type
          }
        }
      }
    }
  }
`;

export const useTechnicalInformation = (subscriptionId: string) => {
  const customer = useCustomer();

  const { data, loading, error } = useQuery<
    t.useTechnicalInformation,
    t.useTechnicalInformationVariables
  >(SUBSCRIPTION_TECHNICAL_INFO, {
    variables: {
      customerId: customer.id,
      subscriptionId,
    },
    errorPolicy: 'all',
  });
  let subscription = data?.customer?.subscriptions?.[0];

  return { data: subscription, loading, error };
};
