import * as React from 'react';
import { CostCardHeader, CostCategory, WithInvoiceLines } from '..';
import { Card, Button, FormattedNumber, Spinner } from 'src/lib/ui';
import InvoiceLineTable from './InvoiceLineTable';
import './InvoiceLineGroup.scss';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

interface Props {
  cat: CostCategory;
  total: number;
  invoice: string;
}

interface State {
  currentSort: string;
  currentSortOrder: 'asc' | 'desc';
}

class InvoiceLineGroup extends React.Component<Props, State> {
  state: State = {
    currentSort: 'productDesignDescription',
    currentSortOrder: 'asc',
  };

  render() {
    const { cat, invoice } = this.props;
    const i18n = t.costs.InvoiceLineGroup;
    return (
      <Card className="InvoiceLineGroup p-0">
        <CostCardHeader>
          <h2>
            <Icon icon={cat.icon} className="pr-2" />
            {cat.label}
          </h2>
          {/* <div>
            <ExportButton type="invoice" format="pdf" invoiceId={invoice}>
              {i18n.download}
            </ExportButton>
          </div> */}
        </CostCardHeader>
        <WithInvoiceLines
          invoiceId={invoice}
          productGroup={cat.key as any}
          size={10}
        >
          {data => {
            if (data.loading && !data.lines.length) {
              return (
                <div className="d-flex justify-content-center py-4">
                  <Spinner />
                </div>
              );
            }
            if (data.lines && !!data.lines.length) {
              return (
                <div>
                  <div style={{ backgroundColor: cat.color, color: 'white' }}>
                    <div className="d-flex justify-content-between px-4 py-2">
                      <div>{i18n.totalInclVat}</div>
                      <div>
                        <FormattedNumber value={this.props.total} />
                        &nbsp;NOK
                      </div>
                    </div>
                  </div>
                  <InvoiceLineTable
                    cols={[
                      i18n.table.subscription,
                      i18n.table.period,
                      i18n.table.netCost,
                      i18n.table.costInclVat,
                    ]}
                    colAlign={['left', 'left', 'right', 'right']}
                  >
                    {data.lines.map((line, i) => (
                      <tr key={i}>
                        <td>{line.productDesignDescription}</td>
                        <td>{`${line.periodStart} - ${line.periodEnd}`}</td>
                        <td style={{ textAlign: 'right' }}>
                          <FormattedNumber value={line.amountNet} />
                        </td>
                        <td style={{ textAlign: 'right' }}>
                          <FormattedNumber value={line.amountGross} />
                        </td>
                      </tr>
                    ))}
                  </InvoiceLineTable>
                  {!!data.lines.length && (
                    <div className="InvoiceLineGroup-resultText">
                      {i18n.showInvoiceLines(
                        data.lines.length,
                        data.totalResults
                      )}
                    </div>
                  )}
                  {data.isMore && (
                    <div className="d-flex justify-content-center InvoiceLineGroup-loadMore">
                      <Button
                        color="white"
                        rounded={true}
                        outline={true}
                        onClick={data.loadMore}
                      >
                        {i18n.showMore}
                      </Button>
                    </div>
                  )}
                </div>
              );
            }
            return (
              <div className="InvoiceLineGroup-ErrorMessage py-4">
                {i18n.error}
              </div>
            );
          }}
        </WithInvoiceLines>
      </Card>
    );
  }
}
/*

<InvoiceLineTable
  cols={['tjeneste', 'periode', 'sum netto', 'sum inkl. mva']}
  colAlign={['left', 'left', 'right', 'right']}
  colSortKeys={[
    'productDesignDescription',
    'periodStart',
    'amountNet',
    'amountGross',
  ]}
  currentSort={this.state.currentSort}
  currentSortOrder={this.state.currentSortOrder}
  setSort={this.setSort}
>

*/

export default InvoiceLineGroup;
