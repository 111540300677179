import React, { useState, useEffect } from 'react';
import { useApolloClient } from 'react-apollo';
import { useAppContext } from 'src/lib/global';
import {
  GatewayError,
  mapStatusToDocumentError,
} from 'src/areas/main/documents/utils/errorMapper';
import { useDocumentsContext } from 'src/areas/main/documents/DocumentsContext';
import axios from 'axios';
import { setUploadFinishedInCache } from 'src/areas/main/documents/fileUpload/utils';
import { usePath } from 'src/areas/main/documents/contexts/PathContext';
import { toast } from 'react-toastify';
import { FileToast } from 'src/lib/ui';
import { trackDocument } from 'src/lib/analytics';

export const useFileUpload = () => {
  const path = usePath();
  const [files, setFiles] = useState<File[]>([]);
  const [progress, setProgress] = useState<number | undefined>(undefined);
  const [uploadError, setUploadError] = useState<GatewayError | undefined>(
    undefined
  );
  const [loading, setLoading] = useState<boolean>(false);
  const apolloClient = useApolloClient();
  const { customerId } = useDocumentsContext();
  const { auth } = useAppContext();

  const {
    subscriptionId,
    orderId,
    refetchFileStructure,
    setDocumentsInternalOnUpload,
    category,
  } = useDocumentsContext();

  const handleSelectedFiles = (newFiles: File[]) => {
    setFiles(newFiles);
    setLoading(true);
  };
  const uploadFiles = async () => {
    await Promise.all(files.map(file => uploadBlob(file)));
  };

  useEffect(() => {
    if (loading) {
      uploadFiles().finally(() => {
        setLoading(false);
        setFiles([]);
      });
    }
  }, [loading]); // eslint-disable-line react-hooks/exhaustive-deps

  const uploadBlob = (file: File) => {
    trackDocument(category, 'upload_document');
    const formData = new FormData();
    formData.append('file', file, file.name);

    const toastId = toast(
      <FileToast
        loading={loading}
        error={!!uploadError}
        fileName={file.name}
      />,
      {
        position: toast.POSITION.BOTTOM_CENTER,
        progress,
        className: 'FileToast',
        bodyClassName: 'FileToast-body',
        progressClassName: 'FileToast-progress FileToast-progress loading',
      }
    );

    const config = {
      onUploadProgress: progressEvent => {
        const percentCompleted = Math.round(
          (progressEvent.loaded * 100) / progressEvent.total
        );
        setProgress(percentCompleted);
        toast.update(toastId, {
          render: (
            <FileToast
              loading={loading}
              fileName={file.name}
              error={!!uploadError}
            />
          ),
          progress: percentCompleted / 100 - 0.0001,
          autoClose: false,
          progressClassName: 'FileToast-progress loading',
        });
      },
      headers: {
        Authorization: `Bearer ${auth.bearerToken}`,
        'Content-Type': file.type,
        'content-type': 'multipart/form-data',
      },
    };

    const url = subscriptionId
      ? `/api/files/documents/subscriptions/${encodeURIComponent(
          subscriptionId
        )}?folderId=${encodeURIComponent(
          path.folder.id
        )}&internal=${encodeURIComponent(setDocumentsInternalOnUpload)}`
      : orderId
      ? `/api/files/documents/orders/${encodeURIComponent(
          orderId
        )}?folderId=${encodeURIComponent(
          path.folder.id
        )}&internal=${encodeURIComponent(setDocumentsInternalOnUpload)}`
      : `/api/files/documents/${encodeURIComponent(
          path.folder.id
        )}?internal=${encodeURIComponent(setDocumentsInternalOnUpload)}`;

    /*
     * QA/PROD: Requests is proxied through the Portal-server
     * DEV: Proxied through webpack-dev-server proxy cfg in start.js
     */
    return axios
      .post(url, formData, config)
      .then(r => {
        setTimeout(() => toast.dismiss(toastId), 5000);
        setUploadFinishedInCache(
          r.data.id,
          apolloClient,
          customerId,
          subscriptionId,
          orderId
        );
        if (refetchFileStructure) {
          refetchFileStructure();
        }
        toast.update(toastId, {
          render: (
            <FileToast
              loading={false}
              fileName={file.name}
              error={!!uploadError}
            />
          ),
          progress: 0.99999,
          progressClassName: 'FileToast-progress success',
        });
      })
      .catch(err => {
        console.log('Error', err);
        setTimeout(() => toast.dismiss(toastId), 5000);
        setUploadError(
          mapStatusToDocumentError(err.response && err.response.status)
        );
        toast.update(toastId, {
          render: (
            <FileToast
              loading={false}
              fileName={file.name}
              error={
                !!mapStatusToDocumentError(err.response && err.response.status)
              }
            />
          ),
          progress: 0.99999,
          progressClassName: 'FileToast-progress error',
        });
      });
  };

  return { handleSelectedFiles, files, loading, uploadError, progress };
};
