import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';
import { Contact } from 'src/lib/types';
import { StatusBadge } from 'src/lib/ui/StatusBadge/StatusBadge';
import { t } from 'src/lib/i18n';
import { IconDefinition } from '@telia/styleguide';
import { userIsAdmin } from 'src/areas/main/contacts/lib/editContact/utils';

export const SearchListContactResult: React.FC<{ entry: Contact }> = ({
  entry,
}) => {
  const { firstName, lastName, email, mobilePhone, secondaryPhone } = entry;
  const icon: IconDefinition = entry.user
    ? userIsAdmin(entry.user)
      ? 'user-admin'
      : 'user'
    : 'contact';
  return (
    <ListResultCard
      linkTo={entry}
      className="ListResultCard--Contact"
      icon={icon}
      title={`${firstName} ${lastName}`}
      attributes={[
        email ? { value: email } : undefined,
        mobilePhone ? { value: mobilePhone } : undefined,
        secondaryPhone ? { value: secondaryPhone } : undefined,
      ]}
      badge={
        entry.user &&
        !entry.user.emailVerified && (
          <StatusBadge
            label={t.contacts.status.waitingActivation}
            color="warning"
          />
        )
      }
    />
  );
};
