import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useSubscriptionContext } from '../../lib/SubscriptionContext';
import { BarringMobileSettings, MobileSubscription } from 'src/lib/types';
import { WITH_MOBILE_SETTINGS } from 'src/areas/mobile/settings/useMobileSettings';
import * as t from './__types/useUpdateBarringSettings';
import { BarringSettingsFragment } from 'src/lib/types/mobile/fragments';

export const UPDATE_BARRING_SETTINGS = gql`
  mutation useUpdateBarringSettings($input: UpdateBarringMobileSettingsInput) {
    updateBarringMobileSettings(input: $input) {
      msisdn
      barring {
        ...BarringSettingsFragment
      }
      error {
        code
        message
      }
    }
  }
  ${BarringSettingsFragment}
`;

export interface UpdateBarringSettingsResult {
  loading: boolean;
  error: boolean;
  data?: t.useUpdateBarringSettings_updateBarringMobileSettings_barring | null;
  updateSettings: (settings: BarringMobileSettings) => void;
}

export const useUpdateBarringSettings = (): UpdateBarringSettingsResult => {
  const subscription = useSubscriptionContext();
  const [mutation, result] = useMutation<
    t.useUpdateBarringSettings,
    t.useUpdateBarringSettingsVariables
  >(UPDATE_BARRING_SETTINGS);

  const updateSettings = async (settings: BarringMobileSettings) =>
    await mutation({
      variables: {
        input: {
          msisdn: subscription.id,
          ...settings,
        },
      },
      update: (cache, { data }) => {
        if (data && data.updateBarringMobileSettings) {
          const {
            updateBarringMobileSettings: { barring, error, msisdn },
          } = data;
          if (!error) {
            const cachedData: {
              subscription: MobileSubscription;
            } | null = cache.readQuery({
              query: WITH_MOBILE_SETTINGS,
              variables: {
                subscriptionId: msisdn,
              },
            });
            if (
              cachedData &&
              cachedData.subscription &&
              cachedData.subscription.settings
            ) {
              cache.writeQuery({
                query: WITH_MOBILE_SETTINGS,
                variables: {
                  subscriptionId: msisdn,
                },
                data: {
                  subscription: {
                    id: msisdn,
                    settings: {
                      ...cachedData.subscription.settings,
                      barring: barring,
                      __typename: 'MobileSettings',
                    },
                    __typename: 'MobileSubscription',
                  },
                },
              });
            }
          }
        }
      },
    });
  return {
    updateSettings,
    loading: result.loading,
    data: result.data?.updateBarringMobileSettings?.barring,
    error: !!result.error || !!result.data?.updateBarringMobileSettings?.error,
  };
};
