import React from 'react';
import { BodyCard, Attributes, Attribute } from 'src/lib/ui';
import { t, useI18nContext } from 'src/lib/i18n';
import { formatDate } from 'src/lib/utils';

interface TeleSubscriptionAboutProps {
  subscription?: {
    id: string;
    name?: string | null;
    phoneNumbers?: Array<{
      type?: number;
      phoneNumber?: string;
    }> | null;
    sla?: string | null;
    endTime?: any | null;
  };
}

export const TeleSubscriptionAbout = ({
  subscription,
}: TeleSubscriptionAboutProps) => {
  const i18nContext = useI18nContext();
  if (!subscription) {
    return null;
  }
  const i18n = t.subscriptions.TeleSubscriptionAbout;

  return (
    <BodyCard heading={i18n.heading}>
      <Attributes>
        <Attribute label={i18n.type} value={subscription.name} />
        <Attribute label={i18n.id} value={subscription.id} />
        <Attribute
          label={i18n.endTime}
          value={
            subscription.endTime
              ? formatDate(subscription.endTime, 'long', i18nContext.locale)
              : i18n.noEndTime
          }
        />
        {subscription.sla ? (
          <Attribute label={i18n.sla} value={subscription.sla} />
        ) : null}
      </Attributes>
    </BodyCard>
  );
};
