import * as React from 'react';
import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import './HeadingPicker.scss';

import { find } from 'lodash';
import { Icon } from '@telia/styleguide';

interface Props {
  options: Array<{ key: string; label: string }>;
  selectedOption?: string | { key: string; label: string };
  setOption: (option: { key: string; label: string }) => void;
  prefix?: string;
}

export const HeadingPicker: React.SFC<Props> = props => {
  const selectedOption =
    typeof props.selectedOption === 'string'
      ? find(props.options, o => o.key === props.selectedOption)
      : props.selectedOption;

  const selectedLabel = selectedOption
    ? props.prefix
      ? selectedOption.label
      : selectedOption.label.replace(/^./, str => str.toUpperCase())
    : undefined;

  return (
    <h3 className="HeadingPicker">
      <span className="HeadingPicker-prefix"> {props.prefix} </span>
      <div className="HeadingPicker-menu-wrapper">
        <UncontrolledDropdown tabIndex={0}>
          <DropdownToggle tag="span" className="HeadingPicker-toggle">
            {selectedLabel}
            <Icon icon="arrow-small-down" />
          </DropdownToggle>
          <DropdownMenu>
            {props.options.map(o => (
              <DropdownItem onClick={() => props.setOption(o)} key={o.key}>
                {o.label}
              </DropdownItem>
            ))}
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    </h3>
  );
};
