import * as React from 'react';
import { Invoice } from 'src/lib/types';
import { WithSearch } from 'src/areas/main/search';
import { Loading, Button, FilterToggles } from 'src/lib/ui';

import {
  getFirstDayOfPeriod,
  getLastDayOfPeriod,
} from 'src/lib/utils/dateUtils';
import { RouteComponentProps } from 'react-router';

import { OrganisationPage } from './lib/OrganisationPage';
import { t } from 'src/lib/i18n';
import { FilterTogglesValue } from 'src/lib/ui/FilterToggles/FilterToggles';
import { InvoicesTable } from 'src/areas/main/invoices/components/InvoicesTable';
import { SortOrder } from 'src/__types/graphql-global-types';

export const OrganisationInvoices: React.FC<RouteComponentProps<{
  organisationId: string;
}>> = props => (
  <OrganisationPage organisationId={props.match.params.organisationId}>
    {(costContext, organisation) => {
      const organisationFilter = costContext.recursive
        ? [organisation.id, 'recursive']
        : [organisation.id];

      const dateFilter = [
        getFirstDayOfPeriod(costContext.activePeriod || ''),
        getLastDayOfPeriod(costContext.activePeriod || ''),
      ];

      const i18n = t.costs.OrganisationInvoices;
      // invoiceStatus
      const filters: FilterTogglesValue[] = [
        {
          value: 'overdue',
          label: i18n.filters.overdue,
        },
        // { value: 'disputed', label: 'Bestridt' },
        {
          value: 'unpaid',
          label: i18n.filters.unpaid,
        },
        // { value: 'creditNote', label: 'Kreditnota' },
        // { value: 'partiallyPaid', label: 'Delvis betalt' },
        {
          value: 'paid',
          label: i18n.filters.paid,
        },
      ];

      return (
        <WithSearch
          initial={{
            types: ['invoice'],
            filter: [
              {
                filter: 'organisation',
                value: organisationFilter,
              },
              {
                filter: 'invoiceDate',
                value: dateFilter,
              },
            ],
            sortBy: 'dueDate',
            sortOrder: 'desc',
          }}
        >
          {search => {
            return (
              <div className="container">
                <FilterToggles
                  allowMultiple={false}
                  options={filters}
                  currentFilter={
                    search.getFilterValue('invoiceStatus').length > 0
                      ? [search.getFilterValue('invoiceStatus')[0]]
                      : undefined
                  }
                  setCurrentFilter={cf =>
                    search.setSingleFilter('invoiceStatus', cf)
                  }
                />

                {/* <ExportButton
                          type="invoices"
                          format="xlsx"
                          organisationId={organisation.id}
                          recursive={costContext.recursive}
                          invoiceDateStart={startDate}
                          invoiceDateEnd={endDate}
                        >
                          Last ned
                        </ExportButton> */}

                <InvoicesTable
                  setSort={search.setSort}
                  sortBy={search.sortBy}
                  sortOrder={search.sortOrder as SortOrder | undefined}
                  invoices={search.results as Invoice[]}
                  loading={search.loading}
                  noResult={Boolean(search.results.length === 0)}
                  noResultMessage={t.costs.ui.InvoiceTable.noInvoices}
                />

                {search.results.length > 0 ? (
                  <div
                    style={{
                      borderTop: '1px solid #dee2e6',
                      fontSize: '16px',
                    }}
                    className="pl-4 pb-2 pt-2"
                  >
                    Viser {search.results.length} av {search.totalResults}
                  </div>
                ) : null}

                <div className="pt-4 pb-4">
                  {search.loading ? (
                    <Loading />
                  ) : (
                    search.isMore && (
                      <div style={{ textAlign: 'center' }}>
                        <Button
                          base="primary"
                          color="purple"
                          onClick={() => search.loadMore()}
                        >
                          {i18n.showMoreBtn}
                        </Button>
                      </div>
                    )
                  )}
                </div>
              </div>
            );
          }}
        </WithSearch>
      );
    }}
  </OrganisationPage>
);
