import { DatePickerField, DatePickerFieldProps } from 'src/lib/ui';
import { Field, FieldProps } from 'formik';

import _get from 'lodash/get';

import * as React from 'react';

type FormikDatePickerProps = Omit<DatePickerFieldProps, 'value'>;

const FormikDatePicker: React.FunctionComponent<FormikDatePickerProps> = props => {
  return (
    <Field name={props.id}>
      {formikProps => {
        const { field, form } = formikProps as FieldProps;

        const hasError =
          Boolean(_get(form.touched, field.name)) &&
          Boolean(_get(form.errors, field.name));

        if (props.showPicker === true) {
          return (
            <DatePickerField
              {...props}
              showPicker={true}
              pickerOnly={props.showPicker}
              value={field.value || ''}
              onChange={dateValue => {
                form.setFieldValue(field.name, dateValue);
                if (props.onChange) {
                  props.onChange(dateValue);
                }
              }}
              onBlur={() => {
                form.setFieldTouched(field.name, true, false);
                if (props.onBlur) {
                  props.onBlur();
                }
              }}
              onFocus={() => {
                if (props.onFocus) {
                  props.onFocus();
                }
              }}
              error={
                hasError ? (_get(form.errors, field.name) as string) : undefined
              }
            />
          );
        }

        return (
          <DatePickerField
            {...props}
            showPicker={false}
            value={field.value || ''}
            onChange={dateValue => {
              form.setFieldValue(field.name, dateValue);
              if (props.onChange) {
                props.onChange(dateValue);
              }
            }}
            onBlur={() => {
              form.setFieldTouched(field.name, true);
              if (props.onBlur) {
                props.onBlur();
              }
            }}
            onFocus={() => {
              if (props.onFocus) {
                props.onFocus();
              }
            }}
            error={
              hasError ? (_get(form.errors, field.name) as string) : undefined
            }
          />
        );
      }}
    </Field>
  );
};

export default FormikDatePicker;
