import * as React from 'react';
import { Query, useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import { Period } from 'src/lib/types';
import { Organisation } from '../types';
import { sortBy, map } from 'lodash';
import {
  WithCustomerContext,
  CustomerContext,
  useCustomer,
} from 'src/lib/global';
import { organiseOrganisations } from '../utils/organiseOrganisations';
import { ApolloError } from 'apollo-client';

const query = gql`
  query WithCustomerOrganisations($customerId: ID!) {
    customer(id: $customerId) {
      id
      rootOrganisation {
        id
        name
        childrenOrganisations {
          id
        }
      }
      costPeriods {
        invoicePeriods
        usagePeriods
      }
      organisations {
        id
        name
        parentId
        children
        numberOfDescendants
      }
    }
  }
`;

interface Data {
  customer?: {
    id: string;
    rootOrganisation: {
      id: string;
      name: string;
      childrenOrganisations: {
        id: string;
      }[];
    };
    costPeriods: {
      invoicePeriods: Period[];
      usagePeriods: Period[];
    };
    organisations: Organisation[];
  };
}

interface Variables {
  customerId: string;
}

interface Props {
  children: (res: CustomerOrganisationsDataWrapper) => JSX.Element | null;
}

export interface CustomerOrganisationsDataWrapper {
  loading: boolean;
  customerContext: CustomerContext;
  customer?: CustomerOrganisationsData;
}

export interface CustomerOrganisationsData {
  costPeriods: {
    invoicePeriods: Period[];
    usagePeriods: Period[];
  };
  allOrganisations: Organisation[];
  rootOrganisation: Organisation;
}

export interface CustomerOrganisationsResult {
  customer?: CustomerOrganisationsData;
  loading: boolean;
  error?: ApolloError;
}

export const useCustomerOrganisations = () => {
  const customerContext = useCustomer();
  const { data, loading, error } = useQuery<Data, Variables>(query, {
    variables: {
      customerId: customerContext.id,
    },
  });

  // Mapping the root organisation from the backend Organisation type to the frontend definition
  const root =
    data && data.customer && data.customer.rootOrganisation
      ? {
          id: data.customer.rootOrganisation.id,
          name: data.customer.rootOrganisation.name,
          parentId: null,
          children: map(
            data.customer.rootOrganisation.childrenOrganisations,
            (child: { id: string }) => child.id
          ),
          numberOfDescendants: data.customer.rootOrganisation
            .childrenOrganisations
            ? data.customer.rootOrganisation.childrenOrganisations.length
            : 0,
          childOrganisations: [] as Organisation[],
        }
      : undefined;

  const orgMap =
    data && data.customer
      ? organiseOrganisations(data.customer.organisations, root)
      : undefined;
  if (orgMap && !orgMap.rootOrganisation) {
    throw new Error('Could not find root organisation');
  }
  const customer = {
    costPeriods: data
      ? data.customer
        ? data.customer.costPeriods
        : undefined
      : undefined,
    allOrganisations: sortBy(
      data
        ? data.customer
          ? data.customer.organisations
          : undefined
        : undefined,
      o => `${o.id} ${o.name}`
    ),
    rootOrganisation: orgMap ? orgMap.rootOrganisation : undefined,
  };
  return { customer, loading, error } as CustomerOrganisationsResult;
};

export const WithCustomerOrganisations: React.FC<Props> = props => (
  <WithCustomerContext>
    {customerContext => (
      <Query<Data, Variables>
        query={query}
        variables={{ customerId: customerContext.id }}
      >
        {res => {
          if (res.loading) {
            return props.children({
              loading: true,
              customerContext,
            });
          }

          if (!res.data || !res.data.customer) {
            return props.children({
              loading: false,
              customerContext,
            });
          }

          // Mapping the root organisation from the backend Organisation type to the frontend definition
          const root =
            res.data && res.data.customer && res.data.customer.rootOrganisation
              ? {
                  id: res.data.customer.rootOrganisation.id,
                  name: res.data.customer.rootOrganisation.name,
                  parentId: null,
                  children: map(
                    res.data.customer.rootOrganisation.childrenOrganisations,
                    (child: { id: string }) => child.id
                  ),
                  numberOfDescendants: res.data.customer.rootOrganisation
                    .childrenOrganisations
                    ? (res.data.customer.rootOrganisation
                        .childrenOrganisations as any[]).length
                    : 0,
                  childOrganisations: [] as Organisation[],
                }
              : undefined;

          const orgMap = organiseOrganisations(
            res.data.customer.organisations,
            root
          );

          if (!orgMap.rootOrganisation) {
            throw new Error('Could not find root organisation');
          }

          return props.children({
            loading: res.loading,
            customerContext: customerContext,
            customer: {
              costPeriods: res.data.customer.costPeriods,
              allOrganisations: sortBy(
                res.data.customer.organisations,
                o => `${o.id} ${o.name}`
              ),
              rootOrganisation: orgMap.rootOrganisation,
            },
          });
        }}
      </Query>
    )}
  </WithCustomerContext>
);
