import * as React from 'react';
import cs from 'classnames';

import { Props as AttributeProps } from '../Attribute/Attribute';

import './Attributes.scss';

type AcceptableChildren =
  | ''
  | undefined
  | null
  | React.ReactElement<AttributeProps>;

interface Props {
  inline?: boolean;
  className?: string;
  children: Array<AcceptableChildren>;
}

export const Attributes: React.FC<Props> = props => (
  <ul
    className={cs(
      'Attributes',
      props.className,
      props.inline ? 'Attributes--inline' : undefined
    )}
  >
    {React.Children.map(props.children, (child: AcceptableChildren) =>
      child ? React.cloneElement(child, { renderas: 'li' }) : null
    )}
  </ul>
);
