import React from 'react';
import { Alert } from 'src/lib/ui';
import { getIncidentStatusLabel } from 'src/lib/types/incident/getIncidentStatusLabel';
import { IncidentStatus } from 'src/__types/graphql-global-types';
import { formatDate } from 'src/lib/utils';
import { t, useI18nContext } from 'src/lib/i18n';

interface Props {
  incident: {
    id: string;
    incidentStatus?: IncidentStatus | null;
    shortDescription?: string | null;
    opened?: string | null;
  };
}

export const SubscriptionActiveIncidentCard: React.FunctionComponent<Props> = ({
  incident,
}) => {
  const i18n = t.subscriptions.SubscriptionActiveIncidentCard;
  const { locale } = useI18nContext();
  const statusLabel = getIncidentStatusLabel(incident.incidentStatus);
  const headerText = i18n.status(
    `${statusLabel.charAt(0).toLowerCase()}${statusLabel.slice(1)}`
  );

  return (
    <Alert
      status={
        incident.incidentStatus === IncidentStatus.onHoldPendingCustomer
          ? 'warning'
          : 'default'
      }
      icon="email"
      header={headerText}
      subHeader={incident.id}
      linkTo={{ incident: incident.id }}
    >
      <div>{incident.shortDescription}</div>
      {incident.opened ? (
        <small>
          {i18n.opened(formatDate(incident.opened, 'long', locale))}
        </small>
      ) : null}
    </Alert>
  );
};
