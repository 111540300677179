import React from 'react';
import { TextArea, Spinner } from 'src/lib/ui';
import './ChatInput.scss';
import cs from 'classnames';
import { t } from 'src/lib/i18n';
import { useChatContext } from './ChatContext';
import { Button } from 'src/lib/ui/Button/Button';
import { EmailForm } from 'src/areas/worklog/WorklogChat/EmailForm';
import { Icon } from '@telia/styleguide';

export const ChatInput = () => {
  const {
    selectedMessages,
    setSelectedMessages,
    emailSubject,
    setEmailSubject,
    ...chatContext
  } = useChatContext();

  return (
    <form className="ChatInput" onSubmit={chatContext.handlers.handleSend}>
      {selectedMessages.length ? (
        <div className="ChatInput-sendEmail d-flex py-2">
          <Button
            onClick={() => setSelectedMessages([])}
            size="small"
            type="button"
          >
            {t.worklogOrder.chat.sendEmail.clearSelection}
          </Button>
          <EmailForm
            messages={selectedMessages}
            setMessages={setSelectedMessages}
            subject={emailSubject ?? ''}
            setSubject={setEmailSubject}
          />
        </div>
      ) : null}
      <div className="ChatInput-wrapper d-flex flex-column w-100">
        <TextArea
          className={cs('ChatInput-textArea', {
            'animate-height': chatContext.expandInputOnFocus,
          })}
          placeholder={
            chatContext.placeholder || t.incidents.ChatInput.placeHolder
          }
          onChange={chatContext.handlers.handleInputChange}
          value={chatContext.chatInput}
        />
        {'files' in chatContext && chatContext.files.length > 0 ? (
          <div className="ChatInput-filesView">
            {chatContext.files.map((f, i) => (
              <div className="pl-2" key={i}>
                <div
                  className={`ChatInput-fileBox
              ${chatContext.isUploadingFiles &&
                'ChatInput-fileBox--uploading'}`}
                >
                  <div className="pr-3">{f.name}</div>
                  <div>
                    <span
                      role="button"
                      tabIndex={0}
                      onClick={() =>
                        chatContext.handlers.handleRemoveFile(f.name)
                      }
                    >
                      <Icon icon="close" />
                    </span>
                  </div>
                </div>
              </div>
            ))}
            <span className="pl-2">
              {chatContext.isUploadingFiles && <Spinner size="xs" />}
            </span>
          </div>
        ) : null}

        <div className="ChatInput-btnGroup">
          {chatContext.inputActions && (
            <div>{chatContext.inputActions.map(action => action)}</div>
          )}
          <div className="ml-auto">
            {'files' in chatContext && (
              <>
                <label
                  className="ChatInput-fileLabel pr-3"
                  htmlFor="ChatInput-fileInput"
                >
                  <Icon icon="attach" />
                </label>

                <input
                  type="file"
                  name="ChatInput-fileInput"
                  id="ChatInput-fileInput"
                  className="ChatInput-fileInput"
                  onChange={chatContext.handlers.handleFileInputChange}
                />
              </>
            )}
            <div className="d-inline-flex align-items-center">
              {chatContext.loadingSend && (
                <div className="pr-2">
                  <Spinner size="xs" />
                </div>
              )}
              {chatContext.handlers.clearInput && (
                <Button
                  className="mr-3"
                  transparent={true}
                  type="reset"
                  base="primary"
                  disabled={!chatContext.chatInput.length}
                  onClick={chatContext.handlers.clearInput}
                >
                  {t.incidents.ChatInput.btnClear}
                </Button>
              )}
              <Button
                base="primary"
                color="purple"
                disabled={
                  (chatContext.chatInput.length === 0 &&
                    'files' in chatContext &&
                    chatContext.files.length === 0) ||
                  chatContext.loadingSend
                }
              >
                {t.incidents.ChatInput.btnSend}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </form>
  );
};

export default ChatInput;
