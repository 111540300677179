import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useInterfaces';

export const GET_INTERFACES = gql`
  query useInterfaces($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        interfaces {
          alias
          name
          type
          status
          arpTable {
            ip
            mac
          }
          subscription {
            id
            alias
            name
            vrf {
              vpnAlias
              vpnId
              vrfName
              sagCode
            }
          }
          operationalState
        }
      }
    }
  }
`;

export interface InterfaceData {
  loading: boolean;
  error?: ApolloError | null;
  interfaces?: t.useInterfaces_customer_subscriptions_interfaces[] | null;
  refetch: () => void;
}

export const useInterfaces = (
  subscriptionId?: string,
  fetchNetwork?: boolean
) => {
  const customer = useCustomer();
  const { data, loading, error, refetch } = useQuery<
    t.useInterfaces,
    t.useInterfacesVariables
  >(GET_INTERFACES, {
    variables: {
      customerId: customer.id,
      subscriptionId: subscriptionId || '', // Because of the `skip` setting below, this query won't be sent if subscriptionId is falsy.
    },
    errorPolicy: 'all',
    fetchPolicy: fetchNetwork ? 'network-only' : 'cache-first',
    notifyOnNetworkStatusChange: true,
    skip: !subscriptionId,
  });

  const result: InterfaceData = {
    loading,
    error,
    interfaces:
      data &&
      data.customer &&
      data.customer.subscriptions &&
      data.customer.subscriptions.length >= 1 &&
      data.customer.subscriptions[0]
        ? data.customer.subscriptions[0].interfaces
        : undefined,
    refetch,
  };
  return result;
};
