import { Checkbox, CheckboxProps } from 'src/lib/ui';
import { Field, FieldProps } from 'formik';
import * as React from 'react';

type FormikCheckboxProps = Omit<CheckboxProps, 'checked'>;

const FormikCheckbox: React.FunctionComponent<FormikCheckboxProps> = props => {
  return (
    <Field name={props.id}>
      {formikProps => {
        const { field, form } = formikProps as FieldProps;
        return (
          <Checkbox
            {...props}
            checked={Boolean(field.value)}
            onChange={e => {
              // https://github.com/jaredpalmer/formik/issues/1907
              // getValueForCheckbox will crash the app. Unsure if it is the synthetic event of the input-field or the function fault.
              // Set value explicitly to make it stable and predictable.
              form.setFieldValue(field.name, e.target.checked);
              if (props.onChange) {
                props.onChange(e);
              }
            }}
          />
        );
      }}
    </Field>
  );
};

export default FormikCheckbox;
