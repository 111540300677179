import React from 'react';
import { BodyCard, NoResult } from 'src/lib/ui';
import { WrenchIcon } from '@telia/styleguide';
import { usePersonalizedPlannedWork } from './queries/usePersonalizedPlannedWork';
import { PersonalizedDashboardPlannedWorkItem } from './PersonalizedDashboardPlannedWorkItem';
import { t } from 'src/lib/i18n';

import './PersonalizedPlannedWork.scss';

export const PersonalizedPlannedWork = () => {
  const pw = usePersonalizedPlannedWork();

  return (
    <BodyCard noGutter={true} className="PersonalizedPlannedWork">
      <div className="Table-container">
        {pw.plannedWork?.length ? (
          <table className="Table" style={{ minWidth: '0' }}>
            <tbody>
              {pw.plannedWork?.map(p => (
                <PersonalizedDashboardPlannedWorkItem
                  key={p.id}
                  plannedWork={p}
                />
              ))}
            </tbody>
          </table>
        ) : (
          <NoResult>
            <WrenchIcon
              style={{ width: '50px', height: '50px', marginBottom: '10px' }}
            />
            <div>{t.dashboard.plannedWork.noPlannedWork}</div>
          </NoResult>
        )}
      </div>
    </BodyCard>
  );
};
