import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { Formik, FormikProps, FormikTouched } from 'formik';
import * as T from './types';
import { StepFlowCard } from 'src/lib/ui/StepFlowSummaryCard/StepFlowCard';

export interface StepFlowProps<TFlowState> {
  /**
   * For change from and partially filled foorms feed
   * the current values through initialValues
   */
  initialValues?: TFlowState;
  initialTouched?: FormikTouched<TFlowState>;
  initialStep?: number;
  initialChangeSequence?: number;
  /**
   * For conditional forms that render sub step-flow
   */
  startIndex?: number;
  /**
   * Whether to display the next/submit button on the last step
   * used if no custom submit component is provided in the footer
   */
  hideSubmitOnLastStep?: boolean;
  header?: React.FunctionComponent<{ flow: T.Flow<TFlowState> }>;
  footer?: React.FunctionComponent<{ flow: T.Flow<TFlowState> }>;
  steps: Array<T.Step<TFlowState>>;
  /**
   * Whether to collapse completed steps and show summary
   */
  collapse?: boolean;

  /**
   * Extra function to call on next step
   */
  onNextStep?: (values: TFlowState) => void;
  onSubmit: (values: TFlowState, actions: any) => void;
  persistValues?: (
    values: TFlowState,
    currentStep: number,
    touched: FormikTouched<TFlowState>
  ) => Promise<void>;
  removePersistedValues?: () => Promise<void>;
}
type InnerProps<TFlowState> = {
  activeStep?: T.StepShape<TFlowState>;
  changeSequence: number;
  currentStep: number;
  setCurrentStep: (numb: number) => void;
  activeElement: (node: HTMLDivElement) => void; // React.RefObject<HTMLDivElement>;
  next: (values: TFlowState, touched: FormikTouched<TFlowState>) => void;
};

function InnerStepFlow<TFlowState = {}>(
  props: StepFlowProps<TFlowState> &
    FormikProps<TFlowState> &
    InnerProps<TFlowState>
) {
  const {
    values,
    touched,
    activeElement,
    handleSubmit,
    activeStep,
    initialChangeSequence,
    changeSequence,
    currentStep,
    setCurrentStep,
    isSubmitting,
    setFieldValue,
    isValid,
    validateForm,
    steps,
    setErrors,
    next,
  } = props;

  /**
   * We want to wait for changes on activeStep before validating form to ensure that we get the latest validationSchema
   */
  useEffect(() => {
    if (validateForm && activeStep) {
      validateForm();
    }
  }, [validateForm, activeStep]);

  const setStep = async (step: number) => {
    setErrors({});
    setCurrentStep(Math.min(step, steps.length));
  };

  const onContinue = () => {
    next(values, touched);
  };

  // If the change sequence is provided and deifferent from the one stored
  // in current state, it means the stored values have changed from another
  // browser windows/tab.
  if (initialChangeSequence && changeSequence !== initialChangeSequence) {
    return (
      <div className="alert alert-danger pt-3">
        <p>
          <strong>
            Denne bestillingen har endret seg siden den ble åpnet.
          </strong>
        </p>

        <p>
          Er den samme bestillingen åpen i et annet vindu eller en annen fane i
          nettleseren?
        </p>
      </div>
    );
  }

  const isLastStep = currentStep === props.steps.length - 1;

  const flow: T.Flow<TFlowState> = {
    values,
    touched,
    submit: handleSubmit,
    steps: props.steps,
    currentStep,
    isLastStep,
    isFirstStep: currentStep === 0,
    isSubmitting,
    setFieldValue: (
      field: keyof TFlowState & string,
      value: any,
      validateField?: boolean
    ) =>
      // Allow to run validations, but default to false
      setFieldValue(field, value, validateField === true ? true : false),
    setStep,
    isValid,

    cancel: async () => {
      if (props.removePersistedValues) {
        await props.removePersistedValues();
      }
    },
    save: async () => {
      if (props.persistValues) {
        await props.persistValues(values, currentStep, touched);
      }
    },
  };

  const header = props.header
    ? React.createElement(props.header, { flow })
    : null;

  const footer = props.footer
    ? React.createElement(props.footer, { flow })
    : null;

  const activeForm = activeStep
    ? React.createElement(activeStep.renderForm, {
        flow,
      })
    : undefined;

  return (
    <form
      onSubmit={handleSubmit}
      // Must match the height of OrderingFooter
      style={{ paddingBottom: '60px' }}
    >
      {header}
      {/* <div
        style={{
          position: 'fixed',
          top: 0,
          left: 0,
          zIndex: 100000,
          height: '50vh',
          overflow: 'auto',
        }}
      >
        <Debug />
      </div> */}

      <div className="StepFlow">
        {props.steps.map((stepFunc, idx) => {
          const step = stepFunc();

          const summary = step.renderSummary
            ? React.createElement(step.renderSummary, {
                values: flow.values,
              })
            : undefined;

          const thisStepFunc = props.steps[idx];
          const thisStep = thisStepFunc ? thisStepFunc() : undefined;

          const form = thisStep
            ? React.createElement(thisStep.renderForm, {
                flow,
              })
            : undefined;

          return (
            <StepFlowCard
              key={idx}
              title={step.title}
              currentStep={currentStep}
              step={idx}
              form={currentStep === idx ? activeForm : form}
              summary={summary}
              isValid={isValid}
              isLastStep={isLastStep}
              onClick={setStep}
              activeElement={activeElement}
              hideContinue={props.hideSubmitOnLastStep}
              onContinue={onContinue}
            />
          );
        })}
      </div>
      {footer}
    </form>
  );
}

export function StepFlow<TFlowState = {}>(props: StepFlowProps<TFlowState>) {
  const [currentStep, setCurrentStep] = useState<number>(
    props.initialStep || 0
  );
  const [changeSequence, setChangeSequence] = useState<number>(
    props.initialChangeSequence || 0
  );
  const activeElement = useCallback(
    (node: HTMLDivElement) => {
      if (node !== null) {
        node.scrollIntoView();
      }
    },
    // ESLINT: Unsure if this can be removed. Must be tested to confirm.
    [currentStep] // eslint-disable-line react-hooks/exhaustive-deps
  );

  useEffect(() => {
    setCurrentStep(props.initialStep || 0);
  }, [props.initialStep]);

  const next = (values: TFlowState, touched: FormikTouched<TFlowState>) => {
    const nextStep = Math.min(currentStep + 1, props.steps.length);
    setCurrentStep(nextStep);
    setChangeSequence(changeSequence + 1);
    if (props.persistValues) {
      props.persistValues(values, nextStep, touched);
    }
    if (props.onNextStep) {
      props.onNextStep(values);
    }
  };

  const activeStep = useMemo(() => {
    const activeStepFunction = props.steps[currentStep];
    return activeStepFunction ? activeStepFunction() : undefined;
  }, [props.steps, currentStep]);

  // Use Formik instead of useFormik to avoid any-types when using withFormik.
  // Have more control of the types when using Formik and passing directly to InnerStepFlow.
  return (
    <Formik
      enableReinitialize={false}
      validationSchema={
        activeStep ? () => activeStep.validationSchema : undefined
      }
      validateOnMount={true}
      initialValues={props.initialValues || ({} as TFlowState)}
      initialTouched={props.initialTouched}
      onSubmit={(values, bag) => {
        if (props.removePersistedValues) {
          props.removePersistedValues();
        }
        props.onSubmit(values, bag);
      }}
    >
      {formikProps => {
        return (
          <InnerStepFlow
            {...props}
            {...formikProps}
            activeStep={activeStep}
            activeElement={activeElement}
            currentStep={currentStep}
            setCurrentStep={setCurrentStep}
            changeSequence={changeSequence}
            next={next}
            /**
             * isValid prop from Formik is behaving weird when initialValues is set to valid values (example loading a existing Flow from localStorage).
             * This is due to the logic in their "dirty"-computation that compares initialValues to current values.
             * For our usecase, as long as the errors-object is empty, we can assume that the form validated and error-free.
             * Since we remove errors and rerun validation every time we change step, we can trust that the errors object is always being correct.
             */
            isValid={Object.keys(formikProps.errors).length === 0}
          />
        );
      }}
    </Formik>
  );
}
export default StepFlow;
