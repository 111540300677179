import { push } from './Analytics';

export type MapActions = 'filter';

export function trackMapFilter(filter: string) {
  push({
    category: 'Map',
    action: 'filter',
    label: filter,
  });
}
