import React from 'react';
import { t } from 'src/lib/i18n';

const OrderError = (props: { failInfo?: string }) => {
  return (
    <div>
      <h1>{t.ordering.OrderIpVpn.OrderError.heading}</h1>

      {props.failInfo ? <p>{props.failInfo}</p> : null}
    </div>
  );
};

export default OrderError;
