import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global/CustomerContext';
import * as t from './__types/useNotificationSettings';

export const notificationSettingsQuery = gql`
  query useNotificationSettings($customerId: ID!) {
    me {
      id
      contact(customerId: $customerId) {
        id
      }
      notificationSettings(customerId: $customerId) {
        type
        active
        channels
        subscriptions {
          id
          alias
          name
          type
          group
          cpe {
            status
            model
          }
          installationAddress {
            zip
            address
            city
            location {
              lat
              lon
            }
          }
        }
        notifyForAllCustomerSubscriptions
      }
    }
  }
`;

export type NotificationSettings = t.useNotificationSettings_me_notificationSettings;

export const useNotificationSettings = () => {
  const customerContext = useCustomer();
  const res = useQuery<
    t.useNotificationSettings,
    t.useNotificationSettingsVariables
  >(notificationSettingsQuery, {
    variables: {
      customerId: customerContext.id,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
  return res.data;
};
