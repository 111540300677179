import { CpeStatus, SubscriptionGroup } from 'src/__types/graphql-global-types';
import { StatusColor } from 'src/lib/types';
import { getCpeStatusIcon } from './getCpeStatusIcon';
import { IconDefinition } from '@telia/styleguide';

export const getSubscriptionIcon = (entry: {
  group: SubscriptionGroup | null;
  isMonitoringPossible?: boolean | null;
  cpe?: null | {
    status?: CpeStatus | null;
  };
}): {
  icon: IconDefinition;
  color: StatusColor;
} => {
  if (entry.group === 'data') {
    // We intentionally send undefined/null to getCpeStatusIcon so it will handle what icon and color to show if cpeStatus is NULL.
    return getCpeStatusIcon(entry.cpe?.status, entry.isMonitoringPossible);
  }
  if (entry.group === 'mobile') {
    return {
      icon: 'mobile',
      color: 'default',
    };
  }
  if (entry.group === 'tele') {
    return {
      icon: 'phone',
      color: 'default',
    };
  }
  if (entry.group === 'tv') {
    return {
      icon: 'tv',
      color: 'default',
    };
  }
  return {
    icon: 'internet',
    color: 'default',
  };
};
