import * as React from 'react';
import gql from 'graphql-tag';
import { Query, QueryResult, useQuery } from 'react-apollo';
import { Statistics } from 'src/lib/types/statistics/Statistics';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/WithStatistics';
// import { StatisticsPeriod } from '../types';

const query = gql`
  query WithStatistics(
    $customerId: ID!
    $input: SubscriptionStatisticsInput!
    $subscriptionId: [ID!]!
  ) {
    customer(id: $customerId) {
      id
      subscriptions(ids: $subscriptionId) {
        id
        serviceBandwidth {
          downstream
          upstream
        }
        statistics(input: $input) {
          throughput {
            p95In
            p95Out
            averageIn
            averageOut
            maxIn
            maxOut
          }
          utilization {
            averageIn
            averageOut
            maxIn
            maxOut
          }
          packetDrop {
            averageIn
            averageOut
            maxIn
            maxOut
          }
          traffic {
            averageIn
            averageOut
            averageTotal
            sumIn
            sumOut
            sumTotal
          }
          responseTime {
            average
            max
          }
          granularity
          periods {
            periodStart
            periodEnd
            throughput {
              in
              out
              total
              maxIn
              maxOut
              maxTotal
            }
            cumulativeTraffic {
              in
              out
              total
            }
            packetDrop {
              in
              out
              total
            }
            responseTime
            utilization {
              in
              out
              total
            }
          }
        }
      }
    }
  }
`;

// Exporting types that for UI-components
export type StandardStatistics = Omit<
  t.WithStatistics_customer_subscriptions_statistics,
  '__typename'
>;

export type StandardStatisticsPeriod = Omit<
  t.WithStatistics_customer_subscriptions_statistics_periods,
  '__typename'
>;

export type StandardStatisticsBandwidth = Omit<
  t.WithStatistics_customer_subscriptions_serviceBandwidth,
  '__typename'
>;

export const useStandardStatistics = (inputProps: {
  subscriptionId: string;
  interface?: string | null;
  periodStart: string;
  periodEnd: string;
  periods: number;
  type: StandardStatisticsType[];
}) => {
  const { subscriptionId, ...restProps } = inputProps;
  const customer = useCustomer();
  /**
   * Since we cannot conditonally render a hook, we rather skip the query if some params are missing
   */
  const skipQuery = !restProps.interface;

  const res = useQuery<t.WithStatistics, t.WithStatisticsVariables>(query, {
    variables: {
      customerId: customer.id,
      subscriptionId: [subscriptionId],
      input: restProps,
    },
    skip: skipQuery,
  });

  return {
    loading: res.loading,
    error: res.error,
    data: res.data?.customer?.subscriptions?.[0] ?? null,
  };
};
interface SubscriptionStatisticsInput {
  periodStart: string;
  periodEnd: string;
  periods: number;
  interface?: string;
  type?: string[];
}

interface Variables {
  customerId: string;
  input: SubscriptionStatisticsInput;
  subscriptionId: string[];
}

export type StandardStatisticsType =
  | 'throughput'
  | 'cumulative'
  | 'packetDrop'
  | 'responseTime'
  | 'utilization';

interface Data {
  customer: {
    subscriptions: Array<{
      statistics: Statistics;
    }>;
  };
}
interface Props {
  customerId: string;
  subscriptionId: string;
  interface?: string;
  periodStart: string;
  periodEnd: string;
  periods: number;
  type: StandardStatisticsType[];
  children: (result: QueryResult<Data, Variables>) => JSX.Element | null;
}

const WithStatistics = (props: Props) => (
  <Query<Data, Variables>
    query={query}
    variables={{
      customerId: props.customerId,
      subscriptionId: [props.subscriptionId],
      input: {
        interface: props.interface,
        periodStart: props.periodStart,
        periodEnd: props.periodEnd,
        periods: props.periods,
        type: props.type,
      },
    }}
  >
    {props.children}
  </Query>
);

export default WithStatistics;
