import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useMobileDataLink';
const GET_MOBILE_DATA_LINK = gql`
  query useMobileDataLink($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        alias
        name
        group
        phoneNumbers {
          phoneNumber
        }
        mobileDataLink {
          id
          alias
          name
          group
          phoneNumbers {
            phoneNumber
          }
        }
      }
    }
  }
`;

interface Result {
  mobileSubscription?: t.useMobileDataLink_customer_subscriptions_mobileDataLink | null;
  dataSubscription?: t.useMobileDataLink_customer_subscriptions_mobileDataLink | null;
  loading: boolean;
}
export const useMobileDataLink = (subscriptionId: string): Result => {
  const customer = useCustomer();

  const { data, loading } = useQuery<
    t.useMobileDataLink,
    t.useMobileDataLinkVariables
  >(GET_MOBILE_DATA_LINK, {
    variables: {
      customerId: customer.id,
      subscriptionId,
    },
    errorPolicy: 'all',
  });

  const main = data?.customer?.subscriptions?.[0];
  const link = data?.customer?.subscriptions?.[0]?.mobileDataLink;

  const mobileSubscription = main?.group === 'mobile' ? main : link;
  const dataSubscription = main?.group === 'data' ? main : link;

  return { mobileSubscription, dataSubscription, loading };
};
