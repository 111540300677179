import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import {
  CallForwardingMobileSettings,
  MobileSubscription,
  Error,
} from 'src/lib/types';
import { WITH_MOBILE_SETTINGS } from 'src/areas/mobile/settings/useMobileSettings';

export const UPDATE_CALL_FORWARD_SETTINGS = gql`
  mutation useUpdateCallForwardingSettings(
    $input: UpdateCallForwardingMobileSettingsInput
  ) {
    updateCallForwardingMobileSettings(input: $input) {
      msisdn
      callForwarding {
        allCalls
        allCallsDestination
        busy
        busyDestination
        noAnswer
        noAnswerDestination
      }
      error {
        code
        message
      }
    }
  }
`;

interface Variables {
  input: {
    msisdn: string;
  } & CallForwardingMobileSettings;
}

interface Data {
  updateCallForwardingMobileSettings: {
    msisdn: string;
    callForwarding?: CallForwardingMobileSettings;
    error?: Error;
  };
}

export interface UpdateCallforwardSettingsResult {
  loading: boolean;
  error?: boolean;
  data?: Data;
  updateSettings: (settings: CallForwardingMobileSettings) => void;
}

export const useUpdateCallForwardingSettings = (): UpdateCallforwardSettingsResult => {
  const subscription = useSubscriptionContext();
  const [mutation, result] = useMutation<Data, Variables>(
    UPDATE_CALL_FORWARD_SETTINGS,
    {
      update(cache, { data }) {
        if (data && data.updateCallForwardingMobileSettings) {
          const {
            updateCallForwardingMobileSettings: {
              callForwarding,
              error,
              msisdn,
            },
          } = data;
          if (!error) {
            const cachedData: {
              subscription: MobileSubscription;
            } | null = cache.readQuery({
              query: WITH_MOBILE_SETTINGS,
              variables: {
                subscriptionId: msisdn,
              },
            });
            if (
              cachedData &&
              cachedData.subscription &&
              cachedData.subscription.settings
            ) {
              cache.writeQuery({
                query: WITH_MOBILE_SETTINGS,
                variables: {
                  subscriptionId: msisdn,
                },
                data: {
                  subscription: {
                    id: msisdn,
                    settings: {
                      ...cachedData.subscription.settings,
                      callForwarding: callForwarding,
                      __typename: 'MobileSettings',
                    },
                    __typename: 'MobileSubscription',
                  },
                },
              });
            }
          }
        }
      },
    }
  );

  const updateSettings = (settings: CallForwardingMobileSettings) => {
    mutation({
      variables: {
        input: {
          msisdn: subscription.id,
          ...settings,
        },
      },
    });
  };

  return {
    updateSettings,
    data: result.data,
    error:
      !!result.error ||
      (result.data && !!result.data.updateCallForwardingMobileSettings.error),
    loading: result.loading,
  };
};
