import gql from 'graphql-tag';

export const UserFragment = gql`
  fragment UserFragment on User {
    id
    emailVerified
    customerAccess(customerId: $customerId) {
      areas
      roles {
        role
        organisation {
          id
          name
        }
        tag {
          id
          tag
        }
      }
    }
  }
`;
