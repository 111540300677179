import * as React from 'react';

import { RouteComponentProps } from 'react-router';
import { map } from 'lodash';

import { CostCenter } from 'src/lib/types';
import { LinkTo, ListFooter, Loading, LoadMoreButton } from 'src/lib/ui';

import { PageHeader } from '../common/PageHeader/PageHeader';
import { WithSearch } from 'src/areas/main/search';
import { OrganisationTreeNavigator } from 'src/lib/ui/OrganisationTreeNavigator/OrganisationTreeNavigator';
import { t } from 'src/lib/i18n';

type Props = RouteComponentProps<{ type: 'fakturasted' | 'kostnadssted' }>;

export const ChooseOrganisationOrCostCenter: React.FC<Props> = ({ match }) => {
  const i18n = t.costs.ChooseOrganisationOrCostCenter;
  return (
    <>
      <PageHeader
        title={i18n.PageHeader.title}
        navTabs={[
          {
            label: i18n.PageHeader.navTabs.organisation,
            url: '/kostnader/velg-fakturasted',
            exact: true,
          },
          {
            label: i18n.PageHeader.navTabs.costCenter,
            url: '/kostnader/velg-kostnadssted',
            exact: true,
          },
        ]}
      />
      {/*
            <ListHeader>
              <PeriodPicker
                costPeriods={cc.costPeriods}
                period={cc.activePeriod}
                setPeriod={cc.setActivePeriod}
              />

              <PeriodSelector
                className="px-4"
                setActivePeriodType={cc.setPeriodBy}
                activePeriodType={cc.periodBy}
              />

              {this.props.match.params.type !== 'kostnadssted' ? (
                <form className="ml-auto">
                  <Toggle
                    id="OrganisationHeader-recursive"
                    checked={
                      cc.recursive !== undefined
                        ? cc.recursive
                        : true
                    }
                    onChange={e => cc.setRecursive(e)}
                  />
                  <label
                    htmlFor="OrganisationHeader-recursive"
                    className="pl-3"
                    style={{ fontSize: '16px', color: '#6b6e98' }}
                  >
                    Inkluder underliggende fakturasteder
                  </label>
                </form>
              ) : null}
            </ListHeader> */}

      {/*

            Remnants from ListCostCenters.tsx; button for creating new cost centers

            <ListHeader>
              {search.results.length ? (
                <span>
                  Viser {search.results.length} av {search.totalResults}
                </span>
              ) : null}

              <WithCreateCostCenter>
                {context => {
                  return (
                    <CostCenterModal
                      buttonClassname="ml-auto"
                      onChange={context.createCostCenter}
                    />
                  );
                }}
              </WithCreateCostCenter>
            </ListHeader>

            <List>
              {search.results.map((cc: CostCenter) => (
                <SearchListCostCenterResult key={cc.id} entry={cc} />
              ))}
            </List>
            */}

      <div className="container">
        {match.params.type === 'kostnadssted' ? (
          <WithSearch
            initial={{
              types: ['costCenter'],
              size: 10000,
            }}
          >
            {search => (
              <>
                <div className="container">
                  <table>
                    <thead>
                      <tr>
                        <th>{i18n.costCenterTable.id}</th>
                        <th>{i18n.costCenterTable.description}</th>
                        <th>&nbsp;</th>
                      </tr>
                    </thead>
                    <tbody>
                      {map((search.results as unknown) as CostCenter[], res => (
                        <tr
                          key={res.id}
                          // onClick={() =>
                          //   this.props.history.push(`/ordre/${res.id}`)
                          // }
                        >
                          <td>
                            <LinkTo area="cost" costCenter={res.id}>
                              {res.id}
                            </LinkTo>
                          </td>
                          <td>{res.description}</td>
                        </tr>
                      ))}

                      {search.loading ? (
                        <tr>
                          <td colSpan={3}>
                            <Loading />
                          </td>
                        </tr>
                      ) : null}
                    </tbody>
                  </table>
                </div>

                {/* {search.loading ? <Loading /> : null} */}

                <ListFooter>
                  {search.loading || search.results.length ? (
                    <LoadMoreButton
                      onClick={search.loadMore}
                      disabled={search.loading || !search.isMore}
                      label={i18n.costCenterTable.LoadMoreBtn}
                    />
                  ) : null}
                </ListFooter>
              </>
            )}
          </WithSearch>
        ) : (
          <div className="pt-4">
            <OrganisationTreeNavigator linkToOrganisations={true} />
          </div>
        )}
      </div>
    </>
  );
};
