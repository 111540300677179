import React from 'react';
import { List, NoResult } from 'src/lib/ui';
import { Contact } from 'src/lib/types';
import { SearchResult } from 'src/areas/main/search';
import { SearchListContactResult } from 'src/areas/main/search/components/list/SearchListContactResult';
import { useDelayedRender } from 'src/lib/utils/useDelayedRender';

const ListContacts = (props: {
  loading: boolean;
  results: SearchResult[];
  noResultsMessage: string;
}) => {
  const { results, loading, noResultsMessage } = props;
  const delayedRender = useDelayedRender(500, loading);

  return (
    <List border={true} container={false} loading={delayedRender}>
      {props.results.length === 0 && !props.loading ? (
        <div style={{ width: '100%', height: '50vh' }}>
          <NoResult text={noResultsMessage} />
        </div>
      ) : (
        results.map(entry => {
          const contact = entry as Contact;
          return <SearchListContactResult key={contact.id} entry={contact} />;
        })
      )}
    </List>
  );
};

export default ListContacts;
