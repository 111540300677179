import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { WithCustomerContext } from 'src/lib/global';
import { Error } from 'src/lib/types';
import { UnitOrderDetailsFragment } from '../lib/UnitOrderDetailsFragment';

import { UnitOrderDetails } from '../types';

const MUTATION_RESERVE_SIM_CARDS_ORDER = gql`
  mutation reserveSimCardsOrder($input: ReserveSimCardsOrderInput) {
    reserveSimCardsOrder(input: $input) {
      orderDetails {
        ... on UnitOrderDetails {
          ...UnitOrderDetailsResult
        }
      }
    }
  }
  ${UnitOrderDetailsFragment}
`;

export interface ReserveSimCardsOrderInput {
  quantity: number;
  simCardsRecipientAddress: {
    address: string;
    postalCode: string;
    postalArea: string;
    attention: string;
  };
  simCardType: string;
}

interface Variables {
  input: ReserveSimCardsOrderInput & { customerId: string };
}
interface Data {
  reserveSimCardsOrder: UnitOrderDetails;
  error?: Error;
}

interface Props {
  children: (res: ReserveSimCardsOrderResult) => JSX.Element | null;
}

export interface ReserveSimCardsOrderResult {
  reserveSimCardsOrder: (input: ReserveSimCardsOrderInput) => void;
  loading: boolean;
  error?: ApolloError;
  data?: UnitOrderDetails;
  reset?: () => void;
}

const WithReserveSimCardsOrderMutation = (props: Props) => {
  const [data, setData] = useState<UnitOrderDetails | undefined>(undefined);
  return (
    <WithCustomerContext>
      {cContext => (
        <Mutation<Data, Variables> mutation={MUTATION_RESERVE_SIM_CARDS_ORDER}>
          {(mutation, mutationResult) => {
            const reserveSimcardsOrderContext: ReserveSimCardsOrderResult = {
              reserveSimCardsOrder: async input => {
                const res = await mutation({
                  variables: { input: { ...input, customerId: cContext.id } },
                });
                setData(
                  res.data && res.data.reserveSimCardsOrder
                    ? res.data.reserveSimCardsOrder
                    : undefined
                );
              },
              loading: mutationResult.loading,
              error: mutationResult.error,
              data,
              reset: () => setData(undefined),
            };
            return props.children(reserveSimcardsOrderContext);
          }}
        </Mutation>
      )}
    </WithCustomerContext>
  );
};

export default WithReserveSimCardsOrderMutation;
