/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================

/**
 * A functional area of the portal to which a user can be given access.
 */
export enum Area {
  admin = "admin",
  contact = "contact",
  cost = "cost",
  document = "document",
  incident = "incident",
  order = "order",
  organisation = "organisation",
  subscription = "subscription",
  tag = "tag",
  user = "user",
}

export enum CpeStatus {
  Critical = "Critical",
  NoStatus = "NoStatus",
  Normal = "Normal",
  NotInstalled = "NotInstalled",
  Unknown = "Unknown",
}

export enum DeliveryStatus {
  active = "active",
  assigning = "assigning",
  terminated = "terminated",
  terminating = "terminating",
}

export enum DirectoryVisibility {
  Hidden = "Hidden",
  Secret = "Secret",
  ShowCompany = "ShowCompany",
  ShowUser = "ShowUser",
  ShowUserAndCompany = "ShowUserAndCompany",
}

export enum ExtendedQosStatisticsType {
  jitter = "jitter",
  packetLoss = "packetLoss",
  roundTripTime = "roundTripTime",
}

export enum GroupByType {
  costType = "costType",
  groupedProductDesign = "groupedProductDesign",
  month = "month",
  productDesign = "productDesign",
  productGroup = "productGroup",
}

export enum GsmSignalType {
  signal2g = "signal2g",
  signal3g = "signal3g",
  signal4g = "signal4g",
  unknown = "unknown",
}

export enum IncidentSeverity {
  Critical = "Critical",
  Major = "Major",
  Minor = "Minor",
}

/**
 * Status of the incident
 */
export enum IncidentStatus {
  canceled = "canceled",
  closed = "closed",
  onHoldPendingCustomer = "onHoldPendingCustomer",
  onHoldPendingTelia = "onHoldPendingTelia",
  solved = "solved",
}

export enum IncidentType {
  ConnectionDown = "ConnectionDown",
  Other = "Other",
  SlowConnection = "SlowConnection",
  UnstableConnection = "UnstableConnection",
}

export enum InterfaceMode {
  Port = "Port",
  Trunk = "Trunk",
}

export enum InvoiceStatus {
  CreditNote = "CreditNote",
  Overdue = "Overdue",
  Paid = "Paid",
  PartiallyPaid = "PartiallyPaid",
  Unpaid = "Unpaid",
}

export enum NetworkOrderType {
  change = "change",
  new = "new",
  termination = "termination",
}

/**
 * The channels that a user can get notificaitons in.
 */
export enum NotificationChannel {
  email = "email",
  portal = "portal",
  sms = "sms",
}

export enum NotificationSettingsType {
  change = "change",
  incident = "incident",
}

/**
 * Types of notificaitons
 */
export enum NotificationType {
  change = "change",
  incidentChange = "incidentChange",
  incidentComment = "incidentComment",
  incidentCreate = "incidentCreate",
  incidentStatus = "incidentStatus",
  order = "order",
  plannedWorkCreate = "plannedWorkCreate",
}

export enum Operator {
  Ice = "Ice",
  Telenor = "Telenor",
  Telia = "Telia",
  Unknown = "Unknown",
}

export enum OrderStatus {
  cancelled = "cancelled",
  closed = "closed",
  done = "done",
  failed = "failed",
  newOrder = "newOrder",
  pending = "pending",
  porting = "porting",
  portingFailed = "portingFailed",
  processing = "processing",
  resend = "resend",
  unknown = "unknown",
}

export enum OrderType {
  mobile = "mobile",
  mobileUnit = "mobileUnit",
  subscription = "subscription",
}

export enum PeriodByType {
  invoice = "invoice",
  usage = "usage",
}

export enum PlannedWorkState {
  Cancelled = "Cancelled",
  Closed = "Closed",
  Implement = "Implement",
  Review = "Review",
  Scheduled = "Scheduled",
}

/**
 * Merged statuses for all order types.
 */
export enum PortalOrderStatus {
  cancelled = "cancelled",
  completed = "completed",
  inProgress = "inProgress",
  newOrder = "newOrder",
  unknown = "unknown",
}

export enum ProductGroupType {
  data = "data",
  mobile = "mobile",
  other = "other",
  tele = "tele",
}

/**
 * The different types/templates of roles a user can be assigned.
 */
export enum RoleTemplate {
  CustomerAdministrator = "CustomerAdministrator",
  FinancialManager = "FinancialManager",
  OrderManager = "OrderManager",
  SystemAdministrator = "SystemAdministrator",
}

export enum SearchDomain {
  contact = "contact",
  costCenter = "costCenter",
  customer = "customer",
  dataSubscription = "dataSubscription",
  file = "file",
  incident = "incident",
  invoice = "invoice",
  mobileSubscription = "mobileSubscription",
  notification = "notification",
  order = "order",
  organisation = "organisation",
  plannedWork = "plannedWork",
  subscription = "subscription",
  tag = "tag",
  teleSubscription = "teleSubscription",
  tvSubscription = "tvSubscription",
}

export enum SearchFilterOption {
  cpeType = "cpeType",
  type = "type",
}

export enum SortOrder {
  asc = "asc",
  desc = "desc",
}

export enum StatisticsGranularity {
  day = "day",
  hour = "hour",
  raw = "raw",
}

export enum SubscriptionGroup {
  data = "data",
  mobile = "mobile",
  other = "other",
  tele = "tele",
  tv = "tv",
}

export enum SubscriptionTaxModel {
  departmentPhone = "departmentPhone",
  free = "free",
  none = "none",
  officePhone = "officePhone",
  personal = "personal",
}

export enum TvChannelPackageQuantityDescription {
  numberOfAvailableOutlets = "numberOfAvailableOutlets",
  numberOfAvailableSeats = "numberOfAvailableSeats",
  numberOfGetBoxes = "numberOfGetBoxes",
}

export enum TvDistributionTechnology {
  getBox = "getBox",
  tvCentral = "tvCentral",
}

export enum UpdateTagsOnSubscriptionsErrorCode {
  InvalidSubscriptionAccess = "InvalidSubscriptionAccess",
  ServiceError = "ServiceError",
  SubscriptionDoesNotExist = "SubscriptionDoesNotExist",
  TagDoesNotExist = "TagDoesNotExist",
}

export interface AddTagToSubscriptionInput {
  subscriptionId: string;
  tag?: string | null;
  tagId?: string | null;
}

export interface AlterFileInput {
  id: string;
  name?: string | null;
  folderId?: string | null;
}

export interface AlterFolderInput {
  id: string;
  name?: string | null;
  parentId?: string | null;
  protected?: boolean | null;
}

export interface AssignFeatureFlagInput {
  key: string;
  userId?: string | null;
  customerId?: string | null;
  value?: string | null;
}

export interface BlockSimCardOrderInput {
  customerId: string;
  msisdn: string;
}

export interface CallerIdConfigurationInput {
  subscriptionId: string;
  allowedCallerIdList?: string[] | null;
}

export interface CopyNotificationSettingsInput {
  customerId: string;
  fromContactId: string;
  toContactId: string;
}

export interface CopyRolesInput {
  customerId: string;
  fromUserId: string;
  toUserId: string;
}

export interface CreateCostCenterInput {
  description?: string | null;
  costCenterId?: string | null;
  costCenterCode?: string | null;
  customerId: string;
}

export interface CreateFolderInput {
  parentId: string;
  name: string;
  protected?: boolean | null;
}

export interface CreateIncidentInput {
  customerId: string;
  description: string;
  incidentType?: IncidentType | null;
  severity?: IncidentSeverity | null;
  subscriptionId: string;
  addonId?: string | null;
  customerReference?: string | null;
  smsNotification?: boolean | null;
  emailNotification?: boolean | null;
  contactIds?: string[] | null;
}

export interface CreateNetworkOrderInput {
  customerId: string;
  organisationId: string;
  address?: string | null;
  postalCode?: string | null;
  country?: string | null;
  municipality?: string | null;
  gnr?: string | null;
  bnr?: string | null;
  floor?: string | null;
  needsWiring: boolean;
  accessBandwidth: string;
  siteContactId: string;
  deliveryDate: any;
  additionalInformation?: string | null;
}

export interface CreateNewMobileSubscriptionOrderInput {
  costCenterId?: string | null;
  customerId: string;
  organisationId: string;
  orderReference?: string | null;
  phoneNumber?: string | null;
  simCardId?: string | null;
  newSimCardRecipient?: SimCardOrderRecipientInput | null;
  personalDetails: MobileSubcriptionPersonalDetailsInput;
  taxModel?: string | null;
  products: string[];
  flex1?: string | null;
  flex2?: string | null;
  flex3?: string | null;
  flex4?: string | null;
}

export interface CreatePersonInput {
  customerId: string;
  contactId?: string | null;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  mobilePhone?: string | null;
  secondaryPhone?: string | null;
  description?: string | null;
  employeeNumber?: string | null;
  locale?: string | null;
  roles?: RoleInput[] | null;
  useIdentity?: boolean | null;
}

export interface CreateTagInput {
  customerId: string;
  tag: string;
  description?: string | null;
}

export interface CustomerSearchInput {
  query?: string | null;
  after?: string | null;
  size?: number | null;
  page?: number | null;
}

export interface DeleteFeatureFlagInput {
  key: string;
}

export interface DeletePersonInput {
  contactId: string;
}

export interface DeleteTagInput {
  customerId: string;
  id: string;
}

export interface DowngradeToContactInput {
  contactId: string;
}

export interface EditPersonInput {
  contactId: string;
  email?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  mobilePhone?: string | null;
  secondaryPhone?: string | null;
  description?: string | null;
  employeeNumber?: string | null;
  locale?: string | null;
  roles?: RoleInput[] | null;
}

export interface EditTagInput {
  customerId: string;
  tagId: string;
  tag?: string | null;
  description?: string | null;
}

export interface ExtendedQosStatisticsInput {
  periodStart: any;
  periodEnd: any;
  types: ExtendedQosStatisticsType[];
}

export interface ExtraSimCardOrderInput {
  customerId: string;
  msisdn: string;
  extraSimType: string;
  simCardId?: string | null;
  newSimCardRecipient?: SimCardOrderRecipientInput | null;
  phoneNumber?: string | null;
  taxModel?: string | null;
  flex1?: string | null;
  flex2?: string | null;
  flex3?: string | null;
  flex4?: string | null;
}

export interface GSMStatisticsInput {
  periodStart: any;
  periodEnd: any;
  periods: number;
}

export interface GetChangelogsInput {
  page: number;
  size: number;
  from: any;
  to?: any | null;
  includeUnpublished?: boolean | null;
}

export interface LanRouteInput {
  id: string;
  networkAddress?: string | null;
  subnetMask?: string | null;
  interfaceMode?: InterfaceMode | null;
  interfaceId?: string | null;
  hsrpIpAddress?: string | null;
}

export interface MarkNotificationAsReadInput {
  notificationId: string;
}

export interface MobileSubcriptionFormerOwnerInput {
  businessName?: string | null;
  organisationNumber?: string | null;
  firstName?: string | null;
  lastName?: string | null;
  birthDate?: any | null;
}

export interface MobileSubcriptionPersonalDetailsInput {
  firstName: string;
  lastName: string;
  jobTitle?: string | null;
  localNumber?: string | null;
  email?: string | null;
  employeeNumber?: string | null;
}

export interface NetworkChangeOrderInput {
  customerId: string;
  subscriptionId: string;
  staticRouting?: StaticRouteInput[] | null;
  lanRouting?: LanRouteInput[] | null;
}

export interface NetworkTerminationOrderInput {
  customerId: string;
  subscriptionId: string;
  terminationDate: any;
}

export interface NotificationSettingsInput {
  type: NotificationSettingsType;
  active: boolean;
  channels: NotificationChannel[];
  subscriptionIds?: string[] | null;
  tags?: string[] | null;
  notifyForAllCustomerSubscriptions: boolean;
}

export interface OpenSimCardOrderInput {
  customerId: string;
  msisdn: string;
}

export interface PortMobileSubscriptionOrderInput {
  activationDate?: any | null;
  costCenterId?: string | null;
  customerId: string;
  organisationId: string;
  phoneNumber: string;
  simCardId?: string | null;
  newSimCardRecipient?: SimCardOrderRecipientInput | null;
  personalDetails: MobileSubcriptionPersonalDetailsInput;
  formerOwner: MobileSubcriptionFormerOwnerInput;
  taxModel?: string | null;
  products: string[];
  flex1?: string | null;
  flex2?: string | null;
  flex3?: string | null;
  flex4?: string | null;
  transferPaymentsToNewOwner: boolean;
}

export interface QoSStatisticsInput {
  periodStart: any;
  periodEnd: any;
  periods: number;
  interface?: string | null;
}

export interface RemoveTagFromSubscriptionInput {
  subscriptionId: string;
  tag?: string | null;
  tagId?: string | null;
}

export interface ReplaceSimCardOrderInput {
  customerId: string;
  msisdn: string;
  simCardId?: string | null;
  newSimCardRecipient?: SimCardOrderRecipientInput | null;
}

export interface ReserveNumberSeriesOrderInput {
  customerId: string;
  quantity: number;
}

export interface ReserveOrderNumberInput {
  customerId: string;
  orderReference: string;
  phoneNumber: string;
}

export interface ReserveSimCardOrderRecipientInput {
  address: string;
  postalCode: string;
  postalArea: string;
  attention: string;
}

export interface ReserveSimCardsOrderInput {
  customerId: string;
  quantity: number;
  simCardsRecipientAddress?: ReserveSimCardOrderRecipientInput | null;
  simCardType?: string | null;
}

export interface RestoreFileInput {
  id: string;
}

export interface RestoreFolderInput {
  id: string;
}

export interface RoleInput {
  role: string;
  organisationId?: string | null;
  tag?: string | null;
}

export interface SearchFilter {
  filter: string;
  value?: (string | null)[] | null;
}

export interface SearchInput {
  query?: string | null;
  types?: string[] | null;
  sortBy?: string | null;
  sortOrder?: SortOrder | null;
  after?: string | null;
  filters?: SearchFilter[] | null;
  cluster?: boolean | null;
  size?: number | null;
  page?: number | null;
}

export interface SendSmsInput {
  sender: string;
  to: string[];
  message: string;
}

export interface SetAttributeForSubscriptionInput {
  subscriptionId: string;
  attribute: string;
  value: string;
}

/**
 * Creates Changelog entity if input 'id' is not present, else updates
 */
export interface SetChangelogInput {
  id?: string | null;
  headingNorwegian: string;
  headingEnglish: string;
  bodyNorwegian?: string | null;
  bodyEnglish?: string | null;
  summaryNorwegian: string;
  summaryEnglish: string;
  icon: string;
  publishedAt?: any | null;
  hidden?: boolean | null;
}

export interface SetCostCenterForSubscriptionInput {
  costCenterId: string;
  internalId?: string | null;
  subscriptionId?: string | null;
}

export interface SetFeatureFlagInput {
  key: string;
  description?: string | null;
  valueLabel?: string | null;
  exposeToFrontend?: boolean | null;
  expectDeletionAt?: any | null;
  contextLink?: string | null;
}

export interface SetFileTeliaInternalInput {
  fileId: string;
  internal: boolean;
}

export interface SetFolderAccessInput {
  folderId: number;
  userIds: string[];
}

export interface SetNotificationSettingsInput {
  customerId: string;
  settings: NotificationSettingsInput;
}

export interface SetNotificationSettingsOnUserInput {
  customerId: string;
  contactId: string;
  settings: NotificationSettingsInput;
}

export interface SetOwnUserPreferenceInput {
  key: string;
  value: string;
}

export interface SetOwnUserPreferencesInput {
  preferences: SetOwnUserPreferenceInput[];
}

export interface SetTechnicalContactsOnSubscriptionInput {
  subscriptionId: string;
  technicalContacts?: TechnicalContactInput[] | null;
}

export interface SimCardOrderRecipientInput {
  name: string;
  address: string;
  postalCode: string;
  postalArea: string;
  attention?: string | null;
}

export interface StaticRouteInput {
  id: string;
  networkAddress?: string | null;
  subnetMask?: string | null;
  gateway?: string | null;
}

export interface SubscriptionStatisticsInput {
  periodStart: any;
  periodEnd: any;
  periods: number;
  interface?: string | null;
  type: string[];
}

export interface TechnicalContactInput {
  contactId?: string | null;
  priority: number;
}

export interface TerminateMobileSubscriptionOrderInput {
  customerId: string;
  msisdn: string;
  terminationDate?: any | null;
  parkNumber: boolean;
}

export interface UnassignFeatureFlagInput {
  key: string;
  userId?: string | null;
  customerId?: string | null;
}

export interface UpdateBarringMobileSettingsInput {
  msisdn: string;
  data?: boolean | null;
  dataRoaming?: boolean | null;
  internationalCalls?: boolean | null;
  incomingCallsAndSms?: boolean | null;
  internationalIncomingCalls?: boolean | null;
  outgoingCallsAndSms?: boolean | null;
  internationalOutgoingCalls?: boolean | null;
  cpaDisabled?: boolean | null;
  premiumServices?: boolean | null;
  numberInformation?: boolean | null;
  outgoingCalls?: boolean | null;
  internationalCallsExceptHome?: boolean | null;
  cpaAmount100?: boolean | null;
  cpaAmount250?: boolean | null;
  cpaAmount500?: boolean | null;
  cpaGoodsAndServices?: boolean | null;
  premiumServices820?: boolean | null;
  premiumServices829?: boolean | null;
  donations?: boolean | null;
}

export interface UpdateCallForwardingMobileSettingsInput {
  msisdn: string;
  allCalls?: boolean | null;
  allCallsDestination?: string | null;
  busy?: boolean | null;
  busyDestination?: string | null;
  noAnswer?: boolean | null;
  noAnswerDestination?: string | null;
}

export interface UpdateContactsOnNotificationsInput {
  incidentId: string;
  customerId: string;
  contactIdsToAdd?: string[] | null;
  notificationIdsToDelete?: string[] | null;
}

export interface UpdateIncidentCustomerReferenceInput {
  id: string;
  customerReference: string;
}

export interface UpdateIncidentNotificationInput {
  id: string;
  sms: boolean;
  email: boolean;
}

export interface UpdateMobileDirectoryInput {
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  streetAddress?: string | null;
  postCode?: string | null;
  postArea?: string | null;
  country?: string | null;
  visibility: DirectoryVisibility;
}

export interface UpdateMobileOriginInput {
  id: string;
  firstName?: string | null;
  middleName?: string | null;
  lastName?: string | null;
  streetAddress?: string | null;
  streetNum?: string | null;
  apartmentNum?: string | null;
  floor?: string | null;
  gnr?: string | null;
  fnr?: string | null;
  knr?: string | null;
  postCode?: string | null;
  postArea?: string | null;
  country?: string | null;
}

export interface UpdateMobileUserInput {
  id: string;
  firstName?: string | null;
  lastName?: string | null;
  position?: string | null;
  employeeNum?: string | null;
  email?: string | null;
  flexField1?: string | null;
  flexField2?: string | null;
  flexField3?: string | null;
  flexField4?: string | null;
}

export interface UpdatePasswordInput {
  password: string;
}

export interface UpdateRoamingAlertMobileSettingsInput {
  msisdn: string;
  smsWhenRoaming?: boolean | null;
}

export interface UpdateTagsOnSubscriptionsInput {
  customerId: string;
  subscriptionIds: string[];
  tagIds: string[];
}

export interface UpdateVoicemailMobileSettingsInput {
  msisdn: string;
  smsNotification?: boolean | null;
  emailNotification?: boolean | null;
  visualVoicemail?: boolean | null;
}

export interface WorklogSearchInput {
  query?: string | null;
  filters?: SearchFilter[] | null;
  after?: string | null;
  size?: number | null;
  page?: number | null;
}

//==============================================================
// END Enums and Input Objects
//==============================================================
