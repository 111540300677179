import gql from 'graphql-tag';

export type GetChangelogsInput = {
  page: number;
  size: number;
  from: string;
  to?: string;
  includeUnpublished?: boolean;
};

export const GET_CHANGELOGS = gql`
  query WithChangelogs($input: GetChangelogsInput!) {
    changelogs(input: $input) {
      id
      publishedAt
      hidden
      createdAt
      createdBy {
        id
        name
      }
      updatedAt
      updatedBy {
        id
        name
      }
      headingNorwegian
      headingEnglish
      bodyNorwegian
      bodyEnglish
      summaryNorwegian
      summaryEnglish
      icon
    }
  }
`;
