import React, { useMemo } from 'react';
import { SearchOptionSelect } from 'src/lib/ui';
import { SortOrder } from 'src/__types/graphql-global-types';
import { useSearchContacts } from '../../queries/useSearchContacts';

interface Props {
  activeContactName: string;
  setActiveContact: (contactId: string) => void;
}

export const ContactSelector: React.FC<Props> = props => {
  const { contacts } = useSearchContacts({
    size: 200,
    filter: [{ filter: 'hasUser', value: ['true'] }],
    sortOrder: SortOrder.asc,
    sortBy: 'firstName',
  });
  const contactOptions = useMemo(
    () =>
      contacts?.map(contact => ({
        value: contact.id,
        label: `${contact.firstName} ${contact.lastName}` ?? '',
      })) ?? [],
    [contacts]
  );

  const selectContact = (selected?: string[] | null) => {
    props.setActiveContact(selected ? selected[0] : '');
  };

  return (
    <div className="ContactSelector ml-2">
      <SearchOptionSelect
        id="costCenter"
        icon="arrow-small-down"
        iconPosition="after"
        outline={true}
        fullWidth={true}
        label={props.activeContactName}
        options={contactOptions}
        selectedOptions={[props.activeContactName]}
        handleSave={selectContact}
        disableSaveOnClickOutside={true}
      />
    </div>
  );
};
