import { useAlterFolder } from './../mutations/useAlterFolder';
import { useEffect } from 'react';
import { getUndoFolderMoveAction } from '../undo/UndoActions';
import { Folder } from 'src/lib/types';
import undoPopup from 'src/areas/main/documents/undo/UndoPopup';
import { toast } from 'react-toastify';
import { t } from 'src/lib/i18n';

export const useMoveFolder = (folder: Folder) => {
  const { alterFolder, data, loading, error } = useAlterFolder();
  const currentFolderId = folder.parentId;

  let toastId;
  const displayError = () => {
    toastId = toast(t.documents.errors.couldNotMoveFile, {
      position: toast.POSITION.BOTTOM_CENTER,
      className: 'FileToast',
      progress: 0.99999,
      bodyClassName: 'FileToast-body',
      progressClassName: 'FileToast-progress error',
    });
  };

  useEffect(() => {
    if (!loading) {
      if (
        data?.alterFolder?.folder &&
        data.alterFolder.folder.parentId !== currentFolderId
      ) {
        undoPopup(
          getUndoFolderMoveAction(
            {
              id: data.alterFolder.folder.id,
              parentId: currentFolderId,
              name: data.alterFolder.folder.name,
            } as Folder,
            folder.name || '',
            alterFolder
          )
        );
      } else if (error) {
        displayError();
        setTimeout(() => toast.dismiss(toastId), 5000);
      }
    }
  }, [loading]); //eslint-disable-line

  const moveFile = (folderId: string, parentId: string) =>
    alterFolder(folderId, parentId);

  return moveFile;
};
