import React from 'react';
import { CircleIcon, CardBody, CardHeader, BodyCard } from 'src/lib/ui';
import { formatInstallationAddress } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import './ConnectionStretch.scss';

interface Props {
  installationAddress: {
    zip?: string | null;
    address?: string | null;
    city?: string | null;
  };
  installationAddress2: {
    zip?: string | null;
    address?: string | null;
    city?: string | null;
  };
}
export const ConnectionStretch = (props: Props) => {
  const i18n = t.subscriptions.ConnectionStretch;
  return (
    <BodyCard noGutter borders>
      <CardHeader>
        <h2>{i18n.stretch}</h2>
      </CardHeader>
      <CardBody>
        <div className="d-flex align-items-center pb-2">
          <CircleIcon icon="map" filled />
          <div className="ml-4">
            <small>{i18n.startPoint}</small>
            <div>
              {formatInstallationAddress(
                props.installationAddress,
                'fullAddress'
              )}
            </div>
          </div>
        </div>
        <div className="ConnectionStretch-connected" />
        <div className="d-flex align-items-center pt-2">
          <CircleIcon icon="map" filled />
          <div className="ml-4">
            <small>{i18n.endPoint}</small>
            <div>
              {formatInstallationAddress(
                props.installationAddress2,
                'fullAddress'
              )}
            </div>
          </div>
        </div>
      </CardBody>
    </BodyCard>
  );
};
