import React, { useState, useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import HTML5Backend from 'react-dnd-html5-backend';

import DocumentTable from './table/DocumentTable';
import { Page, Loading, colors, Button } from 'src/lib/ui';
import DocumentsModal from './lib/DocumentsModal/DocumentsModal';
import NewFolderForm from 'src/areas/main/documents/forms/NewFolderForm';
import DownloadFileForm from 'src/areas/main/documents/forms/DownloadFileForm';
import './DocumentsContent.scss';
import { t } from 'src/lib/i18n';
import { usePath } from './contexts/PathContext';
import { useDocumentsContext } from './DocumentsContext';
import ParentFolderRow from './table/ParentFolderTableRow';
import UploadFile from '../subscriptions/documents/UploadFile';
import FileUpload from './fileUpload/FileUpload';

const DocumentsContent: React.FC<{}> = () => {
  const {
    parentFolder,
    allFolders,
    file,
    folder,
    folderFiles,
    loading,
    subFolders,
    navigateTo,
  } = usePath();
  const { subscriptionId, disableFolders } = useDocumentsContext();
  const [newFolderModal, setNewFolderModal] = useState<boolean>(false);
  const [downloadModal, setDownloadModal] = useState<boolean>(
    file ? true : false
  );

  useEffect(() => setDownloadModal(!!file), [file]);

  useEffect(() => {
    if (!downloadModal && allFolders) {
      navigateTo({ folder: folder });
    }
  }, [downloadModal, folder]); // eslint-disable-line react-hooks/exhaustive-deps

  const toggleNewFolderModal = () => {
    setNewFolderModal(!newFolderModal);
  };

  const closeDownloadModal = () => {
    setDownloadModal(false);
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className="d-flex align-items-center ml-4"
        style={{ color: colors.greyDarkText }}
      >
        {parentFolder && folder.parentId ? (
          <ParentFolderRow folders={allFolders} folder={parentFolder} />
        ) : (
          <div className="font-weight-bold">{t.documents.title}</div>
        )}
        <div className="ml-auto pr-2 py-1">
          <UploadFile />

          {!disableFolders && (
            <Button
              color="dark"
              transparent={true}
              onClick={toggleNewFolderModal}
              icon="folder-new"
            >
              {t.documents.btnNewFolder}
            </Button>
          )}
        </div>
      </div>
      <Page className="h-auto" noPadding={true}>
        {loading && (
          <div className="DocumentsContent-header pt-4 pb-2">
            <Loading />
          </div>
        )}
        {folder ? (
          subFolders.length > 0 || folderFiles.length > 0 ? (
            <div className="pb-4">
              <DocumentTable
                folder={folder}
                parentFolder={parentFolder}
                folders={allFolders}
                subFolders={subFolders}
                files={folderFiles}
                hideBreadcrumbs={!!subscriptionId}
              />
            </div>
          ) : (
            <FileUpload />
          )
        ) : null}

        {folder && (
          <DocumentsModal
            show={newFolderModal}
            toggle={toggleNewFolderModal}
            title={t.documents.newFolderModal.heading}
          >
            <NewFolderForm cancel={toggleNewFolderModal} folder={folder} />
          </DocumentsModal>
        )}

        {file && (
          <DocumentsModal
            show={downloadModal}
            toggle={closeDownloadModal}
            title={t.documents.downloadModal.heading}
          >
            <DownloadFileForm cancel={closeDownloadModal} file={file} />
          </DocumentsModal>
        )}
      </Page>
    </DndProvider>
  );
};

export default DocumentsContent;
