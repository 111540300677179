import React from 'react';
import { Attribute } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

// "New generation firewall" product
export const NgfwDetails: React.FC<{
  ngfw: {
    protectionPackage: string | null;
    totalCapacity: string | null;
    location: string | null;
    binding: string | null;
    httpsInspection: string | null;
    idAwareness: string | null;
    eventReports: string | null;
    logApi: string | null;
  };

  heading: string;
}> = props => {
  const translate = t.subscriptions.TechnicalDetailsCard;

  return (
    <div>
      <h3>{props.heading}</h3>
      <Attribute
        label={translate.ngfw.protectionPackage}
        value={props.ngfw.protectionPackage ?? '-'}
      />
      <Attribute
        label={translate.ngfw.totalCapacity}
        value={props.ngfw.totalCapacity ?? '-'}
      />
      <Attribute
        label={translate.ngfw.location}
        value={props.ngfw.location ?? '-'}
      />
      <Attribute
        label={translate.ngfw.binding}
        value={props.ngfw.binding ?? '-'}
      />
      <Attribute
        label={translate.ngfw.httpsInspection}
        value={props.ngfw.httpsInspection ?? '-'}
      />
      <Attribute
        label={translate.ngfw.idAwareness}
        value={props.ngfw.idAwareness ?? '-'}
      />
      <Attribute
        label={translate.ngfw.eventReports}
        value={props.ngfw.eventReports ?? '-'}
      />
      <Attribute
        label={translate.ngfw.logApi}
        value={props.ngfw.logApi ?? '-'}
      />
    </div>
  );
};
