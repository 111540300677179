import * as React from 'react';
import { List, NoResult } from 'src/lib/ui';
import { SearchListIncidentResult } from '../search/components/list/SearchListIncidentResult';
import { t } from 'src/lib/i18n';
import { useDelayedRender } from 'src/lib/utils/useDelayedRender';
import { useSearchIncidents_customer_search_results_IncidentSearchResult_incident } from 'src/areas/main/incidents/queries/__types/useSearchIncidents';

function ListIncidents(props: {
  loading: boolean;
  results: useSearchIncidents_customer_search_results_IncidentSearchResult_incident[];
}) {
  const i18n = t.incidents;

  const delayedRender = useDelayedRender(500, props.loading);

  return (
    <List border={true} container={false} loading={delayedRender}>
      {props.results.length === 0 && !props.loading ? (
        <div style={{ width: '100%', height: '50vh' }}>
          <NoResult text={i18n.noIncidents} />
        </div>
      ) : (
        props.results.map(incident => (
          <SearchListIncidentResult key={incident.id} entry={incident} />
        ))
      )}
    </List>
  );
}

export default ListIncidents;
