import { formatDate } from 'src/lib/utils';

const dashboard = {
  bannerText: {
    part1: 'Vi har for tiden utfordringer med feilmelding via vår portal. ',
    part2: 'Kontakt Service Desk på tlf 02150 (tastevalg 2) for å melde feil.',
    part3: '',
    message:
      'Det er for øyeblikket problemer med innrapporterte feil. Feilretting pågår og vi beklager for ulempene dette medfører.',
  },
  new: {
    heading: 'Din oversikt',
    activeFilters: 'Aktive filtre',
    status: 'Status',
    filterButtonLabel: (numberOfActiveFilters: number) =>
      numberOfActiveFilters
        ? `Endre filtre (${numberOfActiveFilters})`
        : 'Filtrer',
    kpi: {
      subscriptionsWithUnreportedFaults: 'Tjenester offline uten feilmelding',
      incidentsOnHoldPendingCustomer: 'Feilmeldinger venter på svar fra deg',
      servicesWithPlannedWork: 'Tjenester berøres av planlagt arbeid',
      overdueInvoices: 'Forfalte fakturaer',
      activeIncidents: 'Aktive feilmeldinger',
      activeOrders: 'Aktive ordre',
      servicesAssigning: 'Tjenester under levering',
      servicesTerminating: 'Tjenester under terminering',
    },
  },

  Dashboard2: {
    greeting: (name: string | null | undefined) =>
      name ? `Hei, ${name}!` : null,
    welcome: (name: string) =>
      `Her er en oversikt over dagens saker for ${name}, ${formatDate(
        new Date(),
        'long',
        'nb'
      )}.`,
  },

  DashboardTags: {
    heading: 'Tags',
    introText:
      'Med tags kan du sortere tjenestene dine på en måte som er logisk for din bedrift. ' +
      'Du kan bruke dem til å filtrere i lister og søketreff, gruppere tjenester i ' +
      ' rapporter og styre hvem som skal ha tilgang til tjenestene i portalen.',
  },

  DashboardGrid: {
    activeIncidents: (n?: number | null) =>
      (n !== 1 ? 'aktive feilmeldinger' : 'aktiv feilmelding') as string,
    activeSolvedIncidents: (n?: number | null) =>
      (n !== 1 ? 'feilmeldinger' : 'feilmelding') + ' løst siste 5 dager',
    servicesWithUnreportedIncidents: (n?: number | null) =>
      `tjeneste${n !== 1 ? 'r' : ''} med umeldte feil`,
    incidentsWaitingForClient: (n?: number | null) =>
      `feilmelding${n !== 1 ? 'er' : ''} venter på svar`,
    activeOrders: (n?: number | null) =>
      (n !== 1 ? 'aktive ordre' : 'aktiv ordre') as string,
    overdueInvoices: (n: number | null) =>
      (n !== 1 ? 'forfalte fakturaer' : `forfalt faktura`) as string,
    servicesWithPlannedWork: (n: number | null): string =>
      n !== 1
        ? 'tjenester med planlagt arbeid'
        : `tjeneste med planlagt arbeid`,
  },

  hello: 'Hei!',

  statusForClient: (client?: string) =>
    client
      ? `Status for ${client}, ${formatDate(new Date(), 'long')}`
      : `Status, ${formatDate(new Date(), 'long')}`,

  servicesWithUnreportedIncidents: (count: number) =>
    `${count} tjeneste${count !== 1 ? 'r' : ''} med feil som ikke er meldt inn`,

  incidentsWaitingForClient: (
    count: number,
    client: string | null | undefined
  ) =>
    `${count} feilmelding${count !== 1 ? 'er' : ''} venter på svar${
      client ? ` fra ${client}` : ''
    }`,

  showMeTheServices: 'Vis meg tjenestene',

  showMeXServices: (count: number) =>
    `Vis meg ${count} tjeneste${count !== 1 ? 'r' : ''}`,

  showMeIncidents: 'Vis meg feilmeldinger',

  dataServices: 'Data og internett-tjenester',

  mobileServices: 'Mobiltjenester',

  teleServices: 'Telefonitjenester',

  activeIncidents: (count: number) =>
    count === 1 ? '1 aktiv feilmelding' : `${count} aktive feilmeldinger`,

  unpaidInvoices: (count: number) =>
    count === 1 ? '1 ubetalt faktura' : `${count} ubetalte fakturaer`,

  tagsHeading: 'Tags',

  tagsComments: [
    'Med tags kan du ...',
    '...filtrere lister og søketreff',
    '...gruppere tjenester i rapporter',
    '...styre hvem som har tilgang til tjenestene',
  ],

  footerHeading: 'Lurer du på noe?',

  supportContactLabel: 'Kundeservice',
  supportContactDetails: 'Hjelper deg med spørsmål om tjenester og faktura',

  contactCardEmail: 'E-post:',
  contactCardPhone: 'Telefon:',
  cannyApp: {
    header: 'Gi tilbakemelding',
    feedbackLabel: 'Tilbakemelding',
    feedbackDetails: 'Se hva vi jobber med og gi tilbakemelding',
    linkLabel: 'Gi tilbakemelding',
  },
  plannedWork: {
    date: 'Dato',
    time: 'Tidspunkt',
    referenceNumber: 'Referansenummer',
    affectedServices: 'Påvirkede tjenester',
    numberOfAffectedServices: 'Antall tjenester',
    startTime: 'start',
    endTime: 'slutt',
    emergency: 'Nødstilfelle',
    emergencyDesription:
      'Nødvendig endring som utføres for å rette en feil eller unngå/begrense feil.',
    nextTwoWeeks: 'Neste 14 dager',
    all: 'Alle tilfeller',
    close: 'Lukk',
    impactedSubscription: 'Påvirkede tjenester',
    plannedWork: 'Planlagt arbeid',
    to: 'til',
    noPlannedWork: 'Ingen tilfeller av planlagt arbeid',
    servicesAffected: (num: number) =>
      num === 1 ? `${num} tjeneste påvirkes` : `${num} tjenester påvirkes`,
    download: 'Last ned',
  },
};

export default dashboard;
