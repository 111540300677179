import * as React from 'react';
import { urlFor, ListHeader, Button } from 'src/lib/ui';
import { Toggle } from '@telia/styleguide/business';

import { CostContext, useCostContext } from './CostContext';
import { Redirect } from 'react-router';
import { Organisation } from './types';

import { PeriodSelector } from '.';
import { usePeriodFilter } from 'src/lib/filters';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
import FilterSection from 'src/areas/main/common/FilterSection';

import { t } from 'src/lib/i18n';

interface Props {
  organisationId: string;
  children: (
    costContext: CostContext,
    organisation: Organisation
  ) => React.ReactNode;
}

export const OrganisationPage: React.FC<Props> = ({
  organisationId,
  children,
}) => {
  const costContext = useCostContext();
  const periodFilter = usePeriodFilter({
    costPeriods: costContext.costPeriods,
    period: costContext.activePeriod,
    setPeriod: costContext.setActivePeriod,
  });

  const organisation = costContext.getOrganisationById(organisationId);

  if (!organisation) {
    // Organisation not found!
    // Possible explanations:
    //   - Can be a valid organisation for another customer
    //   - Can be a organisation since deleted
    //   - Typo in the URL

    // Now, we'll just redirect to the root organisation.
    return (
      <Redirect to={urlFor({ organisation: costContext.rootOrganisationId })} />
    );
  }

  const rootUrl = urlFor({ area: 'cost', organisation: organisation.id });
  const i18n = t.costs.OrganisationPage;

  return (
    <>
      <PageHeader
        title={organisation ? `${organisation.id} ${organisation.name}` : ''}
        navTabs={[
          {
            url: rootUrl,
            label: i18n.Pageheader.navTabs.summary,
            exact: true,
          },
          {
            url: `${rootUrl}/detaljer`,
            label: i18n.Pageheader.navTabs.details,
          },
          {
            url: `${rootUrl}/fakturaer`,
            label: i18n.Pageheader.navTabs.invoice,
          },
        ]}
        breadcrumbs={[
          {
            url: urlFor({ area: 'cost' }),
            title: i18n.Pageheader.breadcrumbs.cost,
          },
        ]}
      />
      <FilterSection
        currentView="list"
        filterOptions={[periodFilter]}
        totalFiltersActive={1}
        actions={
          <>
            <Button
              to="/kostnader/velg-fakturasted"
              icon="company"
              color="white"
            >
              {i18n.Pageheader.actions.chooseOrg}
            </Button>
            <Button
              to="/kostnader/velg-kostnadssted"
              icon="company"
              color="white"
            >
              {i18n.Pageheader.actions.chooseCostCenter}
            </Button>
          </>
        }
      />

      <ListHeader className="mb-4">
        <PeriodSelector
          className="px-4"
          setActivePeriodType={costContext.setPeriodBy}
          activePeriodType={costContext.periodBy}
        />

        <form className="ml-auto">
          <Toggle
            id="OrganisationHeader-recursive"
            checked={
              costContext.recursive !== undefined ? costContext.recursive : true
            }
            onChange={e => costContext.setRecursive(e.target.checked)}
          />
          <label
            htmlFor="OrganisationHeader-recursive"
            className="pl-3"
            style={{ fontSize: '16px', color: '#6b6e98' }}
          >
            {i18n.ListHeader.toggleBtn(organisation.numberOfDescendants)}
          </label>
        </form>
      </ListHeader>

      {children(costContext, organisation)}
    </>
  );
};
