import gql from 'graphql-tag';
import { Contact, ContactFragment } from 'src/lib/types';
import { useMutation } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { mapRolesToInput } from '../lib/editContact/utils';
import { Personalia, Role } from '../lib/createContact/types';
import { UserFragment } from 'src/lib/types/contact/UserFragment';
import * as t from './__types/useCreatePerson';
import { trackContactCreate } from 'src/lib/analytics';

const MUTATION_CREATE_PERSON = gql`
  mutation useCreatePerson($input: CreatePersonInput, $customerId: ID!) {
    createPerson(input: $input) {
      contact {
        ...ContactSearchResult
        user {
          ...UserFragment
        }
      }
      errors {
        code
        message
      }
    }
  }
  ${ContactFragment}
  ${UserFragment}
`;

export interface CreatePersonResult {
  createPerson: (personalia: Personalia, roles?: Role[]) => void;
  createUserOnContact: (id: string, roles?: Role[]) => void;
  data?: Contact;
  loading: boolean;
  hasError: boolean;
  error?: t.useCreatePerson_createPerson_errors[] | null;
}

export const useCreatePerson = (): CreatePersonResult => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    t.useCreatePerson,
    t.useCreatePersonVariables & { customerId: string }
  >(MUTATION_CREATE_PERSON, { errorPolicy: 'all' });
  const createPerson = (personalia: Personalia, roles?: Role[]) => {
    trackContactCreate();
    const mappedRoles = roles ? mapRolesToInput(roles) : undefined;
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          ...personalia,
          roles: mappedRoles?.length ? mappedRoles : undefined,
        },
        customerId: customer.id,
      },
    });
  };

  const createUserOnContact = (contactId: string, roles?: Role[]) => {
    const mappedRoles = roles ? mapRolesToInput(roles) : undefined;
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          firstName: '',
          lastName: '',
          email: '',
          contactId,
          roles: mappedRoles?.length ? mappedRoles : undefined,
        },
        customerId: customer.id,
      },
    });
  };

  return {
    createPerson,
    createUserOnContact,
    data: data?.createPerson?.contact ?? undefined,
    loading,
    hasError: !!error || !!data?.createPerson?.errors?.length,
    error: data?.createPerson?.errors,
  };
};
