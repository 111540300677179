import * as React from 'react';
import cs from 'classnames';

import './ExpandButton.scss';
import { Icon } from '@telia/styleguide';

interface Props {
  toggle?: () => void;
  active?: boolean;
  className?: string;
  rounded?: boolean;
}

export const ExpandButton: React.SFC<Props> = props => (
  <div
    className={cs(
      `ExpandButton`,
      'ExpandButton-' + props.className ? props.className : undefined,
      props.rounded ? 'ExpandButton-rounded' : undefined
    )}
  >
    <div className="ExpandButton-iconWrapper" onClick={props.toggle}>
      <div style={{ marginBottom: '4px' }}>
        <Icon
          style={{ height: '1rem', width: '1rem' }}
          icon={props.active ? 'arrow-down' : 'arrow-right'}
        />
      </div>
    </div>
  </div>
);
