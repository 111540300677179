import * as React from 'react';
import { AlterFileFunc } from 'src/areas/main/documents/mutations/useAlterFile';
import { t } from 'src/lib/i18n';
import { AlterFolderFunc } from '../mutations/useAlterFolder';
import { RestoreFileFunction } from '../mutations/useRestoreFile';
import { File, Folder } from 'src/lib/types';

export const getUndoRenameFileAction = (
  file: File,
  newName: string,
  func: AlterFileFunc
) => {
  const undoMsg = (
    <span>
      {t.documents.UndoActions.renamedFileA} <b>{file.name}</b>
      {t.documents.UndoActions.renamedFileB}
      <b>{newName}</b>
    </span>
  );

  return {
    mutation: () => func(file.id, file.folderId, file.name),
    msg: undoMsg,
  };
};

export const getUndoRenameFolderAction = (
  folder: Folder,
  newName: string,
  func: AlterFolderFunc
) => {
  const undoMsg = (
    <span>
      {t.documents.UndoActions.renamedFolderA} <b>{folder.name}</b>
      {t.documents.UndoActions.renamedFolderB}
      <b>{newName}</b>
    </span>
  );

  return {
    mutation: (cb?: () => void) =>
      func(folder.id, folder.parentId, folder.name, cb),
    msg: undoMsg,
  };
};

export const getUndoDeleteFolderAction = (
  folder: Folder,
  func: (id: string, cb?: () => void) => void
) => {
  const undoMsg = (
    <span>
      {t.documents.UndoActions.deletedFolder} <b>{folder.name}</b>
    </span>
  );

  return {
    mutation: (cb?: () => void) => func(folder.id, cb),
    msg: undoMsg,
  };
};

export const getUndoDeleteFileAction = (
  file: File,
  func: RestoreFileFunction
) => {
  const undoMsg = (
    <span>
      {t.documents.UndoActions.deletedFile} <b>{file.name}</b>
    </span>
  );

  return {
    mutation: (cb?: () => void) => func(file.id, cb),
    msg: undoMsg,
  };
};

export const getUndoFileMoveAction = (
  data: File,
  parentFolderName: string,
  func: AlterFileFunc
) => {
  const undoMsg = (
    <span>
      {t.documents.UndoActions.movedFile} <b>{data.name}</b>{' '}
      {t.documents.UndoActions.to} <b>{parentFolderName}</b>
    </span>
  );

  return {
    mutation: (cb?: () => void) => func(data.id, data.folderId, data.name),
    msg: undoMsg,
  };
};

export const getUndoFolderMoveAction = (
  data: Folder,
  parentFolderName: string,
  func: AlterFolderFunc
) => {
  const undoMsg = (
    <span>
      {t.documents.UndoActions.movedFolder} <b>{data.name}</b>{' '}
      {t.documents.UndoActions.to} <b>{parentFolderName}</b>
    </span>
  );

  return {
    mutation: (cb?: () => void) => func(data.id, data.parentId, data.name, cb),
    msg: undoMsg,
  };
};
