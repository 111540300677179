import { t } from 'src/lib/i18n';

interface Dates {
  /**
   * For Navi orders
   */
  committedDeliveryDate?: string | null;
  estimatedDeliveryDate?: string | null;
  plannedDeliveryDate?: string | null;

  /**
   * For insight orders
   */
  estimatedAt?: string | null;
  closedAt?: string | null;
}

type Result =
  | undefined
  | {
      status: string;
      date: string;
    };

export function pickMostPreciseDeliveryDate(dates?: Dates | null): Result {
  const i18n = t.orders.ServiceOrderContent;

  // Pick one of these, in prioritised order

  /**
   * For Navi orders
   */
  if (dates?.committedDeliveryDate) {
    return {
      status: i18n.deliveryDateStatus.committed,
      date: dates.committedDeliveryDate,
    };
  }

  if (dates?.plannedDeliveryDate) {
    return {
      status: i18n.deliveryDateStatus.planned,
      date: dates.plannedDeliveryDate,
    };
  }

  if (dates?.estimatedDeliveryDate) {
    return {
      status: i18n.deliveryDateStatus.estimated,
      date: dates.estimatedDeliveryDate,
    };
  }

  /**
   * For Insight orders
   */

  if (dates?.closedAt) {
    return {
      status: i18n.deliveryDateStatus.closed,
      date: dates.closedAt,
    };
  }
  if (dates?.estimatedAt) {
    return {
      status: i18n.deliveryDateStatus.estimated,
      date: dates.estimatedAt,
    };
  }

  return undefined;
}
