import * as React from 'react';
import cs from 'classnames';

import './BottomOverlay.scss';

interface Props {
  className?: string;
  ignoreSidebar?: boolean;
}

export const BottomOverlay: React.FC<Props> = props => (
  <div
    className={cs('BottomOverlay', props.className, {
      ignoreSidebar: props.ignoreSidebar,
    })}
  >
    {props.children}
  </div>
);
