import React, { useState, useEffect, useMemo } from 'react';
import { Option } from './index';
import { useWindowScroll } from 'src/areas/main/documents/table/useWindowScroll';
import isEqual from 'lodash/isEqual';
export const useOptionSelect = (props: {
  options: Option[];
  allowMultiple?: boolean;
  selectedOptions?: string[];
  partiallySelectedOptions?: string[];
  containerRef?: React.RefObject<HTMLDivElement>;
  /**
   * action options to render at the bottom of the options list
   */
  actionOptions?: Option[];
}) => {
  const [selectedOptions, setSelectedOptions] = useState<string[]>(
    props.selectedOptions ?? []
  );
  const [partiallySelectedOptions, setPartiallySelectedOptions] = useState<
    string[]
  >(props.partiallySelectedOptions ?? []);
  const [filteredResult, setFilteredResult] = useState(props.options ?? []);

  useEffect(() => {
    setSelectedOptions(props.selectedOptions ?? []);
  }, [props.selectedOptions]);
  useEffect(
    () => setPartiallySelectedOptions(props.partiallySelectedOptions ?? []),
    [props.partiallySelectedOptions]
  );

  const filterResults = (queryString: string) => {
    return props.options?.filter(option =>
      option?.label.toLowerCase().includes(queryString.toLowerCase())
    );
  };
  const rowHeight = 60;

  const options = useMemo(
    () => [...filteredResult, ...(props.actionOptions ?? [])],
    [props.actionOptions, filteredResult]
  );

  /**
   * We get our list to render from window scroll to support large datasets as options,
   * while maintaining a quick ui response
   */
  const renderList = useWindowScroll<Option>({
    allNodes: options,
    rowHeight,
    initialOffsetTop: props.containerRef?.current?.offsetTop || 0,
    scrollRef: props.containerRef,
  });

  const filterResultAndSetState = (input: string) =>
    setFilteredResult(filterResults(input));

  const handleClick = (option: Option) => {
    /**
     * If the clicked option is partially selected,
     * we remove it from parial selection and add to fully selected
     */
    if (optionIsPartiallySelected(option)) {
      removeFromPartialSelection(option);
      return addOption(option);
    }
    return optionIsSelected(option) ? removeOption(option) : addOption(option);
  };

  const optionIsSelected = (option: Option) =>
    option &&
    !!selectedOptions?.find(selectedValue => selectedValue === option.value);

  const optionIsPartiallySelected = (option: Option) =>
    option && !!partiallySelectedOptions?.find(value => value === option.value);

  const removeOption = (option: Option) =>
    props.allowMultiple
      ? setSelectedOptions(
          selectedOptions.filter(
            selectedValue => selectedValue !== option.value
          )
        )
      : setSelectedOptions([]);

  const addOption = (option: Option) =>
    props.allowMultiple
      ? setSelectedOptions([...selectedOptions, option.value])
      : setSelectedOptions([option.value]);

  /**
   * Some options may be partially selected.
   * When clicked these options will become checked and removed from the list of partially selected options.
   *
   * It is not possible to partilly select an options -- this state can only come from the initila state
   */

  const removeFromPartialSelection = (option: Option) =>
    setPartiallySelectedOptions(
      partiallySelectedOptions.filter(o => o !== option.value)
    );
  const reset = () => {
    setSelectedOptions([]);
  };

  /**
   * If no changes have been made (initial state === current state),
   * we disable the options to save
   */
  const disableSave =
    isEqual(props.selectedOptions, selectedOptions) &&
    (isEqual(props.partiallySelectedOptions, partiallySelectedOptions) ||
      !props.partiallySelectedOptions);
  return {
    options: renderList,
    filterResults: filterResultAndSetState,
    selectedOptions,
    partiallySelectedOptions,
    setSelectedOptions,
    rowHeight,
    handleClick,
    reset,
    optionIsSelected,
    optionIsPartiallySelected,
    disableSave,
  };
};
