import * as React from 'react';
import { Loading, urlFor } from 'src/lib/ui';
import { RouteComponentProps, Redirect } from 'react-router';
import OrderingFooter from '../layout/OrderingFooter';

import { Process, Step } from 'src/lib/flow';
import { formatOrderingDateToDateString } from 'src/lib/utils/dateUtils';
import { useCustomer } from 'src/lib/global';
import { t } from 'src/lib/i18n';

import WithCreateNewMobileSubscriptionOrder, {
  CreateNewMobileSubscriptionOrderInput,
} from './WithCreateNewMobileSubscriptionOrder';
import WithPortMobileSubscriptionOrder, {
  PortMobileSubscriptionOrderInput,
} from './WithPortMobileSubscriptionOrder';
import WithMobile, { MobileProductsData } from '../queries/WithMobile';
import OrderMobileSubscriptionFailure from './OrderMobileSubscriptionFailure';

import Step1, { Step1Values } from './Step1';
import Step2, { Step2Values } from './Step2';
import Step3, { Step3Values } from './Step3';
import Step4, { Step4Values } from './Step4';
import Step5, { Step5Values } from './Step5';
import { MobileType } from '../types';
import { SubscriptionTaxModel } from 'src/__types/graphql-global-types';
import { useReserveOrderNumber } from '../queries/useReserveNumber';

export type FormerOwnerTypes = 'self' | 'private' | 'company';
export type PhoneNumberTypes = 'newNumber' | 'existingNumber';
export type SimcardTypes = 'newSimcard' | 'existingSimcard';
export type ActivationDateType = 'default' | 'custom';

/**
 * This describes the state of the whole flow (_without_ metadata like created/last touch etc.)
 * Note that all fields must be optional! The object will always start out entirely empty.
 * Alternatives: optionally provide default? or automatically provide Partial<>?
 */

export interface OrderMobileSubscriptionState
  extends Step1Values,
    Step2Values,
    Step3Values,
    Step4Values,
    Step5Values {}

type MobileOrderContextType = MobileProductsData & { isBergen?: boolean };

const { Provider, Consumer } = React.createContext<MobileOrderContextType>(
  {} as MobileOrderContextType
);

export { Consumer as MobileOrderConsumer };

const getAdditionalProducts = (additionalProducts: {
  [key: string]: string[];
}) => {
  let additionalProductsArray: string[] = [];
  Object.values(additionalProducts).forEach(products => {
    additionalProductsArray = additionalProductsArray.concat(products);
  });
  return additionalProductsArray;
};

const OrderMobileSubscription = (
  props: RouteComponentProps & {
    orderingType: MobileType;
    orderReference: string;
  }
) => {
  const {
    reserveOrderNumber,
    data: reserveOrderNumberResult,
  } = useReserveOrderNumber(props.orderReference);
  const customer = useCustomer();
  const mobileType = props.orderingType === 'm2m' ? 'm2m' : 'mobile';
  const i18n = t.ordering.OrderMobileSubscription;

  const createMobileOrder = (
    stateValues: OrderMobileSubscriptionState,
    handler: (input: CreateNewMobileSubscriptionOrderInput) => void
  ) => {
    const additionalProductsArray = stateValues.additionalProducts
      ? getAdditionalProducts(stateValues.additionalProducts)
      : [];

    if (
      stateValues.organisation &&
      stateValues.firstName &&
      stateValues.lastName &&
      stateValues.selectedPackage
    ) {
      handler({
        phoneNumber: stateValues.phoneNumber,
        organisationId: stateValues.organisation,
        costCenterId: stateValues.costCenter,
        personalDetails: {
          firstName: stateValues.firstName,
          lastName: stateValues.lastName,
          jobTitle: stateValues.jobTitle,
          // localNumber: stateValues.localNumber,
          email: stateValues.email,
          employeeNumber: stateValues.employeeNumber,
        },
        simCardId:
          stateValues.simcardSource === 'existingSimcard'
            ? stateValues.simcardNumber
            : undefined,
        newSimCardRecipient:
          stateValues.newSimCardRecipient &&
          stateValues.simcardSource === 'newSimcard'
            ? {
                attention: stateValues.newSimCardRecipient.attention,
                name: stateValues.newSimCardRecipient.name,
                address: stateValues.newSimCardRecipient.address,
                postalArea: stateValues.newSimCardRecipient.postalArea,
                postalCode: stateValues.newSimCardRecipient.postalCode,
              }
            : undefined,
        taxModel: stateValues.taxModel,
        products: [...stateValues.selectedPackage, ...additionalProductsArray],
        flex1: stateValues.flex1,
        flex2: stateValues.flex2,
      });
    }
  };
  const portMobileOrder = async (
    stateValues: OrderMobileSubscriptionState,
    handler: (input: PortMobileSubscriptionOrderInput) => void
  ) => {
    const additionalProductsArray = stateValues.additionalProducts
      ? getAdditionalProducts(stateValues.additionalProducts)
      : [];

    if (
      stateValues.selectedPackage &&
      stateValues.phoneNumber &&
      stateValues.organisation &&
      stateValues.firstName &&
      stateValues.lastName
    ) {
      handler({
        activationDate: stateValues.activationDate
          ? formatOrderingDateToDateString(stateValues.activationDate)
          : undefined,
        phoneNumber: stateValues.phoneNumber,
        organisationId: stateValues.organisation,
        costCenterId: stateValues.costCenter
          ? stateValues.costCenter
          : undefined,
        personalDetails: {
          firstName: stateValues.firstName,
          lastName: stateValues.lastName,
          jobTitle: stateValues.jobTitle,
          // localNumber: stateValues.localNumber,
          email: stateValues.email,
          employeeNumber: stateValues.employeeNumber,
        },
        simCardId:
          stateValues.simcardSource === 'existingSimcard'
            ? stateValues.simcardNumber
            : undefined,
        newSimCardRecipient:
          stateValues.newSimCardRecipient &&
          stateValues.simcardSource === 'newSimcard'
            ? {
                attention: stateValues.newSimCardRecipient.attention,
                name: stateValues.newSimCardRecipient.name,
                address: stateValues.newSimCardRecipient.address,
                postalArea: stateValues.newSimCardRecipient.postalArea,
                postalCode: stateValues.newSimCardRecipient.postalCode,
              }
            : undefined,
        formerOwner: generateFormerOwnerObject(stateValues),
        taxModel: stateValues.taxModel,
        products: [...stateValues.selectedPackage, ...additionalProductsArray],
        transferPaymentsToNewOwner: stateValues.transferPaymentsToNewOwner,
        flex1: stateValues.flex1,
        flex2: stateValues.flex2,
      });
    }
  };
  const generateFormerOwnerObject = (
    stateValues: OrderMobileSubscriptionState
  ) => {
    if (
      stateValues.formerOwner &&
      stateValues.formerOwnerSource === 'private'
    ) {
      return {
        firstName: stateValues.formerOwner.firstName,
        lastName: stateValues.formerOwner.lastName,
        birthDate: formatOrderingDateToDateString(
          stateValues.formerOwner.birthDate
        ),
      };
    }
    if (
      stateValues.formerOwner &&
      stateValues.formerOwnerSource === 'company'
    ) {
      return {
        businessName: stateValues.formerOwner.businessName,
        organisationNumber: stateValues.formerOwner.organisationNumber,
      };
    }
    // user himself, no former owner
    return {
      firstName: stateValues.firstName,
      lastName: stateValues.lastName,
      birthDate: stateValues.birthDate
        ? formatOrderingDateToDateString(stateValues.birthDate)
        : '',
    };
  };

  return (
    <WithMobile mobileType={mobileType}>
      {mobileContext => (
        <WithPortMobileSubscriptionOrder>
          {portMobileSubscriptionMutation => (
            <WithCreateNewMobileSubscriptionOrder>
              {createMobileSubscriptionMutation => {
                if (mobileContext.loading) {
                  return <Loading />;
                }
                if (
                  portMobileSubscriptionMutation.loading ||
                  createMobileSubscriptionMutation.loading
                ) {
                  return <Loading loadingText="Sender bestilling" />;
                }
                if (createMobileSubscriptionMutation.data) {
                  if (
                    createMobileSubscriptionMutation.data.orderDetails &&
                    createMobileSubscriptionMutation.data.orderDetails.orderId
                  ) {
                    return (
                      <Redirect
                        to={urlFor({
                          order:
                            createMobileSubscriptionMutation.data.orderDetails
                              .orderId,
                        })}
                      />
                    );
                  }
                  return (
                    <OrderMobileSubscriptionFailure
                      failInfo={i18n.orderingWarning}
                    />
                  );
                }
                if (portMobileSubscriptionMutation.data) {
                  if (
                    portMobileSubscriptionMutation.data.orderDetails &&
                    portMobileSubscriptionMutation.data.orderDetails.orderId
                  ) {
                    return (
                      <Redirect
                        to={urlFor({
                          order:
                            portMobileSubscriptionMutation.data.orderDetails
                              .orderId,
                        })}
                      />
                    );
                  }
                  return (
                    <OrderMobileSubscriptionFailure
                      failInfo={i18n.orderingWarning}
                    />
                  );
                }

                if (
                  portMobileSubscriptionMutation.error ||
                  createMobileSubscriptionMutation.error
                ) {
                  return (
                    <OrderMobileSubscriptionFailure
                      failInfo={i18n.orderingError}
                    />
                  );
                }

                // NOTE: InitialValues must be initialized for onSubmit to correctly set all fields to touched.
                return (
                  <Provider
                    value={{
                      ...mobileContext,
                      isBergen: customer.id === 'C-NO-25351',
                    }}
                  >
                    <Process<OrderMobileSubscriptionState>
                      initialValues={{
                        phoneNumberSource: 'existingNumber' as PhoneNumberTypes,
                        simcardSource: 'existingSimcard' as SimcardTypes,
                        taxModel: SubscriptionTaxModel.none,
                        transferPaymentsToNewOwner: false,
                        activationDateType: 'default' as ActivationDateType,
                      }}
                      footer={({ flow }) => (
                        <OrderingFooter
                          enableSubmit={
                            !flow.isSubmitting &&
                            flow.currentStep === flow.steps.length - 1
                          }
                          next={flow.submit}
                          cancel={async () => {
                            await flow.cancel();
                            props.history.push('/bestilling');
                          }}
                          save={async () => {
                            await flow.save();
                            props.history.push('/bestilling');
                          }}
                        />
                      )}
                      steps={
                        [
                          () =>
                            Step1({
                              orderingType: props.orderingType,
                              reserveOrderNumber,
                              reserveOrderNumberResult,
                            }),
                          Step2,
                          Step3,
                          () => Step4({ orderingType: props.orderingType }),
                          Step5,
                        ] as Array<Step<OrderMobileSubscriptionState>>
                      }
                      onSubmit={(values, actions) => {
                        if (values.phoneNumberSource === 'newNumber') {
                          return createMobileOrder(
                            values,
                            createMobileSubscriptionMutation.createNewMobileSubscriptionOrder
                          );
                        }
                        return portMobileOrder(
                          values,
                          portMobileSubscriptionMutation.portMobileSubscriptionOrder
                        );
                      }}
                      setFlowId={(id: string) =>
                        props.history.replace({
                          pathname: props.location.pathname,
                          search: `continue=${id}`,
                        })
                      }
                      url={props.location.pathname}
                    />
                  </Provider>
                );
              }}
            </WithCreateNewMobileSubscriptionOrder>
          )}
        </WithPortMobileSubscriptionOrder>
      )}
    </WithMobile>
  );
};

export default OrderMobileSubscription;
