import * as React from 'react';
import { BodyCard, Attributes, Attribute, Toolbar, Button } from 'src/lib/ui';
import { UserDetails, OriginDetails } from 'src/areas/mobile/types';
import { t } from 'src/lib/i18n';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { ProfileFormModal } from '../../settings/forms/ProfileFormModal';

interface Props {
  user: UserDetails;
  origin: OriginDetails;
  customer?: string | null;
}

const OverviewProfileCard: React.SFC<Props> = ({ user, origin, customer }) => {
  const i18n = t.mobile.overview.profile;
  const [editProfile, setEditProfile] = React.useState(false);
  const { id } = useSubscriptionContext();

  return (
    <>
      <BodyCard borders={true}>
        <h2>{i18n.profile}</h2>
        <Attributes>
          <Attribute
            label={i18n.name}
            value={user?.firstName + ' ' + user?.lastName}
          />
          <Attribute label={i18n.email} value={user?.email} />
          <Attribute label={i18n.address} value={origin?.streetAddress} />
          <Attribute label={i18n.phoneNumber} value={id} />
          <Attribute label={i18n.organisation} value={customer} />
          <Attribute label={i18n.employeeNum} value={user?.employeeNum} />
          <Attribute label={i18n.position} value={user?.position} />
        </Attributes>
        <Toolbar className="mt-4">
          <Button color="white" onClick={() => setEditProfile(true)} icon="pen">
            {i18n.change}
          </Button>
        </Toolbar>
      </BodyCard>

      <ProfileFormModal
        open={editProfile}
        setOpen={setEditProfile}
        userDetails={user}
        origin={origin}
      />
    </>
  );
};
export default OverviewProfileCard;
