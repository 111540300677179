import React, { useContext } from 'react';
import { SearchHistoryEntry } from 'src/lib/localState/types';
import { UserPreferences } from './UserPreferencesProvider';
import { Locale } from 'src/lib/i18n';

export interface UserPreferencesContext {
  activeCustomerId?: string;
  activeCustomerName?: string;
  searchHistoryEntries?: SearchHistoryEntry[];
  showRecursiveCost?: boolean;
  deleteSearchHistoryEntry: (idx: number) => void;
  addSearchHistoryEntry: (query: string) => void;
  set: (partialState: Partial<UserPreferences>) => void;
  setPreferredCustomer: (customerId: string, customerName: string) => void;
  setRecursiveCostPreference: (preference: boolean) => void;
  preferredLocale?: Locale;
  setPreferredLocale: (locale: Locale) => void;
}

const userPreferencesContext = React.createContext<UserPreferencesContext>(
  {} as UserPreferencesContext
);

const { Provider, Consumer } = userPreferencesContext;

const useUserPreferences = () => useContext(userPreferencesContext);

export { Provider, Consumer as WithUserPreferences, useUserPreferences };
