import { useSearchTag } from 'src/areas/main/subscriptions/queries/useSearchTag';
import { ApolloQueryResult } from 'apollo-client';
import * as t from 'src/areas/main/subscriptions/queries/__types/useSearchTag';
import { useSearchTagVariables } from './../queries/__types/useSearchTag';
import sortBy from 'lodash/sortBy';

export default function useGetTagOptions(
  input: string
): {
  loading: boolean;
  result: Array<{ value: string; label: string; negative?: boolean }>;
  refetch: (
    variables?: useSearchTagVariables | undefined
  ) => Promise<ApolloQueryResult<t.useSearchTag>>;
} {
  // fetch all customer tags. Limit in ES is 10K
  const { data, refetch, loading } = useSearchTag({
    filterQuery: input,
    size: 10000,
  });
  return {
    loading,
    refetch,
    result:
      loading && !data
        ? []
        : sortBy(data, wrappedTagObject => wrappedTagObject.tag.tag).map(
            ({ tag }) => ({
              value: tag.id,
              label: tag.tag,
            })
          ),
  };
}
