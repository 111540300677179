import React, { useMemo } from 'react';
import { Icon } from '@telia/styleguide';
import { Attributes, Attribute, colors } from 'src/lib/ui';
import {
  NewStatisticsDetails,
  NewStatisticsDetailsBlock,
} from '../../lib/StatisticsDetails';
import { StandardStatistics } from '../../queries/WithStatistics';
import { mapStandardDataDetails } from './mapStandardDataDetails';
import { t } from 'src/lib/i18n';
import { getGranularity } from '../../utils/getGranularity';

interface StandardDetailsProps {
  data: StandardStatistics;
  showCumulativeInMB?: boolean;
  // NOT in use for now
  // serviceBandwidth?: {
  //   upstream?: number;
  //   downstream?: number;
  // };
}

/**
 * Convinience function to return a Attribute with common props.
 */
const attr = (label: string, value: React.ReactNode) => (
  <Attribute label={label} value={value} displayBlock={true} />
);

export const StandardDetails: React.FunctionComponent<StandardDetailsProps> = props => {
  const i18n = t.statistics.StandardDetails;
  const { inAttributes, outAttributes, otherAttributes } = useMemo(() => {
    const detailedData = mapStandardDataDetails(props.data);

    const inAttributes: Array<React.ReactNode> = [];
    const outAttributes: Array<React.ReactNode> = [];
    const otherAttributes: Array<React.ReactNode> = [];
    if (detailedData) {
      if (detailedData.throughput) {
        inAttributes.push(
          attr(i18n.throughputAvg, detailedData.throughput.averageIn),
          attr(i18n.throughputMax, detailedData.throughput.maxIn)
        );

        outAttributes.push(
          attr(i18n.throughputAvg, detailedData.throughput.averageOut),
          attr(i18n.throughputMax, detailedData.throughput.maxOut)
        );
      }
      if (detailedData.packetDrop) {
        inAttributes.push(
          attr(i18n.packetDrop, detailedData.packetDrop.averageIn)
        );
        outAttributes.push(
          attr(i18n.packetDrop, detailedData.packetDrop.averageOut)
        );
      }
      if (detailedData.responseTime) {
        otherAttributes.push(
          attr(i18n.responseTimeAvg, detailedData.responseTime.average)
        );
        otherAttributes.push(
          attr(i18n.responseTimeMax, detailedData.responseTime.max)
        );
      }
      if (detailedData.traffic) {
        inAttributes.push(
          // TODO: ASK HG about average cumulative means!
          // attr(i18n.cumulativeAvg, detailedData.traffic.averageIn),
          attr(i18n.cumulativeTotal, detailedData.traffic.sumIn)
        );
        outAttributes.push(
          // attr(i18n.cumulativeAvg, detailedData.traffic.averageOut),
          attr(i18n.cumulativeTotal, detailedData.traffic.sumOut)
        );
      }
    }

    return {
      inAttributes,
      outAttributes,
      otherAttributes,
    };
  }, [props.data, i18n]);

  return (
    <>
      <div className="grey-dark-text">{`${
        t.statistics.granularity
      }: ${getGranularity(props.data.granularity)}`}</div>
      <NewStatisticsDetails className="pt-4">
        {inAttributes.length > 0 ? (
          <NewStatisticsDetailsBlock
            heading={
              <span className="d-inline-flex align-items-center">
                {i18n.in}
                <Icon
                  icon="arrow-large-up"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '0.5rem',
                    transform: 'rotate(45deg)',
                    fill: '#9b10e3',
                  }}
                />
              </span>
            }
          >
            <Attributes inline={true}>
              {inAttributes.map((attr, idx) => (
                <React.Fragment key={idx}>{attr}</React.Fragment>
              ))}
            </Attributes>
          </NewStatisticsDetailsBlock>
        ) : null}

        {outAttributes.length > 0 ? (
          <NewStatisticsDetailsBlock
            heading={
              <span className="d-inline-flex align-items-center">
                {i18n.out}
                <Icon
                  icon="arrow-large-down"
                  style={{
                    width: '20px',
                    height: '20px',
                    marginLeft: '0.5rem',
                    transform: 'rotate(-45deg)',
                    fill: colors.blueDark,
                  }}
                />
              </span>
            }
          >
            <Attributes inline={true}>
              {outAttributes.map((attr, idx) => (
                <React.Fragment key={idx}>{attr}</React.Fragment>
              ))}
            </Attributes>
          </NewStatisticsDetailsBlock>
        ) : null}

        {otherAttributes.length > 0 ? (
          <NewStatisticsDetailsBlock
            fullWidth={true}
            heading={<span>{i18n.other}</span>}
          >
            <Attributes inline={true}>
              {otherAttributes.map((attr, idx) => (
                <React.Fragment key={idx}>{attr}</React.Fragment>
              ))}
            </Attributes>
          </NewStatisticsDetailsBlock>
        ) : null}
      </NewStatisticsDetails>
    </>
  );
};
