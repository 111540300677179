import { Organisation, Tag } from 'src/lib/types';

export interface Roles {
  orderRole?: Role<'OrderManager'> | undefined;
  sysAdminRole?: Role<'SystemAdministrator'> | undefined;
  financialRole?: Role<'FinancialManager'> | undefined;
  adminRole?: boolean;
}

export type PermissionLevel = 'full' | 'partial' | 'none';

export type MappedRoleName =
  | 'sysAdminRole'
  | 'orderRole'
  | 'financialRole'
  | 'adminRole';

export const mapRoleNames: {
  orderRole: RoleName;
  sysAdminRole: RoleName;
  financialRole: RoleName;
  adminRole: RoleName;
  OrderManager: MappedRoleName;
  SystemAdministrator: MappedRoleName;
  FinancialManager: MappedRoleName;
  CustomerAdministrator: MappedRoleName;
} = {
  orderRole: 'OrderManager',
  sysAdminRole: 'SystemAdministrator',
  financialRole: 'FinancialManager',
  adminRole: 'CustomerAdministrator',
  OrderManager: 'orderRole',
  SystemAdministrator: 'sysAdminRole',
  FinancialManager: 'financialRole',
  CustomerAdministrator: 'adminRole',
};
export interface Personalia {
  firstName: string;
  lastName: string;
  email?: string;
  mobilePhone?: string;
  secondaryPhone?: string;
  description?: string;
  employeeNumber?: string;
}

export type RoleName =
  | 'OrderManager'
  | 'SystemAdministrator'
  | 'CustomerAdministrator'
  | 'FinancialManager';

export interface RadioRoles {
  orderRoleLevel: PermissionLevel;
  systemRoleLevel: PermissionLevel;
  financeRoleLevel: PermissionLevel;
}
export interface Role<T = RoleName> {
  role: T | RoleName;
  organisations?: string[];
  tags?: string[];
  level?: PermissionLevel;
}

export interface SingleRole {
  role: RoleName;
  organisation?: Organisation | null;
  tag?: Tag | null;
}

export type RoleVariable =
  | { customerId?: string; role: RoleName } // Global roles
  | { customerId?: string; role: RoleName; organisationId: string } // Organisation roles
  | { customerId?: string; role: RoleName; tagId: string }; // Tag roles

export type RoleVariableWithoutTagId =
  | { customerId?: string; role: RoleName } // Global roles
  | { customerId?: string; role: RoleName; organisationId: string } // Organisation roles
  | { customerId?: string; role: RoleName; tag: string }; // Tag roles
