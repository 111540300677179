import gql from 'graphql-tag';
import { FileFragment } from 'src/lib/types';
import { FolderFragment } from 'src/lib/types/documents/Folder';

export const ORDER_DOCUMENTS_QUERY = gql`
  query useOrderFilesAndFolders($input: WorklogSearchInput) {
    worklogSearch(input: $input) {
      size
      totalResults
      results {
        __typename
        id
        documents {
          allFolders {
            ...FolderFragment
          }
          allFiles {
            ...FileSearchResult
          }
        }
      }
    }
  }
  ${FileFragment}
  ${FolderFragment}
`;
