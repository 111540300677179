import * as React from 'react';
import { RouteComponentProps } from 'react-router';
import {
  List,
  ListHeader,
  ListResultCard,
  Loading,
  urlFor,
  Button,
} from 'src/lib/ui';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
import { t } from 'src/lib/i18n';
import { useServicePlusAddress } from 'src/areas/main/servicePlus/queries/useServicePlusAddress';
import { useCustomer } from 'src/lib/global';
import { useCreateServicePlusPerson_createServicePlusPerson_servicePlusPerson } from 'src/areas/main/servicePlus/queries/mutations/__types/useCreateServicePlusPerson';
import { map } from 'lodash';
import { useServicePlusPersonsOnAddress } from 'src/areas/main/servicePlus/queries/useServicePlusPersonOnAddress';
import { useDeleteServicePlusAddressMutation } from 'src/areas/main/servicePlus/queries/mutations/useDeleteServicePlusAddress';

export const AddressDetails: React.FC<RouteComponentProps<{
  addressId: string;
}>> = props => {
  const translations = t.servicePlus;
  const addressId = props.match.params.addressId;
  const customer = useCustomer();
  const addressObject = useServicePlusAddress(customer.id, addressId);
  const deleteAddressMutation = useDeleteServicePlusAddressMutation();
  const servicePlusAddress =
    addressObject.servicePlusLocationData?.customer.servicePlusAddress;

  const PersonListCard: React.FC<{
    entry: useCreateServicePlusPerson_createServicePlusPerson_servicePlusPerson;
  }> = ({ entry }) => {
    const { email, firstName, lastName, phone } = entry;
    return (
      <ListResultCard
        className="ListResultCard--Contact"
        icon="user"
        title={`${firstName} ${lastName}`}
        attributes={[
          email ? { value: 'Epost: ' + email } : undefined,
          phone ? { value: 'Telefon: ' + phone } : undefined,
        ]}
      />
    );
  };

  const personsOnAddress = useServicePlusPersonsOnAddress(
    customer.id,
    addressId
  );

  const personsOnAddressList =
    personsOnAddress.servicePlusPersonsOnAddressData?.customer
      .servicePlusPersonsOnAddress;

  const deleteAddress = () => {
    deleteAddressMutation.useDeleteServicePlusAddress(
      customer.id,
      parseInt(addressId, 10)
    );
    props.history.push(`/service-plus/oversikt`);
  };

  return (
    <>
      <PageHeader
        title={
          servicePlusAddress?.address +
            ', ' +
            servicePlusAddress?.postalCode +
            ' ' +
            servicePlusAddress?.city ?? ''
        }
        breadcrumbs={[
          {
            url: urlFor({ area: 'servicePlus' }),
            title: translations.address.servicePlus,
          },
        ]}
      />
      <ListHeader className="mb-4">
        <h1>Varsling for {servicePlusAddress?.address}</h1>
        <Button icon="trash" color="white" onClick={() => deleteAddress()}>
          {' '}
          Slett Varslingsadresse
        </Button>
      </ListHeader>
      {personsOnAddress.servicePlusPersonsOnAddressLoading ? (
        <Loading />
      ) : (
        <div style={{ textAlign: 'center' }}>
          {personsOnAddressList.length > 0 ? (
            <List border={true}>
              {map(personsOnAddressList, result => {
                const EntryComponent = PersonListCard;

                if (!EntryComponent) {
                  return null;
                }
                const key = `${result.id}`;

                return (
                  <EntryComponent
                    key={key}
                    entry={(result as unknown) as any}
                  />
                );
              })}
            </List>
          ) : (
            <h2>Ingen personer er lagt til lokasjonen</h2>
          )}
        </div>
      )}
    </>
  );
};
