import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { SubscriptionContentList } from '../Components/SubscriptionContentList/SubscriptionContentList';

interface TelePhoneNumberListProps {
  subscription?: {
    phoneNumbers?: null | Array<{
      type?: number;
      phoneNumber?: string;
    }>;
  };
}

export const TelePhoneNumberList = ({
  subscription,
}: TelePhoneNumberListProps) => {
  const i18n = t.subscriptions.TeleSubscriptionPage;

  if (!subscription) {
    return null;
  }

  return (
    <SubscriptionContentList heading={i18n.phoneNumbers}>
      {subscription.phoneNumbers?.map((num, idx) => (
        <ListResultCard title={num.phoneNumber ?? ''} key={idx} />
      ))}
    </SubscriptionContentList>
  );
};
