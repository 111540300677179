import { useState } from 'react';
import { useMutation } from 'react-apollo';

import gql from 'graphql-tag';
import * as t from './__types/useAddTagToSubscription';

export const MUTATION_ADD_TAG_TO_SUBSCRIPTION = gql`
  mutation useAddTagToSubscription($input: AddTagToSubscriptionInput) {
    addTagToSubscription(input: $input) {
      error {
        code
      }
      tags {
        id
        tag
      }
    }
  }
`;

export const useAddTagToSubscription = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [mutation, mutationRes] = useMutation<
    t.useAddTagToSubscription,
    t.useAddTagToSubscriptionVariables
  >(MUTATION_ADD_TAG_TO_SUBSCRIPTION);

  const addTagsToSubscription = async (
    subscriptionId: string,
    tags: string[]
  ) => {
    const mutations = tags.map(tag => {
      return mutation({
        variables: {
          input: {
            subscriptionId,
            tag,
          },
        },
      });
    });
    setLoading(true);
    await Promise.all(mutations);
    setLoading(false);
  };
  return {
    addTagsToSubscription,
    loading: loading,
    error: mutationRes.error,
  };
};
