import gql from 'graphql-tag';

export const InvoiceFragment = gql`
  fragment InvoiceSearchResult on Invoice {
    id
    organisation {
      id
      name
    }
    # customerId
    customer {
      id
      name
    }
    status
    date
    dueDate
    amountGross
    amountNet
    vat
  }
`;
