import React from 'react';
import { BodyCard, CardBody, Loading } from 'src/lib/ui';
import { useStatisticsParams } from '../../utils/useStatisticsParams';
import { isAggregatedByDay } from '../../utils/isAggregatedByDay';

import {
  ExtendedQosStatisticsPeriods,
  useExtendedQosStatistics,
} from '../../queries/useExtendedQosStatistics';
import { StandardChart } from '../../components/StandardChart';
import { JitterToolTip, JitterChartElements } from './Jitter';
import {
  RoundTripTimeTooltip,
  RoundTripTimeChartElements,
} from './RoundTripTime';
import { PacketLossTooltip, PacketLossChartElements } from './PacketLoss';
import { mapExtendedQosData } from './mapExtendedQosData';
import { ExtendedQosDetails } from './ExtendedQosDetails';
import { ExtendedQosStatisticsType } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';

export const ExtendedQos = (props: {
  currentView: ExtendedQosStatisticsType;
}) => {
  const types = [props.currentView];
  const params = useStatisticsParams();
  const statisticsQuery = useExtendedQosStatistics({
    subscriptionId: params.subscriptionId,
    periodStart: params.currentStartPeriod,
    periodEnd: params.currentEndPeriod,
    types: types,
  });

  const mappedData = mapExtendedQosData(
    statisticsQuery.data?.statisticsExtendedQos?.periods
  );

  const trafficClasses = mappedData?.find(
    data => data.trafficClasses && data.trafficClasses?.length > 0
  )?.trafficClasses;

  const hideTimestamp = isAggregatedByDay(
    params.currentStartPeriod,
    params.currentEndPeriod
  );

  const getTooltip = (view: string) => {
    switch (view) {
      case 'jitter':
        return JitterToolTip;
      case 'roundTripTime':
        return RoundTripTimeTooltip;
      case 'packetLoss':
        return PacketLossTooltip;
    }
  };

  const chartElements = (view: string, idx: number) => {
    switch (view) {
      case 'jitter':
        return JitterChartElements(idx, view);
      case 'roundTripTime':
        return RoundTripTimeChartElements(idx, view);
      case 'packetLoss':
        return PacketLossChartElements(idx, view);
    }
  };

  return (
    <>
      {statisticsQuery.loading ? (
        <Loading />
      ) : mappedData === null ? (
        <div>{t.statistics.noData}</div>
      ) : trafficClasses && trafficClasses.length > 0 ? (
        trafficClasses.map((tc, idx) => {
          const tooltip = getTooltip(props.currentView);
          return (
            tooltip && (
              <BodyCard noGutter={true}>
                <CardBody>
                  <StandardChart<ExtendedQosStatisticsPeriods>
                    title={tc.name}
                    periods={mappedData}
                    tooltip={tooltip(idx)}
                    hideTimestamp={hideTimestamp}
                  >
                    {chartElements(props.currentView, idx)}
                  </StandardChart>
                  {statisticsQuery.data?.statisticsExtendedQos ? (
                    <ExtendedQosDetails
                      data={statisticsQuery.data?.statisticsExtendedQos}
                      idx={idx}
                    />
                  ) : null}
                </CardBody>
              </BodyCard>
            )
          );
        })
      ) : (
        <BodyCard noGutter={true}>
          <CardBody>
            <div>{t.statistics.noTrafficClasses}</div>{' '}
          </CardBody>
        </BodyCard>
      )}
    </>
  );
};
