import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';

import * as t from './__types/usePersonalizedDashboardData';
import { format, subDays } from 'date-fns';

const query = gql`
  query usePersonalizedDashboardData(
    $customerId: ID!
    $tags: [String!]
    $changelogInput: GetChangelogsInput!
  ) {
    customer(id: $customerId) {
      id

      rootOrganisation {
        id
      }

      servicesWithPlannedWork: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [
            { filter: "cases", value: ["withPlannedWork"] }
            { filter: "tag", value: $tags }
          ]
        }
      ) {
        totalResults
      }

      servicesAssigning: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [
            { filter: "deliveryStatus", value: ["assigning"] }
            { filter: "tag", value: $tags }
          ]
        }
      ) {
        totalResults
      }

      servicesTerminating: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [
            { filter: "deliveryStatus", value: ["terminating"] }
            { filter: "tag", value: $tags }
          ]
        }
      ) {
        totalResults
      }

      subscriptionsWithUnreportedFaults: search(
        input: {
          types: ["subscription"]
          size: 0
          filters: [
            { filter: "cases", value: ["withoutIncidents"] }
            { filter: "status", value: ["critical"] }
            { filter: "tag", value: $tags }
          ]
        }
      ) {
        totalResults
      }

      incidentsOnHoldPendingCustomer: search(
        input: {
          types: ["incident"]
          size: 0
          filters: [
            { filter: "incidentStatus", value: ["onHoldPendingCustomer"] }
            { filter: "tag", value: $tags }
          ]
        }
      ) {
        totalResults
      }

      activeIncidents: search(
        input: {
          types: ["incident"]
          size: 0
          filters: [
            { filter: "active", value: ["true"] }
            { filter: "tag", value: $tags }
          ]
        }
      ) {
        totalResults
      }

      activeSolvedIncidents: search(
        input: {
          types: ["incident"]
          size: 0
          filters: [
            { filter: "active", value: ["true"] }
            { filter: "incidentStatus", value: ["solved"] }
            { filter: "tag", value: $tags }
          ]
        }
      ) {
        totalResults
      }

      newOrders: search(
        input: {
          types: ["order"]
          filters: [{ filter: "orderStatus", value: ["newOrder"] }]
          size: 0
        }
      ) {
        totalResults
      }

      inProgressOrders: search(
        input: {
          types: ["order"]
          filters: [{ filter: "orderStatus", value: ["inProgress"] }]
          size: 0
        }
      ) {
        totalResults
      }

      overdueInvoices: search(
        input: {
          types: ["invoice"]
          filters: [{ filter: "invoiceStatus", value: ["overdue"] }]
          size: 0
        }
      ) {
        totalResults
      }
    }

    changelogs(input: $changelogInput) {
      id
      publishedAt
      headingNorwegian
      bodyNorwegian
      headingEnglish
      bodyEnglish
    }
  }
`;

interface PersonalizedDashboardData {
  loading: boolean;
  customer: {
    id: string;
    name: string;
  };
  servicesWithPlannedWork: number | null;
  subscriptionsWithUnreportedFaults: number | null;
  incidentsOnHoldPendingCustomer: number | null;
  activeIncidents: number;
  activeSolvedIncidents: number;
  activeOrders: number | null;
  overdueInvoices: number | null;
  changelogs: t.usePersonalizedDashboardData_changelogs[] | null;
  servicesAssigning: number | null;
  servicesTerminating: number | null;
}

function getActiveOrders(
  customer?: t.usePersonalizedDashboardData_customer | null
) {
  if (
    customer?.newOrders?.totalResults === null ||
    customer?.newOrders?.totalResults === undefined ||
    customer?.inProgressOrders?.totalResults === null ||
    customer?.inProgressOrders?.totalResults === undefined
  ) {
    return null;
  }
  return (
    customer.newOrders.totalResults + customer.inProgressOrders.totalResults
  );
}

interface Options {
  /**
   * If present and non-empty; filter the data according to these tags.
   */
  filterByTags?: string[];
}

export default function usePersonalizedDashboardData(
  opts: Options
): PersonalizedDashboardData {
  const customer = useCustomer();

  const result = useQuery<
    t.usePersonalizedDashboardData,
    t.usePersonalizedDashboardDataVariables
  >(query, {
    variables: {
      customerId: customer.id,
      tags: opts.filterByTags,
      changelogInput: {
        page: 1,
        // Show at most four of the latest entries in
        // the change log from the last 30 days.
        size: 4,
        from: format(subDays(new Date(), 30), 'YYYY-MM-DD') + ' 00:00:00',
      },
    },
    errorPolicy: 'all',
    skip: !customer.id,
  });

  if (!result.data || !result.data.customer) {
    return {
      loading: result.loading,
      customer: {
        id: customer.id,
        name: customer.name,
      },
      servicesWithPlannedWork: null,
      subscriptionsWithUnreportedFaults: null,
      incidentsOnHoldPendingCustomer: null,
      activeOrders: null,
      activeIncidents: 0,
      activeSolvedIncidents: 0,
      overdueInvoices: null,
      changelogs: null,
      servicesAssigning: null,
      servicesTerminating: null,
    };
  }

  const rdc = result.data.customer;

  return {
    loading: result.loading,
    customer: {
      id: customer.id,
      name: customer.name,
    },
    subscriptionsWithUnreportedFaults:
      rdc.subscriptionsWithUnreportedFaults?.totalResults ?? null,
    incidentsOnHoldPendingCustomer:
      rdc.incidentsOnHoldPendingCustomer?.totalResults ?? null,
    activeOrders: getActiveOrders(rdc),
    activeIncidents: rdc.activeIncidents?.totalResults ?? 0,
    activeSolvedIncidents: rdc.activeSolvedIncidents?.totalResults ?? 0,
    servicesWithPlannedWork: rdc.servicesWithPlannedWork?.totalResults ?? null,

    overdueInvoices: rdc.overdueInvoices?.totalResults ?? null,

    servicesAssigning: rdc.servicesAssigning?.totalResults ?? null,
    servicesTerminating: rdc.servicesTerminating?.totalResults ?? null,

    changelogs: result.data.changelogs,
  };
}
