import React from 'react';
import { useRouter } from 'src/lib/utils/useRouter';
import { useSubscription } from '../../../queries/useSubscription';
import { SubscriptionSummaryContainer } from '../../../layout';
import { ButtonCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

import {
  DefaultAboutCard,
  SubscriptionReportIncidentButton,
  AdditionalInformationCard,
  TechnicalContactsCard,
  SubscriptionCostDetails,
  ConnectionStretch,
  SubscriptionConfiguration,
} from '../../Components';

export const SdWanSummary = () => {
  const router = useRouter<{ id: string }>();
  const { subscription } = useSubscription(router.match.params.id);

  if (subscription) {
    const i18n = t.subscriptions.SdWanSummary;
    return (
      <>
        <SubscriptionSummaryContainer
          subscription={subscription}
          main={
            <>
              {subscription.installationAddress &&
                subscription.installationAddress2 && (
                  <ConnectionStretch
                    installationAddress={subscription.installationAddress}
                    installationAddress2={subscription.installationAddress2}
                  />
                )}

              <ButtonCard
                color="dark"
                stackHorizontally={true}
                href="https://cns-admin.cns.telia.net/login/"
                target="_blank"
              >
                <div className="d-flex align-items-center">
                  <span>{i18n.description}</span>
                  <span className="ml-auto">
                    <Icon icon="open" />
                  </span>
                </div>
              </ButtonCard>

              <SubscriptionReportIncidentButton subscription={subscription} />
              <SubscriptionConfiguration subscription={subscription} />
            </>
          }
          sidebar={
            <>
              <DefaultAboutCard subscription={subscription} />
              <SubscriptionCostDetails subscription={subscription} />
              <TechnicalContactsCard subscription={subscription} />
              <AdditionalInformationCard subscription={subscription} />
            </>
          }
        />
      </>
    );
  }
  return null;
};
