import * as React from 'react';
import cs from 'classnames';
import { Icon } from '@telia/styleguide';

import './OldMapMarker.scss';

/**
 * Note: lat/lon is not used by the marker itself, but by
 * the Google Maps integration. They need to be present in Props.
 */
export interface Props {
  lat?: number;
  lon?: number;
  big?: boolean;
  hasWarning?: boolean;
  onClick?: () => void;
}

function OldMapMarker(props: Props) {
  return (
    <div
      onClick={props.onClick}
      tabIndex={props.onClick ? 0 : undefined}
      role={props.onClick ? 'button' : undefined}
      className={cs({
        OldMapMarker: true,
        'OldMapMarker--disabled': !props.onClick,
        'OldMapMarker--big': props.big,
        'OldMapMarker--warning': props.hasWarning,
      })}
    >
      <Icon className="OldMapMarker-icon" icon="map" />
    </div>
  );
}

export default OldMapMarker;
