import React from 'react';
import { Link } from 'react-router-dom';

export function Dash() {
  return (
    <div className="Dev-dash">
      <h1>Development</h1>

      <Link to="/">Min portal</Link>
      <Link to="/minside">Min side</Link>

      <h2>Tools</h2>

      <a href="/report.html">Build report</a>
      <a href="/voyager.html">GraphQL voyager</a>
      <a href="/graphiql">GraphiQL</a>
    </div>
  );
}
