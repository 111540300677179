const ui = {
  ConfirmationModalButton: {
    cancel: 'Avbryt',
    confirm: 'Bekreft',
  },

  ContactPermissionsForm: {
    fullAccess: 'Administratorrettigheter',
    fullAccessDescription:
      'Gi tilgang til administrasjon av hele organisasjonen og alle kontakter',
    roles: {
      SystemAdministrator: 'Drift',
      FinancialManager: 'Økonomi',
      OrderManager: 'Bestilling',
      CustomerAdministrator: 'Administrator',
    },
  },

  DatePicker: {
    showCalendar: 'Vis kalender',
    expectedFormat: '(dd.mm.åååå)',
  },

  DateRangePicker: {
    presetDays: {
      0: 'I dag',
      1: 'I går',
      7: 'Siste 7 dager',
      28: 'Siste 28 dager',
      90: 'Siste 90 dager',
    },
    placeholder: {
      startDate: 'Startdato',
      endDate: 'Sluttdato',
    },
  },

  Fullscreen: {
    close: 'Lukk',
  },

  FullscreenError: {
    heading: 'Oj!',
    mainMessage: 'Vi klarte ikke å vise deg siden.',
    subMessage: 'Fortvil ikke.',
    linkMessage: 'Prøv å gå tilbake til forsiden.',
    errorCode: 'Feilkode:',
  },

  InnerOrganisationTreeNavigator: {
    searchOrgs: 'Søk etter et fakturasted',
  },

  ItemListPicker: {
    addItem: 'Legg til:',
    noMoreItemsInList: 'Ingen flere elementer i listen.',
    submitting: 'Lagrer ...',
  },

  LoadMoreButton: {
    showMore: 'Vis flere',
  },

  NavItem: {
    currentItem: 'aktiv',
  },

  OrganisationPicker: {
    title: 'Rediger fakturasteder for brukeren',
    btnSave: 'Lagre',
  },
  Pagination: {
    rowsPerPage: 'Rader per side:',
    xOfY: (x: string | number, y: string | number) => `${x} av ${y}`,
    // btnSelectPageSize: 'velg side størrelse',
    btnSelectPage: 'velg side',
    btnPrevPage: 'forrige side',
    btnNextPage: 'neste side',
  },
  PermissionCard: {
    noAccess: 'Ingen tilgang',
    partialAccess: 'Begrenset tilgang',
    fullRoleAccess: 'Full tilgang',
    btnSelectOrgs: 'Velg fakturasteder',
    btnSelectTags: 'Velg tags',
    searchTags: 'Søk etter en tag',
    editTagsTitle: 'Rediger tags for brukeren',
  },

  FormSubmitBar: {
    btnSubmit: 'Lagre',
    btnBack: 'Tilbake',
    btnCancel: 'Avbryt',
  },

  SearchInput: {
    btnClear: 'Tøm søkefelt',
    btnSearch: 'Søk',
  },

  SearchListPicker: {
    noHits: 'Ingen resultater',
  },

  SelectField: {
    noOptionsMessage: 'Ingen resultater',
    apply: 'Bruk',
    reset: 'Nullstill',
    cancel: 'Avbryt',
  },

  UserPicker: {
    searchForUsers: 'Søk etter brukere',
    searchForUser: 'Søk etter bruker',
  },

  WizardTable: {
    noSelection: 'Ingen valgt',
  },

  Table: {
    SelectedRowsBanner: {
      allPageRows: (num: number) =>
        `Alle ${num} radene på denne siden er valgt.`,
      allRows: (num: number, domain: string) =>
        `Velg alle ${num} radene i ${domain}`,
      allRowsAreSelected: (num: number, domain: string) =>
        `Alle ${num} rader i ${domain} er valgt.`,
      clearSelection: 'Fjern alle valgte rader',
    },
    noData: 'Ingen data funnet',
    ColumnOptions: {
      sort: 'Sorter',
      show: 'Vis',
      aToå: 'A til Å',
      åToa: 'Å til A',
      zeroToNine: '0 til 9',
      nineToZero: '9 til 0',
      descending: 'Synkende',
      ascending: 'Stigende',
    },
  },

  Toggle: {
    on: 'På',
    off: 'Av',
  },

  Toast: {
    saveOk: 'Endringen er lagret',
    saveError: 'Vi klarte ikke å lagre endringen',
    disabled: 'Overstyrt av ',
    close: 'Lukk',
    uploading: (fileName: string) => `Laster opp ${fileName}`,
    success: 'Opplastningen er ferdig.',
    error: 'Ops! Vi klarte ikke å laste opp dokumentet.',
  },
  times: {
    days: 'dager',
    day: 'dag',
    hours: 'timer',
    hour: 'time',
    minutes: 'minutter',
    minute: 'minutt',
    second: 'sek',
  },

  FilterSection: {
    selectedFilters: 'valgte filtre',
    showResults: (num: number) => `vis ${num} resultater`,
    noResults: 'Ingen treff',
    resetAllFilters: 'Nullstill alle filtre',
  },
  PlannedWorlState: {
    cancelled: 'Avbrutt',
    closed: 'Lukket',
    scheduled: 'Planlagt',
    inProgress: 'Under arbeid',
  },
};

export default ui;
