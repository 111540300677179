/**
 * Types
 */
export * from './cluster/Cluster';
export * from './cluster/ClusterFragment';
export * from './contact/Contact';
export * from './contact/ContactOrderStatus';
export * from './contact/User';
export * from './contact/SubscriptionContact';
export * from './contact/ContactFragment';

export * from './costcenter/CostCenter';
export * from './costcenter/CostCenterFragment';

export * from './customer/Customer';

export * from './file/File';
export * from './documents/DocumentArchive';
export * from './documents/Folder';
export * from './documents/NavigateTo';

export * from './file/FileFragment';

export * from './incident/IncidentCategory';
export * from './incident/IncidentFragment';
export * from './incident/IncidentNotificationContact';
export * from './invoice/Invoice';
export * from './invoice/InvoiceFragment';
export * from './invoice/InvoiceStatus';

export * from './notification/NotificationFragment';

export * from './order/Order';
export * from './order/OrderFragment';

export * from './organisation/Organisation';
export * from './organisation/OrganisationFragment';

export * from './subscription/Coordinates';
export * from './subscription/DeliveryStatus';
export * from './subscription/InstallationAddress';

export * from './subscription/Bandwidth';
export * from './subscription/Subscription';
export * from './subscription/SubscriptionFragment';
export * from './subscription/getSubscriptionDisplayTitles';

export * from './subscription/getCpeStatusIcon';
export * from './subscription/getSubscriptionIcon';

export * from './tag/Tag';
export * from './tag/TagFragment';

export * from './mobile/MobileSettings';

export type ResourceType =
  | 'contact'
  | 'file'
  | 'incident'
  | 'invoice'
  | 'organisation'
  | 'subscription'
  | 'dataSubscription'
  | 'mobileSubscription'
  | 'teleSubscription'
  | 'tvSubscription'
  | 'otherSubscription'
  | 'costCenter'
  | 'tag'
  | 'order'
  | 'notification';

// This follows Bootstrap semantics.
export type StatusColor =
  | 'default'
  | 'danger'
  | 'success'
  | 'warning'
  | 'muted';

/**
 * Represents a specific month, in the format `YYYY-MM`.
 */
export type Period = string;

export type PeriodBy = 'invoice' | 'usage';

export interface InvoiceAggregateLine {
  productDesignId?: string;
  productDesignDescription?: string;
  productClass: string;
  costType: string;
  periodGroup: string;
  totalAmountNet: number;
  totalAmountGross: number;
}

export interface InvoiceAggregate {
  recursive: boolean;
  periodBy: PeriodBy;
  periodStart: Date;
  periodEnd: Date;
  groupByClassAndType: boolean;
  data: InvoiceAggregateLine[];
}

/**
 * Represents a Date in the format `YYYY-MM-DD`. Corresponds to the GraphQL `Date` type.
 */
export type DateString = string;

export interface Error {
  code: string;
  message?: string;
}

/**
 * Frontend related type. How a data-result set should be shown.
 */
export type DataViewType = 'list' | 'table';

/**
 * Global generic key-value type
 */
export interface KeyValue {
  key: string;
  value: string | null;
}

export interface DomainResult {
  domain: string;
  resultCount: number;
}
