import React from 'react';
import { Contact } from 'src/lib/types';
import { Card } from 'src/lib/ui';
import { useSearch } from 'src/areas/main/search';
import { useAppContext } from 'src/lib/global';
import { SearchListContactResult } from 'src/areas/main/search/components/list/SearchListContactResult';
import { SearchDomain } from 'src/__types/graphql-global-types';

const UserContactCard = () => {
  const app = useAppContext();
  const search = useSearch({
    types: [SearchDomain.contact],
    filter: [{ filter: 'hasUser', value: ['true'] }],
    size: 10000,
  });

  const me = (search.results as Contact[]).filter(
    c => c.user && app.access && c.user.id === app.access.id
  )[0];

  if (me) {
    return (
      <Card padding={false} shadow="1" border={true}>
        <SearchListContactResult entry={me} />
      </Card>
    );
  } else {
    return null;
  }
};

export default UserContactCard;
