import * as React from 'react';
import { DetailCard, List, ListResultCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import { Report } from './context/ReportContext';

type Props = {
  selectReport: (reportType: Report) => void;
};

export const Accounting = (props: Props) => {
  const translate = t.reports;

  return (
    <DetailCard heading={translate.categories.accounting} color="white">
      <List container={false}>
        <ListResultCard
          className="pl-3 border-bottom"
          title={translate.accounting.accountingData}
          onClick={() => props.selectReport('accountingDataServices')}
          action={<Icon icon="arrow-right" />}
        />
        <ListResultCard
          className="pl-3 border-bottom"
          title={translate.accounting.accountingLandline}
          onClick={() => props.selectReport('accountingFixedPhones')}
          action={<Icon icon="arrow-right" />}
        />
        <ListResultCard
          className="pl-3 border-bottom"
          title={translate.accounting.invoiceDetails}
          onClick={() => props.selectReport('accountingDetailedInvoice')}
          action={<Icon icon="arrow-right" />}
        />
      </List>
    </DetailCard>
  );
};
