import React from 'react';
import { Subscription } from 'src/lib/types';
import { formatInstallationAddress } from 'src/lib/utils';
import { SubscriptionStatusIcon } from 'src/areas/main/subscriptions/lib/SubscriptionStatusIcon/SubscriptionStatusIcon';
import { CircleIcon } from 'src/lib/ui';

import { getSubscriptionDisplayTitles } from 'src/lib/types/subscription/getSubscriptionDisplayTitles';
export const Tooltip = (props: { subscription?: Subscription }) => {
  if (!props.subscription) {
    return null;
  }
  const { title, subtitle } = getSubscriptionDisplayTitles(props.subscription);

  const hasActiveState = plannedWork =>
    plannedWork.state === 'Scheduled' || plannedWork.state === 'Implement';
  const hasPlannedwork = !!props.subscription?.plannedWork?.filter(
    hasActiveState
  ).length;

  return (
    <div className="grey-dark-text">
      <div className="px-3 py-2 border-bottom">{title}</div>
      <div className="px-3 py-2">
        <div className="d-flex mb-2">
          <SubscriptionStatusIcon
            circleBackground={true}
            cpeStatus={props.subscription.cpe?.status}
            hasStatus={!!props.subscription.isMonitoringPossible}
          />
          {hasPlannedwork ? (
            <CircleIcon
              className="ml-2"
              icon="wrench"
              color="warning"
              filled={true}
            />
          ) : null}
          {props.subscription.activeIncidents?.length ? (
            <CircleIcon className="ml-2" icon="email" filled={true} />
          ) : null}
        </div>

        <div>{subtitle}</div>
        <div>
          {formatInstallationAddress(
            props.subscription.installationAddress,
            'fullAddress'
          )}
        </div>
      </div>
    </div>
  );
};
