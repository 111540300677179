import React from 'react';
import './NotificationSection.scss';
import { EditNotifications } from './EditNotifications';
import cs from 'classnames';
import { useUpdateNotificationSettings_setNotificationSettings_notificationSettings } from '../mutations/__types/useUpdateNotificationSettings';
import {
  useContact_customer_contacts_notificationSettings_change,
  useContact_customer_contacts_notificationSettings_incident,
} from 'src/areas/main/contacts/queries/__types/useContact';

interface Props {
  contactId?: string;
  forceEmailChannel?: boolean;
  title: string;
  allowAllNotifications?: boolean;
  allowSelectChannel?: boolean;
  notificationSettings: useUpdateNotificationSettings_setNotificationSettings_notificationSettings;
  setSettings?: (
    settings:
      | useContact_customer_contacts_notificationSettings_incident
      | useContact_customer_contacts_notificationSettings_change
      | null
  ) => void;
}

const NotificationSection = (props: Props) => (
  <div
    className={cs('NotificationSection', {
      'NotificationSection--open': props.notificationSettings.active,
    })}
  >
    <>
      <EditNotifications
        forceEmailChannel={props.forceEmailChannel}
        title={props.title}
        notifications={props.notificationSettings}
        allowSelectChannel={props.allowSelectChannel}
        allowAllNotifications={props.allowAllNotifications}
        contactId={props.contactId}
        setSettings={props.setSettings}
      />
    </>
  </div>
);

export default NotificationSection;
