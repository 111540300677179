import React from 'react';
import { t } from 'src/lib/i18n';
import { useTechnicalInformation } from 'src/areas/main/subscriptions/queries/useTechnicalInformation';
import { IpDetails } from './IpDetails';
import { MplsDetails } from './MplsDetails';
import { IpRoutingComponent } from './IpRoutingComponent';
import { LanIp } from './LanIp';
import { useFeature } from 'src/lib/utils';

import './TechnicalInformation.scss';
import { NgfwDetails } from './NgfwDetails';

export const TechnicalInformation = (props: {
  subscription: { id: string };
}) => {
  const translate = t.subscriptions.TechnicalDetailsCard;
  const { data } = useTechnicalInformation(props.subscription.id);
  const subscription = data;
  const showNgfwInfo = useFeature('show-ngfw-info');

  if (subscription) {
    return (
      <div className="TechnicalInformation">
        {subscription?.cpe?.configuration?.lan && (
          <LanIp
            subscription={subscription}
            ipAddresses={subscription.cpe.configuration.lan}
          />
        )}

        {subscription?.cpe?.configuration?.wan && (
          <IpDetails
            ipAddresses={subscription.cpe.configuration.wan}
            heading={translate.wanIp}
          />
        )}

        {subscription.dmVpnTunnelInformation && (
          <IpDetails
            ipAddresses={[
              {
                networkAddress: subscription.dmVpnTunnelInformation.ipAddress,
                subnetMask: subscription.dmVpnTunnelInformation.subnetMask,
              },
            ]}
            heading={translate.dmVpnIp}
          />
        )}

        <IpRoutingComponent
          subscription={props.subscription}
          ipRouting={subscription.ipRouting}
        />

        {subscription.vrf ? (
          <MplsDetails heading={translate.mpls} vrf={subscription.vrf} />
        ) : null}

        {showNgfwInfo && subscription.ngfw ? (
          <NgfwDetails
            heading={translate.ngfw.header}
            ngfw={subscription.ngfw}
          />
        ) : null}
      </div>
    );
  }
  return null;
};
