import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { WithCustomerContext } from 'src/lib/global';
import { ApolloError } from 'apollo-client';

import { sortBy } from 'lodash';

import { Tag } from 'src/lib/types';

export const query = gql`
  query WithCustomerTags($customerId: ID!) {
    customer(id: $customerId) {
      id
      tags {
        id
        tag
      }
    }
  }
`;

export interface Data {
  customer?: {
    tags?: Tag[];
  };
}

export interface Variables {
  customerId: string;
}

export interface TagsData {
  loading: boolean;
  error: ApolloError | null;
  tags: Tag[];
}

interface Props {
  children: (data: TagsData) => JSX.Element | null;
}

export const WithCustomerTags: React.SFC<Props> = (props: Props) => {
  return (
    <WithCustomerContext>
      {customer => (
        <Query<Data, Variables>
          query={query}
          variables={{
            customerId: customer.id,
          }}
          errorPolicy="all"
          fetchPolicy="cache-and-network"
        >
          {result =>
            props.children({
              loading: result.loading,
              error: result.error ? result.error : null,
              tags:
                result.data && result.data.customer && result.data.customer.tags
                  ? sortBy(result.data.customer.tags, t => t.tag)
                  : [],
            })
          }
        </Query>
      )}
    </WithCustomerContext>
  );
};
