import React from 'react';
import {
  List,
  ListHeader,
  ListFooter,
  LoadMoreButton,
  Loading,
} from 'src/lib/ui';

import { t } from 'src/lib/i18n';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
import FilterSection from 'src/areas/main/common/FilterSection';
import NotificationCard from './Notification';
import { withRouter, RouteComponentProps } from 'react-router';
import { getUrlForNotification } from './utils';
import { MarkNotificationRead } from './mutation/MarkNotificationRead';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { WithSearch } from 'src/areas/main/search';
import { Notification } from './query/useNotifications';
import { getFilterCountFromSearchFilter } from 'src/lib/utils/';

const Notifications = (props: RouteComponentProps) => {
  const query = useQueryParameters();
  const translate = t.notifications.NotificationPage;

  // read
  const statusOptions = [
    {
      value: 'false',
      label: translate.filterLabels.unread,
    },
    { value: 'true', label: translate.filterLabels.read },
  ];

  const statusFilter = {
    id: 'status',
    label: translate.status,
    options: statusOptions,
    selectedOptions: query.getArray('read'),
    handleSave: value => query.set('read', value),
    allowMultiple: false,
  };

  // notificationType
  const typeOptions = [
    {
      value: 'incidentCreate',
      label: translate.filterLabels.created,
    },
    {
      value: 'incidentComment',
      label: translate.filterLabels.newComment,
    },
    {
      value: 'incidentStatus',
      label: translate.filterLabels.statusChange,
    },
    {
      value: 'incidentChange',
      label: translate.filterLabels.updated,
    },
  ];

  const typeFilters = {
    id: 'type',
    label: translate.type,
    options: typeOptions,
    selectedOptions: query.getArray('notificationType'),
    handleSave: value => query.set('notificationType', value),
    allowMultiple: true,
  };

  return (
    <WithSearch
      initial={{
        types: ['notification'],
        sortOrder: 'desc',
        sortBy: 'created',
        filter: [
          {
            filter: 'read',
            value: query.get('read'),
          },
          {
            filter: 'notificationType',
            value: query.getArray('notificationType'),
          },
        ],
      }}
    >
      {search => {
        const filterCount = search.filter
          ? getFilterCountFromSearchFilter(search.filter)
          : undefined;
        return (
          <>
            <PageHeader title={translate.title} />
            <FilterSection
              filterToggling={false}
              filterOptions={[statusFilter, typeFilters]}
              totalSearchResults={search.totalResults}
              totalFiltersActive={filterCount}
            />

            <MarkNotificationRead>
              {markNotificatonContext => {
                const onClickNotifications = async (
                  notification: Notification
                ) => {
                  if (!notification.read) {
                    markNotificatonContext.markNotificationRead(
                      notification.id
                    );
                  }
                  props.history.push(getUrlForNotification(notification));
                };
                return (
                  <>
                    {search.results && search.results.length > 0 && (
                      <ListHeader>
                        <span>
                          {translate.showXofY(
                            search.results.length,
                            search.totalResults
                          )}
                        </span>
                      </ListHeader>
                    )}

                    <List border={true}>
                      {search.results.map(notification =>
                        notification.__typename === 'Notification' ? (
                          <NotificationCard
                            key={notification.id}
                            notification={notification}
                            onClick={() => onClickNotifications(notification)}
                          />
                        ) : null
                      )}
                    </List>

                    <ListFooter>
                      {!search.loading &&
                        search.results.length > 0 &&
                        search.isMore && (
                          <LoadMoreButton
                            onClick={search.loadMore}
                            disabled={search.loading || !search.isMore}
                          />
                        )}
                    </ListFooter>

                    {search.loading && <Loading />}
                  </>
                );
              }}
            </MarkNotificationRead>
          </>
        );
      }}
    </WithSearch>
  );
};

export default withRouter(Notifications);
