import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useSubscriptionIncidents';
import { IncidentFragment } from 'src/lib/types';

export const SUBSCRIPTION_INCIDENTS = gql`
  query useSubscriptionIncidents($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        incidents {
          ...IncidentSearchResult
        }
        activeIncidents {
          ...IncidentSearchResult
        }
      }
    }
  }
  ${IncidentFragment}
`;

export const useSubscriptionIncidents = (subId: string) => {
  const customer = useCustomer();

  return useQuery<
    t.useSubscriptionIncidents,
    t.useSubscriptionIncidentsVariables
  >(SUBSCRIPTION_INCIDENTS, {
    variables: {
      customerId: customer.id,
      subscriptionId: subId,
    },
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
  });
};
