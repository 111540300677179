import * as React from 'react';

import DocumentsContainer from './DocumentsContainer';
import { useDocumentArchiveFilesAndFolders } from './queries/useDocumentArchiveFilesAndFolders';
import { urlFor } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils/useRouter';

import { NavigateTo } from 'src/lib/types';
import { DocumentsContextProvider } from './DocumentsContext';
import { PageHeader } from '../common/PageHeader/PageHeader';
import { t } from 'src/lib/i18n';
import { useCustomer } from 'src/lib/global';

const Documents = () => {
  const router = useRouter();
  const { id } = useCustomer();
  const data = useDocumentArchiveFilesAndFolders();
  const navigateTo: NavigateTo = link => {
    if (typeof link === 'object' && 'file' in link) {
      router.history.push(urlFor({ file: link.file.id }));
    } else if (typeof link === 'object' && 'folder' in link) {
      if (link.folder.parentId) {
        router.history.push(urlFor({ folder: link.folder.id }));
      } else {
        router.history.push(router.match.url);
      }
    } else {
      router.history.push(router.match.url);
    }
  };

  // Anything following `props.match.url` in
  // `props.location.pathname` is used to specify the file/folder
  const path = router.location.pathname.substr(router.match.url.length);

  return (
    <DocumentsContextProvider
      value={{
        path,
        documentArchive: {
          folders: data.folders,
          files: data.files,
        },
        setDocumentsInternalOnUpload: false,
        navigateTo,
        loading: data.loading,
        refetchFileStructure: data.refetch,
        customerId: id,
        category: 'Documents',
      }}
    >
      <PageHeader title={t.documents.title} />
      <div className="container">
        <DocumentsContainer />
      </div>
    </DocumentsContextProvider>
  );
};

export default Documents;
