import React, { useContext } from 'react';
import { Folder, NavigateTo, File } from 'src/lib/types';

interface PathContext {
  /**
   * The details of the current folder.
   */
  folder: Folder;
  parentFolder: Folder | undefined;
  subFolders: Folder[];
  folderFiles: File[];
  navigateTo: NavigateTo;
  loading: boolean;
  allFiles: File[];
  allFolders: Folder[];
  file?: File;
}

const PathContext = React.createContext<PathContext | undefined>(undefined);

const usePath = () => {
  const path = useContext(PathContext);
  if (!path) {
    throw new Error('No path');
  }
  return path;
};

const { Provider, Consumer } = PathContext;
const PathContextConsumer: React.SFC<{
  children: (ctx: PathContext) => React.ReactNode;
}> = props => (
  <Consumer>
    {context => {
      if (!context) {
        throw new Error('No PathContext provided');
      }
      return props.children(context);
    }}
  </Consumer>
);

export {
  Provider as PathContextProvider,
  PathContextConsumer,
  PathContext,
  usePath,
};
