import React, { useState, useRef } from 'react';
import {
  useStatisticsParams,
  StatisticsView,
} from './../utils/useStatisticsParams';
import { useStatisticsInterfaces } from '../utils/useStatisticsInterfaces';
import { useSubHasStatistics } from '../queries/useSubHasStatistics';
import DateRangePicker from 'src/lib/ui/DateRangePicker/DateRangePicker';
import { Loading, FilterSelect } from 'src/lib/ui';
import moment from 'moment';
import { FocusedInputShape } from 'react-dates';
import { t } from 'src/lib/i18n';

import './StatisticsFilter.scss';

export const hasInterfaceList: StatisticsView[] = [
  'live',
  'throughput',
  'responseTime',
  'packetDrop',
  'cumulative',
  'qosDrop',
  'qosTransmit',
  // 'packetLoss',
  // 'jitter',
  // 'roundTripTime',
  'all',
];

const hasPeriodList: StatisticsView[] = [
  'qosDrop',
  'qosTransmit',
  'gsm',
  'throughput',
  'responseTime',
  'packetDrop',
  'packetLoss',
  'jitter',
  'roundTripTime',
  'cumulative',
  'all',
];

export const StatisticsFilter = () => {
  const params = useStatisticsParams();

  return (
    <div className="pb-3">
      <div className="StatisticsFilter">
        <span className="StatisticsFilter-filter">
          <span>{t.statistics.StatisticsFilter.show}</span>
          <ViewSelectfield />
        </span>
        {params.currentView && hasInterfaceList.includes(params.currentView) ? (
          <span className="StatisticsFilter-filter">
            <span>{t.statistics.StatisticsFilter.for}</span>
            <InterfaceSelectfield />
          </span>
        ) : null}
        {params.currentView && hasPeriodList.includes(params.currentView) ? (
          <span className="StatisticsFilter-filter">
            <span>{t.statistics.StatisticsFilter.inPeriod}</span>
            <StatisticsDatePicker />
          </span>
        ) : null}
      </div>
    </div>
  );
};

interface ViewSelectOptions {
  label: string;
  value: StatisticsView;
}

const ViewSelectfield = () => {
  const params = useStatisticsParams();
  const interfaces = useStatisticsInterfaces(params.subscriptionId);
  const hasStatisticsQuery = useSubHasStatistics(params.subscriptionId);
  const i18n = t.statistics.StatisticsFilter;
  if (hasStatisticsQuery.loading || interfaces.loading) {
    return <Loading height="extra-small" />;
  }

  const sub = hasStatisticsQuery.data?.customer?.subscriptions?.[0];
  const hasStandardStatistics = interfaces?.standardInterfaces?.length > 0;

  const viewOptions: Array<ViewSelectOptions> = [
    ...((interfaces.liveInterfaces?.length
      ? [
          {
            label: i18n.live,
            value: 'live',
          },
        ]
      : []) as ViewSelectOptions[]),

    // TODO: Change this to new interfaceList when backend is ready from backend.
    // This list will contain interfaces even if the interfaces is offline in order to show historic data
    ...((hasStandardStatistics
      ? [
          {
            label: i18n.throughput,
            value: 'throughput',
          },
          {
            label: i18n.cumulative,
            value: 'cumulative',
          },
          {
            label: i18n.responseTime,
            value: 'responseTime',
          },
          {
            label: i18n.packetDrop,
            value: 'packetDrop',
          },
        ]
      : []) as ViewSelectOptions[]),

    ...((sub?.hasExtendedStatistics
      ? [
          {
            label: i18n.packetLoss,
            value: 'packetLoss',
          },
          {
            label: i18n.jitter,
            value: 'jitter',
          },
          {
            label: i18n.roundTripTime,
            value: 'roundTripTime',
          },
        ]
      : []) as ViewSelectOptions[]),
    ...((hasStandardStatistics
      ? [
          {
            label: i18n.allStandard,
            value: 'all',
          },
        ]
      : []) as ViewSelectOptions[]),
    ...((sub?.hasGsmStatistics
      ? [
          {
            label: i18n.gsm,
            value: 'gsm',
          },
        ]
      : []) as ViewSelectOptions[]),
    ...((sub?.hasQosStatistics
      ? [
          {
            label: i18n.qosTransmit,
            value: 'qosTransmit',
          },
          {
            label: i18n.qosDrop,
            value: 'qosDrop',
          },
        ]
      : []) as ViewSelectOptions[]),
  ];

  const currentLabel =
    viewOptions.find(opt => opt.value === params.currentView)?.label ??
    t.statistics.StatisticsFilter.chooseStatistics;

  return (
    <FilterSelect
      id="viewSelect"
      className="StatisticsFilter-select"
      label={currentLabel}
      selectedOptions={params.currentView ? [params.currentView] : undefined}
      options={viewOptions}
      handleSave={value =>
        params.setView(value?.[0] ? (value[0] as StatisticsView) : undefined)
      }
      allowMultiple={false}
    />
  );
};

const InterfaceSelectfield = () => {
  const params = useStatisticsParams();
  const interfaces = useStatisticsInterfaces(params.subscriptionId);

  if (interfaces.loading) {
    return <Loading height="extra-small" />;
  }
  const currentInterfaces =
    params.currentView === 'live'
      ? interfaces.liveInterfaces
      : interfaces.standardInterfaces;

  const currentLabel =
    currentInterfaces?.find(i => i.value === params.currentInterface)?.label ??
    t.statistics.StatisticsFilter.chooseInterface;

  return (
    <FilterSelect
      id="interfaceSelect"
      className="StatisticsFilter-select"
      label={currentLabel}
      selectedOptions={
        params.currentInterface ? [params.currentInterface] : undefined
      }
      options={currentInterfaces}
      handleSave={value => params.setInterface(value?.[0])}
      allowMultiple={false}
    />
  );
};

/**
 * Moved logic for the StatisticsDatepicker to own component for readability and seperation of concerns.
 */
const StatisticsDatePicker = () => {
  const [showDayPicker, setShowDayPicker] = useState<boolean>(false);
  const [focusedInput, setFocusedInput] = useState<FocusedInputShape>(
    'startDate'
  );
  const params = useStatisticsParams();
  const tomorrow = useRef<moment.Moment>(
    moment()
      .add(1, 'd')
      .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
  );

  const startDate = moment(params.currentStartPeriod);
  const endDate = moment(params.currentEndPeriod);
  //const startTime = startDate.format('HH:mm');
  //const endTime = endDate.format('HH:mm');

  const onDatePickerChange = (dates: {
    startDate: moment.Moment | null;
    endDate: moment.Moment | null;
  }) => {
    params.setPeriod(
      dates.startDate
        ? dates.startDate
            .set({ hour: 0, minute: 0, second: 0, millisecond: 0 })
            .format('YYYY-MM-DD HH:mm:ss')
        : undefined,
      dates.endDate
        ? dates.endDate
            .set({ hour: 23, minute: 59, second: 0, millisecond: 0 })
            .format('YYYY-MM-DD HH:mm:ss')
        : undefined
    );
  };

  /* const onTimeChange = (time: { startTime: string; endTime: string }) => {
    const newStartDate = `${startDate.format('YYYY-MM-DD')} ${
      time.startTime
    }:00`;
    const newEndDate = `${endDate.format('YYYY-MM-DD')} ${time.endTime}:00`;

    params.setPeriod(newStartDate, newEndDate);
  }; */
  return (
    <DateRangePicker
      showDayPicker={showDayPicker}
      dayPickerProps={{
        minimumNights: 0,
        endDate: endDate,
        focusedInput: focusedInput,
        numberOfMonths: 2,
        startDate: startDate,
        onDatesChange: onDatePickerChange,
        onClose: () => setShowDayPicker(false),
        onOutsideClick: () => setShowDayPicker(false),
        onFocusChange: input => setFocusedInput(input ? input : 'startDate'),
        isOutsideRange: (day: moment.Moment) =>
          day.isSameOrAfter(moment(tomorrow.current)),
      }}
      inputProps={{
        inputType: 'Period',
        readonly: true,
        onInputFocus: () => setShowDayPicker(true),
      }}
    />
  );
};
