import React, { useState } from 'react';
import { Toggle } from '@telia/styleguide/business';
import { Chip, Feature } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils';
import { OptionSelectProps } from '../OptionSelect';

export const SelectedFilterChips = (props: {
  filterOptions?: OptionSelectProps[];
}) => {
  const query = useQueryParameters();
  const tags = query.getArray('tag');
  const tagsCombined = query.get('tagsCombined') ?? false;
  const [combineTags, setCombineTags] = useState(tagsCombined as boolean);

  if (!props.filterOptions) {
    return null;
  }
  const allSelectedOptions = props.filterOptions
    ?.filter(o => o.id !== 'sort')
    .map(o => ({
      options: o.options.filter(option =>
        o.selectedOptions?.includes(option.value)
      ),
      handleSave: o.handleSave,
    }));

  const toggleCombined = () => {
    setCombineTags(!combineTags);
    query.set('tagsCombined', combineTags ? undefined : 'true');
  };

  return (
    <div className="FilterSectionFilterContainer-activeFilters">
      <Feature feature="combine-tags">
        {tags.length > 1 && (
          <span className="d-inline-flex">
            {t.subscriptions.filters.combineTags}:
            <Toggle
              checked={combineTags}
              onChange={toggleCombined}
              className="mx-2"
            />
          </span>
        )}
      </Feature>
      {allSelectedOptions?.map(({ handleSave, options }) =>
        options.map(currentOption => {
          return (
            <Chip
              key={currentOption.value}
              label={currentOption.label}
              mode="select"
              active={true}
              onClick={() =>
                handleSave(
                  options
                    .filter(o => o.value !== currentOption.value)
                    .map(o => o.value)
                )
              }
            />
          );
        })
      )}
    </div>
  );
};
