import { throttle } from 'lodash';
import { useState, useEffect } from 'react';

/**
 * From Bootstrap
 */
type Breakpoints = 'sm' | 'md' | 'lg' | 'xl';
const breakpoints: { [key in Breakpoints]: number } = {
  sm: 576,
  md: 768,
  lg: 992,
  xl: 1200,
};

export const useBreakpoint = (breakpoint: Breakpoints): boolean => {
  const [width, setWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWidth(window.innerWidth);
    };

    const throttledHandleResize = throttle(handleResize, 100);
    window.addEventListener('resize', throttledHandleResize);
    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, []);

  return width > breakpoints[breakpoint];
};
