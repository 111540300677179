import React from 'react';
import { Attribute, colors, urlFor } from 'src/lib/ui';
import { Link } from 'react-router-dom';
import { t } from 'src/lib/i18n';
import { Operator } from 'src/__types/graphql-global-types';

interface Props {
  subscription?: null | {
    name: string;
    id: string;
  };
  dataInclusive?: string;
  dataUsed?: string;
  speedReduce?: string;
  simNumber?: string;
  operator?: Operator | null;
}

export const M2MAttributes = (props: Props) => {
  const hasAttribute =
    props.subscription ||
    props.speedReduce ||
    props.dataUsed ||
    props.dataInclusive ||
    props.operator ||
    props.simNumber;
  const i18n = t.subscriptions.M2MUsage;

  if (!hasAttribute) {
    return null;
  }
  return (
    <div className="mt-n2">
      {props.subscription && (
        <div className="d-flex align-items-center mt-2">
          <Attribute className="mt-0 mr-1" label={i18n.subscription} />
          <Link to={urlFor({ subscription: props.subscription.id })}>
            {props.subscription.name}
          </Link>
        </div>
      )}
      {props.dataInclusive && (
        <Attribute
          className="mt-2"
          label={i18n.dataIncluded}
          value={props.dataInclusive}
        />
      )}
      {props.dataUsed && (
        <div className="d-flex align-items-center mt-2">
          <Attribute className="mt-0 mr-1" label={i18n.dataUsage} />
          <div className="mediumBold" style={{ color: colors.corePurple }}>
            {props.dataUsed}
          </div>
        </div>
      )}

      {props.speedReduce && !props.speedReduce.includes('undefined') && (
        <Attribute
          className="mt-2"
          label={i18n.speedReduction}
          value={props.speedReduce}
        />
      )}
      {props.simNumber && (
        <Attribute
          className="mt-2"
          label={i18n.mainSimCard}
          value={props.simNumber}
        />
      )}
      {props.operator && (
        <Attribute
          className="mt-2"
          label={i18n.operator}
          value={props.operator}
        />
      )}
    </div>
  );
};
