import * as React from 'react';
import { Link } from 'react-router-dom';
import { InnerPageContainer } from '../InnerPageContainer/InnerPageContainer';
import { map } from 'lodash';
import cs from 'classnames';
import { Icon, IconDefinition } from '@telia/styleguide';

interface Props {
  narrow?: boolean;
  breadcrumbs?: Array<{
    label: string;
    to?: string;
    active?: boolean;
  }>;
  serviceTags?: Array<{
    label: string;
    icon?: IconDefinition;
    to?: string;
  }>;
  noPadding?: boolean;
  className?: string;
  children?: any;
}

export const Page: React.SFC<Props> = props => {
  const container = (
    <InnerPageContainer
      narrow={props.narrow}
      className={cs('h-100', props.noPadding ? 'p-0' : 'pt-3', props.className)}
    >
      {props.children}
    </InnerPageContainer>
  );
  if (props.breadcrumbs || props.serviceTags) {
    return (
      <React.Fragment>
        <div
          style={{
            backgroundColor: '#f0f2f4',
            borderBottom: 'solid 1px #dbe3e8',
          }}
          className="py-2"
        >
          <InnerPageContainer>
            {props.serviceTags ? (
              <div className="float-right">
                {map(props.serviceTags, st =>
                  st.to ? (
                    <Link key={st.label} to={st.to} className="pl-3">
                      {st.icon && <Icon className="pr-1" icon={st.icon} />}
                      {st.label}
                    </Link>
                  ) : (
                    <span key={st.label} className="pl-3">
                      {st.icon && <Icon className="pr-1" icon={st.icon} />}
                      {st.label}
                    </span>
                  )
                )}
              </div>
            ) : null}
            {props.breadcrumbs ? (
              <ol className="Page-breadcrumbs">
                {map(props.breadcrumbs, (b, x) => (
                  <li
                    key={`${b.to}-${b.label}`}
                    className={b.active ? 'active' : undefined}
                  >
                    {b.to ? <Link to={b.to}>{b.label}</Link> : b.label}
                  </li>
                ))}
              </ol>
            ) : null}
          </InnerPageContainer>
        </div>

        {container}
      </React.Fragment>
    );
  }
  return container;
};
