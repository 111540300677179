import React from 'react';
import { Fullscreen, Button } from 'src/lib/ui';

interface Props {
  onClose: () => void;
  children: React.ReactNode;
}

export const TempFullscreen: React.FC<Props> = props => {
  return (
    <Fullscreen>
      <div
        className="container my-4"
        style={{ position: 'relative', paddingTop: '4rem' }}
      >
        <div style={{ position: 'absolute', top: 0, right: 0 }}>
          <Button icon="close" hideLabel={true} onClick={props.onClose}>
            close
          </Button>
        </div>
        <div>{props.children}</div>
      </div>
    </Fullscreen>
  );
};
