import * as React from 'react';
import { Card } from 'src/lib/ui';
import { map } from 'lodash';
import './MobileCostSummary.scss';
import MobileCostSummaryList from './MobileCostSummaryList';
import { parsePeriod } from 'src/lib/utils';
import { Period, PeriodBy } from 'src/lib/types';
import { CircleChart } from 'src/lib/charts';
import { t } from 'src/lib/i18n';

interface Props {
  data: Array<{ label: string; totalAmountNet: number; color: string }>;
  activeIndex: number | undefined;
  setActiveIndex: (index: number | undefined) => void;
  activePeriod: Period;
  periodBy: PeriodBy;
}

const MobileCostSummary: React.SFC<Props> = props => {
  const totalCost = props.data.reduce(
    (total, y) => total + y.totalAmountNet,
    0
  );
  const costWithPercent =
    totalCost > 0
      ? props.data.map(d => ({
          ...d,
          percent: d.totalAmountNet / totalCost,
        }))
      : props.data;

  const periodMeta = parsePeriod(props.activePeriod);

  const circleChartSegments = map(props.data, cat => ({
    color: cat.color,
    label: cat.label,
    value: cat.totalAmountNet,
  }));
  const i18n = t.costs.ui.MobileCostSummary;

  return (
    <Card className="MobileCostSummary p-0">
      <div className="MobileCostSummary-header pt-2 pb-2 pl-4 pr-4">
        <div className="MobileCostSummary-header-left">
          <h3>
            {i18n.header(props.periodBy, periodMeta.monthLong, periodMeta.year)}
          </h3>
        </div>
        <div>{/* <Button icon="download">{i18n.downloadBtn}</Button> */}</div>
      </div>
      <div className="MobileCostSummary-body p-4">
        <div style={{ width: '350px' }}>
          <MobileCostSummaryList
            items={costWithPercent}
            total={totalCost}
            activeIndex={props.activeIndex}
            setActiveIndex={props.setActiveIndex}
          />
        </div>
        <div className="pl-5" style={{ minWidth: '300px' }}>
          <CircleChart
            activeIndex={props.activeIndex}
            segments={circleChartSegments}
            label={i18n.circleChartLabel}
            onClickSegment={idx =>
              props.setActiveIndex(idx === props.activeIndex ? undefined : idx)
            }
          />
        </div>
      </div>
    </Card>
  );
};

export default MobileCostSummary;
