import React, { useState, useEffect } from 'react';
import { useFormik } from 'formik';
import {
  TextField,
  Checkbox,
  RadioButton,
  TextArea,
  colors,
  Button,
  FormSubmitBar,
} from 'src/lib/ui';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { useSetChangelogMutation } from './mutation/useSetChangelogMutation';
import { DateTimePicker } from 'src/lib/ui/DateTimePicker/DateTimePicker';
import { availableIcons } from '@telia/styleguide';
import moment from 'moment';

import * as yup from 'yup';
import { GetChangelogsInput } from './query/WithChangelogs';
import { useExpandableTextArea } from 'src/lib/ui/TextArea/useExpandableTextArea';

interface Props {
  /*
    If 'id' is included, it will run an edit on an existing changelog.
    If not, it will create a new one.
  */
  id?: string;
  initialValues?: FormValues;
  getChangelogInput: GetChangelogsInput;
}

interface FormValues {
  icon: string;
  headingNorwegian: string;
  headingEnglish: string;
  bodyEnglish: string | null;
  bodyNorwegian: string | null;
  summaryEnglish: string;
  summaryNorwegian: string;
  publishedAt: string | null;
  hidden: boolean;
}

type PublishState = 'ready' | 'draft';

export const SetChangelogModal: React.FunctionComponent<Props> = props => {
  const setChangelogMutation = useSetChangelogMutation();
  const [open, setOpen] = useState(false);
  const textAreaRefSummaryNorwegian = useExpandableTextArea();
  const textAreaRefSummaryEnglish = useExpandableTextArea();
  const textAreaRefBodyNorwegian = useExpandableTextArea();
  const textAreaRefBodyEnglish = useExpandableTextArea();

  function toggleState() {
    setOpen(!open);
  }

  function isStringValidIconDefinition(value: string): boolean {
    return Boolean(availableIcons.find(icon => icon.toString() === value));
  }

  useEffect(() => {
    if (setChangelogMutation.data?.setChangelog?.error) {
      return;
    }
    setOpen(false);
  }, [setChangelogMutation.data?.setChangelog]); // eslint-disable-line react-hooks/exhaustive-deps

  const isNew = props.id === undefined;

  const formik = useFormik<FormValues>({
    initialValues: {
      publishedAt:
        props.initialValues && props.initialValues.publishedAt !== null
          ? props.initialValues?.publishedAt
          : moment()
              .set({ h: 0, m: 0, s: 0 })
              .format('YYYY-MM-DD HH:mm:ss'),
      headingNorwegian: props.initialValues?.headingNorwegian ?? '',
      headingEnglish: props.initialValues?.headingEnglish ?? '',
      bodyNorwegian: props.initialValues?.bodyNorwegian ?? '',
      bodyEnglish: props.initialValues?.bodyEnglish ?? '',
      summaryNorwegian: props.initialValues?.summaryNorwegian ?? '',
      summaryEnglish: props.initialValues?.summaryEnglish ?? '',
      hidden: props.initialValues?.hidden ?? false,
      icon: props.initialValues?.icon ?? '',
    },
    onSubmit: (values, formikBag) => {
      const res = setChangelogMutation.setChangelog(
        {
          id: props.id,
          headingNorwegian: values.headingNorwegian,
          bodyNorwegian: values.bodyNorwegian,
          headingEnglish: values.headingEnglish,
          bodyEnglish: values.bodyEnglish,
          summaryEnglish: values.summaryEnglish,
          summaryNorwegian: values.summaryNorwegian,
          hidden: values.hidden,
          publishedAt: values.publishedAt,
          icon: values.icon,
        },
        props.getChangelogInput.page,
        props.getChangelogInput.size,
        props.getChangelogInput.from,
        props.getChangelogInput.includeUnpublished
      );
      // Necessary to avoid prefilled form on second create button push
      if (isNew) {
        formikBag.resetForm();
      }
      return res;
    },
    validationSchema: () =>
      yup.object().shape({
        publishedAt: yup.string().nullable(true),
        icon: yup
          .string()
          .test('valid type', 'Not a valid icon type', value =>
            isStringValidIconDefinition(value)
          )
          .required('Required'),
        headingNorwegian: yup
          .string()
          .max(250, 'Too long')
          .required('Required'),
        headingEnglish: yup
          .string()
          .max(250, 'Too long')
          .required('Required'),
        bodyNorwegian: yup.string(),
        bodyEnglish: yup.string(),
        summaryNorwegian: yup.string().required('Required'),
        summaryEnglish: yup.string().required('Required'),
      }),
  });

  const publishState: PublishState = formik.values.publishedAt
    ? 'ready'
    : 'draft';

  return (
    <>
      <Button
        icon={isNew ? 'add' : 'pen'}
        className="mr-2"
        size="small"
        onClick={toggleState}
        style={{ color: colors.corePurple }}
      >
        {isNew ? 'Lag ny' : 'Edit'}
      </Button>
      <Modal isOpen={open} toggle={toggleState} size="lg">
        <ModalHeader>{isNew ? 'Lag ny' : 'Rediger'}</ModalHeader>
        <ModalBody>
          {setChangelogMutation.data?.setChangelog?.error
            ? 'Something went wrong!'
            : undefined}
          <form onSubmit={formik.handleSubmit}>
            <div>
              <TextField
                id="headingNorwegian"
                label="Overskrift norsk"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.headingNorwegian}
                required={true}
                error={
                  formik.touched.headingNorwegian &&
                  formik.errors.headingNorwegian
                    ? formik.errors.headingNorwegian
                    : undefined
                }
              />
            </div>
            <div>
              <TextArea
                id="summaryNorwegian"
                label="Oppsummering norsk"
                innerRef={textAreaRefSummaryNorwegian}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.summaryNorwegian}
                required={true}
                error={
                  formik.touched.summaryNorwegian &&
                  formik.errors.summaryNorwegian
                    ? formik.errors.summaryNorwegian
                    : undefined
                }
              />
            </div>
            <div>
              <TextArea
                id="bodyNorwegian"
                label="Innhold norsk"
                innerRef={textAreaRefBodyNorwegian}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bodyNorwegian ?? ''}
                required={false}
                error={
                  formik.touched.bodyNorwegian && formik.errors.bodyNorwegian
                    ? formik.errors.bodyNorwegian
                    : undefined
                }
              />
            </div>
            <div>
              <TextField
                id="headingEnglish"
                label="Overskrift engelsk"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.headingEnglish}
                required={true}
                error={
                  formik.touched.headingEnglish && formik.errors.headingEnglish
                    ? formik.errors.headingEnglish
                    : undefined
                }
              />
            </div>
            <div>
              <TextArea
                id="summaryEnglish"
                label="Oppsummering engelsk"
                innerRef={textAreaRefSummaryEnglish}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.summaryEnglish}
                required={true}
                error={
                  formik.touched.summaryEnglish && formik.errors.summaryEnglish
                    ? formik.errors.summaryEnglish
                    : undefined
                }
              />
            </div>
            <div>
              <TextArea
                id="bodyEnglish"
                label="Innhold engelsk"
                innerRef={textAreaRefBodyEnglish}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.bodyEnglish ?? ''}
                required={false}
                error={
                  formik.touched.bodyEnglish && formik.errors.bodyEnglish
                    ? formik.errors.bodyEnglish
                    : undefined
                }
              />
            </div>
            <div>
              <TextField
                id="icon"
                label="Icon"
                value={formik.values.icon}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                required={true}
                error={
                  formik.touched.icon && formik.errors.icon
                    ? formik.errors.icon
                    : undefined
                }
              />
            </div>
            <div>
              <Checkbox
                label="Skjul for portalbrukere"
                checked={formik.values.hidden ? formik.values.hidden : false}
                id=""
                onChange={() => {
                  formik.setFieldValue('hidden', !formik.values.hidden);
                  formik.setFieldTouched('hidden');
                }}
              />
            </div>
            <div>
              <div>
                <RadioButton
                  label="Publiseres"
                  checked={publishState === 'ready'}
                  value={publishState}
                  onChange={() => {
                    formik.setFieldValue(
                      'publishedAt',
                      moment().format('YYYY-MM-DD HH:mm:ss')
                    );
                    formik.setFieldTouched('publishedAt');
                  }}
                />{' '}
                <div>
                  {formik.values.publishedAt !== null && (
                    <div>
                      Publiseringsdato:
                      <DateTimePicker
                        value={formik.values.publishedAt}
                        onChange={val => {
                          formik.setFieldValue('publishedAt', val);
                          formik.setFieldTouched('publishedAt');
                        }}
                      />
                    </div>
                  )}
                </div>
              </div>
              <div>
                <RadioButton
                  label="Utkast"
                  checked={publishState === 'draft'}
                  value={publishState}
                  onChange={() => {
                    formik.setFieldValue('publishedAt', null);
                    formik.setFieldTouched('publishedAt');
                  }}
                />
              </div>
            </div>
          </form>
        </ModalBody>
        <ModalFooter>
          <FormSubmitBar
            cancel={toggleState}
            submit={() => formik.handleSubmit()}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};
