import React from 'react';
import { Alert } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { formatDate } from 'src/lib/utils';
import { colors } from 'src/lib/ui/colors';
import { compact } from 'lodash';

export interface PlannedWork {
  id: string;
  startDate: string;
  endDate: string | Date;
  state: string | null;
  oldStartDate?: string;
  oldEndDate?: string;
  expectedTotalDowntimeInMinutes?: number | null;
  description?: string | null;
  serviceImpact?: string | null;
}

interface Props {
  plannedWork: PlannedWork;
}

const minutesToHours = n =>
  `${(n / 60) ^ 0}` +
  t.subscriptions.SubscriptionPlannedWorkCard.hours +
  ('' + (n % 60)) +
  ' min';

export const SubscriptionPlannedWorkCard: React.FunctionComponent<Props> = ({
  plannedWork,
}) => {
  const i18n = t.subscriptions.SubscriptionPlannedWorkCard;
  const headerText =
    i18n.plannedWorkHeader +
    ' ' +
    formatDate(plannedWork.startDate, 'shortWithTime') +
    i18n.to +
    formatDate(plannedWork.endDate, 'shortWithTime');

  const texts = compact([
    ...(plannedWork.description || '')?.split('\n'),
    plannedWork.serviceImpact,
  ]);

  return (
    <Alert
      status={plannedWork.state ? 'warning' : 'default'}
      icon="wrench"
      header={headerText}
      subHeader={
        plannedWork.expectedTotalDowntimeInMinutes
          ? i18n.expectedDowntime +
            ' ' +
            minutesToHours(plannedWork.expectedTotalDowntimeInMinutes)
          : i18n.noExpectedDowntime
      }
      secondSubHeader={'Ref. nr.: ' + plannedWork.id}
    >
      {texts.map((t, i) => (
        <p key={i} style={{ color: colors.black }}>
          {t}
        </p>
      ))}
      <small>{t.subscriptions.SubscriptionPlannedWorkCard.contact}</small>
    </Alert>
  );
};
