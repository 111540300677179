import React, { useEffect } from 'react';
import { useFormik } from 'formik';
import './EditNotifications.scss';
import { t } from 'src/lib/i18n';
import AreaSelector from './AreaSelector';
import { Subscription, Tag } from 'src/lib/types';
import { WithSearch } from 'src/areas/main/search';
import { SearchResult } from 'src/areas/main/search/types';
import TabbedRadioButtonPair from '../../../../lib/ui/TabbedRadioButtonGroup/TabbedRadioButtonGroup';
import { colors, Checkbox, toast } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';
import { Toggle } from '@telia/styleguide/business';
import {
  NotificationSettingsInput,
  NotificationChannel,
  NotificationSettingsType,
} from 'src/__types/graphql-global-types';
import { NotificationSettings } from '../mutations/useNotificationSettings';
import { trackNotificationSettings } from 'src/lib/analytics';
import { NotificationSettingsActions } from 'src/lib/analytics/NotificationSettings';
import { useUpdateNotificationSettingsOnUser } from '../mutations/useUpdateNotificationSettingsOnUser';
import {
  useContact_customer_contacts_notificationSettings_change,
  useContact_customer_contacts_notificationSettings_incident,
} from 'src/areas/main/contacts/queries/__types/useContact';
import { useFeature } from 'src/lib/utils';

interface Props {
  contactId?: string;
  forceEmailChannel?: boolean;
  title: string;
  notifications: NotificationSettings;
  allowAllNotifications?: boolean;
  allowSelectChannel?: boolean;
  setSettings?: (
    settings:
      | useContact_customer_contacts_notificationSettings_incident
      | useContact_customer_contacts_notificationSettings_change
      | null
  ) => void;
}

interface Values {
  sms: boolean;
  email: boolean;
  selectedSubscriptions: Subscription[];
  notificationsLevel: 'all' | 'selected';
  activeNotification: boolean;
}

export const EditNotifications: React.FC<Props> = props => {
  const i18n = t.profile.editNotifications;
  const { updateSettings, error } = useUpdateNotificationSettingsOnUser(
    props.contactId
  );

  const hasTeliaNow = useFeature('telia-now');

  const { handleSubmit, values, ...formik } = useFormik<Values>({
    initialValues: {
      email: !!props.notifications?.channels?.find(
        channel => channel === 'email'
      ),
      sms: !!props.notifications?.channels?.find(channel => channel === 'sms'),
      selectedSubscriptions:
        (props.notifications?.subscriptions?.filter(
          sub => !!sub
        ) as Subscription[]) || [],
      notificationsLevel: props.notifications.notifyForAllCustomerSubscriptions
        ? 'all'
        : 'selected',
      activeNotification: hasTeliaNow ? true : props.notifications.active,
    },
    onSubmit: values => {
      const subscriptionIds = values.selectedSubscriptions.map(
        subscription => subscription.id
      );

      /** As of 24.06.19 we do not support turnign off notifications in Min portal. Always includer 'portal' in the channels of the updated settings objects */
      const channels: NotificationChannel[] = [NotificationChannel.portal];
      if (values.email || props.forceEmailChannel) {
        channels.push(NotificationChannel.email);
      }
      if (values.sms) {
        channels.push(NotificationChannel.sms);
      }

      const settings: NotificationSettingsInput = {
        type: props.notifications.type,
        active: values.activeNotification,
        channels,
        subscriptionIds,
        notifyForAllCustomerSubscriptions: values.notificationsLevel === 'all',
      };
      updateSettings(settings);
    },
  });

  useEffect(() => {
    if (props.setSettings) {
      const channels: NotificationChannel[] = [NotificationChannel.portal];
      if (values.email || props.forceEmailChannel) {
        channels.push(NotificationChannel.email);
      }
      if (values.sms) {
        channels.push(NotificationChannel.sms);
      }

      const settings = {
        type: props.notifications.type,
        active: values.activeNotification,
        channels,
        subscriptions: values.selectedSubscriptions,
        notifyForAllCustomerSubscriptions: values.notificationsLevel === 'all',
      };

      if (props.notifications.type === NotificationSettingsType.incident) {
        props.setSettings(
          settings as useContact_customer_contacts_notificationSettings_incident
        );
      } else {
        props.setSettings(
          settings as useContact_customer_contacts_notificationSettings_change
        );
      }
    } else {
      handleSubmit();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    handleSubmit,
    values.sms,
    values.email,
    values.selectedSubscriptions,
    values.notificationsLevel,
    values.activeNotification,
  ]);

  useEffect(() => {
    if (error) {
      toast('error', i18n.error);
    }
  }, [error, i18n.error]);

  const toggle = () => {
    trackNotificationSettings(
      'toggle',
      props.title,
      (!values.activeNotification).toString()
    );
    formik.setFieldValue('activeNotification', !values.activeNotification);
  };

  const setChannel = (e: React.ChangeEvent<HTMLInputElement>) => {
    trackNotificationSettings(
      `set_channel_${e.target.value}` as NotificationSettingsActions,
      props.title,
      e.target.checked.toString()
    );
    formik.setFieldValue(e.target.value, e.target.checked);
  };

  const setServices = (serviceSetting: string) => {
    trackNotificationSettings('set_services', props.title, serviceSetting);
    formik.setFieldValue('notificationsLevel', serviceSetting);
  };

  const selectServices = (currentSubscriptions: SearchResult[]) => {
    if (values.selectedSubscriptions.length < currentSubscriptions.length) {
      trackNotificationSettings('select_services', props.title, 'add service');
    } else {
      trackNotificationSettings(
        'select_services',
        props.title,
        'remove service'
      );
    }
    formik.setFieldValue('selectedSubscriptions', currentSubscriptions);
  };

  return (
    <>
      <div className="EditNotifications-header">
        <div>
          <h3>{props.title}</h3>
          {!props.allowAllNotifications &&
            props.notifications.notifyForAllCustomerSubscriptions && (
              <small style={{ color: `${colors.greyDarkText}` }}>
                <Icon icon="info" />
                {i18n.globalNotificationsInformation}
              </small>
            )}
        </div>
        {!hasTeliaNow && (
          <Toggle checked={values.activeNotification} onChange={toggle} />
        )}
      </div>
      {values.activeNotification && (
        <div className="EditNotifications">
          <div className="EditNotifications-section">
            {props.allowSelectChannel ? (
              <>
                <p className="EditNotifications-section-title mediumBold">
                  {i18n.channelDescription}
                </p>
                <div className="EditNotifications-checkboxGroup">
                  <Checkbox
                    checked={values.email}
                    onChange={setChannel}
                    label={i18n.email}
                    value="email"
                  />
                  <Checkbox
                    checked={values.sms}
                    onChange={setChannel}
                    label={i18n.sms}
                    value="sms"
                  />
                </div>
              </>
            ) : (
              <p className="EditNotifications-section-title mediumBold">
                {i18n.emailNotifications}
              </p>
            )}
          </div>

          <div className="EditNotifications-section">
            <p className="EditNotifications-section-title mediumBold">
              {i18n.subscriptionSelection}
            </p>
            {props.allowAllNotifications && (
              <TabbedRadioButtonPair
                id="notificationsLevel"
                parentContainerClassName="NotificationSection"
                value={values.notificationsLevel}
                options={[
                  { value: 'all', label: i18n.all },
                  { value: 'selected', label: i18n.selected },
                ]}
                tabbedOptions={[{ value: 'selected', label: i18n.selected }]}
                onChange={value => setServices(value ?? '')}
              />
            )}
          </div>
          {!props.allowAllNotifications ||
          values.notificationsLevel === 'selected' ? (
            <WithSearch
              initial={{
                types: ['subscription', 'tag'],
                filter: [
                  {
                    filter: 'group',
                    value: 'data',
                  },
                ],
              }}
            >
              {search => (
                <AreaSelector
                  searchProvider={search}
                  title={i18n.subscriptionSearchHeader}
                  searchPlaceholder={i18n.placeholder}
                  selectedItems={values.selectedSubscriptions}
                  availableItems={search.results as Subscription[] | Tag[]}
                  onChange={currentSubscriptions =>
                    selectServices(currentSubscriptions)
                  }
                />
              )}
            </WithSearch>
          ) : null}
        </div>
      )}
    </>
  );
};
