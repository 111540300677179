import gql from 'graphql-tag';

export const UnitOrderDetailsFragment = gql`
  fragment UnitOrderDetailsResult on UnitOrderDetails {
    postalArea
    postalCode
    quantity
    status
    attention
    address
    orderId
  }
`;
