import React from 'react';
import { t } from 'src/lib/i18n';
import ContactPermissionsForm from './ContactPermissionsForm';
import { InfoCard, StepFlowCard } from 'src/lib/ui';
import { FormState } from './CreateContact';
import { minPortalUrl } from 'src/translations/variables';

export const Step3 = (props: { form: FormState; isValid: boolean }) => {
  return (
    <StepFlowCard
      title={t.contacts.create.step3.title}
      step={2}
      currentStep={props.form.currentStep}
      isValid={props.isValid}
      onClick={() => props.form.setStep(0)}
      onContinue={props.form.nextStep}
      hideContinue={true}
      isLastStep={true}
      form={
        <div>
          <ContactPermissionsForm
            roles={props.form.values.roles}
            setFieldValue={props.form.setValue}
          />
          <InfoCard>
            <div className="d-flex flex-column">
              <div className="font-weight-bold mb-2">
                {t.contacts.create.whatHappensNext}
              </div>
              <div className="mb-2">
                {t.contacts.create.emailInfo_part1}
                <a href={minPortalUrl} className="pl-1">
                  {minPortalUrl}
                </a>
                {t.contacts.create.emailInfo_part2}
              </div>
              <div>{t.contacts.create.emailInfo_part3}</div>
            </div>
          </InfoCard>
        </div>
      }
    />
  );
};
