import * as React from 'react';
import cs from 'classnames';

import './DetailCard.scss';
import { Heading } from '@telia/styleguide';

interface Props {
  heading?: string;
  className?: string;
  color?: 'white';
}

export const DetailCard: React.FC<Props> = props => (
  <div
    className={cs('DetailCard', props.className, {
      'DetailCard--white': props.color === 'white',
    })}
  >
    {props.heading ? (
      <div className="DetailCard__heading">
        <Heading tag="h2" size="s" text={props.heading} />{' '}
      </div>
    ) : null}

    {props.children}
  </div>
);
