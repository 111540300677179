import gql from 'graphql-tag';
import { DirectoryDetails } from 'src/areas/mobile/types';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { useMutation } from 'react-apollo';
import { SUBSCRIPTION_DIRECTORY_DETAILS } from 'src/areas/mobile/lib/useSubscriptionDirectoryDetails';
import produce from 'immer';

export const UPDATE_SUBSCRIPTION_DIRECTORY_DETAILS = gql`
  mutation useUpdateSubscriptionDirectoryDetails(
    $input: UpdateMobileDirectoryInput
  ) {
    updateMobileDirectory(input: $input) {
      id
      directory {
        firstName
        middleName
        lastName
        streetAddress
        postCode
        postArea
        country
        visibility
      }
    }
  }
`;

interface Data {
  updateMobileDirectory: {
    msisdn: string;
    directory: DirectoryDetails;
  };
}

interface Variables {
  input: {
    id: string;
  } & DirectoryDetails;
}

export interface UpdateDirectoryDetailsResult {
  loading: boolean;
  error: boolean;
  data?: Data;
  updateDirectory: (directory: DirectoryDetails) => void;
}

export const useUpdateSubscriptionDirectoryDetails = (): UpdateDirectoryDetailsResult => {
  const subscription = useSubscriptionContext();
  const [mutation, { data, loading, error }] = useMutation<Data, Variables>(
    UPDATE_SUBSCRIPTION_DIRECTORY_DETAILS,
    {
      update: (cache, res) => {
        if (
          res.data &&
          res.data.updateMobileDirectory &&
          res.data.updateMobileDirectory.directory
        ) {
          const cached = cache.readQuery({
            query: SUBSCRIPTION_DIRECTORY_DETAILS,
            variables: { id: subscription.id },
          });

          const newDirectory = res.data.updateMobileDirectory.directory;
          const newCache = produce(cached, draft => {
            (cached as any).subscription.directory = newDirectory;
          });

          cache.writeQuery({
            query: SUBSCRIPTION_DIRECTORY_DETAILS,
            variables: { id: subscription.id },
            data: newCache,
          });
        }
      },
    }
  );

  const updateDirectory = (directory: DirectoryDetails) => {
    mutation({
      variables: {
        input: {
          id: subscription.id,
          ...directory,
        },
      },
    });
  };

  return {
    updateDirectory,
    data,
    error: !!error,
    loading,
  };
};
