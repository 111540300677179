import React, { useContext, useState, useEffect } from 'react';
import { ChatActivity, CommonActivityProps, MessageActivity } from './types';
import { orderBy } from 'lodash';

type ChatContextProps = {
  activeUserId: string;
  messages: ChatActivity[];
  chatInput: string;
  selectable?: boolean;
  handlers: {
    handleSend: (e: React.FormEvent) => void;
    handleInputChange: (e: React.ChangeEvent<HTMLTextAreaElement>) => void;
    clearInput?: () => void;
  };
  commentHandlers?: {
    handleEdit?: (commentId: string, message: string) => void;
    handleDelete?: (commentId: string) => void;
  };
  disableInput?: boolean;
  loadingSend?: boolean;
  innerRef?: React.RefObject<HTMLTextAreaElement>;
  contentRef?: React.MutableRefObject<HTMLDivElement | null>;
  inputActions?: Array<React.ReactElement>;
  placeholder?: string;
  expandInputOnFocus?: boolean;
  noResult?: React.ReactNode;
  emailSubject?: string;
  setEmailSubject?: (subject: string) => void;
} & (
  | {
      files: File[];
      isUploadingFiles: boolean;
      handlers: {
        handleRemoveFile: (name: string) => void;
        handleFileInputChange: (e: React.ChangeEvent<HTMLInputElement>) => void;
      };
    }
  | {}
);

type SelectedMessageProps = {
  selectedMessages: Array<MessageActivity & CommonActivityProps>;
  setSelectedMessages: (
    message: Array<MessageActivity & CommonActivityProps>
  ) => void;
};
type EditingMessages = {
  openMessages: ChatActivity[];
  setOpenMessages: (message: ChatActivity[]) => void;
};

type ChatContext = ChatContextProps &
  SelectedMessageProps &
  EditingMessages & { isEditing: boolean };

const Context = React.createContext<ChatContext | undefined>(undefined);

export const useChatContext = (): ChatContext => {
  const context = useContext(Context);
  if (!context) {
    throw new Error('No chat context provided');
  }
  return context;
};

export const ChatContextProvider: React.FC<{
  value: ChatContextProps;
}> = props => {
  const [openMessages, setOpenMessages] = useState<ChatActivity[]>([]);
  const [selectedMessages, setSelectedMessages] = useState<
    Array<MessageActivity & CommonActivityProps>
  >([]);

  useEffect(
    () => setSelectedMessages(orderBy(selectedMessages, 'timestamp', 'desc')),
    [selectedMessages.length] // eslint-disable-line react-hooks/exhaustive-deps
  );

  return (
    <Context.Provider
      value={{
        ...props.value,
        openMessages,
        setOpenMessages,
        isEditing: !!props.value.chatInput || !!openMessages.length,
        selectedMessages: props.value.selectable ? selectedMessages : [],
        setSelectedMessages: setSelectedMessages,
      }}
    >
      {props.children}
    </Context.Provider>
  );
};
