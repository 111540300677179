import { dateDiffDuration } from 'src/lib/utils';

// time format YYYY-MM-DD HH:MM:SS (optional time)
export const isAggregatedByDay = (periodStart: string, periodEnd: string) => {
  /**
   * When statistics is fetched for a period larger than one week,
   * the statistics is  aggregates by day.
   *
   * At upto and including exactly a week, it is aggregated by hour
   * */
  const periodSpan = dateDiffDuration(
    new Date(periodStart),
    new Date(periodEnd)
  );

  return (
    periodSpan.days > 6 &&
    !(
      periodSpan.days === 7 &&
      periodSpan.hours === 0 &&
      periodSpan.minutes === 0
    )
  );
};
