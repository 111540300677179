import React, { useEffect, useRef } from 'react';
import { useI18nContext } from 'src/lib/i18n';
import * as types from '../../../areas/dev/changelog/query/__types/WithChangelogs';
import { Card } from 'src/lib/ui';
import './ChangelogCardGrouped.scss';
import { Icon, IconDefinition } from '@telia/styleguide';

import { formatDate } from 'src/lib/utils';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';

type Props = {
  className?: string;
  publishedAtDate: string;
  changelogs: types.WithChangelogs_changelogs[];
};

const ChangelogCardGrouped: React.FC<Props> = props => {
  const locale = useI18nContext();
  const query = useQueryParameters();
  const goToPublishedDate = query.get('publishedDate');
  let scrollRef = useRef<HTMLDivElement>(null);

  // FullscreenHeader is fixed with height of 48px
  const pixelDifferenceToHeader = 48;
  useEffect(() => {
    if (goToPublishedDate && goToPublishedDate === props.publishedAtDate) {
      const container = document.getElementsByClassName('Fullscreen');
      const item = container?.item(0);
      if (item && scrollRef?.current) {
        item.scrollTop = scrollRef.current.offsetTop - pixelDifferenceToHeader;
      }
    }
  }, [scrollRef.current, goToPublishedDate]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div className="ChangelogCardGrouped" ref={scrollRef}>
      <div className="ChangelogCardGrouped-publishedDate">
        {formatDate(props.publishedAtDate, 'long', locale.locale)}
      </div>
      <Card className="ChangelogCardGrouped-card" shadow="1" border={true}>
        {props.changelogs.map(changelog => (
          <div key={changelog.id} className="ChangelogCardGrouped-card-single">
            <div className="ChangelogCardGrouped-card-header">
              <div>
                <Icon
                  className="ChangelogCardGrouped-card-header-icon"
                  icon={changelog.icon as IconDefinition}
                  style={{ height: '2.5rem', width: '2.5rem' }}
                />
              </div>
              <div>
                {locale.locale === 'en'
                  ? changelog.headingEnglish
                  : changelog.headingNorwegian}
              </div>
            </div>
            <div className="ChangelogCardGrouped-card-summary">
              {locale.locale === 'en'
                ? changelog.summaryEnglish
                : changelog.summaryNorwegian}
            </div>
            <div className="ChangelogCardGrouped-card-body">
              {locale.locale === 'en'
                ? changelog.bodyEnglish
                : changelog.bodyNorwegian}
            </div>
          </div>
        ))}
      </Card>
    </div>
  );
};

export default ChangelogCardGrouped;
