const cases = {
  cases: 'Saker',
  sort: 'Sorter',
  createdNewest: 'Opprettet nyeste først',
  createdOldest: 'Opprettet eldste først',
  updated: 'Sist endret',
  incident: 'Feilmelding',
  plannedWork: 'Planlagt arbeid',
  active: 'Aktiv',
  closed: 'Lukket',
  reset: 'Nullstill',
  use: 'Bruk',
};

export default cases;
