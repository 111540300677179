import { SortOrder } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';

type SortKey =
  | 'type'
  | 'phoneNumber'
  | 'ownerName'
  | 'subscriptionId'
  | 'subscriptionAlias'
  | 'subscriptionName'
  | 'address'
  | 'monitoringStatus'
  | 'alias'
  | 'firstName'
  | 'lastName'
  | 'email'
  | 'status'
  | 'id'
  | 'createdAt'
  | 'updated'
  | 'date'
  | 'dueDate'
  | 'net';
export const getSortOptions = (sortKeys: Array<SortKey>) =>
  sortKeys.flatMap(key => getSortOption(key));

/**
 * Single is used to spesify if a sort option stands alone in the presentation
 * (or is presented alongside other sort options)
 *
 * We use a different lable for single options --
 * ascending/descending in stead of a label describing the sort property
 */
export const getSortOption = (sortKey: SortKey, single?: boolean) => {
  const i18n = t.subscriptions.sortFields;
  const alias = [
    {
      key: 'alias',
      order: SortOrder.asc,
      label: (single ? i18n.ascending : i18n.alias) + ': ' + i18n.AtoÅ,
    },
    {
      key: 'alias',
      order: SortOrder.desc,
      label: (single ? i18n.descending : i18n.alias) + ': ' + i18n.ÅtoA,
    },
  ];
  const type = [
    {
      key: 'type',
      order: SortOrder.asc,
      label: (single ? i18n.ascending : i18n.type) + ': ' + i18n.AtoÅ,
    },
    {
      key: 'type',
      order: SortOrder.desc,
      label: (single ? i18n.descending : i18n.type) + ': ' + i18n.ÅtoA,
    },
  ];
  const monitoringStatus = [
    {
      key: 'monitoringStatus',
      order: SortOrder.desc,
      label: i18n.status + ': ' + i18n.importantFirst,
    },
  ];

  const address = [
    {
      key: 'address',
      order: SortOrder.asc,
      label: (single ? i18n.ascending : i18n.address) + ': ' + i18n.AtoÅ,
    },
    {
      key: 'address',
      order: SortOrder.desc,
      label: (single ? i18n.descending : i18n.address) + ': ' + i18n.ÅtoA,
    },
  ];
  const subscriptionId = [
    {
      key: 'subscriptionId',
      label: (single ? i18n.ascending : i18n.id) + ': ' + i18n.zeroToNine,
      order: SortOrder.asc,
    },
    {
      key: 'subscriptionId',
      label: (single ? i18n.descending : i18n.id) + ': ' + i18n.nineToZero,
      order: SortOrder.desc,
    },
  ];
  const subscriptionName = [
    {
      key: 'subscriptionName',
      label: (single ? i18n.ascending : i18n.type) + ': ' + i18n.AtoÅ,
      order: SortOrder.asc,
    },
    {
      key: 'subscriptionName',
      label: (single ? i18n.descending : i18n.type) + ': ' + i18n.ÅtoA,
      order: SortOrder.desc,
    },
  ];

  const subscriptionAlias = [
    {
      key: 'subscriptionAlias',
      label: (single ? i18n.ascending : i18n.alias) + ': ' + i18n.AtoÅ,
      order: SortOrder.asc,
    },
    {
      key: 'subscriptionAlias',
      label: (single ? i18n.descending : i18n.alias) + ': ' + i18n.ÅtoA,
      order: SortOrder.desc,
    },
  ];

  const ownerName = [
    {
      key: 'ownerName',
      label: (single ? i18n.ascending : i18n.name) + ': ' + i18n.AtoÅ,
      order: SortOrder.asc,
    },
    {
      key: 'ownerName',
      label: (single ? i18n.descending : i18n.name) + ': ' + i18n.ÅtoA,
      order: SortOrder.desc,
    },
  ];
  const phoneNumber = [
    {
      key: 'phoneNumber',
      label:
        (single ? i18n.ascending : i18n.phoneNumber) + ': ' + i18n.zeroToNine,
      order: SortOrder.asc,
    },
    {
      key: 'phoneNumber',
      label:
        (single ? i18n.descending : i18n.phoneNumber) + ': ' + i18n.nineToZero,
      order: SortOrder.desc,
    },
  ];

  const firstName = [
    {
      key: 'firstName',
      order: SortOrder.asc,
      label: `${single ? i18n.ascending : t.contacts.sortFields.firstName}: ${
        i18n.AtoÅ
      }`,
    },
    {
      key: 'firstName',
      order: SortOrder.desc,
      label: `${single ? i18n.descending : t.contacts.sortFields.firstName}: ${
        i18n.ÅtoA
      }`,
    },
  ];

  const lastName = [
    {
      key: 'lastName',
      order: SortOrder.asc,
      label: `${single ? i18n.ascending : t.contacts.sortFields.lastName}: ${
        i18n.AtoÅ
      }`,
    },
    {
      key: 'lastName',
      order: SortOrder.desc,
      label: `${single ? i18n.descending : t.contacts.sortFields.lastName}: ${
        i18n.ÅtoA
      }`,
    },
  ];

  const email = [
    {
      key: 'email',
      order: SortOrder.asc,
      label: `${single ? i18n.ascending : t.contacts.sortFields.email}: ${
        i18n.AtoÅ
      }`,
    },
    {
      key: 'email',
      order: SortOrder.desc,
      label: `${single ? i18n.descending : t.contacts.sortFields.email}: ${
        i18n.ÅtoA
      }`,
    },
  ];

  const status = [
    {
      key: 'status',
      order: SortOrder.asc,
      label: `${single ? i18n.ascending : t.incidents.sortFields.status}: ${
        i18n.leastImportant
      }`,
    },

    {
      key: 'status',
      order: SortOrder.desc,
      label: `${single ? i18n.descending : t.incidents.sortFields.status}: ${
        i18n.importantFirst
      }`,
    },
  ];

  const id = [
    {
      key: 'id',
      label:
        (single ? i18n.ascending : t.incidents.sortFields.id) +
        ': ' +
        i18n.zeroToNine,
      order: SortOrder.asc,
    },
    {
      key: 'id',
      label:
        (single ? i18n.descending : t.incidents.sortFields.id) +
        ': ' +
        i18n.nineToZero,
      order: SortOrder.desc,
    },
  ];

  const createdAt = [
    {
      key: 'createdAt',
      label:
        (single ? i18n.ascending : t.incidents.sortFields.created) +
        ': ' +
        i18n.oldest,
      order: SortOrder.asc,
    },
    {
      key: 'createdAt',
      label:
        (single ? i18n.descending : t.incidents.sortFields.created) +
        ': ' +
        i18n.mostRecent,
      order: SortOrder.desc,
    },
  ];

  const updated = [
    {
      key: 'updated',
      label:
        (single ? i18n.ascending : t.incidents.sortFields.updated) +
        ': ' +
        i18n.oldest,
      order: SortOrder.asc,
    },
    {
      key: 'updated',
      label:
        (single ? i18n.descending : t.incidents.sortFields.updated) +
        ': ' +
        i18n.mostRecent,
      order: SortOrder.desc,
    },
  ];

  const date = [
    {
      key: 'date',
      label:
        (single ? i18n.ascending : t.invoices.invoiceDate) + ': ' + i18n.oldest,
      order: SortOrder.asc,
    },
    {
      key: 'date',
      label:
        (single ? i18n.descending : t.invoices.invoiceDate) +
        ': ' +
        i18n.mostRecent,
      order: SortOrder.desc,
    },
  ];

  const dueDate = [
    {
      key: 'dueDate',
      label:
        (single ? i18n.ascending : t.invoices.dueDate) + ': ' + i18n.oldest,
      order: SortOrder.asc,
    },
    {
      key: 'dueDate',
      label:
        (single ? i18n.descending : t.invoices.dueDate) +
        ': ' +
        i18n.mostRecent,
      order: SortOrder.desc,
    },
  ];

  const net = [
    {
      key: 'net',
      label:
        (single ? i18n.ascending : t.invoices.sumExVat) +
        ': ' +
        i18n.zeroToNine,
      order: SortOrder.asc,
    },
    {
      key: 'net',
      label:
        (single ? i18n.descending : t.invoices.sumExVat) +
        ': ' +
        i18n.nineToZero,
      order: SortOrder.desc,
    },
  ];
  switch (sortKey) {
    case 'phoneNumber':
      return phoneNumber;
    case 'address':
      return address;
    case 'alias':
      return alias;
    case 'monitoringStatus':
      return monitoringStatus;
    case 'ownerName':
      return ownerName;
    case 'subscriptionId':
      return subscriptionId;
    case 'subscriptionAlias':
      return subscriptionAlias;
    case 'subscriptionName':
      return subscriptionName;
    case 'type':
      return type;
    case 'firstName':
      return firstName;
    case 'lastName':
      return lastName;
    case 'email':
      return email;
    case 'status':
      return status;
    case 'id':
      return id;
    case 'createdAt':
      return createdAt;
    case 'updated':
      return updated;
    case 'date':
      return date;
    case 'dueDate':
      return dueDate;
    case 'net':
      return net;
    default:
      return [];
  }
};
