import { customerSupportEmail, customerSupportNumber } from './../../variables';
import OrderIpVpn from './ordering.OrderIpVpn';

const ordering = {
  required: 'Påkrevd',
  numberOnly: 'Må være et tall',

  OrderIpVpn,
  OrderingModal: {
    cancelBtn: 'Avbryt',
    submitBtn: 'Bekreft',
  },
  SubscriptionOrdering: {
    heading: 'Bestill endringer til tjenesten',
  },
  dateLabels: {
    required: 'Fyll inn dato',
    match: 'Fyll inn dato (dd.mm.yyyy)',
    notFutureDate: 'Dato kan ikke være fram i tid',
    notPastDate: 'Velg en dato fram i tid.',
  },
  OrderingContent: {
    noOrdering: `Ønsker du å bestille andre tjenester? Chat med oss, ta kontakt på e-post ${customerSupportEmail}, eller ring tlf ${customerSupportNumber}, så hjelper vi deg med å ordne det.`,
    pageTitle: 'Bestilling',
    mobileHeader: 'Mobil',
    newSubscriptionBtn: {
      label: 'Bestill abonnement',
      helpText: 'Bestill et nytt mobilabonnement',
    },
    newM2mSubBtn: {
      label: 'Bestill M2M',
      helpText: 'Bestill et nytt M2M-abonnement',
    },
    newNumberSeriesBtn: {
      label: 'Bestill nummer',
      helpText: 'Reserver ett eller flere telefonnumre',
    },
    newReserveSimCardBtn: {
      label: 'Bestill SIM-kort',
      helpText: 'Bestill nye SIM-kort',
    },
    ordersHeader: 'Påbegynte bestillinger',
    newOrder: 'Ny bestilling',
    started: 'Påbegynt',
    lastUpdated: 'Sist oppdatert',
    deleteModal: {
      btn: 'Slett',
      header: 'Slett påbegynt bestilling',
      content: 'Ønsker du å slette påbegynt bestilling for nytt abonnement?',
    },
  },
  layout: {
    continue: 'Fortsett',
    OrderingFooter: {
      delete: 'Slett',
      save: 'Lagre og lukk',
      submit: 'Send bestilling',
      modal: {
        confirmText: 'Er du sikker på at du ønsker å slette bestillingen?',
        header: 'Slett',
      },
    },
  },
  MobileUnitOrder: {
    OrderNumberSeries: {
      pageHeader: 'Bestill telefonnumre',
      loading: 'Sender bestilling',
      submitted: {
        thankYou: 'Takk for din bestilling!',
        expectedDelivery:
          'Forventet levering av telefonnumre er 1-2 virkedager.',
        info: 'Du finner ordren i ordreoversikten med ordrenummer',
        orderDetails: 'Ordredetaljer',
      },
      ordering: {
        quantity: {
          label: 'Antall',
          error: 'Velg et antall',
        },
        comment: 'Kommentar',
        sendOrderBtn: 'Send bestilling',
      },
    },
    OrderReserveSimCards: {
      pageHeader: 'Bestill SIM-kort',
      loading: 'Sender bestilling',
      submitted: {
        thankYou: 'Takk for din bestilling!',
        expectedDelivery: 'Forventet levering av SIM-kort er 2-3 virkedager.',
        info: 'Du finner ordren i ordreoversikten med ordrenummer',
        orderDetails: 'Ordredetaljer',
      },
      ordering: {
        quantity: {
          label: 'Antall',
          error: 'Fyll inn antall.',
        },
        formFactor: {
          label: 'Velg SIM-korttype',
          error: 'Velg en SIM-korttype.',
          placeholder: 'Velg SIM-korttype',
        },
        address: {
          label: 'Adresse',
          error: 'Fyll inn adresse.',
        },
        postalCode: {
          label: 'Postnummer',
          error: 'Fyll inn postnummer.',
        },
        postalArea: {
          label: 'Poststed',
          error: 'Fyll inn poststed.',
        },
        att: {
          label: 'Tilleggsinformasjon',
          error: 'Fyll inn tilleggsinformasjon.',
        },
        sendOrderBtn: 'Send bestilling',
        formFactorOpts: {
          regular: 'Vanlig',
        },
      },
    },
  },
  OrderBlockSim: {
    errorMsg: 'Noe gikk galt! Vi klarte ikke sperre SIM-kortet',
    blockSimCard: 'Sperr SIM-kort',
    blockSim: 'Sperr SIM',
    description:
      'Ved sperring av SIM-kort blir abonnementet sperret for forbruk. Månedsavgiften på abonnementet fortsetter å løpe selv om SIM-kortet er sperret. Du kan når som helst gjenåpne det sperrede SIM-kortet.',
  },
  OrderOpenSimcard: {
    header: 'Åpne simkort',
    label: 'Åpne simkort',
    content: 'Ønsker du å åpne simkort?',
  },
  OrderTerminateMobileSubscription: {
    additionalSim: {
      terminateDataSim: 'Terminer ekstra data SIM-kort',
      terminateVoiceSim: 'Terminer ekstra tale SIM-kort',
      terminate: 'Terminer',
      description: 'SIM-kortet vil bli terminert 10 dager fra i dag.',
      errorMsg: 'Noe gikk galt! Vi klarte ikke terminere tjenesten.',
    },
    header: 'Avslutt abonnement',
    terminationDate: {
      label: 'Ønsket dato for oppsigelse',
      info:
        'Abonnementet vil bli sagt opp tidligst 10 dager fra dags dato. Abonnementet kan reaktiveres i løpet av 30 dager fra oppsigelsesdato.',
    },
    parkNumber: {
      label: number => `Ønsker du å beholder nummeret ${number}?`,
      info:
        'Beholder du nummeret kan dette tas i bruk med en gang abonnementet er sagt opp. Dersom du velger å ikke beholde nummeret vil dette bli tilbakeført til opprinnelig mobiloperatør etter 6 måneder.',
      options: {
        yes: 'Ja, behold nummer',
        no: 'Nei, kanseller nummer',
      },
    },
    btnSubmit: 'Avslutt abonnement',
    orderComplete: 'Fullført',
    error: 'Noe gikk galt, prøv igjen eller kontakt Telia.',
  },
  OrderExtraSim: {
    header: 'Bestill ekstra sim',
    deliveryDescription: 'Oppgi leveringsadresse for det nye SIM-kortet',
    deliveryTime:
      'Vi behandler din bestilling umiddelbart. Leveranse tar normalt 2-5 virkedager.',
    activationTime: 'Ditt nye SIM-kort blir aktivert i løpet av noen minutter.',
    simType: {
      label: 'SIM-type',
      error: 'Velg en type',
      opts: {
        voice: {
          label: 'Ekstra talekort',
          description:
            'Ekstra talekort lar deg ha flere enheter på samme abonnement, med samme telefonnummer. Det er kun mulig å ha ett ekstra talekort.',
        },
        data: {
          label: 'Ekstra datakort',
          description:
            'Med et ekstra datakort kan du surfe på nettbrettet med datamengden du har inkludert i abonnementet ditt. Det er mulig å ha opp til to ekstra datakort.',
        },
      },
    },
    name: {
      label: 'Navn',
      error: 'Fyll inn navn',
    },
    address: {
      label: 'Adresse',
      error: 'Fyll inn adresse',
    },
    postalArea: {
      label: 'Poststed',
      error: 'Fyll inn poststed',
    },
    postalCode: {
      label: 'Postnummer',
      error: 'Fyll inn postnummer (4 siffer)',
    },
    att: {
      label: 'Att',
    },
    submitBtn: 'Send bestilling',
    orderSent: 'Bestilling sendt',
  },
  OrderMobileSubscription: {
    pageTitleM2m: 'Bestill nytt M2M-abonnment',
    pageTitleMobile: 'Bestill nytt mobilabonnement',
    OrderMobileSubscriptionFailure: {
      header: 'Noe gikk galt...',
    },
    orderingError:
      'Bestilling ble ikke fullført. Oppdater siden og prøv igjen. Hvis problemet vedvarer, kontakt Telia.',
    orderingWarning:
      'Bestilling ble gjennomført, men vi fant ikke ordren for øyeblikket.',
    Step1: {
      title: 'Telefonnummer',
      header: 'Telefonnummer',
      phoneNumberSourceOpts: {
        new: 'Bestill nytt nummer',
        existing: 'Overfør eksisterende nummer',
      },
      phoneNumber: {
        label: 'Nummer',
        placeholderSelect: 'Velg et nummer',
        noOpts: 'Du har ingen tilgjengelig telefonnumre',
        placeholderTextM2m: 'Eks. 580012345678',
        placeholderTextMobile: 'Eks. 40820125',
        helpTextM2m: '12 siffer, uten landkode',
        helpTextMobile: '8 siffer, uten landkode',
        error: 'Fyll inn telefonnummer, 8 siffer uten landkode',
        errorM2m: 'Fyll inn telefonnummer, 12 siffer uten landkode',
        errorReserved: 'Nummeret er reservert, vennligst velg et annet',
      },
      formerOwnerHeader: 'Tidligere juridisk eier',
      formerOwner: {
        self: 'Abonnenten selv',
        company: 'Bedrift',
        private: 'Annen privatperson',
        error: 'Velg tidligere juridisk eier',
      },
      firstName: {
        label: 'Fornavn',
        placeholder: 'Eks. Navn',
        error: 'Fyll inn fornavn',
      },
      lastName: {
        label: 'Etternavn',
        placeholder: 'Eks. Navnesen',
        error: 'Fyll inn etternavn',
      },
      birthDate: {
        label: 'Fødselsdato',
        helpText: 'Fødselsdato er nødvendig for å bekrefte eierskifte',
        error: 'Fyll inn fødselsdato',
      },
      orgName: {
        label: 'Organisasjonsnavn',
        placeholder: 'Eks. Telia Norge AS',
        error: 'Fyll inn organisasjonsnavn',
      },
      orgNumber: {
        label: 'Organisasjonsnummer',
        placeholder: 'Eks. 981929055',
        error: 'Fyll inn organisasjonsnummer',
      },
      transferPaymentsToNewOwner: {
        label:
          'Ny ansvarlig bedrift samtykker til å ta over gjenværende bindingstid og bruddgebyr for GSM-nummer, videre samtykkes det til at GSM-nummeret overføres til samme, eller tilsvarende abonnement.',
        value: (value: boolean): string => (value ? 'Ja' : 'Nei'),
      },
    },
    Step2: {
      title: 'Abonnent',
      header: 'Abonnent',
      firstName: {
        label: 'Fornavn',
        placeholder: 'Eks. Navn',
        error: 'Fyll inn fornavn',
      },
      lastName: {
        label: 'Etternavn',
        placeholder: 'Eks. Navnesen',
        error: 'Fyll inn etternavn',
      },
      birthDate: {
        label: 'Fødselsdato',
        helpText: 'Fødselsdato er nødvendig for å bekrefte eierskifte',
        error: 'Fyll inn fødselsdato dd.mm.åååå',
      },
      email: {
        label: 'E-post',
        helpText:
          'E-postadressen er nødvendig for å gi abonnenten tilgang til min side for abonnementet',
        placeholder: 'Eks. eksempel@telia.no',
        error: 'Fyll inn e-postadresse',
      },
      employeeNumber: {
        label: 'Ansattnummer',
        placeholder: 'Eks. 1234',
      },
      jobTitle: {
        label: 'Stilling',
        placeHolder: 'Eks. prosjektleder',
      },
    },
    Step3: {
      title: 'Kostnadsinnstillinger',
      header: 'Kost og faktura',
      organisation: {
        label: 'Velg fakturasted',
        placeholder: 'Velg fakturasted',
        error: 'Velg ett fakturasted',
      },
      costCenter: {
        label: 'Velg kostnadssted',
        placeholder: 'Ikke valgt',
      },
      taxHeader: 'Skatteinnstillinger',
      taxModel: {
        opts: {
          none: 'Ikke satt',
          free: 'Fri',
          officePhone: 'Arbeidstelefon',
          departmentPhone: 'Kontortelefon',
          personal: 'Privat',
        },
        error: 'Velg felt',
        // special field for Bergen Kommune
      },
      fakturaMottaker: 'Fakturamottaker',
      areaOfUse: 'Velg bruksområde', // special field for Bergen Kommune
      notSelected: 'Ikke valgt',
    },
    Step4: {
      title: 'Abonnement',
      header: 'Velg abonnement',
      products: {
        establishmentFee: 'Etableringsgebyr',
        monthlyFee: 'Månedspris',
      },
      chooseSub: 'Velg abonnement',
      subscription: 'Abonnement',
      extra: 'Tilleggsprodukter',
      extraHeader: 'Velg tilleggstjenester',
    },
    Step5: {
      title: 'SIM-kort',
      header: 'SIM-kort',
      activateSim: 'Aktiver SIM',
      simcardSource: {
        opts: {
          new: 'Få tilsendt nytt SIM-kort',
          existing: 'Aktiver et SIM-kort du har',
        },
      },
      simcardNumber: {
        placeholder: 'Velg tilgjengelig simkort',
        error: 'Velg ett simkortnummer',
      },
      recipientHeader: 'Mottaker (firma eller privatperson)',
      recipient: {
        label: 'Mottaker',
        placeholder: 'Eks. Navn Navnesen eller Bedrift AS',
        error: 'Fyll inn mottaker',
      },
      address: {
        label: 'Adresse',
        placeholder: 'Eks. Adresseveien 2',
        error: 'Fyll inn adresse',
      },
      postalCode: {
        label: 'Postnummer',
        placeholder: 'Eks. 1234',
        error: 'Fyll inn postnummer',
      },
      postalArea: {
        label: 'Poststed',
        placeholder: 'Eks. Stedstad',
        error: 'Fyll inn poststed',
      },
      attention: {
        label: 'Tilleggsinformasjon',
        placeholder: 'Eks. Leveres til Karl Svendsen',
      },
      activationDateHeader: 'Ønsket dato for portering',
      activationDate: {
        label: 'Porteringsdato',
        error: 'Fyll inn dato for portering',
      },
      activationDateType: {
        default: 'Porter så tidlig som mulig',
        custom: 'Velg porteringsdato',
      },
      activationDateInfo: {
        newSim:
          'Portering vil skje tidligst 5 virkedager, eventuelt på ønsket porteringsdato.',
        exixtingSim:
          'Portering vil skje tidligst 2 virkedager etter Telia har mottatt eierskifteskjema, eventuelt på ønsket porteringsdato.',
      },
    },
  },
};

export default ordering;
