import React from 'react';
import { BodyCard, CardBody, Loading, colors } from 'src/lib/ui';
import { useStatisticsParams } from '../../utils/useStatisticsParams';
import { isAggregatedByDay } from '../../utils/isAggregatedByDay';

import {
  useStatisticsGsm,
  GsmStatisticsPeriod,
} from '../../queries/WithStatisticsGSM';
import { StandardChart } from '../../components/StandardChart';
import { mapGsmData } from './mapGsmData';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { Area, YAxis } from 'recharts';
import { t } from 'src/lib/i18n';
import { getGranularity } from '../../utils/getGranularity';

export const Gsm = () => {
  const i18n = t.statistics.Gsm;
  const params = useStatisticsParams();
  const statisticsQuery = useStatisticsGsm({
    subscriptionId: params.subscriptionId,
    periods: 100,
    periodStart: params.currentStartPeriod,
    periodEnd: params.currentEndPeriod,
  });

  const mappedPeriods = mapGsmData(
    statisticsQuery.data?.statisticsGSM?.periods
  );

  const hideTimestamp = isAggregatedByDay(
    params.currentStartPeriod,
    params.currentEndPeriod
  );
  return (
    <BodyCard noGutter={true}>
      <CardBody>
        {statisticsQuery.loading ? (
          <Loading />
        ) : mappedPeriods === null ? (
          <div>{t.statistics.noData}</div>
        ) : (
          <>
            <StandardChart<GsmStatisticsPeriod>
              title="RSSI"
              periods={mappedPeriods}
              tooltip={RssiTooltip}
              hideTimestamp={hideTimestamp}
              customYAxis={
                <YAxis
                  domain={['auto', 'auto']}
                  orientation="right"
                  axisLine={false}
                  tickLine={false}
                />
              }
            >
              <Area
                stroke={colors.orange}
                strokeWidth="1"
                fill={colors.orange}
                fillOpacity="0.2"
                dataKey="avg_rssi"
              />
            </StandardChart>
            {statisticsQuery.data?.statisticsGSM && (
              <div className="grey-dark-text mb-4">{`${
                t.statistics.granularity
              }: ${getGranularity(
                statisticsQuery.data.statisticsGSM.granularity
              )}`}</div>
            )}
            <StandardChart<GsmStatisticsPeriod>
              title={i18n.signalQuality}
              periods={mappedPeriods}
              tooltip={EcioTooltip}
              hideTimestamp={hideTimestamp}
              customYAxis={<YAxis domain={['auto', 0]} orientation="right" />}
            >
              <Area
                stroke={colors.orange}
                strokeWidth="1"
                fill={colors.orange}
                fillOpacity="0.2"
                dataKey="avg_ecio"
              />
            </StandardChart>
            {statisticsQuery.data?.statisticsGSM && (
              <div className="grey-dark-text">{`${
                t.statistics.granularity
              }: ${getGranularity(
                statisticsQuery.data.statisticsGSM.granularity
              )}`}</div>
            )}
          </>
        )}
      </CardBody>
    </BodyCard>
  );
};

const RssiTooltip = ({
  data,
  hideTimestamp,
}: {
  data: GsmStatisticsPeriod;
  hideTimestamp?: boolean;
}) => {
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header="RSSI">
        <SectionItem
          title=""
          color={colors.orange}
          value={
            data.avg_rssi !== null
              ? `${data.avg_rssi.toFixed()} dBm`
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};

const EcioTooltip = ({
  data,
  hideTimestamp,
}: {
  data: GsmStatisticsPeriod;
  hideTimestamp?: boolean;
}) => {
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header={t.statistics.Gsm.signalQuality}>
        <SectionItem
          title=""
          color={colors.orange}
          value={
            data.avg_ecio !== null
              ? `${data.avg_ecio.toFixed()} dB`
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};
