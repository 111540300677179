import nb from '../../nb/Main/reports';

const reports: typeof nb = {
  reports: 'Reports',
  generatingReports: 'Downloading report',
  errorDescription:
    'It may be because of server maintenance or a server is down. Please try again later.',
  somethingWentWrong: 'Something went wrong!',
  noContent: 'No content to show in the report',
  categories: {
    accounting: 'Accounting',
    landline: 'Landline',
    mobile: 'Mobile',
    aboutReports: 'About reports',
  },
  accounting: {
    accountingLandline: 'Accounting file, landline',
    accountingData: 'Accounting file, data',
    momsReport: 'VAT report deduction for content services',
    invoiceDetails: 'Invoice details report',
  },
  landline: {
    sumPerANum: 'Sum per A-number',
    sumPerANumTotal: 'Sum per A-number, total',
    trafficReportTotal: 'Traffic report total, year',
    subscriptionOverview: 'Subscription overview',
  },
  mobile: {
    taxReport: 'Tax report, overview content services',
    trafficReportTotal: 'Traffic report, total',
    privateUsagePerMonth: 'Private usage pr. month',
    privateUsagePerYear: 'Private usage pr. year',
    overviewSubscription: 'Overview subscription',
    overviewBinding: 'Overview binding',
  },
  aboutReports: {
    description:
      'Here you can find the reports from Invoice Online. You can still download the reports in excel-format, and it works almost as before.',
  },
  actions: {
    cancel: 'Cancel',
    download: 'Download',
  },
  downloadModal: {
    invoiceAccount: 'Invoice account',
    invoiceAccountPlaceholder: 'All',
    period: 'Period',
    subscriptions: 'Subscriptions',
    subscriptionsPlaceholder: 'All subscriptions',
    from: 'From',
    to: 'To',
    select: 'Select',
    subscriptionType: {
      data: 'Network',
      mobile: 'Mobile',
      landlineSubscriptions: 'Landline services',
      other: 'Other',
    },
    year: 'Year',
  },
};

export default reports;
