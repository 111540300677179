import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useReserveOrderNumber';

const RESERVE_NUMBER = gql`
  mutation useReserveOrderNumber($input: ReserveOrderNumberInput!) {
    reserveOrderNumber(input: $input) {
      orderReference
      reservedPhoneNumber
      error {
        code
      }
    }
  }
`;

export const useReserveOrderNumber = (orderRef: string) => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    t.useReserveOrderNumber,
    t.useReserveOrderNumberVariables
  >(RESERVE_NUMBER);

  const reserveOrderNumber = async (phoneNumber: string) =>
    await mutation({
      variables: {
        input: {
          customerId: customer.id,
          orderReference: orderRef,
          phoneNumber,
        },
      },
    });

  return {
    reserveOrderNumber,
    loading,
    error: !!error,
    data: data?.reserveOrderNumber,
  };
};
