import Nb from '../../nb/Main/locations';

const locations: typeof Nb = {
  h1SrOnly: 'Locations',
  searchInThisArea: 'Search in this area',
  Sidebar: {
    backBtn: 'Return to list',
    btnClose: 'Close',
    services: 'Services',
    header: {
      count: 'Count',
      noResults: 'No results',
    },
    subscription: {
      goToService: 'Go to service',
      back: 'Back',
      status: 'Status',
      cpeModel: 'CPE model',
      responseTime: 'Response time',
      position: 'Position',
      lat: 'Latitude',
      lon: 'Longitude',
      addons: 'Addons',
    },
    filters: {
      back: 'Back',
      reset: 'Reset',
      activeFilters: 'Active filter',
      offline: 'Offline',
      plannedWork: 'Planned work',
      data: 'Networks',
      mobile: 'Mobile',
      tele: 'Telephony',
      tv: 'TV',
    },
    footer: {
      title: 'Why are not all services in the map?',
      description:
        'Only services with valid location data can be show in the map',
    },
  },
  searchinputPlaceholder: 'Filter',
};

export default locations;
