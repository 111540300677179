import { getSubscriptionDisplayTitles } from 'src/lib/types';
import { toast } from 'src/lib/ui';
import { useEffect } from 'react';
import { useCustomer } from 'src/lib/global/CustomerContext';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as t from './__types/useRemoveTagsFromSubscriptions';
import * as i18n from 'src/lib/i18n';

const REMOVE_TAGS_FROM_SUBSCRIPTIONS = gql`
  mutation useRemoveTagsFromSubscriptions(
    $input: UpdateTagsOnSubscriptionsInput
  ) {
    removeTagsFromSubscriptions(input: $input) {
      subscriptions {
        id
        tags {
          tag
          id
        }
      }
      errors {
        ... on Error {
          code
          message
        }
        ... on TagOrSubscriptionUpdateError {
          tag {
            id
            tag
          }
          subscription {
            name
            alias
            group
            id
          }
          errorCode
        }
      }
    }
  }
`;

export const useRemoveTagsFromSubscriptions = () => {
  const customer = useCustomer();
  const [mutation, mutationResult] = useMutation<
    t.useRemoveTagsFromSubscriptions,
    t.useRemoveTagsFromSubscriptionsVariables
  >(REMOVE_TAGS_FROM_SUBSCRIPTIONS);

  const { error, data } = mutationResult;
  const errors = i18n.t.subscriptions.tags.errors;
  useEffect(() => {
    if (data?.removeTagsFromSubscriptions?.errors?.length) {
      data.removeTagsFromSubscriptions.errors.forEach(err => {
        if (err.__typename === 'TagOrSubscriptionUpdateError') {
          if (err.subscription) {
            toast(
              'error',
              errors.removeFromSubscription(
                getSubscriptionDisplayTitles(err.subscription).title
              )
            );
          } else if (err.tag) {
            toast('error', errors.removeTag(err.tag.tag));
          }
        } else toast('error', errors.genericRemove);
      });
    } else if (error) {
      toast('error', errors.genericRemove);
    }
  }, [error, data, errors]);

  const removeTags = (tagIds: string[], subscriptionIds: string[]) =>
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          tagIds,
          subscriptionIds,
        },
      },
    });

  return { removeTags, ...mutationResult };
};
