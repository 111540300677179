import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

import { useCustomer } from 'src/lib/global';
import * as t from './__types/useAddons';

const GET_ADDONS = gql`
  query useAddons($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        addons {
          id
          subscriptionId
          alias
          name
          deliveryStatus
          type
          group
          lastModified
          isMonitoringPossible
          cpe {
            name
            description
            model
            status
          }

          vrf {
            vpnAlias
            vpnId
            vrfName
            sagCode
          }
        }
      }
    }
  }
`;

export interface Result {
  loading: boolean;
  error: boolean;
  addons?: t.useAddons_customer_subscriptions_addons[] | null;
}

export type Addon = t.useAddons_customer_subscriptions_addons;

export const useAddons = (subscriptionId: string): Result => {
  const customer = useCustomer();
  const result = useQuery<t.useAddons, t.useAddonsVariables>(GET_ADDONS, {
    variables: {
      customerId: customer.id,
      subscriptionId: subscriptionId,
    },
    errorPolicy: 'all',
  });
  return {
    loading: result.loading,
    error: !!result.error,
    addons: result.data?.customer?.subscriptions?.[0]?.addons,
  };
};
