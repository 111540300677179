import * as React from 'react';
import { NavLink, Link } from 'react-router-dom';
import logo from 'src/images/telia-logo.svg';
import './MobileMenu.scss';
import { Button } from 'src/lib/ui';

interface Props {
  children?: React.ReactNode | React.ReactNode[];
}

export const MobileMenu: React.SFC<Props> = props => (
  <div className="MobileMenu">
    <div className="MobileMenu-content">
      <div className="mx-3">
        <Link to="/minside">
          <img src={logo} alt="Telia logo" />
        </Link>
      </div>
      <div className="MobileMenu-items mr-2">{props.children}</div>
    </div>
  </div>
);

type MobileMenuItemProps = {
  exact?: boolean;
  children?: string | React.ReactNode;
} & ({ to: string } | { onClick?: () => void });

export const MobileMenuItem: React.SFC<MobileMenuItemProps> = props => (
  <div className="MobileMenuItem px-3">
    {'to' in props ? (
      <NavLink exact={props.exact} to={props.to}>
        {props.children}
      </NavLink>
    ) : (
      <Button color="dark" onClick={props.onClick} style={{ padding: 0 }}>
        {props.children}
      </Button>
    )}
  </div>
);
