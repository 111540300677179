import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as t from './__types/useEditCustomerReference';
import { trackMetadata } from 'src/lib/analytics';

const EDIT_CUSTOMER_REFERENCE = gql`
  mutation useEditCustomerReference(
    $input: UpdateIncidentCustomerReferenceInput
  ) {
    updateIncidentCustomerReference(input: $input) {
      incident {
        id
        customerReference
      }
    }
  }
`;

export interface EditCustomerReferenceResult {
  editCustomerReference: (id: string, customerReference: string) => void;
  error?: boolean;
  loading?: boolean;
  data?: t.useEditCustomerReference_updateIncidentCustomerReference_incident;
}

export const useEditCustomerReference = (): EditCustomerReferenceResult => {
  const [mutation, { data, loading, error }] = useMutation<
    t.useEditCustomerReference,
    t.useEditCustomerReferenceVariables
  >(EDIT_CUSTOMER_REFERENCE);

  const editCustomerReference = (
    incidentId: string,
    customerReference: string
  ) => {
    trackMetadata('referansenummer');
    mutation({
      variables: {
        input: {
          id: incidentId,
          customerReference,
        },
      },
    });
  };

  return {
    editCustomerReference,
    error: !!error,
    loading: loading,
    data: data?.updateIncidentCustomerReference?.incident ?? undefined,
  };
};
