import * as React from 'react';
import { Link } from 'react-router-dom';
import cs from 'classnames';

import './Button.scss';
import { Icon, IconDefinition } from '@telia/styleguide';
// export type ButtonBase = 'primary' | 'secondary' | 'default';
/**
 * Primary - CTA-buttons with restricted properties
 * Default - Regular buttons with all properties available
 * NOTE! Some combinations can be applied that does not exist in Storybook. Storybook only matches UX-design template.
 */
export type ButtonBase = 'primary' | 'default';
/**
 * ColorStyles for button
 * Purple - CorePurple BG with White color
 * White - White BG with CorePurple color
 * Dark - Transparent BG with Dark color - DEFAULT
 */
export type ButtonColorStyle = 'purple' | 'white' | 'dark' | 'grey';

export type ButtonProps = {
  className?: string;
  style?: React.CSSProperties;
  disabled?: boolean;
  innerRef?: any;
  title?: string;
  icon?: IconDefinition;
  type?: 'submit' | 'reset' | 'button';
  transparent?: boolean;
  color?: ButtonColorStyle;
  'aria-expanded'?: boolean;
  'aria-label'?: string;
  'aria-haspopup'?: boolean;
} & (
  | {
      base: 'primary';
    }
  | {
      base?: 'default';
      rounded?: boolean;
      outline?: boolean;
      hideLabel?: boolean;
      size?: 'small' | 'xs' | 'default';
      /** Icon positioning in button. Default: before */
      iconPosition?: 'before' | 'after';
    }
) &
  (
    | { to?: string }
    | {
        onClick?: (evt: React.SyntheticEvent<HTMLButtonElement>) => void;
      }
  );

// Regarding the types above: a Button is rendered either as
// a <Link with `to` path, or a Button with a onClick event

export class Button extends React.Component<ButtonProps> {
  onClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if (this.props.disabled) {
      e.preventDefault();
      return;
    }
    if ('onClick' in this.props && this.props.onClick) {
      this.props.onClick(e);
    }
  };

  render() {
    const props = this.props;

    // TODO: Change className from Button -> Button when transition over to new button styles
    const sharedProps = {
      type: 'to' in props && props.to ? undefined : props.type,
      style: props.style,
      disabled: props.disabled,
      className: cs(
        'Button',
        `Button--${props.base || 'default'}`,
        `Button--${props.color || 'dark'}`,
        props.transparent ? 'transparent' : undefined,
        props.className
      ),
      ref: props.innerRef,
      title: props.title,
      'aria-expanded': props['aria-expanded'],
      'aria-label': props['aria-label'],
      'aria-haspopup': props['aria-haspopup'],
    };

    if ('base' in props && props.base === 'primary') {
      const primaryButtonContent = (
        <>
          {props.icon ? (
            <Icon
              icon={props.icon}
              style={{
                marginRight: '0.5rem',
                marginLeft: '-0.25rem',
              }}
            />
          ) : null}
          {props.children}
        </>
      );
      if ('to' in props && props.to) {
        return (
          <Link to={props.to} {...sharedProps}>
            {primaryButtonContent}
          </Link>
        );
      }
      return (
        <button onClick={this.onClick} {...sharedProps}>
          {primaryButtonContent}
        </button>
      );
    }

    const hideLabel = 'icon' in props && props.icon && props.hideLabel;
    const iconPosition = props.iconPosition || 'before';
    const children = hideLabel ? (
      <span className="sr-only">{props.children}</span>
    ) : (
      props.children
    );

    const defaultButtonContent = (
      <>
        {props.icon && iconPosition === 'before' ? (
          <Icon
            icon={props.icon}
            style={
              props.hideLabel
                ? {}
                : {
                    marginRight: '0.5rem',
                    marginLeft: '-0.25rem',
                  }
            }
          />
        ) : null}
        {children}
        {props.icon && iconPosition === 'after' ? (
          <Icon
            icon={props.icon}
            style={
              props.hideLabel
                ? {}
                : {
                    marginLeft: '0.5rem',
                    marginRight: '-0.25rem',
                  }
            }
          />
        ) : null}
      </>
    );

    const defaultButtonClassNames = cs(
      sharedProps.className,
      hideLabel ? 'hideLabel' : undefined,
      props.rounded ? 'rounded' : undefined,
      props.outline ? 'outline' : undefined,
      props.transparent ? 'transparent' : undefined,
      props.size
        ? props.size === 'small'
          ? 'btnSmall'
          : props.size === 'xs'
          ? 'btnXS'
          : undefined
        : undefined
    );

    if ('to' in props && props.to) {
      return (
        <Link
          to={props.to}
          {...sharedProps}
          className={defaultButtonClassNames}
        >
          {defaultButtonContent}
        </Link>
      );
    }

    return (
      <button
        onClick={this.onClick}
        {...sharedProps}
        className={defaultButtonClassNames}
      >
        {defaultButtonContent}
      </button>
    );
  }
}
