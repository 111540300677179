import React, { useMemo } from 'react';
import {
  Loading,
  Table,
  TableBodyRow,
  TableBodyCell,
  TableColumnDefintion,
  Pagination,
} from 'src/lib/ui';
import { useInterfaceModal } from './useInterfaceModal';
import { t } from 'src/lib/i18n';
import { orderBy } from 'lodash';
import SubscriptionStatusIcon from '../../../lib/SubscriptionStatusIcon/SubscriptionStatusIcon';
import { usePagination } from 'src/lib/ui/Pagination/usePagination';
import { InterfaceModal } from './InterfaceModal';
import { useSavedUserPreferences } from 'src/lib/userPreferences/SavedUserPreferencesContext';
import { parseStringToNumber } from 'src/lib/utils/parseStringTo';
import { CpeStatus } from 'src/__types/graphql-global-types';

export const InterfaceTable = (props: {
  interfaces?: null | Array<{
    name: string;
    alias: null | string;
    status: CpeStatus | null;
    arpTable: Array<{
      ip: string;
      mac: string;
    }> | null;
    subscription: null | {
      vrf: null | {
        vpnAlias: string | null;
        vpnId: string;
        vrfName: string;
        sagCode: string;
      };
    };
  }>;
  loading: boolean;
}) => {
  const { interfaces, loading } = props;
  const { renderModal, ...interfaceModalProps } = useInterfaceModal();
  const { userPreferences, setUserPreference } = useSavedUserPreferences();
  const pageSize = userPreferences.subscriptionConfigPageSize;
  const defaultPageSize = pageSize ? parseStringToNumber(pageSize) : 5;

  const weightedInterfaces = useMemo(
    () =>
      interfaces?.map(i => {
        let statusWeight = 0;
        switch (i.status) {
          case CpeStatus.Unknown:
            statusWeight = 4;
            break;
          case CpeStatus.Critical:
            statusWeight = 3;
            break;
          case CpeStatus.Normal:
            statusWeight = 2;
            break;
          case CpeStatus.NotInstalled:
          case CpeStatus.NoStatus:
            statusWeight = 1;
            break;
        }
        return { ...i, statusWeight, numDevices: i.arpTable?.length ?? 0 };
      }),
    [interfaces]
  );

  const sortedResult = useMemo(
    () =>
      orderBy(
        weightedInterfaces,
        ['statusWeight', 'numDevices'],
        ['desc', 'desc']
      ),
    [weightedInterfaces]
  );

  const { filteredResult, paginationProps } = usePagination({
    results: sortedResult,
    defaultPageSize,
    onTogglePageSize: num =>
      setUserPreference('subscriptionConfigPageSize', num.toString()),
  });
  const i18n = t.subscriptions.SubscriptionInterfaces;

  const columns: TableColumnDefintion[] = [
    {
      key: 'status',
      label: i18n.status,
      cellWidth: 80,
    },
    {
      key: 'name',
      label: i18n.interface,
      cellWidth: 280,
    },
    {
      key: 'vpn',
      label: i18n.VPNVRF,
    },
    {
      key: 'arpTableCount',
      label: i18n.devices,
      cellWidth: 80,
    },
  ];

  if (loading) {
    return <Loading />;
  }
  if (!interfaces || interfaces.length === 0) {
    return null;
  }

  return (
    <div>
      <Table
        loading={loading}
        columns={columns}
        sortable={false}
        bordered={false}
        rowsCount={defaultPageSize}
        noResult={!filteredResult?.length}
      >
        {filteredResult?.map(row => {
          if (!row) {
            return null;
          }
          const vrfInfo = row.subscription?.vrf;

          return (
            <TableBodyRow key={row.name} onClick={() => renderModal(row)}>
              <TableBodyCell>
                <div className="d-flex align-items-center">
                  <SubscriptionStatusIcon
                    cpeStatus={row.status}
                    circleBackground={true}
                    size="sm"
                    className="mr-2"
                  />
                </div>
              </TableBodyCell>
              <TableBodyCell>
                {row.name} {row.alias}
              </TableBodyCell>

              <TableBodyCell>
                {vrfInfo
                  ? `${vrfInfo.vpnAlias} ${vrfInfo.vpnId} ${vrfInfo.vrfName}`
                  : null}
              </TableBodyCell>
              <TableBodyCell>{row.arpTable?.length ?? 0}</TableBodyCell>
            </TableBodyRow>
          );
        })}
      </Table>
      <div className="d-flex p-3 justify-content-end border-top">
        <Pagination {...paginationProps} />
      </div>

      <InterfaceModal {...interfaceModalProps} />
    </div>
  );
};
