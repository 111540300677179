// Load Sentry as quickly as possible
import './sentry';

/**
 * Import the b2b/business CSS from @telia/styleguide
 */
import '@telia/styleguide/business.css';
import '@telia/styleguide/components.css';
import './styles/styleguide-overrides.scss';
import './styles/main.scss';

// Polyfills
import 'core-js/features/map';
import 'core-js/features/promise';
import 'core-js/features/array';
import 'core-js/features/string/repeat';
import 'core-js/features/string/includes';
import 'core-js/features/string/ends-with';
import 'core-js/features/object/keys';
import 'core-js/features/object/values';
import 'core-js/features/object/assign';
import 'core-js/features/object/entries';
import 'core-js/features/object/get-own-property-descriptor';
import 'core-js/features/object/define-property';
import 'core-js/features/symbol';
import 'core-js/proposals/url';
import 'whatwg-fetch';
import smoothscroll from 'smoothscroll-polyfill';

import React from 'react';
import ReactDOM from 'react-dom';

import { ApolloProvider } from 'react-apollo';
import { Router } from 'react-router-dom';
import { createBrowserHistory } from 'history';

import Modal from 'react-modal';

import createApolloClient from './createApolloClient';

import App from './areas/App';

import { I18nProvider } from './lib/i18n';

import UserPreferencesProvider from 'src/lib/userPreferences/UserPreferencesProvider';
import { addToPathHistory } from 'src/lib/ui/FullscreenHeader/FullscreenHeader';

/**
 * There are circular dependencies in this project. To ensure that the GraphQL fragment
 * definitions are run before the queries that use them, we import them here.
 */
import './lib/types/cluster/ClusterFragment';
import './lib/types/contact/ContactFragment';
import './lib/types/costcenter/CostCenterFragment';
import './lib/types/file/FileFragment';
import './lib/types/incident/IncidentFragment';
import './lib/types/invoice/InvoiceFragment';
import './lib/types/notification/NotificationFragment';
import './lib/types/order/OrderFragment';
import './lib/types/organisation/OrganisationFragment';
import './lib/types/subscription/SubscriptionFragment';
import './lib/types/tag/TagFragment';
import { WithUserPreferences } from './lib';
import AuthProvider from 'src/modules/auth/AuthProvider';
import enableTabKeyDetector from 'src/lib/enableTabKeyDetector';

/**
 * Kick off the scrollTo polyfill.
 * This function will detect if the spec is natively supported
 * and take action only when necessary.
 */
smoothscroll.polyfill();

/**
 * Redirect away from portal.gettdc.no in case the
 * domain is still used by some clients.
 */
if (window?.location?.hostname === 'portal.gettdc.no') {
  window.location.href = window.location.href.replace(
    '://portal.gettdc.no',
    '://minportal.telia.no'
  );
}

const browserHistory = createBrowserHistory();

browserHistory.listen(state => {
  addToPathHistory(state.pathname);
  /**
   * 04.03.2020. Added a exception for statistics page because all filters are URL-controlled. We want to avoid scrolling to top for each change in filter.
   */
  // if (state.pathname.includes('/statistikk')) {
  if (state.pathname.includes('/statistikk')) {
    return;
  }
  window.scrollTo(0, 0);
});

// Track initial page

const rootElement = document.getElementById('root');
const apolloClient = createApolloClient();

Modal.setAppElement('#root');

// Detect usage of tab keys and add .user-is-tabbing to the body element.
enableTabKeyDetector();

/**
 * NOTE: The I18nProvider must be within the AuthProvider. When the locale changes in I18nProvider,
 * the whole subtree is invalidated and reinstantiated. If AuthProvider is affected by this, the
 * authentication is invalidated and the user is redirected to the SSO process.
 */
ReactDOM.render(
  <ApolloProvider client={apolloClient}>
    <UserPreferencesProvider>
      <Router history={browserHistory}>
        <AuthProvider>
          <WithUserPreferences>
            {userPrefs => (
              <I18nProvider
                preferredLocale={userPrefs.preferredLocale}
                onChangeLocale={userPrefs.setPreferredLocale}
              >
                <App />
              </I18nProvider>
            )}
          </WithUserPreferences>
        </AuthProvider>
      </Router>
    </UserPreferencesProvider>
  </ApolloProvider>,
  rootElement
);

// registerServiceWorker();
