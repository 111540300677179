import * as React from 'react';

import { Period, Invoice } from 'src/lib/types';
import { getFirstDayOfPeriod, getLastDayOfPeriod } from 'src/lib/utils';
import { Loading, DownloadLink, Pagination, Alert } from 'src/lib/ui';

import { useSearch } from '../search';
import { PageHeader } from '../common/PageHeader/PageHeader';
import FilterSection from '../common/FilterSection';
import { usePeriodFilter } from 'src/lib/filters';
import { useOrganisationFilter } from 'src/lib/filters';
import { useCustomerOrganisations } from '../cost/lib';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { InvoicesTable } from './components/InvoicesTable';
import { useListQueryParams } from 'src/lib/utils/useListQueryParams';
import { parseStringToPeriod } from 'src/lib/utils/parseStringTo';
import { useQueryParamPagination } from 'src/lib/ui/Pagination/useQueryParamPaginations';
import { getFilterCountFromSearchFilter } from 'src/lib/utils/';
import { SearchDomain } from 'src/__types/graphql-global-types';

interface InvoiceDetailReportDownloadProps {
  period?: Period;
  disabled?: boolean;
}

function InvoiceDetailReportDownload(props: InvoiceDetailReportDownloadProps) {
  const translate = t.invoices;

  if (!props.period || props.disabled) {
    return (
      <DownloadLink
        href="/api/files/report/invoiceDetails"
        icon="download"
        disabled={props.disabled}
      >
        {translate.detailReportDownloadBtn}
      </DownloadLink>
    );
  }
  return (
    <DownloadLink
      href="/api/files/report/invoiceDetails"
      formParameters={{
        from: getFirstDayOfPeriod(props.period),
        to: getLastDayOfPeriod(props.period),
        periodBy: 'invoice',
      }}
      icon="download"
      disabled={props.disabled}
    >
      {translate.detailReportDownloadBtn}
    </DownloadLink>
  );
}

const Invoices: React.SFC = () => {
  const query = useQueryParameters();
  const listParams = useListQueryParams({
    defaultSortKey: 'dueDate',
    defaultSortOrder: 'desc',
    preferenceNamespace: 'invoices',
  });
  const paginationProps = useQueryParamPagination({
    defaultPageSize: listParams.pageSize,
    onTogglePageSize: listParams.setPageSize,
  });
  const translate = t.invoices;

  // invoiceStatus
  const statusFilterOptions = [
    {
      value: 'overdue',
      label: `${translate.status.Overdue.label}`,
    },
    // { value: 'disputed', label: 'Bestridt' },
    {
      value: 'unpaid',
      label: `${translate.status.Unpaid.label}`,
    },
    // { value: 'partiallyPaid', label: 'Delvis betalt' },
    {
      value: 'paid',
      label: `${translate.status.Paid.label}`,
    },
    { value: 'creditNote', label: translate.status.CreditNote.label },
  ];

  const organisationsFilter = useOrganisationFilter();
  const statusFilters = {
    id: 'status',
    label: translate.statusLabel,
    options: statusFilterOptions,
    allowMultiple: true,
    selectedOptions: query.getArray('invoiceStatus'),
    handleSave: value => query.set('invoiceStatus', value),
  };
  const customerOrganisations = useCustomerOrganisations();

  const periods = customerOrganisations.customer?.costPeriods ?? {
    invoicePeriods: [],
    usagePeriods: [],
  };

  const currentPeriod = parseStringToPeriod(query.get('period'));

  const periodFilter = usePeriodFilter({
    costPeriods: periods,
    period: currentPeriod,
    setPeriod: period => query.set('period', period),
    allPeriodsAvailable: true,
  });
  const dateFilter = {
    filter: 'invoiceDate',
    value: currentPeriod
      ? [getFirstDayOfPeriod(currentPeriod), getLastDayOfPeriod(currentPeriod)]
      : undefined,
  };
  const invoiceFilter = {
    filter: 'invoiceStatus',
    value: query.getArray('invoiceStatus'),
  };
  const organisationFilter = {
    filter: 'organisation',
    value: query.getArray('organisation'),
  };

  const search = useSearch({
    types: [SearchDomain.invoice],
    page: listParams.page,
    size: listParams.pageSize,
    query: listParams.searchQuery,
    filter: [invoiceFilter, dateFilter, organisationFilter],
    sortBy: listParams.sortBy,
    sortOrder: listParams.sortOrder,
  });

  if (customerOrganisations.loading) {
    return <Loading />;
  }

  const invoices = search.results as Invoice[];

  const filtersToCount = [
    invoiceFilter,
    organisationFilter,
    {
      filter: 'invoiceDate',
      value: currentPeriod ? [currentPeriod] : undefined,
    },
  ];

  const filterCount = getFilterCountFromSearchFilter(filtersToCount);

  return (
    <>
      <div className="container">
        <Alert
          icon="document"
          header={t.invoices.newBankAccountNumber.title}
          status="default"
        >
          <a
            href={t.invoices.newBankAccountNumber.url}
            target="_blank"
            rel="noopener noreferrer"
          >
            {t.invoices.newBankAccountNumber.linkText}
          </a>
        </Alert>
      </div>
      <PageHeader title={translate.title} />
      <FilterSection
        searchValue={listParams.searchQuery}
        totalFiltersActive={filterCount}
        onSearch={val => listParams.setSearchQuery(val)}
        onResetSearch={() => listParams.setSearchQuery('')}
        filterToggling={true}
        filterOptions={[statusFilters, periodFilter, organisationsFilter]}
        totalSearchResults={search.totalResults}
        currentView="table"
        actions={
          <InvoiceDetailReportDownload
            period={currentPeriod}
            disabled={!currentPeriod}
          />
        }
      />

      <div className="container">
        <InvoicesTable
          setSort={listParams.setSort}
          sortBy={listParams.sortBy}
          sortOrder={listParams.sortOrder}
          invoices={invoices}
          loading={search.loading}
          noResult={Boolean(search.results.length === 0)}
          noResultMessage={translate.noInvoices}
          setColumns={listParams.setColumns}
          savedColumnPreferences={listParams.columns}
        />
      </div>

      <div
        className="d-flex container"
        style={{
          paddingTop: '1rem',
          paddingBottom: '60px',
        }}
      >
        <div className="ml-auto">
          <Pagination
            totalResults={search.totalResults || 0}
            {...paginationProps}
          />
        </div>
      </div>
    </>
  );
};

export default Invoices;
