import React from 'react';
import cs from 'classnames';

import { Icon, IconDefinition } from '@telia/styleguide';
import { DropdownItem } from 'reactstrap';

export interface OptionsDropdownItemProps {
  disabled?: boolean;
  onClick?: React.MouseEventHandler<any>;
  className?: string;
  href?: string;
  target?: string;
  toggle?: boolean;
  active?: boolean;
  rel?: string;

  /**
   * Create a centered and more spacious dropdown item, typically used at the bottom of the dropdown.
   */
  centered?: boolean;

  /**
   * Custom content for the dropdown item.
   */
  children?: React.ReactNode;

  /**
   * The icon to show next to the text when using `label`.
   */
  icon?: IconDefinition;

  /**
   * The text to display in the dropdown item.
   */
  label?: string;
}

export const OptionsDropdownItem = (props: OptionsDropdownItemProps) => (
  <DropdownItem
    onClick={props.onClick}
    disabled={props.disabled}
    className={cs(
      props.centered ? 'OptionsDropdown-centered' : undefined,
      props.className
    )}
    href={props.href}
    toggle={props.toggle}
    active={props.active}
    target={props.target}
  >
    {props.icon && <Icon icon={props.icon} />}

    {props.label}

    {props.children}
  </DropdownItem>
);
