import * as React from 'react';
import { formatNordic } from 'src/lib/utils';
import { CircleProgress } from 'src/lib/charts';
import { colors } from 'src/lib/ui';
import { MobilePeriodUsage } from '../types';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import { convertMegaByteToGigaByte } from 'src/areas/main/statistics/utils/convertUnit';

interface Props {
  usageRatio: number;
  usageLimit: number;
  usage: MobilePeriodUsage;
}

const OverviewPackage: React.FC<Props> = ({
  usageLimit,
  usageRatio,
  usage,
}) => {
  const i18n = t.mobile.overview;
  return (
    <div className="d-flex justify-content-center">
      {/* Circle chart */}
      <div>
        <div
          className="d-flex align-items-center py-5"
          style={{
            borderBottom: `1px solid ${colors.greyMedium}`,
          }}
        >
          <div className="mr-5" style={{ textAlign: 'center' }}>
            <div style={{ color: colors.greyDarkText, marginBottom: '-7px' }}>
              {i18n.total}
            </div>
            <div style={{ color: colors.black, fontSize: '28px' }}>
              {formatNordic(convertMegaByteToGigaByte(usageLimit), 2)} GB
            </div>
          </div>
          <div style={{ width: '200px', position: 'relative' }}>
            <CircleProgress
              progress={usageRatio >= 1 ? 1 : usageRatio}
              color={usageRatio >= 1 ? colors.red : colors.tealDark}
              label={i18n.usage.dataUsage}
            />
            <div
              style={{
                position: 'absolute',
                top: '0',
                width: '100%',
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                textAlign: 'center',
                justifyContent: 'center',
              }}
            >
              <div
                style={{ color: colors.greyDarkText, marginBottom: '-10px' }}
              >
                {i18n.usage.used}
              </div>
              <div
                style={{
                  color: usageRatio >= 1 ? colors.red : colors.tealDark,
                  fontSize: '32px',
                  fontWeight: 500,
                }}
              >
                {formatNordic(
                  usage && usage.nationalDataVolume
                    ? convertMegaByteToGigaByte(usage.nationalDataVolume)
                    : 0,
                  2
                )}{' '}
                GB
              </div>
            </div>
          </div>
          <div className="ml-5" style={{ textAlign: 'center' }}>
            <div style={{ color: colors.greyDarkText, marginBottom: '-7px' }}>
              {usageRatio >= 1 ? 'OVER' : 'IGJEN'}
            </div>
            <div
              style={{
                fontSize: '28px',
                color: colors.black,
              }}
            >
              {usage?.nationalDataVolume !== null &&
              usage?.nationalDataVolume !== undefined ? (
                <>
                  {formatNordic(
                    convertMegaByteToGigaByte(
                      Math.abs(usageLimit - usage.nationalDataVolume)
                    ),
                    2
                  )}{' '}
                  GB
                </>
              ) : null}
            </div>
          </div>
        </div>

        {/* Volume labels */}
        <div className="d-flex align-items-center justify-content-between py-5">
          <div className="d-flex align-items-center">
            <div
              style={{ fontSize: '28px', color: colors.blueDark }}
              className="mr-3"
            >
              <Icon icon="chat" />
            </div>
            <div>
              <div
                style={{ color: colors.greyDarkText, marginBottom: '-10px' }}
              >
                {i18n.smsSent}
              </div>
              <div
                style={{
                  fontWeight: 500,
                  color: colors.blueDark,
                  fontSize: '28px',
                }}
              >
                {usage && usage.smsVolume} stk
              </div>
            </div>
          </div>
          <div className="d-flex align-items-center px-5">
            <div
              style={{ fontSize: '28px', color: colors.warning }}
              className="mr-3"
            >
              <Icon icon="phone" />
            </div>
            <div>
              <div
                style={{ color: colors.greyDarkText, marginBottom: '-10px' }}
              >
                {i18n.minutesCalled}
              </div>
              {usage?.nationalCallVolume !== null &&
              usage?.nationalCallVolume !== undefined ? (
                <div
                  style={{
                    fontWeight: 500,
                    color: colors.warning,
                    fontSize: '28px',
                  }}
                >
                  {formatNordic(usage.nationalCallVolume, 1)} min
                </div>
              ) : null}
            </div>
          </div>
          <div className="d-flex align-items-center">
            <div
              style={{ fontSize: '28px', color: colors.primary }}
              className="mr-3"
            >
              <Icon icon="money" />
            </div>
            <div>
              <div
                style={{ color: colors.greyDarkText, marginBottom: '-10px' }}
              >
                {i18n.cost}
              </div>

              {usage?.totalUsageAmount !== null &&
              usage?.totalUsageAmount !== undefined ? (
                <div
                  style={{
                    fontWeight: 500,
                    color: colors.primary,
                    fontSize: '28px',
                  }}
                >
                  Kr {formatNordic(usage.totalUsageAmount, 2)}
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OverviewPackage;
