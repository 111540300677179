import { useEffect, useState } from 'react';

/**
 * This hooks lets you viritully render large lists in a window scroll.
 * Only the visible rows, pluss som padding above and below, will actually be rendered.
 * The hook returns a complete array with null for every item exept the ones to render (no markup, only data)
 * This lets us use a viritual scroll in any context, including Table.
 *
 * NB: remeber to set a height to your container.
 */

export function useWindowScroll<T>({
  allNodes,
  initialOffsetTop,
  rowHeight,
  scrollRef,
}: {
  allNodes: Array<T>;
  initialOffsetTop: number;
  rowHeight: number;
  scrollRef?: React.RefObject<HTMLDivElement>;
}) {
  const [renderList, setRenderList] = useState<(T | null)[]>([]);
  const [currentRowIndex, setCurrentRowIndex] = useState<number>(0);
  const stackSize = Math.floor(window.innerHeight / rowHeight);

  useEffect(() => {
    updateRenderList();
  }, [currentRowIndex, allNodes]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    const handleScroll = updateCurrentIndex;
    const container = scrollRef?.current ?? window;
    container.addEventListener('scroll', handleScroll);
    return () => {
      container.removeEventListener('scroll', handleScroll);
    };
  }, [scrollRef]); // eslint-disable-line react-hooks/exhaustive-deps

  const updateRenderList = () => {
    const start = Math.max(0, currentRowIndex - stackSize);
    const end = Math.min(currentRowIndex + 2 * stackSize, allNodes.length);
    setRenderList(
      allNodes.map((item, index) => {
        if (index >= start && index <= end) {
          return item;
        }
        return null;
      })
    );
  };

  const updateCurrentIndex = (e: Event) => {
    const offset = scrollRef?.current?.scrollTop ?? window.pageYOffset;
    const index = Math.floor((offset - (initialOffsetTop || 0)) / rowHeight);
    setCurrentRowIndex(Math.max(index, 0));
  };
  return renderList;
}
