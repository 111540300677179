import chatNb from '../../nb/Main/chat';

const chat: typeof chatNb = {
  messagePlaceholder: 'Short description of how we may help you',

  invalidMessage: 'Please provide a description of how we may help you.',

  submitConfirmation: 'Thank you! We will direct you to customer support.',
  editMessage: 'Edit message',
  deleteMessgae: 'Delete message',
  couldNotEdit: 'Ops! We were not able to edit your message',
  couldNotDelete: 'Ops! We were not able to delete you message',
};

export default chat;
