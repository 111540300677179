import * as React from 'react';
import { Formik, Form } from 'formik';
import { FormikTextField, FormikSelectField } from 'src/lib/flow';
import { LinkTo, Loading, Attributes, Attribute } from 'src/lib/ui';
import * as yup from 'yup';
import WithReserveSimCardsOrder, {
  ReserveSimCardsOrderInput,
} from './WithReserveSimCardsOrder';
import { t } from 'src/lib/i18n';
import { OrderingComponentShape } from '../types';
import { AmountListForOrders } from '../lib/GetAmountListForOrders';

interface OrderReserveSimCardsState {
  quantity: string;
  simCardType: string;
  address: string;
  postalCode: string;
  postalArea: string;
  attention: string;
}

const initialValues: OrderReserveSimCardsState = {
  quantity: '',
  simCardType: '',
  address: '',
  postalCode: '',
  postalArea: '',
  attention: '',
};

const OrderReserveSimCards: React.FC<{
  children: (props: OrderingComponentShape) => React.ReactNode;
}> = props => {
  const i18n = t.ordering.MobileUnitOrder.OrderReserveSimCards;
  const handleSubmit = (
    values: OrderReserveSimCardsState,
    handler: (input: ReserveSimCardsOrderInput) => void
  ) => {
    handler({
      quantity: parseInt(values.quantity, undefined),
      simCardType: values.simCardType,
      simCardsRecipientAddress: {
        address: values.address,
        postalCode: values.postalCode,
        postalArea: values.postalArea,
        attention: values.attention,
      },
    });
  };

  const simCardTypes = [
    {
      value: 'regular',
      label: i18n.ordering.formFactorOpts.regular,
    },
    {
      value: 'data',
      label: 'Data',
    },
    {
      value: 'm2m',
      label: 'M2M',
    },
  ];

  return (
    <WithReserveSimCardsOrder>
      {reserveSimCardsMutation => {
        return (
          <div>
            <Formik
              initialValues={initialValues}
              validationSchema={() =>
                yup.object().shape({
                  quantity: yup.number().required(i18n.ordering.quantity.error),
                  simCardType: yup
                    .string()
                    .required(i18n.ordering.formFactor.error),
                  address: yup.string().required(i18n.ordering.address.error),
                  postalCode: yup
                    .string()
                    .required(i18n.ordering.postalCode.error),
                  postalArea: yup
                    .string()
                    .required(i18n.ordering.postalArea.error),
                  attention: yup.string().required(i18n.ordering.att.error),
                })
              }
              onSubmit={(values, actions) => {
                handleSubmit(
                  values,
                  reserveSimCardsMutation.reserveSimCardsOrder
                );
              }}
            >
              {formikProps => {
                let form;
                if (reserveSimCardsMutation.loading) {
                  form = <Loading loadingText={i18n.loading} />;
                } else if (
                  reserveSimCardsMutation.data &&
                  reserveSimCardsMutation.data.orderDetails &&
                  !reserveSimCardsMutation.error
                ) {
                  form = (
                    <div>
                      <h2>{i18n.submitted.thankYou}</h2>
                      <div>
                        {i18n.submitted.expectedDelivery}
                        <br />
                        {i18n.submitted.info}{' '}
                        <LinkTo
                          order={
                            reserveSimCardsMutation.data.orderDetails.orderId
                          }
                        >
                          {reserveSimCardsMutation.data.orderDetails.orderId}
                        </LinkTo>
                      </div>
                      <br />
                      <h2>{i18n.submitted.orderDetails}</h2>
                      <Attributes>
                        <Attribute
                          label={i18n.ordering.address.label}
                          value={
                            reserveSimCardsMutation.data.orderDetails.address
                          }
                        />
                        <Attribute
                          label={i18n.ordering.postalCode.label}
                          value={
                            reserveSimCardsMutation.data.orderDetails.postalCode
                          }
                        />
                        <Attribute
                          label={i18n.ordering.postalArea.label}
                          value={
                            reserveSimCardsMutation.data.orderDetails.postalArea
                          }
                        />
                        <Attribute
                          label={i18n.ordering.att.label}
                          value={
                            reserveSimCardsMutation.data.orderDetails.attention
                          }
                        />
                      </Attributes>
                      <div />
                    </div>
                  );
                } else {
                  form = (
                    <Form>
                      <div className="d-flex flex-column">
                        <FormikSelectField
                          className="mb-3"
                          id="quantity"
                          options={AmountListForOrders}
                          required={true}
                          label={i18n.ordering.quantity.label}
                          fastField={true}
                        />
                        <FormikSelectField
                          className="mb-3"
                          id="simCardType"
                          options={simCardTypes}
                          required={true}
                          label={i18n.ordering.formFactor.label}
                          placeholder={i18n.ordering.formFactor.placeholder}
                        />
                        <FormikTextField
                          className="mb-3"
                          id="address"
                          required={true}
                          label={i18n.ordering.address.label}
                        />
                        <FormikTextField
                          className="mb-3"
                          id="postalCode"
                          required={true}
                          label={i18n.ordering.postalCode.label}
                        />
                        <FormikTextField
                          className="mb-3"
                          id="postalArea"
                          required={true}
                          label={i18n.ordering.postalArea.label}
                        />
                        <FormikTextField
                          className="mb-3"
                          id="attention"
                          required={true}
                          label={i18n.ordering.att.label}
                        />
                      </div>
                    </Form>
                  );
                }

                return props.children({
                  header: i18n.pageHeader,
                  render: form,
                  submitLabel: i18n.ordering.sendOrderBtn,
                  submit: formikProps.submitForm,
                  isFormValid: formikProps.isValid,
                  resetForm: () => {
                    formikProps.resetForm();
                    if (reserveSimCardsMutation.reset) {
                      reserveSimCardsMutation.reset();
                    }
                  },
                });
              }}
            </Formik>
          </div>
        );
      }}
    </WithReserveSimCardsOrder>
  );
};

export default OrderReserveSimCards;
