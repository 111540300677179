import React from 'react';
import { formatDate } from 'src/lib/utils';
import './Notification.scss';
import cs from 'classnames';
import { withRouter, RouteComponentProps } from 'react-router-dom';
import { mapNotificationTypeToLabel } from './utils';
import { Attribute, Attributes } from 'src/lib/ui';
import { getIncidentStatusLabel } from 'src/lib/types/incident/getIncidentStatusLabel';
import { t } from 'src/lib/i18n';
import { Notification as NotificationType } from './query/useNotifications';
import { NotificationType as NotificationTypeEnum } from 'src/__types/graphql-global-types';

interface Props {
  notification: NotificationType;
  onClick?: () => void;
}

const Notification: React.FC<Props & RouteComponentProps> = props => (
  <div
    role={props.onClick ? 'button' : undefined}
    tabIndex={0}
    onClick={props.onClick}
    className={cs('Notification', {
      'Notification--unread': !props.notification.read,
    })}
    onKeyDown={event => {
      // activate button if space or enter
      if (event.keyCode === 32 || event.keyCode === 13) {
        props.onClick?.();
      }
    }}
  >
    <div className="Notification-content">
      <div className="Notification-content-header">
        <span className="Notification-content-header-label">
          {
            mapNotificationTypeToLabel(props.notification.notificationType)
              .label
          }
        </span>
        {props.notification.createdAt && (
          <small className="d-block ml-2">
            {formatDate(props.notification.createdAt, 'shortWithTime')}
          </small>
        )}
      </div>
      <small className="Notification-content-attributes">
        {<Description notification={props.notification} />}
      </small>
    </div>
    {props.notification.read || <div className="Notification-marker" />}
  </div>
);

function Description(props: { notification: NotificationType }) {
  const translate = t.notifications;
  const { notification } = props;

  if (notification.incident) {
    const id = <Attribute value={notification.incident.id} />;
    const status =
      notification.notificationType === NotificationTypeEnum.incidentStatus ? (
        notification.incidentStatus && (
          <Attribute
            className="mt-0"
            label={translate.incidentStatus.description}
            value={getIncidentStatusLabel(notification.incidentStatus)}
          />
        )
      ) : (
        <Attribute
          className="mt-0"
          value={
            mapNotificationTypeToLabel(notification.notificationType)
              .description
          }
        />
      );
    const comment = notification.comment && (
      <Attribute
        className="mt-0"
        label={translate.incidentComment.description}
        value={
          notification.comment.length > 20
            ? notification.comment.slice(0, 20) + '...'
            : notification.comment
        }
      />
    );
    return (
      <Attributes>
        {id}
        {status}
        {comment}
      </Attributes>
    );
  } else if (notification.notificationType === 'change') {
    return <Attribute value={translate.plannedWork.description} />;
  }
  return null;
}

export default withRouter(Notification);
