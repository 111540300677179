import React, { useRef, useEffect, useState, useCallback } from 'react';
import { createPortal } from 'react-dom';
import cn from 'classnames';
import { BreadcrumbsAndTitle, NavTab, NavTabs } from 'src/lib/ui';
import { throttle } from 'lodash';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';

import './PageHeader.scss';

export type Tab = {
  url: string;
  label: string;
  exact?: boolean;
  onClick?: () => void;
};
export interface PageHeaderProps {
  title?: string | undefined;
  titleActions?: React.ReactNode;
  navTabs?: Array<Tab>;
  breadcrumbs?: Array<{
    title: string;
    url: string;
  }>;
  className?: string;
}

export const PageHeader: React.FunctionComponent<PageHeaderProps> = props => {
  const breakpointMd = useBreakpoint('lg');
  const headerRef = useRef<HTMLElement | null>(null);
  const [footerStuck, setFooterStuck] = useState<boolean>(false);
  const renderNavtabs: boolean = Boolean(
    props.navTabs && props.navTabs.length > 0
  );
  /**
   * footerRefCallback: For the stickyness of the footer to function properly. We need to get the initial offsetTop value when it is rendered and before it is sticky.
   * Reason for this is that the offsetTop value changes the moment it sticks the top, making the calculation for when to un-stick wrong.
   *
   * footerRef: used instead of useState because the scrollListener cant access the state because of the useEffect only able to read state from the same render-cycle.
   */
  const footerRef = useRef<{
    offsetTop: number;
    offsetHeight: number;
  } | null>(null);
  const footerRefCallback = useCallback((node: HTMLDivElement) => {
    if (node !== null) {
      footerRef.current = {
        offsetTop: node.offsetTop,
        offsetHeight: node.offsetHeight,
      };
    }
  }, []);

  useEffect(() => {
    const headerElement = document.getElementById('MainApp-header-portal');
    headerRef.current = headerElement;

    /**
     * Calculate if footer has to be sticky onMount.
     * For cases where user enters a page in a scrolled position.
     */
    const headerHeight = headerRef.current ? headerRef.current.offsetHeight : 0;
    if (footerRef.current) {
      setFooterStuck(
        window.pageYOffset > footerRef.current.offsetTop - headerHeight
      );
    }
  }, []);

  useEffect(() => {
    const scrollListener = () => {
      const headerHeight = headerRef.current
        ? headerRef.current.offsetHeight
        : 0;

      if (footerRef.current) {
        setFooterStuck(
          window.pageYOffset > footerRef.current.offsetTop - headerHeight
        );
      }
    };

    // 10ms seems to transition fine. Higher value causes it to stick too late making the footer jump before sticking.
    const throttledListener = throttle(scrollListener, 10);
    window.addEventListener('scroll', throttledListener);
    return () => {
      window.removeEventListener('scroll', throttledListener);
    };
  }, []);

  return (
    <div className={cn('PageHeader', props.className)}>
      {headerRef && headerRef.current
        ? createPortal(
            <div
              className={cn(
                'PageHeader-portal',
                footerStuck ? 'sticky' : undefined
              )}
            >
              <BreadcrumbsAndTitle
                breadcrumbs={props.breadcrumbs}
                title={props.title}
                minimizedBreadcrumbs={true}
              />
            </div>,
            headerRef.current
          )
        : null}

      {
        <div
          className={cn(
            'PageHeader-title container',
            footerStuck ? 'sticky' : undefined
          )}
        >
          {/**
           * We add a wrapper to ensure that when title actions is wrapping, the spacing remains consistent
           */}
          <div className="PageHeader-title-wrapper">
            <BreadcrumbsAndTitle
              breadcrumbs={props.breadcrumbs}
              title={props.title}
              minimizedBreadcrumbs={!breakpointMd}
            />
            {props.titleActions ? (
              <span className="PageHeader-title-actions-span">
                {props.titleActions}
              </span>
            ) : null}
          </div>
        </div>
      }
      {props.children && (
        <div className="PageHeader-content container">{props.children}</div>
      )}
      <div
        className={cn('PageHeader-footer', footerStuck ? 'sticky' : undefined)}
        ref={footerRefCallback}
      >
        {renderNavtabs && (
          <NavTabs className="PageHeader-footer-navtab container">
            {props.navTabs &&
              props.navTabs.map((tab, idx) => (
                <NavTab
                  key={idx}
                  exact={tab.exact}
                  path={tab.url}
                  label={tab.label.toUpperCase()}
                  onClick={tab.onClick}
                />
              ))}
          </NavTabs>
        )}
      </div>
      {renderNavtabs && (
        <div
          style={{
            paddingTop:
              footerStuck && footerRef.current
                ? footerRef.current.offsetHeight
                : 0,
          }}
        />
      )}
    </div>
  );
};
