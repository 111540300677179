import * as React from 'react';

import './Attribute.scss';
import cs from 'classnames';
import { IconDefinition } from '@telia/styleguide';

export interface Props {
  className?: string;
  label?: string | null;
  value?: number | string | null | React.ReactNode;
  style?: React.CSSProperties;
  renderas?: 'div' | 'li';
  icon?: IconDefinition;
  displayBlock?: boolean;
}

export const Attribute: React.FC<Props> = props => {
  if (!props.label && !props.value) {
    return null;
  }

  const Element = props.renderas || 'div';

  return (
    <Element
      className={cs(
        'Attribute',
        props.displayBlock ? 'Attribute-block' : undefined,
        props.className
      )}
      style={props.style}
    >
      {props.label ? (
        <span className="Attribute-label">{props.label}</span>
      ) : null}

      {typeof props.value === 'number' || props.value ? (
        <span className="Attribute-value">{props.value}</span>
      ) : null}
    </Element>
  );
};
