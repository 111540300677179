import React from 'react';
import { Attribute, ToggeFiltersButton } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { FilterChips } from './FilterChips';

export const ListFilters = (props: {
  numberOfActiveFilters: number;
  toggleFilters: () => void;
  totalResults: number;
}) => (
  <div className="ListFilters border-bottom p-3">
    <FilterChips />
    <div className="d-flex">
      <ToggeFiltersButton
        numberOfActiveFilters={props.numberOfActiveFilters}
        toggle={props.toggleFilters}
        isOpen={false}
      />

      <Attribute
        label={t.locations.Sidebar.header.count}
        value={props.totalResults}
        className="ml-auto pr-1"
      />
    </div>
  </div>
);
