import React from 'react';
import { urlFor, Button, CardBody, CardHeader, CardFooter } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Subscription } from 'src/lib/types';
import { SidebarSubscriptionItem } from './SidebarSubscriptionItem';
interface Props {
  activeSubscription: Subscription;
  locationRef: HTMLDivElement | null;
}

export const SidebarSubscription = (props: Props) => {
  const reset = () => {
    props.locationRef?.dispatchEvent(new CustomEvent('resetToDefault'));
  };
  return (
    <>
      <CardHeader>
        <Button
          icon="arrow-large-left"
          to={urlFor({ area: 'map' })}
          hideLabel={true}
        >
          {t.locations.Sidebar.backBtn}
        </Button>
      </CardHeader>
      <CardBody className="Sidebar-mainContent">
        <SidebarSubscriptionItem
          activeSubscription={props.activeSubscription}
        />
      </CardBody>
      <CardFooter noSpacing={true}>
        <div className="p-3 text-right">
          <Button
            color="white"
            base="primary"
            className="mr-3"
            to={urlFor({ subscription: props.activeSubscription.id })}
          >
            {t.locations.Sidebar.subscription.goToService}
          </Button>
          <Button color="purple" base="primary" onClick={reset}>
            {t.locations.Sidebar.subscription.back}
          </Button>
        </div>
      </CardFooter>
    </>
  );
};
