import Nb from '../../nb/Main/documents';

const documents: typeof Nb = {
  title: 'Documents',
  thName: 'Name',
  thLastChange: 'Last change',
  thLastChangeBy: 'Last changed by',
  thSize: 'Size',
  thSharing: 'Sharing',
  btnUpload: 'Upload file',
  btnNewFolder: 'New folder',
  rootFolder: 'Top folder',
  folderIsEmpty: 'The folder is empty.',
  genericError: 'An error occurred.',
  shared: 'Shared with customer',
  internal: 'Telia internal',
  newFolderModal: {
    heading: 'Create new folder',
    label: 'Name',
    submit: 'Create folder',
  },

  uploadModal: {
    placeholder: {
      main: 'There are no documents here yet',
      secondary: 'Drag a file here or click here to upload',
    },
  },

  downloadModal: {
    heading: 'Download file',
    submit: 'Download',
  },

  ChangeSharingModal: {
    heading: 'You are about to change sharing of this file',
    confirm: 'Confirm',
    setFileSharedConfirmation:
      'Are you sure you want to share this file? The customers will be able to access this file.',
    setFileInternalConfirmation:
      'Are you sure you want to make this file Telia internal? The customer that currently has access to this file will no longer be able to access it.',
  },

  DeleteFileForm: {
    submit: 'Delete',
    confirmDelete: 'Are you sure you want to delete the file',
  },

  DeleteFolderForm: {
    submit: 'Delete',
    confirmDelete: 'Are you sure you want to delete the folder',
  },

  NewFolderForm: {
    nameLabel: 'Name',
    submit: 'Create folder',
    accessControl: 'Access control',
  },

  FileOptions: {
    deleteFile: 'Delete file',
    renameFile: 'Rename file',
    deleteFileModalHeading: 'Delete file',
    renameFileModalHeading: 'Rename file',
    setFileShared: 'Set file as shared',
    setFileInternal: 'Set file as Telia internal',
  },

  FolderOptions: {
    accessFolder: 'Access control',
    deleteFolder: 'Delete folder',
    renameFolder: 'Rename folder',
    deleteFolderModalHeading: 'Delete folder',
    renameFolderModalHeading: 'Rename folder',
  },

  validations: {
    nameTooLong: (max: number) =>
      `The name cannot be longer than ${max} characters.`,
    nameTooShort: 'The name cannot be empty.',
  },
  errors: {
    couldNotMoveFile: 'Ops! We could not move the file',
    couldNotMoveFolder: 'Ops! We could not move the folder',
  },

  RenameFileForm: {
    newNameLabel: 'New name',
    newNamePlaceholder: 'Filename',
    submit: 'Rename',
  },

  RenameFolderForm: {
    newNameLabel: 'New name',
    newNamePlaceholder: 'Folder name',
    submit: 'Rename',
  },

  UpdateFolderAccessForm: {
    description: 'Add persons that should have access to the folder',
    submit: 'Save access',
    personsWithAccess: 'Persons with access',
  },

  UndoActions: {
    movedFile: 'Moved the file',
    movedFolder: 'Moved the folder',
    deletedFile: 'Deleted the file',
    deletedFolder: 'Deleted the folder',
    renamedFolderA: 'Renamed the folder',
    renamedFolderB: '. Its new name is ',
    renamedFileA: 'Renamed the file',
    renamedFileB: '. Its new name is ',
    to: 'til',
  },

  UndoPopup: {
    undo: 'Undo',
    undoing: 'Undoing ...',
  },
};

export default documents;
