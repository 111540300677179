import React, { useState, useEffect } from 'react';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { TextField, FormSubmitBar } from 'src/lib/ui';

/**
 * Common for all modals
 */
type GenericModalProps<T> = {
  onCancel?: () => void;
  onSubmit: (values: T) => void | Promise<void>;
  // trigger: React.ReactElement<{ onClick: (e: Even<HTMLButtonElement>) => void; }>;
  trigger: React.ReactElement<{ onClick: any }>;
  initialValues: T;
};

/**
 * For this specific modal
 */
type AssignmentModalProps = {
  mode: 'add' | 'edit';
};

// Represents values going out of the modal; the modal result
interface Values {
  key: string;
  userId?: string;
  customerId?: string;
  value?: string;
}

type Props = AssignmentModalProps & GenericModalProps<Values>;

export function AssignmentModal(props: Props) {
  const [values, setValues] = useState<Values>(props.initialValues);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValues(props.initialValues);
  }, [props.initialValues]);

  function onClick() {
    setOpen(true);
  }
  const trigger = React.cloneElement(props.trigger, { onClick });

  function toggleState() {
    setOpen(!open);
  }

  function submit() {
    props.onSubmit(values);
    setOpen(false);
  }

  return (
    <>
      {trigger}
      <Modal isOpen={open} toggle={toggleState}>
        <ModalHeader>
          {props.mode === 'edit' ? 'Edit assignment' : 'Assign feature flag'}
        </ModalHeader>
        <ModalBody className="p-4">
          <TextField
            label="Key"
            id="key"
            value={values.key}
            onChange={e => setValues({ ...values, key: e.currentTarget.value })}
            readOnly={true}
          />

          <TextField
            label="User ID"
            className="mt-3"
            id="userId"
            value={values.userId}
            onChange={e =>
              setValues({ ...values, userId: e.currentTarget.value })
            }
            readOnly={props.mode === 'edit'}
          />

          <TextField
            label="Customer ID"
            className="mt-3"
            id="customerId"
            value={values.customerId}
            onChange={e =>
              setValues({ ...values, customerId: e.currentTarget.value })
            }
            readOnly={props.mode === 'edit'}
          />

          <TextField
            label="Value"
            className="mt-3"
            id="value"
            value={values.value}
            onChange={e =>
              setValues({ ...values, value: e.currentTarget.value })
            }
          />
        </ModalBody>
        <ModalFooter>
          <FormSubmitBar cancel={toggleState} submit={submit} />
        </ModalFooter>
      </Modal>
    </>
  );
}
