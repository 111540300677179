import React from 'react';
import {
  colors,
  Loading,
  BodyCard,
  CardHeader,
  CardBody,
  CardFooter,
  ProgressBar,
  LinkCard,
  urlFor,
} from 'src/lib/ui';
import { useAppContext } from 'src/lib/global';
import { map } from 'lodash';
import { SubscriptionContextProvider } from 'src/areas/mobile/lib/SubscriptionContext';
import { useOverviewData } from 'src/areas/mobile/overview/useOverviewData';
import { M2MAttributes } from './M2MAttributes';
import { t } from 'src/lib/i18n';
import { formatDate, formatNordic } from 'src/lib/utils';
import { M2MUsageGraph } from './M2MUsageGraph';
import { useSubscriptionHistoric } from 'src/areas/mobile/lib/useSubscriptionHistoric';
import { getDefaultPeriod, getPastMonthPeriod } from 'src/lib/utils';
import { useRouter } from 'src/lib/utils';
import { NoData } from '../';
import { useMobileDataLink } from 'src/areas/main/subscriptions/queries/useMobileDataLink';
import { useConnectedSimCard } from 'src/areas/main/subscriptions/queries/useConnectedSim';
import * as types from '../../../queries/__types/useConnectedSimCard';
import { DifferentVendor } from 'src/areas/main/subscriptions/Pages/Components/DifferentVendor';

const formatGB = (value: number) => parseFloat((value / 1024).toFixed(2));

interface Props {
  subscription: {
    id: string;
  };
  connectedSimCard?: types.useConnectedSimCard_customer_subscriptions_connectedSimCard;
}
export const M2MUsage = (props: Props) => {
  const app = useAppContext();
  const connectedSimCard = useConnectedSimCard(props.subscription.id);
  const { mobileSubscription } = useMobileDataLink(props.subscription.id);
  const phoneNumber = mobileSubscription?.phoneNumbers?.[0]?.phoneNumber;

  const subscriptionContext = {
    id: phoneNumber ?? '',
    name: mobileSubscription?.name ?? '',
    availableSubscriptionIds: map(app.access.mobileSubscriptions, s => s.id),
    customerId: app.activeCustomerId || '',
    selectSubscriptionId: app.setActiveSubscriptionId,
  };

  return (
    <SubscriptionContextProvider value={subscriptionContext}>
      <InnerM2MUsage
        subscription={props.subscription}
        connectedSimCard={connectedSimCard?.connectedSimCard}
      />
    </SubscriptionContextProvider>
  );
};

const InnerM2MUsage = (props: Props) => {
  const router = useRouter<{ id: string }>();
  const { mobileSubscription, dataSubscription } = useMobileDataLink(
    props.subscription.id
  );
  const overview = useOverviewData();
  const { periodDetails, loading } = useSubscriptionHistoric(
    getPastMonthPeriod(2),
    getDefaultPeriod()
  );
  const i18n = t.subscriptions.M2MUsage;
  const isMobileSubscription = props.subscription.id === mobileSubscription?.id;

  let body;
  if (overview.loading) {
    body = (
      <CardBody>
        <Loading />
      </CardBody>
    );
  }
  if (overview.data?.subscription?.current) {
    const { current } = overview.data.subscription;
    const dataInclusive = current.dataInclusive
      ? formatGB(current.dataInclusive)
      : undefined;
    const speedDrop = current.speedDrop
      ? formatGB(current.speedDrop)
      : undefined;
    const usedData = current?.usage?.nationalDataVolume
      ? formatGB(current.usage.nationalDataVolume)
      : undefined;
    const mainSim = overview?.data?.subscription?.simCards?.find(
      sim => sim.isMainSimCard
    )?.simCardNumber;
    const operator = overview?.data?.subscription?.simCards?.find(
      sim => sim.isMainSimCard
    )?.operator;

    const allPeriods = loading
      ? []
      : periodDetails?.map(d => ({
          month: formatDate(d?.period, 'month'),
          usage: formatGB(d?.usage?.nationalDataVolume ?? 0),
        }));

    const lastFourMonths = allPeriods
      ? [
          ...allPeriods.slice(allPeriods.length - 3),
          {
            month: formatDate(current.period, 'month'),
            usage: formatGB(current?.usage?.nationalDataVolume ?? 0),
          },
        ]
      : undefined;

    body = (
      <>
        <CardBody>
          <small
            className="d-block mt-n3 mb-5"
            style={{ textTransform: 'capitalize' }}
          >
            {'1. - ' + formatDate(new Date(), 'long')}
          </small>
          <div className="d-flex align-items-center">
            <div className="flex-fill m-auto ">
              {dataInclusive && usedData !== undefined ? (
                <ProgressBar
                  value={usedData}
                  maxValue={dataInclusive}
                  color={colors.corePurple}
                  unit="GB"
                />
              ) : (
                <M2MAttributes
                  dataUsed={
                    usedData !== undefined
                      ? formatNordic(usedData, 2) + 'GB'
                      : undefined
                  }
                  speedReduce={speedDrop + 'GB'}
                />
              )}
            </div>

            <div className="ml-5 pl-5" style={{ flex: '0 0 50%' }}>
              {isMobileSubscription ? (
                dataInclusive ? (
                  <M2MAttributes speedReduce={speedDrop + 'GB'} />
                ) : null
              ) : (
                <M2MAttributes
                  subscription={mobileSubscription}
                  dataInclusive={
                    dataInclusive ? dataInclusive + 'GB' : i18n.ongoingUsage
                  }
                  speedReduce={speedDrop + 'GB'}
                  simNumber={mainSim}
                  operator={operator}
                />
              )}
            </div>
          </div>
        </CardBody>
        <CardBody>
          <div className="pt-5">
            <div>{i18n.history}</div>
            <small>{i18n.pastFourMonths}</small>
          </div>
          <M2MUsageGraph data={lastFourMonths} maxValue={dataInclusive} />
        </CardBody>
      </>
    );
  } else if (props.connectedSimCard) {
    body = (
      <CardBody>
        <M2MAttributes
          simNumber={props.connectedSimCard?.simCardNumber || undefined}
          operator={props.connectedSimCard?.operator}
        />
        {props.connectedSimCard.operator !== 'Telia' ? (
          <DifferentVendor />
        ) : (
          <NoData dataTypeLabel={i18n.usage} />
        )}
      </CardBody>
    );
  } else {
    body = (
      <CardBody>
        <NoData dataTypeLabel={i18n.usage} />
      </CardBody>
    );
  }

  return (
    <BodyCard noGutter>
      <CardHeader>
        <h2>
          {isMobileSubscription ? i18n.dataUsage : i18n.subscriptionAndData}
        </h2>
      </CardHeader>
      {body}

      {mobileSubscription && dataSubscription && (
        <CardFooter noSpacing>
          <LinkCard
            className="py-4"
            url={
              isMobileSubscription
                ? router.match.url + '/forbruk'
                : urlFor({ subscription: mobileSubscription.id })
            }
          >
            {isMobileSubscription
              ? i18n.showUsageDetails
              : i18n.showDetailAboutSubscription}
          </LinkCard>
        </CardFooter>
      )}
    </BodyCard>
  );
};
