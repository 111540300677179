import React from 'react';
import { BodyCard, CardBody, Loading, colors } from 'src/lib/ui';
import { useStatisticsParams } from '../../utils/useStatisticsParams';
import { isAggregatedByDay } from '../../utils/isAggregatedByDay';
import {
  useQosStatistics,
  QosStatisticsPeriod,
} from '../../queries/WithStatisticsQoS';
import { StandardChart } from '../../components/StandardChart';
import { mapQosData } from './mapQosData';
import { Area } from 'recharts';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { formatNordic } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import { getPrecision } from '../../utils/getPrecision';
import { WithStatisticsQoS_customer_subscriptions_statisticsQoS_trafficClasses } from 'src/areas/main/statistics/queries/__types/WithStatisticsQoS';

const getStatisticsTitle = ({
  alternativeName,
  name,
}: WithStatisticsQoS_customer_subscriptions_statisticsQoS_trafficClasses) =>
  alternativeName ? `${alternativeName} (${name})` : name;

export const Qos = (props: { currentView: 'qosTransmit' | 'qosDrop' }) => {
  const params = useStatisticsParams();
  const statisticsQuery = useQosStatistics({
    subscriptionId: params.subscriptionId,
    periods: 100,
    periodStart: params.currentStartPeriod,
    periodEnd: params.currentEndPeriod,
    interface: params.currentInterface,
  });

  const trafficClasses = statisticsQuery.data?.statisticsQoS?.trafficClasses;
  const mappedData = mapQosData(
    statisticsQuery.data?.statisticsQoS?.periods,
    trafficClasses
  );
  const dataKey =
    props.currentView === 'qosTransmit' ? 'transmitRate' : 'dropRate';
  const tooltip =
    props.currentView === 'qosTransmit' ? QoSTooltipTransmit : QoSTooltipDrop;

  const hideTimestamp = isAggregatedByDay(
    params.currentStartPeriod,
    params.currentEndPeriod
  );

  return (
    <BodyCard noGutter={true}>
      <CardBody>
        {statisticsQuery.loading ? (
          <Loading />
        ) : mappedData === null ? (
          <div>{t.statistics.noData}</div>
        ) : trafficClasses && trafficClasses.length > 0 ? (
          trafficClasses.map((tc, idx) => (
            <StandardChart<QosStatisticsPeriod>
              key={idx}
              title={getStatisticsTitle(tc)}
              periods={mappedData}
              tooltip={tooltip(idx)}
              hideTimestamp={hideTimestamp}
            >
              <Area
                stroke={colors.blueDark}
                strokeWidth="1"
                fill={colors.blueDark}
                fillOpacity="0.2"
                dataKey={`trafficClasses[${idx}].${dataKey}`}
                name={getStatisticsTitle(tc)}
                dot={{ r: 2 }}
              />
            </StandardChart>
          ))
        ) : (
          <div>{t.statistics.noTrafficClasses}</div>
        )}
      </CardBody>
    </BodyCard>
  );
};

const QoSTooltipTransmit = (idx: number) => ({
  data,
  hideTimestamp,
}: {
  data: QosStatisticsPeriod;
  hideTimestamp?: boolean;
}) => {
  const currentData = data.trafficClasses?.[idx];

  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header={currentData?.name ?? ''}>
        {currentData && (
          <SectionItem
            title={t.statistics.Qos.traffic}
            color={colors.orange}
            value={
              currentData.transmitRate !== null
                ? formatNordic(
                    currentData.transmitRate,
                    getPrecision(currentData.transmitRate),
                    false
                  ) + ' kbps'
                : t.statistics.Qos.noTraffic
            }
          />
        )}
      </Section>
    </CustomTooltipContainer>
  );
};

const QoSTooltipDrop = (idx: number) => ({
  data,
  hideTimestamp,
}: {
  data: QosStatisticsPeriod;
  hideTimestamp?: boolean;
}) => {
  const currentData = data.trafficClasses?.[idx];

  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header={currentData?.name ?? ''}>
        {currentData && (
          <SectionItem
            title={t.statistics.Qos.dropRate}
            color={colors.orange}
            value={
              currentData.dropRate !== null
                ? formatNordic(
                    currentData.dropRate,
                    getPrecision(currentData.dropRate),
                    false
                  ) + ' kbps'
                : t.statistics.Qos.noTraffic
            }
          />
        )}
      </Section>
    </CustomTooltipContainer>
  );
};
