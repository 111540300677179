import React from 'react';
import FilterSection from 'src/areas/main/common/FilterSection';
import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { SubscriptionsFilterSectionProps } from '../../types';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { SubscriptionExportButton } from 'src/areas/main/subscriptions/lib/SubscriptionExportButton/SubscriptionExportButton';
import { t } from 'src/lib/i18n';
import {
  useTagsFilter,
  useSortFilter,
  useSubscriptionStatusFilter,
  useSubscriptionTypeFilter,
  useDeliveryStatusFilter,
  useCaseFilter,
  useCpeTypeFilter,
} from 'src/lib/filters';
import { BatchEditTags } from '../Components';

export const DataSubscriptionsFilters = (
  props: SubscriptionsFilterSectionProps
) => {
  const { subscriptionListParams: sp, sortPickerOptions } = props;
  const query = useQueryParameters();

  const tagsFilter = useTagsFilter();
  const statusFilter = useSubscriptionStatusFilter();
  const sortOptions = useSortFilter({
    label: t.subscriptions.sortFields.lable,
    currentSortBy: sp.sortBy,
    currentSortOrder: sp.sortOrder,
    setSort: sp.setSort,
    sortFields: sortPickerOptions,
    defaultOption: sp.defaultSort,
  });
  const cpeFilter = useCpeTypeFilter();
  const subscriptionTypeFilter = useSubscriptionTypeFilter();
  const deliveryFilter = useDeliveryStatusFilter();
  const caseFilter = useCaseFilter();
  return (
    <FilterSection
      searchValue={sp.searchQuery}
      onSearch={val => setFilterAndResetPage(query, 'query', val)}
      onResetSearch={() => setFilterAndResetPage(query, 'query')}
      currentView={sp.view}
      setCurrentView={
        props.selectProps?.selected.length ? undefined : sp.setView
      }
      totalSearchResults={props.totalResults}
      totalFiltersActive={props.filterCount}
      filterOptions={[
        tagsFilter,
        caseFilter,
        statusFilter,
        ...(cpeFilter ? [cpeFilter] : []),
        subscriptionTypeFilter,
        deliveryFilter,
        ...(sp.view === 'list' ? [sortOptions] : []),
      ]}
      filterToggling={true}
      actions={
        <>
          <BatchEditTags
            selectedSubscriptionIds={props.selectProps?.selected ?? []}
          />

          <SubscriptionExportButton
            query={sp.searchQuery}
            group={'data'}
            tags={query.getArray('tag')}
            cases={query.getArray('cases')}
            status={query.getArray('status')}
            type={query.getArray('type')}
            deliveryStatus={query.getArray('deliveryStatus')}
            subscriptionsIds={props.selectProps?.selected}
          />
        </>
      }
      selectProps={props.selectProps}
    />
  );
};
