import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { ChooseServicePlusArea } from 'src/areas/main/servicePlus/ChooseServicePlusArea';
import { AddressDetails } from 'src/areas/main/servicePlus/AddressDetails';

const ServicePlus: React.FC<RouteComponentProps<{}>> = props => {
  return (
    <Switch>
      <Route
        path={`${props.match.url}/:type(oversikt|lokasjoner)`}
        component={ChooseServicePlusArea}
      />
      <Route
        path={`${props.match.url}/lokasjon/:addressId`}
        component={AddressDetails}
      />
      <Route
        path={props.match.url}
        render={() => <Redirect to={`${props.match.url}/oversikt`} />}
      />
    </Switch>
  );
};

export default ServicePlus;
