import * as React from 'react';
import cn from 'classnames';
import { Link } from 'react-router-dom';
import { Icon, IconDefinition } from '@telia/styleguide';

import './ButtonCard.scss';

import newSim from 'src/images/icons/new-sim-card-light.svg';
import newTelephone from 'src/images/icons/new-telephone.svg';
import newMobile from 'src/images/icons/new-mobile-lite.svg';
import connectedRouter from 'src/images/icons/connected-router.svg';

export type SvgIconDefinition =
  | 'newSim'
  | 'newTelephone'
  | 'newMobile'
  | 'connectedRouter';
const svgBigIcons: ButtonCardIcon[] = [
  'newSim',
  'newTelephone',
  'newMobile',
  'connectedRouter',
];

type ButtonCardIcon = SvgIconDefinition | IconDefinition;

// NOTE! All SVGs are 48x48 and exported from Zeplin. If we want to use other icons, we need to adjust the size.
const SvgIcon = (props: { icon: SvgIconDefinition }) => {
  const imgSrc =
    props.icon === 'newSim'
      ? newSim
      : props.icon === 'newTelephone'
      ? newTelephone
      : props.icon === 'newMobile'
      ? newMobile
      : props.icon === 'connectedRouter'
      ? connectedRouter
      : undefined;

  if (!imgSrc) {
    return null;
  }
  return <img src={imgSrc} alt={props.icon} />;
};

type ButtonCardProps = {
  stackHorizontally?: boolean;
  className?: string;
  helpText?: string;
  title?: string;
  icon?: ButtonCardIcon;
  type?: 'submit' | 'reset' | 'button';
  color?: 'purple' | 'dark';
  'aria-expanded'?: boolean;
  'aria-label'?: string;
  'aria-haspopup'?: boolean;
  // disabled?: boolean;
} & (
  | { to?: string }
  | {
      onClick?: (evt: React.SyntheticEvent<HTMLButtonElement>) => void;
    }
  | { href?: string; target?: '_blank' }
);

export const ButtonCard: React.FunctionComponent<ButtonCardProps> = props => {
  const buttonColor = props.color || 'purple';
  const onClick = (e: React.SyntheticEvent<HTMLButtonElement>) => {
    if ('onClick' in props && props.onClick) {
      props.onClick(e);
    }
  };
  const sharedProps = {
    className: cn(
      'ButtonCard',
      `ButtonCard--${buttonColor}`,
      props.className,
      props.stackHorizontally ? 'ButtonCard--horizontal' : undefined
    ),
    title: props.title,
    'aria-expanded': props['aria-expanded'],
    'aria-label': props['aria-label'],
    'aria-haspopup': props['aria-haspopup'],
  };

  let icon: React.ReactNode = null;
  if (props.icon) {
    if (svgBigIcons.includes(props.icon)) {
      icon = <SvgIcon icon={props.icon as SvgIconDefinition} />;
    } else {
      icon = <Icon icon={props.icon as IconDefinition} />;
    }
  }

  const buttonCardContent = (
    <>
      {icon && <div className="ButtonCard-icon">{icon}</div>}
      <div className="ButtonCard-flexWrapper">
        {props.children}
        {props.helpText && (
          <>
            <div className="ButtonCard-divider" />
            <div className="ButtonCard-helpText">{props.helpText}</div>
          </>
        )}
      </div>
    </>
  );

  if ('to' in props && props.to) {
    return (
      <Link to={props.to} {...sharedProps}>
        {buttonCardContent}
      </Link>
    );
  }

  if ('href' in props && props.href) {
    return (
      <a href={props.href} target={props.target} {...sharedProps}>
        {buttonCardContent}
      </a>
    );
  }

  return (
    <button onClick={onClick} type={props.type} {...sharedProps}>
      {buttonCardContent}
    </button>
  );
};
