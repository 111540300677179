import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as types from './__types/useCopyRoles';

export const MUTATION_COPY_ROLES = gql`
  mutation useCopyRoles($input: CopyRolesInput) {
    copyRoles(input: $input) {
      roles {
        role
        organisation {
          id
          name
        }
        tag {
          id
          tag
        }
      }
      error {
        message
        code
      }
    }
  }
`;

export const useCopyRoles = () => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    types.useCopyRoles,
    types.useCopyRolesVariables
  >(MUTATION_COPY_ROLES);
  const copyRoles = (fromUserId: string, toUserId: string) =>
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          fromUserId,
          toUserId,
        },
      },
    });
  return {
    copyRoles,
    data,
    loading,
    error: !!error || !!data?.copyRoles?.error,
  };
};
