import { Definition } from './types';
import { formatColumns, ContactColumns } from './index';
import { getSortOptions, getSortOption } from '../utils/getSortOptionsByKey';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getContactColumnDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const contactColumnMap: {
    [key in ContactColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    icon: {
      label: '',
      cellWidth: 90,
      fixed: true,
    },
    name: {
      label: t.contacts.table.name,
      sortOptions: getSortOptions(['firstName', 'lastName']),
      fixed: true,
    },
    phone: {
      label: t.contacts.table.phone,
      cellWidth: 230,
    },
    email: {
      label: t.contacts.table.email,
      sortOptions: getSortOption('email', true),
    },
    description: {
      label: t.contacts.table.description,
      cellWidth: 230,
    },
    cases: {
      label: t.contacts.table.cases,
      cellWidth: 230,
    },
    access: {
      label: t.contacts.table.access,
    },
  };

  const defaultContactColumns: Array<ContactColumns> = [
    'icon',
    'name',
    'phone',
    'email',
    'description',
  ];

  return formatColumns({
    map: contactColumnMap,
    defaultColumns: defaultContactColumns,
    savedColumnPreferences,
  });
};
