import { Definition } from './types';
import { formatColumns, TeleColumns } from './index';
import { getSortOptions, getSortOption } from '../utils/getSortOptionsByKey';
import { TableColumnDefintion } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const getTeleColumnDefinitions = (
  savedColumnPreferences: string[]
): Definition => {
  const teleColumnMap: {
    [key in TeleColumns]: Omit<TableColumnDefintion, 'key'>;
  } = {
    name: {
      label: t.subscriptions.GenericSubscriptionTable.service,
      sortOptions: getSortOptions(['alias', 'type', 'subscriptionId']),
      fixed: true,
    },
    phoneNumber: {
      label: t.subscriptions.TeleSubscriptionTable.phoneNumber,
      sortOptions: getSortOption('phoneNumber', true),
      cellWidth: 150,
    },
    address: {
      label: t.subscriptions.GenericSubscriptionTable.address,
      sortOptions: getSortOption('address', true),
    },
    deliveryStatus: {
      label: t.subscriptions.TeleSubscriptionTable.deliveryStatus,
      cellWidth: 200,
    },
    invoiceCenter: {
      label: t.subscriptions.GenericSubscriptionTable.invoiceCenter,
    },
    costCenter: {
      label: t.subscriptions.GenericSubscriptionTable.costCenter,
    },
    tags: {
      label: t.subscriptions.GenericSubscriptionTable.tags,
    },
  };

  const defaultTeleColumns: Array<TeleColumns> = [
    'name',
    'phoneNumber',
    'address',
    'invoiceCenter',
    'tags',
  ];
  return formatColumns({
    map: teleColumnMap,
    defaultColumns: defaultTeleColumns,
    savedColumnPreferences,
  });
};
