import React from 'react';
import { Link } from 'react-router-dom';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import * as types from '../queries/__types/usePlannedWork';
import { t } from 'src/lib/i18n';
import { Button, Attribute, InfoCard, Attributes, urlFor } from 'src/lib/ui';
import {
  getDurationFromMilliseconds,
  formatDate,
} from 'src/lib/utils/dateUtils';
import moment from 'moment';
import { formatDurationString } from '../../subscriptions/utils';
import { getSubscriptionDisplayTitles } from '../../../../lib/types/subscription/getSubscriptionDisplayTitles';
import { SubscriptionStatusIcon } from '../../subscriptions/lib/SubscriptionStatusIcon/SubscriptionStatusIcon';
import { useSubscription } from '../../subscriptions/queries/useSubscription';
import { useSubscriptions } from '../../subscriptions/queries/useSubscriptions';
import { formatInstallationAddress, getPlannedWorkState } from 'src/lib/utils';
import './PlannedWorkModal.scss';
import { Subscription } from 'src/lib/types';
import { compact } from 'lodash';

interface Props {
  open: boolean;
  toggleOpen: () => void;
  plannedWork: types.usePlannedWork_customer_plannedWork;
}

export const PlannedWorkModal = (props: Props) => {
  const i18n = t.dashboard.plannedWork;
  const durationString = formatDuration(
    props.plannedWork.expectedTotalDowntimeInMinutes ?? 0
  );

  const riskOnly =
    props.plannedWork.serviceImpact === 'This message is given as risk only.';
  const noDowntime =
    props.plannedWork.expectedTotalDowntimeInMinutes === 0 ||
    props.plannedWork.expectedTotalDowntimeInMinutes === null;
  const detailsList = props.plannedWork.description?.split('\n');

  return (
    <Modal
      isOpen={props.open}
      toggle={props.toggleOpen}
      size="lg"
      style={{ maxHeight: '100vh' }}
    >
      <ModalHeader>
        {i18n.plannedWork}{' '}
        {moment(props.plannedWork.dates.plannedStartDate).format('DD.MM.YYYY')}
      </ModalHeader>
      <ModalBody>
        <div className="row" style={{ maxHeight: '70vh', overflowY: 'scroll' }}>
          <div className="col-xl-6">
            <div className="pb-3">
              {t.subscriptions.SubscriptionPlannedWorkCard.details}
            </div>
            <small>
              <Attributes>
                <Attribute
                  label={t.subscriptions.SubscriptionPlannedWorkCard.status}
                  value={getPlannedWorkState(props.plannedWork.state)}
                />
                <Attribute
                  label={t.subscriptions.SubscriptionPlannedWorkCard.reference}
                  value={props.plannedWork.changeNumber}
                />
                <Attribute
                  label={t.subscriptions.SubscriptionPlannedWorkCard.startTime}
                  value={formatDate(
                    props.plannedWork.dates.plannedStartDate,
                    'shortWithTime'
                  )}
                />
                <Attribute
                  label={t.subscriptions.SubscriptionPlannedWorkCard.endTime}
                  value={formatDate(
                    props.plannedWork.dates.plannedEndDate,
                    'shortWithTime'
                  )}
                />
                {!riskOnly && !noDowntime ? (
                  <Attribute
                    label={
                      t.subscriptions.SubscriptionPlannedWorkCard
                        .expectedDowntime
                    }
                    value={durationString}
                  />
                ) : null}
                {props.plannedWork.serviceImpact ? (
                  <Attribute
                    label={t.subscriptions.SubscriptionPlannedWorkCard.impact}
                    value={props.plannedWork.serviceImpact}
                  />
                ) : null}
                <Attribute
                  label={t.subscriptions.SubscriptionPlannedWorkCard.details}
                  value={
                    <React.Fragment>
                      {detailsList?.map(elem => (
                        <Attribute value={elem} />
                      ))}
                    </React.Fragment>
                  }
                />
              </Attributes>
              {props.plannedWork.emergency ? (
                <InfoCard
                  className="mt-3"
                  icon="alert"
                  color="danger"
                  heading={i18n.emergency}
                >
                  {<div>{i18n.emergencyDesription}</div>}
                </InfoCard>
              ) : null}
            </small>
          </div>
          <div className="mt-3 mt-xl-0 col-xl-6">
            <div className="pb-3">{i18n.impactedSubscription}</div>
            {props.plannedWork.impactedSubscriptionIds?.map(id =>
              id ? <Subs key={id} id={id} /> : null
            )}
          </div>
        </div>
      </ModalBody>
      <ModalFooter>
        <Button onClick={props.toggleOpen} base="primary" color="purple">
          {i18n.close}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
export const SubLinks: React.FC<{ ids: (string | null)[] | null }> = ({
  ids,
}) => {
  const { subscriptions } = useSubscriptions(compact(ids) || []);
  return (
    <>
      {subscriptions.map(subscription => (
        <SubComponent subscription={subscription} key={subscription.id} />
      ))}
    </>
  );
};

const Subs = (props: { id: string }) => {
  const { subscription } = useSubscription(props.id);
  return <SubComponent subscription={subscription} />;
};

const SubComponent = ({ subscription }: { subscription?: Subscription }) => {
  if (!subscription) {
    return null;
  }
  const { title } = getSubscriptionDisplayTitles(subscription);
  const address = formatInstallationAddress(
    subscription.installationAddress,
    'fullAddress'
  );
  return (
    <Link
      to={urlFor({ subscription: subscription.id })}
      style={{ textDecoration: 'none', color: 'inherit' }}
    >
      <small className="Subscriptions d-flex align-items-center p-2">
        <div className="mr-3">
          <SubscriptionStatusIcon
            cpeStatus={subscription.cpe?.status}
            hasStatus={subscription.isMonitoringPossible ?? undefined}
            circleBackground={true}
          />
        </div>
        <div className="d-flex flex-column">
          <div>{title}</div>
          <div className="grey-dark-text" style={{ fontSize: '12px' }}>
            {address}
          </div>
        </div>
      </small>
    </Link>
  );
};

export const formatDuration = (expectedTotalDowntimeInMinutes: number) => {
  if (!expectedTotalDowntimeInMinutes) {
    return t.subscriptions.SubscriptionPlannedWorkCard.noExpectedDowntime;
  }
  const duration = formatDurationString(
    getDurationFromMilliseconds(expectedTotalDowntimeInMinutes * 60000),
    t.ui.times
  );

  const removeSeconds = (durationString: string) => {
    const startIndexSeconds = durationString.lastIndexOf(',');
    return durationString.slice(0, startIndexSeconds);
  };

  return removeSeconds(duration);
};
