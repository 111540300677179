import React from 'react';
import { SubscriptionStatus } from '../../../../lib/SubscriptionStatusSummaryCard/SubscriptionStatus';
import { SubscriptionSummaryContainer } from '../../../../layout';
import {
  AdditionalInformationCard,
  SubscriptionCostDetails,
  TechnicalContactsCard,
  SubscriptionReportIncidentButton,
  SubscriptionConfiguration,
  M2MUsage,
  SignalCard,
  ConnectionStretch,
  TerminateSubscription,
} from '../../../Components';
import { DataWithStatusAbout } from './DataWithStatusAbout';
import { Feature } from 'src/lib/ui';
// TODO: Remove this type.
import { Subscription } from 'src/lib/types';
interface DataWithStatusSummaryProps {
  subscription: Subscription;
}

const subscriptionsWithMobile = ['IP VPN wireless', 'Link Protection Wireless'];

export const DataWithStatusSummary: React.FunctionComponent<DataWithStatusSummaryProps> = ({
  subscription,
}: DataWithStatusSummaryProps) => {
  return (
    <SubscriptionSummaryContainer
      subscription={subscription}
      main={
        <>
          <SubscriptionStatus subscription={subscription} />
          {subscription.installationAddress2 &&
          subscription.installationAddress ? (
            <ConnectionStretch
              installationAddress={subscription.installationAddress}
              installationAddress2={subscription.installationAddress2}
            />
          ) : null}
          <SubscriptionReportIncidentButton subscription={subscription} />
          {subscriptionsWithMobile.includes(subscription.name ?? '') && (
            <SignalCard subscription={subscription} />
          )}
          {/* Forbruk og kostnader */}
          {subscriptionsWithMobile.includes(subscription.name ?? '') ? (
            <M2MUsage subscription={subscription} />
          ) : null}
          <SubscriptionConfiguration subscription={subscription} />
          {/* Live-trafikk */}
          {/* <SubscriptionInterfaces subscription={subscription} /> */}
          {/* {subscription.addons ? <Addons subscription={subscription} /> : null} */}
          {/* <TechnicalDetailsCard subscription={subscription} /> */}
        </>
      }
      sidebar={
        <>
          <DataWithStatusAbout subscription={subscription} />
          <SubscriptionCostDetails subscription={subscription} />
          <TechnicalContactsCard subscription={subscription} />
          <AdditionalInformationCard subscription={subscription} />
          <Feature feature="poc-network-change-order">
            <TerminateSubscription subscription={subscription} />
          </Feature>
        </>
      }
    />
  );
};
