import React, { useState } from 'react';
import { BodyCard, Toolbar, Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import OrderExtraSim from 'src/areas/main/ordering/OrderExtraSim/OrderExtraSim';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import OrderingModal from 'src/areas/main/ordering/layout/OrderingModal';
import { useSubscriptionOriginDetails } from 'src/areas/mobile/lib/useSubscriptionOriginDetails';
import { SimOptions } from 'src/areas/main/subscriptions/Pages/Mobile/SimOptions';
import { Icon } from '@telia/styleguide';
import { Operator } from 'src/__types/graphql-global-types';

interface SimCard {
  simCardNumber: string;
  msisdn: string | null;
  initialPin: string | null;
  puk1: string | null;
  isMainSimCard: boolean;
  type: string | null;
  operator: Operator | null;
}

const SimCardsCardRow = (props: {
  simCard: SimCard;
  disableEdit?: boolean;
}) => {
  const { simCard } = props;
  const [visible, setVisible] = useState(false);
  const toggle = () => setVisible(!visible);
  const i18n = t.mobile.overview.sim;
  const operatorColor = (operator: Operator) => {
    switch (operator) {
      case Operator.Telia:
        return '#990AE3';
      case Operator.Ice:
        return '#FF9B00';
      case Operator.Telenor:
        return '#0099FF';
      default:
        return '';
    }
  };

  return (
    <li className="d-flex align-items-center">
      <Icon
        icon="sim-card"
        style={
          simCard.operator
            ? { color: operatorColor(simCard.operator) }
            : undefined
        }
        className="mr-3"
      />

      <div className="pr-3">
        <div>
          {simCard.isMainSimCard
            ? i18n.mainSim + ` (${simCard.operator})`
            : simCard.type + `-${i18n.sim} (${simCard.operator})`}{' '}
          {simCard.simCardNumber}
        </div>
        {(simCard.initialPin || simCard.puk1) && (
          <div className="d-flex">
            {simCard.initialPin && (
              <div className="mr-1">
                {i18n.pin}: {visible ? simCard.initialPin : '****'}
              </div>
            )}
            {simCard.puk1 && (
              <div className="mx-1">
                {i18n.puk}: {visible ? simCard.puk1 : '********'}
              </div>
            )}
          </div>
        )}
      </div>
      {(simCard.initialPin || simCard.puk1) && (
        <Button
          className="ml-auto"
          icon={visible ? 'visibility' : 'visibility-filled'}
          hideLabel={true}
          onClick={toggle}
        >
          {visible ? i18n.hide : i18n.show}
        </Button>
      )}
      {simCard.msisdn !== null && !props.disableEdit ? (
        <SimOptions msidn={simCard.msisdn} isMain={simCard.isMainSimCard} />
      ) : null}
    </li>
  );
};

interface Props {
  simCards: SimCard[];
}

const OverviewSimCardsCard: React.SFC<Props> = ({ simCards }) => {
  const { id, disableEdit } = useSubscriptionContext();
  const { data } = useSubscriptionOriginDetails();

  // We only allow 2 additional data-sims. A data always sim has 12 digits
  const allowExtraDataSim =
    simCards.filter(sim => sim.msisdn?.length === 12).length < 2;

  // We only allow 1 main sim + 1  additional speech sim. A tele sim has 8 digits
  const allowExtraVoiceSim =
    simCards.filter(sim => sim.msisdn?.length === 8).length < 2;

  return (
    <BodyCard borders={true}>
      <h2>{t.mobile.overview.sim.simCard}</h2>
      <ul className=" px-0 m-0 pb-4 pt-2 ">
        {simCards.map((sim, i) => (
          <SimCardsCardRow key={i} simCard={sim} disableEdit={disableEdit} />
        ))}
      </ul>

      {!disableEdit && (
        <Toolbar>
          <OrderExtraSim
            msisdn={id}
            disableTypeSelect={!(allowExtraDataSim && allowExtraVoiceSim)}
            initialValues={{
              simState: 'existing',
              simCardId: '',
              extraSimType: !allowExtraVoiceSim ? 'data' : 'voice',
              name:
                data?.subscription?.origin?.firstName ||
                '' + data?.subscription?.origin?.lastName ||
                '',
              postalArea: data?.subscription?.origin?.postArea || '',
              address: data?.subscription?.origin?.streetAddress || '',
              postalCode: data?.subscription?.origin?.postCode || '',
              attention: '',
            }}
          >
            {orderExtraSim => (
              <OrderingModal
                title={t.mobile.overview.sim.addSim}
                disableSubmitButton={!orderExtraSim.isFormValid}
                confirmButtonLabel={t.mobile.overview.sim.addSim}
                submit={orderExtraSim.submit}
                icon="add"
                btnStyle="white"
                disabled={!allowExtraDataSim && !allowExtraVoiceSim}
              >
                {orderExtraSim.render}
              </OrderingModal>
            )}
          </OrderExtraSim>
        </Toolbar>
      )}
    </BodyCard>
  );
};
export default OverviewSimCardsCard;
