import * as React from 'react';
import './DocumentsModal.scss';
import { Modal, ModalHeader } from 'reactstrap';

interface Props {
  show: boolean;
  title: string;
  toggle: () => void;
  size?: 'sm' | 'md' | 'lg';
}

const DocumentsModal: React.SFC<Props> = props => {
  const { show, toggle } = props;
  return (
    <Modal
      size={props.size ? props.size : 'md'}
      className="DocumentsModal"
      isOpen={show}
      toggle={toggle}
      centered={true}
    >
      <ModalHeader>
        <div>{props.title}</div>
      </ModalHeader>
      {props.children}
    </Modal>
  );
};

export default DocumentsModal;
