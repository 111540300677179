import React from 'react';
import { Subscription } from 'src/lib/types';
import { getSubscriptionDisplayTitles } from 'src/lib/types';
import { CaseBadges } from '../../../../subscriptions/Pages/Components';
import { Position } from './Position';
import { Status } from './Status';
import { Addons } from './Addons';

interface Props {
  activeSubscription: Subscription;
}
export const SidebarSubscriptionItem = ({ activeSubscription }: Props) => {
  const { title, subtitle } = getSubscriptionDisplayTitles(activeSubscription);
  return (
    <div>
      <div className="pb-3">
        <div className="preamble">{title}</div>
        <div>{subtitle}</div>
        <CaseBadges subscription={activeSubscription} />
      </div>

      <div>
        <Position subscription={activeSubscription} className="pb-4" />
        <Status subscription={activeSubscription} className="pb-4" />

        <Addons subscription={activeSubscription} />
      </div>
    </div>
  );
};
