export const getCostCenterLabel = (
  costCenter?: null | {
    id: string;
    code: string;
    description: string | null;
  }
) =>
  costCenter
    ? costCenter.description
      ? `${costCenter.description} - ${costCenter.code}`
      : costCenter.code
    : null;
