import overviewNo from '../../nb/Mobile/overview';

const overview: typeof overviewNo = {
  greeting: name => `Hello, ${name}`,
  dataUsed: 'Data used',
  smsSent: 'SMS sent',
  minutesCalled: 'Minutes called',
  cost: 'Cost',
  total: 'Total',
  over6GB: 'Over 6 GB',
  overviewDescription:
    'Here is the overview of your mobile subscription this month',
  usage: {
    usageCurrentMonth: 'Usage this month',
    usage: 'Usage',
    data: 'Data',
    dataUsage: 'Data usage',
    used: 'Used',
  },
  subscription: {
    name: 'Name',
    phoneNumber: 'Phone number',
    aboutSubscription: 'About subscription',
    pricePerMB: 'Price per MB data traffic',
    step: 'Step',
    stepPriceNow: 'Step price - Now',
    fixedPrice: 'Fixed price',
    dataIncluded: 'Data included',
    minutesIncluded: 'Speech included',
    smsIncluded: 'SMS included',
    freeUse: 'Free use',
    chartLabel: 'Price/MB',
  },
  sim: {
    simCard: 'SIM-card',
    sim: 'SIM',
    mainSim: 'Main SIM',
    pin: 'PIN',
    puk: 'PUK',
    hide: 'Hide PIN and PUK',
    show: 'Show PIN and PUK',
    replaceSim: 'Replace SIM-card',
    addSim: 'Add extra SIM-card',
    modalHeader: 'Swap SIM-card',
    submitLabelNew: 'Get a new SIM card',
    submitLabelExisting: 'Activate SIM-card',
    textLabel: 'Choose available SIM-card',
    existingSim: 'Activate an existing SIM-card',
    newSim: 'Order a new SIM-card',
    addSimToSubscription: 'Add extra SIM-card to this subscription',
    chooseType: 'Choose the right type of extra SIM for your needs',
    activateOrOrderNew: 'Switch to another SIM-card or order a new one.',
    activateOrOrderNewQuestion: 'Activate or order new?',
    deliveryInformation: 'Delivery information',
    errorMsg:
      'Ops, we could not fint the SIM-card. Make sure you typed it in correctly',
    activateSimDescription:
      'Your new SIM-card will be activated within a few minutes. When the new SIM-card is activated, the old SIM-card will be deactivated. You vill not be able to reactivate the old SIM-card.',
    orderSimDescription:
      'When your new SIM-card is activated, the old SIM-card will be deactivated. You vill not be able to reactivate the old SIM-card. Delivery of a new SIM-card is 2-5 business days',
  },
  profile: {
    change: 'Change',
    profile: 'Profile',
    name: 'Name',
    address: 'Address',
    organisation: 'Organisation',
    email: 'Email',
    phoneNumber: 'Phone number',
    employeeNum: 'Employee number',
    position: 'Employee position',
  },
};

export default overview;
