const sms = {
  recipients: 'Mottakere',
  recipientsInputLabel: 'Skriv inn telefonnummer',
  writeSms: 'Skriv SMS',
  newSmsToSameRecipients: 'Send ny SMS til samme mottakere',
  previouslySentSms: 'Tidligere sendte SMS',
  characterCount: 'Antall tegn',
  members: 'Medlemmer',
  showMoreSMS: 'Vis flere SMS',
  showXofY: (showing: number, total: number) => `Viser ${showing} av ${total}`,
  send: 'Send',
  tlf: 'Tlf',
  invalidPhoneNumber: 'Ugyldig telefonnummer',
  add: 'Legg til',
  sentTo: 'SMS sendt til',
  failed: 'Klarte ikke å sende SMS til',
};

export default sms;
