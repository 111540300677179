import * as React from 'react';
import {
  DeliveryStatus,
  IncidentStatus,
} from 'src/__types/graphql-global-types';
import { SubscriptionDeliveryStatusCard } from '../../Pages/Components/SubscriptionDeliveryStatusCard';
import { SubscriptionActiveIncidentCard } from '../../Pages/Components/SubscriptionActiveIncidentCard';
import { SubscriptionAdditionalCasesLink } from '../../Pages/Components/SubscriptionAdditionalCasesLink';

import './SubscriptionSummaryContainer.scss';

export interface SubscriptionSummaryContainerProps {
  /**
   * All content that belongs in the sidebar.
   */
  sidebar: React.ReactNode;
  /**
   * Main content of the page.
   */
  main: React.ReactNode;
  /**
   * Props that are shared between all Subscription types for reuse of components.
   */
  subscription: {
    id: string;
    deliveryStatus?: DeliveryStatus | null;
    activeIncidents?: Array<{
      id: string;
      incidentStatus?: IncidentStatus | null;
      shortDescription?: string | null;
      opened?: string | null;
    }> | null;
  };
}

export function SubscriptionSummaryContainer(
  props: SubscriptionSummaryContainerProps
) {
  const { subscription, sidebar, main } = props;

  const activeIncident = subscription?.activeIncidents?.[0];
  const numberOfIncidents = subscription?.activeIncidents?.length ?? 0;

  return (
    <div className="SubscriptionSummaryContainer">
      {subscription.deliveryStatus ? (
        <SubscriptionDeliveryStatusCard
          subscription={subscription}
          className="mb-4"
        />
      ) : null}
      {activeIncident ? (
        <div className="mb-4">
          <SubscriptionActiveIncidentCard incident={activeIncident} />
        </div>
      ) : null}
      {numberOfIncidents > 1 ? (
        <div className="mb-4">
          <SubscriptionAdditionalCasesLink subscription={subscription} />
        </div>
      ) : null}

      <div className="row pb-4">
        {/** margin-bottom: 24px to match the gutter-size of bootstrap */}
        <div className="SubscriptionSummaryContainer-main col-xl-8 mb-4">
          {main}
        </div>
        <div className="SubscriptionSummaryContainer-sidebar col-xl-4">
          {sidebar}
        </div>
      </div>
    </div>
  );
}
