const chatSupport = {
  chat: 'Chat',
  waitingText: 'Vennligst vent mens vi finner en ledig kundekonsulent.',
  automatedChat: {
    heyUser: (name: string) =>
      `Hei ${name}! Du får straks snakke med en kundekonsulent.`,
    heyNoUser: 'Hei! Du får straks snakke med en kundekonsulent.',
    waitingMessage:
      'Mens vi venter, kan du fortelle meg hva henvendelsen gjelder? Det gjør det enklere for oss å hjelpe deg.',
  },
  confirmationModal: {
    header: (name: string) => `Avslutt samtale med ${name}`,
    message:
      'Når du avslutter de aktive samtalene dine vil du ikke kunne gå tilbake til dem igjen.',
    cancel: 'Nei, gå tilbake',
    confirm: 'Ja, avslutt samtaler',
  },
  chatSelector: {
    mainText:
      'Vi er her for å sørge for at du får en god kundeopplevelse. Hva kan vi hjelpe deg med?',
    conversation: 'Du er i en samtale med',
    stopBtn: 'Avslutt aktive samtaler',
    chatCategories: {
      mobCustomerService: 'Mobil Kundeservice',
      customerService: 'Kundeservice',
      delivery: 'Leveranse',
      serviceDesk: 'Service Desk',
    },
  },
};

export default chatSupport;
