import * as React from 'react';
import { formatNordic } from 'src/lib/utils';
import { ListResultCard, Badge } from 'src/lib/ui';
import { Invoice } from 'src/lib/types';
import { t } from 'src/lib/i18n';

export const SearchListInvoiceResult: React.FC<{ entry: Invoice }> = ({
  entry,
}) => (
  <ListResultCard
    linkTo={entry}
    className="ListResultCard--Invoice"
    icon="document"
    title={`${t.search.SearchListInvoiceResult.titlePrefix} ${entry.id}`}
    badge={<Badge>{entry.status}</Badge>}
    attributes={[
      {
        label: t.search.SearchListInvoiceResult.organisation,
        value: entry.organisation ? entry.organisation.name : '',
      },
      {
        label: t.search.SearchListInvoiceResult.invoiceDate,
        value: entry.date,
      },
      {
        label: t.search.SearchListInvoiceResult.dueDate,
        value: entry.dueDate,
      },
      {
        label: t.search.SearchListInvoiceResult.amountExclVat,
        value: formatNordic(entry.amountNet, 2),
      },
    ]}
  />
);
