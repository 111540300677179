import React, { useState, useCallback } from 'react';
import cn from 'classnames';
import { SearchInput, Button, TableSelectionProps, Checkbox } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import './FilterSection.scss';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import { DataViewType } from 'src/lib/types';
import { debounce } from 'lodash';
import { FilterSectionFilterContainer } from 'src/lib/ui/FilterSectionFilters/FilterSectionFilterContainer';
import { ToggeFiltersButton } from 'src/lib/ui/FilterSectionFilters/ToggleFiltersButton';
import { OptionSelectProps } from 'src/lib/ui/OptionSelect';

type Props = {
  totalSearchResults?: number;
  totalFiltersActive?: number;
  filterToggling?: boolean;
  actions?: React.ReactNode;
  className?: string;
  // Will default to true
  container?: boolean;
  filterOptions?: (OptionSelectProps & {
    alignRight?: boolean;
  })[];

  /**
   * Support selectable table/list
   * Add checkbox for select all when this props is defined
   */
  selectProps?: TableSelectionProps;
} & (
  | { currentView: DataViewType; setCurrentView: (view: DataViewType) => void }
  | {}
) &
  (
    | {
        searchValue: string;
        onSearch: (value: string) => void;
        onResetSearch: () => void;
      }
    | {}
  );

const FilterSection: React.FC<Props> = props => {
  const container = props.container !== false ? true : props.container;
  const [filterSectionOpen, toggleFilterSectionOpen] = useState<boolean>(false);
  const [searchInput, setSearchInput] = useState<string>(
    'searchValue' in props ? props.searchValue : ''
  );
  const debouncedSearch = useCallback(
    debounce((value: string) => {
      if ('searchValue' in props) {
        props.onSearch(value);
      }
    }, 300),
    ['searchValue' in props ? props.onSearch : undefined]
  );

  const inputOnChangeHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    setSearchInput(e.target.value);
    debouncedSearch(e.target.value);
  };

  const onInputClose = () => {
    if ('searchValue' in props) {
      setSearchInput('');
      props.onResetSearch();
    }
  };

  const i18n = t.layout.FilterSection;
  const setPlaceHolder = currentView => {
    switch (currentView) {
      case 'list':
        return i18n.searchInputListPlaceholder;
      case 'table':
        return i18n.searchInputTablePlaceholder;
      default:
        return i18n.searchInputPlaceholder;
    }
  };

  const breakpointMd = useBreakpoint('md');
  const hasPartiallySelectedSubscriptions =
    !props.selectProps?.allSelected && !!props.selectProps?.selected.length;
  return (
    <div
      className={cn(
        'FilterSection',
        props.className,
        container ? 'container' : undefined
      )}
    >
      <div className="FilterSection-mainSection">
        {props.selectProps ? (
          <div className="pl-3 mr-0">
            {/**
             * TODO set partial when a row is selected
             * 16.04 waiting for checkbox changes to be merged
             */}
            <Checkbox
              checked={props.selectProps?.allSelected}
              indeterminate={hasPartiallySelectedSubscriptions}
              onChange={() =>
                hasPartiallySelectedSubscriptions
                  ? props.selectProps?.setSelected([])
                  : props.selectProps?.onSelectAll()
              }
            />
          </div>
        ) : null}
        {'searchValue' in props &&
          props.searchValue !== undefined &&
          props.onSearch &&
          props.onResetSearch && (
            <SearchInput
              value={searchInput}
              onChange={inputOnChangeHandler}
              onClose={onInputClose}
              placeholder={setPlaceHolder(
                'currentView' in props ? props.currentView : undefined
              )}
              narrow={true}
            />
          )}

        {(props.filterToggling || !breakpointMd) &&
          (props.children || props.filterOptions) && (
            <div>
              <ToggeFiltersButton
                isOpen={filterSectionOpen}
                toggle={() => toggleFilterSectionOpen(!filterSectionOpen)}
                numberOfActiveFilters={props.totalFiltersActive ?? 0}
              />
            </div>
          )}
        {/* Only move to right if searchField is present */}
        <div
          className={cn(
            'searchValue' in props &&
              props.searchValue !== undefined &&
              props.onSearch &&
              props.onResetSearch
              ? 'ml-auto'
              : undefined,
            'FilterSection-actions'
          )}
        >
          {props.actions}
          {'setCurrentView' in props && props.setCurrentView && breakpointMd && (
            <Button
              icon={props.currentView === 'list' ? 'table-view' : 'list-view'}
              color="dark"
              size="small"
              transparent={true}
              onClick={() =>
                props.setCurrentView(
                  props.currentView === 'list' ? 'table' : 'list'
                )
              }
            >
              {props.currentView === 'list' ? i18n.showTable : i18n.showList}
            </Button>
          )}
        </div>
      </div>
      {props.filterOptions?.length && (
        <FilterSectionFilterContainer
          filterToggling={props.filterToggling}
          open={filterSectionOpen}
          close={() => toggleFilterSectionOpen(false)}
          totalSearchResults={props.totalSearchResults}
          filterOptions={props.filterOptions}
        />
      )}
    </div>
  );
};

export default FilterSection;
