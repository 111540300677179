import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';

import { CostCenter } from 'src/lib/types';
import { t } from 'src/lib/i18n';

export const SearchListCostCenterResult: React.FC<{ entry: CostCenter }> = ({
  entry,
}) => (
  <ListResultCard
    linkTo={entry}
    className="ListResultCard--CostCenter"
    icon="company"
    title={`${t.search.SearchListCostCenterResult.costCenter} ${entry.description}`}
    attributes={[
      {
        value: entry.description,
      },
    ]}
  />
);
