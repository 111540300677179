import React from 'react';
import { Rectangle } from 'recharts';
import { formatNordic } from 'src/lib/utils';
import { RectangleProps } from 'src/lib/charts/StepChart/StepChart';
import { colors } from 'src/lib/ui';

interface Props extends RectangleProps {
  usage: number;
  currentStep: number;
  fractionOfCurrentStep: number | undefined;
}

export const StepBar: React.SFC<Props> = props => {
  const colorUsed = colors.corePurple;
  const colorUnused = colors.grey;
  const usage = formatNordic(props.usage / 1024, 2);
  // Handle current step
  if (props.index === props.currentStep) {
    /**
     * According to stepsMeta.ts, "fractionOfCurrentStep" is undefined when there is no steps left.
     * We let this default to 0 if its null/undefined so the calculations does not fail.
     */
    // Gradient
    const fractionOfCurrentStep = props.fractionOfCurrentStep ?? 0;
    const gradientOffset = fractionOfCurrentStep * 100;
    const gradientId = `CurrentStepGradient-${gradientOffset}`;
    // ReferenceLine
    const lineOffset = props.x + props.width * fractionOfCurrentStep;
    const y1 = props.y + props.height;
    const y2 = props.y - 20 < 100 ? props.y - 20 : 100;

    // Label
    const labelColor = colors.corePurple;
    const labelHeight = 30;
    const labelWidth = `${usage}`.length > 4 ? 80 : 75;
    const labelText = `${usage} GB`;
    const labelY = y2 - labelHeight * 1.5;
    const labelX = lineOffset - labelWidth / 2;
    const polyPoints = `
    ${labelX + labelWidth / 2 - 10 / 2} ${labelY + labelHeight},
    ${labelX + labelWidth / 2 - 10 / 2 + 5} ${labelY + labelHeight + 10},
    ${labelX + labelWidth / 2 - 10 / 2 + 10} ${labelY + labelHeight}`;

    return (
      <svg xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient id={gradientId} x2="100%" y2="0%">
            <stop offset={`${gradientOffset}%`} stopColor={colorUsed} />
            <stop offset={`${gradientOffset}%`} stopColor={colorUnused} />
          </linearGradient>
        </defs>
        <Rectangle {...{ ...props, fill: `url(#${gradientId})` }} />
        <line
          x1={lineOffset}
          x2={lineOffset}
          y1={y1}
          y2={y2}
          strokeDasharray="4 3"
          stroke={colors.grey}
        />
        <g>
          <rect
            x={labelX}
            y={labelY}
            width={labelWidth}
            height={labelHeight}
            fill={labelColor}
            rx={2}
            ry={2}
          />
          <polygon points={polyPoints} fill={labelColor} />
          <text
            textAnchor="middle"
            x={labelX + labelWidth / 2}
            y={labelY + labelHeight / 2 + 5}
            fill="#fff"
          >
            {labelText}
          </text>
        </g>
      </svg>
    );
  }

  // Handle succeeding steps
  if (props.index > props.currentStep) {
    return <Rectangle {...{ ...props, fill: colorUnused }} />;
  }

  // Handle preceding/full steps
  return <Rectangle {...props} />;
};
