import React from 'react';
import './NoResult.scss';

interface NoResultProps {
  text?: string;
}

export const NoResult: React.FC<NoResultProps> = ({ text, children }) => {
  return (
    <div className="NoResult">
      <div className="NoResult-body">
        {text} <div>{children}</div>
      </div>
    </div>
  );
};
