export interface GatewayError {
  statusCode: number;
  statusMsg: string;
}

export const FILE_TOO_LARGE_ERROR = 'Opplasting feilet. Filen er for stor.';
export const DEFAULT_ERROR = 'Opplasting feilet.';

export function mapStatusToDocumentError(status: number): GatewayError {
  switch (status) {
    case 413:
      return { statusCode: 413, statusMsg: FILE_TOO_LARGE_ERROR };
    default:
      return { statusCode: status, statusMsg: DEFAULT_ERROR };
  }
}
