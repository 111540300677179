import { Locale } from 'src/lib/i18n';
import { format, parse, lastDayOfMonth } from 'date-fns';
import { union, orderBy, map } from 'lodash';
import { Period, DateString } from '../types';
import nbLocale from 'date-fns/locale/nb';
import moment from 'moment';

// const monthsLong = {
//   '01': 'januar',
//   '02': 'februar',
//   '03': 'mars',
//   '04': 'april',
//   '05': 'mai',
//   '06': 'juni',
//   '07': 'juli',
//   '08': 'august',
//   '09': 'september',
//   '10': 'oktober',
//   '11': 'november',
//   '12': 'desember',
// };

// const monthsShort = {
//   '01': 'jan',
//   '02': 'feb',
//   '03': 'mar',
//   '04': 'apr',
//   '05': 'mai',
//   '06': 'jun',
//   '07': 'jul',
//   '08': 'aug',
//   '09': 'sep',
//   '10': 'okt',
//   '11': 'nov',
//   '12': 'des',
// };

export const firstDayOfPeriod: (period: Period) => Date = period =>
  parse(`${period}-01`);

export const lastDayOfPeriod: (period: Period) => Date = period =>
  lastDayOfMonth(`${period}-01`);

/**
 * Parse a given period to get its year, month and month labels.
 * @param period The period to parse.
 * @param locale
 */
export const parsePeriod = (period: Period, locale: Locale = 'nb') => {
  const periodRx = /^[0-9]{4}-(0[0-9]|1[0-2])$/;
  if (!periodRx.test(period)) {
    throw new Error(
      `Invalid period group ${period}, must be in format YYYY-MM.`
    );
  }
  const chosenLocale = locale === 'nb' ? nbLocale : undefined;
  const mIdx = period.substr(5, 2);
  return {
    year: parseInt(period.substr(0, 4), 10),
    month: parseInt(mIdx, 10),
    monthShort: format(period, 'MMM', {
      locale: chosenLocale,
    }).toLowerCase(),
    monthLong: format(period, 'MMMM', {
      locale: chosenLocale,
    }).toLowerCase(),
  };
};

/**
 * Get the default period (typically current) when no customer data is available.
 */
export const getDefaultPeriod = (): Period => {
  const now = new Date();
  const year = now.getFullYear();
  const month = now.getMonth();
  return `${year}-${month < 10 ? '0' : ''}${month}`;
};

export const getPastMonthPeriod = (numMonths: number): Period => {
  const date = new Date();
  date.setMonth(date.getMonth() - numMonths);
  const year = date.getFullYear();
  const month = date.getMonth();
  return `${year}-${month < 10 ? '0' : ''}${month}`;
};

export const formatPeriod = (period: Period, locale: Locale) => {
  const p = parsePeriod(period, locale);
  return `${p.monthLong} ${p.year}`;
};

export const getPeriodOptions = (
  periods: {
    invoicePeriods: Period[];
    usagePeriods: Period[];
  },
  locale: Locale
) =>
  map(
    orderBy(
      union(periods.invoicePeriods, periods.usagePeriods),
      undefined,
      'desc'
    ),
    p => ({
      value: p,
      label: formatPeriod(p, locale),
    })
  );

export const getFirstDayOfPeriod = (period: Period) => `${period}-01`;

export const getLastDayOfPeriod = (period: Period) =>
  format(lastDayOfPeriod(period), 'YYYY-MM-DD');

/**
 * Calculates the number of days between two dates.
 * @param start The start date.
 * @param end The end date.
 */
export const dateDiffInDays = (start: Date, end: Date) => {
  // Discard the time and time-zone information.
  const utc1 = Date.UTC(start.getFullYear(), start.getMonth(), start.getDate());
  const utc2 = Date.UTC(end.getFullYear(), end.getMonth(), end.getDate());
  // Divide the difference by the number of MS in a day.
  return Math.floor((utc2 - utc1) / (1000 * 60 * 60 * 24));
};

/**
 * Calculates the number of days, hours, minutes and seconds between two dates.
 * @param start The start date.
 * @param end The end date.
 */
export const dateDiffDuration = (start: Date, end: Date) => {
  // Discard the time and time-zone information.
  const utc1 = start.getTime();
  const utc2 = end.getTime();
  const diffInMilli = Math.abs(Math.floor(utc2 - utc1));
  return getDurationFromMilliseconds(diffInMilli);
};

export type FormatType =
  | 'short'
  | 'medium'
  | 'long'
  | 'shortWithTime'
  | 'mediumWithTime'
  | 'longWithTime'
  | 'dateWithMonth'
  | 'monthWithYear'
  | 'month'
  | 'day';
export const formatDate = (
  date: Date | DateString,
  formatType: FormatType,
  lang: Locale = 'nb'
) => {
  const d = typeof date === 'string' ? parse(date) : date;

  if (lang === 'nb') {
    if (formatType === 'short') {
      return format(d, 'DD.MM.YY', { locale: nbLocale });
    } else if (formatType === 'medium') {
      return format(d, 'D. MMM YYYY', { locale: nbLocale });
    } else if (formatType === 'long') {
      return format(d, 'D. MMMM YYYY', { locale: nbLocale });
    } else if (formatType === 'shortWithTime') {
      return format(d, 'DD.MM.YY - HH:mm', { locale: nbLocale });
    } else if (formatType === 'mediumWithTime') {
      return format(d, 'D. MMM YYYY - HH:mm', { locale: nbLocale });
    } else if (formatType === 'longWithTime') {
      return format(d, 'D. MMMM YYYY - [kl.] HH:mm', { locale: nbLocale });
    } else if (formatType === 'dateWithMonth') {
      return format(d, 'D. MMM', { locale: nbLocale });
    } else if (formatType === 'monthWithYear') {
      return format(d, 'MMM YYYY', { locale: nbLocale });
    } else if (formatType === 'month') {
      return format(d, 'MMM', { locale: nbLocale });
    } else if (formatType === 'day') {
      return format(d, 'dddd DD. MMM', { locale: nbLocale });
    }
  } else if (lang === 'en') {
    if (formatType === 'short') {
      return format(d, 'D MMM YYYY');
    } else if (formatType === 'medium') {
      return format(d, 'D MMM YYYY');
    } else if (formatType === 'long') {
      return format(d, 'D MMMM YYYY');
    } else if (formatType === 'shortWithTime') {
      return format(d, 'D MMM YYYY - HH:mm');
    } else if (formatType === 'mediumWithTime') {
      return format(d, 'D MMM YYYY - HH:mm');
    } else if (formatType === 'longWithTime') {
      return format(d, 'D MMMM YYYY - HH:mm');
    } else if (formatType === 'dateWithMonth') {
      return format(d, 'D. MMM');
    } else if (formatType === 'monthWithYear') {
      return format(d, 'MMM YYYY', { locale: nbLocale });
    } else if (formatType === 'month') {
      return format(d, 'MMM', { locale: nbLocale });
    } else if (formatType === 'day') {
      return format(d, 'dddd DD. MMM');
    }
  }
  throw new Error(
    `Invalid parameters to formatDate (lang=${lang}, formatType=${formatType})`
  );
};

export const formatDateInterval = (
  from: Date | string,
  to: Date | string,
  lang: Locale = 'nb'
) => {
  let start = formatDate(from, 'medium', lang);
  let end = formatDate(to, 'medium', lang);
  return `${start}—${end}`;
};

/**
 * For reference:
 *
 * &ndash;  –
 * &mdash;  —
 *
 */

/**
 * Date util functions for the ordering process.
 * Moment is used here because components has dependency to Moment.js
 */

/**
 * Returns DateString in format YYYY-MM-DD (equal GraphQL Date)
 * @param dateString String in DD.MM.YYYY format used in ordering.
 */
export const formatOrderingDateToDateString = (dateString: string) =>
  moment(dateString, 'DD.MM.YYYY').format('YYYY-MM-DD');

/**
 * Return a boolean if the day is before today. Today is also excluded.
 * @param daysAfterToday How many days after today to perform check
 * @param startDate
 */
export const isBefore = (
  daysAfterToday: number,
  startDate?: Date | DateString
) => (day: moment.Moment) => {
  // startOf set time to 00:00:00.
  // We want to exclude "today", therefore add a extra day to exclude the correct dates,
  // Example: If todays date is 1.jan, and we pass "daysAfterToday = 2" (exclude all dates before 3.jan, including 3. jan). We will add an extra day to check if incoming date is before 4.jan.

  const momentObject = startDate ? moment(startDate) : moment();
  return day.isBefore(
    momentObject.add(daysAfterToday + 1, 'days').startOf('day')
  );
};

export const getDurationFromMilliseconds = (ms: number) => {
  const days = Math.floor(ms / (24 * 60 * 60 * 1000));
  const daysms = ms % (24 * 60 * 60 * 1000);
  const hours = Math.floor(daysms / (60 * 60 * 1000));
  const hoursms = ms % (60 * 60 * 1000);
  const minutes = Math.floor(hoursms / (60 * 1000));
  const minutesms = ms % (60 * 1000);
  const sec = Math.floor(minutesms / 1000);

  return {
    days,
    hours,
    minutes,
    sec,
  };
};

export const convertDMYtoYMD = (input: string) => {
  if (/^[0-9]{2}\.[0-9]{2}\.[0-9]{4}$/.test(input)) {
    return input.slice(6) + '-' + input.slice(3, 5) + '-' + input.slice(0, 2);
  }
  return input;
};

export const getEndOfMonthMonthsAway = (
  startTime: Date,
  monthsFromNow: number
) => {
  /**
   * day in Date() should normally be in the range 1-31.
   * day=0 will give the last day of the months
   **/
  const end = new Date(
    startTime.getFullYear(),
    startTime.getMonth() + 1 + monthsFromNow,
    0
  );
  return moment(end).format('YYYY-MM-DD HH:mm:ss');
};
