import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { PlannedWorkFragment } from 'src/lib/types/subscription/PlannedWorkFragment';
import { IncidentFragment } from 'src/lib/types';
import { SearchFilter, SortOrder } from 'src/__types/graphql-global-types';
import * as t from './__types/useSearchCases';

export const QUERY_CASES = gql`
  query useSearchCases($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        totalResults
        results {
          ... on PlannedWorkSearchResult {
            plannedWork {
              ...PlannedWorkSearchResult
            }
          }
          ... on IncidentSearchResult {
            incident {
              ...IncidentSearchResult
            }
          }
        }
      }
    }
  }
  ${PlannedWorkFragment}
  ${IncidentFragment}
`;

export const useSearchCases = (search: {
  query?: string;
  size?: number;
  page?: number;
  subscriptionId?: string;
  filters?: SearchFilter[];
  sortOrder?: SortOrder;
  sortBy?: string;
}) => {
  const customer = useCustomer();
  const { data, loading, error } = useQuery<
    t.useSearchCases,
    t.useSearchCasesVariables
  >(QUERY_CASES, {
    variables: {
      customerId: customer.id,
      search: {
        types: ['cases'],
        size: search.size ?? 20,
        query: search.query ?? '',
        filters: search.filters,
        sortOrder: search.sortOrder ?? SortOrder.desc,
        sortBy: search.sortBy ?? 'createdAt',
        page: search.page ?? 1,
      },
    },
  });
  return {
    cases: data?.customer?.search?.results,
    totalResults: data?.customer?.search?.totalResults,
    loading,
    error,
  };
};
