import React from 'react';
import { colors, Badges, Badge, TableBodyCell } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const TagsTableCell = (props: {
  subscription?: null | {
    id: string;
    tags?: null | Array<{ tag: string }>;
  };
}) => {
  if (props.subscription?.tags) {
    const displayTags = props.subscription?.tags.slice(
      0,
      Math.min(3, props.subscription?.tags?.length)
    );
    const remainingTags = props.subscription?.tags.length - displayTags?.length;

    return (
      <TableBodyCell>
        <Badges inline={true}>
          {displayTags?.map(tag => (
            <Badge key={tag.tag}>{tag.tag} </Badge>
          ))}
        </Badges>
        {remainingTags ? (
          <small
            className="d-block py-1"
            style={{ color: colors.greyDarkText }}
          >
            + {remainingTags}{' '}
            {remainingTags === 1
              ? t.subscriptions.tags.tag
              : t.subscriptions.tags.tags}
          </small>
        ) : null}
      </TableBodyCell>
    );
  }
  return <TableBodyCell />;
};
