const history = {
  history: 'History',
  total: 'Total cost',
  product: 'Product',
  volume: 'Quantity',
  subtotal: 'Subtotal',
  showMore: 'Show more',
  showLess: 'Show less',
  nationalDataVolume: 'Data usage inland',
  roaming: 'Data roaming',
  abroadVolume: 'Call minutes abroad',
  nationalCallVolume: 'Call minutes inland',
  specialNumberVolume: 'Call minutes spescial number',
  smsVolume: 'Sent SMS',
  mmsVolume: 'Sent MMS',
};

export default history;
