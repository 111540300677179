import React, { useState } from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { WithCustomerContext } from 'src/lib/global';

import { UnitOrderDetails } from '../types';

const MUTATION_RESERVE_NUMBER_SERIES_ORDER = gql`
  mutation reserveNumberSeriesOrder($input: ReserveNumberSeriesOrderInput) {
    reserveNumberSeriesOrder(input: $input) {
      orderDetails {
        postalArea
        postalCode
        quantity
        status
        attention
        address
        orderId
      }
    }
  }
`;

export interface ReserveNumberSeriesOrderInput {
  customerId: string;
  quantity: number;
}

interface Variables {
  input: ReserveNumberSeriesOrderInput;
}
interface Data {
  reserveNumberSeriesOrder: UnitOrderDetails;
}

interface Props {
  children: (res: ReserveNumberSeriesOrderResult) => JSX.Element | null;
}

export interface ReserveNumberSeriesOrderResult {
  reserveNumberSeriesOrder: (input: { quantity: number }) => void;
  loading: boolean;
  error?: ApolloError;
  data?: UnitOrderDetails;
  reset?: () => void;
}

const WithReserveNumberSeriesOrderMutation = (props: Props) => {
  const [data, setData] = useState<UnitOrderDetails | undefined>(undefined);
  return (
    <WithCustomerContext>
      {cContext => (
        <Mutation<Data, Variables>
          mutation={MUTATION_RESERVE_NUMBER_SERIES_ORDER}
        >
          {(mutation, mutationResult) => {
            const portMobileSubscriptionOrderContext: ReserveNumberSeriesOrderResult = {
              reserveNumberSeriesOrder: async input => {
                const res = await mutation({
                  variables: { input: { customerId: cContext.id, ...input } },
                });
                setData(
                  res.data && res.data.reserveNumberSeriesOrder
                    ? res.data.reserveNumberSeriesOrder
                    : undefined
                );
              },
              loading: mutationResult.loading,
              error: mutationResult.error,
              data,
              reset: () => setData(undefined),
            };
            return props.children(portMobileSubscriptionOrderContext);
          }}
        </Mutation>
      )}
    </WithCustomerContext>
  );
};

export default WithReserveNumberSeriesOrderMutation;
