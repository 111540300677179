import React from 'react';

import './ChatStatusSeparator.scss';
import {
  CommonActivityProps,
  StatusChangeActivity,
  FieldChangeActivity,
} from 'src/lib/chat/types';
import { t, useI18nContext } from 'src/lib/i18n';
import { formatDate } from 'src/lib/utils';
import { Icon } from '@telia/styleguide';

interface Props {
  activity: CommonActivityProps & (StatusChangeActivity | FieldChangeActivity);
}

export function ChatFieldChangeSeparator(props: Props) {
  const i18n = useI18nContext();
  const isSolved =
    props.activity.type === 'statusChange'
      ? props.activity.status === 'Løst' || props.activity.status === 'Solved'
      : false;
  const isClosed =
    props.activity.type === 'statusChange'
      ? props.activity.status === 'Lukket' || props.activity.status === 'Closed'
      : false;
  return (
    <div className="ChatStatusSeparator">
      <div className="ChatStatusSeparator-border" />
      <div className="pr-4 pl-2">
        <div className="d-flex align-items-center">
          {props.activity.type === 'statusChange' ? (
            <div className="d-flex flex-column flex-sm-row mr-3">
              <Icon
                className="ChatStatusSeparator-icon"
                icon={
                  isSolved
                    ? 'check-mark'
                    : isClosed
                    ? 'check-mark-circle'
                    : 'time-waiting'
                }
                style={{ marginRight: '22px' }}
              />
              <div className="ChatStatusSeparator-title pr-2">
                {props.activity.status}
              </div>
              <div>
                {formatDate(
                  props.activity.timestamp || new Date(),
                  'mediumWithTime',
                  i18n.locale
                )}
              </div>
            </div>
          ) : (
            <span>
              {props.activity.fieldName} {t.incidents.ChatMessage.setTo}{' '}
              <b>{props.activity.value}</b>
            </span>
          )}
        </div>
      </div>
      <div className="ChatStatusSeparator-border" />
    </div>
  );
}
