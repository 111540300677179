import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { ApolloError } from 'apollo-client';
import { DirectoryDetails } from 'src/areas/mobile/types';

export const SUBSCRIPTION_DIRECTORY_DETAILS = gql`
  query useSubscriptionDirectoryDetails($id: ID!) {
    subscription(id: $id) {
      id
      directory {
        firstName
        middleName
        lastName
        streetAddress
        postCode
        postArea
        country
        visibility
      }
    }
  }
`;

interface Data {
  subscription: {
    id: string;
    directory: DirectoryDetails;
  };
}

interface Variables {
  id: string;
}

export interface DirectoryDetailsResult {
  loading: boolean;
  error?: ApolloError;
  directory?: Data;
}
export const useSubscriptionDirectoryDetails = () => {
  const subscription = useSubscriptionContext();
  const { data, loading, error } = useQuery<Data, Variables>(
    SUBSCRIPTION_DIRECTORY_DETAILS,
    {
      variables: {
        id: subscription.id,
      },
    }
  );
  const result = {
    error,
    loading,
    directory:
      data && data.subscription ? data.subscription.directory : undefined,
  };
  return result;
};
