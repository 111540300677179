import * as React from 'react';
import {
  Step,
  StepShape,
  FormikTextField,
  FormikDatePicker,
} from 'src/lib/flow';
import * as yup from 'yup';
import { Attribute } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

import OrderingSection from '../layout/OrderingSection';
import { yupValidateDateAndIsBeforeTomorrow } from 'src/lib/utils/yupValidations';
import { Step1Values } from './Step1';

export interface Step2Values {
  firstName?: string;
  lastName?: string;
  birthDate?: string;
  email?: string;
  employeeNumber?: string;
  jobTitle?: string;
}

const Step2: Step<Step2Values & Step1Values> = (): StepShape<Step2Values &
  Step1Values> => {
  const i18n = t.ordering.OrderMobileSubscription.Step2;
  return {
    title: i18n.title,
    validationSchema: yup.object().shape({
      birthDate: yup.string().when(['phoneNumberSource', 'formerOwnerSource'], {
        is: (phoneNumberSource, formerOwnerSource) =>
          phoneNumberSource === 'existingNumber' &&
          formerOwnerSource === 'self',
        then: yupValidateDateAndIsBeforeTomorrow(),
      }),
      firstName: yup.string().required(i18n.firstName.error),
      lastName: yup.string().required(i18n.lastName.error),
      email: yup
        .string()
        .required(i18n.email.error)
        .email(i18n.email.error),
      employeeNumber: yup.string(),
      jobTitle: yup.string(),
      // localNumber: yup.string(),
      // address: yup.string().required('Obligatorisk felt.'),
      // postalCode: yup.string().required('Obligatorisk felt.'),
      // postalArea: yup.string().required('Obligatorisk felt.'),
    }),
    renderSummary: ({ values }) => {
      return (
        <>
          <Attribute label={i18n.firstName.label} value={values.firstName} />
          <Attribute label={i18n.lastName.label} value={values.lastName} />
          {values.birthDate && (
            <Attribute label={i18n.birthDate.label} value={values.birthDate} />
          )}
          <Attribute label={i18n.email.label} value={values.email} />
          {values.employeeNumber && values.employeeNumber !== '' && (
            <Attribute
              label={i18n.employeeNumber.label}
              value={values.employeeNumber}
            />
          )}
          {values.jobTitle && values.jobTitle !== '' && (
            <Attribute label={i18n.jobTitle.label} value={values.jobTitle} />
          )}
        </>
      );
    },
    renderForm: ({ flow }) => (
      <OrderingSection>
        <div className="row pb-4">
          <FormikTextField
            id="firstName"
            label={i18n.firstName.label}
            required={true}
            className="col-md-6"
          />
          <FormikTextField
            id="lastName"
            label={i18n.lastName.label}
            required={true}
            className="col-md-6"
          />
        </div>
        {flow.values.phoneNumberSource === 'existingNumber' &&
          flow.values.formerOwnerSource === 'self' && (
            <FormikDatePicker
              id="birthDate"
              label={i18n.birthDate.label}
              required={true}
              helpText={i18n.birthDate.helpText}
              className="pb-4"
            />
          )}
        <div className="row">
          <FormikTextField
            id="email"
            label={i18n.email.label}
            required={true}
            /**
             * 23.10.2019: Removed for now. If not needed, remove from i18n as well.
             */
            // helpText={i18n.email.helpText}
            className="col-md-6"
          />
          <FormikTextField
            id="employeeNumber"
            label={i18n.employeeNumber.label}
            className="col-md-6"
          />
        </div>

        {<FormikTextField id="jobTitle" label="Stilling" className="mb-2" />}
        {/* <FormikTextField id="localNumber" label="Lokalnummer" className="mb-2" /> */}
      </OrderingSection>
    ),
  };
};

export default Step2;
