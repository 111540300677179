import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import * as types from './__types/useCreateTag';
import { useCustomer } from 'src/lib/global';

const CREATE_TAG = gql`
  mutation useCreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      tag {
        id
        tag
        description
      }
      error {
        code
        message
      }
    }
  }
`;

export const useCreateTag = () => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    types.useCreateTag,
    types.useCreateTagVariables
  >(CREATE_TAG);
  const createTag = (tag: string, description?: string | null) => {
    if (tag.length) {
      mutation({
        variables: {
          input: {
            customerId: customer.id,
            tag,
            description,
          },
        },
        optimisticResponse: {
          createTag: {
            __typename: 'CreateTagPayload',
            tag: {
              __typename: 'Tag',
              id: '',
              description: null,
              tag,
            },
            error: null,
          },
        },
      });
    }
  };
  return {
    createTag: createTag,
    data,
    loading,
    error,
  };
};
