import React, { useMemo } from 'react';
import sortBy from 'lodash/sortBy';

import { useCustomer } from 'src/lib/global';
import { t } from 'src/lib/i18n';
import { ButtonProps, Button } from 'src/lib/ui';
import { useCustomerSpecificTagPreferences } from './useCustomerSpecificTagPreferences';
import useGetTagOptions from 'src/areas/main/subscriptions/utils/useGetTagOptions';
import { urlFor } from 'src/lib/ui';
import { PersonalizedDashboardGrid } from './PersonalizedDashboardGrid';
import PersonalizedDashboardFooter from './PersonalizedDashboardFooter';
import { PersonalizedPlannedWork } from './PersonalizedPlannedWork';
import { SelectTagsDropdown } from './SelectTagsDropdown';

import './PersonalizedDashboard.scss';
import { Icon } from '@telia/styleguide';
import { useDownloadLink, useFeature } from 'src/lib/utils';

/**
 * Need to put this in a separate component because
 * DropdownToggle won't pass through the necessary props.
 */
function TriggerButton(props: ButtonProps) {
  return <Button icon="filter" color="white" transparent={true} {...props} />;
}

export function PersonalizedDashboard() {
  const customer = useCustomer();
  const tags = useGetTagOptions('');
  const showBanner = useFeature('show-banner');

  const downloadLink = useDownloadLink();
  const downloadPlannedWorkReportsLink =
    '/api/files/report/customer/plannedWork?';
  const formParameters = {
    type: 'plannedWork',
  };

  /**
   * Load the selected filter tags from
   * user preferences.
   */
  const [selectedTags, setSelectedTags] = useCustomerSpecificTagPreferences();

  /**
   * Resolve the tag names
   */
  const resolvedTags = useMemo(() => {
    const unsorted = selectedTags.map(selectedValue => {
      const foundTag = tags.result.find(x => x.value === selectedValue);

      /**
       * If we _do_ have the tag definitions (length>0), but the
       * tag was _not_ found, we assume the tag is deleted or that
       * the user no longer has access. We'll delete it from the currently
       * selected filters. We might also be loading tags for a new customer,
       * so we also confirm it's not currently loading.
       */
      if (!foundTag && tags.result.length && !tags.loading) {
        setSelectedTags(selectedTags.filter(x => x !== selectedValue));
        return undefined;
      }
      return foundTag;
    });
    return sortBy(unsorted, x => x?.label);
  }, [tags, selectedTags, setSelectedTags]);

  return (
    <div className="PersonalizedDashboard">
      <div className="container">
        {showBanner && (
          <div
            style={{
              backgroundColor: '#990ae3',
              padding: '0.8rem',
              color: 'white',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '6px',
            }}
          >
            <Icon
              icon="alert"
              className="mr-3"
              style={{ width: '40px', height: '40px' }}
            />
            <div>{t.dashboard.bannerText.message}</div>
          </div>
        )}
        <div className="PersonalizedDashboard-header">
          <div className="PersonalizedDashboard-header-one">
            <h1 className="PersonalizedDashboard-h1">
              {t.dashboard.new.heading}
            </h1>

            <div className="PersonalizedDashboard-user-and-customer">
              {customer.name}
              <span className="PersonalizedDashboard-heading-dot">•</span>
              {customer.auth.user?.name}
            </div>

            {selectedTags.length ? (
              <div>
                <h2 className="PersonalizedDashboard-h3">
                  {t.dashboard.new.activeFilters}
                </h2>

                {resolvedTags.map(resolvedTag =>
                  resolvedTag ? (
                    <Button
                      size="xs"
                      to={urlFor({
                        area: 'subscriptions',
                        filterByTag: resolvedTag.value,
                      })}
                      rounded={true}
                      key={resolvedTag.value}
                      className="mr-1 mb-1"
                      color="grey"
                    >
                      {resolvedTag.label}
                    </Button>
                  ) : null
                )}
              </div>
            ) : null}
          </div>
          <div className="PersonalizedDashboard-header-two">
            {tags.result?.length ? (
              <SelectTagsDropdown
                selectedTags={selectedTags}
                setSelectedTags={setSelectedTags}
                trigger={TriggerButton}
                buttonLabel={t.dashboard.new.filterButtonLabel(
                  selectedTags.length
                )}
              />
            ) : null}
          </div>
        </div>

        <h2 className="PersonalizedDashboard-h2">{t.dashboard.new.status}</h2>

        <PersonalizedDashboardGrid tags={selectedTags} />

        <div className="PersonalizedPlannedWork-container">
          <div className="PersonalizedPlannedWork-header">
            <h2 className="PersonalizedDashboard-h2">
              {t.dashboard.plannedWork.plannedWork}
            </h2>
            <Button
              icon="download"
              color="dark"
              transparent={true}
              size="small"
              onClick={() =>
                downloadLink(downloadPlannedWorkReportsLink, formParameters)
              }
              className="mb-4"
            >
              {t.dashboard.plannedWork.download}
            </Button>
          </div>

          <PersonalizedPlannedWork />
        </div>

        <PersonalizedDashboardFooter />
      </div>
    </div>
  );
}
