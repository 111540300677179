export const formatPhoneNumber = (number: string) => {
  const nospace = number.replace(/\s/g, '');

  // 8-digit phone number, possibly prefixed by +47 or 00
  const m = nospace.match(/^(\+47|0047)?([0-9]{8})$/);
  if (m) {
    // retain the +47/00.
    const parts = m[1] ? [m[1]] : [];
    const n = m[2];
    // group it 3-2-3 if mobile (begins with 4 or 9), otherwise 2-2-2-2
    if (n[0] === '4' || n[0] === '9') {
      parts.push(n.substr(0, 3));
      parts.push(n.substr(3, 2));
      parts.push(n.substr(5));
    } else {
      parts.push(n.substr(0, 2));
      parts.push(n.substr(2, 2));
      parts.push(n.substr(4, 2));
      parts.push(n.substr(6));
    }
    return parts.join(' ');
  }
  return number;
};
