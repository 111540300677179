import React, { useState } from 'react';
import {
  DragDropContext,
  Draggable,
  Droppable,
  DropResult,
} from 'react-beautiful-dnd';
import { map } from 'lodash';
import { Contact } from 'src/lib/types';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { UpdateTechnicalContactsResult } from '../../../mutations/useUpdateTechnicalContacts';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { FormSubmitBar, SearchInput } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import {
  useSubscription_customer_subscriptions_technicalContacts,
  useSubscription_customer_subscriptions_technicalContacts_contact,
} from '../../../queries/__types/useSubscription';
import TechnicalContactCard from './TechnicalContactCard';
import CloseableCard from './CloseableCard';
import {
  SearchDomain,
  SubscriptionGroup,
} from 'src/__types/graphql-global-types';
import { useSearch } from 'src/areas/main/search';
import './EditTechnicalContacts.scss';
import { trackUpdateServiceMetadata } from 'src/lib/analytics';
import { SearchMenuResult } from 'src/areas/main/search/components/menu/SearchMenuResult';

interface Props {
  updateContext: UpdateTechnicalContactsResult;
  subscriptionId: string;
  group?: SubscriptionGroup | null;
  initContacts: useSubscription_customer_subscriptions_technicalContacts[];
  toggleState: () => void;
  isOpen?: boolean;
}

const TECHNICAL_CONTACT_SIZE_LIMIT = 3;

const initializeTechnicalContacts = (
  technicalContacts: useSubscription_customer_subscriptions_technicalContacts[]
) => {
  return map(technicalContacts, (tc, index) => ({
    __typename: tc.__typename,
    priority: index + 1,
    contact: { ...tc.contact },
  }));
};

const EditTechnicalContacts = (props: Props) => {
  const [technicalContacts, setTechnicalContacts] = useState<
    useSubscription_customer_subscriptions_technicalContacts[]
  >(initializeTechnicalContacts(props.initContacts));
  const [isDragging, setIsDragging] = useState(false);
  const [showResults, setShowResults] = useState(false);
  const [query, setQuery] = useState('');
  const search = useSearch({
    query: query,
    types: [SearchDomain.contact],
  });

  const handleSave = async (
    context: UpdateTechnicalContactsResult,
    event: React.SyntheticEvent
  ) => {
    event.preventDefault();
    const success = await context.setTechnicalContactsOnSubscription(
      props.subscriptionId,
      props.initContacts.map(c => c.contact.id),
      technicalContacts
    );
    if (success) {
      props.toggleState();
    }
    if (props.group) trackUpdateServiceMetadata(props.group, 'contacts');
  };

  const handleRemove = (removeIndex: number) => {
    setTechnicalContacts(prev => {
      const updated = Array.from(prev);
      updated.splice(removeIndex, 1);
      updated.forEach((tc, index) => (tc.priority = index + 1));
      return updated;
    });
  };

  const handleAdd = (contact?: Contact) => {
    const priority = technicalContacts?.length + 1;
    if (priority > TECHNICAL_CONTACT_SIZE_LIMIT) {
      // Supports only three technical contacts on a subscription.
      return;
    }
    const technicalContact: useSubscription_customer_subscriptions_technicalContacts = {
      __typename: 'TechnicalContact',
      priority,
      contact: contact as useSubscription_customer_subscriptions_technicalContacts_contact,
    };

    setTechnicalContacts(prev => [...prev, technicalContact]);
    setShowResults(false);
  };

  const onDragStart = () => {
    setIsDragging(true);
  };

  const onDragEnd = (result: DropResult) => {
    if (!result.destination) {
      setIsDragging(false);
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;

    setTechnicalContacts(prevState => {
      const updated = prevState ?? [];
      const [removed] = updated.splice(startIndex, 1);
      updated.splice(endIndex, 0, removed);
      updated.forEach((contact, ix) => {
        contact.priority = ix + 1;
      });
      return updated;
    });
    setIsDragging(false);
  };

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleState}>
      <ModalHeader>
        {t.subscriptions.TechnicalContactsCard.editTitle}
      </ModalHeader>
      <ModalBody>
        {t.subscriptions.TechnicalContactsCard.editDescription}
        <div className="mb-3 mt-3">
          <div className="EditAccessForm position-relative mb-4">
            <SearchInput
              value={query}
              onChange={e => setQuery(e.target.value)}
              onClose={() => setQuery('')}
              narrow={true}
              onFocus={() => setShowResults(true)}
            />
            <div className="SearchMenuResult-list">
              {showResults &&
                search.results.map((contact, key) => (
                  <div
                    onClick={c => {
                      handleAdd(contact as Contact);
                    }}
                  >
                    <SearchMenuResult
                      key={key}
                      id={contact.id}
                      entry={contact}
                      disabled={
                        technicalContacts.length >= TECHNICAL_CONTACT_SIZE_LIMIT
                      }
                    />
                  </div>
                ))}
            </div>
          </div>
        </div>
        <DragDropContext onDragStart={onDragStart} onDragEnd={onDragEnd}>
          <Droppable droppableId="test">
            {(droppableProvided, droppableSnapshot) => (
              <div
                className="EditTechnicalContacts"
                ref={droppableProvided.innerRef}
                {...droppableProvided.droppableProps}
              >
                {technicalContacts.map((technicalContact, index) => (
                  <Draggable
                    draggableId={`${technicalContact.contact.id}-${technicalContact.priority}`}
                    key={`${technicalContact.contact.id}-${technicalContact.priority}`}
                    index={index}
                  >
                    {(provided, snapshot) => (
                      <div
                        className="mb-2"
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        <CloseableCard
                          className="CloseableCard"
                          closeAction={() => handleRemove(index)}
                          disabled={isDragging}
                        >
                          <TechnicalContactCard
                            contact={technicalContact.contact}
                          />
                        </CloseableCard>
                      </div>
                    )}
                  </Draggable>
                ))}
                {droppableProvided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          error={
            !!(
              props.updateContext.mutationErrorCode ||
              props.updateContext.graphqlError
            )
          }
          errorMsg={t.subscriptions.TechnicalContactsCard.editError}
          loading={props.updateContext.loading}
          cancel={props.toggleState}
          submit={e =>
            handleSave(props.updateContext, e as React.SyntheticEvent)
          }
        />
      </ModalFooter>
    </Modal>
  );
};

export default EditTechnicalContacts;
