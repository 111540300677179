import * as React from 'react';
import './Spinner.scss';
import cs from 'classnames';
export interface SpinnerProps {
  className?: string;
  style?: React.CSSProperties;
  size?: 'xs' | 'md' | 'default';
  type?: 'svg' | 'css';
}

export const Spinner: React.FC<SpinnerProps> = props => {
  const { className, style, size } = props;

  if (props.type === 'css') {
    return (
      <div className={`Spinner ${className}`} style={style}>
        <div className="Spinner-css" />
      </div>
    );
  }

  return (
    <div
      className={cs(
        `Spinner`,
        className,
        size && size !== 'default' ? `Spinner--${size}` : undefined
      )}
      style={style}
    >
      <svg className="Spinner-svg" x="0px" y="0px" viewBox="0 0 150 150">
        <circle className="Spinner-circle" cx="75" cy="75" r="60" />
      </svg>
    </div>
  );
};
