import * as React from 'react';
import { Link } from 'react-router-dom';

import UncontrolledDropdown from 'reactstrap/lib/UncontrolledDropdown';
import DropdownToggle from 'reactstrap/lib/DropdownToggle';
import DropdownMenu from 'reactstrap/lib/DropdownMenu';
import DropdownItem from 'reactstrap/lib/DropdownItem';

import { DownloadLink } from '../DownloadLink/DownloadLink';

import './ContextMenu.scss';
import { Icon, IconDefinition } from '@telia/styleguide';

interface MenuItem {
  icon?: IconDefinition;
  disabled?: boolean;
  onClick?: () => void;
}

interface Props {
  direction?: 'up' | 'left' | 'right';
  alignRight?: boolean;
}

const ContextMenu: React.SFC<Props> = props => (
  <UncontrolledDropdown
    onClick={e => e.stopPropagation()}
    className="ContextMenu"
    direction={props.direction}
  >
    <DropdownToggle tag="span">
      <Icon className="ContextMenu-icon" icon="more" />
    </DropdownToggle>
    <DropdownMenu right={props.alignRight}>{props.children}</DropdownMenu>
  </UncontrolledDropdown>
);

const ContextMenuHeader: React.SFC<MenuItem> = props => (
  <DropdownItem
    onClick={() => (props.onClick ? props.onClick() : null)}
    header={true}
    disabled={props.disabled}
  >
    {props.icon && (
      <span>
        <Icon className="ContextMenuItem-icon" icon={props.icon} />
      </span>
    )}
    {props.children}
  </DropdownItem>
);

const ContextMenuLink: React.SFC<{
  icon?: IconDefinition;
  disabled?: boolean;
  to?: string;
}> = props => (
  <DropdownItem tag={Link} to={props.to} className="btn btn-default">
    {props.icon && (
      <span>
        <Icon className="ContextMenuItem-icon" icon={props.icon} />
      </span>
    )}

    {props.children}
  </DropdownItem>
);

const ContextMenuItem: React.SFC<MenuItem> = props => (
  <DropdownItem
    onClick={() => (props.onClick ? props.onClick() : null)}
    disabled={props.disabled}
  >
    {props.icon && (
      <span>
        <Icon className="ContextMenuItem-icon" icon={props.icon} />
      </span>
    )}
    {props.children}
  </DropdownItem>
);

interface ContextMenuDownloadLinkProps {
  href: string;
  icon?: IconDefinition;
  formParameters?: {
    [key: string]: string | number | null | undefined;
  };
}

const ContextMenuDownloadLink: React.SFC<ContextMenuDownloadLinkProps> = props => (
  <DownloadLink
    className="dropdown-item"
    color="dark"
    href={props.href}
    icon={props.icon}
    formParameters={props.formParameters}
  >
    {props.children}
  </DownloadLink>
);

const ContextMenuDivider: React.SFC = () => <DropdownItem divider={true} />;

export {
  ContextMenu,
  ContextMenuDivider,
  ContextMenuHeader,
  ContextMenuItem,
  ContextMenuLink,
  ContextMenuDownloadLink,
};
