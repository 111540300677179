import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { decode } from 'latlon-geohash';

import { InstallationAddress, Coordinate } from '../../types';
import { Subscription, Cluster } from 'src/lib/types';
import './Map.scss';
import config from '../../../../../config';
import MapStyles from './MapStyles';
import ProductMarker from '../ProductMarker/ProductMarker';
import OldMapMarker from '../MapMarker/OldMapMarker';
import ClusterMarker from '../ClusterMarker/ClusterMarker';
import { hasServerError } from '../../../subscriptions/utils/subscriptionUtils';
import { LinkTo } from 'src/lib/ui';

const mapOptions: any = {
  fullscreenControl: false,
  draggable: true,
  zoomControl: true,
  zoomControlOptions: {
    style: 0, // DEFAULT=0, SMALL=1, LARGE=2
    position: 3, // google.maps.ControlPosition.TOP_RIGHT -- google is loaded async, so the object itself isn't available yet
  },
  styles: MapStyles,
  clickableIcons: false,
  // mapTypeControl: true,
  // mapTypeControlOptions: {
  //   style: 1, // google.maps.MapTypeControlStyle.HORIZONTAL_BAR -- google is loaded async, so the object itself isn't available yet
  //   position: 2, // google.maps.ControlPosition.TOP_CENTER -- google is loaded async, so the object itself isn't available yet
  // },

  streetViewControl: false,
  streetViewControlOptions: {
    position: 3, // google.maps.ControlPosition.TOP_CENTER -- google is loaded async, so the object itself isn't available yet
  },
  streetViewPanoramaOptions: {
    zoomControl: false,
    addressControl: false,
    fullscreenControl: false,
    motionTrackingControl: false,
  },
};

const defaultCenter = { lat: 65.03438558537329, lon: 10.2705078125 };
const defaultZoomLevel = 5;

const singleSubscriptionZoomLevel = 15;

interface SubscriptionWithLocation {
  installationAddress: InstallationAddress;
}

interface Props {
  setMapBounds?: (bounds: string) => void;
  openSidebar?: () => void;
  activeSubscription?: Subscription;
  searchResult: Array<Subscription | Cluster>;
  subscriptions?: Array<Subscription>;
  center?: Coordinate;
  zoomLevel?: number;
}

const lonToLng = (c: Coordinate) => ({
  ...c,
  lng: c.lon,
});

export const OldMap = (props: Props) => {
  const map = useRef<any>(null);
  const [zoom, setZoom] = useState(props.zoomLevel || defaultZoomLevel);
  const { activeSubscription } = props;

  useEffect(() => {
    if (activeSubscription) {
      panMapToActiveSubscription();
    } else {
      resetToDefault();
    }
  }, [activeSubscription]); //eslint-disable-line

  const onGoogleApiLoaded = (apiLoaded: any) => {
    map.current = apiLoaded.map;
    panMapToActiveSubscription();
  };

  const onGoogleMapChangeHandler = (e: {
    // Note that Google uses `lng` for longitude, while we (and Elasticsearch) uses `lon`.
    bounds: {
      nw: {
        lat: number;
        lng: number;
      };
      se: {
        lat: number;
        lng: number;
      };
    };
  }) => {
    if (e && props.setMapBounds) {
      const bounds = `${e.bounds.nw.lat};${e.bounds.nw.lng};${e.bounds.se.lat};${e.bounds.se.lng}`;
      return props.setMapBounds(bounds);
    }
  };

  const resetToDefault = () => {
    if (map.current) {
      map.current.panTo(
        new (window as any).google.maps.LatLng(
          defaultCenter.lat,
          defaultCenter.lon
        )
      );
      setZoom(defaultZoomLevel);
    }
  };

  const incrementZoom = (lat: number, lon: number) => {
    map.current?.panTo(new (window as any).google.maps.LatLng(lat, lon));
    setZoom(zoom + 1);
  };

  const panMapToActiveSubscription = () => {
    if (
      map.current &&
      activeSubscription?.installationAddress?.location?.lat &&
      activeSubscription?.installationAddress?.location?.lon
    ) {
      panToMapLocation(
        activeSubscription.installationAddress.location.lat,
        activeSubscription.installationAddress.location.lon
      );
    }
  };

  const panToMapLocation = (lat: number, lon: number) => {
    if (map.current) {
      map.current.panTo(new (window as any).google.maps.LatLng(lat, lon));
      return setZoom(singleSubscriptionZoomLevel);
    }
  };

  const renderMarkers = () => {
    if (props.searchResult.length <= 0) {
      return null;
    }

    return props.searchResult.map(res => {
      if (res.__typename === 'Cluster') {
        return renderClusterMarker(res);
      } else if (res.__typename === 'Subscription') {
        return renderSubscriptionMarker(res);
      }
      return null;
    });
  };

  const renderClusterMarker = (cluster: Cluster) => {
    if (cluster.members.length <= 5) {
      return cluster.members.map(member => {
        const subscription = props.subscriptions?.find(s => s.id === member.id);
        if (subscription) {
          return renderSubscriptionMarker(subscription);
        }
        return null;
      });
    }
    const coords: { lat: number; lon: number } = decode(cluster.id);
    return (
      <ClusterMarker
        key={cluster.id}
        lat={coords.lat}
        lng={coords.lon}
        hasWarning={cluster.hasFault}
        count={cluster.members.length}
        onClick={() => incrementZoom(coords.lat, coords.lon)}
      />
    );
  };

  const renderSubscriptionMarker = (sub: Subscription) => {
    if (!sub.installationAddress || !sub.installationAddress.location) {
      return null;
    }
    // dont render active subscription if its in the searchResult
    if (activeSubscription && activeSubscription.id === sub.id) {
      return null;
    }
    // We assume that subscription that is filtered by location always will have a location.
    const coords = {
      lat: (sub as SubscriptionWithLocation).installationAddress.location.lat,
      lng: (sub as SubscriptionWithLocation).installationAddress.location.lon,
    };
    return (
      <LinkTo key={sub.id} {...coords} area="map" subscription={sub.id || ''}>
        <ProductMarker
          status={sub.cpe?.status}
          onClick={props.openSidebar}
          subscription={sub ?? undefined}
        />
      </LinkTo>
    );
  };

  const renderActiveMarker = () => {
    if (!activeSubscription) {
      return null;
    }
    const isSevereStatus = hasServerError(activeSubscription);
    const coords = {
      lat: (activeSubscription as SubscriptionWithLocation).installationAddress
        .location.lat,
      lng: (activeSubscription as SubscriptionWithLocation).installationAddress
        .location.lon,
    };
    return (
      <LinkTo {...coords} area="map" subscription={activeSubscription.id}>
        <OldMapMarker
          hasWarning={isSevereStatus}
          big={isSevereStatus}
          onClick={props.openSidebar}
        />
      </LinkTo>
    );
  };

  return (
    <div className="Map-container">
      <GoogleMapReact
        bootstrapURLKeys={{
          key: config.googleMapsApiKey || '',
        }}
        center={lonToLng(props.center || defaultCenter)}
        options={mapOptions}
        draggable={true}
        onGoogleApiLoaded={onGoogleApiLoaded}
        yesIWantToUseGoogleMapApiInternals={true}
        onChange={onGoogleMapChangeHandler}
        zoom={zoom}
      >
        {renderActiveMarker()}
        {renderMarkers()}
      </GoogleMapReact>
    </div>
  );
};
