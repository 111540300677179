import React, { useEffect } from 'react';
import { Fullscreen, FullscreenBody, FullscreenHeader } from 'src/lib/ui';
import { Contact } from 'src/lib/types';
import {
  yupValidateEmail,
  yupValidatePhone,
} from 'src/lib/utils/yupValidations';
import { validRoles, getRoleList } from '../editContact/utils';
import { Step2 } from './Step2';
import { Step1 } from './Step1';
import { Step3 } from './Step3';
import { Roles } from './types';
import SaveOverlay from '../SaveOverlay/SaveOverlay';
import { useRouter, useForm, Form } from 'src/lib/utils';
import { useCreatePerson } from '../../mutations/useCreatePerson';
import { t } from 'src/lib/i18n';
import { useFeature } from 'src/lib/utils/useFeature';

type Values = {
  type: string;
  selectedContact: Contact | undefined;
  firstName: string;
  lastName: string;
  email: string;
  mobilePhone: string;
  employeeNumber: string;
  description: string;
  secondaryPhone: string;
  roles: Roles | undefined;
};

export type FormState = Form<Values>;

export const CreateContact = () => {
  const isFederatedUser = useFeature('scim-federated-user');
  const router = useRouter();
  const {
    createPerson,
    createUserOnContact,
    data,
    hasError,
    loading,
  } = useCreatePerson();
  useEffect(() => {
    if (data && !hasError && data?.id) {
      router.history.push(`/kontakter/${data.id}`);
    }
  }, [data, hasError]); // eslint-disable-line react-hooks/exhaustive-deps

  const form = useForm<Values>({
    initialValues: {
      type: isFederatedUser ? 'contact' : '',
      selectedContact: undefined,
      firstName: '',
      lastName: '',
      email: '',
      description: '',
      employeeNumber: '',
      mobilePhone: '',
      secondaryPhone: '',
      roles: undefined,
    },
    validations: {
      type: values => !!values.type,
      firstName: values => !!values.selectedContact || !!values.firstName,
      lastName: values => !!values.selectedContact || !!values.lastName,
      mobilePhone: values =>
        values.mobilePhone
          ? yupValidatePhone('').isValidSync(values.mobilePhone)
          : true,
      secondaryPhone: values =>
        values.secondaryPhone
          ? yupValidatePhone('').isValidSync(values.secondaryPhone)
          : true,
      email: values =>
        !!values.selectedContact ||
        yupValidateEmail('').isValidSync(values.email),
      description: values =>
        values.description ? values.description.length < 100 : true,
      employeeNumber: values =>
        values.employeeNumber ? values.employeeNumber.length < 40 : true,
      roles: values =>
        values.type === 'contact' ||
        (values.type === 'user' && validRoles(values.roles)),
      selectedContact: values =>
        values.type === 'user' &&
        !!values.selectedContact &&
        !values.selectedContact?.user,
    },
  });

  const submit = () => {
    const { type, selectedContact, roles, ...personalia } = form.values;
    const rolesList = getRoleList(roles);
    if (form.values.selectedContact) {
      createUserOnContact(form.values.selectedContact.id, rolesList);
    } else {
      createPerson(personalia, rolesList);
    }
  };

  const step1Valid = form.isFieldValid('type');
  const step2Valid = form.values.selectedContact
    ? form.isFieldValid('selectedContact')
    : form.isFieldValid('lastName') &&
      form.isFieldValid('firstName') &&
      form.isFieldValid('email') &&
      form.isFieldValid('mobilePhone') &&
      form.isFieldValid('secondaryPhone') &&
      form.isFieldValid('description') &&
      form.isFieldValid('employeeNumber');

  const step3Valid = form.isFieldValid('roles');
  const isFromValid = step1Valid && step2Valid && step3Valid;

  return (
    <Fullscreen>
      <FullscreenHeader title={t.contacts.create.createNew} />
      <FullscreenBody>
        <form>
          <div className="pb-5 mb-5">
            <Step1 form={form} isFederatedUser={isFederatedUser} />
            <Step2
              form={form}
              isLastStep={form.values.type === 'contact'}
              isValid={step2Valid}
            />
            {form.values.type === 'user' && (
              <Step3 form={form} isValid={step3Valid} />
            )}
          </div>

          <SaveOverlay
            ignoreSidebar={true}
            cancel={() => router.history.push('/kontakter')}
            submit={submit}
            loading={loading}
            disabled={!isFromValid}
            error={hasError}
            errorMsg={t.contacts.create.defaultError}
          />
        </form>
      </FullscreenBody>
    </Fullscreen>
  );
};
