import { Folder, File } from 'src/lib/types';

/**
 * Returns an ordered array of the parent folders, beginning with the root folder, ending with the given folder itself.
 * @param folder The folder for which to return the full path.
 * @param folders All folders in the documents archive.
 */
export function getFullPath(folder: Folder, folders: Folder[]) {
  const path: Folder[] = [];
  let currentFolder: Folder | undefined = folder;
  while (currentFolder !== undefined) {
    if (!currentFolder.parentId) {
      break;
    }
    path.push(currentFolder);
    for (let i = 0, j = folders.length; i < j; i++) {
      if (folders[i].id === currentFolder.parentId) {
        currentFolder = folders[i];
      }
    }
  }
  path.reverse();
  return path;
}

export function getFullFilePath(file: File, folders: Folder[]) {
  const path: string[] = [];
  let currentParentId: string | undefined | null = file.folderId;
  let currentName = file.name;
  while (currentParentId && currentName) {
    path.push(currentName);
    let currentFolder: Folder | undefined;
    for (let i = 0, j = folders.length; i < j; i++) {
      if (folders[i].id === currentParentId) {
        currentFolder = folders[i];
      }
    }
    currentParentId = currentFolder?.parentId;
    currentName = currentFolder?.name ?? '';
  }
  return path.reverse();
}
