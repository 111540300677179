import * as t from './__types/useInvoice';
import { useCustomer } from 'src/lib/global/CustomerContext';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';

const query = gql`
  query useInvoice($customerId: ID!, $invoiceId: ID!) {
    customer(id: $customerId) {
      id
      invoice(id: $invoiceId) {
        id
        date
        dueDate
        amountGross
        amountNet
        paidAmount
        status
        vat
        organisation {
          id
          name
          organisationNumber
          billingInfo {
            useEhf
            useEmail
            email
            vat
            billingCycle
            paymentTerms
            address {
              address
              address2
              postCode
              postArea
            }
          }
        }
        costAggregate(groupBy: [costType, productGroup]) {
          data {
            productDesignId
            productDesignDescription
            groupedProductDesign
            costType
            periodGroup
            productGroup
            totalAmountGross
            totalAmountNet
          }
        }
        # + size, after
        # lines(productGroup: mobil) {
        #   cursor
        #   size
        #   totalResults
        #   results {
        #     id
        #     invoiceId
        #     amountGross
        #     amountNet
        #     periodStart
        #     periodEnd
        #     productDesignId
        #     productDesignDescription
        #     productGroup
        #     costType
        #     groupedProductDesign
        #     vat
        #   }
        # }
      }
    }
  }
`;

export const useInvoice = (invoiceId: string) => {
  const customer = useCustomer();
  const { data, loading } = useQuery<t.useInvoice, t.useInvoiceVariables>(
    query,
    {
      variables: {
        customerId: customer.id,
        invoiceId: invoiceId,
      },
    }
  );

  const invoice = data?.customer?.invoice;
  return { invoice, loading };
};
