import React from 'react';
import { t } from 'src/lib/i18n';

const OrderMobileSubscriptionFailure = (props: { failInfo?: string }) => {
  const i18n =
    t.ordering.OrderMobileSubscription.OrderMobileSubscriptionFailure;
  return (
    <div>
      <h1>{i18n.header}</h1>
      {props.failInfo ? <p>{props.failInfo}</p> : null}
    </div>
  );
};

export default OrderMobileSubscriptionFailure;
