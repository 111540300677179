import React from 'react';
import { TableBodyCell } from 'src/lib/ui';
import { Organisation } from 'src/lib/types';

export const OrganisationTableCell = (props: {
  subscription?: null | {
    organisation?: Organisation | null;
  };
}) => (
  <TableBodyCell>
    <div>
      {props.subscription?.organisation
        ? props.subscription?.organisation.name
          ? `${props.subscription?.organisation.name} (${props.subscription?.organisation.id})`
          : props.subscription.organisation.id
        : null}
    </div>
  </TableBodyCell>
);
