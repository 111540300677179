import * as t from '../types';
import { isArray } from 'lodash';

const asQueryVariables: (
  customerId: string,
  input: t.SearchInput
) => t.QueryVariables = (customerId, input) => ({
  customerId: customerId,
  search: {
    query: 'query' in input && input.query ? input.query : undefined,
    sortBy: 'sortBy' in input && input.sortBy ? input.sortBy : undefined,
    sortOrder:
      'sortOrder' in input && input.sortOrder ? input.sortOrder : undefined,
    types:
      'types' in input && input.types && input.types.length > 0
        ? input.types
        : undefined,
    after: 'after' in input && input.after ? input.after : undefined,
    page: 'page' in input && input.page ? input.page : undefined,
    size:
      'size' in input && input.size !== undefined && input.size !== null
        ? input.size
        : undefined,
    cluster: 'cluster' in input ? input.cluster : undefined,
    filters:
      'filter' in input && input.filter
        ? input.filter
            .map(f => ({
              filter: f.filter,
              value: Array.isArray(f.value)
                ? f.value.map(v => (typeof v === 'string' ? v : v))
                : typeof f.value === 'string'
                ? f.value
                : f.value,
            }))
            .filter(f => !isArray(f.value) || f.value.length > 0)
        : undefined,
  },
});

export default asQueryVariables;
