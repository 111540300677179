import gql from 'graphql-tag';

export const PlannedWorkFragment = gql`
  fragment PlannedWorkSearchResult on PlannedWork {
    id
    changeNumber
    active
    emergency
    state
    serviceImpact
    description
    dates {
      plannedStartDate
      plannedEndDate
      createdAt
      updatedAt
      oldEndDate
      oldEndDate
      oldStartDate
      __typename
    }
    expectedTotalDowntimeInMinutes
    impactedSubscriptionIds
    numberOfImpactedSubscriptions
    closeCode
    cancelReason
    onHoldReason
    onHold
    customer {
      id
    }
    __typename
  }
`;
