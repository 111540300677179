import * as React from 'react';
import cs from 'classnames';

import './Tag.scss';
import { Icon } from '@telia/styleguide';
interface Props {
  tag: {
    id: string;
    tag: string;
  };
  className?: string;
  style?: React.CSSProperties;
  renderAs?: 'div' | 'li';
  onClick?: (event: React.SyntheticEvent) => void;
  onDelete?: () => void;
  onSelect?: (tagId: string) => void;
  disabled?: boolean;
  redirect?: (tagId: string) => void;
}

export const Tag: React.FC<Props> = props => {
  const onClick = (e: React.SyntheticEvent<Element>) => {
    if (props.disabled) {
      e.preventDefault();
      return;
    }
    if ('onClick' in props && props.onClick) {
      props.onClick(e);
    }
  };
  const Element = props.renderAs || 'div';

  const className = cs(
    'Tag',
    {
      redirect: props.redirect,
      select: props.onSelect,
    },
    props.className
  );

  // TAG on dashboard. Taken out of the normal tag behaviour
  if (props.redirect) {
    return (
      <Element
        className={className}
        style={props.style}
        onClick={() => {
          if (props.redirect) {
            props.redirect(props.tag.id);
          }
        }}
      >
        {props.tag.tag}
      </Element>
    );
  }
  if (props.onSelect) {
    return (
      <Element
        className={className}
        style={props.style}
        onClick={() => {
          if (props.onSelect) {
            props.onSelect(props.tag.id);
          }
        }}
      >
        {props.tag.tag}
      </Element>
    );
  }

  return props.disabled ? (
    <Element className={className} style={props.style}>
      {props.tag.tag}
    </Element>
  ) : (
    <Element
      className={className}
      style={props.style}
      onClick={e => onClick(e)}
    >
      {props.tag.tag}
      <Icon icon="close" />
    </Element>
  );
};

export default Tag;
