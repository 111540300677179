import * as React from 'react';
import { map } from 'lodash';

import { SearchResult } from '../../types';

import { SearchListContactResult } from './SearchListContactResult';
import { SearchListCostCenterResult } from './SearchListCostCenterResult';
import { SearchListFileResult } from './SearchListFileResult';
import { SearchListIncidentResult } from './SearchListIncidentResult';
import { SearchListInvoiceResult } from './SearchListInvoiceResult';
import { SearchListOrderResult } from './SearchListOrderResult';
import { SearchListOrganisationResult } from './SearchListOrganisationResult';
import { SearchListSubscriptionResult } from './SearchListSubscriptionResult';
import { SearchListTagResult } from './SearchListTagResult';
import { List } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

const listResultComponentsByTypename = {
  Contact: SearchListContactResult,
  CostCenter: SearchListCostCenterResult,
  File: SearchListFileResult,
  Incident: SearchListIncidentResult,
  Invoice: SearchListInvoiceResult,
  Order: SearchListOrderResult,
  Organisation: SearchListOrganisationResult,
  Subscription: SearchListSubscriptionResult,
  Tag: SearchListTagResult,
};

interface SearchListResultsProps {
  renderAs?: 'ul' | 'ol';
  results?: SearchResult[];
}

export const SearchListResults: React.FC<SearchListResultsProps> = props => {
  if (!props.results || !props.results.length) {
    return (
      <div className="container mt-4">{t.search.SearchListResult.noResult}</div>
    );
  }

  return (
    <List renderAs={props.renderAs || 'ol'} border={true}>
      {map(props.results, result => {
        const EntryComponent =
          listResultComponentsByTypename[result.__typename];

        if (!EntryComponent) {
          return null;
        }

        const key = `${result.__typename}:${result.id}`;

        return <EntryComponent key={key} entry={(result as unknown) as any} />;
      })}
    </List>
  );
};
