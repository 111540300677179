import React from 'react';
import {
  PageHeaderProps,
  PageHeader,
} from 'src/areas/main/common/PageHeader/PageHeader';
import SubscriptionTagsPicker from '../../Pages/Components/SubscriptionTagsPicker/SubscriptionTagsPicker';
import { Icon } from '@telia/styleguide';
import { colors, Button, urlFor, LinkTo } from 'src/lib/ui';
import { formatInstallationAddress } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import { EditSubscriptionAlias } from '../../Pages/Components/Alias/EditSubscriptionAlias';
import { useTags } from '../../tags/useTags';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';

import cn from 'classnames';

import './SubscriptionPageHeader.scss';

export type SubscriptionPageHeaderProps = {
  description: string;
  subscription: {
    id: string;
    alias?: string | null;
    tags?: Array<{
      __typename: 'Tag';
      tag: string;
      id: string;
    }> | null;
    group?: SubscriptionGroup | null;
    installationAddress?: {
      zip?: string | null;
      address?: string | null;
      city?: string | null;
      location?: {
        lat?: number | null;
        lon?: number | null;
      } | null;
    } | null;
  };
} & PageHeaderProps;

/**
 *  Layout component for the PageHeader for all subscription pages.
 */
export function SubscriptionPageHeader(props: SubscriptionPageHeaderProps) {
  const { subscription, description, ...pageHeaderProps } = props;
  const i18n = t.subscriptions.layout.SubscriptionPageHeader;
  const tagsQuery = useTags(subscription.id);
  const subscriptionTags = tagsQuery.data?.customer?.subscriptions?.[0];

  const mapLabel = (
    <>
      <div>
        <Icon icon="map" />
      </div>
      <span>
        <small>{i18n.position}</small>
        <div className="SubscriptionPageHeader-address-label">
          {formatInstallationAddress(
            subscription.installationAddress,
            'fullAddress'
          )}
        </div>
      </span>
    </>
  );
  return (
    <PageHeader
      {...pageHeaderProps}
      titleActions={<EditSubscriptionAlias subscription={props.subscription} />}
    >
      <div className="row align-items-start">
        {description && (
          <div className="col-md-6 d-none d-sm-block mb-2">{description}</div>
        )}
        <div className={cn(description ? 'col-md-6' : 'cl-md-12', 'col-sm-12')}>
          {subscription.installationAddress ? (
            <div className="mb-4">
              {subscription.installationAddress.location?.lat &&
              subscription.installationAddress.location?.lon ? (
                <LinkTo
                  className="SubscriptionPageHeader-address SubscriptionPageHeader-address-link"
                  area="map"
                  subscription={subscription.id}
                >
                  {mapLabel}
                </LinkTo>
              ) : (
                <div className="SubscriptionPageHeader-address">{mapLabel}</div>
              )}
            </div>
          ) : null}
          <div className="d-flex flex-row align-items-start">
            <div>
              <Icon
                className="mr-4"
                style={{ fill: colors.corePurple }}
                icon="tags"
              />
            </div>
            <div className="SubscriptionPageHeader-tags">
              {subscriptionTags?.tags?.map(tag => (
                <Button
                  color="grey"
                  size="small"
                  rounded={true}
                  key={tag.id}
                  to={urlFor({
                    area: 'subscriptions',
                    filterByTag: tag.id,
                  })}
                >
                  {tag.tag}
                </Button>
              ))}
              {/** If subscription is null/undefined, we dont have a sub to update tags for. Therefore, do not render Tag-picker */}
              {subscriptionTags && (
                <SubscriptionTagsPicker
                  serviceType={subscription.group}
                  subscription={subscriptionTags}
                  refetch={tagsQuery.refetch}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    </PageHeader>
  );
}
