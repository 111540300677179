import { SortOrder } from './../../__types/graphql-global-types';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import {
  parseStringToSortOrder,
  parseStringToDataViewType,
  parseStringToNumber,
} from 'src/lib/utils/parseStringTo';
import { DataViewType } from 'src/lib/types';
import {
  useSavedUserPreferences,
  SavedUserPreferences,
} from 'src/lib/userPreferences/SavedUserPreferencesContext';

export interface ListQueryParams {
  searchQuery: string;
  setSearchQuery: (value: string) => void;
  page: number;
  pageSize: number;
  sortBy: string;
  sortOrder: SortOrder;
  view?: DataViewType;
  setPageSize: (size: number) => void;
  setSort: (key: string | undefined, order: 'asc' | 'desc') => void;
  setView: (view: DataViewType) => void;
  columns: string[];
  setColumns: (columns: string[]) => void;
}

/**
 * Hook for getting common props for listing data.
 */
export const useListQueryParams = (params: {
  defaultSortKey: string;
  defaultSortOrder: string;
  preferenceNamespace?: string;
}): ListQueryParams => {
  const query = useQueryParameters();
  const { userPreferences, setUserPreference } = useSavedUserPreferences();

  const viewNamespace = params.preferenceNamespace
    ? (`${params.preferenceNamespace}View` as keyof SavedUserPreferences)
    : undefined;
  const sizeNamespace = params.preferenceNamespace
    ? (`${params.preferenceNamespace}PageSize` as keyof SavedUserPreferences)
    : undefined;

  const columnNamespace = params.preferenceNamespace
    ? ((params.preferenceNamespace + 'Columns') as keyof SavedUserPreferences)
    : undefined;

  const preferredPageView = viewNamespace
    ? userPreferences[viewNamespace]
    : undefined;
  const preferredPageSize = sizeNamespace
    ? userPreferences[sizeNamespace]
    : undefined;

  const searchQuery = query.get('query') ?? '';
  const page = query.getNumber('page') ?? 1;
  const pageSize =
    query.getNumber('size') ?? parseStringToNumber(preferredPageSize) ?? 20;
  const sortBy = query.get('sortBy') ?? params.defaultSortKey;
  const sortOrder = (parseStringToSortOrder(query.get('sortOrder')) ??
    params.defaultSortOrder) as SortOrder;
  const view = parseStringToDataViewType(
    query.get('view') ?? preferredPageView ?? 'list'
  );

  const setSearchQuery = (value: string) => {
    if (page && page > 1) {
      query.set({
        query: value,
        page: 1,
      });
    } else {
      query.set('query', value);
    }
  };

  const setPageSize = (size: number) => {
    query.set({ size: size, page: 1 });
    if (sizeNamespace) {
      setUserPreference(sizeNamespace, size.toString());
    }
  };

  const setSort = (key: string | undefined, order: 'asc' | 'desc') => {
    /**
     * 28.02.2020 Edgecase: We only want to show the important statuses, so we force only `desc` sortOrder.
     * TODO: Create a generic way to do this instead of handling each sortKey manually here.
     */
    if (key === 'monitoringStatus') {
      query.set({ sortBy: key, sortOrder: 'desc', page: 1 });
    } else {
      query.set({ sortBy: key, sortOrder: order, page: 1 });
    }
  };

  const setView = (selectedView: DataViewType) => {
    query.set('view', selectedView);
    if (viewNamespace) {
      setUserPreference(viewNamespace, selectedView);
    }
  };

  let columns = columnNamespace
    ? userPreferences[columnNamespace]?.split(',') ?? []
    : [];

  const setColumns = (columnDef: string[]) => {
    if (columnNamespace) {
      const stringedColumns = columnDef.toString();
      setUserPreference(columnNamespace, stringedColumns);
    }
  };

  return {
    searchQuery,
    setSearchQuery,
    page,
    pageSize,
    sortBy,
    sortOrder,
    view,
    setPageSize,
    setSort,
    setView,
    columns,
    setColumns,
  };
};
