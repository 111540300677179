/* eslint-disable */

/**
 * Canny SDK, as taken from https://developers.canny.io/install/widget/web
 */

const cannySdk = {
  init: () => {
    (function(w: any, d, i) {
      // Only initialise once
      if (typeof w.Canny === 'function') {
        return;
      }

      const c = function() {
        c.q.push(arguments);
      };
      c.q = [];
      w.Canny = c;

      function whenLoaded() {
        if (d.getElementById('canny-jssdk')) {
          return;
        }
        var f = d.getElementsByTagName('script')[0];
        var e = d.createElement('script');
        e.type = 'text/javascript';
        e.async = true;
        e.src = 'https://canny.io/sdk.js';
        if (f.parentNode) {
          f.parentNode.insertBefore(e, f);
        }
      }

      if (d.readyState === 'complete') {
        whenLoaded();
      } else if (w.attachEvent) {
        w.attachEvent('onload', whenLoaded);
      } else {
        w.addEventListener('load', whenLoaded, false);
      }
    })(window, document);
  },
};

export default cannySdk;
