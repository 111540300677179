const tags = {
  create: 'Opprett ny tag',
  edit: 'Endre informasjon',
  delete: 'Slett tag',
  deleteButton: 'slett',
  deleteInformation:
    'En tag kan kun slettes hvis den ikke er tilknyttet noen tjenester og hvis den ikke blir brukt som tilgangsstyring',
  noResults: 'Ingen resultater',
  sortFields: {
    lable: 'Sorter',
    name: 'Navn',
    AtoÅ: 'A til Å',
    ÅtoA: 'Å til A',
  },
  descriptionHeader: 'Hva er tags?',
  descriptionBody:
    'Med tags kan du sortere tjenestene dine på en måte som er logisk for din bedrift. Du kan bruke dem til å filtrere i lister og søketreff, gruppere tjenester i rapporter, og styre hvem som skal ha tilgang til tjenestene i portalen.',
  form: {
    tag: 'Navn',
    description: 'Beskrivelse',
  },
  error: {
    deleteTag:
      'Taggen har tjenester tilknyttet seg eller brukes som tilgangsstyring.',
    createAndEditTag: 'Tag med samme navn finnes allerede',
  },
  success: {
    tagCreated: (tag: string) =>
      `Tag ${tag} ble opprettet. Prøv å søke etter den i lista`,
  },
};

export default tags;
