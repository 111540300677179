import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';
import { formatDate } from 'src/lib/utils/dateUtils';
import { Order } from 'src/lib/types';
import {
  mapOrderStatusToLabel,
  mapOrderStatustoIcon,
} from 'src/areas/main/orders/utils';

import { t } from 'src/lib/i18n';

export const SearchListOrderResult: React.FC<{ entry: Order }> = ({
  entry,
}) => {
  const orderStatusLabel = mapOrderStatusToLabel(entry.portalStatus);

  return (
    <ListResultCard
      linkTo={entry}
      className="ListResultCard--Order"
      icon={mapOrderStatustoIcon(entry.portalStatus)}
      iconDescription={orderStatusLabel}
      title={formatSearchListOrderTitle(entry.id, entry.description)}
      attributes={formatDatesForAttributes(entry.dates)}
    />
  );
};

const formatDatesForAttributes = (
  dates:
    | null
    | {
        createdAt?: string;
        updatedAt?: string;
      }
    | undefined
) => {
  if (!dates) {
    return [];
  }
  const i18n = t.search.SearchListOrderResult;
  const attributes: Array<{ label: string; value: string }> = [];
  if (dates.createdAt) {
    attributes.push({
      label: i18n.created,
      value: formatDate(dates.createdAt, 'medium'),
    });
  }
  if (dates.updatedAt) {
    attributes.push({
      label: i18n.lastUpdated,
      value: formatDate(dates.updatedAt, 'medium'),
    });
  }

  return attributes;
};

const formatSearchListOrderTitle = (
  id: string,
  description?: string | null
) => {
  let title = '';
  if (description) {
    title = title + description + ' ';
  }

  return title + id;
};
