import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { MobileOrderDetailsFragment } from '../lib/MobileOrderDetailsFragment';
import { MobileOrderDetails } from '../types';
import * as t from './__types/useTerminateMobileSubscription';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';

const MUTATION_TERMINATE_MOBILE_SUBSCRIPTION_ORDER = gql`
  mutation useTerminateMobileSubscription(
    $input: TerminateMobileSubscriptionOrderInput
  ) {
    terminateMobileSubscriptionOrder(input: $input) {
      orderDetails {
        ... on MobileOrderDetails {
          ...MobileOrderDetailsResult
        }
      }
    }
  }
  ${MobileOrderDetailsFragment}
`;

export interface TerminateMobileSubscriptionOrderResult {
  terminateMobileSubscriptionOrder: (input: {
    msisdn: string;
    parkNumber: boolean;
    terminationDate?: string;
  }) => void;
  loading: boolean;
  error?: boolean;
  data?: MobileOrderDetails | null;
}

export const useTerminateMobileSubscription = (): TerminateMobileSubscriptionOrderResult => {
  const { customerId } = useSubscriptionContext();
  const [mutation, { data, loading, error }] = useMutation<
    t.useTerminateMobileSubscription,
    t.useTerminateMobileSubscriptionVariables
  >(MUTATION_TERMINATE_MOBILE_SUBSCRIPTION_ORDER);
  const terminateMobileSubscriptionOrder = async (input: {
    msisdn: string;
    parkNumber: boolean;
    terminationDate?: string;
  }) => {
    await mutation({
      variables: {
        input: { customerId: customerId, ...input },
      },
    });
  };

  return {
    terminateMobileSubscriptionOrder,
    loading,
    error: !!error,
    data: data?.terminateMobileSubscriptionOrder,
  };
};
