import gql from 'graphql-tag';
import { InvoiceLine, ProductGroup } from '../types';
import { InvoiceLineLoader, PropsForInvoice } from './WithInvoiceLines';

const query = gql`
  query WithInvoiceLinesForInvoice(
    $customerId: ID!
    $invoiceId: ID!
    $size: Int
    $after: String
    $productGroup: ProductGroupType
  ) {
    customer(id: $customerId) {
      id
      invoice(id: $invoiceId) {
        id
        lines(size: $size, after: $after, productGroup: $productGroup) {
          cursor
          size
          totalResults
          results {
            id
            invoiceId
            amountGross
            amountNet
            periodStart
            periodEnd
            productDesignId
            productDesignDescription
            productGroup
            costType
            groupedProductDesign
            vat
          }
        }
      }
    }
  }
`;

interface Variables {
  customerId: string;
  invoiceId: string;
  size?: number;
  after?: string;
  productGroup?: ProductGroup;
}

interface Data {
  customer: {
    id: string;
    invoice: {
      id: string;
      lines: {
        cursor: string;
        size: number;
        totalResults: number;
        results: InvoiceLine[];
      };
    };
  };
}

const loader: InvoiceLineLoader<PropsForInvoice> = async (
  client,
  customerId,
  props,
  size,
  after
) => {
  const res = await client.query<Data, Variables>({
    query,
    variables: {
      customerId,
      size,
      after,
      invoiceId: props.invoiceId,
      productGroup: props.productGroup,
    },
  });

  return {
    cursor: res.data.customer.invoice.lines.cursor,
    size: res.data.customer.invoice.lines.size,
    totalResults: res.data.customer.invoice.lines.totalResults,
    lines: res.data.customer.invoice.lines.results,
  };
};

export default loader;
