import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { useCustomerOrganisations } from 'src/areas/main/cost/lib';

export const useOrganisationFilter = () => {
  const query = useQueryParameters();
  const { customer } = useCustomerOrganisations();
  const selectedOptions = query.getArray('organisation');
  const i18n = t.subscriptions.filters.organisation;

  const options =
    customer?.allOrganisations?.map(option => ({
      label: option.name,
      value: option.id,
    })) ?? [];

  return {
    id: 'organisation',
    label: i18n.label,
    options: options,
    allowMultiple: true,
    allowSearch: true,
    selectedOptions,
    handleSave: values => {
      setFilterAndResetPage(query, 'organisation', values ? values : undefined);
    },
  };
};
