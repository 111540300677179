import React from 'react';
import './CustomTooltip.scss';
import { format } from 'date-fns';
import { Icon, IconDefinition } from '@telia/styleguide';
import { Attribute } from 'src/lib/ui';
import cn from 'classnames';

export const Header: React.SFC<{
  data: string | number;
  hideTimestamp: boolean;
}> = ({ data, hideTimestamp }) => {
  const date = new Date(data);
  return (
    <div className="CustomTooltip-header">
      <span>{format(date, 'DD/MM/YYYY')}</span>
      {!hideTimestamp ? <span>{format(date, 'HH:mm')}</span> : null}
    </div>
  );
};
interface SectionItemProps {
  title?: string;
  value: string;
  color?: string;
  filled?: boolean;
  icon?: IconDefinition;
  hideIcon?: boolean;
}

export const SectionItem: React.SFC<SectionItemProps> = props => {
  return (
    <div className="Section-item">
      {!props.hideIcon ? (
        props.icon ? (
          <Icon
            className="mr-2"
            icon={props.icon}
            style={{ fill: props.color }}
          />
        ) : (
          <div
            className={cn('Section-item-dot')}
            style={{ backgroundColor: props.color }}
          >
            {props.filled === false && (
              <div className="Section-item-dot--inner" />
            )}
          </div>
        )
      ) : null}
      <Attribute label={props.title} value={props.value} />
    </div>
  );
};

interface SectionProps {
  header: string;
  children: React.ReactNode;
}

export const Section: React.SFC<SectionProps> = ({ header, children }) => {
  return (
    <React.Fragment>
      <div className="CustomTooltip-border" />
      <div className="CustomTooltip-section">
        <div className="CustomTooltip-section-header">{header}</div>
        <div className="CustomTooltip-section-body">{children}</div>
      </div>
    </React.Fragment>
  );
};

export const CustomTooltipContainer: React.SFC<{
  children: React.ReactNode;
}> = ({ children }) => <div className="CustomTooltip">{children}</div>;
