import servicePlusNb from '../../nb/Main/servicePlus';

const servicePlus: typeof servicePlusNb = {
  newLocation: {
    address: 'Address',
    postalCode: 'Postal code',
    city: 'City',
    organisationNumber: 'Organisation number',
    contact: 'Contact',
    contactFirstName: 'First name',
    contactLastName: 'Last name',
    contactEmail: 'E-mail',
    contactPhone: 'Phone',
    addNewLocation: 'Add new location',
    postalCodeError: 'Postalcode must be 4 characters',
    tooShortError: 'Too short',
    required: 'Required field',
  },
  overview: {
    title: 'Overview',
  },
  address: {
    servicePlus: 'Service Plus',
    title: 'Locations',
    statusOk: 'Everything looks good',
  },
};

export default servicePlus;
