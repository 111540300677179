import menuNo from '../../nb/Mobile/menu';

const menu: typeof menuNo = {
  mySubscription: 'My subscription',
  sendSMS: 'Send SMS',
  useageAndCost: 'Usage and costs',
  subscriptionSelectorHeader: 'My phone numbers',
  settings: 'Settings',
  history: 'History',
  profile: 'My profile',
  language: 'Change language',
  overview: 'Overview',
  toPortal: 'Go to Min Portal',
  logout: 'Log out',
};

export default menu;
