import * as React from 'react';
import './FilterToggles.scss';
import Chip from '../Chip/Chip';
import { IconDefinition } from '@telia/styleguide';

export type FilterTogglesValue<T = string> = {
  value: T;
  label: string;
  icon?: IconDefinition;
};

type FilterTogglesProps = {
  options: FilterTogglesValue[];
  allowMultiple?: boolean;
  currentFilter: string[] | undefined;
  setCurrentFilter: (filter: string[]) => void;
};

export const FilterToggles: React.SFC<FilterTogglesProps> = props => {
  const applyFilter = (value: string) =>
    props.allowMultiple
      ? props.currentFilter?.find(option => option === value)
        ? props.setCurrentFilter(props.currentFilter.filter(o => o !== value))
        : props.setCurrentFilter([...(props.currentFilter ?? []), value])
      : props.currentFilter?.find(option => option === value)
      ? props.setCurrentFilter([])
      : props.setCurrentFilter([value]);

  return (
    <div className="FilterToggles">
      {props.options.map((f, index) => {
        const checked = props.currentFilter?.includes(f.value);

        return (
          <Chip
            className="mr-2"
            key={index}
            label={f.label || ''}
            icon={f.icon}
            active={checked}
            mode="filter"
            onClick={e => applyFilter(f.value)}
          />
        );
      })}
    </div>
  );
};
