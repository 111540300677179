import React from 'react';
import { t } from 'src/lib/i18n';
import {
  DownloadLink,
  Button,
  OptionsDropdownItem,
  OptionsDropdownMenu,
  OptionsDropdownToggle,
  UncontrolledOptionsDropdown,
} from 'src/lib/ui';
import { useDownloadLink } from 'src/lib/utils/useDownloadLink';

interface SubscriptionExportProperties {
  group?: string;
  query?: string;
  tags?: string[];
  cases?: string[];
  status?: string[];
  type?: string[];
  deliveryStatus?: string[];
  cpeType?: string[];
  subscriptionsIds?: string[];
}

export function SubscriptionExportButton(props: SubscriptionExportProperties) {
  const translate = t.subscriptions;
  const downloadLink = useDownloadLink();

  const MAX_NUMBER_OF_ELASTIC_SEARCH_RESULTS = 100000;
  const group = props.group ? '&filter=group:' + props.group : '';
  const tags =
    (props.tags?.length ?? 0) > 0 ? '&filter=tag:' + props.tags?.join(';') : '';
  const cases =
    (props.cases?.length ?? 0) > 0
      ? '&filter=cases:' + props.cases?.join(';')
      : '';
  const status =
    (props.status?.length ?? 0) > 0
      ? '&filter=status:' + props.status?.join(';')
      : '';
  const type =
    (props.type?.length ?? 0) > 0
      ? '&filter=type:' + props.type?.join(';')
      : '';
  const cpeType =
    (props.cpeType?.length ?? 0) > 0
      ? '&filter=cpeType:' + props.cpeType?.join(';')
      : '';
  const deliveryStatus =
    (props.deliveryStatus?.length ?? 0) > 0
      ? '&filter=deliveryStatus:' + props.deliveryStatus?.join(';')
      : '';

  const subscriptionsIds =
    (props.subscriptionsIds?.length ?? 0) > 0
      ? '&filter=subscription:' + props.subscriptionsIds?.join(';')
      : '';

  const formParameters = {
    type: 'subscription',
    size: MAX_NUMBER_OF_ELASTIC_SEARCH_RESULTS,
    query: props.query,
  };
  const downloadAllUrl =
    '/api/files/report/customer/subscriptions?' +
    group +
    tags +
    cases +
    status +
    type +
    deliveryStatus +
    cpeType;

  const downloadSelectedUrl =
    '/api/files/report/customer/subscriptions?' + group + subscriptionsIds;

  if (props.subscriptionsIds?.length) {
    return (
      <UncontrolledOptionsDropdown>
        <OptionsDropdownToggle tag="span">
          <Button icon="download" color="dark" transparent={true} size="small">
            {translate.download}
          </Button>
        </OptionsDropdownToggle>
        <OptionsDropdownMenu right={true}>
          <OptionsDropdownItem
            icon="download"
            onClick={() => downloadLink(downloadSelectedUrl, formParameters)}
            label={translate.selectedServices}
          />
          <OptionsDropdownItem
            icon="download"
            onClick={() => downloadLink(downloadAllUrl, formParameters)}
            label={translate.allServices}
          />
        </OptionsDropdownMenu>
      </UncontrolledOptionsDropdown>
    );
  }
  return (
    <DownloadLink
      href={downloadAllUrl}
      formParameters={formParameters}
      icon="download"
      color="dark"
      className="Button Button--default Button--dark transparent transparent btnSmall"
    >
      {translate.download}
    </DownloadLink>
  );
}
