import { useEffect } from 'react';
import { useTableSelection } from 'src/lib/ui';
import { useLazySearchSubscriptions } from '../queries/useLazySearchSubscriptions';
import { compact } from 'lodash';

export const useSelectableSubscriptions = (props: {
  results: Array<{ id: string | null }>;
  query: string;
  totalResults: number;
  filters: Array<{
    filter: string;
    value: string[];
  }>;
}) => {
  const { results, query, totalResults, filters } = props;

  const lazySearch = useLazySearchSubscriptions();
  const selectProps = useTableSelection(results);

  useEffect(() => {
    if (lazySearch.res.records.length) {
      selectProps?.setSelected(compact(lazySearch.res.records.map(r => r.id)));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [lazySearch.res.records]);

  const handleSelectAll = () => {
    lazySearch.getSearchResult({
      query,
      size: totalResults,
      filter: filters,
    });
  };

  return {
    selectProps,
    handleSelectAll,
    loading: lazySearch.res.loading,
  };
};
