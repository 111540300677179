import { useQueryParameters } from 'src/lib/utils/useQueryParameters';

export const useQueryParamPagination = (props: {
  defaultPageSize: number;
  onTogglePageSize?: (pageSize: number) => void;
}) => {
  const { defaultPageSize, onTogglePageSize } = props;
  const query = useQueryParameters();

  const page = query.getNumber('page') ?? 1;
  const pageSize = query.getNumber('size') ?? defaultPageSize ?? 20;

  const setPage = (newPage: number) => query.set('page', newPage);

  const setPageSize = (selectedPageSize: number) => {
    setPage(1);
    query.set('size', selectedPageSize);
    if (onTogglePageSize) {
      onTogglePageSize(selectedPageSize);
    }
  };

  return { page, pageSize, setPageSize, setPage };
};
