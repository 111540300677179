import { useFeature } from 'src/lib/utils/useFeature';

interface Props {
  feature: string;
  children: React.ReactNode;
  fallback?: any;
}

/**
 *
 * Features can be read from Keycloak or the gateway.
 */
export function Feature(props: Props) {
  const hasFeature = useFeature(props.feature);

  if (hasFeature && props.children) {
    return props.children;
  }

  /**
   * No feature flag set; return null.
   */
  if (props.fallback) {
    return props.fallback;
  }

  return null;
}
