import { getSortOptions } from 'src/lib/utils/getSortOptionsByKey';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';

export const getSubscriptionSortOptions = (
  group: SubscriptionGroup | 'tag'
) => {
  switch (group) {
    case SubscriptionGroup.data:
      return getSortOptions([
        'monitoringStatus',
        'alias',
        'type',
        'address',
        'subscriptionId',
      ]);
    case SubscriptionGroup.mobile:
      return getSortOptions([
        'ownerName',
        'phoneNumber',
        'type',
        'subscriptionId',
      ]);
    case SubscriptionGroup.tele:
      return getSortOptions([
        'alias',
        'phoneNumber',
        'type',
        'address',
        'subscriptionId',
      ]);
    case SubscriptionGroup.tv:
      return getSortOptions(['alias', 'type', 'address', 'subscriptionId']);
    case SubscriptionGroup.other:
      return getSortOptions([
        'monitoringStatus',
        'alias',
        'type',
        'address',
        'subscriptionId',
      ]);
  }
};
