import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import DisplayContact from './lib/displayContact/DisplayContact';
import { CreateContact } from './lib/createContact/CreateContact';
import { ContactsContainer } from './ContactsContainer';

const ContactSection: React.SFC<RouteComponentProps<{}>> = ({ match }) => (
  <Switch>
    <Route path={`${match.url}/opprett`} component={CreateContact} />
    <Route path={`${match.url}/:id`} component={DisplayContact} />
    <Route path={match.url} component={ContactsContainer} />
  </Switch>
);

export default ContactSection;
