import React from 'react';
import { ListChangelogAdmin } from './ListChangelogAdmin';
import './ChangelogDev.scss';
import { ListChangelogForUser } from './ListChangelogForUser';
import { useQueryUserDropdownChangelogs } from 'src/areas/dashboard/Changelog/useQueryUserDropdownChangelogs';
export function ChangelogDev() {
  const queryChangelogsUser = useQueryUserDropdownChangelogs();
  return (
    <div>
      <h1>Changelog</h1>
      <div className="ChangelogDev">
        <div style={{ marginRight: '20%' }}>
          <ListChangelogAdmin />
        </div>
        <div>
          <div
            style={{ marginBottom: '2rem', width: '20rem', padding: '0.3rem' }}
          >
            Slik vil det se ut for en bruker i portalen med samme
            språkinnstilling som deg. Viktig å merke seg at dette er de som er
            publisert og ikke markert skjult.
          </div>
          <ListChangelogForUser
            changelogs={queryChangelogsUser.data?.changelogs}
            totalResults={queryChangelogsUser.data?.changelogs?.length}
          />
        </div>
      </div>
    </div>
  );
}
