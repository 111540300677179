import React, { useState } from 'react';
import { BodyCard, Attribute, Toolbar, Button, ButtonCard } from 'src/lib/ui';
import { map, reduce } from 'lodash';
import { EditAdditionalInformation } from './';
import { useCustomer } from 'src/lib/global';
import { t } from 'src/lib/i18n';

interface Props {
  subscription: {
    id: string;
    flex1?: string | null;
    flex2?: string | null;
    flex3?: string | null;
    flex4?: string | null;
  };
  disableEdit?: boolean;
}

export const AdditionalInformationCard: React.FC<Props> = ({
  subscription,
  disableEdit,
}) => {
  const [edit, setEdit] = useState<boolean>(false);
  const customer = useCustomer();
  const translate = t.subscriptions.AdditionalInformationCard;
  const hasFlex = Boolean(
    subscription.flex1 ||
      subscription.flex2 ||
      subscription.flex3 ||
      subscription.flex4
  );

  const toggle = () => {
    setEdit(!edit);
  };

  const flexValues = [
    subscription.flex1,
    subscription.flex2,
    subscription.flex3,
    subscription.flex4,
  ];
  const flexes = map(customer.flexFields, (ff, i) => {
    return {
      id: ff.id,
      label: ff.label || `Flex ${ff.id}`,
      value: flexValues[i],
    };
  });

  const flexValueProps = reduce(
    flexes,
    (coll, item) => {
      if (item.value) {
        coll[`flex${item.id}`] = item.value;
      }
      return coll;
    },
    { flex1: '', flex2: '', flex3: '', flex4: '' }
  );

  return (
    <>
      {hasFlex ? (
        <BodyCard heading={translate.title}>
          {map(flexes, ff => (
            <Attribute key={ff.id} label={ff.label} value={ff.value} />
          ))}
          {!disableEdit && (
            <Toolbar className="mt-4">
              <Button icon="pen" onClick={toggle} color="white">
                {translate.btnEdit}
              </Button>
            </Toolbar>
          )}
        </BodyCard>
      ) : (
        !disableEdit && (
          <ButtonCard
            onClick={toggle}
            color="dark"
            icon="info"
            stackHorizontally={true}
          >
            {translate.btnAdd}
          </ButtonCard>
        )
      )}

      <EditAdditionalInformation
        {...flexValueProps}
        subscription={subscription}
        toggleState={toggle}
        isOpen={edit}
      />
    </>
  );
};
