import React from 'react';
import { t } from 'src/lib/i18n';
import { MinSidePageHeader } from 'src/areas/mobile/ui/MinSidePageHeader/MinSidePageHeader';
import { History } from 'src/areas/mobile/history/History';

export function HistoryPage() {
  return (
    <>
      <MinSidePageHeader title={t.mobile.menu.history} />
      <div className="container">
        <History />
      </div>
    </>
  );
}
