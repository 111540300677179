import React from 'react';
import cs from 'classnames';
import './TextField.scss';
import { Icon, IconDefinition } from '@telia/styleguide';
import { Loading } from '../Loading/Loading';
import { useTextField } from './useTextField';

export type TextFieldProps = {
  id?: string;
  icon?: IconDefinition;
  disabled?: boolean;
  value?: string;
  type?: 'text' | 'password' | 'email' | 'number';
  innerRef?: React.RefObject<HTMLInputElement>;
  readOnly?: boolean;
  'aria-label'?: string;
  'aria-activedescendant'?: string;
  'aria-autocomplete'?: 'none' | 'inline' | 'list' | 'both';
  'aria-controls'?: string;
  'aria-labelledby'?: string;
  autoComplete?: string;
  onFocus?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onBlur?: (event: React.FocusEvent<HTMLInputElement>) => void;
  onKeyDown?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void;
  onKeyUp?: (event: React.KeyboardEvent<HTMLInputElement>) => void;
  onMouseEnter?: (event: React.MouseEvent<HTMLInputElement>) => void;
  onMouseLeave?: (event: React.MouseEvent<HTMLInputElement>) => void;
  loading?: boolean;
  className?: string;
  required?: boolean;
  success?: boolean;
  error?: string;
  label?: string;
  helpText?: string;
  bordered?: boolean;
  /**
   * RegEx for the input value
   */
  pattern?: string;
  /**
   * For use with Downshift -- inputProps from DownShift are handled custom and so it does not break with TextField existing logic.
   * Downshifts event handler are added to the input event handlers
   * Downshifts ref has precedence over innerRef from props and local inputRef
   */
  inputProps?: any;
};

export const TextField: React.FC<TextFieldProps> = props => {
  const {
    ref,
    focus,
    setFocus,
    fieldGuid,
    onFocus,
    onBlur,
    onChange,
    onKeyDown,
  } = useTextField(props);

  const getStatusIcon = (): React.ReactNode => {
    const { error, success, loading } = props;
    /**
     * Render priority
     * 1. Success
     * 2. Error
     * 3. Loading
     */
    if (success) {
      return <Icon icon="check-mark" />;
    }
    if (error) {
      return <Icon icon="alert" />;
    }
    if (loading) {
      return <Loading size="xs" style={{ minHeight: '1rem' }} />;
    }
    return null;
  };

  const statusIcon = getStatusIcon();

  return (
    <div
      className={cs(
        {
          TextField,
          isPrepended: !!props.icon,
          success: !!props.success,
          error: !!props.error,
          disabled: !!props.disabled,
          focus,
          bordered: !!props.bordered,
        },
        props.className
      )}
    >
      <div className="TextField-input-wrapper" onClick={() => setFocus(true)}>
        <div className={'input-group'}>
          {props.icon ? (
            <div className="input-group-prepend">
              <span className="input-group-text">
                <Icon icon={props.icon} />
              </span>
            </div>
          ) : null}
          <input
            id={props.id}
            name={props.id}
            type={props.type || 'text'}
            className="form-control"
            disabled={props.disabled}
            value={props.value}
            ref={ref}
            aria-label={props['aria-label']}
            aria-activedescendant={props['aria-activedescendant']}
            aria-autocomplete={props['aria-autocomplete']}
            aria-controls={props['aria-controls']}
            aria-labelledby={fieldGuid.current}
            autoComplete={props.autoComplete}
            onFocus={onFocus}
            onBlur={onBlur}
            onChange={onChange}
            onKeyDown={onKeyDown}
            readOnly={props.readOnly}
            pattern={props.pattern}
          />
          {statusIcon ? (
            <div className="input-group-append">
              <span className="input-group-text">{statusIcon}</span>
            </div>
          ) : null}
        </div>
        {props.label ? (
          <label
            className={cs(
              'TextField-label',
              'animated',
              focus || props.value ? 'label-top' : 'label-root'
            )}
            id={fieldGuid.current}
          >
            {props.label}
            {props.required ? ' *' : null}
          </label>
        ) : null}
      </div>

      <small className={cs('TextField-subText')}>
        {props.error || props.helpText || ''}
      </small>
    </div>
  );
};
