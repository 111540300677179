import { SubscriptionGroup } from './../../../__types/graphql-global-types';
import { getSubscriptionDisplayTitles } from 'src/lib/types';
import { urlFor, SubscriptionGroupInUrl } from 'src/lib/ui/LinkTo/urlFor';
import { t } from 'src/lib/i18n';
type Breadcrumbs = Array<{ url: string; title: string }>;

/**
 * For a given subscription, return an array of its breadcrumbs.
 * @param subscription
 * @param options
 */
export const getSubscriptionBreadcrumbs: (
  /**
   * The subcription for which to extract breadcrumbs.
   */
  subscription: {
    id: string;
    name: string | null;
    alias: string | null;
    group: SubscriptionGroup | null;
    ownerAttributes?: null | {
      firstName: string | null;
      lastName: string | null;
      employeeNumber: string | null;
      position: string | null;
      email: string | null;
    };
    phoneNumbers?: null | Array<{
      type: number;
      phoneNumber: string;
      seriesStart: string | null;
      seriesEnd: string | null;
      validTo: any;
    }>;
    breadcrumbs?: null | Array<{
      id: string;
      name: string | null;
      alias: string | null;
      group: SubscriptionGroup | null;
      ownerAttributes?: null | {
        firstName: string | null;
        lastName: string | null;
        employeeNumber: string | null;
        position: string | null;
        email: string | null;
      };
      phoneNumbers?: null | Array<{
        type: number;
        phoneNumber: string;
        seriesStart: string | null;
        seriesEnd: string | null;
        validTo: any;
      }>;
    }>;
  },
  options?: {
    /**
     * Whether to include the subscription itself as a breadcrumb.
     */
    includeSelf?: boolean;
  }
) => Breadcrumbs = (subscription, options) => {
  const crumbs: Breadcrumbs = [];
  const opts = options || {};
  const translate = t.subscriptions.breadCrumbs;
  crumbs.push({
    url: urlFor({
      area: 'subscriptions',
      group: mapSubGroupToSubGroupInUrl(subscription),
    }),
    title: translate.title,
  });

  if (subscription.breadcrumbs && subscription.breadcrumbs.length >= 1) {
    for (let i = 0, j = subscription.breadcrumbs.length; i < j; i++) {
      const { title } = getSubscriptionDisplayTitles(
        subscription.breadcrumbs[i]
      );
      crumbs.push({
        url: urlFor({ subscription: subscription.breadcrumbs[i].id }),
        title: title,
      });
    }
  }

  if (opts.includeSelf === true) {
    const itself = getSubscriptionDisplayTitles(subscription);
    crumbs.push({
      url: urlFor({ subscription: subscription.id }),
      title: itself.title,
    });
  }
  return crumbs;
};

const mapSubGroupToSubGroupInUrl = (sub: {
  group: SubscriptionGroup | null;
}): SubscriptionGroupInUrl => {
  if (sub.group === 'mobile') {
    return 'mobil';
  } else if (sub.group === 'data') {
    return 'nettverk';
  } else if (sub.group === 'tele') {
    return 'telefoni';
  } else if (sub.group === 'tv') {
    return 'tv';
  }
  return 'alle';
};
