import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormSubmitBar } from 'src/lib/ui';
import { useUpdateBarringSettings } from '../mutations/useUpdateBarringSettings';
import { t } from 'src/lib/i18n';
import { BarringMobileSettings } from 'src/lib/types';
import { useToggler, ToggleAction } from '../useToggler';
import { BarOutgoingCalls } from './BarringSettings/BarOutgoingCalls';
import { BarData } from './BarringSettings/BarData';
import { BarIncomingCalls } from './BarringSettings/BarIncomingCalls';
import { BarCpa } from './BarringSettings/BarCpa';
import { BarPremiumServices } from './BarringSettings/BarPremiumServices';
import { colors } from 'src/lib/ui';

interface Props {
  barring: BarringMobileSettings;
  isOpen: boolean;
  toggle: () => void;
}

export type SectionProps = {
  state: BarringMobileSettings;
  toggleOption: (action: ToggleAction<BarringMobileSettings>) => void;
};

export function BarringSettingsFormModal(props: Props) {
  const [state, toggleOption] = useToggler<BarringMobileSettings>(
    props.barring
  );
  const { updateSettings, data, loading, error } = useUpdateBarringSettings();
  const i18n = t.mobile.settings.barring;

  const submit = () => {
    updateSettings(state);
  };

  useEffect(() => {
    if (data && !error) {
      props.toggle();
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={props.isOpen} size="lg" toggle={props.toggle}>
      <ModalHeader>{i18n.restrictUsage}</ModalHeader>
      <ModalBody>
        <small style={{ color: colors.greyDarkText }}>{i18n.description}</small>

        <BarData state={state} toggleOption={toggleOption} />

        <BarOutgoingCalls state={state} toggleOption={toggleOption} />

        <BarIncomingCalls state={state} toggleOption={toggleOption} />

        <BarCpa state={state} toggleOption={toggleOption} />

        <BarPremiumServices state={state} toggleOption={toggleOption} />
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          error={error}
          errorMsg={i18n.errorMsg}
          loading={loading}
          submit={submit}
          cancel={props.toggle}
        />
      </ModalFooter>
    </Modal>
  );
}
