import * as React from 'react';
import './SubscriptionCard.scss';

import { Subscription } from 'src/lib/types';
import SubscriptionStatusIcon from '../SubscriptionStatusIcon/SubscriptionStatusIcon';
import { colors } from 'src/lib/ui';
import { CaseBadges } from '../../Pages/Components';
import { getCpeStatus } from '../../utils/subscriptionUtils';
import { Icon } from '@telia/styleguide';

interface Props {
  subscription: Subscription;
}

export const SubscriptionCard: React.SFC<Props> = ({ subscription }: Props) => {
  const { deliveryStatus } = subscription;
  const cpeStatus = getCpeStatus(subscription);
  const { redundancies } = subscription;
  return (
    <div
      className={`SubscriptionCard simple ${
        deliveryStatus === 'terminated' ? 'SubscriptionCard--terminated' : ''
      }`}
    >
      <div className="d-flex align-items-center">
        <div className="pr-2">
          <SubscriptionStatusIcon cpeStatus={cpeStatus} />
        </div>
        <div
          className="SubscriptionCard-header"
          style={{
            maxWidth: '100%',
            paddingRight: '20px' /* To allow some space for text wrapping */,
          }}
        >
          {subscription.alias ? (
            <div
              style={{
                color: colors.black,
                fontSize: '16px',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {subscription.alias}
            </div>
          ) : null}
          <div
            style={{
              color: colors.black,
              fontSize: '16px',
            }}
          >
            {subscription.name} {subscription.subscriptionId}
          </div>
        </div>
      </div>
      {<CaseBadges subscription={subscription} />}
      {redundancies?.map(redundancy => (
        <div className="SubscriptionCard-backup py-1">
          <div className="d-flex align-items-center">
            <div className="py-1 px-2">
              <Icon
                className="SubscriptionCard-backup-icon"
                icon="arrow-subdirectory"
              />
            </div>
            <div className="d-flex align-items-center ellipsis">
              <div className="pr-2">
                <SubscriptionStatusIcon
                  cpeStatus={redundancy.cpe && redundancy.cpe.status}
                />
              </div>
              <div className="ellipsis" style={{ color: colors.black }}>
                {redundancy.name
                  ? `${redundancy.name} ${redundancy.subscriptionId} (${redundancy.group})`
                  : redundancy.subscriptionId}
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
