import { customerSupportEmail, customerSupportNumber } from './../../variables';
import Nb from '../../nb/Main/subscriptions';

const subscriptions: typeof Nb = {
  title: 'Services',
  unknown: 'Unknown',
  case: 'case',
  cases: 'cases',
  download: 'Download',
  allServices: 'All services',
  selectedServices: 'Selected services',
  sortFields: {
    lable: 'Sort',
    alias: 'Alias',
    status: 'Status',
    type: 'Type',
    updated: 'Last changed',
    address: 'Address',
    id: 'ID',
    name: 'Name',
    phoneNumber: 'Phone number',
    AtoÅ: 'A to Å',
    ÅtoA: 'Å to A',
    zeroToNine: '0 to 9',
    nineToZero: '9 to 0',
    descending: 'Descending',
    ascending: 'Ascending',
    mostRecent: 'Most recent',
    oldest: 'Oldest',
    importantFirst: 'important first',
    leastImportant: 'least important first',
  },
  showXofY: (showing: number, total?: number) =>
    `Showing ${showing} of ${total} services`,
  xCount: (count: number) => `${count} numbers`,
  noSubscriptions: 'We have no services to show you',
  groups: {
    data: 'Data',
    mobile: 'Mobile',
    tele: 'Telephony',
    other: 'Other',
  },
  taxModel: {
    department: {
      title: 'Department',
      description:
        'joint subscription used by multiple employees in a department.',
    },
    free: {
      title: 'Free',
      description: 'subscription for work and private use.',
    },
    office: {
      title: 'Office',
      description: 'subscription only used for work.',
    },
    personal: 'Personal',
    none: 'Not set',
  },

  SubscriptionConfiguration: {
    configuration: 'Configuration',
    tabs: {
      interfaces: 'Interfaces',
      addons: 'Addons',
      technicalInfo: 'Technical information',
    },
  },

  NoData: {
    weWereUnableToFetchData: 'We were unable to fetch data about',
    tryTo: 'Try to',
    reloadThePage: 'reload the page.',
    helpText:
      'If the error persists you can call customer service on +47 21 50 21 50, and we will help to resolve the issue.',
  },
  DifferentVendor: {
    title: 'We are unable to receive the usage data.',
    helpText:
      'The service is using a subscription and SIM-card from another provider',
  },
  filters: {
    combineTags: 'Combine tags',
    operationalStatus: 'Operational status',
    withFaults: 'With faults',
    withUnreportedFaults: 'With unreported faults',
    withPlannedWork: 'With planned work',
    withNoTags: 'Services without tags',
    deliveryStatus: {
      label: 'Delivery status',
      underDelivery: 'Under delivery',
      underTermination: 'Under termination',
    },
    cases: {
      label: 'Cases',
      withIncidents: 'With incident',
      withoutIncidents: 'Without incident',
      withPlannedWork: 'With planned work',
      withoutPlannedWork: 'Without planned work',
    },
    cpeType: {
      label: 'Cpe-model',
    },
    subscriptionType: {
      label: 'Type',
    },
    organisation: {
      label: 'Invoice center',
    },
  },

  tags: {
    tag: 'tag',
    tags: 'tags',
    changeButton: 'Change',
    changedTagsForServices: (numServices: number) =>
      `Changed tags for ${numServices} services`,
    errors: {
      addToSubscription: (subscription: string) =>
        `We could not add tags to service ${subscription}`,
      addTag: (tag: string) => `We could not add tag ${tag} to subscriptions `,
      genericAdd: 'We could not add tags to subscriptions',
      removeFromSubscription: (subscription: string) =>
        `We could not reomve tags from service ${subscription}`,
      removeTag: (tag: string) =>
        `We could not remove tag ${tag} from subscriptions `,
      genericRemove: 'We could not remove tags from subscriptions',
    },
    createNewTag: 'Create new tag',
  },

  status: {
    title: 'Status',
    updateStatus: 'Update status',
    reportIncident: 'Report incident',
    viewStatistics: 'Statistics',
    primary: 'Primary',
    redundancy: 'Redundancy',
    notMonitored: 'Not monitored',
    notInstalled: 'Not installed',
  },
  SubscriptionActiveIncidentCard: {
    opened: (date: string) => `Created ${date}`,
    status: (status: string) => `Incident ${status}`,
  },
  SubscriptionPlannedWorkCard: {
    plannedWorkHeader: 'Planned work',
    impact: 'Impact',
    hours: ' h ',
    expectedDowntime: 'Expected downtime',
    to: ' to ',
    noExpectedDowntime: 'Duration of downtime unknown',
    contact: `Any questions may be directed to the chat, by calling ${customerSupportNumber} or  by sending an email to ${customerSupportEmail}`,
    status: 'Status',
    reference: 'Reference number',
    startTime: 'Start time',
    endTime: 'End time',
    details: 'Details',
    close: 'Close',
  },
  SubscriptionCard: {
    withoutIncident: 'Without incident',
    incidentReported: 'Incident reported',
    labelType: 'Type',
    labelID: 'ID',
    labelServiceBandwidth: 'Bandwidth',
    labelPosition: 'Position',
    labelCpeType: 'cpe type',
  },
  SubscriptionPage: {
    service: 'service',
    // We want this to be empty (to avoid flashing this in the title e.g. after a session timeout), but be truthy (so it's rendered)
    noData: ' ',
    btnGiveAlias: 'Give this service an alias',
    btnSetTags: 'Tag this service',
    btnEditAlias: 'Edit alias',
    btnEditTags: 'Edit tags',
    btnEdit: 'Edit',
    btnEditCostCenter: 'Edit cost center',
    tabs: {
      summary: 'Summary',
      incidents: 'Incidents',
      cases: 'Cases',
      statistics: 'Statistics',
      documents: 'Documents',
    },
  },

  SubscriptionAbout: {
    title: 'About',
    alias: 'Alias',
    type: 'Type',
    id: 'ID',
    address: 'Address',
    connection: 'Connection',
    accessBandwidth: 'Access bandwidth',
    serviceBandwidth: 'Bandwidth',
    unusedCapacity: 'None - There is unused capacity on the line',
    sla: 'Service level agreement (SLA)',
    startTime: 'Created',
    endTime: 'Valid to',
    endTimeNotSet: 'Ongoing',
    supplier: 'Supplier',
    accessType: 'Access type',
    networkToNetworkInterface: 'NNI ODP',
  },
  M2MUsage: {
    operator: 'Operator',
    usage: 'usage',
    dataUsage: 'Data usage',
    subscriptionAndData: 'Subscription and data',
    speedReduction: 'Speed reduction after',
    subscription: 'Subscription',
    dataIncluded: 'Data included',
    ongoingUsage: 'Ongoing usage',
    dataUsed: 'Data used',
    mainSimCard: 'Main SIM-card',
    history: 'History',
    pastFourMonths: 'Past 4 months',
    showUsageDetails: 'Show usage details',
    showDetailAboutSubscription: 'Show detail about subscription',
  },
  SubscriptionCostDetails: {
    title: 'Cost preferences',
    costCenter: 'Cost center',
    changeCostSettings: 'Change cost preferences',
    errorMsg: `We were unable to complete the change. If the error persists you can call us on +47 ${customerSupportNumber}, and we will help you to solve it`,
    selectCostCenter: 'Select cost center',
    btnChange: 'Change',
    btnAdd: 'Add cost preferences',
    taxModel: 'Tax model',
  },

  SubscriptionCostCenterPicker: {
    title: 'Set cost center',
    filterPlaceholder: 'Search for cost center',
  },
  SubscriptionDeliveryStatusCard: {
    assigning: {
      status: 'The service is under delivery',
      statusShort: 'Under delivery',
      infoText:
        'You may find that the information here is incorrect or incomplete until the delivery is complete.',
    },
    terminating: {
      status: 'The service is under termination',
      statusShort: 'Under termination',
      infoText:
        'This service is being terminated and will soon be removed from the service list',
    },
    support: `If you have any questions about this you can contact us via chat, by calling ${customerSupportNumber} or email ${customerSupportEmail}`,
  },
  SubscriptionStatusSummaryCard: {
    checkingService: 'Checking service',
    isNormal: 'working as expected',
    noSignal: 'is down',
    statusUnknow: 'We do not know the status of the service',

    unknownPrimary: 'We do not know the status of primary',
    unknownRedundancy: 'We do not know the status of redundancy',
    notInstalled: 'The service has not been installed yet',

    serviceIsNormal: 'The service is working as expected',
    serviceNoSignal: 'The service is down',

    cpeModel: 'CPE-model',
    uptime: 'Uptime CPE',
    responsetime: 'Response time',
    title: 'Status',
    primary: 'Primary',
    redundancy: 'Redundancy',
    btnReportError: 'Report incident',
    btnViewStatistics: 'View statistics',
    btnRefetch: 'Check status',
    btnRefetching: 'Checking status and pinging services...',
    gsm: {
      title: 'Signal',
      band: 'Band',
      channelNumber: 'Channel number',
      timeStamp: 'Last checked',
    },
  },
  SignalCard: {
    bandNumber: 'Band',
    channelNumber: 'Channel',
    title: 'Signal',
    strength: 'Strength(RSSI)',
    quality: 'Quality(EC/IO)',
    showDetailedSignalStatistics: 'Show detailed signal statistics',
    signal: 'signal',
    signalType: {
      unknown: 'Unknown',
      gen4: '4G',
      gen3: '3G',
      gen2: '2G',
    },
    lastUpdated: (time: string) => `Last updated ${time} ago`,
  },
  SubscriptionStatusItem: {
    noStatus: 'No status',
  },
  ConnectionStretch: {
    startPoint: 'Start point',
    endPoint: 'End point',
    stretch: 'Stretch',
  },
  NotMonitored: {
    title:
      'This service is not set to monitoring and we therefore have no status information.',
    description: `Do you think this is an error? Contact us via chat, by calling ${customerSupportNumber} or sending a email to ${customerSupportEmail}. Remember to provide the ID of the service.`,
  },
  Documents: {
    title: 'Documents',
  },
  SubscriptionCpeCard: {
    title: 'Equipment',
    cpeModel: 'CPE model',
    uptime: 'Uptime on CPE',
    days: 'days',
  },
  breadCrumbs: {
    title: 'Services',
    singleService: 'Service',
  },
  Incidents: {
    header: 'Incidents for',
    title: 'Incidents',
    noIncidnets: 'There are no incidents on this service',
    showing: (count: number) => `Showing ${count} incidents`,
  },

  SubscriptionInterfaces: {
    status: 'Status',
    interface: 'Interface',
    VPNVRF: 'VPN/VRF',
    alias: 'Alias',
    devices: 'Devices',
    title: 'Interfaces',
    criticalError: 'Critical error',
    showLess: 'Show less',
    showAll: (count: number) => `Show all ${count} interfaces`,
  },
  Addons: {
    title: 'Addons',
    noAddons: 'There are no addons for this service',
    status: 'Status',
    alias: 'Alias',
    type: 'Type',
    vpn: 'VPN/VRF',
  },
  TechnicalDetailsCard: {
    title: 'Technical information',
    lanIp: 'LAN IP',
    wanIp: 'WAN IP',
    dmVpnIp: 'DMvpn IP',
    staticRouting: 'Static routing',
    mpls: 'Multiprotocol Label Switching (MPLS)',
    vrf: 'VRF',
    vrfName: 'VRF-name',
    vrfAlias: 'VRF-alias',
    vrfId: 'VRF-id',
    ngfw: {
      header: 'New generation firewall (NGFW)',
      protectionPackage: 'Protection package',
      totalCapacity: 'Total capacity',
      location: 'Location',
      binding: 'Binding',
      httpsInspection: 'HTTPS inspection',
      idAwareness: 'ID awareness',
      eventReports: 'Event reports',
      logApi: 'Log api',
    },
    subnetMask: 'Subnet mask',
    interfaceMode: 'Interface mode',
    interfaceId: 'Interface-ID',
    cpeAddress: 'CPE address',
    hsrpIpAddress: 'HSRP IP address',
    gateway: 'Gateway',
    networkAddress: 'Network address',
    noRedist: 'None',
    editLanIP: {
      edit: 'Edit',
      changeLanIP: 'Change LAN IP-addresses',
      description: (subsId: string) =>
        `Choose which LAN IP-addresses should be available at IP VPN ${subsId}`,
      changeAddress: 'Changde addresses',
      address: 'Address',
      whatsNext: 'What happens next?',
      whatsNextDescription:
        'When you complete the changes, they will be sent to one of our technicians. You will get a confirmation in the order overview once the change is beeing applied. There you can keep track of what happens next.',
      submitLabel: 'Complete changes',
      changeSubmitted: 'Changes have been sendt',
      submittedDescription: [
        'You will receice a confirmation in the order overview once the changes are beeing applied. There you can see more information regarding your order and when it will take effect.',
        'In the confirmation you will also get more information about the process of these changes.',
      ],
      goBackToService: 'Go back to the service',
    },
    editIpRouting: {
      edit: 'Edit',
      close: 'Close',
      changeIpRouting: 'Change routing',
      submittedDescription: [
        'You will receice a confirmation in the order overview once the changes are beeing applied. There you can see more information regarding your order and when it will take effect.',
      ],
      description:
        'By setting routing address you can define how you want traffic to flow in the netwerk. With static routing you avoid the overhead in dynamic routing.',
      onCompleteDescription:
        'When you complete the changes, they will be sent to one of our technicians. You will get a confirmation in the order overview once the change is beeing applied. There you can keep track of what happens next.',
      submitLabel: 'Complete changes',
    },
  },
  TeleSubscriptionPage: {
    phoneNumbers: 'Numbers',
  },
  TeleSubscriptionAbout: {
    heading: 'Details',
    id: 'ID',
    type: 'Type',
    endTime: 'Valid until',
    sla: 'SLA',
    noEndTime: 'Ongoing',
  },
  TvSubscriptionAbout: {
    heading: 'Details',
    alias: 'Alias',
    aliasNotSet: 'not set',
    id: 'ID',
    type: 'Service type',
    createdAt: 'Created at',
    validUntil: 'Valid until',
    validUntilNotSet: 'Ongoing',
    customerType: 'Customer type',
    distributionTech: 'Distribution technology',
    modelType: 'Model type',
    hdmiCount: 'HDMI count',
    tags: 'Tags',
    editAlias: 'Edit alias',
    editTags: 'Edit tags',
  },
  TvSubscriptionPage: {
    text:
      'Telia Visning allows the company to display content on digital display surfaces, whether commercial channels or their own content. The service consists of TV viewing, info channel and digital signage.',
    contentServices: 'Content services',
  },
  TvSubscriptionContentList: {
    outletsAvailable: 'Num. of available outlets',
    seatsAvailable: 'Num. of seats allowed',
    boxes: 'Num. of Get-boxes',
    numAvailable: 'Num. available',
    prefix: {
      mainChannel: 'TV basic package:',
      extraChannel: 'Additional channel:',
      addon: 'TV Add-on:',
    },
  },
  AdditionalInformationCard: {
    title: 'Additional information',
    btnEdit: 'Edit',
    btnAdd: 'Add additional information',
  },
  AboutMobileSubscriptionUserCard: {
    title: 'Service user',
    name: 'Name',
    employeeNumber: 'Employee number',
    employeePosition: 'Position',
    phoneNumber: 'Phone number',
    email: 'Email',
    btnEdit: 'Edit',
    directoryListing: 'Directory listing',
  },
  TechnicalContactsCard: {
    title: 'Contacts',
    phoneNumber: 'Tel.',
    noContacts: 'No contacts on this subscription',
    searchPlaceholder: 'Search for contact',
    editTitle: 'Edit contacts',
    btnEdit: 'Edit',
    btnAdd: 'Add contacts',
    editDescription:
      'You can add up to three contacts on a subscription. If you would like to add a new contact, you first need to make a contact card for this person.',
    editError: 'Could not update contacts',
  },
  GenericSubscriptionPage: {
    aboutSubscription: 'About the service',
  },
  EditAdditionalInformationForm: {
    title: 'Edit additional information for this service',
    noValue: 'No value chosen',
    alternatives: 'Alternatives',
    errorMsg:
      'We were unable to perform the update for you. If the error persists you can call customer service on +47 21 50 21 50, and we will help to resolve the issue',
  },
  AboutMobileSubscriptionCard: {
    details: 'Details',
    subscription: 'Subscription',
    fixedCost: 'Fix cost',
    dataIncluded: 'Included data',
    freeUse: 'free use',
    started: 'Started',
    validTo: 'Valid to',
    continuous: 'running agreement',
    serviceType: 'Service type',
    id: 'ID',
    cancelSubscription: 'Cancel subscription',
  },
  EditSubscriptionAlias: {
    description: 'Edit alias for this service',
    alias: 'Alias',
    toggleBtnEdit: 'Change alias',
    toggleBtnAdd: 'Give the service an alias',
  },
  SubscriptionTagsPicker: {
    title: 'Edit tags for this service',
    filterPlaceholder: 'Search for a tag',
    toggleBtnEdit: 'Change tags',
    toggleBtnAdd: 'Add tags',
  },
  ArpTable: {
    title: 'ARP-table',
    interface: 'Interface',
    mac: 'MAC',
    ipAddress: 'IP address',
    showLess: 'Show less',
    showAll: 'Show all',
    noConnectedDevices: 'No connected devices',
    close: 'Close',
    update: 'Update',
  },
  StatisticsSection: {
    title: 'Statistics for',
    noStatistics: 'There is no statistics for this service',
    noSubscription: 'Could not fint service',
  },
  EditSubscriptionUserForm: {
    description: 'Edit information about user',
    user: 'Subscription user',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    postalCode: 'Postal code',
    postalArea: 'Postal area',
    country: 'Country',
    employeeNumber: 'Emplyee number',
    employeePosition: 'Position',
    street: 'Street address',
    noAddress: 'No listing',
    userAddress: 'Useres own directory listing',
    businessAddress: 'Business address',
    btnSave: 'Save',
    directoryListing: 'Directory listing',
  },
  ReportIncidentSection: {
    title: 'Report incident for',
  },
  Invoices: {
    title: 'Invoices',
  },
  MobileSubscription: {
    dataUsage: 'Data usage',
    viewInvoices: 'View invoices',
    summary: 'Summary',
    settings: 'Settings',
    usage: 'Usage',
  },
  ConnectedSubscription: {
    subscriptionIsUsedBy: 'Subscription is used by',
  },
  cpeStatus: {
    normal: 'Online',
    critical: 'Offline',
    noStatus: 'No status',
    unknown: 'Unknown',
    statusNull: 'Not monitored',
    redundancyOnline: 'Redundancy is online',
    redundancyOffline: 'Redundancy is offline',
    notInstalled: 'Not installed',
  },
  deliveryStatus: {
    assigning: 'Under delivery',
    active: 'Normal',
    terminating: 'Under termination',
    terminated: 'Terminated',
  },
  getSubscriptionDisplayTitles: {
    aNumber: 'A-number Tel: ',
    aNumberSerie: 'A-number series Tel: ',
    phoneNumber: 'Tel. ',
  },
  ListSubscriptionsByGroup: {
    header: 'Services',
    data: {
      title: 'Networks',
    },
    mobile: {
      title: 'Mobile',
    },
    tele: {
      title: 'Telephony',
    },
    tv: {
      title: 'Tv',
    },
    all: {
      title: 'All services',
    },
    filters: {
      query: {
        label: 'Filter',
        helpText: 'Enter to filter the list',
      },
      status: {
        label: 'Service filtering',
        placeholder: 'Show services with...',
      },
      tags: {
        label: 'Tags',
        placeholder: 'Filter on tags',
      },
    },
    noResultMessage: {
      text: 'No services',
    },
  },
  DataSubscriptionTable: {
    status: 'Status',
    cases: 'Cases and additional info',
    incident: 'Incident',
    cpeModel: 'Cpe model',
  },
  MobileSubscriptionTable: {
    name: 'Name',
    phoneNumber: 'Phone number',
    deliveryStatus: 'Delivery status',
  },
  TeleSubscriptionsList: {
    phoneNumber: 'Phone number',
  },
  TeleSubscriptionTable: {
    phoneNumber: 'Phone number',
    deliveryStatus: 'Delivery status',
  },
  GenericSubscriptionTable: {
    service: 'Service',
    address: 'Address',
    cases: 'Cases and additional info',
    tags: 'Tags',
    invoiceCenter: 'Invoice center',
    costCenter: 'Cost center',
  },
  DefaultAboutCard: {
    heading: 'Details',
    type: 'Type',
    id: 'ID',
    created: 'Created',
    validUntil: 'Valid to',
    validNotSet: 'Ongoing agreement',
    accessBandwidth: 'Access bandwidth',
  },
  DataWithStatusAbout: {
    heading: 'Details',
    alias: 'Alias',
    type: 'Type',
    id: 'ID',
    equipment: 'Equipment',
    access: 'Access',
    accessBandwidth: 'Access bandwidth',
    created: 'Created',
    serviceBandwidth: 'Bandwidth',
    transmissionNumber: 'Transmission number',
    sla: 'Service level agreement',
    validUntil: 'Valid until',
    validUntilNotSet: 'Ongoing',
    supplier: 'Supplier',
    networkToNetworkInterface: 'NNI ODP',
  },
  layout: {
    SubscriptionPageHeader: {
      position: 'Position',
    },
    SubscriptionReportIncidentButton: {
      preText: 'Do you experience that something is not working properly?',
      link: 'Report error',
      postText: 'and we will help you.',
    },
    SubscriptionLinkToIncidentTab: {
      text: 'See more incidents on this subscription',
    },
  },
  terminate: {
    terminateSubscription: 'Terminate service',
    description:
      'If you are sure you want to terminate this subscription, you can complete below.',
    invoicePeriodDescription: (date: string) =>
      `The service will be taken down 3 months after the current invoice period is ended on ${date}`,
    chooseDateForShutdown:
      'If you want the service to be taken down before the invoice period is up, you can set an earlier date.',
    dateForShutdown: 'Date for shutdown',
    confirmationDescription:
      'Once you complete the termination, it will be handled shortly. You will then get a confirmation with more information in your order overview.',
    goBack: 'Go back',
    completeTermination: 'Complete termination',
    serviceTerminated: 'Service is terminated',
    submittedDescription: [
      'You will receive a confirmation of the termination here in Min Portal. There you can see more information regarding your ordered termination and when it will take effect.',
    ],
    close: 'Close',
  },
  SdWanSummary: {
    title: 'Manage SD-WAN',
    description: 'You can manage SD-WAN services in the SD-WAN application.',
  },
};

export default subscriptions;
