import gql from 'graphql-tag';

export const ClusterFragment = gql`
  fragment ClusterSearchResult on Cluster {
    id
    hasFault
    members {
      id
    }
  }
`;
