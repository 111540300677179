import React, { useState, useRef, useEffect } from 'react';
import {
  useQueryParameters,
  getFilterCountFromSearchFilter,
  useRouter,
} from 'src/lib/utils';
import { Feature } from 'src/lib/ui';
import { useSearch } from '../search';
import { Subscription, Cluster } from 'src/lib/types';
import { useSubscription } from 'src/areas/main/subscriptions/queries/useSubscription';
import { t } from 'src/lib/i18n';
import { SearchDomain } from 'src/__types/graphql-global-types';
import { Map } from './lib/Map/Map';
import { OldMap } from './lib/Map/OldMap';
import { Sidebar } from './lib/Sidebar/Sidebar';
import './Locations.scss';

/**
 * Map bounds are used to set the location filter in the cluster search.
 * This sets the bound for Norway
 */
const defaultMapBounds =
  '69.48011139008682;-22.42480468750003;59.7002068512696;42.96582031249997';

export default () => {
  const [mapBounds, setMapBounds] = useState(defaultMapBounds);
  const [searchSize, setSearchSize] = useState(1000);
  const [openSidebar, setOpenSidebar] = useState(true);
  const locationRef = useRef<HTMLDivElement>(null);
  const { set, get, getArray } = useQueryParameters();
  const query = get('query') ?? '';
  const setQuery = (query: string) => set('query', query);
  const router = useRouter<{ subscriptionId: string }>();

  useEffect(() => {
    const savedSearchSize = localStorage.getItem('Locations.searchSize');
    if (savedSearchSize) {
      const size = parseInt(savedSearchSize, 10);
      setSearchSize(size);
    }
  }, []);

  const filters = [
    { filter: 'location', value: mapBounds.split(';') },
    {
      filter: 'tag',
      value: getArray('tag'),
    },
    {
      filter: 'cases',
      value: getArray('cases'),
    },
    { filter: 'status', value: getArray('status') },
    {
      filter: 'cpeType',
      value: getArray('cpeType'),
    },
    {
      filter: 'type',
      value: getArray('type'),
    },
    {
      filter: 'deliveryStatus',
      value: getArray('deliveryStatus'),
    },
    {
      filter: 'group',
      value: getArray('group'),
    },
  ];

  const search = useSearch({
    types: [SearchDomain.subscription],
    filter: filters,
    size: searchSize,
    query: query,
  });

  const clusterSearch = useSearch({
    types: [SearchDomain.subscription],
    cluster: true,
    filter: filters,
    query: query,
    size: 10000,
  });
  const activeSubscriptionData = useSubscription(
    router.match.params.subscriptionId
  );

  const activeSubscription = activeSubscriptionData?.subscription;
  const subscriotionList = search.results as Subscription[];
  const subscriptionClusters = clusterSearch.results as Cluster[];

  const getActiveFilterCount = () => {
    const allFilters = getFilterCountFromSearchFilter(
      search.filter?.filter(filter => filter.filter !== 'location')
    );

    /**
     * These filters are particularly important,
     * hence extracted from the hidden filtes.
     * We do not include them in the filter count
     */
    const hasPlannedWorkFilter = getArray('cases').includes('withPlannedWork');
    const hasOfflineFilter = getArray('status').includes('critical');
    return (
      allFilters - (hasPlannedWorkFilter ? 1 : 0) - (hasOfflineFilter ? 1 : 0)
    );
  };

  return (
    <>
      <h1 className="sr-only">{t.locations.h1SrOnly}</h1>
      <div className="Locations-mapwrap" ref={locationRef}>
        <Feature
          feature="heat-map-cluster"
          fallback={
            <OldMap
              searchResult={subscriptionClusters}
              setMapBounds={setMapBounds}
              activeSubscription={activeSubscription}
              openSidebar={() => setOpenSidebar(true)}
              subscriptions={subscriotionList}
            />
          }
        >
          <Map
            locationRef={locationRef.current}
            clusters={subscriptionClusters}
            setMapBounds={setMapBounds}
            defaultBounds={defaultMapBounds}
            activeSubscription={activeSubscription}
            openSidebar={() => setOpenSidebar(true)}
            subscriptions={subscriotionList}
          />
        </Feature>

        <Sidebar
          searchResult={subscriotionList}
          locationRef={locationRef.current}
          isLoading={search.loading}
          numberOfResults={search.totalResults}
          activeSubscription={activeSubscription}
          isSidebarOpen={openSidebar}
          toggleSidebar={() => setOpenSidebar(!openSidebar)}
          query={query}
          setQuery={setQuery}
          totalFiltersActive={getActiveFilterCount()}
        />
      </div>
    </>
  );
};
