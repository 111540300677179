import React from 'react';
import { StandardStatisticsPeriod } from '../../../queries/WithStatistics';
import { colors } from 'src/lib/ui';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { Area } from 'recharts';
import { t } from 'src/lib/i18n';

export const ResponseTimeChartElements = () => [
  <Area
    key="1"
    stroke={colors.orange}
    strokeWidth="1"
    fill={colors.orange}
    fillOpacity="0.2"
    dataKey="responseTime"
    name={t.statistics.Legend.responseTime}
  />,
];

/**
 * Area filled dot
 * Line circle
 */
export const ResponseTimeTooltip = ({
  data,
  hideTimestamp,
}: {
  data: StandardStatisticsPeriod;
  hideTimestamp?: boolean;
}) => {
  const i18n = t.statistics.StandardDetails;
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header="">
        <SectionItem
          title={i18n.responseTimeAvg}
          color={colors.orange}
          value={
            data.responseTime
              ? `${data.responseTime.toFixed()} ms`
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};
