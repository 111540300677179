import * as React from 'react';
import { useDeleteFile } from '../mutations/useDeleteFile';
import Form from 'reactstrap/lib/Form';
import { FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { File } from 'src/lib/types';
import { useRestoreFile } from '../mutations/useRestoreFile';
import { getUndoDeleteFileAction } from 'src/areas/main/documents/undo/UndoActions';
import undoPopup from 'src/areas/main/documents/undo/UndoPopup';
import { ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  cancel: () => void;
  file: File;
}

const DeleteFileForm = (props: Props) => {
  const context = useDeleteFile();
  const { restoreFile } = useRestoreFile();

  return (
    <Form
      onSubmit={e => {
        e.preventDefault();
        context
          .deleteFile(props.file.id)
          .then(() =>
            undoPopup(getUndoDeleteFileAction(props.file, restoreFile))
          );
      }}
    >
      <ModalBody>
        <div>
          <p>
            {t.documents.DeleteFolderForm.confirmDelete}{' '}
            <b>{props.file.name}</b>?
          </p>
        </div>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          cancel={props.cancel}
          loading={context.loading}
          submitLabel={t.documents.DeleteFolderForm.submit}
          error={!!context.error}
        />
      </ModalFooter>
    </Form>
  );
};
export default DeleteFileForm;
