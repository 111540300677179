import * as React from 'react';
import { useCustomer } from 'src/lib/global';
import { t } from 'src/lib/i18n';
import { DashboardGrid } from './DashboardGrid';
import DashboardFooter from './DashboardFooter';
import { DashboardPlannedWork } from './PlannedWork/DashboardPlannedWork';
import { useFeature } from 'src/lib/utils/useFeature';
import { PersonalizedDashboard } from './new/PersonalizedDashboard';
import { Icon } from '@telia/styleguide';

export default function Dashboard() {
  const customer = useCustomer();

  const useNewPersonalizedDashboard = useFeature('personalized-dashboard');
  const showBanner = useFeature('show-banner');

  if (useNewPersonalizedDashboard) {
    return <PersonalizedDashboard />;
  }

  return (
    <div className="Dashboard">
      <div className="container">
        {showBanner && (
          <div
            style={{
              backgroundColor: '#990ae3',
              padding: '0.8rem',
              color: 'white',
              fontSize: '18px',
              display: 'flex',
              alignItems: 'center',
              borderRadius: '6px',
            }}
          >
            <Icon
              icon="alert"
              className="pr-2"
              style={{ width: '40px', height: '40px' }}
            />
            <div>{t.dashboard.bannerText.message}</div>
          </div>
        )}
        <h1 className="Dashboard-greeting mt-5 mb-4">
          {t.dashboard.Dashboard2.greeting(customer.auth.user?.name)}
        </h1>

        <p className="my-4">{t.dashboard.Dashboard2.welcome(customer.name)}</p>

        <DashboardGrid />
        {customer.id && <DashboardPlannedWork />}
        <DashboardFooter />
      </div>
    </div>
  );
}
