import React from 'react';
import { TextField, colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { configKeys } from './useEditLanIp';
import { SaveOverlay } from 'src/areas/main/contacts/lib/SaveOverlay/SaveOverlay';
import { ipLanInputConstraints } from './validations';
import { LanIp } from './../../../../../../lib/types/subscription/Cpe';

export const LanIpChange = (props: {
  ipAddresses: LanIp[];
  subscription: {
    id: string;
  };
  loading: boolean;
  handleChange: (payload: {
    configKey: keyof LanIp;
    value: string;
    index: number;
  }) => void;
  handleSubmit: (subsId: string) => void;
  toggle: () => void;
  disableSubmit: boolean;
}) => {
  const i18n = t.subscriptions.TechnicalDetailsCard;
  return (
    <>
      <div className="border-bottom px-4 pb-5">
        {i18n.editLanIP.description(props.subscription.id)}
      </div>
      <div className="py-5 px-4">
        <div className="pb-4" style={{ fontSize: '1.25rem' }}>
          {i18n.editLanIP.changeAddress}
        </div>
        {props.ipAddresses.map((ip, index) => (
          <div key={index}>
            <div className="pb-2">
              {i18n.editLanIP.address} {index + 1}
            </div>
            <div className="d-flex">
              {configKeys.map(key => (
                <TextField
                  key={`${key}-${index}`}
                  className="pr-3"
                  bordered={true}
                  pattern={ipLanInputConstraints[key]}
                  value={ip[key] ?? ''}
                  onChange={e => {
                    if (!e.target.validity.patternMismatch) {
                      return props.handleChange({
                        configKey: key,
                        value: e.target.value,
                        index,
                      });
                    }
                  }}
                  label={i18n[key]}
                />
              ))}
            </div>
          </div>
        ))}
      </div>

      <div
        className="p-4 mb-5 rounded-lg"
        style={{ backgroundColor: colors.greyMedium }}
      >
        <div className="pb-2 font-weight-bold">{i18n.editLanIP.whatsNext}</div>
        {i18n.editLanIP.whatsNextDescription}
      </div>
      <SaveOverlay
        submit={() => props.handleSubmit(props.subscription.id)}
        cancel={props.toggle}
        submitLabel={i18n.editLanIP.submitLabel}
        loading={props.loading}
        disabled={props.disableSubmit}
      />
    </>
  );
};
