import React, { useState } from 'react';
import { SettingsCard } from './SettingsCard';
import { SettingsListItem } from './SettingsListItem';
import { useSubscriptionOriginDetails } from 'src/areas/mobile/lib/useSubscriptionOriginDetails';
import { OriginFromModal } from 'src/areas/mobile/settings/forms/OriginFormModal';
import { t } from 'src/lib/i18n';

const OriginSection = () => {
  const [isModalOpen, setModalOpen] = useState(false);
  const { data } = useSubscriptionOriginDetails();
  const origin = data?.subscription?.origin;
  const address = origin
    ? (
        (origin.streetAddress || '') +
        ', ' +
        (origin.postCode || '') +
        ' ' +
        (origin.postArea || '') +
        ' ' +
        (origin.country || '')
      ).trim()
    : '';

  const name = origin
    ? `${origin.firstName || ''} ${origin.lastName || ''}`
    : '';
  const i18n = t.mobile.settings.origin;
  if (origin) {
    return (
      <SettingsCard
        title={i18n.title}
        description={i18n.description}
        onClick={() => setModalOpen(true)}
      >
        <SettingsListItem title={t.mobile.settings.name} description={name} />
        <SettingsListItem
          title={t.mobile.settings.address}
          description={address}
        />
        <OriginFromModal
          open={isModalOpen}
          toggle={() => setModalOpen(!isModalOpen)}
          origin={origin}
        />
      </SettingsCard>
    );
  }
  return null;
};

export default OriginSection;
