import React from 'react';
import { useHistory, useLocation } from 'react-router';
import OrderingFooter from '../layout/OrderingFooter';
import { Process, Step } from 'src/lib/flow';
import Step1, { Step1Values } from './Step1';
import Step2, { Step2Values } from './Step2';
import Step3, { Step3Values } from './Step3';
import Step4, { Step4Values } from './Step4';
import Step5, { Step5Values } from './Step5';
import Step6, { Step6Values } from './Step6';

import { OrderIpVpnSummary } from './OrderIpVpnSummary';

import { useCustomer } from 'src/lib/global';
import { usePocIpVpnMutation } from './mutation';
import { OrderContextProvider } from './context';
import OrderError from './OrderError';

import { OrderReceipt } from './OrderReceipt';

import * as dateUtils from 'src/lib/utils/dateUtils';

export interface OrderIpVpnState
  extends Step1Values,
    Step2Values,
    Step3Values,
    Step4Values,
    Step5Values,
    Step6Values {}

export function OrderIpVpn() {
  const c = useCustomer();
  const history = useHistory();
  const location = useLocation();
  const [mutation, mutationResult] = usePocIpVpnMutation();

  return (
    <OrderContextProvider>
      {ctx => {
        if (mutationResult.error) {
          return <OrderError />;
        }

        if (mutationResult.data?.createNetworkOrder?.errors?.length) {
          return <OrderError />;
        }

        if (mutationResult.data?.createNetworkOrder?.success === true) {
          return <OrderReceipt />;
        }

        return (
          <Process<OrderIpVpnState>
            initialValues={{
              useGnrBnr: false,
              address: '',
              bnr: '',
              knr: '',
              gnr: '',
              postCode: '',
              country: 'Norge',
            }}
            footer={({ flow }) => (
              <>
                {flow.currentStep === flow.steps.length && (
                  <OrderIpVpnSummary />
                )}

                <OrderingFooter
                  enableSubmit={
                    !flow.isSubmitting && flow.currentStep === flow.steps.length
                  }
                  next={flow.submit}
                  cancel={async () => {
                    await flow.cancel();
                    history.push('/bestilling');
                  }}
                  save={async () => {
                    await flow.save();
                    history.push('/bestilling');
                  }}
                />
              </>
            )}
            steps={
              [Step1, Step2, Step3, Step4, Step5, Step6] as Array<
                Step<OrderIpVpnState>
              >
            }
            onSubmit={(values, actions) => {
              return mutation({
                variables: {
                  input: {
                    customerId: c.id,
                    organisationId: values.organisationId || '',
                    address: values.address,
                    postalCode: values.postCode,
                    country: values.country,
                    gnr: values.gnr,
                    bnr: values.bnr,
                    municipality: values.knr,
                    floor: values.floor,
                    needsWiring: Boolean(values.needsWiring),
                    accessBandwidth: values.accessBandwidth || '',
                    siteContactId: values.siteContact?.id ?? '',
                    deliveryDate: values.deliveryDate
                      ? dateUtils.convertDMYtoYMD(values.deliveryDate)
                      : '',
                    additionalInformation: values.additionalInformation,
                  },
                },
              });
            }}
            setFlowId={(id: string) => {
              history.replace({
                pathname: location.pathname,
                search: `continue=${id}`,
              });
            }}
            url={location.pathname}
          />
        );
      }}
    </OrderContextProvider>
  );
}
