const layout = {
  menu: {
    home: 'Hjem',
    services: 'Tjenester',
    servicesData: 'Nettverk',
    servicesMobile: 'Mobil',
    servicesTele: 'Telefoni',
    servicesOther: 'Andre tjenester',
    map: 'Kart',
    incidents: 'Feilmeldinger',
    cost: 'Kostnader',
    invoices: 'Fakturaer',
    orders: 'Ordre',
    ordering: 'Bestilling',
    contacts: 'Kontakter',
    documents: 'Dokumenter',
    reports: 'Rapporter',

    mobileSubscriptionsHeading: 'Abonnement',
  },

  AreaSelectorDropdown: {
    openButton: 'Bytt applikasjon',
    heading: 'Bytt applikasjon',
  },

  ProfileDropdown: {
    openButton: 'Åpne meny',
    contactDetails: 'Kontaktinformasjon',
    switchToNorwegian: 'Bytt til norsk',
    switchToEnglish: 'View in English',
    privacyStatement: 'Personvern',
    privacyStatementLink:
      'https://www.telia.no/personvern/personvern-for-bedrift/',
    logOut: 'Logg ut',
  },

  SearchField: {
    label: 'Søk',
    searchInputPlaceholder: 'Søk',
  },

  SearchPicker: {
    searchInputPlaceholder: 'Søk',
  },

  MainApp: {
    customersHeading: 'Kunde',
    search: 'Søk',
    closeMenu: 'Lukk meny',
  },

  NotFoundPage: {
    pageNotFound: 'Kunne ikke finne siden',
  },

  CustomerSelection: {
    searchForCustomer: 'Søk etter kunde',
    selectCustomer: 'Velg kunde',
    nHits: (count: number) => `${count} treff`,
  },

  CustomerSelector: {
    searchListHeader: 'Søk:',
    searchForCustomerPlaceholder: 'Søk etter kunde',
    yourCustomers: 'Dine kunder:',
  },

  FilterSection: {
    fullscreenCloseBtn: 'Lukk',
    filter: 'Filter',
    searchInputPlaceholder: 'Filtrer',
    searchInputListPlaceholder: 'Søk i liste',
    searchInputTablePlaceholder: 'Søk i tabell',
    showList: 'Vis liste',
    showTable: 'Vis tabell',
  },
};

export default layout;
