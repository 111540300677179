import React from 'react';
import { User } from 'src/lib/global';

const AuthContextDefinition = React.createContext<AuthContext>(
  {} as AuthContext
);

const { Provider } = AuthContextDefinition;

export type AuthContext = {
  user?: User;
  idToken?: string;
  bearerToken?: string;
  isAuthenticated: boolean;
  isSigningIn: boolean;
  isSigningOut: boolean;
  isLoading: boolean;
  requestSignIn: () => void;
  requestSignOut: () => void;
};

export function useAuthContext() {
  return React.useContext(AuthContextDefinition);
}

export { Provider as AuthContextProvider };
