import * as React from 'react';
import cs from 'classnames';

interface Props {
  children?: React.ReactNode;
  className?: string;
  linkClassName?: string;
  onClick?: () => void;
}

export class NavButton extends React.PureComponent<Props> {
  click = (e: React.SyntheticEvent) => {
    if (this.props.onClick) {
      e.preventDefault();
      this.props.onClick();
    }
  };

  render() {
    return (
      <li className={cs('nav-item', this.props.className)}>
        <button
          className={cs('nav-link', this.props.linkClassName)}
          onClick={this.click}
        >
          <span>{this.props.children}</span>
        </button>
      </li>
    );
  }
}
