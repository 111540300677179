import * as React from 'react';
import { Modal, ModalBody, ModalHeader, ModalFooter } from 'reactstrap';
import { useSubscriptionOrder_customer_order_orderLines } from '../queries/__types/useSubscriptionOrder';
import { Attributes, Attribute, colors, Button } from 'src/lib/ui';
import { formatDate, formatInstallationAddress } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
import { mapOrderStatusToLabel } from '../utils';
import { pickMostPreciseDeliveryDate } from './pickMostPreciseDeliveryDate';

interface OrderLineSubscriptionModalProps {
  isOpen: boolean;
  toogle: () => void;
  onClose: () => void;
  orderLine: useSubscriptionOrder_customer_order_orderLines | undefined;
  parentLabel: string | undefined;
}

export const OrderLineSubscriptionModal: React.FunctionComponent<OrderLineSubscriptionModalProps> = ({
  isOpen,
  toogle,
  orderLine,
  onClose,
  parentLabel,
}) => {
  const i18n = t.orders.ServiceOrderContent.OrderLineSubscriptionModal;

  const mainSubId = orderLine?.mainSubscriptionId;
  const orderLineSubscriptions = orderLine?.subscriptions?.filter(sub => {
    return sub.id !== mainSubId;
  });

  const mostPreciseDeliveryDate = pickMostPreciseDeliveryDate(orderLine?.dates);

  return (
    <Modal toggle={toogle} isOpen={isOpen} onClosed={onClose} size="lg">
      {orderLine ? (
        <>
          <ModalHeader>{`${orderLine.description} (ID ${orderLine.id})`}</ModalHeader>
          <ModalBody>
            {parentLabel ? (
              <>
                <div className="mb-2">
                  <Attribute label={i18n.parentCase} value={parentLabel} />
                </div>
                <div
                  style={{
                    borderBottom: `1px solid ${colors.greyMedium}`,
                    margin: '1rem 0',
                  }}
                />
              </>
            ) : null}

            <div className="row">
              <div className="col-md-6">
                <Attributes>
                  <Attribute
                    label={i18n.created}
                    value={
                      orderLine.dates?.createdAt
                        ? formatDate(orderLine.dates.createdAt, 'medium')
                        : ''
                    }
                  />
                  <Attribute
                    label={i18n.updated}
                    value={
                      orderLine.dates?.updatedAt
                        ? formatDate(orderLine.dates.updatedAt, 'medium')
                        : ''
                    }
                  />

                  <Attribute
                    label={i18n.deliveryDate}
                    value={
                      mostPreciseDeliveryDate
                        ? `${formatDate(
                            mostPreciseDeliveryDate.date,
                            'medium'
                          )} (${mostPreciseDeliveryDate.status.toLocaleLowerCase()})`
                        : i18n.notSet
                    }
                  />
                </Attributes>
              </div>
              <div className="col-md-6">
                <Attributes>
                  <Attribute
                    label={i18n.status}
                    value={mapOrderStatusToLabel(orderLine.portalStatus)}
                  />
                  <Attribute label={i18n.id} value={orderLine.id} />
                  <Attribute
                    label={i18n.description}
                    value={orderLine.description}
                  />
                </Attributes>
              </div>
            </div>

            <div
              style={{
                borderBottom: `1px solid ${colors.greyMedium}`,
                margin: '1rem 0',
              }}
            />

            <div className="row">
              {orderLine.mainSubscription ? (
                <div className="col-md-6">
                  <div style={{ fontSize: '1.25rem' }}>
                    {i18n.mainSubHeading(
                      `${orderLine.mainSubscription.name} ${orderLine.mainSubscription.id}`
                    )}
                  </div>
                  <Attributes className="mt-4">
                    <Attribute
                      label={i18n.alias}
                      value={orderLine.mainSubscription?.alias ?? i18n.notSet}
                    />
                    <Attribute
                      label="ID"
                      value={orderLine.mainSubscription.id}
                    />
                    <Attribute
                      label={i18n.type}
                      value={orderLine.mainSubscription.name}
                    />
                    {orderLine.mainSubscription.installationAddress ? (
                      <Attribute
                        label={i18n.address}
                        value={formatInstallationAddress(
                          orderLine.mainSubscription.installationAddress,
                          'fullAddress'
                        )}
                      />
                    ) : null}
                  </Attributes>
                </div>
              ) : null}

              {orderLineSubscriptions && orderLineSubscriptions.length > 0 ? (
                <div className="col-md-6">
                  <div style={{ fontSize: '1.25rem' }}>
                    {i18n.orderLineSubHeading}
                  </div>
                  <ul className="mt-4" style={{ padding: 0 }}>
                    {orderLineSubscriptions.map((sub, idx) => (
                      <li
                        key={sub.id}
                        style={{
                          marginTop: idx === 0 ? undefined : '0.5rem',
                          listStyle: 'none',
                          fontWeight: 'bolder',
                        }}
                      >
                        {sub.name} {sub.id}
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </ModalBody>
        </>
      ) : null}
      <ModalFooter className="d-flex">
        <Button className="ml-auto" onClick={toogle} color="purple">
          Lukk
        </Button>
      </ModalFooter>
    </Modal>
  );
};
