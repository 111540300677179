import React from 'react';
import { t } from 'src/lib/i18n';
import { BodyCard, LinkCard, urlFor } from 'src/lib/ui';
import { useIncidentFollowers_customer_incidents_subscription_incidentFollowers } from '../../queries/__types/useIncidentFollowers';
import SubscriptionFollowerCard from './SubscriptionFollowerCard';

interface Props {
  incidentFollowers?: useIncidentFollowers_customer_incidents_subscription_incidentFollowers[];
}

export const SubscriptionIncidentFollowers: React.FC<Props> = props => {
  if (!props.incidentFollowers || props.incidentFollowers.length === 0) {
    return null;
  }

  return (
    <BodyCard className="ml-4 ml-sm-0 mr-4" noGutter={true}>
      <div>
        <div className="px-4 pt-4" style={{ fontSize: '1.25rem' }}>
          {t.incidents.IncidentSidebar.followsTheService}
        </div>

        {props.incidentFollowers?.map(contact => (
          <LinkCard
            key={contact.id}
            renderAs="div"
            url={urlFor({ contact: contact.id })}
          >
            <SubscriptionFollowerCard contact={contact} />
          </LinkCard>
        ))}
      </div>
    </BodyCard>
  );
};
