import { SubscriptionGroupInUrl } from './../ui/LinkTo/urlFor';
import { useRouter } from 'src/lib/utils/useRouter';
import { SearchDomain } from './../../__types/graphql-global-types';
import { useCustomer } from 'src/lib/global/CustomerContext';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useSearchOptions';

const SEARCH_OPTIONS = gql`
  query useSearchOptions($customerId: ID!, $domain: SearchDomain) {
    customer(id: $customerId) {
      id
      searchOptions(domain: $domain) {
        filters {
          domain
          filter
          options
        }
      }
    }
  }
`;

const getDomainByGroup = (
  group: SubscriptionGroupInUrl
): SearchDomain | null => {
  switch (group) {
    case 'mobil':
      return SearchDomain.mobileSubscription;
    case 'nettverk':
      return SearchDomain.dataSubscription;
    case 'telefoni':
      return SearchDomain.teleSubscription;
    case 'tv':
      return SearchDomain.tvSubscription;
    default:
      return null;
  }
};

export const useSearchOptions = () => {
  const customer = useCustomer();
  const router = useRouter<{ group: SubscriptionGroupInUrl }>();
  const group = router.match.params.group;
  const domain = getDomainByGroup(group);

  return useQuery<t.useSearchOptions, t.useSearchOptionsVariables>(
    SEARCH_OPTIONS,
    {
      variables: {
        customerId: customer.id,
        domain,
      },
    }
  );
};
