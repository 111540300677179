import React from 'react';
import { Attribute, Attributes } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { InterfaceMode } from 'src/__types/graphql-global-types';

export const IpDetails: React.FC<{
  ipAddresses: Array<{
    networkAddress?: null | string;
    subnetMask?: null | string;
    interfaceMode?: InterfaceMode | null;
    interfaceId?: null | string;
    hsrpIpAddress?: null | string;
  }>;
  heading: string;
}> = props => {
  const i18n = t.subscriptions.TechnicalDetailsCard;
  const ips = props.ipAddresses.map(ip => {
    if (!ip.networkAddress) {
      return null;
    }
    return (
      <div key={ip.networkAddress} className="pb-4">
        <span style={{ fontWeight: 600 }}>{ip.networkAddress}</span>
        <Attributes inline={true}>
          {ip.subnetMask && (
            <Attribute label={i18n.subnetMask} value={ip.subnetMask} />
          )}
          {ip.interfaceMode && (
            <Attribute label={i18n.interfaceMode} value={ip.interfaceMode} />
          )}
          {ip.interfaceId && (
            <Attribute label={i18n.interfaceId} value={ip.interfaceId} />
          )}
          {ip.hsrpIpAddress && (
            <Attribute label={i18n.hsrpIpAddress} value={ip.hsrpIpAddress} />
          )}
        </Attributes>
      </div>
    );
  });

  return (
    <div>
      <h3 style={{ fontWeight: 600 }}>{props.heading}</h3>
      {ips}
    </div>
  );
};
