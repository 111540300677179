import React, { useState } from 'react';
import { t } from 'src/lib/i18n';
import Form from 'reactstrap/lib/Form';
import { Contact, Folder } from 'src/lib/types';
import { Button, FormSubmitBar, SearchInput } from 'src/lib/ui';
import { ModalBody, ModalFooter } from 'reactstrap';
import { SearchResult, useSearch } from '../../search';
import { SearchMenuResult } from '../../search/components/menu/SearchMenuResult';
import { useSetFolderAccess } from '../mutations/useSetFolderAccess';
import { SearchDomain } from 'src/__types/graphql-global-types';
import './EditAccessForm.scss';

interface Props {
  cancel: () => void;
  folder: Folder;
  folderContacts: Contact[];
}

const EditAccessForm: React.FC<Props> = props => {
  const [query, setQuery] = useState('');
  const [showResults, setShowResults] = useState(false);
  const [contacts, setContacts] = useState<Contact[]>(props.folderContacts);
  const { setFolderAccess, loading, error } = useSetFolderAccess();
  const search = useSearch({
    query: query,
    types: [SearchDomain.contact],
    filter: [{ filter: 'hasUser', value: ['true'] }],
  });

  const addContact = (c: Contact) => {
    setContacts(prevUsers => [...prevUsers, c]);
    setShowResults(false);
  };

  const removeContact = (contactId: string) => {
    const updatedContacts = contacts.filter(c => c.id !== contactId);
    setContacts(updatedContacts);
  };

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    const userIds = contacts.map(c => c.user?.id);
    setFolderAccess(+props.folder.id, userIds);
    props.cancel();
  };

  const contactIsDisabled = (item: SearchResult) => {
    return contacts.includes(item as Contact);
  };

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <ModalBody>
        <div className="mb-1">
          {t.documents.UpdateFolderAccessForm.description}
        </div>
        <div className="EditAccessForm position-relative mb-4">
          <SearchInput
            value={query}
            onChange={e => setQuery(e.target.value)}
            onClose={() => setQuery('')}
            narrow={true}
            onFocus={() => setShowResults(true)}
          />
          <div className="SearchMenuResult-list">
            {showResults &&
              search.results.map((contact, key) => (
                <div onClick={() => addContact(contact as Contact)} style={{}}>
                  <SearchMenuResult
                    key={key}
                    id={contact.id}
                    entry={contact}
                    disabled={contactIsDisabled(contact) ?? false}
                  />
                </div>
              ))}
          </div>
        </div>
        {contacts.length > 0 && (
          <div className="mb-1">
            {t.documents.UpdateFolderAccessForm.personsWithAccess}
          </div>
        )}
        {contacts.map(contact => (
          <SearchMenuResult
            key={contact.id}
            entry={contact}
            itemProps={{
              disabled: true,
              role: 'list-item',
            }}
            additionalInfo={
              <Button
                type="button"
                className="text-danger remove mr-2"
                color="dark"
                icon="close"
                transparent={true}
                hideLabel={true}
                onClick={() => removeContact(contact.id)}
              />
            }
          />
        ))}
      </ModalBody>

      <ModalFooter>
        <FormSubmitBar
          cancel={props.cancel}
          loading={loading}
          submitLabel={t.documents.UpdateFolderAccessForm.submit}
          error={error}
          errorMsg={t.documents.genericError}
        />
      </ModalFooter>
    </Form>
  );
};

export default EditAccessForm;
