import React, { useState, useEffect, useRef } from 'react';
import './FullscreenHeader.scss';
import { useRouter } from 'src/lib/utils/useRouter';
import cs from 'classnames';
import { Button } from '../Button/Button';
import { throttle } from 'lodash';

const pathHistory: string[] = [];

/**
 * A single-purpose path history to keep track of what page to send the user "back" to when fullscreen mode is exited.
 * Add a path to the path history. This should be called in the browserHistory.listen event (in src/index.tsx).
 * @param path The path to add to the history.
 */
export function addToPathHistory(path: string) {
  pathHistory.push(path);
}

function getPreviousPath() {
  if (pathHistory.length > 1) {
    return pathHistory[pathHistory.length - 2];
  } else {
    return undefined;
  }
}

type Props = {
  title: string;
  color?: 'purple';
  onClickBack?: () => void;
  hideBackOption?: boolean;
  align?: 'left';
  // 23.11.2019: Unsure if NavTabs should be a part of FullscreenHeader
  // tabs?: Array<{
  //   url: string;
  //   label: string;
  //   exact?: boolean;
  // }>;
};

export const FullscreenHeader: React.FC<Props> = props => {
  const router = useRouter();
  const headerRef = useRef<HTMLDivElement>(null);
  const [scrollTop, setScrollTop] = useState(0);
  const initialPrevUrl = useRef<string | undefined>(getPreviousPath()).current;
  let scrollRef: Element;

  useEffect(() => {
    scrollRef = document.getElementsByClassName('Fullscreen')[0]; // eslint-disable-line react-hooks/exhaustive-deps
    const throttledScroll = throttle(handleScroll, 5);
    if (scrollRef) {
      scrollRef.addEventListener('scroll', throttledScroll);
      return () => {
        scrollRef.removeEventListener('scroll', throttledScroll);
      };
    }
  }, []);

  const handleScroll = (ev?: Event) => {
    if (scrollRef) {
      setScrollTop(scrollRef.scrollTop);
    }
  };

  return (
    <div
      className={cs('FullscreenHeader', {
        'FullscreenHeader--purple': props.color,
        shrink: scrollTop > 80,
      })}
      ref={headerRef}
    >
      <div
        className={cs('FullscreenHeader-content', {
          'text-left': props.align === 'left',
        })}
      >
        {!props.hideBackOption && (
          <Button
            className="FullscreenHeader-content-backButton"
            icon="arrow-large-left"
            hideLabel={true}
            onClick={
              props.onClickBack
                ? props.onClickBack
                : () => router.history.push(initialPrevUrl || '/')
            }
            transparent={true}
            color={props.color ? 'purple' : 'white'}
          >
            tilbake
          </Button>
        )}
        {props.title ? (
          <h1 className="FullscreenHeader-content-title">{props.title}</h1>
        ) : null}
        {/* {props.children} */}
        {/* {props.tabs ? (
          <div className="FullscreenHeader-tabs">
            <NavTabs>
              {props.tabs.map(tab => (
                <NavTab
                  key={tab.url}
                  exact={tab.exact}
                  path={tab.url}
                  label={tab.label}
                />
              ))}
            </NavTabs>
          </div>
        ) : null} */}
      </div>
    </div>
  );
};
