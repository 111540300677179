import React from 'react';
import cs from 'classnames';

import {
  Dropdown,
  DropdownMenu,
  DropdownItem,
  DropdownProps,
  DropdownToggle,
  UncontrolledDropdown,
} from 'reactstrap';

import './OptionsDropdown.scss';

export type OptionsDropdownProps = Pick<
  DropdownProps,
  'className' | 'toggle' | 'children' | 'isOpen' | 'direction'
>;

export const OptionsDropdown = (props: OptionsDropdownProps) => {
  return (
    <Dropdown
      className={cs('OptionsDropdown', props.className)}
      isOpen={props.isOpen}
      toggle={props.toggle}
      direction={props.direction}
    >
      {props.children}
    </Dropdown>
  );
};

export const UncontrolledOptionsDropdown = (props: OptionsDropdownProps) => {
  return (
    <UncontrolledDropdown
      className={cs('OptionsDropdown', props.className)}
      direction={props.direction}
    >
      {props.children}
    </UncontrolledDropdown>
  );
};

export const OptionsDropdownDivider = () => <DropdownItem divider={true} />;

export const OptionsDropdownMenu = DropdownMenu;

export const OptionsDropdownToggle = DropdownToggle;

export { OptionsDropdownHeader } from './OptionsDropdownHeader';

export { OptionsDropdownItem } from './OptionsDropdownItem';
