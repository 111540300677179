import React, { useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useTerminateMobileSubscription } from './useTerminateMobileSubscription';

interface Props {
  msisdn: string;
  isOpen: boolean;
  toggle: () => void;
}
export const TerminateAdditionalSimModal: React.FC<Props> = props => {
  const {
    terminateMobileSubscriptionOrder,
    data,
    loading,
    error,
  } = useTerminateMobileSubscription();

  useEffect(() => {
    if (data && !error) {
      props.toggle();
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggle}>
      <ModalHeader>
        {props.msisdn.length === 12
          ? t.ordering.OrderTerminateMobileSubscription.additionalSim
              .terminateDataSim
          : t.ordering.OrderTerminateMobileSubscription.additionalSim
              .terminateVoiceSim}
      </ModalHeader>
      <ModalBody>
        {t.ordering.OrderTerminateMobileSubscription.additionalSim.description}
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={() =>
            terminateMobileSubscriptionOrder({
              msisdn: props.msisdn,
              parkNumber: true,
            })
          }
          submitLabel={
            t.ordering.OrderTerminateMobileSubscription.additionalSim.terminate
          }
          cancel={props.toggle}
          loading={loading}
          error={error}
          errorMsg={
            t.ordering.OrderTerminateMobileSubscription.additionalSim.errorMsg
          }
        />
      </ModalFooter>
    </Modal>
  );
};
