import React, { useRef, useEffect } from 'react';
import ChatMessage from 'src/lib/chat/ChatMessage';
import ChatInput from 'src/lib/chat/ChatInput';
import './Chat.scss';

import { ChatAttachment } from 'src/lib/chat/ChatAttachment';
import { ChatFieldChangeSeparator } from 'src/lib/chat/ChatFieldChangeSeparator';
import { useChatContext } from 'src/lib/chat/ChatContext';

/** Use ChatContextProvider with Chat to feed  data and functions */

const Chat = () => {
  const chatContext = useChatContext();

  const chatMessagesEnd = useRef<HTMLDivElement>(null);

  useEffect(() => scrollToBottom(), [chatContext?.messages.length]); // eslint-disable-line react-hooks/exhaustive-deps
  const scrollToBottom = () => {
    if (chatMessagesEnd.current) {
      chatMessagesEnd.current.scrollIntoView({
        block: 'end',
        inline: 'nearest',
        behavior: 'smooth',
      });
    }
  };

  return (
    <div className="Chat">
      <div className="Chat-messages pt-4" ref={chatContext.contentRef}>
        {chatContext.messages.length === 0 && chatContext.noResult
          ? chatContext.noResult
          : null}
        {chatContext.messages.map((m, i) => {
          if (m.type === 'comment') {
            return <ChatMessage key={i} {...m} />;
          }
          if (m.type === 'attachment') {
            return <ChatAttachment key={i} {...m} />;
          }
          if (m.type === 'statusChange') {
            return (
              <div className="Chat-status-change pt-4 pb-5" key={i}>
                <ChatFieldChangeSeparator key={i} activity={m} />
              </div>
            );
          }
          return null;
        })}
        <div ref={chatMessagesEnd} />
      </div>
      {!chatContext.disableInput ? <ChatInput /> : null}
    </div>
  );
};

export default Chat;
