import React from 'react';
import { t } from 'src/lib/i18n';
import {
  Attributes,
  Attribute,
  TextField,
  colors,
  Alert,
  Button,
  StepFlowCard,
} from 'src/lib/ui';
import { formatName } from 'src/lib/utils/formatName';
import { SearchResult } from 'src/areas/main/search/types';
import { TextFieldWithSearch } from 'src/lib/ui/TextField/TextFieldWithSearch';
import ContactCard from '../displayContact/ContactCard';
import { useSearch } from 'src/areas/main/search';
import { FormState } from './CreateContact';
import { SearchDomain } from 'src/__types/graphql-global-types';
import { SearchMenuContactResult } from 'src/areas/main/search/components/menu/SearchMenuContactResult';

export const Step2 = (props: {
  form: FormState;
  isLastStep: boolean;
  isValid: boolean;
}) => {
  return (
    <StepFlowCard
      step={1}
      title={t.contacts.create.step2.title}
      currentStep={props.form.currentStep}
      isValid={props.isValid}
      hideContinue={
        props.isLastStep || !!props.form.values.selectedContact?.user
      }
      onContinue={props.form.nextStep}
      onClick={props.form.setStep}
      form={<Form form={props.form} isLastStep={props.isLastStep} />}
      summary={<Summary {...props.form} />}
    />
  );
};

const Form = (props: { form: FormState; isLastStep: boolean }) => {
  const { form, isLastStep } = props;
  const firstNameSearch = useSearch({
    types: [SearchDomain.contact],
    query: form.values.firstName,
  });

  const lastNameSearch = useSearch({
    types: [SearchDomain.contact],
    query: form.values.lastName,
  });

  const emailSearch = useSearch({
    types: [SearchDomain.contact],
    query: form.values.email,
  });

  const i18n = t.contacts.create.step2;

  const setSelectedContact = (item?: SearchResult) => {
    if (item?.__typename === 'Contact') {
      return form.setValue('selectedContact', item);
    }
    return form.setValue('selectedContact', undefined);
  };

  return (
    <>
      <small className="d-block pb-5" style={{ color: colors.greyDarkText }}>
        {i18n.description}
      </small>
      {form.values.selectedContact ? (
        <div className="mb-5">
          <ContactCard
            contact={form.values.selectedContact}
            hideOptions={true}
            clickable={true}
          />
          <Button
            type="button"
            icon="close"
            className="d-block ml-auto mt-2"
            size="small"
            onClick={() => setSelectedContact(undefined)}
          >
            {i18n.removeContact}
          </Button>
          <Alert
            status="warning"
            className="my-3"
            icon="alert"
            header={
              form.values.selectedContact?.user
                ? i18n.useExists
                : i18n.contactExists
            }
          >
            {form.values.selectedContact?.user
              ? i18n.clickToEditUser
              : !isLastStep
              ? i18n.moveForwardToGiveRoles(
                  formatName({
                    firstName: form.values.selectedContact.firstName,
                    lastName: form.values.selectedContact.lastName,
                  })
                )
              : i18n.clickToEditContact}
          </Alert>
        </div>
      ) : (
        <>
          <div className="d-flex mb-5">
            <TextFieldWithSearch
              id="firstName"
              className=" mr-3 flex-grow-1"
              search={{
                ...firstNameSearch,
                setQuery: input => form.setValue('firstName', input),
              }}
              type="text"
              value={form.values.firstName}
              onSelect={setSelectedContact}
              label={t.contacts.formLabels.firstName}
              required={true}
              error={
                !form.isFieldValid('firstName') &&
                form.isFieldTouched('firstName')
                  ? t.contacts.EditContact.errors.required
                  : undefined
              }
              aria-label={t.contacts.formLabels.firstName}
            />

            <TextFieldWithSearch
              id="lastName"
              className="flex-grow-1"
              search={{
                ...lastNameSearch,
                setQuery: input => form.setValue('lastName', input),
              }}
              type="text"
              value={form.values.lastName}
              onSelect={setSelectedContact}
              label={t.contacts.formLabels.lastName}
              required={true}
              error={
                !form.isFieldValid('lastName') &&
                form.isFieldTouched('lastName')
                  ? t.contacts.EditContact.errors.required
                  : undefined
              }
              aria-label={t.contacts.formLabels.lastName}
            />
          </div>

          <div className="mb-5">
            <TextFieldWithSearch
              id="email"
              className="flex-grow-1"
              search={{
                ...emailSearch,
                setQuery: input => form.setValue('email', input),
              }}
              onBlur={() => {
                if (emailSearch.results.length) {
                  setSelectedContact(
                    emailSearch.results.find(
                      c =>
                        c.__typename === 'Contact' &&
                        c.email === form.values.email
                    )
                  );
                }
              }}
              type="email"
              value={form.values.email}
              onSelect={setSelectedContact}
              label={t.contacts.formLabels.email}
              required={true}
              aria-label={t.contacts.formLabels.lastName}
              error={
                !form.isFieldValid('email') && form.isFieldTouched('email')
                  ? t.contacts.EditContact.errors.email
                  : undefined
              }
            />
          </div>

          <div className="d-flex flex-column w-50">
            <TextField
              id="mobilePhone"
              className="mb-5"
              type="number"
              label={t.contacts.formLabels.mobilePhone}
              aria-label={t.contacts.formLabels.mobilePhone}
              value={form.values.mobilePhone}
              onChange={e => form.setValue('mobilePhone', e.target.value)}
              error={
                !form.isFieldValid('mobilePhone') &&
                form.isFieldTouched('mobilePhone')
                  ? t.contacts.EditContact.errors.phone
                  : undefined
              }
            />
            <TextField
              id="secondaryPhone"
              type="number"
              className="mb-5"
              label={t.contacts.formLabels.secondaryPhone}
              aria-label={t.contacts.formLabels.secondaryPhone}
              value={form.values.secondaryPhone}
              onChange={e => form.setValue('secondaryPhone', e.target.value)}
              error={
                !form.isFieldValid('secondaryPhone') &&
                form.isFieldTouched('secondaryPhone')
                  ? t.contacts.EditContact.errors.phone
                  : undefined
              }
            />
          </div>

          <TextField
            id="description"
            className="mb-5 w-100"
            label={t.contacts.formLabels.description}
            aria-label={t.contacts.formLabels.description}
            value={form.values.description}
            onChange={e => form.setValue('description', e.target.value)}
            error={
              !form.isFieldValid('description') &&
              form.isFieldTouched('description')
                ? t.contacts.EditContact.errors.tooLong(100)
                : undefined
            }
          />

          <TextField
            id="employeeNumber"
            className="mb-5 w-50"
            label={t.contacts.formLabels.employeeNumber}
            aria-label={t.contacts.formLabels.employeeNumber}
            value={form.values.employeeNumber}
            onChange={e => form.setValue('employeeNumber', e.target.value)}
            error={
              !form.isFieldValid('employeeNumber') &&
              form.isFieldTouched('employeeNumber')
                ? t.contacts.EditContact.errors.tooLong(40)
                : undefined
            }
          />
        </>
      )}
    </>
  );
};

const Summary = (form: FormState) => {
  if (form.values.selectedContact) {
    return (
      <SearchMenuContactResult
        entry={form.values.selectedContact}
        disabled={true}
      />
    );
  }
  return (
    <div>
      <Attributes>
        <Attribute
          label={t.contacts.formLabels.firstName}
          value={form.values.firstName}
        />
        <Attribute
          label={t.contacts.formLabels.lastName}
          value={form.values.lastName}
        />
        <Attribute
          label={t.contacts.formLabels.email}
          value={form.values.email}
        />
        <Attribute
          label={t.contacts.formLabels.mobilePhone}
          value={form.values.mobilePhone}
        />
        <Attribute
          label={t.contacts.formLabels.secondaryPhone}
          value={form.values.secondaryPhone}
        />
        <Attribute
          label={t.contacts.formLabels.description}
          value={form.values.description}
        />
        <Attribute
          label={t.contacts.formLabels.employeeNumber}
          value={form.values.employeeNumber}
        />
      </Attributes>
    </div>
  );
};
