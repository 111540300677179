import gql from 'graphql-tag';

export const OrderFragment = gql`
  fragment OrderSearchResult on Order {
    id
    displayId
    type
    customerOrderId
    dates {
      createdAt
      updatedAt
      estimatedDeliveryDate
      plannedDeliveryDate
      committedDeliveryDate
      estimatedAt
      closedAt
    }
    placedBy
    portalStatus
    description
    customer {
      id
      name
      rootOrganisation {
        id
        organisationNumber
      }
    }
  }
`;
