const reports = {
  reports: 'Rapporter',
  generatingReports: 'Laster ned rapport',
  errorDescription:
    'Det kan skyldes vedlikehold på server eller at en server er nede. Vennligst prøv igjen senere.',
  somethingWentWrong: 'Noe gikk galt!',
  noContent: 'Fant ikke noe innhold til rapporten',
  categories: {
    accounting: 'Regnskap',
    landline: 'Fastnett',
    mobile: 'Mobil',
    aboutReports: 'Om rapportene',
  },
  accounting: {
    accountingLandline: 'Regnskapsfil fast telefoni',
    accountingData: 'Regnskapsfil data',
    momsReport: 'Momrapport fradrag for innholdstjenester',
    invoiceDetails: 'Fakturadetaljrapport',
  },
  landline: {
    sumPerANum: 'Sum per A-nummer',
    sumPerANumTotal: 'Sum per A-nummer, total',
    trafficReportTotal: 'Trafikkrapport total, år',
    subscriptionOverview: 'Abonnementsoversikt',
  },
  mobile: {
    taxReport: 'Skatterapport, oversikt innholdstjenester',
    trafficReportTotal: 'Trafikkrapport totalt',
    privateUsagePerMonth: 'Privat forbruk pr. mnd',
    privateUsagePerYear: 'Privat forbruk pr. år',
    overviewSubscription: 'Oversikt abonnement',
    overviewBinding: 'Oversikt bindingstid',
  },
  aboutReports: {
    description:
      'Her finner du igjen rapportene som var i Invoice Online. Du kan fremdeles laste dem ned i excel-format, og ting fungerer nesten likt som før.',
  },
  actions: {
    cancel: 'Avbryt',
    download: 'Last ned',
  },
  downloadModal: {
    invoiceAccount: 'Fakturasted',
    invoiceAccountPlaceholder: 'Alle',
    period: 'Periode',
    subscriptions: 'Tjenester',
    subscriptionsPlaceholder: 'Alle tjenester',
    from: 'Fra',
    to: 'Til',
    select: 'Velg',
    subscriptionType: {
      data: 'Nettverk',
      mobile: 'Mobil',
      landlineSubscriptions: 'Fastnettjenester',
      other: 'Annet',
    },
    year: 'År',
  },
};

export default reports;
