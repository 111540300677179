import React from 'react';
import { useAddons } from 'src/areas/main/subscriptions/queries/useAddons';
import { SubscriptionCard } from '../../../../subscriptions/lib/SubscriptionCard/SubscriptionCard';
import { t } from 'src/lib/i18n';
export const Addons = (props: {
  subscription: {
    id: string;
  };
}) => {
  const { addons } = useAddons(props.subscription.id);
  if (!addons?.length) {
    return null;
  }
  return (
    <div>
      <div className="pb-3">{t.locations.Sidebar.subscription.addons}</div>
      {addons?.map(addon => (
        <div className="py-2 mx-n4 border-bottom" key={addon.id}>
          <div className="px-4">
            <SubscriptionCard subscription={addon} />
          </div>
        </div>
      ))}
    </div>
  );
};
