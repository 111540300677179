import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useTvSubscription';

// TODO: Use SubscriptionFragment when we have defined the common fields that all subscriptions should have.
// TODO: Then add fields that only applies to TV.
const USE_TV_SUBSCRIPTION = gql`
  query useTvSubscription($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        name
        subscriptionId
        alias
        tags {
          tag
          id
        }
        deliveryStatus
        type
        group
        lastModified
        installationAddress {
          zip
          address
          city
          location {
            lat
            lon
          }
        }
        startTime
        endTime

        # only needed to check if incidents tab should be visible
        incidents {
          id
        }
        # To show activeIncidentCard
        activeIncidents {
          id
          incidentStatus
          shortDescription
          opened
        }

        costCenter {
          id
          code
          description
        }

        technicalContacts {
          priority
          contact {
            id
            firstName
            lastName
            email
            mobilePhone
            secondaryPhone
            description
          }
        }
        breadcrumbs {
          id
          subscriptionId
          name
          alias
          group
        }
        flex1
        flex2
        flex3
        flex4
        taxModel
        tv {
          distributionTechnology
          customerType
          model
          hdmiCount
          locationType
          mainChannelPackage {
            name
            quantityDescription
            quantity
          }
          additionalChannels {
            name
            quantityDescription
            quantity
          }
          additionalChannelPackages {
            name
            quantityDescription
            quantity
          }

          tvAddon {
            name
            quantity
          }
        }
      }
    }
  }
`;

export const useTvSubscription = (subscriptionId: string) => {
  const customer = useCustomer();

  return useQuery<t.useTvSubscription, t.useTvSubscriptionVariables>(
    USE_TV_SUBSCRIPTION,
    {
      variables: {
        customerId: customer.id,
        subscriptionId: subscriptionId,
      },
      errorPolicy: 'all',
    }
  );
};
