import React, { useState } from 'react';
import {
  BodyCard,
  Toolbar,
  Button,
  urlFor,
  ButtonCard,
  LinkCard,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import EditTechnicalContacts from './EditTechnicalContacts';
import TechnicalContactCard from './TechnicalContactCard';
import { WithSearch } from 'src/areas/main/search';
import { useRouter } from 'src/lib/utils/useRouter';
import { useUpdateTechnicalContacts } from '../../../mutations/useUpdateTechnicalContacts';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';
import { useSubscription_customer_subscriptions_technicalContacts } from '../../../queries/__types/useSubscription';

interface Props {
  subscription: {
    id: string;
    group?: SubscriptionGroup | null;
    technicalContacts?:
      | useSubscription_customer_subscriptions_technicalContacts[]
      | null;
  };
  disableEdit?: boolean;
}

export const TechnicalContactsCard: React.FC<Props> = ({
  subscription,
  disableEdit,
}) => {
  const mutationContext = useUpdateTechnicalContacts();
  const [editTechnicalContacts, setEditTechnicalContacts] = useState<boolean>(
    false
  );
  const router = useRouter();

  const toggleEditTechnicalContacts = () => {
    setEditTechnicalContacts(!editTechnicalContacts);
  };

  const technicalContacts = subscription.technicalContacts?.length
    ? subscription.technicalContacts.sort((a, b) => a.priority - b.priority)
    : [];

  return (
    <WithSearch
      initial={{
        types: ['contact'],
      }}
    >
      {search => (
        <>
          {technicalContacts.length === 0 && !disableEdit ? (
            <ButtonCard
              onClick={toggleEditTechnicalContacts}
              icon="support"
              stackHorizontally={true}
              color="dark"
            >
              {t.subscriptions.TechnicalContactsCard.btnAdd}
            </ButtonCard>
          ) : (
            <BodyCard
              heading={t.subscriptions.TechnicalContactsCard.title}
              noGutter={true}
              headingClassName="px-4 pt-4"
            >
              {technicalContacts.length > 0 ? (
                technicalContacts.map((technicalContact, ix) => (
                  <div key={ix}>
                    <LinkCard
                      renderAs="div"
                      onClick={() => {
                        router.history.push(
                          urlFor({ contact: technicalContact.contact.id })
                        );
                      }}
                    >
                      <TechnicalContactCard
                        contact={technicalContact.contact}
                      />
                    </LinkCard>
                  </div>
                ))
              ) : (
                <div className="p-4">
                  {t.subscriptions.TechnicalContactsCard.noContacts}
                </div>
              )}
              {!disableEdit && (
                <Toolbar className="py-4 mx-3">
                  <Button
                    color="white"
                    onClick={toggleEditTechnicalContacts}
                    icon="pen"
                  >
                    {t.subscriptions.TechnicalContactsCard.btnEdit}
                  </Button>
                </Toolbar>
              )}
            </BodyCard>
          )}
          <EditTechnicalContacts
            updateContext={mutationContext}
            subscriptionId={subscription.id}
            group={subscription.group}
            initContacts={technicalContacts}
            toggleState={toggleEditTechnicalContacts}
            isOpen={editTechnicalContacts}
          />
        </>
      )}
    </WithSearch>
  );
};
