import React from 'react';
import { formatDate } from 'src/lib/utils';
import { pickMostPreciseDeliveryDate } from './pickMostPreciseDeliveryDate';

interface Props {
  dates?: null | {
    committedDeliveryDate?: string | null;
    plannedDeliveryDate?: string | null;
    estimatedDeliveryDate?: string | null;
    estimatedAt?: string | null;
    closedAt?: string | null;
  };
}

export function DeliveryDateLabel(props: Props) {
  const mostPreciseDate = pickMostPreciseDeliveryDate(props.dates);

  if (!mostPreciseDate) {
    return null;
  }

  return (
    <>
      {formatDate(mostPreciseDate.date, 'short')} (
      {mostPreciseDate.status.toLocaleLowerCase()})
    </>
  );
}
