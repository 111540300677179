import { t } from 'src/lib/i18n';

export function getPhoneNumberDisplayLabel(
  phoneNumber:
    | undefined
    | null
    | Array<{
        phoneNumber: string;
      }>
    | null,
  /**
   * Returns a display-label for a phoneNumber array.
   *
   * Short: Show only count
   * Long: Show max three numbers, and then show count
   * Full: Show all numbers
   */
  format: 'short' | 'long' | 'full'
): string {
  if (!phoneNumber) {
    return '';
  }
  if (phoneNumber.length === 1) {
    return phoneNumber[0].phoneNumber;
  }

  if (phoneNumber.length > 1) {
    if (format === 'short') {
      return t.subscriptions.xCount(phoneNumber.length);
    }
    const numbers = phoneNumber.map(num => num.phoneNumber);

    if (format === 'long') {
      const displayNumbers = numbers.slice(0, 3);
      const restNumbers = numbers.slice(3, numbers.length);

      let label = displayNumbers.join(', ');

      if (restNumbers.length > 0) {
        label = `${label} + ${t.subscriptions.xCount(restNumbers.length)}`;
      }

      return label;
    }
    if (format === 'full') {
      return numbers.join(', ');
    }
  }

  return '';
}
