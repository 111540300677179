import React, { useMemo } from 'react';
import { BodyCard, CardBody, Loading } from 'src/lib/ui';
import { useStatisticsParams } from '../../utils/useStatisticsParams';
import { isAggregatedByDay } from '../../utils/isAggregatedByDay';
import {
  useStandardStatistics,
  StandardStatisticsType,
  StandardStatisticsPeriod,
} from '../../queries/WithStatistics';
import { StandardChart } from '../../components/StandardChart';
import { StandardDetails } from './StandardDetails';
import { isLowerThan1GB, mapStandardData } from './mapStandardData';
import * as st from './StandardTypes';
import { useI18nContext, t } from 'src/lib/i18n';
import { Checkbox } from 'src/lib/ui';
import { useStatisticsInterfaces } from '../../utils/useStatisticsInterfaces';
import { hasInterfaceList } from '../../components/StatisticsFilter';
import { convertKilobitsToMegabits } from '../../utils/convertUnit';

export const Standard = (props: { currentView: StandardStatisticsType }) => {
  const params = useStatisticsParams();
  const { locale } = useI18nContext();
  const statisticsQuery = useStandardStatistics({
    subscriptionId: params.subscriptionId,
    type: [props.currentView],
    periods: 100,
    interface: params.currentInterface,
    periodStart: params.currentStartPeriod,
    periodEnd: params.currentEndPeriod,
  });

  const hideTimestamp = isAggregatedByDay(
    params.currentStartPeriod,
    params.currentEndPeriod
  );

  const interfaces = useStatisticsInterfaces(params.subscriptionId);
  const currentInterface = interfaces.liveInterfaces?.find(
    i => i.value === params.currentInterface
  );
  const subTitle =
    currentInterface && hasInterfaceList.includes(props.currentView)
      ? `${currentInterface.label} ${currentInterface.subLabel}`
      : undefined;

  /**
   * Throughput is mapped to Mbit. Therefore we need to map serviceBandwidth from Kbit to Mbit
   */
  const serviceBandwidth = {
    downstream: statisticsQuery.data?.serviceBandwidth?.downstream
      ? convertKilobitsToMegabits(
          statisticsQuery.data?.serviceBandwidth?.downstream
        )
      : undefined,
    upstream: statisticsQuery.data?.serviceBandwidth?.upstream
      ? convertKilobitsToMegabits(
          statisticsQuery.data?.serviceBandwidth?.upstream
        )
      : undefined,
  };

  const mappedData = mapStandardData(
    statisticsQuery.data?.statistics?.periods,
    statisticsQuery.data?.serviceBandwidth,
    statisticsQuery.data?.statistics?.traffic?.sumIn,
    statisticsQuery.data?.statistics?.traffic?.sumOut
  );

  const showCumulativeInMB =
    isLowerThan1GB(statisticsQuery.data?.statistics?.traffic?.sumIn ?? 0) &&
    isLowerThan1GB(statisticsQuery.data?.statistics?.traffic?.sumOut ?? 0);

  const { tooltip, chartContent, title } = useMemo(
    () => mapViewToProps(props.currentView),
    // Edgecase: force a function-call to rerender the table so i18n will be updated
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [props.currentView, locale]
  );

  return (
    <BodyCard noGutter={true}>
      <CardBody>
        {statisticsQuery.loading || interfaces.loading ? (
          <Loading />
        ) : mappedData === null ? (
          <div>Ingen data</div>
        ) : (
          <>
            {!chartContent || !tooltip ? (
              <div>Type unknown</div>
            ) : (
              <StandardChart<StandardStatisticsPeriod>
                title={title}
                subTitle={subTitle}
                periods={mappedData}
                tooltip={tooltip}
                showThreshold={Boolean(params.showUtilization)}
                threshold={
                  props.currentView === 'throughput'
                    ? serviceBandwidth
                    : undefined
                }
                hideTimestamp={hideTimestamp}
                showCumulativeInMB={showCumulativeInMB}
                actions={
                  <>
                    {serviceBandwidth && params.currentView === 'throughput' ? (
                      <Checkbox
                        className="mr-4"
                        label={t.statistics.StandardDetails.showUtilization}
                        checked={!!params.showUtilization}
                        onChange={params.toggleUtilization}
                      />
                    ) : null}
                  </>
                }
              >
                {chartContent}
              </StandardChart>
            )}
            {statisticsQuery.data?.statistics && (
              <StandardDetails
                data={statisticsQuery.data.statistics}
                showCumulativeInMB={showCumulativeInMB}
                // NOT in use for now
                //serviceBandwidth={serviceBandwidth}
              />
            )}
          </>
        )}
      </CardBody>
    </BodyCard>
  );
};

const mapViewToProps = (view: StandardStatisticsType) => {
  const i18n = t.statistics.StatisticsFilter;
  switch (view) {
    case 'cumulative':
      return {
        tooltip: st.CumulativeTooltip,
        chartContent: st.CumulativeChartElements(),
        title: i18n.cumulative,
      };
    case 'throughput':
      return {
        tooltip: st.ThroughputTooltip,
        chartContent: st.ThroughputChartElements(),
        title: i18n.throughput,
      };
    case 'responseTime':
      return {
        tooltip: st.ResponseTimeTooltip,
        chartContent: st.ResponseTimeChartElements(),
        title: i18n.responseTime,
      };
    case 'packetDrop':
      return {
        tooltip: st.PacketDropTooltip,
        chartContent: st.PacketDropChartElements(),
        title: i18n.packetDrop,
      };
  }
  return {
    tooltip: null,
    chartContent: null,
    title: null,
  };
};
