import { useCustomer } from 'src/lib/global/CustomerContext';
import { useMutation } from '@apollo/react-hooks';
import gql from 'graphql-tag';
import * as t from './__types/useNetworkChangeOrder';
import {
  LanRouteInput,
  StaticRouteInput,
} from 'src/__types/graphql-global-types';

const NETWORK_CHANGE_ORDER = gql`
  mutation useNetworkChangeOrder($input: NetworkChangeOrderInput) {
    createNetworkChangeOrder(input: $input) {
      success
      order {
        id
        type
        createdAt
        createdBy {
          id
        }
        updatedAt
        updatedBy {
          id
        }
      }
      errors {
        code
        message
      }
    }
  }
`;

export const useNetworkChangeOrder = () => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    t.useNetworkChangeOrder,
    t.useNetworkChangeOrderVariables
  >(NETWORK_CHANGE_ORDER);

  const changeLanRouting = (
    subscriptionId: string,
    lanRouting: LanRouteInput[]
  ) =>
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          subscriptionId,
          lanRouting,
          staticRouting: undefined,
        },
      },
    });

  const changeStaticRouting = (
    subscriptionId: string,
    staticRouting: StaticRouteInput[]
  ) =>
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          subscriptionId,
          lanRouting: undefined,
          staticRouting,
        },
      },
    });

  return {
    changeLanRouting,
    changeStaticRouting,
    data,
    loading,
    error: !!error || !!data?.createNetworkChangeOrder?.errors,
  };
};
