import uiNb from '../nb/ui';

const ui: typeof uiNb = {
  ConfirmationModalButton: {
    cancel: 'Cancel',
    confirm: 'Confirm',
  },

  ContactPermissionsForm: {
    fullAccess: 'Admin role',
    fullAccessDescription:
      'Full access to the whole organisation and all contacts',
    roles: {
      SystemAdministrator: 'Operations',
      FinancialManager: 'Economy',
      OrderManager: 'Order',
      CustomerAdministrator: 'Administrator',
    },
  },

  DatePicker: {
    showCalendar: 'Show calendar',
    expectedFormat: '(dd.mm.yyyy)',
  },

  DateRangePicker: {
    presetDays: {
      0: 'Today',
      1: 'Yesterday',
      7: 'Last 7 days',
      28: 'Last 28 days',
      90: 'Last 90 days',
    },
    placeholder: {
      startDate: 'Start date',
      endDate: 'End date',
    },
  },

  Fullscreen: {
    close: 'Close',
  },

  FullscreenError: {
    heading: 'Oops!',
    mainMessage: 'We cannot display this page.',
    subMessage: '',
    linkMessage: 'Please go back to the front page.',
    errorCode: 'Error code:',
  },

  InnerOrganisationTreeNavigator: {
    searchOrgs: 'Search for invoice center',
  },

  ItemListPicker: {
    addItem: 'Add:',
    noMoreItemsInList: 'No more items in the list.',
    submitting: 'Submitting ...',
  },

  LoadMoreButton: {
    showMore: 'Show more',
  },

  NavItem: {
    currentItem: 'current',
  },

  OrganisationPicker: {
    title: 'Edit invoice centers for user',
    btnSave: 'Save',
  },
  Pagination: {
    rowsPerPage: 'Rows per page:',
    xOfY: (x: string | number, y: string | number) => `${x} of ${y}`,
    // btnSelectPageSize: 'select page size',
    btnSelectPage: 'select page',
    btnPrevPage: 'previous page',
    btnNextPage: 'next page',
  },
  PermissionCard: {
    noAccess: 'No access',
    partialAccess: 'Partial access',
    fullRoleAccess: 'Full access',
    btnSelectOrgs: 'Select invoice centers',
    btnSelectTags: 'Select tags',
    searchTags: 'Search for tag',
    editTagsTitle: 'Edit tags for user',
  },

  FormSubmitBar: {
    btnSubmit: 'Save',
    btnBack: 'Back',
    btnCancel: 'Cancel',
  },

  SearchInput: {
    btnClear: 'Clear searchfield',
    btnSearch: 'Search',
  },

  SearchListPicker: {
    noHits: 'No results',
  },

  SelectField: {
    noOptionsMessage: 'No results',
    apply: 'Apply',
    reset: 'Reset',
    cancel: 'Cancel',
  },

  UserPicker: {
    searchForUsers: 'Search for users',
    searchForUser: 'Search for user',
  },

  WizardTable: {
    noSelection: 'No selection made',
  },
  Table: {
    SelectedRowsBanner: {
      allPageRows: (num: number) =>
        `All ${num} rows on this page are selected.`,
      allRows: (num: number, domain: string) =>
        `Select all ${num} rows in ${domain}`,
      allRowsAreSelected: (num: number, domain: string) =>
        `All ${num} rows in ${domain} are selected.`,
      clearSelection: 'Clear  selection',
    },
    noData: 'No data found',
    ColumnOptions: {
      sort: 'Sort',
      show: 'Show',
      aToå: 'A to Å',
      åToa: 'Å to A',
      zeroToNine: '0 to 9',
      nineToZero: '9 to 0',
      descending: 'Descending',
      ascending: 'Ascending',
    },
  },

  Toggle: {
    on: 'On',
    off: 'Off',
  },
  Toast: {
    saveOk: 'Changes saved',
    saveError: 'Could not save changes',
    disabled: 'Disabled by ',
    close: 'Close',
    uploading: (fileName: string) => `Uploading ${fileName}`,
    success: 'Uploading finished.',
    error: 'We could not upload your file.',
  },
  times: {
    days: 'days',
    day: 'day',
    hours: 'hours',
    hour: 'hour',
    minutes: 'minutes',
    minute: 'minute',
    second: 'sec',
  },
  FilterSection: {
    selectedFilters: 'selected filters',
    showResults: (num: number) => `show ${num} results`,
    noResults: 'No results',
    resetAllFilters: 'Reset all filters',
  },
  PlannedWorlState: {
    cancelled: 'Cancelled',
    closed: 'Closed',
    scheduled: 'Planned',
    inProgress: 'In progress',
  },
};

export default ui;
