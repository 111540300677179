import React, { useState, useEffect, useMemo } from 'react';
import { BodyCard, ButtonTab, ButtonTabs } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useInterfaces } from 'src/areas/main/subscriptions/queries/useInterfaces';
import { useAddons } from 'src/areas/main/subscriptions/queries/useAddons';
import { useTechnicalInformation } from 'src/areas/main/subscriptions/queries/useTechnicalInformation';

import { InterfaceTable } from '../Interfaces/InterfaceTable';
import { Subscription } from 'src/lib/types';
import { AddonTable } from '../Addons/AddonTable';
import { TechnicalInformation } from '../TechnicalDetailsCard/TechnicalInformation';

import { useSavedUserPreferences } from 'src/lib/userPreferences/SavedUserPreferencesContext';
interface SubscriptionConfigurationProps {
  subscription: Subscription;
}
type Tab = 'interface' | 'addon' | 'technicalInformation';

export const SubscriptionConfiguration: React.FunctionComponent<SubscriptionConfigurationProps> = props => {
  const i18n = t.subscriptions.SubscriptionConfiguration;
  const { userPreferences, setUserPreference } = useSavedUserPreferences();
  const tab = userPreferences.subscriptionConfigTab;
  const interfaceQuery = useInterfaces(props.subscription.id);
  const addonsQuery = useAddons(props.subscription.id);
  const technicalInformation = useTechnicalInformation(props.subscription.id);

  const [active, setActive] = useState<Tab | undefined>(tab as Tab | undefined);

  const availableTabs = useMemo(
    () => [
      ...(interfaceQuery.interfaces?.length
        ? [
            {
              value: 'interface',
              label: i18n.tabs.interfaces,
            },
          ]
        : []),
      ...(addonsQuery.addons?.length
        ? [
            {
              value: 'addon',
              label: i18n.tabs.addons,
            },
          ]
        : []),
      ...(props.subscription
        ? [
            {
              value: 'technicalInformation',
              label: i18n.tabs.technicalInfo,
            },
          ]
        : []),
    ],
    [addonsQuery.addons, interfaceQuery.interfaces] // eslint-disable-line
  );

  useEffect(() => {
    /**
     * Logic to check if the activeTab exists.
     * Defaults to first Tab of the activeTab is not available.
     *
     * Edgecase: Because of async, there might be timings where no Tabs are available. Avoid updating userPrefs if activeTab === undefined.
     */
    if (!interfaceQuery.loading && !addonsQuery.loading) {
      const newActive = (availableTabs.find(tab => tab.value === active)
        ?.value ?? availableTabs?.[0]?.value) as Tab | undefined;

      setActive(newActive);
    }
  }, [availableTabs, active, interfaceQuery.loading, addonsQuery.loading]);

  const onChangeHandler = (value: Tab) => {
    setActive(value);
    setUserPreference('subscriptionConfigTab', value);
  };

  const hasInterfaces =
    !interfaceQuery.loading && interfaceQuery.interfaces?.length;
  const hasAddons = !addonsQuery.loading && addonsQuery.addons?.length;
  const hasConfig =
    !!technicalInformation.data?.cpe?.configuration ||
    technicalInformation.data?.dmVpnTunnelInformation ||
    technicalInformation.data?.ipRouting ||
    technicalInformation.data?.vrf;

  if (!hasInterfaces && !hasAddons && !hasConfig) {
    return null;
  }
  return (
    <BodyCard noGutter={true}>
      <h2 className="p-4">{i18n.configuration}</h2>
      <ButtonTabs
        onChangeHandler={value => onChangeHandler(value as Tab)}
        currentActive={active}
        className="border-bottom"
      >
        {availableTabs.map(tab => (
          <ButtonTab key={tab.value} label={tab.label} value={tab.value} />
        ))}
      </ButtonTabs>
      {active === 'interface' ? (
        <InterfaceTable
          interfaces={interfaceQuery.interfaces}
          loading={interfaceQuery.loading}
        />
      ) : active === 'addon' ? (
        <AddonTable addons={addonsQuery.addons} loading={addonsQuery.loading} />
      ) : (
        <TechnicalInformation subscription={props.subscription} />
      )}
    </BodyCard>
  );
};
