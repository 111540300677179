import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useAppContext } from 'src/lib/global';
import { FOLDER_QUERY } from '../queries/useFolders';
import * as t from './__types/useSetFolderAccess';

const MUTATION_SET_FOLDER_ACCESS = gql`
  mutation useSetFolderAccess($input: SetFolderAccessInput) {
    setFolderAccess(input: $input) {
      usersWithAccessToFolder
      error {
        message
        code
      }
    }
  }
`;

export const useSetFolderAccess = () => {
  const appContext = useAppContext();
  const [mutation, { data, loading, error }] = useMutation<
    t.useSetFolderAccess,
    t.useSetFolderAccessVariables
  >(MUTATION_SET_FOLDER_ACCESS);

  const setFolderAccess = async (folderId, userIds) =>
    mutation({
      variables: {
        input: {
          folderId,
          userIds,
        },
      },
      refetchQueries: [
        {
          query: FOLDER_QUERY,
          variables: {
            customerId: appContext.activeCustomerId || '',
            ids: [folderId],
          },
        },
      ],
    });

  return {
    setFolderAccess,
    data,
    loading,
    error: !!error || !!data?.setFolderAccess?.error,
  };
};
