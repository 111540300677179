import React, { useState, useEffect, useRef } from 'react';
import Form from 'reactstrap/lib/Form';
import { TextField, FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useCreateFolder } from '../mutations/useCreateFolder';
import { Folder } from 'src/lib/types';
import { ModalBody, ModalFooter } from 'reactstrap';
import { useDocumentsContext } from '../DocumentsContext';
import { trackDocument } from 'src/lib/analytics';

interface Props {
  cancel: () => void;
  folder: Folder;
}

const NewFolderForm: React.FC<Props> = props => {
  const [name, setName] = useState<string>('');
  const [inputError, setError] = useState<string | undefined>(undefined);
  const [inputIsValid, setInputIsValid] = useState<boolean>(false);
  const textInput = useRef<HTMLInputElement>(null);
  const { category } = useDocumentsContext();
  const context = useCreateFolder();
  useEffect(() => {
    if (textInput.current) {
      window.setInterval(
        () => textInput.current && textInput.current.focus(),
        25
      );
    }
  }, [textInput]);

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value.length > 50) {
      setError(t.documents.validations.nameTooLong(50));
      setInputIsValid(false);
    } else if (e.target.value.length <= 0) {
      setError(t.documents.validations.nameTooShort);
      setInputIsValid(false);
    } else {
      setError(undefined);
      setInputIsValid(true);
    }
  };

  const handleSubmit = async e => {
    trackDocument(category, 'create_folder');
    e.preventDefault();
    if (name && inputIsValid) {
      context
        .createFolder(name, props.folder.id, props.cancel)
        .then(r => {
          props.cancel();
        })
        .catch(error => {
          console.log(error);
        });
    }
  };

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <ModalBody>
        <TextField
          id="new-folder-input"
          className="w-100"
          label={t.documents.NewFolderForm.nameLabel}
          innerRef={textInput}
          onChange={handleNameInputChange}
          value={name}
          required={true}
          error={inputError ? inputError : undefined}
        />
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          cancel={props.cancel}
          loading={context.loading}
          submitLabel={t.documents.NewFolderForm.submit}
          disabled={!inputIsValid}
          error={!!context.error}
          errorMsg={t.documents.genericError}
        />
      </ModalFooter>
    </Form>
  );
};

export default NewFolderForm;
