import * as React from 'react';

import { t } from 'src/lib/i18n';

import './DashboardContactCard.scss';

interface Props {
  label: string;
  text: string;
  email?: string;
  phoneNumber?: string;
  children?: React.ReactNode;
}

export function DashboardContactCard(props: Props) {
  return (
    <div className="DashboardContactCard">
      <h2>{props.label}</h2>

      <p>{props.text}</p>

      {props.email && (
        <p className="DashboardContactCard-contact">
          <strong>{t.dashboard.contactCardEmail} </strong>
          {props.email}
        </p>
      )}

      {props.phoneNumber && (
        <p className="DashboardContactCard-contact">
          <strong>{t.dashboard.contactCardPhone} </strong>
          {props.phoneNumber}
        </p>
      )}

      {props.children}
    </div>
  );
}
