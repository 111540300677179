export const getFileSortKey = key => {
  let fileKey;
  switch (key) {
    case 'createdBy':
      fileKey = 'uploadedBy';
      break;
    case 'createdAt':
      fileKey = 'uploadedAt';
      break;
    default:
      fileKey = key;
  }
  return fileKey;
};
