import React, { useMemo, useState } from 'react';
import { List, Loading, Pagination } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils/useRouter';
import { useSearchCases } from 'src/areas/main/subscriptions/queries/useSearchCases';
import { ListGroupItem } from 'reactstrap';
import { SearchListIncidentResult } from 'src/areas/main/search/components/list/SearchListIncidentResult';
import { SearchListPlannedWorkResult } from 'src/areas/main/search/components/list/SearchListPlannedWorkResult';
import * as types from '../queries/__types/useSearchCases';
import { SortOrder } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';
import { useSortFilter } from 'src/lib/filters';
import FilterSection from 'src/areas/main/common/FilterSection';

const DEFAULT_PAGE_SIZE = 20;

const caseToListEntry = (casesList): ListGroupItem => {
  return casesList?.map(
    (singleCase: types.useSearchCases_customer_search_results, key) => {
      if ('incident' in singleCase) {
        return (
          <SearchListIncidentResult key={key} entry={singleCase.incident} />
        );
      }
      if ('plannedWork' in singleCase) {
        return (
          <SearchListPlannedWorkResult
            key={key}
            entry={singleCase.plannedWork}
          />
        );
      }
    }
  );
};

type SortType = {
  key: string;
  order: SortOrder;
  label: string;
};

const Cases = () => {
  const translations = t.cases;
  const sortByCreatedAt: SortType = {
    key: 'createdAt',
    order: SortOrder.desc,
    label: translations.createdNewest,
  };
  const sortByCreatedAtAscending: SortType = {
    key: 'createdAt',
    order: SortOrder.asc,
    label: translations.createdOldest,
  };
  const sortByUpdatedAt: SortType = {
    key: 'updatedAt',
    order: SortOrder.desc,
    label: translations.updated,
  };

  const sortFields = [
    sortByCreatedAt,
    sortByCreatedAtAscending,
    sortByUpdatedAt,
  ];

  const initialSort = sortByCreatedAt;
  const router = useRouter<{ id: string }>();
  const subscriptionId = router.match.params.id;

  const [pageSize, setPageSize] = useState(DEFAULT_PAGE_SIZE);
  const [page, setPage] = useState(1);

  const [sortBy, setSortBy] = useState<string | undefined>(initialSort.key);
  const [sortOrder, setSortOrder] = useState<SortOrder>(initialSort.order);

  const [selectedTypeFilters, setSelectedTypeFilters] = useState<string[]>([]);
  const [selectedStatusFilters, setSelectedStatusFilters] = useState<string[]>(
    []
  );

  const setSort = (sortByType: string | undefined, sortByOrder: SortOrder) => {
    setSortBy(sortByType);
    setSortOrder(sortByOrder);
  };

  const filters = useMemo(
    () => [
      { filter: 'subscriptionId', value: [subscriptionId] },

      {
        filter: 'caseType',
        value: selectedTypeFilters,
      },
      {
        filter: 'active',
        value: selectedStatusFilters,
      },
    ],
    [selectedTypeFilters, selectedStatusFilters, subscriptionId]
  );

  const { cases, totalResults, loading } = useSearchCases({
    subscriptionId: subscriptionId,
    sortOrder: sortOrder,
    filters: filters,
    sortBy: sortBy,
    size: pageSize,
    page: page,
  });

  const sortFilters = useSortFilter({
    label: translations.sort,
    sortFields: sortFields,
    setSort: setSort,
    currentSortBy: sortBy,
    currentSortOrder: sortOrder,
    defaultOption: sortByCreatedAt,
  });

  if (loading) {
    return <Loading />;
  }

  const statusFilters = {
    id: 'status',
    label: 'Status',
    handleSave: value => {
      if (value) {
        setSelectedStatusFilters(value);
      }
    },
    selectedOptions: selectedStatusFilters,
    allowMultiple: true,
    options: [
      {
        value: 'true',
        label: translations.active,
      },
      {
        value: 'false',
        label: translations.closed,
      },
    ],
  };

  const typeFilters = {
    id: 'type',
    label: 'Type',
    allowMultiple: true,
    handleSave: value => {
      if (value) {
        setSelectedTypeFilters(value);
      }
    },
    selectedOptions: selectedTypeFilters,
    options: [
      {
        value: 'incident',
        label: translations.incident,
      },
      {
        value: 'plannedWork',
        label: translations.plannedWork,
      },
    ],
  };
  return (
    <>
      <FilterSection
        filterOptions={[statusFilters, typeFilters, sortFilters]}
      />

      <List border={true} container={false}>
        {caseToListEntry(cases)}
      </List>
      <div className="d-flex">
        <div className="ml-auto mt-2">
          <Pagination
            totalResults={totalResults ?? 0}
            page={page}
            pageSize={pageSize}
            setPage={setPage}
            setPageSize={setPageSize}
          />
        </div>
      </div>
    </>
  );
};

export default Cases;
