import * as React from 'react';
import { Button, Checkbox, FormSubmitBar, Spinner } from 'src/lib/ui';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'src/lib/i18n';
import { Contact } from 'src/lib/types';
import { ContactSelector } from './ContactSelector';
import { ContactPermissions } from '../displayContact/ContactPermissions';
import { formatName } from 'src/lib/utils';
import { ContactNotifications } from './ContactNotifications';
import { useContact } from '../../queries/useContact';
import { useCopyRoles } from '../../mutations/useCopyRoles';
import { useCopyNotificationSettings } from '../../mutations/useCopyNotificationSettings';
import { Icon } from '@telia/styleguide';

interface Props {
  setOpen: (open: boolean) => void;
  backToContactOverview: () => void;
  open: boolean;
  contact: Contact;
}

const CopyRolesFromUser = (props: Props) => {
  const i18n = t.contacts.editRoles;
  const [step, setStep] = React.useState(1);
  const [approve, setApprove] = React.useState(false);
  const [selectedContact, setSelectedContact] = React.useState('');

  const { data, loading: contactLoading } = useContact(selectedContact);
  const {
    copyRoles,
    data: copyRolesResult,
    loading: copyRolesLoading,
    error: copyRolesError,
  } = useCopyRoles();

  const {
    copyNotificationSettings,
    data: copyNotificationsResult,
    loading: copyNotificationsLoading,
    error: copyNotificationsError,
  } = useCopyNotificationSettings();

  const contact = data?.customer?.contacts?.length
    ? data.customer.contacts[0]
    : undefined;

  const onClick = () => {
    if (step === 1) {
      setApprove(false);
      setStep(2);
    } else if (step === 2 && approve) {
      copyNotificationSettings(selectedContact, props.contact.id);
      copyRoles(
        data?.customer?.contacts?.[0]?.user?.id ?? '',
        props.contact.user?.id ?? ''
      );
    }
  };

  React.useEffect(() => {
    if (!props.open) {
      setTimeout(function() {
        setStep(1);
      }, 500);
    }
    if (
      copyNotificationsResult &&
      !copyNotificationsError &&
      copyRolesResult &&
      !copyRolesError &&
      props.open
    ) {
      props.backToContactOverview();
    }
  }, [
    copyNotificationsError,
    copyNotificationsResult,
    copyRolesError,
    copyRolesResult,
    props,
    props.open,
  ]);

  React.useEffect(() => {
    if (
      copyNotificationsResult &&
      !copyNotificationsError &&
      copyRolesResult &&
      !copyRolesError
    ) {
      setStep(3);
    }
  }, [
    copyRolesResult,
    copyRolesError,
    copyNotificationsResult,
    copyNotificationsError,
  ]);

  const fullName = formatName({
    firstName: contact?.firstName,
    lastName: contact?.lastName,
  });

  const disabled = !approve || !contact || contactLoading;
  const error = (copyNotificationsError || copyRolesError) && step !== 1;
  const errorMsg =
    copyNotificationsError && copyRolesError
      ? i18n.somethingWentWrong
      : copyNotificationsError
      ? i18n.somethingWentWrongNotifications
      : i18n.somethingWentWrongRoles;

  return (
    <Modal
      size="md"
      isOpen={props.open}
      toggle={() => props.setOpen(!props.open)}
    >
      <ModalHeader>{i18n.copyAccess}</ModalHeader>
      <ModalBody>
        {!error ? (
          step !== 3 ? (
            <div className="d-flex flex-column">
              <div className="d-flex pb-4 align-items-center">
                {`${i18n.copyAccess} ${i18n.from} `}
                <ContactSelector
                  activeContactName={contact ? fullName : i18n.selectPerson}
                  setActiveContact={setSelectedContact}
                />
              </div>
              {step === 1 && (
                <div className="d-flex flex-column pb-4">
                  <div className="pt-4 font-weight-bold">{i18n.access}</div>
                  {contact && <ContactPermissions contact={contact} />}
                  {contactLoading && <Spinner />}
                </div>
              )}
              {step === 2 && contact && (
                <div className="d-flex flex-column pb-4">
                  <div className="pt-4 pb-2 font-weight-bold">
                    {i18n.notifications}
                  </div>

                  <ContactNotifications contact={contact} />
                </div>
              )}
              <div>
                <Checkbox
                  checked={approve}
                  label={
                    step === 1 ? i18n.confirmAccess : i18n.confirmNotifications
                  }
                  onChange={() => setApprove(!approve)}
                />
              </div>
            </div>
          ) : (
            <div className="d-flex flex-column align-items-center">
              <Icon
                icon="check-mark-circle"
                className="text-success"
                style={{ width: '42px', height: '42px' }}
              />
              <div className="text-success font-weight-bold">
                {i18n.accessAndNotificationsCopied}
              </div>
            </div>
          )
        ) : (
          <div className="d-flex flex-column align-items-center">
            <Icon
              icon="stop"
              className="text-danger"
              style={{ width: '42px', height: '42px' }}
            />
            <div className="text-danger font-weight-bold">{errorMsg}</div>
          </div>
        )}
      </ModalBody>
      <ModalFooter>
        {step === 3 || error ? (
          <Button
            color="grey"
            base="primary"
            onClick={() => props.setOpen(false)}
          >
            {i18n.close}
          </Button>
        ) : (
          <FormSubmitBar
            cancel={() => props.setOpen(false)}
            submitLabel={
              step === 1 ? i18n.reviewNotifications : i18n.copyAndSave
            }
            submit={onClick}
            disabled={disabled}
            loading={copyRolesLoading || copyNotificationsLoading}
          />
        )}
      </ModalFooter>
    </Modal>
  );
};
export default CopyRolesFromUser;
