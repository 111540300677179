import Modal from 'reactstrap/lib/Modal';
import React, { useEffect } from 'react';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ModalFooter } from 'reactstrap';
import { useFormik } from 'formik';
import { useCreateTag } from 'src/areas/main/subscriptions/tags/useCreateTag';
import { TextField, FormSubmitBar, toast } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import * as yup from 'yup';
import { trackTagsUpdate } from 'src/lib/analytics';

interface Props {
  title: string;
  toggle: () => void;
  show: boolean;
  refetchTags: () => void;
}

interface FormValues {
  tag: string;
  description: string | null;
}

const CreateTagModal: React.FC<Props> = props => {
  const translate = t.tags;
  const { createTag, data, loading, error } = useCreateTag();

  const formik = useFormik<FormValues>({
    initialValues: {
      tag: '',
      description: '',
    },
    onSubmit: values => {
      trackTagsUpdate('create', values.tag);
      return createTag(values.tag, values.description);
    },
    validationSchema: () => {
      return yup.object().shape({
        tag: yup.string().required('Required'),
        description: yup.string(),
      });
    },
  });
  useEffect(() => {
    if (data && !data.createTag?.error && !error) {
      props.refetchTags();
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (data && !loading) {
      props.toggle();
      formik.resetForm();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data, loading]);

  useEffect(() => {
    if (data && !data.createTag?.error && !loading && !error) {
      toast(
        'ok',
        translate.success.tagCreated(data.createTag?.tag?.tag ?? ''),
        { autoClose: 7000 }
      );
    } else if ((!loading && data?.createTag?.error) || error) {
      toast('error', translate.error.createAndEditTag, { autoClose: 7000 });
    }
  }, [data, loading, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={props.show} toggle={props.toggle} centered={true}>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="w-100"
            id={'tag'}
            label={translate.form.tag}
            required={true}
            error={
              formik.touched.tag && formik.errors.tag
                ? formik.errors.tag
                : undefined
            }
            onChange={formik.handleChange}
            value={formik.values.tag}
            onBlur={formik.handleBlur}
          />
          <TextField
            className="w-100"
            id={'description'}
            label={translate.form.description}
            required={false}
            onChange={formik.handleChange}
            value={formik.values.description ?? ''}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          cancel={props.toggle}
          submit={() => formik.handleSubmit()}
          loading={loading}
        />
      </ModalFooter>
    </Modal>
  );
};
export default CreateTagModal;
