import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import useStructureCacheUpdater from 'src/areas/main/documents/lib/useStructureCacheUpdater';
import { ApolloError } from 'apollo-client';
import { FileFragment } from 'src/lib/types';
import * as t from './__types/useRestoreFile';

const MUTATION_RESTORE_FILE = gql`
  mutation useRestoreFile($input: RestoreFileInput) {
    restoreFile(input: $input) {
      file {
        ...FileSearchResult
      }
    }
  }
  ${FileFragment}
`;

export type RestoreFileFunction = (
  fileId: string,
  cb?: () => void
) => Promise<void>;
interface RestoreFileResult {
  restoreFile: RestoreFileFunction;
  loading: boolean;
  error?: ApolloError;
}

export const useRestoreFile = (): RestoreFileResult => {
  const cacheUpdater = useStructureCacheUpdater<t.useRestoreFile>();

  const [mutation, { loading, error }] = useMutation<
    t.useRestoreFile,
    t.useRestoreFileVariables
  >(MUTATION_RESTORE_FILE);

  const restoreFile = async (id, cb) => {
    await mutation({
      variables: {
        input: { id },
      },
      update: cacheUpdater((ff, data) => ({
        files: data.restoreFile?.file
          ? [...ff.files, data.restoreFile.file]
          : ff.files,
        folders: ff.folders,
      })),
    });
    if (cb) {
      cb();
    }
  };

  return { restoreFile, loading, error };
};
