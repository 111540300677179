import * as React from 'react';
import gql from 'graphql-tag';
import { Mutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import { WithCustomerContext } from 'src/lib/global';
import { SubscriptionTaxModel } from 'src/__types/graphql-global-types';
import {
  MobileOrderDetails,
  NewSimCardRecipient,
  PersonalDetails,
} from '../types';

import { MobileOrderDetailsFragment } from '../lib/MobileOrderDetailsFragment';

const MUTATION_CREATE_NEW_MOBILE_SUBSCRIPTION_ORDER = gql`
  mutation createNewMobileSubscriptionOrderMutation(
    $input: CreateNewMobileSubscriptionOrderInput
  ) {
    createNewMobileSubscriptionOrder(input: $input) {
      orderDetails {
        ... on MobileOrderDetails {
          ...MobileOrderDetailsResult
        }
      }
    }
  }
  ${MobileOrderDetailsFragment}
`;

export interface CreateNewMobileSubscriptionOrderInput {
  costCenterId?: string;
  organisationId: string;
  phoneNumber?: string;
  simCardId?: string;
  newSimCardRecipient?: NewSimCardRecipient;
  personalDetails: PersonalDetails;
  taxModel: SubscriptionTaxModel;
  products: string[];
  flex1?: string;
  flex2?: string;
  flex3?: string;
  flex4?: string;
}

interface Variables {
  input: CreateNewMobileSubscriptionOrderInput & { customerId: string };
}
interface Data {
  createNewMobileSubscriptionOrder: MobileOrderDetails;
}

interface Props {
  children: (res: CreateMobileSubscriptionResult) => JSX.Element | null;
}

export interface CreateMobileSubscriptionResult {
  createNewMobileSubscriptionOrder: (
    input: CreateNewMobileSubscriptionOrderInput
  ) => void;
  loading: boolean;
  error?: ApolloError;
  data?: MobileOrderDetails;
}

const WithCreateNewMobileSubscriptionOrder = (props: Props) => (
  <WithCustomerContext>
    {cContext => (
      <Mutation<Data, Variables>
        mutation={MUTATION_CREATE_NEW_MOBILE_SUBSCRIPTION_ORDER}
      >
        {(mutation, mutationResult) => {
          const newMobileSubscriptionOrderContext: CreateMobileSubscriptionResult = {
            createNewMobileSubscriptionOrder: async input => {
              await mutation({
                variables: { input: { customerId: cContext.id, ...input } },
              });
            },
            loading: mutationResult.loading,
            error: mutationResult.error,
            data:
              mutationResult.data &&
              mutationResult.data.createNewMobileSubscriptionOrder,
          };
          return props.children(newMobileSubscriptionOrderContext);
        }}
      </Mutation>
    )}
  </WithCustomerContext>
);

export default WithCreateNewMobileSubscriptionOrder;
