import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { TvSubscriptionPageContext } from './TvSubscriptionPage';
import { useContext } from 'react';
import { TvChannelPackageQuantityDescription } from 'src/__types/graphql-global-types';
import { SubscriptionContentList } from '../Components/SubscriptionContentList/SubscriptionContentList';

const getLabel = (
  description: TvChannelPackageQuantityDescription | null
): string => {
  const i18n = t.subscriptions.TvSubscriptionContentList;
  switch (description) {
    case TvChannelPackageQuantityDescription.numberOfAvailableOutlets:
      return i18n.outletsAvailable;
    case TvChannelPackageQuantityDescription.numberOfAvailableSeats:
      return i18n.seatsAvailable;
    case TvChannelPackageQuantityDescription.numberOfGetBoxes:
      return i18n.boxes;
    default:
      return '';
  }
};

export const TvSubscriptionContentList = () => {
  const i18n = t.subscriptions.TvSubscriptionContentList;
  const subscription = useContext(TvSubscriptionPageContext);

  if (!subscription) {
    return null;
  }

  const mainChannel = subscription?.tv?.mainChannelPackage;
  const listResultCards: Array<React.ReactNode> = [];

  if (mainChannel) {
    listResultCards.push(
      <ListResultCard
        className="TvSubscriptionContentList-card"
        title={`${i18n.prefix.mainChannel} ${mainChannel.name}`}
        attributes={[
          {
            label: getLabel(mainChannel.quantityDescription),
            value: mainChannel.quantity,
          },
        ]}
      />
    );
  }

  if (subscription?.tv?.additionalChannels) {
    subscription?.tv?.additionalChannels.forEach(channel => {
      listResultCards.push(
        <ListResultCard
          className="TvSubscriptionContentList-card"
          title={`${i18n.prefix.extraChannel} ${channel.name}`}
          attributes={[
            {
              label: getLabel(channel.quantityDescription),
              value: channel.quantity,
            },
          ]}
        />
      );
    });
  }

  if (subscription?.tv?.additionalChannelPackages) {
    subscription?.tv?.additionalChannelPackages.forEach(channel => {
      listResultCards.push(
        <ListResultCard
          className="TvSubscriptionContentList-card"
          title={`${i18n.prefix.extraChannel} ${channel.name}`}
          attributes={[
            {
              label: getLabel(channel.quantityDescription),
              value: channel.quantity,
            },
          ]}
        />
      );
    });
  }
  if (subscription?.tv?.tvAddon) {
    subscription?.tv?.tvAddon.forEach(addon => {
      listResultCards.push(
        <ListResultCard
          className="TvSubscriptionContentList-card"
          title={`${i18n.prefix.addon} ${addon.name}`}
          attributes={[
            {
              label: i18n.numAvailable,
              value: addon.quantity,
            },
          ]}
        />
      );
    });
  }

  return (
    <SubscriptionContentList
      heading={t.subscriptions.TvSubscriptionPage.contentServices}
    >
      {listResultCards.map((node, idx) => (
        <React.Fragment key={idx}>{node}</React.Fragment>
      ))}
    </SubscriptionContentList>
  );
};
