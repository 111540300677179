import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { getIncidentIcon } from 'src/lib/types/incident/getIncidentIcon';
import { getIncidentStatusLabel } from 'src/lib/types/incident/getIncidentStatusLabel';
import { IncidentSearchResult } from 'src/lib/types/incident/__types/IncidentSearchResult';

export const SearchMenuIncidentResult: React.FC<SearchMenuResultProps<
  IncidentSearchResult
>> = props => {
  const { icon, iconColor } = getIncidentIcon(props.entry.incidentStatus);
  const statusLabel = getIncidentStatusLabel(props.entry.incidentStatus);

  return (
    <SearchMenuResultItem
      {...props}
      title={
        props.entry.shortDescription
          ? props.entry.shortDescription
          : props.entry.id
      }
      entry={props.entry}
      icon={icon}
      iconColor={iconColor}
      summary={`${statusLabel} ${
        props.entry.subscription ? props.entry.subscription.alias : null
      }`}
    />
  );
};
