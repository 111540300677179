import * as React from 'react';
import { t } from 'src/lib/i18n';
import { MappedRoleName, mapRoleNames, Roles } from './types';
import { colors } from 'src/lib/ui';
import AnimateHeight from 'react-animate-height';
import PermissionCard from '../PermissionCard/PermissionCard';
import { Toggle } from '@telia/styleguide/business';
import { trackContactAccess } from 'src/lib/analytics';
import { useFeature } from 'src/lib/utils';

interface Props {
  roles?: Roles;
  setFieldValue?: (field: any, values: any) => void;
  disabled?: boolean;
}

const ContactPermissionsForm: React.FC<Props> = props => {
  const isFederatedUser = useFeature('scim-federated-user'); // Scim-customers should not be able to select admin role
  const translate = t.ui.ContactPermissionsForm;
  const isAdmin = props.roles?.adminRole;

  const handleRoleChange = (params: {
    role: MappedRoleName;
    level: string;
    organisations?: string[];
    tags?: string[];
  }) => {
    const { role, level, organisations, tags } = params;

    trackContactAccess(role, level);
    if (level === 'full' || level === 'none') {
      props.setFieldValue?.(`roles.${role}`, {
        role: mapRoleNames[role],
        organisations: undefined,
        tags: undefined,
        level,
      });
    } else {
      props.setFieldValue?.(`roles.${role}`, {
        role: mapRoleNames[role],
        organisations,
        tags,
        level,
      });
    }
  };

  const handleAdminRole = (e: React.ChangeEvent<HTMLInputElement>) => {
    props.setFieldValue?.('roles.adminRole', e.target.checked);
    trackContactAccess('adminRole', 'true');
  };

  return (
    <div className="NewUser-permissions py-3">
      {!isFederatedUser && (
        <div style={{ color: colors.greyDarkText }}>
          <div className="d-flex pb-4 ">
            <div className="pr-4">{translate.fullAccess}</div>
            <Toggle
              id="roles.adminRole"
              checked={!!props.roles?.adminRole}
              onChange={e => handleAdminRole(e)}
            />
          </div>
          <small>{translate.fullAccessDescription}</small>
        </div>
      )}
      <AnimateHeight duration={500} height={isAdmin ? 0 : 'auto'}>
        <div className="mb-4">
          <PermissionCard
            heading={translate.roles.FinancialManager}
            id="roles.financialRole.level"
            disabled={props.disabled}
            selectedOrganisations={props.roles?.financialRole?.organisations}
            selectedLevel={props.roles?.financialRole?.level ?? 'none'}
            allowTags={false}
            onPermissionChange={(level, organisations) =>
              handleRoleChange({ role: 'financialRole', level, organisations })
            }
          />

          <PermissionCard
            heading={translate.roles.SystemAdministrator}
            id="roles.sysAdminRole.level"
            disabled={props.disabled}
            selectedOrganisations={props.roles?.sysAdminRole?.organisations}
            selectedTags={props.roles?.sysAdminRole?.tags}
            allowTags={true}
            selectedLevel={props.roles?.sysAdminRole?.level ?? 'none'}
            onPermissionChange={(level, organisations, tags) =>
              handleRoleChange({
                role: 'sysAdminRole',
                level,
                organisations,
                tags,
              })
            }
          />

          <PermissionCard
            heading={translate.roles.OrderManager}
            id="roles.orderRole.level"
            disabled={props.disabled}
            selectedOrganisations={props.roles?.orderRole?.organisations}
            allowTags={false}
            selectedLevel={props.roles?.orderRole?.level ?? 'none'}
            onPermissionChange={(level, organisations) =>
              handleRoleChange({ role: 'orderRole', level, organisations })
            }
          />
        </div>
      </AnimateHeight>
    </div>
  );
};

export default ContactPermissionsForm;
