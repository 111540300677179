import React, { useMemo } from 'react';
import { Icon } from '@telia/styleguide';
import { Attributes, Attribute, colors } from 'src/lib/ui';
import {
  NewStatisticsDetails,
  NewStatisticsDetailsBlock,
} from '../../lib/StatisticsDetails';
import { t } from 'src/lib/i18n';
import { ExtendedQosStatistics } from '../../queries/useExtendedQosStatistics';
import { getGranularity } from '../../utils/getGranularity';

const attr = (label: string, value: React.ReactNode) => (
  <Attribute label={label} value={value} displayBlock={true} />
);

interface ExtendedQosDetailsProps {
  data: ExtendedQosStatistics;
  idx: number;
}

export const ExtendedQosDetails = (props: ExtendedQosDetailsProps) => {
  const i18n = t.statistics.StandardDetails;
  const { inAttributes, outAttributes, otherAttributes } = useMemo(() => {
    const detailedData = props.data?.trafficClasses
      ? props.data?.trafficClasses[props.idx]
      : null;

    const inAttributes: Array<React.ReactNode> = [];
    const outAttributes: Array<React.ReactNode> = [];
    const otherAttributes: Array<React.ReactNode> = [];
    if (detailedData) {
      if (detailedData.jitter) {
        inAttributes.push(attr(i18n.jitter, detailedData.jitter.in));
        outAttributes.push(attr(i18n.jitter, detailedData.jitter.out));
      }
      if (detailedData.packetLoss) {
        inAttributes.push(attr(i18n.packetLoss, detailedData.packetLoss.in));
        outAttributes.push(attr(i18n.packetLoss, detailedData.packetLoss.out));
      }
      if (detailedData.roundTripTime) {
        otherAttributes.push(
          attr(i18n.roundTripTimeAvg, detailedData.roundTripTime.average)
        );
        otherAttributes.push(
          attr(i18n.roundTripTimeMax, detailedData.roundTripTime.max)
        );
      }
    }

    return {
      inAttributes,
      outAttributes,
      otherAttributes,
    };
  }, [props.data, props.idx, i18n]);

  return (
    <NewStatisticsDetails className="pt-4">
      <div className="grey-dark-text">{`${
        t.statistics.granularity
      }: ${getGranularity(props.data.granularity)}`}</div>
      {inAttributes.length > 0 ? (
        <NewStatisticsDetailsBlock
          heading={
            <span className="d-inline-flex align-items-center">
              {i18n.in}
              <Icon
                icon="arrow-large-up"
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '0.5rem',
                  transform: 'rotate(45deg)',
                  fill: '#9b10e3',
                }}
              />
            </span>
          }
        >
          <Attributes inline={true}>
            {inAttributes.map((attr, idx) => (
              <React.Fragment key={idx}>{attr}</React.Fragment>
            ))}
          </Attributes>
        </NewStatisticsDetailsBlock>
      ) : null}

      {outAttributes.length > 0 ? (
        <NewStatisticsDetailsBlock
          heading={
            <span className="d-inline-flex align-items-center">
              {i18n.out}
              <Icon
                icon="arrow-large-down"
                style={{
                  width: '20px',
                  height: '20px',
                  marginLeft: '0.5rem',
                  transform: 'rotate(-45deg)',
                  fill: colors.blueDark,
                }}
              />
            </span>
          }
        >
          <Attributes inline={true}>
            {outAttributes.map((attr, idx) => (
              <React.Fragment key={idx}>{attr}</React.Fragment>
            ))}
          </Attributes>
        </NewStatisticsDetailsBlock>
      ) : null}

      {otherAttributes.length > 0 ? (
        <NewStatisticsDetailsBlock
          fullWidth={true}
          heading={<span>{i18n.other}</span>}
        >
          <Attributes inline={true}>
            {otherAttributes.map((attr, idx) => (
              <React.Fragment key={idx}>{attr}</React.Fragment>
            ))}
          </Attributes>
        </NewStatisticsDetailsBlock>
      ) : null}
    </NewStatisticsDetails>
  );
};
