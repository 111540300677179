import { useState, useEffect } from 'react';
import { useRouter } from 'src/lib/utils/useRouter';
import { useFeature } from 'src/lib/utils/useFeature';
import { useCustomer } from 'src/lib/global';

interface Opts {
  /**
   * The namespace key used in `sessionStorage`.
   */
  key: string;
}

/**
 * Lightweight hook used to persist chosen filters across page
 * navigations (but within the same browsing session).
 *
 * It functions superficially on the URL level: When the query string
 * changes, it persists that query string in `sessionStorage`. And
 * when the page is first accessed _without_ a query string, it will
 * immediately redirect to the query string from `sessionStorage`,
 * if any.
 *
 */
export function usePersistFiltersInSession(opts: Opts) {
  const doNothing = !useFeature('filter-improvements');
  const customer = useCustomer();
  const [currentQueryString, setCurrentQueryString] = useState('');
  const router = useRouter();

  const queryString = router.location.search;
  const key = `usePersistFiltersInSession:${customer.id}:${opts.key}`;

  /**
   * First time we run the hook, we want to reuse the stored
   * filter parameters. We intentionally disable the eslint
   * rule react-hooks/exhaustive-deps to only run the first time.
   */
  useEffect(() => {
    if (doNothing) {
      return;
    }

    // If the query string is specified in the initial request,
    // we do nothing -- respect the query parameters.
    if (queryString) {
      return;
    }

    // Otherwise, we check the session storage to see if we
    // have a stored filter for this key:
    const preferredQueryString = window.sessionStorage.getItem(key);
    if (preferredQueryString) {
      router.history.replace(router.location.pathname + preferredQueryString);
    }
  }, [key, doNothing]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * Every time we run the hook, we save the current query string.
   */
  useEffect(() => {
    if (doNothing) {
      return;
    }

    // Update the stored query string if it differs from the one
    // currently cached.
    if (currentQueryString !== queryString) {
      setCurrentQueryString(queryString);
      window.sessionStorage.setItem(key, queryString);
    }
  }, [key, doNothing, currentQueryString, queryString]);
}
