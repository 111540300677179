import React from 'react';
import { t } from 'src/lib/i18n';
import { Attribute, StepFlowCard, colors, RadioGroup } from 'src/lib/ui';
import { FormState } from './CreateContact';

export const Step1 = (props: { form: FormState; isFederatedUser: boolean }) => (
  <StepFlowCard
    step={0}
    title={t.contacts.create.step1.title}
    currentStep={props.form.currentStep}
    isValid={props.form.isFieldValid('type')}
    onClick={() => props.form.setStep(0)}
    onContinue={props.form.nextStep}
    form={<Form flow={props.form} isFederatedUser={props.isFederatedUser} />}
    summary={<Summary {...props.form} />}
  />
);

const Form = (props: { flow: FormState; isFederatedUser?: boolean }) => (
  <>
    <small className="pb-5 d-block" style={{ color: colors.greyDarkText }}>
      {t.contacts.create.step1.description}
    </small>
    <RadioGroup
      value={props.flow.values.type}
      name="type"
      options={[
        {
          value: 'contact',
          label: t.contacts.create.step1.no,
        },
        {
          value: 'user',
          label: t.contacts.create.step1.yes,
          disabled: props.isFederatedUser,
        },
      ]}
    />
  </>
);
const Summary = (form: FormState) => (
  <Attribute
    label={t.contacts.formLabels.accessToMinPortal}
    value={
      form.values.type === 'contact'
        ? t.contacts.create.step1.no
        : t.contacts.create.step1.yes
    }
  />
);
