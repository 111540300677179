import React from 'react';
import { t as translate } from 'src/lib/i18n';
import './Settings.scss';
import { MinSidePageHeader } from 'src/areas/mobile/ui/MinSidePageHeader/MinSidePageHeader';
import { Settings } from 'src/areas/mobile/settings/Settings';

function SettingsPage() {
  return (
    <div>
      <MinSidePageHeader title={translate.mobile.menu.settings} />
      <div className="Settings container">
        <Settings />
      </div>
    </div>
  );
}
export default SettingsPage;
