import * as React from 'react';
import cs from 'classnames';

import './ClusterMarker.scss';

export interface ClusterMarkerProps {
  lat: number;
  lng: number;
  hasWarning?: boolean;
  count: number;
  onClick?: () => void;
}

const ClusterMarker: React.SFC<ClusterMarkerProps> = props => (
  <div
    className={cs({
      ClusterMarker: true,
      'ClusterMarker--warning': props.hasWarning,
      'ClusterMarker--n1': props.count >= 0 && props.count < 10,
      'ClusterMarker--n2': props.count >= 10 && props.count < 100,
      'ClusterMarker--n3': props.count >= 100 && props.count < 1000,
      'ClusterMarker--n4': props.count >= 1000 && props.count < 10000,
      'ClusterMarker--n5': props.count >= 10000 && props.count < 100000,
    })}
    onClick={props.onClick}
  >
    <svg viewBox="-10 -10 20 20" className="ClusterMarker-svg">
      <circle className="ClusterMarker-dot" cx={0} cy={0} r={5.6} />
      <circle className="ClusterMarker-ring" cx={0} cy={0} r={7.0} />
      {props.hasWarning && (
        <>
          <circle
            className="ClusterMarker-dot warning"
            cx={5}
            cy={-5}
            r={1.7}
          />
          <circle
            className="ClusterMarker-ring warning"
            cx={5}
            cy={-5}
            r={2.7}
          />
        </>
      )}
    </svg>
    <div className="ClusterMarker-text">{props.count}</div>
  </div>
);

export default ClusterMarker;
