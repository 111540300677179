import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';
import { getIncidentStatusLabel } from 'src/lib/types/incident/getIncidentStatusLabel';
import { getIncidentIcon } from 'src/lib/types/incident/getIncidentIcon';
import { SubscriptionBadge } from 'src/areas/main/subscriptions/lib/SubscriptionBadge/SubscriptionBadge';
import { t } from 'src/lib/i18n';
import {
  IncidentStatus,
  SubscriptionGroup,
} from 'src/__types/graphql-global-types';

interface Props {
  renderAs?: 'div' | 'li';
  entry: {
    id: string;
    incidentStatus?: IncidentStatus | null;
    shortDescription?: string | null;
    opened?: string | null;
    updatedAt?: string | null;
    createdAt?: string | null;
    subscription?: null | {
      id: string;
      name: string;
      alias: string | null;
      group: SubscriptionGroup | null;
      ownerAttributes?: {
        firstName: string | null;
        lastName: string | null;
      };
      phoneNumbers?: null | Array<{
        type: number;
        phoneNumber: string;
        seriesStart: string | null;
        seriesEnd: string | null;
      }>;
    };
  };
}

export const SearchListIncidentResult: React.FC<Props> = ({
  entry,
  renderAs,
}) => {
  const statusLabel = getIncidentStatusLabel(entry.incidentStatus);
  const { icon, iconColor } = getIncidentIcon(entry.incidentStatus);
  const translate = t.search.SearchListIncidentResult;

  return (
    <ListResultCard
      renderAs={renderAs}
      linkTo={{ incident: entry.id }}
      className="ListResultCard--Incident"
      icon={icon}
      iconColor={iconColor}
      iconDescription={statusLabel}
      title={`${translate.title} ${entry.id}`}
      badge={
        entry.shortDescription || statusLabel ? (
          <>
            {entry.subscription ? (
              <SubscriptionBadge
                className="ml-2"
                subscription={entry.subscription}
              />
            ) : null}
          </>
        ) : (
          undefined
        )
      }
      attributes={[
        entry.shortDescription
          ? {
              value: entry.shortDescription,
            }
          : undefined,

        entry.createdAt
          ? {
              label: translate.opened,
              value: entry.createdAt,
            }
          : undefined,

        entry.updatedAt
          ? {
              label: translate.lastUpdated,
              value: entry.updatedAt,
            }
          : undefined,
      ]}
      contextual={null}
    />
  );
};
