import * as React from 'react';
import { NavItem } from '../NavItem/NavItem';
import { NavButton } from '../NavButton/NavButton';

import { Icon, IconDefinition } from '@telia/styleguide';

export type MainMenuItemProps = {
  children?: string | React.ReactNode | undefined;
  label: string;
  icon?: IconDefinition;
  className?: string;
  exact?: boolean;
  to?: string;
  onClick?: () => void;
};

export const MainMenuItem: React.FC<MainMenuItemProps> = props => {
  const onClick = () => {
    if (props.onClick) {
      props.onClick();
    }
  };
  /**
   * If no "to" is defined, we only want to render a button and not a Link
   */
  if (!props.to) {
    return (
      <NavButton onClick={props.onClick} className={props.className}>
        {props.icon && (
          <Icon
            className="mr-2"
            style={{ fontSize: '16px' }}
            icon={props.icon}
          />
        )}
        {props.label}
      </NavButton>
    );
  }

  return (
    <NavItem
      exact={props.exact}
      to={props.to || ''}
      onClick={onClick}
      className={props.className}
    >
      {props.icon && (
        <Icon className="mr-2" style={{ fontSize: '16px' }} icon={props.icon} />
      )}
      {props.label}
    </NavItem>
  );
};
