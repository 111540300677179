import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useGenerateReportDetailedInvoice';
import { useCustomer } from 'src/lib/global';

export const GENERATE_ACCOUNTING_REPORT_FOR_DETAILED_INVOICE = gql`
  mutation useGenerateReportDetailedInvoice(
    $customerId: ID!
    $fromDate: Date!
    $toDate: Date!
    $organisationId: ID
    $subscriptionType: String
  ) {
    generateReportDetailedInvoice(
      input: {
        customerId: $customerId
        fromDate: $fromDate
        toDate: $toDate
        organisationId: $organisationId
        subscriptionType: $subscriptionType
      }
    ) {
      url
      filename
    }
  }
`;

export const useAccountingReportDetailedInvoice = () => {
  const customer = useCustomer();

  const [mutation, { loading, error }] = useMutation<
    t.useGenerateReportDetailedInvoice,
    t.useGenerateReportDetailedInvoiceVariables & { customerId: string }
  >(GENERATE_ACCOUNTING_REPORT_FOR_DETAILED_INVOICE);

  const generate = async (
    fromDate: string,
    toDate: string,
    organisationId?: string,
    subscriptionType?: string
  ) =>
    await mutation({
      variables: {
        customerId: customer.id,
        fromDate,
        toDate,
        organisationId,
        subscriptionType,
      },
    });

  return {
    generate,
    loading,
    error,
  };
};
