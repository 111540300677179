import React, { useState, useEffect } from 'react';
import {
  Fullscreen,
  FullscreenBody,
  FullscreenHeader,
  Button,
  ButtonTabs,
  ButtonTab,
} from 'src/lib/ui';
import ContactPermissionsForm from '../createContact/ContactPermissionsForm';
import { Contact } from 'src/lib/types';
import {
  getRoles,
  mapResponseToRoles,
  validRoles,
  getRoleList,
  mapRolesToInput,
} from '../editContact/utils';
import SaveOverlay from '../SaveOverlay/SaveOverlay';
import ContactCard from '../displayContact/ContactCard';
import './EditRoles.scss';
import { formatName } from 'src/lib/utils/formatName';
import { t } from 'src/lib/i18n';
import { useEditPerson } from '../../mutations/useEditPerson';
import { useCreatePerson } from '../../mutations/useCreatePerson';
import { useDowngradeUserToContact } from '../../mutations/useDowngradeUserToContact';
import { isEqual } from 'lodash';
import { useForm } from 'src/lib/utils';
import { trackContactAccess } from 'src/lib/analytics';
import { useUser } from 'src/lib/global/useUser';
import RemoveAccessRolesModal from './RemoveAccessRolesModal';
import CopyRolesFromUser from './CopyRolesFromUser';
import { EditNotificationSettings } from './EditNotificationSettings';

type TabValue = 'access' | 'notifications';

export const EditRoles = (props: { contact: Contact }) => {
  const [open, setOpen] = useState(false);
  const [openRemoveRoles, setOpenRemoveRoles] = useState(false);
  const [openCopyModal, setOpenCopyModal] = useState(false);
  const [tab, setTab] = useState<TabValue>('access');

  const i18n = t.contacts.editRoles;
  const { editRoles, ...editContext } = useEditPerson();
  const { data } = useUser();

  const {
    downgradeUserToContact,
    ...downgradeContext
  } = useDowngradeUserToContact();
  const { createUserOnContact, ...createContext } = useCreatePerson();
  const mappedRoles = mapResponseToRoles(getRoles(props.contact) || []);
  const currentUserRoles = mapResponseToRoles(
    data?.me?.customerAccess?.roles ?? []
  );

  const initialValues = {
    roles: mappedRoles,
    removeAccess: false,
  };
  const form = useForm({
    initialValues,
  });

  const submitRoles = () => {
    const roles = getRoleList(form.values.roles);
    if (form.values.removeAccess) {
      downgradeUserToContact(props.contact.id);
    } else if (props.contact.user) {
      editRoles(props.contact.id, mapRolesToInput(roles));
    } else {
      createUserOnContact(props.contact.id, mapRolesToInput(roles));
    }
  };

  useEffect(() => {
    if (editContext.data && !editContext.error) {
      setOpen(false);
    }
  }, [editContext.data, editContext.error]);

  useEffect(() => {
    if (createContext.data && !createContext.hasError) {
      setOpen(false);
    }
  }, [createContext.data, createContext.hasError]);

  useEffect(() => {
    if (downgradeContext.data && !downgradeContext.error) {
      setOpen(false);
    }
  }, [downgradeContext.data, downgradeContext.error]);

  const handleAdminAccessToggle = (
    e: React.SyntheticEvent<HTMLButtonElement, Event>
  ) => {
    trackContactAccess('remove access', '');
    form.setValue?.('removeAccess', true);
    setOpenRemoveRoles(true);
  };

  const onClickTab = (value: string) => {
    setTab(value as TabValue);
  };

  const fullName = formatName({
    firstName: props.contact.firstName,
    lastName: props.contact.lastName,
  });

  const isAdminOrOwnUser =
    currentUserRoles.adminRole || data?.me?.id === props.contact.user?.id;

  if (!open) {
    return (
      <Button color="white" onClick={() => setOpen(!open)}>
        {props.contact.user ? i18n.editAccess : i18n.giveAccess}
      </Button>
    );
  } else {
    return (
      <Fullscreen>
        <FullscreenHeader
          title={props.contact.user ? i18n.editAccess : i18n.giveAccess}
          onClickBack={() => setOpen(false)}
        />
        <FullscreenBody>
          <>
            <div className="EditRoles-contact">
              <h2>{i18n.accessToMinPortal} </h2>
              <div className="pb-3">
                {props.contact.user
                  ? i18n.editingAccessFor
                  : i18n.givingAccessFor}
                {fullName}
              </div>
              <ContactCard contact={props.contact} hideOptions={true} />
              {isAdminOrOwnUser && props.contact.user && (
                <div className="my-5">
                  <Button
                    icon="group"
                    color="grey"
                    onClick={() => setOpenCopyModal(true)}
                    className="mb-2 mb-md-0"
                  >
                    {`${i18n.copyAccess} ${i18n.fromAnotherUser}`}
                  </Button>
                  <Button
                    icon="stop"
                    color="grey"
                    onClick={e => handleAdminAccessToggle(e)}
                    className="ml-0 ml-md-2"
                  >
                    {i18n.removeAllAccess}
                  </Button>
                  <RemoveAccessRolesModal
                    submitChanges={submitRoles}
                    open={openRemoveRoles}
                    setOpen={setOpenRemoveRoles}
                    contactName={fullName}
                  />
                  <CopyRolesFromUser
                    open={openCopyModal}
                    setOpen={setOpenCopyModal}
                    contact={props.contact}
                    backToContactOverview={() => {
                      setOpenCopyModal(false);
                      setOpen(false);
                    }}
                  />
                </div>
              )}
            </div>

            <ButtonTabs
              currentActive={tab}
              onChangeHandler={onClickTab}
              className="EditRoles-tabs"
              fullWidth
            >
              <ButtonTab
                value="access"
                label={i18n.access}
                onClick={() => setTab('access')}
              />

              <ButtonTab
                value="notifications"
                label={i18n.notifications}
                onClick={() => setTab('notifications')}
              />
            </ButtonTabs>
            {tab === 'access' ? (
              <div className="EditRoles-form">
                <ContactPermissionsForm
                  roles={form.values.roles}
                  setFieldValue={form.setValue}
                />
                <SaveOverlay
                  disabled={
                    !validRoles(form.values.roles) ||
                    isEqual(form.values, initialValues)
                  }
                  submit={submitRoles}
                  cancel={() => setOpen(false)}
                  ignoreSidebar={true}
                  loading={editContext.loading || createContext.loading}
                  error={editContext.error || createContext.hasError}
                />
              </div>
            ) : (
              <EditNotificationSettings
                contact={props.contact}
                cancel={() => setOpen(false)}
              />
            )}
          </>
        </FullscreenBody>
      </Fullscreen>
    );
  }
};
