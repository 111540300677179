import React from 'react';
import { DropdownItem } from 'reactstrap';

interface OptionsDropdownHeaderProps {
  /**
   * The main heading text
   */
  heading?: string | null;

  /**
   * An optional subheading to display below the heading.
   */
  subheading?: string | null;

  /**
   * An optional action component, typically a button/icon,
   * to display to the right.
   */
  action?: React.ReactNode;
}

export const OptionsDropdownHeader = (props: OptionsDropdownHeaderProps) =>
  props.heading ? (
    <>
      <DropdownItem className="OptionsDropdown-header" header={true}>
        <div>
          <div className="OptionsDropdown-heading">{props.heading}</div>
          {props.subheading && (
            <div className="OptionsDropdown-subheading">{props.subheading}</div>
          )}
        </div>

        {props.action}
      </DropdownItem>

      <DropdownItem divider={true} />
    </>
  ) : null;
