import { UserFragment } from 'src/lib/types/contact/UserFragment';
import gql from 'graphql-tag';

export const ContactFragment = gql`
  fragment ContactSearchResult on Contact {
    id
    firstName
    lastName
    email
    mobilePhone
    secondaryPhone
    description
    employeeNumber
    user {
      ...UserFragment
    }
  }
  ${UserFragment}
`;
