import * as React from 'react';
import { getCpeStatusIcon } from 'src/lib/types';
import { CpeStatus } from 'src/__types/graphql-global-types';
import { CircleIcon } from 'src/lib/ui';
interface Props {
  className?: string;
  cpeStatus?: CpeStatus | null;
  hasStatus?: boolean;
  circleBackground?: boolean;
  size?: 'sm' | 'default';
  loading?: boolean;
}
export const SubscriptionStatusIcon: React.SFC<Props> = props => {
  const { icon, color } = getCpeStatusIcon(props.cpeStatus, props.hasStatus);
  return (
    <CircleIcon
      className={props.className}
      icon={icon}
      color={color}
      size={props.size}
      filled={props.circleBackground}
    />
  );
};

export default SubscriptionStatusIcon;
