import React, { useState, useEffect, useRef } from 'react';
import GoogleMapReact from 'google-map-react';
import { decode } from 'latlon-geohash';

import { InstallationAddress, Coordinate } from '../../types';
import { Subscription, Cluster } from 'src/lib/types';
import './Map.scss';
import config from '../../../../../config';
import MapStyles from './MapStyles';
import ProductMarker from '../ProductMarker/ProductMarker';
import MapMarker from '../MapMarker/MapMarker';
// import ClusterMarker from '../ClusterMarker/ClusterMarker';
import { hasServerError } from '../../../subscriptions/utils/subscriptionUtils';
import { LinkTo, Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useRouter } from 'src/lib/utils';
import { getHeatWeight } from './getHeatOption';
const mapOptions: any = {
  fullscreenControl: false,
  draggable: true,
  zoomControl: true,
  zoomControlOptions: {
    style: 0, // DEFAULT=0, SMALL=1, LARGE=2
    position: 3, // google.maps.ControlPosition.TOP_RIGHT -- google is loaded async, so the object itself isn't available yet
  },
  styles: MapStyles,
  clickableIcons: false,
  // mapTypeControl: true,
  // mapTypeControlOptions: {
  //   style: 1, // google.maps.MapTypeControlStyle.HORIZONTAL_BAR -- google is loaded async, so the object itself isn't available yet
  //   position: 2, // google.maps.ControlPosition.TOP_CENTER -- google is loaded async, so the object itself isn't available yet
  // },

  streetViewControl: false,
  streetViewControlOptions: {
    position: 3, // google.maps.ControlPosition.TOP_CENTER -- google is loaded async, so the object itself isn't available yet
  },
  streetViewPanoramaOptions: {
    zoomControl: false,
    addressControl: false,
    fullscreenControl: false,
    motionTrackingControl: false,
  },
};

const defaultCenter = { lat: 65.03438558537329, lon: 10.2705078125 };
const defaultZoomLevel = 5;
const selectedSubscriptionZoomLevel = 15;
const splitClustersZoomThreshold = 7;
const resetSelectedSubscriptionZoomThreshold = 14;
const clusterSizeThreshold = 3;

interface SubscriptionWithLocation {
  installationAddress: InstallationAddress;
}

interface Props {
  setMapBounds?: (bounds: string) => void;
  defaultBounds?: string;
  openSidebar?: () => void;
  activeSubscription?: Subscription;
  clusters?: Array<Cluster>;
  subscriptions?: Array<Subscription>;
  center?: Coordinate;
  zoomLevel?: number;
  locationRef?: HTMLDivElement | null;
}

const lonToLng = (c: Coordinate) => ({
  ...c,
  lng: c.lon,
});

export const Map = (props: Props) => {
  const map = useRef<any>(null);
  const router = useRouter();
  const { activeSubscription } = props;
  const [bounds, setBounds] = useState<string>();
  const zoomLevel = map.current?.zoom;

  useEffect(() => {
    const reset = () => {
      router.history.push('/kart');
      resetToDefault();
    };
    props.locationRef?.addEventListener('resetToDefault', reset);
    return () =>
      props.locationRef?.removeEventListener('resetToDefault', reset);
  }, [props.locationRef]); //eslint-disable-line

  useEffect(() => {
    if (activeSubscription) {
      panMapToActiveSubscription();
    }
  }, [activeSubscription]); //eslint-disable-line

  const loadSubscriptions = () => {
    if (zoomLevel < resetSelectedSubscriptionZoomThreshold) {
      router.history.push('/kart');
    }
    if (bounds) {
      return props.setMapBounds?.(bounds);
    } else {
      return resetToDefault();
    }
  };

  const onGoogleApiLoaded = (apiLoaded: any) => {
    map.current = apiLoaded.map;
    if (activeSubscription) {
      return panMapToActiveSubscription();
    } else {
      return loadSubscriptions();
    }
  };

  const onGoogleMapChangeHandler = (e: {
    // Note that Google uses `lng` for longitude, while we (and Elasticsearch) uses `lon`.
    bounds: {
      nw: {
        lat: number;
        lng: number;
      };
      se: {
        lat: number;
        lng: number;
      };
    };
  }) => {
    if (e && props.setMapBounds) {
      const bounds = `${e.bounds.nw.lat};${e.bounds.nw.lng};${e.bounds.se.lat};${e.bounds.se.lng}`;
      return setBounds(bounds);
    }
  };

  const resetToDefault = () => {
    if (props.defaultBounds) {
      props.setMapBounds?.(props.defaultBounds);
    }
    map.current?.setZoom(defaultZoomLevel);
  };

  const panMapToActiveSubscription = () => {
    if (
      map.current &&
      activeSubscription?.installationAddress?.location?.lat &&
      activeSubscription?.installationAddress?.location?.lon
    ) {
      panToMapLocation(
        activeSubscription.installationAddress.location.lat,
        activeSubscription.installationAddress.location.lon
      );
    }
  };

  const panToMapLocation = (
    lat: number,
    lon: number,
    zoomLevel = selectedSubscriptionZoomLevel
  ) => {
    if (map.current) {
      map.current.panTo(new (window as any).google.maps.LatLng(lat, lon));
      map.current.setZoom(zoomLevel);
    }
  };

  const getHeatMap = () => {
    const clusters =
      zoomLevel < splitClustersZoomThreshold
        ? props.clusters?.filter(
            cluster => cluster.members.length > clusterSizeThreshold
          )
        : [];
    const heatMapData = clusters?.map(cluster => {
      const coords: { lat: number; lon: number } = decode(cluster.id);

      return {
        lat: coords.lat,
        lng: coords.lon,
        weight: getHeatWeight(cluster.members.length),
      };
    });
    if (heatMapData) {
      return {
        positions: heatMapData,
        options: {
          radius: 50,
          opacity: 0.75,
          gradient: ['rgba(153, 10, 227, 0)', 'rgba(153, 10, 227, 1)'],
        },
      };
    }
    return undefined;
  };

  const renderErrorSubscriptions = (cluster: Cluster) => {
    if (cluster.hasFault) {
      cluster.members.map(sub => {
        const subscription = props.subscriptions?.find(s => s.id === sub.id);
        if (subscription && hasServerError(subscription)) {
          return renderSubscriptionMarker(sub);
        }
        return null;
      });
    }
  };

  const renderSingleSubscriptionMarkers = () => {
    return props.clusters?.map(cluster =>
      cluster.members.length <= clusterSizeThreshold ||
      zoomLevel >= splitClustersZoomThreshold
        ? cluster.members.map(member => renderSubscriptionMarker(member))
        : renderErrorSubscriptions(cluster)
    );
  };

  const renderSubscriptionMarker = (sub: { id: string }) => {
    const subscription = props.subscriptions?.find(s => s.id === sub.id);
    if (!subscription?.installationAddress?.location) {
      return null;
    }
    // dont render active subscription if its in the searchResult
    if (activeSubscription && activeSubscription.id === sub.id) {
      return null;
    }
    const coords = {
      lat: subscription.installationAddress.location.lat,
      lng: subscription.installationAddress.location.lon,
    };
    return (
      <LinkTo key={sub.id} {...coords} area="map" subscription={sub.id || ''}>
        <ProductMarker
          subscription={subscription}
          onClick={props.openSidebar}
        />
      </LinkTo>
    );
  };

  const renderActiveMarker = () => {
    if (!activeSubscription) {
      return null;
    }
    const coords = {
      lat: (activeSubscription as SubscriptionWithLocation).installationAddress
        .location.lat,
      lng: (activeSubscription as SubscriptionWithLocation).installationAddress
        .location.lon,
    };
    return (
      <LinkTo {...coords} area="map" subscription={activeSubscription.id}>
        <MapMarker
          status={activeSubscription.cpe?.status}
          onClick={props.openSidebar}
        />
      </LinkTo>
    );
  };

  return (
    <>
      <div className="Map-container heat map">
        <GoogleMapReact
          bootstrapURLKeys={{
            key: config.googleMapsApiKey || '',
          }}
          center={lonToLng(props.center || defaultCenter)}
          options={mapOptions}
          draggable={true}
          onGoogleApiLoaded={onGoogleApiLoaded}
          yesIWantToUseGoogleMapApiInternals={true}
          onChange={onGoogleMapChangeHandler}
          defaultZoom={props.zoomLevel ?? defaultZoomLevel}
          {...{
            heatmap: getHeatMap(),
            heatmapLibrary: true,
          }}
        >
          {renderActiveMarker()}
          {renderSingleSubscriptionMarkers()}
        </GoogleMapReact>
      </div>
      <Button
        className="Map-load"
        base="primary"
        icon="search"
        color="white"
        onClick={loadSubscriptions}
      >
        {t.locations.searchInThisArea}
      </Button>
    </>
  );
};
