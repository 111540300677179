export const getHeatWeight = (clusterSize: number) => {
  switch (true) {
    case clusterSize < 10:
      return 5;
    case clusterSize < 50:
      return 6;
    case clusterSize < 100:
      return 7;
    case clusterSize < 200:
      return 8;
    default:
      return 9;
  }
};
