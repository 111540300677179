import React from 'react';
import { t } from 'src/lib/i18n';
import { StepFlowCard, colors, RadioGroup } from 'src/lib/ui';
import { FormState } from './useReplaceContactStepFlow';

export const Step1 = (props: { form: FormState }) => (
  <StepFlowCard
    step={0}
    title={t.contacts.editServices.step1.whatChangesDoYouWantToMake}
    currentStep={props.form.currentStep}
    isValid={props.form.isFieldValid('replace')}
    onClick={() => props.form.setStep(0)}
    onContinue={props.form.nextStep}
    form={<Form {...props.form} />}
  />
);

const Form = (flow: FormState) => {
  const i18n = t.contacts.editServices.step1;
  return (
    <>
      <small className="pb-5 d-block" style={{ color: colors.greyDarkText }}>
        <div>{i18n.description(Object.keys(flow.values.map ?? {}).length)}</div>
      </small>
      <RadioGroup
        block={true}
        value={flow.values.replace}
        name="replace"
        options={[
          {
            value: 'all',
            label: i18n.option1,
          },
          {
            value: 'custom',
            label: i18n.option2,
          },
          {
            value: 'none',
            label: i18n.option3,
          },
        ]}
      />
    </>
  );
};
