import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { SubscriptionFragment } from 'src/lib/types/subscription/SubscriptionFragment';
import { SortOrder } from 'src/__types/graphql-global-types';
import * as t from './__types/useSearchSubscriptions';

const QUERY_SUBSCRIPTIONS = gql`
  query useSearchSubscriptions($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        results {
          __typename
          ... on SubscriptionSearchResult {
            subscription {
              ...SubscriptionSearchResult
            }
          }
        }
      }
    }
  }
  ${SubscriptionFragment}
`;

export function useSearchSubscriptions(search: {
  sortBy?: string;
  sortOrder?: SortOrder;
  filterQuery?: string;
  size?: number;
  page?: number;
  filter?: Array<{ filter: string; value: string[] }>;
  skipSearch?: boolean;
}) {
  const customer = useCustomer();
  const { data, error, loading } = useQuery<
    t.useSearchSubscriptions,
    t.useSearchSubscriptionsVariables
  >(QUERY_SUBSCRIPTIONS, {
    skip: search.skipSearch,
    variables: {
      customerId: customer.id,
      search: {
        types: ['subscription'],
        query: search.filterQuery ?? '',
        sortBy: search.sortBy,
        sortOrder: search.sortOrder,
        size: search.size ?? 20,
        page: search.page,
        filters: search.filter,
      },
    },
    errorPolicy: 'all',
  });

  const subscriptions =
    (data?.customer?.search
      ?.results as t.useSearchSubscriptions_customer_search_results_SubscriptionSearchResult[]) ??
    [];

  return { subscriptions, error, loading };
}
