import React, { useState } from 'react';
import RenameFileForm from '../forms/RenameFileForm';
import DeleteFileForm from '../forms/DeleteFileForm';
import { ContextMenu, ContextMenuItem } from 'src/lib/ui';
import DocumentsModal from '../lib/DocumentsModal/DocumentsModal';
import { t } from 'src/lib/i18n';
import { File } from 'src/lib/types';
import { useDocumentsContext } from '../DocumentsContext';
import { ChangeSharing } from './ChangeSharing';
import { Icon } from '@telia/styleguide';

interface Props {
  file: File;
}

const FileOptions: React.FC<Props> = props => {
  const [renameModal, setRenameModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [teliaInternal, setTeliaInternal] = useState(false);
  const { allowEditFileSharing } = useDocumentsContext();

  const toggleRenameModal = () => {
    setRenameModal(!renameModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };
  const toggelTeliaInternal = () => {
    setTeliaInternal(!teliaInternal);
  };

  return (
    <React.Fragment>
      <ContextMenu alignRight={true}>
        <ContextMenuItem onClick={toggleRenameModal}>
          <Icon icon="pen" className="mr-2" />
          {t.documents.FileOptions.renameFile}
        </ContextMenuItem>
        <ContextMenuItem onClick={toggleDeleteModal}>
          <Icon icon="trash" className="mr-2" />
          {t.documents.FileOptions.deleteFile}
        </ContextMenuItem>
        {allowEditFileSharing ? (
          <ContextMenuItem onClick={toggelTeliaInternal}>
            <Icon icon="visibility" className="mr-2" />
            {props.file.teliaInternal
              ? t.documents.FileOptions.setFileShared
              : t.documents.FileOptions.setFileInternal}
          </ContextMenuItem>
        ) : (
          undefined
        )}
      </ContextMenu>

      <DocumentsModal
        show={renameModal}
        toggle={toggleRenameModal}
        title={t.documents.FileOptions.renameFileModalHeading}
      >
        <RenameFileForm file={props.file} cancel={toggleRenameModal} />
      </DocumentsModal>
      <DocumentsModal
        show={deleteModal}
        toggle={toggleDeleteModal}
        title={t.documents.FileOptions.deleteFileModalHeading}
      >
        <DeleteFileForm cancel={toggleDeleteModal} file={props.file} />
      </DocumentsModal>
      {allowEditFileSharing ? (
        <DocumentsModal
          show={teliaInternal}
          toggle={toggelTeliaInternal}
          title={
            props.file.teliaInternal
              ? t.documents.FileOptions.setFileShared
              : t.documents.FileOptions.setFileInternal
          }
        >
          <ChangeSharing
            file={props.file}
            close={() => setTeliaInternal(false)}
          />
        </DocumentsModal>
      ) : (
        undefined
      )}
    </React.Fragment>
  );
};

export default FileOptions;
