import React, { useState } from 'react';

import { Loading, Button } from 'src/lib/ui';

import { useQuery } from '@apollo/react-hooks';
import { useAppContext } from 'src/lib/global';
import { useFeatureMutations } from './useFeatureMutations';

import * as q from './queries';

import { DefinitionModal } from './DefinitionModal';
import { AssignmentModal } from './AssignmentModal';
import { Icon } from '@telia/styleguide';

// {props.subscription ? (
//   <EditSubscriptionAlias
//     subscription={props.subscription}
//     toggleState={this.toggleEditAlias}
//     isOpen={this.state.editAlias}
//   />
// ) : null}

export function Features() {
  const [activeKey, setActiveKey] = useState<string | undefined>();
  const app = useAppContext();
  const { loading, error, data } = useQuery<q.QueryData>(q.query);
  const m = useFeatureMutations();

  /**
   * Assignment modal
   */
  // const [assignmentModalOpen, setAssignmentModalOpen] = useState(false);
  // const toggleAssignmentModalOpen = () =>
  //   setAssignmentModalOpen(!assignmentModalOpen);

  if (loading) {
    return <Loading />;
  }

  if (error) {
    return <div className="alert alert-error">{error.message}</div>;
  }

  if (!data) {
    return (
      <div className="alert alert-error">Could not load feature data.</div>
    );
  }

  const flags = data.featureFlags || [];
  flags.sort((a, b) => a.key.toLowerCase().localeCompare(b.key.toLowerCase()));

  const activeFeature = flags.find(f => f.key === activeKey);

  return (
    <div className="Features">
      <h1>Feature flags</h1>

      <DefinitionModal
        mode="add"
        initialValues={{
          key: '',
          description: '',
          exposeToFrontend: false,
        }}
        onSubmit={vals => {
          m.setFeatureFlag(vals);
        }}
        trigger={
          <Button icon="add" className="mb-3" transparent={true}>
            New feature flag
          </Button>
        }
      />

      <table className="Dev-table">
        <thead>
          <tr>
            <th>Feature, description</th>
            <th>
              Expose to
              <br /> frontend
            </th>
            <th>Assignments</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          {flags.map(f => (
            <tr
              key={f.key}
              style={{
                backgroundColor:
                  f.key === activeKey ? 'rgba(153,10,227,0.15)' : 'transparent',
              }}
            >
              <td
                style={{
                  cursor: 'pointer',
                  paddingLeft: '8px',
                }}
                onClick={() => setActiveKey(f.key)}
              >
                <div
                  style={{
                    fontWeight: 'bold',
                    fontSize: '14px',
                    fontFamily: 'monospace',
                    marginBottom: '4px',
                  }}
                >
                  {f.key}
                </div>
                <div style={{ fontSize: '12px' }}>{f.description}</div>
              </td>
              <td>
                {f.exposeToFrontend ? (
                  <span
                    style={{
                      color: '#00a388',
                      fontWeight: 700,
                    }}
                  >
                    <Icon icon="check-mark" /> Yes
                  </span>
                ) : null}
              </td>
              <td>{f.assignments ? f.assignments.length : 0}</td>
              <td>
                <DefinitionModal
                  mode="edit"
                  initialValues={{
                    key: f.key,
                    description: f.description || '',
                    exposeToFrontend: f.exposeToFrontend,
                  }}
                  onSubmit={values => {
                    m.setFeatureFlag(values);
                  }}
                  trigger={
                    <Button icon="pen" className="mr-2" size="small">
                      Edit
                    </Button>
                  }
                />

                <Button
                  disabled={Boolean(f.assignments && f.assignments.length > 0)}
                  onClick={() =>
                    window.confirm(
                      `Are you sure you want to delete the feature flag "${f.key}"?`
                    ) && m.deleteFeatureFlag({ key: f.key })
                  }
                  size="small"
                  icon="trash"
                >
                  Delete
                </Button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      <br />
      <br />
      {activeFeature ? (
        <>
          <h2>
            Flag{' '}
            <span style={{ fontFamily: 'monospace', fontSize: '87.5%' }}>
              {activeFeature.key}
            </span>
          </h2>

          <AssignmentModal
            mode="add"
            initialValues={{
              key: activeFeature.key,
              userId: app.access.id,
              customerId: undefined,
              value: undefined,
            }}
            onSubmit={vals => {
              m.assignFeatureFlag(vals);
            }}
            trigger={
              <Button icon="add" className="mb-3">
                New assignment
              </Button>
            }
          />

          {activeFeature.assignments && activeFeature.assignments.length > 0 ? (
            <table className="Dev-table">
              <thead>
                <tr>
                  <th>Customer</th>
                  <th>User</th>
                  <th>Value</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                {activeFeature.assignments.map((asn, ix) => (
                  <tr key={ix}>
                    <td>
                      {asn.customer ? (
                        <span>
                          {asn.customer.name} ({asn.customer.id})
                        </span>
                      ) : (
                        <span>
                          <em>All customers</em>
                        </span>
                      )}
                    </td>
                    <td>
                      {asn.user ? (
                        <span>{asn.user.email}</span>
                      ) : (
                        <span>
                          <em>All users</em>
                        </span>
                      )}
                    </td>
                    <td>
                      {asn.value === null ? <em>No value</em> : asn.value}
                    </td>

                    <td>
                      <AssignmentModal
                        mode="edit"
                        initialValues={{
                          key: activeFeature.key,
                          userId: asn.user ? asn.user.id : undefined,
                          customerId: asn.customer
                            ? asn.customer.id
                            : undefined,
                          value: asn.value || undefined,
                        }}
                        onSubmit={vals => {
                          m.assignFeatureFlag(vals);
                        }}
                        trigger={
                          <Button icon="pen" className="mr-2" size="small">
                            Edit
                          </Button>
                        }
                      />

                      <Button
                        onClick={() => {
                          console.log('Click!', asn, activeFeature);
                          if (
                            window.confirm(
                              `Are you sure you want to delete this assignment?`
                            )
                          ) {
                            m.unassignFeatureFlag({
                              key: activeFeature.key,
                              userId: asn.user ? asn.user.id : null,
                              customerId: asn.customer ? asn.customer.id : null,
                            });
                          }
                        }}
                        size="small"
                        icon="trash"
                      >
                        Delete
                      </Button>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          ) : (
            <div>No assignments.</div>
          )}
        </>
      ) : (
        <div>
          <em>Select a feature flag to view assignments.</em>
        </div>
      )}
    </div>
  );
}
