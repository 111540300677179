import gql from 'graphql-tag';

export const IncidentFollowerFragment = gql`
  fragment IncidentFollowerFragment on Contact {
    id
    firstName
    lastName
    email
    mobilePhone
    notificationSettings {
      incident {
        channels
      }
      change {
        channels
      }
    }
  }
`;
