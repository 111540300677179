import * as React from 'react';
import cs from 'classnames';
import { Link } from 'react-router-dom';
import { withRouter, RouteComponentProps } from 'react-router';

import './NavTabs.scss';

interface NavTabProps {
  path: string;
  exact?: boolean;
  label: string | React.ReactNode;
  active?: boolean;
  onClick?: (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void;
}

export const NavTab: React.FC<NavTabProps> = props => (
  <li
    className={cs({
      active: props.active,
    })}
  >
    <Link to={props.path} onClick={props.onClick}>
      {props.label}
    </Link>
  </li>
);

interface ButtonTabProps {
  value: string;
  label: string | React.ReactNode;
  active?: boolean;
  onClick?: (
    e: React.MouseEvent<HTMLAnchorElement | HTMLButtonElement, MouseEvent>,
    value: string
  ) => void;
}

export const ButtonTab: React.FC<ButtonTabProps> = props => (
  <li className={props.active ? 'active' : undefined}>
    <button
      onClick={e => {
        if (props.onClick) {
          props.onClick(e, props.value);
        }
      }}
    >
      {props.label}
    </button>
  </li>
);

interface TabsProps {
  headerTabs?: boolean;
  rightAligned?: boolean;
  fullWidth?: boolean;
  className?: string;
  type?: 'underlined' | 'outlined';
  centered?: boolean;
}

type ButtonTabsProps = TabsProps & {
  currentActive?: string;
  onChangeHandler: (value: string) => void;
};

export const ButtonTabs: React.FC<ButtonTabsProps> = props => {
  const onClick = (e, value: string) => {
    props.onChangeHandler(value);
  };
  return (
    <div
      className={cs(
        'NavTabs',
        props.fullWidth ? 'NavTabs--fullwidth' : undefined,
        props.type === 'outlined' ? 'NavTabs--outlined' : undefined,
        props.centered ? 'NavTabs--centered' : undefined,
        props.className
      )}
    >
      <ul>
        {React.Children.map(props.children, ch =>
          React.isValidElement(ch)
            ? React.cloneElement(ch, {
                active: ch.props.value === props.currentActive,
                onClick,
              })
            : null
        )}
      </ul>
    </div>
  );
};

const InnerNavTabs: React.FC<TabsProps & RouteComponentProps<{}>> = props => {
  return (
    <div
      className={cs(
        'NavTabs',
        props.fullWidth ? 'NavTabs--fullwidth' : undefined,
        props.type === 'outlined' ? 'NavTabs--outlined' : undefined,
        props.centered ? 'NavTabs--centered' : undefined,
        props.className
      )}
    >
      <ul>
        {React.Children.map(props.children, ch =>
          React.isValidElement(ch)
            ? React.cloneElement(ch, {
                active: ch.props.exact
                  ? props.location.pathname === ch.props.path
                  : props.location.pathname.indexOf(ch.props.path) === 0,
              })
            : null
        )}
      </ul>
    </div>
  );
};

export const NavTabs = withRouter(InnerNavTabs);
