import * as React from 'react';
import { NavLinkProps, Link, Route } from 'react-router-dom';
import cs from 'classnames';
import { t } from 'src/lib/i18n';

interface Props extends NavLinkProps {
  activeLinkClassName?: string;
  linkClassName?: string;
}

export const NavItem: React.FC<Props> = ({
  to,
  exact,
  strict,
  location: outerLocation,
  className,
  activeClassName,
  linkClassName,
  activeLinkClassName,
  activeStyle,
  style,
  isActive: getIsActive,
  'aria-current': ariaCurrent,
  children,
  ...rest
}) => {
  const path = typeof to === 'object' ? to.pathname : to;
  const escapedPath = path && path.replace(/([.+*?=^!:${}()[\]|/\\])/g, '\\$1');
  return (
    <Route
      path={escapedPath}
      exact={exact}
      strict={strict}
      location={outerLocation}
      children={({ location, match }) => {
        const isActive = !!(getIsActive && match
          ? getIsActive(match, location)
          : match);
        return (
          <li
            className={cs({
              [className !== undefined
                ? `nav-item ${className}`
                : 'nav-item']: true,
              [activeClassName !== undefined
                ? activeClassName
                : 'active']: isActive,
            })}
          >
            <Link
              to={to}
              className={cs({
                [linkClassName !== undefined
                  ? `nav-link ${linkClassName}`
                  : 'nav-link']: true,
                [activeLinkClassName !== undefined
                  ? activeLinkClassName
                  : 'active']: isActive,
              })}
              aria-current={(isActive && (ariaCurrent || 'page')) || undefined}
              {...rest}
            >
              <span>
                {children}
                {isActive ? (
                  <span className="sr-only"> ({t.ui.NavItem.currentItem})</span>
                ) : null}
              </span>
            </Link>
          </li>
        );
      }}
    />
  );
};
