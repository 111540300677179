import React, { useState } from 'react';
import { MobileMenu, MobileMenuItem } from './MobileMenu';

import './MobileHeader.scss';

import { useAppContext } from 'src/lib/global';
import SubscriptionSelector from 'src/areas/mobile/ui/menu/SubscriptionSelector';
import { MainAppMenuToggler } from 'src/areas/main/MainApp';
import { t } from 'src/lib/i18n';

import MobileSidebar from './MobileSidebar';
import { AreaSelectorDropdown } from 'src/areas/main/common/AreaSelectorDropdown';
import { NavTabs, NavTab } from 'src/lib/ui';
import { ProfileDropdown } from 'src/areas/main/common/ProfileDropdown';

export default function MobileHeader() {
  const [menuOpen, setMenuOpen] = useState(false);
  const appContext = useAppContext();

  return (
    <div className="MobileHeader">
      <div className="MobileHeader-desktop">
        <MobileMenu>
          <div className="ml-auto d-flex">
            {appContext.auth.user && !appContext.auth.user.isMobileUser && (
              <AreaSelectorDropdown />
            )}

            <ProfileDropdown />

            {appContext.access.mobileSubscriptions &&
              appContext.access.mobileSubscriptions.length > 1 && (
                <MobileMenuItem>
                  <SubscriptionSelector
                    subscriptionList={appContext.access.mobileSubscriptions}
                    activeSubscriptionId={appContext.activeSubscriptionId}
                    setActiveSubscription={appContext.setActiveSubscriptionId}
                  />
                </MobileMenuItem>
              )}
          </div>
        </MobileMenu>

        <div style={{ width: '60%', margin: 'auto' }}>
          <NavTabs fullWidth={true}>
            <NavTab
              path="/minside"
              exact={true}
              label={t.mobile.menu.mySubscription}
            />
            <NavTab path="/minside/historikk" label={t.mobile.menu.history} />
            <NavTab path="/minside/sms" label={t.mobile.menu.sendSMS} />
            <NavTab
              path="/minside/innstillinger"
              label={t.mobile.menu.settings}
            />
          </NavTabs>
        </div>
      </div>

      <div className="MobileHeader-mobile row justify-content-between justify-content-sm-center px-3">
        <MainAppMenuToggler
          className="d-block ml-3 mr-auto"
          onClick={() => setMenuOpen(!menuOpen)}
        />
        <MobileSidebar
          isOpen={menuOpen}
          onClose={() => setMenuOpen(false)}
          user={appContext.auth.user ? appContext.auth.user.name : undefined}
          company={appContext.activeCustomerName}
        />
      </div>
    </div>
  );
}
