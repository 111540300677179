import React from 'react';
import './SearchMenuSimple.scss';
import { t } from 'src/lib/i18n';
import { SearchMenuResult } from './menu/SearchMenuResult';
import { SearchMenuProps } from './SearchMenu';
import { Loading } from 'src/lib/ui';

const SearchMenuSimple: React.FC<SearchMenuProps> = props => {
  const menuProps = props.getMenuProps({
    className: 'SearchMenuSimple-list',
  });

  if (!props.isOpen) {
    return (
      <div className="SearchMenuSimple" style={{ display: 'none' }}>
        <ul {...menuProps} />
      </div>
    );
  }

  if (props.results && props.totalResults) {
    const items: Array<JSX.Element> = [];

    props.results.forEach((item, i) => {
      const disabled = props.itemIsDisabled
        ? props.itemIsDisabled(item)
        : false;
      const itemProps = props.getItemProps
        ? props.getItemProps({
            item: item,
            index: i,
          })
        : undefined;
      const key = `${item.__typename}:${item.id}`;

      items.push(
        <SearchMenuResult
          key={key}
          id={key}
          itemProps={itemProps}
          active={props.highlightedIndex === i}
          selected={props.selectedItem === item}
          entry={item}
          disabled={disabled}
          additionalInfo={
            props.additionalInfoOnResult
              ? props.additionalInfoOnResult(item)
              : null
          }
        />
      );
    });

    return (
      <div className="SearchMenuSimple">
        <div className="SearchMenuSimple-body">
          <ul {...menuProps}>{items}</ul>
        </div>
      </div>
    );
  }

  if (props.loading) {
    return (
      <div className="SearchMenuSimple">
        <ul {...menuProps}>
          <li>
            <Loading />
          </li>
        </ul>
      </div>
    );
  }

  return (
    <div className="SearchMenuSimple">
      <ul {...menuProps}>
        <li>{props.noHitsMessage || t.search.SearchMenu.noHits}</li>
      </ul>
    </div>
  );
};

export default SearchMenuSimple;
