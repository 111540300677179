import * as React from 'react';

type StateValueGetter<T> = (prev: T) => T;

function isStateValueGetter<T>(
  obj: T | StateValueGetter<T>
): obj is StateValueGetter<T> {
  return typeof obj === 'function';
}

interface Props<T> {
  children: (
    /**
     * The current state
     */
    state: T,
    /**
     * A function that takes either a state object or a function that returns a
     * new state object given the current state object as its parameter.
     */
    setState: (state: T | StateValueGetter<T>) => void
  ) => null | React.ReactNode;

  /**
   * The initial/default state value
   */
  default: T;
}

interface State<T> {
  stateValue: T;
}

export class WithState<T> extends React.Component<Props<T>, State<T>> {
  state = { stateValue: this.props.default };

  setStateValue = (update: T | StateValueGetter<T>) =>
    this.setState(({ stateValue }) => ({
      stateValue: isStateValueGetter(update) ? update(stateValue) : update,
    }));

  render() {
    return this.props.children(this.state.stateValue, this.setStateValue);
  }
}
