import React from 'react';
import { PageHeader, Tab } from 'src/areas/main/common/PageHeader/PageHeader';
import {
  Loading,
  SubscriptionGroupContainer,
  SubscriptionGroupInUrl,
  urlFor,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import useSubscriptionTypeCount, {
  SubscriptionTypeCount,
} from 'src/areas/main/subscriptions/queries/useSubscriptionTypeCount';
import { AppContext, useAppContext } from 'src/lib/global';
import { RoleTemplate } from 'src/__types/graphql-global-types';
import { Redirect } from 'react-router';
import { useSavedUserPreferences } from 'src/lib/userPreferences/SavedUserPreferencesContext';
import { useRouter } from 'src/lib/utils/useRouter';
import Tags from 'src/areas/main/subscriptions/Pages/Tag/Tag';
import { ListSubscriptionsByGroup } from 'src/areas/main/subscriptions/ListSubscriptionsByGroup';

function userIsAdmin(appContext: AppContext) {
  return appContext.customerRoles.includes(RoleTemplate.CustomerAdministrator);
}

export const SubscriptionsListContainer = () => {
  const subscriptionTypeCount = useSubscriptionTypeCount();
  const { userPreferences, setUserPreference } = useSavedUserPreferences();
  const router = useRouter<{ group: SubscriptionGroupContainer }>();

  const appContext = useAppContext();
  const isAdmin = userIsAdmin(appContext);

  if (subscriptionTypeCount.loading) {
    return <Loading />;
  }

  const getAvailableSubscriptionGroups = (
    types: SubscriptionTypeCount
  ): { [key in SubscriptionGroupInUrl]: boolean } => {
    return {
      alle: true,
      nettverk: Boolean(types.dataSubscriptions && types.dataSubscriptions > 0),
      mobil: Boolean(
        types.mobileSubscriptions && types.mobileSubscriptions > 0
      ),
      telefoni: Boolean(types.teleSubscriptions && types.teleSubscriptions > 0),
      tv: Boolean(types.tvSubscriptions && types.tvSubscriptions > 0),
    };
  };

  const availableSubscriptionGroups = getAvailableSubscriptionGroups(
    subscriptionTypeCount
  );
  const preferredSubscriptionGroup = userPreferences.subscriptionGroup as SubscriptionGroupContainer;

  const setUserPrefSubscriptionGroup = (group: SubscriptionGroupContainer) => {
    setUserPreference('subscriptionGroup', group);
  };

  /**
   * Routing logic to always ensure that the user goes to a valid sub-group.
   * "All" will always be available, and is the default fallback route.
   */

  /**
   * If chosen group in URL or userPrefs is not available for the current customer, redirect to "All".
   */
  if (
    (router.match.params.group ?? preferredSubscriptionGroup) !== 'tags' &&
    !availableSubscriptionGroups[
      router.match.params.group ?? preferredSubscriptionGroup
    ]
  ) {
    return (
      <Redirect
        to={urlFor({
          area: 'subscriptions',
          group: 'alle',
        })}
      />
    );
  }

  /**
   * This case is only valid when navigating from the main-menu, or when we dont spesific an explicit subGroup to navigate to.
   * Routing priority is then: URL -> userPrefs -> all (fallback)
   */
  if (!router.match.params.group) {
    return (
      <Redirect
        to={urlFor({
          area: 'subscriptions',
          group: preferredSubscriptionGroup ?? 'alle',
        })}
      />
    );
  }

  /**
   * NavTabs
   */
  const tabs: Tab[] = [
    {
      label: t.subscriptions.ListSubscriptionsByGroup.all.title,
      url: urlFor({ area: 'subscriptions' }),
      exact: true,
      onClick: () => setUserPrefSubscriptionGroup('alle'),
    },
    ...(availableSubscriptionGroups.nettverk
      ? [
          {
            label: t.subscriptions.ListSubscriptionsByGroup.data.title,
            url: urlFor({ area: 'subscriptions', group: 'nettverk' }),
            onClick: () => setUserPrefSubscriptionGroup('nettverk'),
          },
        ]
      : []),
    ...(availableSubscriptionGroups.mobil
      ? [
          {
            label: t.subscriptions.ListSubscriptionsByGroup.mobile.title,
            url: urlFor({ area: 'subscriptions', group: 'mobil' }),
            onClick: () => setUserPrefSubscriptionGroup('mobil'),
          },
        ]
      : []),
    ...(availableSubscriptionGroups.telefoni
      ? [
          {
            label: t.subscriptions.ListSubscriptionsByGroup.tele.title,
            url: urlFor({ area: 'subscriptions', group: 'telefoni' }),
            onClick: () => setUserPrefSubscriptionGroup('telefoni'),
          },
        ]
      : []),
    ...(availableSubscriptionGroups.tv
      ? [
          {
            label: t.subscriptions.ListSubscriptionsByGroup.tv.title,
            url: urlFor({ area: 'subscriptions', group: 'tv' }),
            onClick: () => setUserPrefSubscriptionGroup('tv'),
          },
        ]
      : []),
    ...(isAdmin
      ? [
          {
            label: 'Tags',
            url: urlFor({ area: 'subscriptions', group: 'tags' }),
            onClick: () => setUserPrefSubscriptionGroup('tags'),
          },
        ]
      : []),
  ];

  const groupArea =
    router.match.params.group || preferredSubscriptionGroup || 'alle';

  return (
    <>
      <PageHeader
        title={t.subscriptions.ListSubscriptionsByGroup.header}
        navTabs={tabs}
      />
      {groupArea === 'tags' ? (
        <Tags />
      ) : (
        <ListSubscriptionsByGroup subscriptionGroup={groupArea} />
      )}
    </>
  );
};
