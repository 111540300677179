import React from 'react';

import { useHistory } from 'react-router';

import { Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import illustration from './IpVpnOrderCard.illustration.svg';
import './IpVpnOrderCard.scss';

interface Props {
  /**
   * The URL the order button should link to
   */
  linkTo?: string;
}

export function IpVpnOrderCard(props: Props) {
  const history = useHistory();

  function onClick() {
    if (props.linkTo) {
      history.push(props.linkTo);
    }
  }

  return (
    <div className="IpVpnOrderCard">
      <div className="row align-items-center">
        <div className="col-md-8">
          <h2>{t.ordering.OrderIpVpn.IpVpnOrderCard.heading}</h2>

          <p>{t.ordering.OrderIpVpn.IpVpnOrderCard.description}</p>

          <Button base="primary" color="purple" onClick={onClick}>
            {t.ordering.OrderIpVpn.IpVpnOrderCard.buttonLabel}
          </Button>
        </div>
        <div className="col-md-4 d-none d-md-block text-center">
          <img src={illustration} alt="" />
        </div>
      </div>
    </div>
  );
}
