import { QueryParameters } from './useQueryParameters';
import { parseStringToNumber } from './parseStringTo';
import { trackFilter } from 'src/lib/analytics';

export const setFilterAndResetPage = (
  query: QueryParameters,
  field: string,
  value?: string | string[] | number
) => {
  trackFilter(query.path, field, value);

  const currentPage = parseStringToNumber(query.get('page')) || 1;

  if (currentPage && currentPage > 1) {
    query.set({
      [field]: value,
      page: 1,
    });
  } else {
    query.set(field, value);
  }
};
