import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';

import * as types from './__types/useAccessQuery';

export const query = gql`
  query useAccessQuery {
    globalSystemMessage {
      message
      twitterHandle
      timestamp
    }
    me {
      id
      firstName
      lastName
      name
      email
      mobilePhone
      locale
      allowCustomerSearch
      mobileSubscriptions {
        id
        customer {
          id
          name
        }
        user {
          firstName
          lastName
        }
        directory {
          firstName
          lastName
        }
      }
      customers {
        id
        name
        organisationNumber
      }
      featureFlags {
        flag
        value
      }
    }
  }
`;

interface Options {
  /**
   * Whether to skip the query (do not send the query)
   */
  skip?: boolean;
}

export default function useAccessQuery(opts: Options = {}) {
  return useQuery<types.useAccessQuery, {}>(query, {
    errorPolicy: 'all',
    skip: opts && opts.skip,
  });
}
