import * as React from 'react';
import { CostAggregate } from './lib/types';
import { FilterToggles } from 'src/lib/ui';
import {
  WithInvoiceLines,
  InvoiceLine,
  CostTable,
  CostTableColumn,
  CostItem,
} from './lib';
import { orderBy } from 'lodash';
import { RouteComponentProps } from 'react-router';
import {
  getFirstDayOfPeriod,
  getLastDayOfPeriod,
} from 'src/lib/utils/dateUtils';
import { OrganisationPage } from './lib/OrganisationPage';
import { WithCostAggregateForOrganisation } from './lib/queries/WithCostAggregateForOrganisation';

import { t } from 'src/lib/i18n';
import { FilterTogglesValue } from 'src/lib/ui/FilterToggles/FilterToggles';

type ProductGroup = 'mobile' | 'data' | 'tele' | 'other';
interface State {
  expandedItem: string | undefined;
  sortKey: string;
  sortOrder: 'asc' | 'desc';
  productGroup?: ProductGroup;
}

export class OrganisationDetails extends React.Component<
  RouteComponentProps<{ organisationId: string }>,
  State
> {
  constructor(props) {
    super(props);
    this.state = {
      expandedItem: undefined,
      sortKey: 'name',
      sortOrder: 'asc',
    };
  }

  setExpandedItem = (id: string) =>
    this.setState(prevState => ({
      expandedItem: id !== prevState.expandedItem ? id : undefined,
    }));

  setSort = (sortKey: string) => {
    const sortOrder: 'asc' | 'desc' =
      sortKey !== this.state.sortKey
        ? 'asc'
        : this.state.sortOrder === 'asc'
        ? 'desc'
        : 'asc';
    this.setState({ sortKey, sortOrder });
  };

  getCostTableItems = (items: CostAggregate[], invoiceLines: InvoiceLine[]) => {
    const tableItems = items.map(
      c =>
        ({
          id: c.productDesignId,
          name: c.productDesignDescription,
          amountGross: c.totalAmountGross,
          amountNet: c.totalAmountNet,
          lines:
            c.productDesignId === this.state.expandedItem
              ? invoiceLines.map(il => ({
                  name: il.productDesignDescription,
                  invoiceId: il.invoiceId,
                  periodStart: il.periodStart,
                  periodEnd: il.periodEnd,
                  amountGross: il.amountGross,
                  amountNet: il.amountNet,
                }))
              : [],
        } as CostItem)
    );

    return orderBy(
      tableItems,
      this.state.sortKey === 'amountNet' || this.state.sortKey === 'amountGross'
        ? [this.state.sortKey]
        : [ca => ca[this.state.sortKey].toLowerCase()],
      [this.state.sortOrder]
    );
  };

  getTotalGross = (items: CostAggregate[]) =>
    items.reduce((a, b) => a + b.totalAmountGross, 0);

  getTotalNet = (items: CostAggregate[]) =>
    items.reduce((a, b) => a + b.totalAmountNet, 0);

  setProductGroupFilter = (filter: ProductGroup | undefined) => {
    this.setState({
      productGroup: filter,
    });
  };

  render() {
    const { expandedItem } = this.state;
    const i18n = t.costs.OrganisationDetails;

    // group
    const availableFilters: FilterTogglesValue[] = [
      {
        icon: 'internet',
        label: i18n.filters.data,
        value: 'data',
      },
      {
        icon: 'mobile',
        label: i18n.filters.mobile,
        value: 'mobile',
      },
      {
        icon: 'phone',
        label: i18n.filters.tele,
        value: 'tele',
      },
      {
        label: i18n.filters.other,
        value: 'other',
      },
    ];

    return (
      <OrganisationPage organisationId={this.props.match.params.organisationId}>
        {(costContext, organisation) => (
          <>
            <WithCostAggregateForOrganisation
              organisationId={organisation.id}
              recursive={costContext.recursive}
              periodBy={costContext.periodBy}
              periodStart={costContext.activePeriod || ''}
              periodEnd={costContext.activePeriod || ''}
              groupBy={['month', 'productDesign']}
              productGroup={this.state.productGroup}
            >
              {res => (
                <WithInvoiceLines
                  organisationId={organisation.id}
                  recursive={costContext.recursive}
                  periodBy={costContext.periodBy}
                  periodStart={getFirstDayOfPeriod(
                    costContext.activePeriod || ''
                  )}
                  periodEnd={getLastDayOfPeriod(costContext.activePeriod || '')}
                  productDesign={expandedItem}
                  skip={!expandedItem}
                  size={10000} // No paging here?
                >
                  {invoiceLinesRes => (
                    <div className="container">
                      {/*
                            <ExportButton
                              type="invoiceLines"
                              format="xlsx"
                              organisationId={organisation.id}
                              recursive={costContext.recursive}
                              periodBy={costContext.periodBy}
                              periodStart={getFirstDayOfPeriod(
                                costContext.activePeriod
                              )}
                              periodEnd={getLastDayOfPeriod(
                                costContext.activePeriod
                              )}
                              productGroup={this.state.productGroup}
                            >
                              Last ned
                            </ExportButton>
                          */}

                      <FilterToggles
                        allowMultiple={false}
                        options={availableFilters}
                        currentFilter={
                          this.state.productGroup
                            ? [this.state.productGroup]
                            : undefined
                        }
                        setCurrentFilter={value =>
                          this.setProductGroupFilter(value?.[0] as ProductGroup)
                        }
                      />

                      <CostTable
                        data={this.getCostTableItems(
                          res.costAggregates,
                          invoiceLinesRes.lines
                        )}
                        expandedItem={this.state.expandedItem}
                        expandItem={this.setExpandedItem}
                        totalNet={this.getTotalNet(res.costAggregates)}
                        totalGross={this.getTotalGross(res.costAggregates)}
                        colWidths={['', '', '', '', '', '65px']}
                        totalLabel={i18n.CostTable.totalLabel}
                      >
                        <CostTableColumn
                          className="pl-4"
                          onClick={() => this.setSort('name')}
                          sorted={this.state.sortKey === 'name'}
                          sortOrder={this.state.sortOrder}
                        >
                          {i18n.CostTable.costGroup}
                        </CostTableColumn>
                        <CostTableColumn className="pl-4">
                          {i18n.CostTable.invoiceNumber}
                        </CostTableColumn>
                        <CostTableColumn className="pl-4">
                          {i18n.CostTable.period}
                        </CostTableColumn>
                        <CostTableColumn
                          style={{ textAlign: 'right' }}
                          onClick={() => this.setSort('amountNet')}
                          sorted={this.state.sortKey === 'amountNet'}
                          sortOrder={this.state.sortOrder}
                        >
                          {i18n.CostTable.noVAT}
                        </CostTableColumn>
                        <CostTableColumn
                          style={{ textAlign: 'right' }}
                          onClick={() => this.setSort('amountGross')}
                          sorted={this.state.sortKey === 'amountGross'}
                          sortOrder={this.state.sortOrder}
                        >
                          {i18n.CostTable.VAT}
                        </CostTableColumn>
                        <CostTableColumn />
                      </CostTable>
                    </div>
                  )}
                </WithInvoiceLines>
              )}
            </WithCostAggregateForOrganisation>
          </>
        )}
      </OrganisationPage>
    );
  }
}
