import React, { useEffect } from 'react';
import { useAppContext } from 'src/lib/global';
import { Page } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils';

import { Link } from 'react-router-dom';
import { CustomerSelector } from 'src/areas/main/common/CustomerSelector';
import logo from 'src/images/telia-logo.svg';
import './CustomerSelection.scss';
import { Button } from 'src/lib/ui/Button/Button';
import { t } from 'src/lib/i18n';

export default function CustomerSelection() {
  const router = useRouter();
  const appContext = useAppContext();

  useEffect(() => {
    if (
      appContext.access?.customers?.length === 1 &&
      !appContext.access?.allowCustomerSearch
    ) {
      appContext.setActiveCustomer(
        appContext.access.customers?.[0].id,
        appContext.access.customers?.[0].name
      );
      router.history.push('/');
    }
  }, [appContext.access?.customers, appContext.access?.allowCustomerSearch]); // eslint-disable-line

  return (
    <Page>
      <div className="CustomerSelection-header">
        <div className="CustomerSelection-header-logo mr-4">
          <Link to="/">
            <img src={logo} alt="Telia logo" />
          </Link>
        </div>

        <Button
          color="white"
          className="ml-auto"
          onClick={appContext.auth.requestSignOut}
          transparent={true}
        >
          {t.myDashboard.btnSignOut}
        </Button>
      </div>

      <div className="CustomerSelection-body">
        <h1>{t.customerSelection.hello}</h1>

        <div className="my-4">{t.customerSelection.chooseCustomer}</div>

        <div className="CustomerSelection-content">
          <CustomerSelector
            customerList={appContext.access?.customers ?? []}
            allowSearch={appContext.access.allowCustomerSearch === true}
            activeCustomerName={appContext.activeCustomerName || ''}
            setActiveCustomer={appContext.setActiveCustomer}
          />
        </div>
      </div>
    </Page>
  );
}
