import gql from 'graphql-tag';

export const MobileOrderDetailsFragment = gql`
  fragment MobileOrderDetailsResult on MobileOrderDetails {
    simCardNumber
    phoneNumber
    products {
      name
      monthlyCost
      action
      installationCost
    }
    customerName
    orderId
    status
    description
  }
`;
