import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import { CSSTransition, TransitionGroup } from 'react-transition-group';
import { ListResultCardSkeleton } from '../ListResultCard/ListResultCardSkeleton';

import './List.scss';
import { generateUeid } from 'src/lib/utils';

interface ListProps {
  renderAs?: 'ul' | 'ol';
  /**
   * List is by default put in a Bootstrap-container. Can be overriden and set to "false" if content is already in a container.
   */
  container?: boolean;
  /**
   * Border list and list-elements
   */
  border?: boolean;
  /**
   * Indicator that the data is loading
   */
  loading?: boolean;
  rowCount?: number;
}

export const List: React.FunctionComponent<ListProps> = props => {
  const [uid, setUid] = useState<string>(generateUeid());
  useEffect(() => {
    /**
     * If props.loading has changed, and is set to "false". There has been a fetch of new data, so we want to set new "UID" in order to tell CSS-transition to fade in.
     * This is to avoid generating a "UID" in each render.
     */
    if (props.loading === false) {
      setUid(generateUeid());
    }
  }, [props.loading]);

  const Component = props.renderAs ? props.renderAs : 'ul';

  const listContent = (
    <div
      className={cs(
        'List-container',
        props.border ? 'List-container--border' : undefined
      )}
    >
      {/** We need the transitionGroup wrapped around the CSS-trans in order for the transition to trigger. */}
      <TransitionGroup component={null}>
        <CSSTransition
          timeout={{
            enter: 300,
            appear: 0,
            exit: 0,
          }}
          classNames="fade"
          key={uid}
        >
          <Component
            className={cs('List', props.border ? 'List--border' : undefined)}
          >
            {props.loading ? skeletonNodes(props.rowCount) : props.children}
          </Component>
        </CSSTransition>
      </TransitionGroup>
    </div>
  );

  if (props.container !== false) {
    return <div className="container">{listContent}</div>;
  }

  return listContent;
};

const skeletonNodes = (count: number = 20) => {
  const skeletonArr: JSX.Element[] = [];
  for (let i = 0; i < count; i++) {
    skeletonArr.push(<ListResultCardSkeleton key={i} icon={true} />);
  }

  return skeletonArr;
};
