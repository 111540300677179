import { useCustomer } from 'src/lib/global';
import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useNetworkTerminationOrder';

const NETWORK_TERMINATION_ORDER = gql`
  mutation useNetworkTerminationOrder($input: NetworkTerminationOrderInput) {
    createNetworkTerminationOrder(input: $input) {
      success
      errors {
        code
        message
      }
    }
  }
`;
export const useNetworkTerminationOrder = () => {
  const [mutation, { data, loading, error }] = useMutation<
    t.useNetworkTerminationOrder,
    t.useNetworkTerminationOrderVariables
  >(NETWORK_TERMINATION_ORDER);
  const customer = useCustomer();
  const terminateSubscription = (
    subscriptionId: string,

    // YYYY-MM-DD
    terminationDate: string
  ) => {
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          subscriptionId,
          terminationDate,
        },
      },
    });
  };
  return {
    terminateSubscription,
    error: !!error || !!data?.createNetworkTerminationOrder?.errors,
    loading,
    submitted: !!data?.createNetworkTerminationOrder?.success,
  };
};
