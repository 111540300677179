import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { CostCenter } from 'src/lib/types';

export const SearchMenuCostCenterResult: React.FC<SearchMenuResultProps<
  CostCenter
>> = props => (
  <SearchMenuResultItem
    {...props}
    icon="company"
    title={props.entry.description ?? props.entry.id}
    summary={props.entry.id}
  />
);
