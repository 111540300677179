import React from 'react';
import { t } from 'src/lib/i18n';
import { Contact } from 'src/lib/types';

interface Props {
  contact: Contact;
}

export const ContactNotifications: React.FC<Props> = ({ contact }) => {
  const i18n = t.contacts.editRoles;
  const incidentSettings = contact.notificationSettings?.incident;
  const changeSettings = contact.notificationSettings?.change;

  const noNotifications =
    (!incidentSettings?.active && !changeSettings?.active) ||
    (incidentSettings?.subscriptions &&
      incidentSettings.subscriptions.length < 1 &&
      changeSettings?.subscriptions &&
      changeSettings.subscriptions.length < 1);

  const subscriptionLabel = (
    id: string,
    name: string,
    alias?: string | null
  ) => {
    return `${alias ? alias : name} ${id}`;
  };

  return (
    <div
      className="ml-2 d-flex flex-column overflow-auto"
      style={{ maxHeight: '220px' }}
    >
      {!noNotifications ? (
        <>
          <div className="pt-4 font-weight-bold">{i18n.incidents}</div>
          <div className="d-flex flex-column">
            {incidentSettings?.active &&
              incidentSettings.subscriptions &&
              incidentSettings.subscriptions.map(
                sub =>
                  sub && (
                    <div>{subscriptionLabel(sub.id, sub.name, sub.alias)}</div>
                  )
              )}
          </div>
          <div className="pt-4 font-weight-bold">{i18n.plannedWork}</div>
          <div className="d-flex flex-column">
            {changeSettings?.active &&
              changeSettings.subscriptions &&
              changeSettings.subscriptions.map(
                sub =>
                  sub && (
                    <div>{subscriptionLabel(sub.id, sub.name, sub.alias)}</div>
                  )
              )}
          </div>
        </>
      ) : (
        <div>{i18n.noNotifications}</div>
      )}
    </div>
  );
};
