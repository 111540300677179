import React from 'react';
import { Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import {
  OptionSelect,
  SearchOptionSelect,
  FormSubmitBar,
  Feature,
  toast,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useEditCostPreferences, Props } from './useEditCostPreferences';
import { trackUpdateServiceMetadata } from 'src/lib/analytics';

export const EditCostPreferences = (props: Props) => {
  const {
    costCenterLabel,
    costCenterOptions,
    costCenterSelectedOption,
    setCostCenter,
    taxModelLabel,
    taxModelOptions,
    taxModelSelectedOption,
    setTaxModel,
    handleSave,
    cancel,
    error,
    loading,
    disableSave,
  } = useEditCostPreferences(props);

  const handleSetCostCenter = (selectedCostCenter?: string[] | null) => {
    setCostCenter(selectedCostCenter);
    if (props.subscription.group)
      trackUpdateServiceMetadata(props.subscription.group, 'cost_center');
  };

  const handleSetTaxModel = (selectedCostCenter?: string[] | null) => {
    setTaxModel(selectedCostCenter);
    if (props.subscription.group)
      trackUpdateServiceMetadata(props.subscription.group, 'tax_model');
  };

  React.useEffect(() => {
    if (error) {
      toast('error', t.subscriptions.EditAdditionalInformationForm.errorMsg, {
        position: 'bottom-right',
        bodyClassName: 'd-flex w-  p-2 align-items-center',
        autoClose: false,
      });
    }
  }, [error]);

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleOpen}>
      <ModalHeader>
        {t.subscriptions.SubscriptionCostDetails.changeCostSettings}
      </ModalHeader>
      <ModalBody>
        <div className="pb-5">
          <div className="pb-3">
            {t.subscriptions.SubscriptionCostDetails.costCenter}
          </div>
          <SearchOptionSelect
            id="costCenter"
            icon="arrow-small-down"
            iconPosition="after"
            outline={true}
            fullWidth={true}
            label={costCenterLabel}
            options={costCenterOptions}
            selectedOptions={costCenterSelectedOption}
            handleSave={handleSetCostCenter}
            disableSaveOnClickOutside={true}
          />
          <Feature feature="tax-model">
            <div className="pb-3 pt-4">
              {t.subscriptions.SubscriptionCostDetails.taxModel}
            </div>
            <OptionSelect
              id="costCenter"
              icon="arrow-small-down"
              iconPosition="after"
              outline={true}
              fullWidth={true}
              label={taxModelLabel}
              options={taxModelOptions}
              selectedOptions={taxModelSelectedOption}
              handleSave={handleSetTaxModel}
              disableSaveOnClickOutside={true}
            />
          </Feature>
        </div>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          error={error}
          submit={handleSave}
          cancel={cancel}
          loading={loading}
          disabled={disableSave}
        />
      </ModalFooter>
    </Modal>
  );
};
