import * as React from 'react';
import cs from 'classnames';
import { CpeStatus } from 'src/__types/graphql-global-types';
import { Subscription } from 'src/lib/types';
import { Tooltip } from './Tooltip';
import './ProductMarker.scss';
import { MarkerProps } from '../../types';
// import { useSubscription } from 'src/areas/main/subscriptions/queries/WithSubscription';

/**
 * TODO: clean up subscription cluster fragmen, and fetch subscription
 */
const ProductMarker: React.SFC<MarkerProps & {
  subscription?: Subscription;
}> = props => {
  // const { subscription } = useSubscription(props.subscription?.id);

  return (
    <div className="ProductMarker-container" onClick={props.onClick}>
      <svg
        viewBox="0 0 1 1"
        className={cs({
          ProductMarker: true,
          'ProductMarker--disabled': !props.onClick,
          'ProductMarker--warning':
            props.subscription?.cpe?.status === CpeStatus.Critical,
        })}
      >
        <circle className="ProductMarker-dot" cx={0.5} cy={0.5} r={0.2} />
        <circle className="ProductMarker-ring" cx={0.5} cy={0.5} r={0.3} />
      </svg>
      <div className="ProductMarker-tooltip">
        <Tooltip subscription={props.subscription} />
      </div>
    </div>
  );
};

export default ProductMarker;
