import React, { useContext } from 'react';
import { BodyCard, Attributes, Attribute } from 'src/lib/ui';
import { t, useI18nContext } from 'src/lib/i18n';
import { TvSubscriptionPageContext } from './TvSubscriptionPage';
import { formatDate } from 'src/lib/utils';

export const TvSubscriptionAbout = () => {
  const subscription = useContext(TvSubscriptionPageContext);
  const i18nContext = useI18nContext();
  if (!subscription) {
    return null;
  }
  const i18n = t.subscriptions.TvSubscriptionAbout;

  return (
    <BodyCard heading={i18n.heading}>
      <Attributes>
        <Attribute
          label={i18n.alias}
          value={subscription.alias || <i>{i18n.aliasNotSet}</i>}
        />
        <Attribute label={i18n.id} value={subscription.id} />
        {/** SubscriptionType - map to "Telia TV"?*/}
        <Attribute label={i18n.type} value="TV" />
        <Attribute
          label={i18n.createdAt}
          value={formatDate(subscription.startTime, 'long', i18nContext.locale)}
        />
        <Attribute
          label={i18n.validUntil}
          value={
            subscription.endTime
              ? formatDate(subscription.endTime, 'long', i18nContext.locale)
              : i18n.validUntilNotSet
          }
        />
        <Attribute
          label={i18n.customerType}
          value={subscription.tv?.customerType}
        />
        <Attribute
          label={i18n.distributionTech}
          value={subscription.tv?.distributionTechnology}
        />
        <Attribute label={i18n.modelType} value={subscription.tv?.model} />
        <Attribute label={i18n.hdmiCount} value={subscription.tv?.hdmiCount} />
      </Attributes>
    </BodyCard>
  );
};
