import * as React from 'react';
import { MobilePeriodDetails } from 'src/areas/mobile/types';
import { formatNordic } from 'src/lib/utils';
import { Attributes, Attribute } from 'src/lib/ui';
import { useAppContext } from 'src/lib/global';
import { t } from 'src/lib/i18n';
import { convertMegaByteToGigaByte } from 'src/areas/main/statistics/utils/convertUnit';

interface Props {
  subscription: MobilePeriodDetails;
}

const SummaryGeneric: React.SFC<Props> = ({ subscription }) => {
  const { activeSubscriptionId } = useAppContext();
  const i18n = t.mobile.overview.subscription;
  return (
    <div className="py-3">
      <Attributes>
        <Attribute label={i18n.name} value={subscription.name} />
        <Attribute label={i18n.phoneNumber} value={activeSubscriptionId} />
        <Attribute
          label={i18n.fixedPrice}
          value={`Kr ${
            subscription.monthlyFee
              ? formatNordic(subscription.monthlyFee, 2)
              : ''
          }`}
        />
        <Attribute
          label={i18n.dataIncluded}
          value={
            subscription.dataFreeUse
              ? i18n.freeUse
              : subscription.dataInclusive
              ? `${formatNordic(
                  convertMegaByteToGigaByte(subscription.dataInclusive)
                )} GB`
              : ''
          }
        />
        <Attribute
          label={i18n.smsIncluded}
          value={
            subscription.smsFreeUse ? i18n.freeUse : subscription.smsInclusive
          }
        />
        <Attribute
          label={i18n.minutesIncluded}
          value={
            subscription.minutesFreeUse
              ? i18n.freeUse
              : subscription.minutesInclusive
          }
        />
      </Attributes>
    </div>
  );
};

export default SummaryGeneric;
