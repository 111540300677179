import React from 'react';
import {
  CardBody,
  CardHeader,
  CardFooter,
  SearchInput,
  colors,
} from 'src/lib/ui';
import { ListFilters } from './ListFilters';
import { List } from './List';

import { t } from 'src/lib/i18n';
import { Subscription } from 'src/lib/types';

interface Props {
  query: string;
  setQuery: (query: string) => void;
  loading: boolean;
  toggleFilters: () => void;
  totalFiltersActive: number;
  totalResults: number;
  searchResult: Subscription[];
}

export const SidebarSubscriptionList = (props: Props) => {
  const { query, setQuery } = props;
  return (
    <>
      <CardHeader>
        <h2> {t.locations.Sidebar.services}</h2>
      </CardHeader>
      <CardBody>
        <SearchInput value={query} onChange={e => setQuery(e.target.value)} />
      </CardBody>
      <CardBody noGutter={true}>
        <ListFilters
          toggleFilters={props.toggleFilters}
          numberOfActiveFilters={props.totalFiltersActive}
          totalResults={props.totalResults}
        />
      </CardBody>
      <CardBody noGutter={true} className="pb-0 w-100 Sidebar-mainContent">
        <List loading={props.loading} searchResult={props.searchResult} />
      </CardBody>
      <CardFooter noSpacing={true}>
        <small
          className="d-block my-3 mx-4 p-2 rounded-lg"
          style={{ backgroundColor: colors.greyMedium }}
        >
          <div className="font-weight-bold pb-2">
            {t.locations.Sidebar.footer.title}
          </div>
          <div>{t.locations.Sidebar.footer.description}</div>
        </small>
      </CardFooter>
    </>
  );
};
