import * as React from 'react';
import { map } from 'lodash';
import { SearchResult } from '../../search';
import { useDelayedRender } from 'src/lib/utils/useDelayedRender';
import { Order } from 'src/lib/types';
import { SearchListOrderResult } from '../../search/components/list/SearchListOrderResult';
import { List, NoResult } from 'src/lib/ui';

interface OrdersListProps {
  results: SearchResult[];
  loading: boolean;
  noResultsMessage: string;
}

export const OrdersList: React.FunctionComponent<OrdersListProps> = ({
  loading,
  results,
  noResultsMessage,
}) => {
  const delayedRender = useDelayedRender(500, loading);
  return (
    <List container={false} border={true} loading={delayedRender}>
      {results.length === 0 && !loading ? (
        <div style={{ width: '100%', height: '50vh' }}>
          <NoResult text={noResultsMessage} />
        </div>
      ) : (
        map(results, (res: Order) => (
          <SearchListOrderResult key={res.id} entry={res} />
        ))
      )}
    </List>
  );
};
