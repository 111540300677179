import React, { ReactNode, useState } from 'react';
import { Icon, IconDefinition } from '@telia/styleguide';
import AnimateHeight from 'react-animate-height';
import cs from 'classnames';

import './ExpansionPanel.scss';

interface Props {
  icon?: IconDefinition;
  heading: ReactNode;
  expanded?: boolean;
  children?: ReactNode;
  className?: string;
}
export const ExpansionPanel: React.FC<Props> = props => {
  const [expanded, setExpanded] = useState(props.expanded ?? false);
  return (
    <div
      className={cs(
        'ExpansionPanel',
        props.children ? undefined : 'ExpansionPanel--empty',
        props.className
      )}
    >
      <button
        disabled={!props.children}
        className="ExpansionPanel-button"
        onClick={() => setExpanded(!expanded)}
      >
        <div className="d-flex p-4 align-items-center">
          {props.icon && <Icon className="mr-3" icon={props.icon} />}
          {props.heading}
          {props.children && (
            <Icon
              className="ExpansionPanel-icon"
              icon="arrow-up" // expanded ? 'arrow-up' : 'arrow-down'}
              style={{ transform: `rotate(${expanded ? 0 : 180}deg)` }}
            />
          )}
        </div>
      </button>

      {props.children && (
        <AnimateHeight height={expanded ? 'auto' : 0}>
          <div className="text-left">{props.children}</div>
        </AnimateHeight>
      )}
    </div>
  );
};
