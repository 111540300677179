import React from 'react';
import { colors, urlFor, ButtonCard } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';
import { t } from 'src/lib/i18n';
import { getSubscriptionDisplayTitles } from 'src/lib/types/subscription/getSubscriptionDisplayTitles';
import { useMobileDataLink } from 'src/areas/main/subscriptions/queries/useMobileDataLink';

interface Props {
  subscription: {
    id: string;
  };
}
export const ConnectedSubscription = (props: Props) => {
  const { dataSubscription } = useMobileDataLink(props.subscription.id);

  if (!dataSubscription) {
    return null;
  }
  return (
    <ButtonCard
      icon="connectedRouter"
      stackHorizontally={true}
      to={urlFor({ subscription: dataSubscription.id })}
    >
      <div className="d-flex align-items-center">
        <div>
          <small style={{ color: colors.greyDarkText }}>
            {t.subscriptions.ConnectedSubscription.subscriptionIsUsedBy}
          </small>
          <div style={{ color: colors.black }}>
            {getSubscriptionDisplayTitles(dataSubscription).title}
          </div>
        </div>
        <Icon
          icon="arrow-large-right"
          className="ml-auto"
          style={{ height: '2rem', width: '2rem', fill: colors.black }}
        />
      </div>
    </ButtonCard>
  );
};
