import { SearchResult } from '../types';
import { ResourceType } from 'src/lib/types';

/**
 *
 * @param searchItem SearchResult item
 *
 * Mapper that maps the gql __typename to a corresponding resourceType
 */
export const mapTypenameToResourceType = (
  searchItem: SearchResult
): ResourceType | undefined => {
  if (searchItem.__typename === 'Subscription') {
    switch (searchItem.group) {
      case 'data':
        return 'dataSubscription';
      case 'mobile':
        return 'mobileSubscription';
      case 'tele':
        return 'teleSubscription';
      case 'tv':
        return 'tvSubscription';
      case 'other':
        return 'otherSubscription';
      // TODO Remove when backend removes this
      default:
        return 'subscription';
    }
  }
  switch (searchItem.__typename) {
    case 'Contact':
      return 'contact';
    case 'CostCenter':
      return 'costCenter';
    case 'File':
      return 'file';
    case 'Incident':
      return 'incident';
    case 'Invoice':
      return 'invoice';
    case 'Order':
      return 'order';
    case 'Organisation':
      return 'organisation';
    case 'Tag':
      return 'tag';
    default:
      return undefined;
  }
};
