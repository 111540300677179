import * as React from 'react';
import { Button, ButtonColorStyle } from '../Button/Button';
import { IconDefinition } from '@telia/styleguide';
import { useDownloadLink } from 'src/lib/utils/useDownloadLink';
import cs from 'classnames';

interface DownloadLinkProps {
  href: string;
  children: React.ReactNode;
  color?: ButtonColorStyle;
  className?: string;
  icon?: IconDefinition;
  formParameters?: {
    [key: string]: string | number | null | undefined;
  };
  disabled?: boolean;
  renderAs?: 'button' | 'link';
}

export const DownloadLink: React.FC<DownloadLinkProps> = ({
  href,
  children,
  className,
  color,
  formParameters,
  icon,
  disabled,
  renderAs,
}) => {
  const downloadLink = useDownloadLink();

  const onClickHandler = () => {
    downloadLink(href, formParameters ?? {});
  };

  if (renderAs === 'link') {
    return (
      // eslint-disable-next-line jsx-a11y/anchor-is-valid
      <a href="#" onClick={onClickHandler}>
        {children}
      </a>
    );
  }

  return (
    <Button
      color={color ? color : 'white'}
      onClick={onClickHandler}
      icon={icon}
      className={cs('DownloadLink-button', className)}
      disabled={disabled}
    >
      {children}
    </Button>
  );
};
