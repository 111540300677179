import * as React from 'react';
import {
  Step,
  StepShape,
  FormikSelectField,
  FormikRadioGroup,
  FormikTextField,
} from 'src/lib/flow';
import { Attribute, SelectOption } from 'src/lib/ui';
import * as yup from 'yup';
import OrderingSection from '../layout/OrderingSection';
import { MobileOrderConsumer } from './OrderMobileSubscription';
import { t } from 'src/lib/i18n';
import { SubscriptionTaxModel } from 'src/__types/graphql-global-types';
import { useCustomer } from 'src/lib/global';

export interface Step3Values {
  organisation?: string;
  costCenter?: string;
  taxModel: SubscriptionTaxModel;
  flex1?: string;
  flex2?: string;
}

const Step3: Step<Step3Values> = (): StepShape<Step3Values> => {
  const customer = useCustomer();
  const i18n = t.ordering.OrderMobileSubscription.Step3;
  const taxModelOptions = [
    { value: SubscriptionTaxModel.none, label: i18n.taxModel.opts.none },
    { value: SubscriptionTaxModel.free, label: i18n.taxModel.opts.free },
    {
      value: SubscriptionTaxModel.officePhone,
      label: i18n.taxModel.opts.officePhone,
    },
    {
      value: SubscriptionTaxModel.departmentPhone,
      label: i18n.taxModel.opts.departmentPhone,
    },
    {
      value: SubscriptionTaxModel.personal,
      label: i18n.taxModel.opts.personal,
    },
  ];

  const flex2 = customer.flexFields?.find(ff => ff.id === '2');
  const flex2Options: SelectOption[] =
    flex2?.options?.map(option => ({
      value: option.option,
      label: option.description ?? '',
    })) ?? [];

  return {
    title: i18n.title,
    validationSchema: yup.object().shape({
      organisation: yup.string().required(i18n.organisation.error),
      costCenter: yup.string(),
      taxModel: yup.string().required(i18n.taxModel.error),
    }),
    renderSummary: ({ values }) => (
      <MobileOrderConsumer>
        {mobileContext => {
          const organisationObject =
            mobileContext.data.organisations &&
            mobileContext.data.organisations.find(
              org => org.id === values.organisation
            );

          const taxModelObject = taxModelOptions.find(
            tax => tax.value === values.taxModel
          );

          const costCenterObject =
            mobileContext.data.costCenters &&
            mobileContext.data.costCenters.find(
              cc => cc.id === values.costCenter
            );

          return (
            <>
              <Attribute
                label={i18n.organisation.label}
                value={organisationObject ? organisationObject.name : ''}
              />
              {values.costCenter && values.costCenter !== '' && (
                <Attribute
                  label={i18n.costCenter.label}
                  value={
                    costCenterObject
                      ? `${costCenterObject.id} - ${costCenterObject.description}`
                      : ''
                  }
                />
              )}
              {mobileContext.isBergen &&
                values.flex2 &&
                values.flex2 !== '' && (
                  <Attribute label={i18n.areaOfUse} value={values.flex2} />
                )}
              {mobileContext.isBergen &&
                values.flex1 &&
                values.flex1 !== '' && (
                  <Attribute
                    label={i18n.fakturaMottaker}
                    value={values.flex1}
                  />
                )}
              <Attribute
                label={i18n.taxHeader}
                value={taxModelObject ? taxModelObject.label : ''}
              />
            </>
          );
        }}
      </MobileOrderConsumer>
    ),
    renderForm: ({ flow }) => (
      <MobileOrderConsumer>
        {mobileContext => {
          const costCenters = mobileContext.data.costCenters
            ? [
                { value: '', label: i18n.notSelected },
                ...mobileContext.data.costCenters.map(cc => ({
                  value: cc.id,
                  label: `${cc.description} - ${cc.code}`,
                })),
              ]
            : undefined;

          const organisations = mobileContext.data.organisations
            ? mobileContext.data.organisations.map(org => ({
                value: org.id || '',
                label: `${org.name} - ${org.id}`,
              }))
            : [];

          return (
            <>
              <OrderingSection>
                <div className="row">
                  <FormikSelectField
                    id="organisation"
                    label={i18n.organisation.label}
                    required={true}
                    options={organisations}
                    placeholder={i18n.organisation.placeholder}
                    className="col-12"
                    fastField={true}
                    virtualized={true}
                  />

                  {costCenters && (
                    <FormikSelectField
                      id="costCenter"
                      label={i18n.costCenter.label}
                      options={costCenters}
                      placeholder={i18n.costCenter.placeholder}
                      className="col-12 pt-4"
                      fastField={true}
                      virtualized={true}
                    />
                  )}

                  {mobileContext.isBergen && flex2Options && (
                    <FormikSelectField
                      id="flex2"
                      label={i18n.areaOfUse}
                      options={flex2Options}
                      placeholder={i18n.notSelected}
                      className="col-12 pt-4"
                      fastField={true}
                      virtualized={true}
                    />
                  )}

                  {mobileContext.isBergen && (
                    <FormikTextField
                      id="flex1"
                      label={i18n.fakturaMottaker}
                      className="col-md-6 pt-4"
                    />
                  )}
                </div>
              </OrderingSection>
              <OrderingSection header={i18n.taxHeader}>
                <FormikRadioGroup
                  block={true}
                  id="taxModel"
                  options={taxModelOptions}
                />
              </OrderingSection>
            </>
          );
        }}
      </MobileOrderConsumer>
    ),
  };
};

export default Step3;
