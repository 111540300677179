import React from 'react';
import cs from 'classnames';

import './BodyCard.scss';

interface Props {
  heading?: string;
  className?: string;
  headingClassName?: string;
  // bold?: boolean;
  noGutter?: boolean;
  borders?: boolean;
  boxShadow?: 1 | 4 | 24;
}

export const BodyCard: React.FC<Props> = props => (
  <div
    className={cs(
      'BodyCard',
      props.className,
      // props.bold && 'BodyCard--bold',
      props.noGutter && 'noGutter',
      props.borders !== false ? 'BodyCard--borders' : undefined,
      props.boxShadow ? `BodyCard--boxShadow--${props.boxShadow}` : undefined
    )}
  >
    {props.heading ? (
      <h2 className={props.headingClassName}>{props.heading}</h2>
    ) : null}

    {props.children}
  </div>
);

export const CardHeader: React.FC = props => {
  return <header className="CardHeader">{props.children}</header>;
};

export const CardBody: React.FC<{
  noGutter?: boolean;
  className?: string;
}> = props => {
  return (
    <div
      className={cs(
        'CardBody',
        props.noGutter ? 'CardBody--noGutter' : undefined,
        props.className
      )}
    >
      {props.children}
    </div>
  );
};

export const CardFooter: React.FC<{ noSpacing?: boolean }> = props => {
  return (
    <footer
      className={cs(
        'CardFooter',
        props.noSpacing ? 'CardFooter--noSpacing' : undefined
      )}
    >
      <hr className="CardFooter-divider" />
      <div className="CardFooter-content">{props.children}</div>
    </footer>
  );
};

export const CardDivider = (props: { noGutter?: boolean }) => {
  return (
    <hr
      className={cs(
        'CardDivider',
        props.noGutter ? 'CardDivider--noGutter' : undefined
      )}
    />
  );
};
