import React from 'react';
import logo from 'src/images/telia-logo-white.svg';
import { Button } from 'src/lib/ui';
import { t, useI18nContext } from 'src/lib/i18n';
import { useAppContext } from 'src/lib/global';
import cs from 'classnames';
import './MobileSidebar.scss';
import { NavLink } from 'react-router-dom';
import { Icon, IconDefinition } from '@telia/styleguide';

interface MobileSidebarProps {
  isOpen: boolean;
  onClose: () => void;
  user?: string;
  company?: string;
}

function MobileSidebar(props: MobileSidebarProps) {
  const appContext = useAppContext();
  const { toggleLocale } = useI18nContext();

  return (
    <>
      <nav className={cs('MobileSidebar', props.isOpen ? null : 'Closed')}>
        <div className="MobileSidebar-top">
          <div className="row flex-nowrap m-0">
            <img src={logo} alt="Telia logo" />

            <Button
              className="ml-auto"
              color="purple"
              transparent={true}
              icon="close"
              hideLabel={true}
              onClick={props.onClose}
            />
          </div>

          <div className="pt-3">
            <p className="MobileSidebar-user">{props.user}</p>
            <p className="MobileSidebar-company">{props.company}</p>
          </div>
        </div>

        <div className="MobileSidebar-body">
          <MobileSidebarNavItem
            to={'/minside'}
            exact={true}
            icon="home"
            text={t.mobile.menu.overview}
          />

          <MobileSidebarNavItem
            to={'/minside/historikk'}
            icon="graph"
            text={t.mobile.menu.history}
          />

          <MobileSidebarNavItem
            to={'/minside/sms'}
            icon="chat"
            text={t.mobile.menu.sendSMS}
          />

          <MobileSidebarNavItem
            to={'/minside/innstillinger'}
            icon={'settings'}
            text={t.mobile.menu.settings}
          />

          <hr />

          <MobileSidebarItem
            icon="world"
            text={t.mobile.menu.language}
            onClick={toggleLocale}
          />

          {appContext.auth.user && !appContext.auth.user.isMobileUser && (
            <MobileSidebarNavItem
              to={'/'}
              exact={true}
              icon="org-chart"
              text={t.mobile.menu.toPortal}
            />
          )}

          <hr />

          <MobileSidebarItem
            icon="lock-locked"
            text={t.mobile.menu.logout}
            onClick={appContext.auth.requestSignOut}
          />
        </div>
      </nav>

      <div className={'Grey'} onClick={props.onClose} />
    </>
  );
}

interface MobileSidebarItemProps {
  icon: IconDefinition;
  text: string;
  onClick?: () => void;
}

function MobileSidebarItem(props: MobileSidebarItemProps) {
  return (
    <div
      className="MobileSidebarItem row flex-nowrap align-items-center"
      onClick={props.onClick}
    >
      <div className="Indicator" />
      <Icon
        icon={props.icon}
        className="Icon mx-3"
        style={{ fontSize: '1.5rem' }}
      />
      <p>{props.text}</p>
    </div>
  );
}

interface MobileSidebarNavItemProps extends MobileSidebarItemProps {
  to: string;
  exact?: boolean;
}

function MobileSidebarNavItem(props: MobileSidebarNavItemProps) {
  const { to, exact, ...sidebarItemProps } = props;
  return (
    <NavLink
      to={to}
      exact={exact}
      activeClassName={'Active'}
      style={{ textDecoration: 'none' }}
    >
      <MobileSidebarItem {...sidebarItemProps} />
    </NavLink>
  );
}

export default MobileSidebar;
