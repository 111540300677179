import * as React from 'react';
import { Formik, Form } from 'formik';
import { FormikSelectField, FormikTextField } from 'src/lib/flow';
import { Loading, Attribute, LinkTo } from 'src/lib/ui';
import * as yup from 'yup';
import WithReserveNumberSeriesOrder from './WithReserveNumberSeriesOrder';
import { t } from 'src/lib/i18n';
import { OrderingComponentShape } from '../types';
import { AmountListForOrders } from '../lib/GetAmountListForOrders';

interface OrderNumberSeriesProps {
  children: (props: OrderingComponentShape) => React.ReactNode;
}

const OrderNumberSeries: React.FC<OrderNumberSeriesProps> = props => {
  const i18n = t.ordering.MobileUnitOrder.OrderNumberSeries;
  const handleSubmit = (
    values,
    handler: (input: { quantity: number }) => void
  ) => {
    handler({
      quantity: parseInt(values.quantity, undefined),
    });
  };

  return (
    <WithReserveNumberSeriesOrder>
      {reserveNumberSeriesMutation => {
        return (
          <div>
            <Formik
              initialValues={{
                quantity: '',
                comment: '',
              }}
              validationSchema={() =>
                yup.object().shape({
                  quantity: yup.number().required(i18n.ordering.quantity.error),
                  comment: yup.string(),
                })
              }
              onSubmit={(values, actions) => {
                handleSubmit(
                  values,
                  reserveNumberSeriesMutation.reserveNumberSeriesOrder
                );
              }}
            >
              {formikProps => {
                let form;
                if (reserveNumberSeriesMutation.loading) {
                  form = <Loading loadingText={i18n.loading} />;
                } else if (
                  reserveNumberSeriesMutation.data &&
                  reserveNumberSeriesMutation.data.orderDetails &&
                  !reserveNumberSeriesMutation.error
                ) {
                  form = (
                    <div>
                      <h2>{i18n.submitted.thankYou}</h2>
                      <div>
                        {i18n.submitted.expectedDelivery}
                        <br />
                        {i18n.submitted.info}{' '}
                        <LinkTo
                          order={
                            reserveNumberSeriesMutation.data.orderDetails
                              .orderId
                          }
                        >
                          {
                            reserveNumberSeriesMutation.data.orderDetails
                              .orderId
                          }
                        </LinkTo>
                      </div>
                      <br />
                      <h2>{i18n.submitted.orderDetails}</h2>
                      <Attribute
                        label={i18n.ordering.quantity.label}
                        value={
                          reserveNumberSeriesMutation.data.orderDetails.quantity
                        }
                      />
                    </div>
                  );
                } else {
                  form = (
                    <Form>
                      <div className="d-flex flex-column">
                        <FormikSelectField
                          className="mb-3"
                          id="quantity"
                          options={AmountListForOrders}
                          required={true}
                          label={i18n.ordering.quantity.label}
                          fastField={true}
                        />
                        <FormikTextField
                          className="mb-3"
                          id="comment"
                          label={i18n.ordering.comment}
                        />
                      </div>
                    </Form>
                  );
                }

                return props.children({
                  header: i18n.pageHeader,
                  render: form,
                  submit: formikProps.submitForm,
                  submitLabel: i18n.ordering.sendOrderBtn,
                  isFormValid: formikProps.isValid,
                  resetForm: () => {
                    formikProps.resetForm();
                    if (reserveNumberSeriesMutation.reset) {
                      reserveNumberSeriesMutation.reset();
                    }
                  },
                });
              }}
            </Formik>
          </div>
        );
      }}
    </WithReserveNumberSeriesOrder>
  );
};

export default OrderNumberSeries;
