import * as React from 'react';
import gql from 'graphql-tag';
import { Query, QueryResult, useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/WithStatisticsQoS';

const query = gql`
  query WithStatisticsQoS(
    $customerId: ID!
    $input: QoSStatisticsInput!
    $subscriptionId: [ID!]!
  ) {
    customer(id: $customerId) {
      id
      subscriptions(ids: $subscriptionId) {
        id
        statisticsQoS(input: $input) {
          took
          periodStart
          periodEnd
          trafficClasses {
            name
            alternativeName
          }
          granularity
          periods {
            periodStart
            periodEnd
            trafficClasses {
              name
              transmittedBytes
              transmitRate
              dropRate
              droppedBytes
            }
          }
        }
      }
    }
  }
`;

export type QosStatisticsPeriod = Omit<
  t.WithStatisticsQoS_customer_subscriptions_statisticsQoS_periods,
  '__typename'
>;

export const useQosStatistics = (inputProps: {
  subscriptionId: string;
  periodStart: string;
  periodEnd: string;
  periods: number;
  interface?: string | null;
}) => {
  const { subscriptionId, ...restProps } = inputProps;
  const customer = useCustomer();
  /**
   * Since we cannot conditonally render a hook, we rather skip the query if some params are missing
   */
  const skipQuery = !restProps.interface;

  const res = useQuery<t.WithStatisticsQoS, t.WithStatisticsQoSVariables>(
    query,
    {
      variables: {
        customerId: customer.id,
        subscriptionId: [subscriptionId],
        input: restProps,
      },
      skip: skipQuery,
    }
  );

  return {
    loading: res.loading,
    error: res.error,
    data: res.data?.customer?.subscriptions?.[0] ?? null,
  };
};

interface QoSStatisticsInput {
  periodStart: string;
  periodEnd: string;
  periods: number;
}

interface Variables {
  customerId: string;
  input: QoSStatisticsInput;
  subscriptionId: string[];
}
/**
 * Interface for a period shown in statistics
 * Allowing string | number for "Dates" since we parse Date-Strings to number (unix-epoch)
 */
export interface Period {
  periodStart: string | number;
  periodEnd: string | number;
  trafficClasses: TrafficClasses[];
}

interface TrafficClasses {
  name: string;
  transmittedBytes: number;
  /**
   * kbps
   */
  transmitRate: number;
  /**
   * kbps
   */
  dropRate: number;
  droppedBytes: number;
}

interface Data {
  customer?: {
    subscriptions?: Array<{
      statisticsQoS?: {
        periods?: null | Array<Period>;
      };
    }>;
  };
}
interface Props {
  customerId: string;
  subscriptionId: string;
  periodStart: string;
  periodEnd: string;
  periods: number;
  children: (result: QueryResult<Data, Variables>) => JSX.Element | null;
}

const WithStatisticsQoS = (props: Props) => (
  <Query<Data, Variables>
    query={query}
    variables={{
      customerId: props.customerId,
      subscriptionId: [props.subscriptionId],
      input: {
        periodStart: props.periodStart,
        periodEnd: props.periodEnd,
        periods: props.periods,
      },
    }}
  >
    {props.children}
  </Query>
);

export default WithStatisticsQoS;
