import React, { useContext } from 'react';
import { PeriodBy, CostCenter, Period } from 'src/lib/types';
import { Organisation } from './types';

export interface CostContext {
  activePeriod: Period | undefined;

  /**
   * Set the active period, or undefined to unselect the currently selected active periode.
   */
  setActivePeriod: (period: Period | undefined) => void;

  recursive: boolean;
  setRecursive: (recursive: boolean) => void;
  periodBy: PeriodBy;
  setPeriodBy: (periodBy: PeriodBy) => void;

  /**
   * The root organisation ID of the customer, from the perspective of the current user.
   */
  rootOrganisationId: string;

  /**
   * The root organisation of the customer, from the perspective of the current user.
   */
  rootOrganisation: Organisation;

  /**
   * A list of all organisations for the customer.
   */
  allOrganisations?: Organisation[];

  /**
   * The customer to which the organisation belongs.
   */
  customer: {
    /**
     * The ID of the customer to which the organisation belongs.
     */
    id: string;

    /**
     * The name of the customer to which the organisation belongs.
     */
    name: string;
  };

  /**
   * The available cost/invoice/usage periods for the current customer.
   */
  costPeriods: {
    invoicePeriods: Period[];
    usagePeriods: Period[];
  };

  /**
   * Find the cost center with the given ID, or `undefined` if the cost center was not found.
   */
  getCostCenterById: (id: string) => CostCenter | undefined;

  /**
   * Find the organisation with given ID, or `undefined` if the organisation was not found.
   */
  getOrganisationById: (id: string) => Organisation | undefined;

  /**
   * Find the children of the organisation with the given ID, or undefined if the organisation was not found.
   */
  getChildrenById: (id: string) => Organisation[] | undefined;
}

export const CostContext = React.createContext<CostContext>({} as CostContext);
export const useCostContext = () => useContext(CostContext);

const { Provider } = CostContext;
export { Provider as CostContextProvider };
