const myDashboard = {
  heading: {
    profile: 'Profil og innstillinger',
    services: 'Tjenestetilganger',
  },
  title: {
    preferences: 'Varslingsinnstillinger',
    portal: 'Min portal',
    mobileSite: 'Min side',
    profile: 'Min profil',
    services: 'Mine tjenester',
  },
  btnDevelop: 'Development',
  description: {
    preferences:
      'Varsling på tjenester og feilmeldinger. Kanal- og områdevelger for varslinger...',
    portal:
      'Få oversikt over kundens tjenester, melde feil og bestilling av nye tjenester',
    profile: 'Brukernavn og passord, adresse og personopplysninger...',
    mobileSite:
      'Dine mobileabonnement, faktura, oppgarder, forbruk, kostander...',
    services: 'Få oversikt over tjeneste du er kontakt for...',
    mobileSubscription: (number: string) =>
      `Se detaljer og gjør endringer for ditt abonnement på telefon ${number}`,
    customer: (name: string) =>
      `Få oversikt tjenester, melde feil og bestilling av nye tjenester for ${name}`,
  },
  myLanguage: {
    language: 'Språk:',
    norwegian: 'Norsk',
    english: 'English',
  },
  btnSignOut: 'Logg ut',
  btnBack: 'Tilbake',
};

export default myDashboard;
