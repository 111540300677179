import React, { useState } from 'react';
import {
  Button,
  Attribute,
  BodyCard,
  Toolbar,
  ButtonCard,
  Feature,
} from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { EditCostPreferences } from '../Components';
import { getTaxModelLabelAndDescription } from '../../utils/getTaxModelLabelAndDescription';
import { Subscription } from 'src/lib/types';
import { useTvSubscription_customer_subscriptions } from '../Tv/__types/useTvSubscription';

interface Props {
  subscription: Subscription | useTvSubscription_customer_subscriptions;
  disableEdit?: boolean;
}

export const SubscriptionCostDetails: React.FC<Props> = props => {
  const [editCostCenter, setEditCostCenter] = useState<boolean>(false);

  const toggleEditCostCenter = () => {
    setEditCostCenter(!editCostCenter);
  };

  const translate = t.subscriptions.SubscriptionCostDetails;
  if (!props.subscription) {
    return null;
  }

  const { costCenter, taxModel } = props.subscription;

  return (
    <>
      {!(costCenter || taxModel) && !props.disableEdit ? (
        <ButtonCard
          onClick={toggleEditCostCenter}
          stackHorizontally={true}
          icon="money"
          color="dark"
        >
          {translate.btnAdd}
        </ButtonCard>
      ) : (
        <BodyCard borders={true} heading={translate.title}>
          {costCenter && (
            <Attribute
              label={translate.costCenter}
              value={
                costCenter.description
                  ? `${costCenter.description} - ${costCenter.code}`
                  : costCenter.code
              }
            />
          )}
          <Feature feature="tax-model">
            {taxModel && (
              <Attribute
                label={translate.taxModel}
                value={getTaxModelLabelAndDescription(taxModel).title}
              />
            )}
          </Feature>
          {!props.disableEdit && (
            <Toolbar className="mt-4">
              <Button color="white" onClick={toggleEditCostCenter} icon="pen">
                {translate.btnChange}
              </Button>
            </Toolbar>
          )}
        </BodyCard>
      )}

      <EditCostPreferences
        subscription={props.subscription}
        toggleOpen={toggleEditCostCenter}
        isOpen={editCostCenter}
      />
    </>
  );
};
