import { SearchFilterOption } from './../../__types/graphql-global-types';
import { useSearchOptions } from './useSearchOptions';
import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';

export const useCpeTypeFilter = () => {
  const query = useQueryParameters();
  const { data } = useSearchOptions();
  const selectedOptions = query.getArray('cpeType');
  const i18n = t.subscriptions.filters.cpeType;

  const cpeOptions = data?.customer?.searchOptions?.filters
    ?.filter(option => option.filter === SearchFilterOption.cpeType)
    ?.flatMap(o => o.options);

  const options =
    cpeOptions?.map(option => ({ label: option, value: option })) ?? [];

  return options?.length
    ? {
        id: 'cpeType',
        label: i18n.label,
        options: options,
        allowMultiple: true,
        allowSearch: true,
        selectedOptions,
        handleSave: values => {
          setFilterAndResetPage(query, 'cpeType', values ? values : undefined);
        },
      }
    : null;
};
