import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import * as t from './__types/useAllowedCallerIdsForSubscription';

export const QUERY_ALLOWED_CALLER_IDS_FOR_SUBSCRIPTION = gql`
  query useAllowedCallerIdsForSubscription($msisdn: ID!) {
    subscription(id: $msisdn) {
      id
      allowedCallerIdList
    }
  }
`;

export const useAllowedCallerIdsForSubscription = (subscriptionId: string) => {
  const { data, loading, error } = useQuery<
    t.useAllowedCallerIdsForSubscription,
    t.useAllowedCallerIdsForSubscriptionVariables
  >(QUERY_ALLOWED_CALLER_IDS_FOR_SUBSCRIPTION, {
    variables: {
      msisdn: subscriptionId,
    },
  });
  return {
    allowedCallerIdList: data?.subscription?.allowedCallerIdList,
    error,
    loading,
  };
};
