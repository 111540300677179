import React from 'react';

import { Icon } from '@telia/styleguide';

import { Button } from 'src/lib/ui';

import { t } from 'src/lib/i18n';

import './OrderReceipt.scss';

interface Props {
  title?: string;
}

export function OrderReceipt(props: Props) {
  return (
    <div className="OrderReceipt">
      <div className="OrderReceipt-inner">
        <div className="OrderReceipt-icon">
          <Icon icon="check-mark" />
        </div>

        <h1 className="OrderReceipt-heading">
          {t.ordering.OrderIpVpn.OrderReceipt.heading}
        </h1>

        <hr />

        <div>
          <p>{t.ordering.OrderIpVpn.OrderReceipt.p1}</p>
          <p>{t.ordering.OrderIpVpn.OrderReceipt.p2}</p>
          <p>{t.ordering.OrderIpVpn.OrderReceipt.p3}</p>
        </div>

        <div className="d-flex justify-content-between mt-5">
          <Button base="primary" color="purple" to="/bestilling">
            {t.ordering.OrderIpVpn.OrderReceipt.backButton}
          </Button>

          <Button
            base="primary"
            color="white"
            transparent={true}
            to="/bestilling/ip-vpn/new"
          >
            {t.ordering.OrderIpVpn.OrderReceipt.newOrderButton}
          </Button>
        </div>
      </div>
    </div>
  );
}
