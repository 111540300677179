import * as React from 'react';
import { ListSelectCard } from 'src/lib/ui';
import './WizardTable.scss';
import cx from 'classnames';

import { t } from 'src/lib/i18n';

type TableContentType = 'SINGLE_CHOICE' | 'MULTI_CHOICE';

interface TableOption {
  value: string;
  label: string;
  disabled?: boolean;
  attributes?: Array<{ label?: string; value: string }>;
}

export interface WizardTableProps {
  id?: string; // formik related
  value: string[];
  options: TableOption[];
  header: string;
  type: TableContentType;
  required?: boolean;
  className?: string;
  onChange?: (active: string[]) => void;
}

export class WizardTable extends React.Component<WizardTableProps, {}> {
  onChangeRadio = (value: string) => {
    if (this.props.onChange) {
      return this.props.onChange(value !== '' ? [value] : []);
    }
  };

  onChangeCheckbox = (checkboxValue: string, checked: boolean) => {
    if (!this.props.onChange) {
      return;
    }

    const { value } = this.props;
    if (checked) {
      return this.props.onChange(value.concat([checkboxValue]));
    }

    return this.props.onChange(value.filter(v => checkboxValue !== v));
  };

  onChangeHandler = (value: string, checked: boolean) => {
    if (this.props.type === 'MULTI_CHOICE') {
      this.onChangeCheckbox(value, checked);
    } else {
      this.onChangeRadio(value);
    }
  };

  render() {
    const selectType =
      this.props.type === 'SINGLE_CHOICE' ? 'radio' : 'checkbox';

    let listCardElements = this.props.options.map((option, idx) => (
      <ListSelectCard
        key={idx}
        selectType={selectType}
        name={option.value}
        value={option.value}
        cardLabel={option.label}
        checked={this.props.value.includes(option.value)}
        onClick={this.onChangeHandler}
        attributes={option.attributes}
        disabled={option.disabled}
      />
    ));

    if (selectType === 'radio' && !this.props.required) {
      const emptyElement = (
        <ListSelectCard
          key="-1"
          selectType="radio"
          name="empty"
          value=""
          cardLabel={t.ui.WizardTable.noSelection}
          checked={this.props.value.length === 0}
          onClick={this.onChangeHandler}
        />
      );
      listCardElements = [emptyElement].concat(listCardElements);
    }

    return (
      <ul className={cx('WizardTable', this.props.className)}>
        {listCardElements}
      </ul>
    );
  }
}
