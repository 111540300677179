import React from 'react';
import { Pagination, urlFor } from 'src/lib/ui';
import { useSearch } from '../search';
import { PageHeader, Tab } from '../common/PageHeader/PageHeader';
import FilterSection from '../common/FilterSection';

import './ListOrders.scss';
import { t } from 'src/lib/i18n';
import { FilterTogglesValue } from 'src/lib/ui/FilterToggles/FilterToggles';
import { useListQueryParams } from 'src/lib/utils/useListQueryParams';
import { PortalOrderStatus } from 'src/__types/graphql-global-types';

import OrdersTable from './components/OrdersTable';
import { OrdersList } from './components/OrdersList';
import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import { useRouter } from 'src/lib/utils/useRouter';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import { useQueryParamPagination } from 'src/lib/ui/Pagination/useQueryParamPaginations';
import { getFilterCountFromSearchFilter } from 'src/lib/utils';
import { useDomainColumns } from 'src/lib/table';
import { SearchDomain } from 'src/__types/graphql-global-types';
const OrdersContainer = () => {
  const i18n = t.orders;
  const queryParams = useListQueryParams({
    defaultSortKey: 'createdAt',
    defaultSortOrder: 'desc',
    preferenceNamespace: 'orders',
  });
  const query = useQueryParameters();
  const breakpoint = useBreakpoint('md');
  const router = useRouter();
  const isHistoryPage = router.match.path.includes('/historikk');
  const noResultsMessage = i18n.ListOrders.noResult;

  const paginationProps = useQueryParamPagination({
    defaultPageSize: queryParams.pageSize,
    onTogglePageSize: queryParams.setPageSize,
  });

  // Works for now
  const orderStatusArray = isHistoryPage
    ? [PortalOrderStatus.cancelled, PortalOrderStatus.completed]
    : [PortalOrderStatus.newOrder, PortalOrderStatus.inProgress];

  const { columns, availableColumns } = useDomainColumns(
    SearchDomain.order,
    queryParams.columns
  );
  // orderType
  // 31.01.2019: No filtering on type for now beacuse only Bergen has mobile. This might not be needed.
  // const orderTypeFilters: FilterTogglesValue[] = [
  //   {
  //     value: 'mobile',
  //     label: i18n.orderType.mobile,
  //   },
  //   {
  //     filterValue: 'service',
  //     label: i18n.orderType.service,
  //   },
  //   {
  //     filterValue: 'mobileUnit',
  //     label: i18n.orderType.mobileUnit,
  //   },
  // ];

  let orderStatusFilterOptions: FilterTogglesValue<PortalOrderStatus>[] = [];
  if (isHistoryPage) {
    orderStatusFilterOptions = [
      {
        value: PortalOrderStatus.completed,
        label: i18n.orderStatus.completed,
      },
      {
        value: PortalOrderStatus.cancelled,
        label: i18n.orderStatus.cancelled,
      },
    ];
  } else {
    orderStatusFilterOptions = [
      {
        value: PortalOrderStatus.newOrder,
        label: i18n.orderStatus.newOrder,
      },
      {
        value: PortalOrderStatus.inProgress,
        label: i18n.orderStatus.inProgress,
      },
    ];
  }

  const tabs: Tab[] = [
    {
      label: i18n.navTabs.active,
      url: urlFor({ area: 'orders' }),
      exact: true,
    },
    {
      label: i18n.navTabs.history,
      url: urlFor({ area: 'orders', orderState: 'historikk' }),
    },
  ];

  const orderStatusFilter = query.getArray('orderStatus');

  const filterOptions = [
    {
      id: 'status',
      label: i18n.status,
      allowMultiple: false,
      options: orderStatusFilterOptions,
      selectedOptions: orderStatusFilter,
      handleSave: f => query.set('orderStatus', f),
    },
  ];

  const search = useSearch({
    query: queryParams.searchQuery,
    size: queryParams.pageSize,
    page: queryParams.page,
    types: [SearchDomain.order],
    filter: [
      {
        filter: 'orderStatus',
        value: orderStatusFilter.length ? orderStatusFilter : orderStatusArray,
      },
    ],
    sortBy: queryParams.sortBy,
    sortOrder: queryParams.sortOrder,
  });

  const filterCount = search.filter
    ? getFilterCountFromSearchFilter(search.filter)
    : undefined;
  return (
    <>
      <PageHeader title={i18n.PageHeader.title} navTabs={tabs} />
      <FilterSection
        searchValue={queryParams.searchQuery}
        onSearch={val => queryParams.setSearchQuery(val)}
        onResetSearch={() => queryParams.setSearchQuery('')}
        currentView={queryParams.view}
        setCurrentView={queryParams.setView}
        totalSearchResults={search.totalResults}
        filterOptions={filterOptions}
        totalFiltersActive={filterCount}
      />
      <div className="container">
        {queryParams.view === 'list' || !breakpoint ? (
          <OrdersList
            loading={search.loading}
            results={search.results}
            noResultsMessage={noResultsMessage}
          />
        ) : (
          <OrdersTable
            queryParams={queryParams}
            loading={search.loading}
            results={search.results}
            columns={columns}
            noResultsMessage={noResultsMessage}
            availableColumns={availableColumns}
            setColumns={queryParams.setColumns}
          />
        )}
      </div>

      <div
        className="d-flex container"
        style={{
          paddingTop: '1rem',
          paddingBottom: '60px',
        }}
      >
        <div className="ml-auto">
          <Pagination
            totalResults={search.totalResults || 0}
            {...paginationProps}
          />
        </div>
      </div>
    </>
  );
};

export default OrdersContainer;
