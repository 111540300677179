import { getCategory } from './utils';
import { push, Category } from './Analytics';
import { firstCharToUpper } from 'src/lib/utils/strings';

export type ServiceActions =
  | 'update_metadata'
  | 'report_incident'
  | 'terminate'
  | 'download_report'
  | 'batch_edit_tags';
export type DataActions = 'order_change';
export type MobileActions = 'terminate';
export type TagsActions = 'create' | 'update' | 'delete';

export function trackUpdateServiceMetadata(
  category: string,
  label: string,
  value?: string
) {
  push({
    category: firstCharToUpper(category) as Category,
    action: 'update_metadata',
    label: label,
    value: value,
  });
}

export function trackReportIncident(category: string) {
  push({
    category: firstCharToUpper(category) as Category,
    action: 'report_incident',
  });
}

export function trackOrderChange(label: string) {
  push({
    category: 'Data',
    action: 'order_change',
    label: label,
  });
}

export function trackTerminateSubscription(category: string) {
  push({
    category: firstCharToUpper(category) as Category,
    action: 'terminate',
  });
}

export function trackTagsUpdate(action: TagsActions, value?: string) {
  push({
    category: 'Tags',
    action: action,
    label: 'tag',
    value: value,
  });
}

export function trackServicesDownload(url: string) {
  let subscriptionGroup = 'All services';
  if (url.includes('group')) {
    subscriptionGroup = url.split('group:')[1];
  }

  let label = 'All';
  if (url.includes('subscription:')) {
    label = 'Selected';
    subscriptionGroup = subscriptionGroup.split('&')[0];
  }
  push({
    category: firstCharToUpper(subscriptionGroup) as Category,
    action: 'download_report',
    label: label,
  });
}

export function trackBatchEditTags(serviceGroup: string) {
  push({
    category: getCategory(serviceGroup),
    action: 'batch_edit_tags',
  });
}
