import * as React from 'react';
import { memoize, reduce, map, sumBy } from 'lodash';

import cs from 'classnames';

import './CircleChart.scss';
import { formatNordic } from 'src/lib/utils';

import { Motion, spring } from 'react-motion';

const radius = 45;
const circleLength = 2 * radius * Math.PI;
const segmentMargin = 3;
const strokeWidth = 5;

interface Props {
  segments: Segment[];
  label: string;
  backPanel?: boolean;
  activeIndex?: number;
  onClickSegment?: (idx: number) => void;
}

interface Segment {
  color: string;
  label: string;
  value: number;
}

interface SegmentMeta {
  // Original segment stuff
  color: string;
  label: string;
  value: number;

  // Additional props
  fraction: number;
  length: number;
  offset: number;
}

class CircleChart extends React.Component<Props> {
  loadSegmentMeta = memoize((segments: Segment[]) => {
    const filteredSegments = segments.filter(s => s.value > 0);
    const clwsm =
      circleLength -
      segmentMargin *
        (filteredSegments.length !== 1 ? filteredSegments.length : 0);
    const total = sumBy(filteredSegments, s => s.value);
    const meta = reduce(
      filteredSegments,
      (prev, curr) => {
        prev.segments.push({
          ...curr,
          fraction: curr.value / total,
          length: clwsm * (curr.value / total),
          offset:
            clwsm * (prev.cumulativeTotal / total) + prev.index * segmentMargin,
        });
        prev.cumulativeTotal += curr.value;
        prev.index += 1;
        return prev;
      },
      {
        cumulativeTotal: 0,
        index: 0,
        segments: [] as SegmentMeta[],
      }
    );

    // 0, // No fill before offset
    // (s.offset / meta.total) *
    //   meta.circleLengthWithoutSegmentMargins +
    //   s.index * segmentMargin, // Offset before it starts
    // s.length, // Length of segment
    // circleLength, // More gap!

    return {
      total,
      segments: meta.segments,
      circleLengthWithoutSegmentMargins: clwsm,
    };
  });

  onClickSegment = memoize((idx: number) => () => {
    if (this.props.onClickSegment) {
      this.props.onClickSegment(idx);
    }
  });

  render() {
    const meta = this.loadSegmentMeta(this.props.segments);
    const active =
      typeof this.props.activeIndex === 'number' &&
      this.props.activeIndex >= 0 &&
      this.props.activeIndex < meta.segments.length
        ? meta.segments[this.props.activeIndex]
        : undefined;
    return (
      <div className="CircleChart">
        <svg viewBox="0 0 100 100" xmlns="http://www.w3.org/2000/svg">
          <Motion
            style={{ x: spring(1, { stiffness: 300, damping: 40 }) }}
            defaultStyle={{ x: 0 }}
          >
            {motion => (
              <g
                id="circles"
                strokeWidth={strokeWidth}
                fill="none"
                fillRule="evenodd"
                transform="rotate(-90 50 50)"
              >
                {map(meta.segments, (s, x) => (
                  <circle
                    className={cs({
                      'CircleChart-segment': true,
                      'CircleChart-segment--active':
                        this.props.activeIndex === x,
                      'CircleChart-segment--inactive':
                        this.props.activeIndex !== undefined &&
                        this.props.activeIndex !== x,
                    })}
                    key={x}
                    stroke={s.color}
                    cx="50"
                    cy="50"
                    r={radius}
                    strokeDasharray={[
                      0, // No fill before offset,
                      s.offset, // Offset before we start drawing this segment
                      s.length * motion.x, // Length of this segment
                      circleLength, // Make sure we don't draw any more
                    ].join(' ')}
                    onClick={this.onClickSegment(x)}
                  />
                ))}
              </g>
            )}
          </Motion>

          {this.props.backPanel ? (
            <circle
              stroke="none"
              fill="#e9e9e9"
              cx="50"
              cy="50"
              r={radius - strokeWidth}
            />
          ) : null}
        </svg>

        <Motion
          style={{
            val: spring(active ? active.value : meta.total, {
              stiffness: 1200,
              damping: 60,
            }),
          }}
          defaultStyle={{
            val: 0,
          }}
        >
          {motion => (
            <div className="CircleChart-mid">
              <div
                className={cs({
                  'CircleChart-val': true,
                  'CircleChart-val--big': motion.val >= 10000,
                })}
                style={{ color: active ? active.color : undefined }}
              >
                {formatNordic(motion.val, 2)}
              </div>
              <div
                className="CircleChart-legend"
                style={{ color: active ? active.color : undefined }}
              >
                {active ? active.label : this.props.label}
                <br />
                {active ? (
                  <span>
                    ({formatNordic(active ? active.fraction * 100 : 100)}
                    %)
                  </span>
                ) : (
                  <span>&nbsp;</span>
                )}
              </div>
            </div>
          )}
        </Motion>
      </div>
    );
  }
}

export default CircleChart;
