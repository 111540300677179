import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useUpdateContactsOnIncident';
import { ExecutionResult } from 'graphql';
import { INCIDENT_QUERY } from 'src/areas/main/incidents/queries/useIncident';
import { ContactFragment } from 'src/lib/types/contact/ContactFragment';
import { trackMetadata } from 'src/lib/analytics';

const UPDATE_CONTACTS_ON_INCIDENT = gql`
  mutation useUpdateContactsOnIncident(
    $input: UpdateContactsOnNotificationsInput
    $customerId: ID!
  ) {
    updateContactsOnNotifications(input: $input) {
      incident {
        id
        notificationContacts {
          id
          name
          email
          phoneNumber
          contact {
            ...ContactSearchResult
          }
        }
      }
    }
  }
  ${ContactFragment}
`;

export interface UpdateContactsOnIncidentResult {
  updateContactsOnIncident: (
    incidentId: string,
    contactIdsToAdd?: string[],
    notificationIdsToDelete?: string[]
  ) => Promise<ExecutionResult<t.useUpdateContactsOnIncident>>;
  loading: boolean;
  error: boolean;
  data?: t.useUpdateContactsOnIncident_updateContactsOnNotifications_incident;
}

export const useUpdateContactsOnIncident = (): UpdateContactsOnIncidentResult => {
  const customer = useCustomer();

  const [mutation, { data, loading, error }] = useMutation<
    t.useUpdateContactsOnIncident,
    t.useUpdateContactsOnIncidentVariables
  >(UPDATE_CONTACTS_ON_INCIDENT);

  const updateContactsOnIncident = (
    incidentId: string,
    contactIdsToAdd?: string[],
    notificationContactIdsToDelete?: string[]
  ) => {
    trackMetadata('kontaktpersoner');
    return mutation({
      variables: {
        input: {
          incidentId,
          contactIdsToAdd,
          notificationIdsToDelete: notificationContactIdsToDelete,
          customerId: customer.id,
        },
        customerId: customer.id,
      },
      refetchQueries: [
        {
          query: INCIDENT_QUERY,
          variables: { customerId: customer.id, incidentId },
        },
      ],
    });
  };

  return {
    updateContactsOnIncident,
    loading,
    error: !!error,
    data: data?.updateContactsOnNotifications?.incident ?? undefined,
  };
};
