import * as React from 'react';
import cs from 'classnames';
import { Button } from '../Button/Button';

import './FilterButton.scss';
import { IconDefinition } from '@telia/styleguide';

interface Props {
  icon?: IconDefinition;
  checked?: boolean;
  label: string;
  onClick: (e: React.SyntheticEvent<HTMLButtonElement>) => void;
}

export const FilterButton: React.FC<Props> = props => (
  <Button
    className={cs('FilterButton', props.checked ? 'active' : undefined)}
    color="dark"
    rounded={true}
    outline={true}
    size="small"
    onClick={props.onClick}
    icon={props.icon}
  >
    {props.label}
  </Button>
);
