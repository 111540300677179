import React from 'react';
import { Json } from '../shared/Json';
import { useAppContext } from 'src/lib/global';
import { Link } from 'react-router-dom';
import { MobileSubscriptions } from './MobileSubscriptions';
import { Customers } from './Customers';

function FlagTable(props: {
  flags: null | Array<{ flag: string; value: null | string }>;
}) {
  return (
    <table className="Dev-table" style={{ width: 'auto' }}>
      <thead>
        <tr>
          <th>Feature flag</th>
          <th>Value</th>
        </tr>
      </thead>
      {props.flags ? (
        <tbody>
          {props.flags.map(ff => (
            <tr key={ff.flag}>
              <td>
                <code>{ff.flag}</code>
              </td>
              <td>{ff.value}</td>
            </tr>
          ))}
        </tbody>
      ) : (
        <tbody>
          <td colSpan={2}>No flags registered.</td>
        </tbody>
      )}
    </table>
  );
}

export function AccountDetails() {
  const app = useAppContext();
  return (
    <>
      <h1>Account details</h1>

      <h2>Global feature flags</h2>

      <FlagTable flags={app.access.featureFlags} />

      <h2>Customer-specific feature flags</h2>

      <FlagTable flags={app.customerFeatureFlags} />

      <h2>Mobile subscriptions</h2>

      <MobileSubscriptions app={app} />

      <div>
        <h3>Kundesøk</h3>
        {app.access.allowCustomerSearch ? (
          <span>
            <Link to="/velg-kunde">Kundesøk er tillatt for denne brukeren</Link>
            .
          </span>
        ) : (
          <span>Kundesøk er ikke tillatt for denne brukeren.</span>
        )}
      </div>
      {app.access && app.access.customers && app.access.customers.length ? (
        <div>
          <h3>Kunder</h3>
          <Customers app={app} />
        </div>
      ) : null}

      <h2>Application context</h2>

      <Json data={app} />
    </>
  );
}
