import React from 'react';

import { AppContext } from 'src/lib/global';
import { get } from 'lodash';
import { Icon } from '@telia/styleguide';
import { colors } from 'src/lib/ui';

export function MobileSubscriptions(props: { app: AppContext }) {
  if (
    !props.app.access.mobileSubscriptions ||
    props.app.access.mobileSubscriptions.length < 1
  ) {
    return <div>Ingen mobilabonnement på denne brukeren.</div>;
  }
  return (
    <table className="Dev-table">
      <thead>
        <tr>
          <th style={{ width: '40px', textAlign: 'center', lineHeight: 0 }}>
            &nbsp;
          </th>
          <th>MSISDN</th>
          <th>Subscription</th>
          <th>Name</th>
          <th>Customer name</th>
          <th>Customer ID</th>
        </tr>
      </thead>
      <tbody>
        {props.app.access.mobileSubscriptions.map(s => (
          <tr
            key={s.id}
            role="button"
            style={{ cursor: 'pointer' }}
            onClick={() => props.app.setActiveSubscriptionId(s.id)}
          >
            <td style={{ width: '40px', textAlign: 'center', lineHeight: 0 }}>
              {s.id === props.app.activeSubscriptionId ? (
                <Icon
                  icon="check-mark"
                  style={{
                    fill: colors.success,
                    height: '0.5rem',
                    width: '0.5rem',
                  }}
                />
              ) : null}
            </td>
            <td>{s.id}</td>
            <td>{get(s, 'current.name')}</td>
            <td>
              {get(s, 'user.firstName')} {get(s, 'user.lastName')}
            </td>
            <td>{get(s, 'customer.name')}</td>
            <td>{get(s, 'customer.id')}</td>
          </tr>
        ))}
      </tbody>
    </table>
  );
}
