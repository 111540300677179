import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import {
  RoamingAlertMobileSettings,
  MobileSubscription,
  Error,
} from 'src/lib/types';
import { WITH_MOBILE_SETTINGS } from 'src/areas/mobile/settings/useMobileSettings';

export const UPDATE_ROAMING_ALERT_SETTINGS = gql`
  mutation useUpdateRoamingAlertSettings(
    $input: UpdateRoamingAlertMobileSettingsInput
  ) {
    updateRoamingAlertMobileSettings(input: $input) {
      msisdn
      roamingAlert {
        smsWhenRoaming
      }
      error {
        code
        message
      }
    }
  }
`;

interface Variables {
  input: {
    msisdn: string;
  } & RoamingAlertMobileSettings;
}

interface Data {
  updateRoamingAlertMobileSettings: {
    msisdn: string;
    roamingAlert?: RoamingAlertMobileSettings;
    error?: Error;
  };
}

export interface UpdateRoamingAlertSettingsResult {
  loading: boolean;
  error: boolean;
  data?: Data;
  updateSettings: (settings: RoamingAlertMobileSettings) => void;
}

export const useUpdateRoamingAlertSettings = (): UpdateRoamingAlertSettingsResult => {
  const subscription = useSubscriptionContext();

  const [mutation, result] = useMutation<Data, Variables>(
    UPDATE_ROAMING_ALERT_SETTINGS,
    {
      update: (cache, { data }) => {
        if (data && data.updateRoamingAlertMobileSettings) {
          const {
            updateRoamingAlertMobileSettings: { roamingAlert, error, msisdn },
          } = data;
          if (!error) {
            const cachedData: {
              subscription: MobileSubscription;
            } | null = cache.readQuery({
              query: WITH_MOBILE_SETTINGS,
              variables: {
                subscriptionId: msisdn,
              },
            });
            if (
              cachedData &&
              cachedData.subscription &&
              cachedData.subscription.settings
            ) {
              cache.writeQuery({
                query: WITH_MOBILE_SETTINGS,
                variables: {
                  subscriptionId: msisdn,
                },
                data: {
                  subscription: {
                    id: msisdn,
                    settings: {
                      ...cachedData.subscription.settings,
                      roamingAlert: roamingAlert,
                      __typename: 'MobileSettings',
                    },
                    __typename: 'MobileSubscription',
                  },
                },
              });
            }
          }
        }
      },
    }
  );

  const updateSettings = settings =>
    mutation({
      variables: {
        input: {
          msisdn: subscription.id,
          ...settings,
        },
      },
    });

  return {
    updateSettings,
    data: result.data,
    error:
      !!result.error ||
      !!(result.data && result.data.updateRoamingAlertMobileSettings.error),
    loading: result.loading,
  };
};
