import * as React from 'react';
import { ListResultCard, Badge } from 'src/lib/ui';
import {
  getSubscriptionDisplayTitles,
  getSubscriptionIcon,
} from 'src/lib/types';
import { getLabelFromCpeStatus } from 'src/areas/main/subscriptions/utils/subscriptionUtils';
import { t } from 'src/lib/i18n';
import { formatInstallationAddress } from 'src/lib/utils';
import { Subscription } from 'src/lib/types';
import { convertKilobitsToMegabits } from 'src/areas/main/statistics/utils/convertUnit';

interface Props {
  renderAs?: 'div' | 'li';
  entry: Subscription;
  showIconDescription?: boolean;
  attributes?: Array<{ label?: string; value: React.ReactNode }>;
  onSelect?: () => void;
  selected?: boolean;
}

export const SearchListSubscriptionResult: React.FC<Props> = props => {
  const { renderAs, showIconDescription, attributes, entry } = props;
  const { serviceBandwidth, deliveryStatus, incidents } = entry;
  const translate = t.search.SearchListSubscriptionResult;
  /* General attributes  */
  const { title, subtitle } = getSubscriptionDisplayTitles(entry);
  const { icon, color } = getSubscriptionIcon(entry);
  const iconDescription = showIconDescription
    ? getLabelFromCpeStatus(entry?.cpe?.status, entry?.isMonitoringPossible)
    : undefined;
  const address = formatInstallationAddress(
    entry.installationAddress,
    'fullAddress'
  );

  const displayDeliveryStatus =
    deliveryStatus === 'assigning'
      ? t.subscriptions.deliveryStatus.assigning
      : deliveryStatus === 'terminating'
      ? t.subscriptions.deliveryStatus.terminating
      : undefined;

  const Badges = () => {
    return (
      <React.Fragment>
        {displayDeliveryStatus ? <Badge>{displayDeliveryStatus}</Badge> : null}
        {activeIncident ? (
          <Badge icon="email" iconColor="danger">
            {`${translate.incident} ${activeIncident.id} ${
              activeIncident.shortDescription
                ? ' - ' + activeIncident.shortDescription
                : ''
            }`}
          </Badge>
        ) : null}
      </React.Fragment>
    );
  };

  /* Data attributes */
  const activeIncident = incidents
    ? incidents.filter(incident => incident.active)[0]
    : null;
  const cpeModel = entry.cpe ? entry.cpe.model : '';
  const serviceBandwidthUpstream =
    serviceBandwidth && serviceBandwidth.upstream
      ? convertKilobitsToMegabits(serviceBandwidth.upstream) + ' Mbit/s'
      : '';

  return (
    <ListResultCard
      renderAs={renderAs}
      className="ListResultCard--Subscription"
      icon={icon}
      iconColor={color}
      iconDescription={iconDescription}
      title={subtitle ? `${title} (${subtitle})` : title}
      badge={<Badges />}
      linkTo={entry}
      attributes={[
        address ? { value: address } : undefined,
        serviceBandwidthUpstream
          ? { value: serviceBandwidthUpstream }
          : undefined,
        cpeModel ? { value: cpeModel } : undefined,
        ...(attributes ? attributes : []),
      ]}
      subResults={
        entry.redundancies?.length
          ? entry.redundancies.map(r => {
              const { icon, color } = getSubscriptionIcon(r);
              const rt = getSubscriptionDisplayTitles(r);
              const title = rt.subtitle
                ? `${rt.title} (${rt.subtitle})`
                : rt.title;
              return {
                title,
                icon,
                iconColor: color,
              };
            })
          : undefined
      }
      selected={props.selected}
      onSelect={props.onSelect}
    />
  );
};
