import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';

export const useDeliveryStatusFilter = () => {
  const query = useQueryParameters();
  const selectedOptions = query.getArray('deliveryStatus');

  const i18n = t.subscriptions.filters.deliveryStatus;

  const options = [
    {
      value: 'assigning',
      label: i18n.underDelivery,
    },
    {
      value: 'terminating',
      label: i18n.underTermination,
    },
  ];
  return {
    id: 'deliveryStatus',
    label: i18n.label,
    options: options,
    allowMultiple: true,
    selectedOptions,
    handleSave: values => {
      setFilterAndResetPage(
        query,
        'deliveryStatus',
        values ? values : undefined
      );
    },
  };
};
