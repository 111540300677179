import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import ListNotifications from './ListNotifications';

const Subscriptions: React.SFC<RouteComponentProps<{}>> = ({ match }) => (
  <Switch>
    <Route path={match.path} component={ListNotifications} />
  </Switch>
);

export default Subscriptions;
