import * as React from 'react';
import { RouteComponentProps, Switch, Route } from 'react-router';
import { Feature } from 'src/lib/ui';

import ReportsContent from './ReportsContent';

const Reports: React.FunctionComponent<RouteComponentProps> = ({ match }) => (
  <Switch>
    <Feature feature="io-reports">
      <Route exact={true} path={match.url} component={ReportsContent} />
    </Feature>
  </Switch>
);

export default Reports;
