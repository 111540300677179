import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import {
  Subscription,
  getSubscriptionIcon,
  getSubscriptionDisplayTitles,
} from 'src/lib/types';
import { formatInstallationAddress } from 'src/lib/utils';

export const SearchMenuSubscriptionResult: React.FC<SearchMenuResultProps<
  Subscription
>> = props => {
  const { entry } = props;
  const { alias, name, tags } = entry;
  const { title, subtitle } = getSubscriptionDisplayTitles(entry);
  const address = formatInstallationAddress(
    entry.installationAddress,
    'fullAddress'
  );
  const { icon, color } = getSubscriptionIcon(entry);

  return (
    <SearchMenuResultItem
      {...props}
      title={title}
      subTitle={subtitle}
      icon={icon}
      iconColor={color}
      summary={`${address} ${alias ? name : ''}`}
    >
      {JSON.stringify(tags)}
    </SearchMenuResultItem>
  );
};
