import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { MobileOrderDetailsFragment } from '../lib/MobileOrderDetailsFragment';
import { MobileOrderDetailsResult } from '../lib/__types/MobileOrderDetailsResult';

import * as t from './__types/useExtraSimCardOrder';
import { ExtraSimCardOrderInput } from 'src/__types/graphql-global-types';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';

const MUTATION_EXTRA_SIM_CARD_ORDER = gql`
  mutation useExtraSimCardOrder($input: ExtraSimCardOrderInput) {
    extraSimCardOrder(input: $input) {
      orderDetails {
        ... on MobileOrderDetails {
          ...MobileOrderDetailsResult
        }
      }
    }
  }
  ${MobileOrderDetailsFragment}
`;

export type SimOptions = 'voice' | 'data';

export interface ExtraSimCardOrderResult {
  extraSimCardOrder: (
    input: Omit<ExtraSimCardOrderInput, 'customerId'>
  ) => Promise<void>;
  loading: boolean;
  error?: boolean;
  data?: MobileOrderDetailsResult;
}

export const useExtraSimCardOrder = (): ExtraSimCardOrderResult => {
  const { customerId } = useSubscriptionContext();

  const [mutation, { data, loading, error }] = useMutation<
    t.useExtraSimCardOrder,
    t.useExtraSimCardOrderVariables
  >(MUTATION_EXTRA_SIM_CARD_ORDER);

  const extraSimCardOrder = async (
    input: Omit<ExtraSimCardOrderInput, 'customerId'>
  ) => {
    await mutation({
      variables: {
        input: {
          customerId,
          msisdn: input.msisdn,
          extraSimType: input.extraSimType,
          simCardId: input.simCardId,
          newSimCardRecipient: input.newSimCardRecipient,
          phoneNumber: input.msisdn,
        },
      },
    });
  };

  return {
    extraSimCardOrder,
    data: data?.extraSimCardOrder?.orderDetails || undefined,
    error: !!error,
    loading,
  };
};
