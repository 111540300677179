import nb from '../../nb/Main/ordering.OrderIpVpn';

const orderIpVpn: typeof nb = {
  title: 'Order access point',

  OrderError: {
    heading: 'An error occurred',
  },

  IpVpnOrderCard: {
    heading: 'Order new IP VPN access point',
    description:
      'Telia IP VPN is a multi-service access point that collects all network needs in a single line.' +
      ' With Telia IP VPN your organisation will have a feature-complete network for transmitting' +
      ' all kinds of traffic, like ASP/Citrix, IP-telephony, video conferencing and alarms.',
    buttonLabel: 'Order access point',
  },

  OrderReceipt: {
    heading: 'The order is received',
    p1: 'We will shortly process your submitted details and create an order.',
    p2:
      'You will receive an order confirmation by email with information about' +
      ' what we can deliver to the requested location. Your order will also appear' +
      ' under "active orders" here in Min Portal.',

    p3:
      'In the confirmation you will receive details about the next steps in the process.',
    backButton: 'Back to the portal',
    newOrderButton: 'Order a new access point',
  },

  OrderIpVpnSummary: {
    title: 'Confirm and send',
    summary: 'Please confirm the provided details above and confirm the order.',
    whatsNext: "What's next?",
    whatsNextSummary:
      'When you confirm the order, we will evaluate how to provide the service to you.' +
      ' After the evaluation, an order will be created in Min Portal. You will also receive' +
      ' an order confirmation by email. The confirmation will contain your provided' +
      ' information and suggested delivery.',
  },

  Step1: {
    title: 'Location',
    summary:
      'Provide details about the location at which the access point will be set up.' +
      ' Its location will be used to evaluate how the access will be provided.',
    toggleAddress: 'Address',
    toggleGnrBnr: 'Gnr/Bnr (Gårds-/bruksnummer)',
    knrSummary: 'Municipality',
    gnrSummary: 'Gnr',
    bnrSummary: 'Bnr',
    addressSummary: 'Address',
    postCodeSummary: 'Postal code',
    countrySummary: 'Country',

    knrLabel: 'Municipality',
    gnrLabel: 'Gnr',
    bnrLabel: 'Bnr',
    addressLabel: 'Address',
    postCodeLabel: 'Postal code',
    countryLabel: 'Country',
  },

  Step2: {
    title: 'Information about the building',
    summary:
      'Provide details about where in the building the access point should be set up. This' +
      ' information will be given to the technicians setting up the access point.',
    floorSummary: 'Floor',
    floorLabel: 'Floor',
    needsWiring: {
      label: 'Does the location already have internal wiring?',
      summary: 'Internal wiring present',
      yes: 'No, we want Telia to provide internal wiring',
      no: 'Yes, ready for installation',
    },
  },

  Step3: {
    title: 'Bandwidth',
    summary:
      'How much bandwidth do you need? This will also affect the type of access point used.',
    accessBandwidthSummary: 'Bandwidth',
    accessBandwidthLabel: 'Bandwidth',
    accessBandwidthPlaceholder: 'Bandwidth (Mbit/s)',
  },

  Step4: {
    title: 'Contact person',
    summary:
      'Add a contact person to be contacted when the access is being delivered.',
    siteContactSummary: 'Contact person',
    siteContactLabel: 'Contact person',
    siteContactPlaceholder: 'Select contact person',
  },

  Step5: {
    title: 'Invoice center',
    formSummary: 'Select the invoice center to receive invoices by EHF.',
    organisationIdLabel: 'Select invoice center',
    organisationIdSummary: 'Invoice center',
  },

  Step6: {
    title: 'Delivery date',
    summary:
      'Here you can request a delivery date for the access line installation. You can also' +
      ' provide any additional information.',
    deliveryDateLabel: 'Requested delivery date',
    additionalInformationLabel: 'Enter any additional information (optional)',
    deliveryDateSummary: 'Requested delivery date',
    additionalInformationSummary: 'Additional information',
  },
};

export default orderIpVpn;
