import React from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { TextField, FormSubmitBar } from 'src/lib/ui';
import { map } from 'lodash';

import { withFormik, FormikProps } from 'formik';
import {
  useUpdateAttribute,
  UpdateSubscriptionResult,
} from 'src/areas/main/subscriptions/mutations/useUpdateAttribute';
import { useCustomer } from 'src/lib/global';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { t } from 'src/lib/i18n';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';
import { trackUpdateServiceMetadata } from 'src/lib/analytics';

interface Props {
  flex1?: string;
  flex2?: string;
  flex3?: string;
  flex4?: string;
}

interface ContextProps {
  context?: UpdateSubscriptionResult;
  subscription?: {
    id: string;
    group?: SubscriptionGroup | null;
    subscriptionId?: string | null;
    flex1?: string | null;
    flex2?: string | null;
    flex3?: string | null;
    flex4?: string | null;
  };
  isOpen?: boolean;
  toggleState?: () => void;
}

const EditAdditionalInformationForm: React.FC<ContextProps &
  FormikProps<Props>> = ({
  values,
  handleChange,
  handleSubmit,
  toggleState,
  context,
}) => {
  const customer = useCustomer();
  const translate = t.subscriptions.EditAdditionalInformationForm;

  return (
    <form>
      <ModalHeader>{translate.title}</ModalHeader>
      <ModalBody className="p-4">
        {map(customer.flexFields, ff => {
          const flexKey = `flex${ff.id}`;
          const flexVal = values[flexKey] || '';
          return (
            <div className="row py-2" key={ff.id}>
              <div className="col">
                {ff.options && ff.options.length > 0 ? (
                  <>
                    <div>{ff.label}</div>
                    <select
                      className="form-control"
                      id={flexKey}
                      onChange={handleChange}
                      value={flexVal}
                    >
                      <option value=""> {translate.noValue}</option>
                      <optgroup label={translate.alternatives}>
                        {ff.options.map((o, ix) => (
                          <option key={ix} value={o.option}>
                            {o.option}
                            {o.description ? ` (${o.description})` : null}
                          </option>
                        ))}
                      </optgroup>
                    </select>
                    <small className="form-text">&nbsp;</small>
                  </>
                ) : (
                  <TextField
                    label={ff.label || ''}
                    id={flexKey}
                    value={flexVal}
                    onChange={handleChange}
                  />
                )}
              </div>
            </div>
          );
        })}
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={() => handleSubmit()}
          cancel={toggleState}
          loading={context?.loading}
        />
      </ModalFooter>
    </form>
  );
};

const FormikForm = withFormik<Props & ContextProps, {}, {}>({
  mapPropsToValues({ flex1, flex2, flex3, flex4 }) {
    return {
      flex1: flex1 || '',
      flex2: flex2 || '',
      flex3: flex3 || '',
      flex4: flex4 || '',
    };
  },
  handleSubmit(values: Props, { props }) {
    const {
      flex1,
      flex2,
      flex3,
      flex4,
      context,
      subscription,
      toggleState,
    } = props;
    if (context && subscription) {
      if (flex1 !== values.flex1) {
        context.setAttributeForSubscription(
          subscription.subscriptionId ?? '',
          'flex1',
          values.flex1 ?? ''
        );
      }
      if (flex2 !== values.flex2) {
        context.setAttributeForSubscription(
          subscription.subscriptionId ?? '',
          'flex2',
          values.flex2 ?? ''
        );
      }
      if (flex3 !== values.flex3) {
        context.setAttributeForSubscription(
          subscription.subscriptionId ?? '',
          'flex3',
          values.flex3 ?? ''
        );
      }
      if (flex4 !== values.flex4) {
        context.setAttributeForSubscription(
          subscription.subscriptionId ?? '',
          'flex4',
          values.flex4 ?? ''
        );
      }
    }
    if (subscription && subscription.group)
      trackUpdateServiceMetadata(subscription.group, 'additional info');

    if (toggleState) {
      toggleState();
    }
  },
})(EditAdditionalInformationForm);

export const EditAdditionalInformation: React.FunctionComponent<Props &
  ContextProps> = props => {
  const updateAttribute = useUpdateAttribute(
    t.subscriptions.EditAdditionalInformationForm.errorMsg
  );

  return (
    <Modal isOpen={props.isOpen} toggle={props.toggleState}>
      <FormikForm
        flex1={props.flex1}
        flex2={props.flex2}
        flex3={props.flex3}
        flex4={props.flex4}
        toggleState={props.toggleState}
        context={updateAttribute}
        subscription={props.subscription}
      />
    </Modal>
  );
};
