import * as React from 'react';

import { Step, StepShape, FormikWizardTable } from 'src/lib/flow';
import { Loading, Attribute } from 'src/lib/ui';
import WithMobileProducts from '../queries/WithMobileProducts';
import { MobileOrderConsumer } from './OrderMobileSubscription';
import { formatNordic } from 'src/lib/utils';

import OrderingSection from '../layout/OrderingSection';
import { Product, ProductPackage, MobileType, ProductType } from '../types';
import _groupBy from 'lodash/groupBy';
import _sortBy from 'lodash/sortBy';
import * as yup from 'yup';
import { t } from 'src/lib/i18n';
import { Step3Values } from './Step3';
import { Step1Values } from './Step1';

export interface Step4Values {
  selectedPackage?: string[]; // array because its a WizardTable
  additionalProducts?: {
    [key: string]: any;
  };
}

const getLabelsForAdditionalProducts = (
  additionalProducts: {
    [key: string]: any;
  },
  productPackage: ProductPackage
): string[] => {
  const products = productPackage.additionalProducts;
  const keys = Object.keys(additionalProducts);
  if (!products || products.length === 0 || keys.length === 0) {
    return [];
  }
  const labels: string[] = [];

  keys.forEach(key => {
    const group = additionalProducts[key] as string[];

    group.forEach(additionalProductId => {
      const productObject = products.find(p => p.id === additionalProductId);
      if (productObject) {
        labels.push(productObject.name);
      }
    });
  });
  return labels;
};

const Step4: Step<Step4Values & Step3Values & Step1Values> = (opts?: {
  orderingType?: MobileType;
}): StepShape<Step4Values & Step3Values & Step1Values> => {
  const i18n = t.ordering.OrderMobileSubscription.Step4;
  const orderingType =
    opts && 'orderingType' in opts ? opts.orderingType : 'mobile';
  const mapProducts = (packageList: Product[]) =>
    _sortBy(packageList, ['name']).map(pack => ({
      value: pack.id,
      label: pack.name,
      disabled: pack.mandatoryForPackage,
      attributes: [
        ...(pack.installationCost !== 0
          ? [
              {
                label: i18n.products.establishmentFee,
                value: `Kr ${formatNordic(pack.installationCost, 2)}`,
              },
            ]
          : []),
        {
          label: i18n.products.monthlyFee,
          value: `Kr ${formatNordic(pack.monthlyCost, 2)}`,
        },
      ],
    }));

  const Form = (props: { flow: any; mobileProducts: any }) => {
    const { flow, mobileProducts } = props;

    const packages =
      mobileProducts.availableProductPackages &&
      _sortBy(
        mobileProducts.availableProductPackages,
        prod => prod.mainProduct.name
      ).map(pack => ({
        value: pack.mainProduct.id,
        label: pack.mainProduct.name,
        attributes: [
          ...(pack.mainProduct.installationCost
            ? [
                {
                  label: i18n.products.establishmentFee,
                  value: `Kr ${formatNordic(
                    pack.mainProduct.installationCost
                  )}`,
                },
              ]
            : []),
          {
            label: i18n.products.monthlyFee,
            value: `Kr ${formatNordic(pack.mainProduct.monthlyCost, 2)}`,
          },
        ],
      }));

    const selectedPackage =
      mobileProducts.availableProductPackages &&
      mobileProducts.availableProductPackages.find(pack =>
        Boolean(
          flow.values.selectedPackage &&
            flow.values.selectedPackage.includes(pack.mainProduct.id)
        )
      );

    const productGroups = selectedPackage
      ? _groupBy(selectedPackage.additionalProducts, 'uniqueGroup')
      : [];

    React.useEffect(() => {
      if (selectedPackage) {
        const selectedAdditionalProducts: string[] =
          selectedPackage.additionalProducts
            ?.filter(p => p.mandatoryForPackage === true)
            .map(p => p.id) ?? [];

        flow.setFieldValue(
          `additionalProducts.${null}`,
          selectedAdditionalProducts ? selectedAdditionalProducts : []
        );
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [flow.values.selectedPackage]);

    return (
      <>
        <OrderingSection header={i18n.header}>
          {mobileProducts.loading ? (
            <Loading />
          ) : (
            <FormikWizardTable
              required={true}
              id="selectedPackage"
              type="SINGLE_CHOICE"
              header={i18n.chooseSub}
              options={packages || []}
              onChange={() => flow.setFieldValue('additionalProducts', {})}
            />
          )}
        </OrderingSection>

        {selectedPackage && (
          <OrderingSection header={i18n.extraHeader}>
            {Object.keys(productGroups).map(key => {
              const productGroup: Product[] = productGroups[key];
              const header =
                key !== 'null'
                  ? productGroup[0].name.split(' ')[0]
                  : i18n.extra;

              return (
                <div key={key} className="mb-4">
                  <small>{header}</small>
                  <FormikWizardTable
                    required={false}
                    id={`additionalProducts.${key}`}
                    header=""
                    type={key === 'null' ? 'MULTI_CHOICE' : 'SINGLE_CHOICE'}
                    options={mapProducts(productGroup)}
                  />
                </div>
              );
            })}
          </OrderingSection>
        )}
      </>
    );
  };

  const productType = (
    orderingType?: string,
    phoneNumberSource?: string,
    phoneNumber?: string
  ): ProductType[] => {
    if (orderingType === 'm2m') return ['m2m'];
    if (phoneNumberSource === 'existingNumber') return ['voice'];
    if (phoneNumber && phoneNumber.length === 12) return ['data'];
    else return ['voice'];
  };

  return {
    title: i18n.title,
    validationSchema: yup.object().shape({
      selectedPackage: yup.array().required(),
      additionalProducts: yup.object(),
    }),
    renderSummary: ({ values }) => (
      <MobileOrderConsumer>
        {mobileContext => (
          <WithMobileProducts
            organisationId={values.organisation || ''}
            productType={productType(
              orderingType,
              values.phoneNumberSource,
              values.phoneNumber
            )}
          >
            {mobileProducts => {
              const mobileSubscriptionObject =
                mobileProducts.availableProductPackages &&
                mobileProducts.availableProductPackages.find(
                  mobSub =>
                    mobSub.mainProduct.id ===
                    (values.selectedPackage &&
                      values.selectedPackage.length > 0 &&
                      values.selectedPackage[0])
                );

              return (
                <>
                  <Attribute
                    label={i18n.subscription}
                    value={
                      mobileSubscriptionObject
                        ? mobileSubscriptionObject.mainProduct.name
                        : ''
                    }
                  />
                  {values.additionalProducts &&
                    Object.keys(values.additionalProducts).length > 0 &&
                    mobileSubscriptionObject && (
                      <>
                        <div className="pt-2">
                          <Attribute label={i18n.extra} value="" />
                        </div>
                        {getLabelsForAdditionalProducts(
                          values.additionalProducts,
                          mobileSubscriptionObject
                        ).map((product, idx) => (
                          <Attribute key={idx} value={product} />
                        ))}
                      </>
                    )}
                </>
              );
            }}
          </WithMobileProducts>
        )}
      </MobileOrderConsumer>
    ),

    // TODO: Create a React-class for render-comp
    renderForm: ({ flow }) => (
      <WithMobileProducts
        organisationId={flow.values.organisation || ''}
        productType={productType(
          orderingType,
          flow.values.phoneNumberSource,
          flow.values.phoneNumber
        )}
      >
        {mobileProducts => <Form flow={flow} mobileProducts={mobileProducts} />}
      </WithMobileProducts>
    ),
  };
};

export default Step4;
