import React, { useEffect, useState } from 'react';
import { getDefaultPeriod, getPastMonthPeriod } from 'src/lib/utils';
import { useSubscriptionHistoric } from 'src/areas/mobile/lib/useSubscriptionHistoric';
import { MobilePeriodDetails } from 'src/areas/mobile/types';
import { Loading, Button } from 'src/lib/ui';
import { Period } from 'src/areas/mobile/history/Period';
import { t } from 'src/lib/i18n';

export const History = () => {
  const { periodDetails, loading } = useSubscriptionHistoric(
    getPastMonthPeriod(2),
    getDefaultPeriod()
  );

  const [showing, setShowing] = useState<number>(6);
  const [periodsShowing, setPeriodsShowing] = useState<MobilePeriodDetails[]>(
    []
  );
  const [periods, setPeriods] = useState<MobilePeriodDetails[]>([]);

  useEffect(() => {
    if (periodDetails?.length) {
      const new_arr = periodDetails
        .filter(x => x) // To remove any null elements from the GraphQL response
        .slice()
        .reverse();
      setPeriods(new_arr as MobilePeriodDetails[]);
    }
  }, [periodDetails]);

  useEffect(() => {
    periods.length > showing
      ? setPeriodsShowing(periods.slice(0, showing))
      : setPeriodsShowing(periods);
  }, [showing, periods]);

  if (loading) {
    return <Loading />;
  }
  if (!periodDetails?.length) {
    return null;
  }
  return (
    <>
      <ul className="p-0">
        {periodsShowing.map((period, i) => (
          <Period key={i} period={period} />
        ))}
      </ul>
      {periods.length > 6 ? (
        <div className="text-center">
          <Button
            color="white"
            transparent={true}
            onClick={() =>
              setShowing(showing <= periods.length ? showing + 6 : 6)
            }
          >
            {showing <= periods.length
              ? t.mobile.history.showMore
              : t.mobile.history.showLess}
          </Button>
        </div>
      ) : null}
    </>
  );
};
