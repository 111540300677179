import { CpeStatus } from './../../../__types/graphql-global-types';
import { buildUrl } from './buildUrl';
import { SearchResult } from 'src/areas/main/search/types';
import { ResourceType } from 'src/lib/types';
import { getUrlForNotification } from 'src/areas/dashboard/Notification/utils';
import {
  PortalOrderStatus,
  IncidentStatus,
  DeliveryStatus,
} from 'src/__types/graphql-global-types';
import { firstCharToLower } from 'src/lib/utils/strings';

export type UrlForObject =
  | { invoice: string }
  | { contact: string }
  | { order: string }
  | { notification: string }
  | { subscriptionFolder: string; subscription: string }
  | { subscriptionFile: string; subscription: string }
  | { area: 'servicePlus' }
  | { servicePlusAddress: string }
  | {
      subscription: string;
      subPage?:
        | 'child-subscriptions'
        | 'incidents'
        | 'invoices'
        | 'report-incident'
        | 'statistics';
    }
  | { organisation: string }

  // | { location: string }
  | { costForOrganisation: string }
  | { invoicesForOrganisation: string }
  | { incident: string }
  | { report: string; period: string }
  | {
      area: 'invoices';
      invoiceStatus?: InvoiceStatusFilter;
      organisation?: string | null;
    }
  | {
      area: 'orders';
      orderStatus?: PortalOrderStatus;
      orderState?: 'historikk';
    }
  | {
      area: 'incidents';
      group?: IncidentGroupInUrl;
      incidentStatus?: IncidentStatus | IncidentStatus[];
      filterByTag?: string | string[];
    }
  | {
      area: 'subscriptions';
      group?: SubscriptionGroupContainer;
      filterByTag?: string | string[];
      cases?: CasesFilter;
      status?: CpeStatus;
      deliveryStatus?: DeliveryStatus;
    }
  | { area: 'map' }
  | { area: 'map'; subscription: string }
  | { area: 'cost' }
  | { area: 'cost'; costCenter: string }
  | { area: 'changelog'; publishedDate?: string }
  | { area: 'cost'; organisation: string }
  | { area: 'search'; query?: string; resourceType?: ResourceType }
  | { worklogOrder: string; caseId?: string; worklogArea?: string }
  | { worklogSubscription: string }
  | { path?: string; folder: string }
  | { path?: string; file: string }

  // Handle SearchResults
  | SearchResult;

type InvoiceStatusFilter = 'unpaid' | 'overdue' | 'paid';

// SubscriptionGroup types that are used in URL because we want URLs to be in Norwegian, while the app is in English
export type SubscriptionGroupInUrl =
  | 'nettverk'
  | 'mobil'
  | 'telefoni'
  | 'tv'
  | 'alle';
export type SubscriptionGroupContainer = SubscriptionGroupInUrl | 'tags';
export type IncidentGroupInUrl = 'aktive' | 'historikk';

type CasesFilter =
  | 'withPlannedWork'
  | 'withoutPlannedWork'
  | 'withIncidents'
  | 'withoutIncidents';

export const urlFor: (link: UrlForObject) => string = link => {
  /**
   * If, for some reason, a non-object is passed to urlFor that is
   * not catched by TypeScript, we have an escape hatch here:
   */
  if (!link) {
    return '';
  }

  /**
   * Search results! This should be first, since the search results themselves
   * can also contain other field names that overlap with the choices below.
   */
  if ('__typename' in link) {
    switch (link.__typename) {
      case 'CostCenter':
        return urlFor({ area: 'cost', costCenter: link.id });
      case 'Contact':
        return urlFor({ contact: link.id });
      case 'File':
        return urlFor({ file: link.id });
      case 'Invoice':
        return urlFor({ invoice: link.id });
      case 'Incident':
        return urlFor({ incident: link.id });
      case 'Order':
        return urlFor({ order: link.id });
      case 'Organisation':
        return urlFor({ area: 'invoices', organisation: link.id });
      case 'Subscription':
        return urlFor({ subscription: link.id });
      case 'Tag':
        return urlFor({ area: 'subscriptions', filterByTag: link.tag });
      case 'Notification':
        return urlFor({ notification: getUrlForNotification(link) });
    }
  }
  if ('worklogOrder' in link) {
    if (link.caseId) {
      if (link.worklogArea) {
        return `/worklog/order/${link.worklogOrder}/case/${link.caseId}/${link.worklogArea}`;
      }
      return `/worklog/order/${link.worklogOrder}/case/${link.caseId}/log`;
    }
    if (link.worklogArea) {
      return `/worklog/order/${link.worklogOrder}/${link.worklogArea}`;
    }
    return `/worklog/order/${link.worklogOrder}/log`;
  }

  if ('worklogSubscription' in link) {
    return `/worklog/subscription/${link.worklogSubscription}`;
  }

  if ('invoice' in link) {
    return `/kostnader/fakturaer/${encodeURIComponent(link.invoice)}`;
  } else if ('costForOrganisation' in link) {
    return `/kostnader/org/${encodeURIComponent(link.costForOrganisation)}`;
  } else if ('invoicesForOrganisation' in link) {
    return `/kostnader/org/${encodeURIComponent(
      link.invoicesForOrganisation
    )}/fakturaer`;
  } else if ('incident' in link) {
    return `/meldinger/${encodeURIComponent(link.incident)}`;
    // } else if ('location' in link) {
    //   return `/lokasjon/${encodeURIComponent(link.location)}`;
  } else if ('contact' in link) {
    return `/kontakter/${encodeURIComponent(link.contact)}`;
  } else if ('order' in link) {
    return `/ordre/${encodeURIComponent(link.order)}`;
  }
  /**
   * Areas
   */
  if ('area' in link) {
    /**
     * Area: subscriptions
     */
    if (link.area === 'subscriptions') {
      return buildUrl(
        // we link to "alle"-tab if no group is explicitly set
        `/tjenester/${encodeURIComponent(link.group ?? 'alle')}`,
        {
          tag: link.filterByTag,
          cases: link.cases,
          status: link.status ? firstCharToLower(link.status) : undefined,
          deliveryStatus: link.deliveryStatus,
        }
      );
    }

    /**
     * Area: Map
     */
    if (link.area === 'map') {
      if ('subscription' in link) {
        return (
          '/kart' +
          (link.subscription ? `/${encodeURIComponent(link.subscription)}` : '')
        );
      }
      return '/kart';
    }

    /**
     * Area: Cost
     */
    if (link.area === 'cost') {
      if ('organisation' in link) {
        return `/kostnader/org/${encodeURIComponent(link.organisation)}`;
      }
      if ('costCenter' in link) {
        // IDs can contain non-alphanumeric characters; encoding them here causes
        // CostCenterPage > PageHeader > NavTabs to not recognize the currently selected tab.
        return `/kostnader/ks/${link.costCenter}`;
      }
      return `/kostnader`;
    }

    /**
     * Area: Invoices
     */
    if (link.area === 'invoices') {
      return buildUrl('/fakturaer', {
        invoiceStatus: link.invoiceStatus,
        organisation: link.organisation,
      });
    }

    /**
     * Area: Changelog
     */
    if (link.area === 'changelog') {
      if ('publishedDate' in link) {
        return buildUrl(`/changelog?publishedDate=${link.publishedDate}`);
      }
      return buildUrl('/changelog');
    }

    /**
     * Area: Orders
     */
    if (link.area === 'orders') {
      return buildUrl(`/ordre${link.orderState ? `/${link.orderState}` : ''}`, {
        orderStatus: link.orderStatus,
      });
    }

    /**
     * Area: incidents
     */
    if (link.area === 'incidents') {
      return buildUrl(
        // we link to "aktive"-tab if no group is explicitly set
        `/meldinger/${encodeURIComponent(link.group ?? 'aktive')}`,
        {
          incidentStatus: link.incidentStatus,
          tag: link.filterByTag,
        }
      );
    }

    /**
     * Area: Search
     */
    if (link.area === 'search') {
      return buildUrl('/sok', {
        query: link.query,
        resourceType: link.resourceType,
      });
    }

    /**
     * Area: Service Plus
     */
    if (link.area === 'servicePlus') {
      return buildUrl('/service-plus');
    }
  }

  /**
   * Subscriptions
   */
  if ('subscriptionFolder' in link) {
    return `/tjenester/${encodeURIComponent(
      link.subscription
    )}/dokumenter/m/${encodeURIComponent(link.subscriptionFolder)}`;
  }
  if ('subscriptionFile' in link) {
    return `/tjenester/${encodeURIComponent(
      link.subscription
    )}/dokumenter/m/${encodeURIComponent(link.subscriptionFile)}`;
  }
  if ('subscription' in link) {
    if (!link.subPage) {
      return `/tjenester/${encodeURIComponent(link.subscription)}`;
    }
    if (link.subPage === 'child-subscriptions') {
      return `/tjenester/${encodeURIComponent(
        link.subscription
      )}/tilhorende-tjenester`;
    }
    if (link.subPage === 'incidents') {
      return `/tjenester/${encodeURIComponent(link.subscription)}/saker`;
    }
    if (link.subPage === 'invoices') {
      return `/tjenester/${encodeURIComponent(link.subscription)}/fakturaer`;
    }
    if (link.subPage === 'report-incident') {
      return `/tjenester/${encodeURIComponent(link.subscription)}/meld-feil`;
    }
    if (link.subPage === 'statistics') {
      return `/tjenester/${encodeURIComponent(link.subscription)}/statistikk`;
    }
  }

  /**
   * Organisations
   */
  if ('organisation' in link) {
    return buildUrl('/fakturaer', { organisation: link.organisation });
  }

  /**
   * Documents
   */
  if ('file' in link) {
    if ('path' in link) {
      return `${link.path}/f/${link.file}`;
    }
    return `/dokumenter/f/${encodeURIComponent(link.file)}`;
  }
  if ('folder' in link) {
    if ('path' in link) {
      return `${link.path}/m/${link.folder}`;
    }
    return `/dokumenter/m/${encodeURIComponent(link.folder)}`;
  }

  /**
   * Reports
   */
  if ('report' in link) {
    return `/rapporter/${encodeURIComponent(
      link.report
    )}?period=${encodeURIComponent(link.period)}`;
  }

  /**
   * ServicePlus
   */
  if ('servicePlusAddress' in link) {
    return `/service-plus/lokasjon/${encodeURIComponent(
      link.servicePlusAddress
    )}`;
  }

  // No default -- TypeScript will help us ensure that we're catching
  // all variants as long as the input props are well defined.
  // Update: Default is to redirect to '/' until we map all other code paths ...
  return '/';
};
