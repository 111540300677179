import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { useIncident_customer_incidents } from './__types/useIncident';
import { ContactFragment } from 'src/lib/types/contact/ContactFragment';
import * as t from './__types/useIncident';
export const INCIDENT_QUERY = gql`
  query useIncident($customerId: ID!, $incidentId: ID!) {
    customer(id: $customerId) {
      id
      incidents(ids: [$incidentId]) {
        id
        active
        category
        createdAt
        updatedAt
        incidentStatus
        notify
        shortDescription
        description
        priority
        severity
        slaList {
          definition
        }
        closeCode
        closeNotes
        closed
        contactType
        portalCreatedBy {
          firstName
          lastName
          email
          mobilePhone
          secondaryPhone
        }
        createdBy
        customerReference
        customer {
          id
          name
        }
        notificationContacts {
          id
          name
          email
          phoneNumber
          contact {
            ...ContactSearchResult
          }
        }
        attachments {
          userName
          userEmail
          userPhone
          userCustomerName
          userCustomerId
          fileName
          updatedOn
          contentType
          size
          downloadLink
        }
        activities {
          ... on IncidentComment {
            userName
            userEmail
            userPhone
            userCustomerName
            userCustomerId
            comment
            type
            timestamp
          }
          ... on IncidentFieldChange {
            userName
            userEmail
            userPhone
            userCustomerName
            userCustomerId
            value
            type
            fieldName
            timestamp
          }
        }
        subscription {
          id
          name
          alias
          type
          deliveryStatus
          group
          parent {
            id
            alias
            group
            name
          }
          subscriptionId
          startTime
          endTime
          lastModified
          installationAddress {
            address
            city
            zip
            location {
              lat
              lon
            }
          }
          cpe {
            status
          }
          redundancies {
            id
            name
            alias
            type
            group
            cpe {
              status
            }
          }
        }
        addon {
          id
          name
          alias
          type
          group
          cpe {
            status
          }
        }
      }
    }
  }
  ${ContactFragment}
`;

export type ActivityType = 'comments' | 'fieldChange';
export type NotificationType = 'DoNotNotify' | 'Email' | 'SMS' | 'SMSEmail';
export type Category = 'Fault' | 'Inquiry / Help' | 'Light Change';

export type Attachment = t.useIncident_customer_incidents_attachments;
export interface IncidentData {
  loading: boolean;
  error: boolean;
  incident?: useIncident_customer_incidents;
  refetch: () => void;
}

export const useIncident = (
  incidentId: string,
  fetchNetwork?: boolean
): IncidentData => {
  const customer = useCustomer();
  const { data, loading, error, refetch } = useQuery<
    t.useIncident,
    t.useIncidentVariables
  >(INCIDENT_QUERY, {
    variables: {
      customerId: customer.id,
      incidentId: incidentId,
    },
    fetchPolicy: fetchNetwork ? 'network-only' : 'cache-first',
  });

  return {
    loading: loading,
    refetch: refetch,
    error: !!error,
    incident: data?.customer?.incidents?.[0] ?? undefined,
  };
};
