import { getContactColumnDefinitions } from './getContactColumnDefinitions';
import { getInvoiceColumnDefinitions } from './getInvoiceColumnDefinitions';
import { getMobileColumnDefinitions } from './getMobileColumnDefinitions';
import {
  getTvTableDefinitions,
  getDataColumnDefinitions,
  getTeleColumnDefinitions,
  getIncodentColumnDefinitions,
  getOrderColumnDefinitions,
} from './';
import { SearchDomain } from '../../__types/graphql-global-types';
import { SubscriptionColumnDefinitions } from '../../areas/main/subscriptions/types';

/**
 * This hooks handles logic for available columns and user selected columns,
 * as well ass typings for this feature.
 */
export const useDomainColumns = (
  domain: SearchDomain,
  savedColumnPreferences: string[]
): SubscriptionColumnDefinitions => {
  switch (domain) {
    case SearchDomain.dataSubscription:
      return getDataColumnDefinitions(savedColumnPreferences);
    case SearchDomain.mobileSubscription:
      return getMobileColumnDefinitions(savedColumnPreferences);
    case SearchDomain.tvSubscription:
      return getTvTableDefinitions(savedColumnPreferences);
    case SearchDomain.teleSubscription:
      return getTeleColumnDefinitions(savedColumnPreferences);
    case SearchDomain.incident:
      return getIncodentColumnDefinitions(savedColumnPreferences);
    case SearchDomain.invoice:
      return getInvoiceColumnDefinitions(savedColumnPreferences);
    case SearchDomain.order:
      return getOrderColumnDefinitions(savedColumnPreferences);
    case SearchDomain.contact:
      return getContactColumnDefinitions(savedColumnPreferences);
    default:
      return { availableColumns: [], columns: [] };
  }
};
