import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { Invoice } from 'src/lib/types';
import { formatNordic } from 'src/lib/utils';
import { t } from 'src/lib/i18n';

export const SearchMenuInvoiceResult: React.FC<SearchMenuResultProps<
  Invoice
>> = props => (
  <SearchMenuResultItem
    {...props}
    icon="document"
    title={`${t.search.SearchMenuInvoiceResult.titlePrefix} ${props.entry.id}`}
  >
    {props.entry.amountGross !== undefined ? (
      <span className="ml-3">
        {t.search.SearchMenuInvoiceResult.currencyInclVat(
          formatNordic(props.entry.amountGross, 2)
        )}
      </span>
    ) : null}
    {props.entry.amountNet !== undefined ? (
      <span className="ml-3">
        {t.search.SearchMenuInvoiceResult.currencyExclVat(
          formatNordic(props.entry.amountNet, 2)
        )}
      </span>
    ) : null}
  </SearchMenuResultItem>
);
