import * as React from 'react';

import * as t from './types';

const localStorageKey = 'appState';
const maxSearchHistoryEntries = 8;

interface State {
  searchHistoryEntries: t.SearchHistoryEntry[];
}

interface Props {
  children: (localState: t.LocalState) => null | React.ReactNode;
}

class WithLocalState extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      searchHistoryEntries: [],
    };
    try {
      const local = localStorage.getItem(localStorageKey);
      if (local) {
        this.state = JSON.parse(local);
      }
    } catch (err) {
      console.log(err);
    }
  }

  writeToStorage = () => {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(this.state));
    } catch (err) {
      console.log(err);
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.writeToStorage();
    }
  }

  deleteSearchHistoryEntry = (idx: number) => {
    this.setState(prevState => {
      if (idx >= 0 && idx < prevState.searchHistoryEntries.length) {
        return {
          searchHistoryEntries: [
            ...prevState.searchHistoryEntries.slice(0, idx),
            ...prevState.searchHistoryEntries.slice(idx + 1),
          ],
        };
      }
      return null;
    });
  };

  addSearchHistoryEntry = (query: string) => {
    this.setState(prevState => {
      return {
        searchHistoryEntries: [
          { query },
          ...prevState.searchHistoryEntries.filter(e => e.query !== query),
        ].slice(0, maxSearchHistoryEntries),
      };
    });
  };

  render() {
    return this.props.children({
      searchHistory: {
        entries: this.state.searchHistoryEntries,
        deleteEntry: this.deleteSearchHistoryEntry,
        addEntry: this.addSearchHistoryEntry,
      },
    });
  }
}

export default WithLocalState;
