import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useConnectedSimCard';
const GET_CONNECTED_SIM_CARD = gql`
  query useConnectedSimCard($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        connectedSimCard {
          simCardNumber
          operator
        }
      }
    }
  }
`;

interface Result {
  connectedSimCard?: t.useConnectedSimCard_customer_subscriptions_connectedSimCard;
  loading: boolean;
}
export const useConnectedSimCard = (subscriptionId: string): Result => {
  const customer = useCustomer();

  const { data, loading } = useQuery<
    t.useConnectedSimCard,
    t.useConnectedSimCardVariables
  >(GET_CONNECTED_SIM_CARD, {
    variables: {
      customerId: customer.id,
      subscriptionId,
    },
    errorPolicy: 'all',
  });

  const connectedSimCard =
    data?.customer?.subscriptions?.[0]?.connectedSimCard || undefined;

  return { connectedSimCard, loading };
};
