import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useStatisticsInterfaces';

export const GET_STATISTICS_INTERFACES = gql`
  query useStatisticsInterfaces($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        interfaces {
          alias
          name
          type
          status
          subscription {
            id
            vrf {
              vpnAlias
              vpnId
              vrfName
              sagCode
            }
          }
        }
      }
    }
  }
`;

interface StatisticsInterface {
  value: string;
  label: string;
  subLabel: string;
}

/**
 * Query and format interfaces for use in Statistics.
 */
export const useStatisticsInterfaces = (
  subscriptionId: string
): {
  loading: boolean;
  standardInterfaces: StatisticsInterface[];
  liveInterfaces: StatisticsInterface[];
} => {
  const customer = useCustomer();
  const { data, loading } = useQuery<
    t.useStatisticsInterfaces,
    t.useStatisticsInterfacesVariables
  >(GET_STATISTICS_INTERFACES, {
    variables: {
      customerId: customer.id,
      subscriptionId,
    },
    errorPolicy: 'all',
    skip: !subscriptionId,
  });

  if (loading) {
    return {
      loading,
      standardInterfaces: [],
      liveInterfaces: [],
    };
  }

  const interfaces = data?.customer?.subscriptions?.[0]?.interfaces;

  /**
   * Consists only of active interfaces
   */
  const standardInterfaces = interfaces
    ? mapInterfaceToFilterOption(
        interfaces.filter(i => i.status !== 'NoStatus')
      )
    : [];

  /**
   * Consists of all interfaces for the sub sorted by the active interfaces first.
   */
  const liveInterfaces = standardInterfaces.concat(
    interfaces
      ? mapInterfaceToFilterOption(
          interfaces.filter(i => i.status === 'NoStatus')
        )
      : []
  );

  return {
    loading,
    standardInterfaces,
    liveInterfaces,
  };
};

const mapInterfaceToFilterOption = (
  interfaces: t.useStatisticsInterfaces_customer_subscriptions_interfaces[]
): StatisticsInterface[] => {
  return interfaces.map(i => {
    let subLabel = '';
    if (i.alias && i.alias.toLowerCase().includes('wan')) {
      subLabel = subLabel + 'WAN';
    }
    if (i.subscription && i.subscription.vrf) {
      if (subLabel) {
        subLabel = subLabel + ' ';
      }
      const vrfInfo = i.subscription.vrf;
      subLabel =
        subLabel + `${vrfInfo.vpnAlias} ${vrfInfo.vpnId} ${vrfInfo.vrfName}`;
    }

    return {
      value: i.name,
      label: i.name,
      subLabel,
    };
  });
};
