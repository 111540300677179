export function firstCharToLower(s: string): string {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toLowerCase() + s.slice(1);
}

export function firstCharToUpper(s: string): string {
  if (typeof s !== 'string') {
    return '';
  }
  return s.charAt(0).toUpperCase() + s.slice(1);
}
