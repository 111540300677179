import React, { useState } from 'react';
import { VoicemailMobileSettings } from 'src/lib/types';
import { SettingsCard } from 'src/areas/mobile/settings/SettingsCard';
import { t } from 'src/lib/i18n';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import VisualVoiceMailFormModal from 'src/areas/mobile/settings/forms/VisualVoiceMailFormModal';
interface Props {
  voicemailSettings: VoicemailMobileSettings;
}

export const VisualVoicemailSetting: React.FC<Props> = ({
  voicemailSettings,
}) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const i18n = t.mobile.settings.visualVoicemail;
  return (
    <SettingsCard
      title={i18n.visualVoicemailForiPhone}
      description={i18n.visualVoicemailInfo}
      onClick={() => setModalOpen(true)}
    >
      <SettingsListItem
        title={i18n.VisualVoicemail}
        description={
          voicemailSettings.visualVoicemail
            ? t.mobile.settings.activated
            : t.mobile.settings.notActivated
        }
      />
      <VisualVoiceMailFormModal
        voicemailSettings={voicemailSettings}
        isOpen={isModalOpen}
        toggle={() => setModalOpen(!isModalOpen)}
      />
    </SettingsCard>
  );
};
