import { Role } from 'src/areas/main/contacts/lib/createContact/types';

export const allowAllNotifications = (roles?: Role[]) =>
  roles
    ? !!roles.filter(
        role =>
          (role.role === 'CustomerAdministrator' &&
            !role.organisations &&
            !role.tags) ||
          (role.role === 'SystemAdministrator' &&
            !role.organisations &&
            !role.tags)
      ).length
    : false;
