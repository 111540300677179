import React from 'react';
import { Spinner, Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

interface Props {
  loading?: boolean;
  error?: boolean;
  errorMsg?: string;
  success?: boolean;
  successMsg?: string;
  submitLabel?: string;
  submit?: (
    e?:
      | React.MouseEvent<HTMLButtonElement>
      | React.FormEvent<HTMLFormElement>
      | React.SyntheticEvent<EventTarget>
  ) => void;
  cancel?: (e?: React.SyntheticEvent<EventTarget>) => void;
  linkToOnCancel?: string;
  disabled?: boolean;
}

export const FormSubmitBar: React.FC<Props> = props => {
  const translate = t.ui.FormSubmitBar;
  if (props.loading) {
    return (
      <div style={{ margin: 'auto' }}>
        <Spinner />
      </div>
    );
  } else {
    return (
      <div className="FormSubmitBar d-flex flex-fill">
        {props.error ? (
          <div style={{ margin: 'auto', fontSize: '18px' }}>
            <Icon icon="alert" style={{ color: 'red' }} className="mr-1" />
            {props.errorMsg ? props.errorMsg : ''}
          </div>
        ) : (
          undefined
        )}

        {props.success && (
          <div style={{ margin: 'auto', fontSize: '18px' }}>
            <Icon
              style={{ color: 'green' }}
              icon="check-mark"
              className="mr-1"
            />
            {props.successMsg ? props.successMsg : ''}
          </div>
        )}

        <div className="d-flex ml-auto">
          <div className="mr-3">
            <Button
              type="button"
              base="primary"
              to={props.linkToOnCancel}
              onClick={props.cancel}
            >
              {props.successMsg || props.success
                ? `${translate.btnBack}`
                : `${translate.btnCancel}`}
            </Button>
          </div>
          <div>
            <Button
              disabled={props.disabled}
              type="submit"
              color="purple"
              base="primary"
              onClick={props.submit}
            >
              {props.submitLabel || `${translate.btnSubmit}`}
            </Button>
          </div>
        </div>
      </div>
    );
  }
};
