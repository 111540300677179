import React, { useState, useEffect } from 'react';
import { TextArea, Chip, colors, TextField, Button } from 'src/lib/ui';
import AnimateHeight from 'react-animate-height';
import { t } from 'src/lib/i18n';
import { yupValidatePhone } from 'src/lib/utils/yupValidations';
import * as yup from 'yup';

interface Props {
  message: string;
  recipients: string[];
  send: (e?: React.SyntheticEvent<HTMLButtonElement>) => void;
  addRecipient: (phoneNumber: string) => void;
  removeRecipient: (recipient: string) => void;
  handleMessageChange: (value: string) => void;
}

const SmsFormRebrand: React.SFC<Props> = props => {
  const [phoneNumber, setPhoneNumber] = useState<string>('');
  const [isValid, setIsValid] = useState<boolean>(true);

  useEffect(() => {
    if (!phoneNumber.length) {
      setIsValid(true);
    }
  }, [phoneNumber]);
  const addRecipient = (phone: string) => {
    const schema = yup.object().shape({
      phone: yupValidatePhone(''),
    });
    schema
      .validate({ phone })
      .then(r => {
        props.addRecipient(phone);
        setPhoneNumber('');
        setIsValid(true);
      })
      .catch(err => setIsValid(false));
  };

  const onkeydown = async (e: React.KeyboardEvent) => {
    if (e.keyCode === 13 || e.keyCode === 9) {
      e.preventDefault();
      addRecipient(phoneNumber.replace(',', '').trim());
    }
    if (e.keyCode === 32 && phoneNumber.slice(phoneNumber.length - 1) === ',') {
      addRecipient(phoneNumber.replace(',', '').trim());
    }
  };

  return (
    <div style={{ color: colors.greyDarkText }}>
      <div>
        <div className="d-flex mb-4">
          <TextField
            className="w-50"
            label={t.mobile.sms.recipientsInputLabel}
            value={phoneNumber}
            bordered={true}
            onChange={e => setPhoneNumber(e.target.value)}
            onKeyDown={e => onkeydown(e)}
            error={isValid ? '' : t.mobile.sms.invalidPhoneNumber}
          />
          <div className="pl-2 pr-4 align-self-center">
            <Button
              icon="add"
              base="primary"
              color="white"
              transparent={true}
              onClick={() => addRecipient(phoneNumber.trim())}
            >
              {t.mobile.sms.add}
            </Button>
          </div>
        </div>

        <AnimateHeight height={props.recipients.length ? 'auto' : 0}>
          <div className="mb-3">
            <div className="mb-3">{t.mobile.sms.recipients}</div>
            {props.recipients.map(recipient => (
              <Chip
                key={recipient}
                label={recipient}
                mode="select"
                active={true}
                onClick={() => props.removeRecipient(recipient)}
              />
            ))}
          </div>
        </AnimateHeight>
      </div>

      <form>
        <TextArea
          style={{ height: '250px' }}
          label={t.mobile.sms.writeSms}
          onChange={e => props.handleMessageChange(e.target.value)}
          value={props.message}
          helpText={t.mobile.sms.characterCount + `: ${props.message.length}`}
          id="sms-textarea"
        />

        <Button
          className="d-block ml-auto"
          disabled={props.message.length === 0 || props.recipients.length === 0}
          onClick={props.send}
          color="purple"
        >
          {t.mobile.sms.send}
        </Button>
      </form>
    </div>
  );
};

export default SmsFormRebrand;
