import { DataViewType, Period } from 'src/lib/types';

export const parseStringToNumber = (text?: string): number | undefined => {
  if (text && /^-?[0-9]+$/.test(text)) {
    return parseInt(text, 10);
  }
  return undefined;
};

export const parseStringToDataViewType = (
  text?: string
): DataViewType | undefined => {
  if (!text) {
    return undefined;
  }
  if ((text as DataViewType) === 'list' || (text as DataViewType) === 'table') {
    return text as DataViewType;
  }
  return undefined;
};

export const parseStringToSortOrder = (
  param: string | undefined
): 'asc' | 'desc' | undefined => {
  if (param === 'asc' || param === 'desc') {
    return param;
  }
  return undefined;
};

export const parseStringToPeriod = (
  dateString: string | undefined
): Period | undefined => {
  if (dateString && /^([12]\d{3}-(0[1-9]|1[0-2]))/.test(dateString)) {
    return dateString;
  }
  return undefined;
};
