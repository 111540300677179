import React from 'react';
import cs from 'classnames';
import './StatusBadge.scss';

interface Props {
  label: string;
  color: 'danger' | 'warning' | 'success';
}
export const StatusBadge = (props: Props) => {
  return (
    <div className={cs('StatusBadge', `StatusBadge--${props.color}`)}>
      {props.label}
    </div>
  );
};
