import React, { useState, useEffect } from 'react';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { Formik, FormikProps } from 'formik';
import { Contact } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { colors, Button, RequireRole, FormSubmitBar } from 'src/lib/ui';
import { yupValidatePersonalia } from 'src/lib/utils/yupValidations';
import { useAppContext } from 'src/lib/global';
import { EditSettingIdentity } from './EditSettingIdentity';
import { trackContactUpdate } from 'src/lib/analytics';

import { useEditPerson } from '../../mutations/useEditPerson';
import { Personalia } from '../createContact/types';
import PersonaliaForm from './PersonaliaForm';

interface Props {
  contact: Contact;
}

export const EditContactModal: React.FC<Props> = props => {
  const [open, setOpen] = useState(false);
  const app = useAppContext();

  const i18n = t.contacts.EditContact;
  const { editPersonalia, loading, error, data } = useEditPerson();

  useEffect(() => {
    if (data && !error) {
      setOpen(false);
    }
  }, [data, error]);

  const isMe =
    props.contact?.user?.id && props.contact.user.id === app.access?.id;

  const handleSubmit = (formik: FormikProps<Personalia>) => {
    trackContactUpdate('contact_info');
    formik.handleSubmit();
  };

  return (
    <>
      <div>
        <Button color="white" onClick={() => setOpen(true)}>
          {i18n.title}
        </Button>
      </div>
      <Formik<Personalia>
        initialValues={{
          firstName: props.contact.firstName || '',
          lastName: props.contact.lastName || '',
          mobilePhone: props.contact.mobilePhone || '',
          secondaryPhone: props.contact.secondaryPhone || '',
          description: props.contact.description || '',
          employeeNumber: props.contact.employeeNumber || '',
          email: props.contact.email || '',
        }}
        enableReinitialize={true}
        validationSchema={() => yupValidatePersonalia}
        onSubmit={personalia => {
          const contact: Contact = {
            __typename: 'Contact',
            id: props.contact.id,
            firstName: personalia.firstName,
            lastName: personalia.lastName,
            mobilePhone: personalia.mobilePhone,
            secondaryPhone: personalia.secondaryPhone,
            description: personalia.description,
            employeeNumber: personalia.employeeNumber,
            email: personalia.email,
          };
          editPersonalia(contact);
        }}
      >
        {formik => (
          <>
            <Modal isOpen={open} toggle={() => setOpen(!open)} size="lg">
              <ModalHeader>
                <div>{i18n.title}</div>
              </ModalHeader>
              <ModalBody>
                <small
                  className="d-block mb-3"
                  style={{ color: colors.greyDarkText }}
                >
                  {i18n.description}
                </small>
                <PersonaliaForm
                  values={formik.values}
                  enableEmail={!props.contact.user}
                  setFieldValue={(field, value) =>
                    formik.setFieldValue(field, value)
                  }
                />
                {props.contact.user &&
                  (isMe ? (
                    <EditSettingIdentity />
                  ) : (
                    <RequireRole role="CustomerAdministrator">
                      <small className="d-block pb-5 grey-dark-text">
                        <div className="font-weight-bold pb-2">
                          {i18n.changeEmailAddress}
                        </div>
                        <div>{i18n.adminChangeEmailInfoText}</div>
                      </small>
                    </RequireRole>
                  ))}
              </ModalBody>
              <ModalFooter>
                <FormSubmitBar
                  submit={() => handleSubmit(formik)}
                  cancel={() => setOpen(false)}
                  error={error}
                  errorMsg={i18n.errors.failed}
                  loading={loading}
                  disabled={!formik.isValid}
                />
              </ModalFooter>
            </Modal>
          </>
        )}
      </Formik>
    </>
  );
};
