import { reduce, memoize } from 'lodash';

import {
  ProductGroupLabel,
  CostMatrixKey,
  CostType,
  CostMatrixCategoryKey,
  CostAggregate,
  CostMatrix,
} from '../types';

function productGroupKey(pg?: ProductGroupLabel): CostMatrixKey {
  if (pg === 'Mobiltjenester') {
    return 'mobile';
  } else if (pg === 'Datatjenester') {
    return 'data';
  } else if (pg === 'Fastnettjenester') {
    return 'tele';
  }
  return 'other';
}

function costTypeKey(ct?: CostType): CostMatrixCategoryKey {
  if (ct === 'Abonnement') {
    return 'subscription';
  } else if (ct === 'Engangsbeløp') {
    return 'oneTime';
  }
  return 'usage';
}

export const extractCostMatrix = memoize(
  (costAggregates?: null | CostAggregate[]) =>
    costAggregates
      ? reduce(
          costAggregates,
          (prev, ca) => {
            const pg = productGroupKey(ca.productGroup as ProductGroupLabel);
            const ct = costTypeKey(ca.costType as CostType);
            prev[pg][ct].gross += ca.totalAmountGross;
            prev[pg][ct].net += ca.totalAmountNet;
            prev[pg].total.gross += ca.totalAmountGross;
            prev[pg].total.net += ca.totalAmountNet;
            prev.total[ct].gross += ca.totalAmountGross;
            prev.total[ct].net += ca.totalAmountNet;
            prev.total.total.gross += ca.totalAmountGross;
            prev.total.total.net += ca.totalAmountNet;
            return prev;
          },
          {
            mobile: {
              usage: { gross: 0, net: 0 },
              subscription: { gross: 0, net: 0 },
              oneTime: { gross: 0, net: 0 },
              total: { gross: 0, net: 0 },
            },
            tele: {
              usage: { gross: 0, net: 0 },
              subscription: { gross: 0, net: 0 },
              oneTime: { gross: 0, net: 0 },
              total: { gross: 0, net: 0 },
            },
            data: {
              usage: { gross: 0, net: 0 },
              subscription: { gross: 0, net: 0 },
              oneTime: { gross: 0, net: 0 },
              total: { gross: 0, net: 0 },
            },
            other: {
              usage: { gross: 0, net: 0 },
              subscription: { gross: 0, net: 0 },
              oneTime: { gross: 0, net: 0 },
              total: { gross: 0, net: 0 },
            },
            total: {
              usage: { gross: 0, net: 0 },
              subscription: { gross: 0, net: 0 },
              oneTime: { gross: 0, net: 0 },
              total: { gross: 0, net: 0 },
            },
          } as CostMatrix
        )
      : undefined
);
