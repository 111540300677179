import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { UserFragment } from 'src/lib/types/contact/UserFragment';
import * as t from './__types/useContact';
import { ContactFragment } from 'src/lib/types';

export const QUERY_CONTACT = gql`
  query useContact($customerId: ID!, $contactId: ID!) {
    customer(id: $customerId) {
      id
      contacts(ids: [$contactId]) {
        ...ContactSearchResult
        technicalContactSubscriptions {
          id
          # If the subs is under delivery/terminating, the query will fail if "name" is included. Do not include this for now.
          # name
          alias
          group
          cpe {
            status
          }
          group
          installationAddress {
            zip
            address
            city
            location {
              lat
              lon
            }
          }
        }
        user {
          ...UserFragment
        }
        notificationSettings {
          incident {
            type
            active
            channels
            subscriptions {
              id
              alias
              name
              type
              group
              cpe {
                status
                model
              }
              installationAddress {
                zip
                address
                city
                location {
                  lat
                  lon
                }
              }
            }
            notifyForAllCustomerSubscriptions
          }
          change {
            type
            active
            channels
            subscriptions {
              id
              alias
              name
              type
              group
              cpe {
                status
                model
              }
              installationAddress {
                zip
                address
                city
                location {
                  lat
                  lon
                }
              }
            }
            notifyForAllCustomerSubscriptions
          }
        }
      }
    }
  }
  ${ContactFragment}
  ${UserFragment}
`;

export type TechnicalContactSubscription = t.useContact_customer_contacts_technicalContactSubscriptions;

export const useContact = (contactId: string) => {
  const customer = useCustomer();
  return useQuery<t.useContact, t.useContactVariables>(QUERY_CONTACT, {
    variables: { customerId: customer.id, contactId },
    errorPolicy: 'all',
    skip: !contactId,
  });
};
