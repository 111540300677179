import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as t from './__types/useResolveIncident';
import { trackMarkAsSolved } from 'src/lib/analytics';

const MUATATION_RESOLVE_INCIDENT = gql`
  mutation useResolveIncident($commentInput: String, $incidentId: ID!) {
    markIncidentAsResolved(input: { id: $incidentId, comment: $commentInput }) {
      incident {
        id
        activities {
          ... on IncidentComment {
            userName
            userEmail
            userPhone
            userCustomerName
            userCustomerId
            comment
            type
            timestamp
          }
          ... on IncidentFieldChange {
            userName
            userEmail
            userPhone
            userCustomerName
            userCustomerId
            value
            type
            fieldName
            timestamp
          }
        }
        incidentStatus
      }
    }
  }
`;

interface ResolveIncidentResult {
  resolveIncident: (incidentId: string, commentInput: string) => void;
  loading: boolean;
  error: boolean;
}

export const useResolveIncident = (): ResolveIncidentResult => {
  const [mutation, { loading, error }] = useMutation<
    t.useResolveIncident,
    t.useResolveIncidentVariables
  >(MUATATION_RESOLVE_INCIDENT);

  const resolveIncident = (incidentId: string, commentInput: string) => {
    trackMarkAsSolved();
    mutation({
      variables: {
        incidentId,
        commentInput,
      },
    });
  };

  return {
    resolveIncident,
    loading,
    error: !!error,
  };
};
