import { push } from './Analytics';

export type DashboardActions =
  | 'open_changelog'
  | 'shortcut'
  | 'main_search'
  | 'start_chat'
  | 'update_settings'
  | 'open_notifications'
  | 'give_feedback'
  | 'view_planned_work';

export function trackChangelog() {
  push({
    category: 'Dashboard',
    action: 'open_changelog',
  });
}

export function trackShortcuts(shortcut: string, value: string) {
  push({
    category: 'Dashboard',
    action: 'shortcut',
    label: shortcut,
    value: value,
  });
}

export function trackMainSearch(searchValue: string) {
  push({
    category: 'Dashboard',
    action: 'main_search',
    label: searchValue,
  });
}

export function trackChat(chatService: string) {
  push({
    category: 'Dashboard',
    action: 'start_chat',
    label: chatService,
  });
}

export function trackSettings(settings: string) {
  push({
    category: 'Dashboard',
    action: 'update_settings',
    label: settings,
  });
}

export function trackNotifications() {
  push({
    category: 'Dashboard',
    action: 'open_notifications',
  });
}

export function trackFeedback() {
  push({
    category: 'Dashboard',
    action: 'give_feedback',
  });
}

export function trackPlannedWork() {
  push({
    category: 'Dashboard',
    action: 'view_planned_work',
  });
}
