import React from 'react';
import { SubscriptionsTableProps } from '../../types';
import { Subscription } from 'src/lib/types';
import { TableBodyRow, TableBodyCell, Table, colors } from 'src/lib/ui';
import { formatInstallationAddress } from 'src/lib/utils';
import {
  TagsTableCell,
  CostCenterTableCell,
  OrganisationTableCell,
  CasesTableCell,
} from '../Components';
import { TvColumns } from 'src/lib/table';

import { getBasicSubscriptionTitles } from '../../utils';
export const TvSubscriptionsTable = (props: SubscriptionsTableProps) => {
  const {
    loading,
    results,
    subscriptionListParams: sp,
    columns,
    selectProps,
  } = props;
  return (
    <Table
      loading={loading}
      columns={columns}
      sortable={true}
      setSort={sp.setSort}
      sortBy={sp.sortBy}
      sortOrder={sp.sortOrder}
      noResultMessage={sp.noResultsMessage}
      rowsCount={sp.pageSize}
      availableColumns={props.availableColumns}
      onChangeColumns={sp.setColumns}
      activeSort={{ key: sp.sortBy, order: sp.sortOrder }}
      noResult={!results.length}
      {...(selectProps ? { selectable: true, ...selectProps } : {})}
    >
      {results.map(row => {
        const subsData = row as Subscription;
        return (
          <TableBodyRow
            key={subsData.id}
            onClick={() => {
              sp.routeToSubscription(subsData.id);
            }}
            {...(selectProps ? { selectable: true } : {})}
            selected={selectProps?.selected.includes(subsData.id)}
            onSelectRow={() => selectProps?.onSelect(subsData.id)}
          >
            {props.columns.map((col, i) => (
              <TvTableCell
                key={`${col.key}-${i}`}
                column={col.key as TvColumns}
                subsData={subsData}
              />
            ))}
          </TableBodyRow>
        );
      })}
    </Table>
  );
};

const TvTableCell = (props: { column: TvColumns; subsData: Subscription }) => {
  const { subsData, column } = props;
  const { title, subtitle } = getBasicSubscriptionTitles(props.subsData);

  switch (column) {
    case 'name':
      return (
        <TableBodyCell>
          <div>{title}</div>
          <div style={{ color: colors.greyDarkText }}>{subtitle}</div>
        </TableBodyCell>
      );
    case 'address':
      return (
        <TableBodyCell>
          {subsData.installationAddress
            ? formatInstallationAddress(
                subsData.installationAddress,
                'fullAddress'
              )
            : ''}
        </TableBodyCell>
      );
    case 'cases':
      return <CasesTableCell subscription={subsData} />;

    case 'invoiceCenter':
      return <OrganisationTableCell subscription={subsData} />;
    case 'costCenter':
      return <CostCenterTableCell subscription={subsData} />;
    case 'tags':
      return <TagsTableCell subscription={subsData} />;
    default:
      return null;
  }
};
