import * as React from 'react';
import { MobilePeriodUsage } from 'src/areas/mobile/types';
import { BodyCard, Attribute } from 'src/lib/ui';
import { PeriodTable } from 'src/areas/mobile/history/PeriodTable';
import { t } from 'src/lib/i18n';
import { formatNordic, formatDate } from 'src/lib/utils';

interface Props {
  subscriptionName: string | null;
  usage: MobilePeriodUsage;
}

const CurrentUsage: React.SFC<Props> = ({ usage, subscriptionName }) => (
  <BodyCard noGutter={true}>
    <div className="p-4">
      <h2>{t.mobile.overview.usage.usageCurrentMonth}</h2>
      <small>
        <div style={{ textTransform: 'capitalize' }}>
          {'1. - ' + formatDate(new Date(), 'long')}
        </div>
        {subscriptionName}
      </small>
    </div>
    <Attribute
      className="
      pl-4 pb-4"
      label={t.mobile.history.total}
      value={
        usage.totalUsageAmount !== null
          ? ` Kr ${formatNordic(usage.totalUsageAmount, 2)}`
          : undefined
      }
    />
    <PeriodTable usage={usage} />
  </BodyCard>
);

export default CurrentUsage;
