import gql from 'graphql-tag';
import { useMutation, ExecutionResult } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { Contact, ContactFragment } from 'src/lib/types';
import { RoleVariableWithoutTagId } from '../lib/createContact/types';
import * as types from './__types/useEditPerson';

export const MUTATION_UPDATE_CONTACT = gql`
  mutation useEditPerson($input: EditPersonInput, $customerId: ID!) {
    editPerson(input: $input) {
      contact {
        ... on Contact {
          ...ContactSearchResult
        }
        user {
          customerAccess(customerId: $customerId) {
            roles {
              role
              organisation {
                id
                name
              }
              tag {
                id
                tag
              }
            }
          }
        }
      }
      errors {
        message
        code
      }
    }
  }
  ${ContactFragment}
`;

export interface UpdatePersonResult {
  editPersonalia: (
    contact: Contact
  ) => Promise<ExecutionResult<types.useEditPerson>>;

  editRoles: (
    contactId: string,
    roles?: Array<RoleVariableWithoutTagId>
  ) => Promise<ExecutionResult<types.useEditPerson>>;
  error: boolean;
  loading: boolean;
  data?: types.useEditPerson;
}

export const useEditPerson = (): UpdatePersonResult => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    types.useEditPerson,
    types.useEditPersonVariables
  >(MUTATION_UPDATE_CONTACT);
  const editPersonalia = (contact: Omit<Contact, '__typename'>) =>
    mutation({
      variables: {
        input: {
          contactId: contact.id,
          firstName: contact.firstName,
          lastName: contact.lastName,
          mobilePhone: contact.mobilePhone,
          secondaryPhone: contact.secondaryPhone,
          description: contact.description,
          email: contact.email,
          employeeNumber: contact.employeeNumber,
        },
        customerId: customer.id,
      },
    });

  const editRoles = (
    contactId: string,
    roles?: Array<RoleVariableWithoutTagId>
  ) =>
    mutation({
      variables: {
        input: {
          contactId,
          roles,
        },
        customerId: customer.id,
      },
    });
  return {
    editPersonalia,
    editRoles,
    data,
    loading,
    error: !!error || !!data?.editPerson?.errors?.length,
  };
};
