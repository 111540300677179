import React from 'react';
import { Button } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';
import { t } from 'src/lib/i18n';
import cs from 'classnames';
export const ChangeSubmitted = (props: {
  onGoBack: () => void;
  heading?: string;
  submittedDescription: string[];
  goBackLabel?: string;
  className?: string;
}) => {
  const i18n = t.subscriptions.TechnicalDetailsCard.editLanIP;
  return (
    <div className={cs('mx-auto', props.className)}>
      <div className="border-bottom text-center pb-5 mb-4">
        <div className="mb-3">
          <Icon
            icon="check-mark-circle"
            style={{ width: '64px', height: '64px' }}
          />
        </div>
        <div style={{ fontSize: '1.25rem' }}>
          {props.heading ?? i18n.changeSubmitted}
        </div>
      </div>
      <div className="pb-5">
        {props.submittedDescription.map((d, i) => (
          <React.Fragment key={i}>
            {d} <br /> <br />
          </React.Fragment>
        ))}
      </div>

      <Button
        className="d-block ml-auto"
        base="primary"
        color="white"
        onClick={props.onGoBack}
      >
        {props.goBackLabel ?? i18n.goBackToService}
      </Button>
    </div>
  );
};
