import React, { useRef, useEffect } from 'react';
import { Button, SearchInput } from 'src/lib/ui';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import './OptionSelect.scss';
import cs from 'classnames';
import { useCombobox } from 'downshift';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import {
  useOptionSelect,
  useTabbedDropdown,
  OptionSelectProps,
  OptionSelectMenu,
  OptionSelectFooter,
  OptionSelectButtonStyleProps,
} from './index';

export const SearchOptionSelect = (
  props: Omit<OptionSelectProps, 'allowSearch'> & OptionSelectButtonStyleProps
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useTabbedDropdown();
  const {
    options,
    rowHeight,
    selectedOptions,
    partiallySelectedOptions,
    optionIsSelected,
    optionIsPartiallySelected,
    handleClick,
    filterResults,
    reset,
    disableSave,
  } = useOptionSelect({
    options: props.options,
    actionOptions: props.actionOptions,
    selectedOptions: props.selectedOptions,
    partiallySelectedOptions: props.partiallySelectedOptions,
    containerRef,
    allowMultiple: props.allowMultiple,
  });

  const downshift = useCombobox({
    items: options,
    circularNavigation: false,
    onInputValueChange: change => filterResults(change.inputValue ?? ''),
    onIsOpenChange: changes => {
      if (!changes.isOpen) {
        if (!props.allowMultiple && changes.selectedItem) {
          return props.handleSave([changes.selectedItem?.value]);
        }
        if (!props.disableSaveOnClickOutside) {
          return props.handleSave(selectedOptions, partiallySelectedOptions);
        }
      }
    },
    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useCombobox.stateChangeTypes.FunctionOpenMenu:
          return {
            ...actionAndChanges.changes,
            highlightedIndex: 0,
          };
        case useCombobox.stateChangeTypes.InputBlur:
          return state;
        case useCombobox.stateChangeTypes.ItemClick:
        case useCombobox.stateChangeTypes.InputKeyDownEnter:
          if (actionAndChanges.changes?.selectedItem?.onClick) {
            actionAndChanges.changes.selectedItem.onClick(state.inputValue);
            return state;
          } else if (actionAndChanges.changes?.selectedItem) {
            handleClick(actionAndChanges.changes?.selectedItem);
          }
          return {
            ...state,
            selectedItem: actionAndChanges.changes.selectedItem,
            isOpen: !!props.allowMultiple,
          };
        default:
          return actionAndChanges.changes;
      }
    },
  });

  useEffect(
    () => filterResults(downshift.inputValue),
    [downshift.inputValue, props.options] // eslint-disable-line
  );

  const cancel = props.cancel
    ? () => {
        props.cancel?.();
        downshift.closeMenu();
      }
    : undefined;
  const menuProps = downshift.getMenuProps({
    className: 'OptionSelectMenu',
    ref: containerRef,
  });
  const currentIsDefault =
    props.defaultOption && props.selectedOptions?.includes(props.defaultOption);

  const key =
    props.alignRight && downshift.isOpen
      ? props.label + downshift.isOpen.toString()
      : undefined;

  const applySelection = () => {
    if (props.disableSaveOnClickOutside) {
      props.handleSave(selectedOptions, partiallySelectedOptions);
    }
    downshift.closeMenu();
  };
  return (
    <div {...downshift.getComboboxProps()} ref={dropdownRef} className="Select">
      <Dropdown
        isOpen={downshift.isOpen}
        toggle={downshift.isOpen ? downshift.closeMenu : downshift.openMenu}
        className="d-inline"
      >
        <DropdownToggle tag="span">
          <Button
            color={props.color ?? 'dark'}
            outline={props.outline}
            size={props.size}
            transparent={props.transparent}
            className={cs({
              'w-100': props.fullWidth,
              open: downshift.isOpen,
              inUse:
                props.blackBorderOnSelect &&
                !currentIsDefault &&
                selectedOptions?.length,
            })}
          >
            {props.icon &&
            (!props.iconPosition || props.iconPosition === 'before') ? (
              <Icon className="mr-2 ml-n1" icon={props.icon} />
            ) : null}
            {props.label}
            {props.icon && props.iconPosition === 'after' ? (
              <Icon
                icon={props.icon}
                className={cs(props.fullWidth ? 'ml-auto' : 'ml-2', 'mr-n1')}
              />
            ) : null}
          </Button>
        </DropdownToggle>
        {/**
         * Alignment does not work when using persist.
         * If we want to right align the menu we re-mount the dropdown menu by updating the key
         * Related issue: https://github.com/reactstrap/reactstrap/issues/1070
         */}
        <DropdownMenu
          key={key}
          persist={true}
          right={props.alignRight}
          className={cs({ 'w-100': props.fullWidth })}
        >
          <SearchInput inputProps={downshift.getInputProps} />
          <OptionSelectMenu
            menuProps={menuProps}
            getItemProps={downshift.getItemProps}
            options={options}
            loading={props.loading}
            highlightedIndex={downshift.highlightedIndex}
            optionIsSelected={optionIsSelected}
            optionIsPartiallySelected={optionIsPartiallySelected}
            noOptionsMessage={props.noOptionsMessage}
            rowHeight={rowHeight}
            allowMultiple={props.allowMultiple}
            fullWidth={props.fullWidth}
            inputValue={downshift.inputValue}
          />
          {props.allowMultiple ? (
            <OptionSelectFooter
              apply={applySelection}
              reset={cancel ?? reset}
              resetLabel={cancel ? t.ui.SelectField.cancel : undefined}
              disabled={disableSave}
              loading={props.loading}
            />
          ) : null}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
