import React from 'react';
import { PersonalizedDashboardContactCard } from './PersonalizedDashboardContactCard';
import { Feature } from 'src/lib/ui';
import { Link } from 'react-router-dom';
import { Icon } from '@telia/styleguide';
import { t, variables } from 'src/lib/i18n';
import { trackFeedback } from 'src/lib/analytics';

export default function PersonalizedDashboardFooter() {
  return (
    <div className="container my-4">
      <div className="row row-eq-height ">
        <Feature feature="canny-widget">
          <div className="col-md-4 my-3">
            <PersonalizedDashboardContactCard
              label={t.dashboard.cannyApp.feedbackLabel}
              text={t.dashboard.cannyApp.feedbackDetails}
            >
              <p>
                <Link to="/feedback" onClick={() => trackFeedback()}>
                  {t.dashboard.cannyApp.linkLabel}{' '}
                  <Icon icon="arrow-large-right" />
                </Link>
              </p>
            </PersonalizedDashboardContactCard>
          </div>
        </Feature>

        <div className="col-md-4 my-3">
          <PersonalizedDashboardContactCard
            label={t.dashboard.supportContactLabel}
            text={t.dashboard.supportContactDetails}
            email={variables.customerSupportEmail}
            phoneNumber={variables.customerSupportNumber}
          />
        </div>
      </div>
    </div>
  );
}
