import gql from 'graphql-tag';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { useQuery } from 'react-apollo';

import * as types from './__types/useSubscriptionOriginDetails';

export const SUBSCRIPTION_ORIGIN_DETAILS = gql`
  query useSubscriptionOriginDetails($id: ID!) {
    subscription(id: $id) {
      id
      origin {
        firstName
        middleName
        lastName
        streetAddress
        streetNum
        apartmentNum
        floor
        gnr
        fnr
        knr
        postCode
        postArea
        country
      }
    }
  }
`;

export const useSubscriptionOriginDetails = () => {
  const subscription = useSubscriptionContext();
  return useQuery<
    types.useSubscriptionOriginDetails,
    types.useSubscriptionOriginDetailsVariables
  >(SUBSCRIPTION_ORIGIN_DETAILS, {
    variables: {
      id: subscription.id,
    },
  });
};
