import React from 'react';
import { SelectContactDropdown } from './SelectContactDropdown';
import { formatName } from 'src/lib/utils/formatName';
import { WithSearch, SearchField } from 'src/areas/main/search';
import { colors, StepFlowCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import ContactCard from '../displayContact/ContactCard';
import { Step2Virtualized } from './Step2Virtualized';
import { FormState } from './useReplaceContactStepFlow';

export const Step2 = (props: {
  form: FormState;
  isValid: boolean;
  isLastStep?: boolean;
}) => {
  const i18n = t.contacts.DeletePerson.step2;
  let title = i18n.selectReplacement;
  return (
    <StepFlowCard
      step={1}
      title={title}
      currentStep={props.form.currentStep}
      isValid={props.isValid}
      isLastStep={props.isLastStep}
      onClick={() => props.form.setStep(1)}
      onContinue={props.form.nextStep}
      form={<Form {...props.form} />}
    />
  );
};
const Form = (form: FormState) => {
  const i18n = t.contacts.DeletePerson.step2;
  const options = Object.entries(form.values.map ?? {});
  return form.values.replace === 'all' ? (
    <>
      <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
        {i18n.replaceAll(options.length)}
      </small>
      {form.values.replaceAllContact && (
        <div className="pb-3">
          <ContactCard contact={form.values.replaceAllContact} />{' '}
        </div>
      )}
      <div className="w-75 pb-4">
        <SelectContactDropdown
          activeContactName={formatName({
            firstName: form.values?.replaceAllContact?.firstName,
            lastName: form.values?.replaceAllContact?.lastName,
          })}
        >
          <WithSearch initial={{ types: ['contact'] }}>
            {sp => (
              <SearchField
                narrow={true}
                searchProvider={{
                  ...sp,
                  results: sp.results.filter(
                    c => c.id !== form.values.initialContactId
                  ),
                }}
                renderSearchMenuOnFocus={true}
                onSelect={item => {
                  if (item?.__typename === 'Contact') {
                    form.setValue('replaceAllContact', item);
                  }
                }}
              />
            )}
          </WithSearch>
        </SelectContactDropdown>
      </div>
    </>
  ) : form.values.replace === 'custom' ? (
    <Step2Virtualized values={form.values} setFieldValue={form.setValue} />
  ) : null;
};
