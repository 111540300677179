import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import useStructureCacheUpdater from '../lib/useStructureCacheUpdater';
import { FolderFragment } from 'src/lib/types';
import * as t from './__types/useCreateFolder';

const MUTATION_CREATE_FOLDER = gql`
  mutation useCreateFolder($input: CreateFolderInput) {
    createFolder(input: $input) {
      folder {
        ...FolderFragment
      }
    }
  }
  ${FolderFragment}
`;

interface CreateFolderResult {
  createFolder: (
    name: string,
    parentId: string,
    cb?: () => void
  ) => Promise<void>;
  loading: boolean;
  error?: ApolloError;
}

export const useCreateFolder = (): CreateFolderResult => {
  const cacheUpdater = useStructureCacheUpdater<t.useCreateFolder>();
  const [mutation, { loading, error }] = useMutation<
    t.useCreateFolder,
    t.useCreateFolderVariables
  >(MUTATION_CREATE_FOLDER);
  const createFolder = async (name, parentId) => {
    await mutation({
      variables: {
        input: {
          name,
          parentId,
        },
      },
      update: cacheUpdater((ff, data) => ({
        files: ff.files,
        folders: data.createFolder?.folder
          ? [...ff.folders, data.createFolder?.folder]
          : ff.folders,
      })),
    });
  };

  return { createFolder, loading, error };
};
