import React, { useState } from 'react';
import { SettingsCard } from 'src/areas/mobile/settings/SettingsCard';
import { t } from 'src/lib/i18n';
import { RoamingAlertMobileSettings } from 'src/lib/types';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { RoamingAlertFormModal } from 'src/areas/mobile/settings/forms/RoamingAlertFormModal';

interface Props {
  roamingAlert: RoamingAlertMobileSettings;
}
export const RoamingAlertSettings: React.FC<Props> = ({ roamingAlert }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const i18n = t.mobile.settings.smsRoaming;
  return (
    <SettingsCard
      title={i18n.smsRoamingAlert}
      onClick={() => setModalOpen(true)}
    >
      <SettingsListItem
        title={i18n.smsRoamingAlertInfo}
        description={
          roamingAlert.smsWhenRoaming
            ? t.mobile.settings.activated
            : t.mobile.settings.notActivated
        }
      />
      <RoamingAlertFormModal
        roaminAlertSettings={roamingAlert}
        open={isModalOpen}
        toggle={() => setModalOpen(!isModalOpen)}
      />
    </SettingsCard>
  );
};
