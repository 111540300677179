import * as React from 'react';
import * as t from '../types';
import { PeriodBy } from 'src/lib/types';
import { WithCustomerContext } from 'src/lib/global';
import { ApolloConsumer } from 'react-apollo';
import ApolloClient from 'apollo-client';

// import { WithLocalState, LocalState } from 'src/lib';
// import { ApolloConsumer } from 'react-apollo';
// import ApolloClient from 'apollo-client';
// import { castArray, find, get } from 'lodash';

// import { WithCustomerContext } from 'src/lib/global';
// import asQueryVariables from './asQueryVariables';
// import { withRouter, RouteComponentProps } from 'react-router';
// import { SearchHistory } from 'src/lib/localState';

// import { throttle } from 'lodash';
// import WithInvoiceLines from './WithInvoiceLinesForOrganisation';
// import { ProductType } from 'src/lib/types/types';

import invoiceLoader from './WithInvoiceLines.invoiceLoader';
import organisationLoader from './WithInvoiceLines.organisationLoader';

export interface PropsForInvoice {
  invoiceId: string;
  productGroup?: t.ProductGroup;
}

export interface PropsForOrganisation {
  organisationId: string;
  recursive: boolean;
  periodStart: string;
  periodEnd: string;
  periodBy: PeriodBy;
  productDesign?: string;
}

export type InvoiceLineLoader<QueryProps> = (
  client: ApolloClient<any>,
  customerId: string,
  props: QueryProps,
  size: number,
  after?: string
) => Promise<{
  cursor?: string;
  size?: number;
  totalResults: number;
  lines: t.InvoiceLine[];
}>;

type Props = {
  skip?: boolean; // Return empty right away if this is true
  size?: number; // Default 10?
  children: (result: {
    loading: boolean;
    totalResults: number | undefined;
    isMore: boolean | undefined;
    loadMore: () => void;
    lines: t.InvoiceLine[];
  }) => React.ReactNode;
} & (PropsForInvoice | PropsForOrganisation);

type InnerProps = Props & {
  client: ApolloClient<any>;
  customerId: string;
};

interface State {
  loading: boolean;
  totalResults: number;
  lines: t.InvoiceLine[];
  noOfRunningSearches: number;
  after?: string;
  // size?: number;
  // filter?: t.Filter[];
  // sortBy?: string;
  // sortOrder?: 'asc' | 'desc';
}

class InnerWithInvoiceLines extends React.Component<InnerProps, State> {
  constructor(props: InnerProps) {
    super(props);
    this.state = {
      noOfRunningSearches: 0,
      loading: false,
      totalResults: 0,
      lines: [],
    };
  }

  private _isMounted: boolean = false;

  componentDidMount() {
    this._isMounted = true;
    this.restart();
  }

  componentWillUnmount() {
    this._isMounted = false;
  }

  restart = () => {
    this.setState(
      {
        noOfRunningSearches: 0,
        loading: false,
        totalResults: 0,
        lines: [],
        after: undefined,
      },
      this.loadMore
    );
  };

  componentDidUpdate(prevProps: InnerProps, prevState: State) {
    if (this.props !== prevProps) {
      this.restart();
    }
  }

  loadMore = async () => {
    if (this.state.loading || this.props.skip) {
      return;
    }
    // Would be nice to cancel any previous searches here for performance and
    // privacy reasons, for now we simply ignore any results from earlier searches.

    if (!this._isMounted) {
      return;
    }

    this.setState(prevState => ({
      noOfRunningSearches: prevState.noOfRunningSearches + 1,
      loading: true,
    }));

    /**
     * TODO: Two separate loaders shoe-horned into the same WithInvoiceLines
     * component. I'd imagine this would be better with two separate query hooks,
     * e.g. useInvoiceLinesForInvoiceQuery and useInvoiceLinesForOrganisationQuery
     */
    const resPromise =
      'invoiceId' in this.props
        ? invoiceLoader(
            this.props.client,
            this.props.customerId,
            this.props,
            this.props.size || 10,
            this.state.after
          )
        : organisationLoader(
            this.props.client,
            this.props.customerId,
            this.props,
            this.props.size || 10,
            this.state.after
          );

    const res = await resPromise;

    if (!this._isMounted) {
      return;
    }

    let cursor: string | undefined;

    if (res.lines.length > 0) {
      cursor = res.cursor;
    }

    this.setState(prevState => {
      const lines = [
        // If 'after' is undefined, this is a new search and we
        // want to discard all the previous results.
        ...(this.state.after ? prevState.lines : []),
        ...res.lines,
      ];
      const totalResults = res.totalResults;

      return {
        loading: prevState.noOfRunningSearches !== 1,
        noOfRunningSearches: prevState.noOfRunningSearches - 1,
        totalResults,
        lines,
        after: cursor,
      };
    });
  };

  render() {
    return this.props.children({
      loading: this.state.loading,
      lines: this.state.lines,
      totalResults: this.state.totalResults,
      loadMore: this.loadMore,
      isMore: this.state.totalResults
        ? this.state.lines.length < this.state.totalResults
        : undefined,
      // filter: this.state.filter,
      // setFilter: this.setFilter,
      // setSingleFilter: this.setSingleFilter,
      // getFilterValue: this.getFilterValue,

      // sortOrder: this.state.sortOrder,
      // sortBy: this.state.sortBy,
      // setSortOrder: this.setSortOrder,
      // setSortBy: this.setSortBy,
      // setSort: this.setSort,
    });
  }
}

export class WithInvoiceLines extends React.Component<Props> {
  render() {
    return (
      <WithCustomerContext>
        {customer => (
          <ApolloConsumer>
            {client => (
              <InnerWithInvoiceLines
                client={client}
                customerId={customer.id}
                {...this.props}
              />
            )}
          </ApolloConsumer>
        )}
      </WithCustomerContext>
    );
  }
}

//       <WithGroupedInvoiceLinesQuery
//         query={query}
//         variables={{
//           ...props,
//           customerId: cContext.id,
//         }}
//       >
//         {res => {
//           if (res.loading) {
//             return props.children({ loading: true });
//           }
//           return props.children({
//             loading: false,
//             lines:
//               res.data &&
//               res.data.customer &&
//               res.data.customer.invoice &&
//               res.data.customer.invoice.lines
//                 ? res.data.customer.invoice.lines.results
//                 : undefined,
//           });
//         }}
//       </WithGroupedInvoiceLinesQuery>

//       return props.productDesign !== undefined ? (
//         <WithInvoiceLineQuery
//           query={WITH_INVOICE_LINE_QUERY}
//           variables={{
//             customerId: context.id,
//             recursive: props.recursive,
//             periodStart: props.periodStart,
//             periodEnd: props.periodEnd,
//             periodBy: props.periodBy,
//             organisationId: props.organisationId,
//             productDesign: props.productDesign,
//           }}
//         >
//           {result => {
//             const res =
//               result.data &&
//               result.data.customer &&
//               result.data.customer.organisation &&
//               result.data.customer.organisation.invoiceLines
//                 ? {
//                     loading: result.loading,
//                     invoiceLines:
//                       result.data.customer.organisation.invoiceLines.results,
//                   }
//                 : { loading: result.loading, invoiceLines: [] };
//             return result.loading
//               ? props.children({ loading: result.loading, invoiceLines: [] })
//               : props.children(res);
//           }}
//         </WithInvoiceLineQuery>
//       ) : (
//         props.children({ loading: false, invoiceLines: [] })
//       );
