import React from 'react';
import { SubscriptionsTableProps } from '../../types';
import { Subscription } from 'src/lib/types';
import { TableBodyRow, TableBodyCell, Table, colors } from 'src/lib/ui';
import {
  TagsTableCell,
  CostCenterTableCell,
  OrganisationTableCell,
  CasesTableCell,
} from '../Components';
import { formatInstallationAddress, generateUeid } from 'src/lib/utils';
import SubscriptionStatusIcon from '../../lib/SubscriptionStatusIcon/SubscriptionStatusIcon';
import { getBasicSubscriptionTitles, getLabelFromCpeStatus } from '../../utils';
import { DataColumns } from 'src/lib/table';

export const DataSubscriptionsTable = (props: SubscriptionsTableProps) => {
  const {
    loading,
    results,
    totalResults,
    subscriptionListParams: sp,
    columns,
    selectProps,
  } = props;
  return (
    <Table
      columns={columns}
      loading={loading}
      sortable={true}
      setSort={sp.setSort}
      sortBy={sp.sortBy}
      sortOrder={sp.sortOrder}
      noResult={totalResults === 0}
      noResultMessage={sp.noResultsMessage}
      rowsCount={sp.pageSize}
      availableColumns={props.availableColumns}
      onChangeColumns={sp.setColumns}
      activeSort={{ key: sp.sortBy, order: sp.sortOrder }}
      {...(selectProps ? { selectable: true, ...selectProps } : {})}
    >
      {results.map(row => {
        const subsData = row as Subscription;
        const redundancies = subsData.redundancies;
        return (
          <React.Fragment key={subsData.id}>
            <TableBodyRow
              onClick={() => {
                sp.routeToSubscription(subsData.id);
              }}
              {...(selectProps ? { selectable: true } : {})}
              selected={selectProps?.selected.includes(subsData.id)}
              onSelectRow={() => selectProps?.onSelect(subsData.id)}
              connectedToNextRow={Boolean(redundancies?.length)}
            >
              {props.columns.map(c => (
                <DataTableBodyCell
                  col={c.key as DataColumns}
                  subsData={subsData}
                  key={generateUeid()}
                />
              ))}
            </TableBodyRow>

            {redundancies?.length
              ? redundancies.map((r, idx) => {
                  return (
                    <TableBodyRow
                      key={r.id}
                      onClick={() => {
                        sp.routeToSubscription(r.id);
                      }}
                      connectedToNextRow={Boolean(
                        idx < redundancies.length - 1
                      )}
                      {...(selectProps ? { selectable: true } : {})}
                      selected={selectProps?.selected.includes(r.id)}
                      onSelectRow={() => selectProps?.onSelect(r.id)}
                    >
                      {props.columns.map(c => (
                        <DataTableBodyCell
                          col={c.key as DataColumns}
                          subsData={r}
                          key={generateUeid()}
                        />
                      ))}
                    </TableBodyRow>
                  );
                })
              : null}
          </React.Fragment>
        );
      })}
    </Table>
  );
};

const DataTableBodyCell = (props: {
  col: DataColumns;
  subsData: Subscription;
}) => {
  const { col, subsData } = props;
  const cpeStatus = subsData.cpe && subsData.cpe.status;
  const installationAddress = subsData.installationAddress
    ? formatInstallationAddress(subsData.installationAddress, 'fullAddress')
    : '';
  const { title, subtitle } = getBasicSubscriptionTitles(props.subsData);

  switch (col) {
    case 'status':
      return (
        <TableBodyCell>
          <div className="d-flex align-items-center">
            <SubscriptionStatusIcon
              className="mr-2"
              cpeStatus={cpeStatus}
              hasStatus={!!subsData.isMonitoringPossible}
            />
            {getLabelFromCpeStatus(cpeStatus, !!subsData.isMonitoringPossible)}
          </div>
        </TableBodyCell>
      );
    case 'address':
      return (
        <TableBodyCell title={installationAddress}>
          {installationAddress}
        </TableBodyCell>
      );

    case 'name':
      return (
        <TableBodyCell title={subsData.name ?? undefined}>
          <div>{title}</div>
          <div style={{ color: colors.greyDarkText }}>{subtitle}</div>
        </TableBodyCell>
      );
    case 'cases':
      return <CasesTableCell subscription={subsData} />;
    case 'cpe':
      return (
        <TableBodyCell>
          <div>{subsData.cpe?.model}</div>
        </TableBodyCell>
      );
    case 'invoiceCenter':
      return <OrganisationTableCell subscription={subsData} />;
    case 'costCenter':
      return <CostCenterTableCell subscription={subsData} />;

    case 'tags':
      return <TagsTableCell subscription={subsData} />;
    default:
      return null;
  }
};
