import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from './__types/useSubHasStatistics';

const SUB_HAS_STATISTICS = gql`
  query useSubHasStatistics($customerId: ID!, $subscriptionId: [ID!]!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: $subscriptionId) {
        id
        hasExtendedStatistics
        hasQosStatistics
        hasGsmStatistics
      }
    }
  }
`;

export const useSubHasStatistics = (subscriptionId: string) => {
  const customer = useCustomer();
  return useQuery<t.useSubHasStatistics, t.useSubHasStatisticsVariables>(
    SUB_HAS_STATISTICS,
    {
      variables: {
        customerId: customer.id,
        subscriptionId: [subscriptionId],
      },
    }
  );
};
