import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { Organisation } from 'src/lib/types';

export const SearchMenuOrganisationResult: React.FC<SearchMenuResultProps<
  Organisation
>> = props => (
  <SearchMenuResultItem
    {...props}
    title={props.entry.name ?? `[${props.entry.id}]`}
    summary={props.entry.id}
    icon="company"
  />
);
