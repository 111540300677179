import { useState } from 'react';
import { useMutation } from 'react-apollo';
import * as t from './__types/useRemoveTagFromSubscription';
import gql from 'graphql-tag';

export const MUTATION_REMOVE_TAG_ON_SUBSCRIPTION = gql`
  mutation useRemoveTagFromSubscription(
    $input: RemoveTagFromSubscriptionInput
  ) {
    removeTagFromSubscription(input: $input) {
      error {
        code
      }
      tags {
        id
        tag
      }
    }
  }
`;

export const useRemoveTagFromSubscription = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const [mutation, mutationRes] = useMutation<
    t.useRemoveTagFromSubscription,
    t.useRemoveTagFromSubscriptionVariables
  >(MUTATION_REMOVE_TAG_ON_SUBSCRIPTION);

  const removeTagsFromSubscription = async (
    subscriptionId: string,
    tags: string[]
  ) => {
    const mutations = tags.map(tag => {
      return mutation({
        variables: {
          input: {
            subscriptionId,
            tag,
          },
        },
      });
    });
    setLoading(true);
    await Promise.all(mutations);
    setLoading(false);
  };
  return {
    removeTagsFromSubscription,
    loading: loading,
    error: mutationRes.error,
  };
};
