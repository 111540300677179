import * as React from 'react';
import {
  Table,
  TableBodyRow,
  TableBodyCell,
  DownloadLink,
  LinkTo,
  Feature,
} from 'src/lib/ui';
import { t, useI18nContext } from 'src/lib/i18n';
import { Invoice } from 'src/lib/types';
import InvoiceStatusBadge from './InvoiceStatusBadge';
import { InvoiceDownloadDropdown } from './InvoiceDownloadDropdown';
import { formatDate } from 'src/lib/utils/dateUtils';
import { formatNordic } from 'src/lib/utils';
import { useDomainColumns, InvoiceColumns } from 'src/lib/table';

import { SortOrder, SearchDomain } from 'src/__types/graphql-global-types';
interface InvoicesTableProps {
  invoices: Invoice[];
  setSort: (key: string, order: SortOrder) => void;
  loading: boolean;
  noResult: boolean;
  noResultMessage: string;
  sortBy?: string;
  sortOrder?: SortOrder;
  setColumns?: (cols: string[]) => void;
  savedColumnPreferences?: string[];
}

export const InvoicesTable: React.FunctionComponent<InvoicesTableProps> = props => {
  const { columns, availableColumns } = useDomainColumns(
    SearchDomain.invoice,
    props.savedColumnPreferences ?? []
  );
  return (
    <Table
      columns={columns}
      loading={props.loading}
      noResult={props.noResult}
      noResultMessage={props.noResultMessage}
      setSort={props.setSort}
      sortable={true}
      sortBy={props.sortBy}
      sortOrder={props.sortOrder}
      availableColumns={availableColumns}
      onChangeColumns={props.setColumns}
      activeSort={{
        key: props.sortBy ?? '',
        order: props.sortOrder ?? SortOrder.asc,
      }}
    >
      {props.invoices.map(invoice => {
        return (
          <TableBodyRow key={invoice.id}>
            {columns.map((col, i) => (
              <InvoiceTableCell
                key={`${col.key}-${i}`}
                column={col.key as InvoiceColumns}
                invoice={invoice}
              />
            ))}
          </TableBodyRow>
        );
      })}
    </Table>
  );
};

const InvoiceTableCell = (props: {
  column: InvoiceColumns;
  invoice: Invoice;
}) => {
  const translate = t.invoices;
  const { column, invoice } = props;
  const i18n = useI18nContext();

  switch (column) {
    case 'status':
      return (
        <TableBodyCell>
          <InvoiceStatusBadge
            status={invoice.status}
            label={translate.status[invoice.status].label}
          />
        </TableBodyCell>
      );
    case 'id':
      return (
        <TableBodyCell>
          <DownloadLink
            href={`/api/export/invoice/${encodeURIComponent(invoice.id)}.pdf`}
            renderAs="link"
          >
            {invoice.id}
          </DownloadLink>
        </TableBodyCell>
      );
    case 'address':
      return (
        <TableBodyCell title={invoice.organisation?.name}>
          {invoice.organisation ? (
            <Feature
              feature="main-menu-cost"
              fallback={invoice.organisation.name}
            >
              <LinkTo organisation={invoice.organisation.id}>
                {invoice.organisation.name}
              </LinkTo>
            </Feature>
          ) : null}
        </TableBodyCell>
      );
    case 'invoiceDate':
      return (
        <TableBodyCell>
          {formatDate(invoice.date, 'medium', i18n.locale)}
        </TableBodyCell>
      );
    case 'dueDate':
      return (
        <TableBodyCell>
          {formatDate(invoice.dueDate, 'medium', i18n.locale)}
        </TableBodyCell>
      );
    case 'sumExVat':
      return (
        <TableBodyCell>kr {formatNordic(invoice.amountNet, 2)}</TableBodyCell>
      );
    case 'actions':
      return (
        <TableBodyCell ellipsis={false}>
          <InvoiceDownloadDropdown invoiceId={invoice.id} />
        </TableBodyCell>
      );

    default:
      return null;
  }
};
