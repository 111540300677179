import * as React from 'react';
import cx from 'classnames';
import './StepCircle.scss';

interface StepCircleProps {
  completed?: boolean;
  active?: boolean;
  className?: string;
  role?: string;
  onClick?: () => void;
  step: number;
}

export const StepCircle: React.FunctionComponent<StepCircleProps> = props => {
  return (
    <div
      className={cx('StepCircle', props.className, {
        completed: props.completed,
        active: props.active,
      })}
      role={props.role}
      onClick={props.onClick}
    >
      {props.step}
    </div>
  );
};
