import React from 'react';
import { Loading, RequireRole } from 'src/lib/ui';
import { useMobileSettings } from 'src/areas/mobile/settings/useMobileSettings';
import OriginSection from 'src/areas/mobile/settings/OriginSection';
import { DirectorySettings } from 'src/areas/mobile/settings/DirectorySettings';
import { CallForwardingSettings } from 'src/areas/mobile/settings/CallForwardingSettings';
import { RoamingAlertSettings } from 'src/areas/mobile/settings/RoamingAlertSettings';
import { VoicemailSetting } from 'src/areas/mobile/settings/VoicemailSettings';
import { VisualVoicemailSetting } from 'src/areas/mobile/settings/VisualVoicemailSetting';
import { BarringSettings } from 'src/areas/mobile/settings/BarringSettings';
import { t } from 'src/lib/i18n';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { CallerIdSettings } from 'src/areas/mobile/settings/CallerIdSettings';
import { useAllCallerIdsForSubscription } from 'src/areas/main/subscriptions/queries/useAllCallerIdsForSubscription';

export const Settings = () => {
  const { settings, loading, error } = useMobileSettings();
  const subscriptionContext = useSubscriptionContext();
  const allCallerIds = useAllCallerIdsForSubscription(subscriptionContext?.id);
  if (loading) {
    return <Loading />;
  }
  if (error || !settings) {
    return <span>{t.mobile.settings.noSettings}</span>;
  }

  const { voicemail, callForwarding, roamingAlert, barring } = settings;

  return (
    <>
      <OriginSection />
      <DirectorySettings />
      {callForwarding ? (
        <CallForwardingSettings callForwarding={callForwarding} />
      ) : null}
      {roamingAlert ? (
        <RoamingAlertSettings roamingAlert={roamingAlert} />
      ) : null}
      {voicemail ? <VoicemailSetting voicemailSettings={voicemail} /> : null}
      {voicemail ? (
        <VisualVoicemailSetting voicemailSettings={voicemail} />
      ) : null}
      {barring ? (
        <RequireRole
          any={['CustomerAdministrator', 'OrderManager', 'SystemAdministrator']}
        >
          <BarringSettings barring={barring} />
        </RequireRole>
      ) : null}
      {allCallerIds?.allCallerIds?.length > 0 ? (
        <RequireRole
          any={['CustomerAdministrator', 'OrderManager', 'SystemAdministrator']}
        >
          <CallerIdSettings msisdn={subscriptionContext.id} />
        </RequireRole>
      ) : null}
    </>
  );
};
