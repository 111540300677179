import React from 'react';

import { SubscriptionGroupInUrl } from 'src/lib/ui/LinkTo/urlFor';

import { useBreakpoint } from 'src/lib/utils/useBreakpoint';
import { usePersistFiltersInSession } from './lib/usePersistFiltersInSession';
import { SubscriptionsProps } from 'src/areas/main/subscriptions/types';
import DataSubscriptions from 'src/areas/main/subscriptions/Pages/Data/DataSubscriptions';
import MobileSubscriptions from 'src/areas/main/subscriptions/Pages/Mobile/MobileSubscriptions';
import TeleSubscriptions from 'src/areas/main/subscriptions/Pages/Telephony/TeleSubscriptions';
import TvSubscriptions from 'src/areas/main/subscriptions/Pages/Tv/TvSubscriptions';
import GenericSubscriptions from 'src/areas/main/subscriptions/Pages/Generic/GenericSubscriptions';

type Props = {
  subscriptionGroup: SubscriptionGroupInUrl;
};

/**
 * Subscription group
 */
const subscriptionGroups: {
  [key in SubscriptionGroupInUrl]: (props: SubscriptionsProps) => JSX.Element;
} = {
  nettverk: DataSubscriptions,
  mobil: MobileSubscriptions,
  telefoni: TeleSubscriptions,
  tv: TvSubscriptions,
  alle: GenericSubscriptions,
};

export function ListSubscriptionsByGroup(props: Props) {
  const breakpointMd = useBreakpoint('md');
  usePersistFiltersInSession({ key: props.subscriptionGroup });

  const Subscriptions = subscriptionGroups[props.subscriptionGroup];

  return <Subscriptions mobileBreakpoint={breakpointMd} />;
}
