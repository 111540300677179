import { customerSupportNumber, customerSupportEmail } from './../../variables';
const settings = {
  noSettings: 'Ingen innstillinger',
  activated: 'Aktivert',
  notActivated: 'Ikke aktivert',
  edit: 'Endre',

  name: 'Navn',
  email: 'Email',
  firstName: 'Fornavn',
  middleName: 'Mellomnavn',
  lastName: 'Etternavn',
  address: 'Adresse',
  streetNum: 'Nummer',
  postCode: 'Postnr.',
  postArea: 'Poststed',
  country: 'Land',
  attention: 'Att.',
  employeeNum: 'Ansattnummer',
  employeePosition: 'Stilling',
  changeAddress:
    'Du kan endre adressen ved å gå til opprinnelsesmarkering under innstillinger',

  forwarding: {
    forwardedToVoicemail: 'Viderekobles til personsvar',
    required: 'Skriv inn nummeret samtalen skal viderekobles til',
    invalidNumber: 'Ugyldig telefonnummer',
    forwarding: 'Viderekobling',
    forwardingInfo:
      'Når noen ringer deg og du ikke har mulighet til å svare kan samtalen viderekobles til et annet nummer som du oppgir her. ',
    forwardAll: 'Viderekoble alle samtaler',
    forwardAllInfo:
      'Når noen ringer deg, vil de automatisk viderekobles til nummeret du fyller inn under. Dersom du ikke oppgir et nummer vil samtalene bli sent til personsvar',
    forwardBusy: 'Viderekoble når du er opptatt',
    forwardBusyInfo:
      'Når noen ringer deg og du allerede prater i telefonen, vil de automatisk viderekobles til nummeret du fyller inn under.',
    forwardNoAnswer: 'Viderekoble når du ikke svarer',
    forwardNoAnswerInfo:
      'Når noen ringer deg og du ikke tar telefonen, vil de automatisk viderekobles til nummeret du fyller inn under.',
    phoneNumberLabel: 'Telefonnummer',
    callRecipient: phoneNumber => `Sett over til ${phoneNumber}`,
  },

  smsRoaming: {
    smsRoamingAlert: 'SMS om roamingpriser',
    smsRoamingAlertInfo: `Få prisopplysninger på SMS når telefonen benyttes i utlandet`,
  },

  phonebook: {
    service: 'Opplysningstjenester',
    reserveFromDirectory: `Reserver fra opplysningstjenester`,
    showCompanyInformation: 'Vis kun firma',
    phonebookInfo:
      'Opplysningstjenester er oppslagskataloger der andre kan finne kontaktinformasjon, f.eks. GuleSider, 1880, 1881.',
  },

  origin: {
    title: 'Opprinnelsesmarkering',
    description:
      'Når du ringer et av nødnumrene blir telefonnummeret ditt sjekket mot teleselskapenes nasjonale database. Navn og adresse som du har oppgitt her overføres så til nødsentralen, slik at de raskere kan finne deg.',
  },

  profileUpdate: {
    title: 'Endre profil',
  },

  voicemail: {
    voicemail: 'Telefonsvar',
    notificationOnSMS: 'Motta varsling på SMS',
    sendSmsOnMessage:
      'Når denne funksjonen er slått på vil det sendes en automatisk melding til ditt telefonnummer når du mottar en ny talemelding.',
    voicemailInfo:
      'Når du ikke kan ta telefonen, kan de som ringer legge igjen en talemelding til deg. Her kan du velge om du vil varsles om en ny melding.',
  },

  visualVoicemail: {
    visualVoicemailForiPhone: 'Visual Voicemail for iPhone',
    VisualVoicemail: 'Visual Voicemail',
    visualVoicemailInfo:
      'Visual Voicemail samler personsvarmeldinger på telefonen din. Visual Voicemail støttes kun av iPhone-telefoner (4S og nyere). Dersom du har en annen telefonmodell vil ikke denne innstillingen gjøre noe.',
  },

  barring: {
    usageRestrictions: 'Forbruksbegrensninger',
    restrictUsage: 'Sett begrensninger for forbruk',
    description:
      'Du kan begrense forbruket til abonnenten ved å sperre enkelte tjenester og forbruk',
    barData: {
      barData: 'Datatrafikk',
      barAllData: 'Sperr all datatrafikk',
      barRoamingData: 'Sperr datatrafikk ved roaming',
    },
    barCalls: {
      outgoing: 'Utgående samtaler og SMS',
      barCallsMsgOutg: 'Sperr alle utgående samtaler og meldinger',
      barCallsOutg: 'Sperr alle utgående samtaler',
      barCallsAbroad: 'Sperr alle utgående samtaler i utlandet',
      barCallsAbroadExcHome:
        'Sperr alle samtaler i utlandet unntatt til hjemlandet',
      barOutgCallsAbroad: 'Sperr utgående samtaler i utlandet',

      incoming: 'Innkommende samtaler og SMS',
      barCallsMsgIncom: 'Sperr alle innkommende samtaler og meldinger',
      barIncomCallsAbroad: 'Sperr alle innkommende samtaler i utlandet',
    },
    barContentServices: {
      description: 'Gjelder kjøp av tjenester som belaster telefonregningen',
      contentServices: 'Innholdstjenester',
      cpa: 'Sperr alle innholdstjenester',
      cpa100: 'Sperr innholdstjenester etter kr 100,00',
      cpa250: 'Sperr innholdstjenester etter kr 250,00',
      cpa500: 'Sperr innholdstjenester etter kr 500,00',
      cpaGoodsAndServices:
        'Sperr kjøp av varer (Eks. brusautomater, billetter)',
    },
    barPaidServices: {
      description:
        'Gjelder samtaler til teletorgtjenester som belaster telefonregningen',
      paidServices: 'Betalings- og teletorgtjenester',
      allowPaidServices: 'Tillat innholdstjenester',
      barPaidServices: 'Sperr all teletorgtjenester',
      bar820: 'Sperr teletorgtjenester fra 820-numre',
      bar829: 'Sperr teletorgtjenester fra 829-numre',
      barDonation: 'Sperr doneringsnumre',
      barPhonebook: 'Sperr nummeropplysningstjenester (som 1881, 1880 mm.)',
    },
    errorMsg: 'Noe gikk galt! Vi klarte ikke lagre endringene dine',
  },

  modalFields: {
    unableToSave: 'Vi klarte ikke å lagre innstillingene dine.',
    retry: 'Prøv på nytt',
    ohDear: 'Uff.',
    errorPersists: `Hvis feilen vedvarer kan du kontakte kundeservice på e-post: ${customerSupportEmail}, eller ved å ringe tlf. ${customerSupportNumber}`,
    savingSettings: 'Vi lagrer innstillingene dine...',
    enterNumber: 'Fyll inn telefonnummer eks. 92405050',
    recipentNum: 'Telefonnummer til mottaker',
    exampleNum: 'Eks. 92405050',
  },

  callerId: {
    title: 'Ring med et annet nummer',
    description:
      'Abonnenten kan selv velge hvilke nummer som skal vises hos mottaker ved oppringing.',
    allowedCallerIds: 'Tillatte visningsnumre',
    ownNumberOnly: 'Kun abonnentens eget nummer',
    modal: {
      header: 'Velg visningsnummer',
      description:
        'Abonnenten kan selv velge hvilke nummer som skal vises hos mottaker ved utgående anrop. Her kan du velge hvilke telefonnumre abonnenten skal kunne ta i bruk.',
      filterPlaceholder: 'Søk etter telefonnummer',
      selectedItemsSubHeader: 'Tillatte visningsnumre: ',
      availableItemsSubHeader: 'Tilgjengelige telefonnumre: ',
    },
  },
};

export default settings;
