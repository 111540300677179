import * as React from 'react';
import cs from 'classnames';
import './ListResultCardSkeleton.scss';

interface ListResultCardSkeletonProps {
  /**
   * The main icon for the search result.
   */
  icon?: boolean;

  /**
   * An optional description to explain icon
   */
  iconDescription?: boolean;

  /**
   * What element the card will be rendered with. Default is `li`.
   */
  renderAs?: 'li' | 'div';

  /**
   * What element will be used to render the card title. Default is `h2`.
   */
  renderTitleAs?: 'h2' | 'h3' | 'h1';

  className?: string;
}

/**
 * A copy of ListResultCard without all the props and logic, but with the markup and css-classes.
 * Some props are still needed to mimic the layout of the ListResultCard.
 * Used to show a skeleton of the ListResultCard when loading data.
 */
export const ListResultCardSkeleton: React.FunctionComponent<ListResultCardSkeletonProps> = props => {
  const Element = props.renderAs || 'li';
  const TitleElement = props.renderTitleAs || 'h2';
  return (
    <Element className={cs('ListResultCard skeleton', props.className)}>
      <div className="ListResultCard-primary d-flex flex-row align-items-center">
        {props.icon ? (
          <div
            className={cs(
              'ListResultCard-icon justify-self-baseline px-4',
              props.iconDescription ? 'ListResultCard-icon-wide' : undefined
            )}
          >
            <div className="ListResultCard-icon-skeleton">&nbsp;</div>
            {props.iconDescription ? (
              <span className="ListResultCard-icon-description">
                <div>&nbsp;</div>
              </span>
            ) : null}
          </div>
        ) : null}

        <div className="ListResultCard-main pl-0">
          <div className="d-sm-flex flex-row align-items-center mb-1">
            <TitleElement className="ListResultCard-title mr-sm-2 mb-2 my-sm-0">
              <div>&nbsp;</div>
            </TitleElement>
          </div>
          <div className="ListResultCard-attributes">
            <div>&nbsp;</div>
          </div>
        </div>
      </div>
    </Element>
  );
};
