import * as React from 'react';
import cs from 'classnames';
import { ExpandButton } from 'src/lib/ui';

import './OrganisationTreeNavigator.scss';
import { Link } from 'react-router-dom';

import './OrganisationTreeNavigatorItem.scss';
import { Icon } from '@telia/styleguide';

export interface Props {
  /**
   * What level in the hierarchy this item represents. Level 0 is root.
   */
  level?: number;
  label: string;
  onToggleSelect?: () => void;
  onToggleExpand?: () => void;
  expandable?: boolean;
  expanded?: boolean;
  linkTo?: string;
  selectable?: boolean;
  selected?: boolean;
  disabled?: boolean;
  // hasSelectedChild?
}

export const OrganisationTreeNavigatorItem: React.FC<Props> = props => (
  <div
    className={cs(
      'OrganisationTreeNavigatorItem',
      props.expanded ? 'expanded' : undefined,
      props.selected ? 'selected' : undefined
    )}
  >
    <div className="OrganisationTreeNavigatorItem-expander">
      {props.expandable ? (
        <ExpandButton
          rounded={true}
          toggle={props.onToggleExpand}
          active={props.expanded}
        />
      ) : null}
    </div>
    <div
      className="OrganisationTreeNavigatorItem-body"
      onClick={props.onToggleSelect}
    >
      {props.linkTo ? (
        <Link to={props.linkTo}>{props.label}</Link>
      ) : (
        props.label
      )}
    </div>

    <div className="OrganisationTreeNavigatorItem-selection ml-auto pr-4">
      {props.selected ? <Icon icon="check-mark" /> : null}
    </div>
  </div>
);
