import React from 'react';
import { SearchResult } from 'src/areas/main/search';
import { NoResult, List } from 'src/lib/ui';
import { SearchListSubscriptionResult } from 'src/areas/main/search/components/list/SearchListSubscriptionResult';
import { Subscription } from 'src/lib/types';
import { useDelayedRender } from 'src/lib/utils/useDelayedRender';

interface Props {
  loading: boolean;
  results: SearchResult[];
  noResultsMessage: string;
  onSelectRow?: (id: string) => void;
  selected?: string[];
}

export const SubscriptionsList = (props: Props) => {
  const delayedRender = useDelayedRender(500, props.loading);

  return (
    <List border={true} container={false} loading={delayedRender}>
      {props.results.length === 0 && !props.loading ? (
        <div style={{ width: '100%', height: '50vh' }}>
          <NoResult text={props.noResultsMessage} />
        </div>
      ) : (
        props.results.map(sub => {
          const subscription = sub as Subscription;
          const onSelectRow = props.onSelectRow
            ? () => props.onSelectRow?.(subscription.id)
            : undefined;
          return (
            <SearchListSubscriptionResult
              key={subscription.id}
              entry={subscription}
              onSelect={onSelectRow}
              selected={props.selected?.includes(subscription.id)}
            />
          );
        })
      )}
    </List>
  );
};
