import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { t } from 'src/lib/i18n';
import useGetTagOptions from 'src/areas/main/subscriptions/utils/useGetTagOptions';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
const NO_TAGS_FILTER_VALUE = 'none';

export const useTagsFilter = (props?: { disableNoTagsFilter?: boolean }) => {
  const query = useQueryParameters();
  const selectedOptions = query.getArray('tag');
  let { result, loading } = useGetTagOptions('');
  const translate = t.subscriptions;

  // Add hardcoded filter option for subscriptions without tags
  result = [
    ...(!props?.disableNoTagsFilter
      ? [
          {
            label: translate.filters.withNoTags,
            value: NO_TAGS_FILTER_VALUE,
            negative: true,
          },
        ]
      : []),
    ...result,
  ];
  return {
    id: 'tags',
    label: translate.ListSubscriptionsByGroup.filters.tags.label,
    options: result,
    allowMultiple: true,
    allowSearch: true,
    selectedOptions: selectedOptions,
    loading: loading,
    handleSave: values => {
      setFilterAndResetPage(query, 'tag', values ? values : undefined);
    },
  };
};
