import * as React from 'react';
import cs from 'classnames';

import './Badge.scss';
import { StatusColor } from 'src/lib/types';
import { Icon, IconDefinition } from '@telia/styleguide';

export interface BadgeProps {
  icon?: IconDefinition;
  iconColor?: StatusColor;
  children: string | React.ReactNode;
  style?: React.CSSProperties;
  className?: string;
}

export const Badge: React.FC<BadgeProps> = props => {
  return (
    <div className={cs('Badge', props.className)} style={props.style}>
      <span className="Badge-content">
        {props.icon ? (
          <Icon
            className={cs(
              'mr-1',
              props.iconColor ? `text-${props.iconColor}` : undefined
            )}
            icon={props.icon}
          />
        ) : null}
        {props.children}
      </span>
    </div>
  );
};

export const Badges: React.FC<{ inline?: boolean }> = props => (
  <div className={cs('Badges', { 'Badges--inline': props.inline })}>
    {props.children}
  </div>
);
