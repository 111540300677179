import Nb from '../../nb/Main/costs';

const costs: typeof Nb = {
  ui: {
    CostSummaryTable: {
      category: 'Category',
      consumption: 'Consumption',
      fixedCosts: 'Fixed costs',
      oneTimeFee: 'One-time fee',
      VAT: 'Sum incl. VAT',
      noVAT: 'Sum excl. VAT',
      total: 'Total',
    },
    PeriodSelector: {
      invoice: 'Invoiced expenses',
      usage: 'Current expenses',
    },
    InvoiceTable: {
      status: 'Status',
      invoiceNumber: 'Invoice number',
      invoiceDate: 'Invoice date',
      dueDate: 'Due date',
      net: 'Cost excl. VAT',
      gross: 'Total cost',
      ContextMenu: {
        showInvoice: 'Show invoice',
        dlPdf: 'Download PDF',
        dlCsv: 'Download CSV',
        dlExcel: 'Download Excel',
      },
      noInvoices: 'No invoices',
    },
    OrganisationCostSummary: {
      downloadBtn: 'Download',
      detailsBtn: 'See details',
      circleChartLabel: 'Total usage in kroner',
    },
    OrganisationCostSummaryTable: {
      category: 'Category',
      usage: 'Usage',
      fixedCost: 'Fixed costs',
      oneTimeFee: 'One time fee',
      noVat: 'excl. VAT',
      vat: 'incl. VAT',
    },
    MobileCostSummary: {
      header: (periodBy, month, year) =>
        `Mobile costs beyond package ${
          periodBy === 'usage' ? 'currently' : 'billed'
        } in ${month} ${year}`,
      circleChartLabel: 'Total usage in kroner',
      downloadBtn: 'Download',
    },
  },
  ChooseOrganisationOrCostCenter: {
    PageHeader: {
      title: 'Costs',
      navTabs: {
        organisation: 'Invoice centers',
        costCenter: 'Cost centers',
      },
    },
    costCenterTable: {
      id: 'ID',
      description: 'Description',
      LoadMoreBtn: 'Show more cost centers',
    },
  },
  OrganisationPage: {
    Pageheader: {
      actions: {
        chooseOrg: 'Choose invoice center',
        chooseCostCenter: 'Choose cost center',
      },
      navTabs: {
        summary: 'Summary',
        details: 'Details',
        invoice: 'Invoice',
      },
      breadcrumbs: {
        cost: 'Cost',
      },
    },
    ListHeader: {
      toggleBtn: (count: number) => `Include ${count} subunits`,
    },
  },
  categoryDefinitions: {
    mobile: 'Mobile',
    data: 'Data',
    tele: 'Phones',
    other: 'Other',
  },
  OrganisationDetails: {
    CostTable: {
      costGroup: 'Cost group',
      invoiceNumber: 'Invoice number',
      period: 'Period',
      noVAT: 'Cost excl. VAT',
      VAT: 'Cost incl. VAT.',
      totalLabel: 'Total cost',
    },
    filters: {
      data: 'Data',
      mobile: 'Mobile',
      tele: 'Phones',
      other: 'Other',
    },
  },
  OrganisationInvoices: {
    filters: {
      overdue: 'Overdue',
      unpaid: 'Unpaid',
      paid: 'Paid',
    },
    showMoreBtn: 'Show more invoices',
  },
  CostCenterPage: {
    PageHeader: {
      actions: {
        chooseOrg: 'Choose invoice center',
        chooseCostCenter: 'Choose another cost center',
      },
      navTabs: {
        summary: 'Summary',
      },
    },
  },
  InvoiceDetails: {
    noInvoice: 'Invoice not found',
    backBtn: 'Back',
    header: 'Invoice',
    dueDate: 'Due date',
    invoicePeriod: 'Billing period',
    invoiceDate: 'Invoice date',
    reference: 'Reference',
    unknown: 'unknown',
    sentAs: 'Sent as',
    sentAsEhf: 'Sent as EHF',
    sentAsPdf: 'Sent to e-mail (PDF)',
    sentAsMail: 'Sent as mail',
    invoicePart: 'Invoice part',
    objectionString: 'Do you have any objections to the invoice?',
    contact: 'Contact us at',
    returnAdr: 'Return address',
    invoiceRecipient: 'Invoice recipient',
    adr: 'Address',
    email: 'E-mail address',
    noEmail: 'No e-mail address registered',
    billCycle: 'Billing cycle',
    noReg: 'Not registered',
    invoiceSummary: 'Invoice summary',
    paymentTerms: 'Payment terms',
    noPaymentText:
      'In case of late payment, the current interest rate will be charged as of today. Intrum Justitia AS handles the payment follow-up on behalf of Telia Norge AS.',
    exclVat: 'Net cost',
    inclVat: 'Cost incl. VAT',
  },
  InvoiceLineGroup: {
    download: 'Download',
    totalInclVat: 'Total incl. VAT',
    showInvoiceLines: (lines, total) =>
      `Showing ${lines} of ${total} invoice lines`,
    showMore: 'Show more invoice lines',
    error: 'Could not load costs',
    table: {
      subscription: 'subscription',
      period: 'period',
      netCost: 'net cost',
      costInclVat: 'cost incl. VAT',
    },
  },
  CostCenterModal: {
    createBtn: 'Create a new cost center',
    header: 'Create a new cost center',
    id: 'ID',
    idError: 'ID is mandatory',
    description: 'description',
    cancelBtn: 'Cancel',
    confirmBtn: 'Create',
  },
};

export default costs;
