import * as React from 'react';
import { Switch, Route, RouteComponentProps, Redirect } from 'react-router';

import { Period } from 'src/lib/types';
import { WithCustomerContext } from 'src/lib/global';

import { PeriodBy } from 'src/lib/types';
import { last } from 'lodash';
import { Loading } from 'src/lib/ui';

import {
  WithCostCenters,
  WithCustomerOrganisations,
  organiseOrganisations,
} from './lib';

import {
  WithUserPreferences,
  UserPreferencesContext,
} from 'src/lib/userPreferences/UserPreferencesContext';
import { CostContext, CostContextProvider } from './lib/CostContext';
import { CustomerOrganisationsData } from './lib/queries/WithCustomerOrganisations';
import { getDefaultPeriod } from 'src/lib/utils/dateUtils';
import { CostCenter } from 'src/lib/types';

import { ChooseOrganisationOrCostCenter } from './ChooseOrganisationOrCostCenter';
import { InvoiceDetails } from './InvoiceDetails';
import { OrganisationSummary } from './OrganisationSummary';
import { OrganisationDetails } from './OrganisationDetails';
import { OrganisationInvoices } from './OrganisationInvoices';
import { CostCenterSummary } from './CostCenterSummary';

/**
 * State for whole Cost area -- will be available throughout the Cost area,
 * but will be reset once the user leaves the area.
 */
interface State {
  activePeriod: Period | undefined;
  recursive: boolean;
  periodBy: PeriodBy;
}

interface Props {
  areaRootUrl: string;
  userPreferences: UserPreferencesContext;
  costCenters: CostCenter[];
  customerOrganisations: CustomerOrganisationsData;
}

class CostArea extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      recursive: this.props.userPreferences.showRecursiveCost ? true : false,
      activePeriod:
        last(props.customerOrganisations.costPeriods.usagePeriods) ||
        getDefaultPeriod(),
      periodBy: 'invoice',
    };
  }

  componentDidUpdate(prevProps, prevState) {
    /**
     * If the customer organisations have been loaded or reset,
     * find the correct usage period again.
     */
    if (prevProps.customerOrganisations !== this.props.customerOrganisations) {
      const period = last(
        this.props.customerOrganisations.costPeriods.usagePeriods
      );
      if (period) {
        this.setState({
          activePeriod: period,
        });
      }
    }
    /**
     * Store the recursive preference if it's changed
     */
    if (prevState.recursive !== this.state.recursive) {
      this.props.userPreferences.setRecursiveCostPreference(
        this.state.recursive
      );
    }
  }

  setActivePeriod = (activePeriod: Period | undefined) => {
    this.setState({ activePeriod });
  };

  setRecursive = (recursive: boolean) => {
    this.setState({ recursive });
  };

  setPeriodBy = (periodBy: PeriodBy) => {
    this.setState({ periodBy });
  };

  render() {
    return (
      <WithCustomerContext>
        {customerContext => {
          const orgMap = organiseOrganisations(
            this.props.customerOrganisations.allOrganisations
          );

          const getOrganisationById = (id: string | undefined) => {
            if (!id) {
              return undefined;
            }
            return orgMap.organisationById[id];
          };

          const getCostCenterById = (id: string | undefined) => {
            if (
              !id ||
              !this.props.costCenters ||
              this.props.costCenters.length === 0
            ) {
              return undefined;
            }
            return this.props.costCenters.find(c => c.id === id);
          };

          const getChildrenById = (id: string | undefined) => {
            const org = getOrganisationById(id);
            if (!org) {
              return undefined;
            }
            return org.childOrganisations;
          };

          if (!orgMap.rootOrganisation) {
            throw new Error('Could not find root organisation');
          }

          const costContext: CostContext = {
            customer: {
              id: customerContext.id,
              name: customerContext.name,
            },
            ...this.state,
            setPeriodBy: this.setPeriodBy,
            setActivePeriod: this.setActivePeriod,
            setRecursive: this.setRecursive,
            getOrganisationById,
            getCostCenterById,
            getChildrenById,
            costPeriods: this.props.customerOrganisations.costPeriods,
            allOrganisations: this.props.customerOrganisations.allOrganisations,
            rootOrganisation: orgMap.rootOrganisation,
            rootOrganisationId: orgMap.rootOrganisation.id,
          };

          return (
            <CostContextProvider value={costContext}>
              <Switch>
                <Route
                  path={`${this.props.areaRootUrl}/velg-:type(fakturasted|kostnadssted)`}
                  component={ChooseOrganisationOrCostCenter}
                />

                <Route
                  path={`${this.props.areaRootUrl}/fakturaer/:invoiceId([0-9A-Za-z]+)`}
                  component={InvoiceDetails}
                />

                <Route
                  exact={true}
                  path={`${this.props.areaRootUrl}/org/:organisationId`}
                  component={OrganisationSummary}
                />

                <Route
                  path={`${this.props.areaRootUrl}/org/:organisationId/detaljer/:section?`}
                  component={OrganisationDetails}
                />

                <Route
                  path={`${this.props.areaRootUrl}/org/:organisationId/fakturaer`}
                  component={OrganisationInvoices}
                />

                <Route
                  exact={true}
                  path={`${this.props.areaRootUrl}/ks/:costCenterId`}
                  component={CostCenterSummary}
                />

                <Route
                  // exact={true} // IF we don't find a match, we always redirect to the org.picker
                  path={this.props.areaRootUrl}
                  render={() => (
                    <Redirect
                      // Redirect to root organisation:
                      // to={urlFor({ organisation: rootOrganisationId })}
                      // Redirect to organisation picker:
                      to={`${this.props.areaRootUrl}/velg-fakturasted`}
                    />
                  )}
                />
              </Switch>
            </CostContextProvider>
          );
        }}
      </WithCustomerContext>
    );
  }
}

const Cost: React.FC<RouteComponentProps<{}>> = props => (
  <WithUserPreferences>
    {userPreferences => (
      <WithCustomerOrganisations>
        {customerOrganisationsRes => (
          <WithCostCenters
            customerId={customerOrganisationsRes.customerContext.id}
          >
            {costCenterRes => {
              if (customerOrganisationsRes.loading || costCenterRes.loading) {
                return <Loading />;
              }

              if (!customerOrganisationsRes.customer) {
                throw new Error('No customer organisations');
              }

              return (
                <CostArea
                  userPreferences={userPreferences}
                  customerOrganisations={customerOrganisationsRes.customer}
                  costCenters={costCenterRes.costCenters || []}
                  areaRootUrl={props.match.url}
                />
              );
            }}
          </WithCostCenters>
        )}
      </WithCustomerOrganisations>
    )}
  </WithUserPreferences>
);

export default Cost;
