import { push } from './Analytics';

export type ContactsActions =
  | 'update_contact'
  | 'update_access'
  | 'delete'
  | 'create';

export function trackContactAccess(label: string, value: string) {
  push({
    category: 'Contacts',
    action: 'update_access',
    label: label,
    value: value,
  });
}

export function trackContactUpdate(label: string, value?: string) {
  push({
    category: 'Contacts',
    action: 'update_contact',
    label: label,
    value: value,
  });
}

export function trackContactDelete() {
  push({
    category: 'Contacts',
    action: 'delete',
  });
}

export function trackContactCreate() {
  push({
    category: 'Contacts',
    action: 'create',
  });
}
