import gql from 'graphql-tag';
import { SmsEntity } from 'src/areas/mobile/types';
import { useMutation } from 'react-apollo';
import { QUERY_SMS_HISTORY } from 'src/areas/mobile/lib/useSmsHistory';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import { map } from 'lodash';
import { ExecutionResult } from 'graphql';
import { produce } from 'immer';
import { concat } from 'lodash';

export const MUTATION_SMS = gql`
  mutation useSendSms($input: SendSmsInput) {
    sendSms(input: $input) {
      sms {
        id
        msisdn
        sender
        message
        timestamp
        recipients {
          msisdn
          success
        }
      }
      error {
        code
        message
      }
    }
  }
`;

interface Data {
  sendSms: {
    sms: SmsEntity;
    error: {
      code: string;
    };
  };
}
interface Variables {
  input: {
    sender: string;
    message: string;
    to: string[];
  };
}

export type SendSmsResult = {
  sendSms: (message: string, to: string[]) => Promise<ExecutionResult<Data>>;
  loading: boolean;
  error: boolean;
  sms?: SmsEntity;
};

export const useSendSms = (): SendSmsResult => {
  const subscription = useSubscriptionContext();
  const [mutation, { loading, error, data }] = useMutation<Data, Variables>(
    MUTATION_SMS
  );

  const sendSms = async (message: string, to: string[]) =>
    await mutation({
      variables: {
        input: {
          sender: subscription.id,
          message,
          to,
        },
      },
      update: (cache, result) => {
        const cachedSmsHistory = cache.readQuery<any>({
          query: QUERY_SMS_HISTORY,
        });

        let newCache;
        if (result.data && cachedSmsHistory) {
          newCache = produce(cachedSmsHistory, draft => {
            draft.me.mobileSubscriptions.filter(
              sub => sub.id === subscription.id
            )[0].smsHistory = concat(
              cachedSmsHistory.me.mobileSubscriptions.filter(
                sub => sub.id === subscription.id
              )[0].smsHistory,
              result.data ? [result.data.sendSms.sms] : []
            );
          });
        }

        cache.writeQuery({
          query: QUERY_SMS_HISTORY,
          data: newCache,
        });
      },
      optimisticResponse: {
        sendSms: {
          sms: {
            id: 'optimistic-id',
            sender: subscription.id,
            msisdn: subscription.id,
            message: message,
            timestamp: Date.now(),
            recipients: map(to, t => ({
              msisdn: t,
              success: true,
              __typename: 'MobileSmsRecipient',
            })),
            __typename: 'MobileSms',
          },
          error: null,
          __typename: 'SendSmsPayload',
        },
      } as any,
    });

  return {
    sendSms,
    error: !!error || !!(data && data.sendSms.error),
    loading: loading,
    sms: data ? data.sendSms.sms : undefined,
  };
};
