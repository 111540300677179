import React from 'react';
import { FolderOptions } from './FolderOptions';
import FileIcon from './FileIcon';
import { usePath } from 'src/areas/main/documents/contexts/PathContext';
import { Folder } from 'src/lib/types';
import { formatDate } from 'src/lib/utils';
import { useMoveFile } from '../lib/useMoveFile';
import { useMoveFolder } from '../lib/useMoveFolder';
import { Avatar } from 'src/lib/ui/Avatar/Avatar';
interface Props {
  folder: Folder;
  allowDisplaySharing?: boolean;
}

const FolderTableRow = (props: Props) => {
  const { folder } = props;
  const moveFile = useMoveFile(folder);
  const moveFolder = useMoveFolder(folder);
  const pathContext = usePath();

  const handleDragStart = (e: React.DragEvent) => {
    e.dataTransfer.setData('objectType', 'folder');
    e.dataTransfer.setData('objectData', JSON.stringify(folder));
  };

  const handleOnDrop = (e: React.DragEvent<HTMLTableRowElement>) => {
    const objectType = e.dataTransfer.getData('objectType');
    const data = JSON.parse(e.dataTransfer.getData('objectData'));
    if (!(objectType === 'folder' && data.id === folder.id)) {
      if (objectType === 'folder') {
        moveFolder(data.id, folder.id);
      } else if (objectType === 'file') {
        moveFile(data.id, folder.id);
      }
    }
  };

  const handleKeyUp = (e: React.KeyboardEvent) => {
    e.preventDefault();
    if (e.keyCode === 13) {
      pathContext.navigateTo({ folder });
    }
  };

  return (
    <tr
      onDragOver={e => e.preventDefault()}
      onDrop={handleOnDrop}
      onClick={() => pathContext.navigateTo({ folder })}
      onKeyUp={handleKeyUp}
      tabIndex={0}
    >
      <td
        draggable={true}
        onDragStart={e => handleDragStart(e)}
        className="d-flex align-items-center"
      >
        <span className="DocumentTable-folderIcon pr-3">
          {folder.protected ? (
            <div>
              <Avatar
                color="transparent"
                avatar={
                  <Avatar
                    icon="lock-locked"
                    size="compact"
                    color="transparent"
                    className="m-0 border-0 justify-content-start"
                  />
                }
                icon="folder"
                className="justify-content-start m-0 w-100"
              />
            </div>
          ) : (
            <FileIcon folder={true} />
          )}
        </span>
        {folder.name}
      </td>
      <td>{formatDate(folder.modified, 'shortWithTime')}</td>
      <td>{folder.modifiedBy}</td>
      <td>-</td>
      {props.allowDisplaySharing && <td />}
      <td onClick={e => e.stopPropagation()}>
        <FolderOptions folder={folder} />
      </td>
    </tr>
  );
};

export default FolderTableRow;
