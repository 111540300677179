import * as React from 'react';
import DeleteFolderForm from '../forms/DeleteFolderForm';
import RenameFolderForm from '../forms/RenameFolderForm';
import EditAccessForm from '../forms/EditAccessForm';
import { ContextMenuItem, ContextMenu } from 'src/lib/ui';
import DocumentsModal from '../lib/DocumentsModal/DocumentsModal';
import { t } from 'src/lib/i18n';
import { Contact, Folder } from 'src/lib/types';
import { Icon } from '@telia/styleguide';
import { useState } from 'react';
import { useFolders } from '../queries/useFolders';

interface Props {
  folder: Folder;
}

export const FolderOptions = (props: Props) => {
  const [renameModal, setRenameModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [accessModal, setAccessModal] = useState(false);

  const { folders, loading } = useFolders([props.folder.id]);

  const folderContacts: Contact[] =
    folders?.[0].users
      .filter(u => u?.contact !== null)
      .map(u => u.contact as Contact) ?? [];

  const toggleRenameModal = () => {
    setRenameModal(!renameModal);
  };

  const toggleDeleteModal = () => {
    setDeleteModal(!deleteModal);
  };

  const toggleAccessModal = () => {
    setAccessModal(!accessModal);
  };

  if (loading || !folders) return null;

  return (
    <React.Fragment>
      <ContextMenu alignRight={true}>
        <ContextMenuItem onClick={toggleRenameModal}>
          <Icon icon="pen" className="mr-2" />
          {t.documents.FolderOptions.renameFolder}
        </ContextMenuItem>
        <ContextMenuItem onClick={toggleDeleteModal}>
          <Icon icon="trash" className="mr-2" />
          {t.documents.FolderOptions.deleteFolder}
        </ContextMenuItem>
        <ContextMenuItem onClick={toggleAccessModal}>
          <Icon icon="lock-locked" className="mr-2" />
          {t.documents.FolderOptions.accessFolder}
        </ContextMenuItem>
      </ContextMenu>

      <DocumentsModal
        show={renameModal}
        toggle={toggleRenameModal}
        title={t.documents.FolderOptions.renameFolderModalHeading}
      >
        <RenameFolderForm cancel={toggleRenameModal} folder={props.folder} />
      </DocumentsModal>

      <DocumentsModal
        show={deleteModal}
        toggle={toggleDeleteModal}
        title={t.documents.FolderOptions.deleteFolderModalHeading}
      >
        <DeleteFolderForm cancel={toggleDeleteModal} folder={props.folder} />
      </DocumentsModal>

      <DocumentsModal
        show={accessModal}
        toggle={toggleAccessModal}
        title={t.documents.FolderOptions.accessFolder}
        size="lg"
      >
        <EditAccessForm
          cancel={toggleAccessModal}
          folder={props.folder}
          folderContacts={folderContacts}
        />
      </DocumentsModal>
    </React.Fragment>
  );
};
