import { Toggle } from '@telia/styleguide/business';
import { Field, FieldProps } from 'formik';
import * as React from 'react';

/**
 * ToggleProps are not exported from the styleguide at the time.
 * We infer the ToggleProps from the function expression instead.
 */
type FormikToggleProps = Omit<Parameters<typeof Toggle>[0], 'checked'>;

const FormikToggle: React.FunctionComponent<FormikToggleProps> = props => {
  return (
    <Field name={props.id}>
      {formikProps => {
        const { field, form } = formikProps as FieldProps;

        function onChange(e: React.ChangeEvent<HTMLInputElement>) {
          form.setFieldValue(field.name, e.target.checked);
          form.setFieldTouched(field.name);
          if (props.onChange) {
            props.onChange(e);
          }
        }

        return (
          <Toggle
            {...props}
            checked={Boolean(field.value)}
            onChange={onChange}
          />
        );
      }}
    </Field>
  );
};

export default FormikToggle;
