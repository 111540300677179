import Modal from 'reactstrap/lib/Modal';
import React, { useEffect } from 'react';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ModalFooter } from 'reactstrap';
import { toast, FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useDeleteTagMutation } from 'src/areas/main/subscriptions/tags/useDeleteTag';
import { useCustomer } from 'src/lib/global';
import { trackTagsUpdate } from 'src/lib/analytics';

interface Props {
  title: string;
  toggle: () => void;
  show: boolean;
  id: string;
  tagName: string;
  refetchTags: () => void;
}

const DeleteTagModal: React.FC<Props> = props => {
  const translate = t.tags;
  const { data, error, loading, deleteTag } = useDeleteTagMutation();
  const customer = useCustomer();

  function actionOnSubmit() {
    trackTagsUpdate('delete');
    deleteTag({ customerId: customer.id, id: props.id });
  }

  useEffect(() => {
    if (data?.deleteTag?.id) {
      props.toggle();
      props.refetchTags();
    }
  }, [data?.deleteTag?.id]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (!loading && (data?.deleteTag?.error || error)) {
      toast('error', translate.error.deleteTag);
    }
  }, [data?.deleteTag?.error, error, loading]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <Modal isOpen={props.show} toggle={props.toggle} centered={true}>
      <ModalHeader>{props.title + ` ${props.tagName}`}</ModalHeader>
      <ModalBody>{translate.deleteInformation}</ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submitLabel={translate.deleteButton}
          cancel={props.toggle}
          submit={actionOnSubmit}
          loading={loading}
        />
      </ModalFooter>
    </Modal>
  );
};
export default DeleteTagModal;
