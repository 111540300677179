import { useMutation } from 'react-apollo';
import { SERVICE_PLUS_LOCATIONS_QUERY } from 'src/areas/main/servicePlus/queries/useServicePlusAddresses';
import gql from 'graphql-tag';

export const DELETE_ADDRESS_QUERY = gql`
  mutation useDeleteServicePlusAddress($customerId: String!, $addressId: Int!) {
    deleteServicePlusAddress(
      input: { customerId: $customerId, addressId: $addressId }
    ) {
      deleted
      error {
        code
        message
      }
    }
  }
`;

export const useDeleteServicePlusAddressMutation = () => {
  const [mutation, { data, loading, error }] = useMutation(
    DELETE_ADDRESS_QUERY
  );
  const useDeleteAddress = (customerId, addressId) => {
    return mutation({
      variables: {
        customerId: customerId,
        addressId: addressId,
      },
      refetchQueries: [
        {
          query: SERVICE_PLUS_LOCATIONS_QUERY,
          variables: {
            customerId: customerId,
          },
        },
      ],
    });
  };
  return {
    useDeleteServicePlusAddress: useDeleteAddress,
    deleteLocationData: data,
    deleteLocationLoading: loading,
    deleteLocationError: error,
  };
};
