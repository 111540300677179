import React, { useState } from 'react';

import {
  Button,
  OptionsDropdown,
  OptionsDropdownToggle,
  OptionsDropdownMenu,
  OptionsDropdownItem,
  OptionsDropdownHeader,
} from 'src/lib/ui';

import { t } from 'src/lib/i18n';
import { useAppContext } from 'src/lib/global';

export const AreaSelectorDropdown: React.FC = () => {
  const appContext = useAppContext();
  const [open, setOpen] = useState<boolean>(false);

  // No mobileSubs
  if (!appContext.activeSubscriptionId) {
    return null;
  }

  const toggleDropdown = () => {
    setOpen(!open);
  };

  return (
    <OptionsDropdown isOpen={open} toggle={toggleDropdown}>
      <OptionsDropdownToggle tag="span">
        <Button
          color="white"
          icon="services"
          hideLabel={true}
          transparent={true}
        >
          {t.layout.AreaSelectorDropdown.openButton}
        </Button>
      </OptionsDropdownToggle>

      <OptionsDropdownMenu right={true}>
        <OptionsDropdownHeader
          heading={t.layout.AreaSelectorDropdown.heading}
        />

        <OptionsDropdownItem href="/" label="Min Portal" icon="wireless" />

        {/* <OptionsDropdownItem href="/" label="Min Bedrift" icon="product-bedrift-click" /> */}

        <OptionsDropdownItem href="/minside" label="Min Side" icon="mobile" />
      </OptionsDropdownMenu>
    </OptionsDropdown>
  );
};
