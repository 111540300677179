import React from 'react';

// import { Button, Icon, TextField } from 'src/lib/ui';
// import { map } from 'lodash';
// import './SearchField.scss';
// import SearchMenu from 'src/areas/main/search/menu/SearchMenu';
import { map } from 'lodash';
import { urlFor } from 'src/lib/ui';
import { Link } from 'react-router-dom';

import './SearchMenu.scss';

import { SearchResult } from '../types';
import { SearchHistory } from 'src/lib/localState';
import { t } from 'src/lib/i18n';

import { SearchMenuResult } from './menu/SearchMenuResult';
import { groupSearchResults } from '../lib/groupSearchResults';
import { mapResourceTypeToLabel } from '../lib/mapResourceTypeToLabel';
import { ResourceType, DomainResult } from 'src/lib/types';

export interface SearchMenuProps {
  isOpen: boolean;
  selectedItem: any;
  highlightedIndex: number | null;
  getItemProps: any;
  getMenuProps: any;
  inputValue: string | null;
  noHitsMessage?: string;
  searchHistory?: SearchHistory;
  results: SearchResult[];
  totalResults: number | undefined;
  totalResultsByDomain: undefined | Array<DomainResult>;
  loading?: boolean;
  onCategoryClick?: () => void;
  itemIsDisabled?: (item: SearchResult) => boolean;
  additionalInfoOnResult?: (
    item: SearchResult
  ) => React.ReactElement | undefined | null;
}

// const SearchMenuHeadingRow: React.FC<{ heading: string }> = props => (
//   <li className="SearchMenu-heading-item">{props.heading}</li>
// );

const SearchMenuGroupHeadingRow: React.FC<{ type: string }> = props => (
  <li className="SearchMenu-group-heading-item">{props.type}</li>
);

const SearchMenu: React.FC<SearchMenuProps> = props => {
  const menuProps = props.getMenuProps({
    className: 'SearchMenu-list',
  });

  if (!props.isOpen) {
    return (
      <div className="SearchMenu" style={{ display: 'none' }}>
        <ul {...menuProps} />
      </div>
    );
  }

  /**
   * We dont have history at the moment.
   */
  // const showHistory = !props.inputValue || props.inputValue.length < 3;

  // if (showHistory) {
  //   if (props.searchHistory.entries && props.searchHistory.entries.length > 0) {
  //     return (
  //       <ul {...menuProps}>
  //         {map(props.searchHistory.entries, (entry, ix) => {
  //           const itemProps = props.getItemProps({
  //             item: entry,
  //             index: ix,
  //           });
  //           return (
  //             <SearchMenuHistoryEntry
  //               key={ix}
  //               itemProps={itemProps}
  //               active={props.highlightedIndex === ix}
  //               selected={props.selectedItem === entry}
  //               entry={entry}
  //               deleteEntry={() => props.searchHistory.deleteEntry(ix)}
  //             />
  //           );
  //         })}
  //       </ul>
  //     );
  //   }
  //   return <ul {...menuProps} />;
  // }

  if (props.results && props.totalResults) {
    const items: Array<JSX.Element> = [];
    const groupedItems = groupSearchResults(props.results);
    let index = 0;

    groupedItems.forEach(group => {
      if (group.resourceType) {
        items.push(
          <SearchMenuGroupHeadingRow
            key={group.resourceType}
            type={mapResourceTypeToLabel(group.resourceType)}
          />
        );
        group.items.forEach(item => {
          const itemProps = props.getItemProps({
            item: item,
            index: index,
          });
          const key = `${group.resourceType}:${item.id}`;
          items.push(
            <SearchMenuResult
              key={key}
              id={key}
              itemProps={itemProps}
              active={props.highlightedIndex === index}
              selected={props.selectedItem === item}
              entry={item}
            />
          );
          index++;
        });
      }
    });

    // TODO: Add footer when icons are ready
    return (
      <div className="SearchMenu">
        <div className="SearchMenu-body row px-3">
          <div className="col-md-8 py-3">
            <ul {...menuProps}>
              {/* <SearchMenuHeadingRow
                heading={t.search.SearchMenu.foundHits(
                  props.results.length,
                  props.totalResults
                )}
              /> */}
              {items}
            </ul>
          </div>
          <div className="col-md-4 py-3">
            <div className="mb-4">{t.search.SearchMenu.sidemenuTitle}</div>
            <ul className="SearchMenu-sidebar">
              {map(props.totalResultsByDomain, domain => {
                return (
                  <li key={domain.domain}>
                    <Link
                      to={urlFor({
                        area: 'search',
                        query: props.inputValue ? props.inputValue : undefined,
                        resourceType: domain.domain
                          ? (domain.domain as ResourceType)
                          : undefined,
                      })}
                      onClick={props.onCategoryClick}
                    >{`${mapResourceTypeToLabel(
                      domain.domain as ResourceType
                    )} (${domain.resultCount})`}</Link>
                  </li>
                );
              })}
              <li>
                <Link
                  to={urlFor({
                    area: 'search',
                    query: props.inputValue ? props.inputValue : undefined,
                  })}
                  onClick={props.onCategoryClick}
                >
                  {t.search.SearchMenu.allResults(props.totalResults)}
                </Link>
              </li>
            </ul>
          </div>
        </div>
        {/* <div className="SearchMenu-footer">
          <div>
            <Icon icon="arrow-up" className="mr-1" />
            <Icon icon="arrow-down" className="mr-2" />
            for å navigere
          </div>
          <div>
            <Icon icon="arrow-left" className="mr-2" />
            for å velge
          </div>
          <div>
            <Icon icon="close" className="mr-2" />
            for å lukke
          </div>
        </div> */}
      </div>
    );
  }

  if (props.loading) {
    return (
      <div className="SearchMenu">
        <ul {...menuProps} style={{ padding: '1rem' }}>
          <li>{t.search.SearchMenu.loading}</li>
        </ul>
      </div>
    );
  }

  return (
    <div className="SearchMenu">
      <ul {...menuProps} style={{ padding: '1rem' }}>
        <li>{t.search.SearchMenu.noHits}</li>
      </ul>
    </div>
  );
};

export default SearchMenu;
