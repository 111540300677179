import * as React from 'react';
import { LocalStorage } from 'src/lib/LocalStorage';
import { WithCustomerContext } from 'src/lib/global';
import { FormikTouched } from 'formik';

import { find, sortBy, filter } from 'lodash';

import * as t from './types';

export interface StepFlowStorageProps<T> {
  children: (flows: {
    list: t.StoredFlow<T>[];
    save: (id: string, flow: t.StoredFlow<T>) => Promise<void>;
    remove: (id: string) => Promise<void>;
    load: (id: string) => t.StoredFlow<T> | undefined;
    updateValues: (
      id: string,
      values: T,
      url: string,
      currentStep: number,
      touched: FormikTouched<T>
    ) => Promise<void>;
  }) => React.ReactNode;
}

export class StepFlowStorage<T = {}> extends React.Component<
  StepFlowStorageProps<T>
> {
  render() {
    return (
      <WithCustomerContext>
        {cContext => {
          const storageKey = `StepFlowStorage_${cContext.id}`;
          return (
            <LocalStorage<t.StoredFlow<T>[]>
              storageKey={storageKey}
              refresh={1000}
            >
              {lsi => {
                return this.props.children({
                  list: lsi.value
                    ? sortBy(lsi.value, f => f.lastTouchedAt)
                    : [],

                  load: (id: string) => find(lsi.value, l => l.id === id),
                  remove: async (id: string) => {
                    await lsi.setValue(
                      lsi.value ? filter(lsi.value, v => v.id !== id) : []
                    );
                  },
                  save: async (id: string, flow: t.StoredFlow<T>) => {
                    await lsi.setValue([
                      ...(lsi.value ? filter(lsi.value, v => v.id !== id) : []),
                      {
                        ...flow,
                        id,
                      },
                    ]);
                  },
                  updateValues: async (
                    id: string,
                    values: T,
                    url: string,
                    currentStep: number,
                    touched: FormikTouched<T>
                  ) => {
                    const flow = find(lsi.value, l => l.id === id);
                    await lsi.setValue([
                      ...(lsi.value ? filter(lsi.value, v => v.id !== id) : []),
                      {
                        ...flow,
                        id,
                        changeSequence: flow ? flow.changeSequence + 1 : 1,
                        lastTouchedAt: new Date(),
                        startedAt: flow ? flow.startedAt : new Date(),
                        currentStep: currentStep,
                        url: url,
                        values,
                        touched,
                      },
                    ]);
                  },
                });
              }}
            </LocalStorage>
          );
        }}
      </WithCustomerContext>
    );
  }
}
