import React from 'react';
import { map } from 'lodash';
import cs from 'classnames';
import './ProgressIndicator.scss';

import { throttle } from 'lodash';
import { colors } from '../colors';
import { Icon, IconDefinition } from '@telia/styleguide';

export interface ProgressIndicatorStep {
  step: string;
  icon: IconDefinition;
  focus?: boolean;
}

interface Props {
  steps: Array<ProgressIndicatorStep>;
  currentStep: number;
  className?: string;
  style?: React.CSSProperties;
  showStatusLabels?: boolean;
}

interface State {
  containerWidth: number;
  labelWidth: number;
}

export class ProgressIndicator extends React.Component<Props, State> {
  private container: React.RefObject<HTMLDivElement>;
  private label: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);
    this.container = React.createRef<HTMLDivElement>();
    this.label = React.createRef<HTMLDivElement>();
    this.resize = throttle(this.resize.bind(this), 32);
    this.state = {
      containerWidth: 0,
      labelWidth: 0,
    };
  }

  resize(e?: UIEvent) {
    this.setState({
      containerWidth: this.container.current
        ? this.container.current.offsetWidth
        : 0,
      labelWidth: this.label.current ? this.label.current.offsetWidth : 0,
    });
  }

  componentDidMount() {
    window.addEventListener('resize', this.resize);
    this.resize();
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.resize);
  }

  render() {
    const props = this.props;

    /**
     * Label position arithmetic
     */
    const currentStep = props.steps[props.currentStep];
    const circleSize = 32;

    // The container width measured between the centers of the first and last circles
    const innerContainerWidth = Math.max(
      0,
      this.state.containerWidth - circleSize
    );

    // The offset from the left container border to the center of the current step
    const circleOffset =
      (innerContainerWidth / (this.props.steps.length - 1)) *
        this.props.currentStep +
      circleSize / 2;

    // The left position of the label given that its x-center should be the same as its corresponding circle
    const leftPos = circleOffset - this.state.labelWidth / 2;

    // The label left position, constrained by the parent container
    const constrainedLeftPos = Math.min(
      this.state.containerWidth - this.state.labelWidth,
      Math.max(0, leftPos)
    );

    return (
      <div
        ref={this.container}
        className={cs('ProgressIndicator', props.className)}
        style={props.style}
      >
        <div className="ProgressIndicator-bars">
          {map(props.steps.slice(0, -1), (step, ix) => {
            const passed = ix < props.currentStep;

            return (
              <div
                key={ix}
                className={cs({
                  'ProgressIndicator-bar': true,
                  'ProgressIndicator-bar--passed': passed,
                })}
              />
            );
          })}
        </div>

        <div className="ProgressIndicator-circles">
          {map(props.steps, (step, ix) => {
            const passed = ix < props.currentStep + 1;
            const current = ix === props.currentStep;
            const focus = current && step.focus;

            return (
              <div
                className={cs({
                  'ProgressIndicator-circle': true,
                  'ProgressIndicator-circle--focus': focus,
                  'ProgressIndicator-circle--passed': passed,
                })}
                key={ix}
              >
                {focus && <div className="ProgressIndicator-circle--inner" />}
                {current ? (
                  <Icon
                    className="ProgressIndicator-circle-icon"
                    icon={step.icon}
                  />
                ) : passed ? (
                  <Icon
                    className="ProgressIndicator-circle-icon"
                    icon="check-mark"
                  />
                ) : null}
              </div>
            );
          })}
        </div>
        {props.showStatusLabels && (
          <div
            className="ProgressIndicator-label"
            ref={this.label}
            style={{ transform: `translateX(${constrainedLeftPos}px)` }}
          >
            {currentStep.step}
            <div
              className="ProgressIndicator-label-arrow"
              style={{
                position: 'absolute',
                left: circleOffset - constrainedLeftPos - circleSize / 4,
                top: -7,
                borderLeft: `1px solid ${colors.greyMedium}`,
                borderTop: '1px solid #ccc',
                backgroundColor: 'white',
                transform: 'rotate(45deg)',
                width: '12px',
                height: '12px',
              }}
            />
          </div>
        )}
      </div>
    );
  }
}

export default ProgressIndicator;
