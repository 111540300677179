import React, { useContext, useState } from 'react';
import { useUserPreferencesQuery } from './UserPreferencesQuery';
import { formatKeyValueArrayToObject } from 'src/lib/utils/formatKeyValueArrayToObject';
import * as t from './__types/setOwnUserPreferences';
import { useApolloClient } from 'react-apollo';
import gql from 'graphql-tag';
import { trackUserPreferences } from 'src/lib/analytics';

const SET_USER_PREFERENCES = gql`
  mutation setOwnUserPreferences($input: SetOwnUserPreferencesInput) {
    setOwnUserPreferences(input: $input) {
      preferences {
        key
        value
      }
      error {
        message
        code
      }
    }
  }
`;

// TODO: Transfer user prefs from UserPreferences.tsx to this Context when we know what to save i DB
/**
 * SavedUserPreferences: All values is persisted as string.
 */
export interface SavedUserPreferences {
  dataSubscriptionView?: string;
  dataSubscriptionPageSize?: string;
  dataSubscriptionColumns?: string;
  mobileSubscriptionView?: string;
  mobileSubscriptionPageSize?: string;
  mobileSubscriptionColumns?: string;
  teleSubscriptionView?: string;
  teleSubscriptionPageSize?: string;
  teleSubscriptionColumns?: string;
  tvSubscriptionView?: string;
  tvSubscriptionPageSize?: string;
  tvSubscriptionColumns?: string;
  allSubscriptionPageSize?: string;
  subscriptionGroup?: string;
  changelogLastSeenAtTimestamp?: string;
  ordersView?: string;
  ordersPageSize?: string;
  ordersColumns?: string;
  contactsView?: string;
  contactsPageSize?: string;
  constactsColumns?: string;
  incidentsView?: string;
  incidentsColumns?: string;
  incidentsPageSize?: string;
  incidentDetailsOpen?: string;
  subscriptionConfigPageSize?: string;
  subscriptionConfigTab?: string;
  invoicesColumns?: string;
  dashboardFilterTags?: string;
}

interface SavedUserPreferencesContextType {
  setUserPreference: (key: keyof SavedUserPreferences, value: string) => void;
  userPreferences: SavedUserPreferences;
}

const SavedUserPreferencesContext = React.createContext<
  SavedUserPreferencesContextType
>({} as SavedUserPreferencesContextType);

const { Provider, Consumer } = SavedUserPreferencesContext;

const useSavedUserPreferences = () => {
  const context = useContext(SavedUserPreferencesContext);
  if (!context) {
    throw new Error(
      'Add SavedUserPreferencesContext.Provider before using hook.'
    );
  }
  return context;
};

interface InnerSavedUserPreferencesProviderProps {
  initialValues?: SavedUserPreferences;
}

const InnerSavedUserPreferencesProvider: React.FC<InnerSavedUserPreferencesProviderProps> = ({
  initialValues,
  children,
}) => {
  const client = useApolloClient();
  const [userPreferences, setUserPreferences] = useState<SavedUserPreferences>(
    initialValues || {}
  );

  const setUserPreference = async (
    key: keyof SavedUserPreferences,
    value: string
  ) => {
    /**
     * Send mutation to update DB
     * NOTE! We can not use the useMutation-hook because the hook will trigger a re-render of this Provider when running a mutation.
     * It is probably because the reference to the hook is updated after a mutation, causing the component to rerender.
     * Which will also trigger a rerender of all children.
     * Therefore we wait until the mutation is done to force an update
     */
    await client.mutate<
      t.setOwnUserPreferences,
      t.setOwnUserPreferencesVariables
    >({
      mutation: SET_USER_PREFERENCES,
      variables: {
        input: {
          preferences: [{ key: key, value: value }],
        },
      },
    });
    setUserPreferences({ ...userPreferences, [key]: value });
    trackUserPreferences(key, value);
  };

  return (
    <Provider value={{ userPreferences, setUserPreference }}>
      {children}
    </Provider>
  );
};

const SavedUserPreferencesProvider: React.FC = ({ children }) => {
  const { data, loading } = useUserPreferencesQuery();

  if (loading) {
    return null;
  }
  const savedUserPreferences = data?.me?.preferences
    ? formatKeyValueArrayToObject(data.me.preferences)
    : undefined;

  return (
    <InnerSavedUserPreferencesProvider initialValues={savedUserPreferences}>
      {children}
    </InnerSavedUserPreferencesProvider>
  );
};

export {
  SavedUserPreferencesProvider,
  Consumer as WithSavedUserPreferences,
  useSavedUserPreferences,
};
