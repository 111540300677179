import React from 'react';
import { useRouter } from 'src/lib/utils/useRouter';
import { useSubscription } from '../../queries/useSubscription';
import { SubscriptionPlannedWorkCard } from 'src/areas/main/subscriptions/Pages/Components';
import { usePlannedWorkOnSubscription } from '../../queries/usePlannedWorkOnSubscription';
import { getDataSubscriptionView } from './views';
import { toPlannedWork, shouldShowPlanedWork } from './plannedWork';
import { Subscription, DateString } from 'src/lib/types';
import { usePlannedWorkOnSubscription_customer_subscriptions_plannedWork } from 'src/areas/main/subscriptions/queries/__types/usePlannedWorkOnSubscription';

export const DataSummaryComponent = ({
  plannedWork,
  subscription,
  now = new Date(),
}: {
  plannedWork?:
    | usePlannedWorkOnSubscription_customer_subscriptions_plannedWork[]
    | null;
  subscription: Subscription;
  now?: Date | DateString;
}) => {
  const activePlannedWork = (plannedWork || [])
    .map(toPlannedWork)
    .filter(p => shouldShowPlanedWork(p, now));
  const Element = getDataSubscriptionView(subscription);
  return (
    <>
      {activePlannedWork && activePlannedWork.length > 0 ? (
        <div className="mb-4">
          {activePlannedWork.map(entry => (
            <SubscriptionPlannedWorkCard key={entry.id} plannedWork={entry} />
          ))}
        </div>
      ) : null}
      <Element subscription={subscription} />
    </>
  );
};

/**
 * This component will contain items that are common between all DataSubscriptionViews.
 */
const DataSummary = () => {
  const router = useRouter<{ id: string }>();
  const { subscription } = useSubscription(router.match.params.id);

  const { plannedWork } = usePlannedWorkOnSubscription(subscription?.id);
  return subscription ? (
    <DataSummaryComponent
      subscription={subscription}
      plannedWork={plannedWork}
    />
  ) : null;
};

export default DataSummary;
