import React from 'react';
import { OrganisationTreeNavigator } from '../OrganisationTreeNavigator/OrganisationTreeNavigator';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { t } from 'src/lib/i18n';

import './OrganisationPicker.scss';
import { FormSubmitBar } from 'src/lib/ui';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { Icon } from '@telia/styleguide';

interface Props {
  initialOrganisations: string[] | undefined;
  disabled?: boolean;
  onSelectedOrganisationsChange: (organisationIds: string[]) => void;
  toggleState: () => void;
}

interface State {
  selectedOrganisationIds: string[] | undefined;
}

export class OrganisationPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      selectedOrganisationIds: this.props.initialOrganisations,
    };
  }

  onSelectChange = (selectedOrganisationIds: string[]) => {
    this.setState({ selectedOrganisationIds });
  };

  handleSave = () => {
    this.props.onSelectedOrganisationsChange(
      this.state.selectedOrganisationIds || []
    );
    this.props.toggleState();
  };

  render() {
    const { disabled } = this.props;
    const translate = t.ui.OrganisationPicker;
    return (
      <Modal size="md" isOpen={true} toggle={this.props.toggleState}>
        <div className="OrganisationPicker-header pr-3 pl-3">
          <div>
            {<Icon icon="pen" className="pr-2" />}
            {translate.title}
          </div>
          <div onClick={this.props.toggleState}>
            <Icon icon="close" />
          </div>
        </div>
        <ModalBody>
          <OrganisationTreeNavigator
            selectedOrganisationIds={this.state.selectedOrganisationIds}
            disabled={disabled}
            onSelectedChange={this.onSelectChange}
            selectOrganisations={true}
            allowMultipleSelections={true}
          />
        </ModalBody>
        <ModalFooter>
          <FormSubmitBar
            submit={this.handleSave}
            cancel={this.props.toggleState}
          />
        </ModalFooter>
      </Modal>
    );
  }
}
