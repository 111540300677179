import React from 'react';
import { BodyCard } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import './Settings.scss';
import { colors, Button } from 'src/lib/ui';
import { useSubscriptionContext } from '../lib/SubscriptionContext';

export interface Props {
  title: string;
  description?: string;
  onClick?: () => void;
  children?: Array<React.ReactNode> | React.ReactNode;
}

export function SettingsCard(props: Props) {
  const { disableEdit } = useSubscriptionContext();

  return (
    <BodyCard borders={true} noGutter={true}>
      <div>
        <h2 className="px-4 pt-4 pb-3">{props.title}</h2>
        <div className="px-4 pb-4">
          {props.description && (
            <small style={{ color: colors.greyDarkText }}>
              {props.description}
            </small>
          )}
        </div>
        {props.children}
        {!disableEdit && (
          <div className="px-4 py-3">
            <Button onClick={props.onClick} color="white">
              {t.mobile.settings.edit}
            </Button>
          </div>
        )}
      </div>
    </BodyCard>
  );
}
