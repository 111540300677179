import { useSubscription } from './../../subscriptions/queries/__types/useSubscription';
import { WITH_SUBSCRIPTION } from './../../subscriptions/queries/useSubscription';
import { useCustomer } from 'src/lib/global/CustomerContext';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as t from './__types/useDeletePerson';
import produce from 'immer';
import { trackContactDelete } from 'src/lib/analytics';
import { searchQuery } from '../../search';
import { useContactsParams } from '../useContactsParams';
import { SearchDomain } from 'src/__types/graphql-global-types';

const DELETE_PERSON = gql`
  mutation useDeletePerson($input: DeletePersonInput) {
    deletePerson(input: $input) {
      success
      error {
        code
      }
    }
  }
`;

export interface DeleteContactResult {
  deletePerson: (contactId: string, contactSubscriptionIds?: string[]) => void;
  error: boolean;
  errorCode: string;
  loading: boolean;
  data?: t.useDeletePerson;
}

export const useDeletePerson = (): DeleteContactResult => {
  const { listParams, hasUser } = useContactsParams();
  const customer = useCustomer();

  const [mutation, { error, loading, data }] = useMutation<
    t.useDeletePerson,
    t.useDeletePersonVariables
  >(DELETE_PERSON, {
    refetchQueries: [
      {
        query: searchQuery,
        variables: {
          customerId: customer.id,
          search: {
            query: listParams.searchQuery,
            types: [SearchDomain.contact],
            filters: [{ filter: 'hasUser', value: hasUser }],
            sortBy: listParams.sortBy,
            sortOrder: listParams.sortOrder,
            size: listParams.pageSize,
            page: listParams.page,
          },
        },
      },
    ],
    awaitRefetchQueries: true,
  });

  const deletePerson = (
    contactId: string,
    contactSubscriptionIds?: string[]
  ) => {
    trackContactDelete();
    return mutation({
      variables: {
        input: {
          contactId,
        },
      },

      update: cache => {
        contactSubscriptionIds?.map(subsId => {
          const variables = {
            customerId: customer.id,
            subscriptionId: subsId,
          };

          const cachedData = cache.readQuery<useSubscription>({
            query: WITH_SUBSCRIPTION,
            variables,
          });

          if (cachedData) {
            const data = produce<useSubscription>(cachedData, draft => {
              if (draft?.customer?.subscriptions?.[0]?.technicalContacts) {
                draft.customer.subscriptions[0].technicalContacts = draft?.customer?.subscriptions?.[0]?.technicalContacts?.filter(
                  contact => contact.contact.id !== contactId
                );
              }
            });

            cache.writeQuery<useSubscription>({
              query: WITH_SUBSCRIPTION,
              variables,
              data,
            });
          }
        });
      },
    });
  };
  return {
    deletePerson,
    error: !!error || !!data?.deletePerson?.error,
    errorCode: data?.deletePerson?.error?.code ?? '',
    loading,
    data,
  };
};
