import gql from 'graphql-tag';
import { useMutation, ExecutionResult } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { SUBSCRIPTION_INCIDENTS } from '../../subscriptions/queries/useSubscriptionIncidents';
import * as t from './__types/CreateIncident';
import produce from 'immer';
import { searchQuery, QueryVariables, QueryData } from '../../search';
import { CreateIncidentInput } from 'src/__types/graphql-global-types';
import { IncidentFragment } from 'src/lib/types';
import * as subscriptionIncidentsTypes from '../../subscriptions/queries/__types/useSubscriptionIncidents';

const MUATATION_CREATE_INCIDENT = gql`
  mutation CreateIncident($input: CreateIncidentInput) {
    createIncident(input: $input) {
      incident {
        ...IncidentSearchResult
      }
      error {
        code
        message
      }
    }
  }
  ${IncidentFragment}
`;

export interface CreateIncidentResult {
  data?: t.CreateIncident;
  error?: boolean;
  loading: boolean;
  createIncident: (
    variables: Omit<CreateIncidentInput, 'customerId'>
  ) => Promise<ExecutionResult<t.CreateIncident>>;
}
export const useCreateIncident = (): CreateIncidentResult => {
  const customer = useCustomer();

  const [mutation, { data, loading, error }] = useMutation<
    t.CreateIncident,
    t.CreateIncidentVariables
  >(MUATATION_CREATE_INCIDENT);
  const createIncident = (variables: Omit<CreateIncidentInput, 'customerId'>) =>
    mutation({
      variables: {
        input: {
          customerId: customer.id,
          smsNotification: false,
          ...variables,
        },
      },
      update(cache, data) {
        // Update subscription
        let cachedSubscription;
        try {
          cachedSubscription = cache.readQuery<
            subscriptionIncidentsTypes.useSubscriptionIncidents,
            subscriptionIncidentsTypes.useSubscriptionIncidentsVariables
          >({
            query: SUBSCRIPTION_INCIDENTS,
            variables: {
              customerId: customer.id,
              subscriptionId: variables.subscriptionId,
            },
          });
        } catch (e) {
          console.log('error', e);
        }
        if (cachedSubscription) {
          // we cast this to the actual type of the mutation for the moment, since the app is still using the type "Incident" everywhere else in the portal

          const newSubscriptionCache = produce(cachedSubscription, draft => {
            draft.customer.subscriptions[0].incidents.unshift(newIncident);
            draft.customer.subscriptions[0].activeIncidents.unshift(
              newIncident
            );
          });

          cache.writeQuery<subscriptionIncidentsTypes.useSubscriptionIncidents>(
            {
              query: SUBSCRIPTION_INCIDENTS,
              data: newSubscriptionCache,
            }
          );
        }
        const newIncident = data.data?.createIncident?.incident;

        // Update incidents-search result

        let cachedSearch;
        try {
          cachedSearch = cache.readQuery<QueryData, QueryVariables>({
            query: searchQuery,
            variables: {
              customerId: customer.id,
              search: {
                filter: [],
                size: 20,
                sortBy: 'opened',
                sortOrder: 'desc',
                types: ['incident'],
              },
            },
          });
        } catch (e) {
          console.log('error', e);
        }
        if (cachedSearch) {
          const newSearchCache = produce(cachedSearch, draft => {
            // Old code, before wrapping
            // draft.customer.search.results.unshift(newIncident);
            // New code, after wrapping
            draft.customer.search.results.unshift({
              __typename: 'IncidentSearchResult',
              incident: newIncident,
            });
          });

          cache.writeQuery<QueryData>({
            query: searchQuery,
            data: newSearchCache,
          });
        }
      },
    });
  return { createIncident, data, loading, error: !!error };
};
