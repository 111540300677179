import * as React from 'react';
import { ContextMenu, ContextMenuDownloadLink } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

interface InvoiceDownloadDropdownProps {
  invoiceId: string;
}

export const InvoiceDownloadDropdown: React.FunctionComponent<InvoiceDownloadDropdownProps> = props => {
  const translate = t.invoices;
  return (
    <ContextMenu alignRight={true}>
      <ContextMenuDownloadLink
        href={`/api/export/invoice/${encodeURIComponent(props.invoiceId)}.pdf`}
        icon="download"
      >
        {translate.btnDownloadPdf}
      </ContextMenuDownloadLink>
      <ContextMenuDownloadLink
        href={`/api/export/invoice/${encodeURIComponent(props.invoiceId)}.csv`}
        icon="download"
      >
        {translate.btnDownloadCSV}
      </ContextMenuDownloadLink>
      <ContextMenuDownloadLink
        href={`/api/export/invoice/${encodeURIComponent(props.invoiceId)}.xlsx`}
        icon="download"
      >
        {translate.btnDownloadExcel}
      </ContextMenuDownloadLink>
    </ContextMenu>
  );
};
