import * as React from 'react';
import { BottomOverlay, ConfirmationModalButton, Button } from 'src/lib/ui';
import './OrderingFooter.scss';
import { t } from 'src/lib/i18n';

interface OrderingFooterProps {
  next: () => void;

  enableSubmit: boolean;
  cancel: () => void;
  save: () => void;
}

const OrderingFooter: React.FunctionComponent<OrderingFooterProps> = ({
  next,
  enableSubmit,
  cancel,
  save,
}) => {
  const i18n = t.ordering.layout.OrderingFooter;
  return (
    <BottomOverlay ignoreSidebar={true}>
      <div className="OrderingFooter">
        <div className="OrderingFooter-actions container">
          <ConfirmationModalButton
            base="primary"
            type="button"
            color="white"
            onConfirm={cancel}
            headerText={i18n.modal.header}
            label={i18n.delete}
          >
            <p> {i18n.modal.confirmText} </p>
          </ConfirmationModalButton>
          <Button
            base="primary"
            color="white"
            type="button"
            onClick={save}
            className="ml-auto"
          >
            {i18n.save}
          </Button>

          <Button
            base="primary"
            color="purple"
            type="submit"
            onClick={next}
            disabled={!enableSubmit}
          >
            {i18n.submit}
          </Button>
        </div>
      </div>
    </BottomOverlay>
  );
};

export default OrderingFooter;
