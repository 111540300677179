import React, { useEffect } from 'react';
import { SectionProps } from '../BarringSettingsFormModal';
import { SettingsListItem } from '../../SettingsListItem';
import { Toggle } from '@telia/styleguide/business';
import { t } from 'src/lib/i18n';

export function BarIncomingCalls(props: SectionProps) {
  const { state, toggleOption } = props;
  const i18n = t.mobile.settings.barring;

  useEffect(() => {
    if (state.incomingCallsAndSms) {
      toggleOption({ internationalIncomingCalls: false });
    }
  }, [state.incomingCallsAndSms, toggleOption]);

  return (
    <>
      <div className="pt-4 pb-2">{i18n.barCalls.incoming}</div>
      <div className="mx-3" style={{ fontSize: '0.875rem' }}>
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barCalls.barCallsMsgIncom}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.incomingCallsAndSms}
              onChange={() => toggleOption('incomingCallsAndSms')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barCalls.barIncomCallsAbroad}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.internationalIncomingCalls}
              disabled={state.incomingCallsAndSms}
              onChange={() => toggleOption('internationalIncomingCalls')}
            />
          }
        />
      </div>
    </>
  );
}
