import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global/CustomerContext';
import * as t from './__types/useContactId';

export const GET_CONTACT_ID = gql`
  query useContactId($customerId: ID!) {
    me {
      id
      contact(customerId: $customerId) {
        id
      }
    }
  }
`;

export const useCurrentContact = () => {
  const customerContext = useCustomer();
  const res = useQuery<t.useContactId, t.useContactIdVariables>(
    GET_CONTACT_ID,
    {
      variables: {
        customerId: customerContext.id,
      },
      errorPolicy: 'all',
    }
  );
  return {
    contactId: res.data?.me?.contact?.id,
    loading: res.loading,
    error: res.error,
  };
};
