import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global/CustomerContext';
import * as t from './__types/useUser';

export const GET_USER = gql`
  query useUser($customerId: ID!) {
    me {
      id
      customerAccess(customerId: $customerId) {
        roles {
          role
          organisation {
            id
            name
          }
          tag {
            id
            tag
          }
        }
      }
    }
  }
`;

export const useUser = () => {
  const customerContext = useCustomer();
  const { data, loading, error } = useQuery<t.useUser, t.useUserVariables>(
    GET_USER,
    {
      variables: {
        customerId: customerContext.id,
      },
      errorPolicy: 'all',
    }
  );
  return { data, loading, error };
};
