let notYetRemoved = true;

interface Opts {
  /**
   * The first time `useInitialSplashLoader` is called with
   * `stillLoading: false`, the element `.initial-splash-loader`
   * will be removed from the DOM.
   */
  stillLoading: boolean;
}

/**
 * The purpose of useInitialSplashLoader is to hide the `<div class="initial-splash-loader">` element
 * once the application is loaded and ready.
 */
function useInitialSplashLoader(opts: Opts) {
  if (notYetRemoved && !opts.stillLoading) {
    const element = document.querySelector('body > .initial-splash-loader');
    if (element && element.parentNode) {
      // To make it work in IE11 ...
      element.parentNode.removeChild(element);
      // element.remove();
    }
    notYetRemoved = false;
  }
}

export default useInitialSplashLoader;
