import React from 'react';
import { useSearchTag } from 'src/areas/main/subscriptions/queries/useSearchTag';
import { ListResultCard, NoResult, Pagination, List } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import TagContextMenu from './TagContextMenu';
import TagHeader from 'src/areas/main/subscriptions/Pages/Tag/TagHeader';
import { useListQueryParams } from 'src/lib/utils/useListQueryParams';
import { useDelayedRender } from 'src/lib/utils/useDelayedRender';
import { useQueryParamPagination } from 'src/lib/ui/Pagination/useQueryParamPaginations';

export const tagsDefaultSortOrder = 'asc';
export const tagsDefaultSortBy = 'tag';
export const tagsPreferredNamespace = 'tags';

export default function Tags() {
  const translate = t.tags;
  const queryParams = useListQueryParams({
    defaultSortKey: tagsDefaultSortBy,
    defaultSortOrder: tagsDefaultSortOrder,
    preferenceNamespace: tagsPreferredNamespace,
  });

  const paginationProps = useQueryParamPagination({
    defaultPageSize: queryParams.pageSize,
    onTogglePageSize: queryParams.setPageSize,
  });

  const { data, loading, refetch, totalResults } = useSearchTag({
    sortBy: queryParams.sortBy,
    sortOrder: queryParams.sortOrder,
    filterQuery: queryParams.searchQuery,
    size: queryParams.pageSize,
    page: queryParams.page,
  });
  const delayedRender = useDelayedRender(500, loading);

  return (
    <div className="container">
      <TagHeader totalResults={totalResults ?? 0} refetchTags={refetch} />

      <List border={true} container={false} loading={delayedRender}>
        {data?.length === 0 && !loading ? (
          <div style={{ width: '100%', height: '50vh' }}>
            <NoResult text={translate.noResults} />
          </div>
        ) : (
          data?.map(({ tag }) => (
            <ListResultCard
              key={tag.id}
              className="ListResultCard--Tag pl-3"
              linkTo={{ area: 'subscriptions', filterByTag: tag.id }}
              title={tag.tag}
              attributes={[
                tag.description ? { value: tag.description } : undefined,
              ]}
              contextual={<TagContextMenu tag={tag} refetchTags={refetch} />}
            />
          ))
        )}
      </List>

      <div
        className="d-flex container"
        style={{
          paddingTop: '1rem',
          paddingBottom: '60px',
        }}
      >
        <div className="ml-auto">
          <Pagination totalResults={totalResults || 0} {...paginationProps} />
        </div>
      </div>
    </div>
  );
}
