import * as React from 'react';
import { Statistics } from '../queries/WithStatisticsRealtime';
import { format } from 'date-fns';
import { Loading } from 'src/lib/ui';

import RealTimeChart from 'src/lib/charts/RealTimeChart/RealTimeChart';
import { NewRealTimeChart } from 'src/lib/charts/RealTimeChart/NewRealTimeChart';
import { convertByteToMegaByte } from '../utils/convertUnit';

const defaultMaxTicks = 20;
const bitsInByte = 8;
const msInSecond = 1000;

interface Tick {
  in: number;
  out: number;
  timestamp: string;
}

export interface Props {
  data: Statistics;
  timestamp: Date;
  // Temporary prop to show new realtime-chart.
  newView?: boolean;
  subTitle?: string;
}

export interface State {
  data: Tick[];
}

export default class StatisticsRealtime extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      data: new Array(defaultMaxTicks).fill(null),
    };
  }

  componentDidUpdate(prevProps: Props) {
    if (this.props.timestamp !== prevProps.timestamp) {
      return this.handleNewData(
        prevProps.data,
        this.props.data,
        this.props.timestamp,
        prevProps.timestamp
      );
    }
    return null;
  }

  handleNewData = (
    prevTick: Statistics,
    newTick: Statistics,
    timestamp: Date,
    prevTimeStamp: Date
  ) => {
    this.setState((prevState: State) => {
      const trafficIn =
        parseInt(newTick.cumulativeIn, undefined) -
        parseInt(prevTick.cumulativeIn, undefined);
      const trafficOut =
        parseInt(newTick.cumulativeOut, undefined) -
        parseInt(prevTick.cumulativeOut, undefined);
      const timeSpan =
        (timestamp.getTime() - prevTimeStamp.getTime()) / msInSecond;
      const tick = {
        in: (convertByteToMegaByte(trafficIn) / timeSpan) * bitsInByte,
        out: (convertByteToMegaByte(trafficOut) / timeSpan) * bitsInByte,
        timestamp: format(timestamp, 'YYYY-MM-DD HH:mm:ss'),
      };

      const { data } = prevState;
      const dataLen = data.filter(x => x).length;

      if (dataLen === 0) {
        return {
          data: [tick, ...data.slice(1)],
        };
      } else if (dataLen < defaultMaxTicks) {
        return {
          data: [
            ...data.slice(0, dataLen),
            tick,
            ...data.slice(dataLen + 1, defaultMaxTicks - 1),
          ],
        };
      }
      return {
        data: [...data.slice(1), tick],
      };
    });
  };

  render() {
    const { data } = this.state;
    const dataCount = data.filter(x => x).length;

    if (dataCount === 0) {
      return <Loading />;
    }
    if (this.props.newView) {
      return <NewRealTimeChart data={data} subTitle={this.props.subTitle} />;
    }
    return <RealTimeChart data={data} />;
  }
}
