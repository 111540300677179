import * as React from 'react';
import { Icon, IconDefinition } from '@telia/styleguide';

type Props = { parentFolder: true } | { folder: true } | { mimeType?: string };

const mimeTypes: Array<{
  startsWith: string;
  icon: IconDefinition;
  color?: string;
}> = [
  // General file types
  { startsWith: 'text/', icon: 'document' },
  { startsWith: 'image/', icon: 'image', color: '#008391' },
  // Spreadsheets, Excel
  {
    startsWith: 'application/vnd.ms-excel',
    icon: 'document-xls',
    color: '#0f9d58',
  },
  {
    startsWith: 'application/vnd.openxmlformats-officedocument.spreadsheetml',
    icon: 'document-xls',
    color: '#0f9d58',
  },
  // Documents, Word
  {
    startsWith: 'application/vnd.msword',
    icon: 'document-doc',
    color: '#4688f4',
  },
  {
    startsWith:
      'application/vnd.openxmlformats-officedocument.wordprocessingml',
    icon: 'document-doc',
    color: '#4688f4',
  },
  // Presentations, Powerpoint
  {
    startsWith: 'application/vnd.ms-powerpoint',
    icon: 'document-ppt',
    color: '#dd4b3e',
  },
  {
    startsWith:
      'application/vnd.openxmlformats-officedocument.presentationml.presentation',
    icon: 'document-ppt',
    color: '#dd4b3e',
  },
  // PDF
  { startsWith: 'application/pdf', icon: 'document-pdf', color: '#dd4b3e' },
];

const getIconByMimeType = (
  mimeType?: string
): { icon: IconDefinition; color?: string } => {
  if (mimeType) {
    for (let i = 0, len = mimeTypes.length; i < len; i++) {
      if (mimeType.indexOf(mimeTypes[i].startsWith) === 0) {
        return mimeTypes[i];
      }
    }
  }
  return {
    icon: 'document',
  };
};

const FileIcon: React.SFC<Props> = props => {
  if ('folder' in props) {
    return <Icon icon="folder" />;
  }
  if ('parentFolder' in props) {
    return <Icon icon="arrow-large-left" />;
  }
  const icon = getIconByMimeType(props.mimeType);
  return <Icon icon={icon.icon} />;
};

export default FileIcon;
