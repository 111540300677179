import React, { useMemo } from 'react';
import { BodyCard, CardBody, Loading } from 'src/lib/ui';
import { useStatisticsParams } from '../../utils/useStatisticsParams';
import { isAggregatedByDay } from '../../utils/isAggregatedByDay';
import {
  useStandardStatistics,
  StandardStatisticsPeriod,
} from '../../queries/WithStatistics';
import { useSubHasStatistics } from '../../queries/useSubHasStatistics';
import { StandardChart } from '../../components/StandardChart';
import { StandardDetails } from '../Standard/StandardDetails';
import { mapStandardData } from '../Standard/mapStandardData';
import * as st from '../Standard/StandardTypes';
import { t, useI18nContext } from 'src/lib/i18n';
import { SnapshotTooltip } from './SnapshotTooltip';

/**
 *  We call it Snapshot, which is a view that includes all the "Standard" charts.
 */
export const Snapshot = () => {
  const params = useStatisticsParams();
  const hasStatisticsQuery = useSubHasStatistics(params.subscriptionId);

  if (hasStatisticsQuery.loading) {
    return null;
  }

  return (
    <InnerSnapshot
      hasExtendedStatistics={
        !!hasStatisticsQuery?.data?.customer?.subscriptions?.[0]
          ?.hasExtendedStatistics
      }
    />
  );
};

const InnerSnapshot = (props: { hasExtendedStatistics: boolean }) => {
  // reuse labels from filter
  const i18n = t.statistics.StatisticsFilter;
  const { locale } = useI18nContext();
  const params = useStatisticsParams();
  const statisticsQuery = useStandardStatistics({
    subscriptionId: params.subscriptionId,
    type: ['throughput', 'cumulative', 'packetDrop', 'responseTime'],
    periods: 100,
    interface: params.currentInterface,
    periodStart: params.currentStartPeriod,
    periodEnd: params.currentEndPeriod,
  });

  const mappedData = mapStandardData(
    statisticsQuery.data?.statistics?.periods,
    statisticsQuery.data?.serviceBandwidth
  );

  const hideTimestamp = isAggregatedByDay(
    params.currentStartPeriod,
    params.currentEndPeriod
  );
  const charts = useMemo(() => {
    return [
      {
        title: i18n.throughput,
        chart: st.ThroughputChartElements(),
      },
      {
        title: i18n.packetDrop,
        chart: st.PacketDropChartElements(),
      },
      {
        title: i18n.cumulative,
        chart: st.CumulativeChartElements(),
      },
      {
        title: i18n.responseTime,
        chart: st.ResponseTimeChartElements(),
      },
    ];
    // We do not want react to call chartElements function on every render, so we make use of memo. But if locale changes we want to update all labels.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locale]);

  return (
    <BodyCard noGutter={true}>
      <CardBody>
        {statisticsQuery.loading ? (
          <Loading />
        ) : mappedData === null ? (
          <div>Ingen data</div>
        ) : (
          <>
            {charts.map(c => (
              <StandardChart<StandardStatisticsPeriod>
                key={c.title}
                title={c.title}
                periods={mappedData}
                tooltip={SnapshotTooltip}
                className="mb-4"
                hideTimestamp={hideTimestamp}
              >
                {c.chart}
              </StandardChart>
            ))}

            {statisticsQuery.data?.statistics && (
              <StandardDetails
                data={statisticsQuery.data.statistics}
                // NOT in use for now
                // serviceBandwidth={statisticsQuery.data.serviceBandwidth}
              />
            )}
          </>
        )}
      </CardBody>
    </BodyCard>
  );
};
