import * as React from 'react';

import { InvoiceStatus } from 'src/lib/types';

import './InvoiceStatusBadge.scss';
import { Icon } from '@telia/styleguide';

interface Props {
  status: InvoiceStatus;
  label: string;
}

const InvoiceStatusBadge: React.FC<Props> = props => {
  if (!props.status) {
    return null;
  }
  const modifier = props.status ? props.status.toLowerCase() : 'undefined';
  return (
    <div className={`InvoiceStatusBadge InvoiceStatusBadge--${modifier}`}>
      <Icon className="InvoiceStatusBadge-icon mr-2" icon="document" />
      <span className="InvoiceStatusBadge-label">{props.label}</span>
    </div>
  );
};

export default InvoiceStatusBadge;
