import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import {
  VoicemailMobileSettings,
  MobileSubscription,
  Error,
} from 'src/lib/types';
import { WITH_MOBILE_SETTINGS } from 'src/areas/mobile/settings/useMobileSettings';

export const UPDATE_VOICEMAIL_SETTINGS = gql`
  mutation useUpdateSubscriptionVoicemailSetting(
    $input: UpdateVoicemailMobileSettingsInput
  ) {
    updateVoicemailMobileSettings(input: $input) {
      msisdn
      voicemail {
        smsNotification
        emailNotification
        visualVoicemail
      }
      error {
        code
        message
      }
    }
  }
`;

interface Variables {
  input: {
    msisdn: string;
  } & VoicemailMobileSettings;
}

interface Data {
  updateVoicemailMobileSettings: {
    msisdn: string;
    voicemail?: VoicemailMobileSettings;
    error?: Error;
  };
}

export interface UpdateVoicemailSettingsResult {
  loading: boolean;
  error: boolean;
  data?: Data;
  updateSettings: (settings: VoicemailMobileSettings) => void;
}

export const useUpdateSubscriptionVoicemailSetting = (): UpdateVoicemailSettingsResult => {
  const subscription = useSubscriptionContext();
  const [mutation, result] = useMutation<Data, Variables>(
    UPDATE_VOICEMAIL_SETTINGS,
    {
      update: (cache, { data }) => {
        if (data && data.updateVoicemailMobileSettings) {
          const {
            updateVoicemailMobileSettings: { voicemail, error, msisdn },
          } = data;
          if (!error) {
            const cachedData: {
              subscription: MobileSubscription;
            } | null = cache.readQuery({
              query: WITH_MOBILE_SETTINGS,
              variables: {
                subscriptionId: msisdn,
              },
            });
            if (
              cachedData &&
              cachedData.subscription &&
              cachedData.subscription.settings
            ) {
              cache.writeQuery({
                query: WITH_MOBILE_SETTINGS,
                variables: {
                  subscriptionId: msisdn,
                },
                data: {
                  subscription: {
                    id: msisdn,
                    settings: {
                      ...cachedData.subscription.settings,
                      voicemail: voicemail,
                      __typename: 'MobileSettings',
                    },
                    __typename: 'MobileSubscription',
                  },
                },
              });
            }
          }
        }
      },
    }
  );

  const updateSettings = (settings: VoicemailMobileSettings) =>
    mutation({
      variables: {
        input: {
          msisdn: subscription.id,
          ...settings,
        },
      },
    });

  return {
    updateSettings,
    data: result.data,
    error:
      !!result.error &&
      !!(result.data && result.data.updateVoicemailMobileSettings.error),
    loading: result.loading,
  };
};
