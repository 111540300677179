import * as React from 'react';
import { extractCostMatrix } from './lib';
import { WithState } from 'src/lib/ui';
import { RouteComponentProps } from 'react-router';
import {
  MobileCostSummary,
  WithCostAggregateForCostCenter,
  CostSummaryTable,
  CostCenterPage,
  groupMobileCostAggregates,
} from './lib';

export const CostCenterSummary: React.FC<RouteComponentProps<{
  costCenterId: string;
}>> = props => (
  <CostCenterPage costCenterId={props.match.params.costCenterId}>
    {(costContext, costCenter) => (
      <div className="container">
        <WithCostAggregateForCostCenter
          costCenterId={costCenter.id}
          periodStart={costContext.activePeriod}
          periodEnd={costContext.activePeriod}
          periodBy={costContext.periodBy}
          groupBy={['month', 'costType', 'productGroup']}
        >
          {c => (
            <CostSummaryTable
              costType="net"
              costMatrix={extractCostMatrix(c.costAggregates)}
            />
          )}
        </WithCostAggregateForCostCenter>
        <WithCostAggregateForCostCenter
          costCenterId={costCenter.id}
          periodStart={costContext.activePeriod}
          periodEnd={costContext.activePeriod}
          periodBy={costContext.periodBy}
          productGroup="mobile"
          groupBy={['month', 'costType', 'groupedProductDesign']}
        >
          {c => {
            if (c.loading) {
              return null;
            }
            if (!c.costAggregates.length) {
              return null;
            }
            if (!costContext.activePeriod) {
              return null;
            }
            const activePeriod = costContext.activePeriod;
            return (
              <div className="py-5">
                <WithState<number | undefined> default={undefined}>
                  {(activeIndex, setActiveIndex) => (
                    <MobileCostSummary
                      data={groupMobileCostAggregates(c.costAggregates, {
                        filterBy: { costType: 'Forbruk' },
                      })}
                      activePeriod={activePeriod}
                      periodBy={costContext.periodBy}
                      activeIndex={activeIndex}
                      setActiveIndex={setActiveIndex}
                    />
                  )}
                </WithState>
              </div>
            );
          }}
        </WithCostAggregateForCostCenter>
      </div>
    )}
  </CostCenterPage>
);
