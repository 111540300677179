import { ExtendedQosStatisticsPeriods } from '../../queries/useExtendedQosStatistics';
import moment from 'moment';

export const mapExtendedQosData = (
  data?: ExtendedQosStatisticsPeriods[] | null
) => {
  if (!data) {
    return null;
  }

  return data.map(x => {
    return {
      ...x,
      periodStart: moment(x.periodStart).valueOf(),
      periodEnd: moment(x.periodEnd).valueOf(),
    };
  });
};
