import React, { useState } from 'react';
import { t } from 'src/lib/i18n';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { SettingsCard } from 'src/areas/mobile/settings/SettingsCard';
import CallerIdFormModal from 'src/areas/mobile/settings/forms/CallerIdFormModal';
import { Item } from 'src/lib/ui';
import { useAllowedCallerIdsForSubscription } from 'src/areas/main/subscriptions/queries/useAllowedCallerIdsForSubscription';

export const CallerIdSettings = ({ msisdn }) => {
  const [isModalOpen, setModalOpen] = useState<boolean>(false);
  const [allowedCallerIds, setAllowedCallerIds] = useState<
    string[] | undefined
  >(undefined);
  const [isCallsFetched, setCallsFetched] = useState<boolean>(false);
  const allowedCallerIdsResult = useAllowedCallerIdsForSubscription(msisdn)
    ?.allowedCallerIdList;

  if (allowedCallerIdsResult && !isCallsFetched) {
    setAllowedCallerIds(allowedCallerIdsResult);
    setCallsFetched(true);
  }

  const updateCallerIds = callerIds => {
    setAllowedCallerIds(callerIds);
  };

  let allowedCallerIdItems = allowedCallerIds?.map(
    callerId =>
      ({
        id: callerId,
        label: callerId,
      } as Item)
  );

  const i18n = t.mobile.settings;
  return (
    <SettingsCard
      title={i18n.callerId.title}
      description={i18n.callerId.description}
      onClick={() => setModalOpen(true)}
    >
      {allowedCallerIds ? (
        <SettingsListItem
          title={i18n.callerId.allowedCallerIds}
          description={
            allowedCallerIds?.length > 0
              ? allowedCallerIds.join(', ')
              : i18n.callerId.ownNumberOnly
          }
        />
      ) : null}
      <CallerIdFormModal
        msisdn={msisdn}
        callerIds={allowedCallerIdItems || []}
        isOpen={isModalOpen}
        updateCallerIds={updateCallerIds}
        toggle={() => setModalOpen(!isModalOpen)}
      />
    </SettingsCard>
  );
};
