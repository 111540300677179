import gql from 'graphql-tag';
import { ExecutionResult, useMutation } from 'react-apollo';
import * as t from './__types/useUpdateAttribute';
import { toast } from 'src/lib/ui';

const MUTATION_UPDATE_SUBSCRIPTION_ATTRIBUTE = gql`
  mutation useUpdateAttribute($input: SetAttributeForSubscriptionInput) {
    setAttributeForSubscription(input: $input) {
      subscription {
        id
        alias
        tags {
          id
        }
        flex1
        flex2
        flex3
        flex4
        ownerAttributes {
          firstName
          lastName
          employeeNumber
          position
          email
        }
        taxModel
      }
      error {
        code
        message
      }
    }
  }
`;

export type Attribute =
  | 'alias'
  | 'tag'
  | 'employeeNumber'
  | 'flex1'
  | 'flex2'
  | 'flex3'
  | 'flex4'
  | 'position'
  | 'subMail'
  | 'taxModel'
  | 'firstName'
  | 'lastName';

export interface UpdateSubscriptionResult {
  setAttributeForSubscription: (
    subscriptionId: string,
    attribute: Attribute,
    attributeValue: string
  ) => Promise<ExecutionResult<t.useUpdateAttribute>>;
  loading: boolean;
  error: boolean;
  data: t.useUpdateAttribute | undefined;
}

export const useUpdateAttribute = (errorMessage?: string) => {
  const [updateAttribute, { data, loading, error }] = useMutation<
    t.useUpdateAttribute,
    t.useUpdateAttributeVariables
  >(MUTATION_UPDATE_SUBSCRIPTION_ATTRIBUTE, {
    onCompleted({ setAttributeForSubscription }) {
      if (setAttributeForSubscription?.error && errorMessage) {
        toast('error', errorMessage, {
          position: 'bottom-right',
          bodyClassName: 'd-flex w-  p-2 align-items-center',
          autoClose: false,
        });
      }
    },
  });

  const setAttributeForSubscription = (
    subscriptionId: string,
    attribute: Attribute,
    attributeValue: string
  ) =>
    updateAttribute({
      variables: {
        input: {
          subscriptionId: subscriptionId,
          attribute: attribute,
          value: attributeValue,
        },
      },
    });

  return {
    setAttributeForSubscription,
    loading,
    data,
    error: !!error || !!data?.setAttributeForSubscription?.error,
  };
};
