import React, { useState, useEffect } from 'react';
import cs from 'classnames';
import { formatDate } from 'src/lib/utils/dateUtils';
import { t, useI18nContext } from 'src/lib/i18n';
import './ChatMessage.scss';
import { ChatMessageProps } from 'src/lib/chat/types';
import { EditChatMessage } from 'src/lib/chat/EditChatMessage';
import { ChatMessageContextMenu } from 'src/lib/chat/ChatMessageContextMenu';
import { useChatContext } from './ChatContext';
import { Checkbox } from 'src/lib/ui';

const ChatMessage: React.FC<ChatMessageProps> = props => {
  const [edit, setEdit] = useState(false);
  const chatContext = useChatContext();

  useEffect(() => {
    if (edit) {
      chatContext.setOpenMessages([...chatContext.openMessages, props]);
    } else {
      chatContext.setOpenMessages(
        chatContext.openMessages.filter(m => m.id !== props.id)
      );
    }
  }, [edit]); // eslint-disable-line react-hooks/exhaustive-deps

  const selected =
    chatContext.selectable &&
    chatContext.selectedMessages?.find(m => m.id === props.id);

  const i18n = useI18nContext();
  const message = 'message' in props ? props.message : undefined;

  const handleEditSave = (newComment: string) => {
    chatContext.commentHandlers?.handleEdit?.(props.id, newComment);
    setEdit(false);
  };

  const handleSelect = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.checked) {
      chatContext.setSelectedMessages?.([
        ...(chatContext?.selectedMessages ?? []),
        {
          id: props.id,
          type: 'comment',
          timestamp: props.timestamp,
          contact: props.contact,
          message: message ?? '',
        },
      ]);
    } else {
      chatContext.setSelectedMessages?.([
        ...(chatContext?.selectedMessages?.filter(m => m.id !== props.id) ??
          []),
      ]);
    }
  };

  const messageAuthor =
    props.contact.name === 'emailComment'
      ? t.incidents.ChatMessage.emailFromCustomer
      : props.contact.name;

  return (
    <div className={cs('ChatMessage', props.className)}>
      <div
        className={cs('ChatMessage-icon', {
          selectable: chatContext.selectable,
        })}
      >
        <div
          className={cs('ChatMessage-letter', {
            prominent: props.prominent,
            'd-none': selected,
          })}
        >
          {props.contact.name.slice(0, 1).toUpperCase()}
        </div>
        <div className={cs('ChatMessage-select', { 'd-block': selected })}>
          <Checkbox checked={!!selected} onChange={handleSelect} />
        </div>
      </div>

      <div className="ChatMessage-wrapper pl-3 w-100">
        <div className="ChatMessage-header">
          {props.contact ? (
            <span className="ChatMessage-contact">{messageAuthor}</span>
          ) : null}
          <span className="ChatMessage-time">
            {formatDate(props.timestamp, 'mediumWithTime', i18n.locale)}
          </span>
        </div>

        {edit && message ? (
          <EditChatMessage
            message={message}
            handleEditSave={handleEditSave}
            cancel={() => setEdit(false)}
          />
        ) : (
          <div className="d-flex w-100">
            <div className={cs('ChatMessage-body', { selected })}>
              {message ? (
                <>
                  <div className="ChatMessage-text">{message}</div>
                </>
              ) : (
                <div className="ChatMessage-text">{props.children}</div>
              )}
            </div>
            {chatContext?.activeUserId === props.contact.id &&
              chatContext?.commentHandlers && (
                <ChatMessageContextMenu
                  id={props.id}
                  setEdit={() => setEdit(!edit)}
                />
              )}
          </div>
        )}
      </div>
    </div>
  );
};

export default ChatMessage;
