import React, { useState, useEffect } from 'react';
import { RoamingAlertMobileSettings } from 'src/lib/types';
import { Modal, ModalHeader, ModalBody } from 'reactstrap';
import { FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Toggle } from '@telia/styleguide/business';
import { useUpdateRoamingAlertSettings } from 'src/areas/mobile/settings/mutations';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import ModalFooter from 'reactstrap/lib/ModalFooter';

interface Props {
  roaminAlertSettings: RoamingAlertMobileSettings;
  open: boolean;
  toggle: () => void;
}

export const RoamingAlertFormModal: React.FC<Props> = ({
  roaminAlertSettings,
  ...props
}) => {
  const [sms, setSms] = useState(roaminAlertSettings.smsWhenRoaming);
  const {
    updateSettings,
    data,
    loading,
    error,
  } = useUpdateRoamingAlertSettings();
  const i18n = t.mobile.settings.smsRoaming;

  useEffect(() => {
    if (data && !error) {
      props.toggle();
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () =>
    updateSettings({
      smsWhenRoaming: !roaminAlertSettings.smsWhenRoaming,
    });

  return (
    <Modal isOpen={props.open} size="lg" toggle={props.toggle}>
      <ModalHeader>{i18n.smsRoamingAlert}</ModalHeader>
      <ModalBody>
        <SettingsListItem
          className="border-0"
          title={i18n.smsRoamingAlertInfo}
          toggle={<Toggle checked={sms} onChange={() => setSms(!sms)} />}
        />
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={submit}
          cancel={props.toggle}
          loading={loading}
          error={error}
          errorMsg={t.mobile.settings.modalFields.unableToSave}
        />
      </ModalFooter>
    </Modal>
  );
};
