import React, { useRef } from 'react';
import { Button } from 'src/lib/ui';
import { Dropdown, DropdownToggle, DropdownMenu } from 'reactstrap';
import './OptionSelect.scss';
import cs from 'classnames';
import { useSelect } from 'downshift';
import { OptionSelectProps } from './';
import {
  OptionSelectFooter,
  useOptionSelect,
  OptionSelectMenu,
  OptionSelectButtonStyleProps,
} from '../OptionSelect/index';
import { useTabbedDropdown } from './useTabbedDropdown';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

export const OptionSelect = (
  props: Omit<OptionSelectProps, 'actionOptions' | 'allowSearch'> &
    OptionSelectButtonStyleProps
) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const dropdownRef = useTabbedDropdown();

  const {
    options,
    rowHeight,
    selectedOptions,
    partiallySelectedOptions,
    optionIsPartiallySelected,
    optionIsSelected,
    handleClick,
    reset,
    disableSave,
  } = useOptionSelect({
    options: props.options,
    selectedOptions: props.selectedOptions,
    containerRef: containerRef,
    allowMultiple: props.allowMultiple,
  });

  const downshift = useSelect({
    items: options,
    circularNavigation: false,
    onIsOpenChange: changes => {
      if (!changes.isOpen) {
        if (!props.allowMultiple && changes.selectedItem) {
          return props.handleSave([changes.selectedItem?.value]);
        }
        if (!props.disableSaveOnClickOutside) {
          return props.handleSave(selectedOptions, partiallySelectedOptions);
        }
      }
    },
    stateReducer: (state, actionAndChanges) => {
      switch (actionAndChanges.type) {
        case useSelect.stateChangeTypes.FunctionOpenMenu:
          return {
            ...actionAndChanges.changes,
            highlightedIndex: 0,
          };
        case useSelect.stateChangeTypes.MenuBlur:
          return state;
        case useSelect.stateChangeTypes.ItemClick:
        case useSelect.stateChangeTypes.MenuKeyDownEnter:
          if (actionAndChanges.changes?.selectedItem) {
            handleClick(actionAndChanges.changes?.selectedItem);
          }
          return {
            ...state,
            selectedItem: actionAndChanges.changes.selectedItem,
            isOpen: !!props.allowMultiple,
          };
        default:
          return actionAndChanges.changes;
      }
    },
  });
  const cancel = props.cancel
    ? () => {
        props.cancel?.();
        downshift.closeMenu();
      }
    : undefined;
  const menuProps = downshift.getMenuProps({
    className: 'OptionSelectMenu',
  });
  const currentIsDefault =
    props.defaultOption && props.selectedOptions?.includes(props.defaultOption);

  const key =
    props.alignRight && downshift.isOpen
      ? props.label + downshift.isOpen.toString()
      : undefined;

  const applySelection = () => {
    if (props.disableSaveOnClickOutside) {
      props.handleSave(selectedOptions, partiallySelectedOptions);
    }
    downshift.closeMenu();
  };
  return (
    <div ref={dropdownRef}>
      <Dropdown
        isOpen={downshift.isOpen}
        toggle={downshift.isOpen ? downshift.closeMenu : downshift.openMenu}
        className="d-inline Select"
      >
        <DropdownToggle tag="span">
          <Button
            id="toggle"
            className={cs({
              'w-100': props.fullWidth,
              open: downshift.isOpen,
              inUse:
                props.blackBorderOnSelect &&
                !currentIsDefault &&
                selectedOptions?.length,
            })}
            color={props.color ?? 'dark'}
            outline={props.outline}
            size={props.size}
            transparent={props.transparent}
            {...downshift.getToggleButtonProps({
              refKey: 'innerRef',
            })}
            onClick={
              downshift.isOpen ? downshift.closeMenu : downshift.openMenu
            }
            type="button"
            aria-expanded={true}
          >
            {props.icon &&
            (!props.iconPosition || props.iconPosition === 'before') ? (
              <Icon className="mr-2 ml-n1" icon={props.icon} />
            ) : null}
            {props.label}
            {props.icon && props.iconPosition === 'after' ? (
              <Icon
                icon={props.icon}
                className={cs(props.fullWidth ? 'ml-auto' : 'ml-2', 'mr-n1')}
              />
            ) : null}
          </Button>
        </DropdownToggle>
        {/**
         * Alignment does not work when using persist.
         * If we want to right align the menu we re-mount the dropdown menu by updating the key
         * Related issue: https://github.com/reactstrap/reactstrap/issues/1070
         */}
        <DropdownMenu
          key={key}
          persist={true}
          right={props.alignRight}
          className={cs({ 'w-100': props.fullWidth })}
        >
          <OptionSelectMenu
            menuProps={menuProps}
            getItemProps={downshift.getItemProps}
            options={options}
            loading={props.loading}
            highlightedIndex={downshift.highlightedIndex}
            optionIsSelected={optionIsSelected}
            noOptionsMessage={props.noOptionsMessage}
            rowHeight={rowHeight}
            tabIndex={0}
            allowMultiple={props.allowMultiple}
            optionIsPartiallySelected={optionIsPartiallySelected}
            fullWidth={props.fullWidth}
          />
          {props.allowMultiple && (
            <OptionSelectFooter
              apply={applySelection}
              reset={cancel ?? reset}
              resetLabel={cancel ? t.ui.SelectField.cancel : undefined}
              loading={props.loading}
              disabled={disableSave}
            />
          )}
        </DropdownMenu>
      </Dropdown>
    </div>
  );
};
