import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useAppContext } from 'src/lib/global';
import * as t from './__types/useFolders';

export const FOLDER_QUERY = gql`
  query useFolders($customerId: ID!, $ids: [ID!]!) {
    customer(id: $customerId) {
      id
      documentArchive {
        folders(input: { ids: $ids }) {
          id
          name
          protected
          users {
            id
            contact(customerId: $customerId) {
              id
              firstName
              lastName
              email
              user {
                id
              }
            }
          }
        }
      }
    }
  }
`;

export const useFolders = (ids: string[]) => {
  const appContext = useAppContext();
  const { data, loading, error } = useQuery<
    t.useFolders,
    t.useFoldersVariables
  >(FOLDER_QUERY, {
    variables: {
      customerId: appContext.activeCustomerId || '',
      ids,
    },
    fetchPolicy: 'network-only',
    skip: !appContext.activeCustomerId,
  });

  return { folders: data?.customer?.documentArchive?.folders, loading, error };
};
