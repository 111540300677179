import React, { useState, useEffect } from 'react';

import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { TextField, Checkbox, FormSubmitBar } from 'src/lib/ui';

/**
 * Common for all modals
 */
type GenericModalProps<T> = {
  onCancel?: () => void;
  onSubmit: (values: T) => void | Promise<void>;
  // trigger: React.ReactElement<{ onClick: (e: Even<HTMLButtonElement>) => void; }>;
  trigger: React.ReactElement<{ onClick: any }>;
  initialValues: T;
};

/**
 * For this specific modal
 */
type DefinitionModalProps = {
  mode: 'add' | 'edit';
};

// Represents values going out of the modal; the modal result
interface Values {
  key: string;
  description: string;
  exposeToFrontend: boolean;
}

type Props = DefinitionModalProps & GenericModalProps<Values>;

export function DefinitionModal(props: Props) {
  const [values, setValues] = useState<Values>(props.initialValues);
  const [open, setOpen] = useState(false);

  useEffect(() => {
    setValues(props.initialValues);
  }, [props.initialValues]);

  function onClick() {
    setOpen(true);
  }
  const trigger = React.cloneElement(props.trigger, { onClick });

  function toggleState() {
    setOpen(!open);
  }

  function submit() {
    props.onSubmit(values);
    setOpen(false);
  }

  return (
    <>
      {trigger}
      <Modal isOpen={open} toggle={toggleState}>
        <ModalHeader>
          {props.mode === 'edit' ? 'Edit feature flag' : 'Add feature flag'}
        </ModalHeader>
        <ModalBody className="p-4">
          <TextField
            label="Key"
            id="key"
            value={values.key}
            onChange={e => setValues({ ...values, key: e.currentTarget.value })}
            readOnly={props.mode === 'edit'}
          />

          <TextField
            label="Description"
            className="mt-3"
            id="description"
            value={values.description}
            onChange={e =>
              setValues({ ...values, description: e.currentTarget.value })
            }
          />

          <Checkbox
            label="Expose to frontend"
            className="mt-3"
            id="exposeToFrontend"
            checked={values.exposeToFrontend}
            onChange={e =>
              setValues({
                ...values,
                exposeToFrontend: e.currentTarget.checked,
              })
            }
          />
        </ModalBody>
        <ModalFooter>
          <FormSubmitBar cancel={toggleState} submit={submit} />
        </ModalFooter>
      </Modal>
    </>
  );
}
