import gql from 'graphql-tag';
import { useMutation, ExecutionResult } from 'react-apollo';
import { ApolloError } from 'apollo-client';
import useStructureCacheUpdater from '../lib/useStructureCacheUpdater';
import { FileFragment } from 'src/lib/types';
import * as t from './__types/useAlterFile';

const MUTATION_ALTER_FILE = gql`
  mutation useAlterFile($input: AlterFileInput) {
    alterFile(input: $input) {
      file {
        ...FileSearchResult
      }
      error {
        message
        code
      }
    }
  }
  ${FileFragment}
`;

export type AlterFileFunc = (
  fileId: string,
  folderId?: string,
  name?: string
) => Promise<ExecutionResult<t.useAlterFile>>;
export interface AlterFileResult {
  alterFile: AlterFileFunc;
  loading: boolean;
  error?: ApolloError;
  data?: t.useAlterFile;
}

export const useAlterFile = (): AlterFileResult => {
  const cacheUpdater = useStructureCacheUpdater<t.useAlterFile>();
  const [mutation, { data, loading, error }] = useMutation<
    t.useAlterFile,
    t.useAlterFileVariables
  >(MUTATION_ALTER_FILE);

  const alterFile = (fileId, folderId, name) =>
    mutation({
      variables: {
        input: {
          name,
          folderId,
          id: fileId,
        },
      },
      update: cacheUpdater((ff, muationData) => {
        if (muationData?.alterFile?.file) {
          const files: t.useAlterFile_alterFile_file[] = ff.files.map(f =>
            f?.id === fileId && muationData.alterFile?.file
              ? muationData.alterFile?.file
              : f
          );
          return {
            ...ff,
            files,
          };
        }
        return ff;
      }),
    });

  return { alterFile, loading, error, data };
};
