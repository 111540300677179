import React from 'react';
import { Chip, urlFor } from 'src/lib/ui';
import { Contact, Tag } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { getContactDisplayName } from 'src/areas/main/incidents/utils/incidentUtils';
import { RoleName, SingleRole } from '../createContact/types';
import { Icon, IconDefinition } from '@telia/styleguide';
import { Organisation } from '../../../cost/lib';
import { useRouter } from 'src/lib/utils/useRouter';
import { ExpansionPanel } from 'src/lib/ui/ExpansionPanel/ExpansionPanel';

interface Props {
  contact: Contact;
}

const groupRoles = (
  roles: SingleRole[]
): {
  [role in RoleName]?: {
    organisations: Array<Organisation>;
    tags: Array<Tag>;
  };
} => {
  const groupedRoles = {};
  roles?.forEach(role => {
    groupedRoles[role.role] = {
      tags: [
        ...(groupedRoles[role.role]?.tags || []),
        ...(role.tag ? [role.tag] : []),
      ],
      organisations: [
        ...(groupedRoles[role.role]?.organisations ?? []),
        ...(role.organisation ? [role.organisation] : []),
      ],
    };
  });

  return groupedRoles;
};

const areaIcon = (role: RoleName): IconDefinition => {
  if (role === 'SystemAdministrator') {
    return 'internet';
  } else if (role === 'OrderManager') {
    return 'shoppingcart';
  } else if (role === 'FinancialManager') {
    return 'money';
  } else if (role === 'CustomerAdministrator') {
    return 'company';
  } else {
    return 'internet';
  }
};

const areaPermission = (full: boolean, role: RoleName) => {
  return `${
    role === 'CustomerAdministrator'
      ? t.contacts.admin
      : full
      ? t.contacts.fullAccess + ' '
      : t.contacts.partialAccess + ' '
  } ${
    role === 'FinancialManager'
      ? t.contacts.accessTo.finance
      : role === 'OrderManager'
      ? t.contacts.accessTo.ordering
      : role === 'SystemAdministrator'
      ? t.contacts.accessTo.services
      : ''
  }`;
};

export const ContactPermissions: React.FC<Props> = ({ contact }) => {
  const i18n = t.contacts;
  const { history } = useRouter();
  const groupedRoles = groupRoles(contact?.user?.customerAccess?.roles ?? []);
  const keys = Object.keys(groupedRoles) as RoleName[];

  return (
    <div>
      {keys?.length ? (
        keys.map((r, i) => (
          <ExpansionPanel
            key={i}
            icon={areaIcon(r)}
            heading={areaPermission(
              groupedRoles[r]?.organisations.length === 0 &&
                groupedRoles[r]?.tags.length === 0,
              r
            )}
          >
            {!!groupedRoles[r]?.organisations.length ||
            !!groupedRoles[r]?.tags.length ? (
              <div className="p-4">
                {groupedRoles[r]?.organisations.length ? (
                  <div className="pb-4">
                    <small className="d-block pb-2">
                      {i18n.accessToOrganisations}:
                    </small>
                    {groupedRoles[r]?.organisations.map(org => (
                      <Chip
                        key={org.id}
                        label={org.name}
                        className="mr-2"
                        mode="filter"
                        onClick={e => {
                          history.push(
                            urlFor({
                              organisation: org.id,
                            })
                          );
                        }}
                      />
                    ))}
                  </div>
                ) : null}

                {groupedRoles[r]?.tags.length ? (
                  <div className="pb-4">
                    <small className="d-block pb-2">{i18n.accessToTags}:</small>
                    {groupedRoles[r]?.tags.map(tag => (
                      <Chip
                        label={tag.tag}
                        key={tag.id}
                        mode="filter"
                        className="mr-2"
                        onClick={e => {
                          history.push(
                            urlFor({
                              area: 'subscriptions',
                              filterByTag: tag.id,
                            })
                          );
                        }}
                      />
                    ))}
                  </div>
                ) : null}
              </div>
            ) : null}
          </ExpansionPanel>
        ))
      ) : (
        <div className="p-4">
          <Icon className="mr-3" icon="internet" />
          {i18n.noPermissions(getContactDisplayName(contact))}
        </div>
      )}
    </div>
  );
};
