export const activeIncidentText = (
  {
    activeIncidents = 0,
    activeSolvedIncidents = 0,
  }: {
    activeIncidents?: number;
    activeSolvedIncidents?: number;
  } = {},
  useNewActiveIncidents: boolean
) => {
  if (useNewActiveIncidents) {
    if (activeIncidents > 0) {
      return activeIncidents - activeSolvedIncidents;
    }
  }
  return activeIncidents || '0';
};
