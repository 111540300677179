import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { ContactWithNotificationSettingsFragment } from 'src/lib/types/contact/ContactWithNotificationSettingsFragment';
import * as types from './__types/useCopyNotificationSettings';

export const MUTATION_COPY_NOTIFICATIONS = gql`
  mutation useCopyNotificationSettings(
    $input: CopyNotificationSettingsInput
    $customerId: ID!
  ) {
    copyNotificationSettings(input: $input) {
      contact {
        ...ContactWithNotificationSettingsFragment
      }
      error {
        message
        code
      }
    }
  }
  ${ContactWithNotificationSettingsFragment}
`;

export const useCopyNotificationSettings = () => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    types.useCopyNotificationSettings,
    types.useCopyNotificationSettingsVariables
  >(MUTATION_COPY_NOTIFICATIONS);
  const copyNotificationSettings = (
    fromContactId: string,
    toContactId: string
  ) =>
    mutation({
      variables: {
        customerId: customer.id,
        input: {
          customerId: customer.id,
          fromContactId,
          toContactId,
        },
      },
    });
  return {
    copyNotificationSettings,
    data,
    loading,
    error: !!error || !!data?.copyNotificationSettings?.error,
  };
};
