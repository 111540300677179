import gql from 'graphql-tag';
import { MobileOrderDetailsFragment } from 'src/areas/main/ordering/lib/MobileOrderDetailsFragment';
import { MobileOrderDetails } from 'src/areas/main/ordering/types';
import { useMutation, ExecutionResult } from 'react-apollo';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';

const WITH_ACTIVATE_SIM = gql`
  mutation useReplaceSimCard($input: ReplaceSimCardOrderInput) {
    replaceSimCardOrder(input: $input) {
      orderDetails {
        ...MobileOrderDetailsResult
      }
      error {
        code
      }
    }
  }
  ${MobileOrderDetailsFragment}
`;

type Variables =
  | {
      input: {
        customerId: string;
        msisdn: string;
        simCardId?: string;
      };
    }
  | {
      input: {
        customerId: string;
        msisdn: string;
        newSimCardRecipient?: {
          name: string;
          address: string;
          postalCode: string;
          postalArea: string;
          attention: string;
        };
      };
    };

interface Data {
  replaceSimCardOrder: {
    orderDetails: MobileOrderDetails;
    error: {
      code: string;
    };
  };
}

export interface ReplaceSimResult {
  replaceSimCard: (
    simCardId?: string,
    newSimCardRecipient?: {
      name: string;
      address: string;
      postalCode: string;
      postalArea: string;
      attention: string;
    }
  ) => Promise<ExecutionResult<Data>>;
  data?: MobileOrderDetails;
  loading: boolean;
  error: boolean;
}
export const useReplaceSimCard = (): ReplaceSimResult => {
  const { id, customerId } = useSubscriptionContext();
  const [mutation, { data, loading, error }] = useMutation<Data, Variables>(
    WITH_ACTIVATE_SIM
  );

  const replaceSimCard = async (
    simCardId?: string,
    newSimCardRecipient?: {
      name: string;
      address: string;
      postalCode: string;
      postalArea: string;
      attention: string;
    }
  ) =>
    await mutation({
      variables: {
        input: {
          customerId,
          msisdn: id,
          simCardId,
          newSimCardRecipient,
        },
      },
    });

  return {
    replaceSimCard,
    loading,
    data: data ? data.replaceSimCardOrder.orderDetails : undefined,
    error: !!error || !!(data && data.replaceSimCardOrder.error),
  };
};
