import { push } from './Analytics';
import { getCategory } from './utils';
import { trackInvoiceDownload, trackServicesDownload } from 'src/lib/analytics';

export function trackFilter(
  url: string,
  filter: string,
  value?: string | string[] | number | null
) {
  let subscriptionGroup = '';

  if (url.includes('meldinger')) {
    const values = url.split('/');
    subscriptionGroup = values[1];
    value = values[2];
  } else if (url.includes('tjenester')) {
    subscriptionGroup = url.split('/')[2];
  } else if (url.includes('kontakter')) {
    subscriptionGroup = url.split('/')[1];
  }

  const category = getCategory(subscriptionGroup);
  push({
    category: category,
    action: 'filter',
    label: filter,
    value: value?.toString() ?? undefined,
  });
}

export function trackDownload(url: string) {
  if (url.includes('invoice')) {
    trackInvoiceDownload(url);
  }

  if (url.includes('report') && url.includes('subscriptions')) {
    trackServicesDownload(url);
  }
}
