import React from 'react';
import { Contact, getSubscriptionDisplayTitles } from 'src/lib/types';
import './DeleteContactModal.scss';
import { formatName } from 'src/lib/utils/formatName';
import { ReplaceTechnicalContact } from './ReplaceTechnicalContact';
import { formatInstallationAddress } from 'src/lib/utils';
import { getGroupLabel } from 'src/areas/main/subscriptions/utils';
import { DeletePersonConfirmationModal } from './DeletePersonConfirmationModal';

interface Props {
  contact: Contact;
}

export const DeletePerson = (props: Props) => {
  const contactSubscriptions = props.contact.technicalContactSubscriptions?.map(
    s => ({
      id: s.id,
      name: getSubscriptionDisplayTitles(s).title,
      address: s.installationAddress
        ? formatInstallationAddress(s.installationAddress, 'fullAddress')
        : '',
      type: `${s.group ? ` (${getGroupLabel(s.group)})` : ''}`,
    })
  );

  return (
    <>
      {contactSubscriptions?.length ? (
        <ReplaceTechnicalContact
          technicalContactSubscriptions={contactSubscriptions}
          contactId={props.contact.id}
        />
      ) : (
        <DeletePersonConfirmationModal
          contactId={props.contact.id}
          contactName={formatName({
            firstName: props.contact.firstName,
            lastName: props.contact.lastName,
          })}
          isUser={!!props.contact.user}
        />
      )}
    </>
  );
};
