import * as Sentry from '@sentry/browser';
import config from 'src/config';

Sentry.init({
  environment: config.environment,
  release: config.gitCommit,
  dsn: config.sentryDsn,
});

/**
 * Attach Sentry to window for more convenient testing of error handling in QA/Prod.
 */
(window as any).Sentry = Sentry;
