import * as React from 'react';
import Table from 'reactstrap/lib/Table';
import { CostMatrix, categoryDefinitions } from '..';
import { formatNordic } from 'src/lib/utils';
import { t } from 'src/lib/i18n';

import './OrganisationCostSummaryTable.scss';
import { Icon } from '@telia/styleguide';

interface Props {
  costMatrix: CostMatrix;
  costType: 'gross' | 'net';
}

const OrganisationCostSummaryTable: React.SFC<Props> = ({
  costMatrix,
  costType,
}) => {
  const categoryDefs = categoryDefinitions();
  const i18n = t.costs.ui.OrganisationCostSummaryTable;
  return (
    <Table striped={true} className="OrganisationCostSummaryTable">
      <thead>
        <tr>
          <th>{i18n.category}</th>
          <th>{i18n.usage}</th>
          <th>{i18n.fixedCost}</th>
          <th>{i18n.oneTimeFee}</th>
          <th>{`Sum (${costType === 'gross' ? i18n.vat : i18n.noVat})`}</th>
        </tr>
      </thead>
      <tbody>
        {categoryDefs.map(cd => (
          <tr key={cd.label} style={{ borderLeft: `5px solid ${cd.color}` }}>
            <td style={{ color: cd.color }}>
              <Icon icon={cd.icon} className="pr-2" />
              {cd.label}
            </td>
            <td>{formatNordic(costMatrix[cd.key].usage[costType], 2)}</td>
            <td>
              {formatNordic(costMatrix[cd.key].subscription[costType], 2)}
            </td>
            <td>{formatNordic(costMatrix[cd.key].oneTime[costType], 2)}</td>
            <td>{formatNordic(costMatrix[cd.key].total[costType], 2)}</td>
          </tr>
        ))}
      </tbody>
      <tfoot>
        <tr>
          <td>Total</td>
          <td>{formatNordic(costMatrix.total.usage[costType], 2)}</td>
          <td>{formatNordic(costMatrix.total.subscription[costType], 2)}</td>
          <td>{formatNordic(costMatrix.total.oneTime[costType], 2)}</td>
          <td>{formatNordic(costMatrix.total.total[costType], 2)}</td>
        </tr>
      </tfoot>
    </Table>
  );
};
export default OrganisationCostSummaryTable;
