import { customerSupportEmail, customerSupportNumber } from './../../variables';
const subscriptions = {
  title: 'Tjenester',
  unknown: 'Ukjent',
  case: 'sak',
  cases: 'saker',
  download: 'Last ned',
  allServices: 'Alle tjenester',
  selectedServices: 'Valgte tjenester',
  sortFields: {
    lable: 'Sorter',
    alias: 'Alias',
    status: 'Status',
    type: 'Type',
    address: 'Adresse',
    updated: 'Sist endret',
    id: 'ID',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    AtoÅ: 'A til Å',
    ÅtoA: 'Å til A',
    zeroToNine: '0 til 9',
    nineToZero: '9 til 0',
    descending: 'Synkende',
    ascending: 'Stigende',
    mostRecent: 'Nyeste først',
    oldest: 'Eldste først',
    importantFirst: 'viktigste først',
    leastImportant: 'minst viktig først',
  },
  breadCrumbs: {
    title: 'Tjenester',
    singleService: 'Tjeneste',
  },
  showXofY: (showing: number, total?: number) =>
    `Viser ${showing} av ${total} tjenester`,
  xCount: (count: number) => `${count} nummer`,
  noSubscriptions: 'Vi har ingen tjenester å vise deg',
  groups: {
    data: 'Data',
    mobile: 'Mobil',
    tele: 'Telefoni',
    other: 'Annet',
  },
  taxModel: {
    department: {
      title: 'Avdeling',
      description:
        'felles abonnement som kan brukes av flere ansatte på en avdeling',
    },
    free: {
      title: 'Fri',
      description: 'abonnement som kan brukes både på jobb og privat',
    },
    office: {
      title: 'Kontor',
      description: 'abonnement som kun brukes på jobb',
    },
    personal: 'Personlig',
    none: 'Ikke satt',
  },

  filters: {
    combineTags: 'Kombiner tags',
    operationalStatus: 'Driftsstatus',
    withFaults: 'Med feil',
    withUnreportedFaults: 'Med urapporterte feil',
    withPlannedWork: 'Med planlagt arbeid',
    withNoTags: 'Tjenester uten tags',
    deliveryStatus: {
      label: 'Leveransestatus',
      underDelivery: 'Under levering',
      underTermination: 'Under terminering',
    },
    cases: {
      label: 'Saker',
      withIncidents: 'Med feilmelding',
      withoutIncidents: 'Uten feilmelding',
      withPlannedWork: 'Med planlagt arbeid',
      withoutPlannedWork: 'Uten planlagt arbeid',
    },
    cpeType: {
      label: 'Cpe-modell',
    },
    organisation: {
      label: 'Fakturasted',
    },
    subscriptionType: {
      label: 'Type',
    },
  },

  tags: {
    tags: 'tags',
    tag: 'tag',
    changeButton: 'Endre',
    changedTagsForServices: (numServices: number) =>
      `Endret tags for ${numServices} tjenester`,
    errors: {
      addToSubscription: (subscription: string) =>
        `Vi klarte ikke legge til tags på tjeneste ${subscription}`,
      addTag: (tag: string) =>
        `Vi klarte ikke legge til tag ${tag} på tjenestene `,
      genericAdd: 'Vi klarte ikke legge til tags på tjenestene',
      removeFromSubscription: (subscription: string) =>
        `Vi klarte ikke fjerne tags fra tjeneste ${subscription}`,
      removeTag: (tag: string) =>
        `Vi klarte ikke fjerne tag ${tag} fra tjenestene `,
      genericRemove: 'Vi klarte ikke fjerne tags fra tjenestene',
    },
    createNewTag: 'Opprett ny tag',
  },

  status: {
    title: 'Status',
    updateStatus: 'Oppdater status',
    reportIncident: 'Meld feil',
    viewStatistics: 'Se statistikk',
    primary: 'Primær',
    redundancy: 'Redundans',
    notMonitored: 'Ikke overvåket',
    notInstalled: 'Ikke installert',
  },

  SubscriptionConfiguration: {
    configuration: 'Konfigurasjon',
    tabs: {
      interfaces: 'Interfaces',
      addons: 'Tilhørende tjenester',
      technicalInfo: 'Teknisk informasjon',
    },
  },

  SubscriptionActiveIncidentCard: {
    opened: (date: string) => `Opprettet den ${date}`,
    status: (status: string) => `Feilmelding ${status}`,
  },
  SubscriptionPlannedWorkCard: {
    plannedWorkHeader: 'Planlagt arbeid',
    expectedDowntime: 'Forventet nedetid',
    impact: 'Påvirkning',
    hours: ' t ',
    to: ' til ',
    noExpectedDowntime: 'Ingen informasjon om nedetid oppgitt',
    contact: `Har du spørsmål om dette kan du kontakte oss via chat, ved å ringe ${customerSupportNumber} eller sende e-post til ${customerSupportEmail}`,
    status: 'Status',
    reference: 'Referansenummer',
    startTime: 'Starttidspunkt',
    endTime: 'Slutttidspunkt',
    details: 'Detaljer',
    close: 'Lukk',
  },
  SubscriptionPage: {
    service: 'tjeneste',
    // We want this to be empty (to avoid flashing this in the title e.g. after a session timeout), but be truthy (so it's rendered)
    noData: ' ',
    btnGiveAlias: 'Gi tjenesten et alias',
    btnSetTags: 'Tag tjenesten',
    btnEditAlias: 'Endre alias',
    btnEditTags: 'Endre tags',
    btnEdit: 'Endre',
    btnEditCostCenter: 'Endre kostnadssted',
    tabs: {
      summary: 'Sammendrag',
      incidents: 'Feilmeldinger',
      cases: 'Saker',
      statistics: 'Statistikk',
      documents: 'Dokumenter',
    },
  },
  SubscriptionCard: {
    withoutIncident: 'Uten feilmelding',
    incidentReported: 'Feilmelding registrert',
    labelType: 'Type',
    labelID: 'ID',
    labelServiceBandwidth: 'Kapasitet',
    labelPosition: 'Posisjon',
    labelCpeType: 'cpe type',
  },
  SubscriptionAbout: {
    title: 'Om tjenesten',
    alias: 'Alias',
    type: 'Tjenestetype',
    id: 'ID',
    address: 'Adresse',
    connection: 'Sambandsnummer',
    accessBandwidth: 'Aksesskapasitet',
    serviceBandwidth: 'Kapasitet',
    supplier: 'Leverandør',
    accessType: 'Aksess type',
    networkToNetworkInterface: 'NNI ODP',
    unusedCapacity: 'Ingen - Du har ubenyttet kapasitet på linjen',
    sla: 'Service level agreement (SLA)',
    startTime: 'Opprettet',
    endTime: 'Gyldig til',
    endTimeNotSet: 'Løpende',
  },
  SubscriptionCostDetails: {
    title: 'Kostnadsinnstillinger',
    costCenter: 'Kostnadssted',
    btnChange: 'Endre',
    changeCostSettings: 'Endre kostnadsinnstillinger',
    errorMsg:
      'Vi klarte ikke å gjennomføre endringen for deg. Hvis feilen vedvarer kan du ringe kunderservice på tlf. +47 21 50 21 50, så hjelper vi deg med å få løst feilen',
    selectCostCenter: 'Velg kostnadssted',
    btnAdd: 'Legg til kostnadsinnstillinger',
    taxModel: 'Skattemodell',
  },
  M2MUsage: {
    operator: 'Operatør',
    usage: 'forbruk',
    dataUsage: 'Dataforbruk',
    subscriptionAndData: 'Abonnement og data',
    speedReduction: 'Hastighetsreduksjon etter',
    subscription: 'Abonnement',
    dataIncluded: 'Data inkludert',
    ongoingUsage: 'Løpende forbruk',
    dataUsed: 'Data brukt',
    mainSimCard: 'Hoved SIM-kort',
    history: 'Historikk',
    pastFourMonths: 'Siste 4 måneder',
    showUsageDetails: 'Vis forbruksdetaljer',
    showDetailAboutSubscription: 'Vis detaljer om abonnementet',
  },
  SubscriptionCostCenterPicker: {
    title: 'Sett kostnadssted',
    filterPlaceholder: 'Søk etter kostnadssted',
  },
  SubscriptionDeliveryStatusCard: {
    assigning: {
      status: 'Tjenesten er under levering',
      statusShort: 'Under levering',
      infoText:
        'Du kan oppleve at informasjonen her er feil eller mangelfull frem til leveransen er ferdigstilt.',
    },
    terminating: {
      status: 'Tjenesten er under terminering',
      statusShort: 'Under terminering',
      infoText:
        'Denne tjenesten er under terminering og vil snart fjernes fra tjenesteoversikten.',
    },
    support: `Har du spørsmål om dette kan du kontakte oss via chat, ved å ringe ${customerSupportNumber} eller sende e-post til ${customerSupportEmail}`,
  },
  SubscriptionStatusSummaryCard: {
    checkingService: 'Sjekker tjenesten',
    isNormal: 'kjører som normalt',
    noSignal: 'gir ikke signal',
    unknownPrimary: 'Vi vet ikke status til primær',
    unknownRedundancy: 'Vi vet ikke status til redundans',
    notInstalled: 'Tjenesten har ikke blitt installert enda',
    statusUnknow: 'Vi vet ikke status til tjenesten',
    serviceIsNormal: 'Tjenesten fungerer som den skal',
    serviceNoSignal: 'Tjenesten gir ikke signal',
    cpeModel: 'CPE-modell',
    uptime: 'Oppetid CPE',
    responsetime: 'Responstid',
    title: 'Status',
    primary: 'Primær',
    redundancy: 'Redundans',
    btnReportError: 'Meld feil',
    btnViewStatistics: 'Se statistikk',
    btnRefetch: 'Sjekk status',
    btnRefetching: 'Sjekker status og pinger tjenester...',
    gsm: {
      title: 'Signal',
      band: 'Bånd',
      channelNumber: 'Kanalnummer',
      timeStamp: 'Sist sjekket',
    },
  },
  SubscriptionStatusItem: {
    noStatus: 'Ingen status',
  },
  ConnectionStretch: {
    startPoint: 'Startpunkt',
    endPoint: 'Sluttpunkt',
    stretch: 'Strekning',
  },
  SignalCard: {
    bandNumber: 'Bånd',
    channelNumber: 'Kanal',
    title: 'Signal',
    strength: 'Styrke(RSSI)',
    quality: 'Kvalitet(EC/IO)',
    signal: 'signal',
    showDetailedSignalStatistics: 'Vis detaljert signalstatistikk',
    signalType: {
      unknown: 'Ukjent',
      gen4: '4G',
      gen3: '3G',
      gen2: '2G',
    },
    lastUpdated: (time: string) => `Sist oppdatert for ${time} siden`,
  },
  NotMonitored: {
    title:
      'Denne tjenesten er ikke satt til monitorering og vi har derfor ingen informasjon om status.',
    description: `Mener du dette er feil? Kontakt oss via chat, ved å ringe ${customerSupportNumber} eller send en e-post til ${customerSupportEmail}. Husk å ta med ID-nummeret på tjenesten.`,
  },
  Documents: {
    title: 'Dokumenter',
  },

  SubscriptionCpeCard: {
    title: 'Utstyr',
    cpeModel: 'CPE-modell',
    uptime: 'Oppetid på CPE',
    days: 'dager',
  },
  Incidents: {
    header: 'Feilmeldinger for',
    title: 'Feilmeldinger',
    noIncidnets: 'Det er ingen feilmeldinger på tjenesten',
    showing: (count: number) => `Viser ${count} feilmeldinger`,
  },

  SubscriptionInterfaces: {
    status: 'Status',
    interface: 'Interface',
    alias: 'Alias',
    VPNVRF: 'VPN/VRF',
    devices: 'Enheter',
    title: 'Interfaces',
    criticalError: 'Kritisk feil',
    showLess: 'Vis færre',
    showAll: (count: number) => `Vis alle ${count} interfaces`,
  },
  Addons: {
    title: 'Tilhørende tjenester',
    noAddons: 'Det er ingen tilhørende tjenester på denne tjenesten',
    status: 'Status',
    alias: 'Alias',
    type: 'Type',
    vpn: 'VPN/VRF',
  },
  TechnicalDetailsCard: {
    title: 'Teknisk informasjon',
    lanIp: 'LAN IP',
    wanIp: 'WAN IP',
    dmVpnIp: 'DMvpn IP',
    staticRouting: 'Statisk routing',
    mpls: 'Multiprotocol Label Switching (MPLS)',
    vrf: 'VRF',
    vrfName: 'VRF-navn',
    vrfAlias: 'VPN-alias',
    vrfId: 'VPN-id',
    networkAddress: 'Nettverksadresse',
    subnetMask: 'Subnettmaske',
    hsrpIpAddress: 'HSRP IP address',
    interfaceId: 'Interface-ID',
    interfaceMode: 'Interfacemodus',
    gateway: 'Gateway',
    cpeAddress: 'CPE adresse',
    noRedist: 'Ingen',
    ngfw: {
      header: 'New generation firewall (NGFW)',
      protectionPackage: 'Beskyttelsespakke',
      totalCapacity: 'Total kapasitet',
      location: 'Lokasjon',
      binding: 'Bindingstid',
      httpsInspection: 'HTTPS inspeskjon',
      idAwareness: 'ID bevissthet',
      eventReports: 'Event rapporter',
      logApi: 'Logg api',
    },
    editLanIP: {
      edit: 'Endre',
      changeLanIP: 'Endre LAN IP-adresser',
      description: (subsId: string) =>
        `Velg hvilke LAN IP-adresser som skal være tilgjengelige ved IP VPN ${subsId}`,
      changeAddress: 'Endre adresser',
      address: 'Adresse',
      whatsNext: 'Hva skjer videre?',
      whatsNextDescription:
        'Når du fullfører endringen blir den sendt videre til en av våre teknikere. Du vil få en bekreftelse i ordreoversikten når endringen iverksettes. Der kan du følge med på hva som skjer videre.',
      submitLabel: 'Fullfør endringen',
      changeSubmitted: 'Endringen er sendt',
      submittedDescription: [
        'Du får snart en bekreftelse i ordreoversikten om at endringen er iverksatt. Der kan du se mer om når endringen vil tre i kraft.',
        'I bekreftelsen får du også mer informasjon om hva som skjer videre.',
      ],
      goBackToService: 'Gå tilbake til tjenesten',
    },
    editIpRouting: {
      edit: 'Endre',
      close: 'Lukk',
      changeIpRouting: 'Endre routing',
      submittedDescription: [
        'Du får snart en bekreftelse i ordreoversikten om at endringen er iverksatt. Der kan du se mer om når endringen vil tre i kraft, og hva som skjer videre.',
      ],
      description:
        'Ved å endre routing-adresse kan du definere hvordan du ønsker at trafikken skal bevege seg i nettet. Med statisk routing unngår du overhead som kommer med dynamisk routing.',
      onCompleteDescription:
        'Når du fullfører endringen blir den sendt videre til en av våre teknikere. Du vil få en bekreftelse i ordreoversikten når endringen iverksettes.',
      submitLabel: 'Fullfør endringen',
    },
  },

  AdditionalInformationCard: {
    title: 'Tilleggsinformasjon',
    btnEdit: 'Endre',
    btnAdd: 'Legg til tilleggsinformasjon',
  },
  TechnicalContactsCard: {
    title: 'Kontaktpersoner',
    phoneNumber: 'Tlf.',
    noContacts: 'Ingen kontaktpersoner på tjenesten',
    searchPlaceholder: 'Søk etter person',
    editTitle: 'Endre kontaktpersoner',
    btnEdit: 'Endre',
    btnAdd: 'Legg til kontaktpersoner',
    editDescription:
      'Du kan legge til opp til tre kontaktpersoner på en tjeneste. Dersom du ønsker å legge til en helt ny kontakt må du først opprette en ny person inne på kontaktsiden.',
    editError: 'Kunne ikke endre kontaktpersoner',
  },
  TeleSubscriptionPage: {
    phoneNumbers: 'Nummer',
  },
  TeleSubscriptionAbout: {
    heading: 'Detaljer',
    id: 'ID',
    type: 'Type',
    endTime: 'Varighet',
    sla: 'SLA',
    noEndTime: 'Løpende avtale',
  },
  TvSubscriptionAbout: {
    heading: 'Detaljer',
    alias: 'Alias',
    aliasNotSet: 'ikke satt',
    id: 'ID',
    type: 'Tjenestetype',
    createdAt: 'Opprettet',
    validUntil: 'Gyldig til',
    validUntilNotSet: 'Løpende',
    customerType: 'Kundetype',
    distributionTech: 'Distribusjonsteknologi',
    modelType: 'Modelltype',
    hdmiCount: 'Antall HDMI',
    tags: 'Tags',
    editAlias: 'Endre alias',
    editTags: 'Endre tags',
  },
  TvSubscriptionPage: {
    text:
      'Telia Visning lar bedriften vise innhold ut på digitale skjermflater, enten dette er kommersielle kanaler eller eget innhold. Tjenesten består av TV-visning, infokanal og digital skilting.',
    contentServices: 'Innholdstjenester',
  },
  TvSubscriptionContentList: {
    outletsAvailable: 'Ant. tilgjenglige uttak',
    seatsAvailable: 'Ant. tillatte seter',
    boxes: 'Ant. Get-bokser',
    numAvailable: 'Ant. tilgjenglige',
    prefix: {
      mainChannel: 'TV grunnpakke:',
      extraChannel: 'Tilleggskanal:',
      addon: 'TV Add-on:',
    },
  },
  AboutMobileSubscriptionUserCard: {
    title: 'Abonnenten',
    name: 'Navn',
    employeeNumber: 'Ansattnummer',
    employeePosition: 'Stilling',
    phoneNumber: 'Telefonnummer',
    email: 'E-post',
    btnEdit: 'Endre',
    directoryListing: 'Katalogoppføring',
  },
  EditAdditionalInformationForm: {
    title: 'Rediger tilleggsinformasjon om tjenesten',
    noValue: 'Ingen verdi valgt',
    alternatives: 'Alternativer',
    errorMsg: `Vi klarte ikke å gjennomføre endringen for deg. Hvis feilen vedvarer kan du ringe kunderservice på tlf. ${customerSupportNumber}, så hjelper vi deg med å få løst feilen`,
  },
  GenericSubscriptionPage: {
    aboutSubscription: 'Om tjenesten',
  },
  NoData: {
    weWereUnableToFetchData: 'Vi fikk ikke hentet data om',
    tryTo: 'Forsøk å',
    reloadThePage: 'laste inn siden på nytt.',
    helpText: `Hvis feilen vedvarer kan du ringe kunderservice på tlf. ${customerSupportNumber}, så hjelper vi deg med å få løst feilen`,
  },
  DifferentVendor: {
    title: 'Vi får dessverre ikke hentet data om forbruket.',
    helpText:
      'Tjenesten benytter abonnement og SIM-kort fra en annen leverandør.',
  },
  AboutMobileSubscriptionCard: {
    details: 'Detaljer',
    subscription: 'Abonnement',
    fixedCost: 'Fast kostnad',
    dataIncluded: 'Inkludert data',
    freeUse: 'fri bruk',
    serviceType: 'Tjenestetype',
    id: 'ID',
    started: 'Opprettet',
    validTo: 'Varighet',
    continuous: 'løpende avtale',
    cancelSubscription: 'Avslutt abonnement',
  },
  EditSubscriptionAlias: {
    description: 'Rediger alias for tjenesten',
    alias: 'Alias',
    toggleBtnEdit: 'Endre alias',
    toggleBtnAdd: 'Gi tjenesten et alias',
  },
  ArpTable: {
    title: 'ARP-tabell',
    interface: 'Interface',
    mac: 'MAC',
    ipAddress: 'IP adresse',
    showLess: 'Vis færre',
    showAll: 'Vis alle',
    noConnectedDevices: 'Ingen tilkoblede enheter',
    close: 'Lukk',
    update: 'Oppdater',
  },
  StatisticsSection: {
    title: 'Statistikk for',
    noStatistics: 'Det finnes ikke statistikk for tjenesten',
    noSubscription: 'Fant ikke tjenesten',
  },
  SubscriptionTagsPicker: {
    title: 'Rediger tags for tjenesten',
    filterPlaceholder: 'Søk etter en tag',
    toggleBtnEdit: 'Endre tags',
    toggleBtnAdd: 'Legg til tags',
  },
  EditSubscriptionUserForm: {
    description: 'Rediger informasjon om brukeren',
    user: 'Abonnent',
    firstName: 'Fornavn',
    lastName: 'Etternavn',
    email: 'E-post',
    postalCode: 'Postnummer',
    postalArea: 'Poststed',
    country: 'Land',
    employeeNumber: 'Ansattnummer',
    employeePosition: 'Stilling',
    street: 'Gateadresse',
    noAddress: 'Ingen oppføring',
    userAddress: 'Abonnentens egne kontaktopplysninger',
    businessAddress: 'Firmaadresse',
    btnSave: 'Lagre',
    directoryListing: 'Katalogoppføring',
  },
  ReportIncidentSection: {
    title: 'Meld feil for',
  },
  Invoices: {
    title: 'Fakturaer',
  },
  MobileSubscription: {
    dataUsage: 'Dataforbruk',
    viewInvoices: 'Se fakturaer',
    summary: 'Sammendrag',
    settings: 'Innstillinger',
    usage: 'Forbruk',
  },
  ConnectedSubscription: {
    subscriptionIsUsedBy: 'Abonnementet brukes av',
  },
  cpeStatus: {
    normal: 'Online',
    critical: 'Offline',
    redundancyOnline: 'Redundans er online',
    redundancyOffline: 'Redundans er offline',
    unknown: 'Ukjent',
    noStatus: 'Ingen status',
    statusNull: 'Ikke overvåket',
    notInstalled: 'Ikke installert',
  },
  deliveryStatus: {
    assigning: 'Under levering',
    active: 'Normal',
    terminating: 'Under terminering',
    terminated: 'Terminert',
  },
  getSubscriptionDisplayTitles: {
    aNumber: 'A-nummer Tlf: ',
    aNumberSerie: 'A-nummerserie Tlf: ',
    phoneNumber: 'Tlf. ',
  },
  ListSubscriptionsByGroup: {
    header: 'Tjenester',
    data: {
      title: 'Nettverk',
    },
    mobile: {
      title: 'Mobil',
    },
    tele: {
      title: 'Telefoni',
    },
    tv: {
      title: 'Tv',
    },
    all: {
      title: 'Alle tjenester',
    },
    filters: {
      query: {
        label: 'Filtrer',
        helpText: 'Tast inn for å filtrere i listen',
      },
      status: {
        label: 'Tjenestefiltrering',
        placeholder: 'Se tjenester med...',
      },
      tags: {
        label: 'Tags',
        placeholder: 'Filtrer på tags',
      },
    },
    noResultMessage: {
      text: 'Ingen tjenester',
    },
  },
  DataSubscriptionTable: {
    status: 'Status',
    cases: 'Saker og tilleggsinfo',
    incident: 'Feilmelding',
    cpeModel: 'Cpe-modell',
    // capacity: 'Kapasitet',
  },
  MobileSubscriptionTable: {
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    deliveryStatus: 'Leveransestatus',
  },
  TeleSubscriptionsList: {
    phoneNumber: 'Telefonnummer',
  },
  TeleSubscriptionTable: {
    phoneNumber: 'Telefonnummer',
    deliveryStatus: 'Leveransestatus',
  },
  GenericSubscriptionTable: {
    address: 'Adresse',
    cases: 'Saker og tilleggsinfo',
    service: 'Tjeneste',
    tags: 'Tags',
    invoiceCenter: 'Fakturasted',
    costCenter: 'Kostnadssted',
  },
  DefaultAboutCard: {
    heading: 'Detaljer',
    type: 'Type',
    id: 'ID',
    created: 'Opprettet',
    validUntil: 'Varighet',
    validNotSet: 'Løpende avtale',
    accessBandwidth: 'Aksesskapasitet',
  },
  DataWithStatusAbout: {
    heading: 'Detaljer',
    alias: 'Alias',
    type: 'Type',
    id: 'ID',
    equipment: 'Utstyr',
    access: 'Aksess',
    accessBandwidth: 'Aksesskapasitet',
    created: 'Opprettet',
    serviceBandwidth: 'Kapasitet',
    transmissionNumber: 'Sambandsnummer',
    sla: 'Serviceavtale',
    validUntil: 'Varighet',
    validUntilNotSet: 'Løpende',
    supplier: 'Leverandør',
    networkToNetworkInterface: 'NNI ODP',
  },
  layout: {
    SubscriptionPageHeader: {
      position: 'Posisjon',
    },
    SubscriptionReportIncidentButton: {
      preText: 'Opplever du at noe ikke fungerer helt som det skal?',
      link: 'Meld feilen',
      postText: 'så hjelper vi deg.',
    },
    SubscriptionLinkToIncidentTab: {
      text: 'Se flere feilmeldinger på denne tjenesten',
    },
  },
  terminate: {
    terminateSubscription: 'Avslutt tjenesten',
    description:
      'Hvis du er sikker på at du vil avslutte tjenesten, kan du fullføre under.',
    invoicePeriodDescription: (date: string) =>
      `Oppsigelsen trer i kraft 3 måneder etter at inneværende fakturaperiode er avsluttet den ${date}`,
    chooseDateForShutdown:
      'Du kan velge dato for nedkobling hvis du vil at tjenesten tas ned før fakturaperioden er avsluttet.',
    dateForShutdown: 'Ønsket dato for nedkobling',
    confirmationDescription:
      'Når du fullfører oppsigelsen blir den snart tatt hånd om. Du får da en bekreftelse i ordreoversikten med mer informasjon.',
    goBack: 'Gå tilbake',
    completeTermination: 'Fullfør oppsigelse',
    serviceTerminated: 'Tjenesten er avsluttet',
    submittedDescription: [
      'Du får snart en bekreftelse for terminering i portalen. Der står det mer om hva som skjer videre.',
    ],
    close: 'Lukk',
  },
  SdWanSummary: {
    title: 'Administrer SD-WAN',
    description:
      'Du kan administrere SD-WAN tjenestene dine i SD-WAN-applikasjonen.',
  },
};

export default subscriptions;
