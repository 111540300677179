import React, { useState } from 'react';
import cs from 'classnames';
import { Button, BodyCard } from 'src/lib/ui';
import './Sidebar.scss';
import { SidebarFilters } from './Filter/SidebarFilters';
import { Subscription } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { SidebarSubscriptionList } from './List/SidebarSubscriptionList';
import { SidebarSubscription } from './Subscription/SidebarSubscription';
import { useTrackMapFilters } from '../useTrackMapFilters';
interface Props {
  activeSubscription: Subscription | undefined;
  searchResult: Subscription[];
  numberOfResults: number | undefined;
  toggleSidebar: () => void;
  isSidebarOpen: boolean;
  isLoading: boolean;
  query: string;
  setQuery: (query: string) => void;
  totalFiltersActive: number;
  locationRef: HTMLDivElement | null;
}

export const Sidebar = (props: Props) => {
  const [filtersOpen, setFiltersOpen] = useState(false);
  useTrackMapFilters();

  let content: JSX.Element = (
    <SidebarSubscriptionList
      query={props.query}
      setQuery={props.setQuery}
      toggleFilters={() => setFiltersOpen(!filtersOpen)}
      loading={props.isLoading}
      searchResult={props.searchResult}
      totalFiltersActive={props.totalFiltersActive}
      totalResults={props.numberOfResults ?? 0}
    />
  );

  if (props.activeSubscription) {
    content = (
      <SidebarSubscription
        activeSubscription={props.activeSubscription}
        locationRef={props.locationRef}
      />
    );
  }

  if (filtersOpen) {
    content = (
      <SidebarFilters toggleFilters={() => setFiltersOpen(!filtersOpen)} />
    );
  }

  return (
    <div className={cs('Sidebar', { narrow: !props.isSidebarOpen })}>
      <Button
        className="Sidebar-toggle"
        icon={props.isSidebarOpen ? 'arrow-left' : 'arrow-right'}
        onClick={props.toggleSidebar}
        hideLabel={true}
      >
        {t.locations.Sidebar.btnClose}
      </Button>
      <BodyCard noGutter={true}>{content}</BodyCard>
    </div>
  );
};
