import React from 'react';
import { StandardStatisticsPeriod } from '../../../queries/WithStatistics';
import { colors } from 'src/lib/ui';
import { formatNordic } from 'src/lib/utils';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { Area, Line } from 'recharts';
import { t } from 'src/lib/i18n';

export const ThroughputChartElements = () => [
  <Line
    key="1"
    strokeWidth="1"
    stroke={colors.corePurple}
    strokeOpacity="0.6"
    dataKey="throughput.maxIn"
    dot={false}
    name={t.statistics.Legend.trafficInMax}
    legendType="circle"
  />,
  <Line
    key="2"
    stroke={colors.blueDark}
    strokeWidth="1"
    strokeOpacity="0.6"
    dataKey="throughput.maxOut"
    dot={false}
    name={t.statistics.Legend.trafficOutMax}
    legendType="circle"
  />,
  <Area
    key="3"
    stroke={colors.corePurple}
    strokeWidth="1"
    fill={colors.corePurple}
    fillOpacity="0.2"
    dataKey="throughput.in"
    name={t.statistics.Legend.trafficInAvg}
  />,
  <Area
    key="4"
    stroke={colors.blueDark}
    strokeWidth="1"
    fill={colors.blueDark}
    fillOpacity="0.2"
    dataKey="throughput.out"
    name={t.statistics.Legend.trafficOutAvg}
  />,
];

/**
 * Area filled dot
 * Line circle
 */
export const ThroughputTooltip = ({
  data,
  hideTimestamp,
}: {
  data: StandardStatisticsPeriod;
  hideTimestamp?: boolean;
}) => {
  const i18n = t.statistics.StandardDetails;
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />

      <Section header={i18n.in}>
        <SectionItem
          title={i18n.throughputAvg}
          color={colors.corePurple}
          value={
            data.throughput?.in
              ? `${formatNordic(data.throughput.in, 5)} Mbit/s ${
                  data.utilization
                    ? data.utilization.in &&
                      `${formatNordic(data.utilization.in, 2)}%`
                    : ''
                }`
              : t.statistics.noData
          }
        />
        <SectionItem
          title={i18n.throughputMax}
          color={colors.corePurple}
          filled={false}
          value={
            data.throughput?.maxIn
              ? `${formatNordic(data.throughput.maxIn, 5)} Mbit/s `
              : t.statistics.noData
          }
        />
      </Section>
      <Section header={i18n.out}>
        <SectionItem
          title={i18n.throughputAvg}
          color={colors.blueDark}
          value={
            data.throughput?.out
              ? `${formatNordic(data.throughput.out, 5)} Mbit/s ${
                  data.utilization
                    ? data.utilization.out &&
                      `${formatNordic(data.utilization.out, 2)}%`
                    : ''
                }`
              : t.statistics.noData
          }
        />
        <SectionItem
          title={i18n.throughputMax}
          color={colors.blueDark}
          filled={false}
          value={
            data.throughput?.maxOut
              ? `${formatNordic(data.throughput.maxOut, 5)} Mbit/s`
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};
