import React from 'react';
import { getSubscriptionDisplayTitles } from 'src/lib/types';
import { SubscriptionPageHeader } from '../../layout';
import { useRouter } from 'src/lib/utils/useRouter';
import { getSubscriptionBreadcrumbs } from 'src/lib/types/subscription/getSubscriptionBreadcrumbs';
import { t } from 'src/lib/i18n';
import { Switch, Route } from 'react-router';
import { TvSubscriptionSummary } from './TvSubscriptionSummary';
import SubscriptionDocuments from '../../documents/SubscriptionDocuments';
import { Loading } from 'src/lib/ui';
import Incidents from '../../incidents/Incidents';
import { useTvSubscription } from './useTvSubscription';
import * as types from './__types/useTvSubscription';

export const TvSubscriptionPageContext = React.createContext<
  types.useTvSubscription_customer_subscriptions
>({} as types.useTvSubscription_customer_subscriptions);

export const TvSubscriptionPage = () => {
  const router = useRouter<{ id: string }>();
  const { data, loading } = useTvSubscription(router.match.params.id);

  if (loading) {
    return <Loading />;
  }

  const subscription = data?.customer?.subscriptions?.[0];
  if (!subscription) {
    return null;
  }

  const breadcrumbs = getSubscriptionBreadcrumbs(subscription);
  const i18n = t.subscriptions.SubscriptionPage;
  const { title } = getSubscriptionDisplayTitles(subscription);

  const navTabs = [
    {
      url: router.match.url,
      label: i18n.tabs.summary,
      exact: true,
    },
    ...(subscription.incidents && subscription.incidents.length
      ? [
          {
            url: `${router.match.url}/saker`,
            label: i18n.tabs.cases,
          },
        ]
      : []),
    {
      url: `${router.match.url}/dokumenter`,
      label: i18n.tabs.documents,
    },
  ];
  return (
    <>
      <SubscriptionPageHeader
        title={title}
        navTabs={navTabs}
        breadcrumbs={breadcrumbs}
        subscription={subscription}
        description={t.subscriptions.TvSubscriptionPage.text}
      />
      <div className="container pt-4">
        <TvSubscriptionPageContext.Provider value={subscription}>
          <Switch>
            <Route
              path={router.match.path}
              exact={true}
              component={TvSubscriptionSummary}
            />

            <Route path={`${router.match.path}/saker`} component={Incidents} />

            <Route
              path={`${router.match.path}/dokumenter`}
              component={SubscriptionDocuments}
            />
          </Switch>
        </TvSubscriptionPageContext.Provider>
      </div>
    </>
  );
};
