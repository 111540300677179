import React from 'react';
import { t } from 'src/lib/i18n';
import { Button, ConfirmationModalButton, urlFor } from 'src/lib/ui';
import { PageHeader } from 'src/areas/main/common/PageHeader/PageHeader';
import { useIncident_customer_incidents } from 'src/areas/main/incidents/queries/__types/useIncident';
import { Icon } from '@telia/styleguide';
import { useResolveIncident } from 'src/areas/main/incidents/mutations/useResolveIncident';

import './IncidentPageHeader.scss';
import { getSubscriptionDisplayTitles, Subscription } from 'src/lib/types';

type Props = {
  incident: useIncident_customer_incidents;
  routerId: string;
  sidebarOpen: boolean;
  setSidebarOpen: () => void;
  incidentStatusText: string;
};

export const IncidentPageHeader = (props: Props) => {
  /**
   * Temporary state to avoid bugs when sending messages when the incident is closed/unactive
   * TODO: Find a correct state for when a incident is resolved
   */
  const incidentClosed = props.incident?.incidentStatus === 'closed';

  const { resolveIncident } = useResolveIncident();

  const incidentActions = (
    <div className="IncidentPageHeader-actions">
      <div className="IncidentPageHeader-actions-left">
        <div className="header-status action">
          {incidentClosed ? (
            <>
              <Icon icon="check-mark-circle" />
              <div className="status-text grey-dark-text">
                {t.incidents.status.closed}
              </div>
            </>
          ) : (
            <>
              <Icon icon="time-waiting" />
              <div className="status-text grey-dark-text">
                {props.incidentStatusText}
              </div>
            </>
          )}
        </div>
      </div>
      <div className="IncidentPageHeader-actions-right">
        <div className="IncidentPageHeader-actions-service action">
          {props.incident.subscription?.subscriptionId ? (
            <Button
              transparent={true}
              color="dark"
              icon="wireless"
              rounded={true}
              to={urlFor({
                subscription: props.incident.subscription?.subscriptionId ?? '',
              })}
            >
              {
                getSubscriptionDisplayTitles(
                  props.incident.subscription as Subscription
                ).title
              }
            </Button>
          ) : null}
        </div>
        <div className="IncidentPageHeader-actions-mark-solved action">
          <ConfirmationModalButton
            disabled={incidentClosed}
            transparent={true}
            color="dark"
            rounded={true}
            icon="check-mark-circle"
            headerText={t.incidents.ResolveIncidentConfirmationModal.title}
            label={t.incidents.IncidentActions.markSolved}
            onConfirm={() => resolveIncident(props.incident.id, '')}
          >
            <p>
              <div className="mb-3">
                {t.incidents.ResolveIncidentConfirmationModal.description}
              </div>
              <div>
                {t.incidents.ResolveIncidentConfirmationModal.description2}
              </div>
            </p>
          </ConfirmationModalButton>
        </div>
        <div className="IncidentPageHeader-actions-show-details action">
          <Button
            className={props.sidebarOpen ? 'active' : undefined}
            transparent={true}
            color="dark"
            icon="info"
            rounded={true}
            onClick={props.setSidebarOpen}
          >
            {t.incidents.IncidentActions.showDetails}
          </Button>
        </div>
      </div>
    </div>
  );

  return (
    <PageHeader
      breadcrumbs={[
        {
          title: t.incidents.listTitle,
          url: urlFor({
            area: 'incidents',
            group: props.incident?.active ? 'aktive' : 'historikk',
          }),
        },
      ]}
      title={props.routerId}
      titleActions={incidentActions}
      className="IncidentPageHeader"
    />
  );
};
