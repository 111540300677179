import * as React from 'react';

import { useMobileUnitOrder } from '../queries/useMobileUnitOrder';
import { useOrder_customer_order } from '../queries/__types/useOrder';
import { Attribute, Loading, BodyCard } from 'src/lib/ui';
import { formatDate } from 'src/lib/utils/dateUtils';
import { mapOrderStatusToLabel } from '../utils';
import { t } from 'src/lib/i18n';

const MobileUnitOrderContent = (props: { order: useOrder_customer_order }) => {
  const i18n = t.orders.MobileUnitOrderContent;
  const useMobileUnitOrderQuery = useMobileUnitOrder(props.order.id);
  const mobileUnitOrder =
    useMobileUnitOrderQuery.data?.customer?.order?.mobileUnitOrderDetails;

  if (useMobileUnitOrderQuery.loading) {
    return <Loading />;
  }

  if (!mobileUnitOrder) {
    return <div>{i18n.noOrder}</div>;
  }

  return (
    <div className="container">
      <BodyCard>
        <Attribute label={i18n.description} value={props.order.description} />
        <Attribute
          label={i18n.created}
          value={formatDate(
            props.order.dates && props.order.dates.createdAt
              ? props.order.dates.createdAt
              : '',
            'medium'
          )}
        />
        <Attribute
          label={i18n.status}
          value={mapOrderStatusToLabel(mobileUnitOrder.portalStatus)}
        />
        {mobileUnitOrder ? (
          <>
            <Attribute label={i18n.quantity} value={mobileUnitOrder.quantity} />
          </>
        ) : null}
      </BodyCard>
    </div>
  );
};

export default MobileUnitOrderContent;
