import { useMutation } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/useGenerateReportAccountingFixedPhones';
import { useCustomer } from 'src/lib/global';

export const GENERATE_ACCOUNTING_REPORT_FOR_FIXED_PHONES = gql`
  mutation useGenerateReportAccountingFixedPhones(
    $customerId: ID!
    $fromDate: Date!
    $toDate: Date!
    $organisationId: ID
  ) {
    generateReportAccountingFixedPhones(
      input: {
        customerId: $customerId
        fromDate: $fromDate
        toDate: $toDate
        organisationId: $organisationId
      }
    ) {
      url
      filename
    }
  }
`;

export const useAccountingReportForFixedPhones = () => {
  const customer = useCustomer();

  const [mutation, { loading, error }] = useMutation<
    t.useGenerateReportAccountingFixedPhones,
    t.useGenerateReportAccountingFixedPhonesVariables & { customerId: string }
  >(GENERATE_ACCOUNTING_REPORT_FOR_FIXED_PHONES);

  const generate = async (
    fromDate: string,
    toDate: string,
    organisationId?: string
  ) =>
    await mutation({
      variables: {
        customerId: customer.id,
        fromDate,
        toDate,
        organisationId,
      },
    });

  return {
    generate,
    loading,
    error,
  };
};
