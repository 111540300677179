export const getBasicSubscriptionTitles = (sub: {
  id: string;
  name: string | null;
  alias: string | null;
}) => {
  const nameAndId = sub.name ? `${sub.name} ${sub.id}` : sub.id;

  return {
    title: sub.alias ? sub.alias : nameAndId,
    subtitle: sub.alias ? nameAndId : '',
  };
};
