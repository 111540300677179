export interface StepsMeta {
  currentStep: number;
  fractionOfCurrentStep: number | undefined;
  usageOnCurrentStep: number;
  totalCost: number;
  totalUsage: number;
  steps: UsageStep[];
}

export interface UsageStep {
  usageUpTo?: number | null;
  cost: number;
}

export const calculateStepMeta: (
  steps: Array<UsageStep>,
  usage: number
) => StepsMeta = (steps, usage) => {
  // Start with the specific amount of usage
  let usageLeft = usage;
  let totalCost = 0;
  // Climb up the steps, 'spending' or usage on every step
  for (let i = 0, j = steps.length; i < j; i++) {
    const step = steps[i];
    if (step.usageUpTo !== undefined && step.usageUpTo !== null) {
      // If we have more usage left than the usage on this level, we climb to the next step
      if (usageLeft > step.usageUpTo) {
        totalCost += step.usageUpTo * step.cost;
        usageLeft -= step.usageUpTo;
        continue;
      }
      // Otherwise, there's a fraction:
      totalCost += usageLeft * step.cost;
      return {
        currentStep: i,
        fractionOfCurrentStep: usageLeft / step.usageUpTo,
        usageOnCurrentStep: usageLeft,
        totalUsage: usage,
        totalCost,
        steps,
      };
    }
    totalCost += usageLeft * step.cost;
    // We stop here; no limit on current step!
    return {
      currentStep: i,
      fractionOfCurrentStep: undefined,
      usageOnCurrentStep: usageLeft,
      totalUsage: usage,
      totalCost,
      steps,
    };
  }
  throw Error('No steps given');
};
