import * as React from 'react';
import { useAlterFolder } from '../mutations/useAlterFolder';
import { useAlterFile } from '../mutations/useAlterFile';
import FileIcon from './FileIcon';
import {
  getUndoFolderMoveAction,
  getUndoFileMoveAction,
} from '../undo/UndoActions';
import { usePath } from 'src/areas/main/documents/contexts/PathContext';
import { getFullPath } from '../utils/pathMatcher';
import { t } from 'src/lib/i18n';
import { Folder } from 'src/lib/types';
import undoPopup from 'src/areas/main/documents/undo/UndoPopup';

interface Props {
  folder: Folder;
  folders: Folder[];
}

const ParentFolderRow: React.FC<Props> = props => {
  const folderContext = useAlterFolder();
  const fileContext = useAlterFile();
  const pathContext = usePath();

  const { folder, folders } = props;

  const handleOnDrop = (e: React.DragEvent) => {
    const data = JSON.parse(e.dataTransfer.getData('objectData'));
    const objectType = e.dataTransfer.getData('objectType');
    const folderName = folder.parentId ? folder.name : 'rotmappen';
    if (!(objectType === 'folder' && data.id === folder.id)) {
      if (objectType === 'folder') {
        return folderContext
          .alterFolder(data.id, folder.id, data.name)
          .then(() =>
            undoPopup(
              getUndoFolderMoveAction(
                data,
                folderName,
                folderContext.alterFolder
              )
            )
          );
      }
      if (objectType === 'file') {
        return fileContext
          .alterFile(data.id, folder.id, data.name)
          .then(() =>
            undoPopup(
              getUndoFileMoveAction(data, folderName, fileContext.alterFile)
            )
          );
      }
    }
    return null;
  };
  const handleKeyUp = (e: React.KeyboardEvent) => {
    if (e.keyCode === 13) {
      e.preventDefault();
      pathContext.navigateTo({ folder });
    }
  };

  const path: Folder[] = getFullPath(folder, folders);
  let breadcrumbs = t.documents.title;
  path.map(precedingFolder => (breadcrumbs += ' / ' + precedingFolder.name));

  return (
    <div>
      <div
        tabIndex={0}
        onDragOver={e => e.preventDefault()}
        onDrop={e => handleOnDrop(e)}
        onClick={() => pathContext.navigateTo({ folder })}
        onKeyUp={handleKeyUp}
        className="my-3"
        style={{ cursor: 'pointer' }}
      >
        <span className="pr-3">
          <FileIcon parentFolder={true} />
        </span>
        {pathContext.folder.parentId
          ? (breadcrumbs += ' / ' + pathContext.folder.name)
          : breadcrumbs}
        {(folderContext.error || fileContext.error) && (
          <span className="pl-3" style={{ color: 'red' }}>
            Det oppstod en feil
          </span>
        )}
      </div>
    </div>
  );
};

export default ParentFolderRow;
