import * as React from 'react';
import { ListResultCard } from 'src/lib/ui';

import { File } from 'src/lib/types';

export const SearchListFileResult: React.FC<{ entry: File }> = ({ entry }) => (
  <ListResultCard
    linkTo={entry}
    className="ListResultCard--File"
    icon="document"
    title={entry.name ? entry.name : entry.id}
  />
);
