import Modal from 'reactstrap/lib/Modal';
import React, { useEffect } from 'react';
import ModalBody from 'reactstrap/lib/ModalBody';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { ModalFooter } from 'reactstrap';
import { useFormik } from 'formik';
import { TextField, FormSubmitBar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useEditTagMutation } from 'src/areas/main/subscriptions/tags/useEditTag';
import * as yup from 'yup';
import { useCustomer } from 'src/lib/global';
import { trackTagsUpdate } from 'src/lib/analytics';

interface Props {
  title: string;
  toggle: () => void;
  show: boolean;
  id: string;
  initialValues: FormValues;
}

interface FormValues {
  tag: string;
  description: string;
}

const EditTagModal: React.FC<Props> = props => {
  const translate = t.tags;
  const useEditTag = useEditTagMutation();
  const customer = useCustomer();
  const formik = useFormik<FormValues>({
    initialValues: {
      tag: props.initialValues.tag,
      description: props.initialValues.description ?? '',
    },
    onSubmit: values => {
      trackTagsUpdate('update');
      return useEditTag.editTag({
        customerId: customer.id,
        tagId: props.id,
        tag: values.tag,
        description: values.description,
      });
    },
    validationSchema: () => {
      return yup.object().shape({
        tag: yup.string().required('Required'),
        description: yup.string(),
      });
    },
  });

  useEffect(() => {
    if (props.show === true) {
      if (useEditTag?.data !== undefined && !useEditTag?.data?.editTag?.error) {
        props.toggle();
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [useEditTag?.data, useEditTag?.data?.editTag?.tag]);

  return (
    <Modal isOpen={props.show} toggle={props.toggle} centered={true}>
      <ModalHeader>{props.title}</ModalHeader>
      <ModalBody>
        <form onSubmit={formik.handleSubmit}>
          <TextField
            className="w-100"
            id={'tag'}
            label={translate.form.tag}
            required={true}
            onChange={formik.handleChange}
            value={formik.values.tag}
            error={
              formik.touched.tag && formik.errors.tag
                ? formik.errors.tag
                : undefined
            }
          />
          <TextField
            className="w-100"
            id={'description'}
            label={translate.form.description}
            required={false}
            onChange={formik.handleChange}
            value={formik.values.description}
          />
        </form>
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          errorMsg={translate.error.createAndEditTag}
          error={
            useEditTag?.data?.editTag?.error?.code === 'InvalidOperationError'
          }
          cancel={props.toggle}
          submit={() => formik.handleSubmit()}
          loading={useEditTag.loading}
        />
      </ModalFooter>
    </Modal>
  );
};
export default EditTagModal;
