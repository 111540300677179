import React, { useState, useEffect } from 'react';
import { Button, Spinner, colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export const SelectedRowsBanner = (props: {
  numCurrentRows: number;
  allRows: number;
  selectAll: () => void;
  clearSelect: () => void;
  domain: string;
  loading?: boolean;
}) => {
  const [fetching, setFetching] = useState(false);

  /**
   * Only display loading indication if we are fetching a large set of data
   */
  useEffect(() => {
    if (props.loading) {
      if (props.allRows < 300) {
        const timeout = setTimeout(() => setFetching(true), 1000);
        return () => clearTimeout(timeout);
      } else setFetching(true);
    } else {
      setFetching(false);
    }
  }, [props.loading, props.allRows]);

  const i18n = t.ui.Table.SelectedRowsBanner;
  return (
    <div
      className="py-2 mb-3 w-100 text-center"
      style={{ backgroundColor: colors.greyMedium, borderRadius: '4px' }}
    >
      {props.allRows > props.numCurrentRows ? (
        <>
          <small className="pr-2">
            {i18n.allPageRows(props.numCurrentRows)}
          </small>
          <Button
            color="white"
            transparent={true}
            onClick={props.selectAll}
            size="small"
          >
            {fetching
              ? 'velger rader... '
              : i18n.allRows(props.allRows, props.domain)}
            {fetching && <Spinner className="ml-2" size="xs" />}
          </Button>
        </>
      ) : (
        <>
          <small className="pr-2">
            {i18n.allRowsAreSelected(props.allRows, props.domain)}
          </small>
          <Button
            color="white"
            transparent={true}
            onClick={props.clearSelect}
            size="small"
          >
            {i18n.clearSelection}
          </Button>
        </>
      )}
    </div>
  );
};
