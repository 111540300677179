import gql from 'graphql-tag';
import { InvoiceLine } from '../types';
import { InvoiceLineLoader, PropsForOrganisation } from './WithInvoiceLines';

const query = gql`
  query WithInvoiceLinesForOrganisation(
    $customerId: ID!
    $organisationId: ID!
    $recursive: Boolean!
    $periodStart: Date!
    $periodEnd: Date!
    $periodBy: PeriodByType!
    $productDesign: String!
    $size: Int
    $after: String
  ) {
    customer(id: $customerId) {
      id
      organisation(id: $organisationId) {
        id
        invoiceLines(
          recursive: $recursive
          periodStart: $periodStart
          periodEnd: $periodEnd
          periodBy: $periodBy
          productDesign: $productDesign
          size: $size
          after: $after
        ) {
          cursor
          size
          totalResults
          results {
            id
            invoiceId
            amountGross
            amountNet
            periodStart
            periodEnd
            productDesignId
            productDesignDescription
            productGroup
            costType
            groupedProductDesign
            vat
          }
        }
      }
    }
  }
`;

interface Variables {
  customerId: string;
  organisationId: string;
  recursive: boolean;
  periodStart: string;
  periodEnd: string;
  periodBy: string;
  productDesign?: string;
  size?: number;
  after?: string;
}

interface Data {
  customer: {
    organisation: {
      invoiceLines: {
        cursor: string;
        size: number;
        totalResults: number;
        results: InvoiceLine[];
      };
    };
  };
}

const loader: InvoiceLineLoader<PropsForOrganisation> = async (
  client,
  customerId,
  props,
  size,
  after
) => {
  const res = await client.query<Data, Variables>({
    query,
    variables: {
      customerId,
      size,
      after,
      organisationId: props.organisationId,
      periodBy: props.periodBy,
      periodEnd: props.periodEnd,
      periodStart: props.periodStart,
      productDesign: props.productDesign,
      recursive: props.recursive,
    },
  });

  return {
    cursor: res.data.customer.organisation.invoiceLines.cursor,
    size: res.data.customer.organisation.invoiceLines.size,
    totalResults: res.data.customer.organisation.invoiceLines.totalResults,
    lines: res.data.customer.organisation.invoiceLines.results,
  };
};

export default loader;
