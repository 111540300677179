import { useAppContext } from 'src/lib/global';
import { useAccessQuery_me_featureFlags } from 'src/lib/global/__types/useAccessQuery';

export type FeatureFlag = Omit<useAccessQuery_me_featureFlags, '__typename'>;

const hasUserFeature = (feature: string, userFeatures?: string[] | null) =>
  !!userFeatures?.includes(feature);

const hasFeatureFlag = (feature: string, featureFlags?: FeatureFlag[] | null) =>
  hasUserFeature(
    feature,
    featureFlags?.map(f => f.flag)
  );

export const hasFeature = (
  feature: string,
  userFeatures: string[] | null = [],
  accessFeatureFlags: FeatureFlag[] | null = [],
  customerFeatureFlags: FeatureFlag[] | null = []
) => {
  const hasUserFeatureFlag = hasUserFeature(feature, userFeatures);
  const hasAccessFeatureFlag = hasFeatureFlag(feature, accessFeatureFlags);
  const hasCustomerFeatureFlags = hasFeatureFlag(feature, customerFeatureFlags);

  return hasUserFeatureFlag || hasAccessFeatureFlag || hasCustomerFeatureFlags;
};

/**
 *
 * Features can be read from Keycloak or the gateway.
 */
export function useFeature(feature: string) {
  const app = useAppContext();

  return hasFeature(
    feature,
    app.auth.user?.features,
    app.access.featureFlags,
    app.customerFeatureFlags
  );
}
