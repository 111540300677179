import React from 'react';
import {
  Loading,
  ListFooter,
  LoadMoreButton,
  FilterToggles,
  SearchInput,
} from 'src/lib/ui';
import { PageHeader } from '../common/PageHeader/PageHeader';

import WithSearch from './lib/WithSearch';
import { SearchListResults } from './components/list/SearchListResults';
import { t } from 'src/lib/i18n';
import { ResourceType } from 'src/lib/types';
import { mapResourceTypeToLabel } from './lib/mapResourceTypeToLabel';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';

const mapResourceTypeToSearchParams = (resourceType: ResourceType) => {
  let filter;
  if (resourceType === 'dataSubscription') {
    resourceType = 'subscription';
    filter = [
      {
        filter: 'group',
        value: 'data',
      },
    ];
  } else if (resourceType === 'mobileSubscription') {
    resourceType = 'subscription';
    filter = [
      {
        filter: 'group',
        value: 'mobile',
      },
    ];
  } else if (resourceType === ('teleSubscription' as ResourceType)) {
    resourceType = 'subscription';
    filter = [
      {
        filter: 'group',
        value: 'tele',
      },
    ];
  } else if (resourceType === ('otherSubscription' as ResourceType)) {
    resourceType = 'subscription';
    filter = [
      {
        filter: 'group',
        value: 'other',
      },
    ];
  }
  return {
    types: resourceType ? [resourceType] : undefined,
    filter: filter,
  };
};

const Search: React.FC = () => {
  const queryParams = useQueryParameters();
  const resourceType = queryParams.get('resourceType')
    ? (queryParams.get('resourceType') as ResourceType)
    : undefined;

  const query = queryParams.get('query');

  const resourceLabel = resourceType
    ? mapResourceTypeToLabel(resourceType)
    : '';

  return (
    <WithSearch
      initial={{
        size: 0,
        query: query,
      }}
      searchImmediately={true}
    >
      {searchDomains => (
        <WithSearch
          initial={{
            query: query,
            ...(resourceType && mapResourceTypeToSearchParams(resourceType)),
          }}
          searchImmediately={true}
        >
          {search => {
            const availableFilters = searchDomains.totalResultsByDomain
              ? searchDomains.totalResultsByDomain.map(domain => ({
                  label: `${mapResourceTypeToLabel(
                    domain.domain as ResourceType
                  )} (${domain.resultCount})`,
                  value: domain.domain,
                }))
              : undefined;
            return (
              <>
                <PageHeader title={t.search.Search.title(query)} />

                <div className="container mt-2">
                  <div className="d-block d-lg-none">
                    <SearchInput
                      value={query}
                      onChange={e => queryParams.set('query', e.target.value)}
                      onClose={() => queryParams.set('query', '')}
                      narrow={true}
                    />
                  </div>

                  {availableFilters ? (
                    <div className="mt-2">
                      <FilterToggles
                        allowMultiple={false}
                        options={availableFilters}
                        currentFilter={
                          resourceType !== undefined
                            ? [resourceType]
                            : undefined
                        }
                        setCurrentFilter={cf =>
                          queryParams.set('resourceType', cf)
                        }
                      />
                    </div>
                  ) : null}

                  {search.results.length ? (
                    <div className="mt-4">
                      {t.search.Search.showingNResults(
                        search.results.length,
                        search.totalResults || 0
                      )}

                      {resourceLabel ? (
                        <span>
                          {t.search.Search.searchingForSuffix(resourceLabel)}
                        </span>
                      ) : null}
                    </div>
                  ) : null}
                </div>

                {search.loading && !search.results ? <Loading /> : null}

                <SearchListResults results={search.results} />

                <ListFooter>
                  {search.isMore ? (
                    <LoadMoreButton
                      onClick={() => search.loadMore(80)}
                      disabled={search.loading || !search.isMore}
                    />
                  ) : null}
                </ListFooter>
              </>
            );
          }}
        </WithSearch>
      )}
    </WithSearch>
  );
};

export default Search;
