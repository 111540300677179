import { t } from 'src/lib/i18n';
export const getSubscriptionTypes = () => {
  const translate = t.reports.downloadModal;
  const subscriptionTypes = [
    'data',
    'mobile',
    'landlineSubscriptions',
    'other',
  ];
  return subscriptionTypes.map(sub => ({
    label: translate.subscriptionType[sub],
    value: sub,
  }));
};
