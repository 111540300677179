const overview = {
  greeting: name => `Hei, ${name}`,
  dataUsed: 'Data brukt',
  smsSent: 'SMS sendt',
  minutesCalled: 'Minutter ringt',
  cost: 'Kostnad',
  total: 'Total',
  over6GB: 'Over 6 GB',
  overviewDescription:
    'Her er oversikten for mobilabonnementet ditt denne måneden',
  usage: {
    usageCurrentMonth: 'Forbruk denne måneden',
    usage: 'Forbruk',
    data: 'Data',
    dataUsage: 'Dataforbruk',
    used: 'Brukt',
  },
  subscription: {
    aboutSubscription: 'Om abonnementet',
    name: 'Navn',
    phoneNumber: 'Telefonnummer',
    pricePerMB: 'Pris per MB datatrafikk',
    step: 'Trappetrinnsteg',
    stepPriceNow: 'Trinnpris - Nå',
    fixedPrice: 'Fast kostnad',
    dataIncluded: 'Inkludert data',
    minutesIncluded: 'Inkludert taletid',
    smsIncluded: 'Inkludert SMS',
    freeUse: 'Fri bruk',
    chartLabel: 'Pris/MB',
  },
  sim: {
    simCard: 'SIM-kort',
    sim: 'SIM',
    mainSim: 'Hoved SIM-kort',
    pin: 'PIN',
    puk: 'PUK',
    hide: 'Skjul PIN og PUK',
    show: 'Vis PIN og PUK',
    textLabel: 'Velg tilgjengelig SIM-kort',
    replaceSim: 'Erstatt SIM-kort',
    addSim: 'Legg til ekstra SIM-kort',
    modalHeader: 'Bytt SIM-kort',
    submitLabelNew: 'Få tilsendt et nytt SIM-kort',
    submitLabelExisting: 'Aktiver valgt SIM-kort',
    existingSim: 'Aktiver et SIM-kort du har',
    newSim: 'Bestill et nytt SIM-kort',
    activateOrOrderNew: 'Bytt til et annet SIM-kort eller bestill et nytt.',
    activateOrOrderNewQuestion: 'Aktiver eller bestill nytt?',
    addSimToSubscription: 'Legg til ekstra SIM-kort på dette mobilabonnementet',
    chooseType: 'Velg riktig type ekstra SIM til ditt formål',
    deliveryInformation: 'Leveranseinformasjon',
    errorMsg:
      'Oi, vi klarte ikke finne det SIM-kortet. Sjekk at det er skrevet inn riktig',
    activateSimDescription:
      'Ditt nye SIM-kort blir aktivert i løpet av noen minutter. Når det nye SIM-kortet er aktivert vil ditt gamle SIM-kort bli deaktivert, og det vil ikke være mulig å gjenåpne.',
    orderSimDescription:
      'Når ditt nye SIM-kort aktiveres vil også ditt gamle SIM-kort bli deaktivert. Det vil ikke være mulig å gjenåpne dette. Leveranse av nytt kort tar normalt 2-5 virkedager.',
  },
  profile: {
    change: 'Endre',
    profile: 'Profil',
    name: 'Navn',
    address: 'Adresse',
    organisation: 'Organisasjon',
    email: 'E-post',
    phoneNumber: 'Telefonnummer',
    employeeNum: 'Ansattnummer',
    position: 'Stilling',
  },
};

export default overview;
