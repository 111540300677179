import * as React from 'react';
import { Link } from 'react-router-dom';
import cs from 'classnames';
import { UrlForObject, urlFor } from '../LinkTo/urlFor';
import './Card.scss';

type CardProps = {
  renderAs?: 'div' | 'span' | 'li' | 'ul';
  className?: string;
  style?: React.CSSProperties;
  children?: React.ReactNode;
  border?: boolean;
  padding?: boolean;
  // corresponds to sketches in Zeplin
  shadow?: '0' | '1' | '4' | '24';
  transparent?: boolean;
} & ({ linkTo?: string | UrlForObject } | { onClick?: () => void });

export const Card: React.FC<CardProps> = props => {
  const Element = props.renderAs || 'div';

  const element = (
    <Element
      style={props.style}
      className={cs(
        'Card',
        props.className,
        props.shadow ? `shadow-${props.shadow}` : undefined,
        {
          'Card--border': !!props.border,
          'Card--transparent': !!props.transparent,
          'p-4': !!props.padding,
          clickable:
            ('linkTo' in props && !!props.linkTo) ||
            ('onClick' in props && !!props.onClick),
        }
      )}
    >
      {props.children}
    </Element>
  );

  if ('linkTo' in props && props.linkTo) {
    return (
      <Link
        className="Card--link"
        to={
          typeof props.linkTo === 'string' ? props.linkTo : urlFor(props.linkTo)
        }
      >
        {element}
      </Link>
    );
  }
  if ('onClick' in props) {
    return (
      <div role="button" onClick={props.onClick}>
        {element}
      </div>
    );
  }
  return element;
};
