import { SubscriptionGroup } from './../../../__types/graphql-global-types';
import { formatPhoneNumber } from 'src/lib/utils';
import { t } from 'src/lib/i18n';
export const getSubscriptionDisplayTitles: (sub: {
  id: string;
  name?: string | null;
  alias?: string | null;
  group?: SubscriptionGroup | null;
  ownerAttributes?: null | {
    firstName: string | null;
    lastName: string | null;
  };
  phoneNumbers?: null | Array<{
    phoneNumber: string;
    type?: number;
    seriesStart?: string | null;
    seriesEnd?: string | null;
  }>;
}) => { title: string; subtitle?: string } = sub => {
  // Basic name/ID representation. We'll definitely use this.
  const nameAndId = sub.name ? `${sub.name} ${sub.id}` : sub.id;
  const translate = t.subscriptions.getSubscriptionDisplayTitles;
  /**
   * New 17.02.2020: Handling of tele subs only.
   * We do not want to use Alias for Tele.
   */

  if (sub.group === SubscriptionGroup.tele) {
    return {
      title: nameAndId,
    };
  }

  /**
   * Specific handling for mobile/tele subscriptions
   */
  if (sub.group === SubscriptionGroup.mobile) {
    let title: string = '';

    if (sub.alias) {
      return {
        title: sub.alias,
        subtitle: nameAndId,
      };
    } else {
      if (
        sub.ownerAttributes &&
        sub.ownerAttributes.firstName &&
        sub.ownerAttributes.lastName
      ) {
        const name = `${sub.ownerAttributes.firstName} ${sub.ownerAttributes.lastName}`;
        title = title.concat(name, ' ');
      }

      if (sub.phoneNumbers) {
        if (sub.phoneNumbers.length === 1) {
          if (sub.phoneNumbers[0].type === 0) {
            title = title.concat(
              translate.aNumber,
              formatPhoneNumber(sub.phoneNumbers[0].phoneNumber),
              ' '
            );
          } else {
            title = title.concat(
              translate.phoneNumber,
              formatPhoneNumber(sub.phoneNumbers[0].phoneNumber),
              ' '
            );
          }
        } else if (sub.phoneNumbers.length > 1) {
          title = title.concat(
            translate.aNumberSerie,
            sub.phoneNumbers[0].seriesStart
              ? sub.phoneNumbers[0].seriesEnd
                ? formatPhoneNumber(sub.phoneNumbers[0].seriesStart) +
                  ' - ' +
                  formatPhoneNumber(sub.phoneNumbers[0].seriesEnd)
                : formatPhoneNumber(sub.phoneNumbers[0].seriesStart)
              : sub.phoneNumbers[0].seriesEnd
              ? formatPhoneNumber(sub.phoneNumbers[0].seriesEnd)
              : ''
          );
        }
      }
    }

    return {
      title: title === '' ? nameAndId : title,
      subtitle: title === '' ? undefined : nameAndId,
    };
  }

  /**
   * For other subscription types, use simple alias with name/id fallback
   */
  return sub.alias
    ? {
        title: sub.alias,
        subtitle: nameAndId,
      }
    : {
        title: nameAndId,
        subtitle: undefined,
      };
};
