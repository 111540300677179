import React from 'react';
import { ExtendedQosStatisticsPeriods } from '../../queries/useExtendedQosStatistics';
import { colors } from 'src/lib/ui';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { Area } from 'recharts';
import { t } from 'src/lib/i18n';
import { createDataKeyFromTrafficClass } from '../../utils/createDataKeyFromTrafficClass';

export const RoundTripTimeChartElements = (idx: number, dataKey: string) => [
  <Area
    key="1"
    stroke={colors.orange}
    strokeWidth="1"
    fill={colors.orange}
    fillOpacity="0.2"
    dataKey={createDataKeyFromTrafficClass(idx, dataKey)}
    name={t.statistics.Legend.roundTripTime}
  />,
];

/**
 * Area filled dot
 * Line circle
 */
export const RoundTripTimeTooltip = (idx: number) => ({
  data,
  hideTimestamp,
}: {
  data: ExtendedQosStatisticsPeriods;
  hideTimestamp?: boolean;
}) => {
  const i18n = t.statistics.StandardDetails;
  const currentData = data.trafficClasses?.[idx];
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header="">
        <SectionItem
          title={i18n.roundTripTimeAvg}
          color={colors.orange}
          value={
            currentData?.roundTripTime
              ? `${currentData.roundTripTime.toFixed()} ms`
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};
