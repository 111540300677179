import * as React from 'react';
import { Switch, Route } from 'react-router-dom';
import { map } from 'lodash';

import { useAppContext } from 'src/lib/global';
import { SubscriptionContextProvider } from './lib/SubscriptionContext';

import MobileFooter from './MobileFooter';
import MobileHeader from './ui/menu/MobileHeader';

import Overview from './overview/Overview';
import Sms from './sms/Sms';

import SettingsPage from 'src/areas/mobile/settings/SettingsPage';
import NotFoundPage from 'src/areas/main/NotFoundPage';

import './MobileApp.scss';
import { HistoryPage } from 'src/areas/mobile/history/HistoryPage';

export default function MobileApp() {
  const app = useAppContext();

  if (!app.activeSubscriptionId) {
    // throw new Error('No active subscription');
    // Most likely a temporary situation: we are still waiting for the subscription from the AppContext.
    return null;
  }

  const activeSub = app.access.mobileSubscriptions
    ? app.access.mobileSubscriptions.filter(
        sub => sub.id === app.activeSubscriptionId && sub.customer
      )[0]
    : undefined;

  return (
    <SubscriptionContextProvider
      value={{
        id: app.activeSubscriptionId,
        name: '',
        availableSubscriptionIds: map(
          app.access.mobileSubscriptions,
          s => s.id
        ),
        customerId:
          activeSub && activeSub.customer ? activeSub.customer.id : '',
        selectSubscriptionId: app.setActiveSubscriptionId,
      }}
    >
      <div className="MobileApp">
        <MobileHeader />

        <Switch>
          <Route exact={true} path="/minside" component={Overview} />
          <Route path="/minside/sms" component={Sms} />
          <Route path="/minside/historikk" component={HistoryPage} />
          <Route path="/minside/innstillinger" component={SettingsPage} />
          <Route component={NotFoundPage} />
        </Switch>

        <MobileFooter />
      </div>
    </SubscriptionContextProvider>
  );
}
