import gql from 'graphql-tag';

export const IncidentFragment = gql`
  fragment IncidentSearchResult on Incident {
    id
    active
    category
    incidentStatus
    shortDescription
    createdAt
    createdBy
    priority
    incidentType
    severity
    description
    updatedAt
    closed
    subscription {
      id
      name
      alias
      type
      deliveryStatus
      group
      cpe {
        status
      }
      parent {
        id
      }
      costCenter {
        id
        description
        code
      }
      organisation {
        id
        name
      }
      tags {
        id
        tag
      }
      installationAddress {
        address
        city
        zip
        location {
          lat
          lon
        }
      }
      subscriptionId
      startTime
      endTime
      lastModified
    }
    addon {
      id
      name
      alias
      group
    }
  }
`;
