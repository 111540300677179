import React, { useState } from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  CartesianGrid,
  ReferenceLine,
  Label,
  Legend,
} from 'recharts';
import { CustomLegend } from './CustomLegend';
import { format } from 'date-fns';
import { dateDiffInDays, formatDate } from 'src/lib/utils/dateUtils';
import { scaleTime as d3ScaleTime } from 'd3-scale';
import { useI18nContext } from 'src/lib/i18n';
import { t } from 'src/lib/i18n';
import { colors, Checkbox } from 'src/lib/ui';
import { trackStatisticsView } from 'src/lib/analytics';

type StandardPeriod<T> = {
  periodStart: number;
  periodEnd: number;
} & Omit<T, '__typename'>;

interface StandardChartProps<T> {
  /**
   * All periods will have periodStart and periodEnd in epoch mills
   */
  periods: Array<StandardPeriod<T>>;
  // showGrid?: boolean;
  customYAxis?: React.ReactNode;
  tooltip: React.FunctionComponent<{
    data: StandardPeriod<T>;
    hideTimestamp: boolean;
    showCumulativeInMB?: boolean;
  }>;
  children?: React.ReactNode;
  className?: string;
  title?: React.ReactNode;
  subTitle?: React.ReactNode;
  showThreshold?: boolean;
  threshold?: {
    downstream?: number | null;
    upstream?: number | null;
  };
  hideTimestamp: boolean;
  showCumulativeInMB?: boolean;
  actions?: React.ReactNode;
}

export function StandardChart<T>(props: StandardChartProps<T>) {
  const [showGrid, setShowGrid] = useState<boolean>(false);
  const toggleGrid = () => {
    trackStatisticsView('rutenett', (!showGrid).toString());
    setShowGrid(!showGrid);
  };

  const i18nContext = useI18nContext();
  const xDomain = [
    props.periods[0].periodStart,
    props.periods[props.periods.length - 1].periodStart,
  ] as [number, number];

  const domainDiffInDays = dateDiffInDays(
    new Date(xDomain[0]),
    new Date(xDomain[1])
  );
  const tScale = d3ScaleTime()
    .domain(xDomain)
    .range([0, 1]);

  function tooltipHandler<T>(e) {
    if (e?.active && e?.payload?.[0]?.payload) {
      return (
        <props.tooltip
          data={e.payload[0].payload}
          hideTimestamp={props.hideTimestamp}
          showCumulativeInMB={props.showCumulativeInMB}
        />
      );
    }
    return null;
  }

  const thresholdLabels = getThresholdLabels(props.threshold);
  const maxDomain =
    props.threshold?.downstream && props.threshold.upstream
      ? props.threshold.downstream >= props.threshold.upstream
        ? props.threshold.downstream
        : props.threshold.upstream
      : undefined;

  return (
    <>
      <div className="mb-4">
        {props.title ? <h2 className="m-0 p-0">{props.title}</h2> : null}
        {props.subTitle ? (
          <div style={{ color: colors.greyDarkText }}>{props.subTitle}</div>
        ) : null}
      </div>
      <ResponsiveContainer
        width="100%"
        height={400}
        className={props.className}
      >
        <ComposedChart data={props.periods}>
          <CartesianGrid strokeOpacity={0.5} vertical={showGrid} />
          <XAxis
            dataKey="periodStart"
            tickFormatter={formatDateByDomain(
              domainDiffInDays,
              i18nContext.locale
            )}
            tickLine={false}
            type="number"
            ticks={tScale.ticks(5)}
            domain={xDomain}
            padding={{ left: 5, right: 5 }}
          />
          {props.customYAxis ? (
            props.customYAxis
          ) : (
            <YAxis
              orientation="right"
              axisLine={false}
              tickLine={false}
              domain={
                props.showThreshold && maxDomain
                  ? ['auto', maxDomain]
                  : undefined
              }
            />
          )}
          {props.children}
          <Tooltip content={tooltipHandler} />
          {props.showThreshold && thresholdLabels.label ? (
            <ReferenceLine
              stroke={colors.black}
              y={props.threshold?.downstream ?? undefined}
            >
              <Label
                value={thresholdLabels.label}
                position="insideBottomLeft"
              />
            </ReferenceLine>
          ) : null}
          {props.showThreshold && thresholdLabels.secondaryLabel ? (
            <ReferenceLine
              stroke={colors.black}
              y={props.threshold?.upstream ?? undefined}
            >
              <Label
                value={thresholdLabels.secondaryLabel}
                position="insideBottomLeft"
              />
            </ReferenceLine>
          ) : null}
          <Legend
            content={
              <CustomLegend
                actions={
                  <>
                    {props.actions}
                    <Checkbox
                      label={t.statistics.showGrid}
                      checked={showGrid}
                      onChange={toggleGrid}
                    />
                  </>
                }
              />
            }
          />
        </ComposedChart>
      </ResponsiveContainer>
    </>
  );
}

const formatDateByDomain = (dateDiff, locale) => tick => {
  const dateObject = new Date(tick);
  if (dateDiff <= 1) {
    return format(dateObject, 'HH:mm');
  }
  if (dateDiff >= 90) {
    return formatDate(dateObject, 'monthWithYear', locale);
  }
  return formatDate(dateObject, 'dateWithMonth', locale);
};

const getThresholdLabels = (threshold?: {
  downstream?: number | null;
  upstream?: number | null;
}): {
  label: string | null;
  secondaryLabel: string | null;
} => {
  if (!threshold) {
    return {
      label: null,
      secondaryLabel: null,
    };
  }

  const { downstream, upstream } = threshold;
  const isDownAndUpDifferent = downstream !== upstream;
  if (!downstream && !upstream) {
    return {
      label: null,
      secondaryLabel: null,
    };
  }

  if (isDownAndUpDifferent) {
    return {
      label: `${t.statistics.StandardChart.capacityIn} ${downstream?.toFixed(
        2
      )} Mbit/s`,
      secondaryLabel: `${
        t.statistics.StandardChart.capacityOut
      } ${upstream?.toFixed(2)} Mbit/s`,
    };
  }

  return {
    label: `${t.statistics.StandardChart.capacity} ${downstream?.toFixed(
      2
    )} / ${downstream?.toFixed(2)} Mbit/s `,
    secondaryLabel: null,
  };
};
