import React, { useState } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button, ButtonColorStyle } from 'src/lib/ui/Button/Button';
import {
  ButtonCard,
  SvgIconDefinition,
} from 'src/lib/ui/ButtonCard/ButtonCard';
import { t } from 'src/lib/i18n';
import { IconDefinition } from '@telia/styleguide';

type OrderingModalProps = {
  title: string;
  submit: () => void;
  className?: string;
  confirmButtonLabel?: string;
  disableSubmitButton?: boolean;
  btnHelpText?: string;
  onClose?: () => void;
} & (
  | { btnStyle: 'card'; icon?: SvgIconDefinition }
  | {
      btnStyle?: ButtonColorStyle;
      icon?: IconDefinition;
      disabled?: boolean;
    }
);

const OrderingModal: React.FC<OrderingModalProps> = props => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const toggleModal = () => {
    setIsOpen(!isOpen);
  };

  const onSubmitHandler = () => {
    if (props.submit) {
      props.submit();
    }
    setIsSubmitted(true);
  };

  const onClosedHandler = () => {
    setIsSubmitted(false);
    if (props.onClose) {
      props.onClose();
    }
  };

  const i18n = t.ordering.OrderingModal;
  return (
    <>
      {props.btnStyle === 'card' ? (
        <ButtonCard
          onClick={toggleModal}
          className={props.className}
          helpText={props.btnHelpText}
          icon={props.icon}
        >
          {props.title}
        </ButtonCard>
      ) : (
        <Button
          type="button"
          onClick={toggleModal}
          className={props.className}
          color={props.btnStyle}
          icon={props.icon}
          disabled={props.disabled}
        >
          {props.title}
        </Button>
      )}

      <Modal isOpen={isOpen} onClosed={onClosedHandler} toggle={toggleModal}>
        <ModalHeader>{props.title}</ModalHeader>
        <ModalBody>
          <div>{props.children}</div>
        </ModalBody>
        <ModalFooter>
          <Button
            className="mr-3"
            type="button"
            onClick={toggleModal}
            base="primary"
            color="white"
          >
            {i18n.cancelBtn}
          </Button>
          <Button
            type="button"
            disabled={props.disableSubmitButton || isSubmitted}
            onClick={onSubmitHandler}
            base="primary"
            color="purple"
          >
            {props.confirmButtonLabel
              ? props.confirmButtonLabel
              : i18n.submitBtn}
          </Button>
        </ModalFooter>
      </Modal>
    </>
  );
};

export default OrderingModal;
