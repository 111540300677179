import React from 'react';
import { ListResultCardSkeleton } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Subscription } from 'src/lib/types';
import { SidebarListItem } from './SidebarListItem';

const renderSkeleton = () => {
  const skeleton: JSX.Element[] = [];
  for (let i = 0; i < 20; i++) {
    skeleton.push(
      <ListResultCardSkeleton key={i} renderAs="div" className="p-3" />
    );
  }
  return skeleton;
};

export const List = (props: {
  loading: boolean;
  searchResult: Subscription[];
}) => {
  return (
    <>
      {props.loading ? (
        renderSkeleton()
      ) : props.searchResult.length > 0 ? (
        props.searchResult.map((r, idx) => (
          <SidebarListItem key={`${r.id}-${idx}`} subscription={r} />
        ))
      ) : (
        <div className="p-3">{t.locations.Sidebar.header.noResults}</div>
      )}
    </>
  );
};
