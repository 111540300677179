import * as React from 'react';

import './OrderingSection.scss';

interface OrderingSectionProps {
  /**
   * Title of the section
   */
  header?: string;
  children: React.ReactNode;
}

const OrderingSection: React.FunctionComponent<OrderingSectionProps> = props => {
  return (
    <div className="OrderingSection py-4">
      {props.header && (
        <h3 className="OrderingSection-header h4">{props.header}</h3>
      )}
      <div className="OrderingSection-content">{props.children}</div>
    </div>
  );
};

export default OrderingSection;
