export const convertBitsToMegabits = (unit: number) => {
  return unit / 1000 / 1000;
};

export const convertKilobitsToMegabits = (unit: number) => {
  return unit / 1000;
};

export const convertByteToGigaByte = (unit: number) => {
  return unit / 1024 / 1024 / 1024;
};

export const convertByteToMegaByte = (unit: number) => {
  return unit / 1024 / 1024;
};

export const convertMegaByteToGigaByte = (unit: number) => {
  return unit / 1024;
};
