// Insert a string into the given string at position idx, after removing 'rem' existing characters
const splice = (str: string, idx: number, rem: number, s: string) =>
  str.slice(0, idx) + s + str.slice(idx + Math.abs(rem));

// Nordic number formatter;
// using , as decimal point when precision>0,
// and space as thousand separator
export const formatNordic = (
  num: number,
  precision?: number | null,
  addTrailingZeros: boolean = true
) => {
  const prec = precision || 0;
  const rounded = Math.round(+num * 10 ** prec) / 10 ** prec;
  let result = rounded.toString().replace('.', ',');

  if (addTrailingZeros && +prec > 0) {
    const pos = result.indexOf(',');
    if (pos === -1) {
      result += `,${'0'.repeat(prec)}`;
    } else if (pos + prec <= result.length) {
      result += '0'.repeat(prec - (result.length - pos - 1));
    }
  }

  let commaPos = result.indexOf(',');
  if (commaPos === -1) {
    commaPos = result.length;
  }

  const len = commaPos;
  for (let x = 0; x < len; x += 1) {
    const offset = len - x - 1;
    if (x % 3 === 0 && x > 0 && result[offset] !== '-') {
      result = splice(result, offset + 1, 0, ' ');
    }
  }

  return result;
};
