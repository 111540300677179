import React from 'react';
import './InfoCard.scss';
import { IconDefinition, Icon } from '@telia/styleguide';
import { colors } from 'src/lib/ui';
import cs from 'classnames';
interface Props {
  className?: string;
  icon?: IconDefinition;
  heading?: string;
  color?: keyof typeof colors;
}

export const InfoCard: React.FC<Props> = props => {
  return (
    <div className={cs('InfoCard d-flex flex-column', props.className)}>
      <div
        className="d-flex align-items-center"
        style={{
          color: props.color ? colors[props.color] : colors.greyDarkText,
        }}
      >
        <Icon
          className="mr-2"
          icon={props.icon ?? 'info'}
          style={{ height: '1.5rem' }}
        />
        {props.heading ? (
          <div className="text-uppercase">{props.heading}</div>
        ) : (
          <div>{props.children}</div>
        )}
      </div>
      {props.heading ? <div>{props.children}</div> : null}
    </div>
  );
};
