import React, { useState } from 'react';
import { useQuery } from 'react-apollo';
import moment from 'moment';
import * as types from './query/__types/WithChangelogs';
import { SetChangelogModal } from './SetChangelogModal';
import { Button, Checkbox } from 'src/lib/ui';
import { Icon, IconDefinition } from '@telia/styleguide';
import './ListChangelogAdmin.scss';
import { DateTimePicker } from 'src/lib/ui/DateTimePicker/DateTimePicker';
import { GET_CHANGELOGS } from './query/WithChangelogs';
import { t } from 'src/lib/i18n';

const initialPageNumber = 1;
const pageSize = 5;

export function ListChangelogAdmin() {
  const [pageNumber, setPageNumber] = useState(initialPageNumber);
  const [getChangelogsFrom, setGetChangelogsFrom] = useState(
    '2018-12-01 00:00:00'
  );
  const [includeUnpublished, setIncludeUnpublished] = useState(true);
  function incrementPageNumber() {
    setPageNumber(pageNumber + 1);
  }

  const query = useQuery<types.WithChangelogs, types.WithChangelogsVariables>(
    GET_CHANGELOGS,
    {
      variables: {
        input: {
          page: pageNumber,
          size: pageSize,
          from: getChangelogsFrom,
          includeUnpublished: includeUnpublished,
        },
      },
    }
  );

  return (
    <div className="ListChangelogAdmin">
      <div>
        <SetChangelogModal
          getChangelogInput={{
            page: pageNumber,
            size: pageSize,
            from: getChangelogsFrom,
            includeUnpublished: includeUnpublished,
          }}
        />
      </div>
      Hent endringslogger fra og med
      <DateTimePicker
        value={getChangelogsFrom}
        onChange={setGetChangelogsFrom}
        /*
        input 'to' becomes "moment.now()" if not present, so ensure input 'from' is not after 'to'
        */
        isOutsideRange={(day: moment.Moment) => {
          return !day.isSameOrBefore(moment().subtract(1, 'day'));
        }}
      />
      <Checkbox
        label="Inkluder upubliserte og skjulte endringslogger"
        checked={includeUnpublished}
        onChange={() => setIncludeUnpublished(!includeUnpublished)}
      />
      <div className="ListChangelogAdmin-header">
        Endringslogger sortert på sist oppdatert:
      </div>
      <table className="ListChangelogAdmin-table">
        <tbody>
          {query?.data?.changelogs?.map(cl => (
            <tr key={cl.id}>
              <td className="ListChangelogAdmin-td">
                <div className="ListChangelogAdmin-content">
                  <div>
                    {cl.hidden ? (
                      <Icon
                        icon="visibility"
                        title="Markert som skjult"
                        style={{ height: '2rem', width: '2rem' }}
                      />
                    ) : (
                      <Icon
                        icon="visibility-filled"
                        title="Ikke markert som skjult"
                        style={{ height: '2rem', width: '2rem' }}
                      />
                    )}
                    {cl.publishedAt !== null
                      ? `Publiseringstidspunkt: ${cl.publishedAt}`
                      : ' Ikke publisert'}
                  </div>
                  <div>
                    Ikon:{' '}
                    <Icon
                      icon={cl.icon as IconDefinition}
                      style={{ height: '2rem', width: '2rem' }}
                    />
                  </div>
                  <div className="ListChangelogAdmin-content-header">
                    {cl.headingNorwegian}
                  </div>
                  <div className="ListChangelogAdmin-content-summary">
                    {cl.summaryNorwegian}
                  </div>
                  <div>{cl.bodyNorwegian}</div>
                  <div className="ListChangelogAdmin-content-header">
                    {cl.headingEnglish}
                  </div>
                  <div className="ListChangelogAdmin-content-summary">
                    {cl.summaryEnglish}
                  </div>
                  <div>{cl.bodyEnglish}</div>
                  <SetChangelogModal
                    key={cl.id}
                    id={cl.id}
                    initialValues={{
                      headingNorwegian: cl.headingNorwegian,
                      headingEnglish: cl.headingEnglish,
                      bodyNorwegian: cl.bodyNorwegian,
                      bodyEnglish: cl.bodyEnglish,
                      summaryNorwegian: cl.summaryNorwegian,
                      summaryEnglish: cl.summaryEnglish,
                      hidden: cl.hidden,
                      publishedAt: cl.publishedAt,
                      icon: cl.icon,
                    }}
                    /*
                    To make the refetchQuery of the mutation run
                    the same query as ListChangelogAdmin and thus
                    automatically update the list
                    */
                    getChangelogInput={{
                      page: pageNumber,
                      size: pageSize,
                      from: getChangelogsFrom,
                      includeUnpublished: includeUnpublished,
                    }}
                  />
                  <div className="ListChangelogAdmin-content-footer">
                    {`Laget av: ${cl.createdBy.name}. Sist endret av: ${cl.updatedBy.name}`}
                  </div>
                </div>
              </td>
            </tr>
          ))}
          {query?.data?.changelogs &&
            query?.data?.changelogs.length >= pageNumber * pageSize && (
              <Button
                base="primary"
                color="white"
                transparent={true}
                onClick={incrementPageNumber}
              >
                {t.changelogs.toDetailedPage}
              </Button>
            )}
        </tbody>
      </table>
    </div>
  );
}
