import * as React from 'react';
import matchSorter from 'match-sorter';
import Downshift from 'downshift';
import { Card } from '../Card/Card';
import './UserPicker.scss';
import { t } from 'src/lib/i18n';

export interface UserPickerItem {
  id: string;
  label: string;
  access: boolean;
}

export interface UserPickerItemProps {
  item: UserPickerItem;
  itemProps: any;
}

interface Props {
  items: UserPickerItem[];
  children: (item: UserPickerItemProps) => React.ReactNode;
}

interface State {
  input: string;
}

export class UserPicker extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = { input: '' };
  }

  render() {
    const { input } = this.state;
    const items = input.length
      ? matchSorter(this.props.items, input, { keys: ['label'] })
      : this.props.items;

    return (
      <Downshift
        inputValue={this.state.input}
        itemToString={item => (item ? item.label : '')}
      >
        {({ getInputProps, getItemProps, getLabelProps }) => (
          <div className="UserPicker">
            <label className="UserPicker-label" {...getLabelProps()}>
              {t.ui.UserPicker.searchForUsers}
            </label>
            <div className="UserPicker-searchWrapper">
              <div className="UserPicker-inputWrapper pb-3">
                <input
                  {...getInputProps({
                    placeholder: t.ui.UserPicker.searchForUser,
                    onChange: this.handleInputChange,
                  })}
                />
              </div>
              <Card className="UserPicker-result p-0">
                <div>
                  {items.map((item, i) => {
                    const props = getItemProps({
                      item,
                      index: i,
                    } as any);

                    return this.props.children({
                      itemProps: props,
                      item,
                    });
                  })}
                </div>
              </Card>
            </div>
          </div>
        )}
      </Downshift>
    );
  }

  handleInputChange = evt => {
    this.setState({ input: evt.target.value });
  };
}
