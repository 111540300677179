import * as React from 'react';
import cn from 'classnames';

import { RadioButton, Checkbox } from 'src/lib/ui';
import './ListSelectCard.scss';

interface ListSelectCardProps {
  selectType: 'radio' | 'checkbox';
  name: string;
  checked: boolean;
  value: string;
  cardLabel: string;
  onClick?: (value: string, checked: boolean) => void;
  disabled?: boolean;
  className?: string;
  attributes?: Array<{
    label?: string;
    value: React.ReactNode;
  }>;
}

export class ListSelectCard extends React.PureComponent<
  ListSelectCardProps,
  {}
> {
  onClickHandler = () => {
    if (this.props.onClick) {
      if (this.props.selectType === 'checkbox') {
        this.props.onClick(this.props.value, !this.props.checked);
      } else {
        // Radio will always return true when clicked on
        this.props.onClick(this.props.value, true);
      }
    }
  };

  render() {
    return (
      <li
        onClick={!this.props.disabled ? this.onClickHandler : undefined}
        className={cn('ListSelectCard', this.props.className)}
      >
        <div className="ListSelectCard-primary d-flex flex-row align-items-center">
          <div className={cn('ListSelectCard-icon justify-self-baseline px-1')}>
            {this.props.selectType === 'radio' && (
              <RadioButton
                name={this.props.name}
                value={this.props.value}
                checked={this.props.checked}
                onChange={this.onClickHandler}
                disabled={this.props.disabled}
              />
            )}
            {this.props.selectType === 'checkbox' && (
              <Checkbox
                name={this.props.name}
                value={this.props.value}
                checked={this.props.checked}
                onChange={this.onClickHandler}
                disabled={this.props.disabled}
              />
            )}
          </div>
          <div className="ListSelectCard-main pl-0">
            <div className="d-sm-flex flex-row align-items-center mb-1">
              <h1 className="mr-sm-2 mb-2 my-sm-0">{this.props.cardLabel}</h1>
            </div>
            {this.props.attributes && this.props.attributes.length > 0 ? (
              <div className="ListResultCard-attributes">
                {this.props.attributes.map((attribute, idx) => (
                  <span key={idx} className="ListResultCard-attribute">
                    {attribute.label ? `${attribute.label}: ` : null}
                    {attribute.value}
                  </span>
                ))}
              </div>
            ) : null}
          </div>
        </div>
      </li>
    );
  }
}
