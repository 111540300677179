import { produce } from 'immer';
import { useOrderFilesAndFolders } from './../../orders/queries/__types/useOrderFilesAndFolders';
import { WithSubscriptionFilesAndFolders } from './../../subscriptions/queries/__types/WithSubscriptionFilesAndFolders';
import { useDocumentArchiveFilesAndFolders } from './../queries/__types/useDocumentArchiveFilesAndFolders';
import { DOCUMENT_ARCHIVE_QUERY } from '../queries/useDocumentArchiveFilesAndFolders';
import { SUBSCRIPTION_DOCUMENTS_QUERY } from 'src/areas/main/subscriptions/queries/WithSubscriptionFilesAndFolders';
import ApolloClient from 'apollo-client';
import { ORDER_DOCUMENTS_QUERY } from 'src/areas/main/orders/queries/useOrderFilesAndFolders';

export const setUploadFinishedInCache = (
  fileId: string,
  apolloClient: ApolloClient<any>,
  customerId: string,
  subscriptionId?: string,
  orderId?: string
) => {
  if (subscriptionId) {
    setSubscriptionUploadFinishedInCache(
      fileId,
      subscriptionId,
      apolloClient,
      customerId
    );
  } else if (orderId) {
    setOrderUploadFinishedInCache(fileId, orderId, apolloClient);
  } else {
    setDocumentArchiveUploadFinishedInCache(fileId, apolloClient, customerId);
  }
};

const setDocumentArchiveUploadFinishedInCache = (
  fileId: string,
  apolloClient: ApolloClient<any>,
  customerId: string
) => {
  const cachedStructure = apolloClient.readQuery<
    useDocumentArchiveFilesAndFolders
  >({
    query: DOCUMENT_ARCHIVE_QUERY,
    variables: { customerId },
  });
  if (cachedStructure) {
    const newData = produce<useDocumentArchiveFilesAndFolders>(
      cachedStructure,
      draft => {
        if (draft.customer?.documentArchive) {
          draft.customer.documentArchive.allFiles = draft.customer.documentArchive.allFiles.map(
            f => (f.id === fileId ? { ...f, uploading: null } : f)
          );
        }
      }
    );
    apolloClient.writeQuery<useDocumentArchiveFilesAndFolders>({
      query: DOCUMENT_ARCHIVE_QUERY,
      data: newData,
    });
  }
};

const setSubscriptionUploadFinishedInCache = (
  fileId: string,
  subscriptionId: string,
  apolloClient: ApolloClient<any>,
  customerId: string
) => {
  const cachedStructure = apolloClient.readQuery<
    WithSubscriptionFilesAndFolders
  >({
    query: SUBSCRIPTION_DOCUMENTS_QUERY,
    variables: { customerId, subscriptionId },
  });
  if (cachedStructure) {
    const newData = produce<WithSubscriptionFilesAndFolders>(
      cachedStructure,
      draft => {
        if (draft.customer?.subscriptions?.[0]?.documents) {
          draft.customer.subscriptions[0].documents.allFiles = draft.customer.subscriptions[0].documents.allFiles.map(
            f => (f.id === fileId ? { ...f, uploading: null } : f)
          );
        }
      }
    );

    apolloClient.writeQuery<WithSubscriptionFilesAndFolders>({
      query: SUBSCRIPTION_DOCUMENTS_QUERY,
      data: newData,
    });
  }
};

const setOrderUploadFinishedInCache = (
  fileId: string,
  orderId: string,
  apolloClient: ApolloClient<any>
) => {
  const cachedStructure = apolloClient.readQuery<useOrderFilesAndFolders>({
    query: ORDER_DOCUMENTS_QUERY,
    variables: { input: { filters: [{ filter: 'id', value: [orderId] }] } },
  });
  if (cachedStructure) {
    const newData = produce<useOrderFilesAndFolders>(cachedStructure, draft => {
      if (draft.worklogSearch?.results?.[0].documents) {
        draft.worklogSearch.results[0].documents.allFiles = draft.worklogSearch.results[0].documents.allFiles.map(
          f => (f.id === fileId ? { ...f, uploading: null } : f)
        );
      }
    });
    apolloClient.writeQuery<useOrderFilesAndFolders>({
      query: ORDER_DOCUMENTS_QUERY,
      data: newData,
    });
  }
};
