import React from 'react';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { Button } from 'src/lib/ui';
import {
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from 'reactstrap';

import './BreadcrumbsAndTitle.scss';

type Breadcrumbs = Array<{
  title: string;
  url?: string;
}>;

interface Props {
  title?: string;
  breadcrumbs?: Breadcrumbs;
  minimizedBreadcrumbs?: boolean;
  className?: string;
}

interface BreadcrumbProps {
  breadcrumb?: {
    title: string;
    url?: string;
  };
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ breadcrumb }) => {
  if (!breadcrumb) {
    return null;
  }

  if (breadcrumb.url) {
    return <Link to={breadcrumb.url}>{breadcrumb.title}</Link>;
  }
  return <span>{breadcrumb.title}</span>;
};

const MinimizedBreadcrumbs: React.FC<{
  breadcrumbs?: Breadcrumbs;
}> = ({ breadcrumbs }) => {
  if (!breadcrumbs) {
    return null;
  }

  return (
    <>
      <UncontrolledDropdown tag="li">
        <DropdownToggle tag="span">
          <Button
            icon="more"
            rounded={true}
            transparent={true}
            hideLabel={true}
          >
            dropdown breadcrumbs
          </Button>
        </DropdownToggle>

        <DropdownMenu tag="ol">
          {breadcrumbs?.map((breadcrumb, i) => (
            <DropdownItem tag="li" key={i}>
              <Breadcrumb breadcrumb={breadcrumb} />
            </DropdownItem>
          ))}
        </DropdownMenu>
      </UncontrolledDropdown>
    </>
  );
};

export const BreadcrumbsAndTitle: React.FC<Props> = props => {
  let crumbs: JSX.Element | JSX.Element[] | null = null;
  if (props.minimizedBreadcrumbs) {
    crumbs = <MinimizedBreadcrumbs breadcrumbs={props.breadcrumbs} />;
  } else if (props.breadcrumbs) {
    crumbs = props.breadcrumbs.map((breadcrumb, i) => (
      <li key={i}>
        <Breadcrumb breadcrumb={breadcrumb} />
      </li>
    ));
  }
  return (
    <ol className={cn('BreadcrumbsAndTitle', props.className)}>
      {crumbs}
      <li
        className={cn(
          'BreadcrumbsAndTitle-title',
          props.minimizedBreadcrumbs ? 'minimized' : undefined
        )}
      >
        {props.title}
      </li>
    </ol>
  );
};
