import { DateString } from 'src/lib/types';
import * as yup from 'yup';
import moment from 'moment';
import { t } from 'src/lib/i18n';
import {
  Personalia,
  Roles,
} from 'src/areas/main/contacts/lib/createContact/types';
import { validRoles } from 'src/areas/main/contacts/lib/editContact/utils';

/**
 * More advanced yup-validdations
 */

const dateRegex = /^\s*(3[01]|[12][0-9]|0?[1-9])\.(1[012]|0?[1-9])\.((?:19|20)\d{2})\s*$/;

export const yupValidateDate = () =>
  yup
    .string()
    .required(t.ordering.dateLabels.required)
    .matches(dateRegex, t.ordering.dateLabels.match);

export const yupValidateDateAndIsBeforeTomorrow = () =>
  yup
    .string()
    .required(t.ordering.dateLabels.required)
    .matches(dateRegex, t.ordering.dateLabels.match)
    .test({
      name: 'excludeFutureDays',
      test: (value: string) => {
        const inputMoment = moment(value, 'DD.MM.YYYY');
        return inputMoment.isSameOrBefore();
      },
      message: t.ordering.dateLabels.notFutureDate,
    });

export const yupValidateDateAndIsAfterTomorrow = (daysAfterTomorrow?: number) =>
  yup
    .string()
    .required(t.ordering.dateLabels.required)
    .matches(dateRegex, t.ordering.dateLabels.match)
    .test({
      name: 'excludePastDays',
      test: (value: string) => {
        const inputMoment = moment(value, 'DD.MM.YYYY');
        return inputMoment.isAfter(
          daysAfterTomorrow
            ? moment()
                .add(daysAfterTomorrow, 'd')
                .startOf('day')
            : moment().startOf('day')
        );
      },
      message: t.ordering.dateLabels.notPastDate,
    });

export const yupValidateDateIsAfter = (startDate?: DateString) =>
  yup
    .string()
    .required(t.ordering.dateLabels.required)
    .matches(dateRegex, t.ordering.dateLabels.match)
    .test({
      name: 'excludePastDays',
      test: (value: string) => {
        const inputMoment = moment(value, 'DD.MM.YYYY');
        return inputMoment.isAfter(
          startDate ? moment(startDate).startOf('day') : moment().startOf('day')
        );
      },
      message: t.ordering.dateLabels.notPastDate,
    });

export const yupValidatePostalCode = (errorMessage: string) =>
  yup
    .string()
    .required(errorMessage)
    .matches(/\d{4}/, errorMessage);

export const yupValidatePhone = (errorMessage: string) =>
  yup
    .string()
    .min(8, t.contacts.EditContact.errors.tooShort(8))
    .matches(/(^(\+?-? *[0-9]+)([,0-9 ]*)([0-9])*$)/, {
      message: errorMessage,
      excludeEmptyString: true,
    });

export const yupValidateEmail = (errorMessage: string) =>
  yup
    .string()
    .required(t.contacts.EditContact.errors.required)
    .email(errorMessage);

export const yupValidatePersonalia = yup.object().shape<Personalia>({
  firstName: yup.string().required(t.contacts.EditContact.errors.required),
  lastName: yup.string().required(t.contacts.EditContact.errors.required),
  email: yupValidateEmail(t.contacts.EditContact.errors.email),
  mobilePhone: yupValidatePhone(t.contacts.EditContact.errors.phone),
  secondaryPhone: yupValidatePhone(t.contacts.EditContact.errors.phone),
  description: yup
    .string()
    .max(100, t.contacts.EditContact.errors.tooLong(100)),
  employeeNumber: yup
    .string()
    .max(40, t.contacts.EditContact.errors.tooLong(40)),
});

export const yupValidateRoles = yup.object<Roles>().test<Roles>({
  test: (values: Roles) => validRoles(values),
});
