import gql from 'graphql-tag';

export const FileFragment = gql`
  fragment FileSearchResult on File {
    id
    name
    folderId
    size
    uploaded
    uploadedBy
    mimeType
    uploading
    downloadToken
    teliaInternal
  }
`;
