import * as React from 'react';
import { Attribute } from 'src/lib/ui';
import cn from 'classnames';
import { t } from 'src/lib/i18n';

import './StatisticsDetails.scss';

const StatisticsDetails: React.FunctionComponent<{}> = props => (
  <div className="px-4 py-3">
    <h3>{t.statistics.StatisticsDetails.header}</h3>
    <div className="StatisticsDetails">{props.children}</div>
  </div>
);

const NewStatisticsDetails: React.FunctionComponent<{
  className?: string;
}> = props => {
  return (
    <div className={cn('NewStatisticsDetails row', props.className)}>
      {props.children}
    </div>
  );
};

const StatisticsDetailsBlock: React.FunctionComponent<{}> = props => (
  <div className="StatisticsDetails-block">{props.children}</div>
);

const NewStatisticsDetailsBlock: React.FunctionComponent<{
  heading: React.ReactNode;
  fullWidth?: boolean;
}> = props => (
  <div
    className={cn(
      'NewStatisticsDetails-column',
      props.fullWidth ? 'col-md-12' : 'col-md-6'
    )}
  >
    <h2>{props.heading}</h2>
    <div>{props.children}</div>
  </div>
);

const StatisticsDetailsItem: React.FunctionComponent<{
  title: string;
  value: string;
}> = props => <Attribute label={props.title} value={props.value} />;

const StatisticsDetailsItemLine: React.FunctionComponent<{}> = props => (
  <div className="StatisticsDetails-item-line my-2" />
);

export {
  StatisticsDetails,
  NewStatisticsDetails,
  StatisticsDetailsBlock,
  NewStatisticsDetailsBlock,
  StatisticsDetailsItem,
  StatisticsDetailsItemLine,
};
