import nb from '../nb';

import cases from './Main/cases';
import chat from './Main/chat';
import chatSupport from './Chat/chatSupport';
import contacts from './Main/contacts';
import costs from './Main/costs';
import dashboard from './Main/dashboard';
import documents from './Main/documents';
import incidents from './Main/incidents';
import invoices from './Main/invoices';
import layout from './Main/layout';
import locations from './Main/locations';
import ordering from './Main/ordering';
import orders from './Main/orders';
import search from './Main/search';
import servicePlus from './Main/servicePlus';
import statistics from './Main/statistics';
import subscriptions from './Main/subscriptions';
import tags from './Main/tags';
import reports from './Main/reports';
import myDashboard from './Dashboard/myDashboard';
import profile from './Dashboard/profile';
import notifications from './Dashboard/notifications';
import changelogs from './Dashboard/changelogs';
import customerSelection from './Dashboard/customerSelection';
import ui from './ui';
import menu from './Mobile/menu';
import history from './Mobile/history';
import sms from './Mobile/sms';
import overview from './Mobile/overview';
import settings from './Mobile/settings';
import worklogOrder from './Worklog/worklog';

const en: typeof nb = {
  cases,
  chat,
  chatSupport,
  contacts,
  costs,
  customerSelection,
  dashboard,
  documents,
  incidents,
  invoices,
  layout,
  locations,
  ordering,
  orders,
  search,
  servicePlus,
  statistics,
  subscriptions,
  myDashboard,
  profile,
  notifications,
  reports,
  changelogs,
  worklogOrder,
  tags,
  ui,
  mobile: {
    menu,
    overview,
    history,
    sms,
    settings,
  },
};

export default en;
