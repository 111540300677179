import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import * as t from 'src/areas/main/orders/queries/__types/useMobileUnitOrder';

const MOBILE_ORDER = gql`
  query useMobileUnitOrder($customerId: ID!, $orderId: ID!) {
    customer(id: $customerId) {
      id
      order(id: $orderId) {
        id
        mobileUnitOrderDetails {
          quantity
          portalStatus
          postalArea
          address
          attention
          postalCode
          orderId
        }
      }
    }
  }
`;

export const useMobileUnitOrder = (orderId: string) => {
  const customer = useCustomer();

  return useQuery<t.useMobileUnitOrder, t.useMobileUnitOrderVariables>(
    MOBILE_ORDER,
    {
      variables: {
        customerId: customer.id,
        orderId: orderId,
      },
      fetchPolicy: 'network-only',
      errorPolicy: 'all',
    }
  );
};
