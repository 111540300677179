import chatSupportNb from '../../nb/Chat/chatSupport';

const chatSupport: typeof chatSupportNb = {
  chat: 'Chat',
  waitingText: 'Please wait while we find a free customer consultant.',
  automatedChat: {
    heyUser: (name: string) =>
      `Hi ${name}! You will soon be able to speak to a customer consultant.`,
    heyNoUser: 'Hi! You will soon be able to speak to a customer consultant.',
    waitingMessage:
      'While we wait, can you tell us what the inquiry is about? It makes it easier for us to help you.',
  },
  confirmationModal: {
    header: (name: string) => `End conversation with ${name}`,
    message:
      'When you end your active conversations you will not be able to return to them again.',
    cancel: 'No, go back',
    confirm: 'Yes, end conversations',
  },
  chatSelector: {
    mainText:
      'We are here to make sure you have a good customer experience. What can we help you with?',
    conversation: 'You are in a conversation with',
    stopBtn: 'End active conversations',
    chatCategories: {
      mobCustomerService: 'Mobile customer service',
      customerService: 'Customer service',
      delivery: 'Delivery',
      serviceDesk: 'Service Desk',
    },
  },
};

export default chatSupport;
