import React from 'react';
import { Switch, Route, RouteComponentProps } from 'react-router-dom';
import { useRouter } from 'src/lib/utils/useRouter';
// import PersonaliaSection from './profile/PersonaliaSection';
import { NotificationsSection } from './profile/Notifications/NotificationsSection';
// import ChangePasswordSection from './profile/ChangePasswordSection';
import { TempFullscreen } from './TempFullscreen';
import NotFoundPage from 'src/areas/main/NotFoundPage';

const DashboardRoute = (props: RouteComponentProps) => (
  <Switch>
    {/* <Route
      exact={true}
      path={`${props.match.path}/profil-ny`}
      component={Profile}
    /> */}
    <Route
      exact={true}
      path={`${props.match.path}/varslingsinnstillinger`}
      component={NotificationsSection}
    />
    {/* <Route
      path={`${props.match.path}/profil/varslingsinnstillinger`}
      component={NotificationsSection}
    /> */}
    {/* <Route
      path={`${props.match.path}/profil/endre-passord`}
      component={ChangePasswordSection}
    /> */}

    <Route component={NotFoundPage} />
  </Switch>
);

const DashboardApp = () => {
  const router = useRouter();
  // const [open, setOpen] = useState(false);
  const onCloseHandler = () => {
    router.history.goBack();
  };

  return (
    <TempFullscreen onClose={onCloseHandler}>
      <DashboardRoute {...router} />
    </TempFullscreen>
  );

  // return (
  //   <ProfilePage>
  //     <DahboardRoute {...router} />
  //   </ProfilePage>
  // );
};

export default DashboardApp;
