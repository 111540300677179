import * as React from 'react';

interface Props {
  children?: any;
  narrow?: boolean;
  className?: string;
  style?: React.CSSProperties;
}

const defaultClass = 'InnerPageContainer px-grid';

export const InnerPageContainer: React.FC<Props> = ({
  children,
  narrow,
  style,
  className,
}) => (
  <div
    className={className ? `${defaultClass} ${className}` : defaultClass}
    style={style}
  >
    {narrow ? (
      <div className="row justify-content-center">
        <div className="col-8">{children}</div>
      </div>
    ) : (
      children
    )}
  </div>
);
