import { PeriodFragment } from './../utils/PeriodFragment';
import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import * as t from './__types/useSubscriptionHistoric';
const WITH_HISTORIC = gql`
  query useSubscriptionHistoric(
    $id: ID!
    $periodStart: Period!
    $periodEnd: Period!
  ) {
    subscription(id: $id) {
      id
      historic(periodStart: $periodStart, periodEnd: $periodEnd) {
        ...PeriodFragment
      }
    }
  }
  ${PeriodFragment}
`;

interface Result {
  loading: boolean;
  error: boolean;
  periodDetails: Array<t.useSubscriptionHistoric_subscription_historic | null> | null;
}

export const useSubscriptionHistoric = (
  periodStart: string,
  periodEnd: string
): Result => {
  const { id } = useSubscriptionContext();
  const { data, loading, error } = useQuery<
    t.useSubscriptionHistoric,
    t.useSubscriptionHistoricVariables
  >(WITH_HISTORIC, {
    variables: {
      id,
      periodStart: periodStart,
      periodEnd: periodEnd,
    },
    errorPolicy: 'all',
    skip: !id,
  });

  const subscription = data && data.subscription;
  const res = subscription
    ? {
        error: !!error,
        loading: loading,
        periodDetails: subscription.historic,
      }
    : { error: !!error, loading: loading, periodDetails: [] };

  return res;
};
