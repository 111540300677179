import * as React from 'react';

import {
  Attribute,
  Loading,
  BodyCard,
  Attributes,
  StatusProgressCard,
  StatusProgressCardSteps,
} from 'src/lib/ui';
import { formatDate } from 'src/lib/utils/dateUtils';
import { mapOrderStatusToLabel } from '../utils';
import { t } from 'src/lib/i18n';

import { useSubscriptionOrder } from '../queries/useSubscriptionOrder';
import { PortalOrderStatus } from 'src/__types/graphql-global-types';
import { OrderLinesTable } from './OrderLinesTable';

const countOrderLineStates = (
  orderLines: Array<{
    id: string;
    portalStatus: PortalOrderStatus | null;
  }> | null
): { [key in PortalOrderStatus]: number } => {
  const counter = {
    cancelled: 0,
    completed: 0,
    inProgress: 0,
    newOrder: 0,
    unknown: 0,
  };

  if (!orderLines) {
    return counter;
  }

  orderLines.forEach(line => {
    if (line.portalStatus === null) {
      return;
    }
    counter[line.portalStatus] = counter[line.portalStatus] + 1;
  });
  return counter;
};

const ServiceOrderContent = (props: { id: string }) => {
  const useSubscriptionOrderQuery = useSubscriptionOrder(props.id);
  const subOrder = useSubscriptionOrderQuery.data?.customer?.order;
  const i18n = t.orders.ServiceOrderContent;

  if (useSubscriptionOrderQuery.loading) {
    return <Loading />;
  }

  // TODO: Currently fetching all data for subscriptionOrders.
  // Optimize this and only fetch data for dataSub when we know what data to show, and reuse data from useOrder-query.
  if (!subOrder) {
    return <div>{i18n.noOrder}</div>;
  }

  const orderLineTypeCount = countOrderLineStates(subOrder.orderLines);

  const progressBarStatuses: StatusProgressCardSteps[] = [
    {
      step: t.orders.orderStatus.newOrder,
      icon: 'shoppingcart',
      focus: true,
      description: i18n.newOrderLabel,
    },
    {
      step: t.orders.orderStatus.inProgress,
      icon: 'time-waiting',
      focus: true,
      description: (
        <>
          {orderLineTypeCount.completed > 0 ? (
            <div>
              {orderLineTypeCount.completed === 1
                ? i18n.singleCompletedCase()
                : i18n.multipleCompletedCases(orderLineTypeCount.completed)}
            </div>
          ) : null}
          {orderLineTypeCount.inProgress > 0 ? (
            <div>
              {orderLineTypeCount.inProgress === 1
                ? i18n.singleInProgressCase()
                : i18n.multipleInProgressCases(orderLineTypeCount.inProgress)}
            </div>
          ) : null}
        </>
      ),
    },
    {
      step: t.orders.orderStatus.completed,
      icon: 'check-mark',
      description:
        orderLineTypeCount.completed > 0
          ? orderLineTypeCount.completed === 1
            ? i18n.singleCompletedCase()
            : i18n.multipleCompletedCases(orderLineTypeCount.completed)
          : undefined,
    },
  ];

  return (
    <div className="container">
      <div className="row flex-md-row-reverse">
        <div className="col-md-4">
          <BodyCard className="h-100">
            {subOrder.portalStatus ? (
              <StatusProgressCard
                steps={progressBarStatuses}
                currentStep={progressBarStatuses.findIndex(
                  status =>
                    mapOrderStatusToLabel(subOrder.portalStatus) === status.step
                )}
                hasFailed={
                  subOrder.portalStatus === PortalOrderStatus.cancelled
                }
                failedStep={{
                  step: t.orders.orderStatus.cancelled,
                  icon: 'close',
                  description: i18n.cancelledOrderLabel,
                }}
              />
            ) : null}
          </BodyCard>
        </div>
        <div className="col-md-8">
          <BodyCard heading={i18n.orderDetails} className="h-100">
            <Attributes className="mt-4">
              <Attribute label={i18n.id} value={subOrder.id} />
              <Attribute
                label={i18n.casesCount}
                value={subOrder.orderLines?.length}
              />
              <Attribute
                label={i18n.created}
                value={
                  subOrder.dates?.createdAt
                    ? formatDate(subOrder.dates.createdAt, 'medium')
                    : ''
                }
              />
              <Attribute
                label={i18n.updated}
                value={
                  subOrder.dates?.updatedAt
                    ? formatDate(subOrder.dates.updatedAt, 'medium')
                    : ''
                }
              />
              <Attribute
                label={i18n.description}
                value={subOrder.description}
              />
            </Attributes>
          </BodyCard>
        </div>
        <div className="col mt-4">
          <OrderLinesTable orderLines={subOrder.orderLines ?? []} />
        </div>
      </div>
    </div>
  );
};

export default ServiceOrderContent;
