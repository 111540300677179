const history = {
  history: 'Historikk',
  total: 'Total kostnad',
  product: 'Produkt',
  volume: 'Forbruk',
  subtotal: 'Beløp',
  showMore: 'Vis mer',
  showLess: 'Vis mindre',
  nationalDataVolume: 'Dataforbruk innland',
  roaming: 'Dataroaming',
  abroadVolume: 'Ringeminutter utland',
  nationalCallVolume: 'Ringeminutter innland',
  specialNumberVolume: 'Ringeminutter spesial nummer',
  smsVolume: 'Sendt SMS',
  mmsVolume: 'Sendt MMS',
};

export default history;
