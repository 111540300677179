import React, { useContext } from 'react';
import { Redirect } from 'react-router';
import { useRouter } from 'src/lib/utils/useRouter';
import { AuthContext } from 'src/modules/auth/AuthContext';
import {
  CustomerAccessQuery_me_customerAccess_customer_permissions,
  CustomerAccessQuery_me_contact,
} from './__types/CustomerAccessQuery';
import { useAccessQuery_me } from './__types/useAccessQuery';

export interface CustomerContext {
  id: string;
  name: string;
  myContact: CustomerAccessQuery_me_contact | null;
  access: useAccessQuery_me;
  auth: AuthContext;
  flexFields?: null | Array<{
    id: string;
    label?: string | null;
    options?: Array<{
      option: string;
      description?: string | null;
    }>;
  }>;
  permissions?: CustomerAccessQuery_me_customerAccess_customer_permissions;
}

export const CreateCustomerContext = React.createContext<CustomerContext>(
  {} as CustomerContext
);
const { Provider, Consumer } = CreateCustomerContext;

const useCustomer = (opts?: { optional?: boolean }) => {
  const optional = opts && opts.optional === true;
  const customer = useContext(CreateCustomerContext);
  const router = useRouter();

  if (!optional && !customer) {
    throw new Error('No customer');
  } else if (
    !optional &&
    !customer.id &&
    !(router.history.location.pathname.indexOf('/velg-kunde') === 0)
  ) {
    router.history.push('/velg-kunde');
  }
  return customer;
};

const WithCustomerContext: React.SFC<{
  children: (customer: CustomerContext) => React.ReactNode;
}> = props => (
  <Consumer>
    {customer => {
      if (!customer) {
        throw new Error('No customer');
      } else if (!customer.id) {
        return <Redirect to="/velg-kunde" />;
      }
      return props.children(customer);
    }}
  </Consumer>
);

export {
  Provider as CustomerContextProvider,
  Consumer as CustomerContextConsumer,
  WithCustomerContext,
  useCustomer,
};
