import * as React from 'react';
import { RouteComponentProps, withRouter } from 'react-router';
import qs from 'query-string';

import { isEmpty } from 'lodash';

// Component for reading all query parameters and immediately removing them from the URL.

interface Fields {
  [key: string]: string | string[] | undefined | null;
}

interface State {
  read: boolean;
  fields: Fields;
}

type Props = RouteComponentProps<{}> & {
  children: (fields: Fields) => React.ReactNode | null;
  removeFromUrl?: boolean;
  refreshOnUrlChange?: boolean;
};

class InnerConsumeQueryParameters extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      read: false,
      fields: {},
    };
  }

  componentDidMount() {
    const fields = qs.parse(this.props.location.search);
    // Redirect to the same URL without the query string (we do either this,
    // or we need to update the URL to reflect the currently set filter while
    // using the application).
    // Though, we only do it if the URL actually contains any parameters.
    this.setState({ fields, read: true }, () => {
      if (this.props.removeFromUrl !== false && !isEmpty(fields)) {
        this.props.history.replace(this.props.location.pathname);
      }
    });
  }

  componentDidUpdate(prevProps) {
    // If child component is dynamically reading and changing queryparams,
    // allow component to update its state as well to have newest queryparams
    if (!this.props.refreshOnUrlChange) {
      return;
    }
    if (prevProps.location.search !== this.props.location.search) {
      const fields = qs.parse(this.props.location.search);
      this.setState({ fields, read: true });
    }
  }

  render() {
    // We don't want to render anything until we've read the query string.
    // It's important that the query string is available to the children
    // on their first render.
    if (!this.state.read) {
      return null;
    }
    return this.props.children(this.state.fields);
  }
}

export const ConsumeQueryParameters = withRouter(InnerConsumeQueryParameters);
