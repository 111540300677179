import * as React from 'react';
import './ProgressBar.scss';
import { colors } from 'src/lib/ui';
interface ProgressBarProps {
  value: number;
  maxValue: number;
  color?: string;
  unit?: string;
}

export const ProgressBar: React.SFC<ProgressBarProps> = props => (
  <div className="ProgressBar">
    <div
      className="ProgressBar-label"
      style={{ color: props.color ?? '#009999' }}
    >
      {props.value} {props.unit}
    </div>
    <div className="ProgressBar-bar">
      <div
        style={{
          width: `${Math.min((props.value / props.maxValue) * 100, 100)}%`,
          backgroundColor: props.color ? props.color : '#009999',
        }}
      />
    </div>
    <div className="w-100 d-flex pt-2" style={{ color: colors.greyDarkText }}>
      <div>0 {props.unit}</div>
      <div className="ml-auto">
        {props.maxValue} {props.unit}
      </div>
    </div>
  </div>
);
