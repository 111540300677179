import * as React from 'react';
import { SearchHistoryEntry } from 'src/lib/localState';
import { Provider } from './UserPreferencesContext';
import { Locale } from 'src/lib/i18n';

const localStorageKey = 'appState';
const maxSearchHistoryEntries = 8;

export interface UserPreferences {
  searchHistoryEntries: SearchHistoryEntry[];
  activeCustomerId?: string;
  activeCustomerName?: string;
  showRecursiveCost?: boolean;
  preferredLocale?: Locale;
}

class UserPreferencesProvider extends React.Component<{}, UserPreferences> {
  constructor(props: {}) {
    super(props);
    this.state = {
      searchHistoryEntries: [],
      activeCustomerId: undefined,
      activeCustomerName: undefined,

      // Recursive should be true by default
      showRecursiveCost: true,

      preferredLocale: undefined,
    };
    try {
      const local = localStorage.getItem(localStorageKey);
      if (local) {
        this.state = JSON.parse(local);
      }
    } catch (err) {
      console.log(err);
    }
  }

  writeToStorage = () => {
    try {
      localStorage.setItem(localStorageKey, JSON.stringify(this.state));
    } catch (err) {
      console.log(err);
    }
  };

  set = (partialState: Partial<UserPreferences>) =>
    this.setState(
      prevState => ({
        ...prevState,
        ...partialState,
      }),
      this.writeToStorage
    );

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      this.writeToStorage();
    }
  }

  deleteSearchHistoryEntry = (idx: number) => {
    this.setState(prevState => {
      if (idx >= 0 && idx < prevState.searchHistoryEntries.length) {
        return {
          searchHistoryEntries: [
            ...prevState.searchHistoryEntries.slice(0, idx),
            ...prevState.searchHistoryEntries.slice(idx + 1),
          ],
        };
      }
      return null;
    });
  };

  addSearchHistoryEntry = (query: string) => {
    this.setState(prevState => {
      return {
        searchHistoryEntries: [
          { query },
          ...prevState.searchHistoryEntries.filter(e => e.query !== query),
        ].slice(0, maxSearchHistoryEntries),
      };
    });
  };

  setPreferredCustomer = (customerId: string, customerName: string) =>
    this.setState(
      {
        activeCustomerId: customerId,
        activeCustomerName: customerName,
      },
      this.writeToStorage
    );

  setRecursiveCostPreference = (preference: boolean) =>
    this.setState(
      {
        showRecursiveCost: preference,
      },
      this.writeToStorage
    );

  setPreferredLocale = (locale: Locale | undefined) =>
    this.setState(
      {
        preferredLocale: locale,
      },
      this.writeToStorage
    );

  render() {
    return (
      <Provider
        value={{
          ...this.state,
          deleteSearchHistoryEntry: this.deleteSearchHistoryEntry,
          addSearchHistoryEntry: this.addSearchHistoryEntry,
          setPreferredCustomer: this.setPreferredCustomer,
          setRecursiveCostPreference: this.setRecursiveCostPreference,
          set: this.set,
          setPreferredLocale: this.setPreferredLocale,
        }}
      >
        {this.props.children}
      </Provider>
    );
  }
}

export default UserPreferencesProvider;
