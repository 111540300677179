import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { FileFragment } from 'src/lib/types';
import { useAppContext } from 'src/lib/global';
import { FolderFragment } from 'src/lib/types/documents/Folder';
import * as t from './__types/useDocumentArchiveFilesAndFolders';

export const DOCUMENT_ARCHIVE_QUERY = gql`
  query useDocumentArchiveFilesAndFolders($customerId: ID!) {
    customer(id: $customerId) {
      id
      documentArchive {
        allFolders {
          ...FolderFragment
        }
        allFiles {
          ...FileSearchResult
        }
      }
    }
  }
  ${FileFragment}
  ${FolderFragment}
`;

interface Result {
  loading: boolean;
  files: t.useDocumentArchiveFilesAndFolders_customer_documentArchive_allFiles[];
  folders: t.useDocumentArchiveFilesAndFolders_customer_documentArchive_allFolders[];
  refetch?: () => void;
}

const useDocumentArchiveFilesAndFolders = (): Result => {
  const appContext = useAppContext();
  const { data, loading, refetch } = useQuery<
    t.useDocumentArchiveFilesAndFolders,
    t.useDocumentArchiveFilesAndFoldersVariables
  >(DOCUMENT_ARCHIVE_QUERY, {
    variables: {
      customerId: appContext.activeCustomerId || '',
    },
    skip: !appContext.activeCustomerId,
  });
  const files = data?.customer?.documentArchive?.allFiles
    ? data.customer.documentArchive.allFiles.filter(f => f?.uploading !== true)
    : [];
  const folders = data?.customer?.documentArchive?.allFolders ?? [];
  return { loading, files, folders, refetch };
};

export { useDocumentArchiveFilesAndFolders };
