import React from 'react';
import cn from 'classnames';
import FocusLock from 'react-focus-lock';

import './SlideOverlay.scss';

interface SlideOverlayProps {
  isOpen: boolean;
  onClose: () => void;
}

export const SlideOverlay: React.FC<SlideOverlayProps> = props => {
  return (
    <div
      className={cn('SlideOverlay', { 'SlideOverlay--open': props.isOpen })}
      onClick={e => {
        if (e.target === e.currentTarget) {
          props.onClose();
        }
      }}
    >
      <div className="SlideOverlay-slideContent">
        <FocusLock>{props.children}</FocusLock>
      </div>
    </div>
  );
};
