import gql from 'graphql-tag';
import { useMutation } from '@apollo/react-hooks';
import { EditTagInput } from './../../../../__types/graphql-global-types';
import * as types from './__types/useEditTag';

const EDIT_TAG = gql`
  mutation useEditTag($input: EditTagInput) {
    editTag(input: $input) {
      tag {
        id
        tag
        description
      }
      error {
        code
        message
      }
    }
  }
`;

export const useEditTagMutation = () => {
  const [mutation, { data, loading, error }] = useMutation<
    types.useEditTag,
    types.useEditTagVariables
  >(EDIT_TAG);
  const editTag = (input: EditTagInput) => {
    mutation({
      variables: {
        input: input,
      },
    });
  };
  return {
    editTag: editTag,
    data,
    loading,
    error,
  };
};
