import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import * as t from './__types/usePingStatus';
import { useCustomer } from 'src/lib/global';

const GET_SUBSCRIPTION_STATUS = gql`
  query usePingStatus($customerId: ID!, $subscriptionId: ID!) {
    customer(id: $customerId) {
      id
      subscriptions(ids: [$subscriptionId]) {
        id
        cpe {
          status
          uptime
        }
        statisticsGSMRecent {
          currentBand
          currentChannelNumber
          measurementTimeStamp
        }
      }
    }
  }
`;

interface Props {
  subscriptionId: string;
  redundanciesIdList?: null | Array<{ id: string }>;
}

export const usePingStatus = (props: Props) => {
  const customer = useCustomer();
  const { data, loading, refetch, fetchMore } = useQuery<
    t.usePingStatus,
    t.usePingStatusVariables
  >(GET_SUBSCRIPTION_STATUS, {
    notifyOnNetworkStatusChange: true,
    variables: {
      customerId: customer.id,
      subscriptionId: props.subscriptionId,
    },
    fetchPolicy: 'network-only',
  });

  /**
   * This also fetches data and updated the cache so status will be updated
   */
  const ping = () => {
    refetch();
    /**
     * This is to ensure that redundancies is also refetched to get latest status.
     */
    if (
      props.redundanciesIdList !== undefined &&
      props.redundanciesIdList !== null
    ) {
      props.redundanciesIdList.forEach(r => {
        fetchMore({
          variables: {
            customerId: customer.id,
            subscriptionId: r.id,
          },
          updateQuery: ((prev, { fetchMoreResult }) => {
            return;
          }) as any,
        });
      });
    }
  };

  return {
    uptime: data?.customer?.subscriptions?.[0]?.cpe?.uptime,
    ping,
    loading,
  };
};
