import * as React from 'react';
import {
  ComposedChart,
  XAxis,
  YAxis,
  Tooltip,
  ResponsiveContainer,
  Label,
  Area,
  Line,
} from 'recharts';
import { Card, colors } from 'src/lib/ui';
import { format } from 'date-fns';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
export interface Tick {
  in: number;
  out: number;
  timestamp: string;
}
interface Props {
  data: Tick[];
}

const defaultMargin = { top: 30, right: 0, bottom: 0, left: 0 };
const formatDate = tick => {
  if (!tick) {
    return '';
  }
  return format(new Date(tick), 'HH:mm:ss');
};

const RealTimeChart: React.SFC<Props> = props => {
  return (
    <div>
      <Card padding={true}>
        <h3 className="mb-1">Live trafikk</h3>
        <ResponsiveContainer width="100%" height={400}>
          <ComposedChart data={props.data} margin={defaultMargin}>
            <XAxis
              dataKey="timestamp"
              tickFormatter={formatDate}
              tickLine={false}
            />
            <YAxis tickLine={false}>
              <Label value="Mbit/s" position="top" offset={10} />
            </YAxis>
            <Tooltip content={renderRealtimeTooltip} />
            <Line isAnimationActive={false} dataKey="in" />
            <Area
              isAnimationActive={false}
              dataKey="out"
              fill="rgba(0, 163, 136, 0.3)"
              stroke="rgba(0, 163, 136, 0.3)"
            />
          </ComposedChart>
        </ResponsiveContainer>
      </Card>
    </div>
  );
};

const renderRealtimeTooltip = props => {
  if (
    props.active &&
    props.payload &&
    props.payload[0] &&
    props.payload[0].payload
  ) {
    const tooltipData = props.payload[0].payload as Tick;
    return (
      <CustomTooltipContainer>
        <Header data={tooltipData.timestamp} hideTimestamp={false} />
        <Section header="Traffic">
          <SectionItem
            title="In"
            color={colors.graph_blueDark}
            value={`${tooltipData.in.toFixed(4)} Mbit/s`}
          />
          <SectionItem
            title="Out"
            color={colors.graph_blueLight}
            value={`${tooltipData.out.toFixed(4)} Mbit/s`}
          />
        </Section>
      </CustomTooltipContainer>
    );
  }
  return null;
};

export default RealTimeChart;
