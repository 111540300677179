import * as React from 'react';
import { Query } from 'react-apollo';
import gql from 'graphql-tag';
import { ApolloError } from 'apollo-client';
import { WithCustomerContext } from 'src/lib/global';
import { CostCenter, Organisation } from 'src/lib/types';
import { MobileType } from '../types';
import {
  unwrapWrappedSearchResults,
  WrappedSearchResult,
} from 'src/areas/main/search';
import * as types from './__types/WithMobile';

const WITH_MOBILE = gql`
  query WithMobile(
    $customerId: ID!
    $mobileType: String!
    $search: SearchInput
  ) {
    customer(id: $customerId) {
      id
      voiceNumbers: availableNumbers(type: "voice")
      dataNumbers: availableNumbers(type: "data")
      availableSimCards {
        simCardNumber
      }
      organisationsByType(type: $mobileType) {
        id
        name
      }
      search(input: $search) {
        results {
          __typename
          ... on CostCenterSearchResult {
            costCenter {
              id
              code
              description
            }
          }
        }
      }
    }
  }
`;

interface SimCard {
  simCardNumber: string;
}

export interface MobileProductsData {
  loading: boolean;
  error: ApolloError | null;
  data: {
    voiceNumbers?: string[];
    dataNumbers?: string[];
    availableSimCards?: SimCard[];
    organisations?: Array<Organisation>;
    costCenters?: Array<CostCenter>;
  };
}

interface Props {
  mobileType: MobileType;
  children: (data: MobileProductsData) => JSX.Element | null;
}

const WithMobile: React.SFC<Props> = (props: Props) => {
  return (
    <WithCustomerContext>
      {customerContext => (
        <Query<types.WithMobile, types.WithMobileVariables>
          query={WITH_MOBILE}
          variables={{
            customerId: customerContext.id,
            mobileType: props.mobileType,
            search: {
              types: ['costCenter'],
              size: 10000,
            },
          }}
          errorPolicy="all"
          fetchPolicy="network-only"
        >
          {result => {
            return props.children({
              data: {
                voiceNumbers: result.data?.customer?.voiceNumbers ?? undefined,
                dataNumbers: result.data?.customer?.dataNumbers ?? undefined,
                availableSimCards:
                  result.data?.customer?.availableSimCards ?? undefined,
                organisations:
                  result.data?.customer?.organisationsByType ?? undefined,
                costCenters: result?.data?.customer?.search?.results
                  ? (unwrapWrappedSearchResults(
                      result.data.customer.search
                        .results as WrappedSearchResult[]
                    ) as CostCenter[])
                  : undefined,
              },

              loading: result.loading,
              error: result.error || null,
            });
          }}
        </Query>
      )}
    </WithCustomerContext>
  );
};

export default WithMobile;
