import React, { useState } from 'react';
import {
  Switch,
  Route,
  Link,
  withRouter,
  RouteComponentProps,
} from 'react-router-dom';
import cs from 'classnames';

import Menu from './common/Menu';
import { MainMenuItem, MainMenuDivider } from 'src/lib/ui';
import NotFoundPage from './NotFoundPage';

import Locations from './locations/Locations';
import Incidents from './incidents/Incidents';
import Dashboard from './dashboard/Dashboard';
import Documents from './documents/Documents';
import Invoices from './invoices/Invoices';
import Contacts from './contacts/ContactsSection';
import Search from './search/Search';
import Subscriptions from './subscriptions/Subscriptions';
import Orders from './orders/Orders';
import Ordering from './ordering/Ordering';
import Reports from './reports/Reports';
import CannyApp from './canny/CannyApp';

// For search element
import { SearchField, WithSearch } from './search';
import { trackMainSearch } from 'src/lib/analytics';
import { debounce } from 'lodash';

// For profile dropdown elements
import { AppContextConsumer, AppContext, useAppContext } from 'src/lib/global';
import { CustomerSelector } from './common/CustomerSelector';
import { AreaSelectorDropdown } from './common/AreaSelectorDropdown';

import Cost from './cost/Cost';

import './MainApp.scss';
import { urlFor, SlideOverlay } from 'src/lib/ui';
import { Button } from 'src/lib/ui';
import logo from 'src/images/telia-logo.svg';
import { WithI18n, t } from 'src/lib/i18n';
import Notifications from 'src/areas/dashboard/Notification/Notifications';
import NotificationDropdown from 'src/areas/dashboard/Notification/NotificationDropdown';
import { useNotifications } from 'src/areas/dashboard/Notification/query/useNotifications';
import SearchMenu from './search/components/SearchMenu';
import ServicePlus from 'src/areas/main/servicePlus/ServicePlus';
import { ChangelogDropDown } from 'src/areas/dashboard/Changelog/ChangelogDropdown';
import { Icon } from '@telia/styleguide';
import ChangelogPage from 'src/areas/main/changelog/ChangelogPage';
import { SortOrder } from '../../__types/graphql-global-types';
import { ProfileDropdown } from 'src/areas/main/common/ProfileDropdown';
import { ReportContextProvider } from './reports/context/ReportContext';
import { GlobalBanner } from './dashboard/lib/DashboardTwitterMessage/GlobalBanner';
import { useFeature } from 'src/lib/utils';

const MainContent: React.SFC = () => (
  <ReportContextProvider>
    <Switch>
      <Route exact={true} path="/" component={Dashboard} />

      <Route path="/kontakter" component={Contacts} />

      <Route path="/dokumenter" component={Documents} />
      <Route path="/service-plus" component={ServicePlus} />
      <Route path="/meldinger" component={Incidents} />
      <Route path="/kart/:subscriptionId?" component={Locations} />
      <Route path="/tjenester" component={Subscriptions} />
      <Route path="/kostnader" component={Cost} />
      <Route path="/fakturaer" component={Invoices} />
      <Route path="/feedback" component={CannyApp} />

      <Route path="/varslinger" component={Notifications} />
      <Route path="/ordre" component={Orders} />
      <Route path="/bestilling" component={Ordering} />
      <Route path="/rapporter" component={Reports} />
      <Route path="/changelog" component={ChangelogPage} />
      <Route path="/sok" component={Search} />
      <Route component={NotFoundPage} />
    </Switch>
  </ReportContextProvider>
);

const trackSearch = debounce((query: string) => trackMainSearch(query), 2000);

const InnerMainSearchField: React.FC<RouteComponentProps<{}>> = props => (
  <WithSearch searchImmediately={false}>
    {search => {
      search.query && trackSearch(search.query);
      return (
        <SearchField
          searchProvider={search}
          searchMenu={SearchMenu}
          isExpandable={true}
          onSearch={() => {
            const currentQuery = search.query;
            // Commented out because we want to leave the searchValue in the searchField for quick editing of previous searchValue for the user.
            // search.setQuery('');
            props.history.push(
              urlFor({
                area: 'search',
                query: currentQuery,
              })
            );
          }}
        />
      );
    }}
  </WithSearch>
);

const MainSearchField = withRouter(InnerMainSearchField);

const MainCustomerSelector: React.FC = () => (
  <AppContextConsumer>
    {app => (
      <CustomerSelector
        customerList={
          app.access && app.access.customers ? app.access.customers : []
        }
        allowSearch={app.access.allowCustomerSearch === true}
        activeCustomerName={app.activeCustomerName || ''}
        setActiveCustomer={app.setActiveCustomer}
        renderAsDropdown={true}
      />
    )}
  </AppContextConsumer>
);

interface MainAppMenuTogglerProps {
  // isOpen: boolean;
  className?: string;
  onClick: () => void;
}

export const MainAppMenuToggler: React.FC<MainAppMenuTogglerProps> = props => (
  <Button
    className={props.className}
    icon="menu"
    color="white"
    hideLabel={true}
    onClick={props.onClick}
    transparent={true}
  >
    {t.layout.MainApp.closeMenu}
  </Button>
);

export const MainAppMenuLogo: React.FC<{ to: string }> = ({ to }) => (
  <div className="MainApp-menulogo">
    <Link to={to}>
      <img src={logo} alt="Telia logo" />
    </Link>
  </div>
);

const MainMobileMenuProfileOptions: React.FC<{ app: AppContext }> = ({
  app,
}) => (
  <div>
    <WithI18n>
      {i18n => (
        <>
          {i18n.locale !== 'nb' && (
            <MainMenuItem
              label={t.layout.ProfileDropdown.switchToNorwegian}
              onClick={() => i18n.setLocale('nb')}
            />
          )}
          {i18n.locale !== 'en' && (
            <MainMenuItem
              label={t.layout.ProfileDropdown.switchToEnglish}
              onClick={() => i18n.setLocale('en')}
            />
          )}
        </>
      )}
    </WithI18n>

    <li className="nav-item">
      <a
        className="nav-link"
        href={t.layout.ProfileDropdown.privacyStatementLink}
        rel="noopener noreferrer"
        target="_blank"
      >
        {t.layout.ProfileDropdown.privacyStatement}
      </a>
    </li>

    <MainMenuItem
      label={t.layout.ProfileDropdown.logOut}
      onClick={app.auth.requestSignOut}
    />

    {app.access.mobileSubscriptions &&
    app.access.mobileSubscriptions.length > 0 ? (
      <div>
        <div
          style={{
            display: 'block',
            padding: '.5rem 0',
            marginBottom: '0',
            fontSize: '.875rem',
            color: '#6c757d',
            whiteSpace: 'nowrap',
          }}
        >
          {t.layout.menu.mobileSubscriptionsHeading}
        </div>
        {app.access.mobileSubscriptions.map(subscription => (
          <div key={subscription.id} style={{ margin: '8px 0' }}>
            <Link to="/minside">
              <Icon icon="mobile" className="pr-2" />
              {subscription.id}
            </Link>
          </div>
        ))}
      </div>
    ) : null}
  </div>
);

const MainApp = () => {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const res = useNotifications({
    size: 15,
    sortOrder: SortOrder.desc,
    filters: [{ filter: 'read', value: ['false'] }],
    sortBy: 'created',
  });
  const app = useAppContext();
  const closeMenu = () => setMobileMenuOpen(false);
  const showGlobalBanner = useFeature('show-global-banner');

  return (
    <>
      <header className="MainApp-header">
        <div className="MainApp-actions">
          <MainAppMenuToggler
            className="d-block d-lg-none"
            onClick={() => setMobileMenuOpen(!mobileMenuOpen)}
          />
          <MainAppMenuToggler
            className="d-none d-lg-block"
            onClick={() => setMenuOpen(!menuOpen)}
          />
          <MainAppMenuLogo to="/" />

          <div id="MainApp-header-portal" />

          <div className="MainApp-search">
            <MainSearchField />
          </div>

          <Button
            className="MainApp-searchbutton"
            icon="search"
            to={urlFor({ area: 'search' })}
            hideLabel={true}
            color="white"
            transparent={true}
          >
            {t.layout.MainApp.search}
          </Button>

          <div className="MainApp-customerselector">
            <MainCustomerSelector />
          </div>

          <div className="MainApp-notifications">
            <NotificationDropdown
              notifications={res?.search?.results}
              refetch={res && res.refetch}
              totalResults={res?.search?.totalResults || 0}
            />
          </div>
          <ChangelogDropDown />

          <div className="MainApp-areaselector">
            <AreaSelectorDropdown />
          </div>

          <div className="MainApp-profiledropdown">
            <ProfileDropdown />
          </div>
        </div>
      </header>

      <div
        className={cs({
          'MainApp-menu': true,
          'MainApp-menu-desktop--open': menuOpen,
        })}
      >
        <div className="d-none d-lg-block mr-4">
          <Menu onClickItem={closeMenu} />
        </div>
      </div>

      <div className="d-block d-lg-none">
        <SlideOverlay isOpen={mobileMenuOpen} onClose={closeMenu}>
          <div className="MainApp-menu-mobile">
            <div className="px-4 mt-2 mb-4">
              <div className="MainApp-menu-title">{app.access.name}</div>
              <small style={{ wordWrap: 'break-word' }}>
                {app.access.email}
              </small>
            </div>

            <Menu
              onClickItem={closeMenu}
              className="mt-2"
              renderBefore={
                app.activeCustomerName ? (
                  <>
                    <MainMenuItem
                      icon="org-chart"
                      exact={true}
                      label={app.activeCustomerName}
                      to="/velg-kunde"
                      onClick={closeMenu}
                    />
                    <MainMenuDivider />
                  </>
                ) : (
                  undefined
                )
              }
            >
              <MainMenuDivider />
              <MainMobileMenuProfileOptions app={app} />
            </Menu>
          </div>
        </SlideOverlay>
      </div>

      <div
        className={cs({
          'MainApp-body': true,
          'MainApp-body--fullWidth': !menuOpen,
        })}
      >
        {showGlobalBanner && <GlobalBanner />}
        <MainContent />
      </div>
    </>
  );
};

export default MainApp;
