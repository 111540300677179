function mouseDownHandler() {
  document.body.classList.remove('user-is-tabbing');
  window.removeEventListener('mousedown', mouseDownHandler);
  window.addEventListener('keydown', tabKeyHandler);
}

function tabKeyHandler(e) {
  if (e.keyCode === 9) {
    document.body.classList.add('user-is-tabbing');
    window.removeEventListener('keydown', tabKeyHandler);
    window.addEventListener('mousedown', mouseDownHandler);
  }
}

/**
 * Detect usage of tab keys and add .user-is-tabbing to the body element.
 */
export default function enableTabKeyDetector() {
  window.addEventListener('keydown', tabKeyHandler);
}
