import React from 'react';
import { BodyCard, CardBody, Loading } from 'src/lib/ui';
import { useStatisticsParams } from '../../utils/useStatisticsParams';
import { useStatisticsInterfaces } from '../../utils/useStatisticsInterfaces';
import { useLiveStatistics } from '../../queries/WithStatisticsRealtime';
import StatisticsRealtime from '../../components/StatisticsRealtime';

import { t } from 'src/lib/i18n';

export const Live = () => {
  const params = useStatisticsParams();
  const statisticsQuery = useLiveStatistics({
    subscriptionId: params.subscriptionId,
    interface: params.currentInterface,
  });
  const interfaces = useStatisticsInterfaces(params.subscriptionId);

  const currentInterface = interfaces.liveInterfaces?.find(
    i => i.value === params.currentInterface
  );
  const subTitle = currentInterface
    ? `${currentInterface.label} ${currentInterface.subLabel}`
    : undefined;

  return (
    <BodyCard noGutter={true}>
      <CardBody>
        {statisticsQuery.loading || interfaces.loading ? (
          <Loading />
        ) : statisticsQuery.data === null ? (
          <div>{t.statistics.noData}</div>
        ) : (
          <StatisticsRealtime
            key={params.currentInterface}
            data={statisticsQuery.data}
            timestamp={new Date()}
            newView={true}
            subTitle={subTitle}
          />
        )}
      </CardBody>
    </BodyCard>
  );
};
