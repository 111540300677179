import React from 'react';
import { LinkTo } from 'src/lib/ui';
import './SidebarListItem.scss';
import { Subscription } from 'src/lib/types';
import { SubscriptionCard } from '../../../../subscriptions/lib/SubscriptionCard/SubscriptionCard';

import { formatInstallationAddress } from 'src/lib/utils';

export const SidebarListItem = (props: { subscription: Subscription }) => {
  const fullAddress = formatInstallationAddress(
    props.subscription.installationAddress,
    'fullAddress'
  );
  return (
    <div className="SidebarListItem px-4 py-3 border-bottom">
      <LinkTo area="map" subscription={props.subscription.id}>
        <h3 className="SidebarListItem-header">
          <span className="AddressItem" title={fullAddress}>
            {fullAddress}
          </span>
        </h3>

        <SubscriptionCard subscription={props.subscription} />
      </LinkTo>
    </div>
  );
};
