import profileNb from '../../nb/Dashboard/profile';
import { customerSupportEmail, customerSupportNumber } from '../../variables';
export const profile: typeof profileNb = {
  settings: 'Settings',
  tabs: {
    profile: 'Profile',
    notificationPreferences: 'Notifications',
  },
  personaliaForm: {
    edit: 'Edit',
    description:
      'This is the email address you use to login into Min Portal. The email address will be visible for other contacts that can se your contact information',
    aboutTitle: 'About you',
    firstName: 'First name',
    lastName: 'Last name',
    email: 'Email',
    phoneNumber: 'Phone number',
    btnSave: 'Save',
    emailError: 'Invalid email',
    existingEmail: 'The email address is in use',
    changeEmail: 'Edit email address',
  },
  identityInformation: {
    lookingForUserSettings: 'Are you looking for user settings?',
    changePassword: 'Change password',
    changePasswordInfo:
      'The link opens a new tab where you can change the password for your user.',
    clickToChangePassword: 'Click here to change password',

    changeMFA: 'Change settings for two factor authentication',
    changeMFAInfo:
      'The link opens a new tab where you can change your settings for two factor authentication',
    clickToChangeMFA: 'Click here to change two factor authentication',

    changeEmailAddress: 'Change email address',
    notPossible:
      'It is currently not possible to change an email address that is connected to your user in Min Portal.',
    contactCustomerService: `If you need to change email address contact customer service on chat, by calling ${customerSupportNumber} or by sending an email to ${customerSupportEmail}, and we will assist you in this matter`,
    close: 'Close',
  },
  notificationPreferences: {
    title: 'Notification preferences',
    description:
      'All notifications are visible in Min Portal. Settings you define here apply to email and sms notifications',
    incidents: {
      title: 'Incidents',
    },
    plannedWork: {
      title: 'Planned work',
    },
  },
  editNotifications: {
    channelDescription:
      'Channels where you want to receive these notifications',
    emailNotifications: 'You will get notifications on email',
    subscriptionSelection: 'Services you are notified of',
    subscriptionSelectionInfo:
      'If you choose a tag all the belonging subscriptions will be added to the list. If the tag gets updated with a new subscription, you will have to add this tag again to get notifications on the new subscriptions.',
    placeholder: 'Search for subscriptions',
    all: 'All',
    selected: 'Selected',
    email: 'Email',
    sms: 'SMS',
    btnSave: 'Save',
    subscriptionSearchHeader: 'Find services or tags you want notifcations for',
    globalNotificationsInformation:
      'You have global notifications. Contact your system admin for changes.',
    activeNotifications: 'Notifications activated',
    btnRemove: 'Remove',
    error: 'Something went wrong! Your changes were not saved',
  },
};

export default profile;
