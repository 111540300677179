import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import * as t from './__types/useAddIncidentComment';

const MUATATION_ADD_INCIDENT_COMMENT = gql`
  mutation useAddIncidentComment($commentInput: String, $incidentId: ID!) {
    addCommentToIncident(input: { id: $incidentId, comment: $commentInput }) {
      incident {
        id
        incidentStatus
        activities {
          ... on IncidentComment {
            userName
            userEmail
            userPhone
            userCustomerName
            userCustomerId
            comment
            type
            timestamp
          }
          ... on IncidentFieldChange {
            userName
            userEmail
            userPhone
            userCustomerName
            userCustomerId
            value
            type
            fieldName
            timestamp
          }
        }
      }
    }
  }
`;

interface AddCommentResult {
  addCommentToIncident: (incidentId: string, commentInput: string) => void;
  loading: boolean;
  error: boolean;
}

export const useAddIncidentComment = (): AddCommentResult => {
  const [mutation, { loading, error }] = useMutation<
    t.useAddIncidentComment,
    t.useAddIncidentCommentVariables
  >(MUATATION_ADD_INCIDENT_COMMENT);

  const addCommentToIncident = (incidentId: string, commentInput: string) =>
    mutation({
      variables: {
        incidentId,
        commentInput,
      },
    });

  return { addCommentToIncident, loading, error: !!error };
};
