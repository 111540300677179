import React from 'react';

import {
  Fullscreen,
  Attribute,
  Attributes,
  FullscreenHeader,
  ButtonCard,
  BodyCard,
  ConfirmationModalButton,
  Feature,
} from 'src/lib/ui';
import { Link } from 'react-router-dom';
import { PageHeader } from '../common/PageHeader/PageHeader';
import { StepFlowStorage } from 'src/lib/flow';
import { formatDate } from 'src/lib/utils/dateUtils';
import { t } from 'src/lib/i18n';
import OrderMobileSubscription from './OrderMobileSubscription/OrderMobileSubscription';
import OrderNumberSeries from './OrderNumberSeries/OrderNumberSeries';
import OrderReserveSimCards from './OrderReserveSimCards/OrderReserveSimCards';
// import OrderExtraSim from './OrderSim/OrderExtraSim/OrderExtraSim';
import { FullscreenBody } from 'src/lib/ui/FullscreenBody/FullscreenBody';
import { WithCustomerContext } from 'src/lib/global';
import { useRouter } from 'src/lib/utils/useRouter';
import OrderingModal from './layout/OrderingModal';
import * as uuid from 'uuid';

import { IpVpnOrderCard } from './poc-ip-vpn/IpVpnOrderCard';
import { OrderIpVpn } from './poc-ip-vpn/OrderIpVpn';

import './OrderingContent.scss';

type OrderType =
  | 'mobileSubscription'
  | 'm2mSubscription'
  | 'numberSeries'
  | 'ip-vpn';

function getTitleFromOrderType(orderType?: OrderType) {
  switch (orderType) {
    case 'm2mSubscription':
      return t.ordering.OrderMobileSubscription.pageTitleM2m;
    case 'mobileSubscription':
      return t.ordering.OrderMobileSubscription.pageTitleMobile;
    case 'ip-vpn':
      return t.ordering.OrderIpVpn.title;
  }
  return '';
}

const OrderingContent = () => {
  const router = useRouter<{ orderType: OrderType }>();
  const orderReference = uuid.v4();
  const renderOrderingContent = () => {
    const orderType = router.match.params.orderType;
    if (orderType === 'mobileSubscription') {
      return (
        <OrderMobileSubscription
          orderingType="mobile"
          orderReference={orderReference}
          {...router}
        />
      );
    }
    if (orderType === 'm2mSubscription') {
      return (
        <OrderMobileSubscription
          orderingType="m2m"
          orderReference={orderReference}
          {...router}
        />
      );
    }
    if (orderType === 'ip-vpn') {
      return <OrderIpVpn />;
    }
    return null;
  };

  const i18n = t.ordering.OrderingContent;
  const content = renderOrderingContent();

  return (
    <WithCustomerContext>
      {cContext => {
        return (
          <div>
            {content && (
              <Fullscreen>
                <FullscreenHeader
                  title={getTitleFromOrderType(router.match.params.orderType)}
                />
                <FullscreenBody style={{ position: 'relative' }}>
                  {content}
                </FullscreenBody>
              </Fullscreen>
            )}
            <PageHeader title={i18n.pageTitle} />

            <div className="container">
              <div className="Ordering-actions mt-4">
                <OrderReserveSimCards>
                  {orderReserveSimCardsProps => (
                    <OrderingModal
                      title={i18n.newReserveSimCardBtn.label}
                      btnHelpText={i18n.newReserveSimCardBtn.helpText}
                      icon="newSim"
                      btnStyle="card"
                      submit={orderReserveSimCardsProps.submit}
                      disableSubmitButton={
                        !orderReserveSimCardsProps.isFormValid
                      }
                      onClose={orderReserveSimCardsProps.resetForm}
                    >
                      {orderReserveSimCardsProps.render}
                    </OrderingModal>
                  )}
                </OrderReserveSimCards>

                <OrderNumberSeries>
                  {orderNumberSeriesProps => (
                    <OrderingModal
                      title={i18n.newNumberSeriesBtn.label}
                      btnHelpText={i18n.newNumberSeriesBtn.helpText}
                      icon="newTelephone"
                      btnStyle="card"
                      submit={orderNumberSeriesProps.submit}
                      disableSubmitButton={!orderNumberSeriesProps.isFormValid}
                      onClose={orderNumberSeriesProps.resetForm}
                    >
                      {orderNumberSeriesProps.render}
                    </OrderingModal>
                  )}
                </OrderNumberSeries>

                {cContext.permissions &&
                cContext.permissions.mobileOrders
                  .standardMobileSubscriptions ? (
                  <ButtonCard
                    to="/bestilling/mobileSubscription"
                    icon="newMobile"
                    helpText={i18n.newSubscriptionBtn.helpText}
                  >
                    {i18n.newSubscriptionBtn.label}
                  </ButtonCard>
                ) : null}
                {cContext.permissions &&
                cContext.permissions.mobileOrders.m2mMobileSubscriptions ? (
                  <ButtonCard
                    to="/bestilling/m2mSubscription"
                    icon="newMobile"
                    helpText={i18n.newM2mSubBtn.helpText}
                  >
                    {i18n.newM2mSubBtn.label}
                  </ButtonCard>
                ) : null}
                {/* <Link to="/bestilling/numberSeries">
       <Button color="secondary">Bestill nummerserie</Button>
     </Link> */}
              </div>

              {cContext.permissions &&
              (!cContext.permissions.mobileOrders.m2mMobileSubscriptions ||
                !cContext.permissions.mobileOrders
                  .standardMobileSubscriptions) ? (
                <BodyCard className="mt-4">{i18n.noOrdering}</BodyCard>
              ) : null}

              <StepFlowStorage>
                {flows => {
                  if (flows.list.length === 0) {
                    return null;
                  }
                  return (
                    <BodyCard className="mt-4" noGutter={true}>
                      <h2 className="p-3 m-0">{i18n.ordersHeader}</h2>
                      {flows.list.map(flow => (
                        <Link
                          to={`${flow.url}?continue=${flow.id}`}
                          key={flow.id}
                          className="Ordering-link"
                        >
                          <div className="Ordering-listItem">
                            <div>
                              <h3>{i18n.newOrder}</h3>
                              <div>
                                <Attributes inline={true}>
                                  <Attribute
                                    label={i18n.started}
                                    value={formatDate(
                                      flow.startedAt,
                                      'mediumWithTime'
                                    )}
                                  />
                                  <Attribute
                                    label={i18n.lastUpdated}
                                    value={formatDate(
                                      flow.lastTouchedAt,
                                      'mediumWithTime'
                                    )}
                                  />
                                </Attributes>
                              </div>
                            </div>
                            <div className="ml-auto">
                              <ConfirmationModalButton
                                color="white"
                                icon="trash"
                                hideLabel={true}
                                rounded={true}
                                transparent={true}
                                onConfirm={() => flows.remove(flow.id)}
                                headerText={i18n.deleteModal.header}
                                label={i18n.deleteModal.btn}
                                onClick={e => {
                                  e.preventDefault();
                                }}
                              >
                                {i18n.deleteModal.content}
                              </ConfirmationModalButton>
                            </div>
                          </div>
                        </Link>
                      ))}
                    </BodyCard>
                  );
                }}
              </StepFlowStorage>

              <Feature feature="enable-poc-ip-vpn-ordering">
                <div className="my-4">
                  <IpVpnOrderCard linkTo="/bestilling/ip-vpn" />
                </div>
              </Feature>
            </div>
          </div>
        );
      }}
    </WithCustomerContext>
  );
};

export default OrderingContent;
