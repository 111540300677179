import * as React from 'react';

interface Props {
  style?: React.CSSProperties;
}

export const FullscreenBody: React.FunctionComponent<Props> = props => {
  return (
    <div className="FullscreenBody h-100 container" style={props.style}>
      {props.children}
    </div>
  );
};
