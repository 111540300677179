import gql from 'graphql-tag';
import * as types from './__types/useCreateServicePlusAddress';
import { useMutation } from 'react-apollo';
import { SERVICE_PLUS_LOCATIONS_QUERY } from 'src/areas/main/servicePlus/queries/useServicePlusAddresses';

export const CREATE_LOCATION_QUERY = gql`
  mutation useCreateServicePlusAddress(
    $customerId: String!
    $address: String!
    $city: String!
    $postalCode: String!
    $createdBy: Int!
    $organisationNumber: String!
  ) {
    createServicePlusAddress(
      input: {
        customerId: $customerId
        address: $address
        city: $city
        postalCode: $postalCode
        createdBy: $createdBy
        organisationNumber: $organisationNumber
      }
    ) {
      servicePlusAddress {
        id
        address
        postalCode
        city
        createdAt
        createdBy
        customerId
        organisationNumber
        creatorName
        creatorEmail
        creatorPhone
      }
      error {
        code
        message
      }
    }
  }
`;

export const useCreateLocationMutation = () => {
  const [mutation, { data, loading, error }] = useMutation<
    types.useCreateServicePlusAddress,
    types.useCreateServicePlusAddressVariables
  >(CREATE_LOCATION_QUERY);
  const useCreateLocation = (
    customerId,
    address,
    city,
    postalCode,
    createdBy,
    organisationNumber
  ) => {
    return mutation({
      variables: {
        customerId: customerId,
        address: address,
        city: city,
        postalCode: postalCode,
        createdBy: createdBy,
        organisationNumber: organisationNumber,
      },
      refetchQueries: [
        {
          query: SERVICE_PLUS_LOCATIONS_QUERY,
          variables: {
            customerId: customerId,
          },
        },
      ],
    });
  };
  return {
    useCreateServicePlusLocation: useCreateLocation,
    createLocationData: data,
    createLocationLoading: loading,
    createLocationError: error,
  };
};
