import { produce } from 'immer';
import { useDocumentArchiveFilesAndFolders } from './../queries/__types/useDocumentArchiveFilesAndFolders';
import { useOrderFilesAndFolders } from './../../orders/queries/__types/useOrderFilesAndFolders';
import { WithSubscriptionFilesAndFolders } from './../../subscriptions/queries/__types/WithSubscriptionFilesAndFolders';
import { MutationUpdaterFn } from 'apollo-client';
import { File, Folder } from 'src/lib/types';
import { DOCUMENT_ARCHIVE_QUERY } from '../queries/useDocumentArchiveFilesAndFolders';
import { SUBSCRIPTION_DOCUMENTS_QUERY } from 'src/areas/main/subscriptions/queries/WithSubscriptionFilesAndFolders';
import { useDocumentsContext } from '../DocumentsContext';
import { ORDER_DOCUMENTS_QUERY } from 'src/areas/main/orders/queries/useOrderFilesAndFolders';

interface FilesAndFolders {
  files: File[];
  folders: Folder[];
}

type CacheUpdater<T> = (
  updateFunc: (filesAndFolders: FilesAndFolders, data: T) => FilesAndFolders
) => MutationUpdaterFn<T>;

function useStructureCacheUpdater<Data>(): CacheUpdater<Data> {
  const { orderId, subscriptionId, customerId } = useDocumentsContext();

  if (subscriptionId) {
    return updater => {
      const ret: MutationUpdaterFn<Data> = (cache, { data }) => {
        const cachedStructure = cache.readQuery<
          WithSubscriptionFilesAndFolders
        >({
          query: SUBSCRIPTION_DOCUMENTS_QUERY,
          variables: {
            customerId: customerId,
            subscriptionId: subscriptionId,
          },
        });
        if (data && cachedStructure) {
          const { files, folders } = updater(
            {
              files:
                cachedStructure.customer?.subscriptions?.[0]?.documents
                  ?.allFiles ?? [],
              folders:
                cachedStructure.customer?.subscriptions?.[0]?.documents
                  ?.allFolders ?? [],
            },
            data
          );

          const newData = produce<WithSubscriptionFilesAndFolders>(
            cachedStructure,
            draft => {
              if (draft.customer?.subscriptions?.[0]) {
                draft.customer.subscriptions[0].documents = {
                  __typename: 'DocumentArchive',
                  allFiles: files,
                  allFolders: folders,
                };
              }
            }
          );
          cache.writeQuery<WithSubscriptionFilesAndFolders>({
            query: SUBSCRIPTION_DOCUMENTS_QUERY,
            data: newData,
          });
        }
      };
      return ret;
    };
  } else if (orderId) {
    return updater => {
      const ret: MutationUpdaterFn<Data> = (cache, { data }) => {
        const cachedStructure = cache.readQuery<useOrderFilesAndFolders>({
          query: ORDER_DOCUMENTS_QUERY,
          variables: {
            input: { filters: [{ filter: 'id', value: [orderId] }] },
          },
        });
        if (data && cachedStructure) {
          const { files, folders } = updater(
            {
              files:
                cachedStructure.worklogSearch?.results?.[0].documents
                  ?.allFiles ?? [],
              folders:
                cachedStructure.worklogSearch?.results?.[0].documents
                  ?.allFolders ?? [],
            },
            data
          );

          const newData = produce<useOrderFilesAndFolders>(
            cachedStructure,
            draft => {
              if (draft.worklogSearch?.results?.[0]) {
                draft.worklogSearch.results[0].documents = {
                  __typename: 'DocumentArchive',
                  allFiles: files,
                  allFolders: folders,
                };
              }
            }
          );
          cache.writeQuery<useOrderFilesAndFolders>({
            query: ORDER_DOCUMENTS_QUERY,
            data: newData,
          });
        }
      };
      return ret;
    };
  } else {
    return updater => {
      const ret: MutationUpdaterFn<Data> = (cache, { data }) => {
        const cachedStructure = cache.readQuery<
          useDocumentArchiveFilesAndFolders
        >({
          query: DOCUMENT_ARCHIVE_QUERY,
          variables: { customerId: customerId },
        });
        if (data && cachedStructure) {
          const { files, folders } = updater(
            {
              files: cachedStructure.customer?.documentArchive?.allFiles ?? [],
              folders:
                cachedStructure.customer?.documentArchive?.allFolders ?? [],
            },
            data
          );
          const newData = produce<useDocumentArchiveFilesAndFolders>(
            cachedStructure,
            draft => {
              if (draft.customer) {
                draft.customer.documentArchive = {
                  __typename: 'DocumentArchive',
                  allFiles: files,
                  allFolders: folders,
                };
              }
            }
          );
          cache.writeQuery<useDocumentArchiveFilesAndFolders>({
            query: DOCUMENT_ARCHIVE_QUERY,
            data: newData,
          });
        }
      };
      return ret;
    };
  }
}

export default useStructureCacheUpdater;
