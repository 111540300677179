import * as t from './__types/FolderFragment';
import gql from 'graphql-tag';
export type Folder = t.FolderFragment;

export const FolderFragment = gql`
  fragment FolderFragment on Folder {
    id
    parentId
    name
    created
    createdBy
    modified
    modifiedBy
    protected
  }
`;
