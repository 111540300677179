import React, { useState, useEffect, useRef } from 'react';
import { useAlterFolder } from '../mutations/useAlterFolder';
import Form from 'reactstrap/lib/Form';
import { TextField } from 'src/lib/ui';
import { getUndoRenameFolderAction } from '../undo/UndoActions';
import { t } from 'src/lib/i18n';
import { Folder } from 'src/lib/types';
import { FormSubmitBar } from 'src/lib/ui';
import undoPopup from 'src/areas/main/documents/undo/UndoPopup';
import { ModalBody, ModalFooter } from 'reactstrap';

interface Props {
  cancel: () => void;
  folder: Folder;
}

const RenameFolderForm: React.FC<Props> = props => {
  const [name, setName] = useState<string>(props.folder.name);
  const [inputError, setError] = useState<string | undefined>(undefined);
  const [inputIsValid, setInputIsValid] = useState<boolean>(true);
  const textInput = useRef<HTMLInputElement>(null);
  const context = useAlterFolder();

  useEffect(() => {
    if (textInput.current) {
      window.setInterval(
        () => textInput.current && textInput.current.focus(),
        25
      );
    }
  }, []);

  const handleNameInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setName(e.target.value);
    if (e.target.value.length > 50) {
      setError(t.documents.validations.nameTooLong(50));
      setInputIsValid(false);
    } else if (e.target.value.length <= 0) {
      setError(t.documents.validations.nameTooShort);
      setInputIsValid(false);
    } else {
      setError(undefined);
      setInputIsValid(true);
    }
  };

  const handleSubmit = e => {
    e.preventDefault();
    const { folder, cancel } = props;
    if (inputIsValid) {
      context
        .alterFolder(
          folder.id,
          folder.parentId ? folder.parentId : undefined,
          name
        )
        .then(() =>
          undoPopup(
            getUndoRenameFolderAction(folder, name, context.alterFolder)
          )
        );
      cancel();
    }
  };

  return (
    <Form onSubmit={e => handleSubmit(e)}>
      <ModalBody>
        <TextField
          id="new-folder-input"
          label={t.documents.RenameFolderForm.newNameLabel}
          value={name}
          onChange={handleNameInputChange}
          innerRef={textInput}
          error={inputError ? inputError : undefined}
        />
      </ModalBody>

      <ModalFooter>
        <FormSubmitBar
          cancel={props.cancel}
          loading={context.loading}
          submitLabel={t.documents.RenameFolderForm.submit}
          disabled={!inputIsValid}
          error={!!context.error}
          errorMsg={t.documents.genericError}
        />
      </ModalFooter>
    </Form>
  );
};

export default RenameFolderForm;
