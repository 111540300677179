import { WrappedSearchResult } from './../../search/types/index';
import { unwrapWrappedSearchResults } from './../../search/lib/unwrapWrappedSearchResults';
import gql from 'graphql-tag';
import { useMemo } from 'react';
import { useLazyQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { SearchDomain, SearchFilter } from 'src/__types/graphql-global-types';

export const SEARCH_SUBSCRIPTION_ID = gql`
  query useLazySearchSubscriptions($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        results {
          __typename
          ... on SubscriptionSearchResult {
            subscription {
              id
            }
          }
        }
      }
    }
  }
`;

interface SearchInput {
  query?: string;
  size?: number;
  filter?: SearchFilter[];
}
export const useLazySearchSubscriptions = () => {
  const [get, res] = useLazyQuery(SEARCH_SUBSCRIPTION_ID);
  const customer = useCustomer();
  const defaultSearchSize = 20;
  const getSearchResult = (search: SearchInput) => {
    return get({
      variables: {
        customerId: customer.id,
        search: {
          query: search.query,
          types: [SearchDomain.subscription],
          filters: search.filter,
          size: search.size ?? defaultSearchSize,
        },
      },
    });
  };

  const records = useMemo(() => {
    const unmappedRecords = ((res.data?.customer?.search?.results ??
      []) as unknown) as WrappedSearchResult[];
    return unwrapWrappedSearchResults(unmappedRecords);
  }, [res]);

  return { getSearchResult, res: { records, loading: res.loading } };
};
