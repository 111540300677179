import * as React from 'react';
import { Flow, Step, StepShape, FormikSelectField } from 'src/lib/flow';
import { colors, Attribute } from 'src/lib/ui';
import * as yup from 'yup';
import { t } from 'src/lib/i18n';
import { useOrderContext } from './context';

export interface Step5Values {
  organisationId?: string;
  organisationLabel?: string;
}

function Form({ flow }: { flow: Flow<Step5Values> }) {
  const ctx = useOrderContext();

  const organisations = ctx.organisations
    ? ctx.organisations
        .map(org => ({
          value: org.id,
          label: `${org.name} - ${org.id}`,
        }))
        .sort((a, b) => (a.label < b.label ? -1 : +(a.label > b.label)))
    : // Naive sort, will not correctly sort æøå. https://stackoverflow.com/questions/1179366/is-there-a-javascript-strcmp
      [];

  return (
    <>
      <p className="small mt-0 mb-3" style={{ color: colors.greyDarkText }}>
        {t.ordering.OrderIpVpn.Step5.formSummary}
      </p>

      <div className="row mb-3">
        <FormikSelectField
          id="organisationId"
          required={true}
          label={t.ordering.OrderIpVpn.Step5.organisationIdLabel}
          options={organisations}
          placeholder={t.ordering.OrderIpVpn.Step5.organisationIdLabel}
          className="col-md-8 col-lg-6"
          onChange={val => {
            flow.setFieldValue('organisationLabel', val.label);
          }}
          fastField={true}
          virtualized={true}
        />
      </div>
    </>
  );
}

const Step5: Step<Step5Values> = (): StepShape<Step5Values> => {
  return {
    title: t.ordering.OrderIpVpn.Step5.title,

    validationSchema: yup.object({
      organisationId: yup.string().required(t.ordering.required),
    }),

    renderSummary: ({ values }) => {
      return (
        <>
          <Attribute
            label={t.ordering.OrderIpVpn.Step5.organisationIdSummary}
            value={values.organisationLabel}
          />
        </>
      );
    },
    renderForm: Form,
  };
};

export default Step5;
