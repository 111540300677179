import React, { useState, useEffect } from 'react';
import { VoicemailMobileSettings } from 'src/lib/types';
import { useUpdateSubscriptionVoicemailSetting } from 'src/areas/mobile/settings/mutations/useUpdateSubscriptionVoicemailSetting';
import Modal from 'reactstrap/lib/Modal';
import { ModalHeader, ModalFooter } from 'reactstrap';
import ModalBody from 'reactstrap/lib/ModalBody';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { t } from 'src/lib/i18n';
import { colors, FormSubmitBar } from 'src/lib/ui';
import { Toggle } from '@telia/styleguide/business';

interface Props {
  voicemailSettings: VoicemailMobileSettings;
  isOpen: boolean;
  toggle: () => void;
}

const VisualVoiceMailFormModal: React.FC<Props> = ({
  voicemailSettings,
  ...props
}) => {
  const [visualVoicemail, setVisualVoicemail] = useState(
    voicemailSettings.visualVoicemail
  );
  const {
    updateSettings,
    data,
    loading,
    error,
  } = useUpdateSubscriptionVoicemailSetting();
  const i18n = t.mobile.settings.visualVoicemail;

  useEffect(() => {
    if (data && !error) {
      props.toggle();
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  const submit = () => {
    updateSettings({ ...voicemailSettings, visualVoicemail });
  };
  return (
    <Modal isOpen={props.isOpen} size="lg" toggle={props.toggle}>
      <ModalHeader>{i18n.visualVoicemailForiPhone}</ModalHeader>
      <ModalBody>
        <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
          {i18n.visualVoicemailInfo}
        </small>

        <SettingsListItem
          className="border-0 px-0"
          title={i18n.VisualVoicemail}
          toggle={
            <Toggle
              checked={visualVoicemail}
              onChange={() => setVisualVoicemail(!visualVoicemail)}
            />
          }
        />
      </ModalBody>
      <ModalFooter>
        <FormSubmitBar
          submit={submit}
          cancel={props.toggle}
          loading={loading}
          errorMsg={t.mobile.settings.modalFields.unableToSave}
          error={error}
        />
      </ModalFooter>
    </Modal>
  );
};

export default VisualVoiceMailFormModal;
