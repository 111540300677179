import React from 'react';
import { colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

export function OrderIpVpnSummary() {
  return (
    <div
      className="OrderIpVpnSummary"
      style={{
        paddingLeft: '6rem', // To align with steps above -- padding + stepcircle etc.
        paddingTop: '2rem',
        marginRight: '4rem',
      }}
    >
      <div className="preamble">
        {t.ordering.OrderIpVpn.OrderIpVpnSummary.title}
      </div>

      <div>{t.ordering.OrderIpVpn.OrderIpVpnSummary.summary}</div>

      <div
        style={{
          marginTop: '2.5rem',
          backgroundColor: colors.grey,
          borderRadius: '4px',
          padding: '22px 30px',
        }}
        className="mb-3"
      >
        <div
          style={{
            fontSize: '16px',
            fontWeight: 'bold',
            lineHeight: '1.25',
          }}
        >
          {t.ordering.OrderIpVpn.OrderIpVpnSummary.whatsNext}
        </div>

        <div className="small mt-2 mb-3">
          {t.ordering.OrderIpVpn.OrderIpVpnSummary.whatsNextSummary}
        </div>
      </div>
    </div>
  );
}
