import React, { useState, useRef, useEffect } from 'react';
import Dropzone from 'react-dropzone';
import { colors } from 'src/lib/ui';
import './FileUpload.scss';
import folder from 'src/images/illustrations/folder.svg';
import { t } from 'src/lib/i18n';
import { throttle } from 'lodash';
import { useFileUpload } from 'src/areas/main/subscriptions/documents/useFileUpload';

const FileUpload = () => {
  const [offsetTop, setOffsetTop] = useState<number>(0);
  const dropzoneRef = useRef<HTMLDivElement>(null);
  const {
    handleSelectedFiles,
    // files,
    loading,
    // uploadError,
    // progress,
  } = useFileUpload();

  useEffect(() => {
    const resetOffsetTop = throttle(() => {
      if (dropzoneRef.current) {
        setOffsetTop(dropzoneRef.current.offsetTop);
      }
    }, 500);

    window.removeEventListener('resize', resetOffsetTop);
    return () => {
      window.removeEventListener('resize', resetOffsetTop);
    };
  }, []);

  useEffect(() => {
    if (dropzoneRef.current) {
      setOffsetTop(dropzoneRef.current.offsetTop);
    }
  }, [dropzoneRef]);

  return (
    <div className="FileUpload">
      <div className="FileUpload-dropzone" ref={dropzoneRef}>
        <Dropzone
          style={{ height: `calc(100vh - ${offsetTop}px - 1rem)` }}
          onDrop={handleSelectedFiles}
          disabled={loading}
          disableClick={loading}
        >
          <div className="FileUpload-dropzone--empty">
            <img src={folder} alt="" />
            <div
              className="mediumBold pt-4"
              style={{ color: colors.corePurple }}
            >
              {t.documents.uploadModal.placeholder.main}
            </div>
            <div
              style={{
                height: '0.25rem',
                width: '8rem',
                margin: '1rem auto',
                backgroundColor: colors.grey,
              }}
            />
            <div>{t.documents.uploadModal.placeholder.secondary}</div>
          </div>
        </Dropzone>
      </div>
    </div>
  );
};

export default FileUpload;
