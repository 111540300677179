import { PermissionLevel } from 'src/areas/main/contacts/lib/createContact/types';
import { RoleVariableWithoutTagId } from './../createContact/types';

import { Contact, User, Tag } from 'src/lib/types';
import { Item } from 'src/lib/ui';
import { Roles, Role, mapRoleNames } from '../createContact/types';
import { RoleTemplate } from 'src/__types/graphql-global-types';

export { mapResponseToRoles } from './mapResponseToRoles';

export const getUserOnContact = (contact: Contact): boolean => {
  return !!contact.user;
};

export const getRoles = (contact: Contact) => {
  return contact.user
    ? contact.user.customerAccess
      ? contact.user.customerAccess.roles
      : []
    : undefined;
};

export const getRoleList = (roles?: Roles): Role[] => {
  if (roles) {
    if (roles.adminRole) {
      return [{ role: 'CustomerAdministrator' }] as Role[];
    }

    const get = (key: keyof Omit<Roles, 'adminRole'>) =>
      roles?.[key]?.level === 'partial'
        ? [
            {
              role: mapRoleNames[key],
              tags: roles?.[key]?.tags,
              organisations: roles?.[key]?.organisations,
            },
          ]
        : roles?.[key]?.level === 'full'
        ? [
            {
              role: mapRoleNames[key],
            },
          ]
        : [];

    const financeRole: Role[] = get('financialRole');
    const orderRole: Role[] = get('orderRole');
    const sysAdminRole: Role[] = get('sysAdminRole');

    return [...financeRole, ...orderRole, ...sysAdminRole];
  }
  return [];
};

export const mapRolesToInput = (
  roles: Role[]
): Array<RoleVariableWithoutTagId> => {
  const globalRoles: Array<RoleVariableWithoutTagId> = roles.flatMap(role =>
    !role.organisations && !role.tags ? [{ role: role.role }] : []
  );
  const orgRoles: Array<RoleVariableWithoutTagId> = roles.flatMap(role =>
    !role.organisations
      ? []
      : role.organisations.map(org => ({
          role: role.role,
          organisationId: org,
        }))
  );
  const tagRoles: Array<RoleVariableWithoutTagId> = roles.flatMap(role =>
    !role.tags
      ? []
      : role.tags.map(tag => ({
          role: role.role,
          tag: tag,
        }))
  );

  return orgRoles.concat(tagRoles, globalRoles);
};

const roleIsValid = (role?: Role): boolean =>
  !!role &&
  !(
    role &&
    role.organisations &&
    role.organisations.length === 0 &&
    role.tags &&
    role.tags.length === 0
  );

const partialNotSet = (role?: Role): boolean =>
  !!(
    role &&
    role.level === 'partial' &&
    !role.organisations?.length &&
    !role.tags?.length
  );

export const noPermissions = (roles: Roles): boolean =>
  !roles.adminRole &&
  !roles.financialRole &&
  !roles.orderRole &&
  !roles.adminRole;

const hasValidRole = (roles: Roles) =>
  roles.adminRole ||
  roleIsValid(roles.financialRole) ||
  roleIsValid(roles.orderRole) ||
  roleIsValid(roles.sysAdminRole);

const hasInvalidRole = (roles: Roles) =>
  partialNotSet(roles.financialRole) ||
  partialNotSet(roles.orderRole) ||
  partialNotSet(roles.sysAdminRole);

export const validRoles = (roles?: Roles) =>
  roles?.adminRole ||
  (roles ? hasValidRole(roles) && !hasInvalidRole(roles) : false);

export const getContactDisplayName = (
  firstName?: string | null,
  lastName?: string | null
) =>
  firstName
    ? firstName + (lastName ? ' ' + lastName : '')
    : lastName
    ? lastName
    : '';

export const userIsAdmin = (user: User) =>
  user.customerAccess &&
  user.customerAccess.roles &&
  !!user.customerAccess.roles.filter(
    role => role.role === 'CustomerAdministrator'
  ).length;

export const userIsCustomerOrSystemAdmin = (customerAccess: RoleTemplate[]) =>
  customerAccess &&
  customerAccess.length > 0 &&
  !!customerAccess.filter(
    role => role === 'SystemAdministrator' || role === 'CustomerAdministrator'
  ).length;

export const contactIsAdmin = (roles: Roles) => !!roles.adminRole;

export const getLevelSetForRole = (role: Role | undefined): PermissionLevel => {
  if (role && !role.organisations && !role.tags) {
    return 'full';
  }
  if (role && (role.organisations || role.tags)) {
    return 'partial';
  }
  return 'none';
};

// Not the same as: import { mapTagsToItem } from 'src/areas/main/subscriptions/Pages/Components/SubscriptionTagsPicker/SubscriptionTagsPicker';
// This one only uses tag.tag and ignores tag.id
export const mapTagsToItem = (tags?: Tag[]) => {
  if (!tags) {
    return [];
  }
  return tags.map(
    tag =>
      ({
        id: tag.tag,
        label: tag.tag,
      } as Item)
  );
};
