import React from 'react';
import { Contact } from 'src/lib/types';
import {
  Card,
  colors,
  Attributes,
  Attribute,
  RequireRole,
  urlFor,
} from 'src/lib/ui';
import './ContactCard.scss';
import { getContactDisplayName } from 'src/areas/main/incidents/utils/incidentUtils';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import { useRouter } from 'src/lib/utils/useRouter';
import { EditContactModal } from '../editContact/EditContactModal';
import { userIsAdmin } from '../editContact/utils';
import { useFeature } from 'src/lib/utils/useFeature';

const ContactCard: React.FC<{
  contact: Contact;
  hideOptions?: boolean;
  clickable?: boolean;
}> = ({ contact, hideOptions, clickable }) => {
  const i18n = t.contacts;
  const router = useRouter();
  const isFederatedUser = useFeature('scim-federated-user');
  return (
    <Card
      className="ContactCard d-flex flex-column"
      border={true}
      shadow={clickable ? '1' : '0'}
      padding={true}
      onClick={
        clickable
          ? () => router.history.push(urlFor({ contact: contact.id }))
          : undefined
      }
    >
      <div className="d-flex mb-2">
        <div className="ContactCard-illustration">
          {contact.user ? (
            userIsAdmin(contact.user) ? (
              <div className="d-flex flex-column align-items-center mb-4">
                <Icon icon="user-admin" />
                <small style={{ color: colors.greyDarkText }}>
                  {i18n.admin.toUpperCase()}
                </small>
              </div>
            ) : (
              <div className="d-flex flex-column align-items-center mb-4">
                <Icon icon="user" />
                <small
                  className="d-flex flex-column align-items-center"
                  style={{ color: colors.greyDarkText }}
                >
                  <div>{i18n.contact.toUpperCase()}</div>
                  <div>{i18n.withAccess}</div>
                </small>
              </div>
            )
          ) : (
            <div className="d-flex flex-column align-items-center mb-4">
              <Icon icon="contact" />
              <small style={{ color: colors.greyDarkText }}>
                {i18n.contact.toUpperCase()}
              </small>
            </div>
          )}
        </div>

        <div className="ml-4 flex-fill">
          <div className="mb-3">
            <div
              className="mediumBold mb-2"
              style={{ color: colors.corePurple }}
            >
              {getContactDisplayName(contact)}
            </div>
            <div className="pb-3" style={{ color: colors.greyDarkText }}>
              {contact.description}
            </div>
          </div>
          <div className="d-flex">
            <Attributes>
              {contact.email && (
                <Attribute
                  displayBlock={true}
                  label={i18n.formLabels.email}
                  value={contact.email}
                />
              )}
              {contact.employeeNumber && (
                <Attribute
                  displayBlock={true}
                  label={i18n.formLabels.employeeNumber}
                  value={contact.employeeNumber}
                />
              )}
            </Attributes>
            <Attributes>
              {contact.mobilePhone && (
                <Attribute
                  displayBlock={true}
                  label={i18n.formLabels.mobilePhone}
                  value={contact.mobilePhone}
                />
              )}
              {contact.secondaryPhone && (
                <Attribute
                  displayBlock={true}
                  label={i18n.formLabels.secondaryPhone}
                  value={contact.secondaryPhone}
                />
              )}
            </Attributes>
          </div>
        </div>
      </div>
      {!hideOptions ? (
        <RequireRole
          role="CustomerAdministrator"
          orWhenCustomerSearchIsAllowed={false}
        >
          {!isFederatedUser && <EditContactModal contact={contact} />}
        </RequireRole>
      ) : null}
    </Card>
  );
};

export default ContactCard;
