import React, { useEffect, useState } from 'react';
import { useAppContext, AppContext } from 'src/lib/global';
import { Fullscreen, FullscreenHeader, FullscreenBody } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

import cannySdk from './cannySdk';

/**
 * The name of the feature flag determining whether to enable the Canny widget.
 */
const featureFlag = 'canny-widget';

function getBoardTokenFromAppContext(app: AppContext): string | undefined {
  /**
   * Check global (not customer-specific) gateway features
   */
  if (app.access.featureFlags) {
    const ff = app.access.featureFlags.find(f => f.flag === featureFlag);
    if (ff && ff.value) {
      return ff.value;
    }
  }

  /**
   * Check customer-specific gateway features
   */
  if (app.customerFeatureFlags) {
    const ff = app.customerFeatureFlags.find(f => f.flag === featureFlag);
    if (ff && ff.value) {
      return ff.value;
    }
  }

  return undefined;
}

function firstNameOnly(str: string | null) {
  if (!str) {
    return null;
  }
  const words = str.split(' ');
  return words[0];
}

export default function CannyApp() {
  const [boardToken, setBoardToken] = useState<undefined | string>(undefined);
  const app = useAppContext();
  const i18n = t.dashboard.cannyApp;

  useEffect(() => {
    cannySdk.init();
  }, []);

  useEffect(() => {
    if (app.cannyToken && boardToken) {
      (window as any).Canny('identify', {
        // Static application ID for Canny -- global and public (not sensitive)
        appID: '5d81f4adf9687c2ef7d2a97d',
        user: {
          id: app.access.id,
          name: firstNameOnly(app.access.name),
          email: app.access.email,
          companies: [
            {
              id: app.activeCustomerId,
              name: app.activeCustomerName,
            },
          ],
          customFields: {
            mobilePhone: app.access.mobilePhone,
          },
        },
      });

      (window as any).Canny('render', {
        boardToken: boardToken,
        basePath: '/feedback',
        ssoToken: app.cannyToken,
      });
    }
  }, [app.cannyToken, boardToken, app]);

  /**
   * Updated board token from app context
   */
  useEffect(() => {
    setBoardToken(getBoardTokenFromAppContext(app));
  }, [app]);

  return (
    <Fullscreen>
      <FullscreenHeader title={i18n.header} />
      <FullscreenBody>
        <div data-canny={true} />
      </FullscreenBody>
    </Fullscreen>
  );
}
