import * as React from 'react';
import {
  SearchMenuResultItem,
  SearchMenuResultProps,
} from './SearchMenuResultItem';
import { format } from 'date-fns';
import { File } from 'src/lib/types';

export const SearchMenuFileResult: React.FC<SearchMenuResultProps<
  File
>> = props => {
  const { id, name, uploaded } = props.entry;
  const displayDate = uploaded ? format(uploaded, 'DD.MMM.YYYY') : '';
  return (
    <SearchMenuResultItem
      {...props}
      icon="document"
      title={name ? name : id}
      summary={displayDate}
    />
  );
};
