import React, { useContext } from 'react';
import * as types from './__types/PocIpVpnContext';
import { Loading } from 'src/lib/ui';
import { ApolloError } from 'apollo-client';
import { useQuery } from 'react-apollo';
import gql from 'graphql-tag';
import { useCustomer } from 'src/lib/global';
import { WithSearch, SearchProvider } from 'src/areas/main/search';
import { SearchDomain } from 'src/__types/graphql-global-types';

interface OrderContext {
  loading?: boolean;
  error?: ApolloError | null;
  organisations?: Array<{
    id: string;
    name: string;
  }>;
  contactSearch: SearchProvider;
}

const orderContext = React.createContext<OrderContext>({} as OrderContext);

export const useOrderContext = () => useContext(orderContext);

const query = gql`
  query PocIpVpnContext($customerId: ID!) {
    customer(id: $customerId) {
      id
      costPeriods {
        invoicePeriods
        usagePeriods
      }
      organisations {
        id
        name
        parentId
        children
        numberOfDescendants
      }
    }
  }
`;

const useContextQuery: () => Omit<OrderContext, 'contactSearch'> = () => {
  const customerContext = useCustomer();
  const { data, loading, error } = useQuery<
    types.PocIpVpnContext,
    types.PocIpVpnContextVariables
  >(query, {
    variables: {
      customerId: customerContext.id,
    },
  });
  return {
    error,
    loading,
    organisations: data?.customer?.organisations.map(org => ({
      id: org.id,
      name: org.name,
    })),
  };
};

export function OrderContextProvider(props: {
  children?: (ctx: OrderContext) => React.ReactNode;
}) {
  const oc = useContextQuery();
  if (oc.loading) {
    return <Loading />;
  }
  return (
    <WithSearch
      initial={{
        types: [SearchDomain.contact],
      }}
    >
      {contactSearch => {
        const ctx: OrderContext = {
          ...oc,
          contactSearch,
        };

        return (
          <orderContext.Provider value={ctx}>
            {props.children ? props.children(ctx) : null}
          </orderContext.Provider>
        );
      }}
    </WithSearch>
  );
}
