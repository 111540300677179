import * as React from 'react';
import { formatNordic } from 'src/lib/utils';
import { colors } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { Icon, IconDefinition } from '@telia/styleguide';
import { convertMegaByteToGigaByte } from 'src/areas/main/statistics/utils/convertUnit';
interface Props {
  usage?: {
    nationalDataVolume: number | null;
    nationalCallVolume: number | null;
    smsVolume: number | null;
    totalUsageAmount: number | null;
  } | null;
}

export const OverviewStep: React.SFC<Props> = ({ usage }) => {
  const i18n = t.mobile.overview;
  if (!usage) {
    return null;
  }
  return (
    <div
      className="d-flex justify-content-center flex-wrap mb-5"
      style={{ color: colors.greyDarkText }}
    >
      <OverviewStepItem
        icon="internet"
        color={colors.corePurple}
        title={i18n.dataUsed}
        usage={
          usage.nationalDataVolume !== null
            ? `${formatNordic(
                convertMegaByteToGigaByte(usage.nationalDataVolume),
                2
              )} GB`
            : ''
        }
      />

      <OverviewStepItem
        icon="chat"
        color={colors.blueDark}
        title={i18n.smsSent}
        usage={`${usage.smsVolume} stk`}
      />

      <OverviewStepItem
        icon="phone"
        color={colors.warning}
        title={i18n.minutesCalled}
        usage={
          usage.nationalCallVolume !== null
            ? `${formatNordic(usage.nationalCallVolume, 1)} min`
            : ''
        }
      />
      <OverviewStepItem
        icon="money"
        color={colors.success}
        title={i18n.cost}
        usage={
          usage.totalUsageAmount !== null
            ? ` Kr ${formatNordic(usage.totalUsageAmount, 2)}`
            : ''
        }
      />
    </div>
  );
};

interface OverviewStepItemProps {
  icon: IconDefinition;
  color: string;
  title: string;
  usage: string;
}
const OverviewStepItem: React.FC<OverviewStepItemProps> = props => {
  return (
    <div className="flex-fill d-flex flex-wrap justify-content-center align-items-center pr-4">
      <Icon
        className="pr-4 d-none d-lg-block h2"
        icon={props.icon}
        style={{
          color: props.color,
        }}
      />
      <div>
        <div style={{ marginBottom: '-0.25rem' }}>{props.title}</div>
        <div style={{ fontSize: '1.5rem', color: props.color }}>
          {props.usage}
        </div>
      </div>
    </div>
  );
};

export default OverviewStep;
