import * as React from 'react';

import logo from '../../../images/telia-logo.svg';

import './FullScreenError.scss';
import { t } from 'src/lib/i18n';
import { useAuthContext } from 'src/modules/auth/AuthContext';
import { Link } from 'react-router-dom';

interface Props {
  children?: undefined;

  /**
   * The technical reason why the error message is shown.
   * This message will not be shown to the user.
   */
  technicalReason?: string;
}

export const FullScreenError: React.FC<Props> = props => {
  if (props.technicalReason) {
    console.log('Error:', props.technicalReason);
  }
  const auth = useAuthContext();
  return (
    <div className="FullScreenError">
      <a href="/">
        <img src={logo} alt="" className="FullScreenError-logo" />
      </a>

      <div className="FullScreenError-frame">
        <div className="FullScreenError-body">
          <h1>{t.ui.FullscreenError.heading}</h1>

          <p className="FullScreenError-message">
            {t.ui.FullscreenError.mainMessage}
          </p>

          <p>
            {t.ui.FullscreenError.subMessage}{' '}
            <a href="/">{t.ui.FullscreenError.linkMessage}</a>
          </p>
          <br />
          {props.technicalReason && (
            <p className="FullScreenError-error">
              {t.ui.FullscreenError.errorCode} {props.technicalReason}
            </p>
          )}
          {auth.isAuthenticated && (
            <p className="mt-5">
              <Link to="/" onClick={auth.requestSignOut}>
                {t.layout.ProfileDropdown.logOut}
              </Link>
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
