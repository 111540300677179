import { SavedUserPreferences } from './../userPreferences/SavedUserPreferencesContext';
import { push, Category } from './Analytics';

export type UserPreferenceActions =
  | 'set_columns'
  | 'set_rows'
  | 'set_view'
  | 'set_subscription_group'
  | 'set_subscription_congfig_tab'
  | 'set_subscription_congfig_rows'
  | 'incident_details_open';

type Data = {
  category: Category;
  action: UserPreferenceActions;
  label?: string;
};
export function trackUserPreferences(
  userPref: keyof SavedUserPreferences,
  label: string
) {
  const metaData = getDataLayerDataFromUserPref(userPref, label);
  if (!metaData) {
    return;
  }

  push({
    event: 'custom_event',
    ...metaData,
  });
}
const getDataLayerDataFromUserPref = (
  userPref: keyof SavedUserPreferences,
  label?: string
): Data | null => {
  const viewBase: Omit<Data, 'category'> = {
    action: 'set_view',
    label,
  };
  const pageSizeBase: Omit<Data, 'category'> = {
    action: 'set_rows',
    label,
  };

  const columnsBase: Omit<Data, 'category'> = {
    action: 'set_columns',
    label,
  };

  const category = getCategory(userPref);
  if (!category) {
    return null;
  }

  switch (userPref) {
    /**
     * Views list/table
     */
    case 'dataSubscriptionView':
    case 'mobileSubscriptionView':
    case 'teleSubscriptionView':
    case 'tvSubscriptionView':
    case 'contactsView':
    case 'incidentsView':
    case 'ordersView':
      return {
        ...viewBase,
        category,
      };

    /**
     * Rows
     */
    case 'dataSubscriptionPageSize':
    case 'mobileSubscriptionPageSize':
    case 'teleSubscriptionPageSize':
    case 'tvSubscriptionPageSize':
    case 'allSubscriptionPageSize':
    case 'contactsPageSize':
    case 'ordersPageSize':
    case 'incidentsPageSize':
      return { category, ...pageSizeBase };

    /**
     * Columndefinitions
     */
    case 'dataSubscriptionColumns':
    case 'mobileSubscriptionColumns':
    case 'teleSubscriptionColumns':
    case 'tvSubscriptionColumns':
    case 'constactsColumns':
    case 'incidentsColumns':
    case 'invoicesColumns':
    case 'ordersColumns':
      return { category, ...columnsBase };

    /**
     * Wheter the indicent details is toggled
     */
    case 'incidentDetailsOpen':
      return { category, action: 'incident_details_open', label };

    /**
     * Prefferd subscription group -- set automatically when tabs are used
     */
    case 'subscriptionGroup':
      return { category, action: 'set_subscription_group', label };

    /**
     * Rows in subscription config tables
     */
    case 'subscriptionConfigPageSize':
      return { category, action: 'set_subscription_congfig_rows', label };

    /**
     * Prefferd subscription config tab -- set automatically when tabs are used
     */
    case 'subscriptionConfigTab':
      return { category, action: 'set_subscription_congfig_tab', label };

    /**
     * We track opening the Changelog dropdown instead
     */
    case 'changelogLastSeenAtTimestamp':
      return null;

    default:
      return null;
  }
};

const getCategory = (userPref: keyof SavedUserPreferences): Category | null => {
  switch (userPref) {
    case 'dataSubscriptionView':
    case 'dataSubscriptionPageSize':
    case 'dataSubscriptionColumns':
      return 'Data';

    case 'mobileSubscriptionView':
    case 'mobileSubscriptionPageSize':
    case 'mobileSubscriptionColumns':
      return 'Mobile';

    case 'teleSubscriptionView':
    case 'teleSubscriptionColumns':
    case 'teleSubscriptionPageSize':
      return 'Tele';

    case 'tvSubscriptionView':
    case 'tvSubscriptionColumns':
    case 'tvSubscriptionPageSize':
      return 'Tv';

    case 'ordersColumns':
    case 'ordersPageSize':
    case 'ordersView':
      return 'Orders';

    case 'contactsView':
    case 'constactsColumns':
    case 'contactsPageSize':
      return 'Contacts';

    case 'incidentsView':
    case 'incidentsColumns':
    case 'incidentsPageSize':
    case 'incidentDetailsOpen':
      return 'Contacts';

    case 'subscriptionGroup':
    case 'allSubscriptionPageSize':
      return 'All services';

    case 'subscriptionConfigPageSize':
    case 'subscriptionConfigTab':
      return 'Data';

    case 'invoicesColumns':
      return 'Invoice';

    default:
      return null;
  }
};
