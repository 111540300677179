import React from 'react';
import { Button } from 'src/lib/ui';
import cn from 'classnames';
import { t } from 'src/lib/i18n';

export const ToggeFiltersButton = (props: {
  isOpen: boolean;
  toggle: () => void;
  numberOfActiveFilters: number;
}) => {
  const i18n = t.layout.FilterSection;

  const { isOpen, toggle, numberOfActiveFilters } = props;
  return (
    <Button
      className={cn(
        'FilterSection-mainSection-showFilter',
        isOpen ? 'active' : undefined
      )}
      color={numberOfActiveFilters && !isOpen ? 'white' : 'dark'}
      transparent={true}
      icon="filter"
      onClick={toggle}
      size="small"
    >
      {i18n.filter}
      {numberOfActiveFilters ? ` (${numberOfActiveFilters})` : null}
    </Button>
  );
};
