import * as React from 'react';
import {
  Step,
  StepShape,
  FormikTextField,
  FormikDatePicker,
} from 'src/lib/flow';
import { isBefore } from 'src/lib/utils/dateUtils';
import { colors, Attribute, Attributes } from 'src/lib/ui';
import * as yup from 'yup';
import { t } from 'src/lib/i18n';

export interface Step6Values {
  deliveryDate?: string;
  additionalInformation?: string;
}

const Step6: Step<Step6Values> = (): StepShape<Step6Values> => {
  return {
    title: t.ordering.OrderIpVpn.Step6.title,

    validationSchema: yup.object({
      deliveryDate: yup.string(),
      additionalInformation: yup.string(),
    }),

    renderSummary: ({ values }) => {
      return (
        <Attributes>
          <Attribute
            label={t.ordering.OrderIpVpn.Step6.deliveryDateLabel}
            value={values.deliveryDate}
          />
          <Attribute
            label={t.ordering.OrderIpVpn.Step6.additionalInformationLabel}
            value={values.additionalInformation}
          />
        </Attributes>
      );
    },

    renderForm: ({ flow }) => (
      <>
        <p className="small mt-0 mb-3" style={{ color: colors.greyDarkText }}>
          {t.ordering.OrderIpVpn.Step6.summary}
        </p>

        <div className="row">
          <FormikDatePicker
            showPicker={true}
            label={t.ordering.OrderIpVpn.Step6.deliveryDateLabel}
            id="deliveryDate"
            required={true}
            className="col-md-4"
            isOutsideRange={isBefore(-1)}
          />
        </div>

        <div className="row">
          <FormikTextField
            id="additionalInformation"
            label={t.ordering.OrderIpVpn.Step6.additionalInformationLabel}
            required={true}
            className="col-12"
            bordered={true}
          />
        </div>
      </>
    ),
  };
};

export default Step6;
