import React, { useEffect } from 'react';
import { SettingsListItem } from '../../SettingsListItem';
import { Toggle } from '@telia/styleguide/business';
import { t } from 'src/lib/i18n';
import { SectionProps } from '../BarringSettingsFormModal';

export function BarOutgoingCalls(props: SectionProps) {
  const { state, toggleOption } = props;
  const i18n = t.mobile.settings.barring;

  useEffect(() => {
    if (state.outgoingCallsAndSms) {
      toggleOption({
        outgoingCalls: false,
        internationalOutgoingCalls: false,
        internationalCallsExceptHome: false,
      });
    }
  }, [state.outgoingCallsAndSms]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.outgoingCalls) {
      toggleOption({
        outgoingCallsAndSms: false,
        internationalOutgoingCalls: false,
        internationalCallsExceptHome: false,
      });
    }
  }, [state.outgoingCalls]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.internationalOutgoingCalls) {
      toggleOption({
        outgoingCallsAndSms: false,
        outgoingCalls: false,
        internationalCallsExceptHome: false,
      });
    }
  }, [state.internationalOutgoingCalls]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (state.internationalCallsExceptHome) {
      toggleOption({
        outgoingCallsAndSms: false,
        outgoingCalls: false,
        internationalOutgoingCalls: false,
      });
    }
  }, [state.internationalCallsExceptHome]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <div className="pt-4 pb-2">{i18n.barCalls.outgoing}</div>
      <div className="mx-3" style={{ fontSize: '0.875rem' }}>
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barCalls.barCallsMsgOutg}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.outgoingCallsAndSms}
              onChange={() => toggleOption('outgoingCallsAndSms')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barCalls.barCallsOutg}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.outgoingCalls}
              disabled={state.outgoingCallsAndSms}
              onChange={() => toggleOption('outgoingCalls')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barCalls.barOutgCallsAbroad}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.internationalOutgoingCalls}
              disabled={state.outgoingCallsAndSms}
              onChange={() => toggleOption('internationalOutgoingCalls')}
            />
          }
        />
        <SettingsListItem
          className="px-0 pb-0"
          title={i18n.barCalls.barCallsAbroadExcHome}
          toggelAlignEnd={true}
          toggle={
            <Toggle
              checked={state.internationalCallsExceptHome}
              disabled={state.outgoingCallsAndSms}
              onChange={() => toggleOption('internationalCallsExceptHome')}
            />
          }
        />
      </div>
    </>
  );
}
