import * as React from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { t } from 'src/lib/i18n';
import { Button, SelectField } from 'src/lib/ui';
import { getYears, getSubscriptionTypes } from './lib';
import { useOrganisations } from './queries/useOrganisations';
import { PeriodSelector } from './PeriodSelector';
import { GenerateReportFunction } from './context/ReportContext';

type Props = {
  open: boolean;
  toggle: () => void;
  generateReport: GenerateReportFunction;
  header: string;
  description?: string;
  organizationSelector?: boolean;
  periodSelector?: boolean;
  subscriptionSelector?: boolean;
  yearSelector?: boolean;
};

export const DownloadModal = (props: Props) => {
  const [organization, setOrganization] = React.useState('');
  const [from, setFrom] = React.useState('');
  const [to, setTo] = React.useState('');
  const [year, setYear] = React.useState('');
  const [subType, setSubType] = React.useState('');

  const { organisations } = useOrganisations();
  const organizationOptions =
    organisations?.map(org => ({ label: org.name, value: org.id })) ?? [];

  const years = getYears();
  const subscriptionTypes = getSubscriptionTypes();

  const translate = t.reports;

  const validInput =
    (props.periodSelector ? !!from && !!to : true) &&
    (props.yearSelector ? !!year : true);

  return (
    <Modal isOpen={props.open} toggle={props.toggle}>
      <ModalHeader>{props.header}</ModalHeader>
      <ModalBody className="p-4">
        <div className="pb-4">{props.description}</div>
        {props.organizationSelector && (
          <div className="pb-4">
            <div>
              {organizationOptions.length > 0 && (
                <SelectField
                  label={translate.downloadModal.invoiceAccount}
                  allowMultiple={false}
                  value={organization}
                  options={[
                    {
                      label: translate.downloadModal.invoiceAccountPlaceholder,
                      value: '',
                    },
                    ...organizationOptions,
                  ]}
                  placeholder={
                    translate.downloadModal.invoiceAccountPlaceholder
                  }
                  onChange={val => setOrganization(val?.value ?? '')}
                />
              )}
            </div>
          </div>
        )}
        {props.periodSelector && (
          <PeriodSelector from={from} to={to} setFrom={setFrom} setTo={setTo} />
        )}
        {props.yearSelector && (
          <div className="pb-4">
            <div>
              <SelectField
                label={translate.downloadModal.period}
                allowMultiple={false}
                value={year}
                options={years}
                placeholder={translate.downloadModal.year}
                onChange={val => setYear(val?.value ?? '')}
                required
              />
            </div>
          </div>
        )}
        {props.subscriptionSelector && (
          <SelectField
            label={translate.downloadModal.subscriptions}
            allowMultiple={false}
            value={subType}
            options={[
              {
                label: translate.downloadModal.subscriptionsPlaceholder,
                value: '',
              },
              ...subscriptionTypes,
            ]}
            placeholder={translate.downloadModal.subscriptionsPlaceholder}
            onChange={val => setSubType(val?.value ?? '')}
          />
        )}
      </ModalBody>
      <ModalFooter>
        <Button color="grey" onClick={props.toggle} className="mr-4">
          {translate.actions.cancel}
        </Button>
        <Button
          color="purple"
          transparent={true}
          onClick={() =>
            props.generateReport({
              fromDate: from,
              toDate: to,
              year: year,
              organizationId: organization,
              subscriptionId: subType,
            })
          }
          disabled={!validInput}
        >
          {translate.actions.download}
        </Button>
      </ModalFooter>
    </Modal>
  );
};
