import * as React from 'react';
import { Step, StepShape, FormikSelectField } from 'src/lib/flow';
import { colors, Attribute } from 'src/lib/ui';
import * as yup from 'yup';
import { t } from 'src/lib/i18n';

export interface Step3Values {
  accessBandwidth?: string;
}

const bandwidthOptions = [
  { label: '25 Mbit/s', value: '25 Mbit/s' },
  { label: '50 Mbit/s', value: '50 Mbit/s' },
  { label: '75 Mbit/s', value: '75 Mbit/s' },
  { label: '100 Mbit/s', value: '100 Mbit/s' },
  { label: '500 Mbit/s', value: '500 Mbit/s' },
  { label: '1 Gbit/s', value: '1 Gbit/s' },
];

const Step3: Step<Step3Values> = (): StepShape<Step3Values> => {
  return {
    title: t.ordering.OrderIpVpn.Step3.title,

    validationSchema: yup.object({
      accessBandwidth: yup.string().required(t.ordering.required),
    }),

    renderSummary: ({ values }) => {
      return (
        <Attribute
          label={t.ordering.OrderIpVpn.Step3.accessBandwidthSummary}
          value={values.accessBandwidth}
        />
      );
    },

    renderForm: ({ flow }) => (
      <>
        <p className="small mt-0 mb-3" style={{ color: colors.greyDarkText }}>
          {t.ordering.OrderIpVpn.Step3.summary}
        </p>

        <div className="row mb-3">
          <FormikSelectField
            id="accessBandwidth"
            label={t.ordering.OrderIpVpn.Step3.accessBandwidthLabel}
            required={true}
            placeholder={t.ordering.OrderIpVpn.Step3.accessBandwidthPlaceholder}
            options={bandwidthOptions}
            className="col-md-4"
          />
        </div>
      </>
    ),
  };
};

export default Step3;
