import * as React from 'react';
import matchSorter from 'match-sorter';
import { urlFor } from 'src/lib/ui';
import {
  Organisation,
  WithCustomerOrganisations,
} from 'src/areas/main/cost/lib';
import { OrganisationTreeNavigatorItem } from './OrganisationTreeNavigatorItem';
import './OrganisationTreeNavigator.scss';
import { Loading } from '../Loading/Loading';
import { SearchInput } from '../SearchInput/SearchInput';

export type OrganisationTreeNavigatorProps = Pick<
  Props,
  Exclude<keyof Props, 'rootOrganisation' | 'allOrganisations'>
>;

export const OrganisationTreeNavigator: React.FC<OrganisationTreeNavigatorProps> = props => (
  <WithCustomerOrganisations>
    {co => {
      if (co.loading) {
        return <Loading />;
      }
      if (!co.customer) {
        return null;
      }
      return (
        <InnerOrganisationTreeNavigator
          allOrganisations={co.customer.allOrganisations}
          rootOrganisation={co.customer.rootOrganisation}
          {...props}
        />
      );
    }}
  </WithCustomerOrganisations>
);

/**
 * Props for InnerOrganisationTreeNavigator
 */
interface Props {
  /**
   * The structure of organisations to navigate, pointing to the root organisation.
   */
  rootOrganisation: Organisation;

  /**
   * A list of all available organisations; used for filtering.
   */
  allOrganisations?: Organisation[];

  /**
   * Whether to show the root organisation. Default is true.
   */
  showRootOrganisation?: boolean;

  /**
   * Whether to wrap each organisation label in `<LinkTo organisation={org.id}>...</LinkTo>` links. Default is true.
   */
  linkToOrganisations?: boolean;

  /**
   * Whether to allow the user to select organisations. This will display a checkbox/radiobutton next to each organisation. Default is false.
   */
  selectOrganisations?: boolean;

  /**
   * Whether to close other branches when exploring a new branch. Default false.
   */
  singleBranchOnly?: boolean;

  /**
   * Whether to disable selection controls when `selectOrganisations={true}`.
   */
  disabled?: boolean;

  // currentOrgId: string;
  // handleToggle: (organisationId: string, path: string[]) => void;
  // selectedOrganisationIds?: string[] | undefined;
  // handleSelect?: (orgId: string) => void;

  /**
   * Whether the user can select more than one organisation.
   * This is ignored when onSelectedChange is not set.
   */
  allowMultipleSelections?: boolean;

  /**
   * The callback to trigger when the selected organisations change.
   */
  onSelectedChange?: (organisationIds: string[]) => void;

  /**
   * The IDs of the organisations that are selected.
   */
  selectedOrganisationIds?: string[];

  /**
   * No children supported.
   */
  children?: undefined;
}

interface State {
  // numItemsToShow: number;
  searchFilter: string;
  expandedOrganisationIds: {
    [id: string]: boolean;
  };
}

// const initalNumItemsToShow = 7;

// const getPathForOrgId = (root: Organisation, currentOrgId) => {
//   let queue: OrganisationNode[] = [{ org: root, prev: root }];
//   let current;
//   let result;
//   while (queue.length > 0) {
//     current = queue.pop();
//     if (current.org.id === currentOrgId) {
//       result = current;
//       break;
//     }
//     if (!current.org.childOrganisations.length) {
//       continue;
//     }
//     queue = queue.concat(
//       current.org.childOrganisations.map(c => ({ org: c, prev: current }))
//     );
//   }
//   const path: string[] = [];
//   if (result) {
//     let curr = result;
//     while (curr.org.id !== root.id) {
//       path.push(curr.org.id);
//       curr = curr.prev;
//     }
//   }
//   return path;
// };

export class InnerOrganisationTreeNavigator extends React.Component<
  Props,
  State
> {
  constructor(props: Props) {
    super(props);
    this.state = {
      // numItemsToShow: initalNumItemsToShow,
      searchFilter: '',
      expandedOrganisationIds: {},
    };
  }

  handleToggle = (organisationId: string, path: string[]) => {
    if (this.props.singleBranchOnly) {
      // Close all other branches -- but make sure every parent is expanded
    }
    this.setState(prevState => ({
      expandedOrganisationIds: {
        ...prevState.expandedOrganisationIds,
        [organisationId]: prevState.expandedOrganisationIds[organisationId]
          ? !prevState.expandedOrganisationIds[organisationId]
          : true,
      },
    }));
  };

  toggleOrganisationId = (toggleOrganisationId: string) => {
    const selected = this.props.selectedOrganisationIds || [];
    if (selected.includes(toggleOrganisationId)) {
      return selected.filter(x => x !== toggleOrganisationId);
    } else {
      return this.props.allowMultipleSelections
        ? [...selected, toggleOrganisationId]
        : [toggleOrganisationId];
    }
  };

  handleSelect = (organisationId: string) => {
    if (!this.props.selectOrganisations) {
      return;
    }
    if (this.props.onSelectedChange) {
      this.props.onSelectedChange(this.toggleOrganisationId(organisationId));
    }
  };

  renderChildren = (orgs: Organisation[], level: number) => {
    return orgs.map(co => this.renderOrganisation(co, level));
  };

  renderOrganisation = (org: Organisation, level: number) => (
    <li key={org.id}>
      {/* First, render the organisation item itself */}
      <OrganisationTreeNavigatorItem
        level={level}
        label={`${org.id} ${org.name}`}
        expanded={this.state.expandedOrganisationIds[org.id]}
        linkTo={
          this.props.linkToOrganisations
            ? urlFor({ organisation: org.id })
            : undefined
        }
        onToggleSelect={() => this.handleSelect(org.id)}
        onToggleExpand={() => {
          this.handleToggle(org.id, []);
        }}
        disabled={this.props.disabled}
        expandable={org.childOrganisations && org.childOrganisations.length > 0}
        selected={
          this.props.selectedOrganisationIds &&
          this.props.selectedOrganisationIds.includes(org.id)
        }
        selectable={this.props.selectOrganisations}
      />

      {/* Second, render any children */}
      {org.childOrganisations &&
      org.childOrganisations.length > 0 &&
      this.state.expandedOrganisationIds[org.id] ? (
        <ul>{this.renderChildren(org.childOrganisations, level + 1)}</ul>
      ) : null}
    </li>
  );

  render() {
    // const { numItemsToShow, searchFilter } = this.state;
    // const currentOrgId = '';
    // const path = getPathForOrgId(this.props.rootOrganisation, currentOrgId);
    // const filteredOrganisations = this.props.rootOrganisation.childOrganisations.filter(
    //   org =>
    //     org.name.toLowerCase().includes(searchFilter.toLowerCase()) ||
    //     org.id.toString().includes(searchFilter)
    // );
    // const isMore = numItemsToShow < filteredOrganisations.length;
    return (
      <div className="OrganisationTreeNavigator">
        <div className="pb-3 w-50">
          <SearchInput
            onChange={e =>
              this.setState({
                searchFilter: e.target.value,
                // numItemsToShow: initalNumItemsToShow,
              })
            }
            narrow={true}
          />
        </div>
        <div className="OrganisationTreeNavigator-container">
          {this.state.searchFilter && this.props.allOrganisations ? (
            <ul>
              {this.renderChildren(
                matchSorter(
                  this.props.allOrganisations,
                  this.state.searchFilter,
                  { keys: ['name', 'id'] }
                ),
                0
              )}
            </ul>
          ) : (
            <ul>
              {this.props.showRootOrganisation
                ? this.renderOrganisation(this.props.rootOrganisation, 0)
                : this.renderChildren(
                    this.props.rootOrganisation.childOrganisations,
                    0
                  )}
            </ul>
          )}
        </div>

        {/* {isMore ? (
          <div className="d-flex justify-content-center pt-3">
            <Button
              rounded={true}
              color="primary"
              onClick={e => {
                e.preventDefault();
                this.setState(prevState => ({
                  numItemsToShow:
                    prevState.numItemsToShow + initalNumItemsToShow,
                }));
              }}
            >
              Vis flere fakturasteder
            </Button>
          </div>
        ) : null} */}
      </div>
    );
  }
}
