import React, { useEffect, useMemo } from 'react';
import useGetTagOptions from 'src/areas/main/subscriptions/utils/useGetTagOptions';
import { SearchOptionSelect, Option } from 'src/lib/ui';
import { useTagsOnSubscriptions } from 'src/areas/main/subscriptions/tags/useTags';
import groupBy from 'lodash/groupBy';
import sortBy from 'lodash/sortBy';
import { useForceUpdate, useRouter } from 'src/lib/utils';
import { useUpdateTagsOnSubscriptions } from 'src/areas/main/subscriptions/utils/useUpdateTagsOnSubscriptions';
import { useCreateTag } from 'src/areas/main/subscriptions/tags/useCreateTag';
import { t } from 'src/lib/i18n';
import { trackBatchEditTags } from 'src/lib/analytics';
interface Props {
  selectedSubscriptionIds: string[];
}

const getDistinctEntries = (
  tag: string,
  index: number,
  allTags: Array<string>
) => allTags.indexOf(tag) === index;
const isString = (tagId: string | undefined): tagId is string => !!tagId;
export const BatchEditTags = (props: Props) => {
  const { createTag, ...createTagContext } = useCreateTag();
  const { result, refetch } = useGetTagOptions('');
  const { updateTags } = useUpdateTagsOnSubscriptions();
  const { data } = useTagsOnSubscriptions(props.selectedSubscriptionIds);
  const router = useRouter<{ group: string }>();
  const allTags = useMemo(
    () => data?.customer?.subscriptions?.flatMap(s => s?.tags),
    [data]
  );
  const groupedTags = useMemo(
    () => Object.values(groupBy(allTags, r => r?.id)),
    [allTags]
  );
  const update = useForceUpdate();

  useEffect(() => {
    refetch();
  }, [createTagContext.data, refetch]);

  /**
   * Tags that are set for all of the selected subscription
   */

  const initialCommonTags = useMemo(
    () =>
      groupedTags
        .filter(tags => tags.length === props.selectedSubscriptionIds?.length)
        .flat()
        .map(tag => tag?.id)
        .filter(isString)
        .filter(getDistinctEntries),
    [groupedTags, props.selectedSubscriptionIds]
  );

  /**
   * Tags that are set for one or more, but not all, of the selected subscriptions
   */
  const initialPartialTags = useMemo(
    () =>
      groupedTags
        .flat()
        .filter(tag => tag && !initialCommonTags.includes(tag?.id))
        .map(tag => tag?.id)
        .filter(isString)
        .filter(getDistinctEntries),
    [groupedTags, initialCommonTags]
  );

  if (!props.selectedSubscriptionIds.length) {
    return null;
  }

  const handleSave = (
    allCommonTags?: string[] | null,
    partialTags?: string[] | null
  ) => {
    trackBatchEditTags(router.match.params.group);
    /**
     * Tags that have been selected, and is to be put on all subscriptions
     */
    const tagsToAdd = allCommonTags?.filter(
      t => !initialCommonTags.includes(t)
    );

    /**
     * Tags that were initially set on all subscription, but have been unselected
     */
    const commonTagsToRemove = initialCommonTags.filter(
      tag => !allCommonTags?.includes(tag)
    );

    /**
     * Tags that were initially set on some subscriptions, but have been unselected
     */
    const partialTagsToRemove = initialPartialTags.filter(
      tag => !partialTags?.includes(tag) && !allCommonTags?.includes(tag)
    );

    const tagsToRemove = [...commonTagsToRemove, ...partialTagsToRemove];

    const initialTagsSubscriptionMap = initialPartialTags.map(tag => {
      const subs = data?.customer?.subscriptions
        ?.filter(sub => sub?.tags?.find(t => t.id === tag))
        .map(sub => sub?.id)
        .filter(isString);
      return {
        tagId: tag,
        subscriptionIds: subs ?? [],
      };
    });

    updateTags({
      initial: {
        commonTags: initialCommonTags,
        partialTags: initialTagsSubscriptionMap,
      },
      update: {
        subscriptionIds: props.selectedSubscriptionIds,
        tagsToAdd,
        tagsToRemove,
      },
    });
  };

  const options: Option[] = sortBy(
    result,
    option =>
      !(
        initialCommonTags.includes(option.value) ||
        initialPartialTags.includes(option.value)
      )
  );

  const addItem: Option[] = [
    {
      value: 'function_add_item',
      label: t.subscriptions.tags.createNewTag,
      icon: 'add',
      onClick: createTag,
    },
  ];

  return (
    <SearchOptionSelect
      id="tags"
      label="Tags"
      icon="tags"
      color="dark"
      size="small"
      transparent={true}
      options={options}
      allowMultiple={true}
      selectedOptions={initialCommonTags}
      partiallySelectedOptions={initialPartialTags}
      disableSaveOnClickOutside={true}
      handleSave={handleSave}
      cancel={update}
      actionOptions={addItem}
    />
  );
};
