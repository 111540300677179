import React, { useState, useEffect } from 'react';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import { useUpdateAttribute } from '../../../mutations/useUpdateAttribute';
import { TextField, Button, FormSubmitBar } from 'src/lib/ui';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import { trackUpdateServiceMetadata } from 'src/lib/analytics';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';

import { t } from 'src/lib/i18n';

interface Props {
  subscription: {
    alias?: string | null;
    id?: string | null;
    group?: SubscriptionGroup | null;
  };
}

export const EditSubscriptionAlias: React.FunctionComponent<Props> = props => {
  const [input, setInput] = useState<string>(props.subscription.alias ?? '');
  const [open, setOpen] = useState<boolean>(false);
  const updateAttributeContext = useUpdateAttribute();

  const toggleModal = () => {
    setOpen(!open);
  };

  useEffect(() => {
    if (open === true) {
      setInput(props.subscription.alias ?? '');
    }
  }, [open, props.subscription.alias]);

  const handleSave = (e?: React.SyntheticEvent<EventTarget>) => {
    e?.preventDefault();
    if (props.subscription.group)
      trackUpdateServiceMetadata(props.subscription.group, 'alias', input);
    toggleModal();
    updateAttributeContext.setAttributeForSubscription(
      props.subscription.id ?? '',
      'alias',
      input
    );
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === 'Enter') {
      handleSave(e);
    }
  };

  return (
    <>
      <Button
        icon="settings"
        color="grey"
        size="small"
        rounded={true}
        onClick={toggleModal}
      >
        {props.subscription.alias
          ? t.subscriptions.EditSubscriptionAlias.toggleBtnEdit
          : t.subscriptions.EditSubscriptionAlias.toggleBtnAdd}
      </Button>
      <Modal isOpen={open} toggle={toggleModal}>
        <ModalHeader>
          {t.subscriptions.EditSubscriptionAlias.description}
        </ModalHeader>
        <ModalBody>
          <TextField
            id="alias"
            className="w-100"
            value={input}
            label={t.subscriptions.EditSubscriptionAlias.alias}
            onChange={e => setInput(e.target.value)}
            onKeyDown={handleKeyDown}
          />
        </ModalBody>
        <ModalFooter>
          <FormSubmitBar cancel={toggleModal} submit={handleSave} />
        </ModalFooter>
      </Modal>
    </>
  );
};
