import { IncidentStatus } from 'src/__types/graphql-global-types';
import { IconDefinition } from '@telia/styleguide';
import { StatusColor } from 'src/lib/types';

export const getIncidentIcon = (
  status?: IncidentStatus | null
): { icon: IconDefinition; iconColor: StatusColor | undefined } => {
  switch (status) {
    case 'onHoldPendingCustomer':
      return {
        icon: 'time-waiting',
        iconColor: 'danger',
      };

    case 'onHoldPendingTelia':
      return {
        icon: 'time-waiting',
        iconColor: undefined,
      };
    case 'canceled':
      return {
        icon: 'stop',
        iconColor: undefined,
      };
    case 'solved':
      return {
        icon: 'check-mark',
        iconColor: 'success',
      };
    case 'closed':
      return {
        icon: 'check-mark',
        iconColor: 'success',
      };

    // Default icon/color
    default:
      return {
        icon: 'question',
        iconColor: 'default',
      };
  }
};
