/**
 * This is the configuration settings for the client-side applications.
 * Note that this is available to all clients and must not contain secrets.
 *
 * In development, it fetches settings from the React preset PORTAL_* environment variables
 *    (set in `client/config/env.js` and `client/config/webpack.config.dev.js`).
 * In production, it fetches the settings from the server-set window.APPCONFIG object.
 *
 */

declare global {
  interface Window {
    APPCONFIG: {
      PORTAL_ENVIRONMENT?: string;
      PORTAL_GIT_COMMIT?: string;
      PORTAL_SENTRY_DSN?: string;
      PORTAL_ROOT_URL?: string;
      PORTAL_API_ROOT_URL?: string;
      PORTAL_OPENID_CLIENT_ID?: string;
      PORTAL_OPENID_AUTHORITY?: string;
      PORTAL_OPENID_REALM?: string;
      PORTAL_GTM_ID?: string;
      PORTAL_GOOGLE_MAPS_API_KEY?: string;
      PORTAL_PUBLIC_API_HOST?: string;
    };
  }
}

const env = {
  // First, load default settings from process.env (used in dev only)
  ...process.env,

  // Then, override with settings from window.APPCONFIG (used in prod only)
  ...(typeof window !== 'undefined' && typeof window.APPCONFIG === 'object'
    ? window.APPCONFIG
    : {}),
};

interface Config {
  environment?: string;
  gitCommit?: string;
  sentryDsn?: string;
  applicationRootUrl?: string;
  apiRootUrl?: string;
  openIdClientId: string;
  openIdClientIdMobile: string;
  openIdAuthority?: string;
  openIdRealm?: string;
  gtmId?: string;
  googleMapsApiKey?: string;
  canonicalRootUrl?: string;
  publicApiHost: string;
  devAuth?: boolean;
}

const config: Config = {
  // The deployment environment (qa or prod)
  environment: env.PORTAL_ENVIRONMENT,

  // The git commit hash
  gitCommit: env.PORTAL_GIT_COMMIT,

  // The Sentry DSN
  sentryDsn: env.PORTAL_SENTRY_DSN,

  // Root URL for application. Uses the browser-provided URL if falsy.
  applicationRootUrl: env.PORTAL_ROOT_URL,

  // Root URL for API (including GraphQL) -- this should point to the gateway.
  apiRootUrl: env.PORTAL_API_ROOT_URL,

  // The OpenID client ID to use for authentication in the main portal.
  openIdClientId: 'portal',

  // The OpenID client ID to use for authentication in the mobile site (Min side).
  openIdClientIdMobile: 'portal-minside',

  // The OpenID authority against which the application authenticates.
  openIdAuthority: env.PORTAL_OPENID_AUTHORITY,

  // The OpenID realm against which the application authenticates.
  openIdRealm: env.PORTAL_OPENID_REALM,

  // The Google Tag Manager id
  gtmId: env.PORTAL_GTM_ID,

  // The Google Maps API key
  googleMapsApiKey: env.PORTAL_GOOGLE_MAPS_API_KEY,

  // A self-referencing full URL with hostname and path to root.
  canonicalRootUrl: '',

  publicApiHost: env.PORTAL_PUBLIC_API_HOST || '',
};

if (typeof window !== 'undefined') {
  const { protocol, hostname, port } = window.location;
  config.canonicalRootUrl = `${protocol}//${hostname}${
    port ? `:${port}` : ''
  }/`;
}

export default config;
