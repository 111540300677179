import React from 'react';
import { t } from 'src/lib/i18n';
import { colors } from 'src/lib/ui';
import { NotificationSettings } from './NotificationSettings';
import { useNotificationSettings } from '../mutations/useNotificationSettings';
import { NotificationSettingsType } from 'src/__types/graphql-global-types';
import {
  useContact_customer_contacts_notificationSettings_change as ChangeSettings,
  useContact_customer_contacts_notificationSettings_incident as IncidentSettings,
} from 'src/areas/main/contacts/queries/__types/useContact';

export const NotificationsSection = () => {
  const notificationSettings = useNotificationSettings();

  const getSettings = (type: NotificationSettingsType) =>
    notificationSettings?.me &&
    notificationSettings.me.notificationSettings &&
    notificationSettings.me.notificationSettings.find(
      settings => settings.type === type
    );

  const incidentSettings = getSettings(NotificationSettingsType.incident);
  const changeSettings = getSettings(NotificationSettingsType.change);

  return (
    <>
      <h2 className="pb-3">{t.profile.notificationPreferences.title}</h2>
      <div className="d-flex align-items-center pb-4">
        <div
          style={{
            color: `${colors.greyDarkText}`,
            padding: '0 2rem 0 0.5rem',
          }}
        >
          {t.profile.notificationPreferences.description}
          {notificationSettings && (
            <NotificationSettings
              incidentSettings={incidentSettings as IncidentSettings}
              changeSettings={changeSettings as ChangeSettings}
              contactId={notificationSettings?.me?.contact?.id}
            />
          )}
        </div>
      </div>
    </>
  );
};
