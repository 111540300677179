import { UserDetails } from 'src/areas/mobile/types';
import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import * as t from './__types/useUpdateSubscriptionUser';
import { QUERY_OVERVIEW_DATA } from '../../overview/useOverviewData';

const UPDATE_SUBSCRIPTION_USER = gql`
  mutation useUpdateSubscriptionUser($input: UpdateMobileUserInput) {
    updateMobileUser(input: $input) {
      id
      user {
        firstName
        lastName
        position
        employeeNum
        email
        flexField1
        flexField2
        flexField3
        flexField4
      }
    }
  }
`;

export interface UpdateSubscriptionUserResult {
  updateUser: (userDetails: UserDetails) => void;
  data?: t.useUpdateSubscriptionUser;
  loading: boolean;
  error: boolean;
}

export const useUpdateSubscriptionUser = (): UpdateSubscriptionUserResult => {
  const subscription = useSubscriptionContext();
  const [mutation, { loading, error, data }] = useMutation<
    t.useUpdateSubscriptionUser,
    t.useUpdateSubscriptionUserVariables
  >(UPDATE_SUBSCRIPTION_USER);

  const updateUser = (userDetails: UserDetails) => {
    mutation({
      variables: {
        input: {
          id: subscription.id,
          ...userDetails,
        },
      },
      refetchQueries: [
        {
          query: QUERY_OVERVIEW_DATA,
          variables: {
            subscriptionId: subscription.id,
            dontFetchPinPuk: true,
          },
        },
      ],
    });
  };
  const mutationsResult: UpdateSubscriptionUserResult = {
    updateUser,
    error: !!error,
    loading,
    data,
  };
  return mutationsResult;
};
