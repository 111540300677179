import { SearchResult } from 'src/areas/main/search/types';
import { mapTypenameToResourceType } from './mapTypenameToResourceType';
import { ResourceType } from 'src/lib/types';

type SearchResultReturnType = Array<{
  resourceType: ResourceType;
  items: SearchResult[];
}>;

export function groupSearchResults(
  items: SearchResult[]
): SearchResultReturnType {
  if (!items) {
    return [];
  }

  const groups: ResourceType[] = [];
  const grouped: { [key: string]: SearchResult[] } = {};

  for (let x = 0, l = items.length; x < l; x++) {
    const resourceType = mapTypenameToResourceType(items[x]);
    if (resourceType) {
      if (!grouped[resourceType]) {
        grouped[resourceType] = [];
        groups.push(resourceType);
      }
      grouped[resourceType].push(items[x]);
    }
  }

  const maxNumberOfGroups = 3;
  const maxItemsPerGroup = 3;

  const resultGroups = groups.slice(0, maxNumberOfGroups).map(group => ({
    resourceType: group,
    items: grouped[group].slice(0, maxItemsPerGroup),
  }));

  return resultGroups;
}
