import * as React from 'react';
import { Redirect, Route, RouteComponentProps, Switch } from 'react-router';
import { IncidentsContainer } from './IncidentsContainer';
import { IncidentContent } from 'src/areas/main/incidents/IncidentContent';

const IncidentSection: React.SFC<RouteComponentProps<{}>> = ({ match }) => (
  <Switch>
    <Route path={`/meldinger/:id([A-Z]+[0-9]+)`} component={IncidentContent} />
    <Route
      path={`/meldinger/:group(aktive|historikk)`}
      component={IncidentsContainer}
    />
    <Redirect exact={true} from={`/meldinger`} to={`/meldinger/aktive`} />
  </Switch>
);

export default IncidentSection;
