import React from 'react';
import { CpeStatus } from 'src/__types/graphql-global-types';
import { urlFor } from 'src/lib/ui';
import { useRouter } from 'src/lib/utils';
import './SubscriptionStatusItem.scss';
import SubscriptionStatusIcon from '../SubscriptionStatusIcon/SubscriptionStatusIcon';

export const SubscriptionStatusItem: React.SFC<{
  subscriptionId?: string | null;
  status?: CpeStatus | null;
  refetching: boolean;
  disabled?: boolean;
}> = props => {
  const router = useRouter();
  return (
    <div className="SubscriptionStatusItem d-flex align-items-center">
      <div className="pr-3">
        <SubscriptionStatusIcon
          cpeStatus={props.status}
          circleBackground={true}
          loading={props.refetching}
        />
      </div>

      <div
        className="SubscriptionStatusItem-content"
        role={props.disabled ? undefined : 'button'}
        onClick={() => {
          if (!props.disabled && props.subscriptionId) {
            router.history.push(urlFor({ subscription: props.subscriptionId }));
          }
        }}
      >
        {props.children}
      </div>
    </div>
  );
};
