import React from 'react';
import { SmsEntity } from 'src/areas/mobile/types';
import { t } from 'src/lib/i18n';
import { BodyCard, colors } from 'src/lib/ui';
import { Icon } from '@telia/styleguide';

interface Props {
  sms?: SmsEntity | null;
}
export const SmsItem: React.FC<Props> = ({ sms }) => {
  if (!sms) {
    return null;
  }

  const rcpts = sms.recipients ?? [];

  const sentTo = rcpts.filter(recipient => recipient?.success);
  const failedToSend = rcpts.filter(recipient => !recipient?.success);

  return (
    <BodyCard borders={true} boxShadow={1}>
      <small
        className="pb-3 mb-4 d-block"
        style={{
          color: colors.greyDarkText,
          borderBottom: `1px solid ${colors.grey}`,
        }}
      >
        <div>{sms.timestamp}</div>
        {sentTo.length ? (
          <div className="d-flex">
            <div className="pr-2">{t.mobile.sms.sentTo + ':'}</div>
            <div style={{ color: colors.black }}>
              {sentTo.map(
                (recipient, i) =>
                  recipient?.msisdn + (i === sentTo.length ? ',' : '')
              )}
            </div>
          </div>
        ) : null}
        {failedToSend.length ? (
          <div className="d-flex">
            <Icon icon="alert" className="text-danger pr-2" />
            <div className="pr-2">{t.mobile.sms.failed + ':'}</div>
            <div style={{ color: colors.black }}>
              {failedToSend.map(
                (recipient, i) =>
                  recipient?.msisdn + (i === failedToSend.length ? ',' : '')
              )}
            </div>
          </div>
        ) : null}
      </small>

      <div className="py-2">{sms.message}</div>
    </BodyCard>
  );
};
