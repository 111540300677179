import React, { useState } from 'react';
import { Button, Loading, Chip, RadioGroup } from 'src/lib/ui';
import './PermissionCard.scss';
import { Organisation } from 'src/areas/main/cost/lib';
import AnimateHeight from 'react-animate-height';
import { ItemListPicker, RadioGroupOption } from 'src/lib/ui';
import { Tag } from 'src/lib/types';
import { OrganisationPicker } from 'src/lib/ui/OrganisationPicker/OrganisationPicker';
import { t } from 'src/lib/i18n';
import { mapTagsToItem } from 'src/areas/main/subscriptions/Pages/Components/SubscriptionTagsPicker/SubscriptionTagsPicker';
import { PermissionLevel } from '../createContact/types';
import { useCustomerOrganisations } from 'src/areas/main/cost/lib/queries/WithCustomerOrganisations';
import { WithCustomerTags } from 'src/areas/main/subscriptions/Pages/Components/SubscriptionTagsPicker/WithCustomerTags';
interface Props {
  heading: string;
  /**
   * The id to match Formik values
   */
  id: string;

  disabled?: boolean;

  /**
   * The currently selected tags
   */
  selectedTags?: string[];

  /**
   * The currently selected organisations
   */
  selectedOrganisations?: string[];

  /**
   * The currently selected permission level
   */
  selectedLevel?: PermissionLevel;

  /**
   * Whether to allow selection of tags.
   */
  allowTags: boolean;

  onPermissionChange: (
    level: PermissionLevel,
    organisations?: string[],
    tags?: string[]
  ) => void;
}
const PermissionCard: React.FC<Props> = props => {
  const [showTags, setShowTags] = useState(false);
  const [showOrganisations, setShowOrganisations] = useState(false);

  const getOrganisationNameById = (
    id: string,
    organisations?: Organisation[]
  ): string | undefined => {
    if (organisations) {
      const organisation: Organisation | undefined = organisations.find(
        org => org.id === id
      );
      return organisation
        ? organisation.id + ' ' + organisation.name
        : undefined;
    } else {
      return '';
    }
  };

  const translate = t.ui.PermissionCard;
  const { customer } = useCustomerOrganisations();

  const options: RadioGroupOption[] = [
    { value: 'none', label: translate.noAccess },
    {
      value: 'full',
      label: translate.fullRoleAccess,
    },
    {
      value: 'partial',
      label: translate.partialAccess,
    },
  ];

  return (
    <div className="PermissionCard">
      <div className="PermissionCard-header pt-4 pb-3">{props.heading}</div>
      <div>
        <RadioGroup
          name={props.id}
          block={true}
          value={props.selectedLevel}
          options={options}
          onChange={value => props.onPermissionChange(value as PermissionLevel)}
        />

        <AnimateHeight
          duration={500}
          height={props.selectedLevel === 'partial' ? 'auto' : 0}
        >
          <div className="mt-2 pl-4">
            <>
              <Button
                className="my-4"
                type="button"
                onClick={() => setShowOrganisations(!showOrganisations)}
                base="primary"
                color="purple"
              >
                {translate.btnSelectOrgs}
              </Button>

              {props.selectedOrganisations ? (
                <ul>
                  {props.selectedOrganisations.map(id => (
                    <Chip
                      key={id}
                      label={
                        getOrganisationNameById(
                          id,
                          customer ? customer.allOrganisations : undefined
                        ) || ''
                      }
                      mode="select"
                      disabled={true}
                    />
                  ))}
                </ul>
              ) : null}

              {customer && customer.rootOrganisation && showOrganisations ? (
                <OrganisationPicker
                  initialOrganisations={props.selectedOrganisations}
                  disabled={props.disabled}
                  onSelectedOrganisationsChange={organisationIds =>
                    props.onPermissionChange(
                      'partial',
                      organisationIds,
                      props.selectedTags
                    )
                  }
                  toggleState={() => setShowOrganisations(!showOrganisations)}
                />
              ) : null}
            </>
            {props.allowTags ? (
              <WithCustomerTags>
                {search => {
                  if (search.loading) {
                    return <Loading />;
                  }
                  const allTags: Tag[] = search.tags || [];

                  return (
                    <div className="mt-2">
                      <Button
                        className="mb-4"
                        onClick={() => setShowTags(!showTags)}
                        type="button"
                        base="primary"
                        color="purple"
                      >
                        {translate.btnSelectTags}
                      </Button>
                      {props.selectedTags && props.selectedTags.length > 0 ? (
                        <ul>
                          {props.selectedTags.map(tag => {
                            allTags.find(currentTag => currentTag.tag === tag);
                            return (
                              <Chip
                                key={tag}
                                label={tag}
                                mode="select"
                                disabled={true}
                              />
                            );
                          })}
                        </ul>
                      ) : null}
                      {showTags ? (
                        <>
                          <ItemListPicker
                            heading={translate.editTagsTitle}
                            disableAddNew={true}
                            onSave={tags =>
                              props.onPermissionChange(
                                'partial',
                                props.selectedOrganisations,
                                tags.map(item => item.label)
                              )
                            }
                            initialSelectedItems={
                              props.selectedTags
                                ? mapTagsToItem(
                                    props.selectedTags.map(tag => {
                                      const existingTag = allTags.find(
                                        currentTag => currentTag.tag === tag
                                      );

                                      if (existingTag) {
                                        return existingTag;
                                      }

                                      // This is a new, not yet created tag.
                                      return {
                                        __typename: 'Tag',
                                        id: '', // This should not be used; we're only looking at tag.tag in the contacts/permissions section
                                        tag,
                                      } as Tag;
                                    })
                                  )
                                : []
                            }
                            toggleOnSave={true}
                            availableItems={mapTagsToItem(search.tags)}
                            toggleState={() => setShowTags(!showTags)}
                            isOpen={showTags}
                            filterPlaceholder={translate.searchTags}
                          />
                        </>
                      ) : null}
                    </div>
                  );
                }}
              </WithCustomerTags>
            ) : null}
          </div>
        </AnimateHeight>
      </div>
    </div>
  );
};

export default PermissionCard;
