const notifications = {
  NotificationBell: {
    btnSeeAll: 'Se alle varslinger',
    notificationPreferences: 'Varslingsinnstillinger',
    noUnreadNotifications: 'Du har ingen uleste varsler!',
    btnDropdown: 'Varslinger',
  },
  incidentCreate: {
    label: 'Feilmelding',
    description: 'Ny feilmelding opprettet',
  },
  incidentStatus: {
    label: 'Feilmelding',
    description: 'Endret status',
  },
  incidentComment: {
    label: 'Feilmelding',
    description: 'Ny melding',
  },
  incidentChange: {
    label: 'Feilmelding',
    description: 'Feilmeldingen er oppdatert',
  },
  order: {
    label: 'Ordre',
    description: '',
  },
  plannedWork: {
    label: 'Planlagt arbeid',
    description: 'Det er planlagt arbeid som påvirker noen av dine tjenester',
  },
  default: {
    label: 'Varsling',
    description: '',
  },
  id: 'ID',

  NotificationPage: {
    title: 'Varsler',
    showXofY: (showing: number, total?: number) =>
      `Viser ${showing} av ${total} varsler`,
    noNotifications: 'Ingen varsler',
    incident: 'Feilmelding',
    order: 'Ordre',
    subscription: 'Tjeneste',
    type: 'Type',
    status: 'Status',
    filterLabels: {
      statusChange: 'Endret status',
      newComment: 'Ny melding',
      created: 'Opprettet',
      read: 'Lest',
      unread: 'Ikke lest',
      updated: 'Feilmelding oppdatert',
    },
  },
};

export default notifications;
