import React, { useState } from 'react';

import { useAppContext } from 'src/lib/global';
import { Button } from 'src/lib/ui/Button/Button';
import { t, useI18nContext } from 'src/lib/i18n';
import {
  OptionsDropdown,
  OptionsDropdownToggle,
  OptionsDropdownMenu,
  OptionsDropdownItem,
  OptionsDropdownHeader,
  OptionsDropdownDivider,
} from 'src/lib/ui';

import { IdentityInformationModal } from 'src/areas/dashboard/profile/IdentityInformationModal';

export const ProfileDropdown = () => {
  const appContext = useAppContext();
  const i18n = useI18nContext();
  const [open, setOpen] = useState<boolean>(false);
  const [settingsModalOpen, setSettingsModalOpen] = useState<boolean>(false);

  return (
    <>
      <OptionsDropdown isOpen={open} toggle={() => setOpen(!open)}>
        <OptionsDropdownToggle tag="span">
          <Button color="white" icon="user" hideLabel={true} transparent={true}>
            {t.layout.ProfileDropdown.openButton}
          </Button>
        </OptionsDropdownToggle>

        <OptionsDropdownMenu right={true}>
          <OptionsDropdownHeader
            heading={appContext.access.name}
            subheading={appContext.access.email}
            action={
              <Button
                icon="settings"
                size="small"
                hideLabel={true}
                onClick={() => setSettingsModalOpen(true)}
              >
                {t.profile.settings}
              </Button>
            }
          />

          <OptionsDropdownDivider />

          {/* <OptionsDropdownItem
          icon="contact"
          label={t.layout.ProfileDropdown.contactDetails}
        /> */}

          <OptionsDropdownItem
            onClick={i18n.toggleLocale}
            icon="world"
            label={
              i18n.locale === 'en'
                ? t.layout.ProfileDropdown.switchToNorwegian
                : t.layout.ProfileDropdown.switchToEnglish
            }
          />

          <OptionsDropdownItem
            target="_blank"
            href={t.layout.ProfileDropdown.privacyStatementLink}
            rel="noopener"
            icon="security"
            label={t.layout.ProfileDropdown.privacyStatement}
          />

          <OptionsDropdownDivider />

          <OptionsDropdownItem
            onClick={() => appContext.auth.requestSignOut()}
            className="OptionsDropdown-logout"
            label={t.layout.ProfileDropdown.logOut}
            centered={true}
          />
        </OptionsDropdownMenu>
      </OptionsDropdown>

      <IdentityInformationModal
        open={settingsModalOpen}
        toggle={() => setSettingsModalOpen(!settingsModalOpen)}
      />
    </>
  );
};
