import * as React from 'react';
import TeleSubscriptionPage from '../Pages/Telephony/TeleSubscriptionPage';
import GenericSubscriptionPage from '../Pages/Generic/GenericSubscriptionPage';
import MobileSubscriptionPage from '../Pages/Mobile/MobileSubscriptionPage';
import { TvSubscriptionPage } from '../Pages/Tv/TvSubscriptionPage';
import './Summary.scss';
import { useSubscription } from '../queries/useSubscription';
import { useRouter } from 'src/lib/utils/useRouter';
import { DataSubscriptionPage } from '../Pages/Data/DataSubscriptionPage';
import { Loading } from 'src/lib/ui';

export const Summary = () => {
  const router = useRouter<{ id: string }>();
  const { subscription } = useSubscription(router.match.params.id);
  if (!subscription) {
    return <Loading />;
  }
  switch (subscription.group) {
    case 'data':
      return <DataSubscriptionPage subscription={subscription} />;

    case 'mobile':
      return <MobileSubscriptionPage subscription={subscription} />;

    case 'tele':
      return <TeleSubscriptionPage subscription={subscription} />;

    case 'tv':
      return <TvSubscriptionPage />;

    default:
      return <GenericSubscriptionPage subscription={subscription} />;
  }
};
