import gql from 'graphql-tag';
import { useQuery } from 'react-apollo';
import { useCustomer } from 'src/lib/global';
import { OrderFragment } from 'src/lib/types';
import * as t from 'src/areas/main/orders/queries/__types/useOrder';

const WITH_ORDER = gql`
  query useOrder($customerId: ID!, $orderId: ID!) {
    customer(id: $customerId) {
      id
      order(id: $orderId) {
        ... on Order {
          ...OrderSearchResult
        }
      }
    }
  }
  ${OrderFragment}
`;

export const useOrder = (orderId: string) => {
  const customer = useCustomer();

  return useQuery<t.useOrder, t.useOrderVariables>(WITH_ORDER, {
    variables: {
      customerId: customer.id,
      orderId: orderId,
    },
    fetchPolicy: 'network-only',
    errorPolicy: 'all',
  });
};
