import React from 'react';
import { t } from 'src/lib/i18n';
import { StepFlowCard, colors, RadioGroup } from 'src/lib/ui';
import { FormState } from '../editServices/useReplaceContactStepFlow';

export const Step1 = (props: { form: FormState }) => {
  const i18n = t.contacts.DeletePerson.step1;
  return (
    <StepFlowCard
      step={0}
      title={i18n.replaceContactPerson}
      currentStep={props.form.currentStep}
      isValid={props.form.isFieldValid('replace')}
      onClick={() => props.form.setStep(0)}
      onContinue={props.form.nextStep}
      form={<Form {...props.form} />}
    />
  );
};

const Form = (form: FormState) => {
  const i18n = t.contacts.DeletePerson.step1;
  return (
    <>
      <small className="d-block pb-4" style={{ color: colors.greyDarkText }}>
        <div className="pb-2">{i18n.description1}</div>
        <div>
          {i18n.description2(Object.keys(form.values.map ?? {}).length)}
        </div>
      </small>
      <RadioGroup
        block={true}
        className="pb-5"
        name="replace"
        value={form.values.replace}
        options={[
          {
            value: 'all',
            label: i18n.option1,
          },
          {
            value: 'custom',
            label: i18n.option2,
          },
          {
            value: 'none',
            label: i18n.option3,
          },
        ]}
      />
    </>
  );
};
