import React, { useState, useEffect } from 'react';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { colors, Button, DateTimePicker } from 'src/lib/ui';
import moment from 'moment';
import { t } from 'src/lib/i18n';
import { useNetworkTerminationOrder } from '../../mutations/useNetworkTerminationOrder';
import { ChangeSubmitted } from './TechnicalDetailsCard/ChangeSubmitted';
import { getEndOfMonthMonthsAway } from 'src/lib/utils';
import { SubscriptionGroup } from 'src/__types/graphql-global-types';
import { trackTerminateSubscription } from 'src/lib/analytics';

const defaultTerminationPeriod = 3;
const getLastDayThreeMonthsFromNow = () =>
  getEndOfMonthMonthsAway(new Date(), defaultTerminationPeriod);

export const TerminateSubscription = (props: {
  subscription: {
    id: string;
    name: string | null;
    group?: SubscriptionGroup | null;
  };
}) => {
  const [modalOpen, setModalOpen] = useState(false);
  const [date, setDate] = useState(getLastDayThreeMonthsFromNow());
  const { terminateSubscription, submitted } = useNetworkTerminationOrder();
  const i18n = t.subscriptions.terminate;
  const group = props.subscription.group;
  useEffect(() => {
    if (modalOpen) {
      setDate(getLastDayThreeMonthsFromNow());
    }
  }, [modalOpen]);
  const toggle = () => setModalOpen(!modalOpen);
  const submit = () => {
    if (group) trackTerminateSubscription(group);
    terminateSubscription(
      props.subscription.id,
      moment(date).format('YYYY-MM-DD')
    );
  };
  if (props.subscription.name !== 'IP VPN') {
    return null;
  }
  return (
    <div className="mx-2">
      <Button icon="stop" transparent={true} onClick={toggle}>
        {i18n.terminateSubscription}
      </Button>
      <Modal isOpen={modalOpen} toggle={toggle}>
        {submitted ? (
          <ModalBody style={{ paddingTop: '5rem' }}>
            <ChangeSubmitted
              heading={i18n.serviceTerminated}
              submittedDescription={i18n.submittedDescription}
              goBackLabel={i18n.close}
              onGoBack={toggle}
            />
          </ModalBody>
        ) : (
          <>
            <ModalHeader>{i18n.terminateSubscription}</ModalHeader>
            <ModalBody>
              <small className="d-block pb-4">{i18n.description}</small>
              <small className="d-block pb-4">
                <div className="pb-4">
                  {i18n.invoicePeriodDescription(
                    moment(getEndOfMonthMonthsAway(new Date(), 0)).format(
                      'DD MMM YYYY'
                    )
                  )}
                </div>
                {i18n.chooseDateForShutdown}
              </small>
              <div className="pb-4">
                <small className="d-block grey-dark-text">
                  {i18n.dateForShutdown}
                </small>
                <DateTimePicker
                  hideTime={true}
                  readOnly={true}
                  value={date}
                  onChange={setDate}
                  isOutsideRange={day => !!moment(day).isBefore(moment())}
                />
              </div>
              <small
                className="d-block p-3"
                style={{
                  backgroundColor: colors.background,
                  borderRadius: '0.5rem',
                }}
              >
                {i18n.confirmationDescription}
              </small>
            </ModalBody>
            <ModalFooter>
              <Button base="primary" onClick={toggle}>
                {i18n.goBack}
              </Button>
              <Button base="primary" color="white" onClick={submit}>
                {i18n.completeTermination}
              </Button>
            </ModalFooter>
          </>
        )}
      </Modal>
    </div>
  );
};
