import * as React from 'react';
import { MobilePeriodDetails } from 'src/areas/mobile/types';
import { StepsMeta } from 'src/lib/charts';
import { BodyCard, Toolbar } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import OrderTerminateMobileSubscription from 'src/areas/main/ordering/OrderTerminateMobileSubscription/OrderTerminateMobileSubscription';
import OrderingModal from 'src/areas/main/ordering/layout/OrderingModal';
import { useSubscriptionContext } from 'src/areas/mobile/lib/SubscriptionContext';
import SummaryStepModel from 'src/areas/mobile/overview/cards/SummaryStepModel';
import SummaryGeneric from 'src/areas/mobile/overview/cards/SummaryGeneric';

interface Props {
  subscription: MobilePeriodDetails;
  stepsMeta?: StepsMeta;
  hideToolbar?: boolean;
}

export const Summary: React.SFC<Props> = ({
  subscription,
  hideToolbar,
  stepsMeta,
}) => {
  const i18n = t.mobile.overview.subscription;
  const { id, disableEdit } = useSubscriptionContext();
  return (
    <BodyCard borders={true}>
      <h2>{i18n.aboutSubscription}</h2>
      {stepsMeta ? (
        <SummaryStepModel subscription={subscription} stepsMeta={stepsMeta} />
      ) : (
        <SummaryGeneric subscription={subscription} />
      )}

      {!hideToolbar && !disableEdit && (
        <Toolbar>
          <OrderTerminateMobileSubscription msisdn={id}>
            {terminateMobileSub => (
              <OrderingModal
                title={terminateMobileSub.header}
                disableSubmitButton={!terminateMobileSub.isFormValid}
                confirmButtonLabel={terminateMobileSub.submitLabel}
                submit={terminateMobileSub.submit}
                btnStyle="white"
              >
                {terminateMobileSub.render}
              </OrderingModal>
            )}
          </OrderTerminateMobileSubscription>
        </Toolbar>
      )}
    </BodyCard>
  );
};
