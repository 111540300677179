import React, { useEffect, useState } from 'react';
import ModalHeader from 'reactstrap/lib/ModalHeader';
import Modal from 'reactstrap/lib/Modal';
import ModalBody from 'reactstrap/lib/ModalBody';
import './DeleteContactModal.scss';
import ModalFooter from 'reactstrap/lib/ModalFooter';
import { t } from 'src/lib/i18n';
import { useRouter } from 'src/lib/utils/useRouter';
import { Icon } from '@telia/styleguide';
import { useDeletePerson } from '../../mutations/useDeletePerson';
import { Button, FormSubmitBar, colors } from 'src/lib/ui';
import { useFeature } from 'src/lib/utils';

interface Props {
  contactId: string;
  contactName: string;
  isUser: boolean;
}

export const DeletePersonConfirmationModal = (props: Props) => {
  const { deletePerson, data, loading, error, errorCode } = useDeletePerson();
  const [open, setOpen] = useState(false);
  const disableDeleteContact = useFeature('disable-delete-contact');
  const router = useRouter();
  const i18n = t.contacts.DeletePerson;
  const displayName =
    props.contactName.length > 1 ? props.contactName : i18n.contact;

  useEffect(() => {
    if (data && !error) {
      router.history.push('/kontakter');
    }
  }, [data, error]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      {disableDeleteContact ? (
        <div
          style={{ color: colors.greyDarkText }}
          className="d-block float-right"
        >
          {i18n.deleteIsTemporaryDisabled}
        </div>
      ) : (
        <Button
          icon="trash"
          className="d-block ml-auto"
          color="white"
          transparent={true}
          onClick={() => setOpen(!open)}
        >
          {i18n.delete}
        </Button>
      )}
      <Modal
        className="DeleteContactModal"
        size="md"
        isOpen={open}
        toggle={() => setOpen(!open)}
      >
        <ModalHeader>{i18n.title(displayName)}</ModalHeader>
        <ModalBody className="container">
          {error ? (
            <div className="d-flex flex-column align-items-center">
              <div
                className="DeleteContactModal text-danger"
                style={{ fontSize: '40px' }}
              >
                <Icon icon="alert" />
              </div>
              <span className="DeleteContactModal--text py-2">
                {errorCode === 'ContactOnActiveCase'
                  ? i18n.contactOnActiveCaseDeleteError(displayName)
                  : i18n.error(displayName)}
              </span>
            </div>
          ) : (
            <div className=" d-flex flex-column">
              <span className="DeleteContactModal--text py-2">
                {props.isUser
                  ? i18n.deletionWarningUser(displayName)
                  : i18n.deletionWarningContact(displayName)}
              </span>
            </div>
          )}
        </ModalBody>
        <ModalFooter>
          <FormSubmitBar
            submitLabel={i18n.btnDelete}
            loading={loading}
            cancel={() => setOpen(!open)}
            submit={() => deletePerson(props.contactId)}
          />
        </ModalFooter>
      </Modal>
    </>
  );
};
