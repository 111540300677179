import React from 'react';
import { Attribute, Card, colors } from 'src/lib/ui';
import { formatPhoneNumber, formatName } from 'src/lib/utils';

interface Props {
  contact?: {
    id: string;
    firstName: string | null;
    lastName: string | null;
    email: string | null;
    mobilePhone: string | null;
    secondaryPhone: string | null;
    description: string | null;
  } | null;
}

export const TechnicalContactCard: React.FC<Props> = (props: Props) => {
  const { contact } = props;
  if (!contact) {
    return null;
  }
  return (
    <Card className="TechnicalContactCard  p-2" transparent={true}>
      <div style={{ color: colors.black }}>
        {formatName({
          firstName: contact.firstName,
          lastName: contact.lastName,
        })}
      </div>

      {contact.description && (
        <Attribute className="font-italic" value={contact.description} />
      )}

      {contact.email && (
        /** Email can be very long, so allow wordbreak */
        <Attribute value={contact.email} style={{ wordBreak: 'break-word' }} />
      )}

      {contact.mobilePhone && (
        <Attribute value={formatPhoneNumber(contact.mobilePhone)} />
      )}

      {contact.secondaryPhone && (
        <Attribute value={formatPhoneNumber(contact.secondaryPhone)} />
      )}
    </Card>
  );
};

export default TechnicalContactCard;
