import React from 'react';
import { StandardStatisticsPeriod } from '../../../queries/WithStatistics';
import { colors } from 'src/lib/ui';
import {
  CustomTooltipContainer,
  Header,
  Section,
  SectionItem,
} from 'src/lib/charts/Recharts/CustomTooltip/CustomTooltip';
import { Area } from 'recharts';
import { t } from 'src/lib/i18n';
import { formatNordic } from 'src/lib/utils';

export const CumulativeChartElements = () => [
  <Area
    key="1"
    stroke={colors.corePurple}
    strokeWidth="1"
    fill={colors.corePurple}
    fillOpacity="0.2"
    dataKey="cumulativeTraffic.in"
    name={t.statistics.Legend.trafficIn}
  />,
  <Area
    key="2"
    stroke={colors.blueDark}
    strokeWidth="1"
    fill={colors.blueDark}
    fillOpacity="0.2"
    dataKey="cumulativeTraffic.out"
    name={t.statistics.Legend.trafficOut}
  />,
];

/**
 * Area filled dot
 * Line circle
 */
export const CumulativeTooltip = ({
  data,
  hideTimestamp,
  showCumulativeInMB,
}: {
  data: StandardStatisticsPeriod;
  hideTimestamp: boolean;
  showCumulativeInMB?: boolean;
}) => {
  const i18n = t.statistics.StandardDetails;
  return (
    <CustomTooltipContainer>
      <Header data={data.periodStart} hideTimestamp={!!hideTimestamp} />
      <Section header={i18n.in}>
        <SectionItem
          title={i18n.cumulativeAvg}
          color={colors.corePurple}
          value={
            data.cumulativeTraffic?.in
              ? `${formatNordic(data.cumulativeTraffic.in, 2)} ${
                  showCumulativeInMB ? 'MB' : 'GB'
                }`
              : t.statistics.noData
          }
        />
      </Section>
      <Section header={i18n.out}>
        <SectionItem
          title={i18n.cumulativeAvg}
          color={colors.blueDark}
          value={
            data.cumulativeTraffic?.out
              ? `${formatNordic(data.cumulativeTraffic.out, 2)} ${
                  showCumulativeInMB ? 'MB' : 'GB'
                }`
              : t.statistics.noData
          }
        />
      </Section>
    </CustomTooltipContainer>
  );
};
