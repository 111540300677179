import * as React from 'react';
import { ServicePlusCard } from 'src/areas/main/servicePlus/ServicePlusCard';
import cs from 'classnames';
import { NewAddressModal } from 'src/areas/main/servicePlus/NewAddressModal';
import { useState } from 'react';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';

interface Props {
  className?: string;
  style?: React.CSSProperties;
}

export function NewAddressCard(props: Props) {
  const translations = t.servicePlus;
  const [newLocationModalOpen, setNewLocationModalOpen] = useState(false);

  function toggleNewLocationModal() {
    setNewLocationModalOpen(!newLocationModalOpen);
  }
  return (
    <div
      onClick={() => setNewLocationModalOpen(true)}
      style={{ cursor: 'pointer' }}
    >
      <ServicePlusCard
        className={cs('NewAddressCard', props.className)}
        style={props.style}
        onClick={toggleNewLocationModal}
      >
        <Icon
          icon="add"
          style={{ color: '#9b009b', width: '2rem', height: '2rem' }}
        />
        <p>{translations.newLocation.addNewLocation}</p>
      </ServicePlusCard>
      <NewAddressModal
        isOpen={newLocationModalOpen}
        setOpen={setNewLocationModalOpen}
      />
    </div>
  );
}
