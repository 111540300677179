import { push } from './Analytics';

export type IncidentsActions =
  | 'update_metadata'
  | 'mark_solved'
  | 'update_view'
  | 'change_rows';

export function trackMetadata(metadataType: string) {
  push({
    category: 'Incidents',
    action: 'update_metadata',
    label: metadataType,
  });
}

export function trackMarkAsSolved() {
  push({
    category: 'Incidents',
    action: 'mark_solved',
  });
}

/* export function trackIncidentsPresentation(presentation: string) {
  push({
    ...customEvent,
    action: 'update_view',
    label: presentation,
  });
}

export function trackNumberOfRows(numberOfRows: string) {
  push({
    ...customEvent,
    action: 'change_rows',
    label: numberOfRows,
  });
} */
