import * as React from 'react';
import { StepFlowProps, StepFlow, StepFlowStorage } from '.';
import { ConsumeQueryParameters } from 'src/lib/ui';
import { generateUeid } from 'src/lib/utils';
import { FormikTouched } from 'formik';
// import { StepFlow } from 'src/lib/flow/StepFlow';
// const StepFlow = require('lib/flow/StepFlow').default;

// type Diff<T extends string, U extends string> = ({[P in T]: P } & {[P in U]: never } & { [x: string]: never })[T];
// type Omit<T, K extends keyof T> = Pick<T, Diff<keyof T, K>>;

export type Omit<T, K extends keyof T> = Pick<T, Exclude<keyof T, K>>;

type ProcessProps<T> = { url: string; setFlowId?: (id: string) => void } & Omit<
  StepFlowProps<T>,
  'persistValues' | 'removePersistedValues'
>;

export class Process<T = {}> extends React.Component<ProcessProps<T>, {}> {
  render() {
    return (
      <ConsumeQueryParameters removeFromUrl={false} refreshOnUrlChange={true}>
        {queryParams => {
          return (
            <StepFlowStorage<T>>
              {flows => {
                let initialVals: T = this.props.initialValues
                  ? this.props.initialValues
                  : ({} as T);
                let initialStep: number | undefined;
                let initialChangeSequence: number | undefined;
                let id: string;
                let initialTouched: FormikTouched<T> | undefined;

                if (
                  queryParams.continue &&
                  typeof queryParams.continue === 'string'
                ) {
                  id = queryParams.continue;
                  const initial = flows.load(queryParams.continue);
                  if (initial) {
                    initialVals = {
                      ...initialVals,
                      ...initial.values,
                    };
                    initialStep = initial.currentStep;
                    initialChangeSequence = initial.changeSequence;
                    initialTouched = initial.touched;
                  }
                } else {
                  // If this is a new flow, we generate an ID for it.
                  id = generateUeid();
                }

                return (
                  <>
                    <StepFlow<T>
                      {...this.props}
                      initialValues={initialVals}
                      initialTouched={initialTouched}
                      initialStep={initialStep}
                      initialChangeSequence={initialChangeSequence}
                      persistValues={async (
                        values: T,
                        currentStep: number,
                        touched: FormikTouched<T>
                      ) => {
                        await flows.updateValues(
                          id,
                          values,
                          this.props.url,
                          currentStep,
                          touched
                        );
                        if (this.props.setFlowId) {
                          this.props.setFlowId(id);
                        }
                      }}
                      removePersistedValues={async () => {
                        await flows.remove(id);
                      }}
                    />
                  </>
                );
              }}
            </StepFlowStorage>
          );
        }}
      </ConsumeQueryParameters>
    );
  }
}
