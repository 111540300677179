import {
  StandardStatisticsPeriod,
  StandardStatisticsBandwidth,
} from '../../queries/WithStatistics';
import {
  convertBitsToMegabits,
  convertByteToGigaByte,
  convertByteToMegaByte,
} from '../../utils/convertUnit';
import moment from 'moment';

/**
 * All fields can be "null", so we do a null-check on all fields before mapping.
 */
export const mapStandardData = (
  data?: StandardStatisticsPeriod[] | null,
  serviceBandwidth?: StandardStatisticsBandwidth | null,
  cumulativeTotalIn?: number | null,
  cumulativeTotalOut?: number | null
) => {
  if (!data) {
    return null;
  }

  const showCumulativeInMB =
    isLowerThan1GB(cumulativeTotalIn ?? 0) &&
    isLowerThan1GB(cumulativeTotalOut ?? 0);

  return data.map(x => {
    const throughputIn = x.throughput?.in
      ? convertBitsToMegabits(x.throughput.in)
      : null;
    const throughputOut = x.throughput?.out
      ? convertBitsToMegabits(x.throughput.out)
      : null;
    const throughputMaxIn = x.throughput?.maxIn
      ? convertBitsToMegabits(x.throughput.maxIn)
      : null;
    const throughputMaxOut = x.throughput?.maxOut
      ? convertBitsToMegabits(x.throughput.maxOut)
      : null;

    return {
      ...x,
      periodStart: moment(x.periodStart).valueOf(),
      periodEnd: moment(x.periodEnd).valueOf(),
      throughput: {
        ...x.throughput,
        in: throughputIn,
        out: throughputOut,
        maxIn: throughputMaxIn,
        maxOut: throughputMaxOut,
      },
      // GB
      cumulativeTraffic: {
        ...x.cumulativeTraffic,
        in: x.cumulativeTraffic?.in
          ? showCumulativeInMB
            ? convertByteToMegaByte(x.cumulativeTraffic.in)
            : convertByteToGigaByte(x.cumulativeTraffic.in)
          : null,
        out: x.cumulativeTraffic?.out
          ? showCumulativeInMB
            ? convertByteToMegaByte(x.cumulativeTraffic.out)
            : convertByteToGigaByte(x.cumulativeTraffic.out)
          : null,
      },
      utilization:
        serviceBandwidth &&
        serviceBandwidth.downstream &&
        serviceBandwidth.upstream
          ? {
              ...x.utilization,
              in: throughputIn
                ? (throughputIn / serviceBandwidth.downstream) * 100
                : undefined,
              out: throughputOut
                ? (throughputOut / serviceBandwidth.upstream) * 100
                : undefined,
              maxIn: throughputMaxIn
                ? (throughputMaxIn / serviceBandwidth.upstream) * 100
                : undefined,
              maxOut: throughputMaxOut
                ? (throughputMaxOut / serviceBandwidth.upstream) * 100
                : undefined,
            }
          : null,
    } as StandardStatisticsPeriod;
  });
};

export const isLowerThan1GB = (number: number) => number < 1000000000;
