import React from 'react';
import { t } from 'src/lib/i18n';
import { Icon } from '@telia/styleguide';
import { Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Button } from 'src/lib/ui';
import { trackSettings } from 'src/lib/analytics';

export const CHANGE_PASSWORD =
  'https://identity.telia.no/realms/telia/account/password';
export const CHANGE_AUTHENTICATION =
  'https://identity.telia.no/realms/telia/account/totp';

export const IdentityInformationModal = (props: {
  open: boolean;
  toggle: () => void;
}) => {
  const i18n = t.profile.identityInformation;
  if (props.open) {
    return (
      <Modal isOpen={props.open} toggle={props.toggle}>
        <ModalHeader>{i18n.lookingForUserSettings}</ModalHeader>
        <ModalBody>
          <div className="pb-5">
            <div className="pb-2">{i18n.changePassword}</div>
            <small>
              <div className="pb-2 grey-dark-text">
                {i18n.changePasswordInfo}
              </div>
              <a
                className="font-weight-bold"
                href={CHANGE_PASSWORD}
                onClick={() => trackSettings('password')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.clickToChangePassword}
                <Icon
                  className="ml-2"
                  style={{ height: '1rem', width: '1rem' }}
                  icon="open"
                />
              </a>
            </small>
          </div>

          <div className="pb-5">
            <div className="pb-2">{i18n.changeMFA}</div>
            <small>
              <div className="pb-2 grey-dark-text">{i18n.changeMFAInfo}</div>
              <a
                className="font-weight-bold"
                href={CHANGE_AUTHENTICATION}
                onClick={() => trackSettings('mfa')}
                target="_blank"
                rel="noopener noreferrer"
              >
                {i18n.clickToChangeMFA}
                <Icon
                  className="ml-2"
                  style={{ height: '1rem', width: '1rem' }}
                  icon="open"
                />
              </a>
            </small>
          </div>

          <div className="pb-4">
            <div className="pb-2">{i18n.changeEmailAddress}</div>
            <small className="grey-dark-text">
              <div className="pb-3">{i18n.notPossible}</div>
              <div>{i18n.contactCustomerService}</div>
            </small>
          </div>
        </ModalBody>
        <ModalFooter>
          <Button base="primary" color="purple" onClick={props.toggle}>
            {i18n.close}
          </Button>
        </ModalFooter>
      </Modal>
    );
  }
  return null;
};
