import { useEffect, useState } from 'react';
import { useReplaceTechnicalContact } from 'src/areas/main/subscriptions/mutations/useReplaceTechnicalContact';
import { Contact } from 'src/lib/types';
import { useDeletePerson } from '../../mutations/useDeletePerson';
import { useRouter, Form, useForm } from 'src/lib/utils';

interface Props {
  /**
   * All subscription the user is tecnical contact person for
   */
  technicalContactSubscriptions: Array<{
    id: string;
    name: string;
    address: string;
    type: string;
  }>;

  /**
   * Wheter the contact should be deleted after changes have been made
   */
  deleteContact: boolean;
}

/**
 * Wheter to replace all subscription with on single contact,
 * remove all contact resonsibilites
 * or set new contact person for each service
 */
type Replace = 'all' | 'none' | 'custom';
export interface Values {
  map: ContactMap;
  replaceAllContact?: Contact;
  replace: Replace;
  initialContactId: string;
}

interface ContactMap {
  [subscriptionId: string]: {
    technicalContact: Contact;
    id: string;
    name: string;
    address: string;
    type: string;
  };
}

export type FormState = Form<Values>;

const generateSubscriptionContactMap = (
  technicalContactSubscriptions: Array<{
    id: string;
    name: string;
    address: string;
    type: string;
  }>
): ContactMap => {
  const initialValues = {};
  technicalContactSubscriptions.forEach(
    s =>
      (initialValues[s.id] = {
        name: s.name,
        address: s.address,
        type: s.type,
      })
  );
  return initialValues;
};

/**
 * This hook handels all common top level logic for replacing contact responsibilities
 * in the StepFlow for deleting a user and in the StepFlow for changing contact responsibilities.
 * It lets you cutomize the steps without having to reimplement stateful logic and mutations.
 */
export const useReplaceContactStepFlow = (props: Props) => {
  const [open, setOpen] = useState(false);
  const router = useRouter<{ id: string }>();
  const [isReplacing, setIsReplacing] = useState(false);

  const map = generateSubscriptionContactMap(
    props.technicalContactSubscriptions
  );

  const subsIds = props.technicalContactSubscriptions.map(s => s.id);
  const contactId = router.match.params.id;

  const form = useForm<Values>({
    initialValues: {
      map,
      replace: 'all' as 'all',
      initialContactId: contactId,
      replaceAllContact: undefined,
    },
    validations: {
      replace: v => !!v.replace,
      replaceAllContact: v =>
        v.replace === 'all' ? !!v.replaceAllContact : true,
    },
  });
  const { deletePerson, ...deleteResult } = useDeletePerson();
  const {
    replaceAllTechnicalContact,
    replaceTechnicalContact,
    ...replaceResult
  } = useReplaceTechnicalContact(contactId, subsIds);
  const loading = replaceResult.loading || deleteResult.loading;

  useEffect(() => {
    if (
      !loading &&
      !isReplacing &&
      replaceResult.data &&
      !replaceResult.error
    ) {
      props.deleteContact ? deletePerson(contactId, subsIds) : setOpen(false);
    }
  }, [
    replaceResult.data,
    replaceResult.error,
    deletePerson,
    contactId,
    isReplacing,
    loading,
    props.deleteContact,
    subsIds,
  ]);

  useEffect(() => {
    if (deleteResult.data && !deleteResult.error) {
      router.history.push('/kontakter');

      // 13.12.2019:  temporary fix to force clearing of cache --  should be refactored when we move to Apollo 3.0
      // window.location.reload();
    }
  }, [deleteResult.data, deleteResult.error, router.history]);

  const replaceTechnicalContacts = (values: Values) => {
    if (values.replace === 'none') {
      if (props.deleteContact) {
        deletePerson(values.initialContactId, subsIds);
      } else {
        replaceAllTechnicalContact(undefined);
      }
    }
    if (values.replace === 'all') {
      const newContact = values.replaceAllContact;
      replaceAllTechnicalContact(newContact);
    }
    if (values.replace === 'custom') {
      const options = Object.entries(values.map);
      setIsReplacing(true);
      Promise.all(
        options.map(([subsId, contact]) =>
          replaceTechnicalContact(subsId, contact.technicalContact)
        )
      ).then(() => setIsReplacing(false));
    }
  };

  return {
    form,
    toggle: () => setOpen(!open),
    replaceTechnicalContacts,
    open,
    loading: loading || isReplacing,
    error: deleteResult.error || replaceResult.error,
  };
};
