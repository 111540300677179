import React, { useState, useEffect } from 'react';
import { Button } from 'src/lib/ui/Button/Button';
import NotificationCard from './Notification';
import DropdownItem from 'reactstrap/lib/DropdownItem';
import { t } from 'src/lib/i18n';
import './NotificationDropdown.scss';
import { getUrlForNotification } from './utils';
import { MarkNotificationRead } from './mutation/MarkNotificationRead';
import { ApolloQueryResult } from 'apollo-client';
import { useNotifications } from './query/__types/useNotifications';
import { Notification } from './query/useNotifications';
import { useRouter } from 'src/lib/utils/useRouter';
import { Notification as NotificationType } from './query/useNotifications';

import {
  OptionsDropdown,
  OptionsDropdownMenu,
  OptionsDropdownItem,
  OptionsDropdownToggle,
  OptionsDropdownDivider,
  OptionsDropdownHeader,
} from 'src/lib/ui';

import { trackNotifications } from 'src/lib/analytics';

interface Props {
  notifications?: Notification[];
  totalResults?: number;
  refetch?: () => Promise<ApolloQueryResult<useNotifications>>;
}

interface NotificationItemProps {
  notification: NotificationType;
  onClick?: React.MouseEventHandler<any>;
}

const NotificationItem = (props: NotificationItemProps) => (
  <>
    <OptionsDropdownItem onClick={props.onClick}>
      <NotificationCard notification={props.notification} />
    </OptionsDropdownItem>
    <OptionsDropdownDivider />
  </>
);

const NotificationDropdown: React.FC<Props> = props => {
  const [open, setOpen] = useState(false);
  const router = useRouter();
  const translate = t.notifications.NotificationBell;

  useEffect(() => {
    if (open) trackNotifications();
  }, [open]);

  return (
    <MarkNotificationRead>
      {markNotificatonContext => {
        const onClickNotifications = async (notification: Notification) => {
          setOpen(false);
          if (props.refetch) {
            props.refetch();
          }
          if (!notification.read) {
            await markNotificatonContext.markNotificationRead(notification.id);
          }
          router.history.push(getUrlForNotification(notification));
        };

        return (
          <OptionsDropdown
            className="NotificationDropdown"
            isOpen={open}
            toggle={() => setOpen(!open)}
            direction="down"
          >
            <OptionsDropdownToggle
              className="NotificationDropdown-bell"
              tag="span"
            >
              <Button
                icon="alarm"
                color="white"
                hideLabel={true}
                transparent={true}
              >
                {translate.btnDropdown}
              </Button>

              {!!props.totalResults && (
                <span className="NotificationDropdown-count">
                  {props.totalResults}
                </span>
              )}
            </OptionsDropdownToggle>

            <OptionsDropdownMenu right={true}>
              <OptionsDropdownHeader
                heading="Varslinger"
                action={
                  <Button
                    aria-label={translate.notificationPreferences}
                    size="small"
                    color="dark"
                    transparent={true}
                    icon="settings"
                    className="d-block ml-auto"
                    to="/dashboard/varslingsinnstillinger"
                    hideLabel={true}
                  />
                }
              />

              {props.notifications?.length ? (
                <div className="NotificationDropdown-notifications">
                  {props.notifications.map((notification, ix) => (
                    <NotificationItem
                      key={ix}
                      onClick={() => onClickNotifications(notification)}
                      notification={notification}
                    />
                  ))}
                </div>
              ) : (
                <DropdownItem disabled={true} className="text-center py-4">
                  <small> {translate.noUnreadNotifications} </small>
                </DropdownItem>
              )}

              <OptionsDropdownDivider />

              <OptionsDropdownItem
                centered={true}
                aria-label={translate.btnSeeAll}
                onClick={() => router.history.push('/varslinger')}
                label={translate.btnSeeAll}
              />
            </OptionsDropdownMenu>
          </OptionsDropdown>
        );
      }}
    </MarkNotificationRead>
  );
};

export default NotificationDropdown;
