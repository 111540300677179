import { t } from 'src/lib/i18n';
import { NotificationType } from 'src/__types/graphql-global-types';

export const mapNotificationTypeToLabel = (
  type: NotificationType
): { label: string; description: string } => {
  const translate = t.notifications;
  let label: string;
  let description: string;
  switch (type) {
    case 'incidentCreate':
      label = translate.incidentCreate.label;
      description = translate.incidentCreate.description;
      break;
    case 'incidentComment':
      label = translate.incidentComment.label;
      description = translate.incidentComment.description;
      break;
    case 'incidentStatus':
      label = translate.incidentStatus.label;
      description = translate.incidentStatus.description;
      break;
    case 'incidentChange':
      label = translate.incidentChange.label;
      description = translate.incidentChange.description;
      break;
    case 'plannedWorkCreate':
      label = translate.plannedWork.label;
      description = translate.plannedWork.description;
      break;

    default:
      label = translate.default.label;
      description = translate.default.description;
      break;
  }
  return { label, description };
};

export { allowAllNotifications } from './allowAllNotifications';

export const getUrlForNotification = (notification: {
  order?: { id: string } | null;
  incident?: { id: string } | null;
  subscriptions?: Array<{ id: string } | null> | null;
  notificationType?: string | null;
}): string => {
  if (notification.incident) {
    return `/meldinger/${notification.incident.id}`;
  }
  if (notification.order) {
    return `/ordre/${notification.order.id}`;
  }
  if (notification.subscriptions) {
    /** Where should this lead if there are several subscriptions? */
    return `/tjenester/${notification.subscriptions[0]}`;
  }
  if (notification.notificationType === 'plannedWorkCreate') {
    /** Where should this lead if there are several subscriptions? */
    return `/tjenester/alle?cases=withPlannedWork`;
  } else {
    /** make pages for notifications? /varslinger/${notification.id} */
    return `/varslinger`;
  }
};

export const getUnreadNotifications = (
  notifications?: Array<{ read: boolean }>
) =>
  notifications
    ? notifications.filter(notification => notification.read === false)
    : [];
