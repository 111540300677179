import { Subscription } from 'src/lib/types';
import { get } from 'lodash';
import { t } from 'src/lib/i18n';
import { CpeStatus } from 'src/__types/graphql-global-types';

export const getLabelFromCpeStatus = (
  status?: CpeStatus | null,
  hasStatus?: boolean | null
) => {
  /**
   * Not all subscriptions has monitoring. Ignore status and return empty string because no status should be shown.
   */
  if (hasStatus === false) {
    return '';
  }

  const translate = t.subscriptions.cpeStatus;
  switch (status) {
    case CpeStatus.NoStatus:
      return translate.noStatus;
    case CpeStatus.NotInstalled:
      return translate.notInstalled;
    case CpeStatus.Normal:
      return translate.normal;
    // This status should not exist, but still an existing value in GQL. If for some reason this is returned, we still have to handle it.
    case CpeStatus.Unknown:
      return translate.unknown;
    case CpeStatus.Critical:
      return translate.critical;
    default:
      return translate.statusNull;
  }
};

export const getRedundancyStatusLabel = (
  cpeStatus: CpeStatus | undefined | null
) => {
  const i18n = t.subscriptions.cpeStatus;
  switch (cpeStatus) {
    case CpeStatus.Critical:
      return i18n.redundancyOffline;
    case CpeStatus.Normal:
      return i18n.redundancyOnline;
    default:
      return getLabelFromCpeStatus(cpeStatus);
  }
};

export const getCpeStatus: (
  subscription: Subscription
) => CpeStatus | null | undefined = subscription =>
  get(subscription, 'cpe.status');

export const hasServerError = (subscription: Subscription) => {
  const status = getCpeStatus(subscription);
  return status === CpeStatus.Critical;
};

export const getHoursAndMinutesFromMinutes = (minutes: number) => {
  const hours = minutes / 60;
  const remainingHours = Math.floor(hours);
  const remainingMinutes = minutes % 60;
  return [remainingHours, remainingMinutes];
};

export const getMillisecondsFromUptime = (uptime: string) =>
  parseInt(uptime.slice(1, uptime.indexOf(')'))) * 10;

export const formatDurationString = (
  uptime: {
    days: number;
    hours: number;
    minutes: number;
    sec: number;
  },
  timeStrings: {
    days: string;
    day: string;
    hours: string;
    hour: string;
    minutes: string;
    minute: string;
    second: string;
  }
) => {
  let uptimeString = '';
  if (uptime.days) {
    uptimeString = uptimeString.concat('' + uptime.days + ' ');
    uptimeString = uptimeString.concat(
      uptime.days === 1 ? timeStrings.day : timeStrings.days,
      ', '
    );
  }

  if (uptime.days || uptime.hours) {
    uptimeString = uptimeString.concat('' + uptime.hours + ' ');
    uptimeString = uptimeString.concat(
      uptime.hours === 1 ? timeStrings.hour : timeStrings.hours,
      ', '
    );
  }

  if (uptime.days || uptime.days || uptime.minutes) {
    uptimeString = uptimeString.concat('' + uptime.minutes + ' ');
    uptimeString = uptimeString.concat(
      uptime.minutes === 1 ? timeStrings.minute : timeStrings.minutes,
      ', '
    );
  }

  uptimeString = uptimeString.concat('' + uptime.sec + ' ');
  uptimeString = uptimeString.concat(timeStrings.second);

  return uptimeString;
};
