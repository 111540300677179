import * as React from 'react';
import { RouteComponentProps, Switch, Route, Redirect } from 'react-router';
import ReportIncidentSection from './ReportIncidentSection';
import { Summary } from './summary/Summary';
import { SubscriptionsListContainer } from 'src/areas/main/subscriptions/SubscriptionsListContainer';

// prettier-ignore
const Subscriptions: React.SFC<RouteComponentProps<{}>> = ({ match }) => (
  <Switch>
    <Route path={`${match.path}/:id([a-zA-Z]?[0-9]+)/meld-feil`} component={ReportIncidentSection} />
    <Route path={`${match.path}/:id([a-zA-Z]?[0-9]+)`} component={Summary} />
    <Route path="/tjenester/tag" render={() => <Redirect to="/tjenester/tags" />} />
    <Route path="/tjenester/tele" render={() => <Redirect to="/tjenester/telefoni" />} />
    <Route path="/tjenester/data" render={() => <Redirect to="/tjenester/nettverk" />} />
    <Route path="/tjenester/:group([a-z]+)?" component={SubscriptionsListContainer} />
  </Switch>
);

export default Subscriptions;
