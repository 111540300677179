const incidents = {
  listTitle: 'Feilmeldinger',
  title: 'Feilmelding',
  noIncidents: 'Ingen feilmeldinger',

  subscriptionsFilter: {
    lable: 'Tjenester',
    placeholder: 'Filtrer på tjenester',
  },
  sortFields: {
    lable: 'Sorter',
    opened: 'Opprettelsesdato',
    updated: 'Sist endret',
    created: 'Opprettet',
    id: 'ID',
    status: 'Status',
    subscriptionId: 'Tjeneste id',
    subscriptionName: 'Tjeneste navn',
    subscriptionAlias: 'Tjeneste alias',
    mostRecent: 'nyeste først',
    oldFirst: 'eldste først',
  },
  tabs: {
    active: 'Aktive',
    history: 'Historikk',
  },
  table: {
    status: 'Status',
    created: 'Opprettet',
    updated: 'Sist endret',
    id: 'ID',
    details: 'Detaljer',
    subscription: 'Tjeneste',
    solved: 'Løst',
  },
  showXofY: (showing: number, total?: number) =>
    `Viser ${showing} av ${total} feilmeldinger`,
  status: {
    label: 'Status',
    placeholder: 'Filtrer på status',
    solved: 'Løst',
    canceled: 'Avbrutt',
    closed: 'Lukket',
    onHoldPendingTelia: 'Venter på Telia',
    onHoldPendingCustomer: (customerName?: string) =>
      `Venter på ${customerName || 'kunde'}`,
    fallback: 'Ukjent',
  },
  contact: 'kontakt',

  reportIncident: {
    notifyMeAboutNotifications: 'Varsle meg om oppdateringer i feilmeldingen',
    sendNotificationsByEmail:
      'Send varslinger på e-post til alle følgere av feilmeldingen',
    background: 'Bakgrunn',
    reportIncident: 'Meld feil',
    noContact: {
      text:
        'Du må være oppført som en kontakt for å rapportere feil på denne tjenesten.',
    },
    pickErrorDescription: 'Velg det som best beskriver tjenesten nå',
    step1: {
      whatHappened: 'Fortell hva som har skjedd',
      description:
        'En detaljert beskrivelse gjør at vi kan hjelpe deg raskere. Nevn gjerne om det har vært strømbrudd, og hvilket utstyr som er påvirket.',
    },
    step2: {
      additionalInformation: 'Tilleggsinformasjon',
      customereRefTooLong: 'For langt referansenummer',
      notificationHeading: 'Varslinger',
      notificationDescription:
        'Her kan du legge til kontakter som må varsles i saken. Kontaktene blir varslet på e-post/SMS ut i fra instillinger de selv har satt. ',
    },
    incidentTypes: {
      connectionDown: {
        title: 'Tjenesten er nede',
        helpText: 'Når tilkoblingen ikke fungerer.',
      },
      connectionUnstable: {
        title: 'Tjenesten faller ut i perioder',
        helpText: 'Når du opplever at tjenesten er ustabil.',
      },
      connectionSlow: {
        title: 'Tjenesten er treg',
        helpText: 'Når du opplever at hastigheten til tjenesten er redusert.',
      },
      otherServiceError: {
        title: 'Noe annet er galt',
        subTitle: 'Fortell hva som har skjedd',
        helpText:
          'En detaljert beskrivelse gjør at vi kan hjelpe deg raskere. ' +
          'Fortell gjerne om utstyr har blitt restartet, ' +
          'om dere har opplevd strømbrudd på lokasjonen, ' +
          'hvilken lampestatus som vises på utstyret og ' +
          'hvem som er lokal kontaktperson hos dere for tjenesten.',
      },
    },
    btnReportIncident: 'Fullfør og Send',
    errorMsg: 'Kunne ikke opprette feilmelding. Prøv igjen senere',
  },
  IncidentFileUploader: {
    title: 'Vedlegg',
    description: 'Last opp bilder eller andre vedlegg',
    btnAdd: 'Last opp vedlegg',
  },
  IncidentActions: {
    markSolved: 'Merk som løst',
    showDetails: 'Vis detaljer',
  },
  ResolveIncidentConfirmationModal: {
    title: 'Marker som løst',
    description:
      'Når du bekrefter at feilen er løst, anses saken som ferdig. Feilmeldingen lukkes automatisk etter 3 dager.',
    description2:
      'Hvis feilen vedvarer kan du fremdeles kommentere i saken frem til den lukkes. Da åpner vi den igjen og hjelper deg med problemet.',
  },
  IncidentSidebar: {
    header: 'Detaljer',
    aboutIncident: 'Om feilmeldingen',
    aboutService: 'Om tjenesten',
    contacts: 'kontakter',
    followsTheIncident: 'Følger feilen',
    serviceLevelAgreement: 'Serviceavtale',
    referenceNumber: 'Referansenummer',
    followsTheService: 'Følger tjenesten',
    referenceNumberDescription:
      'Hvis du bruker et eksternt feilrettingssystem kan du legge ved et referansenummer her.',
    sendNotificationsByEmail: 'Send varslinger på e-post',
  },
  EditCustomerReference: {
    title: 'Referansenummer',
    description:
      'Dersom bedriften din bruker egne referansenummer for feilmeldingssaker kan du sette det her. Dette kan også endres etter at feilmeldingen er opprettet. ',
    helpText: 'Maks 40 tegn',
  },

  NotificationSelector: {
    title: 'Kontaktene du legger til skal varsles på:',
    email: 'Epost',
    sms: 'SMS',
  },
  IncidentContacts: {
    contactsHeading: 'Følger feilen',
    btnAdd: 'Legg til',
    btnEdit: 'Endre',
    addContacts: 'Legg til kontakter',
    add: 'Legg til',
  },
  ChatInput: {
    placeHolder: 'Skriv melding her',
    btnSend: 'Send',
    btnClear: 'Tøm',
  },
  ChatMessage: {
    btnShowLess: 'Vis mindre',
    btnShowMore: 'Vis mer',
    statusUpdate: 'Status satt til: ',
    setTo: 'satt til',
    emailFromCustomer: 'E-post fra kunde',
  },
  faultType: 'Feiltype',
  created: 'Opprettet',
  description: 'Beskrivelse',
  customerContact: 'Kundekontakt',
  createdBy: 'Opprettet av',
  IncidentSummary: {
    affectedService: 'Påvirket tjeneste',
    mainService: 'Hovedtjeneste',
    canceledStatusText:
      'Dersom du har noen spørsmål angående feilmeldingen kan du kontakte oss ved å sende e-post til feilmelding@telia.no.',
    status: 'Status',
    btnMarkSolved: 'Merk som løst',
    solvedWarning:
      'Når saken er løst vil Telia ikke lenger følge opp saken. Feilmeldingen vil markeres som løst.',
    subscriptionHeading: 'Tjenesten feilen gjelder',
    mainSubscriptionHeading: 'Hovedtjeneste',
    notificationErrorMesg: 'Vi kunne ikke lagre varslingsendringene',
    referenceErrorTooLong: 'For langt referansenummer',
    referenceErrorDefault: 'Vi kunne ikke lagre referansenummer',
    referenceSaved: 'Nytt referansenummer ble satt',
    severity: 'Påvirkningsgrad',
    minor: 'Mindre feil',
    major: 'Større feil',
    critical: 'Kritisk feil',
    getsNotificationOn: 'Varsles på',
    getsNotificationIn: 'Varsles i',
    portal: 'portalen',
    web: 'Kundeportalen',
    phone: 'Telefon',
    email: 'E-post',
    chat: 'Chat',
    monitoring: 'Proaktiv drift',
    unknown: 'Ukjent',
  },
  origin: 'Opphav',
};

export default incidents;
