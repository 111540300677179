import * as React from 'react';
import { Card, Page, Loading, Button } from 'src/lib/ui';
import { categoryDefinitions, CostCardHeader, CostAggregate } from './lib';
import InvoiceLineGroup from './lib/ui/InvoiceLineGroup';
import OrganisationCostSummary from './lib/ui/OrganisationCostSummary';

import { formatNordic, useRouter } from 'src/lib/utils';
import { reduce } from 'lodash';

import './InvoiceDetails.scss';
import { useInvoice } from './lib/queries/useInvoice';
import { extractCostMatrix } from './lib/utils/extractCostMatrix';
import { t } from 'src/lib/i18n';

const LabeledValue: React.FC<{
  label: string;
  value: string | React.ReactNode;
}> = ({ label, value }) => (
  <div className="InvoiceDetails-labeledValue">
    <div>{label}</div>
    <div>{value}</div>
  </div>
);

const keysToRender = (costAggregate?: null | CostAggregate[]) =>
  costAggregate
    ? reduce(
        costAggregate,
        (prev, entry) => {
          switch (entry.productGroup) {
            case 'Mobiltjenester':
              prev.mobile = true;
              break;
            case 'Fastnettjenester':
              prev.tele = true;
              break;
            case 'Datatjenester':
              prev.data = true;
              break;
            case 'Andre tjenester':
              prev.other = true;
              break;
            default:
              break;
          }
          return prev;
        },
        { mobile: false, tele: false, data: false, other: false }
      )
    : null;

export const InvoiceDetails = () => {
  const i18n = t.costs.InvoiceDetails;
  const router = useRouter<{ invoiceId: string }>();
  const { invoice, loading } = useInvoice(router.match.params.invoiceId);

  const costMatrix = extractCostMatrix(invoice?.costAggregate.data);

  const invoiceGroupsToRender = keysToRender(invoice?.costAggregate.data);

  const categoryDefs = categoryDefinitions();
  return (
    <Page className="InvoiceDetails">
      {loading ? (
        <Loading />
      ) : !invoice ? (
        <div className="InvoiceSection">{i18n.noInvoice}</div>
      ) : (
        <React.Fragment>
          <Card className="p-0 my-4">
            <CostCardHeader className="InvoiceDetails-summaryHeader">
              <Button
                icon="arrow-left"
                className="mr-3"
                hideLabel={true}
                onClick={router.history.goBack}
                color="purple"
              >
                {i18n.backBtn}
              </Button>
              <h3>
                {i18n.header} {invoice.id}
                {invoice.organisation
                  ? `, ${invoice.organisation.name} ${invoice.organisation.id}`
                  : undefined}
              </h3>
            </CostCardHeader>

            <div className="InvoiceDetails-thirds row">
              <div className="col-sm-4 px-4 pt-3 pb-4">
                <LabeledValue label={i18n.dueDate} value={invoice.dueDate} />
                <LabeledValue label={i18n.invoicePeriod} value={i18n.unknown} />
                <LabeledValue label={i18n.invoiceDate} value={invoice.date} />
              </div>
              <div className="col-sm-4 px-4 pt-3 pb-4">
                <LabeledValue label={i18n.reference} value="ukjent" />
                <LabeledValue
                  label={i18n.sentAs}
                  value={
                    invoice.organisation?.billingInfo?.useEhf
                      ? i18n.sentAsEhf
                      : invoice.organisation?.billingInfo?.useEmail
                      ? i18n.sentAsPdf
                      : i18n.sentAsMail
                  }
                />
                <LabeledValue
                  label={i18n.invoicePart}
                  value={invoice.organisation && invoice.organisation.id}
                />
              </div>
              <div className="col-sm-4 px-4 pt-3 pb-4">
                <p style={{ fontSize: '16px' }}>
                  {i18n.objectionString}
                  <br />
                  {i18n.contact}&nbsp;
                  <a href="mailto:faktura@telia.no">faktura@telia.no</a>
                </p>
                <p />
                <div
                  className="pt-3"
                  style={{ color: '#343642', fontWeight: 500 }}
                >
                  <div style={{ fontSize: '14px', color: '#353E6B' }}>
                    {i18n.returnAdr}
                  </div>
                  <div>Telia Norge AS</div>
                  <div>PB 4400 Nydalen</div>
                  <div>0403, Oslo</div>
                </div>
              </div>
            </div>
          </Card>

          <Card className="p-0 my-4">
            <CostCardHeader>
              <h3>{i18n.invoiceRecipient}</h3>
            </CostCardHeader>
            <div className="row InvoiceDetails-thirds">
              <div className="col-sm-4 px-4 pt-3 pb-4">
                <div className="InvoiceDetails-subHeader">{i18n.adr}</div>
                <div className="pb-3">
                  {invoice.organisation?.billingInfo?.address?.address && (
                    <div>
                      {invoice.organisation.billingInfo.address.address}
                    </div>
                  )}
                  {invoice.organisation?.billingInfo?.address?.address2 && (
                    <div>
                      {invoice.organisation.billingInfo.address.address2}
                    </div>
                  )}
                  {invoice.organisation?.billingInfo?.address?.postCode && (
                    <div>
                      {invoice.organisation.billingInfo.address.postCode}{' '}
                      {invoice.organisation.billingInfo.address.postArea}
                    </div>
                  )}
                </div>
                <div className="InvoiceDetails-subHeader">{i18n.email}</div>
                {invoice.organisation?.billingInfo?.email ?? (
                  <em>{i18n.noEmail}</em>
                )}
              </div>
              <div className="col-sm-4 px-4 pt-3 pb-4">
                <div className="pb-3">
                  <div className="InvoiceDetails-subHeader">
                    {i18n.billCycle}
                  </div>
                  {invoice.organisation?.billingInfo?.billingCycle ?? (
                    <em>{i18n.noReg}</em>
                  )}
                </div>

                <div className="pb-3">
                  <div className="InvoiceDetails-subHeader">
                    {i18n.paymentTerms}
                  </div>
                  {invoice.organisation?.billingInfo?.billingCycle ?? (
                    <em>{i18n.noReg}</em>
                  )}
                </div>
                <div>{i18n.noPaymentText}</div>
              </div>
              <div className="col-sm-4 px-4 pt-3 pb-4">
                <div className="InvoiceDetails-subHeader">
                  {i18n.paymentTerms}
                </div>
                <em>{i18n.noReg}</em>
              </div>
            </div>
          </Card>

          {invoice.costAggregate && costMatrix && (
            <OrganisationCostSummary
              costType="gross"
              header={i18n.invoiceSummary}
              costMatrix={costMatrix}
              summaryDescription={
                <div className="d-flex" style={{ fontSize: '18px' }}>
                  <div className="pr-3">
                    <div>{i18n.exclVat}</div>
                    <div>{i18n.inclVat}</div>
                  </div>
                  <div
                    className="d-flex flex-column align-items-end"
                    style={{ fontWeight: 500 }}
                  >
                    <div>{formatNordic(costMatrix.total.total.net, 2)}</div>
                    <div>
                      {formatNordic(
                        costMatrix.total.total.gross -
                          costMatrix.total.total.net,
                        2
                      )}
                    </div>
                  </div>
                </div>
              }
            />
          )}

          {categoryDefs.map(
            cat =>
              invoiceGroupsToRender?.[cat.key] && (
                <div
                  key={cat.key}
                  className="InvoiceDetails-InvoiceLineGroupWrapper"
                >
                  <InvoiceLineGroup
                    total={costMatrix?.[cat.key].total.gross ?? 0}
                    invoice={invoice.id}
                    cat={cat}
                  />
                </div>
              )
          )}
        </React.Fragment>
      )}
    </Page>
  );
};
