import React, { useState } from 'react';
import { Icon } from '@telia/styleguide';
import FilterSection from 'src/areas/main/common/FilterSection';
import { setFilterAndResetPage } from 'src/lib/utils/setFilterAndResetPage';
import { Button, FilterSelect } from 'src/lib/ui';
import { useSortFilter } from 'src/lib/filters';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils/useQueryParameters';
import './TagHeader.scss';
import CreateTagModal from 'src/areas/main/subscriptions/Pages/Tag/CreateTagModal';
import { parseStringToSortOrder } from 'src/lib/utils/parseStringTo';

export default function TagHeader(props: {
  totalResults: number;
  refetchTags: () => void;
}) {
  const translate = t.tags;
  const query = useQueryParameters();

  const [showCreateModal, setShowCreateModal] = useState(false);
  const toggleCreateModal = () => {
    setShowCreateModal(!showCreateModal);
  };
  const sortFields = [
    {
      key: 'tag',
      label: `${translate.sortFields.name}: ${translate.sortFields.AtoÅ}`,
      order: 'asc',
    },
    {
      key: 'tag',
      label: `${translate.sortFields.name}: ${translate.sortFields.ÅtoA}`,
      order: 'desc',
    },
  ];
  const sortOrder = parseStringToSortOrder(query.get('sortOrder')) ?? 'asc';
  const sortBy = 'tag';
  const sortOptions = useSortFilter({
    label: translate.sortFields.lable,
    sortFields: sortFields,
    setSort: (by, order) => {
      query.set({
        sortBy: by,
        sortOrder: order,
      });
    },
    currentSortBy: sortBy,
    currentSortOrder: sortOrder,
    defaultOption: { key: 'tag', order: 'asc' },
  });
  return (
    <div className="TagHeader">
      <div className="TagHeader-information row">
        <div className="col-sm-8 d-flex align-items-center">
          <span className="TagHeader-information-tagIcon">
            <Icon icon="tags" />
          </span>
          <div className="TagHeader-information-text">
            <div className="TagHeader-information-text-header">
              {translate.descriptionHeader}
            </div>
            {translate.descriptionBody}
          </div>
        </div>
        <div className="col-sm-4 d-flex">
          <Button
            className="TagHeader-information-createBtn"
            icon="add"
            transparent={true}
            color="white"
            onClick={toggleCreateModal}
          >
            {translate.create}
          </Button>
        </div>
      </div>
      <FilterSection
        searchValue={query.get('query') ?? ''}
        onSearch={val => setFilterAndResetPage(query, 'query', val)}
        onResetSearch={() => setFilterAndResetPage(query, 'query')}
        filterToggling={false}
        container={false}
        totalSearchResults={props.totalResults}
        totalFiltersActive={0}
        actions={<FilterSelect {...sortOptions} />}
        currentView={'list'}
      />
      <CreateTagModal
        show={showCreateModal}
        toggle={toggleCreateModal}
        title={translate.create}
        refetchTags={props.refetchTags}
      />
    </div>
  );
}
