import gql from 'graphql-tag';
import { useMutation } from 'react-apollo';
import { ContactFragment } from 'src/lib/types';
import * as t from './__types/useDowngradeUserToContact';
import { useCustomer } from 'src/lib/global';

const DOWNGRADE_USER_TO_CONTACT = gql`
  mutation useDowngradeUserToContact(
    $input: DowngradeToContactInput
    $customerId: ID!
  ) {
    downgradeToContact(input: $input) {
      contact {
        ... on Contact {
          ...ContactSearchResult
        }
        user {
          id
          customerAccess(customerId: $customerId) {
            areas
            roles {
              role
              organisation {
                id
                name
              }
              tag {
                id
                tag
              }
            }
          }
        }
      }
      errors {
        message
        code
      }
    }
  }
  ${ContactFragment}
`;

export interface DowngradeUserToContactResult {
  downgradeUserToContact: (contactId: string) => void;
  error: boolean;
  loading: boolean;
  data?: t.useDowngradeUserToContact_downgradeToContact | null;
}

export const useDowngradeUserToContact = (): DowngradeUserToContactResult => {
  const customer = useCustomer();
  const [mutation, { data, loading, error }] = useMutation<
    t.useDowngradeUserToContact,
    t.useDowngradeUserToContactVariables
  >(DOWNGRADE_USER_TO_CONTACT);
  const downgradeUserToContact = (contactId: string) =>
    mutation({
      variables: {
        input: {
          contactId,
        },
        customerId: customer.id,
      },
    });
  return {
    downgradeUserToContact,
    error: !!error || !!data?.downgradeToContact?.errors?.length,
    loading,
    data: data?.downgradeToContact,
  };
};
