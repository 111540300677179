import React from 'react';
import { Card, Button } from 'src/lib/ui';

interface Props {
  children: React.ReactChild;
  closeAction: () => void;
  disabled: boolean;
  className?: string;
}

const CloseableCard: React.FC<Props> = (props: Props) => {
  const { children, closeAction, disabled, ...rest } = props;

  const handleClose = (event: React.SyntheticEvent) => {
    event.preventDefault();
    closeAction();
  };
  return (
    <Card {...rest}>
      <div className="d-flex justify-content-between align-items-center">
        {props.children}
        <div>
          <Button
            disabled={disabled}
            transparent={true}
            rounded={true}
            hideLabel={true}
            icon="close"
            onClick={handleClose}
          />
        </div>
      </div>
    </Card>
  );
};

export default CloseableCard;
