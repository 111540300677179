import smsNo from '../../nb/Mobile/sms';

const sms: typeof smsNo = {
  recipients: 'Recipients',
  recipientsInputLabel: 'Write a phone number',
  writeSms: 'Write SMS',
  newSmsToSameRecipients: 'Send new SMS to same recipients',
  previouslySentSms: 'Previously sent SMS',
  characterCount: 'Character count',
  members: 'Members',
  showMoreSMS: 'Show more SMS',
  showXofY: (showing: number, total: number) =>
    `Showing ${showing} of ${total}`,
  send: 'Send',
  tlf: 'Tlf',
  invalidPhoneNumber: 'Invalid phone number',
  add: 'Add',
  sentTo: 'SMS sent to',
  failed: 'Could not send SMS to',
};

export default sms;
