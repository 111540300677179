import { Organisation, Tag } from 'src/lib/types';
import { groupBy, reduce, map, filter } from 'lodash';
import { Roles, RoleName, mapRoleNames } from '../createContact/types';

export const mapResponseToRoles = (
  roles: Array<{
    role: RoleName;
    organisation?: Organisation | null;
    tag?: Tag | null;
  }>
): Roles => {
  const groupedRoles = reduce(
    groupBy(roles, r => r.role),
    (coll, item, k) => {
      const roleKey = mapRoleNames[k];
      const tags = filter(map(item, i => (i.tag ? i.tag.tag : undefined)));

      const organisations = filter(
        map(item, i => (i.organisation ? i.organisation.id : undefined))
      );

      if (!roleKey) {
        throw new Error(`Invalid role key ${item[0].role}`);
      }

      const isFullAccess = !!item.find(r => !r.tag && !r.organisation);

      if (isFullAccess) {
        if (roleKey === 'adminRole') {
          coll[roleKey] = true;
        } else {
          coll[roleKey] = {
            role: k as RoleName,
            level: 'full',
          };
        }
      } else {
        if (roleKey === 'adminRole') {
          // admin should never have associated tags og organisations
          coll[roleKey] = false;
        } else {
          coll[roleKey] = {
            role: k as RoleName,
            tags,
            organisations,
            level: 'partial',
          };
        }
      }

      return coll;
    },
    {} as Roles
  );

  return groupedRoles;
};
