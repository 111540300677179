import React from 'react';
import { Card, colors, Toolbar } from 'src/lib/ui';
import { Contact } from 'src/lib/types';
import { t } from 'src/lib/i18n';
import { EditRoles } from '../editRoles/EditRoles';
import { ContactPermissions } from './ContactPermissions';
import { ContactNotifications } from './ContactNotifications';

interface Props {
  contact: Contact;
}

export const ContactPermissionsCard: React.FC<Props> = ({ contact }) => {
  const i18n = t.contacts;

  return (
    <Card border={true} style={{ color: colors.greyDarkText }}>
      <div className="preamble px-4 pt-4 pb-2" style={{ color: colors.black }}>
        {i18n.permissions}
      </div>
      <ContactPermissions contact={contact} />
      <ContactNotifications contact={contact} />
      <Toolbar className="ml-0 p-4 border-top-0">
        <EditRoles contact={contact} />
      </Toolbar>
    </Card>
  );
};
