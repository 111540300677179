export const formatInstallationAddress = (
  address:
    | {
        zip?: string | null;
        address?: string | null;
        city?: string | null;
      }
    | undefined
    | null,
  formatType: 'onlyAddress' | 'fullAddress'
): string => {
  if (!address) {
    return '';
  }
  if (formatType === 'onlyAddress') {
    if (address.address) {
      return address.address ?? '';
    }
  } else if (formatType === 'fullAddress') {
    let formattedAddress = '';
    if (address.address) {
      formattedAddress = address.address;
    }

    if (address.address && (address.zip || address.city)) {
      formattedAddress = formattedAddress + ', ';
    }

    if (address.zip) {
      formattedAddress = formattedAddress + address.zip;
    }

    if (address.city) {
      if (address.zip) {
        formattedAddress = formattedAddress + ' ' + address.city;
      } else {
        formattedAddress = formattedAddress + address.city;
      }
    }

    return formattedAddress;
  }

  return '';
};
