import * as React from 'react';
import gql from 'graphql-tag';
import { Query } from 'react-apollo';
import { WithCustomerContext } from 'src/lib/global';
import { Period, PeriodBy } from 'src/lib/types';
import { CostAggregate } from '../index';

const query = gql`
  query WithCostAggregateForOrganisation(
    $customerId: ID!
    $organisationId: ID!
    $recursive: Boolean!
    $periodStart: Period!
    $periodEnd: Period!
    $periodBy: PeriodByType!
    $productGroup: ProductGroupType
    $groupBy: [GroupByType!]!
  ) {
    customer(id: $customerId) {
      id
      organisation(id: $organisationId) {
        id
        costAggregate(
          recursive: $recursive
          periodStart: $periodStart
          periodEnd: $periodEnd
          periodBy: $periodBy
          productGroup: $productGroup
          groupBy: $groupBy
        ) {
          recursive
          data {
            productDesignId
            productDesignDescription
            groupedProductDesign
            costType
            periodGroup
            productGroup
            totalAmountGross
            totalAmountNet
          }
        }
      }
    }
  }
`;

interface Variables {
  customerId: string;
  recursive: boolean;
  periodStart: Period;
  periodEnd: Period;
  periodBy: PeriodBy;
  organisationId: string;
  productGroup?: 'mobile' | 'tele' | 'data' | 'other';
  groupBy: Array<
    | 'month'
    | 'costType'
    | 'productGroup'
    | 'productDesign'
    | 'groupedProductDesign'
  >;
}

interface Data {
  customer: {
    organisation: {
      costAggregate: {
        data: CostAggregate[];
      };
    };
  };
}

interface Result {
  loading: boolean;
  costAggregates: CostAggregate[];
}

interface Props {
  recursive: boolean;
  periodStart: Period;
  periodEnd: Period;
  periodBy: PeriodBy;
  organisationId: string;
  productGroup?: 'mobile' | 'tele' | 'data' | 'other';
  groupBy: Array<
    | 'month'
    | 'costType'
    | 'productGroup'
    | 'productDesign'
    | 'groupedProductDesign'
  >;
  children: (result: Result) => JSX.Element | null;
}

export const WithCostAggregateForOrganisation = (props: Props) => (
  <WithCustomerContext>
    {context => (
      <Query<Data, Variables>
        query={query}
        variables={{
          customerId: context.id,
          recursive: props.recursive,
          periodStart: props.periodStart,
          periodEnd: props.periodEnd,
          periodBy: props.periodBy,
          productGroup: props.productGroup,
          groupBy: props.groupBy,
          organisationId: props.organisationId,
        }}
      >
        {result => {
          const res =
            result.data &&
            result.data.customer &&
            result.data.customer.organisation &&
            result.data.customer.organisation.costAggregate
              ? {
                  loading: result.loading,
                  costAggregates:
                    result.data.customer.organisation.costAggregate.data,
                }
              : { loading: result.loading, costAggregates: [] };
          return props.children(res);
        }}
      </Query>
    )}
  </WithCustomerContext>
);
