import { Bandwidth } from 'src/lib/types';

export const formatSingleBandwidth = (
  bandwidth?: null | number,
  decimals?: number
) => {
  if (typeof bandwidth !== 'number') {
    return null;
  }

  if (bandwidth < 1024) {
    return `${bandwidth} kbit/s`;
  }

  return `${(bandwidth / 1024).toFixed(decimals)} Mbit/s`;
};

/**
 *
 * @param bandwidth Bandwidth object containing upstream and downstream in Kbit.
 * @param decimals Number of decimals in bandwidth value.
 * @returns String version of bandwidth in kbit/s or Mbit/s. If up/down speed are the same, return only one value.
 */
export const formatBandwidth = (
  bandwidth?: null | Bandwidth,
  decimals: number = 0
) => {
  if (!bandwidth) {
    return null;
  }

  if (!bandwidth.downstream || !bandwidth.upstream) {
    return '';
  }

  if (bandwidth.downstream === bandwidth.upstream) {
    return formatSingleBandwidth(bandwidth.downstream, decimals);
  }

  return (
    formatSingleBandwidth(bandwidth.downstream, decimals) +
    ' / ' +
    formatSingleBandwidth(bandwidth.upstream, decimals)
  );
};
