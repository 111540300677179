import * as React from 'react';

import { toast as toastifyToast, ToastOptions } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './Toast.scss';
import { Button } from '../Button/Button';
import { t } from 'src/lib/i18n';
import { Icon, IconDefinition } from '@telia/styleguide';
import cn from 'classnames';
export const CloseButton: React.FC<{ closeToast?: () => void }> = ({
  closeToast,
}) => (
  <Button size="small" onClick={closeToast}>
    {t.ui.Toast.close}
  </Button>
);
// Global Toast configuration. Once per project.
toastifyToast.configure({
  autoClose: false,
  position: toastifyToast.POSITION.BOTTOM_RIGHT,
  draggable: false,
  closeOnClick: true,
  pauseOnHover: false,
  toastClassName: 'toast-container',
  closeButton: <CloseButton />,
});

interface ToastCardProps {
  text: string;
  icon?: IconDefinition;
  className: string;
}

function ToastCard(props: ToastCardProps) {
  return (
    <div className={cn('toast', props.className)}>
      <div className="row m-0">
        <div className="d-flex align-items-center">
          <div className="stripe" />
          {props.icon ? <Icon icon={props.icon} className="mx-3" /> : null}
          <p>{props.text}</p>
        </div>
      </div>
    </div>
  );
}

type ToastType = 'ok' | 'error' | 'warning';

const toastTypeMap: Record<ToastType, Omit<ToastCardProps, 'text'>> = {
  ok: { icon: 'check-mark', className: 'toast-success' },
  error: { icon: 'alert', className: 'toast-error' },
  warning: { icon: 'alert', className: 'toast-warning' },
};

export function toast(
  toastType: ToastType,
  text: string,
  options?: ToastOptions
) {
  const opts = toastTypeMap[toastType];
  return toastifyToast(
    <ToastCard text={text} icon={opts.icon} className={opts.className} />,
    {
      ...options,
      autoClose: options?.autoClose ?? 5000,
      hideProgressBar: true,
      closeButton: false,
    }
  );
}
