import { PlannedWorkState } from 'src/__types/graphql-global-types';
import { t } from 'src/lib/i18n';
export const getPlannedWorkState = (state: PlannedWorkState) => {
  switch (state) {
    case PlannedWorkState.Cancelled:
      return t.ui.PlannedWorlState.cancelled;
    case PlannedWorkState.Closed:
      return t.ui.PlannedWorlState.closed;
    case PlannedWorkState.Implement:
      return t.ui.PlannedWorlState.inProgress;
    case PlannedWorkState.Review:
      return t.ui.PlannedWorlState.inProgress;
    case PlannedWorkState.Scheduled:
      return t.ui.PlannedWorlState.scheduled;
  }
};
