import React, { useState } from 'react';
import { t } from 'src/lib/i18n';
import { CallForwardingMobileSettings } from 'src/lib/types';
import { SettingsListItem } from 'src/areas/mobile/settings/SettingsListItem';
import { SettingsCard } from 'src/areas/mobile/settings/SettingsCard';
import CallForwardingFormModal from 'src/areas/mobile/settings/forms/CallForwardingFormModal';

interface Props {
  callForwarding: CallForwardingMobileSettings;
}
export const CallForwardingSettings: React.FC<Props> = ({ callForwarding }) => {
  const [isModalOpen, setModalOpen] = useState(false);

  const i18n = t.mobile.settings;
  return (
    <SettingsCard
      title={i18n.forwarding.forwarding}
      description={i18n.forwarding.forwardingInfo}
      onClick={() => setModalOpen(true)}
    >
      <SettingsListItem
        title={i18n.forwarding.forwardAll}
        description={
          callForwarding.allCalls
            ? callForwarding.allCallsDestination
              ? i18n.forwarding.callRecipient(
                  callForwarding.allCallsDestination
                )
              : i18n.forwarding.forwardedToVoicemail
            : i18n.notActivated
        }
      />

      <SettingsListItem
        title={i18n.forwarding.forwardBusy}
        description={
          callForwarding.busy
            ? i18n.forwarding.callRecipient(callForwarding.busyDestination)
            : i18n.voicemail.voicemail
        }
      />

      <SettingsListItem
        title={i18n.forwarding.forwardNoAnswer}
        description={
          callForwarding.noAnswer
            ? i18n.forwarding.callRecipient(callForwarding.noAnswerDestination)
            : i18n.voicemail.voicemail
        }
      />

      <CallForwardingFormModal
        isOpen={isModalOpen}
        toggle={() => setModalOpen(!isModalOpen)}
        callForwarding={callForwarding}
      />
    </SettingsCard>
  );
};
