import React from 'react';
import { Chip } from 'src/lib/ui';
import { t } from 'src/lib/i18n';
import { useQueryParameters } from 'src/lib/utils';

export const FilterChips = () => {
  const query = useQueryParameters();
  const stautsFilter = query.getArray('status');
  const includesOffline = stautsFilter.includes('critical');
  const caseFilter = query.getArray('cases');
  const includesPlannedWork = caseFilter.includes('withPlannedWork');
  const groupFilter = query.getArray('group');
  const includesData = groupFilter.includes('data');
  const includesMobile = groupFilter.includes('mobile');
  const includesTv = groupFilter.includes('tv');
  const includesTele = groupFilter.includes('tele');

  const removeFilter = (key: string, value: string, initialFilter: string[]) =>
    query.set(
      key,
      initialFilter.filter(filterValue => filterValue !== value)
    );

  const addFilter = (key: string, value: string, initialFilter: string[]) =>
    query.set(key, [...initialFilter, value]);

  return (
    <div className="pb-2">
      <Chip
        className="mr-2 mb-2"
        label={t.locations.Sidebar.filters.offline}
        icon="wireless-off"
        mode="select"
        active={includesOffline}
        onClick={() =>
          includesOffline
            ? removeFilter('status', 'critical', stautsFilter)
            : addFilter('status', 'critical', stautsFilter)
        }
      />

      <Chip
        className="mr-2 mb-2"
        label={t.locations.Sidebar.filters.plannedWork}
        icon="wrench"
        mode="select"
        active={includesPlannedWork}
        onClick={() =>
          includesPlannedWork
            ? removeFilter('cases', 'withPlannedWork', caseFilter)
            : addFilter('cases', 'withPlannedWork', caseFilter)
        }
      />

      <Chip
        className="mr-2 mb-2"
        label={t.locations.Sidebar.filters.data}
        icon="wireless"
        mode="select"
        active={includesData}
        onClick={() =>
          includesData
            ? removeFilter('group', 'data', groupFilter)
            : addFilter('group', 'data', groupFilter)
        }
      />

      <Chip
        className="mr-2 mb-2"
        label={t.locations.Sidebar.filters.mobile}
        icon="mobile"
        mode="select"
        active={includesMobile}
        onClick={() =>
          includesMobile
            ? removeFilter('group', 'mobile', groupFilter)
            : addFilter('group', 'mobile', groupFilter)
        }
      />

      <Chip
        className="mr-2 mb-2"
        label={t.locations.Sidebar.filters.tele}
        icon="phone"
        mode="select"
        active={includesTele}
        onClick={() =>
          includesTele
            ? removeFilter('group', 'tele', groupFilter)
            : addFilter('group', 'tele', groupFilter)
        }
      />

      <Chip
        className="mr-2 mb-2"
        label={t.locations.Sidebar.filters.tv}
        icon="tv"
        mode="select"
        active={includesTv}
        onClick={() =>
          includesTv
            ? removeFilter('group', 'tv', groupFilter)
            : addFilter('group', 'tv', groupFilter)
        }
      />
    </div>
  );
};
