import React, { useState, useEffect } from 'react';
import { Switch, Route, Redirect } from 'react-router';
import * as Sentry from '@sentry/browser';
import CustomerSelection from './customerSelection/CustomerSelection';
import { useUserPreferences } from 'src/lib/userPreferences/UserPreferencesContext';
import AceChatWidget from 'src/areas/chat/AceChatWidget';
import { AppContext, AppContextProvider } from 'src/lib/global/AppContext';
import { FullScreenError } from 'src/lib/ui';
import { useCustomerAccessQuery } from 'src/lib/global/CustomerAccessQuery';
import { map } from 'lodash';
import {
  CustomerContextProvider,
  CustomerContext,
} from 'src/lib/global/CustomerContext';
import { useRouter } from 'src/lib/utils/useRouter';

import Dev from './dev/Dev'; // const Dev = React.lazy(() => import('./dev/Dev'));
import MainApp from './main/MainApp'; // const MainApp = React.lazy(() => import('./main/MainApp'));
import MobileApp from './mobile/MobileApp'; // const MobileApp = React.lazy(() => import('./mobile/MobileApp'));
import DashboardApp from './dashboard/DashboardApp'; // const DashboardApp = React.lazy(() => import('./dashboard/DashboardApp'));

import { SavedUserPreferencesProvider } from 'src/lib/userPreferences/SavedUserPreferencesContext';
import { useAuthContext } from 'src/modules/auth/AuthContext';
import useAccessQuery from '../lib/global/useAccessQuery';

import useInitialSplashLoader from './useInitialSplashLoader';

type ActiveCustomer = undefined | { id: string; name: string };

/**
 *
 * <App> will always be rendered, no matter if the user is
 * authenticated, has access etc. This component should handle
 * global loading states (e.g. 'authenticating').
 *
 */
function App() {
  // Active mobile subscription and customer
  const [activeMobileSubscriptionId, setActiveMobileSubscriptionId] = useState<
    string | undefined
  >(undefined);
  const [activeCustomer, setActiveCustomer] = useState<ActiveCustomer>();

  const auth = useAuthContext();
  const router = useRouter();
  const userPrefs = useUserPreferences();
  // Don't bother sending the access query if we are not authenticated.
  const accessQuery = useAccessQuery({ skip: !auth || !auth.isAuthenticated });

  // The customer access query will not run when activeCustomer is undefined
  const customerAccessQuery = useCustomerAccessQuery(activeCustomer);

  /**
   * Whenever the active customer changes, we want to update the
   * preferred customer to remember it when the user refreshes.
   *
   * And let Sentry know, so subsequent Sentry events are tagged
   * with the current username and password.
   */
  useEffect(() => {
    if (activeCustomer) {
      if (activeCustomer.id !== userPrefs.activeCustomerId) {
        userPrefs.setPreferredCustomer(activeCustomer.id, activeCustomer.name);
      }

      Sentry.configureScope(scope => {
        scope.setTag('customer', activeCustomer.id);
        scope.setExtra('customer_name', activeCustomer.name);
      });
    }
  }, [activeCustomer, userPrefs]);

  /**
   * When we're authenticated (or the User changes),
   * figure out what customer to select by default
   */
  useEffect(() => {
    if (!accessQuery.data || !accessQuery.data.me) {
      return;
    }
    // First, look at the customers to which the user has been given explicit access.
    // const explicitCustomers = accessQuery?.data?.me?.customers || [];
    const explicitCustomers =
      (accessQuery.data &&
        accessQuery.data.me &&
        accessQuery.data.me.customers) ||
      [];

    // Whether the user is allowed to search customers
    const allowCustomerSearch =
      accessQuery.data &&
      accessQuery.data.me &&
      accessQuery.data.me.allowCustomerSearch;

    if (explicitCustomers || allowCustomerSearch) {
      // If the user has a preferred customer set, we set the
      // active customer to that IF we have access to it.
      if (
        userPrefs.activeCustomerId &&
        userPrefs.activeCustomerName &&
        (explicitCustomers.some(c => c.id === userPrefs.activeCustomerId) ||
          allowCustomerSearch)
      ) {
        setActiveCustomer({
          id: userPrefs.activeCustomerId,
          name: userPrefs.activeCustomerName,
        });
      }
    }
  }, [accessQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * When we're authenticated (or the User changes),
   * figure out what mobile subscription to select by default
   */
  useEffect(() => {
    if (!accessQuery.data || !accessQuery.data.me) {
      return;
    }
    // We just take the first mobile subscription and set it to the active one.
    if (
      accessQuery.data.me.mobileSubscriptions &&
      accessQuery.data.me.mobileSubscriptions.length >= 1 &&
      activeMobileSubscriptionId === undefined
    ) {
      setActiveMobileSubscriptionId(
        accessQuery.data.me.mobileSubscriptions[0].id
      );
    }
  }, [accessQuery.data]); // eslint-disable-line react-hooks/exhaustive-deps

  /**
   * If we're not authenticated, we assume that we are currently being
   * redirected or waiting for the Keycloak adapter in some other way.
   *
   * Also, show loading indicator while we are waiting for the results
   * from the access query.
   */
  const stillLoading =
    !auth?.isAuthenticated ||
    accessQuery.loading ||
    customerAccessQuery.loading;

  useInitialSplashLoader({ stillLoading });

  if (stillLoading) {
    return null;
  }

  if (!accessQuery.data || !accessQuery.data.me) {
    console.log('No data in result?', accessQuery.data);
    return (
      <FullScreenError technicalReason="Application access context query empty" />
    );
  }

  const customerAccess =
    customerAccessQuery &&
    customerAccessQuery.data &&
    customerAccessQuery.data.me &&
    customerAccessQuery.data.me.customerAccess
      ? customerAccessQuery.data.me.customerAccess
      : undefined;

  const customerFlexFields = customerAccess
    ? customerAccess.customer.flexFields
    : [];

  const customerPermissions = customerAccess
    ? customerAccess.customer.permissions
    : undefined;

  const appContext: AppContext = {
    auth: auth,

    cannyToken:
      customerAccessQuery &&
      customerAccessQuery.data &&
      customerAccessQuery.data.me
        ? customerAccessQuery.data.me.cannyToken
        : undefined,

    access: accessQuery.data.me,
    customerFlexFields: customerFlexFields,
    globalSystemMessage: accessQuery.data.globalSystemMessage || undefined,
    activeCustomerId: activeCustomer ? activeCustomer.id : undefined,
    activeCustomerName: activeCustomer ? activeCustomer.name : undefined,
    activeSubscriptionId: activeMobileSubscriptionId,
    setActiveSubscriptionId: setActiveMobileSubscriptionId,

    setActiveCustomer: (id: string, name: string) =>
      setActiveCustomer({ id, name }),

    customerFeatureFlags:
      customerAccessQuery &&
      customerAccessQuery.data &&
      customerAccessQuery.data.me
        ? map(customerAccessQuery.data.me.featureFlags, r => r)
        : [],

    customerAreas:
      customerAccess && customerAccess.areas ? customerAccess.areas : [],

    customerRoles:
      customerAccess && customerAccess.roles
        ? customerAccess.roles.map(r => r.role)
        : [],

    customerPermissions: customerPermissions,
  };

  const customerContext: CustomerContext = {
    id: activeCustomer ? activeCustomer.id : '',
    name: activeCustomer ? activeCustomer.name : '',
    auth: auth,
    access: accessQuery.data.me,

    myContact:
      customerAccessQuery &&
      customerAccessQuery.data &&
      customerAccessQuery.data.me &&
      customerAccessQuery.data.me.contact
        ? customerAccessQuery.data.me.contact
        : null,

    flexFields: customerFlexFields,
    permissions: customerPermissions,
  };

  const renderChatWidget =
    router.location.pathname.indexOf('/dev') !== 0 &&
    router.location.pathname.indexOf('/worklog') !== 0;

  return (
    <AppContextProvider value={appContext}>
      <CustomerContextProvider value={customerContext}>
        <SavedUserPreferencesProvider>
          <Switch>
            <Route path="/dev" component={Dev} />
            <Route path="/velg-kunde" component={CustomerSelection} />
            <Route path="/minside" component={MobileApp} />
            <Route path="/dashboard" component={DashboardApp} />
            <Route path="/intern" render={() => <Redirect to="/" />} />
            <Route path="/ekstern" render={() => <Redirect to="/" />} />
            <Route component={MainApp} />
          </Switch>
          {renderChatWidget && <AceChatWidget />}
        </SavedUserPreferencesProvider>
      </CustomerContextProvider>
    </AppContextProvider>
  );
}

export default App;
