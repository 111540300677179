import myDashboardNo from '../../nb/Dashboard/myDashboard';

const myDashboard: typeof myDashboardNo = {
  heading: {
    profile: 'Profile and settings',
    services: 'Service access',
  },
  title: {
    preferences: 'Notification preferences',
    portal: 'Min portal',
    mobileSite: 'Min side',
    services: 'My services',
    profile: 'My profile',
  },
  btnDevelop: 'Development',
  description: {
    preferences: 'Notifications on services and incidents...',
    portal:
      'Information about services , report incidents, order new services...',
    profile: 'Username and password, address and user information...',
    mobileSite: 'Your mobile subscriptions, invoices, upgrade, usage, costs...',
    services: 'Get an overview of services you are contact person for...',
    mobileSubscription: (number: string) =>
      `View details and make changes to you mobile subscription for ${number}`,
    customer: (name: string) =>
      `Information about services , report incidents, order new services for ${name}`,
  },
  myLanguage: {
    language: 'Language:',
    norwegian: 'Norsk',
    english: 'English',
  },
  btnSignOut: 'Sign out',
  btnBack: 'Back',
};

export default myDashboard;
