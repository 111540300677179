import * as React from 'react';
import { t } from 'src/lib/i18n';
import Table from 'reactstrap/lib/Table';
import './PeriodTable.scss';
import { PeriodTableLine } from 'src/areas/mobile/history/PeriodTableLine';
import { MobilePeriodUsage } from 'src/areas/mobile/types';
import { formatNordic } from 'src/lib/utils';

interface Props {
  usage: MobilePeriodUsage;
}

export const PeriodTable: React.FC<Props> = ({ usage }) => {
  const i18n = t.mobile.history;
  const lines = [
    {
      description: i18n.nationalDataVolume,
      volume:
        usage.nationalDataVolume !== null
          ? `${formatNordic(usage.nationalDataVolume, 2)} MB`
          : '',
      cost: usage.nationalDataAmount,
    },
    {
      description: i18n.roaming,
      volume: '',
      cost: usage.roamingAmount,
    },
    {
      description: i18n.abroadVolume,
      volume:
        usage.abroadVolume !== null
          ? `${formatNordic(usage.abroadVolume, 2)} min`
          : '',
      cost: usage.abroadAmount,
    },
    {
      description: i18n.nationalCallVolume,
      volume:
        usage.nationalCallVolume !== null
          ? `${formatNordic(usage.nationalCallVolume, 2)} min`
          : '',
      cost: usage.nationalCallAmount,
    },
    {
      description: i18n.specialNumberVolume,
      volume:
        usage.specialNumberVolume !== null
          ? `${formatNordic(usage.specialNumberVolume, 2)} min`
          : '',
      cost: usage.specialNumberAmount,
    },
    {
      description: i18n.smsVolume,
      volume:
        usage.smsVolume !== null
          ? `${formatNordic(usage.smsVolume, 0)} stk`
          : '',
      cost: usage.smsAmount,
    },
    {
      description: i18n.mmsVolume,
      volume:
        usage.mmsVolume !== null
          ? `${formatNordic(usage.mmsVolume, 0)} stk`
          : '',
      cost: usage.mmsAmount,
    },
  ];
  return (
    <div className="PeriodTable">
      <Table hover={false} responsive={true} className="InvoiveLineTable m-0">
        <colgroup>
          <col span={1} style={{ width: '33%' }} />
          <col span={1} style={{ width: '33%' }} />
          <col span={1} style={{ width: '33%' }} />
        </colgroup>
        <thead>
          <tr className="pt-3 pb-3">
            <th>{t.mobile.history.product}</th>
            <th>{t.mobile.history.volume}</th>
            <th>{t.mobile.history.subtotal}</th>
          </tr>
        </thead>
        <tbody>
          {lines.map((line, i) => (
            <PeriodTableLine
              key={i}
              description={line.description}
              volume={line.volume}
              cost={line.cost}
            />
          ))}
        </tbody>
      </Table>
    </div>
  );
};
