import { IncidentSearchResult } from './../../../../lib/types/incident/__types/IncidentSearchResult';
import { Notification } from './../../../dashboard/Notification/query/useNotifications';
import gql from 'graphql-tag';

import {
  Cluster,
  ClusterFragment,
  Contact,
  ContactFragment,
  File,
  FileFragment,
  IncidentFragment,
  Invoice,
  InvoiceFragment,
  Organisation,
  OrganisationFragment,
  Subscription,
  SubscriptionFragment,
  CostCenter,
  CostCenterFragment,
  Tag,
  TagFragment,
  Order,
  OrderFragment,
  ResourceType,
  NotificationFragment,
  DomainResult,
} from 'src/lib/types';

import { Search_customer_search_results } from './__types/Search';

/**
 * Search parameter types
 */

export type FilterValue = string | number | boolean | undefined | null;

export type Filter = {
  filter: string;
  value: FilterValue | FilterValue[];
};

export type SingleAreaSearchParameters = {
  query?: string;
  sortBy?: string;
  sortOrder?: 'asc' | 'desc';
  filter?: Filter[];
  types: [ResourceType]; // Må være én enkelt type
  page?: number;
  size?: number;
  after?: string;
  cluster?: boolean;
};

export type GeneralSearchParameters = {
  query?: string;
  types?: ResourceType[];
  page?: number;
  size?: number;
  after?: string;
};

export type SearchInput = SingleAreaSearchParameters | GeneralSearchParameters;

// The QueryVariables type is a bit more general than the SearchInput in order to match the more general GraphQL type.
export interface QueryVariables {
  customerId: string;
  search: {
    query?: string;
    sortBy?: string;
    sortOrder?: 'asc' | 'desc';
    filter?: Filter[];
    types?: ResourceType[];
    after?: string;
    page?: number;
    size?: number;
    cluster?: boolean;
  };
}

export type WrappedSearchResult = Search_customer_search_results;

/**
 * TODO: Remove custom types and use generated.
 * Since we have many components that are using the custom types in the props-definition.
 * We keep this for now until we refactor all our components to stop using these types.
 */
export type SearchResult =
  | Cluster
  | Contact
  | CostCenter
  | File
  | IncidentSearchResult
  | Invoice
  | Order
  | Organisation
  | Subscription
  | Tag
  | Notification;

export const searchQuery = gql`
  query Search($customerId: ID!, $search: SearchInput) {
    customer(id: $customerId) {
      id
      search(input: $search) {
        size
        totalResults
        totalResultsByDomain {
          domain
          resultCount
        }
        cursor
        results {
          __typename
          ... on ClusterSearchResult {
            cluster {
              ...ClusterSearchResult
            }
          }
          ... on ContactSearchResult {
            contact {
              ...ContactSearchResult
            }
          }
          ... on CostCenterSearchResult {
            costCenter {
              ...CostCenterSearchResult
            }
          }
          ... on FileSearchResult {
            file {
              ...FileSearchResult
            }
          }
          ... on IncidentSearchResult {
            incident {
              ...IncidentSearchResult
            }
          }
          ... on InvoiceSearchResult {
            invoice {
              ...InvoiceSearchResult
            }
          }
          ... on OrderSearchResult {
            order {
              ...OrderSearchResult
            }
          }
          ... on OrganisationSearchResult {
            organisation {
              ...OrganisationSearchResult
            }
          }
          ... on SubscriptionSearchResult {
            subscription {
              ...SubscriptionSearchResult
            }
          }
          ... on TagSearchResult {
            tag {
              ...TagSearchResult
            }
          }
          ... on NotificationSearchResult {
            notification {
              ...NotificationSearchResult
            }
          }
        }
      }
    }
  }
  ${ClusterFragment}
  ${ContactFragment}
  ${CostCenterFragment}
  ${FileFragment}
  ${IncidentFragment}
  ${InvoiceFragment}
  ${OrderFragment}
  ${OrganisationFragment}
  ${SubscriptionFragment}
  ${TagFragment}
  ${NotificationFragment}
`;

export interface QueryData {
  customer: {
    search: {
      size: number;
      totalResults: number;
      totalResultsByDomain: Array<DomainResult>;
      cursor?: string;
      results: SearchResult[];
    };
  };
}
