import searchNb from '../../nb/Main/search';

const search: typeof searchNb = {
  ResourceType: {
    contact: 'Contacts',
    file: 'Documents',
    incident: 'Incidents',
    invoice: 'Invoices',
    organisation: 'Invoice centers',
    subscription: 'Services',
    dataSubscription: 'Networks',
    mobileSubscription: 'Mobile',
    teleSubscription: 'Telephony',
    tvSubscription: 'TV',
    otherSubscription: 'Other services',
    costCenter: 'Cost centers',
    tag: 'Tags',
    order: 'Orders',
  },

  SearchListCostCenterResult: {
    costCenter: 'Cost center',
  },

  SearchListSubscriptionResult: {
    noConnection: 'No connection',
    incident: 'Incident',
  },

  SearchListInvoiceResult: {
    titlePrefix: 'Invoice',
    organisation: 'Invoice center',
    invoiceDate: 'Invoice date',
    dueDate: 'Due date',
    amountExclVat: 'Amount excl. VAT',
  },

  SearchListOrderResult: {
    titlePrefix: 'Order',
    created: 'Created',
    lastUpdated: 'Last updated',
  },

  SearchListIncidentResult: {
    title: 'Incident',
    opened: 'Created',
    lastUpdated: 'Last updated',
  },

  SearchListPlannedWorkResult: {
    title: 'Planned Work',
    active: 'Active',
    opened: 'Created',
    closed: 'Closed',
    lastUpdated: 'Last updated',
    expectedDowntime: 'Expected downtime',
  },

  SearchListResult: {
    noResult: 'No hits on search',
  },

  SearchMenuHistoryEntry: {
    removeFromHistory: 'Remove entry from history',
  },

  SearchMenuInvoiceResult: {
    titlePrefix: 'Invoice',
    currencyInclVat: (formattedCurrency: string) =>
      `kr ${formattedCurrency} incl. VAT`,
    currencyExclVat: (formattedCurrency: string) =>
      `kr ${formattedCurrency} excl. VAT`,
  },

  SearchMenu: {
    foundHits: (hits: number, total: number) =>
      `${hits} ${hits === 1 ? 'hit' : 'hits'} (of ${total})`,
    noHits: 'No results',
    loading: 'Loading ...',
    sidemenuTitle: 'Categories',
    allResults: (n: number) => `All results (${n})`,
  },

  Search: {
    showingNResults: (n: number, total: number) => `Showing ${n} of ${total}`,
    searchingForSuffix: (resourceTypeLabel: string) =>
      ` in ${resourceTypeLabel}`,
    title: (label?: string) => (label ? `Results for «${label}»` : 'Results'),
  },

  SearchField: {
    placeholder: 'Search',
  },
};

export default search;
