import React, { useRef, useState, useEffect } from 'react';
import { useExpandableTextArea } from 'src/lib/ui/TextArea/useExpandableTextArea';
import { TextArea, colors, Button } from 'src/lib/ui';
import { t } from 'src/lib/i18n';

interface Props {
  message: string;
  handleEditSave: (comment: string) => void;
  cancel: () => void;
}

export function EditChatMessage(props: Props) {
  const textAreaRef = useExpandableTextArea();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [message, setMessage] = useState(props.message);

  useEffect(() => {
    if (textAreaRef.current) {
      textAreaRef.current.focus();

      wrapperRef.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
      });
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div
      className="px-3 pb-3"
      style={{
        backgroundColor: colors.greyLight,
        borderRadius: '0.5rem',
        paddingTop: '0.75rem',
      }}
      ref={wrapperRef}
    >
      <TextArea
        innerRef={textAreaRef}
        className="w-100 pb-3"
        value={message}
        onChange={e => setMessage(e.target.value)}
      />
      <div className="text-right">
        <Button
          className="mr-3"
          onClick={props.cancel}
          transparent={true}
          size="small"
        >
          {t.ui.FormSubmitBar.btnCancel}
        </Button>
        <Button
          onClick={() => props.handleEditSave(message)}
          color="purple"
          size="small"
        >
          {t.ui.FormSubmitBar.btnSubmit}
        </Button>
      </div>
    </div>
  );
}
